/*
* Author: zhangmeng
* Description: '新闻动态组件'
* Date: 2021/05/12
*/

import React, { useState } from 'react';
import { openNewPage } from '../../../../../utils/urlMethods';
import NewListLable from '../../../../NewListLable';
import PaginationComp from '../../../../Pagination';
import style from './index.module.less';

function NewsDynamic(props) {
  const { data: { data, count }, getList, type } = props;
  const [pageSize, setPageSize] = useState(5); // 默认选中5条/每页
  const [currPage, setCurrPage] = useState(1); // 当前页默认为1

  // 条/页 select-onChange
  const selectOnChange = (value) => {
    setPageSize(value);
    setCurrPage(1);
    getList({
      page_number: 1,
      page_size: value,
    });
  };

  // pagination-onChange
  const paginationOnChange = (page) => {
    setCurrPage(page);
    getList({
      page_number: page,
    });
  };

  const pushToDetail = (param) => {
    if (type === 'news') {
      window.open(param.url);
    } else if (type === 'policy') {
      window.open(param.link);
    }
  };

  return (
    <div className={style.all}>
      {
        data && data.length > 0 && data.map((item, index) => (
          <div key={`item${index}`} onClick={() => pushToDetail(item)}>
            <div className={style.new}>
              <div className={style.headBox}>
                <div className={style.title}>
                  <div className={style.titleWord}>{item.title}</div>
                </div>
                <div className={style.time} style={{ display: `${item.time ? '' : 'none'}`, right: type === 'news' ? '0' : '20%' }}>
                  {item.time}
                </div>
                <div className={style.source} style={{ display: `${item.source ? '' : 'none'}` }}>
                  {item.source}
                </div>
              </div>
              <NewListLable labels={item.tabs} />
              <div className={style.abstractBox} style={{ display: `${item.abstract ? '' : 'none'}` }}>
                <div className={style.abstract}>
                  <span style={{ color: '#1961F5' }}>[简介]</span>
                  {item.abstract}
                </div>
              </div>
              <div className={style.footer}>
                <div className={style.dashedLine} />
              </div>
            </div>
          </div>
        ))
      }
      {
        count > 0 && (
          <PaginationComp
            total={count > 100 ? 100 : count}
            currPage={currPage}
            pageSize={pageSize}
            paginationOnChange={paginationOnChange}
            selectOnChange={selectOnChange}
            isNeedPages={false}
          />
        )
      }
    </div>
  );
}

export default NewsDynamic;
