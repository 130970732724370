import style from './tagColor.module.less';

export const tagStyleMap = {
  所属行业: style.industry,
  公司资质: style.certification,
  资质认证: style.zizhirenzheng,
  获奖情况: style.huojiangqingkuang,
  试点示范: style.shidianshifan,
  国际准入: style.shichangzhunru,
  业务领域: style.yewulingyu,
  产业类型: style.chanyeleixing,
  产品信息: style.chanpinxinxi,
  融资阶段: style.rongzijieduan,
  其他标签: style.qitabiaoqian,
  other: style.other,
  其他: style.qita,
  专利其他标签: style.zhuanliqitabiaoqian,
};
