/*
*Description: 样式文件
*/
import * as CONSTS from '../../consts';

const chartFontSize = {
  small: {
    legnedFontSize: 10,
    labelFontSize: 10,
    textFontSize: 8,
    legendWidth: 10,
    legendHeight: 7,
  },
  middle: {
    legnedFontSize: 12,
    labelFontSize: 12,
    textFontSize: 8,
    legendWidth: 10,
    legendHeight: 7,
  },
  large: {
    legnedFontSize: 14,
    labelFontSize: 14,
    textFontSize: 12,
    legendWidth: 12,
    legendHeight: 10,
  },
};

const modeStyle = {
  light: {
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    color: ['#0D3B66', '#4E7293', '#A4C3B2', '#C5D5EA', '#CCE3DE', '#EAD1C1', '#E9C582', '#CBC7DB', '#EE964B'],
    labelColor: '#0D3B66',
    labelBgdColor: '#E2EEFF',
    labelBorderColor: '#C5D5EA',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',
    industryColors: [
      '#6591B9', '#A4C3B2', '#EAD1C1', '#BEB0F7', '#E9C582', '#EE964B',
      '#D5671D', '#C54646', '#CB997E', '#E0C7C7', '#CCCCBA', '#D8E1E9',
      '#BCB3DF', '#CE7070', '#DBACBD', '#B56D2F', '#D5671D', '#FFC49B',
      '#D2D278', '#B4B471', '#30A170', '#5B7868', '#57BDA7', '#B5F2E5',
      '#93D4ED', '#839EDA', '#5540A9', '#9070AA', '#C474A3', '#E58080',
      '#8C6C6C', '#C8B0B0', '#CACACA', '#917342', '#D6D3B4', '#B4D2D6',
      '#B5BFBB',
    ],
  },
  dark: {
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    color: ['#00F2FE', '#3E83F7', '#1D16F4', '#5170CA', '#390D97', '#E65226', '#FEC52A'],
    labelColor: '#fff',
    labelBgdColor: 'rgba(27, 2, 253, 0.3)',
    labelBorderColor: 'transparent',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
    industryColors: [
      '#6591B9', '#A4C3B2', '#EAD1C1', '#BEB0F7', '#E9C582', '#EE964B',
      '#D5671D', '#C54646', '#CB997E', '#E0C7C7', '#CCCCBA', '#D8E1E9',
      '#BCB3DF', '#CE7070', '#DBACBD', '#B56D2F', '#D5671D', '#FFC49B',
      '#D2D278', '#B4B471', '#30A170', '#5B7868', '#57BDA7', '#B5F2E5',
      '#93D4ED', '#839EDA', '#5540A9', '#9070AA', '#C474A3', '#E58080',
      '#8C6C6C', '#C8B0B0', '#CACACA', '#917342', '#D6D3B4', '#B4D2D6',
      '#B5BFBB',
    ],
  },
};

export {
  chartFontSize,
  modeStyle,
};
