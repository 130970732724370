import { IModelMiddleware, IModelSeries, ITextConfig } from '../interface/base';
import { each, merge, omitBy } from 'lodash';
import { AnyData, AnyDataSource, ICategoryData, IDataItem, IDataItemObject, ISeries } from '../interface/data';
import { AnyChartConfig, IBarConfig, ILineConfig, IScatterConfig } from '../interface/config';
import jsonLogic from 'json-logic-js';
import { OptionParser } from './optionParser';
import { buildJSONLogic, AdditionalOperation } from '../../expression/index';

export function getTextStyle<T extends ITextConfig | undefined>(option: T):
  Omit<ITextConfig, 'color'> & { color?: string } {
  if (!option) {
    return {};
  }
  const {
    fontStyle,
    fontWeight,
    fontFamily,
    fontColor,
    fontSize,
  } = option;

  return omitUndefined({
    fontStyle,
    fontWeight,
    fontFamily,
    color: fontColor,
    fontSize,
  });
}

export const omitUndefined = (obj: object) => {
  return omitBy(obj, value => value === undefined);
};

/**
 * 判断数据是否合法
 */
export const isDataSourceLegal = (dataSource: AnyDataSource) => {
  const { type } = dataSource;
  return !(type === 'model' || (type === 'json' && !dataSource.jsonId) || (type === 'api' && !dataSource.url));
};

/**
 * 获取轴的 key
 * @param raw
 */
export const getAxisKeys = (raw: AnyChartConfig) => {
  const { visualConfig } = raw;
  if (visualConfig.coordinateSystem === 'cartesian2d' || visualConfig.coordinateSystem === 'polar') {
    let axisKey = 'x';
    let axis2Key = 'y';
    if (visualConfig.coordinateSystem === 'cartesian2d') {
      if (visualConfig.axisReverse) {
        axisKey = 'y';
        axis2Key = 'x';
      }
    } else {
      axisKey = 'radius';
      axis2Key = 'angle';
      if (visualConfig.axisReverse) {
        axisKey = 'angle';
        axis2Key = 'radius';
      }
    }
    return [axisKey, axis2Key];
  }

  return [];
};

export const getFormatter = (formulate: (object & { type: 'option'; eachLine?: number; expression?: object }) | string | undefined) => {
  if (typeof formulate === 'string') {
    return formulate;
  }
  if (typeof formulate === 'object' && formulate.type !== 'option') {
    return (value: any) => {
      return jsonLogic.apply(formulate, value);
    };
  }
  if (typeof formulate === 'object' && formulate.type === 'option') {
    const { expression, eachLine } = formulate;
    return (value: any) => {
      if (!expression) {
        return value;
      }
      const _value = jsonLogic.apply(expression, value);
      if (!eachLine) {
        return _value;
      }
      if (typeof _value === 'string') {
        return Array.from(_value).reduce((previousValue, currentValue, currentIndex) => {
          return previousValue + currentValue + (((currentIndex + 1) % eachLine === 0) ? '\n' : '');
        }, '');
      }
      return _value;
    };
  }
  return formulate;
};

export const getLabel = (label: any) => {
  if (!label) return undefined;
  return { ...label, formatter: getFormatter(label.formatter) };
};

/**
 * 获取数据项
 * @param {*} item
 * @param {*} option
 * @returns
 */
export const getDataItem: (item:  IDataItem, option?: any) => IDataItemObject
  = (item, option = {}) => {
  if (typeof item === 'object' && !Array.isArray(item)) {
    return item;
  }
  return { name: undefined, value: item, };
}

/**
 * 获取数据项数据值
 * @param {*} item
 * @param {*} dimension
 * @param option
 * @returns
 */
export const getDataItemValue = (
  item: any,
  dimension = 0,
  option: { flat?: boolean } = {},
) => {
  const { flat = true } = option;
  if (typeof item === 'object') {
    if (!flat) {
      return item.value;
    }
    if (Array.isArray(item)) {
      return item[dimension % item.length];
    }
    return item.value;
  }
  if (Array.isArray(item)) {
    return item[dimension % item.length];
  }
  return item;
}

const getCommonSeriesProperties = (series: any) => {
  const {
    symbol,
    symbolSize,
    symbolOffset,
    label,
    lineStyle,
    itemStyle,
  } = series;

  return omitUndefined({
    symbol,
    symbolSize,
    symbolOffset,
    label,
    lineStyle,
    itemStyle,
  });
}

//
// export const getSeriesHOC: ISeriesHOC<any> = ({
//     getUnique = () => ({}),
// }) =>
//   (option, material) => {
//     const {
//       visualConfig,
//       type,
//     } = material.config ?? {};
//
//     const {
//       coordinateSystem,
//       series: seriesStyles, // a style matcher for series by index
//       itemMatchers, // a style matcher organized by rules
//     } = visualConfig;
//
//     const {
//       series = [],
//     } = material.data ?? {};
//
//     const jsonLogicRules = itemMatchers
//       ? itemMatchers
//         .map(({ rules, result }) => {
//           const r = rules
//             .map(rule => {
//               return buildJSONLogic(rule.conditions, rule.key);
//             })
//             .reduce<AdditionalOperation>((prev, next) => {
//               // TODO: solve this
//               // @ts-ignore
//               return { 'and': [prev, next] } as AdditionalOperation;
//               // @ts-ignore
//             }, true);
//
//           return {
//             rules: r,
//             result,
//           };
//         })
//       : [];
//
//     const optionParser = new OptionParser(option);
//
//     return {
//       ...(getUnique ? getUnique(option, material): {}),
//       series: series.map((item, index) => {
//         const { name, data, axisIndex } = item;
//
//
//         // 处理按照顺序的 matcher
//         const seriesStyle = seriesStyles ? seriesStyles[index] : undefined;
//         // 如果其中的 key 不涉及 类目、数据项名称、数据项编号、数据项值，那么处理整个系列，
//         // 否则，即为处理单个数据项
//         const seriesProperty = {
//           seriesName: name,
//           seriesIndex: index,
//         };
//         // const KEYS = ['category', 'name', 'index', 'value'];
//         const seriesItem = {
//           name,
//           type,
//           coordinateSystem,
//           ...(getEach(visualConfig)),
//         };
//         const categoryAxis = Object.values(optionParser.getAxis(seriesItem)).filter(OptionParser.isAxisCategory);
//
//         const seriesData = data.map((d, index) => {
//           const item = getDataItem(d);
//           const _d = {
//             ...seriesProperty,
//             category: categoryAxis.length !== 0 ? categoryAxis[0].data[index] : undefined,
//             ...item,
//             index,
//           };
//           const matchResult = jsonLogicRules
//             .filter(({ rules }) => {
//               return jsonLogic.apply(rules, _d);
//             })
//             .map(item => item.result);
//           const matcherStyle = merge({}, ...matchResult);
//           if (Object.keys(matcherStyle).length === 0) {
//             return d;
//           }
//           return ({
//             ...item,
//             ...getCommonSeriesProperties(matcherStyle),
//             ...getEach(matcherStyle),
//           });
//         });
//
//         if (seriesItem.coordinateSystem === 'none') {
//           if (seriesItem.type === 'radar') {
//             seriesItem.coordinateSystem = undefined;
//           } else if (seriesItem.type === 'wordCloud') {
//             seriesItem.coordinateSystem = undefined;
//           }
//         }
//
//         return omitUndefined(
//           merge(
//             seriesItem,
//             { data: seriesData },
//             ...(seriesStyle ? [getCommonSeriesProperties(seriesStyle), getEach(seriesStyle)] : []),
//           )
//         );
//       }),
//     };
//   };s

export const extend = (item: any) => Array.isArray(item) ? item : [item];
