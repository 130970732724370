import React, { useState } from 'react';
import { Button } from 'antd';
import styles from './index.module.less';
import SRDIRanking from '../SRDIRanking';
import InvestmentWorthRanking from '../InvestmentWorthRanking';
import PatentRanking from '../PatentRanking';
import RecommendRanking from '../RecommendRanking';
import ExportModal from '../../../../components/ExportModal';
import { openNewPage } from '../../../../utils/urlMethods';

function RankingScheme({ cityCode }) {
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const [srdiRankingState, setSRDIRankingState] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [investmentWorthRankingState, setInvestmentWorthRankingState] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [patentRankingState, setPatentRankingState] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [recommendRankingState, setRecommendRankingState] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [dataKey, setDataKey] = useState('');

  const Title = ({ title, titleKey }) => (
    <div className={styles.title}>
      {title}
      {
        Array.isArray(enterpriseAccountExport)
        && enterpriseAccountExport?.length > 0
        && enterpriseAccountExport?.includes('exportCityRank')
        && (
          <Button
            className={styles.exportButton}
            onClick={() => {
              setExportModalVisible(true);
              setDataKey(titleKey);
            }}
          >
            数据导出
          </Button>
        )
      }
    </div>
  );

  return (
    <div className={styles.rankingScheme}>
      <div className={styles.ranking}>
        <Title title="专精特新潜力榜" titleKey="city_channel_rank_newSpecial" />
        <SRDIRanking
          cityCode={cityCode}
          pageSize={srdiRankingState.pageSize}
          pageNumber={srdiRankingState.pageNumber}
          onCompanyClick={({ _id }) => {
            if (!_id) return;
            openNewPage('companyDetail', { id: _id });
          }}
          onChange={(pagination, filter, sorter) => {
            setSRDIRankingState(prev => ({
              ...prev,
              pageNumber: pagination.current,
            }));
          }}
        />
      </div>
      <div className={styles.ranking}>
        <Title title="投资价值榜" titleKey="city_channel_rank_investment" />
        <InvestmentWorthRanking
          cityCode={cityCode}
          pageSize={investmentWorthRankingState.pageSize}
          pageNumber={investmentWorthRankingState.pageNumber}
          onCompanyClick={({ _id }) => {
            if (!_id) return;
            openNewPage('companyDetail', { id: _id });
          }}
          onChange={(pagination, filter, sorter) => {
            setInvestmentWorthRankingState(prev => ({
              ...prev,
              pageNumber: pagination.current,
            }));
          }}
        />
      </div>
      <div className={styles.ranking}>
        <Title title="创新潜力榜" titleKey="city_channel_patent_num_score_rank" />
        <PatentRanking
          cityCode={cityCode}
          pageSize={patentRankingState.pageSize}
          pageNumber={patentRankingState.pageNumber}
          onCompanyClick={({ _id }) => {
            if (!_id) return;
            openNewPage('companyDetail', { id: _id });
          }}
          onChange={(pagination, filter, sorter) => {
            setPatentRankingState(prev => ({
              ...prev,
              pageNumber: pagination.current,
            }));
          }}
        />
      </div>
      <div className={styles.ranking}>
        <Title title="上奇推荐榜" titleKey="city_channel_company_score_rank" />
        <RecommendRanking
          cityCode={cityCode}
          pageNumber={recommendRankingState.pageNumber}
          pageSize={recommendRankingState.pageSize}
          onChange={(pagination, filter, sorter) => {
            setRecommendRankingState(prev => ({
              ...prev,
              pageNumber: pagination.current,
            }));
          }}
          onCompanyClick={({ _id }) => {
            if (!_id) return;
            openNewPage('companyDetail', { id: _id });
          }}
        />
      </div>
      {
        exportModalVisible && (
          <ExportModal
            dataKey={dataKey}
            params={{
              area_key: cityCode,
            }}
            onClose={() => {
              setExportModalVisible(false);
            }}
          />
        )
      }
    </div>
  );
}

export default RankingScheme;
