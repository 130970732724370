/**
  公共请求
 */

import { apiSlice } from '../slices/api';

const NAVIGATIONBAR = '/navigationBar';
const INTERACTION = '/interaction';
const PREFIX = '/cityChannel';

export const publicApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 获取产业列表
    getSearchIndustryByProportion: builder.query({
      query(arg) {
        return {
          url: `${INTERACTION}/search_industry_by_proportion`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 资质类别
    getCompanySpecialTags: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/get_company_type_list`,
          method: 'post',
          body: arg,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    // 产业列表
    getIndustryTree: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/search_industry_tree`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 头部导航栏-园区 header_navigation_bar_park
    getHeaderNavigationBarPark: builder.query({
      query(arg) {
        return {
          url: `${NAVIGATIONBAR}/header_navigation_bar_park`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 用户监测 埋点记录
    recordingSpot: builder.query({
      query(arg) {
        return {
          url: '/anchor_point/recording_spot',
          method: 'post',
          body: arg,
        };
      },
    }),
    // 省份列表
    getNavigationProvinceList: builder.query({
      query(arg) {
        return {
          url: `${NAVIGATIONBAR}/navigation_province_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 获取城市列表 find_report_template_area
    findReportTemplateArea: builder.query({
      query(arg) {
        return {
          url: `${NAVIGATIONBAR}/find_report_template_area`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 获取工具列表 /industry_knowledge_engine/v2/navigationBar/navigation_tool_list
    getNavigationToolList: builder.query({
      query(arg) {
        return {
          url: `${NAVIGATIONBAR}/navigation_tool_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // /industry_knowledge_engine/v2/jurisdiction/tool_permission_query
    getToolPermissionQuery: builder.query({
      query(arg) {
        return {
          url: 'jurisdiction/tool_permission_query',
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetSearchIndustryByProportionQuery,
  useGetCompanySpecialTagsQuery,
  useGetIndustryTreeQuery,
  useLazyGetHeaderNavigationBarParkQuery,
  useGetHeaderNavigationBarParkQuery,
  useRecordingSpotQuery,
  useLazyRecordingSpotQuery,
  useGetNavigationProvinceListQuery,
  useFindReportTemplateAreaQuery,
  useGetNavigationToolListQuery,
  useLazyGetNavigationProvinceListQuery,
  useLazyGetToolPermissionQueryQuery,
} = publicApi;
