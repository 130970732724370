import React from 'react';
import style from './index.module.less';

function TableNoData() {
  return (
    <div className={style.tableNoData}>暂无数据</div>
  );
}

export default TableNoData;
