import React, { useMemo, useEffect } from 'react';
import { Checkbox } from 'antd';
import styles from './index.module.less';

const CheckboxGroup = Checkbox.Group;

const OPTION_ALL = { label: '不限', value: 'all' };

/**
 * 自定义的普通列表选择器（多选）
 * @param filters
 * @param setSelectedKeys
 * @param selectedKeys
 * @param confirm
 * @returns {JSX.Element}
 * @constructor
 */
function CustomListFilter({
  filters,
  setSelectedKeys,
  selectedKeys,
  confirm,
  extraControl,
  // visible,
  // clearFilters,
}) {
  const options = useMemo(() => {
    if (!filters || !Array.isArray(filters)) {
      return extraControl ? [OPTION_ALL] : [];
    }
    const filteredOptions = filters.map(({ text, value }) => ({
      label: text,
      value,
    }));
    if (!extraControl) {
      return filteredOptions;
    }
    return [OPTION_ALL, ...filteredOptions];
  }, [filters, extraControl]);
  return (
    <div className={styles.filter}>
      <CheckboxGroup
        options={options}
        value={selectedKeys}
        onChange={checkedValue => {
          // 从选中的结果触发，总结所有可能的路径
          // 1. 没有选中，此时自动选中全部
          // 2. 选中不限和其他，但1.其他为后添加，那么取消不限；如果2.不限为后添加，那么取消其他
          // 3. 选中其他，没有选中不限，直接设置
          // 4. 仅有不限（无法到达的状态）
          if (extraControl) {
            const hasAll = !!selectedKeys?.includes('all');
            const nextHasAll = checkedValue.includes('all');
            // 对应 1、2.2
            if (checkedValue.length === 0 || (checkedValue.length > 1 && !hasAll && nextHasAll)) {
              setSelectedKeys(['all']);
              // 对应 2.1
            } else if (checkedValue.length > 1 && hasAll && nextHasAll) {
              setSelectedKeys(checkedValue.filter(item => item !== 'all'));
            } else {
              // 对应 3 及 其他
              setSelectedKeys(checkedValue);
            }
          } else {
            setSelectedKeys(checkedValue);
          }
          // 即时更新筛选选项，不关闭悬浮窗
          // setSelectedKeys(checkedValue);
          confirm(false);
        }}
        className={styles.list}
      />
    </div>
  );
}

export default CustomListFilter;
