/*
* Description: '漏斗图'
* Date: 2022/03/02
*/

import React, { useEffect, useState, useRef, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Spin } from 'antd';
import { VerticalAlignBottomOutlined, FileImageOutlined } from '@ant-design/icons';
import style from './index.module.less';
// import ChartNoData from '../ChartNoData';
import punlicStyle from '../public/index.module.less';

const color1 = [
  '#FD6205', '#59D700', '#4E88FF', '#1961F5',
  '#1307ba', '#0702a5', '#030061',
];
function FunnelChart(props) {
  const canvasRef = useRef();
  const { data, isFetching, downloadImg, leftUnit, dataDownload, preview, setReportData, id, title, labelShow } = props;
  const [options, setOptions] = useState({});
  // const { length: dataLength } = data ?? {};

  const onChartFinished = () => {
    if (!setReportData) return;
    const img = new Image();
    // pieMyChart1 要导出的图表
    img.src = canvasRef.current.getEchartsInstance().getDataURL({
      type: 'png',
      pixelRatio: 1, // 放大2倍
      backgroundColor: '#fff',
    });
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      // 添加文字
      const text = '数据来源：上奇产业通';
      // 设置字体和大小
      ctx.font = '600 14px PingFangSC, PingFang SC';
      // 设置文本颜色
      ctx.fillStyle = '333333';
      //  设置文本
      ctx.fillText(text, canvas.width - 160, canvas.height - 5);
      const dataURL = canvas.toDataURL();
      setReportData(params => ({ ...params, [id]: { ...params[id], el: dataURL } }));
    };
  };
  useEffect(() => {
    const { length } = data || {};
    const gap = 100 / length;
    const newData = data?.map((item, index) => (
      {
        value: index * gap + gap,
        name: item.name,
        amount: item.value,
        percent: item.percent,
        itemStyle: {
          borderWidth: 0,
          color: color1[index % color1.length],
        },
      }
    ));

    const option = {
      series: [
        {
          z: 10,
          name: 'Funnel',
          type: 'funnel',
          left: 'center',
          top: 32,
          // right: 100,
          bottom: 20,
          width: 415,
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          sort: 'ascending',
          gap: 2,
          label: {
            show: labelShow,
            formatter: (params) => `${params.name}\n${params.data.amount}${leftUnit}`,
            position: 'inside',
            color: '#fff',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: 16,
          },
          labelLine: {
            show: false,
            length: 30,
            lineStyle: {
              width: 1,
              type: 'solid',
            },
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 3,
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: newData,
        },
      ],
    };
    setOptions(option);
  }, [data, labelShow]);

  const cheartEl = useMemo(() =>
    // if (data || data.length > 0) {
    // eslint-disable-next-line implicit-arrow-linebreak
    (
      <div className={style.all}>
        <div style={{ width: '100%', height: '100%', zIndex: 10 }}>
          <ReactEcharts
            option={options}
            ref={canvasRef}
            onEvents={{
              finished: onChartFinished,
            }}
            notMerge
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
    ),
  // }
  // return <ChartNoData />;
  [options]);

  return (
    <div>
      {
        !preview && (
          <div className={punlicStyle.tool}>
            <div onClick={dataDownload}>
              <span>
                <VerticalAlignBottomOutlined />
              </span>
              <span>下载数据</span>
            </div>
            <div onClick={() => {
              downloadImg(canvasRef, title, 120, 10);
            }}
            >
              <span>
                <FileImageOutlined />
              </span>
              <span>保存图片</span>
            </div>
          </div>
        )
      }
      <Spin spinning={isFetching} wrapperClassName={punlicStyle.loding}>
        {
          data && cheartEl
        }
      </Spin>
    </div>
  );
}

export default FunnelChart;

FunnelChart.defaultProps = {
  data: [],
};
