/*
 *Author: zhaochenyu
 *Description: 高新技术报告
 *Date: 2022/06/30
*/

import React, { useMemo } from 'react';
import ReportChart from '../ReportChart';
import FrontCover from './FrontCover';
import BackCover from './BackCover';
import style from '../index.module.less';

function HighTechnologyReport(props) {
  const { chartConditions, chartTitlesRef, refList, chartTitles, pushToAchor,
    getChartFinishedPercent, buyStatus } = props;
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');

  return (
    <>
      {buyStatus ? (
        <>
          <div className={style.first}>
            <FrontCover industry={urlReportMsg.industry} area={urlReportMsg.area} title={urlReportMsg.title} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、企业全景')}>
              <div className={style.firstTitle}>一、企业全景</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）区域分布')}>
              <div className={style.secondTitle}>（一）区域分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）细分环节')}>
              <div className={style.secondTitle}>（二）细分环节</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）企业结构')}>
              <div className={style.secondTitle}>（三）企业结构</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、股权融资')}>
              <div className={style.firstTitle}>二、股权融资</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）资本构成')}>
              <div className={style.secondTitle}>（一）资本构成</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）市场全貌')}>
              <div className={style.secondTitle}>（二）市场全貌</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）融资热点')}>
              <div className={style.secondTitle}>（三）融资热点</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>7</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）重点项目')}>
              <div className={style.secondTitle}>（四）重点项目</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、创新能力')}>
              <div className={style.firstTitle}>三、创新能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）全量专利')}>
              <div className={style.secondTitle}>（一）全量专利</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）主导标准')}>
              <div className={style.secondTitle}>（二）主导标准</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>14</div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="一、企业全景">一、企业全景</div>
            <div className={style.secondaryTitle} id="（一）区域分布">（一）区域分布</div>
            {chartConditions.length > 0 && (
            <ReportChart
              chartMsg={chartConditions[0]}
              chartIndex={0}
              ref={chartTitlesRef.current[0]}
              chartHeight={450}
              refList={refList}
              title={`图1 ${chartTitles[0] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）细分环节">（二）细分环节</div>
            {chartConditions.length > 1 && (
            <ReportChart
              chartMsg={chartConditions[1]}
              chartIndex={1}
              ref={chartTitlesRef.current[1]}
              chartHeight={660}
              refList={refList}
              title={`图2 ${chartTitles[1] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {chartConditions.length > 2 && (
            <ReportChart
              chartMsg={chartConditions[2]}
              chartIndex={2}
              ref={chartTitlesRef.current[2]}
              chartHeight={urlReportMsg.area === '全国' ? 700 : 460}
              refList={refList}
              title={`图3 ${chartTitles[2] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）企业结构">（三）企业结构</div>
            {chartConditions.length > 3 && (
            <ReportChart
              chartMsg={chartConditions[3]}
              chartIndex={3}
              ref={chartTitlesRef.current[3]}
              chartHeight={280}
              refList={refList}
              title={`图4 ${chartTitles[3] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {chartConditions.length > 4 && (
            <ReportChart
              chartMsg={chartConditions[4]}
              chartIndex={4}
              ref={chartTitlesRef.current[4]}
              chartHeight={280}
              refList={refList}
              title={`图5 ${chartTitles[4] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="二、股权融资">二、股权融资</div>
            <div className={style.secondaryTitle} id="（一）资本构成">（一）资本构成</div>
            {chartConditions.length > 5 && (
            <ReportChart
              chartMsg={chartConditions[5]}
              chartIndex={5}
              ref={chartTitlesRef.current[5]}
              chartHeight={500}
              refList={refList}
              title={`图6 ${chartTitles[5] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）市场全貌">（二）市场全貌</div>
            {chartConditions.length > 6 && (
            <ReportChart
              chartMsg={chartConditions[6]}
              chartIndex={6}
              ref={chartTitlesRef.current[6]}
              chartHeight={280}
              refList={refList}
              title={`图7 ${chartTitles[6] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {chartConditions.length > 7 && (
            <ReportChart
              chartMsg={chartConditions[7]}
              chartIndex={7}
              ref={chartTitlesRef.current[7]}
              chartHeight={280}
              refList={refList}
              title={`图8 ${chartTitles[7] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）融资热点">（三）融资热点</div>
            {chartConditions.length > 8 && (
            <ReportChart
              chartMsg={chartConditions[8]}
              chartIndex={8}
              ref={chartTitlesRef.current[8]}
              chartHeight={620}
              refList={refList}
              title={`图9 ${chartTitles[8] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            {chartConditions.length > 9 && (
            <ReportChart
              chartMsg={chartConditions[9]}
              chartIndex={9}
              ref={chartTitlesRef.current[9]}
              chartHeight={620}
              refList={refList}
              title={`图10 ${chartTitles[9] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）重点项目">（四）重点项目</div>
            {chartConditions.length > 10 && (
            <ReportChart
              chartMsg={chartConditions[10]}
              chartIndex={10}
              ref={chartTitlesRef.current[10]}
              chartHeight={600}
              refList={refList}
              title={`表1 ${chartTitles[10] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="三、创新能力">三、创新能力</div>
            <div className={style.secondaryTitle} id="（一）全量专利">（一）全量专利</div>
            <div className={style.tertiaryTitle} id="1.专利总量">1. 专利总量</div>
            {chartConditions.length > 11 && (
            <ReportChart
              chartMsg={chartConditions[11]}
              chartIndex={11}
              ref={chartTitlesRef.current[11]}
              chartHeight={460}
              refList={refList}
              title={`图11 ${chartTitles[11] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            {chartConditions.length > 12 && (
            <ReportChart
              chartMsg={chartConditions[12]}
              chartIndex={12}
              ref={chartTitlesRef.current[12]}
              chartHeight={460}
              refList={refList}
              title={`图12 ${chartTitles[12] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="2.专利分布">2. 专利分布</div>
            {/* 产业新增授权发明专利省份对比图 */}
            {chartConditions.length > 13 && (
            <ReportChart
              chartMsg={chartConditions[13]}
              chartIndex={13}
              ref={chartTitlesRef.current[13]}
              chartHeight={620}
              refList={refList}
              title={`图13 ${chartTitles[13] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle} id="3.重点企业">3. 重点企业</div>
            {chartConditions.length > 14 && (
            <ReportChart
              chartMsg={chartConditions[14]}
              chartIndex={14}
              ref={chartTitlesRef.current[14]}
              chartHeight={660}
              refList={refList}
              title={`表2 ${chartTitles[14] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）主导标准">（二）主导标准</div>
            <div className={style.tertiaryTitle} id="1.标准结构">1. 标准结构</div>
            {/* 授权发明专利单位合作关系图 */}
            {chartConditions.length > 15 && (
            <ReportChart
              chartMsg={chartConditions[15]}
              chartIndex={15}
              ref={chartTitlesRef.current[15]}
              chartHeight={460}
              refList={refList}
              title={`图14 ${chartTitles[15] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="2.重点企业">2. 重点企业</div>
            {/* 产业相关标准类型结构图 */}
            {chartConditions.length > 16 && (
            <ReportChart
              chartMsg={chartConditions[16]}
              chartIndex={16}
              ref={chartTitlesRef.current[16]}
              chartHeight={660}
              refList={refList}
              title={`表3 ${chartTitles[16] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.last}>
            <BackCover />
          </div>
        </>
      ) : (
        <>
          <div className={style.first}>
            <FrontCover industry={urlReportMsg.industry} area={urlReportMsg.area} title={urlReportMsg.title} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、企业全景')}>
              <div className={style.firstTitle}>一、企业全景</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）区域分布')}>
              <div className={style.secondTitle}>（一）区域分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）细分环节')}>
              <div className={style.secondTitle}>（二）细分环节</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）企业结构')}>
              <div className={style.secondTitle}>（三）企业结构</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、股权融资')}>
              <div className={style.firstTitle}>二、股权融资</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）资本构成')}>
              <div className={style.secondTitle}>（一）资本构成</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）市场全貌')}>
              <div className={style.secondTitle}>（二）市场全貌</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）融资热点')}>
              <div className={style.secondTitle}>（三）融资热点</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>7</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）重点项目')}>
              <div className={style.secondTitle}>（四）重点项目</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、创新能力')}>
              <div className={style.firstTitle}>三、创新能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）全量专利')}>
              <div className={style.secondTitle}>（一）全量专利</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）主导标准')}>
              <div className={style.secondTitle}>（二）主导标准</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>14</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default HighTechnologyReport;
