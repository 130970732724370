import React, { useState, useEffect, useRef } from 'react';
import { message } from 'antd';
import {
  attentionServiceNumber,
} from '../../../../api/mySelf';
import style from './index.module.less';
import { IconFont } from '../../../../utils/mapIcon';
import { getTicketCode } from '../../../../api/login';

const AttentionService = ({ onCancel, getTaskList }) => {
  const timer = useRef(null);
  const [icon, setIcon] = useState('');
  const [failure, setFailure] = useState(false);

  // 二维码失效倒计时
  const overlayCountDown = () => {
    const time = setTimeout(() => {
      setFailure(true);
      clearTimeout(time);
      clearTimeout(timer.current);
    }, 120 * 1000);
  };

  // 轮训关注任务
  const attentionServiceNumberFun = async (ticket) => {
    clearTimeout(timer.current);
    try {
      const res = await attentionServiceNumber({ ticket });
      const { status, data } = res;
      if (status === 'B0101') {
        timer.current = setTimeout(() => {
          attentionServiceNumberFun(ticket);
        }, 1000);
      } else if (status === '00000') {
        onCancel();
        getTaskList();
      }
    } catch {
      throw new Error('关注服务号请求异常');
    }
  };

  // 获取二维码
  const getTicketCodeFun = async () => {
    try {
      const res = await getTicketCode({ event_key: '关注任务' });
      if (res.status === '00000') {
        const { picbase64, ticket } = res.data;
        setIcon(picbase64);
        overlayCountDown();
        setFailure(false);
        attentionServiceNumberFun(ticket);
      } else {
        message.warning(res.message);
      }
    } catch {
      console.log('获取二维码失败');
    }
  };

  useEffect(() => {
    getTicketCodeFun();

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <div className={style.box}>
      <div className={style.icon}>
        {
          icon && (<img src={`data:image/jpeg;base64,${icon}`} style={{ width: '190px', height: '190px' }} alt="" />)
        }
        {
          failure && (
            <div className={style.overlay}>
              二维码已失效
            </div>
          )
        }

      </div>
      {
        failure && (
          <div className={style.refresh} onClick={getTicketCodeFun}>
            <IconFont type="iconsync" style={{ marginRight: '4px' }} />
            刷新二维码
          </div>
        )
      }
    </div>
  );
};

export default AttentionService;
