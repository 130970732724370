/*
 * Author: zhangmeng
 * Description: '企业页'
 * Date: 2022/06/29
 */

import React, { useMemo, useState, useCallback } from 'react';
import { Button } from 'antd';
import ChannelModal from '../../../../components/MySelf/ChannelModal';
import { openNewPage } from '../../../../utils/urlMethods';
import arrowRight from '../../../../assets/images/dataSearch/arrowRight.png';
import branchesImg from '../../../../assets/images/dataSearch/branchesImg.png';
import companyImg from '../../../../assets/images/dataSearch/companyImg.png';
import style from './index.module.less';

function IndustryChartList(props) {
  const { industryData, participle } = props;
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [defaultBuyIndustryKey, setDefaultBuyIndustryKey] = useState(false);
  const [payType, setPayType] = useState('industry');

  const onGoAllIndustry = (value) => {
    openNewPage('industry', {
      type: 'graph',
      'industry-code': window.btoa(value),
    });
  };

  const onPurchaseIndustry = (value) => {
    setDefaultBuyIndustryKey(value);
    setChannelModalVisible(true);
  };

  const highLightWord = useCallback((value) => {
    if (!value || !participle?.length) return value;
    let word = value;
    participle.forEach(item => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(item)) {
        word = word.split(item).join(`<span style="color: #D70000;">${item}</span>`);
      } else {
        const arr = word.split(item);
        const last = arr.pop();
        word = `${arr.join(`<span style="color: #D70000;">${item}</span>`)}${last}`;
      }
    });
    return word;
  }, [participle]);

  return (
    <>
      <div className={style.listBox}>
        {(industryData || []).map((item, index) => (
          <div className={style.IndustryChartBox} key={index}>
            <div className={style.topChartBox}>
              <div className={style.topLeftChart}>
                <div
                  className={style.topChartTitle}
                  dangerouslySetInnerHTML={{
                    __html: highLightWord(item.industry_name),
                  }}
                />
                <div
                  style={{ display: item.auth ? 'block' : 'none' }}
                  className={style.logoBox}
                >
                  已购
                </div>
              </div>
              <div className={style.topRightChart}>
                <img src={branchesImg} alt='' />
                <span className={style.topRightChartNumber}>
                  {item.link_count}
                </span>
                <span className={style.topRightChartTitle}>个环节</span>
                <img src={companyImg} alt='' style={{ marginLeft: 10 }} />
                <span className={style.topRightChartNumber}>
                  {item.company_count}
                </span>
                <span className={style.topRightChartTitle}>家企业</span>
              </div>
            </div>
            <div className={style.centerChartBox} >
              <div className={style.backgroundImg} />
            </div>
            <div className={style.bottomChartBox}>
              {
                item.auth ? (
                  <div onClick={() => onGoAllIndustry(item.industry_code)} className={style.seeStyle}>
                    <div className={style.bottomTitle}>查看图谱</div>
                    <div className={style.arrowRightImg} />
                  </div>
                ) : (
                  <div onClick={() => onPurchaseIndustry(item.industry_code)} className={style.purchaseStyle}>
                    <div className={style.bottomTitle}>购买图谱</div>
                    <div className={style.arrowRightImg} />
                  </div>
                )
              }
            </div>
          </div>
        ))}
      </div>
      {
        useMemo(() => channelModalVisible && <ChannelModal
          defaultSelect={defaultBuyIndustryKey}
          type={payType}
          setPayType={setPayType}
          onClose={() => setChannelModalVisible(false)}
          paySuccessCb={(curBuyIndustrList) => {
            const urlParams = new URLSearchParams();
            urlParams.set('tab', '个人中心');
            props.history.push({
              pathname: 'accountSetting',
              search: urlParams.toString(),
            });
          }}
        />, [channelModalVisible, setChannelModalVisible, defaultBuyIndustryKey])
      }
    </>
  );
}

export default IndustryChartList;
