import React from 'react';
import style from './index.module.less';

function ChartNoData() {
  return (
    <div className={style.noData}>暂无此数据图谱</div>
  );
}

export default ChartNoData;
