import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { cloneDeep, cloneDeepWith } from 'lodash';
import classnames from 'classnames';
import OrientTree from '../../../../../../components/OrientTree';
// import Tree from '../../components/Tree';
import Button from '../../components/Button';
import Title from '../../../../../../components/Title';
import Subtitle from '../../../../../../components/Subtitle';
import SectorCompanyTable from './SectorCompanyTable';
import styles from './index.module.less';
import { useTargetiIndustryLinkCurrentSituationQuery } from '../../../../../../service/cityTool';
// import { openNewPage } from '../../../../../../utils/urlMethods';
import ZoomButton from '../../components/ZoomButton';

const colorMap = {
  优势环节: '#EE964B',
  覆盖环节: '#1961F5',
  缺失环节: '#0F2849',
};

/**
 * 图谱 scheme
 * 所谓全展开，就是根节点
 * @param {string} industryCode
 * @returns {JSX.Element}
 * @constructor
 */
const IndustrialChainGraph = (props) => {
  const {
    industryCode,
    cityCode,
    cityName,
    industryName,
    name,
  } = props;
  // 当前产业/环节名称，产业同种需要根据产业名称进行查询
  const [selectedId, setSelectedId] = useState('');
  // 树图缩放等级
  const [zoom, setZoom] = useState(0.8);
  // 树的下拉是否展开
  const [isTreeDropdownOpen, setIsTreeDropdownOpen] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState('');
  useEffect(() => {
    setSelectedId(industryCode);
  }, [industryCode]);
  const {
    data: chainData,
    isFetching: isChainFetching,
  } = useTargetiIndustryLinkCurrentSituationQuery({
    industry_code: industryCode,
    area_key: cityCode,
  }, {
    skip: !industryCode || !cityCode,
  });

  const onEvents = params => {
    setSelectedId(params.data?.value);
    setIsTreeDropdownOpen(true);
  };
  // 处理树图的数据将title的值赋值给name
  const updateData = (data) => {
    const newData = cloneDeepWith(cloneDeep(data), (value) => {
      if (typeof value === 'object' && Object.keys(value)?.length > 2) {
        value.name = value.title;
        value.label = {
          // color: '#ccc',
          color: colorMap[value.tag] || '#0F2849',
        };
        // value.itemStyle = {
        //   color: 'pink',
        // };
        // value.lineStyle = {
        //   color: colorMap[value.tag],
        // };
      }
    });
    return newData;
  };
  return (
    <section className={styles.graphScheme}>
      {/* 产业链图，树图结合下拉表格的组合 */}
      <div
        className={classnames(
          styles.subScheme,
          {
            'grey-out': isChainFetching,
          },
        )}
        id={styles.tree}
      >
        {
          isChainFetching && <Spin className={styles.spin} delay={200} />
        }
        <div className={styles.header}>
          <Title title={name || '产业分析'} className={styles.title} />
          <div
            className={styles.buttons}
          >
            <ZoomButton
              zoom={zoom}
              minZoom={0.1}
              maxZoom={2}
              className={styles.button}
              onChange={z => {
                const _z = Number(z?.toFixed(2));
                setZoom(_z);
              }}
            />
          </div>
        </div>
        {
          chainData && <OrientTree useDataColors data={updateData(chainData)} onEvents={onEvents} select={selectedId} zoom={zoom} />
        }
        <div
          className={classnames(
            styles.list,
            { [styles.hidden]: !isTreeDropdownOpen },
          )}
        >
          <div className={styles.overview}>
            <Subtitle
              className={styles.subtitle}
              description="推荐企业"
            />
            <Subtitle
              className={classnames(styles.subtitle, styles.lastSubtitle)}
              description="筛选结果"
              value={count}
              unit="家"
            />
            <span className={classnames(styles.subtitle, styles.lastSubtitle)}>
              {industryName}
            </span>
          </div>
          <SectorCompanyTable
            {...props}
            cityCode={cityCode}
            className={styles.table}
            industryCode={industryCode}
            linkCode={selectedId !== industryCode ? selectedId : undefined}
            page={page}
            setPage={setPage}
            setCount={setCount}
          // onChange={({ current, pageSize }, filter) => {
          //   setTreeListQueryState({
          //     ...treeListQueryState,
          //     pageSize,
          //     pageNumber: current,
          //     filteredInfo: filter,
          //   });
          // }}
          // filteredInfo={treeListQueryState.filteredInfo}
          />
          <Button
            className={classnames(
              styles.toggleButton,
              {
                [styles.slim]: !isTreeDropdownOpen,
              },
            )}
            text={isTreeDropdownOpen ? '收起' : '展开'}
            onClick={() => setIsTreeDropdownOpen(!isTreeDropdownOpen)}
          />
        </div>
      </div>
      <ul className={styles.legend}>
        <li>
          <p />
          <p>优势环节</p>
        </li>
        <li>
          <p />
          <p>覆盖环节</p>
        </li>
        <li>
          <p />
          <p>缺失环节</p>
        </li>
      </ul>
    </section>
  );
};

export default IndustrialChainGraph;
