import { COMPANYREPORT } from '../utils/url';
import { get } from '../utils/request';

// 企业基本信息
const getBasicInfo = param => get(`${COMPANYREPORT}/company_basic_info`, {
  ...param,
});

// 企业资产信息
const getAssetsInfo = param => get(`${COMPANYREPORT}/company_asset_info`, {
  ...param,
});

// 企业社保信息
const getSocialInfo = param => get(`${COMPANYREPORT}/company_social_security_info`, {
  ...param,
});

// 企业出资信息
const getShareInfo = param => get(`${COMPANYREPORT}/shareholder_info`, {
  ...param,
});

export {
  getBasicInfo,
  getAssetsInfo,
  getSocialInfo,
  getShareInfo,
};
