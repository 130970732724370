import { requestFile } from '../utils/request';
// /industry_knowledge_engine/v2/dataAnalysis/catalogue/side_table_contents
const DATAANALYSIS = '/industry_knowledge_engine/v2/dataAnalysis';
// 下载表格
const dataDownload = (param, name) => requestFile('post', `${DATAANALYSIS}/analysis/download_data`, {
  ...param,
}, `${name}.xlsx`, false, {
  'Content-Type': 'application/json',
});

export { dataDownload };
