/*
*Author: zhaochenyu
*Description: 配置接口地址文件
*Date: 2020/09/04
*/

let baseIp;
let oauthIp;
import { getUrlPrefix } from './tool';

if (process.env.NODE_ENV === 'production') {
  let windowIp;
  /* 自动获取服务器地址 */
  if (window.location.origin){
    windowIp = window.location.origin;
  } else {
    // 兼容origin没有的情况
    windowIp = `${window.location.protocol}/${window.location.hostname}
    ${window.location.port ? ':' + window.location.port: ''}`;
  }
  oauthIp = `${windowIp}${getUrlPrefix({ slash: false })}/oauth`;
  baseIp = `${windowIp}${getUrlPrefix({ slash: false })}/industry_knowledge_engine/v2`;
} else {
  baseIp = '/devproxy/industry_knowledge_engine/v2'; // 本地环境
  oauthIp = '/devproxy/oauth'
}

const USER = `${baseIp}/user`;
const AUTH = `${baseIp}/auth`;
const ENTERPRESCONTRAST = `${baseIp}/tool/enterprises_contrast`; // 企业对比对标
const DATASEARCH = `${baseIp}/dataSearch`; // 数据搜索
const COMPANYDETAIL = `${baseIp}/companyDetail`; // 公司详情
const INSTITUTIONDETAIL = `${baseIp}/institutionDetail`
const PATENTDETAIL = `${baseIp}/patentDetail`; // 专利详情
const INDUSTRYREPORT = `${baseIp}/industryReport`; // 图表详情
const POLICYDETAIL = `${baseIp}/policyDetail`; // 政策详情
const REPORT = `${baseIp}/report`; // 报告详情
const CHART = `${baseIp}/chart`; // 宏观
const USERMANAGE = `${baseIp}/userManage`; // 用户管理
const INTERACTION = `${baseIp}/interaction`; // 公共接口
const INDUSTRYGRAPH = `${baseIp}/industryGraph`; // 产业图谱
const INVESTDETAIL = `${baseIp}/investDetail`; // 投融资详情
const STANDARDDETAIL = `${baseIp}/standardDetail`; // 标准详情
const RESEARCHREPORTDETAIL = `${baseIp}/researchReportDetail`; // 研报详情
const MACROECONOMYDETAIL = `${baseIp}/macroEconomyDetail`; // 宏观经济柱状图
const USERCUSTOM = `${baseIp}/userCustom`; // 用户相关
const INVESTMENTEVENTDETAIL = `${baseIp}/investmenteventDetail`; // 投资事件
const COMPANYREPORT = `${baseIp}/companyyearreportDetail`; // 企业年报部分接口
const LEADERBOARD = `${baseIp}/leaderboard`; // 榜单
const ANALYSISENGINE = `${baseIp}/analysisEngine`; // 分析引擎
const REPORTtEMPLATE = `${baseIp}/reportTemplate`; // 报告生成页面接口
const ONLYOFFICE = `${baseIp}/onlyOffice`; // onlyoffice类
const FIGUREPARK = `${baseIp}/figurePark`; // 数字园区接口
const INDUSTRYATLAS = `${baseIp}/industryAtlas`; // 产业地图
const NAVAUTHORITY = `${baseIp}/navigationBar`; // 导航页是否展示
const HOMEPAGE = `${baseIp}/homePage`; // 首页
const HOMEPAGEV2 = `${baseIp}/homePage`; // 首页
const DATASEARCHV2 = `${baseIp}/dataSearch`; // 首页
const HELPCENTER = `${baseIp}/helpcenter`; // 帮助中心
const TRADE = `${baseIp}/trade`; // 支付二维码
const WX = `${baseIp}/wx`; // 微信相关接口
const WX2 = `${baseIp}/wx_oa`;
const PUCHASEINDUSTRY = `${baseIp}/purchaseIndustry`; // 购买产业
const PUCHASECITY = `${baseIp}/purchaseCity`; // 购买城市
const SPECIALIZATION = `${baseIp}/specialization`; // 专精特新专题
const FINESYSTEMREPORT = `${baseIp}/fineSystemReport`;
const PURCHASEINDUSTRY = `${baseIp}/purchaseIndustry`; // 专精特新专题
const CITYCHANNEL = `${baseIp}/cityChannel`; // 城市频道
const SQCOIN = `${baseIp}/sqCoin`; // 上奇币
const DATAOWNLOAD = `${baseIp}/data_download`; // 数据下载
const REPORT_ENGINE = `${baseIp}/report-engine-service`;
const KNOWLEDGECOMPONENT = `${baseIp}/knowledge_component`; // 知识组件

const projectName = 'smart-ke'; // 项目名

export {
  PUCHASEINDUSTRY,
  PUCHASECITY,
  HOMEPAGEV2,
  DATASEARCHV2,
  SPECIALIZATION,
  FINESYSTEMREPORT,
  WX,
  WX2,
  USER,
  oauthIp,
  AUTH,
  TRADE,
  FIGUREPARK,
  NAVAUTHORITY,
  HOMEPAGE,
  projectName,
  DATASEARCH,
  COMPANYDETAIL,
  INDUSTRYATLAS,
  INSTITUTIONDETAIL,
  PATENTDETAIL,
  INDUSTRYREPORT,
  POLICYDETAIL,
  REPORT,
  CHART,
  USERMANAGE,
  baseIp,
  REPORT_ENGINE,
  INTERACTION,
  INDUSTRYGRAPH,
  INVESTDETAIL,
  STANDARDDETAIL,
  RESEARCHREPORTDETAIL,
  MACROECONOMYDETAIL,
  USERCUSTOM,
  INVESTMENTEVENTDETAIL,
  COMPANYREPORT,
  LEADERBOARD,
  ANALYSISENGINE,
  ONLYOFFICE,
  REPORTtEMPLATE,
  HELPCENTER,
  PURCHASEINDUSTRY,
  CITYCHANNEL,
  SQCOIN,
  DATAOWNLOAD,
  ENTERPRESCONTRAST,
  KNOWLEDGECOMPONENT,
};
