import areaback from '../assets/images/report/areaReport.png';
import industryBack from '../assets/images/report/industryReport.png';
import compnayBack from '../assets/images/report/companyReport.png';
import preciseInvest from '../assets/images/report/preciseInvest.png';
import equityInvest from '../assets/images/report/equityInvestment.png';
import zhishichanquan from '../assets/images/report/zhishichanquan.png';
import zhuanjingtexin from '../assets/images/report/zhuanjingtexin.png';
import gaoxinjishu from '../assets/images/report/gaoxinjishu.png';

export const reportCoverMap = {
  '16,23': areaback,
  '16': areaback,
  '23': areaback,
  '17,22': industryBack,
  '17': industryBack,
  '22': industryBack,
  '18': compnayBack,
  '493': zhuanjingtexin,
  '495,494': gaoxinjishu,
  '495': gaoxinjishu,
  '494': gaoxinjishu,
  '497,496': zhishichanquan,
  '497': zhishichanquan,
  '496': zhishichanquan,
  '498': equityInvest,
  '499': preciseInvest,
};