import React from 'react';
import BarAndLine from '../../../../../Charts/BarAndLine';
import { useGetPatentStatisticsIndustryDistributionQuery } from '../../../../../../service/companyDetail';

const IndustrialDistribution = ({ companyId, grade }) => {
  const { data, isFetching } = useGetPatentStatisticsIndustryDistributionQuery({
    company_id: companyId,
    penetration_grade: grade,
  });
  return (
    <div style={{ width: '662px', height: '344px' }}>
      <BarAndLine
        data={{ ...data, bottom: 90, rotate: 30 }}
        isFetching={isFetching}
      />
    </div>
  );
};

export default IndustrialDistribution;
