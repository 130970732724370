import React from 'react';
import StackBarChart from '../../../../../Charts/StackBar';
import { useGetPatentStatisticsApplicationTrendQuery } from '../../../../../../service/companyDetail';

// /industry_knowledge_engine/v2/companyDetail/patent_statistics_application_trend
const ApplicationTrend = ({ companyId, grade }) => {
  const { data, isFetching } = useGetPatentStatisticsApplicationTrendQuery({
    company_id: companyId,
    penetration_grade: grade,
  });
  return (
    <div style={{ width: '662px', height: '344px' }}>
      <StackBarChart data={{ ...data }} isFetching={isFetching} />
    </div >
  );
};

export default ApplicationTrend;
