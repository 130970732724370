import React, { useMemo, useState } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import clsx from 'clsx';
import { useGetParkIndustryDistributionQuery } from '../../../../../../service/parkDetail';
import { StandardModal } from '../../../../../../components/Modal';
import DistributionRanking from './DistributionRanking';
import SectorCompanyTable from './SectorCompanyTable';
import Underline from '../../../../../../components/Underline';
import Subtitle from '../../../../../../components/Subtitle';
import ChartNoData from '../../../../../../components/Charts/ChartNoData';
import { jumpCompantDetail, jumpIndustry } from '../../../../../../utils/jumpCompantDetail';
import style from './index.module.less';

const colorList = [
  '#FDAD61', '#4582FF', '#84F3B6', '#70DFB6', '#FFC559',
  '#FF5A17', '#59D700', '#1961F5', '#DC77BC', '#6B9BFF',
  '#569EFF', '#00B572', '#F88C6B', '#7B61F0', '#FFE050',
  '#EE964B', '#22B6F1', '#B061F0', '#4E88FF', '#51C886',
];

function IndustrialDistribution({ parkCode, title }) {
  const [mapListQueryState, setMapListQueryState] = useState({
    pageSize: 20,
    pageNumber: 1,
    sortedInfo: {},
  });
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState({
    visible: false,
    industryCode: '',
    industryName: '',
    regionCode: '',
    type: '',
    filteredInfo: {},
  });
  const [pool, setPool] = useState({});
  const [count, setCount] = useState(0);

  const { data: chartData, isFetching } = useGetParkIndustryDistributionQuery({
    park_id: parkCode,
  }, {
    skip: !parkCode,
  });

  const option = useMemo(() => {
    const values = chartData?.series?.[0]?.data ?? [];
    const min = values?.[values?.length - 1]?.value;
    const max = values?.[0]?.value;
    const range = (max - min) / 100;
    const seriesData = values.map((item, index) => ({
      ...item,
      symbolSize: 60 + (item.value / range),
      draggable: true,
      itemStyle: {
        shadowBlur: 10,
        shadowColor: colorList[index % colorList.length],
        color: colorList[index % colorList.length],
      },
      label: {
        show: true,
        textBorderWidth: 1,
        textBorderColor: colorList[index % colorList.length],
        textShadowBlur: 2,
        textShadowColor: colorList[index % colorList.length],
        color: '#fff',
      },
    }));

    return {
      tooltip: {
        formatter: params => `${params.marker}${params.name}\u3000\u3000${params.value.toLocaleString()}家`,
      },
      color: ['#fff', '#fff', '#fff'],
      series: [
        {
          type: 'graph',
          layout: 'force',
          force: {
            repulsion: 250,
            edgeLength: 10,
          },
          // roam: true,
          roam: 'move',
          data: seriesData,
        },
      ],
    };
  }, [chartData]);
  const charts = () => {
    if (!chartData) return <div style={{ minHeight: '300px' }}><Spin /></div>;
    if (chartData?.series?.length < 1) return <ChartNoData />;
    return <ECharts
      className={style.chart}
      option={option}
    />;
  };

  return (
    <div className={style.all} id={title}>
      <div className={style.title}>{title}</div>
      <div className={style.content}>
        <div className={style.left}>
          {
            charts()
          }
        </div>
        <div className={style.right}>
          <div className={style.title}>
            <Subtitle
              className={style.subtitle}
              description="企业共计"
              value={pool?.['企业总数']}
              unit="家"
            />
            <br />
            <Subtitle
              className={style.subtitle}
              description="链主企业"
              value={pool?.['链主企业总数']}
              unit="家，"
            />
            <Subtitle
              className={style.subtitle}
              description="上市企业"
              value={pool?.['上市企业总数']}
              unit="家，"
            />
            <Subtitle
              className={style.subtitle}
              description="专精特新"
              value={pool?.['专精特新企业总数']}
              unit="家，"
            />
            <Subtitle
              className={style.subtitle}
              description="高新技术"
              value={pool?.['高新技术企业总数']}
              unit="家"
            />
          </div>
          <Underline />
          <DistributionRanking
            regionCode={parkCode}
            pageSize={mapListQueryState.pageSize}
            pageNumber={mapListQueryState.pageNumber}
            sortedInfo={mapListQueryState.sortedInfo}
            onChange={({ current }, filter, sorter) => {
              setMapListQueryState(prev => ({
                ...prev,
                pageNumber: current,
                sortedInfo: sorter,
              }));
            }}
            onClick={({ record, column }) => {
              const { industry_code: _industryCode, industry_name: _industryName } = record;
              const { dataIndex } = column;
              setMapCompanyListQueryState(prev => ({
                ...prev,
                industryCode: _industryCode,
                industryName: _industryName,
                filteredInfo: {},
                type: [dataIndex],
                visible: true,
              }));
            }}
            // onIndustryClick={(props) => {
            //   if (!props.industry_code) return;
            //   jumpIndustry('graph', window.btoa(props.industry_code));
            // }}
            getPool={setPool}
          />
        </div>
      </div>
      {
        mapCompanyListQueryState.visible && (
          <StandardModal
            header={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ color: '#0f2849', fontWeight: 'bold' }}>
                  {mapCompanyListQueryState.industryName}
                </span>
                <Subtitle
                  className={clsx(style.subtitle, style.modalTitle)}
                  description="筛选结果"
                  value={count}
                  unit="家"
                />
              </div>
            }
            onClose={() => {
              setMapCompanyListQueryState(prev => ({
                ...prev,
                pageNumber: 1,
                visible: false,
              }));
            }}
          >
            <SectorCompanyTable
              industryCode={mapCompanyListQueryState.industryCode}
              regionCode={mapCompanyListQueryState.regionCode || parkCode}
              pageSize={mapCompanyListQueryState.pageSize}
              pageNumber={mapCompanyListQueryState.pageNumber}
              companyTypes={mapCompanyListQueryState.type}
              onChange={({ current, pageSize }, filter) => {
                setMapCompanyListQueryState({
                  ...mapCompanyListQueryState,
                  pageSize,
                  pageNumber: current,
                  filteredInfo: filter,
                });
              }}
              filteredInfo={mapCompanyListQueryState.filteredInfo}
              onCompanyClick={({ company_id: _id }) => {
                if (!_id) return;
                jumpCompantDetail(_id);
              }}
              getCount={setCount}
            />
          </StandardModal>
        )
      }
    </div>
  );
}

export default IndustrialDistribution;
