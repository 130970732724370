/*
* Author: zhangmeng
* Description: '全部页'
* Date: 2022/06/29
*/

import React, { useMemo } from 'react';
import { Spin } from 'antd';
import Title from '../Title';
import CompanyListLine from '../CompanyListLine';
import IndustryChartList from '../IndustryChartList';
import PatentListLine from '../PatentListLine';
import InvestmentListLine from '../InvestmentListLine';
import PolicyListLine from '../PolicyListLine';
import PublicSentimentListLine from '../PublicSentimentListLine';
import ResearchReportListLine from '../ResearchReportListLine';
import { useGetAllDataQuery } from '../../../../service/dataSearch';
import style from './index.module.less';

function AllPage(props) {
  const { moreClick, styles, participle, hideTitle = false } = props;

  const { data, isFetching } = useGetAllDataQuery({
    keyword: participle.join(''),
  });

  const switchComp = (t, r, i) => {
    let comp = null;
    switch (t) {
      case '企业':
        comp = <CompanyListLine key={i} record={r} participle={participle} />;
        break;
      case '产业':
        comp = <IndustryChartList key={i} industryData={r} participle={participle} />;
        break;
      case '专利':
        comp = <PatentListLine key={i} record={r} participle={participle} />;
        break;
      case '投资':
        comp = <InvestmentListLine key={i} record={r} participle={participle} />;
        break;
      case '政策':
        comp = <PolicyListLine key={i} record={r} participle={participle} />;
        break;
      case '舆情':
        comp = <PublicSentimentListLine key={i} record={r} participle={participle} />;
        break;
      case '研报':
        comp = <ResearchReportListLine key={i} record={r} participle={participle} />;
        break;
      default:
        break;
    }
    return comp;
  };

  return (
    <div className={style.all} style={styles}>
      {
        !hideTitle && <div className={style.title}>全部</div>
      }
      <Spin tip="搜索中，请稍候..." spinning={isFetching}>
        {
          data && data.length > 0 && data?.map((item, index) => (
            <div key={item.type + index} className={style.titleWithContent}>
              {
                item.data && item.data.length > 0 && <Title title={item.type || ''} moreClick={moreClick} />
              }
              {
                item.data && item.data.length > 0 && item.type !== '产业' && item.data.map((row, rowI) => (
                  switchComp(item.type, row, rowI)
                ))
              }
              {
                item.data && item.data.length > 0 && item.type === '产业' && switchComp(item.type, item.data, 1)
              }
            </div>
          ))
        }
        {
          data?.map(item => item.data?.length).filter(Boolean)?.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>暂无数据</div>
          )
        }
      </Spin>
    </div>
  );
}

export default AllPage;
