/*
* Author: zhangmeng
* Description: '专利详情页'
* Date: 2020/11/25
*/

import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { IconFont, tabMap } from '../../utils/mapIcon';
import { openNewPage } from '../../utils/urlMethods';
import PatentOverviewScreen from './PatentOverviewScreen';
import PatentListScreen from './PatentListScreen';
import { getPatentInfo, getLikePatent } from '../../api/patentDetail';
import style from './index.module.less';

function IndustryPatentDetail(props) {
  const { detailParams, location: { search } } = props;
  const [detail, setDetail] = useState({});
  const [like, setLike] = useState([]);
  const params = useMemo(() => new URLSearchParams(search.slice(1)), [search]);

  useEffect(() => {
    // 详情
    const patentName = detailParams || params.get('id');
    getPatentInfo({
      patent_id: patentName,
    }).then(res => {
      if (res.status === '00000') {
        const { ipc } = res.data;
        setDetail(res.data);
        // 相似专利
        getLikePatent({
          major_cate_code: ipc,
        }).then(res1 => {
          if (res.status === '00000') {
            setLike(res1.data);
          }
        });
      }
    });
  }, []);

  const itemClick = (item) => {
    openNewPage('patentDetail', {
      id: item.id,
    });
  };

  return (
    <div className={style.all}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.title}>
            <span className={style.before}>
              产业通
              &emsp;
              <span className={style.point} />
              &emsp;
            </span>
            <span className={style.later}>专利</span>
          </div>
          <div className={style.imgBox}>
            <IconFont type={tabMap.patent} />
          </div>
        </div>
      </div>
      <PatentOverviewScreen className={style.screen} data={detail} />
      <PatentListScreen className={style.screen} data={like} onItemClick={itemClick} />
    </div>
  );
}

export default withRouter(IndustryPatentDetail);
