import React, { useMemo, useEffect, useState } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import classNames from 'classnames';
import { DownCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useRadarChartQuery } from '../../../../../../service/tool';
import { getTitleInfo } from '../../../../../../api/companyDetail';
import style from './index.module.less';

const Header = ({ data, id, companyId, downloadPdf }) => {
  const [infoData, setInfoData] = useState([]);
  const [qualified, setQualified] = useState('-');
  const [expand, setExpand] = useState({
    所属行业: true,
    公司资质: true,
  });
  const [schedule, setSchedule] = useState([false, false, false, false, false, false]);
  useEffect(() => {
    if (companyId) {
      getTitleInfo({ company_id: companyId }).then(res => {
        setInfoData(res.data);
      });
    }
  }, [companyId]);
  useEffect(() => {
    if (data) {
      const indexList = Object.values(data.appraisal_result);
      setQualified(indexList.every(item => item) ? '达标' : '待达标');
      const goodList = indexList.filter(item => item);
      setSchedule(
        indexList.map((item, index) => {
          if (index < goodList.length - 1 || index === goodList.length - 1) {
            return true;
          }
          return false;
        }),
      );
    }
  }, [data]);

  const { data: radarData, isFetching } = useRadarChartQuery({
    record_id: id,
  });
  const unpack = (value) => {
    setExpand(params => ({
      ...params,
      [value]: !params[value],
    }));
  };
  const option = useMemo(() => {
    const series = radarData?.series?.map(item => (
      {
        name: item.name,
        type: 'radar',
        data: item.data,
        label: {
          show: false,
        },
        areaStyle: {
          color: 'rgba(68, 115, 244, 0.20)',
        },
        lineStyle: {
          width: 2,
          color: '#4473F4',
        },
      }
    ));
    const indicator = radarData?.indicator?.map((item, index) => (
      {
        name: item,
        max: 100,
        columns: [series?.[0]?.data?.[0]?.value?.[index]],
        // axisLabel: {
        //   show: index === 0,
        // },
      }
    ));

    return {
      radar: {
        radius: '60%',
        nameGap: 10,
        axisName: {
          formatter: (name, indicatorOpt) => `{title|${name || ''}}`,
          rich: {
            title: {
              padding: [2, 0, 0, 0],
              fontSize: 12,
              align: 'center',
            },
            value: {
              width: 54,
              height: 22,
              fontSize: 12,
              color: '#1961F5',
              align: 'center',
              verticalAlign: 'middle',
              padding: [2, 0, 0, 0],
              backgroundColor: 'rgba(217,232,255, 1)',
              borderWidth: 1,
              borderRadius: 4,
            },
          },
        },
        indicator,
      },
      tooltip: {
        show: false,
      },
      series,
    };
  }, [radarData]);
  return (
    <div className={style.header}>
      <div className={style.left}>
        <div className={style.leftTop}>
          <div className={style.companyBox}>
            <div className={style.companyIcon}>
              <div className={style.logo} />
            </div>
            <div className={style.companyName} title={data?.company_name}>
              {data?.company_name}
            </div>
          </div>
          <div className={style.tagBox}>
            {
              useMemo(() => (
                infoData?.tags?.map(item => (
                  <div key={item.type} className={style.tagRow}>
                    <span className={style.tagType}>{item.type}</span>
                    <div className={classNames(style.tagList, { [style.hidden]: expand[item.type] })}>
                      {
                        item.tabs.map((it, index) => <span key={index} className={classNames({ [style.certification]: item.type === '公司资质' })}>{it}</span>)
                      }
                    </div>
                    <div className={classNames(style.more, { [style.certification]: item.type === '公司资质' })} onClick={() => { unpack(item.type); }}>
                      <DownCircleOutlined />
                    </div>
                  </div>
                ))
              ), [infoData, expand])
            }
          </div>
        </div>
        <div className={style.leftBottom}>
          <div className={style.result}>
            <span>综合评分</span>
            <span>
              {
                qualified
              }
            </span>
          </div>
          <div className={style.schedule}>
            <div className={style.scheduleTitle}>
              <span className={style.scheduleName}>硬性指标解锁度</span>
              <span className={style.scheduleIcon}>
                {
                  schedule.filter(item => item).length || '-'
                }
              </span>
            </div>
            <div className={style.goodBox}>
              {
                schedule.map((item, index) => <div key={index} className={classNames(style.good, { [style.noGood]: !item })} />)
              }
            </div>
          </div>
        </div>
      </div>
      <div className={style.right}>
        <div className={style.chart} key={JSON.stringify(expand)}>
          {
            isFetching ? <div className={style.loading}><Spin /></div> : (
              <ECharts
                // showLoading={isFetching}
                style={{ width: '100%', height: '100%' }}
                option={option}
              />
            )
          }
        </div>
        <div className={style.matrix}>
          {
            data && Object.keys(data.appraisal_result).map(item => (
              <div key={item}>
                <div className={classNames(style.icon, { [style.gray]: !data.appraisal_result[item] })}>
                  <CheckOutlined />
                </div>
                <div className={style.target}>{item}</div>
              </div>
            ))
          }
        </div>
        <div />
        <div className={style.time}>
          <div>
            <span>报告生成时间</span>
            <span>{data?.create_time}</span>
          </div>
        </div>
      </div>
      {/* <div className={style.right}>
        <button type="button" onClick={downloadPdf}>
          导出PDF报告
        </button>
        <div>
          <span>报告生成时间</span>
          <span>{ data?.create_time}</span>
        </div>
      </div> */}
    </div >
  );
};

export default Header;
