const data = [
  {
    name: '企业资质',
    require: '中国境内注册',
    select: '1',
  },
  {
    name: '企业资质',
    require: '注册一年以上',
    select: '2',
  },
  {
    name: '核心自主知识产权',
    require: [
      { A: '发明专利、植物新品种、国家新药、国家级农作物品种、国家一级中药保护品种、集成电路布图设计专有权1件以上；' },
      { B: '实用新型专利8件以上；' },
      { C: '非简单改变产品图案和形状的外观设计专利（主要是指：运用科学和工程技术的方法，经过研究与开发过程得到的外观设计）或者软件著作权10件以上' },
    ],
    select: '3',
  },
  {
    name: '产品（服务）范围',
    require: '对企业主要产品（服务）发挥核心支持作用的技术属于《国家重点支持的高新技术领域目录2016》规定的范围：电子信息、生物医药、航空航天、新材料、高技术服务、新能源、节能、智能制造',
    select: '4',
  },
  {
    name: '科技人员及研发人员占比',
    require: '企业从事研发和相关技术创新活动的科技人员占企业当年职工总数的比例不低于10%',
    select: '5',
  },
  {
    name: '研发费用',
    require: [
      { 1: '最近一年销售收入小于5,000万元（含）的企业，比例不低于5%；' },
      { 2: '最近一年销售收入在5,000万元至2亿元（含）的企业，比例不低于4%；' },
      { 3: '最近一年销售收入在2亿元以上的企业，比例不低于3%' },
    ],
    select: '6',
  },
  {
    name: '高新技术产品（服务）收入',
    require: '近一年高新技术产品（服务）收入占企业同期总收入的比例不低于60%',
    select: '7',
  },
  {
    name: '境内研发费用',
    require: '企业申请高新认定前一年内未发生重大安全、重大质量事故或严重环境违法行为',
    select: '8',
  },
];

const DetailedEvaluation = [
  {
    name: '企业资质',
    standard: '中国境内注册',
    condition: '注册地: ',
    satisfy: '是',
    select: '1',
  },
  {
    name: '企业资质',
    standard: '注册一年以上',
    condition: '成立于 ',
    satisfy: '是',
    select: '2',
  },
  {
    name: '核心自主知识产权',
    standard: [
      { A: '发明专利、植物新品种、国家新药、国家级农作物品种、国家一级中药保护品种、集成电路布图设计专有权1件以上；' },
      { B: '实用新型专利8件以上；' },
      { C: '非简单改变产品图案和形状的外观设计专利（主要是指：运用科学和工程技术的方法，经过研究与开发过程得到的外观设计）或者软件著作权10件以上' },
    ],
    condition: '',
    satisfy: '是',
    select: '3',
  },
  {
    name: '产品（服务）范围',
    standard: '对企业主要产品（服务）发挥核心支持作用的技术属于《国家重点支持的高新技术领域目录2016》规定的范围：电子信息、生物医药、航空航天、新材料、高技术服务、新能源、节能、智能制造',
    condition: '产品（服务）不属于规定的范围',
    satisfy: '是',
    select: '4',
  },
  {
    name: '科技人员及研发人员占比',
    standard: '企业从事研发和相关技术创新活动的科技人员占企业当年职工总数的比例不低于10%',
    condition: '占比',
    satisfy: '是',
    select: '5',
  },
  {
    name: '研发费用',
    standard: [
      { 1: ' 最近一年销售收入小于5,000万元（含）的企业，比例不低于5%；' },
      { 2: '最近一年销售收入在5,000万元至2亿元（含）的企业，比例不低于4%；' },
      { 3: '最近一年销售收入在2亿元以上的企业，比例不低于3%其中，企业在中国境内发生的研究开发费用总额占全部研究开发费用总额的比例不低于' },
    ],
    condition: '企业在中国境内发生的研究开发费用总额占全部研究开发费用总额的比例不低于',
    satisfy: '是',
    select: '6',
  },
  {
    name: '境内研发费用',
    standard: '企业在中国境内发生的研究开发费用总额占全部研究开发费用总额的比例不低于60%',
    condition: '占比',
    satisfy: '是',
    select: '7',
  },
  {
    name: '高新技术产品（服务）收入',
    standard: '近一年高新技术产品（服务）收入占企业同期总收入的比例不低于60%',
    condition: '占比',
    satisfy: '是',
    select: '8',
  },
  {
    name: '企业风险',
    standard: '企业申请高新认定前一年内未发生重大安全、重大质量事故或严重环境违法行为',
    condition: '企业风险',
    satisfy: '是',
    select: '9',
  },
];

export {
  data,
  DetailedEvaluation,
};
