import clsx from 'clsx';
import styles from './index.module.scss';

const TableTitle = (props: any) => {
  const { children, style, } = props;
  return <div className={styles.title} style={style}>{children}</div>;
}
const Row = (props: any) => {
  const { children, style, } = props;
  return <div className={styles.row} style={style}>{children}</div>;
}
const Label = (props: any) => <div className={clsx(styles.label, styles.pin)}>{props.children}</div>;
const Cell = (props: any) => {
  const { className, children, style, } = props;
  return <div className={clsx(styles.cell, className)} style={style}>{children}</div>;
}

function EnterpriseFaceTrend(props: any) {
  const {
    companyTotal = '',
    capitalMarket = {},
    businessChange = '',
    expansionism = '',
    riskWarning = '',
    bidInformation = '',
    InformationDynamics = '',
    innovationAbility = {},
    className,
    style,
  } = props;

  return (
    <div
      className={clsx(styles.card, className)}
      style={style}
    >
      
      <Row>
        <Label>企业总量</Label>
        <Cell>{companyTotal || ''}</Cell>
      </Row>
      <Row>
        <Label>资本市场</Label>
        <Cell style={{ padding: 0, borderLeft: 'none' }}>
          <Row>
            <Label>一级市场</Label>
            <Cell>{capitalMarket?.oneLevel || ''}</Cell>
          </Row>
          <Row>
            <Label>二级市场</Label>
            <Cell>{capitalMarket?.twoLevel || ''}</Cell>
          </Row>
        </Cell>
      </Row>
      <Row>
        <Label>工商变更</Label>
        <Cell>{businessChange || ''}</Cell>
      </Row>
      <Row>
        <Label>对外扩张</Label>
        <Cell>{expansionism || ''}</Cell>
      </Row>
      <Row>
        <Label>风险预警</Label>
        <Cell>{riskWarning || ''}</Cell>
      </Row>
      <Row>
        <Label>中标信息</Label>
        <Cell>{bidInformation || ''}</Cell>
      </Row>
      <Row>
        <Label>信息动态</Label>
        <Cell>{InformationDynamics || ''}</Cell>
      </Row>
      <Row>
        <Label>创新能力</Label>
        <Cell style={{ padding: 0, borderLeft: 'none' }}>
          <Row>
            <Label>新增标准</Label>
            <Cell>{innovationAbility?.newStandard || ''}</Cell>
          </Row>
          <Row>
            <Label>新增专利</Label>
            <Cell>{innovationAbility?.newPatent || ''}</Cell>
          </Row>
        </Cell>
      </Row>
    </div>
  );
}

export {
  EnterpriseFaceTrend,
};
