import React from 'react';
import clsx from 'clsx';
import { IconFont } from '../../utils/mapIcon';
import Underline from '../Underline';
import styles from './index.module.less';

const CloseIcon = ({ className, onClick }) => (
  <IconFont className={clsx(styles.icon, className)} type="iconclose" onClick={onClick} />
);

function Modal({
  className,
  onClose,
  onClick,
  children,
}) {
  return (
    <div className={clsx(styles.modal, className)} onClick={onClick}>
      <CloseIcon className={styles.closeIcon} onClick={onClose} />
      {children}
    </div>
  );
}

function StandardModal({
  header,
  className,
  onClose,
  children,
}) {
  return (
    <Modal
      className={clsx(styles.standard, className)}
      onClose={onClose}
    >
      {
        header && (
          <div className={styles.header}>
            {header}
          </div>
        )
      }
      {
        header && <Underline className={styles.line} />
      }
      <div className={styles.content}>
        {children}
      </div>
    </Modal>
  );
}

export default Modal;

export {
  StandardModal,
};
