import wordCloudLayoutHelper from './lib/layout';

if (!wordCloudLayoutHelper.isSupported) {
  throw new Error('Sorry your browser not support wordCloud');
}

// https://github.com/timdream/wordcloud2.js/blob/c236bee60436e048949f9becc4f0f67bd832dc5c/index.js#L233
function updateCanvasMask(maskCanvas) {
  const ctx = maskCanvas.getContext('2d');
  const imageData = ctx.getImageData(0, 0, maskCanvas.width, maskCanvas.height);
  const newImageData = ctx.createImageData(imageData);

  let toneSum = 0;
  let toneCnt = 0;
  for (let i = 0; i < imageData.data.length; i += 4) {
    const alpha = imageData.data[i + 3];
    if (alpha > 128) {
      const tone =
        imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2];
      toneSum += tone;
      ++toneCnt;
    }
  }
  const threshold = toneSum / toneCnt;

  for (let i = 0; i < imageData.data.length; i += 4) {
    const tone =
      imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2];
    const alpha = imageData.data[i + 3];

    if (alpha < 128 || tone > threshold) {
      // Area not to draw
      newImageData.data[i] = 0;
      newImageData.data[i + 1] = 0;
      newImageData.data[i + 2] = 0;
      newImageData.data[i + 3] = 0;
    } else {
      // Area to draw
      // The color must be same with backgroundColor
      newImageData.data[i] = 255;
      newImageData.data[i + 1] = 255;
      newImageData.data[i + 2] = 255;
      newImageData.data[i + 3] = 255;
    }
  }

  ctx.putImageData(newImageData, 0, 0);
}

function adjustRectAspect(gridRect, aspect) {
  const width = gridRect.width;
  const height = gridRect.height;
  if (width > height * aspect) {
    gridRect.x += (width - height * aspect) / 2;
    gridRect.width = height * aspect;
  } else {
    gridRect.y += (height - width / aspect) / 2;
    gridRect.height = width / aspect;
  }
}

export function registerLayout(echarts, options) {
  const {
    canvasCreator = (width, height) => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      return canvas;
    },
    ssr = false,
  } = options ?? {};
  echarts.registerLayout(function (ecModel, api) {
    // ecModel.scheduler.ecInstance.showLoading();
    // ecModel.scheduler.ecInstance.hideLoading();
    // ecModel.scheduler.ecInstance.trigger('rendered');
    // ecModel.scheduler.ecInstance.trigger('finished');
    // ecModel.scheduler.unfinished = true;
    ecModel.eachSeriesByType('wordCloud', function (seriesModel) {
      // ecModel.scheduler.unfinished = true;
      const gridRect = echarts.helper.getLayoutRect(
        seriesModel.getBoxLayoutParams(),
        {
          width: api.getWidth(),
          height: api.getHeight()
        }
      );

      const keepAspect = seriesModel.get('keepAspect');
      const maskImage = seriesModel.get('maskImage');
      const ratio = maskImage ? maskImage.width / maskImage.height : 1;
      keepAspect && adjustRectAspect(gridRect, ratio);

      const data = seriesModel.getData();

      if (!canvasCreator) {
        return;
      }
      const canvas = canvasCreator();
      canvas.width = gridRect.width;
      canvas.height = gridRect.height;

      const ctx = canvas.getContext('2d');
      if (maskImage) {
        try {
          ctx.drawImage(maskImage, 0, 0, canvas.width, canvas.height);
          updateCanvasMask(canvas);
        } catch (e) {
          console.error('Invalid mask image');
          console.error(e.toString());
        }
      }

      const sizeRange = seriesModel.get('sizeRange');
      const rotationRange = seriesModel.get('rotationRange');
      const valueExtent = data.getDataExtent('value');

      const DEGREE_TO_RAD = Math.PI / 180;
      const gridSize = seriesModel.get('gridSize');

      const instance = wordCloudLayoutHelper(canvas, {
        canvasCreator,
        ssr,
        list: data
          .mapArray('value', function (value, idx) {
            var itemModel = data.getItemModel(idx);
            return [
              data.getName(idx),
              itemModel.get('textStyle.fontSize', true) ||
              echarts.number.linearMap(value, valueExtent, sizeRange),
              idx
            ];
          })
          .sort(function (a, b) {
            // Sort from large to small in case there is no more room for more words
            return b[1] - a[1];
          }),
        fontFamily:
          seriesModel.get('textStyle.fontFamily') ||
          seriesModel.get('emphasis.textStyle.fontFamily') ||
          ecModel.get('textStyle.fontFamily'),
        fontWeight:
          seriesModel.get('textStyle.fontWeight') ||
          seriesModel.get('emphasis.textStyle.fontWeight') ||
          ecModel.get('textStyle.fontWeight'),

        gridSize: gridSize,

        ellipticity: gridRect.height / gridRect.width,

        minRotation: rotationRange[0] * DEGREE_TO_RAD,
        maxRotation: rotationRange[1] * DEGREE_TO_RAD,

        clearCanvas: !maskImage,

        rotateRatio: 1,

        rotationStep: seriesModel.get('rotationStep') * DEGREE_TO_RAD,

        drawOutOfBound: seriesModel.get('drawOutOfBound'),
        shrinkToFit: seriesModel.get('shrinkToFit'),

        layoutAnimation: seriesModel.get('layoutAnimation'),

        shuffle: false,

        shape: seriesModel.get('shape')
      });

      function onWordCloudDrawn(e) {
        const item = e.detail.item;
        if (e.detail.drawn && seriesModel.layoutInstance.ondraw) {
          e.detail.drawn.gx += gridRect.x / gridSize;
          e.detail.drawn.gy += gridRect.y / gridSize;
          seriesModel.layoutInstance.ondraw(
            item[0],
            item[1],
            item[2],
            e.detail.drawn
          );
        }
      };

      instance.addEventListener('wordclouddrawn', onWordCloudDrawn);

      const onWordCloudFinished = () => {
        ecModel.scheduler.ecInstance.trigger('wordcloudstop');
      };
      instance.addEventListener('wordcloudstop', onWordCloudFinished);

      if (seriesModel.layoutInstance) {
        // Dispose previous
        seriesModel.layoutInstance.dispose();
      }

      seriesModel.layoutInstance = {
        ondraw: null,

        dispose: function () {
          instance.removeEventListener('wordclouddrawn', onWordCloudDrawn);
          // Abort
          instance.addEventListener('wordclouddrawn', function (e) {
            // Prevent default to cancle the event and stop the loop
            e.preventDefault();
          });
        }
      };
    });
  });
}

export function registerPreprocessor(echarts) {
  echarts.registerPreprocessor(function (option) {
    let series = (option || {}).series;
    !echarts.util.isArray(series) && (series = series ? [series] : []);

    const compats = ['shadowColor', 'shadowBlur', 'shadowOffsetX', 'shadowOffsetY'];

    echarts.util.each(series, function (seriesItem) {
      if (seriesItem && seriesItem.type === 'wordCloud') {
        const textStyle = seriesItem.textStyle || {};

        compatTextStyle(textStyle.normal);
        compatTextStyle(textStyle.emphasis);
      }
    });

    function compatTextStyle(textStyle) {
      textStyle &&
      echarts.util.each(compats, function (key) {
        if (textStyle.hasOwnProperty(key)) {
          textStyle['text' + echarts.format.capitalFirst(key)] = textStyle[key];
        }
      });
    }
  });
}
