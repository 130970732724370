/*
* Author: zhangmeng
* Description: '反馈组件'
* Date: 2021/08/03
*/

import React, { useEffect, useRef, useState } from 'react';
import { Input, Upload, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { IconFont } from '../../../../../../../../utils/mapIcon';
import style from './index.module.less';

const { TextArea } = Input;
const moduleData1 = ['企业标签', '最新动态', '基本信息', '知识产权', '对外投资', '关系图谱', '企业评价', '企业服务'];
function FeedbackDetail(props) {
  const { companyName, closePop, feedbackDetailEvent } = props;
  const [moduleData, setModuleData] = useState([]); // 模块数据
  const [textArea, setTextArea] = useState(''); // 文本域内容
  const [selectName, setSelectName] = useState('');
  const [fileName, setFileName] = useState('');
  const [isEmpty, setIsEmpty] = useState([false, false]); // 是否未填
  const [fileData, setFileData] = useState({});
  const timer = useRef(null);

  useEffect(() => {
    setModuleData(moduleData1);
  }, []);

  const textAreaChange = (p) => {
    setTextArea(p.target.value);
  };

  const upLoadChange = (info) => {
    if (info.file.size > 5120000) {
      message.info('请上传小于5M的图片');
      return;
    }
    if (info.file.type !== 'image/jpeg' && info.file.type !== 'image/png' && info.file.type !== 'image/jpg') {
      message.info('仅支持jpg、jpeg、png格式的图片！');
      return;
    }
    setFileName(info.file.name);
    setFileData(info.fileList[0].originFileObj);
  };

  const onRemoveFunc = () => {
    setFileName('');
    setFileData({});
  };

  const confirmCLick = () => {
    timer.current = null;
    const arr = [...isEmpty];
    if (selectName === '') {
      arr[0] = true;
    }
    if (textArea === '') {
      arr[1] = true;
    }
    setIsEmpty(arr);
    timer.current = setTimeout(() => {
      setIsEmpty([false, false]);
    }, 2000);
    if (selectName !== '' && textArea !== '') {
      const obj = {
        block: selectName,
        content: textArea,
        image: fileData,
      };
      feedbackDetailEvent('confirm', obj);
    }
  };

  return (
    <div className={style.all}>
      <header>
        <span>反馈一下</span>
        <span className={style.add} onClick={() => closePop()}>
          <CloseOutlined />
        </span>
      </header>
      <div className={style.ctn}>
        <div className={style.companyName}>{companyName}</div>
        <div className={style.wrongModule}>
          <div className={style.wrongHeader}>
            <span className={style.mark}>*</span>
            <span className={style.title}>有误模块</span>
            <span className={style.subTitle}>请选择您认为有误的模块内容，一次仅选一项</span>
          </div>
          <div className={style.modules}>
            {
              moduleData.length > 0 && moduleData.map((item, index) => (
                <div
                  className={selectName === item ? style.selectModule : style.module}
                  key={index}
                  onClick={() => setSelectName(item)}
                >
                  {item}
                </div>
              ))
            }
          </div>
          <div className={style.Mask} style={{ display: isEmpty[0] ? 'block' : 'none' }}>
            <span>必填项</span>
          </div>
        </div>
        <div className={style.desc}>
          <div className={style.descHeader}>
            <span className={style.mark}>*</span>
            <span className={style.title}>详细描述</span>
          </div>
          <TextArea
            rows={4}
            allowClear
            placeholder="请描述您要反馈的具体问题，字数限制100字"
            maxLength={100}
            value={textArea}
            onChange={(p) => textAreaChange(p)}
          />
          <div className={style.Mask} style={{ display: isEmpty[1] ? 'block' : 'none' }}>
            <span>必填项</span>
          </div>
        </div>
        <div className={style.imageDesc}>
          <div className={style.imageHeader}>
            <span className={style.title}>图片说明</span>
            <span className={style.subTitle}>仅支持jpg、jpeg、png格式，大小不超过5M</span>
          </div>
          <div className={style.upload}>
            <div className={style.uploadShow}>
              <div className={style.imgIcon}>
                <IconFont type="iconimage" />
              </div>
              <span className={style.name}>
                {fileName}
              </span>
              <div className={style.close}>
                <CloseOutlined onClick={() => onRemoveFunc()} style={{ display: fileName ? '' : 'none' }} />
              </div>
            </div>
            <Upload
              beforeUpload={() => false}
              name="file"
              maxCount={1}
              headers={{
                Authorization: 'authorization-text',
              }}
              showUploadList={false}
              accept="image/png, image/jpg, image/jpeg"
              onChange={upLoadChange}
            >
              {`${fileName ? '重新上传' : '上传图片'}`}
            </Upload>
          </div>
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.confirm} onClick={() => confirmCLick()}>确认</div>
        <div className={style.cancel} onClick={() => feedbackDetailEvent('cancel')}>取消</div>
      </div>
    </div>
  );
}

export default FeedbackDetail;
