import React from 'react';
import apply from '../../assets/images/report/apply.png';
import style from './index.module.less';

export default function PayAmountCard(props) {
  const { count } = props;
  return (
    <div className={style.applyMethod}>
      <div className={style.left}>
        <div>付款方式</div>
        <div style={{ display: 'flex' }}>
          <img alt="" src={apply} style={{ width: '18px', height: '18px', marginRight: '10px' }} />
          微信
        </div>
      </div>
      <div className={style.right}>
        <div className={style.limitDiscount}>限时优惠</div>
        <div className={style.apllyCount}>{`￥${count}`}</div>
        <div className={style.lastLine}>仅支付</div>
      </div>
    </div>
  );
}
