/*
*Description: 样式文件
*/
import * as CONSTS from '../../consts';

const chartFontSize = {
  small: {
    legnedFontSize: 6,
    labelFontSize: 6,
  },
  middle: {
    legnedFontSize: 8,
    labelFontSize: 8,
  },
  large: {
    legnedFontSize: 10,
    labelFontSize: 10,
  },
};

const modeStyle = {
  light: {
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    color: ['#3E5C76', '#30A170', '#EE964B'],
    labelColor: '#0D3B66',
    labelBgdColor: '#E2EEFF',
    labelBorderColor: '#C5D5EA',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',
  },
  dark: {
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    color: ['#00F2FE', '#3E83F7', '#1D16F4', '#5170CA', '#390D97', '#E65226', '#FEC52A'],
    labelColor: '#fff',
    labelBgdColor: 'rgba(27, 2, 253, 0.3)',
    labelBorderColor: 'transparent',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
  },
};

export {
  chartFontSize,
  modeStyle,
};
