import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

function GraphTab({
  name,
  onClick,
  active,
  className,
}) {
  return (
    <div
      className={classnames(
        styles.dataTab,
        { active },
        className,
      )}
      onClick={() => onClick && onClick({ name })}
    >
      <div className={styles.name}>{name}</div>
    </div>
  );
}

export default GraphTab;
