import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import IndustryChannel from './IndustryChannel';
import CityChannelSimple from './CityChannelSimple';
import SpecialPayModal from './SpecialPayModal';
import style from './index.module.less';

const MENU = [
  {
    title: '产业',
    key: 'industry',
  },
  {
    title: '城市',
    key: 'city',
  },
  {
    title: '专题',
    key: 'special',
  },
];

const hint = [
  ['购买后有效期一年，用户可登录产业通网站（', '）或微信搜索“上奇产业通”小程序随时使用'],
  ['购买后可查看内容及权限请参照“氢能”“北京”，其他频道类似（注：平台数据不支持导出，数据查看量为100条/每次搜索）'],
  ['本账号为虚拟产品，原则上一经购买不退不换，请您理解'],
  ['开具发票或使用遇到问题，请联系客服'],
];

function ChannelModal(props) {
  const { onClose, paySuccessCb, defaultSelect, type = 'industry', setPayType, pushDetailPage, getSpecialList } = props;
  const [typeKey, setTypeKey] = useState(type);
  const [defaultSel, setDefaultSel] = useState(defaultSelect);
  const channelModalRef = useRef();
  useEffect(() => setPayType && setPayType('industry'), []);
  return (
    <div className={style.mask}>
      <div className={style.all}>
        <div className={style.title}>
          <div className={style.titleText}>开通专属频道 ，尊享更多内容</div>
          <CloseOutlined onClick={() => onClose && onClose()} className={style.closeIcon} />
        </div>
        <div className={style.type}>
          {
            MENU.map(item => (
              <div
                key={item.key}
                className={classNames(style.typeItem, { [style.active]: item.key === typeKey })}
                onClick={() => {
                  if (channelModalRef.current?.loading) return;
                  setDefaultSel('');
                  setTypeKey(item.key);
                }}
              >
                {item.title}
              </div>
            ))
          }
        </div>
        <div className={style.content}>
          {
            typeKey !== 'special' && <div className={style.describe}>单击选择，￥99/频道/年，3个以上8折</div>
          }
          {
            typeKey === 'industry' && (
              <IndustryChannel
                defaultSelectIndustry={defaultSel}
                paySuccessCb={paySuccessCb}
                onClose={onClose}
                pushDetailPage={pushDetailPage}
                ref={channelModalRef}
              />
            )
          }
          {
            typeKey === 'city' && (
              <CityChannelSimple
                defaultSelectCity={defaultSel}
                paySuccessCb={paySuccessCb}
                onClose={onClose}
                pushDetailPage={pushDetailPage}
                ref={channelModalRef}
              />
            )
          }
          {
            typeKey === 'special' && (
            <SpecialPayModal
              paySuccessCb={paySuccessCb}
              defaultSelectCity={defaultSel}
              setDefaultSel={setDefaultSel}
              onClose={onClose}
              pushDetailPage={pushDetailPage}
              getSpecialList={getSpecialList}
            />
            )
          }
        </div>
        <div className={style.hint} >
          {
            hint.map((item, index) => (
              <div key={index}>
                {index + 1}
                、&nbsp;
                {item.length === 1 ? item.join() : (
                  <>
                    {item[0]}
                    <a href="www.chanyeos.com"> www.chanyeos.com</a>
                    {item[1]}
                  </>
                )}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default ChannelModal;
