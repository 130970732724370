/*
*Description: HeapMapRectangle业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import HeapMapRectangleChart from '../../BasicsCompoment/HeapMapRectangleChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessHeapMapRectangleChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessHeapMapRectangleChart(props: BusinessHeapMapRectangleChartProps) {
  const {
    mode, label, legend = false, ip, conditions,
    onRequestComplete, title, componmentKey,
    imgRef,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data;
          if (Array.isArray(resData.data.data)) {
            resData.data.data = resData.data.data.flat(1); // 二维拍成一维
          }
          setData(resData.data);
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
         onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <HeapMapRectangleChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            label,
            chartDesc,
            legend,
            ref: imgRef,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BusinessHeapMapRectangleChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: false,
  title: true,
};
// 产业企业数量对标
export function EnterPriseStockCount(props: BusinessHeapMapRectangleChartProps) {
  const componmentKey = {
    function_key: 'industry_company_number_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
    />);
}
// 研究机构类型对比
export function ResearchTypeDiff(props: BusinessHeapMapRectangleChartProps) {
  const componmentKey = {
    function_key: 'institution_number_fenxi',
    class_name: '/chart/institution',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
    />);
}
// 融资事件产业分布
export function IndustryDistribution(props: BusinessHeapMapRectangleChartProps) {
  const componmentKey = {
    function_key: 'industry_investment_number_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
    />);
}
// 产业专利分布
export function IndustryPatentDistribution(props: BusinessHeapMapRectangleChartProps) {
  const componmentKey = {
    function_key: 'industry_patent_number_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
    />);
}

// 图十. 产业新增企业数量对标
export function CompanyChartindustryCompanyNumberFenxi(props: BusinessHeapMapRectangleChartProps) {
  const componmentKey = {
    function_key: 'new_industry_company_number_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 图15. 产业新增授权发明专利分布
export function PatentChartnewIndustryPatentNumberFenxi(props: BusinessHeapMapRectangleChartProps) {
  const componmentKey = {
    function_key: 'new_industry_patent_number_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

//  . 139--国家级研究机构产业区域分布
export function InstitutionChartnationalLevelInstitutionIndustryAreaFenxi(
  props: BusinessHeapMapRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'national_level_institution_industry_area_fenxi',
    class_name: '/chart/institution',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 图34 . --存量企业产业区域分布
export function CompanyChartindustrialDistributionCompanyFenxi(
  props: BusinessHeapMapRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'industrial_distribution_company_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 矩形热力图 . --授权发明专利产业区域分布
export function PatentChartindustrialDistributionPatentFenxi(
  props: BusinessHeapMapRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'industrial_distribution_patent_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 矩形热力图 . --新增授权发明专利产业区域分布
export function PatentChartnewIndustrialDistributionPatentFenxi(
  props: BusinessHeapMapRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'new_industrial_distribution_patent_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 产业链环节分布
export function CompnayChartDistributionOfIndustrialLinksFenxi(
  props: BusinessHeapMapRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'distribution_of_industrial_links_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业产业分布
export function CompnayChartIndustrialDistributionCompanySpecialization(
  props: BusinessHeapMapRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'industrial_distribution_company_specialization',
    class_name: '/chart/company',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 细分环节区域分布
export function CompnayChartSubdivideLinkRegionalDistribution(
  props: BusinessHeapMapRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'subdivide_link_regional_distribution',
    class_name: '/chart/company',
  };
  return (
    <BusinessHeapMapRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}
