/*
 *Description: RadialTree
 *Date: Wed Jan 05 2022 14:38:25 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
/* eslint-disable import/no-unresolved */
import { TreeSeriesOption } from 'echarts/types/dist/charts';

import { RadialTreeChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, getWordsWidth, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-radial-tree-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);
const styleBigChart = classNames(`${prefixCls}-big-chart`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const RadialTreeChart = forwardRef((props: RadialTreeChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize, highLightSomeLabel = false, roam = false, collapse,
    industryChain } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [bigChart, setBigChart] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bigChartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    const handledData = data ? [data] : [];
    if (!handledData.length) {
      return;
    }
    // console.log(handledData);
    function getDepthestChild(root: TreeSeriesOption['data']) {
      if (!root) {
        return [];
      }
      let depth = 0;
      const children: any[] = [];
      const colorMap = new Map();
      const hightLightColor = curStyle.highLightColor;
      const hL = hightLightColor.length;
      let i = 0;
      function dfs(node: any, curDepth = 0, childIndex?: number, childrenArr?: any) {
        if (curDepth > depth) {
          depth = curDepth;
          children.length = 0;
        }
        if (curDepth === depth && !node?.children?.length) {
          children.push(node);
        }
        if (highLightSomeLabel) {
          if (node?.need_high_light === 1) {
            let color = colorMap.get(node?.type);
            if (!color) {
              color = hightLightColor[i % hL];
              i += 1;
              colorMap.set(node?.type, color);
            }
            childrenArr[childIndex as number] = {
              ...node,
              itemStyle: {
                color,
              },
              label: {
                color,
              },
            };
          }
        }
        if (node.isGray === false) {
          childrenArr[childIndex as number] = {
            ...node,
            itemStyle: {
              color: '#D2D2D2',
            },
            label: {
              color: '#D2D2D2',
            },
          };
        }
        // eslint-disable-next-line no-unused-expressions
        node?.children?.forEach((item: any, index: number, arr: any) => {
          dfs(item, curDepth + 1, index, arr);
        });
      }
      // 找寻最深节点 顺便处理高亮
      dfs(root![0]);
      return children;
    }
    const depthestChildren = getDepthestChild(handledData);
    handledData![0] = {
      ...handledData![0],
      label: {
        fontSize: 9,
        backgroundColor: 'rgba(163, 194, 210, 0.8)',
        color: '#333333',
        padding: 4,
      },
      symbolSize: 7,
      itemStyle: {
        color: '#89AFD2',
      },
    };
    // eslint-disable-next-line no-unused-expressions
    handledData![0].children?.forEach((node: any) => {
      node.lineStyle = {
        width: 1,
      };
    });

    // 单节点
    if (!data?.children?.length) {
      handledData![0]!.itemStyle = {
        lineStyle: {
          width: 10,
        },
      };
      handledData![0].children = [{
        itemStyle: {
          opacity: 0,
        },
        lineStyle: {
          width: 0,
        },
      }];
    }

    const ins = chartRef.current.getEchartsInstance();
    const conWidth = ins.getWidth();
    const conHeight = ins.getHeight();
    const r = Math.min(conWidth, conHeight) / 2;

    const inintRadius = 320 / 2;
    let rootLabelSize = (8 / inintRadius) * r;
    if (rootLabelSize > 12) {
      rootLabelSize = 12;
    }
    // @ts-ignore
    handledData![0].label.fontSize = rootLabelSize;

    let symbolSize = 5;
    const series = [{
      name: 'name',
      type: 'tree',
      layout: 'radial',
      // width: 'auto',
      // height: 'auto',
      scaleLimit: {
        min: 0.5,
        max: 10,
      },
      roam,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      expandAndCollapse: collapse,
      initialTreeDepth: -1,
      symbolSize,
      data: handledData,
      symbol: 'circle',
      label: {
        distance: 2,
        show: label,
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
      },
      itemStyle: {
        color: '#fff',
        borderColor: 'rgba(13, 59, 102, 1)',
        borderWidth: 1,
      },
      lineStyle: {
        curveness: 0.5,
        color: 'rgba(137, 175, 210, 1)',
        width: 0.5,
      },
      leaves: {
        label: {
          // padding: [10, 0],
          // fontSize: 5,
        },
      },
    }] as TreeSeriesOption[];
    // 最外层最长的一个标签
    const longestName = depthestChildren!.reduce((prev, item) => (
      item?.name?.length > prev?.name?.length ? item : prev
    ))?.name;
    let lengthOfTheName = getWordsWidth(longestName, {
      font: `${series?.[0]!.label!.fontSize}px sans-serif`,
    }) + (series?.[0]?.label?.distance || 0) + (symbolSize / 2);
    // 思路， 最长label的边界触及到 包围块的最大内切圆
    // to do 可能不够精确
    let shirnkSize = (r - lengthOfTheName) * 2 * 100;

    const smallestLabelFontSize = 1;

    while (shirnkSize <= 10000) { // 最小直径为100 当前容器的尺寸已经不足以容纳该图形了
      // @ts-ignore
      if (series?.[0]?.label?.fontSize !== smallestLabelFontSize) {
        // @ts-ignore
        series[0].label.fontSize -= 1;
      }
      // @ts-ignore
      if (symbolSize > 1 && (series?.[0]?.label?.fontSize === smallestLabelFontSize)) {
        symbolSize -= 1;
        // @ts-ignore
        series[0].symbolSize = symbolSize;
      }
      if (series?.[0]?.label?.distance !== 1 && symbolSize <= 3) {
        // @ts-ignore
        series[0].label.distance -= 1;
      }
      lengthOfTheName = getWordsWidth(longestName, {
        font: `${series?.[0]!.label!.fontSize}px sans-serif`,
      }) + (series?.[0]?.label?.distance || 0) + (symbolSize / 2);

      shirnkSize = (r - (lengthOfTheName)) * 2 * 100;
      if (series?.[0]?.label?.fontSize === smallestLabelFontSize && (
        // @ts-ignore
        series[0].label.distance === 1 && symbolSize === 1
      )) { // 这回真没救了
        break;
      }
    }
    if (conWidth < conHeight) {
      series[0].width = `${shirnkSize / conWidth}%`;
      series[0].left = lengthOfTheName;
    } else {
      // console.log(shirnkSize);
      // shirnkSize = 17700;
      series[0].height = `${shirnkSize / conHeight}%`;
      series[0].top = lengthOfTheName;
    }

    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };

    if (fontSize === 'large') {
      handledData[0].label.fontSize = 16;
      // @ts-ignore
      options.series[0] = {
        name: 'name',
        type: 'tree',
        layout: 'radial',
        // width: 'auto',
        // height: 'auto',
        roam,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        expandAndCollapse: collapse,
        initialTreeDepth: -1,
        scaleLimit: {
          min: 0.5,
          max: 10,
        },
        symbolSize,
        data: handledData,
        symbol: 'circle',
        label: {
          distance: 2,
          show: label,
          color: curStyle.labelColor,
          fontSize: curFont.labelFontSize,
        },
        itemStyle: {
          color: '#fff',
          borderColor: 'rgba(13, 59, 102, 1)',
          borderWidth: 1,
        },
        lineStyle: {
          curveness: 0.5,
          color: 'rgba(137, 175, 210, 1)',
          width: 0.5,
        },
        leaves: {
          label: {
            // padding: [10, 0],
            // fontSize: 5,
          },
        },
      };
    }
    setOption(options);
  }, [hanldeData, fontSize, label, legend, mode, title, data]);

  useEffect(() => {
    if (!bigChartRef.current) {
      return;
    }
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize.large;

    const handledData = data ? [data] : [];
    if (!handledData.length) {
      return;
    }
    // console.log(handledData);
    function getDepthestChild(root: TreeSeriesOption['data']) {
      if (!root) {
        return [];
      }
      let depth = 0;
      const children: any[] = [];
      const colorMap = new Map();
      const hightLightColor = curStyle.highLightColor;
      const hL = hightLightColor.length;
      let i = 0;
      function dfs(node: any, curDepth = 0, childIndex?: number, childrenArr?: any) {
        if (curDepth > depth) {
          depth = curDepth;
          children.length = 0;
        }
        if (curDepth === depth && !node?.children?.length) {
          children.push(node);
        }
        if (highLightSomeLabel) {
          if (node?.need_high_light === 1) {
            let color = colorMap.get(node?.type);
            if (!color) {
              color = hightLightColor[i % hL];
              i += 1;
              colorMap.set(node?.type, color);
            }
            childrenArr[childIndex as number] = {
              ...node,
              itemStyle: {
                color,
              },
              label: {
                color,
              },
            };
          }
        }
        if (node.isGray === false) {
          childrenArr[childIndex as number] = {
            ...node,
            itemStyle: {
              color: '#D2D2D2',
            },
            label: {
              color: '#D2D2D2',
            },
          };
        }
        // eslint-disable-next-line no-unused-expressions
        node?.children?.forEach((item: any, index: number, arr: any) => {
          dfs(item, curDepth + 1, index, arr);
        });
      }
      // 找寻最深节点 顺便处理高亮
      dfs(root![0]);
      return children;
    }
    const depthestChildren = getDepthestChild(handledData);
    handledData![0] = {
      ...handledData![0],
      label: {
        fontSize: 16,
        backgroundColor: 'rgba(163, 194, 210, 0.8)',
        color: '#333333',
        padding: 4,
      },
      symbolSize: 10,
      itemStyle: {
        color: '#89AFD2',
      },
    };
    // eslint-disable-next-line no-unused-expressions
    handledData![0].children?.forEach((node: any) => {
      node.lineStyle = {
        width: 1,
      };
    });

    // 单节点
    if (!data?.children?.length) {
      handledData![0]!.itemStyle = {
        lineStyle: {
          width: 10,
        },
      };
      handledData![0].children = [{
        itemStyle: {
          opacity: 0,
        },
        lineStyle: {
          width: 0,
        },
      }];
    }

    const ins = bigChartRef.current.getEchartsInstance();
    const conWidth = ins.getWidth();
    const conHeight = ins.getHeight();
    const r = Math.min(conWidth, conHeight) / 2;

    const inintRadius = 320 / 2;
    let rootLabelSize = (8 / inintRadius) * r;
    if (rootLabelSize > 12) {
      rootLabelSize = 12;
    }
    // @ts-ignore
    handledData![0].label.fontSize = rootLabelSize;

    let symbolSize = 5;
    const series = [{
      name: 'name',
      type: 'tree',
      layout: 'radial',
      // width: 'auto',
      // height: 'auto',
      scaleLimit: {
        min: 0.5,
        max: 10,
      },
      roam,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      expandAndCollapse: collapse,
      initialTreeDepth: -1,
      symbolSize,
      data: handledData,
      symbol: 'circle',
      label: {
        distance: 2,
        show: label,
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
      },
      itemStyle: {
        color: '#fff',
        borderColor: 'rgba(13, 59, 102, 1)',
        borderWidth: 1,
      },
      lineStyle: {
        curveness: 0.5,
        color: 'rgba(137, 175, 210, 1)',
        width: 0.5,
      },
      leaves: {
        label: {
          // padding: [10, 0],
          // fontSize: 5,
        },
      },
    }] as TreeSeriesOption[];
    // 最外层最长的一个标签
    const longestName = depthestChildren!.reduce((prev, item) => (
      item?.name?.length > prev?.name?.length ? item : prev
    ))?.name;
    let lengthOfTheName = getWordsWidth(longestName, {
      font: `${series?.[0]!.label!.fontSize}px sans-serif`,
    }) + (series?.[0]?.label?.distance || 0) + (symbolSize / 2);
    // 思路， 最长label的边界触及到 包围块的最大内切圆
    // to do 可能不够精确
    let shirnkSize = (r - lengthOfTheName) * 2 * 100;

    const smallestLabelFontSize = 1;

    while (shirnkSize <= 10000) { // 最小直径为100 当前容器的尺寸已经不足以容纳该图形了
      // @ts-ignore
      if (series?.[0]?.label?.fontSize !== smallestLabelFontSize) {
        // @ts-ignore
        series[0].label.fontSize -= 1;
      }
      // @ts-ignore
      if (symbolSize > 1 && (series?.[0]?.label?.fontSize === smallestLabelFontSize)) {
        symbolSize -= 1;
        // @ts-ignore
        series[0].symbolSize = symbolSize;
      }
      if (series?.[0]?.label?.distance !== 1 && symbolSize <= 3) {
        // @ts-ignore
        series[0].label.distance -= 1;
      }
      lengthOfTheName = getWordsWidth(longestName, {
        font: `${series?.[0]!.label!.fontSize}px sans-serif`,
      }) + (series?.[0]?.label?.distance || 0) + (symbolSize / 2);

      shirnkSize = (r - (lengthOfTheName)) * 2 * 100;
      if (series?.[0]?.label?.fontSize === smallestLabelFontSize && (
        // @ts-ignore
        series[0].label.distance === 1 && symbolSize === 1
      )) { // 这回真没救了
        break;
      }
    }
    if (conWidth < conHeight) {
      series[0].width = `${shirnkSize / conWidth}%`;
      series[0].left = lengthOfTheName;
    } else {
      // console.log(shirnkSize);
      // shirnkSize = 17700;
      series[0].height = `${shirnkSize / conHeight}%`;
      series[0].top = lengthOfTheName;
    }

    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };
    setBigChart(options);
  }, [hanldeData, fontSize, label, legend, mode, title, data]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = industryChain ? bigChartRef.current.getEchartsInstance()
        : chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      const instance = industryChain ? bigChartRef.current.getEchartsInstance()
        : chartRef.current.getEchartsInstance();
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
      <div className={styleBigChart} style={{ display: industryChain ? '' : 'none' }}>
        <ReactEcharts
          option={bigChart}
          notMerge
          style={{ width: '100%', height: '100%' }}
          ref={bigChartRef}
        />
      </div>
    </div>);
});

export default RadialTreeChart;

RadialTreeChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'small',
};
