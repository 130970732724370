import React, { useCallback, useState, useRef } from 'react';
import { message, Select } from 'antd';
import styles from './index.module.less';
import ReportCard from '../../../../components/ReportCard';
import ReportModal from '../../../../components/ReportModal';
import {
  useGetSystemReportCategoryQuery,
  useGetCitySystemReportListQuery,
} from '../../../../service/find-system-report';
import { openNewPage } from '../../../../utils/urlMethods';
import { judgeArea } from '../../../../utils/tool';
import {
  getCompnayId,
  resetTemplateReport,
  getRegionName,
} from '../../../../api/reportGeneration';

const { Option } = Select;

const DEFAULT_SCENES = [{ title: '全部', value: 'all' }];

function ReportScheme(props) {
  const { cityCode, cityName } = props;
  const { data: scenes = DEFAULT_SCENES } = useGetSystemReportCategoryQuery();
  const [selectedScene, setSelectedScene] = useState('all');
  const [choseModel, setChoseModel] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const choseTemplateMsg = useRef(null);
  const [choseLoading, setChoseLoading] = useState(false);
  const {
    data: reports = [],
  } = useGetCitySystemReportListQuery({
    area_key: cityCode,
    category_name: selectedScene,
  }, {
    skip: !cityCode,
  });

  // 打开报告预览页
  const openNewReportPage = useCallback((curTemplateId, data, conditions) => {
    let obj;
    switch (curTemplateId) {
      case '16':
      case '23':
        obj = window.btoa(window.encodeURIComponent(JSON.stringify({
          curTemplateId,
          title: choseTemplateMsg.current.template_name,
          id: data,
          area: conditions.cityName,
          areaKey: conditions.cityValue,
        })));
        break;
      case '17':
      case '22':
      case '492':
      case '493':
      case '494':
      case '495':
      case '496':
      case '497':
      case '500':
        obj = window.btoa(window.encodeURIComponent(JSON.stringify({
          curTemplateId,
          title: choseTemplateMsg.current.template_name,
          id: data,
          area: conditions.cityName,
          industry: conditions.industryName,
          areaKey: conditions.cityValue,
          industryKey: conditions.industryValue,
        })));
        break;
      case '18':
        obj = window.btoa(window.encodeURIComponent(JSON.stringify({
          curTemplateId,
          title: choseTemplateMsg.current.template_name,
          id: data,
          name: conditions.companyName,
        })));
        break;
      case '498':
      case '499':
        obj = window.btoa(window.encodeURIComponent(JSON.stringify({
          curTemplateId,
          title: choseTemplateMsg.current.template_name,
          id: data,
          industry: conditions.industryName,
          industryKey: conditions.industryValue,
        })));
        break;
      default: break;
    }
    openNewPage('reportPreview', {
      msg: obj,
    });
  }, []);

  // 各种模板-复用模板逻辑
  const analysisReportConditionChange = async (curTemplateId, conditions) => {
    setChoseLoading(true);
    let companymsg = '';
    if (curTemplateId === '18') {
      await getCompnayId({
        company_name: conditions.companyName,
      }).then(res => {
        if (res.status === '00000') {
          companymsg = res.data;
        }
        if (res.status === 'c0401') {
          companymsg = 'error';
          message.error(res.message);
          setChoseLoading(false);
        }
      });
    }
    if (conditions.cityValue) {
      getRegionName({
        code: conditions.cityValue?.[0],
        duplicate: true,
        separator: '',
      }).then(res => {
        if (res.status === '00000') {
          conditions.cityName = res.data;
        }
      });
    }
    if (companymsg === 'error') return;
    let params = {
      template_id: curTemplateId,
    };
    switch (curTemplateId) {
      case '16,23':
        params = {
          template_id: !conditions.is_province_directly ? judgeArea(conditions.cityValue, params.template_id) : '16',
          area_key_list: conditions.cityValue,
        };
        break;
      case '17,22':
      case '493':
      case '495,494':
      case '497,496':
        params = {
          template_id: judgeArea(conditions.cityValue[0], params.template_id),
          area_key_list: conditions.cityValue[0],
          industry_code_list: conditions.industryValue,
        };
        break;
      case '18':
        params = {
          ...params,
          company_info_dict: companymsg || {},
        };
        break;
      case '498':
      case '499':
        params = {
          ...params,
          industry_code_list: conditions.industryValue,
        };
        break;
      default: break;
    }
    resetTemplateReport(params).then(res => {
      if (res.status === '00000') {
        setChoseLoading(false);
        openNewReportPage(params.template_id, res.data, conditions);
        setChoseModel(false);
      } else {
        setChoseLoading(false);
        message.error(res.message);
      }
    }).catch(() => {
      setChoseLoading(false);
    });
  };

  const onReportClick = useCallback(report => {
    const { type } = report;
    if (type === '精品报告') {
      const { id, Buy_state: isApply, price, cover_url: coverImg, title, sub_title: subTitle } = report;
      openNewPage('boutiqueDetail', {
        msg: window.btoa(window.encodeURIComponent(JSON.stringify({
          title,
          subTitle,
          isApply,
          price,
          coverImg,
          id,
        }))),
      });
    } else {
      choseTemplateMsg.current = report;
      setTemplateId(report.id);
      setChoseModel(true);
    }
  }, []);

  return (
    <div className={styles.reportScheme}>
      <div className={styles.selector}>
        <div className={styles.leading}>
          类别：
        </div>
        <Select defaultValue="all" value={selectedScene} style={{ width: 300 }} onChange={val => setSelectedScene(val)}>
          {
            Array.isArray(scenes) && scenes.map((item, index) => (
              <Option value={item.value} key={index}>{item.title}</Option>
            ))
          }
        </Select>
      </div>
      <div className={styles.reports}>
        {
          reports?.map(report => {
            const {
              id,
              template_name: templateName,
              title,
              download_the_number: downloadCount = 0,
              read_the_number: readCount = 0,
              template_type: tag,
              cover_url: coverURL,
              author,
              date,
            } = report;
            return (
              <ReportCard
                title={title ?? templateName}
                key={id}
                tag={tag}
                author={author}
                date={date}
                readCount={readCount}
                downloadCount={downloadCount}
                className={styles.card}
                onClick={() => onReportClick(report)}
                coverId={id}
                coverURL={coverURL}
                coverTitle="新能源汽车"
                coverSubtitle={templateName}
                coverArea={cityName}
              />
            );
          })
        }
      </div>
      {choseModel && (
        <ReportModal
          cityCode={cityCode}
          cityName={cityName}
          templateId={templateId}
          visible={choseModel}
          setVisible={setChoseModel}
          startAnalysis={analysisReportConditionChange}
          loading={choseLoading}
        />
      )}
    </div>
  );
}

export default ReportScheme;
