import { useMemo, useEffect } from 'react';

const ReportDownLoad = (props) => {
  const { href } = window.location;
  useEffect(() => {
    const params = new URLSearchParams(href.split('?')[1]);
    const reportUrl = window.decodeURIComponent(window.atob(params.get('url') || ''));
    const name = window.decodeURIComponent(window.atob(params.get('name') || ''));
    // // 创建一个下载链接
    const link = document.createElement('a');
    link.href = reportUrl;
    link.download = name; // 设置下载文件名
    // 将链接添加到DOM中并模拟点击下载
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);
  return null;
};

export default ReportDownLoad;
