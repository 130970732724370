import { useEffect, useState } from 'react';
import {
  useLazyPotentialRiskQuery,
} from '../../../../../../service/cityTool';

const useGetData = (reqData, type) => {
  const [data, setData] = useState({});
  const [chartIsFetching, setChartIsFetching] = useState(true);
  const {
    area_key: areaKey,
    sort_key: sortKey,
    industry_code_list: industryCodeList,
    sort_value: sortValue,
    page_number: page,
    select_type_dict: selectTypeDict,
  } = reqData;
  const [potentialRisk] = useLazyPotentialRiskQuery();
  const getTableList = () => potentialRisk(reqData);
  useEffect(() => {
    setChartIsFetching(true);
    const time = setTimeout(() => {
      if (type && areaKey) {
        getTableList().then(res => {
          setChartIsFetching(false);
          setData(res.data);
        }).catch(err => {
          setData({});
        });
      }
    }, 5);
    return () => { clearInterval(time); };
  }, [areaKey, sortKey, industryCodeList, selectTypeDict, sortValue, page, type]);
  return {
    data,
    chartIsFetching,
  };
};

export { useGetData };
