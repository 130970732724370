/*
 *Author: zhaochenyu
 *Description: 我的-公司标签表格
 *Date: 2021/12/27
*/

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Pagination, Table, Input, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { updateUserTags } from '../../../api/companyDetail';
import { openNewPage } from '../../../utils/urlMethods';
import style from './index.module.less';

const { TextArea } = Input;

function LabelEdit(props) {
  const { text, record, getLabelTable, pagination } = props;
  const [edit, setEdit] = useState(false);
  const [curText, setCurText] = useState('');
  const labelTableBody = useRef(null);
  const timer = useRef(null);

  const textAreaChange = (p) => {
    message.destroy();
    const arr = p.target.value.split('#').filter(n => n).map(n => n.trimEnd().trimStart());
    let isMoreLen = false;
    arr.forEach(item => {
      if (item.length > 20) {
        isMoreLen = true;
      }
    });
    if (isMoreLen) {
      message.info('每个标签应限制在20字以内！');
      return;
    }
    setCurText(p.target.value);
  };

  const handleChangeCompnayLabel = useCallback(
    (word, oldWord) => {
      if (oldWord === word) {
        setEdit(false);
      } else {
        updateUserTags({
          company_id: record.id,
          tag: word,
        }).then(res => {
          if (res.status === '00000') {
            message.success('更新成功!');
            getLabelTable(pagination);
            setEdit(false);
          }
        });
      }
    },
    [text, record],
  );

  const clickEvent = (e) => {
    let { parentNode } = e.target;
    while (parentNode !== null) {
      if (parentNode === labelTableBody.current) {
        break;
      }
      parentNode = parentNode.parentNode;
    }
    if (!parentNode) {
      setEdit(false);
      // handleChangeCompnayLabel(word, oldWord);
    }
  };

  const clickTimer = (e, word) => {
    if (timer) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      clickEvent(e, word);
    }, 50);
  };

  useEffect(() => {
    // document.addEventListener('click', (e) => clickTimer(e, curText, text));
    document.addEventListener('click', clickTimer);
    return () => {
      document.removeEventListener('click', clickTimer);
      clearTimeout(timer.current);
    };
  }, [curText, text]);

  useEffect(() => {
    if (edit) setCurText(text);
  }, [edit]);
  return (
    <div style={{ width: '100%' }} ref={labelTableBody}>
      {edit ? (
        <div className={style.textArea}>
          <TextArea
            autoSize
            // allowClear
            value={curText}
            placeholder="请输入标签内容，每个标签文字限制20字以内，并以 # 字符号作为标签区分。例 #北京老大哥企业 #曾被标注上万次企业 #榜样企业"
            onChange={(e) => textAreaChange(e)}
          />
          <div className={style.btn} style={{ visibility: edit ? 'visible' : 'hidden' }}>
            <CheckOutlined onClick={() => handleChangeCompnayLabel(curText)} />
            <CloseOutlined onClick={() => setEdit(false)} />
          </div>
        </div>
      ) : (
        <div className={style.editLabel} onClick={() => setEdit(true)}>{text}</div>
      )}
    </div>
  );
}

function CompnayLabelTable(props) {
  const { data: { tableHead, tableBody, count }, pagination, getLabelTable } = props;

  // 跳转到企业详情
  const pushToCompnay = useCallback(
    (rowData) => {
      const { id } = rowData;
      if (id) {
        openNewPage('companyDetail', {
          id,
        });
      }
    },
    [],
  );

  const handleWithTableHead = useCallback(
    (col) => {
      const newColumn = col.map(item => {
        let res;
        if (item.dataIndex === 'company_name') {
          res = {
            ...item,
            width: '25%',
            render: (text, record) => (
              <div className={style.pushToDetail} onClick={() => pushToCompnay(record)}>
                {text}
              </div>
            ),
          };
        } else if (item.dataIndex === 'tag') {
          res = {
            ...item,
            width: '52%',
            render: (text, record) => (
              <LabelEdit
                text={text}
                record={record}
                pagination={pagination}
                getLabelTable={getLabelTable}
              />
            ),
          };
        } else {
          res = {
            ...item,
            ellipsis: true,
          };
        }
        return res;
      });
      return newColumn;
    },
    [],
  );

  return (
    <div className={style.all}>
      <Table
        rowKey={record => record.id}
        columns={handleWithTableHead(tableHead)}
        dataSource={tableBody}
        pagination={false}
      />
      <Pagination
        className={style.pagination}
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={count}
        pageSizeOptions={[10]}
        showQuickJumper
        hideOnSinglePage
        onChange={(page, pageSize) => {
          getLabelTable({ current: page, pageSize });
        }}
      />
    </div>
  );
}

export default CompnayLabelTable;
