import moment from 'moment';

const phoneRegex = /^1[3-9]\d{9}$/;
const numberRegex = /^\d+(\.\d+)?$/;
const currentYear = moment().year();
// 获取近三年的年份
const threeYearsAgo = currentYear - 3;
const twoYearsAgo = currentYear - 2;
const lastYear = currentYear - 1;

const department = [
  { value: '制造业核心基础零部件', label: '制造业核心基础零部件' },
  { value: '元器件', label: '元器件' },
  { value: '关键软件', label: '关键软件' },
  { value: '先进基础工艺', label: '先进基础工艺' },
  { value: '关键基础材料', label: '关键基础材料' },
  { value: '产业技术基础', label: '产业技术基础' },
  { value: '无', label: '无' },
];

const strategicPlanning = [
  {
    value: '新一代信息技术',
    label: '新一代信息技术',
  },
  {
    value: '高档数控机床和机器人',
    label: '高档数控机床和机器人',
  },
  {
    value: '航空航天装备',
    label: '航空航天装备',
  },
  {
    value: '海洋工程装备及技术船舶',
    label: '海洋工程装备及技术船舶',
  },
  {
    value: '先进轨道交通装备',
    label: '先进轨道交通装备',
  },
  {
    value: '节能与新能源汽车',
    label: '节能与新能源汽车',
  },
  {
    value: '电力装备',
    label: '电力装备',
  },
  {
    value: '新材料',
    label: '新材料',
  },
  {
    value: '生物医药及高性能医疗器械',
    label: '生物医药及高性能医疗器械',
  },
  {
    value: '农业机械装备',
    label: '农业机械装备',
  },
  {
    value: '无',
    label: '无',
  },
];

const network = [
  {
    value: '信息基础设施',
    label: '信息基础设施',
  },
  {
    value: '关键核心技术',
    label: '关键核心技术',
  },
  {
    value: '网络安全',
    label: '网络安全',
  },
  {
    value: '数据安全领域',
    label: '数据安全领域',
  },
  {
    value: '无',
    label: '无',
  },
];

const rms = [
  { label: 'OA', value: 'OA' },
  { label: 'MES', value: 'MES' },
  { label: 'ERP', value: 'ERP' },
  { label: 'CRM', value: 'CRM' },
  { label: 'PLM', value: 'PLM' },
  { label: 'SRM', value: 'SRM' },
  { label: 'OMS', value: 'OMS' },
  { label: 'CMP', value: 'CMP' },
  { label: 'MOM', value: 'MOM' },
  { label: 'WMS', value: 'WMS' },
  { label: '其他', value: '其他' },
  { label: '无', value: '无' },
];
const ISO = [
  { label: 'ISO9000 质量管理体系认证', value: 'ISO9000 质量管理体系认证' },
  { label: 'ISO14000环境管理体系认证', value: 'ISO14000环境管理体系认证' },
  { label: 'OHSA18000职业安全健康管理体系认', value: 'OHSA18000职业安全健康管理体系认' },
  { label: '其他', value: '其他' },
  { label: '无', value: '无' },
];
const securityCertificate = [
  { label: 'UL认证', value: 'UL认证' },
  { label: 'CSA认证', value: 'CSA认证' },
  { label: 'ETL认证', value: 'ETL认证' },
  { label: 'GS认证', value: 'GS认证' },
  { label: '其他', value: '其他' },
  { label: '无', value: '无' },
];
const level = [
  { label: '国家级', value: '国家级' },
  { label: '省级', value: '省级' },
  { label: '自建', value: '自建' },
  { label: '无', value: '无' },
];

export const allForm = [
  {
    title: '基本信息',
    value: 'basicInfo',
    list: [
      { name: 'companyName', label: '企业全称 :', type: 'associativeSearch', list: 'companyNameList', placeholder: '请输入', required: true },
      { name: 'contact', label: '联系人', type: 'borderInput', placeholder: '请输入' },
      { name: 'phone', label: '联系电话', type: 'borderInput', placeholder: '请输入', pattern: phoneRegex, message: '请输入正确的手机号' },
      { name: 'socialSecurityNumber', label: '员工社保人数', type: 'borderInput', placeholder: '请输入', pattern: numberRegex, message: '请输入数字' },
    ],
  },
  {
    title: '专业化指标',
    value: 'professionalization',
    list: [
      { name: 'major', label: '深耕于产业链某一环节或某一产品', type: 'multipleChoice', required: true, list: [{ title: '是', value: 'true' }, { title: '否', value: 'false' }] },
      { name: 'majorTime', label: '企业从事特定细分市场时间', type: 'noBorderInput', unit: '年', required: true, standard: 3, pattern: numberRegex, message: '请输入数字年份' },
      { name: 'proportion', label: '主营业务收入占营收比重', type: 'noBorderInput', unit: '%', required: true, standard: 70, pattern: numberRegex, message: '请输入数字' },
      { name: 'growthRate', label: '近2年主营业务收入平均增长率', type: 'noBorderInput', unit: '%', required: true, standard: 5, pattern: numberRegex, message: '请输入数字' },
    ],
  },
  {
    title: '精细化指标',
    value: 'fine',
    list: [
      { name: 'growthRate', label: '上年度资产负债率', type: 'noBorderInput', unit: '%', required: true, standard: 70, pattern: numberRegex, message: '请输入数字年份' },
      { name: 'rms', label: '核心业务采用信息系统支撑情况(多选)', type: 'multiSelect', list: rms, column: true, required: true, message: '请选择' },
      { name: 'ISO', label: '获得管理体系认证名称(多选)', type: 'multiSelect', list: ISO, column: true, required: true, message: '请选择' },
      { name: 'securityCertificate', label: '获得发达国家或地区权威机构认证名称(多选)', type: 'multiSelect', list: securityCertificate, column: true, required: true, message: '请选择' },
    ],
  },
  {
    title: '特色化指标',
    value: 'feature',
    list: [
      { name: 'internationalProportion', label: '主导产品国际细分市场占有率', type: 'noBorderInput', unit: '%', required: true, standard: 10, pattern: numberRegex, message: '请输入数字' },
      { name: 'domesticShare', label: '国内市场占有率', type: 'noBorderInput', unit: '%', pattern: numberRegex, message: '请输入数字' },
      { name: 'export', label: '主导产品出口额', type: 'noBorderInput', unit: '万元', pattern: numberRegex, message: '请输入数字' },
      { name: 'numberBrands', label: '企业自有品牌个数', type: 'noBorderInput', unit: '个', required: true, pattern: numberRegex, message: '请输入数字' },
      { name: 'income', label: '企业自有品牌销售收入', type: 'noBorderInput', unit: '万元', pattern: numberRegex, message: '请输入数字' },
    ],
  },
  {
    title: '创新能力',
    value: 'innovate',
    level: 2,
    required: true,
    list: [
      {
        title: '研究机构',
        value: 'researchInstitute',
        rect: true,
        list: [
          { name: 'academy', label: '技术研究院', type: 'multiSelect', list: level, required: true, message: '请选择' },
          { name: 'technology', label: '技术工程中心', type: 'multiSelect', list: level, required: true, message: '请选择' },
          { name: 'expert', label: '院士专家工作站', type: 'multipleChoice', noBold: true, list: [{ title: '有', value: 'true' }, { title: '无', value: 'false' }], required: true, message: '请选择' },
          { name: 'doctor', label: '博士工作站', type: 'multipleChoice', noBold: true, list: [{ title: '有', value: 'true' }, { title: '无', value: 'false' }], required: true, message: '请选择' },
        ],
      },
      {
        title: '研发费用',
        value: 'studyShare',
        rect: true,
        list: [
          { name: 'threeYearsAgo', label: `${threeYearsAgo}年`, type: 'noBorderInput', unit: '万元', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'twoYearsAgo', label: `${twoYearsAgo}年`, type: 'noBorderInput', unit: '万元', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'lastYear', label: `${lastYear}年`, type: 'noBorderInput', unit: '万元', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
        ],
      },
      {
        title: '研发费用占比',
        value: 'studyShareRatio',
        rect: true,
        list: [
          { name: 'threeYearsAgo', label: `${threeYearsAgo}年`, type: 'noBorderInput', unit: '%', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'twoYearsAgo', label: `${twoYearsAgo}年`, type: 'noBorderInput', unit: '%', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'lastYear', label: `${lastYear}年`, type: 'noBorderInput', unit: '%', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
        ],
      },
      {
        title: '研发人员占比',
        value: 'studyPersonnelRatio',
        rect: true,
        list: [
          { name: 'threeYearsAgo', label: `${threeYearsAgo}年`, type: 'noBorderInput', unit: '%', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'twoYearsAgo', label: `${twoYearsAgo}年`, type: 'noBorderInput', unit: '%', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'lastYear', label: `${lastYear}年`, type: 'noBorderInput', unit: '%', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
        ],
      },
      {
        title: '营业收入',
        value: 'revenue',
        rect: true,
        list: [
          { name: 'threeYearsAgo', label: `${threeYearsAgo}年`, type: 'noBorderInput', unit: '万元', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'twoYearsAgo', label: `${twoYearsAgo}年`, type: 'noBorderInput', unit: '万元', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'lastYear', label: `${lastYear}年`, type: 'noBorderInput', unit: '万元', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
        ],
      },
      {
        title: '股权融资',
        value: 'financing',
        rect: true,
        list: [
          { name: 'twoYearsAgo', label: '近2年新增股权融资总额', type: 'noBorderInput', unit: '万元', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
        ],
      },
      {
        title: 'I类知识产权',
        value: 'intellectualProperty',
        rect: true,
        list: [
          { name: 'patent', label: '发明专利', type: 'noBorderInput', unit: '项', disabled: true, required: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'newPlantVarieties', label: '植物新品种', type: 'noBorderInput', unit: '项', disabled: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'newCrop', label: '国家级农作物品种', type: 'noBorderInput', unit: '项', disabled: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'newDrug', label: '国家新药', type: 'noBorderInput', unit: '项', disabled: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'integratedCircuit', label: '集成电路布图设计专有权', type: 'noBorderInput', unit: '项', disabled: true, pattern: numberRegex, message: '请输入数字' },
          { name: 'awards', label: '国家级科技奖励', type: 'noBorderInput', unit: '项', disabled: true, pattern: numberRegex, message: '请输入数字' },
        ],
      },
      {
        title: '国家级科技奖励，排名前三',
        value: 'top3',
        disabled: true,
        rect: true,
        list: [
          { name: 'top3', label: '国家级科技奖励，排名前三', type: 'multipleChoice', required: true, list: [{ title: '是', value: 'true' }, { title: '否', value: 'false' }] },
        ],
      },
      {
        title: '创客中国”全国大赛50强',
        value: 'top50',
        disabled: true,
        list: [
          { name: 'top50', label: '创客中国”全国大赛50强', type: 'multipleChoice', required: true, list: [{ title: '是', value: 'true' }, { title: '否', value: 'false' }] },
        ],
      },
    ],
  },
  {
    title: '产业链配套',
    value: 'industryChain',
    list: [
      { name: 'industry', label: '所属产业链', type: 'treeSelect', list: 'industryTree', placeholder: '请选择', required: true },
      { name: 'valid', label: '位于产业链关键环节，发挥“补短板”“锻长板”“填空白”等重要作用。', type: 'multipleChoice', noBold: true, list: [{ title: '是', value: 'true' }, { title: '否', value: 'false' }], required: true, message: '请选择' },
      { name: 'mating', label: '知名大企业配套。', type: 'multipleChoice', noBold: true, list: [{ title: '是', value: 'true' }, { title: '否', value: 'false' }] },
    ],
  },
  {
    title: '主导产品所属领域',
    value: 'territory',
    border: true,
    list: [
      { name: 'name', label: '主导产品名称：', type: 'noBorderInput', width: 180, required: true, message: '请输入' },
      { name: 'department', label: '主导产品所属领域', type: 'select', list: department, unit: '', required: true, placeholder: '请选择', message: '请输入' },
      { name: 'strategicPlanning', label: '主导产品所属制造强国战略十大重点产业领域', list: strategicPlanning, type: 'select', unit: '', required: true, placeholder: '请选择', message: '请输入' },
      { name: 'network', label: '主导产品所属网络强国建设', type: 'select', list: network, unit: '', required: true, placeholder: '请选择', message: '请输入' },
    ],
  },
];
