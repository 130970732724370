interface ImageComponentProps {
  src?: string;
  alt?: string;
  className?: string;
  style?: CSSPageRule;
}

/**
 * 报告用 图片组件，如果没有提供 src，应该默认提示请设置图片，
 * 如果提供 src 图片，那么应该读取图片并计算其大小设置初始 size
 * @param props
 * @constructor
 */
export default function ImageComponent(props: ImageComponentProps) {
  const {
    src,
    alt,
    className,
    style,
  } = props;

  return (
    <div style={{ minWidth: 10, minHeight: 10, ...style }} className={className}>
      <img
        src={src}
        alt={alt}
        style={{ width: '100%', height: '100%', objectPosition: 'center' }}
      />
    </div>
  )
}
