import './d3.min.js';
import arrow from '../assets/images/arrow.png';

function drawThroughChart(root, rootName, data, width, height) {
  let _this = this;
  let rootRectWidth = 0; //根节点rect的宽度
  // 冲上冲下
  let forUpward = true;

  const treeChart = function (curd3) {
    this.d3 = curd3;
    this.directions = ['upward', 'downward'];
  };

  treeChart.prototype.drawChart = function () {
    // First get tree data for both directions.
    this.treeData = {};
    var self = this;
    self.directions.forEach(function (direction) {
      self.treeData[direction] = data[direction];
    });
    rootRectWidth = rootName.length * 25;
    self.graphTree(self.getTreeConfig());
  };

  treeChart.prototype.getTreeConfig = function () {
    var treeConfig = {
      'margin': {
        'top': 10,
        'right': 30,
        'bottom': 0,
        'left': 30
      }
    };
    treeConfig.chartWidth = (width - treeConfig.margin.right - treeConfig.margin.left);
    treeConfig.chartHeight = (height - treeConfig.margin.top - treeConfig.margin.bottom);
    treeConfig.centralHeight = treeConfig.chartHeight / 2;
    treeConfig.centralWidth = treeConfig.chartWidth / 2;
    treeConfig.linkLength = 150;
    treeConfig.duration = 500; //动画时间
    return treeConfig;
  };

  treeChart.prototype.graphTree = function (config) {
    var self = this;
    var d3 = this.d3;
    var linkLength = config.linkLength;
    var duration = config.duration;
    var hasChildNodeArr = [];
    var id = 0;

    // 生成三次三次贝塞尔曲线数据，使曲线更加平滑
    // var diagonal = d3.svg.diagonal()
    //   .source(function(d) {
    //     return {
    //       "x": d.source.x,
    //       "y": d.source.name == rootName ? (forUpward ? d.source.y -20 :d.source.y + 20) : (forUpward ? d.source.y - 60 : d.source.y + 60)
    //     };
    //   })
    //   .target(function(d) {
    //     return {
    //       "x": d.target.x,
    //       "y": d.target.y
    //     };
    //   })
    //   .projection(function(d) {
    //     return [d.x, d.y];
    //   });

    // 折线
    var diagonal = function (obj) {
      var s = obj.source;
      var t = obj.target;
      let addDistance = 0;
      if (s.direction && s.depth > 0) {
        addDistance = s.direction === 'downward' ? 40 : -40;
      }
      return "M" + (s.x) + "," + s.y + "L" + s.x + "," + (s.y + (t.y - s.y) / 2 + addDistance) + "L" + t.x + "," +
        (s.y + (t.y - s.y) / 2 + addDistance) + "L" + (t.x) + "," + t.y;
    };
    var zoom = d3.behavior.zoom()
      .scaleExtent([0.5, 2])
      .on('zoom', redraw);

    var svg = d3.select(root)
      .append('svg')
      .attr('width', config.chartWidth + config.margin.right + config.margin.left)
      .attr('height', config.chartHeight + config.margin.top + config.margin.bottom)
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .on('mousedown', disableRightClick)
      .call(zoom);
    // .on('dblclick.zoom', null);

    var treeG = svg.append('g')
      .attr('class', 'gbox')
      .attr('transform', 'translate(' + config.margin.left + ',' + config.margin.top + ')');

    // Initialize the tree nodes and update chart.
    for (var d in this.directions) {
      var direction = this.directions[d];
      var data = self.treeData[direction];
      data.x0 = config.centralWidth;
      data.y0 = config.centralHeight;
      data.children.forEach(collapse);
      update(data, data, treeG);
    }
    function update(source, originalData, g) {
      var direction = originalData['direction'];
      forUpward = direction === 'upward';
      var node_class = direction + 'Node';
      var link_class = direction + 'Link';
      var downwardSign = (forUpward) ? -1 : 1;
      var nodeColor = (forUpward) ? '#262D36' : '#262D36';

      // var isExpand = false;
      var statusUp = true;
      var statusDown = true;
      var nodeSpace = 160;
      var tree = d3.layout.tree().sort(sortByDate).nodeSize([nodeSpace, 0]);
      var nodes = tree.nodes(originalData);
      var links = tree.links(nodes);
      var offsetX = -config.centralWidth;
      nodes.forEach(function (d) {
        d.y = downwardSign * (d.depth * linkLength) + config.centralHeight;
        d.x = d.x - offsetX;
        if (d.name == rootName && d.depth === 0) {
          d.x = config.centralWidth;
          d.y += downwardSign * 0; // 上下两树图根节点之间的距离
        }
      });

      // Update the node.
      var node = g.selectAll('g.' + node_class)
        .data(nodes, function (d) {
          return d.id || (d.id = ++id);
        });

      var nodeEnter = node.enter().append('g')
        .attr('class', node_class)
        .attr('transform', function (d) {
          return 'translate(' + source.x0 + ',' + source.y0 + ')';
        })
        .style('cursor', function (d) {
          return (d.name == rootName && d.depth === 0) ? '' : (d.children || d._children) ? 'pointer' : '';
        });

      // 方形块
      nodeEnter.append("svg:rect")
        .attr("x", function (d) {
          return (d.name == rootName && d.depth === 0) ? -(rootRectWidth / 2) : -75;
        })
        .attr("y", function (d) {
          return (d.name == rootName && d.depth === 0) ? -27 : forUpward ? -75 : 0;
        })
        .attr("width", function (d) {
          return (d.name == rootName && d.depth === 0) ? rootRectWidth : 150;
        })
        .attr("height", function (d) {
          return (d.name == rootName && d.depth === 0) ? 54 : 75;
        })
        .attr("rx", 6)
        .style("stroke", function (d) {
          return (d.name == rootName && d.depth === 0) ? "#1961F5" : "#aaa";
        })
        .style("fill", function (d) {
          return (d.name == rootName && d.depth === 0) ? '#1961F5' : "#FFF";   //节点背景色
        });

      // 箭头
      nodeEnter.append('svg:image')
        .attr('x', -14)
        .attr('y', function (d) {
          return forUpward ? 15 : -25;
        })
        .attr('xlink:href', function (d) {
          return (d.name === rootName && d.depth === 0) ? '' : arrow;
        });

      // 内容第一行文本
      nodeEnter.append("text")
        .attr("class", "linkname")
        .attr("x", function (d) {
          return (d.name == rootName && d.depth === 0) ? 0 : -68;
        })
        .attr('dy', function (d) {
          return (d.name == rootName && d.depth === 0) ? '.35em' : forUpward ? -58 : 16;
        })
        .attr("text-anchor", function (d) {
          return (d.name == rootName && d.depth === 0) ? 'middle' : "start";
        })
        .attr('fill', '#000')
        .text(function (d) {
          if (d.name == rootName && d.depth === 0) {
            // return ((forUpward) ? '根节点TOP' : '根节点Bottom');
            return rootName;
          }
          if (d.repeated) {
            return '[Recurring] ' + d.name;
          }
          return (d.name.length > 10) ? d.name.substr(0, 9) : d.name;
        })
        .style({
          // 'fill-opacity': 1e-6,
          'fill': function (d) {
            if (d.name == rootName && d.depth === 0) {
              return '#fff';
            } else {
              return '#262D36';
            }
          },
          'font-size': 12,
          'cursor': "pointer",
          'letter-spacing': '1.5px',
        });

      // 内容第二行文本
      nodeEnter.append("text")
        .attr("class", "linkname")
        .attr("x", -68)
        .attr("dy", function (d) {
          return (d.name == rootName && d.depth === 0) ? '.35em' : forUpward ? -42 : 32;
        })
        .attr("text-anchor", function () {
          return (d.name == rootName && d.depth === 0) ? 'middle' : "start";
        })
        .text(function (d) {
          return (d.name === rootName && d.depth === 0) ? '' : d.name.substr(9, d.name.length);
        })
        .style({
          'fill': "#262D36",
          'font-size': 12,
          'cursor': "pointer",
          'letter-spacing': '1.5px',
        });
      // 内容第三行文本
      nodeEnter.append("text")
        .attr("x", -66)
        .attr("dy", function (d) {
          return forUpward ? -22 : 52;
        })
        .attr("text-anchor", "start")
        .attr("class", "linkname")
        .style("fill", "#919191")
        .style('font-size', 12)
        .style('letter-spacing', 1.5)
        .text(function (d) {
          return (d.name == rootName && d.depth === 0) ? '' : '认缴金额：';
        });

      // 内容第四行文本
      nodeEnter.append("text")
        .attr("x", -66)
        .attr("dy", function (d) {
          return forUpward ? -6 : 68;
        })
        .attr("text-anchor", "start")
        .attr("class", "linkname")
        .style("fill", "#919191")
        .style('font-size', 12)
        .style('letter-spacing', 1.5)
        .text(function (d) {
          return (d.name == rootName && d.depth === 0) ? '' : d.amount + "万人民币";
        });

      //   // 占股百分比
      // nodeEnter.append("text")
      //   .attr("x", 6)
      //   .attr("dy", function(d) {
      //     return forUpward ? 14 : -4;
      //   })
      //   .attr("text-anchor", "start")
      //   .attr("class", "linkname")
      //   .style("fill", "#262D36")
      //   .style('font-size', 12)
      //   .style('letter-spacing', 1.5)
      //   .text(function(d) {
      //     return(d.name == rootName) ? '' : d.ratio;
      //   });

      // Transition nodes to their new position.原有节点更新到新位置
      var nodeUpdate = node.transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + d.x + ',' + d.y + ')';
        });

      // 展开符号背景色
      nodeEnter.append('svg:rect')
        .attr("x", function (d) {
          return (d.name == rootName && d.depth === 0) ? -(rootRectWidth / 2) : -6;
        })
        .attr("y", function (d) {
          return (d.name == rootName && d.depth === 0) ? 0 : forUpward ? -88 : 76;
        })
        .attr("width", 12)
        .attr("height", 12)
        .style("fill", function (d) {
          return ((d.name === rootName && d.depth === 0) || (d._children && d._children.length > 0) || (d.children && d.children.length > 0)) ? '#1961F5' : '#fff';
        });

      //代表是否展开的+-号
      nodeEnter.append("svg:text")
        .attr("class", "isExpand")
        .attr("x", "0")
        .attr("dy", function (d) {
          return forUpward ? -77 : 87;
        })
        .attr("text-anchor", "middle")
        .style({
          "fill": '#fff',
          'font-size': 16
        })
        .text(function (d) {
          if (d.name == rootName && d.depth === 0) {
            return '';
          }
          return d.isExpend ? "-" : "+";
        })
        .on('click', click);

      var nodeExit = node.exit().transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + source.x + ',' + source.y + ')';
        })
        .remove();

      var link = g.selectAll('path.' + link_class)
        .data(links, function (d) {
          return d.target.id;
        });

      link.enter().insert('path', 'g')
        .attr('class', link_class)
        .attr('stroke', '#aaa')
        .attr('fill', "none")
        .attr('stroke-width', '1px')
        .attr('opacity', 0.5)
        .attr('d', function (d) {
          var o = {
            x: source.x0,
            y: source.y0
          };
          return diagonal({
            source: o,
            target: o
          });
        })
        .attr("id", function (d, i) {
          return "mypath" + i;
        });

      link.transition()
        .duration(duration)
        .attr('d', diagonal);

      link.exit().transition()
        .duration(duration)
        .attr('d', function (d) {
          var o = {
            x: source.x,
            y: source.y
          };
          return diagonal({
            source: o,
            target: o
          });
        })
        .remove();


      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });

      function click(d) {
        if (d.name == rootName && d.depth === 0) {
          return;
        }
        if (d.children) {
          d._children = d.children;
          d.children = null;
          d.isExpend = false;
          d3.select(this).text('+');
        } else {
          d.children = d._children;
          d._children = null;
          // expand all if it's the first node
          if (d.name == rootName && d.depth === 0) {
            d.children.forEach(expand);
          }
          d.isExpend = true;
          d3.select(this).text('-');
        }
        update(d, originalData, g);
      }
    }

    function expand(d) {
      if (d._children) {
        d.children = d._children;
        d.children.forEach(expand);
        d._children = null;
      }
    }

    function collapse(d) {
      if (d.children && d.children.length != 0) {
        d._children = d.children;
        d._children.forEach(collapse);
        d.children = null;
        hasChildNodeArr.push(d);
      }
    }

    function redraw() {
      treeG.attr('transform', 'translate(' + d3.event.translate + ')' +
        ' scale(' + d3.event.scale + ')');
    }

    function disableRightClick() {
      // stop zoom
      if (d3.event.button == 2) {
        d3.event.stopImmediatePropagation();
      }
    }

    function sortByDate(a, b) {
      var aNum = a.name.substr(a.name.lastIndexOf('(') + 1, 4);
      var bNum = b.name.substr(b.name.lastIndexOf('(') + 1, 4);
      return d3.ascending(aNum, bNum) ||
        d3.ascending(a.name, b.name) ||
        d3.ascending(a.id, b.id);
    }
  };

  var d3GenerationChart = new treeChart(d3);
  d3GenerationChart.drawChart();
}

export { drawThroughChart };