import React, { useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import Barandline from '../../../../components/Charts/BarAndLine';
import HollowPie from '../../../../components/Charts/HollowPie';
import Underline from '../Underline';
import Subtitle from '../../../../components/Subtitle';
import InnovativeTable from './InnovativeTable';
import ExportModal from '../../../../components/ExportModal';
import Input from '../../../Industry/components/Input';
import { openNewPage } from '../../../../utils/urlMethods';
import style from './index.module.less';
import { useGetParkCarrierQuery, useGetCityDetailIndustryCarrierOverlayNumberQuery } from '../../../../service/cityDetail';

const DEFAULT_TABLE_STATE = {
  pageSize: 20,
  pageNumber: 1,
  filteredInfo: {},
  sortedInfo: {},
};

function ParkCarrier({ title, cityCode }) {
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const exportFlag = Array.isArray(enterpriseAccountExport)
  && enterpriseAccountExport?.length > 0
  && enterpriseAccountExport?.includes('exportCityGraphOrg');
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState(DEFAULT_TABLE_STATE);
  const [count, setCount] = useState(0);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [params, setParams] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const { data: industryNumber } = useGetCityDetailIndustryCarrierOverlayNumberQuery({
    area_key: cityCode,
  });

  const { data: chartData, isFetching } = useGetParkCarrierQuery({
    area_key: cityCode,
  }, {
    skip: !cityCode,
  });

  return (
    <div className={style.all} id={title}>
      <div className={style.title}>{title}</div>
      <div className={style.main}>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[0]?.title}
            {chartData?.[0].subtitle && `(${chartData?.[0].subtitle})`}
          </div>
          <div className={style.chart}>
            <Barandline
              data={{
                ...chartData?.[0],
                highlight: chartData?.[0]?.highlight[0],
                leftYName: [0, 30, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[1]?.title}
            {chartData?.[1].subtitle && `(${chartData?.[1].subtitle})`}
          </div>
          <div className={style.chart}>
            <Barandline
              data={{
                ...chartData?.[1],
                highlight: chartData?.[1]?.highlight[0],
                leftYName: [0, 30, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[2]?.title}
            {chartData?.[2].subtitle && `(${chartData?.[2].subtitle})`}
          </div>
          <div className={style.chart}>
            <HollowPie data={chartData?.[2].series[0]} isFetching={isFetching} />
          </div>
        </div>
      </div>
      <div className={style.table}>
        <div className={style.header}>
          <div className={style.title}>产业载体</div>
          <Subtitle
            className={style.subtitle}
            description="共计"
            value={industryNumber?.data_count}
            unit="条,"
          />
          <Subtitle
            className={style.subtitle}
            description="覆盖"
            value={industryNumber?.industry_count}
            unit="个产业"
          />
          {
            industryNumber?.data_count !== count && <Subtitle
              className={style.subtitle}
              description="筛选结果"
              value={count}
              unit="条"
            />
          }
          <Input
            className={classNames(style.search, { [style.right]: !exportFlag })}
            value={keyword}
            placeholder="搜索载体名称、依托单位"
            onChange={e => {
              setKeyword(e.target.value);
              if (e.target.value === '') {
                setSearchKeyword('');
                setMapCompanyListQueryState({
                  ...DEFAULT_TABLE_STATE,
                  sortedInfo: mapCompanyListQueryState?.sortedInfo,
                });
              }
            }}
            onPressEnter={value => {
              setSearchKeyword(value);
              setMapCompanyListQueryState({
                ...DEFAULT_TABLE_STATE,
                sortedInfo: mapCompanyListQueryState?.sortedInfo,
              });
            }}
            onSearch={value => {
              setSearchKeyword(value);
              setMapCompanyListQueryState(DEFAULT_TABLE_STATE);
            }}
          />
          {
            exportFlag && (
              <Button className={style.exportButton} onClick={() => setExportModalVisible(true)}>数据导出</Button>
            )
          }
        </div>
        <Underline />
        <InnovativeTable
          keyword={searchKeyword}
          regionCode={cityCode}
          pageSize={mapCompanyListQueryState.pageSize}
          pageNumber={mapCompanyListQueryState.pageNumber}
          filteredInfo={mapCompanyListQueryState.filteredInfo}
          onChange={({ current, pageSize }, filter) => {
            setMapCompanyListQueryState({
              ...mapCompanyListQueryState,
              pageSize,
              pageNumber: current,
              filteredInfo: filter,
            });
          }}
          getCount={setCount}
          onCompanyClick={({ _id }) => {
            if (!_id) return;
            openNewPage('companyDetail', { id: _id });
          }}
          setParams={setParams}
        />
      </div>
      {
        exportModalVisible && (
          <div className={style.mask}>
            <ExportModal
              dataKey="find_industry_carrier_list"
              params={params}
              onClose={() => {
                setExportModalVisible(false);
              }}
            />
          </div>
        )
      }
    </div>
  );
}

export default ParkCarrier;
