import { AnyChartConfig } from '../interface/config';
import { IModelFunction, IModelMiddleware } from '../interface/base';
import { merge } from 'lodash';

export const getLegendOption: IModelFunction = (raw: AnyChartConfig) => {
  const { visualConfig } = raw;
  const {
    legend,
  } = visualConfig;

  if (!legend) {
    return {};
  }

  // 'bottomLeft, topLeft, topRight, left, right, top, bottom'
  const {
    position,
  } = legend;
  const grid : Record<string, number | string>= {
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
    orient: 'horizontal',
  };
  switch (position) {
    case 'top':
    default:
      grid.top = 0;
      grid.left = 'center';
      break;
    case 'bottom':
      grid.bottom = 0;
      grid.left = 'center';
      break;
    case 'left':
      grid.left = 0;
      grid.top = 'middle';
      grid.orient = 'vertical';
      break;
    case 'right':
      grid.left = 0;
      grid.top = 'middle';
      grid.orient = 'vertical';
      break;
  }


  return {
    legend: {
      ...grid,
      ...legend
    },
  };
}

export const legend: IModelMiddleware = (option, material) => {
  return merge(option, getLegendOption(material.config, material.data));
}
