/**
 * 堆叠柱状图
*/
import React, { useMemo, useEffect, useRef, useState } from 'react';
import { ECharts } from '@sqke/design';
import { VerticalAlignBottomOutlined, FileImageOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
// import ChartNoData from '../ChartNoData';
import style from '../public/index.module.less';

/**
 * @param {*} param0
 * @returns
 */
const StackBar = ({ data, isFetching, downloadImg, dataDownload, preview, setReportData, id, title, showLabel }) => {
  const canvasRef = useRef();
  const [options, setOptions] = useState({});
  const {
    x,
    series,
    color = ['#0ECF92', '#FFA02A', '#1961F5'],
    yName,
    yMax,
    download = false,
    leftYName = 0,
    left = 50,
    right = 40,
    bottom = 56,
    rotate = 60,
  } = data || {};

  const onChartFinished = () => {
    if (!setReportData) return;
    const img = new Image();
    // pieMyChart1 要导出的图表
    img.src = canvasRef.current.getEchartsInstance().getDataURL({
      type: 'png',
      pixelRatio: 1, // 放大2倍
      backgroundColor: '#fff',
    });
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      // 添加文字
      const text = '数据来源：上奇产业通';
      // 设置字体和大小
      ctx.font = '600 14px PingFangSC, PingFang SC';
      // 设置文本颜色
      ctx.fillStyle = '333333';
      //  设置文本
      ctx.fillText(text, canvas.width - 160, canvas.height - 5);
      const dataURL = canvas.toDataURL();
      setReportData(params => ({ ...params, [id]: { ...params[id], el: dataURL } }));
    };
  };

  useEffect(() => {
    const option = () => {
      const seriesNew = series?.map((item, index) => ({
        name: item.name,
        data: item.data,
        color: color && color[index],
        type: 'bar',
        // stack: 'total',
        emphasis: {
          focus: 'series',
        },
      }));
      return {
        xAxis: {
          type: 'category',
          axisLabel: {
            interval: 0,
            color: '#000',
            rotate: x?.length > 10 && rotate,
            fontSize: x?.length > 12 ? 12 : 14,
          },
          data: x,
        },
        label: {
          show: showLabel,
          position: 'top',
          color: '#000',
          fontSize: 12,
        },
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            let relVal = params[0].axisValueLabel;
            for (let i = 0; i < params.length; i += 1) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName}\u3000\u3000${params[i].value.toLocaleString()}${series[i].unit}`;
            }
            return relVal;
          },
        },
        toolbox: download && {
          show: true,
          feature: {
            saveAsImage: {
              type: 'png',
            },
          },
        },
        yAxis: {
          name: yName,
          type: 'value',
          max: yMax,
          nameTextStyle: {
            padding: leftYName,
          },
        },
        grid: {
          top: 28,
          right,
          bottom,
          left,
          // 坐标系不跟随内容改变
          containLabel: false,
        },
        legend: {
          show: true,
          bottom: 0,
          // itemGap: 60,
          itemHeight: 7,
          itemWidth: 12,

          textStyle: {
            color: '#000',
          },
        },
        series: seriesNew,
      };
    };
    setOptions(option);
  }, [series, showLabel]);

  useEffect(() => {
    if (canvasRef.current && options) {
      canvasRef.current.getEchartsInstance().clear();
      canvasRef.current.getEchartsInstance().setOption(options);
    }
  }, [series?.length]);

  const cheartEl = useMemo(() =>
  // if (!series || series.length > 0) {
  // eslint-disable-next-line implicit-arrow-linebreak
    (
      <div className={style.chart}>
        <ECharts
          style={{
            height: '100%',
            width: '100%',
          }}
          ref={canvasRef}
          option={options}
          onEvents={{
            finished: onChartFinished,
          }}
        />
      </div>
    ),
  // }
  // return <ChartNoData />;
  [options]);

  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {
        !preview && (
          <div className={style.tool}>
            <div onClick={dataDownload}>
              <span>
                <VerticalAlignBottomOutlined />
              </span>
              <span>下载数据</span>
            </div>
            <div onClick={() => {
              downloadImg(canvasRef, title);
            }}
            >
              <span>
                <FileImageOutlined />
              </span>
              <span>保存图片</span>
            </div>
          </div>
        )
      }
      {data && cheartEl}
    </Spin>
  );
};

export default StackBar;
