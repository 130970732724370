import React, { useState, useCallback, useEffect } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import Barandline from '../../../../components/Charts/BarAndLine';
import HollowPie from '../../../../components/Charts/HollowPie';
import StackBar from '../../../../components/Charts/StackBar';
import Treemap from '../../../../components/Charts/TreeMap';
import Underline from '../Underline';
import Subtitle from '../../../../components/Subtitle';
import ExportModal from '../../../../components/ExportModal';
import PatentTable from './PatentTable';
import ResearchUnitTable from './ResearchUnitTable';
import Input from '../../../Industry/components/Input';
import { StandardModal } from '../Modal';
import { openNewPage } from '../../../../utils/urlMethods';
import CompanyPatentList from '../../../Industry/components/CompanyPatentList';
import style from './index.module.less';
import { useGetInnovationAbilityQuery } from '../../../../service/cityDetail';

const DEFAULT_TABLE_STATE = {
  pageSize: 20,
  pageNumber: 1,
  filteredInfo: {},
  sortedInfo: {},
};

const PATENT_KEY = {
  industry_patent: undefined,
  approval_patent_count: undefined,
  possess_patent_sum: '发明',
  utility_patent_num: '实用新型',
  design_patent_num: '外观设计',
};

function InnovationAbility({ title, cityCode, patentTotal, unitTotal, isProvinceDirectly }) {
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const exportPatentFlag = Array.isArray(enterpriseAccountExport)
    && enterpriseAccountExport?.length > 0
    && enterpriseAccountExport?.includes('exportCityDataPatent');
  const exportUnitFlag = Array.isArray(enterpriseAccountExport)
    && enterpriseAccountExport?.length > 0
    && enterpriseAccountExport?.includes('exportCityDataOrgan');

  const [patentKeyword, setPatentKeyword] = useState('');
  const [unitKeyword, setUnitKeyword] = useState('');
  const [patentSearchKeyword, setPatentSearchKeyword] = useState('');
  const [unitSearchKeyword, setUnitSearchKeyword] = useState('');
  const [patentTableState, setPatentTableState] = useState(DEFAULT_TABLE_STATE);
  const [unitTableState, setUnitTableState] = useState(DEFAULT_TABLE_STATE);
  const [patentCount, setPatentCount] = useState(0);
  const [unitCount, setUnitCount] = useState(0);
  const [params, setParams] = useState(null);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [companyPatentModalState, setCompanyPatentModalState] = useState({
    pageSize: 20,
    pageNumber: 1,
    type: undefined,
    visible: false,
    title: '',
    value: '-',
    companyId: '',
  });
  const [dataKey, setDataKey] = useState('');

  const { data: chartData, isFetching } = useGetInnovationAbilityQuery({
    area_key: cityCode,
  }, {
    skip: !cityCode,
  });
  const onPatentData = useCallback((data = {}) => {
    const { count: _count } = data;
    setPatentCount(_count);
  }, []);
  const onUnitData = useCallback((data = {}) => {
    const { count: _count } = data;
    setUnitCount(_count);
  }, []);

  useEffect(() => {
    setPatentTableState(DEFAULT_TABLE_STATE);
    setUnitTableState(DEFAULT_TABLE_STATE);
  }, [cityCode]);

  return (
    <div className={style.all} id={title}>
      <div className={style.title}>{title}</div>
      <div className={style.main}>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[0]?.title ?? ''}
            {chartData?.[0].subtitle && `(${chartData?.[0].subtitle})`}
          </div>
          <div className={style.chart}>
            <Barandline
              data={{
                ...chartData?.[0],
                highlight: chartData?.[0]?.highlight[0],
                left: 64,
                leftYName: [0, 30, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[1]?.title ?? ''}
            {chartData?.[1].subtitle && `(${chartData?.[1].subtitle})`}
          </div>
          <div className={style.chart}>
            <Treemap data={chartData?.[1].series?.[0].data} isFetching={isFetching} />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[2]?.title}
            {chartData?.[2].subtitle && `(${chartData?.[2].subtitle})`}
          </div>
          <div className={style.chart}>
            <HollowPie data={chartData?.[2].series[0]} isFetching={isFetching} />
          </div>
        </div>
      </div>
      <div className={style.main}>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[3]?.title}
            {chartData?.[3].subtitle && `(${chartData?.[3].subtitle})`}
          </div>
          <div className={style.chart}>
            <StackBar
              data={{
                ...chartData?.[3],
                leftYName: [0, 30, 0, 0],
                left: 56,
                color: ['#1961F5', '#59D700', '#FD6205'],
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[4]?.title}
            {chartData?.[4].subtitle && `(${chartData?.[4].subtitle})`}
          </div>
          <div className={style.chart}>
            <StackBar
              data={{
                ...chartData?.[4],
                leftYName: [0, 30, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          {
            cityCode?.slice(4, 6) === '00' && !isProvinceDirectly && (
              <>
                <div className={style.chartTitle}>
                  {chartData?.[5]?.title}
                  {chartData?.[5].subtitle && `(${chartData?.[5].subtitle})`}
                </div>
                <div className={style.chart}>
                  <Barandline
                    data={{
                      ...chartData?.[5],
                      leftYName: [0, 30, 0, 0],
                    }}
                    isFetching={isFetching}
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
      <div className={style.table}>
        <div className={style.header}>
          <div className={style.title}>专利</div>
          <Subtitle
            description="共计"
            value={patentTotal ?? '-'}
            unit="件"
            className={style.subtitle}
          />
          {
            patentCount !== Number(patentTotal) && <Subtitle
              description="筛选主体"
              value={patentCount ?? '-'}
              unit="家"
              className={style.subtitle}
            />
          }
          <Input
            className={classNames(style.search, { [style.right]: !exportPatentFlag })}
            value={patentKeyword}
            placeholder="搜索单位名称"
            onChange={e => {
              setPatentKeyword(e.target.value);
              if (e.target.value === '') {
                setPatentSearchKeyword('');
                setPatentTableState({
                  ...DEFAULT_TABLE_STATE,
                  sortedInfo: patentTableState?.sortedInfo,
                });
              }
            }}
            onPressEnter={value => {
              setPatentSearchKeyword(value);
              setPatentTableState({
                ...DEFAULT_TABLE_STATE,
                sortedInfo: patentTableState?.sortedInfo,
              });
            }}
            onSearch={value => {
              setPatentSearchKeyword(value);
              setPatentTableState(DEFAULT_TABLE_STATE);
            }}
          />
          {
            exportPatentFlag && (
              <Button className={style.exportButton} onClick={() => { setDataKey('find_patent_list'); setExportModalVisible(true); }}>数据导出</Button>
            )
          }
        </div>
        <Underline />
        <PatentTable
          keyword={patentSearchKeyword}
          cityCode={cityCode}
          pageNumber={patentTableState.pageNumber}
          pageSize={patentTableState.pageSize}
          sortedInfo={patentTableState.sortedInfo}
          filteredInfo={patentTableState.filteredInfo}
          onCompanyClick={({ _id }) => {
            if (!_id) return;
            openNewPage('companyDetail', { id: _id });
          }}
          onChange={({ current }, filters, sorter) => {
            setPatentTableState(prev => ({
              ...prev,
              pageNumber: current,
              filteredInfo: filters,
              sortedInfo: sorter,
            }));
          }}
          onClick={({ record, record: { _id }, column, column: { title: _title, dataIndex } }) => {
            setCompanyPatentModalState(prev => ({
              ...prev,
              pageNumber: 1,
              visible: true,
              companyId: _id,
              title: _title,
              value: '-',
              type: PATENT_KEY[dataIndex],
            }));
          }}
          onData={onPatentData}
          setParams={setParams}
        />
        {
          // 企业专利弹窗
          companyPatentModalState.visible && (
            <StandardModal
              header={
                <div className={style.modalHeader}>
                  <span className={style.title}>{companyPatentModalState.title}</span>
                  <Subtitle
                    className={style.subtitle}
                    description="共"
                    value={companyPatentModalState.value ?? '-'}
                    unit="条"
                  />
                </div>
              }
              onClose={() => {
                setCompanyPatentModalState(prev => ({
                  ...prev,
                  visible: false,
                }));
              }}
              className={style.modal}
            >
              <CompanyPatentList
                patentType={companyPatentModalState.type}
                companyId={companyPatentModalState.companyId}
                pageSize={companyPatentModalState.pageSize}
                pageNumber={companyPatentModalState.pageNumber}
                onChange={({ current }) => {
                  setCompanyPatentModalState(prev => ({
                    ...prev,
                    pageNumber: current,
                  }));
                }}
                onData={(data = {}) => {
                  const { count: _count } = data;
                  setCompanyPatentModalState(prev => ({ ...prev, value: _count }));
                }}
                onPatentClick={({ _id }) => {
                  openNewPage('patentDetail', {
                    id: _id,
                  });
                }}
              />
            </StandardModal>
          )
        }
      </div>
      <div className={style.table}>
        <div className={style.header}>
          <div className={style.title}>研究机构</div>
          <Subtitle
            description="共计"
            value={unitTotal ?? '-'}
            unit="条"
            className={style.subtitle}
          />
          {
            unitCount !== Number(unitTotal) && <Subtitle
              description="筛选结果"
              value={unitCount ?? '-'}
              unit="条"
              className={style.subtitle}
            />
          }
          <Input
            className={classNames(style.search, { [style.right]: !exportUnitFlag })}
            value={unitKeyword}
            placeholder="搜索研究机构"
            onChange={e => {
              setUnitKeyword(e.target.value);
              if (e.target.value === '') {
                setUnitSearchKeyword('');
                setUnitTableState({
                  ...DEFAULT_TABLE_STATE,
                  sortedInfo: unitTableState?.sortedInfo,
                });
              }
            }}
            onPressEnter={value => {
              setUnitSearchKeyword(value);
              setUnitTableState({
                ...DEFAULT_TABLE_STATE,
                sortedInfo: unitTableState?.sortedInfo,
              });
            }}
            onSearch={value => {
              setUnitSearchKeyword(value);
              setUnitTableState(DEFAULT_TABLE_STATE);
            }}
          />
          {
            exportUnitFlag && (
              <Button className={style.exportButton} onClick={() => { setDataKey('find_institutions_list'); setExportModalVisible(true); }}>数据导出</Button>
            )
          }
        </div>
        <Underline />
        <ResearchUnitTable
          keyword={unitSearchKeyword}
          cityCode={cityCode}
          pageNumber={unitTableState.pageNumber}
          pageSize={unitTableState.pageSize}
          sortedInfo={unitTableState.sortedInfo}
          filteredInfo={unitTableState.filteredInfo}
          onData={onUnitData}
          onUnitClick={({ _id }) => {
            if (!_id) return;
            openNewPage('institutionDetail', {
              id: _id,
            });
          }}
          onChange={({ current }, filters, sorter) => {
            setUnitTableState(prev => ({
              ...prev,
              pageNumber: current,
              filteredInfo: filters,
              sortedInfo: sorter,
            }));
          }}
          setParams={setParams}
        />
      </div>
      {
        exportModalVisible && (
          <div className={style.mask}>
            <ExportModal
              dataKey={dataKey}
              params={params}
              onClose={() => {
                setExportModalVisible(false);
              }}
            />
          </div>
        )
      }
    </div>
  );
}

export default InnovationAbility;
