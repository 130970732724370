import { EChartsOption } from 'echarts';
import { getFormatter, omitUndefined } from './utils';
import { IModelFunction, IModelMiddleware } from '../interface/base';
import { merge } from 'lodash';

export const getBaseOption: IModelFunction = (raw, data) => {
  // 收集必须的属性之后，清理 undefined 值，防止颜色无法显示默认
  const {
    visualConfig: {
      geo,
    },
  } = raw;

  if (geo) {
    const { label } = geo ?? {};
    const _formatter = getFormatter(label?.formatter);
    return omitUndefined({
      geo: geo ? omitUndefined({
        ...geo,
        label: label ? omitUndefined({
          ...label,
          formatter: typeof _formatter === 'function' ? (params: any) => {
            if (!data || !data.series) return _formatter({ ...params, value: undefined });
            const _data = data?.series[0]?.data.find((item: any) => (item.name as any) === params.name) as any;
            return _formatter({ ...params, data: _data, value: _data?.value });
           } : _formatter,
        }) : undefined,
      }) : undefined,
    }) as EChartsOption;
  }

  return omitUndefined({
    ...(geo ?? {}),
  }) as EChartsOption;
};

export const geo: IModelMiddleware = (option, material) => {
  return merge(
    option,
    getBaseOption(material.config, material.data),
  );
};
