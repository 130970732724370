import React, { useMemo } from 'react';
import Table from '../../../../components/Table';
import RankingOrder from '../../../../components/RankingOrder';
import { useGetChannelCompanyPatentRankQuery } from '../../../../service/industry-channel';

const THEAD_TABLE = {
  index: {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    render: text => <RankingOrder order={text} />,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (text, { _id }) => <div className="clickable-name" data-id={_id}>{text}</div>,
  },
  doc_count: {
    title: '拥有专利数量',
    dataIndex: 'doc_count',
    key: 'doc_count',
    align: 'center',
    width: 120,
    ellipsis: true,
  },
};

const COLUMN_KEYS = ['index', 'name', 'doc_count'];

/**
 * 专精特新榜单
 * @param {{
 *   industryCode: string;
 *   pageNumber?: number;
 *   pageSize?: number;
 *   className?: string;
 *   onCompanyClick?;
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function PatentRanking(props) {
  const {
    industryCode,
    pageNumber,
    pageSize,
    onCompanyClick,
  } = props;

  const { data, isFetching } = useGetChannelCompanyPatentRankQuery({
    industry_code: industryCode,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });

  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) return COLUMN_KEYS.map(key => THEAD_TABLE[key]);
    return columns.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      return rowConfig;
    });
  }, [columns, onCompanyClick]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      current={pageNumber}
      pageSize={pageSize}
      loading={isFetching}
      total={total}
      {...props}
    />
  );
}

export default PatentRanking;
