import { get, post, requestFile } from '../utils/request';
import { REPORTtEMPLATE, FINESYSTEMREPORT, INTERACTION } from '../utils/url';

// 查询精品报告
const getBoutiqueReport = param => get(`${FINESYSTEMREPORT}/watch_report`, {
  ...param,
});

// 复用模板-扣次数
const resetTemplateReport = param => post(`${REPORTtEMPLATE}/system_template_reelect_param`, {
  ...param,
});

// 下载精品报告
const downloadBoutiqueReport = (params, name) => requestFile('get', `${FINESYSTEMREPORT}/download_report`, {
  ...params,
}, `${name}.pdf`);

// 模糊匹配公司名称
const getVagueCompnayName = param => post(`${REPORTtEMPLATE}/find_company_list_by_like_name`, {
  ...param,
});

// 获取系统模板的区域接口
const getTemplateAreaList = param => post(`${REPORTtEMPLATE}/find_report_template_area`, {
  ...param,
});

// const getTemplateAreaList = param => post(`${INTERACTION}/area_select_box`, {
//   ...param,
// });

// 获取系统模板的产业接口
const getTemplateIndustryList = param => get(`${REPORTtEMPLATE}/find_report_template_industry`, {
  ...param,
});

// 获取系统模板的资质接口
const getTemplateDiyTagList = param => get(`${INTERACTION}/diy_tag_select_list`, {
  ...param,
});

// const getTemplateIndustryList = param => get(`${INTERACTION}/search_industry_tree`, {
//   ...param,
// });

// 根据名称查企业id
const getCompnayId = param => post(`${REPORTtEMPLATE}/find_company_id_by_name`, {
  ...param,
});

// code查询区域名称
const getRegionName = param => post(`${INTERACTION}/find_region_name`, {
  ...param,
});

export {
  downloadBoutiqueReport,
  getBoutiqueReport,
  getVagueCompnayName,
  getTemplateAreaList,
  getTemplateIndustryList,
  getTemplateDiyTagList,
  getCompnayId,
  resetTemplateReport,
  getRegionName,
};
