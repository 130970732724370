/*
* Author: zhangmeng
* Description: '主标题和副标题组件'
* Date: 2020/11/23
*/

import React from 'react';
import style from './index.module.less';

function TitleAndSubTitleComp(props) {
  const { title, subTitle } = props;

  return (
    <div className={style.header}>
      <div className={style.titleBox}>
        <span className={style.title}>{title}</span>
      </div>
      <div className={style.subTitle}>
        {subTitle}
      </div>
    </div>
  );
}

export default TitleAndSubTitleComp;
