import React from 'react';
// import { Progress } from 'antd';
import clsx from 'clsx';
import CrosswiseDar from '../../../../../../../components/Charts/CrosswiseDar';
import HollowPie from '../../../../../../../components/Charts/HollowPie';
import FunnelChart from '../../../../../../../components/Charts/FunnelChart';
import style from './index.module.less';

const ChartList = ({ data, isFetching, link, childrenData, cityCode, isProvinceDirectly, onClick }) => (
  <ul className={style.chartList}>
    <li>
      <p className={style.chartTitle} >
        {data?.[0].title || ''}
        {data?.[0].subtitle && `(${data?.[0].subtitle || ''})`}
      </p>
      <div className={style.chart}>
        <FunnelChart
          data={data?.[0].series[0].data}
          isFetching={isFetching}
        />
      </div>
      {/* <div className={style.chart}>
        <div className={clsx(style.box)}>
          <div className={clsx(style.allTitle)}>产业链统计</div>
          <div className={clsx(style.contentBox)}>
            <div className={clsx(style.leftBox)}>
              <div className={clsx(style.title)}>人工智能</div>
              <div className={clsx(style.centerBox)}>
                <div className={clsx(style.centerTitle)}>企业总量</div>
                <div className={clsx(style.centerConter)}>
                  <span className={clsx(style.centerNumber)}>3456</span>
                  <span className={clsx(style.centerUnit)}>家</span>
                </div>
              </div>
              <div className={clsx(style.bottomBox)}>
                <div className={clsx(style.bottomTitle)}>产业链环节数</div>
                <div className={clsx(style.bottomConter)}>
                  <span className={clsx(style.bottomNumber)}>3456</span>
                  <span className={clsx(style.bottomUnit)}>家</span>
                </div>
              </div>
            </div>
            <div className={clsx(style.lineStyle)}></div>
            <div className={clsx(style.rightBox)}>
              <div className={clsx(style.title)}>全国人工智能</div>
              <div className={clsx(style.centerBox)}>
                <div className={clsx(style.centerTitle)}>企业总量</div>
                <div className={clsx(style.centerConter)}>
                  <span className={clsx(style.centerNumber)}>3456</span>
                  <span className={clsx(style.centerUnit)}>家</span>
                </div>
              </div>
              <div className={clsx(style.bottomBox)}>
                <div className={clsx(style.bottomTitle)}>产业链环节数</div>
                <div className={clsx(style.bottomConter)}>
                  <span className={clsx(style.bottomNumber)}>3456</span>
                  <span className={clsx(style.bottomUnit)}>家</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={clsx(style.progressBox)}>
          <div>产业链环节覆盖度</div>
          <Progress strokeLinecap="butt" percent={20} />
        </div>
      </div> */}
    </li>
    <li>
      <p className={style.chartTitle} >
        {data?.[1].title || ''}
        {data?.[1].subtitle && `(${data?.[1].subtitle || ''})`}
      </p>
      <div className={clsx(style.chart, style.hollowPieBox)}>
        <div className={style.parentHollowPie}>
          <HollowPie
            data={data?.[1].series[0]}
            isFetching={isFetching}
            radius={['40%', '60%']}
            onClick={onClick}
            showCount={false}
            position="inside"
            fonstColor="#fff"
            center={['25%', '50%']}
            defaultPitch
          />
        </div>
        <div className={style.childrenChart}>
          <HollowPie
            data={childrenData?.series[0]}
            isFetching={isFetching}
            radius={['40%', '60%']}
            center={['50%', '60%']}
            showCount={false}
            labelShow={false}
            legend={false}
            title={link}
            titleShow
          />
        </div>
      </div>
    </li>
    <li>
      <p className={style.chartTitle} >
        {data?.[2].title || ''}
        {data?.[2].subtitle && `(${data?.[2].subtitle || ''})`}
      </p>
      <div className={style.chart}>
        <HollowPie
          data={data?.[2].series[0]}
          roseType="radius"
          radius={['20%', '70%']}
          isFetching={isFetching}
        />
      </div>
    </li>
    <li>
      <p className={style.chartTitle} >
        {data?.[3].title || ''}
        {data?.[3].subtitle && `(${data?.[3].subtitle || ''})`}
      </p>
      <div className={style.chart}>
        <CrosswiseDar data={data?.[3]} isFetching={isFetching} isShowOver />
      </div>
    </li>
  </ul>
);

export default ChartList;
