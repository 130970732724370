// 柱线图
import React, { useMemo } from 'react';
// import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { BarAndLineChartProps } from '../basicsType';
import {
  addWaterMark,
  getDefaultCanvas,
  handleToolTipLongTitle,
  getWordsWidth,
  getRoundingNumber,
} from '../../utils/tool';
import { barHightLightColor } from '../../consts';

import './index.less';
import '../../index.less';
import { modeStyle, chartFontSize } from './style';
import { handleData } from './checkDataFormat';

const prefixCls: string = 'cube-component-bar-and-line-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};
const defaultY: BarAndLineChartProps['data']['yAxis'] = [{
  name: '',
  unit: '',
  value: [],
  isType: '0',
}];
const defaultX: string[] | undefined = [];

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const BarAndLineChart = forwardRef((props: BarAndLineChartProps, ref: any) => {
  const { title = '', mode = 'dark', legend = true, data, isArea, isSingleShape,
    chartGrid, fontSize, yMax = '' } = props;
  const [option, setOption] = useState({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);
  // 获取y轴最大label 确定其宽度
  const maxRightYAxisNumber = useRef({ value: 0 });
  const maxYAxisNumber = useRef({ value: '' });

  const xAxis = data.xAxis || defaultX;
  const yAxis = data.yAxis || defaultY;
  const highlights = data.highlight || [];

  useEffect(() => {
    const curStyle = modeStyle[mode] || modeStyle.light;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;
    const xName = data.xName || '';
    const xNameGap = 10;

    let xNameWidth = getWordsWidth(xName, { font: `${curFont.xFontSize}px sans-serif` });
    if (xNameWidth) {
      xNameWidth += xNameGap;
    }
    const grid = {
      show: false,
      left: 10,
      right: 8 + xNameWidth,
      top: 38,
      bottom: 30,
      ...chartGrid,
      containLabel: true,
    };
    // grid.right = 40;
    const { x, y } = handleData(xAxis, yAxis);
    const xAxisDefault = {
      name: xName,
      nameLocation: 'end',
      nameGap: xNameGap,
      boundaryGap: !(isArea && isSingleShape),
      nameTextStyle: {
        fontSize: curFont.xFontSize,
        align: 'left',
      },
      type: 'category',
      position: 'bottom',
      data: x,
      show: x.length && y.length,
      axisLabel: {
        show: true,
        interval: 0,
        fontSize: curFont.xFontSize,
        color: curStyle.xAxisLabelColor,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: curStyle.xAxisLineColor,
        },
      },
      splitLine: {
        show: false,
      },
    };

    const hasLeftData = y.filter((item) => item.isType === '0').length;
    const hasRightData = y.filter((item) => item.isType === '1').length;

    let { yUnit, yRightUnit } = data;
    if (data.yName) {
      if (yUnit) { // 单位存在时
        yUnit = `\n{yUnit|(${yUnit})}`;
      }
    }
    if (data.yRightName) {
      yRightUnit = `\n{yUnit|(${yRightUnit})}`;
    }
    const yName = `${(hasLeftData || isSingleShape) ? `${data.yName}${yUnit}` : ''}`;
    const yRightName = `${hasRightData ? `${data.yRightName}${yRightUnit}` : ''}`;

    const yAxisDefault = [
      {
        show: !(!xAxis.length || !yAxis.length || !yAxis[0].value.length),
        type: 'value',
        max: yMax,
        name: yName,
        nameTextStyle: {
          color: curStyle.yNameTextStyle.color,
          fontSize: curFont.yFontSize,
          align: 'left',
          padding: [0, 0, 0, -0],
          nameGap: 0,
          // backgroundColor: 'red',
          rich: {
            yUnit: {
              align: 'center',
              color: curStyle.yNameTextStyle.color,
              fontSize: curFont.yFontSize,
            },
          },
        },
        nameLocation: 'end',
        axisLabel: {
          fontSize: curFont.yFontSize,
          interval: 0,
          margin: 8,
          color: curStyle.yAxisLabel.color,
          formatter(value: any) {
            value = String(value);
            if (value.length < 3) {
              // 理论上y轴是递增的 所以只保留最后一个就好？ 只针对value型的y轴
              maxYAxisNumber.current.value = value;
              return value;
            }
            let ans = '';
            for (let i = value.length - 1, L = value.length; i >= 0; i -= 1) {
              let prefix = '';
              if (i !== 0 && (L - i) % 3 === 0) {
                prefix = ',';
              }
              ans = prefix + value[i] + ans;
            }
            maxYAxisNumber.current.value = ans;
            return ans;
          },
        },
        position: 'left',
        axisLine: {
          show: props.yAxisStyle?.showYaxisLine,
        },
        splitLine: {
          show: !props.yAxisStyle?.hiddenYsplitLine,
          lineStyle: {
            type: 'dashed',
            color: curStyle.ySplitLine.color,
          },
        },
      }, {
        type: 'value',
        name: yRightName,
        max: yMax,
        nameTextStyle: {
          color: curStyle.yNameTextStyle.color,
          fontSize: curFont.yFontSize,
          align: 'right',
          padding: [0, 0, 0, -0],
          rich: {
            yUnit: {
              align: 'center',
              color: curStyle.yNameTextStyle.color,
              fontSize: curFont.yFontSize,
            },
          },
        },
        nameLocation: 'end',
        axisLabel: {
          fontSize: curFont.yFontSize,
          interval: 0,
          color: curStyle.yAxisLabel.color,
          formatter(value: any) {
            maxRightYAxisNumber.current.value = Math.max(maxRightYAxisNumber.current.value,
              Number(value));
            return value || '';
          },
        },
        position: 'right',
        splitLine: {
          show: false,
        },
      },
    ];
    if (xAxis.some(item => item.length > 5) || xAxis.length > 10) {
      // @ts-ignore
      xAxisDefault.axisLabel.rotate = 45;
    }

    if (xAxis.length >= 25) {
      xAxisDefault.axisLabel.fontSize = 8;
      yAxisDefault.forEach(item => {
        item.axisLabel.fontSize = 10;
      });
    }
    if (isSingleShape) {
      yAxisDefault.pop();
    }
    const highlightIndexies = x.reduce((arr: number[], item, index) => {
      if (highlights.indexOf(item) !== -1) {
        arr.push(index);
      }
      return arr;
    }, []);
    // highlightIndexies.push(3);
    // highlightIndexies.push(9);
    const shapeMap = {
      0: {
        type: 'bar',
        barMaxWidth: 12,
      },
      1: {
        type: 'line',
        smooth: 0.5,
        smoothMonotone: 'x',
        // sampling: 'lttb',
        symbol: 'circle',
        symbolSize: 4,
        lineStyle: {
          width: 1,
        },
      },
    };
    let barHightLightColorIndex = 0;
    let lineHightLightColorIndex = 0;
    function getShapMap(type:number, index:number, seriesIndex: number) {
      const styleMapArr = [
        { // bar
          itemStyle: JSON.parse(JSON.stringify(curStyle.itemStyle.bar)),
          markPoint: {},
          label: {
            show: !highlightIndexies.length,
          },
        }, { // line
          itemStyle: JSON.parse(JSON.stringify(curStyle.itemStyle.line)),
          markPoint: {},
          label: {
            show: !highlightIndexies.length,
          },
        }];
      if (type === 0) {
        const L = curStyle.itemStyle.bar.colorList.length;
        const { colorList, colorList1 } = curStyle.itemStyle.bar;
        styleMapArr[type].itemStyle.color.colorStops[0].color = colorList[seriesIndex % L];
        styleMapArr[type].itemStyle.color.colorStops[1].color = colorList1[seriesIndex % L];

        if (highlightIndexies.indexOf(index) !== -1) {
          const HL = barHightLightColor.length;
          styleMapArr[type].itemStyle.color = barHightLightColor[(barHightLightColorIndex) % HL];
          barHightLightColorIndex += 1;
          styleMapArr[type].label.show = false;
        }
      } else if (type === 1) {
        const { colorList } = curStyle.itemStyle.line;
        const L = colorList.length;
        styleMapArr[type].itemStyle.color = colorList[seriesIndex % L];
        if (highlightIndexies.indexOf(index) !== -1) {
          const HL = barHightLightColor.length;
          styleMapArr[type].itemStyle.color = barHightLightColor[lineHightLightColorIndex % HL];
          lineHightLightColorIndex += 1;
          styleMapArr[type].label.show = true;
          styleMapArr[type].label.show = true;
        }
      }
      return styleMapArr[type];
    }
    function getMarkPoints(values: (number|string)[], type: '0'|'1', _seriesIndex: number) {
      const result: any [] = [];
      if (type === '1') {
        return {};
      }
      if (!values) {
        return {};
      }
      const L = barHightLightColor.length;
      barHightLightColorIndex = 0;
      values.forEach((item, index) => {
        if (highlightIndexies.indexOf(index) !== -1) {
          result.push({
            name: 'highlight',
            value: item,
            xAxis: index,
            yAxis: item || '',
            // symbolSize: type === '0' ? 50 : 25,
            show: false,
            itemStyle: {
              color: barHightLightColor[barHightLightColorIndex % L],
              // opacity: 0.5,
            },
            label: {
              // rotate: type === '0' ? 0 : 45,
              color: '#fff',
              textBorderColor: barHightLightColor[barHightLightColorIndex % L],
              textBorderWidth: 1,
              fontSize: type === '0' ? 11 : 9,
            },

          });
          barHightLightColorIndex += 1;
        }
      });
      return { markPoint: {
        data: result,
      } };
    }
    // 获取系列颜色 用于legend显示颜色
    function getSeriesColor(type: number, seriesIndex: number) {
      const styleMapArr = [{
        itemStyle: JSON.parse(JSON.stringify(curStyle.itemStyle.bar)),
        markPoint: {},
      }, {
        itemStyle: JSON.parse(JSON.stringify(curStyle.itemStyle.line)),
        markPoint: {},
      }];

      if (type === 0) {
        const L = curStyle.itemStyle.bar.colorList.length;
        const { colorList, colorList1 } = curStyle.itemStyle.bar;
        styleMapArr[type].itemStyle.color.colorStops[0].color = colorList[seriesIndex % L];
        styleMapArr[type].itemStyle.color.colorStops[1].color = colorList1[seriesIndex % L];
      } else if (type === 1) {
        const L = curStyle.itemStyle.line.colorList.length;
        styleMapArr[type].itemStyle.color = curStyle.itemStyle.line.colorList[seriesIndex % L];
      }
      return styleMapArr[type];
    }
    const series = y.map((item, _index) => ({
      // name: `${item.name}(${item.unit})`,
      name: `${item.name}`,
      dimensions: [item.unit || ''],
      // 只有一种形状时就取第一个  否则根据类型对应对应的y轴
      yAxisIndex: isSingleShape ? 0 : Number(item.isType),
      data: item?.value?.map((itemInner, index) => ({
        value: itemInner,
        ...getShapMap(Number(item.isType), index, _index),
      })),
      label: {
        show: true || isSingleShape,
        position: 'top',
        fontSize: 8,
      },
      labelLayout: {
        hideOverlap: true,
        moveOverlap: 'shiftY',
      },
      areaStyle: isSingleShape && isArea ? {} : null,
      [item.isType === '1' && item.area ? 'areaStyle' : 'areaStyleUnSet']: {},
      ...getSeriesColor(Number(item.isType), _index),
      ...getMarkPoints(item.value, item.isType, _index),
      ...shapeMap[item.isType],

    }));
    const options = {
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => {
          let str = '<div style="padding: 9px 9px 4px 9px;">';
          if (title) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: ${curStyle.tooltipFont[0]};letter-spacing: 1.75px;">${handleToolTipLongTitle(title)}</div>`;
          }
          params.forEach((param: any) => {
            const { name, dimensionNames, value, seriesName } = param;
            str += `<div style="display: flex; justify-content: space-between; color: ${curStyle.tooltipFont[2]};">
                    <span style="margin-right: 60px">${seriesName || ''}${name}</span>
                    <span>
                      <span style="color: ${curStyle.tooltipFont[3]};font-weight: 600;font-size: 18px;margin-right: 4px;">${value}</span>
                      ${dimensionNames[0]}
                    </span>
                  </div>`;
          });
          str += '</div>';
          return str;
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      xAxis: xAxisDefault,
      yAxis: isSingleShape ? [yAxisDefault[0]] : yAxisDefault,
      // backgroundColor: '#fff',
      legend: {
        show: legend,
        position: 'bottom',
        selectedMode: false,
        itemWidth: 10,
        itemHeight: 7,
        bottom: 6,
        textStyle: {
          color: curStyle.legendTextColor,
          fontSize: curFont.legnedFontSize,
        },
        itemStyle: {
          color: 'inherit',
        },
      },
      series,
      grid,
      animation: true,
    };
    // rendered event中置为false
    setChartLoading(false);
    if (!yMax) {
      options.yAxis.forEach(item => {
        delete item.max;
      });
    }
    if (highlights.length === 1) {
      if (xAxis.findIndex(item => item === highlights[0]) === 0) {
        (options.yAxis[yAxis.findIndex(item => item.isType === '0') || 0].max as any) = function (val: any) {
          const newValue: number = getRoundingNumber(val.max * 1.3);
          return newValue;
        };
      }
    }
    setOption(options);
  }, [mode, title, legend, xAxis, yAxis, data]);

  // 坐标轴名字适应
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    const curOption = instance.getOption();
    if (!curOption.grid?.[0]) {
      return;
    }
    if (!isSingleShape) {
      const yAxisOne = curOption.yAxis[1];
      const yRightMaxVlue = (maxRightYAxisNumber.current.value);
      const length = 8 + getWordsWidth(`${yRightMaxVlue}`, {
        font: `${yAxisOne.axisLabel.fontSize}px sans-serif`,
      });

      if (curOption.grid?.[0].right > length) { // 当前x轴名字的长度加上gap大于 y轴label的长度 需要减去
        curOption.grid[0].right -= (length - 8);
      } else { // 否则 y轴label撑开的宽度就能容纳名字
        curOption.grid[0].right = 12;
      }
      adjustYAxisNameStyle(curOption.yAxis[1], 1, maxRightYAxisNumber.current.value.toString(), 'right');
    }
    function adjustYAxisNameStyle(
      axis: any, paddingIndex: number, yMaxLabel: string, gridKey: string,
    ) {
      const distanceYtoEdge = curOption.grid[0][gridKey] + axis.axisLabel.margin + getWordsWidth(`${yMaxLabel}`, {
        font: `${axis.axisLabel.fontSize}px sans-serif`,
      });
      const yNameWords = (axis.name.split('\n')); // y轴名字坐标换行
      const yPurlyNameWidth = getWordsWidth(`${yNameWords[0]}`, {
        font: `${axis.nameTextStyle.fontSize}px sans-serif`,
      });
      const yUnit = yNameWords[1]?.match(/\{yUnit\|(\S*)\}/) && yNameWords[1].match(/\{yUnit\|(\S*)\}/)[1];
      const yUnitWidth = getWordsWidth(`${yUnit || ''}`, {
        font: `${axis.nameTextStyle.fontSize}px sans-serif`,
      });
      const yNameWidth = Math.max(yUnitWidth, yPurlyNameWidth);
      // console.log(yNameWidth);
      // 最好的效果是让y名字居中于y轴，前提是distanceYtoEdge可以容纳的下yNameWidth的一半
      const putSomeGap = 4;
      const namePadding = axis.nameTextStyle.padding;
      if (yNameWidth / 2 < distanceYtoEdge - putSomeGap) { // 可以居中展示
        namePadding[paddingIndex] = -yNameWidth / 2;
      } else { // 放不下 就尽量往中心点移动
        namePadding[paddingIndex] = -(
          (distanceYtoEdge - putSomeGap)
        );
      }
    }
    adjustYAxisNameStyle(curOption.yAxis[0], 3, maxYAxisNumber.current.value, 'left');
    instance.setOption({ ...curOption });
  }, [option, isSingleShape]);

  //
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    if (!highlights.length || isSingleShape) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    const curOption = instance.getOption();
    if (!curOption.series.length) {
      return;
    }
    curOption.series.forEach((serie: any) => {
      if (serie.type === 'bar') {
        if (serie?.markPoint?.data?.length) {
          // eslint-disable-next-line no-unused-expressions
          serie?.markPoint?.data.forEach((markPoint: any) => {
            const [, markPointY] = instance.convertToPixel({
              xAxisIndex: 0,
              yAxisIndex: 0,
            }, [markPoint.xAxis, markPoint.yAxis]);
            curOption.series.forEach((ser: any) => {
              if (ser.type === 'line') { // 看看有没有点被遮住
                const [, yLine] = instance.convertToPixel({
                  xAxisIndex: 0,
                  yAxisIndex: 1,
                }, [markPoint.xAxis, ser.data[markPoint.xAxis].value]);
                // console.log(x, y);
                // console.log(xLine, yLine);
                // console.log((markPointY - yLine));
                if (Math.abs(markPointY - yLine)) {
                  // console.log('遮挡');
                }
                // to do 处理遮挡
              }
            });
          });
        }
      }
    });
  }, [option]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!xAxis.length || !yAxis.length || !yAxis[0].value.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  const Echart = useMemo(() => {
    const events = {
      // finished() {
      //   setChartLoading(false);
      // },
      // click(event:any) {
      //   // console.log(event);
      // },
    };
    return <ReactEcharts
      option={option}
      notMerge
      style={{ width: '100%', height: '100%' }}
      ref={chartRef}
      onEvents={events}
    />;
  }, [option]);

  // const [showColorInput, setShowColorInput] = useState(false);
  // const curSerisIndexRef = useRef(0);
  // const inputWraperRef = useRef<HTMLDivElement>({} as HTMLDivElement);

  // function handleColorChange(e: React.ChangeEvent<HTMLInputElement>) {
  //   const color = (e.currentTarget.value);
  //   (option as any).series[curSerisIndexRef.current].itemStyle.color = color;
  //   // setOption({ ...option });
  //   if (!chartRef.current) {
  //     return;
  //   }
  //   const echartInstance = chartRef.current.getEchartsInstance();
  //   echartInstance.setOption(option);
  // }
  // useEffect(() => {
  //   if (!chartRef.current) { // 颜色选择 先不要
  //     return;
  //   }
  //   const echartInstance = chartRef.current.getEchartsInstance();
  //   echartInstance.getZr().on('click', (event: any) => {
  //     let { target } = event;
  //     if (target) {
  //       while (target.parent) {
  //         target = target.parent;
  //       }
  //       curSerisIndexRef.current = target.__ecComponentInfo.index as number;
  //       setShowColorInput(true);
  //       if (!inputWraperRef.current) {
  //         return;
  //       }
  //       const input = inputWraperRef.current.querySelector('input') as HTMLInputElement;
  //       input.value = (option as any).series[curSerisIndexRef.current].itemStyle.color;
  //       (inputWraperRef.current).style.top = `${event.offsetY}px`;
  //       (inputWraperRef.current).style.left = `${event.offsetX}px`;
  //     } else {
  //       setShowColorInput(false);
  //     }
  //   });
  // }, [chartLoading]);

  return (
    <div className={styleMap[mode as 'light'] || styleDark} ref={bodyRef}>
      {
        !chartLoading && (!xAxis.length || !yAxis.length || !yAxis[0].value.length) && (
          <div className={styleEmpty}>暂无数据</div>
        )
      }
      {Echart}
      {/* <div className="color-wraper" style={{ ...inputStyle }} ref={inputWraperRef}>
        <input type="color" name="" id="" onChange={handleColorChange} />
      </div> */}
    </div>
  );
});
BarAndLineChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
export default BarAndLineChart;
