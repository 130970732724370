/*
 *Description: Radar
 *Date: Wed Dec 01 2021 15:20:28 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { RadarChartProps } from '../basicsType';
import { addWaterMark } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const testData = {
  xAxis: ['净利润,2021年第三季度', '市值,2021年1月31日', '营业收入,2021年第三季度', '研发费用,2021年第三季度', 'ORE,2021年第三季度'],
  yAxis: [
    {
      name: '全部上市企业',
      value: [99, 96, 90, 93, 94],
      unit: '',
    },
    {
      name: '北京市专精特新企业',
      value: [10, 30, 50, 80, 105],
      unit: '',
    },
  ],
};

const prefixCls: string = 'cube-component-radar-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const RadarChart = forwardRef((props: RadarChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize, splitNumber, max,
    firstLabelHide } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    const { xAxis = [], yAxis = [] } = hanldeData;
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;
    const optionsValue = {
      color: curStyle.color,
      legend: {
        bottom: 0,
        itemWidth: 14,
        itemHeight: 10,
        selectedMode: false,
        textStyle: {
          color: '#919191',
          fontSize: curFont.legnedFontSize,
        },
        // icon: 'circle',
      },
      toolTip: {
        show: false,
      },
      radar: {
        radius: '70%',
        center: ['50%', '50%'],
        splitNumber,
        nameGap: 8,
        axisName: {
          formatter(param: any) {
            let name = '';
            let res = '';
            if (firstLabelHide) {
              const radarName = param.split(',');
              res = `{blod|${radarName[0]}}\n ${radarName[1] || ''}`;
            } else {
              const radarName = param.split('');
              while (radarName.length > 4) {
                name += `${radarName.splice(0, 4).join('')}\n`;
              }
              res = `${name}${radarName.join('')}`;
            }
            return res;
          },
          color: '#aaa',
          borderRadius: 3,
          fontSize: curFont.axisLabelSize,
          rich: {
            blod: {
              fontWeight: 'bold',
              fontSize: curFont.axisLabelSize + 2,
            },
          },
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: curStyle.splitAreaColor,
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: curStyle.splitLineColor,
          },
        },
        axisLine: {
          show: false,
        },
        indicator: xAxis.map((item: any, index: number) => (
          index === 0 ? {
            name: item, axisLabel: { show: true }, axisTick: { show: true, lineStyle: { color: '#BFBFBF' } }, axisLine: { show: true }, min: 0, max,
          } : { name: item, min: 0, max }
        )),
      },
      series: [{
        type: 'radar',
        data: yAxis.map((item: any, index: number) => (
          {
            name: item.name,
            value: item.value,
            symbol: firstLabelHide && index === 0 ? 'circle' : 'emptyCircle',
            symbolSize: firstLabelHide && index === 0 ? 1 : 8,
            label: {
              show: !(firstLabelHide && index === 0),
              fontSize: curFont.labelFontSize,
              color: curStyle.color[index],
            },
            areaStyle: {
              color: curStyle.color[index],
              opacity: 0.3,
            },
            lineStyle: {
              width: 1,
              opacity: 0.9,
            },
          }
        )),
      }],
    };
    setOption(optionsValue);
  }, [hanldeData, fontSize, label, legend, mode, title, firstLabelHide]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default RadarChart;

RadarChart.defaultProps = {
  data: testData,
};
