/*
 *Author: zhaochenyu
 *Description: 溢出产业列表
 *Date: 2022/07/01
*/

import React, { useState, useEffect, useCallback } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import style from './index.module.less';

function OverflowIndustryList(props) {
  const { data, onClick } = props;
  const [arr, setArr] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const labelsBox = useCallback(node => {
    if (node && node.scrollHeight) {
      setArr(node.scrollHeight);
    }
  }, [data]);
  return (
    <div className={style.all}>
      <div
        className={style.left}
        ref={labelsBox}
        style={{
          overflow: arr > 30 ? 'hidden' : '',
          height: arr > 30 ? ' 22px' : 'auto',
        }}
      >
        {data.map((item, index) => (
          <div
            key={`${index}industryItem`}
            className={style.industryItem}
            onClick={() => onClick(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div
        className={style.omit}
        style={{ display: arr > 30 ? '' : 'none' }}
        onClick={() => {
          setModalShow(pre => !pre);
        }}
      >
        展开
      </div>
      {
        modalShow && (
          <div className={style.modal}>
            <div className={style.title}>
              我的频道
              <CloseOutlined onClick={() => setModalShow(false)} />
            </div>
            <div className={style.content}>
              {data.map((item, index) => (
                <div
                  key={`${index}industryItem`}
                  className={style.industryItem}
                  onClick={() => onClick(item)}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        )
      }
    </div>
  );
}

export default OverflowIndustryList;
