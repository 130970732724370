/*
*Description: TreeTb业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import TreeTbChart from '../../BasicsCompoment/TreeTbChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessTreeTbChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessTreeTbChart(props: BusinessTreeTbChartProps) {
  const {
    mode, label, legend = true, ip, conditions,
    onRequestComplete, title, componmentKey,
    imgRef, nodeStyle, roam, collapse,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data;
          setData(resData.data);
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
         onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <TreeTbChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            label,
            legend,
            ref: imgRef,
            chartDesc,
            nodeStyle,
            roam,
            collapse,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BusinessTreeTbChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
  roam: false,
  collapse: false,
};

// 1 . 178--下钻三层股权穿透图
export function CompanyChartcompanyStockMapFenxi(
  props: BusinessTreeTbChartProps,
) {
  const componmentKey = {
    function_key: 'company_stock_map_fenxi',
    class_name: '/chart/company',
  };
  const nodeStyle = [
    {
      label: {
        color: '#3E3E3E',
        fontSize: 12,
        padding: 0,
        fontWeight: 'normal',
        borderRadius: 32,
        lineHeight: 14,
        align: 'center',
        distance: 0,
        position: 'top',
        overflow: 'break',
      },
      lineStyle: {
        width: 1,
      },
    },
    {
      label: {
        // padding: 4,
        fontSize: 8,
        lineHeight: 8,
        color: '#3E3E3E',
        // backgroundColor: '#89AFD2',
        overflow: 'breakAll',
        fontWeight: 'normal',
        distance: 2,
        // align: 'right',
        // width: 48,
        position: 'right',
        offset: [6, -4],
        rotate: 90,
      },
      itemStyle: {
        // opacity:
      },
      symbolSize: 0,
    },
    {
      label: {
        fontSize: 8,
        color: '#3E3E3E',
        distance: 2,
        position: 'right',
        overflow: 'breakAll',
        rotate: 90,
        offset: [6, -4],
      },
    },
    {
      label: {
        color: '#3E3E3E',
        fontSize: 8,
        distance: 0,
        position: 'right',
        rotate: 90,
        offset: [6, -4],
      },
    },
  ];
  return (
    <BusinessTreeTbChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
      nodeStyle={nodeStyle}
    />);
}
