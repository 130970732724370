/*
 * Author: zhangmeng
 * Description: 企业详情上半部分
 * Date: 2020/11/26
 */

import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { message, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
// import { QuestionCircleOutlined } from '@ant-design/icons';
import CompanyTab from '../CompanyTab';
import ToolBox from './ToolBox';
import ToolButton from './ToolButton';
import { findUserContrastCompany, deleteUserContrastCompany } from '../../../api/companyDetail';
import { resetTemplateReport } from '../../../api/reportGeneration';
import style from './index.module.less';
import { openNewPage } from '../../../utils/urlMethods';

const DetailTopforwardRef = forwardRef((props, ref) => {
  const { briefInfo, companyId } = props;
  const {
    companyName,
    establish_date: establishDate,
    staff_num: staffNum,
    reg_capital: regCapital,
    scrollContent,
    address,
    rate,
    score,
    tags,
  } = briefInfo;
  const [companyList, setCompanyList] = useState([]);
  const [pitchCompanyList, setPitchCompanyList] = useState([]);
  const [companyListShow, setCompanyListShow] = useState(false);

  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const history = useHistory();

  const getUserContrastCompany = () => {
    findUserContrastCompany().then(res => {
      const { status, data, message: text } = res;
      if (status === '00000') {
        setCompanyList(data);
        setPitchCompanyList(data);
      } else {
        message.error(`${text}`);
      }
    }).catch(err => console.log(err));
  };

  const deleteUserContrastCompanyReq = (id) => {
    deleteUserContrastCompany({ company_id: id }).then(res => {
      const { status, message: text } = res;
      if (status === '00000') {
        getUserContrastCompany();
      } else {
        message.error(`${text}`);
      }
    });
  };

  const delCompany = (id) => {
    deleteUserContrastCompanyReq(id);
  };
  const goContrast = () => {
    const companyNameList = pitchCompanyList.map(item => item.company_name);
    const companyIdList = pitchCompanyList.map(item => item.company_id);
    localStorage.setItem('companyList', companyNameList.join('、'));
    localStorage.setItem('companyIdList', companyIdList.join('、'));
    // recordingSpotQuery({
    //   spot_name: '企业详情-工具-同行对标',
    // });
    history.push('/tool/firmComparison');
  };

  const selectCompany = (e, item) => {
    if (e.target.checked) {
      setPitchCompanyList(list => [...list, item]);
    } else {
      setPitchCompanyList(list => list.filter(it => it.id !== item.id));
    }
  };

  const checkboxDisabled = (id) => {
    if (pitchCompanyList.length < 5) {
      return false;
    }
    const pitchCompany = pitchCompanyList.filter(item => item.company_id === id);
    if (pitchCompany.length > 0) {
      return false;
    }

    return true;
  };

  const openReport = () => {
    resetTemplateReport({
      template_id: 1216169,
      company_info_dict: {
        company_id: companyId,
        company_name: companyName,
      },
    }).then(res => {
      if (res.status === '00000') {
        const msg = window.btoa(window.encodeURIComponent(JSON.stringify({
          curTemplateId: '1216169',
          title: '企业尽调报告',
          id: res.data,
          name: companyName,
        })));
        openNewPage('scheme', {
          msg,
        });
      } else {
        message.error(res.message);
      }
    });
  };

  useImperativeHandle(ref, () => ({
    openReport,
  }));

  return (
    <div className={style.detailBox}>
      <div className={style.logoDescBox}>
        <div className={style.descBox}>
          <div className={style.content}>
            <div className={style.companyInfo}>
              <div className={style.establishDate}>
                <span className={style.label}>成立日</span>
                <span className={style.value}>{establishDate}</span>
              </div>
              <div className={style.regCapital}>
                <span className={style.label}>注册额</span>
                <span className={style.value}>{regCapital}</span>
              </div>
              <div className={style.staffNum}>
                <span className={style.label}>员工数</span>
                <span className={style.value}>{staffNum}</span>
              </div>
              <div className={style.address}>
                <span className={style.label}>所在地</span>
                <span className={style.value}>{address}</span>
              </div>
            </div>
            {
              !menu.find(item => item === 'hiddenDetailTag') && (
                <div className={style.tags}>
                  {(tags || []).map((item, index) => (
                    item?.tabs.length > 0 && (
                      <div className={style.compantTabs} key={`${index}tab`}>
                        <CompanyTab data={item} />
                      </div>)
                  ))}
                </div>
              )
            }
            <div className={style.btnChart}>
              <ToolButton id={companyId} name={companyName} />
            </div>
          </div>
        </div>
      </div>
      {
        !menu.find(item => item === 'hiddenDetailOption') && <ToolBox companyName={companyName} companyId={companyId} />
      }
      {
        companyListShow && (
          <div className={style.companyMadul}>
            <div className={style.companyMadulTitle}>
              <p>添加企业对比对标</p>
              <div onClick={() => { setCompanyListShow(false); }} />
            </div>
            <ul>
              {
                companyList.map(item => (
                  <li key={item.company_id}>
                    <Checkbox
                      onChange={(e) => selectCompany(e, item)}
                      disabled={checkboxDisabled(item.company_id)}
                      defaultChecked
                    >
                      {item.company_name}
                    </Checkbox>
                    <div onClick={() => { delCompany(item.company_id); }} />
                  </li>
                ))
              }
            </ul>
            {companyList.length > 0 && <button type="button" onClick={goContrast} >去对比</button>}
            <span>*一次性对比仅限5家企业</span>
          </div>
        )
      }
    </div>
  );
});

export default DetailTopforwardRef;
