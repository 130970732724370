/*
 *Author: zhaochenyu
 *Description: 投资事件详情-头部信息
 *Date: 2021/08/04
*/

import React, { useCallback, useState, useRef, useEffect } from 'react';
import img from '../../../assets/images/investmentDetail/investmentImg.png';
// import { companyTabsColorMap } from '../../../utils/color';
import { tagStyleMap } from '../../../utils/tagColor';
import { openNewPage } from '../../../utils/urlMethods';
import { IconFont } from '../../../utils/mapIcon';
import style from './index.module.less';

function DetailTop(props) {
  const { data } = props;
  const [moreTab, setMoreTab] = useState('');
  const [moreDesc, setMoreDesc] = useState('');
  const tabRef = useRef(null);
  const descRef = useRef(null);

  const pushToDetail = useCallback((id) => {
    if (id === -1) return;
    openNewPage('companyDetail', { id });
  }, []);

  const handleTabHeight = useCallback(() => {
    if (tabRef.current) {
      setMoreTab(tabRef.current.clientHeight > 34 ? 'down' : '');
    }
  }, []);

  const handleDescHeight = useCallback(() => {
    if (descRef.current) {
      setMoreDesc(descRef.current.clientHeight > 30 ? 'down' : '');
    }
  }, []);

  useEffect(() => {
    handleDescHeight();
    handleTabHeight();
  }, []);
  return (
    <div className={style.all}>
      <img alt="" src={img} className={style.leftImg} />
      <div className={style.companyMessage}>
        <div className={style.title}>
          <span style={{ cursor: 'pointer' }} onClick={() => pushToDetail(data.company_id)}>{data.ent_name}</span>
          <span className={style.shortName}>
            企业简称&nbsp;|&nbsp;
            <span style={{ color: '#262D36' }}>{data.ent_short}</span>
          </span>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div className={style.otherMsg}>
            公司网址
            <span
              style={{ color: '#3E5C76', cursor: 'pointer' }}
              onClick={() => {
                if (data.link && data.link !== '-') {
                  window.open(data.link);
                }
              }}
            >
              {data.link}
            </span>
          </div>
          <div className={style.otherMsg}>
            所在区域
            <span>{data.area}</span>
          </div>
          <div className={style.otherMsg}>
            获投日期
            <span>{data.announce_date}</span>
          </div>
          <div className={style.otherMsg}>
            获投轮次
            <span>{data.round}</span>
          </div>
          <div className={style.otherMsg}>
            融资金额
            <span>{data.total_money}</span>
          </div>
        </div>
        <div className={style.desc}>
          <div className={style.label}>公司简介</div>
          <div className={style.content} ref={descRef} style={{ height: moreDesc === 'down' ? '24px' : 'auto' }}>
            {data.brief}
          </div>
          <span style={{ display: moreDesc === 'down' ? '' : 'none', position: 'relative', left: '-8px' }}>...</span>
          <div className={style.direct} style={{ visibility: !moreDesc ? 'hidden' : 'visible' }}>
            <IconFont
              type={moreDesc === 'down' ? 'icondown-circle' : 'iconup-circle'}
              className={style.icon}
              style={{ position: 'absolute' }}
              onClick={() => setMoreDesc(moreDesc === 'down' ? 'up' : 'down')}
            />
          </div>
        </div>
        <div className={style.tabs}>
          <div className={style.label}>行业标签</div>
          <div className={style.content} ref={tabRef} style={{ height: moreTab === 'down' ? '24px' : 'auto' }}>
            {data.tags.map((item, index) => (
              item.tabs.map((item1, index1) => (
                <div
                  className={`${style.tabItem} ${tagStyleMap[item.type]}`}
                  key={`${index}tab${index1}`}
                >
                  {item1}
                </div>
              ))
            ))}
          </div>
          <div className={style.direct} style={{ visibility: !moreTab ? 'hidden' : 'visible' }}>
            <IconFont
              type={moreTab === 'down' ? 'icondown-circle' : 'iconup-circle'}
              className={style.icon}
              style={{ position: 'absolute' }}
              onClick={() => setMoreTab(moreTab === 'down' ? 'up' : 'down')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailTop;
