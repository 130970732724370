/*
* Author: zhangmeng
* Description: '申请试用'
* Date: 2021/1/07
*/

import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import CountDown from './CountDown';
import { userNote } from '../../../api/login';
import QrCodeDesc from '../QrCodeDesc';
import { useRecordingSpotQuery } from '../../../service/public';
import style from './index.module.less';

function UseApply(props) {
  const { cancelClick, onFinish, loading } = props;
  const [countDown, setcountDown] = useState(false);
  const [useAgreeCheck, setUserAgreeCheck] = useState(true);

  const onFinishFailed = (params) => {
    // console.log(params, 'onFinishFailed');
  };
  useRecordingSpotQuery({
    spot_name: '首页-注册',
  });

  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 6 },
  //     sm: { span: 6 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 18 },
  //     sm: { span: 18 },
  //   },
  // };

  const getAuthCode = () => {
    if (countDown) return;
    const phone = document.querySelector('#phone').value;
    const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
    if (!phone) {
      message.error('手机号不能为空');
    } else if (!checkPhone.test(phone)) {
      message.error('手机号码格式错误');
    } else {
      userNote({
        phone_no: phone,
      }).then(res => {
        if (res.status === '00000') {
          setcountDown(true);
        } else {
          message.error(res.message);
        }
      });
    }
  };
  return (
    <div className={style.all}>
      <div className={style.form}>
        <Form
          name="basic"
          // labelAlign="left"
          layout="vertical"
          onFinish={(value) => {
            if (useAgreeCheck) {
              onFinish(value);
            } else {
              message.warning('请仔细阅读《用户协议》');
            }
          }}
          onFinishFailed={onFinishFailed}
          // eslint-disable-next-line react/jsx-props-no-spreading
          // {...formItemLayout}
        >
          <Form.Item
            label="姓名"
            name="real_name"
            className="name"
            rules={[
              {
                pattern: /^[\u4e00-\u9fa5]{2,6}$/,
                message: '请填写正确的姓名',
              },
              {
                required: true,
                message: '请填写姓名',
              },
            ]}
          >
            <Input placeholder="请输入您的姓名" />
          </Form.Item>
          <Form.Item
            label="所在公司"
            name="belong_to_company"
            className="companyName"
            rules={[
              {
                required: true,
                message: '请填写您所在公司',
              },
            ]}
          >
            <Input placeholder="请输入您所在的公司" />
          </Form.Item>
          <Form.Item
            label="手机号"
            name="phone"
            className="phone"
            rules={[
              {
                pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                message: '手机号码格式错误',
              },
              {
                required: true,
                message: '请填写手机号码',
              },
            ]}
          >
            <Input placeholder="请输入真实有效的手机号码" id="phone" />
          </Form.Item>
          <Form.Item
            label="验证码"
            name="auth_code"
            className="auth_code"
            rules={[
              {
                required: true,
                message: '请填写验证码',
              },
            ]}
          >
            <Input placeholder="6位短信验证码" maxLength={6} style={{ width: 202 }} />
          </Form.Item>
          <div
            className={`${style.getAuthCode} ${countDown ? style.counting : undefined}`}
            onClick={() => getAuthCode()}
          >
            {countDown ? <CountDown changeSend={setcountDown} /> : '获取验证码'}
          </div>
          <div className={style.zhuceDesc}>
            <QrCodeDesc
              useAgreeCheck={useAgreeCheck}
              setUserAgreeCheck={setUserAgreeCheck}
            />
          </div>
          <Form.Item className="submit">
            <Button className="cancel-btn" onClick={() => cancelClick()}>
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={`submit-btn ${!useAgreeCheck ? style.unChecked : undefined}`}
              loading={loading}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default UseApply;
