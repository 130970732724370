/*
*Description: Tree业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import TreeChart from '../../BasicsCompoment/TreeChart';
import { post } from '../../utils/request';
import { getWordsWidth, handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessTreeChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessTreeChart(props: BusinessTreeChartProps) {
  const {
    mode, label, legend = true, ip, conditions,
    onRequestComplete, title, componmentKey, nodeStyle,
    imgRef, extraStyleHanlder, gridOption, symbolSize,
    labelLayout, edgeForkPosition, roam, collapse,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data;
          setData(resData.data);
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
         onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <TreeChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            extraStyleHanlder,
            data,
            mode,
            label,
            legend,
            ref: imgRef,
            chartDesc,
            nodeStyle,
            gridOption,
            symbolSize,
            labelLayout,
            edgeForkPosition,
            roam,
            collapse,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BusinessTreeChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
  roam: false,
  collapse: false,
};
// 33 头部企业TOP5产业分布
export function HeaderCompanyTop5(props: BusinessTreeChartProps) {
  const componmentKey = {
    function_key: 'header_company_top5_industry_distribution_fenxi',
    class_name: '/chart/company',
  };
  const nodeStyle: any[] | undefined = [
    {
      label: {
        color: '#fff',
        fontSize: 12,
        backgroundColor: '#4E7293',
        padding: [6, 7, 4],
        fontWeight: 'normal',
        borderRadius: 32,
        lineHeight: 14,
        width: 105 - 14,
        align: 'center',
        distance: 64,
        position: 'insideRight',
        overflow: 'break',
      },
      lineStyle: {
        width: 1,
      },
    },
    {
      label: {
        padding: [0, 2, 0, 2],
        fontSize: 12,
        lineHeight: 12,
        // backgroundColor: 'red',
        color: '#3E3E3E',
        overflow: 'breakAll',
        fontWeight: 'normal',
        distance: 0,
        align: 'left',
      },
    },
    {
      label: {
        fontSize: 14,
        color: '#4E7293',
        distance: 2,
        position: 'right',
      },
    },
  ];
  const gridOption = {
    top: 0.1,
    left: 0.28,
    bottom: 0.1,
    right: 0.15,
  };
  function extraStyleHanlder(data: any, chartInstance: any) {
    const instance = chartInstance;
    const containerWidth = instance.getWidth();
    const treeHeight = containerWidth * (1 - (0.28 + 0.15));// 减去left， right
    // 第二层的最大宽度还是基于某个百分比
    const maxSecondLevelLabelWidth = treeHeight * 0.4 - 4;

    data.children.forEach((item: any) => {
      const curWidth = getWordsWidth(item.name, { font: '12px sans-serif' });
      if (curWidth > maxSecondLevelLabelWidth) {
        (item).label.width = maxSecondLevelLabelWidth;
      }
    });
  }
  return (
    <BusinessTreeChart
      {...props}
      componmentKey={componmentKey}
      nodeStyle={nodeStyle}
      extraStyleHanlder={extraStyleHanlder}
      gridOption={gridOption}
    />);
}

// 32. 头部企业类型分布
export function CompanyChartheaderCompanyTypeDistributionFenxi(props: BusinessTreeChartProps) {
  const componmentKey = {
    function_key: 'header_company_type_distribution_fenxi',
    class_name: '/chart/company',
  };
  const nodeStyle: any[] | undefined = [
    {
      label: {
        color: '#fff',
        fontSize: 12,
        backgroundColor: '#4E7293',
        padding: [6, 7, 4],
        fontWeight: 'normal',
        borderRadius: 32,
        lineHeight: 14,
        width: 105 - 14,
        align: 'center',
        distance: 64,
        position: 'insideRight',
        overflow: 'break',
      },
      lineStyle: {
        width: 1,
      },
    },
    {
      label: {
        padding: [0, 2, 0, 2],
        fontSize: 12,
        lineHeight: 12,
        // backgroundColor: 'red',
        color: '#3E3E3E',
        overflow: 'breakAll',
        fontWeight: 'normal',
        distance: 0,
        align: 'left',
      },
    },
    {
      label: {
        fontSize: 14,
        color: '#4E7293',
        distance: 2,
        position: 'right',
      },
    },
  ];
  const gridOption = {
    top: 0.1,
    left: 0.28,
    bottom: 0.1,
    right: 0.15,
  };
  function extraStyleHanlder(data: any, chartInstance: any) {
    const instance = chartInstance;
    const containerWidth = instance.getWidth();
    const treeHeight = containerWidth * (1 - (gridOption.left + gridOption.right));// 减去left， right
    // 第二层的最大宽度还是基于某个百分比
    const maxSecondLevelLabelWidth = treeHeight * 0.4 - 4;

    data.children.forEach((item: any) => {
      const curWidth = getWordsWidth(item.name, { font: '12px sans-serif' });
      // (item).label.width = curWidth - 4;
      if (curWidth > maxSecondLevelLabelWidth) {
        (item).label.width = maxSecondLevelLabelWidth;
      }
    });
  }
  return (
    <BusinessTreeChart
      {...props}
      componmentKey={componmentKey}
      nodeStyle={nodeStyle}
      extraStyleHanlder={extraStyleHanlder}
      gridOption={gridOption}
    />);
}

// 13 . --企业画像-所获资质标签及获得年份
export function CompanyChartcompanyQualificationLabelsFenxi(
  props: BusinessTreeChartProps,
) {
  const componmentKey = {
    function_key: 'company_qualification_labels_fenxi',
    class_name: '/chart/company',
  };
  const nodeStyle = [
    {
      label: {
        color: '#fff',
        fontSize: 12,
        backgroundColor: '#4E7293',
        padding: [6, 2, 4],
        fontWeight: 'normal',
        borderRadius: 32,
        width: 105 - 14,
        align: 'center',
        distance: 58,
        position: 'insideRight',
        overflow: 'break',
      },
      lineStyle: {
        width: 1,
      },
    },
    {
      label: {
        padding: 4,
        fontSize: 10,
        lineHeight: 10,
        borderRadius: 32,
        color: '#3E3E3E',
        backgroundColor: '#89AFD2',
        overflow: 'breakAll',
        fontWeight: 'normal',
        distance: 2,
        align: 'right',
        position: 'left',
      },
      itemStyle: {
        // opacity:
      },
      symbolSize: 0,
    },
    {
      label: {
        // padding: [-9, 0, 0, 0],
        lineHeight: 8,
        fontSize: 7,
        backgroundColor: '#fff',
        color: '#4E7293',
        distance: -28,
        position: 'right',
        overflow: 'breakAll',
      },
    },
    {
      label: {
        fontSize: 7,
        color: '#4E7293',
        distance: -4,
        position: 'right',
        overflow: 'breakAll',
      },
    },
  ];
  const gridOption = {
    top: 0.01,
    left: 0.19,
    bottom: 0.01,
    right: 0.142,
  };

  function extraStyleHanlder(data: any, chartInstance: any) {
    const instance = chartInstance;
    const containerWidth = instance.getWidth();
    const treeHeight = containerWidth * (1 - (gridOption.left + gridOption.right));// 减去left， right
    // 第2层的最大宽度还是基于某个百分比
    const maxSecondLevelLabelWidth = treeHeight * 0.4;
    const leafLabelWidth = treeHeight * 0.2;
    dfsStyle(data, 1, 0, maxSecondLevelLabelWidth);
    dfsStyle(data, 2, 0, leafLabelWidth);
    function dfsStyle(node: any, targetDepth: number, depth = 0, maxLabelWidth: number) {
      if (!node) {
        return;
      }
      // eslint-disable-next-line no-unused-expressions
      node?.children?.forEach((item: any) => {
        if (depth === targetDepth) {
          const curWidth = getWordsWidth(item.name, { font: `${nodeStyle[targetDepth + 1].label.fontSize}px sans-serif` });
          // (item).label.width = curWidth;
          if (curWidth > maxLabelWidth) {
            // console.log(Object.getOwnPropertyDescriptor((item).label, 'width'));
            (item).label = {
              ...(item).label,
              width: maxLabelWidth,
            };
          }
        } else {
          dfsStyle(item, targetDepth, depth + 1, maxLabelWidth);
        }
      });
    }
  }
  function symbolSize(_value: any, param: any) {
    if (param.treeAncestors.length === 2 || param.treeAncestors.length === 3) {
      return 6;
    }
    return 0;
  }
  return (
    <BusinessTreeChart
      {...props}
      componmentKey={componmentKey}
      nodeStyle={nodeStyle}
      extraStyleHanlder={extraStyleHanlder}
      gridOption={gridOption}
      symbolSize={symbolSize}
    />);
}

// 20 . --发明专利主要IPC分类号分布
export function PatentChartpatentTypeIPCCategoricalDistributionGraph(
  props: BusinessTreeChartProps,
) {
  const componmentKey = {
    function_key: 'patent_type_IPC_categorical_distribution_graph',
    class_name: '/chart/patent',
  };
  const nodeStyle = [
    {
      label: {
        color: '#4E7293',
        fontSize: 12,
        // backgroundColor: '#4E7293',
        // padding: [6, 7, 4],
        fontWeight: 'normal',
        borderRadius: 32,
        lineHeight: 14,
        width: 105 - 14,
        align: 'center',
        distance: 55,
        position: 'insideRight',
        overflow: 'break',
      },
      lineStyle: {
        width: 1,
      },
    },
    {
      label: {
        // padding: 4,
        fontSize: 10,
        lineHeight: 10,
        color: '#3E3E3E',
        backgroundColor: 'transparent',
        overflow: 'breakAll',
        fontWeight: 'normal',
        // distance: 8,
        offset: [0, 0],
        align: 'right',
        position: 'top',
      },
      itemStyle: {
        // opacity:
      },
      symbolSize: 0,
    },
    {
      label: {
        fontSize: 8,
        color: '#4E7293',
        distance: 2,
        position: 'right',
        overflow: 'breakAll',
      },
    },
    {
      label: {
        fontSize: 8,
        color: '#4E7293',
        distance: 2,
        position: 'left',
        overflow: 'breakAll',
        backgroundColor: '#fff',
        // offset: [-60, 0],
      },
    },
  ];
  const gridOption = {
    top: 0,
    left: 0.18,
    bottom: 0,
    right: 0.39,
  };
  function extraStyleHanlder(data: any, chartInstance: any) {
    const instance = chartInstance;
    const containerWidth = instance.getWidth();
    const treeHeight = containerWidth * (1 - (gridOption.left + gridOption.right));// 减去left， right
    // 第2层的最大宽度还是基于某个百分比
    const maxSecondLevelLabelWidth = treeHeight * 0.4 - 4;
    const leafLabelWidth = treeHeight * 0.89;
    dfsStyle(data, 0, 0, maxSecondLevelLabelWidth);
    dfsStyle(data, 2, 0, leafLabelWidth);
    function dfsStyle(node: any, targetDepth: number, depth = 0, maxLabelWidth: number) {
      if (!node) {
        return;
      }
      // eslint-disable-next-line no-unused-expressions
      node?.children?.forEach((item: any) => {
        if (depth === targetDepth) {
          // item.name = item.name.replace(/；/g, '');
          const curWidth = getWordsWidth(item.name, { font: `${nodeStyle[targetDepth + 1].label.fontSize}px sans-serif` });
          // console.log(item.name, curWidth);
          (item).label.width = curWidth;
          if (curWidth > maxLabelWidth) {
            // console.log(Object.getOwnPropertyDescriptor((item).label, 'width'));
            // (item).label.width = maxSecondLevelLabelWidth;
            (item).label = {
              ...(item).label,
              width: maxLabelWidth,
            };
          }
        } else {
          dfsStyle(item, targetDepth, depth + 1, maxLabelWidth);
        }
      });
    }
  }

  function symbolSize(_value: any, param: any) {
    if (param.treeAncestors.length === 5) {
      return 0;
    }
    return 6;
  }
  function labelLayout(params: any, ins: any) {
    const { labelRect } = params;
    const conWidth = ins.getWidth();
    if (labelRect.x + labelRect.width > conWidth) {
      return {
        // x: conWidth - (labelRect.x + labelRect.width),
      };
    }
    return params;
  }
  return (
    <BusinessTreeChart
      {...props}
      componmentKey={componmentKey}
      nodeStyle={nodeStyle}
      extraStyleHanlder={extraStyleHanlder}
      gridOption={gridOption}
      symbolSize={symbolSize}
      labelLayout={labelLayout}
      edgeForkPosition="40%"
    />);
}
