import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

function BarChart(props) {
  const { data, labelRotate = 0, yName, left = 55, bottom = 80, unit, showLegend = false } = props;
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!data) return;
    const { xAxis, yAxis } = data;
    if (!Array.isArray(yAxis)) return;
    const legend = yAxis.map((item, index) => (
      {
        name: item.name,
        itemStyle: {
          color: '#1961F5',
        },
      }
    ));
    const newData = yAxis[0]?.value.map((item, index) => (
      {
        value: item,
        itemStyle: {
          color: '#1961F5',
        },
      }
    )) ?? [];

    const option = {
      tooltip: {
        formatter: params => {
          const str = `<div>${params.marker}${params.seriesName}：${params.data.value}${unit}</div>`;
          return str;
        },
      },
      grid: {
        top: 36,
        right: 0,
        bottom,
        left,
      },
      legend: {
        show: showLegend,
        bottom: 0,
        itemWidth: 8,
        itemHeight: 10,
        symbol: 'rect',
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: legend,
      },
      xAxis: [
        {
          type: 'category',
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)',
            },
          },
          axisLabel: {
            rotate: labelRotate,
            color: '#7B8796',
            fontSize: 12,
          },
          data: xAxis,
        },
      ],
      yAxis: [
        {
          name: yName,
          nameLocation: 'end',
          nameTextStyle: {
            align: 'right',
            padding: [0, 8, 0, 0],
          },
          type: 'value',
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: '#7B8796',
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              color: '#EBEBEB',
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          name: yAxis[0].name,
          type: 'bar',
          barMaxWidth: 12,
          data: newData,
          label: {
            show: true,
            color: '#7B8796',
            position: 'top',
          },
        },
      ],
    };
    setOptions(option);
  }, [data]);

  return (
    <ReactEcharts
      option={options}
      notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default BarChart;
