/*
 *Author: zhaochenyu
 *Description: 柱状图校验函数
 *Date: 2021/09/30
*/
import { isObject, isArray } from '../../utils/tool';

export function checkBarChartData(data: any) {
  let resData: any = {
    xAxis: [],
    yAxis: [],
  };
  if (data && isObject(data)) {
    const { xAxis, yAxis } = data;
    let newXAxis = [];
    let newYAxis = [];
    if (xAxis && isArray(xAxis)) {
      newXAxis = xAxis;
    }
    if (yAxis && isArray(yAxis)) {
      newYAxis = yAxis.map((item: any) => ({
        ...item,
        value: isArray(item.value) ? item.value : [],
        unit: item.unit || '',
      }));
    }
    resData = {
      ...data,
      ...resData,
      xAxis: newXAxis,
      yAxis: newYAxis,
    };
  }
  return resData;
}

export function handleWithDarkColor(color: string[]): any[] {
  return color.map(item => ({
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: item, // 0% 处的颜色
    }, {
      offset: 1, color: 'rgba(0,184,252,0)', // 100% 处的颜色
    }],
    global: false,
  }));
}
