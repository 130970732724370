import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import CustomListFilter from '../../../../../CustomListFilter';
import { useGetResearchInstitutionQuery } from '../../../../../../service/companyDetail';
import { FilterIcon } from '../../../../../CustomTableIcon';
import style from './index.module.less';

const levelList = [
  { text: '国家级', value: '国家级' },
  { text: '省级', value: '省级' },
];

function CreativePlatformTable(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [pitchLevel, setPitchLevel] = useState([]);

  const { data: tableData, isFetching: isTableFetching } = useGetResearchInstitutionQuery({
    company_id: companyId,
    level_list: pitchLevel.includes('all') ? [] : pitchLevel,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const tableColumns = () => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'level') {
        return {
          ...item,
          width: 120,
          ellipsis: true,
          filterIcon: <FilterIcon />,
          filteredValue: pitchLevel?.length > 0 ? pitchLevel : ['all'],
          filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
          filters: levelList,
          filterResetToDefaultFilteredValue: true,
        };
      }
      if (item.dataIndex === 'province') {
        return {
          ...item,
          width: 100,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'type') {
        return {
          ...item,
          width: 140,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'support_unit') {
        return {
          ...item,
          width: 300,
        };
      }
      if (item.dataIndex === 'support_unit') {
        return {
          ...item,
          ellipsis: true,
        };
      }
      return {
        ...item,
        ellipsis: true,
      };
    })
  );

  const tableEl = () => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={tableColumns()}
          locale={{ emptyText: ' ' }}
          title={() => (
            <>
              <span>{tableData?.title}</span>
              <span className={style.count}>{tableData?.count}</span>
            </>
          )}
          strip
          loading={isTableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }, filter) => {
            setPitchLevel(filter.level);
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  };

  return tableEl();
}

export default CreativePlatformTable;
