import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Radio, Space, Popover } from 'antd';
import CustomListFilter from '../../../../../CustomListFilter';
import { useGetInnovationPlatformPenetrationQuery } from '../../../../../../service/companyDetail';
import { FilterIcon } from '../../../../../CustomTableIcon';
import { openNewPage } from '../../../../../../utils/urlMethods';
// import Container from '../../../Container';
import style from './index.module.less';

const levelList = [
  { text: '国家级', value: '国家级' },
  { text: '省级', value: '省级' },
];

function InvestPenetrateTable(props) {
  const { companyId, preventPageto } = props;
  // 排序
  // const [sortValue, setSortValue] = useState(null);
  const [pitchLevel, setPitchLevel] = useState([]);
  const [stockLevel, setStockLevel] = useState('0');
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const res = useGetInnovationPlatformPenetrationQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
    level_list: pitchLevel.includes('all') ? [] : pitchLevel,
    penetration_grade: stockLevel === '0' ? '1,3' : stockLevel,
  }, {
    skip: !companyId,
  });
  const { data: tableData, isFetching: isTableFetching } = res;
  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index' && !preventPageto) {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'name' && !preventPageto) {
        return {
          ...item,
          width: 300,
        };
      }
      if (item.dataIndex === 'support_unit' && !preventPageto) {
        return {
          ...item,
          width: 300,
        };
      }
      if (item.dataIndex === 'investee' && !preventPageto) {
        return {
          ...item,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('companyDetail', { id: record.investee_id })}
            >
              {text}
            </div>
          ),
        };
      }
      if (item.dataIndex === 'investor' && !preventPageto) {
        return {
          ...item,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('companyDetail', { id: record.investor_id })}
            >
              {text}
            </div>
          ),
        };
      }
      if (item.dataIndex === 'level') {
        return {
          ...item,
          width: 120,
          ellipsis: true,
          filterIcon: <FilterIcon />,
          filteredValue: pitchLevel?.length > 0 ? pitchLevel : ['all'],
          filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
          filters: levelList,
          filterResetToDefaultFilteredValue: true,
        };
      }
      if (item.dataIndex === 'equity_penetration_hierarchy') {
        return {
          ...item,
          width: 130,
          filteredValue: stockLevel,
          filterIcon: <FilterIcon />,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, filters, clearFilters }) => (
            <div className={style.list}>
              <Radio.Group
                value={selectedKeys[0]}
                options={filters}
                style={{ display: 'block' }}
                onChange={e => {
                  setSelectedKeys([e.target.value]);
                  confirm();
                }}
              >
                <Space direction="vertical">
                  {
                    filters.map(it => <Radio key={it.value} value={it.value}>{it.label}</Radio>)
                  }
                </Space>
              </Radio.Group>
            </div>
          ),
          filters: [{ label: '不限', value: '0', text: '不限' }, { label: '一级', value: '1', text: '一级' }, { label: '二级', value: '2', text: '二级' }, { label: '三级', value: '3', text: '三级' }],
          filterResetToDefaultFilteredValue: true,
        };
      }
      if (item.dataIndex === 'province') {
        return {
          ...item,
          width: 90,
        };
      }
      if (item.dataIndex === 'legal_person') {
        return {
          ...item,
          width: 120,
          sorter: true,
          ellipsis: true,
        };
      }
      return {
        ...item,
        ellipsis: true,
      };
    })
  ), [tableData, preventPageto, pitchLevel]);

  const table = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return <Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={tableColumns}
        locale={{ emptyText: ' ' }}
        title={() => (
          <>
            <span className={style.tableTitle}>{tableData?.title}</span>
            <Popover content='穿透该企业1、2、3级子公司成立创新平台情况' placement="right" overlayClassName={style.pop}>
              <QuestionCircleOutlined />
            </Popover>
            <span className={style.count}>{tableData?.count}</span>
          </>
        )}
        strip
        loading={isTableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }, filters, stort) => {
          // if (stort.order) {
          //   setSortValue('desc');
          // } else {
          //   setSortValue(null);
          // }
          if (filters.level) {
            setPitchLevel(filters.level);
          }
          setStockLevel(filters.equity_penetration_hierarchy);
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />;
    }
    return null;
  }, [tableData]);

  return <div style={{ marginTop: '20px' }}>{table}</div>;
}

export default InvestPenetrateTable;
