/*
 *Author: zhaochenyu
 *Description: 最新动态-新闻动态，企业服务政策推荐
 *Date: 2021/05/24
*/

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Spin } from 'antd';
import { getCompanyNews, getPolicyRecomment } from '../../../../../api/companyDetail';
import Container from '../../Container';
import NewsDynamic from '../NewsDynamic';

function NewsInfomation(props) {
  const { companyId, parent, detail, companyName } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const condition = useRef({
    page_size: 5,
    page_number: 1,
  });

  const getList = (p) => {
    condition.current = {
      ...condition.current,
      ...p,
    };
    switch (parent) {
      case '最新动态':
        setLoading(true);
        getCompanyNews({
          company_id: companyId,
          company_name: companyName,
          ...condition.current,
        }).then(res => {
          if (res.status === '00000') {
            setData({
              count: res.data.data_count,
              data: res.data.data_list,
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '企业服务':
        setLoading(true);
        getPolicyRecomment({
          company_id: companyId,
          company_name: companyName,
          ...condition.current,
        }).then(res => {
          if (res.status === '00000') {
            setData({
              count: res.data.data_count,
              data: res.data.data_list,
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      default: break;
    }
  };

  useEffect(() => {
    getList(condition.current);
  }, []);

  const news = useMemo(() => (
    Object.keys(data).length > 0 && (<NewsDynamic data={data} getList={getList} type="news" />)
  ), [data]);

  const news1 = useMemo(() => (
    Object.keys(data).length > 0 && data.data.length > 0 && (
      <Container id="新闻动态" num={data.count || 0}>
        <Spin tip="搜索中，请稍候...." spinning={loading}>
          <NewsDynamic data={data} getList={getList} type="news" />
        </Spin>
      </Container>
    )
  ), [data]);

  const policy = useMemo(() => (
    Object.keys(data).length > 0 && (<NewsDynamic data={data} size={5} getList={getList} type="policy" />)
  ), [data]);

  // eslint-disable-next-line no-nested-ternary
  return (parent === '最新动态' ? (
    detail ? (
      <Container id="新闻动态" num={data.count || 0}>
        <Spin tip="搜索中，请稍候...." spinning={loading}>
          {news}
        </Spin>
      </Container>) : news1
  ) : (
    <Container id="政策推荐" num={data.count || 0}>
      <Spin tip="搜索中，请稍候...." spinning={loading}>
        {policy}
      </Spin>
    </Container>
  ));
}

export default NewsInfomation;
