/*
 *Author: zhaochenyu
 *Description: 柱状图样式文件
 *Date: 2021/09/30
*/

const chartFontSize = {
  small: {
    xFontSize: 12,
    yFontSize: 12,
    labelFontSize: 8,
    legnedFontSize: 10,
  },
  middle: {
    xFontSize: 14,
    yFontSize: 14,
    labelFontSize: 12,
    legnedFontSize: 12,
  },
  large: {
    xFontSize: 16,
    yFontSize: 16,
    labelFontSize: 14,
    legnedFontSize: 14,
  },
};

const modeStyle = {
  light: {
    toDataURLBackground: '#fff',
    color: ['#0D3B66', '#4E7293', '#A4C3B2', '#C5D5EA', '#CCE3DE', '#EAD1C1', '#E9C582', '#CBC7DB', '#EE964B'],
    labelColor: '#333',
    splitLineColor: '#EBEBEB',
    xColor: '#919191',
    yColor: '#919191',
    xAxisLineColor: '#333',
    yAxisLineColor: '#333',
    legendColor: '#919191',
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',
  },
  dark: {
    toDataURLBackground: '#fff',
    color: ['#00F2FE', '#3E83F7', '#1D16F4', '#5170CA', '#390D97', '#E65226', '#FEC52A'],
    labelColor: '#fff',
    splitLineColor: '#555',
    xColor: '#B4B4B4',
    yColor: '#B4B4B4',
    xAxisLineColor: '#B4B4B4',
    yAxisLineColor: '#B4B4B4',
    legendColor: '#919191',
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
  },
};

export {
  chartFontSize,
  modeStyle,
};
