/**
 * Api状态切片
 */

/**
 * @typedef {import("@reduxjs/toolkit/dist/query/fetchBaseQuery").FetchBaseQueryArgs}  FetchBaseQueryArgs
 */

/**
 * @typedef {{
 *   originalArgs?: unknown;
 *   data?: any;
 *   currentData?: any;
 *   error?: unknown;
 *   requestId?: string;
 *   endpointName?: string;
 *   startedTimeStamp?: number;
 *   fulfilledTimeStamp?: number;
 *   isUninitialized: boolean;
 *   isLoading: boolean;
 *   isFetching: boolean;
 *   isSuccess: boolean;
 *   isError: boolean;
 * }}  UseQueryStateResult
 */

/**
 * @typedef {{
 *   skip?: boolean;
 *   pollingInterval?: number;
 *   refetchOnReconnect?: boolean;
 *   refetchOnFocus?: boolean;
 *   refetchOnMountOrArgChange?: boolean | number;
 *   selectFromResult?: (result: UseQueryStateResult) => any;
 * }} UseQueryOption
 */

/**
 * @typedef {{
 *   originalArgs?: any;
 *   data?: any;
 *   currentData?: any;
 *   error?: any;
 *   requestId?: string;
 *   endpointName?: string;
 *   startedTimeStamp?: number;
 *   fulfilledTimeStamp?: number;
 *   isUninitialized: boolean;
 *   isLoading: boolean;
 *   isFetching: boolean;
 *   isError: boolean;
 *   refetch: () => void;
 * }} UseQueryResult
 */

/**
 * @typedef {
 *   (function(*, *, *): Promise<{error: {status: number, data: unknown}
 * | {status: "FETCH_ERROR", data?: undefined, error: string}
 * | {status: "PARSING_ERROR", originalStatus: number, data: string, error: string}
 * | {
 *     status: "CUSTOM_ERROR", data?: unknown, error: string},
 *     data?: undefined, meta?: FetchBaseQueryMeta
 *  }
 * |{error?: undefined, data: unknown, meta?: FetchBaseQueryMeta}>)|*
 * } BaseQueryResult
 */

/**
 * @typedef {(any) => UseQueryResult} QueryOptions
 */
/**
 * @typedef {[trigger: function]} useMutationResult
 */

import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
// import { Mutex } from 'async-mutex';
import { Modal } from 'antd';
import * as authUtil from '../utils/auth';
// eslint-disable-next-line import/extensions
import mutex from '../utils/mutex';
import { setUser } from '../actions/app';
import { oauthIp } from '../utils/url';
// import { synchronizationUserInfo } from '../utils/sessionToLocal';
import { getUrlPrefix } from '../utils/tool';

// 基地址

// 基地址，总以/进行结尾
const { REACT_APP_BASE_URL } = process.env;
const concatSlash = str => {
  if (!str) return '/';
  if (str[str.length - 1] !== '/') {
    return `${str}/`;
  }
  return str;
};
const BASE_URL = concatSlash(REACT_APP_BASE_URL);
const ENV_PREFIX = getUrlPrefix({ slash: false });
const API_URL = 'industry_knowledge_engine/v2';

// const mutex = new Mutex();
/**
 * baseQuery相关的配置，默认获取Token
 * @type {FetchBaseQueryArgs}
 */
const options = {
  baseUrl: `${BASE_URL}${ENV_PREFIX ? concatSlash(ENV_PREFIX) : ''}${API_URL}`,
  prepareHeaders(headers) {
    // headers各个切片中配置读取后生成的header，相当于是拦截，可在这里覆盖之前的配置
    if (!headers.get('Authorization')) {
      // const token = tokenSelector(getState());
      const token = authUtil.getAccessToken() || '';
      if (token) {
        if (process.env.NODE_ENV !== 'production') {
          // headers.set('Authorization', `Bearer Wao8KD06jLKL9gjaSACg1OIp581c4n5jug8UoqIgDW`);
          // headers.set('user', JSON.stringify({"roleIds": [15]}));
        }
        headers.set('Authorization', `${token}`);
      }
    }
    return headers;
  },
  credentials: 'include',
  mode: 'cors',
};

const baseQuery = fetchBaseQuery(options);
// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

const refreshLogin = (method, uri, body, release) => {
  const url = uri;
  method = method.toUpperCase();
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      Authorization: 'Basic TkpwNWdBZkZEV0ZLdUZWeXVGQ1A0QkJLOkNIc01FRVFXdjBZYkQ5bnRxdkU0R2ZNNTk1SlNQTDc3VkxDT2VpQmtmYnpjM3BhRw==',
    },
    body: formData,
  }).then((res) => {
    if (res.status === 200) {
      const datas = res.json().then((d) => {
        if (d.status === 'A0317' || d.status === 'A0303' || d.status === 'A0207' || d.status === 'A0305') {
          return Promise.reject(new Error('Unauthorized.'));
        }
        if (d.access_token) {
          const maxTime = new Date().getTime() + (d.expires_in * 1000 - 600 * 1000);
          localStorage.setItem('maxTime', maxTime);
          localStorage.setItem('token', `${d.token_type} ${d.access_token}`);
          localStorage.setItem('rft', `${d.refresh_token}`);
          // synchronizationUserInfo({ token: `${d.token_type} ${d.access_token}`, maxTime, rft: d.refresh_token });
          if (localStorage.getItem('rft')) {
            localStorage.setItem('rft', `${d.refresh_token}`);
          }
        }
        if (release) {
          release();
        }
        return d;
      });
      return datas;
    }
    localStorage.setItem('token', '');
    return undefined;
  }).catch(() => {
    localStorage.setItem('token', '');
    return undefined;
  });
};

const postFormLoginRefresh = (url, body, release) => refreshLogin('POST', url, body, release);

async function refreshToken(release) {
  await postFormLoginRefresh(`${oauthIp}/token`, {
    grant_type: 'refresh_token',
    refresh_token: localStorage.getItem('rft') || '',
  }, release);
}

/**
 * OAuth + BaseQuery定制 示例
 * ## 简介：
 * 对fetchBaseQuery的封装，提取数据，这里可以加入路由拦截，判断是否鉴权成功
 * 目前只有Oauth系统是成功后不返回data.status，需要单独拦截处理；
 * 其他情况会判断响应体中的业务status，判断是否提取数据
 *
 * ## 错误处理：
 * * 4xx之外的错误码将进行重试
 * * 如果401，那么判断是否有refreshToken，如果存在，则刷新token，重新请求；不存在，则弹出消息，返回登录页
 *
 * ## 注意:
 * ！！！ 由于使用fetchBaseQuery，其内部自己捕获了错误进行处理，所以不使用try, catch，若使用其他实现，需要处理
 *
 * baseQuery的运行逻辑如下：
 *
 * 1.如果请求成功，那么直接返回数据内容；
 * ```
 * {
 *   data: any, // 响应体内容
 *   meta: {request, response} // 一些请求相关的参数
 * }
 * ```
 * 2.如果请求失败，包裹一层，携带responseStatus，结构
 * ```
 * {
 *   error: {
 *     status: number // 响应状态码
 *     data: any // 响应体内容
 *   },
 *   meta: {request, response} // 请求相关的参数
 * }
 * ```
 * @param {FetchBaseQueryArgs} options
 * @return {BaseQueryResult}
 */
const baseQueryWithInterceptor = retry(
  async (arg, api, extraOption) => {
    // 如果异步锁打开，等待
    await mutex.waitForUnlock();

    // refresh token by time
    const maxTime = localStorage.getItem('maxTime');
    const nowTime = new Date().getTime();
    const token = localStorage.getItem('token');
    const rft = localStorage.getItem('rft');
    if (nowTime >= maxTime && !mutex.isLocked() && token && rft) {
      const release = await mutex.acquire();
      await refreshToken(release);
    }
    await mutex.waitForUnlock();
    // 否则，向下执行
    /**
     * @type {BaseQueryResult}
     */
    const result = await baseQuery(arg, api, extraOption);
    const { meta } = result;

    // ---------------------------- 拦截

    if (arg.interceptor) {
      return arg.interceptor(result, { arg, api, extraOption });
    }

    // ---------------------------- 错误处理

    // 按照js异步规范，错误处理一般放到最前
    // 处理响应错误 响应码指示为错误，但非鉴权失败(401)
    if (result.error) {
      if (typeof result.error.status !== 'string') {
        const { error: { data } } = result;
        // 如果错误代码符合产业通中的业务错误定义，那么弹窗并清理 token
        if (data.status === 'A0317' || data.status === 'A0303' || data.status === 'A0207' || data.status === 'A0331') {
          if (localStorage.getItem('token')) {
            localStorage.setItem('token', '');
            Modal.warning({
              centered: true,
              content: '用户信息已过期，请重新登录！',
              okText: '重新登录',
              keyboard: false,
              onOk: () => {
                if (window.location.href.split('#')[1] === '/homePage') {
                  window.location.reload();
                } else {
                  api.dispatch(setUser(''));
                  window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
                }
                localStorage.clear();
                // localStorage.removeItem('rft');
                // localStorage.removeItem('rftChanyeMax');
              },
              width: 416,
            });
          }
        }
      }

      // 最后，将错误返回
      const { status: _status } = result.error;
      if (_status >= 400 && _status < 500) {
        // 响应鉴权失败
        retry.fail(result.error);
      }
      return result;
    }

    // ---------------------------- 数据格式处理
    // 如果error为宽松false，那么data为宽松true
    const { data } = result;
    // 如果响应体中业务状态码正常，省略一层判断，提取数据
    if (Object.is(data.status, '00000')) {
      return { data: data.data, meta };
    }
    // 否则，返回错误信息
    const _error = {
      status: meta.response.status,
      data,
    };
    retry.fail(_error);
    return {
      error: _error,
      meta,
    };
  },
  {
    maxRetries: 3,
  },
);

// 在此定义和创建 api切片，初始化整个rtk query
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithInterceptor,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: 15,
  keepUnusedDataFor: 60,
});

// selector 这里写

export default apiSlice.reducers;

export {
  options,
  baseQuery,
  // baseQueryWithRetry,
  baseQueryWithInterceptor,
};
