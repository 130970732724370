import React, { useState } from 'react';
import { Tree, Select } from 'antd';
import PotentialCompany from './PotentialCompany';
import { useGetAreaPlugMarqueeV2Query } from '../../../../../service/interactive-v2';
import style from './index.module.less';

const tabs = [
  { title: '已获资质企业' },
  { title: '培育企业' },
];

const Cultivate = (props) => {
  const { cityCode, cityName } = props;
  const [area, setArea] = useState({ code: cityCode, name: cityName });
  const { code, name } = area;
  // 获取地区
  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query();
  const upadteArea = (value, info) => {
    setArea({
      code: info.node.code,
      name: info.node.title,
    });
  };
  return (
    <div>
      <div className={style.city}>
        <p>区域：</p>
        <Select
          value={name}
          style={{
            width: 220,
            marginRight: 12,
          }}
          dropdownRender={() => (
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
              <Tree
                defaultExpandedKeys={['000000']}
                checkedKeys={[code]}
                onSelect={upadteArea}
                treeData={regionTree.map(item => ({ ...item, disabled: true }))}
              />
            </div>
          )}
        />
      </div>
      <PotentialCompany {...props} area={area} />
    </div>
  );
};

export default Cultivate;
