/*
* Author: zhangmeng
* Description: ''
* Date: 2021/05/024
*/

import React, { useEffect, useRef } from 'react';
import G6 from '@antv/g6';

function MindMap(props) {
  const { chartData } = props;
  const treeRef = useRef(null);
  let graph = null;

  useEffect(() => {
    if (!graph) {
      G6.registerNode('rootNode', {
        draw: (cfg, group) => {
          let labelName = '';
          let rootX = 3;
          const arr = cfg.name.split('');
          while (arr.length > 8) {
            rootX = 10;
            labelName += `${arr.splice(0, 8).join('')}\n`;
          }
          labelName = `${labelName}${arr.join('')}`;
          if (labelName.length > 16) {
            labelName = `${labelName.substring(0, 16)}...`;
          }

          let leftText = '';
          let rightText = '';
          if (cfg.children && cfg.children.length) {
            cfg.children.forEach(item => {
              if (item.id.split('-')[0] !== 'customer') {
                leftText = '供应商';
              } else if (item.id.split('-')[0] !== 'supplier') {
                rightText = '客户';
              }
            });
          }
          const shape = group.addShape('rect', {
            draggable: true,
            attrs: {
              x: -62.5,
              y: -20,
              width: 125,
              height: 40,
              fill: cfg.style.fill,
              stroke: '#fff',
              radius: 20,
              shadowColor: '#D5D5D5',
              shadowBlur: 10,
            },
          });
          group.addShape('text', {
            attrs: {
              textBaseline: 'center',
              y: rootX,
              x: 0,
              text: labelName,
              fill: '#fff',
              textAlign: 'center',
              fontWeight: 600,
            },
          });
          group.addShape('text', {
            attrs: {
              textBaseline: 'center',
              y: -10,
              x: -110,
              lineHeight: 10,
              text: leftText,
              fill: '#333',
              fontWeight: 'bold',
            },
          });
          group.addShape('text', {
            attrs: {
              textBaseline: 'center',
              y: -10,
              x: 70,
              lineHeight: 10,
              text: rightText,
              fill: '#333',
              fontWeight: 'bold',
            },
          });
          return shape;
        },
      });
      G6.registerEdge('newEdge', {
        draw(cfg, group) {
          const { startPoint } = cfg;
          const { endPoint } = cfg;
          const type = cfg.id.split(':')[0].split('-')[0];
          const target = cfg.target.split('-')[0];
          let lineColor = '';
          let x = null;
          if (type === 'root') {
            if (target === 'supplier') {
              x = endPoint.x + 100;
            } else if (target === 'customer') {
              x = endPoint.x - 100;
            }
            lineColor = '#3E5C76';
          } else if (type === 'supplier') {
            lineColor = '#F4D394';
            x = endPoint.x;
          } else {
            lineColor = '#A4C3B2';
            x = endPoint.x;
          }
          const shape = group.addShape('path', {
            attrs: {
              stroke: lineColor,
              path: [
                ['M', startPoint.x, startPoint.y],
                ['L', endPoint.x / 3 + (2 / 3) * startPoint.x, startPoint.y],
                ['L', endPoint.x / 3 + (2 / 3) * startPoint.x, endPoint.y],
                ['L', x, endPoint.y],
              ],
            },
          });
          return shape;
        },
      });
      const tooltip = new G6.Tooltip({
        itemTypes: ['node'],
        getContent(e) {
          return `<div>${e.item._cfg.model.name}</div>`;
        },
      });
      graph = new G6.TreeGraph({
        container: treeRef.current,
        width: treeRef.current.clientWidth,
        height: treeRef.current.clientHeight,
        pixelRatio: 2,
        plugins: [tooltip],
        modes: {
          default: [{
            type: 'collapse-expand',
            onChange: function onChange(item, collapsed) {
              const data = item.get('model');
              data.collapsed = collapsed;
              return true;
            },
          }, 'drag-canvas', 'zoom-canvas'],
        },
        defaultNode: {
          size: 10,
          style: {
            fill: '#40a9ff',
            stroke: '#096dd9',
          },
          anchorPoints: [
            [0, 0.5],
            [1, 0.5],
          ],
        },
        defaultEdge: {
          shape: 'polyline',
          style: {
            stroke: '#A3B1BF',
          },
        },
        layout: {
          type: 'mindmap',
          direction: 'H',
          getHeight: () => 10,
          getWidth: () => 10,
          getVGap: () => 10,
          getHGap: () => {
            // const idArr = d.id.split('-');
            let lineWidth = null;
            // if (idArr.length === 3) {
            //   lineWidth = 60;
            // } else {
            lineWidth = 125;
            // }
            return lineWidth;
          },
          getSide: function getSide(d) {
            const type = d.id.split('-')[0];
            if (type === 'supplier') return 'left';
            return 'right';
          },
        },
      });

      let centerX = 0;
      graph.node((node) => {
        const type = node.id.split('-')[0];
        let labelName = '';
        if (type === 'root') {
          centerX = node.x;
        } else if (node.name.length > 6) {
          labelName = `${node.name.substring(0, 6)}...`;
        } else {
          labelName = node.name;
        }
        let posi = '';
        let size = null;
        let labelColor = '';
        if (node.x > centerX) {
          if (node.depth === 1) {
            posi = 'left';
            size = 14;
          } else {
            size = 12;
            posi = 'right';
          }
          labelColor = '#A4C3B2';
        } else {
          if (node.depth === 1) {
            posi = 'right';
            size = 14;
          } else {
            posi = 'left';
            size = 12;
          }
          labelColor = '#F4D394';
        }

        return {
          size: 10,
          type: type === 'root' ? 'rootNode' : 'circle',
          style: {
            fill: type === 'root' ? '#3E5C76' : '#fff',
            stroke: labelColor,
            cursor: 'pointer',
          },
          stateStyles: {
            hover: {
              fill: labelColor,
              shadowColor: '#D5D5D5',
              shadowBlur: 10,
            },
          },
          label: labelName,
          labelCfg: {
            position: posi,
            style: {
              fill: node.children && node.children.length
              > 0 && type === 'root' ? '#fff' : '#333',
              fontSize: size,
            },
          },
        };
      });
      graph.edge(() => ({ type: 'newEdge' }));
      graph.on('node:mouseenter', (evt) => {
        const { item } = evt;
        graph.setItemState(item, 'hover', true);
      });

      graph.on('node:mouseleave', (evt) => {
        const { item } = evt;
        graph.setItemState(item, 'hover', false);
      });

      graph.read(chartData);
      graph.fitView([20, 20]);
      graph.fitCenter();
    }
  }, []);

  return (
    <div ref={treeRef} style={{ width: '100%', height: '100%' }} />
  );
}

export default MindMap;
