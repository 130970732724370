import React, { useEffect } from 'react';
import { ECharts } from '@sqke/design';
import { getOption } from '@sqke/parser';
import { Spin } from 'antd';
import ChartNoData from '../../../ChartNoData';
import { useGetPatentStatisticsValueStatisticsQuery } from '../../../../../../service/companyDetail';

const ValueStatistics = ({ companyId, grade }) => {
  const { data, isFetching } = useGetPatentStatisticsValueStatisticsQuery({
    company_id: companyId,
    penetration_grade: grade,
  });
  const option = getOption(
    {
      type: 'pies',
      visualConfig: {
        color: ['#0048DD', '#1961F5', '#1683FF', '#60AAFF', '#60AAFF'],
        coordinateSystem: 'none',
        backgroundColor: '#fff',
        maxPieHeightPercent: 90,
        minPieHeightPercent: 42,
        itemStyle: {
          borderWidth: 2,
          borderColor: '#FFF',
        },
        label: {
          position: 'center',
          show: true,
          fontSize: 12,
          color: '#fff',
        },
      },
    },
    {
      series: data?.series,
    },
  );
  const chart = () => {
    if (!data || data?.series.length) {
      return (
        <ECharts
          option={option}
          style={{ width: '662px', height: '300px' }}
        />
      );
    }
    return <ChartNoData />;
  };
  return (
    <Spin spinning={isFetching} >
      {
        chart()
      }
    </Spin>
  );
};

export default ValueStatistics;
