import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

/**
 * 下划线
 * @param {string} className
 * @returns {JSX.Element}
 * @constructor
 */
function Underline({ className }) {
  return (
    <div className={classnames(styles.underline, className)} />
  );
}

export default Underline;
