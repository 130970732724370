import React, { useState } from 'react';
import { RestOutlined } from '@ant-design/icons';
import { uniqueId } from 'lodash';
import { Modal, Table, Upload, Progress } from 'antd';
import { uploadinGappraisalTable } from '../../../../../../../api/office';
import style from './index.module.less';

const UploadingModal = ({ open, setOpen, setUploadRecordId }) => {
  const [dataSource, setDataSource] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploadComplete, setUploadComplete] = useState([]);

  // 更新表格数据
  const updataDataSource = ({ name, size, add, id }) => {
    if (add) {
      setDataSource(params => (
        [...params, { id, name, schedule: 0, size: `${Math.ceil(size / 1000)}KB` }]
      ));
    }
  };

  // 点击上传
  const handleOk = () => {
    if (!fileList) return;
    const formdata = new FormData();
    const idList = fileList.map((item, index) => {
      const { file, id } = item;
      if (uploadComplete.includes(id)) { return id; }
      formdata.append('file', file);
      uploadinGappraisalTable(formdata, (event) => {
        const { total, loaded } = event;
        setDataSource(params => params.map(it => {
          if (it.id === id) {
            return { ...it, schedule: Math.floor(loaded / total) * 100 };
          }
          return it;
        }));
      }).then(res => {
        if (index === 0) {
          setUploadRecordId(res.data.upload_record_id);
        }
      }).catch(err => {
        throw Error(err);
      });
      return id;
    });
    setUploadComplete(idList);
  };

  // 删除上传列表
  const delUploadList = (id) => {
    setDataSource(params => params.filter(item => item.id !== id));
    setFileList(params => params.filter(item => item.id !== id));
  };

  const handleCancel = () => {
    setOpen(false);
    setDataSource([]);
    setFileList([]);
  };

  const props = {
    name: 'file',
    multiple: true,
    accept: '.xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,',
    fileList: [],
    // onRemove: (file) => {
    //   console.log(file);
    // },
    beforeUpload: (file) => {
      const id = uniqueId();
      setFileList(params => [...params, { file, id }]);
      const { name, size } = file;
      updataDataSource({ name, size, add: true, id });
      return false;
    },
  };

  const columns = [
    { title: '文件名', width: 200, ellipsis: true, dataIndex: 'name', key: 'name' },
    { title: '大小', dataIndex: 'size', ellipsis: true, key: 'size' },
    {
      title: '上传进度',
      width: 130,
      dataIndex: 'schedule',
      ellipsis: true,
      key: 'schedule',
      render: (text) => (
        <Progress percent={text} />
      ),
    },
    {
      title: '操作',
      width: 60,
      dataIndex: 'active',
      ellipsis: true,
      key: 'active',
      render: (text, row) => (
        <RestOutlined onClick={() => { delUploadList(row.id); }} />
      ),
    },
  ];

  const downloadTemplate = () => {
    const a = document.createElement('a');
    a.href = 'https://industry-engine.ks3-cn-beijing.ksyuncs.com/pc/upload-template/wisdom/specialized-new/upload_data.xlsx';
    a.download = '上传模版.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className={style.box}>
      <Modal
        wrapClassName={style.box}
        title="上传企业名单"
        open={open}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="开始上传"
      >
        <div className={style.content}>
          <div className={style.top}>
            <div className={style.button}>
              <button type="button" onClick={downloadTemplate}>下载模板</button>
              <Upload {...props}>
                <button type="button">选择文件</button>
              </Upload>
            </div>
            <span>请先下载模版，支持批量上传，上传格式为excel文件</span>
          </div>
          <div className={style.table}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              scroll={{
                y: 180,
              }}
              // locale={{
              //   emptyText: () => ' ',
              // }}
              locale={{ emptyText: <div>您还没有上传文件，请上传文件</div> }}
            />
          </div>

        </div>
      </Modal>
    </div>

  );
};

export default UploadingModal;
