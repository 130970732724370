import React, { useMemo } from 'react';
import CarouselList from '../../components/CarouselList';
import ReportList from '../../components/ReportList';
import {
  useGetLatestNewsQuery,
  useGetRelatedPolicyQuery,
  useGetSpecialReportListQuery,
} from '../../../../service/special';
import style from './index.module.less';

const Bottom = ({ name, reportData }) => {
  const { data: newList } = useGetLatestNewsQuery({
    label: name,
    size: 20,
  });
  const { data: policyList } = useGetRelatedPolicyQuery({
    label: name,
    size: 20,
  });
  // const reportData = useGetSpecialReportListQuery({
  //   label: name,
  // });
  const report = useMemo(() => (
    <ReportList
      data={reportData?.data}
    />
  ), [reportData]);
  return (
    <ul className={style.box}>
      <li>
        {/* <p>题目</p> */}
        <div className={style.list}>
          <CarouselList
            data={newList?.data_list || []}
            type="new"
            more={false}
          />
        </div>
      </li>
      <li>
        <div className={style.list}>
          <CarouselList
            data={policyList || []}
            type="policy"
            more={false}
          />
        </div>
      </li>
      <li className={style.cover}>
        {
          report
        }
        {/* <p>题目</p>
        <div className={style.recommend}>
          <ul>
            <li>
              <div>
                <p>
                  {reportData?.data?.[0]?.sub_title }
                  {reportData?.data?.[0]?.title }
                </p>
              </div>
              <div>
                <img src={reportData?.data?.[0]?.cover_url} alt="" />
              </div>
            </li>
            <li>
              <div>
                <p>
                  {reportData?.data?.[0]?.sub_title}
                  {reportData?.data?.[0]?.title}
                </p>
              </div>
              <div>
                <img src={reportData?.data?.[0]?.cover_url} alt="" />
              </div>
            </li>
          </ul>
        </div> */}
      </li>
    </ul>
  );
};

export default Bottom;
