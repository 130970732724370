/*
 *Author: zhaochenyu
 *Description: 思维导图
 *Date: 2022/01/06
*/

import React from 'react';
import G6 from '@antv/g6';
import { cloneDeep } from 'lodash-es';
import classNames from 'classnames';
import { MindMapChartProps } from '../basicsType';
import { modeStyle } from './style';
import { checkMindMapChartData } from './checkDataFormat';
import { addWaterMark, getDefaultCanvas } from '../../utils/tool';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-mind-map-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

// const data1 = [{
//   name: '杭州海康威视数字技术股份有限公司',
//   level: 0,
//   children: [
//     {
//       name: '北京市',
//       level: 1,
//       children: [
//         {
//           name: '北京华夏电通科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京华城智云股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京搏维仕科股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京可视化智能科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京天睿空间科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京市博汇科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京正荣网际科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京汉邦高科数字技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京淳中科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京蓝海华业科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京蓝色星际科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '北京蛙视通信技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '新疆维吾尔自治区',
//       level: 1,
//       children: [
//         {
//           name: '新疆美特智能安全工程股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '新疆熙菱信息技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '上海市',
//       level: 1,
//       children: [
//         {
//           name: '上海异瀚数码科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '上海富瀚微电子股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '上海合胜计算机科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '上海飞田通信股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '上海安技智能科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '上海云多科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '四川省',
//       level: 1,
//       children: [
//         {
//           name: '四川君逸数码科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '山东省',
//       level: 1,
//       children: [
//         {
//           name: '山东联科云计算股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '江苏省',
//       level: 1,
//       children: [
//         {
//           name: '江苏亿通高科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '苏州科达科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '江苏慧眼数据科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '无锡卓信信息科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '江西省',
//       level: 1,
//       children: [
//         {
//           name: '江西佳信捷电子股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '广东省',
//       level: 1,
//       children: [
//         {
//           name: '深圳市华尊科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '深圳市诺龙技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '深圳市迪威迅股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '珠海数字动力科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '深圳市万佳安物联科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '深圳亿维锐创科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '广东盈嘉科技工程发展股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '广东艾科技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '广州像素数据技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '广东长宝信息科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '河南省',
//       level: 1,
//       children: [
//         {
//           name: '郑州天迈科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '湖北省',
//       level: 1,
//       children: [
//         {
//           name: '武汉经纬视通科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '武汉微创光电股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '武汉光庭信息技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '山西省',
//       level: 1,
//       children: [
//         {
//           name: '陕西启翔景程电子科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '浙江省',
//       level: 1,
//       children: [
//         {
//           name: '浙江捷尚视觉科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '浙江大华技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '杭州当虹科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '浙江兆久成信息技术股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '浙江浩腾电子科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//     {
//       name: '福建省',
//       level: 1,
//       children: [
//         {
//           name: '厦门蓝斯通信股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '福建汇川物联网技术科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//         {
//           name: '厦门狄耐克智能科技股份有限公司',
//           level: 2,
//           children: [],
//         },
//       ],
//     },
//   ],
// }];

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

function addAlign(data: any, text: string) {
  data.forEach((item: any) => {
    item.align = text;
    if (item.children) {
      addAlign(item.children, text);
    }
  });
}

function addColor(data: any, color: string) {
  data.forEach((item: any) => {
    item.color = color;
    if (item.children) {
      addColor(item.children, color);
    }
  });
}

function handleWithMindMapNodes(data: any) {
  // const copyData = cloneDeep(data);
  const sortData = data.sort((a: any, b: any) => b.children.length - a.children.length);
  const totalCount = data.reduce((pre: any, cur: any) => {
    pre += cur.children.length; return pre;
  }, 0);
  let sum1 = 0;
  let sum2 = 0;
  // let m = 0;
  // let n = 0;
  for (let i = 0; i < sortData.length; i += 1) {
    const num = sortData[i].children.length;
    if (sum1 <= sum2 && (sum1 + num) <= ((totalCount / 2))) {
      sum1 += num;
      addAlign([sortData[i]], 'right');
      // m += 1;
    } else {
      sum2 += num;
      addAlign([sortData[i]], 'left');
      // n += 1;
    }
  }
  // for (let i = 0; i < data.length; i += 1) {
  //   for (let j = 0; j < sortData.length; j += 1) {
  //     if (data[i].name === sortData[j].name) {
  //       data[i] = cloneDeep(sortData[j]);
  //     }
  //   }
  // }
}

function handleWithMindMapData(data: any, colorList: any): any {
  if (data && data.length === 0) return data;
  const copyData = cloneDeep(data);
  let resData = [];
  const childNodes = copyData[0].children;
  childNodes.forEach((item: any, index: number) => {
    addColor([item], colorList[index % colorList.length]);
  });
  if (childNodes.length === 0) {
    resData = copyData;
  } else if (childNodes.length === 1) {
    addAlign(childNodes, 'right');
    childNodes[0].color = colorList;
    resData = copyData;
  } else {
    handleWithMindMapNodes(childNodes);
    resData = copyData;
  }
  return resData;
}

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const MindMapChart = forwardRef((props: MindMapChartProps, ref) => {
  const { mode, data, chartDesc, roam } = props;
  const [hanldeData, setHanldeData] = useState<any>([]);
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bigChartRef: any = useRef(null);
  const bodyRef: any = useRef(null);
  const graph: any = useRef(null);
  const bigGraph: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkMindMapChartData(data || []));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    G6.registerNode(
      'tree-node',
      {
        drawShape: function drawShape(cfg: any, group:any) {
          const rect = group.addShape('rect', {
            attrs: {
              fill: '#fff',
              stroke: cfg.color,
              x: 0,
              y: 0,
              radius: 2,
              width: 1,
              height: 1,
              lineWidth: 1,
            },
            name: 'rect-shape',
          });
          const content = cfg.level === 0 ? `${cfg.name.slice(0, 10)} \n ${cfg.name.slice(10)}` : cfg.name;
          const text = group.addShape('text', {
            attrs: {
              text: content,
              x: 0,
              y: 0,
              fontSize: cfg.level === 0 ? 14 : 12,
              fontWeight: cfg.level === 0 ? 700 : 500,
              textAlign: 'left',
              textBaseline: 'middle',
              fill: modeStyle[mode!].childNodeColor,
            },
            name: 'text-shape',
          });
          const bbox = text.getBBox();
          if (cfg.align === 'right') {
            rect.attr({
              x: 0,
              y: -bbox.height / 2,
              width: bbox.width + 8,
              height: bbox.height + 8,
            });
            text.attr({
              x: 3,
              y: bbox.height / 2 - 1,
            });
          } else if (cfg.level === 0) {
            rect.attr({
              x: -bbox.width / 2,
              y: -bbox.height / 2,
              width: bbox.width + 8,
              height: bbox.height + 12,
              fill: modeStyle[mode!].centerBackground,
              stroke: modeStyle[mode!].centerBorderColor,
            });
            text.attr({
              x: 4,
              y: 6,
              fill: modeStyle[mode!].centerColor,
              textAlign: 'center',
            });
          } else {
            rect.attr({
              x: -bbox.width,
              y: -bbox.height / 2,
              width: bbox.width + 8,
              height: bbox.height + 8,
            });
            text.attr({
              x: -bbox.width + 3,
              y: bbox.height / 2 - 1,
            });
          }
          return rect;
        },
        update: (cfg: any, item: any) => {
          const group = item.getContainer();
          const icon = group.find((e: any) => e.get('name') === 'collapse-icon');
          icon.attr('symbol', cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse);
        },
      },
      'single-node',
    );
    G6.registerNode(
      'tree-node-big',
      {
        drawShape: function drawShape(cfg: any, group:any) {
          const rect = group.addShape('rect', {
            attrs: {
              fill: '#fff',
              stroke: cfg.color,
              x: 0,
              y: 0,
              radius: 2,
              width: 10,
              height: 10,
              lineWidth: 4,
            },
            name: 'rect-shape',
          });
          const content = cfg.level === 0 ? `${cfg.name.slice(0, 10)} \n ${cfg.name.slice(10)}` : cfg.name;
          const text = group.addShape('text', {
            attrs: {
              text: content,
              x: 0,
              y: 0,
              fontSize: cfg.level === 0 ? 68 : 66,
              fontWeight: cfg.level === 0 ? 700 : 500,
              textAlign: 'left',
              textBaseline: 'middle',
              fill: modeStyle[mode!].childNodeColor,
            },
            name: 'text-shape',
          });
          const bbox = text.getBBox();
          if (cfg.align === 'right') {
            rect.attr({
              x: 0,
              y: -bbox.height / 2 - 6,
              width: bbox.width + 36,
              height: bbox.height + 36,
            });
            text.attr({
              x: 18,
              y: bbox.height / 2 - 18,
            });
          } else if (cfg.level === 0) {
            rect.attr({
              x: -bbox.width / 2,
              y: -bbox.height / 2,
              width: bbox.width + 20,
              height: bbox.height + 40,
              fill: modeStyle[mode!].centerBackground,
              stroke: modeStyle[mode!].centerBorderColor,
            });
            text.attr({
              x: 16,
              y: 22,
              fill: modeStyle[mode!].centerColor,
              textAlign: 'center',
            });
          } else {
            rect.attr({
              x: -bbox.width,
              y: -bbox.height / 2,
              width: bbox.width + 36,
              height: bbox.height + 36,
            });
            text.attr({
              x: -bbox.width + 18,
              y: bbox.height / 2 - 10,
            });
          }
          return rect;
        },
        update: (cfg: any, item: any) => {
          const group = item.getContainer();
          const icon = group.find((e: any) => e.get('name') === 'collapse-icon');
          icon.attr('symbol', cfg.collapsed ? G6.Marker.expand : G6.Marker.collapse);
        },
      },
    );
  }, []);

  useEffect(() => {
    if (hanldeData.length === 0) return;
    let roamList: any[] = [];
    if (roam) {
      roamList = ['drag-canvas', 'zoom-canvas'];
    }
    if (graph.current) {
      graph.current.clear();
      graph.current.destroy();
    }
    if (bigGraph.current) {
      bigGraph.current.clear();
      bigGraph.current.destroy();
    }
    const newData = handleWithMindMapData(hanldeData, modeStyle[mode!].childNodeBorderColor);
    const bigData = cloneDeep(newData);
    const graphOption = {
      container: chartRef.current,
      width: chartRef.current.clientWidth,
      height: chartRef.current.clientHeight,
      modes: {
        default: [
          ...roamList,
        ],
      },
      defaultNode: {
        type: 'tree-node',
        anchorPoints: [
          [0, 0.5],
          [1, 0.5],
        ],
      },
      defaultEdge: {
        type: 'cubic-horizontal',
        style: {
          stroke: modeStyle[mode!].connectLineColor,
          lineWidth: 1,
        },
      },
      layout: {
        type: 'mindmap',
        direction: 'H',
        getId: function getId(d: any) {
          return d.name;
        },
        getHeight: () => 14,
        getWidth: () => 12,
        getVGap: () => 10,
        getHGap: () => 46,
        getSide: (d: any) => d?.data?.align || 'right',
      },
    };
    graph.current = new G6.TreeGraph(graphOption);
    graph.current.data(...newData);
    graph.current.render();
    if (hanldeData.length > 0 && hanldeData[0].children.length > 0) {
      graph.current.fitView([2]);
    } else {
      graph.current.fitCenter();
    }
    const bigGraphOption = {
      ...graphOption,
      modes: {
        default: [],
      },
      container: bigChartRef.current,
      width: bigChartRef.current.clientWidth,
      height: bigChartRef.current.clientHeight,
      defaultNode: {
        type: 'tree-node-big',
        anchorPoints: [
          [0, 0.5],
          [1, 0.5],
        ],
      },
      defaultEdge: {
        type: 'cubic-horizontal',
        style: {
          stroke: modeStyle[mode!].connectLineColor,
          lineWidth: 3,
        },
      },
      layout: {
        type: 'mindmap',
        direction: 'H',
        getId: function getId(d: any) {
          return d.name;
        },
        getHeight: () => 30,
        getWidth: () => 30,
        getVGap: () => 80,
        getHGap: () => 260,
        getSide: (d: any) => d?.data?.align || 'right',
      },
    };
    bigGraph.current = new G6.TreeGraph(bigGraphOption);
    bigGraph.current.data(...bigData);
    bigGraph.current.render();
    if (hanldeData.length > 0 && hanldeData[0].children.length > 0) {
      bigGraph.current.fitView([2, 2, 24, 2]);
    } else {
      bigGraph.current.fitCenter();
    }
  }, [hanldeData]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 2) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.light).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (hanldeData.length === 0) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const chartCanvas = bigChartRef.current.querySelector('canvas');
      // const canvas = document.createElement('canvas');
      // canvas.width = chartCanvas.width;
      // canvas.height = chartCanvas.height;
      // canvas.getContext('2d')!.drawImage(chartCanvas, 0, 0);
      // console.log(canvas);
      return new Promise(resolve => resolve(addWaterMark(chartCanvas, pixelRatio)));
    },
    getChartDesc() {
      return chartDesc;
    },
    getChartSize() {
      if (hanldeData.length === 0) {
        return {
          width: 0,
          height: 0,
        };
      }
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {/* {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..."
      spinning={chartLoading} /></div>)} */}
      {!chartLoading && (hanldeData.length === 0)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <div
        style={{ width: '100%', height: '100%' }}
        // style={{ position: 'absolute', zIndex: -10, left: '-500%',
        // top: '-500%', width: '100%', height: '100%' }}
        ref={chartRef}
      />
      <div
        style={{ position: 'absolute', zIndex: -10, left: '-500%', top: '-500%', width: '200%', height: '200%' }}
        // style={{ width: '200%', height: '200%' }}
        ref={bigChartRef}
      />
    </div>);
});

export default MindMapChart;

MindMapChart.defaultProps = {
  mode: 'light',
  data: [],
};
