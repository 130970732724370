/*
 *矩形树图
*/

import React, { useMemo } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { RectangleChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, getLines, toolTipFormatter } from '../../utils/tool';

import { modeStyle } from './style';

import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-pie-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};
const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const RectangleChart = forwardRef((props: RectangleChartProps, ref: any) => {
  const { mode = 'dark', data = [], title = '' } = props;
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);
  const [option, setOption] = useState({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);

  useEffect(() => {
    const curStyle = modeStyle[mode!] || modeStyle.dark;

    let min = Infinity;
    let max = -Infinity;
    function findEextremum(nodes: any) {
      if (!nodes) {
        return;
      }
      nodes.forEach((node: any) => {
        min = Math.min(+(node.value || 0), min);
        max = Math.max(+(node.value || 0), max);
        if (node.children) {
          findEextremum(node.children);
        }
      });
    }
    findEextremum(data);
    let colorIndex = 0;
    const colors = curStyle.visualMap.color;
    const cL = colors.length;
    function dfsStyle(nodes: any) {
      if (!nodes) {
        return;
      }
      nodes.forEach((item: any) => {
        if (item?.children?.length) {
          // @ts-ignore
          item.itemStyle = {
            borderColor: '#0D2A33',
            // borderColor: 'red',
            // gapWidth: 40,
            // borderColor: ''
            borderWidth: 4,
          };
          item.label = {
            fontSize: 12,
            color: '#fff',
          };
          dfsStyle(item?.children);
        } else {
          // @ts-ignore
          item.itemStyle = {
            color: colors[colorIndex % cL],
            gapWidth: 10,
          };
          item.label = {
            distance: 0,
            fontSize: 16 * (Number(item.value || 0) / max) + 8,
            // lineHeight: 16 * (Number(item.value) / max) + 8,
            ellipsis: '',
            overflow: 'breakAll',
            lineOverflow: 'truncate',
            padding: [4 * (Number(item.value) / max) + 1, 0, 0, 7 * (Number(item.value) / max) + 1],
            fontWeight: 'normal',
            color: colorIndex % curStyle.visualMap.color.length < 3 ? '#fff' : 'rgb(13, 59, 102)',
          };
          colorIndex += 1;
        }
      });
    }
    dfsStyle(data);
    const series = [{
      type: 'treemap',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      data,
      // colorMappingBy: 'value',
      levels: [
        {
          upperLabel: {
            show: false,
          },
        },
      ],
      upperLabel: {
        show: !!props.upperLabel,
        // height: 30,
        // position: 'left',
        fontSize: 10,
        borderWidth: 4,
        distance: 5,
        color: '#fff',
        overflow: 'breakAll',
        // offset: [5, 5],
      },
      silent: !!props.silent,
      nodeClick: props.nodeClick || !props.nodeClick,
      roam: !!props.roam,
      breadcrumb: {
        show: !!props.breadcrumb,
      },
      label: {
        distance: 0,
        show: !!props.label,
        position: props.labelPosition || 'insideTopLeft',
        padding: [0, 0, 0, 0],
        color: curStyle.series.label.color,
        fontSize: 12,
        formatter: (params: any) => {
          const dataItem = params.data;
          if (!dataItem.children) {
            return `${dataItem.name}\n${dataItem.value}`;
          }
          return `${dataItem.name}`;
        },
      },
      labelLayout(params: any) {
        const { labelRect, rect } = params;
        const temp = params.text.split('\n');
        const curLabelText = temp?.[0] || '';
        const curLabelNum = temp?.[1] || '';
        if (labelRect.width > rect.width || labelRect.height > rect.height) {
          // const index = params.dataIndex - 1;
          // console.log(params);
          let curSize = 16 * (Number(Number(curLabelNum) || 0) / max) + 8;

          const paddingLeft = 4 * (Number(curLabelNum) / max) + 1;
          const paddingRight = 7 * (Number(curLabelNum) / max) + 1;
          const validWidth = rect.width - paddingLeft - paddingRight;
          const validHeight = rect.height;
          // console.log(temp?.[0], validWidth);
          let linesLabel = getLines(curLabelText, curSize, validWidth);
          let linesNum = getLines(curLabelNum, curSize, validWidth);
          let lines = linesLabel + linesNum;

          const step = 1;
          while (((lines * curSize + (lines - 1) * 1.5) > (validHeight))) {
            curSize -= step;
            if (curSize <= 1) {
              curSize = 1;
              break;
            }
            linesLabel = getLines(curLabelText, curSize, validWidth);
            linesNum = getLines(curLabelNum, curSize, validWidth);
            lines = linesLabel + linesNum;
          }

          // 缩小字体使得单元格能容纳得下
          return {
            fontSize: curSize,
            // x: params.rect.x,
            // y: params.rect.y,
            // x: -labelRect.width - 10000000000,
            // y: -labelRect.height - 1000000000,
          };
        }
        return {};
      },
      itemStyle: {
        // borderWidth: 1,
        // gapWidth: 1,
        // borderColor: curStyle.itemStyle.borderColor,
      },
      selectedMode: false,
      emphasis: {
        // focus: 'none',
        itemStyle: {
          // borderWidth: 1,
          // color: curStyle.itemStyle.color[1],
          // borderColor: curStyle.itemStyle.borderColor,
        },
      },
    }];
    setOption({
      color: curStyle.visualMap.color,
      visualMap: {
        min,
        max,
        text: ['', ''],
        realtime: false,
        calculable: true,
        inRange: {
          // color: curStyle.visualMap.color.reverse(),
        },
        show: false,
      },
      series,
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
    });
  }, [data, mode, props.label, props.nodeClick, props.silent, props.upperLabel, title]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!data || !data.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  const Echart = useMemo(() => {
    const events = {
      finished() {
        setChartLoading(false);
      },
    };
    return <ReactEcharts
      option={option}
      notMerge
      style={{ width: '100%', height: '100%' }}
      ref={chartRef}
      onEvents={events}
    />;
  }, [option]);

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {
        chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)
      }
      {
        !chartLoading && (!data.length) && (
          <div className={styleEmpty}>暂无数据</div>
        )
      }
      {Echart}
    </div>);
});

RectangleChart.defaultProps = {
  label: true,
  breadcrumb: false,
  roam: false,
};
export default RectangleChart;
