import React, { useState } from 'react';
import { Spin } from 'antd';
import classnames from 'classnames';
import Subtitle from './components/Subtitle';
import Underline from './components/Underline';
import DrillableHeatmap from './components/DrillableHeatmap';
import DistributionRanking from './components/DistributionRanking';
import SectorCompanyTable from './components/SectorCompanyTable';
import { StandardModal } from './components/Modal';
import { openNewPage } from '../../../../../utils/urlMethods';
import styles from './index.module.less';

function GraphScheme({
  cityCode,
  cityName,
  title,
}) {
  const [isMapFetching, setIsMapFetching] = useState(false);
  const [overview, setOverview] = useState({});
  const [mapListQueryState, setMapListQueryState] = useState({
    pageSize: 20,
    pageNumber: 1,
    sortedInfo: {},
  });
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState({
    visible: false,
    regionCode: '',
    regionName: '',
    type: '',
    filteredInfo: {},
  });
  const [count, setCount] = useState(0);

  return (
    <>
      <section className={styles.graphScheme} id={title}>
        <p className={styles.title}>{title}</p>
        {/* ———————— 产业分布，热力图结合右侧表格的组合 ———————— */}
        <div className={classnames(styles.subScheme, styles.mapScheme)} data-id="distribution">
          <div className={styles.content}>
            <div
              className={classnames(
                styles.map,
                { 'grey-out': isMapFetching },
              )}
            >
              <DrillableHeatmap
                regionCode={cityCode}
                onRequestMapStart={() => {
                  setIsMapFetching(true);
                }}
                onRequestMapComplete={() => {
                  setIsMapFetching(false);
                }}
                onRequestMapError={() => {
                  setIsMapFetching(false);
                }}
              />
              {
                isMapFetching && <Spin className={styles.spin} delay={200} size="large" />
              }
            </div>
            <div className={styles.table}>
              <div className={styles.tableTitle}>
                <Subtitle
                  className={styles.subtitle}
                  description={cityName}
                  value={overview.企业总数}
                  unit="家企业"
                />
                <br />
                <Subtitle
                  className={styles.subtitle}
                  description="链主企业"
                  value={overview.链主企业总数}
                  unit="家，"
                />
                <Subtitle
                  className={styles.subtitle}
                  description="上市企业"
                  value={overview.上市企业总数}
                  unit="家，"
                />
                <Subtitle
                  className={styles.subtitle}
                  description="专精特新"
                  value={overview.专精特新企业总数}
                  unit="家，"
                />
                <Subtitle
                  className={styles.subtitle}
                  description="高新技术"
                  value={overview.高新技术企业总数}
                  unit="家"
                />
              </div>
              <Underline />
              <DistributionRanking
                regionCode={cityCode}
                setOverview={setOverview}
                pageSize={mapListQueryState.pageSize}
                pageNumber={mapListQueryState.pageNumber}
                sortedInfo={mapListQueryState.sortedInfo}
                onChange={({ current }, filter, sorter) => {
                  setMapListQueryState(prev => ({
                    ...prev,
                    pageNumber: current,
                    sortedInfo: sorter,
                  }));
                }}
                onClick={({ record, column }) => {
                  // TODO: 添加一个 event，获取当前点击的位置、计算浮窗出现的位置（有时间再做）
                  const { area, area_key: _regionCode } = record;
                  const { dataIndex } = column;
                  setMapCompanyListQueryState(prev => ({
                    ...prev,
                    regionCode: _regionCode,
                    regionName: area,
                    filteredInfo: {},
                    type: [dataIndex],
                    visible: true,
                  }));
                }}
              />
            </div>
            {
              mapCompanyListQueryState.visible && (
                <StandardModal
                  header={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ color: '#0f2849', fontWeight: 'bold' }}>
                        {mapCompanyListQueryState.regionName}
                      </span>
                      <Subtitle
                        className={classnames(styles.subtitle, styles.modalTitle)}
                        description="筛选结果"
                        value={count}
                        unit="家"
                      />
                      {/* <Button
                        className={style.button}
                        type="primary"
                        text="下载"
                        onClick={() => {
                        }}
                      /> */}
                    </div>
                  }
                  onClose={() => {
                    setMapCompanyListQueryState(prev => ({
                      ...prev,
                      pageNumber: 1,
                      visible: false,
                    }));
                  }}
                >
                  <SectorCompanyTable
                    className={styles.modalTable}
                    regionCode={mapCompanyListQueryState.regionCode}
                    pageSize={mapCompanyListQueryState.pageSize}
                    pageNumber={mapCompanyListQueryState.pageNumber}
                    companyTypes={mapCompanyListQueryState.type}
                    onChange={({ current, pageSize }, filter) => {
                      setMapCompanyListQueryState({
                        ...mapCompanyListQueryState,
                        pageSize,
                        pageNumber: current,
                        filteredInfo: filter,
                      });
                    }}
                    filteredInfo={mapCompanyListQueryState.filteredInfo}
                    onCompanyClick={({ company_id: _id }) => {
                      if (!_id) return;
                      openNewPage('companyDetail', { id: _id });
                    }}
                    getCount={setCount}
                  />
                </StandardModal>
              )
            }
          </div>
        </div>
      </section>
    </>
  );
}

export default GraphScheme;
