import * as echarts from 'echarts';
import { ComponentType, forwardRef, memo, useContext, useEffect, useMemo, useState } from 'react';
import { EChartsReactProps } from 'echarts-for-react';
import { ECharts as DECharts } from '@sqke/design';
import { withConfig } from '../../../hoc/withConfig';
import { merge } from 'lodash';
import { getOption } from '@sqke/parser';
import { EChartsOption } from 'echarts';
import { ConfigContext } from '../../../context/config';
import home from './home.json';
import guangdong from './guangdong.json';
import austin from './austin.json';
import china from './china.json';

const promiseMap = new Map();

function ECharts(props: EChartsReactProps & any, ref: any) {
  const { option, className, style, opts = {}, theme = "engineLight", complex } = props;

  const [mOption, setMOption] = useState({});

  const { geoJSONUrl = '{CODE}' } = useContext(ConfigContext);
  useEffect(() => {
    const series = Array.isArray(option.series) ? option.series : [option.series];
    const mapNames = Array
      .from(new Set([option?.geo, ...series].map(item => item?.map)
        .filter(name => Boolean(name) && !echarts.getMap(name))));
    const fetchMapJSON = async (mapName: string) => {
      let mapCode;
      mapCode = mapName === 'china' ? '000000' : mapName;
      if (!promiseMap.get(mapName)) {
        let _url = '';
        if (typeof geoJSONUrl === 'string') {
          _url = geoJSONUrl.replaceAll('{CODE}', mapCode);
        }
        if (typeof geoJSONUrl === 'function') {
          _url = geoJSONUrl(mapCode);
        }
        const request = fetch(_url);
        promiseMap.set(mapName, request);
        return request;
      }
      return promiseMap.get(mapName);
    }
    if (mapNames.length > 0 && !complex) {
      Promise.all(mapNames.map(name => {
        return fetchMapJSON(name)
          .then(res => res.json())
          .then(res => {
            echarts.registerMap(name, res);
            promiseMap.delete(name);
          }).catch(err => {
            promiseMap.delete(name);
            throw err;
          });
      })).then(() => {
        setMOption(option);
      }).catch(err => {
        console.error(err);
      });
    } else {
      if (option?.baseOption?.geo?.map === 'home') {
        echarts.registerMap('home', home as any);
      }
      if (option?.geo?.map === 'guangdong') {
        echarts.registerMap('guangdong', guangdong as any);
      }
      if (option?.geo?.map === 'Austin') {
        echarts.registerMap('Austin', austin as any);
      }
      if (option?.geo?.map === 'china' || option?.baseOption?.geo?.map === 'china') {
        echarts.registerMap('china', china as any);
      }
      setMOption(option);
    }
  }, [option, geoJSONUrl]);

  return (
    useMemo(() => {
      return (
        <DECharts
          theme={theme}
          className={`${className ? ` ${className}` : ''}`}
          option={mOption}
          style={{
            width: '100%',
            height: '400px',
            ...(style ?? {}),
          }}
          opts={{
            ...opts,
            devicePixelRatio: 2,
          }}
          notMerge={true}
          ref={ref}
        />
      )
    }, [theme, className, style, opts, mOption])
  );
}

// export default forwardRef(ECharts);

/**
 *抽象一层 config 的 echarts 图表
 */
const ConfigECharts = withConfig(
  forwardRef(ECharts),
  {
    mapConfigToProps: (raw, data) => {
      if (raw?.complex) {
        return {
          style: raw?.style,
          option: merge({}, raw?.visualConfig, data),
          theme: raw?.visualConfig?.theme,
          notMerge: true,
          complex: true,
        };
      } else {
        return {
          style: raw?.style,
          option: merge({}, getOption(raw, data)) as EChartsOption,
          theme: raw?.visualConfig?.theme,
          notMerge: true,
        };
      }
    },
    empty: (_, data) => !data || (data?.series && data.series.length === 0),
  },
) as ComponentType;

export default memo(ConfigECharts);
