import React from 'react';
import classNames from 'classnames';
import style from './index.module.less';

const ParkTab = ({ data, active, onClick }) => (
  <>
    {
        Object.keys(data).map(item => {
          if (!data[item]) return '';
          return (
            <div key={item} className={style.dropGroup} >
              <div className={style.groupTitle}>
                {item}
              </div>
              <div className={style.groupBody}>
                <div className={style.topLine} />
                <div className={style.groupCityBody}>
                  {data[item].map((item2, index) => (
                    <div
                      className={classNames(style.cityItem, { [style.active]: item2.key === active })}
                      key={index}
                      onClick={() => { onClick(item2, item); }}
                    >
                      {item2.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })
      }
  </>
);

export default ParkTab;
