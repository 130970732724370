/*
* Author: zhangmeng
* Description: '企业评价-雷达图'
* Date: 2020/11/30
*/

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

function RadarChart(props) {
  const { data } = props;
  // console.log(data);
  const { abscissa_list: abscissaList, detail_list: detailList } = data;
  const [option, setOption] = useState({});

  useEffect(() => {
    const options = {
      radar: {
        name: {
          textStyle: {
            fontSize: 12,
            color: '#666',
          },
        },
        axisLine: {
          lineStyle: {
            color: '#B4B4B4',
            width: 1,
          },
        },
        // splitNumber: 4,
        splitLine: {
          lineStyle: {
            color: '#B4B4B4',
          },

        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['#f2f7fb', '#f2f7fb'],
          },
        },
        indicator: abscissaList.map((item, index) => (
          {
            name: item,
            // max: 1200,
            min: 0,
            max: 100,
            color: '#919191',
            axisLabel: { show: index === 0, color: '#919191' },
            axisTick: { show: index === 0 },
            axisLine: { show: index === 0, lineStyle: { color: '#BFBFBF' } },
          }
        )),
      },
      series: [{
        type: 'radar',
        symbol: 'circle',
        symbolSize: 8,
        areaStyle: {
          color: '#3E5C76',
          opacity: 0.3,
        },
        itemStyle: {
          color: '#3E5C76',
        },
        lineStyle: {
          color: '#3E5C76',
        },
        data: [
          {
            value: detailList?.[0]?.ordinate || '',
            name: detailList?.[0]?.target || '',
          },
        ],
      }],
    };
    setOption(options);
  }, [data]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default RadarChart;
