/*
* Author: zhangmeng
* Description: '企业页'
* Date: 2022/06/29
*/

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Button, Pagination, Spin } from 'antd';
import moment from 'moment';
import { useGetSearchCompanyListQuery } from '../../../../service/dataSearch';
import TabSelect from '../SelectorComp/TabSelect';
import MoneySection from '../SelectorComp/MoneySelect';
import InputTreeSelect from '../SelectorComp/InputTreeSelect';
import DateSelect from '../SelectorComp/DateSelect';
import SearchSortor from '../SearchSortor';
import CompanyListLine from '../CompanyListLine';
import style from './index.module.less';

const sortData1 = [
  {
    title: '成立时间',
    key: 'basic.establish_date',
  },
  {
    title: '注册资本',
    key: 'basic.reg_capital_std',
  },
  {
    title: '上奇评价',
    key: 'tags.score_tag.total_point',
  },
];

function CompanyPage(props) {
  const { filter, styles, participle, hideTitle = false } = props;
  const { company_status: companyStatusList, especial_tag: especialTagList, industryTree, areaTree } = filter;
  const [companyStatus, setCompanyStatus] = useState(['不限']);
  const [especialTag, setEspecialTag] = useState(['不限']);
  const [registeredCapital, setRegisteredCapital] = useState(['', '']);
  const [industry, setIndustry] = useState([]);
  const [area, setArea] = useState([]);
  const [date, setDate] = useState(['', '']);
  const [sortKey, setSort] = useState({ key: '', count: 0 });
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isConfirm, setIsConfirm] = useState(false);

  /* eslint-disable no-nested-ternary */
  const params = sortKey.count === 0 ? {
    company_status_list: companyStatus.length === 1 ? (companyStatus[0] === '不限' ? [] : companyStatus) : companyStatus,
    company_register_capital_list: registeredCapital,
    register_date_list: (date?.[0] !== '' && date?.[1] !== '') ? [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')] : [],
    especial_tag_list: especialTag.length === 1 ? (especialTag[0] === '不限' ? [] : especialTag) : especialTag,
    filtering_industry_list: industry,
    filtering_area_list: area,
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
  } : {
    company_status_list: companyStatus.length === 1 ? (companyStatus[0] === '不限' ? [] : companyStatus) : companyStatus,
    company_register_capital_list: registeredCapital,
    register_date_list: (date?.[0] !== '' && date?.[1] !== '') ? [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')] : [],
    especial_tag_list: especialTag.length === 1 ? (especialTag[0] === '不限' ? [] : especialTag) : especialTag,
    filtering_industry_list: industry,
    filtering_area_list: area,
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
    sort_column: sortKey.key,
    sort_order: sortKey.count === 1 ? 'asc' : 'desc',
  };
  const { data: list, isFetching } = useGetSearchCompanyListQuery(params, { skip: isConfirm });

  useEffect(() => {
    if (!isFetching) {
      setIsConfirm(true);
    }
  }, [isFetching]);

  useEffect(() => {
    setIsConfirm(false);
  }, [participle]);

  const resetClick = () => {
    setCompanyStatus(['不限']);
    setEspecialTag(['不限']);
    setRegisteredCapital(['', '']);
    setIndustry([]);
    setArea([]);
    setDate(['', '']);
    setIsConfirm(true);
  };

  const confirmSearch = () => {
    setIsConfirm(false);
    setPageNum(1);
  };

  const changeSort = useCallback(
    (sort) => {
      setSort(sort);
      setIsConfirm(false);
    },
    [],
  );

  // pagination-onChange
  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
    setIsConfirm(false);
  };

  return (
    <div className={style.all} style={styles}>
      <div className={style.topBox}>
        {
          !hideTitle && <div className={style.title}>企业</div>
        }
        <div className={style.selectorBox}>
          {
            useMemo(() => (
              <TabSelect
                title="企业状态"
                data={companyStatusList}
                selected={companyStatus}
                setSelected={p => {
                  setCompanyStatus(p);
                  setIsConfirm(true);
                }}
              />
            ), [companyStatusList, companyStatus])
          }
          {
            useMemo(() => (
              <TabSelect
                title="资质类别"
                data={especialTagList}
                selected={especialTag}
                setSelected={p => {
                  setEspecialTag(p);
                  setIsConfirm(true);
                }}
              />
            ), [especialTagList, especialTag])
          }
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ width: '50%' }}>
              {
                useMemo(() => (
                  <MoneySection
                    title="注册资本"
                    select={registeredCapital}
                    setSelect={p => {
                      setRegisteredCapital(p);
                      setIsConfirm(true);
                    }}
                  />
                ), [registeredCapital, setRegisteredCapital])
              }
            </div>
            <div style={{ width: '50%' }}>
              {
                useMemo(() => (
                  <InputTreeSelect
                    title="产业"
                    data={industryTree}
                    select={industry}
                    setSelect={p => {
                      setIndustry(p);
                      setIsConfirm(true);
                    }}
                    ph="请选择产业"
                  />
                ), [industryTree, industry])
              }
            </div>
            <div style={{ width: '50%' }}>
              {
                useMemo(() => (
                  <DateSelect
                    title="成立日期"
                    select={date}
                    setSelect={p => {
                      if (p) {
                        setDate(p);
                      } else {
                        setDate(['', '']);
                      }
                      setIsConfirm(true);
                    }}
                  />
                ), [date, setDate])
              }
            </div>
            <div style={{ width: '50%' }}>
              {
                useMemo(() => (
                  <InputTreeSelect
                    title="区域"
                    data={areaTree}
                    select={area}
                    setSelect={p => {
                      setArea(p);
                      setIsConfirm(true);
                    }}
                    ph="请选择区域"
                  />
                ), [areaTree, area, setArea])
              }
            </div>
          </div>
          <div className={style.btnGroup}>
            <Button type="primary" ghost onClick={() => resetClick()}>
              重置
            </Button>
            &emsp;
            <Button type="primary" className="confirmSearch" onClick={() => confirmSearch()}>
              查询
            </Button>
          </div>
        </div>
      </div>
      <div className={style.listBox}>
        <Spin tip="搜索中，请稍候..." spinning={isFetching}>
          <SearchSortor
            exportType="exportSearchCompany"
            dataKey="search_company"
            params={params}
            data={sortData1}
            sort={sortKey}
            total={list?.data_count || 0}
            onChange={changeSort}
          />
          <div className={style.list}>
            {
              list && list.data_list && list.data_list.length > 0 ? list.data_list.map((item, index) => (
                <CompanyListLine key={index} record={item} participle={participle} />
              )) : <div style={{ textAlign: 'center', marginTop: '50px' }}>暂无数据</div>
            }
            <div className={style.pagination}>
              <Pagination
                showQuickJumper
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={pageSize}
                current={pageNum}
                total={list?.auth_count || 0}
                onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default CompanyPage;
