import React, { memo, useState, useEffect } from 'react';
import { message, Modal } from 'antd';
import QRcode from 'qrcode';
import style from './index.module.less';
import { useFindSpecialTopicCanBuyQuery, useBuySpecialTopicQrCodeQuery, useLazyFindTradeStatusOnPaySpecialTopicQuery } from '../../../../service/special';

function ChannelModal(props) {
  const { onClose, paySuccessCb, getSpecialList, defaultSelectCity } = props;
  const [codeList, setCodeList] = useState([defaultSelectCity]);
  const [masking, setMasking] = useState(true);
  const [qrCodeText, setQrCodeText] = useState('加载中...');
  const [indTradeStatusOnPaySpecialTopicQuery] = useLazyFindTradeStatusOnPaySpecialTopicQuery();
  const { data: specialList } = useFindSpecialTopicCanBuyQuery();
  const { data: qrCodeData, refetch } = useBuySpecialTopicQrCodeQuery({ code_list: codeList.filter(item => item) }, { skip: !codeList.filter(item => item).length });
  const indTradeStatusOnPaySpecialTopic = () => {
    if (qrCodeData) {
      ChannelModal.paymentTime = setInterval(() => {
        indTradeStatusOnPaySpecialTopicQuery({
          order_id: qrCodeData.order_id,
          code_list: codeList,
        }).then(res => {
          const { data, isError } = res;
          if (data === '购买成功') {
            if (getSpecialList) getSpecialList();
            ChannelModal.num += 1;
            if (ChannelModal.num > 1) return;
            clearInterval(ChannelModal.paymentTime);
            Modal.success({
              centered: true,
              content: <div style={{ fontSize: '20px', lineHeight: 1 }}>已成功支付</div>,
              okText: '去查看用户权益',
              keyboard: false,
              maskStyle: {
                background: 'transparent',
              },
              onOk: () => {
                paySuccessCb(codeList);
                onClose();
              },
              onCancel: () => {
                paySuccessCb(codeList);
                onClose();
              },
              width: 450,
            });
          }
          // if (isError) {
          //   clearTimeout(ChannelModal.paymentTime);
          //   indTradeStatusOnPaySpecialTopic();
          // }
        }).catch(err => {
          console.log(err);
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (specialList && !defaultSelectCity)setCodeList([specialList[0]?.key]);
  }, [specialList]);

  const showQrCode = () => {
    if (qrCodeData) {
      QRcode.toCanvas(qrCodeData.code_url, { width: 137, height: 137 }, (err, canvas) => {
        if (err) {
          console.log(err);
        }
        const contanier = document.getElementById('channelQrcode');
        if (contanier.childNodes[0]) {
          contanier.removeChild(contanier.childNodes[0]);
        }
        ChannelModal.loseTime = setTimeout(() => {
          setQrCodeText('二维码失效');
          setMasking(true);
          clearInterval(ChannelModal.paymentTime);
          clearInterval(ChannelModal.loseTime);
        }, 2000 * 60);
        contanier.appendChild(canvas);
        setMasking(false);
      });
    }
  };

  useEffect(() => {
    ChannelModal.num = 0;
    showQrCode();
    indTradeStatusOnPaySpecialTopic();
    return () => {
      clearInterval(ChannelModal.loseTime);
      clearInterval(ChannelModal.paymentTime);
    };
  }, [qrCodeData]);

  const updateCode = (code) => {
    setQrCodeText('加载中...');
    setMasking(true);
    const newCodeList = codeList.slice();
    if (newCodeList.indexOf(code) > -1) {
      newCodeList.splice(newCodeList.indexOf(code), 1);
    } else {
      newCodeList.push(code);
    }
    if (newCodeList.length < 1) {
      setMasking(false);
      message.warning('请至少选择一个专题！');
      return;
    }
    setCodeList(newCodeList);
  };
  return (
    <div className={style.content}>
      <div className={style.describe}>单击选择，￥199/专题/年</div>
      <div className={style.specialList}>
        {
          specialList?.map(item => <div key={item.title} className={codeList?.includes(item.key) ? style.checked : ''} onClick={() => { updateCode(item.key); }}>{item.title}</div>)
        }
      </div>
      <div className={style.payment}>
        <div className={style.paymentContent}>
          <div className={style.qrCode}>
            <div className={style.icon} id="channelQrcode" />
            {
              masking && qrCodeData && <div className={style.masking} >{qrCodeText}</div>
            }
            <div className={style.newIcon} onClick={() => { refetch(); setMasking(true); setQrCodeText('加载中...'); }}>刷新二维码</div>
          </div>
          <div className={style.paymentList}>
            <div>微信扫码支付</div>
            <div className={style.quantity}>
              专题：
              {codeList.filter(item => item).length}
              个
            </div>
            <div className={style.price}>
              合计：¥
              {qrCodeData?.amount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ChannelModal);
