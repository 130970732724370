import { createContext } from 'react';

export const Context = createContext();

export const menus = [
  {
    name: '首页',
    key: 'homePage'
  },
  {
    name: '产业',
    key: 'industry'
  },
  {
    name: '城市',
    key: 'city'
  },
  {
    name: '园区',
    key: 'park',
    value: 0,
  },
  {
    name: '报告',
    key: 'report'
  },
  {
    name: '专题',
    key: 'special'
  },
  {
    name: 'API商城',
    key: 'apiShop',
  },
  {
    name: '生成报告(内测)',
    key: 'generateReport',
    value: 0,
  },
  {
    name: '工具',
    key: 'toolBox',
    value: 0,
  },
  {
    name: '产业通Pro',
    key: 'proIntroduce',
    value: 1,
  },
];
