/*
* 城市详情
*/

import React, { useEffect, useMemo, useState, useRef } from 'react';
import style from './index.module.less';
import DetailTop from './DetailTop';
import { useQuery } from '../../hooks/url';
import { useRecordingSpotQuery } from '../../service/public';
import CityContent from './CityContent';

const moduleTabs = {
  经济运行: [
    { key: 1, name: '经济数据' },
    { key: 2, name: '社会发展' },
  ],
  产业分析: [
    { key: 3, name: '产业概览' },
    { key: 4, name: '城市对标' },
    { key: 5, name: '区域分布' },
    { key: 6, name: '产业分布' },
    { key: 7, name: '股权融资' },
    { key: 8, name: '创新能力' },
  ],
  园区载体: [{ key: 9, name: '园区载体' }],
  政策清单: [{ key: 10, name: '政策清单' }],
  研究报告: [{ key: 11, name: '研究报告' }],
};

function CityDetails(props) {
  const query = useQuery();
  const { box } = props;
  const [achorFixHeight, setAchorFixHeight] = useState(0);
  const contentRef = useRef(null);
  const topRef = useRef(null);

  const city = useMemo(() => {
    const code = query.get('city-code');
    if (code) {
      const _code = window.atob(query.get('city-code'));
      const title = query.get('city-title');
      const isProvinceDirectly = JSON.parse(query.get('is-province-directly'));
      return {
        code: _code,
        title,
        isProvinceDirectly,
      };
    }
    return undefined;
  }, [query]);
  const { code, title, isProvinceDirectly } = city;
  const heightListener = () => {
    const a = document.getElementById('cityAchorTab')?.offsetTop;
    if (a) {
      setAchorFixHeight(a);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(heightListener);
    resizeObserver.observe(topRef.current);
  }, []);

  useRecordingSpotQuery(
    { spot_name: '城市' },
  );

  const cityTop = useMemo(() => (
    <DetailTop city={city} />
  ), [city]);

  const cityContent = useMemo(() => (
    Object.keys(moduleTabs).length > 0 && (
      <CityContent
        fixHeight={achorFixHeight}
        tabs={moduleTabs}
        ref={contentRef}
        box={box}
        code={code}
        cityName={title}
        isProvinceDirectly={isProvinceDirectly}
      />
    )
  ), [moduleTabs, achorFixHeight, city]);

  return (
    <div className={style.box} ref={topRef}>
      {cityTop}
      {cityContent}
    </div>
  );
}

export default CityDetails;
