import React, { useMemo, useState, useEffect } from 'react';
import { Spin } from 'antd';
import classnames from 'classnames';
import Table from '../../../components/Table';
import { IconFont } from '../../../../../utils/mapIcon';
import styles from './index.module.less';
import { getSelectArea } from '../../../../../utils/tool';
import { popupColumns, THEAD_TABLE } from './utils';
import { useGetCompanySubsidiaryRankQuery, useGetAreaSubsidiaryCompanyListQuery } from '../../../../../service/special';
import { useGetCompanySpecialTagsQuery } from '../../../../../service/interactive-v2';
import { getAllCity, getAllIndustry } from '../../../../../api/knowledgeEngine';

const operation = [
  'head_quarters', 'first_office_count', 'second_office_count', 'third_office_count', 'total_office_count',
];
const mapLevel = {
  head_quarters: '总部',
  first_office_count: '一级子公司',
  second_office_count: '二级子公司',
  third_office_count: '三级及以下',
  total_office_count: '全部',
};

function DistributionRanking({ industryCode, name, titleKey, setTitleKey, area: parentArea, sortKey, setSortKey }) {
  const [cardShow, setCardShow] = useState(false);
  const [curDetail, setCurDetail] = useState(''); // 区域名称
  const [aptitudes, setAptitudes] = useState('');
  const [area, setArea] = useState('000000');
  // const [sortKey, setSortKey] = useState('head_quarters');
  const [secondAreaKey, setSecondAreaKey] = useState('');
  const [areaFilter, setAreaFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [industryList, setIndustryList] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);
  const [companyNum, setCompanyNum] = useState('');
  const { data: companyTypeList } = useGetCompanySpecialTagsQuery();

  // 点击右侧表格的放大镜
  const getDetailData = (titleItem, rowData) => {
    setArea(rowData.area_key);
    setCompanyNum(titleItem);
    const index = Object.values(rowData).indexOf(titleItem);
    setTitleKey(Object.keys(rowData)[index]);
    getAllCity({ area_key: rowData.area_key }).then(res => {
      if (res.status === '00000') {
        setAreaFilter(getSelectArea(res.data, rowData.area_key || '') || []);
      }
    });
    setSecondAreaKey(rowData.area_key);
    setCurDetail(rowData.area);
  };
  // 请求右侧表格数据
  const { data, isFetching: tableLoading } = useGetCompanySubsidiaryRankQuery({
    area_key: parentArea,
    label: name,
    sort_key: sortKey,
  }, {
    skip: !name,
  });
  // 弹出表格数据请求
  const { data: popupTableData, isFetching: popupTableLoading } = useGetAreaSubsidiaryCompanyListQuery({
    area_key: area,
    label: name,
    title_key: titleKey,
    special_tag: aptitudes,
    page_size: pageSize,
    second_area_key: secondAreaKey,
    industry_code: selectKeys,
    page_number: page,
  });

  // 弹出表格的 columns
  const popupHandleColumns = useMemo(() => {
    const { tableHead } = popupTableData || {};
    return popupColumns({
      data: tableHead,
      list: companyTypeList,
      aptitudes,
      industryList,
      curDetail,
      areaFilter,
      area,
      selectKeys,
      setPage,
      setArea,
      setAptitudes,
      setSecondAreaKey,
      setCurDetail,
      setSelectKeys,
    });
  }, [popupTableData, aptitudes, areaFilter, selectKeys]);

  const { tableHead, tableBody = [] } = data ?? {};
  const columns = useMemo(() => {
    if (!tableHead) return [];
    return tableHead.map(item => {
      if (operation.includes(item.dataIndex)) {
        THEAD_TABLE[item.dataIndex].render = (text, value) => (
          <div className={styles.operation}>
            <span>{text}</span>
            <IconFont
              type="iconshujuchaxun-icon"
              className={classnames(styles.icon)}
              onClick={() => { setCardShow(true); getDetailData(text, value); setPage(1); }}
            />
          </div>
        );
      }
      return THEAD_TABLE[item.dataIndex];
    });
  }, [tableHead, titleKey]);
  useEffect(() => {
    getAllIndustry().then(res => {
      if (res.status === '00000') {
        setIndustryList([res.data]);
      }
    });
  }, []);

  const tableChange = (_, filters, { columnKey }) => {
    setSortKey(columnKey);
  };
  return (
    <>
      <Table
        rowKey="index"
        columns={columns}
        dataSource={tableBody}
        loading={tableLoading}
        pagination={false}
        onChange={tableChange}
        scroll={{ y: 600 }}
        className={classnames(styles.distributionRanking)}
      />
      {
        cardShow && (
          <div className={styles.modalCard}>
            <div className={styles.cardTitle} >
              <div>
                <span style={{ fontWeight: 'blod', color: '#262D36', marginRight: '6px' }}>{curDetail}</span>
                {mapLevel[titleKey]}
                ,
                共计
                {companyNum}
                家,
                筛选结果
                {popupTableData?.data_count}
                家。
              </div>
              <div onClick={() => { setCardShow(false); setAptitudes(''); }}>
                X
              </div>
            </div>
            <div className={styles.tableBody} >
              <Spin spinning={popupTableLoading}>
                <Table
                  rowKey="index"
                  dataSource={popupTableData?.tableBody || []}
                  columns={popupHandleColumns}
                  pagination={{
                    current: page,
                    pageSize,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    total: popupTableData?.data_count,
                    onChange: (current) => {
                      setPage(current);
                    },
                    onShowSizeChange: (size) => {
                      setPageSize(size);
                    },
                  }}
                />
              </Spin>
            </div>
          </div>
        )
      }
    </>
  );
}

export default DistributionRanking;
