/**
* Author: tianye
* Description: 专利详情-专利列表,
* Date: 2020/11/26
* */

import React from 'react';
import style from './index.module.less';

export default function PatentListScreen(props) {
  const { data, onItemClick, className } = props;

  return (
    <div className={`${style.mainContainer} ${className}`}>
      <div className={style.header}>相似专利</div>
      <div className={style.content}>
        <div className={style.patenList}>
          {
            data.length > 0 && data.map((item, index) => (
              <div key={index}>
                <span
                  onClick={() => onItemClick(item)}
                  className={style.paten}
                >
                  <span>{`【${index + 1}】`}</span>
                  <span>{item.value}</span>
                </span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

PatentListScreen.defaultProps = {
  data: [{
    key: '0',
    value: '减震装置和制冷系统[P].珠海格力电器股份有限公司.中国专利',
    link: 'https://www.baidu.com',
  }],
  className: '',
};
