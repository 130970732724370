import React, { useEffect, useState, useRef } from 'react';
import { Input, Switch } from 'antd';
import { uniqueId } from 'lodash';
import classnames from 'classnames';
import { SyncOutlined, CloseOutlined, QuestionOutlined } from '@ant-design/icons';
import Barandline from '../../../../components/BarAndLint';
import downloadImg from '../../../../utils/downloadImg';
import ComponentsTable from '../../../../components/ComponentsTable';
import Table from '../../../../components/Table';
import { useGetStructuralTransferQuery, useLazyGetDistributionQuery } from '../../../../../../service/chartTableTool';
import { dataDownload } from '../../../../../../api/chartTableTooldataDownload';
import style from './index.module.less';

const { TextArea } = Input;

// preview 为true的话隐藏边框 禁止编辑

const BarTable = ({ url, title, data, isFetching, chartType, delCrosswise, preview, reportData, id, setDescribeObj, setReportData, chartNumber, tableTitle }) => {
  const elRef = useRef();
  const [getDistributionQuery] = useLazyGetDistributionQuery();
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [edit, setEdit] = useState(false);
  const [remake, setRemake] = useState(false);
  const [added, setAdded] = useState(1);
  const [x, setX] = useState([]);
  const [series, setSeries] = useState(null);
  const [chartTitle, setChartTitle] = useState('');
  const [leftUnit, setLeftUnit] = useState('');
  const [rightUnit, setRightUnit] = useState('');
  const [describe, setDescribe] = useState('');
  const [numberFlat, setNumberFlat] = useState(true);
  const [numberTagFlat, setNumberTagFlat] = useState(true);
  const [tableColumsData, setTableColumnsData] = useState([]);
  const [tableDateSource, setTableDataSource] = useState([]);
  const dataDownloadTable = () => {
    dataDownload({ table_data: { dataSource, columns } }, `${chartTitle}${uniqueId()}`);
  };
  const { data: tableTata, isFetching: tableIsFetching } = useGetStructuralTransferQuery({
    original_data: data,
    original_structure: chartType,
  }, { skip: !data });
  const GetDistribution = (seriesData = data) => {
    if (!data || !Object.keys(data).length || !seriesData?.x.length) return;
    getDistributionQuery({ data: { chat_data: seriesData }, url: url.split('/').pop() }).then(res => {
      if (res.data) {
        setDescribe(res.data);
        setDescribeObj(params => ({ ...params, [id]: { ...params[id], describe: res.data } }));
      }
    }).catch((err) => {
      throw new Error(err);
    });
  };
  // 方法处理表格数据(头部)
  const onSetTableColumns = (val, source) => {
    const tableColums = [];
    tableColums.push({
      title: '排名',
      dataIndex: 'index',
    });
    tableColums.push({
      title: tableTitle,
      dataIndex: tableTitle,
      key: val?.[0]?.key,
    });
    source.forEach((item) => {
      tableColums.push({
        title: item?.name,
        dataIndex: item?.name,
        key: item?.key,
      });
    });
    return tableColums;
  };
  // 方法处理表格数据(内容)
  const onSetTableSource = (val, source) => {
    const tableSource = [];
    const valDataIndex = tableTitle;
    const valStringData = 'index';
    const valData = val.slice(1, val.length);
    const newValData = (valData || []).map((item, index) => (
      {
        [valDataIndex]: item.title,
        [valStringData]: index + 1,
      }
    ));
    (series || []).forEach((item, seriesIndex) => {
      item.data.forEach((i, index) => {
        tableSource[index] = {
          ...tableSource[index],
          ...newValData[index],
          [source?.[seriesIndex]?.name]: i,
        };
      });
    });
    return tableSource;
  };
  // 更新报告数据
  const updateReportData = () => {
    // eslint-disable-next-line no-unused-expressions
    setReportData && setReportData(params => ({
      ...params,
      [id]: {
        ...params[id],
        text: title,
        contents: describe,
        content: chartTitle,
        data: {
          ...params[id]?.data,
          tableData: {
            columns: onSetTableColumns(columns, dataSource) || [],
            dataSource: onSetTableSource(columns, dataSource) || [],
            el: {
              columns: onSetTableColumns(columns, dataSource) || [],
              dataSource: onSetTableSource(columns, dataSource) || [],
            },
            type: 'table',
          },
          describe,
          x,
          numberFlat,
          numberTagFlat,
          series,
          title: chartTitle,
          label: `${title}`,
        },
      },
    }));
  };
  useEffect(() => {
    updateReportData();
  }, [JSON.stringify(data), JSON.stringify(series), JSON.stringify(x), JSON.stringify(columns), JSON.stringify(dataSource), title, describe, numberFlat, numberTagFlat, chartTitle]);
  useEffect(() => {
    if (columns.length && dataSource.length) {
      const newX = columns.map(item => item.title);
      const keyList = columns.map(item => item.dataIndex);
      setX(newX.slice(1, newX.length));
      const newSeriesData = dataSource.map(item => keyList.filter(it2 => it2 !== 'name').map((it, index) => (item[it] || 0)));
      const newSeries = newSeriesData.map((item, index) => ({
        data: item,
        name: series[index]?.name || '',
        unit: series[index]?.unit || '',
        type: series[index]?.type || '',
      }));
      GetDistribution({ x: newX.slice(1, newX.length), series: newSeries });
      setSeries(newSeries);
    }
    // eslint-disable-next-line no-unused-expressions
    setReportData && setReportData(params => ({
      ...params,
      [id]: {
        ...params[id],
        data: {
          ...params[id]?.data,
          tableData: {
            columns: tableColumsData || [],
            dataSource: tableDateSource || [],
            el: {
              columns: tableColumsData || [],
              dataSource: tableDateSource || [],
            },
            type: 'table',
          },
        },
      },
    }));
  }, [columns, dataSource, tableColumsData, tableDateSource]);

  const initChartData = () => {
    let newData = data;
    if (preview) {
      newData = reportData;
      setNumberFlat(newData.numberFlat);
      setNumberTagFlat(newData.numberTagFlat);
    }
    if (newData) {
      setX(newData.x);
      setSeries(newData.series);
      setChartTitle(newData.title);
      setDescribe(newData.describe);
      setTableColumnsData(newData?.tableData?.columns);
      setTableDataSource(newData?.tableData?.dataSource);
      newData.series?.forEach(item => {
        if (item.type) {
          setRightUnit(item.name);
          return;
        }
        setLeftUnit(item.name);
      });
      if (setDescribeObj && !isFetching) {
        setDescribeObj(params => ({ ...params, [id]: { ok: true, describe: newData?.describe } }));
      }
    }
  };

  const initTableData = () => {
    if (tableTata) {
      setColumns(tableTata.columns.map(item => {
        if (item.title) {
          return {
            ...item,
            key: uniqueId(),
          };
        }
        return {
          width: 148,
          ...item,
          key: uniqueId(),
        };
      }));
      setDataSource(tableTata.dataSource.map(item => ({ ...item, key: uniqueId() })));
    }
  };

  useEffect(() => {
    initChartData();
  }, [JSON.stringify(data)]);

  useEffect(() => {
    initTableData();
  }, [tableTata]);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    setDescribeObj && setDescribeObj(params => ({ ...params, [id]: { ...params[id], describe } }));
  }, [describe]);
  /**
   *
   * @param {string} value 更新后的值
   * @param {string | number} row 那行
   * @param {string} column 那列
   */
  const updateDataSource = (value, row, column) => {
    setDataSource(params => params.map(item => {
      if (item.key === row) {
        return {
          ...item,
          [column]: value,
        };
      }
      return item;
    }));
  };
  // 更新columns
  const updateColumns = (value, index) => {
    setColumns(parsms => parsms.map(item => {
      if (item.dataIndex === index) {
        return {
          ...item,
          title: value,
          dataIndex: value,
        };
      }
      return item;
    }));
  };

  // 添加单元格
  const addCell = () => {
    const isAdded = columns.some(item => item.title.slice(0, 2) === '新增');
    setColumns(params => ([
      ...params,
      {
        title: `新增${isAdded ? added : 1}`,
        dataIndex: `新增${isAdded ? added : 1}`,
        key: uniqueId(),
      },
    ]));
    if (isAdded) {
      setAdded(params => params + 1);
    } else {
      setAdded(1);
      setAdded(params => params + 1);
    }
    if (!dataSource.length) {
      setDataSource([
        {
          [`新增${isAdded ? added : 1}`]: '',
          key: uniqueId(),
        },
      ]);
    }
  };
  // 删除列
  const delColumn = (key, dataIndex) => {
    setColumns(params => params.filter(item => item.key !== key));
    setDataSource(params => {
      params.forEach(item => {
        delete item[dataIndex];
      });
      return params;
    });
  };
  // 删除行
  const delDataSource = (key) => {
    setDataSource(params => params.filter(item => item.key !== key));
  };
  // 方法关闭或者打开数据列表
  const onSetNunberList = (checked) => {
    setNumberFlat(checked);
  };
  // 方法关闭或者打开数据标签
  const onSetNunberTagList = (checked) => {
    setNumberTagFlat(checked);
  };
  return (
    <div id={id} className={classnames(style.box, { [style.noBorder]: preview })}>
      <div className={style.title}>
        <span>
          {title}
        </span>
        <button type="button" className={classnames(style.editBtn, { [style.edit]: edit })} onClick={(e) => { setEdit(params => !params); }}>
          {edit ? '确定' : '编辑'}
        </button>

      </div>
      <div className={style.describe}>
        {
          preview ? describe : (
            <TextArea
              value={describe}
              onChange={(e) => { setDescribe(e.target.value); }}
              // placeholder="textarea with clear icon"
              allowClear
              bordered={false}
              disabled={!edit}
            />
          )
        }
      </div>
      <div className={style.chartAndTable}>
        <div className={style.chartAndTableBox}>
          <div className={style.chartBox}>
            <div className={style.chartsTitle}>
              {preview ? `图${chartNumber}${chartTitle}` : chartTitle}
            </div>
            <div className={style.chart}>
              <Barandline
                data={{ x, series, yName: leftUnit, yName1: rightUnit, bottom: 92, left: 120, right: 80, unit: '家' }}
                dataDownload={dataDownloadTable}
                isFetching={isFetching}
                showLabel={numberTagFlat}
                contentTop={80}
                elRef={elRef}
                downloadImg={downloadImg}
                preview={preview}
                setReportData={preview && setReportData}
                title={title}
                id={id}
              />
            </div>
            <div className={style.sourceTextStyle}>
              数据来源：上奇产业通
            </div>
          </div>
          {
            numberFlat && (
              <div className={style.tableStyle}>
                <ComponentsTable
                  columns={preview ? tableColumsData : onSetTableColumns(columns, dataSource)}
                  dataSource={preview ? tableDateSource : onSetTableSource(columns, dataSource)}
                  isFetching={tableIsFetching}
                />
              </div>
            )
          }
          {
            remake && (
              <div className={style.popup}>
                <div className={style.popupTop}>
                  <div className={style.popupTopLeft}>
                    <span>
                      <QuestionOutlined />
                    </span>
                    <span>
                      是否重制数据?
                    </span>
                  </div>
                  <div className={style.right} onClick={() => { setRemake(false); }}>
                    <CloseOutlined />
                  </div>
                </div>
                <div className={style.middle}>
                  重置数据仅对当前模块生效，重置后您编辑的数据无法保存并不可撤销
                </div>
                <div className={style.bottom}>
                  <button type="button" onClick={() => { setRemake(false); }}>取消</button>
                  <button type="button" onClick={() => { setRemake(false); initChartData(); GetDistribution(); initTableData(); }}>确定</button>
                </div>
              </div>
            )
          }
        </div>
        {
          edit && (
            <div className={style.tableCig}>
              <div className={style.tableTitle}>
                <span>图表配置</span>
                <div className={style.remake} onClick={() => { setRemake(true); }}>
                  <span>
                    <SyncOutlined />
                  </span>
                  <span>
                    重置数据
                  </span>
                </div>
              </div>
              <div className={style.chartTitleCig}>
                <div className={style.chartTitleLeft}>
                  <div className={style.chartTitle}>
                    <span>图表标题</span>
                    <div>
                      <Input value={chartTitle} onChange={(e) => { setChartTitle(e.target.value); }} />
                    </div>
                  </div>
                  <div className={style.chartUnit}>
                    <span>单位(左)</span>
                    <div>
                      <Input value={leftUnit} onChange={(e) => { setLeftUnit(e.target.value); }} />
                    </div>
                  </div>
                  {
                    rightUnit && (
                      <div className={style.chartUnit}>
                        <span>单位(右)</span>
                        <div>
                          <Input value={rightUnit} onChange={(e) => { setLeftUnit(e.target.value); }} />
                        </div>
                      </div>
                    )
                  }
                </div>
                <div className={style.chartTitleRight}>
                  <div className={style.titleStyle}>
                    <span>数据列表</span>
                    <Switch onChange={onSetNunberList} checked={numberFlat} />
                  </div>
                  <div className={style.titleStyle}>
                    <span>数据标签</span>
                    <Switch onChange={onSetNunberTagList} checked={numberTagFlat} />
                  </div>
                </div>
              </div>

              <div className={style.table}>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  delCrosswise={delCrosswise}
                  updateDataSource={updateDataSource}
                  updateColumns={updateColumns}
                  delColumn={delColumn}
                  delDataSource={delDataSource}
                  addCell={addCell}
                />
              </div>
            </div>
          )
        }
      </div>
    </div>

  );
};

export default BarTable;
