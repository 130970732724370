export enum SetterGroup {
  Style,
  Data,
  Other
}

export enum APIType {
  API = 'api',
  JSON = 'json',
  API_SELECTION = 'api-selection',
}

export enum APIMethod {
  GET = 'get',
  POST = 'post',
}

/**
 * API 选择里输入的类型，前后端一致
 */
export enum AutoInputType {
  COMPANY = 'choice_company',
  DATE = 'choice_date',
  REGION = 'choice_area',
  INDUSTRY = 'choice_industry',
  TAG = 'choice_company_tag',
}
