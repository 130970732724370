/*
* Author: zhangmeng
* Description: '组件容器'
* Date: 2021/05/12
*/

import React, { useCallback } from 'react';
import { Popover, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import style from './index.module.less';
import TitleComp from '../TitleComp';
import SubTitleComp from '../SubTitleComp';
import { openNewPage } from '../../../../utils/urlMethods';

function Container(props) {
  const { id, title, num, children, look, question, option } = props;
  const pushChart = useCallback(
    () => {
      localStorage.setItem('option', JSON.stringify(option));
      openNewPage('viewAll');
    },
    [option],
  );

  return (
    <div className={style.all}>
      {
        id && (
          <div className={style.title}>
            <TitleComp
              title={id}
              num={num}
            />
            {
              question && !title && (
                <Popover content={question} placement="right" overlayClassName={style.pop}>
                  <QuestionCircleOutlined />
                </Popover>
              )
            }
            {
              look && !title && (
                <div className={style.look} onClick={() => pushChart()}>
                  {`${look} >`}
                </div>
              )
            }
          </div>
        )
      }
      {
        title && (
          <div className={style.title}>
            <SubTitleComp
              title={title}
            />
            {
              question && (
                <Popover content={question} placement="right" overlayClassName={style.pop}>
                  <QuestionCircleOutlined />
                </Popover>
              )
            }
            {
              look && (
                <div className={style.look} onClick={() => pushChart()}>
                  {`${look} >`}
                </div>
              )
            }
          </div>
        )
      }
      {children}
    </div>
  );
}

export default Container;
