/*
 *Author: zhaochenyu
 *Description: 企业画像报告-画像扫描
 *Date: 2022/01/10
*/

import React from 'react';
import { Checkbox } from 'antd';
import html2canvas from 'html2canvas';
import classNames from 'classnames';
import { CompanyPortraitChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas } from '../../utils/tool';
import './index.less';
import '../../index.less';

// const data1 = {
//   info: {
//     name: '杭州海康威视数字技术股份有限公司',
//     code: '91330000733796106P',
//     registrTime: '2001-11-30',
//     legalPerson: '陈总年',
//     address: '杭州市滨江区阡陌路55号',
//     registerCapital: '934,342万元',
//     shareholders: '陈总年',
//     industrys: '计算机、通信和其他电子设备制造业',
//     grade: 'A+',
//   },
//   industryStrength: {
//     listing: 'A股主板',
//     institutionCount: '38',
//     investmentCount: '55',
//     top3Shareholders: '中电海康集团有限公司、龚虹嘉、新疆威讯投资管理有限合伙企业',
//     mainQualifications: '国家高新技术企业、科技型中小企业、国家技术创新示范企业',
//     honorsReceived: '国家级单项冠军企业、国家高新区瞪羚企业、中国电子信息企业100强',
//     standardSort: '4',
//     patentSort: '11',
//     sortTotal: '180647',
//   },
//   innovationAbility: {
//     nationalStandardCount: '86',
//     industryStandardCount: '64',
//     localStandardsCount: '17',
//     teamStandardsCount: '69',
//     enterpriseStandardCount: '0',
//     authorizedPatentCount: '3990',
//     patentApplicationCount: '3134',
//     bationalInnovationPlatformCount: '2',
//     provincialInnovationPlatformCount: '2',
//     partnership: '浙江大华技术股份有限公司、公安部第一研究所、浙江字视科技有限公司',
//   },
//   financingCapacity: {
//     rounds: '3',
//     total: '377500',
//     average: '125833',
//     unit: '万元',
//     process: '14',
//     institutions: '申万宏源证券、中国电子科技集团第五十二研究所、浙江东方',
//   },
//   growthPotential: {
//     revenueGrowth: '上升',
//     populationGrowth: '-',
//     PatentGrowth: '上升',
//     standardGrowth: '下降',
//     MarketValueGrowth: '上升',
//   },
// };

const listTypes = ['A股主板', '创业板', '科创板', '北交所', '三板一创新层', '三板一基础层'];

const prefixCls: string = 'cube-component-company-portrait-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);
const styleMenu = classNames(`${prefixCls}-menu`);
const styleBasicInfo = classNames(`${prefixCls}-info`);
const styleInfoLeft = classNames(`${prefixCls}-info-left`);
const styleInfoRight = classNames(`${prefixCls}-info-right`);
const styleLableValue = classNames(`${prefixCls}-label-value`);
const styleLabel = classNames(`${prefixCls}-label`);
const styleValue = classNames(`${prefixCls}-value`);
const styleScaleFont = classNames(`${prefixCls}-scale-font`);
const styleValueMsg = classNames(`${prefixCls}-value-msg`);
const styleAverage = classNames(`${prefixCls}-average`);
const styleBottomLine = classNames(`${prefixCls}-line`);
const styleGradeLabel = classNames(`${prefixCls}-grade-label`);
const styleGradeValue = classNames(`${prefixCls}-grade-value`);
const styleSpecialNum = classNames(`${prefixCls}-count`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const CompanyPortrait = forwardRef((props: CompanyPortraitChartProps, ref) => {
  const { mode, data, chartDesc } = props;
  const [hanldeData, setHanldeData] = useState<any>([]);
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(data || {});
    setChartLoading(false);
  }, [data]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 2) {
      if (Object.keys(hanldeData).length === 0) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      return html2canvas(bodyRef.current, {
        allowTaint: true,
        useCORS: true,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: pixelRatio,
      }).then((canvas: any) => addWaterMark(canvas, pixelRatio));
    },
    toDataURL(pixelRatio = 3) {
      if (Object.keys(hanldeData).length === 0) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      return html2canvas(bodyRef.current, {
        allowTaint: true,
        useCORS: true,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: pixelRatio,
      }).then((canvas: any) => addWaterMark(canvas, pixelRatio));
    },
    getChartDesc() {
      return chartDesc;
    },
    getChartSize() {
      if (Object.keys(hanldeData).length === 0) {
        return {
          width: 0,
          height: 0,
        };
      }
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {!chartLoading && (Object.keys(hanldeData).length === 0)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <div className={styleMenu}>基本信息</div>
      <div className={styleBasicInfo}>
        <div className={styleInfoLeft}>
          <div className={styleLableValue}>
            <div className={styleLabel}>企业名称</div>
            <div className={styleValueMsg}>{hanldeData?.info?.name || ''}</div>
          </div>
          <div className={styleBottomLine} />
          <div className={styleLableValue}>
            <div className={styleLabel}>信用代码</div>
            <div className={styleValueMsg}>{hanldeData?.info?.code || ''}</div>
          </div>
          <div className={styleBottomLine} />
          <div style={{ width: '100%', display: 'flex' }}>
            <div className={styleAverage}>
              <div className={styleLableValue}>
                <div className={styleLabel}>注册时间</div>
                <div className={styleValueMsg}>{hanldeData?.info?.registrTime || ''}</div>
              </div>
            </div>
            <div className={styleAverage}>
              <div className={styleLableValue}>
                <div className={styleLabel} style={{ borderRight: 'none' }}>法人姓名</div>
                <div className={styleValueMsg}>{hanldeData?.info?.legalPerson || ''}</div>
              </div>
            </div>
          </div>
          <div className={styleBottomLine} />
          <div className={styleLableValue}>
            <div className={styleLabel}>注册地址</div>
            <div className={styleValueMsg}>{hanldeData?.info?.address || ''}</div>
          </div>
          <div className={styleBottomLine} />
          <div className={styleLableValue}>
            <div className={styleLabel}>注册资本</div>
            <div className={styleValueMsg}>{hanldeData?.info?.registerCapital || ''}</div>
          </div>
          <div className={styleBottomLine} />
          <div className={styleLableValue}>
            <div className={styleLabel}>主要股东</div>
            <div className={styleValueMsg}>{hanldeData?.info?.shareholders || ''}</div>
          </div>
          <div className={styleBottomLine} />
          <div className={styleLableValue}>
            <div className={styleLabel}>所属行业</div>
            <div className={styleValueMsg}>{hanldeData?.info?.industrys || ''}</div>
          </div>
        </div>
        <div className={styleInfoRight}>
          <div className={styleGradeLabel}>上奇评级</div>
          <div className={styleGradeValue}>{hanldeData?.info?.grade || ''}</div>
        </div>
      </div>

      <div className={styleMenu}>行业实力</div>
      <div className={styleLableValue}>
        <div className={styleLabel}>上市/挂牌</div>
        <div className={styleValue}>
          <Checkbox.Group options={listTypes} value={[hanldeData?.industryStrength?.listing || '']} />
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>分子公司</div>
        <div className={styleValue}>
          <span className={styleScaleFont}>设立分支机构</span>
          <span className={styleSpecialNum}>
            {hanldeData?.industryStrength?.institutionCount || 0}
          </span>
          <span className={styleScaleFont}>家，对外投资</span>
          <span className={styleSpecialNum}>
            {hanldeData?.industryStrength?.investmentCount || 0}
          </span>
          <span className={styleScaleFont}>家</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>前三股东</div>
        <div className={styleValue}>
          <span className={styleScaleFont}>{hanldeData?.industryStrength?.top3Shareholders || ''}</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>主要资质</div>
        <div className={styleValue}>
          <span className={styleScaleFont}>{hanldeData?.industryStrength?.mainQualifications || ''}</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>所获荣誉</div>
        <div className={styleValue}>
          <span className={styleScaleFont}>{hanldeData?.industryStrength?.honorsReceived || ''}</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>行业排名</div>
        <div className={styleValue} style={{ display: 'flex', padding: 0 }}>
          <div className={styleValue} style={{ width: '50%', borderRight: '1px solid #E9E5E1' }}>
            <span className={styleScaleFont}>标准行业内排名</span>
            <span className={styleSpecialNum}>
              {hanldeData?.industryStrength?.standardSort || 0}
            </span>
            <span className={styleScaleFont} style={{ color: '#3E5C76' }}>
              {`/${hanldeData?.industryStrength?.sortTotal || 0}`}
            </span>
          </div>
          <div className={styleValue} style={{ width: '50%' }}>
            <span className={styleScaleFont}>专利行业内排名</span>
            <span className={styleSpecialNum}>{hanldeData?.industryStrength?.patentSort || 0}</span>
            <span className={styleScaleFont} style={{ color: '#3E5C76' }}>
              {`/${hanldeData?.industryStrength?.sortTotal || 0}`}
            </span>
          </div>
        </div>
      </div>

      <div className={styleMenu}>创新能力</div>
      <div className={styleLableValue}>
        <div className={styleLabel}>主导标准</div>
        <div className={styleValue}>
          <span className={styleScaleFont}>国家标准</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.nationalStandardCount || 0}
          </span>
          <span className={styleScaleFont}>项、行业标准</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.industryStandardCount || 0}
          </span>
          <span className={styleScaleFont}>项、地方标准</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.localStandardsCount || 0}
          </span>
          <span className={styleScaleFont}>项、团队标准</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.teamStandardsCount || 0}
          </span>
          <span className={styleScaleFont}>项、企业标准</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.enterpriseStandardCount || 0}
          </span>
          <span className={styleScaleFont}>项</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>所有专利</div>
        <div className={styleValue}>
          <span className={styleScaleFont}>已授权专利</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.authorizedPatentCount || 0}
          </span>
          <span className={styleScaleFont}>项、申请中专利</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.patentApplicationCount || 0}
          </span>
          <span className={styleScaleFont}>项</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>创新平台</div>
        <div className={styleValue}>
          <span className={styleScaleFont}>国家级创新平台</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.bationalInnovationPlatformCount || 0}
          </span>
          <span className={styleScaleFont}>个、省级创新平台</span>
          <span className={styleSpecialNum}>
            {hanldeData?.innovationAbility?.provincialInnovationPlatformCount || 0}
          </span>
          <span className={styleScaleFont}>个</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>合作关系</div>
        <div className={styleValue}>
          {hanldeData?.innovationAbility?.partnership || ''}
        </div>
      </div>

      <div className={styleMenu}>融资能力</div>
      <div className={styleLableValue}>
        <div className={styleLabel}>融资次数</div>
        <div className={styleValue}>
          <span className={styleSpecialNum} style={{ marginLeft: 0 }}>
            {hanldeData?.financingCapacity?.rounds || 0}
          </span>
          <span className={styleScaleFont}>轮</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>融资总额</div>
        <div className={styleValue}>
          <span className={styleSpecialNum} style={{ marginLeft: 0 }}>
            {hanldeData?.financingCapacity?.total || 0}
          </span>
          <span className={styleScaleFont}>
            {hanldeData?.financingCapacity?.unit || ''}
          </span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>平均额度</div>
        <div className={styleValue}>
          <span className={styleSpecialNum} style={{ marginLeft: 0 }}>
            {hanldeData?.financingCapacity?.average || 0}
          </span>
          <span className={styleScaleFont}>
            {hanldeData?.financingCapacity?.unit || ''}
          </span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>融资历程</div>
        <div className={styleValue}>
          <span className={styleSpecialNum} style={{ marginLeft: 0 }}>
            {hanldeData?.financingCapacity?.process || 0}
          </span>
          <span className={styleScaleFont}>年</span>
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>投资机构</div>
        <div className={styleValue}>
          {hanldeData?.financingCapacity?.institutions || ''}
        </div>
      </div>

      <div className={styleMenu}>成长潜力</div>
      <div className={styleLableValue}>
        <div className={styleLabel}>营收增速</div>
        <div className={styleValue}>
          {hanldeData?.growthPotential?.revenueGrowth || ''}
        </div>
      </div>
      {/* <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>人数增速</div>
        <div className={styleValue}>
          {hanldeData?.growthPotential?.populationGrowth || ''}
        </div>
      </div> */}
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>专利增速</div>
        <div className={styleValue}>
          {hanldeData?.growthPotential?.PatentGrowth || ''}
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>标准增速</div>
        <div className={styleValue}>
          {hanldeData?.growthPotential?.standardGrowth || ''}
        </div>
      </div>
      <div className={styleBottomLine} />
      <div className={styleLableValue}>
        <div className={styleLabel}>市值增速</div>
        <div className={styleValue}>
          {hanldeData?.growthPotential?.MarketValueGrowth || ''}
        </div>
      </div>
    </div>);
});

export default CompanyPortrait;

CompanyPortrait.defaultProps = {
  mode: 'light',
  // data: data1,
};
