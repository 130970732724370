/*
* Author: zhangmeng
* Description: '柱状图'
* Date: 2022/03/02
*/

import React, { useState, useEffect } from 'react';
// import ReactEcharts from '../../../../modules/echarts-for-react';
import ReactEcharts from 'echarts-for-react';

/**
 * @param {{
 *   data: any;
 *   disableLegend: boolean;
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function BarChart(props) {
  const { data, disableLegend = true, download = false } = props;
  const [options, setOptions] = useState({});
  useEffect(() => {
    if (Object.keys(data.data).length < 1) return;
    const { data: { xAxis, yAxis, yName }, curLocation, bubble = 60, gridBottom = 86 } = data;
    if (!Array.isArray(yAxis)) return;
    const legend = yAxis?.map((item, index) => (
      {
        name: item.name,
        itemStyle: {
          color: '#1961F5',
        },
      }
    ));
    const newData = yAxis[0]?.value?.map((item, index) => (
      {
        value: item,
        itemStyle: {
          color: '#1961F5',
        },
      }
    )) ?? [];
    const option = {
      tooltip: {
        // show: false,
        // trigger: 'axis',
        backgroundColor: '#fff',
        borderWidth: 0,
        borderColor: '#7B8796',
        textStyle: {
          color: '#7B8796',
        },
      },
      toolbox: download && {
        show: true,
        feature: {
          saveAsImage: {
            type: 'png',
          },
        },
      },
      grid: {
        top: 30,
        right: 10,
        bottom: gridBottom,
        left: 60,
      },
      legend: {
        show: !disableLegend,
        bottom: 5,
        itemWidth: 8,
        itemHeight: 10,
        symbol: 'rect',
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: legend,
      },
      xAxis: [
        {
          type: 'category',
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#000000',
            },
          },
          axisLabel: {
            interval: 0,
            rotate: xAxis?.length > 10 && bubble,
            fontSize: xAxis?.length > 12 ? 9 : 12,
            formatter: v => {
              let label = '';
              if (v === '客户区') {
                label = `{curArea|${v}}`;
              } else {
                label = `{otherArea|${v}}`;
              }
              return label;
            },
            rich: {
              curArea: {
                color: '#00F2FE',
                fontSize: 8,
              },
              otherArea: {
                color: '#7B8796',
                fontSize: 10,
              },
            },
          },
          data: xAxis,
        },
      ],
      yAxis: [
        {
          name: `(${yName || ''})`,
          // nameTextStyle: {
          //   color: '#7B8796',
          //   fontSize: 12,
          // },
          type: 'value',
          // max: (value) => value.max + 10,
          // axisLine: {
          //   show: true,
          //   lineStyle: {
          //     color: '#02276B',
          //   },
          // },
          axisLabel: {
            color: '#7B8796',
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              color: '#EBEBEB',
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          name: yAxis[0].name,
          type: 'bar',
          barMaxWidth: 18,
          data: newData,
          label: {
            show: newData.length < 6,
            position: 'top',
            valueAnimation: true,
          },
        },
      ],
    };
    setOptions(option);
  }, [data, disableLegend]);

  return (
    <ReactEcharts
      option={options}
      notMerge
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default BarChart;
