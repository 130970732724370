import React from 'react';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import CustomTable from '../../../CustomTable';
import { openNewPage } from '../../../../../../../utils/urlMethods';
import style from './index.module.less';

const Table = (props) => {
  const { data: tableData, y } = props;
  const history = useHistory();

  const columns = (data) => {
    if (!data) return [];
    return data.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'total_point') {
        return {
          ...item,
          width: 100,
          sortDirections: ['ascend', 'descend'],
          sorter: true,
        };
      }
      if (item.dataIndex === 'tag_name') {
        return {
          ...item,
          ellipsis: {
            showTitle: false,
          },
          render: (address) => (
            <Tooltip placement="topLeft" title={address?.join('/')}>
              {address?.join('/')}
            </Tooltip>
          ),
        };
      }
      if (item.dataIndex === 'name') {
        return {
          ...item,
          width: 300,
          render: (text, { _id }) => (
            <div
              className={style.name}
              onClick={() => {
                if (!_id) return;
                openNewPage('companyDetail', { id: _id });
              }}
            >
              {text}
            </div>
          ),
        };
      }
      if (item.dataIndex === 'location') {
        return {
          ...item,
          width: 260,
        };
      }
      if (item.dataIndex === 'operation') {
        return {
          ...item,
          align: 'center',
          width: 200,
          render: (text, { _id, name }) => (
            <div className={style.handle}>
              <button
                type="button"
                onClick={() => { history.push(`/companyIntelligentEvaluation/details?id=${_id}`); }}
              >
                查看详情
              </button>
              <button
                type="button"
                onClick={() => { history.push(`/companyIntelligentEvaluation/basicInformation?companyId=${_id}&name=${name}`); }}
              >
                基础测评
              </button>
            </div>
          ),
        };
      }
      return item;
    });
  };
  return (
    <CustomTable {...props} customOption={{ columns: columns(tableData?.columns), scroll: { y } }} />
  );
};

export default Table;
