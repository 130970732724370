// 靶向招标
import React, { useState, useEffect } from 'react';
import { Select, Tree } from 'antd';
import { useGetIndustryTreeQuery } from '../../../../../../service/public';
import IndustrialChainGraph from './IndustrialChainGraph';
import style from './index.module.less';

const TargetMerchants = (props) => {
  // 产业列表
  const { parkCode } = props;
  const [industryType, setIndustryType] = useState(null);
  const [industry, setIndustry] = useState({ title: '全部', code: 'all' });

  const { data: indusrtyDatalist } = useGetIndustryTreeQuery();

  const onSelectRegion = (selectedKeys, info) => {
    setIndustry({
      title: info.node.title,
      code: selectedKeys[0] || 'all',
    });
    const indusrtyCode = selectedKeys[0];
    setIndustryType(indusrtyCode);
  };

  useEffect(() => {
    if (indusrtyDatalist) {
      setIndustryType(indusrtyDatalist.children[0].children[0].value);
      setIndustry({
        title: indusrtyDatalist.children[0].children[0].title,
        code: indusrtyDatalist.children[0].children[0].value,
      });
    }
  }, [indusrtyDatalist]);

  return (
    <div className={style.box}>
      <div className={style.input}>
        <div className={style.label}>产业</div>
        <Select
          placeholder="请选择行业"
          value={industry.title}
          style={{
            width: 280,
          }}
          dropdownRender={() => (
            indusrtyDatalist && (
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                <Tree
                  onSelect={onSelectRegion}
                  treeData={[indusrtyDatalist]}
                  defaultExpandedKeys={indusrtyDatalist ? [indusrtyDatalist.key] : []}
                />
              </div>
            )
          )}
        />
      </div>
      <div className={style.conterBox}>
        <IndustrialChainGraph {...props} parkCode={parkCode} industryCode={industryType} industry={industry} />
      </div>
    </div>
  );
};

export default TargetMerchants;
