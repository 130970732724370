import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import intelligentIcon from '../../../../../../../assets/images/dataAnalysisTool/intelligentIcon.gif';
import styles from './index.module.less';

export default function IntelligentPreview({ displayData }) {
  const intelligentDataStore = useSelector(store => store.dataAnalysis.intelligentDataStore);
  return (
    <div className={styles.wrapper}>
      {
        intelligentDataStore?.length > 0 && intelligentDataStore.map(item => (
          <div className={classNames(styles.box, { [styles.displayBox]: displayData && displayData.child?.filter(i => i.id === item.id)?.[0]?.disable })} key={item?.id} id={item?.id}>
            <div className={styles.top}>
              <img src={intelligentIcon} alt="" />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>{item?.text}</div>
              <div className={styles.reply}>
                {item.message}
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
}
