// 专利推荐
import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import style from './index.module.less';
import { useGetPatentRecommendationsQuery } from '../../../../../service/companyDetail';
import { openNewPage } from '../../../../../utils/urlMethods';

function PatentRecommend(props) {
  const { companyId, preventPageto } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: tableFetching } = useGetPatentRecommendationsQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'title' && !preventPageto) {
        return {
          ...item,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('patentDetail', { id: record._id })}
            >
              {text}
            </div>
          ),
        };
      }
      return {
        ...item,
      };
    })
  ), [tableData, preventPageto]);

  return (
    <div className={style.all} id="专利推荐">
      <div className={style.title}>专利推荐</div>
      <Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={tableColumns}
        strip
        loading={tableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        locale={{ emptyText: ' ' }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />
    </div>
  );
}

export default PatentRecommend;
