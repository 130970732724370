import { ComponentType, useMemo } from 'react';
import { Macro } from '@sqke/parser';
import { DeltaOperation } from 'quill';
import { merge } from 'lodash';

export interface WithMacroProps {
  macro?: {};
  [key: string]: any;
}

interface NestedObject {
  [key: string]: any;
}

/**
 * 获取数据前，替换其他的宏定义
 * @param properties
 * @param macro
 */
export const applyMacro = (properties: any, macro: Macro) => {
  if (!macro) return properties;
  const _props = {} as any;
  const {
    text,
    title,
    contents,
    children,
    dataConfig,
  } = properties;
  const {
    dataSource,
  } = dataConfig ?? {};

  // 标题
  if (text) {
    _props.text = macro.replace(text);
  }
  // 图、表
  if (title) {
    _props.title = {
      ...title,
      content: macro.replace(title.content),
    };
  }
  // 富文本
  if (contents) {
    _props.contents = contents.map((op: DeltaOperation) => {
      return  {...op, insert: macro.replace(op.insert)};
    });
  }
  // 数据源
  if (dataSource) {
    _props.dataConfig = {
      dataSource: { ...dataSource }
    };
    // _props.dataConfig.dataSource = {};
    _props.dataConfig.dataSource.url = macro.replace(dataSource.url);
    // maybe nest
    const replaceMacro = (obj: NestedObject) => {
      const _nextObj = {} as NestedObject;
      for (const key in obj) {
        const strOrObj = obj[key];
        if (strOrObj && typeof strOrObj === 'object') {
          _nextObj[key] = replaceMacro(strOrObj);
        } else if (typeof strOrObj === 'string') {
          _nextObj[key] = macro.replace(strOrObj);
        } else {
          _nextObj[key] = strOrObj;
        }
      }
      return _nextObj;
    };
    if (dataSource.params) {
      _props.dataConfig.dataSource.params = replaceMacro(dataSource.params);
    }
    if (dataSource.body) {
      _props.dataConfig.dataSource.body = replaceMacro(dataSource.body);
    }
  }

  if (Array.isArray(children) && children.length > 0) {
    _props.children = children.map(item => ({ ...item, properties: applyMacro(item.properties, macro) }));
  }

  return merge(
    {},
    properties,
    _props,
  );
}

export function withMacro<T extends WithMacroProps>(WrappedComponent: ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  const Component = (props: T) => {
    const replacedProps = useMemo(() => {
      if (!props.macro) {
        return {};
      }
      return applyMacro(props, new Macro(props.macro))
    }, [props]);

    return <WrappedComponent {...merge({}, props, replacedProps) as T} />;
  };

  Component.displayName = `withMacro(${displayName})`;

  return Component;
}
