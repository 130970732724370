/**
 报告列表
 */

import { apiSlice } from '../slices/api';

const FINESYSTEMREPORT = '/fineSystemReport';

export const reportPageApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getReport: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/find_report_list`,
          params: arg,
        };
      },
    }),
    getReportCategory: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/find_report_category`,
          params: arg,
        };
      },
    }),
  }),
});

export const {
  useGetReportQuery,
  useGetReportCategoryQuery,
} = reportPageApi;
