/*
 *Author: zhaochenyu
 *Description: 专精特新专题
 *Date: 2022/05/07
*/

import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { Carousel, Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import bannerWord from '../../../assets/images/specializedAndNew/zhuanjingtexin.png';
import bannerWord2 from '../../../assets/images/specializedAndNew/zhongbangfabu.png';
import MapAndTable from './MapAnaTable';
import CarouselList from '../components/CarouselList';
import ReportList from '../components/ReportList';
import Ranking from './Ranking';
import { openNewPage } from '../../../utils/urlMethods';
import {
  getNewsList,
  getPolicyList,
  getReportList,
} from '../../../api/specialAndNews';
import style from './index.module.less';

function SpecializedAndNew() {
  const [newsList, setNews] = useState([]);
  const [policyList, setPolicyList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [areaCode, setAreaCode] = useState('000000');
  const [level, setLevel] = useState('');
  const [loadings, setLoadings] = useState([false, false, false]);
  const carouselRef = useRef(null);
  const loadingRef = useRef([false, false, false]);
  const news = useMemo(() => (
    <CarouselList
      data={newsList}
      type="new"
    />
  ), [newsList]);

  const policys = useMemo(() => (
    <CarouselList
      data={policyList}
      type="policy"
    />
  ), [policyList]);
  const getCurReportList = useCallback(() => {
    loadingRef.current[2] = true;
    setLoadings(JSON.parse(JSON.stringify(loadingRef.current)));
    getReportList().then(res => {
      if (res.status === '00000') {
        setReportList(res.data || []);
        loadingRef.current[2] = false;
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[2] = false;
        setLoadings(cloneDeep(loadingRef.current));
      }
    });
  }, []);

  useEffect(() => {
    setLoadings([true, true, true]);
    loadingRef.current = [true, true, true];
    getNewsList().then(res => {
      if (res.status === '00000') {
        setNews(res.data?.news || []);
        loadingRef.current[0] = false;
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[0] = false;
        setLoadings(cloneDeep(loadingRef.current));
      }
    });
    getPolicyList().then(res => {
      if (res.status === '00000') {
        setPolicyList(res.data?.news || []);
        loadingRef.current[1] = false;
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[1] = false;
        setLoadings(cloneDeep(loadingRef.current));
      }
    });
    getCurReportList();
  }, []);

  const refreshReportList = (msg) => {
    if (msg.data === 'refreshReportList') {
      getCurReportList();
    }
  };

  useEffect(() => {
    window.addEventListener('message', refreshReportList);
    return () => {
      window.removeEventListener('message', refreshReportList);
    };
  }, []);

  const report = useMemo(() => (
    <ReportList
      data={reportList}
    />
  ), [reportList]);

  return (
    <div className={style.all}>
      <div className={style.banner}>
        <div
          className={style.toleft}
          onClick={() => carouselRef.current.prev()}
        >
          <LeftOutlined />
        </div>
        <div
          className={style.toright}
          onClick={() => carouselRef.current.next()}
        >
          <RightOutlined />
        </div>
        <Carousel autoplay dotPosition="bottom" ref={el => { carouselRef.current = el; }}>
          <div className={style.banner1}>
            <img alt="" src={bannerWord} className={style.banner1Word} />
            <div className={style.banner1Desc}>
              “专精特新”中小企业是指具有“专业化、精细化、特色化、 新颖化”特征的中小企业，企业规模必须符合国家《中小企业划型标准》
            </div>
          </div>
          <div className={style.banner2}>
            <div className={style.bangs}>
              <div className={style.desc1}>
                {['竞争力', '创新价值', '投资价值', '成长潜力百强榜'].map((item, index) => (
                  <div className={style.bangsItem} key={`${index}item`}>
                    {item}
                    <div className={style.rightLine} style={{ display: index === 3 ? 'none' : '' }} />
                  </div>
                ))}
              </div>
            </div>
            <div className={style.desc2}>电子信息专精特新百强报告</div>
            <img alt="" src={bannerWord2} className={style.banner2Word} />
            <div
              className={style.reportBtn}
              onClick={() => {
                if (reportList.length > 0) {
                  const { id, title, sub_title: subTitle, Buy_state: isApply, price,
                    cover_url: coverImg } = reportList[0];
                  openNewPage('boutiqueDetail', {
                    msg: window.btoa(window.encodeURIComponent(JSON.stringify({
                      title,
                      subTitle,
                      isApply,
                      price,
                      coverImg,
                      id,
                    }))),
                  });
                }
              }}
            >
              点击查看报告
            </div>
          </div>
        </Carousel>
      </div>
      <div className={style.map}>
        <MapAndTable
          level={level}
          setLevel={setLevel}
          areaCode={areaCode}
          setAreaCode={setAreaCode}
        />
      </div>
      <div className={style.ranking}>
        <p className={style.rankingTitle}>
          榜单
        </p>
        <div className={style.rankingList}>
          <Ranking
            level={level}
            areaCode={areaCode}
          />
        </div>
      </div>
      <div className={style.otherMsg}>
        <div className={style.msgItem}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
            {news}
          </Spin>
        </div>
        <div className={style.msgItem}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
            {policys}
          </Spin>
        </div>
        <div className={style.msgItem}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
            {report}
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default SpecializedAndNew;
