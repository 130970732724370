import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
import Score from '../../../../components/Score';
import styles from './index.module.less';
import {
  useGetChannelReportListQuery,
  useGetChannelReportTypeListQuery,
} from '../../../../service/industry-channel';
import { getHighlightConfig, getNameConfig, getSortKv } from '../../../../utils/table';
import { FilterIcon } from '../../../../components/CustomTableIcon';
import SortTitle from '../SortTitle';
import CustomListFilter from '../../../../components/CustomListFilter';

const MultiLineEllipsis = text => (
  <div className="overflow-multi-5" title={text}>{text}</div>
);

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: 'table-index',
  },
  report_name: {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
  },
  author: {
    title: '作者',
    dataIndex: 'author',
    key: 'author',
    width: 285,
    className: styles.number,
    render: MultiLineEllipsis,
  },
  pages: {
    title: '页数',
    dataIndex: 'pages',
    key: 'pages',
    width: 60,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
  },
  report_type: {
    title: '研报类型',
    dataIndex: 'report_type',
    key: 'report_type',
    width: 100,
    className: styles.number,
    filters: [],
    filterIcon: <FilterIcon />,
    render: MultiLineEllipsis,
  },
  institution: {
    title: '发布机构',
    dataIndex: 'institution',
    key: 'institution',
    width: 100,
    ellipsis: true,
    render: MultiLineEllipsis,
  },
  release_time: {
    title: '发布日期',
    dataIndex: 'release_time',
    key: 'release_time',
    width: 108,
    sorter: true,
    ellipsis: true,
    render: MultiLineEllipsis,
  },
  report_score: {
    title: '研报价值分',
    dataIndex: 'report_score',
    key: 'report_score',
    width: 110,
    ellipsis: true,
    // align: 'center',
    className: styles.score,
    render: text => <Score score={text} />,
    sorter: true,
  },
};

const COLUMN_KEYS = [
  'index', 'report_name', 'pages', 'author', 'report_type', 'institution', 'release_time', 'report_score',
];

function ReportTable(props) {
  const {
    keyword,
    industryCode,
    sortedInfo = {},
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onReportClick,
    onData,
  } = props;

  const {
    key: sortKey,
    value: sortValue,
  } = getSortKv(sortedInfo);

  const { data: types } = useGetChannelReportTypeListQuery();
  const { data, isFetching } = useGetChannelReportListQuery({
    key_word: keyword,
    industry_code: industryCode,
    sort_key: sortKey,
    sort_value: sortValue,
    report_type: (filteredInfo?.report_type && filteredInfo.report_type.filter(item => item !== 'all').length !== 0)
      ? filteredInfo.report_type : undefined,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });

  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      switch (item.dataIndex) {
        case 'report_name':
          rowConfig = {
            ...rowConfig,
            ...getNameConfig(({
              onClick: onReportClick,
              keyword,
              className: 'clickable-name overflow-multi-5',
              idField: '_id',
            })),
          };
          break;
        case 'author':
        case 'institution':
          rowConfig = {
            ...rowConfig,
            ...getHighlightConfig({ keyword, className: 'overflow-multi-5' }),
          };
          break;
        case 'report_type':
          rowConfig.filters = types;
          rowConfig.filteredValue = filteredInfo?.report_type?.length > 0 ? filteredInfo.report_type : ['all'];
          rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
          rowConfig.filterResetToDefaultFilteredValue = true;
          break;
        default:
          break;
      }
      // if ([
      //   'industry_patent',
      //   'accumulation_monthly',
      // ].includes(item.dataIndex)) {
      //   rowConfig.render = (text, record) => (
      //     <DataSearchButton
      //       text={text}
      //       style={{ marginLeft: 8 }}
      //       onClick={() => onClick && onClick({ record, column: item })}
      //     />
      //   );
      // }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [onReportClick, columns, filteredInfo]);

  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);
  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      {...props}
    />
  );
}

export default ReportTable;
