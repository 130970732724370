import { cloneDeep, merge } from 'lodash';
import { array } from '../../utils/array';
const KEYS = ['itemStyle', 'areaStyle', 'label', 'labelLine'];

export class EChartsThemeManager {
  _echarts;
  _themes: Record<string, any> = {};

  constructor(echarts: any) {
    this._echarts = echarts;
  }

  registerTheme(name: string, theme: { base: Record<string, any>, extend?: Record<string, any> }) {
    this._themes[name] = theme;
    const { base } = theme;
    this._echarts.registerTheme(name, base);
  }

  useTheme(option: any, name: string) {
    if (!option || !option.series) {
      return option;
    }
    const mergeGeo = (geo: any) => {
      const _geo = this._themes[name]?.extend?.geo;
      if (!_geo) {
        return geo;
      }
      if (!geo) {
        return _geo;
      }
      const mergeEach = (target: any, source: any) => {
        const _output = {...target, ...source};
        if (target.label && source.label) {
          _output.label = {
            ...target.label,
            ...source.label,
          };
        }
        return _output;
      };
      return mergeEach(cloneDeep(_geo), geo);
    };
    // 根据主题，提取对应的 key 合并到 series 中
    // 如果 property 不为 undefined，则合并 extendProperty
    // 如果 property 为 undefined，则直接使用 extendProperty
    const mergeSeriesStyle = (key: string, series: any, theme: string, index = 0) => {
      const { type } = series;
      const property = series[key];
      const extendProperty = array(this._themes[name]?.extend?.[type])?.[index]?.[key];
      // 排除 null
      if (property === null) {
        return property;
      }
      // 不设置 areaStyle 则应默认不分配
      if (property === undefined && key === 'areaStyle') {
        return property;
      }
      // 其他情况，如果默认无该属性，则直接分配
      if (property === undefined) {
        return extendProperty;
      }
      // 此时，property 不为 null 或者 undefined
      if (extendProperty === undefined) {
        return property;
      }
      // 如果二者类型不一致，则不予合并 排除（null）
      if (typeof property !== typeof extendProperty) {
        return property;
      }
      if (typeof property !== 'object') {
        return extendProperty;
      }
      return merge(cloneDeep(extendProperty), cloneDeep(property));
    };
    // 主题合并到 series 中
    // typeIndex: 每种类型已出现的次数
    const mergeSeries = (item: any, typeIndex: number) => {
      // 支持自定义的属性
      const style = array(this._themes[name]?.extend?.[item.type])?.[typeIndex];
      const keys = style ? Object.keys(style) : [];
      const things = keys.reduce((prev, key) => {
        const mergedStyle = mergeSeriesStyle(key, item, name, typeIndex);
        if (mergedStyle === undefined) {
          return prev;
        }
        prev[key] = mergedStyle;
        return prev;
      }, {} as Record<string, any>);

      return merge(
        {
          ...item,
        },
        things
      );
    };
    const map = new Map();
    const { geo, series } = option;
    const _option = {...option};
    if (geo) {
      _option.geo = mergeGeo(geo);
    }

    if (series) {
      if (!Array.isArray(series)) {
        _option.series = mergeSeries(series, 0);
      }
      _option.series = series?.map((item: any) => {
        const seriesCount = map.get(item.type) ?? 0;
        map.set(item.type, seriesCount + 1);
        return mergeSeries(item, seriesCount);
      });
    }

    return _option;
  }
}
