/*
* Author: zhangmeng
* Description: '标准详情页-top'
* Date: 2021/05/07
*/

import React, { useCallback, useState } from 'react';
import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import Logo from '../../../../assets/images/standarDetail/logo.png';
// import { companyTabsColorMap } from '../../../../utils/color';
import { tagStyleMap } from '../../../../utils/tagColor';
import style from './index.module.less';

function DetailTop(props) {
  const { data } = props;
  const [more1, setMore1] = useState(false);
  const [show1, setShow1] = useState(false);
  const [more2, setMore2] = useState(false);
  const [show2, setShow2] = useState(false);
  const ref1 = useCallback(node => {
    if (node) {
      if (node.scrollWidth > node.clientWidth) {
        setShow1(true);
      } else {
        setShow1(false);
      }
    }
  });
  const ref2 = useCallback(node => {
    if (node) {
      if (node.scrollWidth > node.clientWidth) {
        setShow2(true);
      } else {
        setShow2(false);
      }
    }
  });
  return (
    <div className={style.all}>
      <div className={style.logoDesc}>
        <div className={style.logo}>
          <img src={Logo} alt="" style={{ width: '100%', height: '100%' }} />
        </div>
        <div className={style.desc}>
          <div className={style.title}>{data.title}</div>
          <div className={style.tags}>
            {
              data.tags && data.tags.map((item, index) => (
                item.tabs.map((item1, index1) => (
                  <span
                    key={`${index}${index1}${item1}`}
                    className={`${style.tag} ${tagStyleMap[item.type]}`}
                  >
                    {item1}
                  </span>
                ))
              ))
            }
          </div>
          <div className={style.standardNum}>
            <span className={style.label}>标准号</span>
            <span className={style.value}>{data.standard_num}</span>
          </div>
          <div className={style.standardRecordNum}>
            <span className={style.label}>标准备案号</span>
            <span className={style.value}>{data.standard_record_num}</span>
          </div>
          <div className={style.startDate}>
            <span className={style.label}>实施日期</span>
            <span className={style.value}>{data.start_date}</span>
          </div>
          <div className={style.recordDate}>
            <span className={style.label}>备案日期</span>
            <span className={style.value}>{data.record_date}</span>
          </div>
          <div className={style.industryDomain}>
            <span className={style.label}>行业领域</span>
            <span className={style.value}>{data.industry_domain}</span>
          </div>
          <div className={style.sqType}>
            <span className={style.label}>上奇行业分类</span>
            <span className={style.value}>{data.sq_type}</span>
          </div>
          <div className={style.draftingCommittee}>
            <span className={style.label}>起草单位</span>
            <div className={style.value} ref={ref1} style={{ whiteSpace: more1 ? 'normal' : 'nowrap' }}>
              <div>{data.drafting_committee || '暂无'}</div>
            </div>
            <div className={style.more} style={{ display: show1 || more1 ? '' : 'none' }} onClick={() => setMore1(!more1)}>
              更多
              {
                more1 ? (
                  <UpCircleOutlined />
                ) : (
                  <DownCircleOutlined />
                )
              }
            </div>
          </div>
          <div className={style.rangeApplication}>
            <span className={style.label}>适用范围</span>
            <div className={style.value} ref={ref2} style={{ whiteSpace: more2 ? 'normal' : 'nowrap' }}>
              <span>{data.range_application || '暂无'}</span>
            </div>
            <div className={style.more} style={{ display: show2 || more2 ? '' : 'none' }} onClick={() => setMore2(!more2)}>
              更多
              {
                more2 ? (
                  <UpCircleOutlined />
                ) : (
                  <DownCircleOutlined />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailTop;
