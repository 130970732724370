/*
* Author: zhangmeng
* Description: ''
* Date: 2021/05/08
*/

import React, { useEffect, useMemo, useState } from 'react';
import { IconFont, tabMap } from '../../../../../../utils/mapIcon';
import InstitutionDetail from '../../../../../IndustryInstitutionDetail/InstitutionDetail';
import InstitutionTable from '../../../../../IndustryInstitutionDetail/InstitutionTable';
import { getOneInstitutionData } from '../../../../../../api/institutionDetail';
import style from './index.module.less';

function InstitutionDetailPage() {
  const [detailData, setDetailData] = useState({});
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);

  const institutionDetail = useMemo(() => (
    Object.keys(detailData).length > 0
    && <InstitutionDetail data={detailData} />
  ), [detailData]);

  useEffect(() => {
    getOneInstitutionData({
      institution_id: params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setDetailData(res.data);
      }
    });
  }, []);

  return (
    <div className={style.all}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.title}>
            <span className={style.before}>
              产业通
              &emsp;
              <span className={style.point} />
              &emsp;
            </span>
            <span className={style.later}>机构</span>
          </div>
          <div className={style.imgBox}>
            <IconFont type={tabMap.institution} />
          </div>
        </div>
      </div>
      <div className={style.detail}>
        {institutionDetail}
      </div>
      {
        Object.keys(detailData).length > 0
        && (detailData.field.length !== 0 || detailData.support_unit.length !== 0) && (
          <div
            className={style.other}
          >
            <InstitutionTable field={detailData.field} departments={detailData.support_unit} />
          </div>
        )
      }
    </div>
  );
}

export default InstitutionDetailPage;
