import React, { useMemo, useEffect } from 'react';
import Table from '../../../../../components/Table';
import styles from './index.module.less';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetCompanySpecialTagsQuery,
} from '../../../../../service/interactive-v2';
import { useGetIndustryDistributionSubtableQuery } from '../../../../../service/cityDetail';
import CustomTreeFilter from '../../../../../components/MultipleChoiceTree';
import CustomListFilter from '../../../../../components/CustomListFilter';
import { FilterIcon } from '../../../../../components/CustomTableIcon';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  company_name: {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    ellipsis: true,
    className: styles.name,
  },
  special_tag: {
    title: '资质类别',
    dataIndex: 'special_tag',
    key: 'special_tag',
    width: 120,
    ellipsis: true,
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  location: {
    title: '区域',
    dataIndex: 'location',
    key: 'location',
    width: 120,
    ellipsis: true,
    filters: ['all'],
    filterIcon: <FilterIcon />,
    filterMode: 'tree',
  },
};

const COLUMN_KEYS = ['index', 'company_name', 'special_tag', 'location'];

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

function SectorCompanyTable(props) {
  const {
    industryCode,
    regionCode,
    pageNumber = 1,
    pageSize = 20,
    companyTypes,
    onCompanyClick,
    filteredInfo = {},
    getCount,
  } = props;
  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetIndustryDistributionSubtableQuery({
    area_key: filteredInfo?.location ?? regionCode,
    industry_code: industryCode,
    company_type: getSingleOption(companyTypes),
    special_tag: filteredInfo.special_tag?.filter(tag => tag !== 'all') ?? [],
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });
  const { columns: tableHead = [], dataSource: tableBody, total = 0, count } = companyData ?? {};

  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query({
    area_code: regionCode,
  });
  const { data: companyTags = [] } = useGetCompanySpecialTagsQuery();

  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      // 处理企业名称
      if (item.dataIndex === 'company_name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      if (item.dataIndex === 'special_tag') {
        rowConfig.filteredValue = filteredInfo.special_tag?.length > 0 ? filteredInfo.special_tag : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = companyTags;
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (item.dataIndex === 'location') {
        rowConfig.filters = regionTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
        rowConfig.filteredValue = filteredInfo?.location ?? [regionCode];
      }
      return rowConfig;
    });
  }, [onCompanyClick, tableHead, regionTree, companyTags, filteredInfo, regionCode]);

  useEffect(() => {
    getCount(count);
  }, [getCount, count]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
    />
  );
}

export default SectorCompanyTable;
