import React, { useMemo } from 'react';
import Table from '../../../../components/Table';
import RankingOrder from '../../../../components/RankingOrder';
import Score from '../../../../components/Score';
import { useGetChannelCompanyScoreRankQuery } from '../../../../service/industry-channel';
import styles from './index.module.less';

const THEAD_TABLE = {
  // 123123
  index: {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    render: text => <RankingOrder order={text} />,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    className: 'clickable',
  },
  total_point: {
    title: '上奇评价',
    dataIndex: 'total_point',
    key: 'total_point',
    width: 80,
    render: text => <Score score={text} />,
    align: 'center',
    className: styles.noPadding,
  },
};

const COLUMN_KEYS = ['index', 'name', 'total_point'];

const DATA_SOURCE = [
  {
    index: 1,
    name: '杰克科技股份有限公司',
    score: 92.2,
  },
  {
    index: 2,
    name: '安徽华茂纺织股份有限公司',
    score: 92.2,
  },
  {
    index: 3,
    name: '鲁泰纺织股份有限公司',
    score: 92.2,
  },
  {
    index: 4,
    name: '河南新野纺织股份有限公司',
    score: 92.2,
  },
];

/**
 * 上奇推荐榜单
 * @param {{
 *   industryCode: string;
 *   pageNumber?: number;
 *   pageSize?: number;
 *   onCompanyClick?;
 *   onChange?;
 *   className?: string;
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function RecommendRanking(props) {
  const { pageNumber, pageSize, industryCode, onCompanyClick } = props;
  const { data, isFetching } = useGetChannelCompanyScoreRankQuery({
    industry_code: industryCode,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });

  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) return COLUMN_KEYS.map(key => THEAD_TABLE[key]);
    return columns.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      return rowConfig;
    });
  }, [columns, onCompanyClick]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      current={pageNumber}
      total={total}
      loading={isFetching}
      {...props}
    />
  );
}

export default RecommendRanking;
