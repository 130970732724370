/*
 * 水平条形图
*/
import React, { useMemo } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { HorizontalBarChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, toolTipFormatter } from '../../utils/tool';
import { barHightLightColor } from '../../consts';

import { modeStyle } from './style';

import './index.less';
import '../../index.less';
import { handleData } from './checkDataFormat';

const prefixCls: string = 'cube-component-horizontal-bar-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;
const defaultY = {
  name: '',
  unit: '',
  value: [],
};
const defaultX: string[] | undefined = [];
const defaultHighLight: string[] | undefined = [];
const HorizontalBarChart = forwardRef((props: HorizontalBarChartProps, ref: any) => {
  const {
    title = '', xAxis = defaultX,
    yAxis = defaultY, mode = 'dark',
    fontSize = 'middle', highlight = defaultHighLight,
  } = props;
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    const curStyle = modeStyle[mode];
    const chartColor: any[] = curStyle.itemStyle.barColor;

    const grid = {
      show: false,
      // width: '100%',
      left: 6,
      right: 94,
      top: 8,
      bottom: 20,
      containLabel: true,
    };
    const noData = !xAxis?.length || !yAxis?.value.length;
    const handledData = handleData(xAxis, yAxis);
    const xAxisDefault = {
      name: (!noData && yAxis?.unit) ? `单位: ${yAxis.unit}` : '',
      nameLocation: 'end',
      type: 'value',
      nameTextStyle: {
        color: curStyle.xNameTextStyle.color,
        fontSize: 12,
        padding: [0, 0, 0, 16],
      },
      position: 'bottom',
      // gridIndex: index,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    };

    const yAxisDefault = {
      type: 'category',
      // gridIndex: index,
      data: handledData.map(item => item.name),
      axisLabel: {
        // fontFamily: 'siyuan',
        fontSize: 8,
        interval: 0,
        color: curStyle.yAxisLabel.color,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    };
    const markPoints: any[] = [];
    const labelFontSize = 8;
    let hightLightIndex = -1;
    // highlight.push('青岛市');
    function getItemStyle(
      item: {
        name: string; value: string
      },
      _index: number,
    ) {
      if ((highlight.indexOf(item.name)) !== -1) {
        hightLightIndex += 1;
        const L = barHightLightColor.length;
        markPoints.push({
          name: item.name,
          value: item.value,
          yAxis: item.name,
          xAxis: item.value,
          symbol: 'pin',
          symbolSize: 30,
          symbolOffset: [0, 0],
          symbolRotate: -90,
          itemStyle: {
            color: barHightLightColor[(hightLightIndex) % L],
          },
          label: {
            padding: [0, 0, 0, 8],
            show: true,
            fontSize: 8,
            textBorderColor: barHightLightColor[hightLightIndex % L],
            textBorderWidth: 1,
            color: '#fff',
          },
        });
        return {
          itemStyle: {
            color: barHightLightColor[hightLightIndex % L],
            borderColor: barHightLightColor[hightLightIndex % L],
          },
          label: {
            show: false,
          },
        };
      }
      return {
        itemStyle: {
          colorStops: [
            {
              offset: 0, color: chartColor[0],
            },
            {
              offset: 1, color: chartColor[1],
            },
          ],
        },
      };
    }
    const options = {
      tooltip: {
        show: true,
        confine: true,
        formatter: (params: any) => {
          const { name, data } = params;
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([{
            name: name + yAxis.name,
            unit: yAxis.unit,
            value: data.value,
          }], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      xAxis: xAxisDefault,
      yAxis: yAxisDefault,
      // backgroundColor: '#fff',
      legend: {
        show: false,
      },
      series: {
        barWidth: 10,
        barCategoryGap: 3,
        barMaxHeight: '100%',
        name: '',
        type: 'bar',
        avoidLabelOverlap: true,
        label: {
          color: curStyle.series.label.color,
          fontSize: labelFontSize,
          position: 'right',
          show: true,
        },
        data: handledData.map((item, index) => ({
          value: item.value,
          ...getItemStyle(item as any, index),
        })),
        itemStyle: {
          color: {
            type: 'linear',
            colorStops: [
              {
                offset: 0, color: chartColor[0],
              },
              {
                offset: 1, color: chartColor[1],
              },
            ],
            ...curStyle.itemStyle.linearDirection,
            // global: false,
          },
          borderWidth: 1,
          borderColor: curStyle.itemStyle.borderColor,
        },
        markPoint: {
          data: markPoints,
        },
      },
      grid,
    };
    // rendered event中置为false
    setChartLoading(true);
    setOption(options);
  }, [mode, title, fontSize, xAxis, yAxis, highlight]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!xAxis?.length || !yAxis?.value.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  const [conHeight, setConHeight] = useState('100%');
  const Echart = useMemo(() => {
    const events = {
      finished() {
        setChartLoading(false);
      },
    };
    return <ReactEcharts
      option={option}
      notMerge
      style={{ width: '100%', height: '100%' }}
      ref={chartRef}
      onEvents={events}
    />;
  }, [option, conHeight]);

  // 计算高度 决定当前容器高度
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    const barWidth = 10;
    const allBarCount = ((option as any).series?.data.length || 0);
    let allBarHeight = allBarCount * barWidth;
    let containerHeight = allBarHeight + 3 * (allBarCount - 1) + 36;

    const instance = chartRef.current.getEchartsInstance();
    const ecHeight = instance.getHeight();

    const defaultHeight = 332;
    const defaultYLabelSize = 8;

    let nowYLabelFontSize = (defaultYLabelSize / defaultHeight) * ecHeight;
    let nowBarWidth = (barWidth / defaultHeight) * ecHeight;
    if (nowYLabelFontSize > 12) {
      nowYLabelFontSize = 12;
    }
    if (nowBarWidth > 12) {
      nowBarWidth = 12;
    }
    allBarHeight = allBarCount * nowBarWidth;
    containerHeight = allBarHeight + 3 * (allBarCount - 1) + 36;
    if (!option.yAxis) {
      return;
    }
    option.yAxis.axisLabel.fontSize = nowYLabelFontSize;
    option.series.barWidth = nowBarWidth;
    // setOption({ ...option });
    instance.setOption({ ...option });
    if (ecHeight < containerHeight) {
      setConHeight(`${containerHeight}px`);
    }
  }, [option]);

  return (
    <div className={styleMap[mode!] || styleDark} style={{ height: conHeight }} ref={bodyRef}>
      {
        chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)
      }
      {
        !chartLoading && (!xAxis?.length || !yAxis?.value.length) && (
          <div className={styleEmpty}>暂无数据</div>
        )
      }
      {Echart}
    </div>);
});

export default HorizontalBarChart;

HorizontalBarChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
