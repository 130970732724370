// 行业竞争力
import React, { useMemo } from 'react';
import { ECharts, Table } from '@sqke/design';
import { Spin } from 'antd';
import style from './index.module.less';
import { useGetIndustrialCompetitivenessQuery } from '../../../../../service/companyDetail';

function IndustryCompetitiveness(props) {
  const { companyId } = props;

  const { data, isFetching } = useGetIndustrialCompetitivenessQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const { description, radar_map: radarMap } = data ?? {};

  const option = useMemo(() => {
    const series = radarMap?.series?.map(item => (
      {
        name: item.name,
        type: 'radar',
        data: item.data,
        label: {
          show: false,
        },
        areaStyle: {
          color: 'rgba(25, 97, 245, 0.1)',
        },
      }
    ));
    const indicator = radarMap?.indicator?.map((item, index) => (
      {
        name: item,
        max: 100,
        columns: [series?.[0]?.data?.[0]?.value?.[index]],
        axisLabel: {
          show: index === 0,
        },
      }
    ));

    return {
      radar: {
        radius: '60%',
        nameGap: 10,
        axisName: {
          formatter: (name, indicatorOpt) => `{title|${name || ''}}\n{value|${indicatorOpt?.columns?.[0] || '-'}%}`,
          rich: {
            title: {
              padding: [0, 0, 8, 0],
              fontSize: 14,
              align: 'center',
            },
            value: {
              width: 54,
              height: 22,
              fontSize: 14,
              color: '#1961F5',
              align: 'center',
              verticalAlign: 'middle',
              padding: [2, 0, 0, 0],
              backgroundColor: 'rgb(217,232,255, 0.5)',
              borderWidth: 1,
              borderColor: 'rgba(25,97,245,0.5)',
              borderRadius: 4,
            },
          },
        },
        indicator,
      },
      tooltip: {
        show: false,
      },
      series,
    };
  }, [radarMap]);

  const tableData = useMemo(() => {
    const { pool } = description ?? {};
    const keys = Object.keys(pool ?? {});
    const values = Object.values(pool ?? {});
    const dataSource = keys.map((item, index) => ({
      key: index,
      name: item,
      value: values[index],
    }));
    return {
      dataSource,
      columns: [
        {
          title: 'name',
          dataIndex: 'name',
          width: 150,
          align: 'center',
        },
        {
          title: 'value',
          dataIndex: 'value',
        },
      ],
    };
  }, [description]);

  const chartEl = useMemo(() => {
    if (!radarMap) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (radarMap.series?.length > 0) {
      return (<ECharts
        showLoading={isFetching}
        className={style.chart}
        option={option}
      />);
    }
    return <div className={style.noData}>该企业无此数据图谱</div>;
  }, [radarMap]);

  return (
    <div className={style.all} id="行业竞争力">
      <div className={style.title}>行业竞争力</div>
      <div className={style.content}>
        {
          chartEl
        }
        <Table
          dataSource={tableData?.dataSource}
          showHeader={false}
          columns={tableData?.columns}
          title={() => ''}
          locale={{ emptyText: () => {
            if (!(tableData?.dataSource.length)) {
              return '';
            }
            return <div className={style.tableNoData}>该企业无此项数据</div>;
          } }}
          pagination={false}
          strip
        />
      </div>
    </div>
  );
}

export default IndustryCompetitiveness;
