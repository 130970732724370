/*
 *Author: zhangmeng
 *Description: 单环饼图
 *Date: 2022/03/03
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const COLOR_LIST = [
  '#73EAF7', '#5BB0B8', '#3E84F7', '#1D16F4',
  '#5170CA', '#390D97', '#9B217F', '#B0411C',
  '#C7812D', '#B29530',
];

/**
 * @typedef {string | number} StringOrNumber
 */

/**
 * 饼图
 * @param {{
 *   data: {name: string, value: number}[];
 *   total?: {name: string, value: number | string};
 *   bottom: StringOrNumber;
 *   left: StringOrNumber;
 *   type?: 'normal' | 'trends';
 *   center?: StringOrNumber[] | StringOrNumber;
 *   radius?: StringOrNumber[] | StringOrNumber;
 *   outerRadius?: StringOrNumber[] | StringOrNumber;
 *   isFull?: boolean;
 *   onlyRation?: boolean;
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function PieChart(props) {
  const { data, total, bottom, left, type, center, isFull, radius, outerRadius, onlyRatio, colors } = props;
  const [option, setOption] = useState({});
  useEffect(() => {
    if (!data.length) return;
    const legend = data?.map(item => item.name);
    const option2 = {
      tooltip: {},
      title: {
        text: total?.name || '',
        subtext: total?.value || '',
        left: 'center',
        top: '28%',
        textStyle: {
          color: '#fff',
          fontSize: 14,
        },
        subtextStyle: {
          fontWeight: 600,
          color: '#7BF9FB',
          fontSize: 16,
        },
      },
      legend: [
        {
          data: legend,
          orient: 'horizontal',
          bottom: 0,
          left: 'center',
          align: 'auto',
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 10,
          // padding: [0, 30, 0, 10],
          // selectedMode: false,
          // width: 30,
          textStyle: {
            color: type === 'trends' ? '#7B8796' : '#fff',
          },
          icon: 'circle',
        },
        // {
        //   data: legend.slice(0, 3),
        //   orient: 'horizontal',
        //   bottom: 30,
        //   left: 'center',
        //   align: 'auto',
        //   itemHeight: 10,
        //   itemWidth: 10,
        //   itemGap: 10,
        //   // padding: [0, 30, 0, 10],
        //   // selectedMode: false,
        //   // width: 30,
        //   textStyle: {
        //     color: type === 'trends' ? '#136cda' : '#fff',
        //   },
        //   icon: 'circle',
        // },
      ],
      series: [
        {
          type: 'pie',
          color: colors ?? COLOR_LIST,
          legendHoverLink: false,
          hoverAnimation: true,
          center: center ?? ['50%', '35%'],
          radius: radius ?? [`${isFull ? '0' : '30%'}`, '45%'],
          label: {
            color: '#7B8796',
            formatter: (param) => `${param.name} \n ${param.data.value}个`,
          },
          tooltip: onlyRatio ? {
            formatter: ({ name, marker, percent }) => `${marker}<span style="margin-right: 20px;">${name}</span><span style="font-weight: bold">${percent}%</span>`,

          } : {},
          labelLine: {
            length: 20,
            length2: 10,
            lineStyle: {
              color: '#333333',
              type: 'solid',
            },
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1,
          },
          emphasis: {
            label: {
              color: '#7BF9FB',
            },
            labelLine: {
              lineStyle: {
                color: '#7BF9FB',
                type: 'dotted',
              },
            },
          },
          zlevel: 5,
          data,
        },
      ],
    };
    setOption(option2);
  }, [data, total, bottom, left, type, center, isFull, radius, outerRadius, colors, onlyRatio]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default PieChart;
