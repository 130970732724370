import React, { useMemo, useEffect } from 'react';
import classnames from 'classnames';
import Table from '../../../../components/Table';
import styles from './index.module.less';
import {
  useGetChannelPolicyDeclareListQuery,
  useGetChannelPolicyLevelsQuery,
  useGetChannelPolicySupportObjectListQuery,
  useGetChannelPolicyTypeListQuery,
  useGetChannelPolicySupportWayListQuery,
} from '../../../../service/industry-channel';
import {
  useGetCityChannelPolicyListQuery,
} from '../../../../service/city-channel';
import { getSingleOption } from '../../../../utils/tool';
import { useGetAreaPlugMarqueeV2Query, useGetIndustryTreeQuery } from '../../../../service/interactive-v2';
import CustomTreeFilter from '../../../../components/CustomTreeFilter';
import { getNameConfig } from '../../../../utils/table';
import { FilterIcon } from '../../../../components/CustomTableIcon';

const Cell = ({ text }) => {
  const _text = Array.isArray(text) ? text.join('、') : text;
  return <div className="overflow-multi-2" title={_text}>{_text}</div>;
};

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  title: {
    title: '政策名称',
    dataIndex: 'title',
    key: 'title',
    render: (text, { id }) => (
      <div className="clickable-name" id={id}>
        <Cell text={text} />
      </div>
    ),
  },
  location: {
    title: '区域',
    dataIndex: 'location',
    key: 'location',
    width: 100,
    ellipsis: true,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
  },
  tag_name: {
    title: '产业',
    dataIndex: 'tag_name',
    key: 'tag_name',
    width: 138,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  rank: {
    title: '政策层级',
    dataIndex: 'rank',
    key: 'rank',
    width: 84,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  type: {
    title: '政策类型',
    dataIndex: 'type',
    key: 'type',
    width: 210,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  support_way: {
    title: '支持方式',
    dataIndex: 'support_way',
    key: 'support_way',
    width: 146,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  support_object: {
    title: '支持对象',
    dataIndex: 'support_object',
    key: 'support_object',
    width: 198,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  is_declare: {
    title: '申报类型',
    dataIndex: 'is_declare',
    key: 'is_declare',
    width: 96,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
};

const COLUMN_KEYS = ['index', 'title', 'location', 'tag_name', 'rank', 'type', 'support_way', 'support_object', 'is_declare'];

function PolicyTable(props) {
  const {
    keyword,
    cityCode,
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onPolicyClick,
    onData,
    className,
    setParams,
  } = props;

  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query({
    area_code: cityCode,
  });
  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );
  const { data: levels = [] } = useGetChannelPolicyLevelsQuery();
  const { data: types = [] } = useGetChannelPolicyTypeListQuery();
  const { data: ways = [] } = useGetChannelPolicySupportWayListQuery();
  const { data: objects = [] } = useGetChannelPolicySupportObjectListQuery();
  const { data: declares = [] } = useGetChannelPolicyDeclareListQuery();
  const { data, isFetching } = useGetCityChannelPolicyListQuery({
    key_word: keyword,
    area_key: getSingleOption(filteredInfo.location) ?? cityCode,
    industry_code: getSingleOption(filteredInfo.tag_name) ?? 'all',
    page_size: pageSize,
    page_number: pageNumber,
    policy_level: getSingleOption(filteredInfo.rank),
    policy_type: getSingleOption(filteredInfo.type),
    support_way: getSingleOption(filteredInfo.support_way),
    support_object: getSingleOption(filteredInfo.support_object),
    declare: getSingleOption(filteredInfo.is_declare),
  }, { skip: !cityCode });
  const { total = 0, columns = [], dataSource = [] } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      const { dataIndex } = item;
      let rowConfig = ({
        ...(THEAD_TABLE[dataIndex] ?? {}),
        ...item,
      });
      switch (dataIndex) {
        case 'title':
          rowConfig = {
            ...rowConfig,
            ...getNameConfig({
              onClick: onPolicyClick,
              keyword,
              className: 'clickable-name overflow-multi-2',
              idField: '_id',
            }),
          };
          break;
        case 'location':
          rowConfig.filters = regionTree;
          rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
          rowConfig.filteredValue = filteredInfo?.location ?? [cityCode];
          break;
        case 'tag_name':
          rowConfig.filters = industryTree;
          rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
          rowConfig.filteredValue = filteredInfo?.tag_name ?? ['all'];
          break;
        case 'rank':
          rowConfig.filters = levels;
          rowConfig.filteredValue = filteredInfo?.rank ?? [];
          rowConfig.filterMultiple = false;
          break;
        case 'type':
          rowConfig.filters = types;
          rowConfig.filteredValue = filteredInfo?.type ?? [];
          rowConfig.filterMultiple = false;
          break;
        case 'support_way':
          rowConfig.filters = ways;
          rowConfig.filteredValue = filteredInfo?.support_way ?? [];
          rowConfig.filterMultiple = false;
          break;
        case 'support_object':
          rowConfig.filters = objects;
          rowConfig.filteredValue = filteredInfo?.support_object ?? [];
          rowConfig.filterMultiple = false;
          break;
        case 'is_declare':
          rowConfig.filters = declares;
          rowConfig.filteredValue = filteredInfo?.is_declare ?? [];
          rowConfig.filterMultiple = false;
          break;
        default:
          break;
      }
      return rowConfig;
    });
  }, [columns, levels, types, ways, objects, declares, regionTree, industryTree, filteredInfo, onPolicyClick, keyword]);

  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        area_key: getSingleOption(filteredInfo.location) ?? cityCode,
        industry_code: getSingleOption(filteredInfo.tag_name) ?? 'all',
        policy_level: getSingleOption(filteredInfo.rank),
        policy_type: getSingleOption(filteredInfo.type),
        support_way: getSingleOption(filteredInfo.support_way),
        support_object: getSingleOption(filteredInfo.support_object),
        declare: getSingleOption(filteredInfo.is_declare),
      });
    }
  }, [keyword, cityCode, filteredInfo]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      current={pageNumber}
      {...props}
      className={classnames(styles.table, className)}
    />
  );
}

export default PolicyTable;
