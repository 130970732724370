/*
 *Author: zhaochenyu
 *Description: 公司头部标签
 *Date: 2021/05/10
*/

import React, { useCallback, useState } from 'react';
import { DownCircleOutlined, UpCircleOutlined, DownOutlined } from '@ant-design/icons';
import { tagStyleMap } from '../../../utils/tagColor';
import style from './index.module.less';

function ComponayTabs(props) {
  const [height, setHeight] = useState(20);
  const [upDown, setUpDown] = useState(false);
  const { data, getTabCompanys, tabsCompanyShow, selectTab } = props;

  const tabsRef = useCallback(node => {
    if (node) {
      setHeight(node.scrollHeight);
    }
  }, []);

  const upDownClick = (type) => {
    if (type === 'up') {
      setUpDown(false);
    } else {
      setUpDown(true);
    }
  };
  return (
    <div className={style.all}>
      <div className={style.type}>{data.type || ''}</div>
      <div
        className={style.tabsBox}
        ref={tabsRef}
        style={{
          height: upDown ? 'auto' : '20px',
        }}
      >
        {
          (data.tabs || []).map((item, index) => (
            <div
              className={`${style.tab} ${tagStyleMap[data.type]}`}
              key={`${index}tab11`}
              onClick={(e) => {
                if (data.type === '试点示范' || data.type === '获奖情况') {
                  getTabCompanys(e, item, data.type);
                }
              }}
            >
              {item}
              {
                (data.type === '试点示范' || data.type === '获奖情况') && (
                  <DownOutlined
                    className={tabsCompanyShow && selectTab === item ? style.rotate : ''}
                  />
                )
              }
            </div>
          ))
        }
      </div>
      <div
        className={style.upDown}
        style={{ display: height > 30 ? '' : 'none' }}
      >
        {upDown && height > 30 ? (
          <div className={style.icon} onClick={() => upDownClick('up')}>
            <UpCircleOutlined />
          </div>
        ) : (
          <div className={style.icon} onClick={() => upDownClick('down')}>
            <DownCircleOutlined />
          </div>
        )}
      </div>
    </div>
  );
}

export default ComponayTabs;
