/**
* Author: tianye
* Description: 专利详情,
* Date: 2020/11/26
* */

import React from 'react';
import style from './index.module.less';
import { IconFont } from '../../../utils/mapIcon';
// import { companyTabsColorMap } from '../../../utils/color';
import logo from '../../../assets/images/patentDetail/logo.png';
import { tagStyleMap } from '../../../utils/tagColor';

export default function PatentScreen(props) {
  const { data, className } = props;
  const {
    name, type, applyID, applyDate,
    authDate, authID, legalStatus, patentee,
    ipc, inventor, agency, agent, abstract,
  } = data;

  return (
    <div className={`${style.mainContainer} ${className}`}>
      <section className={style.contentLeft}>
        <div className={style.avatar}>
          <img alt="" src={logo} style={{ width: '100%', height: '100%' }} />
        </div>
      </section>
      <section className={style.contentRight}>
        <div className={style.title}>{name}</div>
        <div className={style.tagContainer}>
          {
            data.tabs && data.tabs.map((item, index) => (
              item.tabs.map((item1, index1) => (
                <span
                  key={`${index}${index1}${item1}`}
                  className={`${style.tag} ${tagStyleMap[item.type]}`}
                >
                  {item1}
                </span>
              ))
            ))
          }
        </div>
        <div className={style.shortInfos}>
          <div className={style.info}>
            <div className={style.label}>专利类型</div>
            <div className={style.text}>{type}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>申请日期</div>
            <div className={style.text}>{applyDate}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>申请号</div>
            <div className={style.text}>{applyID}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>授权公告日</div>
            <div className={style.text}>{authDate}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>授权公告号</div>
            <div className={style.text}>{authID}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>法律状态</div>
            <div className={style.text}>{legalStatus}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>专利权人</div>
            <div className={style.text}>{patentee}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>分类号</div>
            <div className={style.text}>{ipc}</div>
          </div>
        </div>
        <div className={style.longInfos}>
          <div className={style.info}>
            <div className={style.label}>发明人</div>
            <div className={style.text}>{inventor}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>代理机构</div>
            <div className={style.text}>{agency}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>代理人</div>
            <div className={style.text}>{agent}</div>
          </div>
          <div className={style.info}>
            <div className={style.label}>摘要</div>
            <div className={`${style.text} ${style.longText}`}>{abstract}</div>
          </div>
        </div>
      </section>
    </div>
  );
}

PatentScreen.defaultProps = {
  data: {
    name: '发明专利',
    type: '发明专利',
    applyID: 'CN202010364066',
    applyDate: '2020-04-30',
    authDate: '2020-05-30',
    authID: 'CN111538870A',
    legalStatus: '公布',
    patentee: '北京百度网讯科技有限公司',
    ipc: 'G06K9/62（2006.01）',
    inventor: '付小小',
    agency: '北京英赛嘉华知识产权代理有限责任公司',
    abstract: '百度，全球最大的中文搜索引擎、最大的中文网站。2000年1月创立于北京中关村。1999年底，身在美国硅谷的李彦宏看到了中国互联网此时现状正缺最大的中文搜索引擎、最大的中文网站。2000年1月创立于北京中关村。1999年底，身在美国硅谷的李彦中国互联中文搜索引擎、最大的中文网站。2000年1月创立于北京中关村。1999年底，身在美国硅谷的李彦宏看到了中国互联网此时现状正缺最大的中文搜索引擎、最大的中文网站。2000年1月创立于北京中关村。1999年底，身在美国硅谷的李彦中国互联',
  },
};
