/*
 *Author: zhaochenyu
 *Description: 企业画像首页
 *Date: 2022/01/05
*/

import React from 'react';
import style from './index.module.less';

function FrontCover(props) {
  const { name } = props;
  const myDate = new Date();
  return (
    <div className={style.all}>
      <div className={style.coverLogo} />
      <div className={style.pngjia} />
      <div className={style.bigTitle}>精准画像与量化分析</div>
      <div className={style.subTitle}>{name || ''}</div>
      <div className={style.time}>
        {`${myDate.getFullYear()}年${myDate.getMonth() + 1}月`}
      </div>
    </div>
  );
}

export default FrontCover;
