import { ComponentType, CSSProperties } from 'react';
import { AnyDataSource } from '@sqke/parser';

export interface WithConfigProps {
  type: any;
  style?: CSSProperties;
  visualConfig: any;
  data?: any;
  dataConfig: {
    dataSource: AnyDataSource;
    dataMapping: Record<string, string>;
  };
  getExampleData?: (p: WithConfigProps) => any;
  [key: string]: any;
}

/**
 * 定义数据获取
 */
export function withExampleData<T extends {}>(
  WrappedComponent: ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithExampleData = (props: WithConfigProps) => {
    let { data, getExampleData } = props;
    // 如果未提供本地数据，或者本地数据为空，那么根据其数据类型，返回假数据用于显示
    if (!data && getExampleData) {
      data = getExampleData(props);
    }

    return (
      <WrappedComponent {...({ ...props, data, }) as unknown as T} />
    );
  };

  ComponentWithExampleData.displayName = `withExampleData(${displayName})`;

  return ComponentWithExampleData as ComponentType;
}
