import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import { useGetPatentStatisticsPatentDetailQuery } from '../../../../../../service/companyDetail';
import style from './index.module.less';

const PatentParticulars = ({ companyId, grade }) => {
  const [sortValue, setSortValue] = useState(null);
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const res = useGetPatentStatisticsPatentDetailQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
    penetration_grade: grade,
    sort_value: sortValue,
  }, {
    skip: !companyId,
  });
  const stortMap = {
    ascend: 'asc',
    descend: 'desc',
  };
  const { data: tableData, isFetching: tableFetching } = res;
  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'title') {
        return {
          ...item,
          width: 330,
          render: (text, record) => (
            <div className={style.patentName}>
              <span>
                {text}
              </span>
              {
                record.is_high_value && <span>{record.is_high_value}</span>
              }
            </div>
          ),
        };
      }
      if (item.dataIndex === 'type') {
        return {
          ...item,
          width: 100,
        };
      }
      if (item.dataIndex === 'patent_score') {
        return {
          ...item,
          width: 120,
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        };
      }
      return item;
    })
  ), [tableData]);
  const table = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={tableColumns}
          title={
            () => (
              <>
                <span>{tableData?.title}</span>
                <span className={style.count}>{tableData?.count}</span>
              </>
            )
          }
          strip
          loading={tableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          locale={{ emptyText: ' ' }}
          onChange={({ current, pageSize }, filter, stort) => {
            if (stort.order) {
              setSortValue(stortMap[stort.order]);
            } else {
              setSortValue(null);
            }
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [tableData]);
  return <div className={style.table}>{table}</div>;
};

export default PatentParticulars;
