import { EChartsOption } from 'echarts';
import { getTextStyle, omitUndefined } from './utils';
import { IModelFunction, IModelMiddleware } from '../interface/base';
import { merge } from 'lodash';

export const getBaseOption: IModelFunction = raw => {
  // 收集必须的属性之后，清理 undefined 值，防止颜色无法显示默认
  const {
    visualConfig: {
      darkMode,
      color,
      backgroundColor,
    },
  } = raw;

  const textStyle = getTextStyle(raw.visualConfig);

  return omitUndefined({
    darkMode,
    color,
    backgroundColor,
    textStyle: Object.keys(textStyle).length > 0 ? textStyle : undefined,
  }) as EChartsOption;
};

export const base: IModelMiddleware = (option, material) => {
  return merge(
    option,
    getBaseOption(material.config, material.data),
  );
};
