import React from 'react';
import styles from './index.module.less';

const Score = ({ score }) => (
  <div className={styles.score}>
    <span>{score}</span>
  </div>
);

export default Score;
