import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';

const extraOption = [
  {
    z: 9,
    label: {
      position: 'right',
      fontSize: 14,
      fontWeight: 500,
      color: '#0F2849',
    },
    emphasis: {
      label: {
        fontWeight: 600,
        color: '#FDAD61',
        textBorderWidth: 0.5,
        textBorderColor: '#FDAD61',
      },
    },
  },
  {
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: '#0F2849',
    },
    emphasis: {
      label: {
        fontWeight: 600,
        color: '#FDAD61',
        textBorderWidth: 0.5,
        textBorderColor: '#FDAD61',
      },
    },
  },
];

function OrthogonalOrientTree(props) {
  const { data, onEvents, select, zoom, companyId } = props;

  const [isChartReady, setIsChartReady] = useState(0);
  const echartsRef = useRef(null);

  useEffect(() => {
    if (!isChartReady) return;
    const _data = cloneDeep(data);
    const handleChartData = param => {
      const result = [];
      param.forEach(oItem => {
        if (oItem.children && oItem.children.length > 0) {
          oItem.children = handleChartData(oItem.children);
        }
        const sOption = oItem.level >= 1 ? cloneDeep(extraOption[1]) : cloneDeep(extraOption[oItem.level]);
        if (select === oItem.value && companyId === '') {
          sOption.itemStyle = {
            color: '#FDAD61',
            borderWidth: 8,
          };
          sOption.label.fontWeight = 600;
          sOption.label.color = '#FDAD61';
          sOption.label.textBorderWidth = 0.5;
          sOption.label.textBorderColor = '#FDAD61';
        }
        if (companyId === oItem.company_id) {
          sOption.itemStyle = {
            color: '#FDAD61',
            borderWidth: 8,
          };
          sOption.label = {
            fontWeight: 500,
            color: '#FDAD61',
            textBorderWidth: 0.5,
            textBorderColor: '#FDAD61',
          };
        }
        oItem = {
          name: oItem.title,
          code: oItem.value,
          ...oItem,
          ...sOption,
        };
        result.push(oItem);
      });
      return result;
    };
    const instance = echartsRef.current?.getEchartsInstance();
    instance?.clear();
    instance?.setOption({
      tooltip: false,
      series: [
        {
          large: true,
          id: 'tree',
          type: 'tree',
          roam: 'move',
          zoom,
          data: handleChartData([_data]),
          top: '15%',
          left: '-5%',
          bottom: '-8%',
          right: '20%',
          layout: 'radial',
          // layout: 'orthogonal',
          // orient: 'TB',
          symbol: 'circle',
          symbolSize: 10,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#FDAD61',
            color: '#fff',
          },
          lineStyle: {
            color: '#FDAD61',
          },
          expandAndCollapse: false,
          initialTreeDepth: 5,
          animation: false,
          label: {
            color: '#0F2849',
            formatter: p => {
              const { data: d, name } = p;
              if (d.level === 0 || d.level === 1) {
                let n = '';
                const companyName = name.split('');
                while (companyName.length > 4) {
                  n += `${companyName.splice(0, 4).join('')}\n`;
                }
                return `{size0|${n}${companyName.join('')}}`;
              }
              if (d.level === 2 && d.children && d.children.length > 0) {
                let n = '';
                const companyName = name.split('');
                n += `${companyName.splice(0, 6).join('')}...`;
                return `{size1|${n}}`;
              }
              return `{size2|${name}}`;
            },
            rich: {
              size0: {
                align: 'center',
                fontSize: 14,
                fontWeight: 500,
              },
              size1: {
                fontSize: 14,
              },
              size2: {
                fontSize: 14,
              },
            },
          },
          // label: {
          //   color: '#0F2849',
          //   position: 'top',
          //   rotate: -90,
          //   verticalAlign: 'middle',
          //   align: 'right',
          // },
          // leaves: {
          //   label: {
          //     color: '#0F2849',
          //     position: 'bottom',
          //     rotate: -90,
          //     verticalAlign: 'middle',
          //     align: 'left',
          //   },
          // },
        },
      ],
    });
  }, [data, isChartReady, select, zoom, companyId]);

  const onEventsIN = useMemo(() => ({
    click: params => {
      onEvents(params);
    },
  }), []);

  return (
    <ReactEcharts
      option={{}}
      notMerge={true}
      onEvents={onEventsIN}
      style={{ width: '100%', height: '100%' }}
      ref={echartsRef}
      onChartReady={() => {
        setIsChartReady(prev => prev + 1);
      }}
    />
  );
}

export default OrthogonalOrientTree;
