import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

/**
 * 排名次序
 * @param {number} order
 * @returns {JSX.Element|*}
 * @constructor
 */
function RankingOrder({ order }) {
  if (!order) {
    return order;
  }
  const ranking = Number(order);
  return (
    <div className={classnames(styles.order, { [styles.spec]: ranking <= 3 })}>
      {
        ranking <= 3 && (
          <div
            className={classnames({
              [styles.first]: order.toString() === '1',
              [styles.second]: order.toString() === '2',
              [styles.third]: order.toString() === '3',
            })}
         />
        )
      }
      <span className={styles.name}>{order}</span>
    </div>
  );
}

export default RankingOrder;
