// 经营风险
import React, { useState, useMemo } from 'react';
import Container from '../../Container';
import StockRights from '../StockRights';
import PledgeTable from '../PledgeTable';
import OperationalRisksTable from '../OperationalRisksTable';
import IllegalSituationTable from '../IllegalSituationTable';
import PunishTable from '../PunishTable';
import style from './index.module.less';
import {
  // useGetEquityOfferingQuery,
  // useGetMortgageMovablePropertyQuery,
  // useGetAbnormalOperationQuery,
  // useGetSeriousViolationLawQuery,
  // useGetSpotCheckResultsQuery,
  useGetOperationalRiskQuery,
  // useGetAdministrativePenaltyQuery,
} from '../../../../../service/companyDetail';

const operationalRiskList = [
  '股权出质', '动产抵押', '异常经营', '重大违法', '行政处罚',
];

function BusinessRisk(props) {
  const { companyId } = props;

  const { data: riskData } = useGetOperationalRiskQuery({ company_id: companyId });
  return (
    <Container id="经营风险" question="展示该企业股东及投资企业风险情况" >
      <div className={style.all}>
        <div className={style.list}>
          <div className={style.riskCount}>
            {
              riskData && (
                <>
                  <span></span>
                  该公司共有
                  <span>{riskData.总数}</span>
                  条风险数据
                </>
              )
            }
          </div>
          <div className={style.detailsList}>
            {
              riskData && operationalRiskList.map(item => {
                if (riskData[item]) {
                  return (
                    <div className={style.cell} key={item}>
                      <div className={style.left}>
                        <span></span>
                        <span>{riskData[item]}</span>
                      </div>
                      <div className={style.right}>
                        条
                        {item}
                        记录
                      </div>
                    </div>
                  );
                }
                return null;
              })
            }
          </div>
        </div>
        <StockRights {...props} />
        <PledgeTable {...props} />
        <OperationalRisksTable {...props} />
        <IllegalSituationTable {...props} />
        <PunishTable {...props} />
        {/* { riskData['行政处罚'] && <PunishTable {...props} /> } */}
      </div>
    </Container >
  );
}

export default BusinessRisk;
