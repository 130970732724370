import { cloneDeep } from 'lodash';
/**
 * 柱状图 option配置
 * @returns obj option
 */
const barOption = (data) => ({
  xAxis: {
    name: '时间',
    type: 'category',
    data: data?.x,
    axisLabel: {
      rotate: 40,
      textStyle: {
        fontSize: 10,
      },
    },

  },
  tooltip: {
    axisPointer: {
      z: 1000,
    },
  },
  grid: {
    top: 38,
    right: 0,
    bottom: 0,
    left: 10,
  },
  yAxis: {
    name: data?.yName || '',
  },
  series: [
    {
      // name: '专利数量1',
      type: 'bar',
      barCategoryGap: '50%',
      coordinateSystem: 'cartesian2d',
      data: data?.series[0]?.data,
      itemStyle: {
        color: '#44A1F7',
      },
      label: {
        fontSize: 10,
      },
    },
  ],
});

/**
 * 横向柱状图 option配置
 * @returns obj option
 */
const barCrosswiseOption = (data) => ({
  yAxis: {
    axisLine: {
      show: false, // 不显示坐标轴线
    },
    // name: '时间',
    type: 'category',
    data: cloneDeep(data?.x)?.reverse(),
  },
  tooltip: {
    axisPointer: {
      z: 1000,
    },
  },
  grid: {
    top: 20,
    right: 30,
    bottom: 10,
    left: 0,
  },
  xAxis: {
    type: 'value',
    name: '家',
    // 隐藏 x 轴
    show: false,
  },
  series: [
    {
      // name: '专利数量1',
      type: 'bar',
      barCategoryGap: '50%',
      coordinateSystem: 'cartesian2d',
      data: cloneDeep(data?.series[0]?.data)?.reverse(),
      // 隐藏坐标轴
      label: {
        show: true,
        position: 'right',
        valueAnimation: true,
      },
      itemStyle: {
        color: '#44C4F7',
      },
    },
  ],
});

/**
 * 空心饼图 option配置
 * @returns obj option
 */
const hollowPieOption = (data) => ({
  tooltip: {
    formatter: ' {b} : {c} ({d}%)',
    axisPointer: {
      z: 1000,
    },
  },
  legend: {
    type: 'plain',
    orient: 'horizontal',
    icon: 'circle',
    itemHeight: 8,
    itemWidth: 8,
    align: 'auto',
    bottom: 10,
    textStyle: {
      color: '#919191',
      fontSize: 10,
    },
    data: data?.series[0]?.data?.map(item => item.name),
  },
  series: [
    {
      // name: '饼图',
      type: 'pie',
      radius: ['45%', '70%'],
      // 饼图伸出的线 1长度和2阶长度
      labelLine: {
        length: 10,
        length2: 10,
      },
      color: ['#FBE031', '#94C2FD', '#93EED2'],
      bottom: 40,
      label: {
        fontSize: 10,
        color: '#919191',
        formatter: '{b} \n {d}%',
      },
      minAngle: 1,
      data: data?.series[0]?.data,
    },
  ],
});

const pieOption = (data) => ({
  tooltip: {
    // trigger: 'item',
    formatter: '{b} : {c} ({d}%)',
    axisPointer: {
      z: 1000,
    },
  },
  legend: {
    type: 'plain',
    orient: 'horizontal',
    icon: 'circle',
    itemHeight: 8,
    itemWidth: 8,
    align: 'auto',
    bottom: 10,
    textStyle: {
      color: '#919191',
      fontSize: 10,
    },
    data: data?.series[0]?.data?.map(item => item.name),
  },
  series: [
    {
      // name: '饼图',
      type: 'pie',
      // 饼图伸出的线 1长度和2阶长度
      labelLine: {
        show: true,
        length: 10,
        length2: 10,
      },
      // roseType: 'area',
      radius: [0, '70%'],
      bottom: 70,
      color: ['#4E7293', '#A5E0C0', '#17C18F', '#8C75E1', '#EFB68F', '#E9C582', '#1E81DE'],
      label: {
        fontSize: 10,
        color: '#919191',
        formatter: '{b} \n {d}%',
      },
      data: data?.series[0]?.data,
    },
  ],
});

export {
  barOption,
  barCrosswiseOption,
  hollowPieOption,
  pieOption,
};
