import { AnyChartConfig } from '../interface/config';
import { merge } from 'lodash';
import { IModelMiddleware } from '../interface/base';
import { extend, getTextStyle, omitUndefined } from './utils';
import { AnyData } from '../interface/data';

const GRID_DEFAULT = { top: 60, right: '10%', bottom: 60, left: '10%', };

/**
 * 获取轴的配置
 */
export const getAxisOption = (raw: AnyChartConfig, data?: AnyData) => {
  // 在内部暂时不会处理翻转坐标轴的情况，待到最后获取完数据绘制时才会翻转
  const { visualConfig, type } = raw;
  const { coordinateSystem } = visualConfig;
  const textStyle = getTextStyle(visualConfig);
  const { xName = '', yName = '', yName1, x, x1, y } = data ?? {};

  // 如果是笛卡尔坐标系
  if (
    coordinateSystem === 'cartesian2d'
  ) {
    const { xAxis, yAxis, grid, axisReverse: isReverse } = visualConfig;
    const xAxisConfig = extend(xAxis);
    const yAxisConfig = extend(yAxis);
    if (yName1) {
      yAxisConfig.push({ show: true });
    }
    if (x1) {
      return {
        grid,
        xAxis: [
          {
            type: 'value',
            name: yName,
            axisLabel: {
              interval: 0,
              rotate: 40,
              ...textStyle,
            },
          },
          {
            gridIndex: 1,
            type: 'value',
            name: yName1,
            axisLabel: {
              interval: 0,
              rotate: 40,
              ...textStyle,
            },
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: x,
            name: xName,
          },
          {
            gridIndex: 1,
            type: 'category',
            data: x1,
            name: xName,
          },
        ],
      };
    }
    return {
      grid: merge({}, GRID_DEFAULT, grid),
      [!isReverse ? 'xAxis' : 'yAxis']: xAxisConfig.map((item, index) => {
        return omitUndefined({
          type: item?.type ?? 'category',
          data: data?.[`x${index === 0 ? '' : index}`],
          name: data?.[`xName${index === 0 ? '' : index}`],
          ...item,
          axisLabel: {
            interval: 0,
            rotate: 40,
            ...textStyle,
            ...(item?.axisLabel ?? {}),
          },
        });
      }),
      [!isReverse ? 'yAxis' : 'xAxis']: yAxisConfig.map((item, index) => {
        return omitUndefined({
          type: item?.type ?? 'value',
          data: data?.[`y${index === 0 ? '' : index}`],
          name: data?.[`yName${index === 0 ? '' : index}`],
          ...item,
          axisLabel: {
            ...textStyle,
            ...(item?.axisLabel ?? {}),
          },
        });
      }),
    };
  }

  // 如果是极坐标系
  if (
    coordinateSystem === 'polar'
  ) {
    const isReverse = visualConfig.axisReverse;
    const { radiusAxis, angleAxis, polar } = visualConfig;
    return {
      polar: polar ?? {},
      [!isReverse ? 'radiusAxis' : 'angleAxis']: omitUndefined({
        type: radiusAxis?.type ?? 'category',
        data: x,
        name: xName,
        ...visualConfig.radiusAxis,
        axisLabel: {
          ...textStyle,
          ...(radiusAxis?.axisLabel ?? {}),
        },
      }),
      [!isReverse ? 'angleAxis' : 'radiusAxis']: omitUndefined({
        type: angleAxis?.type ?? 'value',
        data: y,
        name: yName,
        ...visualConfig.angleAxis,
        axisLabel: {
          show: visualConfig.angleAxis?.show !== false,
          ...textStyle,
          ...(angleAxis?.axisLabel ?? {}),
        },
      }),
    };
  }

  // 处理地理坐标系
  if (coordinateSystem === 'geo') {
    const { geo } = visualConfig;
    return {
      geo
    };
  }

  if (coordinateSystem === 'none' || !coordinateSystem) {
    // 处理雷达
    if (type === 'radar') {
      const { radar } = visualConfig;
      const {
        shape,
        showSplitArea,
        min,
        max,
        showAxis,
      } = radar ?? {};

      return {
        radar: merge(
          {},
          {
            axisName: textStyle,
            axisLabel: textStyle,
            detail: textStyle,
          },
          omitUndefined({
            shape,
            splitArea: { show: showSplitArea },
            indicator: data ? data.indicator?.map((item, index) => (
              {
                name: item,
                min,
                max,
                ...(
                  !showAxis ? {} : {
                    axisLabel: { show: index === 0 },
                    axisTick: { show: index === 0 },
                    axisLine: { show: index === 0 },
                  }
                ),
              }
            )) : [],
          }),
        ),
      };
    }

    //
    if (type === 'gauge') {

    }
  }

  // 如果没有坐标系
  return {};
};

export const axis: IModelMiddleware = (option, material) => {
  return merge(
    option,
    getAxisOption(material.config, material.data),
  );
};
