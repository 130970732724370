/*
 *Author: zhaochenyu
 *Description: 区域画像报告
 *Date: 2021/11/29
*/

import React, { useMemo } from 'react';
import ReportChart from '../ReportChart';
import FrontCover from './FrontCover';
import BackCover from './BackCover';
import style from '../index.module.less';

function AreaPortraitReport(props) {
  const { chartConditions, chartTitlesRef, refList, chartTitles, allCitys, pushToAchor,
    getChartFinishedPercent, buyStatus } = props;
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  return (
    <>
      {buyStatus ? (
        <>
          <div className={style.first}>
            {/* 首页封皮 */}
            <FrontCover area={urlReportMsg.area} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、对标分析')}>
              <div className={style.firstTitle}>一、对标分析</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）经济指标')}>
              <div className={style.secondTitle}>（一）经济指标</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）产业主体')}>
              <div className={style.secondTitle}>（二）产业主体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）创新资源')}>
              <div className={style.secondTitle}>（三）创新资源</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）融资概况')}>
              <div className={style.secondTitle}>（四）融资概况</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>15</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）园区载体')}>
              <div className={style.secondTitle}>（五）园区载体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>18</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、产业画像')}>
              <div className={style.firstTitle}>二、产业画像</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）经济运行')}>
              <div className={style.secondTitle}>（一）经济运行</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）市场主体')}>
              <div className={style.secondTitle}>（二）市场主体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>22</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）创新活力')}>
              <div className={style.secondTitle}>（三）创新活力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>26</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）融资能力')}>
              <div className={style.secondTitle}>（四）融资能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>37</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）产业载体')}>
              <div className={style.secondTitle}>（五）产业载体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>42</div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="一、对标分析">
              一、对标分析
            </div>
            <div className={style.secondaryTitle} id="（一）经济指标">（一）经济指标</div>
            {/* 生产总值及增长率 */}
            {chartConditions.length > 0 && (
            <ReportChart
              chartMsg={chartConditions[0]}
              chartIndex={0}
              ref={chartTitlesRef.current[0]}
              chartHeight={330}
              refList={refList}
              title={`图1 ${chartTitles[0] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 人均地区生产总值 */}
            {chartConditions.length > 1 && (
            <ReportChart
              chartMsg={chartConditions[1]}
              chartIndex={1}
              ref={chartTitlesRef.current[1]}
              chartHeight={330}
              refList={refList}
              title={`图2 ${chartTitles[1] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 地均地区生产总值 */}
            {chartConditions.length > 2 && (
            <ReportChart
              chartMsg={chartConditions[2]}
              chartIndex={2}
              ref={chartTitlesRef.current[2]}
              chartHeight={330}
              refList={refList}
              title={`图3 ${chartTitles[2] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 地区生产总值结构 */}
            {chartConditions.length > 3 && (
            <ReportChart
              chartMsg={chartConditions[3]}
              chartIndex={3}
              ref={chartTitlesRef.current[3]}
              chartHeight={330}
              refList={refList}
              title={`图4 ${chartTitles[3] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 地方一般公共预算收支 */}
            {chartConditions.length > 4 && (
            <ReportChart
              chartMsg={chartConditions[4]}
              chartIndex={4}
              ref={chartTitlesRef.current[4]}
              chartHeight={330}
              refList={refList}
              title={`图5 ${chartTitles[4] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 社会消费品零售总额 */}
            {chartConditions.length > 5 && (
            <ReportChart
              chartMsg={chartConditions[5]}
              chartIndex={5}
              ref={chartTitlesRef.current[5]}
              chartHeight={330}
              refList={refList}
              title={`图6 ${chartTitles[5] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）产业主体">（二）产业主体</div>
            {/* 存量企业数量对比 */}
            {chartConditions.length > 6 && (
            <ReportChart
              chartMsg={chartConditions[6]}
              chartIndex={6}
              ref={chartTitlesRef.current[6]}
              chartHeight={330}
              refList={refList}
              title={`图7 ${chartTitles[6] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 存量企业数量对标 */}
            {chartConditions.length > 7 && (
            <ReportChart
              chartMsg={chartConditions[7]}
              chartIndex={7}
              ref={chartTitlesRef.current[7]}
              chartHeight={460}
              refList={refList}
              title={`图8 ${chartTitles[7] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 新增企业数量对比 */}
            {chartConditions.length > 8 && (
            <ReportChart
              chartMsg={chartConditions[8]}
              chartIndex={8}
              ref={chartTitlesRef.current[8]}
              chartHeight={330}
              refList={refList}
              title={`图9 ${chartTitles[8] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 新增企业数量对标 */}
            {chartConditions.length > 9 && (
            <ReportChart
              chartMsg={chartConditions[9]}
              chartIndex={9}
              ref={chartTitlesRef.current[9]}
              chartHeight={460}
              refList={refList}
              title={`图10 ${chartTitles[9] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 头部企业数量 */}
            {chartConditions.length > 10 && (
            <ReportChart
              chartMsg={chartConditions[10]}
              chartIndex={10}
              ref={chartTitlesRef.current[10]}
              chartHeight={330}
              refList={refList}
              title={`图11 ${chartTitles[10] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 高技术企业数量 */}
            {chartConditions.length > 11 && (
            <ReportChart
              chartMsg={chartConditions[11]}
              chartIndex={11}
              ref={chartTitlesRef.current[11]}
              chartHeight={330}
              refList={refList}
              title={`图12 ${chartTitles[11] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）创新资源">（三）创新资源</div>
            {/* 存量专利对比 */}
            {chartConditions.length > 12 && (
            <ReportChart
              chartMsg={chartConditions[12]}
              chartIndex={12}
              ref={chartTitlesRef.current[12]}
              chartHeight={330}
              refList={refList}
              title={`图13 ${chartTitles[12] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 产业存量专利分布 */}
            {chartConditions.length > 13 && (
            <ReportChart
              chartMsg={chartConditions[13]}
              chartIndex={13}
              ref={chartTitlesRef.current[13]}
              chartHeight={460}
              refList={refList}
              title={`图14 ${chartTitles[13] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 新增专利对比 */}
            {chartConditions.length > 14 && (
            <ReportChart
              chartMsg={chartConditions[14]}
              chartIndex={14}
              ref={chartTitlesRef.current[14]}
              chartHeight={330}
              refList={refList}
              title={`图15 ${chartTitles[14] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 产业新增专利分布 */}
            {chartConditions.length > 15 && (
            <ReportChart
              chartMsg={chartConditions[15]}
              chartIndex={15}
              ref={chartTitlesRef.current[15]}
              chartHeight={460}
              refList={refList}
              title={`图16 ${chartTitles[15] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 研究机构数量对比 */}
            {chartConditions.length > 16 && (
            <ReportChart
              chartMsg={chartConditions[16]}
              chartIndex={16}
              ref={chartTitlesRef.current[16]}
              chartHeight={330}
              refList={refList}
              title={`图17 ${chartTitles[16] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 研究机构类型对比 */}
            {chartConditions.length > 17 && (
            <ReportChart
              chartMsg={chartConditions[17]}
              chartIndex={17}
              ref={chartTitlesRef.current[17]}
              chartHeight={460}
              refList={refList}
              title={`图18 ${chartTitles[17] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）融资概况">（四）融资概况</div>
            {/* 投融资事件分布 */}
            {chartConditions.length > 18 && (
            <ReportChart
              chartMsg={chartConditions[18]}
              chartIndex={18}
              ref={chartTitlesRef.current[18]}
              chartHeight={660}
              refList={refList}
              title={`图19 ${chartTitles[18] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 投融资金额分布 */}
            {chartConditions.length > 19 && (
            <ReportChart
              chartMsg={chartConditions[19]}
              chartIndex={19}
              ref={chartTitlesRef.current[19]}
              chartHeight={660}
              refList={refList}
              title={`图20 ${chartTitles[19] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：融资金额只统计企业对外公开的数额。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            {/* 融资事件产业分布 */}
            {chartConditions.length > 20 && (
            <ReportChart
              chartMsg={chartConditions[20]}
              chartIndex={20}
              ref={chartTitlesRef.current[20]}
              chartHeight={460}
              refList={refList}
              title={`图21 ${chartTitles[20] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（五）园区载体">（五）园区载体</div>
            {/* 园区数量对比 */}
            {chartConditions.length > 21 && (
            <ReportChart
              chartMsg={chartConditions[21]}
              chartIndex={21}
              ref={chartTitlesRef.current[21]}
              chartHeight={310}
              refList={refList}
              title={`图22 ${chartTitles[21] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 产业载体城市分布 */}
            {chartConditions.length > 22 && (
            <ReportChart
              chartMsg={chartConditions[22]}
              chartIndex={22}
              ref={chartTitlesRef.current[22]}
              chartHeight={310}
              refList={refList}
              title={`图23 ${chartTitles[22] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="二、产业画像">
              二、产业画像
            </div>
            <div className={style.secondaryTitle} id="（一）经济运行">（一）经济运行</div>
            <div className={style.tertiaryTitle}>1. 经济总量</div>
            {/* 地区生产总值及增长率 */}
            {chartConditions.length > 23 && (
            <ReportChart
              chartMsg={chartConditions[23]}
              chartIndex={23}
              ref={chartTitlesRef.current[23]}
              chartHeight={300}
              refList={refList}
              title={`图24 ${chartTitles[23] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 地区生产总值构成 */}
            {chartConditions.length > 24 && (
            <ReportChart
              chartMsg={chartConditions[24]}
              chartIndex={24}
              ref={chartTitlesRef.current[24]}
              chartHeight={300}
              refList={refList}
              title={`图25 ${chartTitles[24] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 人均地区生产总值 */}
            {chartConditions.length > 25 && (
            <ReportChart
              chartMsg={chartConditions[25]}
              chartIndex={25}
              ref={chartTitlesRef.current[25]}
              chartHeight={320}
              refList={refList}
              title={`图26 ${chartTitles[25] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 地均地区生产总值 */}
            {chartConditions.length > 26 && (
            <ReportChart
              chartMsg={chartConditions[26]}
              chartIndex={26}
              ref={chartTitlesRef.current[26]}
              chartHeight={320}
              refList={refList}
              title={`图27 ${chartTitles[26] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>2. 财政及消费</div>
            {/* 地方一般公共预算收支情况 */}
            {chartConditions.length > 27 && (
            <ReportChart
              chartMsg={chartConditions[27]}
              chartIndex={27}
              ref={chartTitlesRef.current[27]}
              chartHeight={320}
              refList={refList}
              title={`图28 ${chartTitles[27] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 社会消费品 */}
            {chartConditions.length > 28 && (
            <ReportChart
              chartMsg={chartConditions[28]}
              chartIndex={28}
              ref={chartTitlesRef.current[28]}
              chartHeight={320}
              refList={refList}
              title={`图29 ${chartTitles[28] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）市场主体">（二）市场主体</div>
            <div className={style.tertiaryTitle}>1. 企业总览</div>
            {/* 存量企业数量变化 */}
            {chartConditions.length > 29 && (
            <ReportChart
              chartMsg={chartConditions[29]}
              chartIndex={29}
              ref={chartTitlesRef.current[29]}
              chartHeight={320}
              refList={refList}
              title={`图30 ${chartTitles[29] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 企业金字塔模型 */}
            {chartConditions.length > 30 && (
            <ReportChart
              chartMsg={chartConditions[30]}
              chartIndex={30}
              ref={chartTitlesRef.current[30]}
              chartHeight={340}
              refList={refList}
              title={`图31 ${chartTitles[30] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高成长企业：近三年获得过融资的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            {/* 企业地理空间分布 */}
            {chartConditions.length > 31 && (
            <ReportChart
              chartMsg={chartConditions[31]}
              chartIndex={31}
              ref={chartTitlesRef.current[31]}
              chartHeight={660}
              refList={refList}
              title={`图32 ${chartTitles[31] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高成长企业：近三年获得过融资的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>2. 存量企业</div>
            {/* 存量企业产业分布 */}
            {chartConditions.length > 32 && (
            <ReportChart
              chartMsg={chartConditions[32]}
              chartIndex={32}
              ref={chartTitlesRef.current[32]}
              chartHeight={400}
              refList={refList}
              title={`图33 ${chartTitles[32] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 存量企业产业区域分布 */}
            {chartConditions.length > 33 && (
            <ReportChart
              chartMsg={chartConditions[33]}
              chartIndex={33}
              ref={chartTitlesRef.current[33]}
              chartHeight={460}
              refList={refList}
              title={`图34 ${chartTitles[33] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）创新活力">（三）创新活力</div>
            <div className={style.tertiaryTitle}>1. 存量专利</div>
            {/* 近年申请专利变化趋势 */}
            {chartConditions.length > 34 && (
            <ReportChart
              chartMsg={chartConditions[34]}
              chartIndex={34}
              ref={chartTitlesRef.current[34]}
              chartHeight={360}
              refList={refList}
              title={`图35 ${chartTitles[34] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 近年授权专利变化趋势 */}
            {chartConditions.length > 35 && (
            <ReportChart
              chartMsg={chartConditions[35]}
              chartIndex={35}
              ref={chartTitlesRef.current[35]}
              chartHeight={360}
              refList={refList}
              title={`图36 ${chartTitles[35] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 授权发明专利产业分布 */}
            {chartConditions.length > 36 && (
            <ReportChart
              chartMsg={chartConditions[36]}
              chartIndex={36}
              ref={chartTitlesRef.current[36]}
              chartHeight={380}
              refList={refList}
              title={`图37 ${chartTitles[36] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 授权发明专利城区分布 */}
            {chartConditions.length > 37 && (
            <ReportChart
              chartMsg={chartConditions[37]}
              chartIndex={37}
              ref={chartTitlesRef.current[37]}
              chartHeight={350}
              refList={refList}
              title={`图38 ${chartTitles[37] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 授权发明专利产业区域分布 */}
            {chartConditions.length > 38 && (
            <ReportChart
              chartMsg={chartConditions[38]}
              chartIndex={38}
              ref={chartTitlesRef.current[38]}
              chartHeight={460}
              refList={refList}
              title={`图39 ${chartTitles[38] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>2. 新增专利</div>
            {/* 新增授权发明专利产业分布 */}
            {chartConditions.length > 39 && (
            <ReportChart
              chartMsg={chartConditions[39]}
              chartIndex={39}
              ref={chartTitlesRef.current[39]}
              chartHeight={380}
              refList={refList}
              title={`图40 ${chartTitles[39] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 新增授权发明专利城区分布 */}
            {chartConditions.length > 40 && (
            <ReportChart
              chartMsg={chartConditions[40]}
              chartIndex={40}
              ref={chartTitlesRef.current[40]}
              chartHeight={350}
              refList={refList}
              title={`图41 ${chartTitles[40] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 新增授权发明专利产业区域分布 */}
            {chartConditions.length > 41 && (
            <ReportChart
              chartMsg={chartConditions[41]}
              chartIndex={41}
              ref={chartTitlesRef.current[41]}
              chartHeight={460}
              refList={refList}
              title={`图42 ${chartTitles[41] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>3. 研究机构</div>
            {/* 研究机构类型分布 */}
            {chartConditions.length > 42 && (
            <ReportChart
              chartMsg={chartConditions[42]}
              chartIndex={42}
              ref={chartTitlesRef.current[42]}
              chartHeight={350}
              refList={refList}
              title={`图43 ${chartTitles[42] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 研究机构城区分布 */}
            {chartConditions.length > 43 && (
            <ReportChart
              chartMsg={chartConditions[43]}
              chartIndex={43}
              ref={chartTitlesRef.current[43]}
              chartHeight={350}
              refList={refList}
              title={`图44 ${chartTitles[43] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 国家级研究机构产业区域分布 */}
            {chartConditions.length > 44 && (
            <ReportChart
              chartMsg={chartConditions[44]}
              chartIndex={44}
              ref={chartTitlesRef.current[44]}
              chartHeight={460}
              refList={refList}
              title={`图45 ${chartTitles[44] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）融资能力">（四）融资能力</div>
            <div className={style.tertiaryTitle}>1. 融资概况</div>
            {/* 融资事件变化 */}
            {chartConditions.length > 45 && (
            <ReportChart
              chartMsg={chartConditions[45]}
              chartIndex={45}
              ref={chartTitlesRef.current[45]}
              chartHeight={300}
              refList={refList}
              title={`图46 ${chartTitles[45] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 融资金额变化 */}
            {chartConditions.length > 46 && (
            <ReportChart
              chartMsg={chartConditions[46]}
              chartIndex={46}
              ref={chartTitlesRef.current[46]}
              chartHeight={300}
              refList={refList}
              title={`图47 ${chartTitles[46] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：融资金额只统计企业对外公开的数额。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            {/* 平均单笔融资额变化 */}
            {chartConditions.length > 47 && (
            <ReportChart
              chartMsg={chartConditions[47]}
              chartIndex={47}
              ref={chartTitlesRef.current[47]}
              chartHeight={300}
              refList={refList}
              title={`图48 ${chartTitles[47] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 融资轮次分布 */}
            {chartConditions.length > 48 && (
            <ReportChart
              chartMsg={chartConditions[48]}
              chartIndex={48}
              ref={chartTitlesRef.current[48]}
              chartHeight={330}
              refList={refList}
              title={`图49 ${chartTitles[48] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 融资事件产业分布 */}
            {chartConditions.length > 49 && (
            <ReportChart
              chartMsg={chartConditions[49]}
              chartIndex={49}
              ref={chartTitlesRef.current[49]}
              chartHeight={700}
              refList={refList}
              title={`图50 ${chartTitles[49] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 融资金额产业分布 */}
            {chartConditions.length > 50 && (
            <ReportChart
              chartMsg={chartConditions[50]}
              chartIndex={50}
              ref={chartTitlesRef.current[50]}
              chartHeight={700}
              refList={refList}
              title={`图51 ${chartTitles[50] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：融资金额只统计企业对外公开的数额。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>2. 上市企业</div>
            {/* 上市企业板块数量 */}
            {chartConditions.length > 51 && (
            <ReportChart
              chartMsg={chartConditions[51]}
              chartIndex={51}
              ref={chartTitlesRef.current[51]}
              chartHeight={360}
              refList={refList}
              title={`图52 ${chartTitles[51] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.tertiaryTitle}>3. 重点融资</div>
            {/* 近一年融资TOP5 */}
            {chartConditions.length > 52 && (
            <ReportChart
              chartMsg={chartConditions[52]}
              chartIndex={52}
              ref={chartTitlesRef.current[52]}
              chartHeight={330}
              refList={refList}
              title={`表1 ${chartTitles[52] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（五）产业载体">（五）产业载体</div>
            <div className={style.tertiaryTitle}>1. 类型分布</div>
            {/* 产业载体类型分布 */}
            {chartConditions.length > 53 && (
            <ReportChart
              chartMsg={chartConditions[53]}
              chartIndex={53}
              ref={chartTitlesRef.current[53]}
              chartHeight={320}
              refList={refList}
              title={`图53 ${chartTitles[53] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 产业载城区分布 */}
            {chartConditions.length > 54 && (
            <ReportChart
              chartMsg={chartConditions[54]}
              chartIndex={54}
              ref={chartTitlesRef.current[54]}
              chartHeight={320}
              refList={refList}
              title={`图54 ${chartTitles[54] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.last}>
            {/* 尾页 */}
            <BackCover />
          </div>
        </>
      ) : (
        <>
          <div className={style.first}>
            {/* 首页封皮 */}
            <FrontCover area={urlReportMsg.area} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、对标分析')}>
              <div className={style.firstTitle}>一、对标分析</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）经济指标')}>
              <div className={style.secondTitle}>（一）经济指标</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）产业主体')}>
              <div className={style.secondTitle}>（二）产业主体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）创新资源')}>
              <div className={style.secondTitle}>（三）创新资源</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）融资概况')}>
              <div className={style.secondTitle}>（四）融资概况</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>15</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）园区载体')}>
              <div className={style.secondTitle}>（五）园区载体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>18</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、产业画像')}>
              <div className={style.firstTitle}>二、产业画像</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）经济运行')}>
              <div className={style.secondTitle}>（一）经济运行</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）市场主体')}>
              <div className={style.secondTitle}>（二）市场主体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>22</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）创新活力')}>
              <div className={style.secondTitle}>（三）创新活力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>26</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）融资能力')}>
              <div className={style.secondTitle}>（四）融资能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>37</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）产业载体')}>
              <div className={style.secondTitle}>（五）产业载体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>42</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AreaPortraitReport;
