import React from 'react';
import classNames from 'classnames';
import styles from './index.module.less';

export default function IndustrySelect({ data, select, setSelect, ifCancel }) {
  console.log(select);
  return (
    <div className={styles.box}>
      {
        data?.length > 0 && data.map((item, index) => (
          <div className={styles.group} key={index}>
            <div className={styles.groupName}>{item.title}</div>
            <div className={styles.list}>
              {
                item.children?.length > 0 && item.children.map(node => (
                  <div
                    key={node.key}
                    className={classNames(styles.industryItem, { [styles.selectedItem]: node.key === select.key, [styles.disableItem]: !node.auth })}
                    onClick={() => {
                      if (ifCancel && select.key === node.key) {
                        setSelect({});
                      } else {
                        setSelect(node);
                      }
                    }}
                  >
                    {node.title}
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
}
