import React, { useMemo, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Header from './Header';
import Content from './Content';
import { useGetTestRecordDetailQuery } from '../../../../../service/tool';
import style from './index.module.less';

const EvaluationResult = () => {
  const { href } = window.location;
  const history = useHistory();
  const boxEl = useRef();
  const routeParams = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);
  const id = routeParams.get('id');
  const companyId = routeParams.get('companyId');
  const { data } = useGetTestRecordDetailQuery({ record_id: id });
  useEffect(() => {
    if (data) {
      localStorage.setItem('formData', JSON.stringify(data.appraisal_content));
    }
  }, [data]);

  const goPreviewPdf = () => {
    history.push(`/companyIntelligentEvaluation/previewPdf?id=${id}&companyId=${companyId}`);
  };
  return (
    <>
      <div
        className={style.box}
        ref={boxEl}
      >
        {
          data && (
            <>
              <Header id={id} data={data} companyId={companyId} goPreviewPdf={goPreviewPdf} />
              <Content id={id} data={data} boxEl={boxEl} />
            </>
          )
        }
        {/* <div className={style.rect}>
        <div></div>
      </div> */}
      </div>
      {/* <EvaluationResult /> */}
    </>
  );
};

export default EvaluationResult;
