/**
 * 数据分析工具接口
 */
import { apiSlice } from '../slices/api';

const PROJECT = '/dataAnalysis/project';
const REPORT = '/dataAnalysis/report';
const DATASELECT = '/dataAnalysis/dataSelect';

export const dataAnalysisToolApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 项目
    // 创建项目
    getCreateProject: builder.query({
      query(arg) {
        return {
          url: `${PROJECT}/create_project`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 删除项目
    getDeleteProject: builder.query({
      query(arg) {
        return {
          url: `${PROJECT}/delete_project`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 更新项目
    getUpdateProject: builder.query({
      query(arg) {
        return {
          url: `${PROJECT}/update_project`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询列表
    getQueryProjectList: builder.query({
      query(arg) {
        return {
          url: `${PROJECT}/query_project_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询单个项目
    getQueryProject: builder.query({
      query(arg) {
        return {
          url: `${PROJECT}/query_project`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 下载列表
    // 报告列表
    getDownloadReportList: builder.query({
      query(arg) {
        return {
          url: `${REPORT}/report_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 删除报告
    getReportDelete: builder.query({
      query(arg) {
        return {
          url: `${REPORT}/report_delete`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 数据筛选
    // 产业筛选项
    getIndustrySelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/industry_select`,
          params: arg,
        };
      },
    }),
    // 区域筛选框
    getRegionSelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/region_select`,
          params: arg,
        };
      },
    }),
    // 园区筛选框
    getParkSelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/park_select`,
          params: arg,
        };
      },
    }),
    // 企业规模筛选
    getCompanyScaleSelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/company_scale_select`,
          params: arg,
        };
      },
    }),
    // 企业状态
    getCompanyStatusSelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/company_status_select`,
          params: arg,
        };
      },
    }),
    // 融资轮次
    getFinanceRoundSelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/finance_round_select`,
          params: arg,
        };
      },
    }),
    // 企业资质
    getCompanyQualificationSelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/company_qualification_select`,
          params: arg,
        };
      },
    }),
    // 上市板块
    getMarketSectorSelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/market_sector_select`,
          params: arg,
        };
      },
    }),
    // 资本类型
    getCapitalTypeSelect: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/capital_type_select`,
          params: arg,
        };
      },
    }),
    // 上传数据
    getFilterData: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/filter_data`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 筛选数据导出
    getFilterDataExport: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/filter_data_export`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 上传数据
    getUploadData: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/upload_data`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 上传数据查询
    getUploadDataQuery: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/upload_data_query`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 上传数据导出
    getExportData: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/export_data`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 上传数据清空
    getUploadDataClear: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/upload_data_clear`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 获取上传出错的表格
    getErrorTable: builder.query({
      query(arg) {
        return {
          url: `${DATASELECT}/find_abnormal_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 数据导出选项 /industry_knowledge_engine/v2/dataAnalysis/dataSelect/export_data_catalog
    exportDataCatalog: builder.query({
      query() {
        return {
          url: `${DATASELECT}/export_data_catalog`,
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useLazyGetCreateProjectQuery,
  useLazyGetDeleteProjectQuery,
  useLazyGetUpdateProjectQuery,
  useLazyGetQueryProjectListQuery,
  useGetQueryProjectQuery,
  useLazyGetQueryProjectQuery,
  useLazyGetDownloadReportListQuery,
  useLazyGetReportDeleteQuery,
  useGetIndustrySelectQuery,
  useGetRegionSelectQuery,
  useGetParkSelectQuery,
  useGetCompanyScaleSelectQuery,
  useGetCompanyStatusSelectQuery,
  useGetFinanceRoundSelectQuery,
  useGetCompanyQualificationSelectQuery,
  useGetMarketSectorSelectQuery,
  useGetCapitalTypeSelectQuery,
  useLazyGetFilterDataQuery,
  useLazyGetFilterDataExportQuery,
  useLazyGetUploadDataQuery,
  useLazyGetUploadDataQueryQuery,
  useLazyGetExportDataQuery,
  useLazyGetUploadDataClearQuery,
  useGetErrorTableQuery,
  useLazyGetErrorTableQuery,
  useExportDataCatalogQuery,
} = dataAnalysisToolApi;
