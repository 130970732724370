import React, { useState, useEffect, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import html2canvas from 'html2canvas';
import { cloneDeep } from 'lodash';
import { Spin } from 'antd';
import backPng from '../../../../../../components/IndustryCompanyDetail/CompanyContent/RelationshipPenetration/IndustrySector/IndustrySectorGraph/components/Tree/background.png';
import styles from './index.module.less';

const extraOption = [
  {
    z: 9,
    label: {
      position: 'right',
      fontSize: 14,
      fontWeight: 500,
      color: '#0F2849',
    },
    emphasis: {
      label: {
        fontWeight: 600,
        color: '#1961F5',
        textBorderWidth: 0.5,
        textBorderColor: '#1961F5',
      },
    },
  },
  {
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: '#0F2849',
    },
    emphasis: {
      label: {
        fontWeight: 600,
        color: '#1961F5',
        textBorderWidth: 0.5,
        textBorderColor: '#1961F5',
      },
    },
  },
];

function OrientTree(props, ref) {
  const { data, select, zoom = 0.85, layout = 'radial', useDataColors, selectIndustryName, selectAreaName, isFetching, areaLegend } = props;
  const [isChartReady, setIsChartReady] = useState(0);
  const echartsRef = useRef(null);
  // const timer = useRef(null);
  // const preZoom = useRef(1.15);

  useEffect(() => {
    if (!isChartReady) return;
    const _data = cloneDeep(data);
    const handleChartData = param => {
      const result = [];
      param.forEach(oItem => {
        if (oItem.children && oItem.children.length > 0) {
          oItem.children = handleChartData(oItem.children);
        }
        const sOption = oItem.level >= 1 ? cloneDeep(extraOption[1]) : cloneDeep(extraOption[oItem.level]);
        if (oItem.need_high_light) {
          sOption.itemStyle = {
            color: '#F59319',
          };
          sOption.lineStyle = {
            color: '#F59319',
          };
          sOption.label = {
            color: '#333333',
            textBorderColor: '#333333',
            textBorderWidth: 0.5,
          };
        }
        oItem = {
          ...oItem,
          ...sOption,
        };
        result.push(oItem);
      });
      return result;
    };
    const instance = echartsRef.current?.getEchartsInstance();
    instance?.clear();
    instance?.setOption({
      tooltip: false,
      series: [
        {
          large: true,
          id: 'tree',
          type: 'tree',
          roam: true,
          zoom,
          scaleLimit: {
            min: 0.6,
          },
          top: '15%',
          left: '25%',
          bottom: '-8%',
          // right: '20%',
          layout,
          orient: 'TB',
          symbol: (value, params) => {
            if (params.collapsed) {
              if (!params.data.children) {
                return 'emptyCircle';
              }
              return 'circle';
            }
            return 'emptyCircle';
          },
          symbolSize: 10,
          itemStyle: {
            color: '#1961F5',
          },
          lineStyle: {
            color: '#1961F5',
          },
          expandAndCollapse: true,
          animation: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
          animationThreshold: 4000000,
          initialTreeDepth: 4,
          label: {
            color: '#666666',
            formatter: p => {
              const { data: d, name, data: { title } } = p;
              if (d.level === 0) {
                let n = '';
                const companyName = name.split('') || title.split('');
                while (companyName.length > 4) {
                  n += `${companyName.splice(0, 4).join('')}\n`;
                }
                return `{size0|${n}${companyName.join('')}}`;
              }
              if ((d.level === 1 || d.level === 2) && d.children && d.children.length > 0) {
                let n = '';
                const companyName = name.split('') || title.split('');
                n += `${companyName.splice(0, 6).join('')}...`;
                return `{size1|${n}}`;
              }
              return `{size2|${name || title}}`;
            },
            rich: {
              size0: {
                align: 'center',
                fontSize: 16,
                color: '#1961F5',
                fontWeight: 600,
              },
              size1: {
                fontSize: 14,
              },
              size2: {
                fontSize: 14,
              },
            },
          },
          data: handleChartData([_data]),
        },
      ],
    }, true);
  }, [data, isChartReady, select, zoom]);

  useImperativeHandle(ref, () => ({
    download: () => {
      // 获取 base64 图片
      const myChart = echartsRef.current.echarts.getInstanceByDom(echartsRef.current.ele);
      const bgImage = new Image();
      bgImage.src = backPng;
      bgImage.onload = () => {
        myChart.setOption({
          graphic: {
            elements: [{
              type: 'image',
              style: {
                image: bgImage,
                width: '100%',
                height: '100%',
              },
              left: 0,
              right: 0,
            }],
          },
        });
      };
      html2canvas(myChart.getDom()).then(canvas => {
        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = `${selectAreaName ?? '全国'}${selectIndustryName}优势企业挂链`;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        document.body.removeChild(a);
      });
    },
  }));

  return (
    <Spin spinning={isFetching} wrapperClassName={styles.spinChart}>
      <ReactEcharts
        option={{}}
        notMerge
        className={styles.chart}
        ref={echartsRef}
        onChartReady={() => {
          setIsChartReady(prev => prev + 1);
        }}
      />
    </Spin>
  );
}

export default forwardRef(OrientTree);
