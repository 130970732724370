/*
*Description: ScatterMap业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ScatterMapChart from '../../BasicsCompoment/ScatterMapChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessScatterMapChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessScatterMapChart(props: BusinessScatterMapChartProps) {
  const {
    mode, label, legend = true, ip, conditions,
    onRequestComplete, title, componmentKey,
    visualColors, showVisual, visualType,
    imgRef, labelFormat,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data.data || res.data;
          // @ts-ignore
          if (window.isDevVite) {
            resData.host = '/cityJsons';
          } else if (typeof process !== 'undefined' && process?.env?.NODE_ENV === 'production') {
            // eslint-disable-next-line no-restricted-globals
            resData.host = `${location.protocol}//${location.host}/industry_selection_web/city_json`;
          } else if (typeof process !== 'undefined' && process?.env?.NODE_ENV === 'development') {
            // eslint-disable-next-line no-restricted-globals
            resData.host = `http://${window.location.host}/city_json`;
          } else {
            // eslint-disable-next-line no-restricted-globals
            resData.host = `${location.protocol}//${location.host}/city_json`;
          }
          setData(resData);
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
          onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        console.log(_reason);
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);
  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark} style={{ overflowY: 'hidden' }}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <ScatterMapChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            label,
            legend,
            ref: imgRef,
            chartDesc,
            visualColors,
            showVisual,
            visualType,
            labelFormat,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BusinessScatterMapChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
};

// 26. 企业地理空间分布
export function CompanyChartHandlerenterpriseGeospatialDistributionFenxi(
  props: BusinessScatterMapChartProps,
) {
  const componmentKey = {
    function_key: 'enterprise_geospatial_distribution_fenxi',
    class_name: '/chart/company',
  };
  function labelFormat(params: any, data: any) {
    const dataItem = data.find((item: any) => params?.name === item?.name);
    if (!dataItem?.name || !dataItem?.value) {
      return '';
    }
    return `${dataItem?.name}\n{small|${dataItem?.value}}`;
  }
  return (
    <BusinessScatterMapChart
      {...props}
      componmentKey={componmentKey}
      label
      labelFormat={labelFormat}
    />);
}

// 11 . 174--授权发明专利分布图
export function PatentChartpatentAreaThermodynamicChartFenxi(
  props: BusinessScatterMapChartProps,
) {
  const componmentKey = {
    function_key: 'patent_area_thermodynamic_chart_fenxi',
    class_name: '/chart/patent',
  };
  const visualColors = ['#4E8466', '#67A181', '#8DB59F', '#A4C3B2', '#DAEAE6', '#F0F9F7'].reverse();

  function labelFormat(params: any, data: any) {
    const dataItem = data.find((item: any) => params?.name === item?.name);
    if (!dataItem?.name || !dataItem?.value) {
      return '';
    }
    return `${dataItem?.name}\n{small|${dataItem?.value}}`;
  }
  return (
    <BusinessScatterMapChart
      {...props}
      componmentKey={componmentKey}
      visualColors={visualColors}
      showVisual={true}
      label
      labelFormat={labelFormat}
      visualType="piecewise"
    />);
}

// 企业区域热力分布
export function CompanyChartCompanyAreaThermodynamicChartFenxi(
  props: BusinessScatterMapChartProps,
) {
  const componmentKey = {
    function_key: 'company_area_thermodynamic_chart_fenxi',
    class_name: '/chart/company',
  };
  const visualColors = ['#4E8466', '#67A181', '#8DB59F', '#A4C3B2', '#DAEAE6', '#F0F9F7'].reverse();
  function labelFormat(params: any, data: any) {
    const dataItem = data.find((item: any) => params?.name === item?.name);
    if (!dataItem?.name || !dataItem?.value) {
      return '';
    }
    return `${dataItem?.name}\n{small|${dataItem?.value}}`;
  }
  return (
    <BusinessScatterMapChart
      {...props}
      componmentKey={componmentKey}
      visualColors={visualColors}
      showVisual
      label
      labelFormat={labelFormat}
      visualType="piecewise"
    />);
}

// 国家级专精特新企业区域分布
export function CompanyChartCompanyAreaThermodynamicChartSpecialization(
  props: BusinessScatterMapChartProps,
) {
  const componmentKey = {
    function_key: 'company_area_thermodynamic_chart_specialization',
    class_name: '/chart/company',
  };
  const visualColors = ['#4E8466', '#67A181', '#8DB59F', '#A4C3B2', '#DAEAE6', '#F0F9F7'].reverse();
  function labelFormat(params: any, data: any) {
    const dataItem = data.find((item: any) => params?.name === item?.name);
    if (!dataItem?.name || !dataItem?.value) {
      return '';
    }
    return `${dataItem?.name}\n{small|${dataItem?.value}}`;
  }
  return (
    <BusinessScatterMapChart
      {...props}
      componmentKey={componmentKey}
      visualColors={visualColors}
      showVisual
      label
      labelFormat={labelFormat}
      visualType="piecewise"
    />);
}
