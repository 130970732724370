import nzh from 'nzh';

/**
 * 收集 scheme 下所有组件的信息，统计三大类的个数：
 * 1. 标题； 2. 图； 3. 表
 * 然后根据这些生成额外的信息
 * position: number 该类型组件在整个树状结构中的顺序（位置）
 * path: string *-*-* 该组件在整个结构中的位置，用于制作页面的锚点
 * @param components
 */
export const generateMetadata = (components: any) => {
  const HEADING_TYPES = ['heading'];
  const CHART_TYPES = ['config-echarts', 'timeline', 'image'];
  const TABLE_TYPES = ['table', 'card-enterprise-face', 'card-enterprise-trend'];

  // 剔除为空情况
  if (!Array.isArray(components)) {
    return [];
  }
  // 准备工作：
  // 1. 准备将标题转为树形结构；并利用 map 统计每种级别标题出现的次数出现的次数
  // 2. 统计图和表格出现和子类型出现的次数次数
  const titlePath: any[] = [];
  const titleMap = new Map<string, number>();
  const tableMap = TABLE_TYPES.reduce((prev, next) => {
    prev[next] = 0;
    return prev;
  }, {} as Record<string, any>);
  const chartMap = CHART_TYPES.reduce((prev, next) => {
    prev[next] = 0;
    return prev;
  }, {} as Record<string, any>);
  let tableCount = 0;
  let chartCount = 0;

  return components.map(item => {
    const { id } = item;
    if (!HEADING_TYPES.includes(item.type) && !TABLE_TYPES.includes(item.type) && !CHART_TYPES.includes(item.type)) {
      return { id };
    }

    // 如果是表格且配置显示标题
    if (TABLE_TYPES.includes(item.type) && item?.properties?.title?.show) {
      tableCount += 1;
      tableMap[item.type] += 1;

      return {
        id,
        position: tableCount,
        path: titlePath.filter(Boolean).join('-'),
      };
    }

    // 如果是图且配置显示标题
    if (CHART_TYPES.includes(item.type) && item?.properties?.title?.show) {
      chartCount += 1;
      chartMap[item.type] += 1;

      return {
        id,
        position: chartCount,
        path: titlePath.filter(Boolean).join('-'),
      };
    }

    // 如果是标题，必定显示
    if (HEADING_TYPES.includes(item.type)) {
      titlePath[item.properties.level - 1] = item.properties.text;
      titleMap.set(item.properties.level, (titleMap.get(item.properties.level) ?? 0) + 1);
      for (let i = item.properties.level + 1; i <= 6; i++) {
        titleMap.set(i, 0);
        titlePath[i - 1] = undefined;
      }
      const count = titleMap.get(item.properties.level) as number;

      return {
        id,
        position: count,
        path: titlePath.filter(Boolean).join('-'),
      };
    }

    return { id };
  });
};

export const convertHeading = (num: number, level: number) => {
  switch (level) {
    case 1:
      return `${nzh.cn.encodeS(num, { tenMin: true })}、`;
    case 2:
      return `（${nzh.cn.encodeS(num, { tenMin: true })}）`;
    default:
      return `${num}. `;
  }
}
