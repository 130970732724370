import React, { useState, useMemo } from 'react';
import { Spin } from 'antd';
import { Table } from '@sqke/design';
import { cloneDeep } from 'lodash';
import {
  useGetCompanyIndustryListQuery,
  useGetPartnershipTableQuery,
} from '../../../../../service/companyDetail';
import { useGetChannelInvestmentRoundListQuery } from '../../../../../service/industry-channel';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetCompanySpecialTagsQuery,
} from '../../../../../service/interactive-v2';
import { useGetAssociationRelationQuery } from '../../../../../service/tool';
import Swiper from './Swiper';
import CustomTreeFilter from '../../../../CustomTreeFilter';
import CustomListFilter from '../../../../CustomListFilter';
import { FilterIcon } from '../../../../CustomTableIcon';
import IndustrySectorGraph from './IndustrySectorGraph';
import Container from '../../Container';
import style from './index.module.less';

const MultiLineEllipsis = text => {
  const _text = Array.isArray(text) ? text.join('、') : text;
  return <div className="overflow-multi-5" title={_text}>{_text}</div>;
};

const sortMap = {
  descend: 'desc',
  ascend: 'asc',
};

const IndustrySector = (props) => {
  const { companyId, companyName } = props;
  const [industryId, setIndustryId] = useState();
  const [industryName, setIndustryName] = useState();
  const [tableState, setTableState] = useState({
    filteredInfo: {},
    sortedInfo: {},
  });
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const { data } = useGetCompanyIndustryListQuery({
    company_id: companyId,
  });
  const amplifyOption = useMemo(() => {
    if (!data) return {};
    const _data = cloneDeep(data);
    _data.industryId = industryId;
    _data.industryName = industryName;
    _data.companyId = companyId;
    _data.graph = true;
    return {
      option: _data,
      title: {
        title: '业务领域',
        question: '展示企业业务覆盖领域及所处领域赛道',
        companyName,
      },
    };
  }, [data, industryId, industryName, companyId]);

  const { data: tableData, isFetching } = useGetPartnershipTableQuery({
    company_id: companyId,
    industry_code_list: [industryId],
    area_key: tableState.filteredInfo?.location || '000000',
    sort_key: 'establish_date',
    sort_value: sortMap[tableState.sortedInfo?.order] || '',
    company_type: tableState.filteredInfo?.special_tag?.[0] === 'all' ? '' : tableState.filteredInfo?.special_tag,
    relationships: (tableState.filteredInfo?.cooperation_type?.[0] === 'all' ? '' : tableState.filteredInfo?.cooperation_type) || '',
    round_list: tableState.filteredInfo?.round?.[0] === 'all' ? '' : tableState.filteredInfo?.round,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, { skip: !industryId });
  const { data: regionTree } = useGetAreaPlugMarqueeV2Query();
  const { data: tags = [] } = useGetCompanySpecialTagsQuery();
  const { data: round = [] } = useGetChannelInvestmentRoundListQuery();
  const { data: type = [] } = useGetAssociationRelationQuery();
  const _columns = useMemo(() => {
    if (!tableData) return [];
    return tableData.columns.map(item => {
      switch (item.dataIndex) {
        case 'index':
          return { ...item, width: 70 };
        case 'name':
          return { ...item, width: 320, sorter: true };
        case 'establish_date':
          return { ...item, sorter: true };
        case 'location':
          return {
            ...item,
            key: item.dataIndex,
            filters: regionTree || [],
            filterDropdown: _props => <CustomTreeFilter {..._props} />,
            filteredValue: tableState.filteredInfo.location?.length > 0 ? tableState.filteredInfo.location : ['000000'],
            ellipsis: true,
            filterMode: 'tree',
            filterIcon: <FilterIcon />,
          };
        case 'special_tag':
          return {
            ...item,
            key: item.dataIndex,
            ellipsis: true,
            filterIcon: <FilterIcon />,
            filters: tags || [],
            filteredValue: tableState.filteredInfo.special_tag?.length > 0 ? tableState.filteredInfo.special_tag : ['all'],
            filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
            filterResetToDefaultFilteredValue: true,
            render: text => (Array.isArray(text) ? text.join('、') : text),
          };
        case 'cooperation_type':
          return {
            ...item,
            key: item.dataIndex,
            ellipsis: true,
            filterIcon: <FilterIcon />,
            filters: type.filter(it => it.key !== '不限').map(it2 => ({ ...it2, value: it2.key, text: it2.key })) || [],
            filteredValue: tableState.filteredInfo.cooperation_type?.length > 0 ? tableState.filteredInfo.cooperation_type : ['all'],
            filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
            filterResetToDefaultFilteredValue: true,
            render: text => (Array.isArray(text) ? text.join('、') : text),
          };
        case 'round':
          return {
            ...item,
            key: item.dataIndex,
            ellipsis: true,
            filterIcon: <FilterIcon />,
            filters: round || [],
            filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
            filteredValue: tableState.filteredInfo.round?.length > 0 ? tableState.filteredInfo.round : ['all'],
            filterResetToDefaultFilteredValue: true,
            render: MultiLineEllipsis,
          };
        default: return { ...item, ellipsis: true, key: item.dataIndex };
      }
    });
  }, [tableData?.columns, regionTree]);

  const tableEl = useMemo(() => {
    if (!tableData || tableData?.columns.length) {
      return (<Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={_columns}
        title={
          () => (
            <>
              <span>{tableData?.title}</span>
              <span className={style.count}>{tableData?.count}</span>
            </>
          )
        }
        strip
        loading={isFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        // locale={{ emptyText: () => <div className={style.tableNoData}>该企业无此项数据</div> }}
        locale={{
          emptyText: () => {
            if (!(tableData?.dataSource)) {
              return '';
            }
            return <div className={style.tableNoData}>该企业无此项数据</div>;
          },
        }}
        onChange={({ current, pageSize }, filters, sorter) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
          setTableState(prev => ({
            ...prev,
            pageNumber: current,
            filteredInfo: filters,
            sortedInfo: sorter,
          }));
        }}
      />);
    }
    return <></>;
  }, [tableData]);
  return (
    <Container id='业务领域' question="展示企业业务覆盖领域及所处领域赛道" look="查看全图" option={amplifyOption}>
      {
        // eslint-disable-next-line no-nested-ternary
        data?.count !== 0 ? (
          data?.industry_list ? <Swiper swiperData={data?.industry_list || []} onSetIndustryId={setIndustryId} onSetsetIndustryName={setIndustryName} />
            : (
              <div className={style.spinBox}>
                <Spin />
              </div>
            )
        )
          : null
      }
      {
        // eslint-disable-next-line no-nested-ternary
        data?.count !== 0 ? (
          data?.industry_list ? <IndustrySectorGraph industryCode={industryId || data?.industry_list[0]?.tag_code} industryName={industryName || data?.industry_list[0]?.tag_name} companyId={companyId} />
            : (
              <div className={style.spinBox}>
                <Spin />
              </div>
            )
        )
          : null
      }
      {
        tableEl
      }

    </Container>
  );
};
export default IndustrySector;
