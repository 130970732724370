/*
 *Description: 柱线图业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { BarAndLineChartProps } from '../../BasicsCompoment/basicsType';
import BarAndLineChart from '../../BasicsCompoment/BarAndLineChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessBarAndLineChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessBarAndLineChart(props: BusinessBarAndLineChartProps) {
  const {
    mode, label, legend = true, ip, conditions,
    onRequestComplete, title, componmentKey,
    isSingleShape, yAxisStyle, showAllXLabel,
    imgRef, isArea, chartGrid, fontSize,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  const [data, setData] = useState<BarAndLineChartProps['data']>({ xAxis: [], yAxis: [], yUnit: '', yRightUnit: '' });
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        const shapeMap = [{}, { area: false }];
        if (res.status === '00000') {
          let resData = res.data.data;
          if (componmentKey.function_key === 'curve_graph_market_sum_fenxi') {
            // debugger;
          }
          if (!resData) {
            resData = res.data;
            resData.yAxis = [{
              isType: 1,
              value: resData.yAxis,
            }];
          }
          setData({
            xAxis: resData.xAxis,
            yAxis: resData.yAxis.map((item: any) => (
              { ...item, ...shapeMap[item.isType] }
            )),
            yUnit: resData.yUnit || '',
            yName: resData.yName || '',
            yRightName: resData.yRightName || '',
            yRightUnit: resData.yRightUnit || '',
            highlight: resData.highlight,
            xName: resData.xName || '',
            showAllXLabel,
          });
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
          onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <BarAndLineChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            label,
            legend,
            ref: imgRef,
            chartDesc,
            isSingleShape,
            yAxisStyle,
            isArea,
            chartGrid,
            fontSize,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BusinessBarAndLineChart.defaultProps = {
  mode: 'light',
  fontSize: 'middle',
  chartGrid: {},
  label: true,
  legend: true,
  title: true,
};

// 投融资交易趋势 交易事件及金额表
export function TransactionTrendsChart(props: BusinessBarAndLineChartProps) {
  const componmentKey = {
    function_key: 'investment_money_count_chart_fenxi',
    class_name: '/chart/investment',
  };

  return (
    <BusinessBarAndLineChart
      {
        ...props
      }
      componmentKey={componmentKey}
    />);
}

TransactionTrendsChart.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 地区生产总值及增长率
export function RegionGrowAndRatioBarAndLineChart(props: BusinessBarAndLineChartProps) {
  const componmentKey = {
    function_key: 'area_total_output_value_chart_fenxi',
    class_name: '/chart/macroEconomy',
  };
  const showAllXLabel = true;
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      showAllXLabel={showAllXLabel}
    />);
}

RegionGrowAndRatioBarAndLineChart.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 平均单笔融资额变化 折线图
export function AverageFinancingChange(props: BusinessBarAndLineChartProps) {
  const componmentKey = {
    function_key: 'curve_graph_investment_avg_fenxi',
    class_name: '/chart/investment',
  };
  const isSingleShape = true;
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape={isSingleShape}
      yAxisStyle={{
        showYaxisLine: true,
        hiddenYsplitLine: true,
      }}
    />);
}

AverageFinancingChange.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 20 地区生产总值及增长率变化
export function AreaGrowAndRatioBarAndLineChart(props: BusinessBarAndLineChartProps) {
  const componmentKey = {
    function_key: 'regional_GDP_growth_rate_changes_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
    />);
}

AreaGrowAndRatioBarAndLineChart.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 报告图50 . 140--融资事件变化
export function InvestmentChartchangesFinancingEventsNumberFenxi(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'changes_financing_events_number_fenxi',
    class_name: '/chart/investment',
  };
  const isSingleShape = true;
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape={isSingleShape}
      yAxisStyle={{
        showYaxisLine: true,
        hiddenYsplitLine: true,
      }}
    />);
}

InvestmentChartchangesFinancingEventsNumberFenxi.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 报告图51 . 141--融资金额变化
export function InvestmentChartchangesFinancingEventsMoneyFenxi(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'changes_financing_events_money_fenxi',
    class_name: '/chart/investment',
  };
  const isSingleShape = true;
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape={isSingleShape}
      yAxisStyle={{
        showYaxisLine: true,
        hiddenYsplitLine: true,
      }}
    />);
}

InvestmentChartchangesFinancingEventsMoneyFenxi.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 7 . 153--上市企业数量变化
export function CompanyChartcurveGraphMarketSumFenxi(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'curve_graph_market_sum_fenxi',
    class_name: '/chart/company',
  };
  const isSingleShape = true;

  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape={isSingleShape}
      yAxisStyle={{
        showYaxisLine: true,
        hiddenYsplitLine: true,
      }}
    />);
}

CompanyChartcurveGraphMarketSumFenxi.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 37 . 27--政策数量变化
export function PolicyChartpolicyQuantityChangeFenxi(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'policy_quantity_change_fenxi',
    class_name: '/chart/policy',
  };
  const isSingleShape = true;
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape={isSingleShape}
      yAxisStyle={{
        showYaxisLine: true,
        hiddenYsplitLine: true,
      }}
    />);
}

PolicyChartpolicyQuantityChangeFenxi.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 2 . --存量企业数量变化
export function CompanyChartchangesNumberExistingEnterprisesFenxi(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'changes_number_existing_enterprises_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      showAllXLabel
      componmentKey={componmentKey}
    />);
}

CompanyChartchangesNumberExistingEnterprisesFenxi.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 14 . --所获资质标签数量
export function CompanyChartcompanyCertificationCurveGraph(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'company_certification_curve_graph',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
    />);
}

CompanyChartcompanyCertificationCurveGraph.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 18 . --授权发明专利时序分布
export function PatentChartinventPatentTimeSeriesFenxi(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'invent_patent_time_series_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
    />);
}

PatentChartinventPatentTimeSeriesFenxi.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 34 . 209--企业画像-各维度增长率曲线
export function CommonChartgrowthRateCurvesOfAllDimensionsFenxi(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'growth_rate_curves_of_all_dimensions_fenxi',
    class_name: '/chart/common',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
    />);
}

CommonChartgrowthRateCurvesOfAllDimensionsFenxi.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 9 . --成长活跃曲线
export function CommonChartcompanyGrowUpActiveCurveFenxi(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'company_grow_up_active_curve_fenxi',
    class_name: '/chart/common',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
    />);
}

// 国家级专精特新企业到达融资轮次平均年龄
export function CompanyChartAvgInvestHistoricalDaysSpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'avg_invest_historical_days_specialization',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
      isArea
    />);
}

CompanyChartAvgInvestHistoricalDaysSpecialization.defaultProps = {
  chartGrid: {
    left: 50,
  },
};

// 国家级专精特新企业融资事件变化
export function InvestmentChartChangesFinancingEventsNumberSpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'changes_financing_events_number_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
    />);
}

InvestmentChartChangesFinancingEventsNumberSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 国家级专精特新企业融资金额变化
export function InvestmentChartChangesFinancingEventsMoneySpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'changes_financing_events_money_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
    />);
}

InvestmentChartChangesFinancingEventsMoneySpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 国家级专精特新企业平均单笔融资额变化
export function InvestmentChartCurveGraphInvestmentAvgSpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'curve_graph_investment_avg_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
    />);
}

InvestmentChartCurveGraphInvestmentAvgSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 国家级专精特新企业融资事件变化
export function InvestmentChartChangeNumberOfFinancingEventsSpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'change_number_of_financing_events_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
      isSingleShape
    />);
}

InvestmentChartChangeNumberOfFinancingEventsSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 国家级专精特新企业上市企业融资金额及平均单笔融资额变化
export function InvestmentChartListedCompanyFinancingAndAvgFinancingSpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'listed_company_financing_and_avg_financing_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
    />);
}

InvestmentChartListedCompanyFinancingAndAvgFinancingSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 非上市国家级专精特新企业融资金额及平均单笔融资额变化
export function InvestmentChartUnlistedCompanyFinancingAndAvgFinancingSpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_company_financing_and_avg_financing_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
    />);
}

InvestmentChartUnlistedCompanyFinancingAndAvgFinancingSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 国家级专精特新企业融资轮次分布
export function InvestmentChartFinancingRoundDistributionSpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'financing_round_distribution_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
    />);
}

InvestmentChartFinancingRoundDistributionSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 国家级专精特新企业融资区域分布
export function InvestmentChartRegionalDistributionFinancingSpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'regional_distribution_financing_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
    />);
}

InvestmentChartRegionalDistributionFinancingSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};

// 国家级专精特新企业融资产业分布
export function InvestmentChartDistributionFinancingIndustrySpecialization(
  props: BusinessBarAndLineChartProps,
) {
  const componmentKey = {
    function_key: 'Distribution_financing_industry_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarAndLineChart
      {...props}
      componmentKey={componmentKey}
    />);
}

InvestmentChartDistributionFinancingIndustrySpecialization.defaultProps = {
  chartGrid: {
    top: 50,
  },
};
