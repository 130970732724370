// // 随机增加二级域名
// function handleWithSecondaryDomain(url: string) {
//   // 非接口请求直接返回
//   if (url.indexOf('http') === -1) return url;
//   if (url.indexOf('localhost') > -1) return url;
//   let handleUrl = url;
//   const host = url.split('://')[1].split('/')[0];
//   let isIp: any[] = [];
//   host.split('.').forEach(item => {
//     const numItem = parseFloat(item);
//     isIp.push(typeof numItem === 'number' && !isNaN(numItem));
//   })
//   if (isIp.every(item => item)) {
//     handleUrl = url;
//   } else {
//     let splicinghost = host;
//     if (host.split('.').length > 2) {
//       splicinghost = host.split('.').slice(1).join('.');
//     }
//     // 随机拼接二级域名
//     const secondaryDomain = ['', 'one.', 'two.'];
//     handleUrl = url.replace(host, `${secondaryDomain[Math.round(Math.random() * (secondaryDomain.length - 1))]}${splicinghost}`)
//   }
//   return handleUrl;
// }

function request(reqMethod: string, uri: string, reqBody: any) {
  // let url = handleWithSecondaryDomain(uri);
  // if (uri.indexOf('login') > -1) {
  //   url = uri;
  // }
  // if (uri.indexOf('city_json') > -1) {
  //   url = uri;
  // }
  let url = uri;
  let body;
  const method = reqMethod.toUpperCase();
  if (method === 'GET') {
    const a: string[] = [];
    Object.keys(reqBody).forEach(item => {
      a.push(`${item}=${reqBody[item]}`);
    });
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = reqBody && JSON.stringify(reqBody);
  }

  let credentials: any = 'same-origin';

  if (process.env.NODE_ENV === 'production') {
    credentials = 'include';
  }

  let Authorization = sessionStorage.getItem('token') || '';

  // if (process.env.NODE_ENV === 'development') {
  //   Authorization = 'Bearer 7WVK4yDd2m0Vgvq6IOda83RwtnqmR7tGrZBBKfFncZ';
  // }

  return fetch(url, {
    method,
    mode: 'cors',
    credentials,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json,text/plain,*/*',
      Authorization,
      // 'Authorization': localStorage.getItem('token') || ''
    },
    body,
  }).then((res) => {
    const datas = res.json().then((resItem) => resItem);
    return datas;
  }).catch(() => ({ status: -1, message: '数据加载异常...' }));
}

export const get = (url: string, body: any) => request('GET', url, body);
export const post = (url: string, body: any) => request('POST', url, body);
export const put = (url: string, body: any) => request('PUT', url, body);
export const del = (url: string, body: any) => request('DELETE', url, body);
