const firstLevelColor = {
  color: '#FF8427',
  borderColor: '#FFD8BB',
  labelColor: '#0F2849',
};

const colorList = [
  {
    color: '#390D97',
    borderColor: '#A479F7',
  },
  {
    color: '#9B217E',
    borderColor: '#EA6ED3',
  },
  {
    color: '#E17A4D',
    borderColor: '#F9DFD3',
  },
  {
    color: '#7AB34E',
    borderColor: '#CAFDA3',
  },
  {
    color: '#C7812D',
    borderColor: '#F6D196',
  },
  {
    color: '#ECD570',
    borderColor: '#FDFFD1',
  },
  {
    color: '#E3FD77',
    borderColor: '#FAFFE4',
  },
  {
    color: '#65BAE4',
    borderColor: '#AEE5FC',
  },
  {
    color: '#B0411C',
    borderColor: '#F0A786',
  },
  {
    color: '#64C97A',
    borderColor: '#B4FCCF',
  },
  {
    color: '#4578F0',
    borderColor: '#B4CBFB',
  },
  {
    color: '#B29530',
    borderColor: '#FAE791',
  },
  {
    color: '#535CE3',
    borderColor: '#C2C6FB',
  },
  {
    color: '#E4364D',
    borderColor: '#F3BEC1',
  },
  {
    color: '#771523',
    borderColor: '#EC858A',
  },
  {
    color: '#F3B963',
    borderColor: '#FCEFD9',
  },
];

// const noLinkColor = '#072C71';
const noLinkColor = '#495B79';

export {
  firstLevelColor,
  colorList,
  noLinkColor,
};
