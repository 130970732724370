/*
 *Author: zhaochenyu
 *Description: 动态监测表格
 *Date: 2022/03/07
*/

import React, { useCallback, useEffect, useState } from 'react';
import { Table, Pagination } from 'antd';
import { openNewPage } from '../../../../utils/urlMethods';
import { getDetailMonitor } from '../../../../api/mySelf';
import style from './index.module.less';

function MonitorTable(props) {
  const { id, name, date, monitorType } = props;
  const [pagnation, setPagination] = useState({ page_size: 10, page_number: 1 });
  const [tableData, setTableData] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const pushToCompanyDetail = useCallback((rowData) => {
    switch (rowData.goto) {
      case '高管变更':
      case '经营变更':
      case '新设机构':
      case '资质认定':
      case '中标项目':
      case '新增专利':
      case '专利详情':
      case '新获投资':
        openNewPage('companyDetail', {
          id,
          anchor: window.btoa(window.encodeURIComponent(rowData.goto)),
        });
        break;
      case '舆情链接':
        window.open(rowData.link);
        break;
      default: break;
    }
  }, [id]);

  useEffect(() => {
    setTableLoading(true);
    getDetailMonitor({
      detection_type_list: monitorType,
      company_id: id,
      company_name: name,
      date,
      ...pagnation,
    }).then(res => {
      if (res.status === '00000') {
        setTableData(res.data);
      }
    }).finally(() => {
      setTableLoading(false);
    });
  }, [pagnation]);

  const handleWithTableHead = useCallback((columns) => {
    const newColumn = columns.map((item) => {
      let res;
      if (item.dataIndex === 'content') {
        res = {
          ...item,
          width: '70%',
          render: (text, record) => (
            <div className={style.word}>
              <span
                className={style.desc}
                title={text.reduce((pre, cur) => {
                  let resWord = pre;
                  resWord += cur;
                  return resWord;
                }, '')}
                >
                {text.map((item1, index) => (
                  <span
                    className={item1.type === 'red' ? style.lightWord : style.normalWord}
                    key={`${index}word`}
                  >
                    {item1.char}
                  </span>
                ))}
              </span>
              <span
                className={style.detail}
                onClick={() => pushToCompanyDetail(record)}
              >
                详情
              </span>
            </div>
          ),
        };
      } else if (item.dataIndex === 'classify') {
        res = {
          ...item,
          width: '12%',
          ellipsis: true,
        };
      } else {
        res = {
          ...item,
          ellipsis: true,
        };
      }
      return res;
    });
    return newColumn;
  }, []);
  return (
    <div className={style.all}>
      <div className={style.content}>
        <Table
          rowKey="index"
          columns={handleWithTableHead(tableData.tableHead || [])}
          dataSource={tableData.tableBody?.map((item, index) => ({
            ...item,
            index,
          })) || []}
          loading={tableLoading}
          pagination={false}
          className={style.table}
        />
        <Pagination
          showQuickJumper
          hideOnSinglePage
          total={tableData.count || 0}
          className={style.pagination}
          current={pagnation.page_number}
          showSizeChanger={false}
          pageSize={pagnation.page_size}
          onChange={(page) => {
            setPagination({
              ...pagnation,
              page_number: page,
            });
          }}
        />
      </div>
    </div>
  );
}

export default MonitorTable;
