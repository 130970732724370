import React, { useState, useMemo, useRef } from 'react';
import { Popover, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ECharts } from '@sqke/design';
import MindMap from '../CompanyContent/AptitudesAward/HonorAptitudes/HonorQualification/Tree';
import IndustrySectorGraph from '../CompanyContent/RelationshipPenetration/IndustrySector/IndustrySectorGraph';
import Swiper from '../CompanyContent/RelationshipPenetration/IndustrySector/Swiper';
import style from './index.module.less';

function ViewAll() {
  const chartRef = useRef(null);
  const { option, title } = JSON.parse(localStorage.getItem('option'));
  // const [industryId, setIndustryId] = useState(option?.industryId);
  // const [industryName, setIndustryName] = useState(option?.industryName);
  const [industryId, setIndustryId] = useState();
  const [industryName, setIndustryName] = useState();
  const [newOption, setNewOption] = useState(option);
  const [all, setAll] = useState(1);
  return (
    <div className={style.all} key={all}>
      <div className={style.title}>
        {
          option?.type === 'searchIndustry' ? (
            <span>{title}</span>
          ) : (
            <>
              <span className={style.companyName}>{title?.companyName}</span>
              /
              {title?.title}
            </>
          )
        }

        {
          title?.question && (
            <Popover content={title?.question} placement="right" overlayClassName={style.pop}>
              <QuestionCircleOutlined />
            </Popover>
          )
        }
        {
          option?.series?.[0]?.lookAll && (
            <Button
              type="primary"
              className={style.lookAll}
              onClick={() => {
                setNewOption(params => {
                  params.series[0].initialTreeDepth = -1;
                  return params;
                });
                setAll(par => par + 1);
              }}
            >
              展开
            </Button>
          )
        }

      </div>
      {
        option && !option.graph && !option.name && option?.type !== 'searchIndustry' && (
          <ECharts
            className={style.chart}
            ref={chartRef}
            option={newOption}
          />
        )
      }
      {
        option && option.graph && (
          <>
            <Swiper
              viewAll
              swiperData={option?.industry_list || []}
              onSetIndustryId={setIndustryId}
              onSetsetIndustryName={setIndustryName}
            />
            <IndustrySectorGraph
              viewAll
              industryCode={industryId || option?.industry_list[0]?.tag_code}
              industryName={industryName || option?.industry_list[0]?.tag_name}
              companyId={option?.companyId}
            />
          </>
        )
      }
      {
        option.name === '荣誉资质' && (
          <div className={style.chart}>
            <MindMap companyId={option.companyId} />
          </div>
        )
      }
    </div>
  );
}

export default ViewAll;
