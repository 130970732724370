/*
 *Description: 水平条形图业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import HorizontalBarChart from '../../BasicsCompoment/HorizontalBarChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BussinessHorizontalBarChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;
const defaultY = {
  name: '',
  unit: '',
  value: [],
};
function BussinessHorizontalBarChart(props: BussinessHorizontalBarChartProps) {
  const {
    mode, label, legend, ip, conditions,
    onRequestComplete, title, componmentKey,
    imgRef,
  } = props;
  const [chartTitle, setChartTitle] = useState<string>('');
  const [xAxis, setXAxis] = useState([]);
  const [highlight, setHghlight] = useState<Array<any>>([]);
  const [yAxis, setYAxis] = useState(defaultY);
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        setHttpLoading(false);
        if (res.status === '00000') {
          const data = (res?.data?.data) || (res?.data) || {};
          setChartTitle(res.data.title);
          setXAxis(data.xAxis);
          setYAxis(Array.isArray(data.yAxis) ? data.yAxis[0] : data.yAxis);
          const highlightData = data?.highlight || [];
          setHghlight(Array.isArray(highlightData) ? highlightData : [highlightData]);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
          onRequestComplete!(res.data);
        }
      }).catch(() => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
        setHttpLoading(false);
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark}>
      {
        title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>
      }
      <div className={title ? styleChart : styleNoTitleChart} style={{ overflowY: 'scroll' }}>
        <HorizontalBarChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            mode,
            label,
            legend,
            ref: imgRef,
            xAxis,
            yAxis,
            chartDesc,
            highlight,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BussinessHorizontalBarChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
};

// 企业注册资本排名图
export function EnterpriseRegisterHorizontalBarChart(props: BussinessHorizontalBarChartProps) {
  const componmentKey = {
    function_key: 'company_capital_ranking_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 企业数量排名
export function EnterpriseCountRankingHorizontalBarChart(props: BussinessHorizontalBarChartProps) {
  const componmentKey = {
    function_key: 'company_number_ranking_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 15 投融资事件分布
export function InvestAndFinancingDistribute(props: BussinessHorizontalBarChartProps) {
  const componmentKey = {
    function_key: 'investment_count_bar_chart_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 16投融资金额分布
export function InvestAndFinancingMoneyDistribute(props: BussinessHorizontalBarChartProps) {
  const componmentKey = {
    function_key: 'investment_money_bar_chart_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 授权发明专利产业区域分布
export function AuthAndDisDistribute(props: BussinessHorizontalBarChartProps) {
  const componmentKey = {
    function_key: 'bubble_diagram_patent_industry_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 融资事件产业分布
export function IndustryDistributionHorizontalBarChart(props: BussinessHorizontalBarChartProps) {
  const componmentKey = {
    function_key: 'investment_industry_count_bar_chart_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 融资金额产业分布
export function IndustryMoneyDistributionHorizontalBarChart(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'investment_industry_money_bar_chart_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 3 . 155--新增企业区域分布情况图
export function CompanyChartnewCompanyNumberRankingFenxi(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'new_company_number_ranking_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 38 . 28--政策数量区域分布
export function PolicyChartregionalDistributionOfPolicyQuantityFenxi(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'regional_distribution_of_policy_quantity_fenxi',
    class_name: '/chart/policy',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 13 . --
export function PatentChartnewAuthorizedInventionPatentCountFenxi(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'new_authorized_invention_patent_count_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 20 . --园区区域分布图
export function ParkChartparkCountDistributionDiagramFenxi(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'park_count_distribution_diagram_fenxi',
    class_name: '/chart/park',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 21 . --融资事件区域分布
export function InvestmentChartinvestmentCountDistributionDiagramFenxi(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'investment_count_distribution_diagram_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 22 . --融资金额区域分布
export function InvestmentChartinvestmentMoneyDistributionDiagramFenxi(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'investment_money_distribution_diagram_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 18 . --研究机构区域分布图
export function InstitutionChartinstitutionCountDistributionDiagramFenxi(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'institution_count_distribution_diagram_fenxi',
    class_name: '/chart/institution',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业存量授权发明专利对比
export function PatentChartNumberOfPatentsBenchmarkingSpecialization(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'number_of_patents_benchmarking_specialization',
    class_name: '/chart/patent',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业获“国家高新区瞪羚企业”标签企业区域分布
export function CompanyChartGazelleCompanyDistributionSpecialization(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'gazelle_company_distribution_specialization',
    class_name: '/chart/company',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业融资事件区域分布
export function InvestmentChartInvestmentCountBarChartSpecialization(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'investment_count_bar_chart_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业融资金额区域分布
export function InvestmentChartInvestmentMoneyBarChartSpecialization(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'investment_money_bar_chart_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业融资事件产业分布
export function InvestmentChartInvestmentIndustryCountBarChartSpecialization(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'investment_industry_count_bar_chart_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业融资金额产业分布
export function InvestmentChartInvestmentIndustryMoneyBarChartSpecialization(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'investment_industry_money_bar_chart_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 未上市“国家高新区瞪羚企业”标签企业区域分布
export function CompanyChartUnlistedGazelleCompanyDistributionSpecialization(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_gazelle_company_distribution_specialization',
    class_name: '/chart/company',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 细节环节分布
export function CompanyChartSubdivisionDistribution(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'subdivision_distribution',
    class_name: '/chart/company',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 起草标准区域分布
export function StandardChartDraftStandardRegionalDistribution(
  props: BussinessHorizontalBarChartProps,
) {
  const componmentKey = {
    function_key: 'draft_standard_regional_distribution',
    class_name: '/chart/standard',
  };
  return (
    <BussinessHorizontalBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}
