import React, { useState, useEffect, useMemo } from 'react';
import { Radio, Input, Spin } from 'antd';
import { Table } from '@sqke/design';
import { useGetFindCompanyListByLikeNameQuery, useGetEstablishmentPeriodQuery } from '../../../service/tool';
import { data } from './tableData';
import { IconFont } from '../../../utils/mapIcon';
import Portrayal from './components/Portrayal';
import style from './index.module.less';

const resultEl = {
  ok: (
    <div className={style.ok}>
      <p>符 &nbsp; 合</p>
      <p>想要更精准的测评，可点击下方完善您的企业信息后得知</p>
    </div>
  ),
  no: (
    <div className={style.no}>
      <p>不 &nbsp;符 &nbsp; 合</p>
      <p>想要更精准的测评，可点击下方完善您的企业信息后得知</p>
    </div>
  ),
  unfinished: (
    <p className={style.unfinished}>全部选择后查看评测结果</p>
  ),
};

const CompantTest = () => {
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const name = params.get('name');
  const [selectionResult, setSelectionResult] = useState({});
  const [companyNameField, setCompanyNameField] = useState('');
  const [isReq, setIsReq] = useState(false);
  const [field, setField] = useState({ name, id: params.get('id') });
  const [companyId, setCompanyId] = useState(params.get('id'));
  const [companyListShow, setCompanyListShow] = useState(false);

  // 获取企业成立年限图
  const { data: portrayalData, isFetching: loading } = useGetEstablishmentPeriodQuery({ company_id: companyId }, { skip: !companyId });
  // 更新已选的资质
  const updataSelectionResult = (key, value) => {
    setSelectionResult(list => ({ ...list, [key]: value }));
  };
  // 监听输入内容的变化
  useEffect(() => {
    const tirm = setTimeout(() => {
      setCompanyNameField(field.name);
    }, [200]);
    return () => {
      clearTimeout(tirm);
    };
  }, [field]);

  const offCompanyListShow = () => {
    setCompanyListShow(false);
  };
  useEffect(() => {
    localStorage.removeItem('companyInfoForm');
    window.addEventListener('click', offCompanyListShow);
    return () => {
      window.removeEventListener('click', offCompanyListShow);
    };
  }, []);

  const resultCount = () => {
    const result = Object.values(selectionResult);
    if (result.length < 8) {
      return 'unfinished';
    }
    if (result.every(item => item)) {
      return 'ok';
    }
    return 'no';
  };
  // 模糊搜做企业
  const { isFetching, data: companyList } = useGetFindCompanyListByLikeNameQuery({ like_name: companyNameField }, { skip: !companyNameField || isReq });
  const columns = [
    {
      title: '指标名称',
      dataIndex: 'name',
      key: 'name',
      width: 226,
    },
    {
      title: '指标要求',
      dataIndex: 'require',
      key: 'age',
      width: 586,
      render: (text, row, index) => {
        if (typeof text === 'string') {
          return <div className={style.standard}>{text}</div>;
        }
        return (
          <div className={style.standardList}>
            {
                text.map(item => (
                  <div key={Object.keys(item)[0]}>
                    <span>{Object.keys(item)[0]}</span>
                    <span>{item[Object.keys(item)[0]]}</span>
                  </div>
                ))
              }
          </div>
        );
      },
    },
    {
      title: '请选择',
      dataIndex: 'select',
      key: 'select',
      width: 294,
      render: (text, row) => (
        <div>
          <Radio.Group
            disabled={row.disabled}
            onChange={(e) => {
              updataSelectionResult(text, e.target.value);
            }}
          >
            <Radio value>符合</Radio>
            <Radio value={false}>不符合</Radio>
          </Radio.Group>
        </div>
      ),
    },
  ];
  return (
    <div className={style.compantTest}>
      <div className={style.content}>
        <div className={style.title}>
          <div>高企测评</div>
          <div className={style.input}>
            <Input
              value={field.name}
              onChange={(e) => {
                setField((paramsState) => ({ ...paramsState, name: e.target.value }));
                setIsReq(false);
                setCompanyListShow(true);
              }}
              allowClear
            />
            <span
              className={style.trailing}
              onClick={(e) => {
                setCompanyId(field.id);
              }}
            >
              <span className={style.verticalLine} />
              <IconFont
                type="iconfenxiang"
                className={style.icon}
              />
            </span>
            {
              companyListShow && (
              <div className={style.companyList}>
                {
                  !isFetching ? (
                    <div style={{ width: '100%', height: '100%' }}>
                      {
                        companyList && companyList.map(item => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                          <p
                            key={item.id}
                            onClick={(e) => {
                              setIsReq(true);
                              setField({ name: item.name, id: item.id });
                            }}
                          >
                            {item.name}
                          </p>
                        ))
                      }
                    </div>
                  ) : <Spin />
                }
              </div>
              )
            }
          </div>
        </div>
        <div className={style.evaluationIndex}>
          <div className={style.logo}>
            初步测评
          </div>
          <div className={style.table}>
            <Table
              rowKey="select"
              columns={columns}
              dataSource={data}
              // autoMerge
              strip
              pagination={{
                defaultPageSize: 20,
                hideOnSinglePage: true,
              }}
            />
          </div>
          <div className={style.resultBox}>
            <p className={style.resultTitle}>初测结果</p>
            {
              resultEl[resultCount()]
            }
          </div>
        </div>
        <div className={style.portrayal}>
          <Portrayal field={field} data={portrayalData} isFetching={loading} name={field.name || name} id={field.id || companyId} />
        </div>
      </div>
    </div>
  );
};

export default CompantTest;
