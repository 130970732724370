/**
 * 企业对比对标
 */
import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
import style from './index.module.less';
import Header from './components/header';
import Table from './components/Table';

const Index = () => {
  // console.log(Table.addUserCompanyContrastCollect);
  // const param = useParams();
  const [fun, setFun] = useState(null);
  return (
    <div className={style.all}>
      <Header addUserCompanyContrastCollect={fun} />
      <Table setFun={setFun} />
    </div>
  );
};

export default Index;
