import React, { useEffect, useState } from 'react';
import Table from '../Table';
import UploadingModal from '../UploadingModal';
import { useLazyUploadTestingDataListQuery, useLazySaveReportQuery } from '../../../../../../../service/tool';
import { conditionTestingExport } from '../../../../../../../api/dataDownload';
import { resetTemplateReport } from '../../../../../../../api/reportGeneration';
import { openNewPage } from '../../../../../../../utils/urlMethods';
import style from './index.module.less';

const sortMap = { ascend: 'asc', descend: 'desc' };

const UploadAssessment = ({ uploadRecordId, setUploadRecordId }) => {
  const [sort, setSort] = useState();
  const [pageSize, setPageSize] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableIsFetching, setTableIsFetching] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploadTestingDataList] = useLazyUploadTestingDataListQuery();
  const [saveReportQuery] = useLazySaveReportQuery();

  const saveReport = async () => {
    if (!uploadRecordId) return;
    const { data } = await saveReportQuery({
      upload_record_id: uploadRecordId,
    });
    const res = await resetTemplateReport({
      list_analysis: { query_record_id: data.record_id },
      template_id: '12116179',
      template_source: 2,
    });
    const msg = window.btoa(window.encodeURIComponent(JSON.stringify({
      curTemplateId: 12116179,
      title: '画像报告',
      id: res.data,
      // name: '百度在线网络技术（北京）有限公司',
    })));
    openNewPage('scheme', {
      msg,
    });
  };

  const downloadTable = () => {
    conditionTestingExport({
      upload_record_id: uploadRecordId,
    }, '测评名单');
  };

  // 获取上传评测后的列表
  const getUploadTestingDataList = () => {
    uploadTestingDataList({
      upload_record_id: uploadRecordId,
      sort_value: sort,
      page_size: pageSize,
      page_number: pageNumber,
    }).then(res => {
      setTableData(res.data);
      setTableIsFetching(false);
    });
  };

  useEffect(() => {
    if (uploadRecordId) {
      getUploadTestingDataList();
    }
  }, [pageSize, pageNumber, sort, uploadRecordId]);

  const getUploadRecordId = (id) => {
    setUploadRecordId(id);
    setPageNumber(1);
    localStorage.setItem('uploadRecordId', id);
  };

  // table事件
  const tableEventHandle = (page, p, sortEvnt) => {
    setPageNumber(page.current);
    setPageSize(page.pageSize);
    setSort(sortMap[sortEvnt.order]);
  };

  return (
    <>
      <div className={style.tableBox}>
        <div className={style.tableHeader}>
          <div className={style.left}>
            潜力小巨人企业
            <span>
              {tableData?.count || '-'}
            </span>
            家
          </div>
          <div className={style.right}>
            <button type="button" onClick={() => { setOpen(true); }}>
              文件上传
            </button>
            <button type="button" onClick={downloadTable}>
              名单导出
            </button>
            <button type="button" onClick={saveReport}>
              名单分析
            </button>
          </div>
        </div>
        {
          uploadRecordId ? (
            <Table
              y={550}
              pageNumber={pageNumber}
              pageSize={pageSize}
              data={tableData}
              isFetching={tableIsFetching}
              onChange={tableEventHandle}
            />
          ) : (
            <div className={style.notHave}>
              <button type="button" onClick={() => { setOpen(true); }}>
                文件上传
              </button>
            </div>
          )
        }

      </div>
      <UploadingModal
        setOpen={setOpen}
        setUploadRecordId={getUploadRecordId}
        open={open}
      />
    </>
  );
};

export default UploadAssessment;
