import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import styles from './index.module.less';

SwiperCore.use([Autoplay, Navigation]);

const SwiperBox = (props) => {
  const { swiperData, onSetIndustryId, onSetsetIndustryName, viewAll } = props;
  const [industryCode, setIndustryCode] = useState(swiperData[0]?.tag_code);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const onGetDtat = (val, name) => {
    onSetIndustryId(val);
    onSetsetIndustryName(name);
    setIndustryCode(val);
  };
  useEffect(() => {
    onSetIndustryId(swiperData[0]?.tag_code);
  }, []);
  return (
    <div className={clsx(styles.demo, { [styles.viewAll]: viewAll })}>
      {
        swiperData.length > 8 ? (
          <>
            <div className={clsx(styles.prevBtn, { [styles.isShow]: swiperIndex === 0 })} >
              <div className={styles.prevStyle} />
            </div>
            <div className={clsx(styles.nextBtn, { [styles.isShow]: (swiperData.length - swiperIndex) <= 8 })} >
              <div className={styles.nextStyle} />
            </div>
          </>
        ) : null
      }
      {
        swiperData.length === 0 ? null : (
          <Swiper
            spaceBetween={16}
            slidesPerView={8}
            navigation={{ prevEl: `.${styles.prevBtn}`, nextEl: `.${styles.nextBtn}` }}
            onSlideChangeTransitionEnd={(swiper) => setSwiperIndex(swiper.activeIndex)}
          >
            {swiperData.map((value) => (
              <SwiperSlide
                key={value?.tag_code}
                className={clsx(styles.tagsBox, { [styles.clickStyle]: value?.tag_code === industryCode })}
                onClick={() => onGetDtat(value?.tag_code, value?.tag_name)}
              >
                <div className={styles.overStyle}>{value?.tag_name}</div>
              </SwiperSlide>
            ))}
          </Swiper>
        )
      }
    </div>
  );
};

export default SwiperBox;
