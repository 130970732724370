import React, { useState } from 'react';
import Underline from '../../../../components/Underline';
import EquityCapitalTable from '../EquityCapitalTable';
import ChartTitle from '../ChartTitle';
import BarChart from '../BarChart';
import LineChart from '../LineChart';
import TransBarChart from '../TransBarChart';
import StackBarChart from '../StackBarChart';
import styles from './index.module.less';
import {
  useGetFinancingQuotaStatisticsQuery,
  useGetFinancingProjectQuantityStatisticsQuery,
  useGetFinancingRoundStatisticsQuery,
  useGetFinancingCityNumberStatisticsQuery,
} from '../../../../service/industry-channel';
import { openNewPage } from '../../../../utils/urlMethods';

const colors = [
  '#1961F5',
  '#4E88FF',
  '#59D700',
  '#FDAD61',
  '#FD6205',
  '#631BFF',
];

function EquityCapital({ industryCode }) {
  const [tableState, setTableState] = useState({
    filteredInfo: {},
    sortedInfo: {},
  });

  const { data: barData } = useGetFinancingQuotaStatisticsQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: lineData } = useGetFinancingProjectQuantityStatisticsQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: stackBarData } = useGetFinancingRoundStatisticsQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: transBarData } = useGetFinancingCityNumberStatisticsQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  return (
    <div className={styles.equityCapital}>
      <div className={styles.table}>
        <Underline />
        <EquityCapitalTable
          industryCode={industryCode}
          filteredInfo={tableState.filteredInfo}
          sortedInfo={tableState.sortedInfo}
          onChange={({ current }, filters, sorter) => {
            setTableState(prev => ({
              ...prev,
              filteredInfo: filters,
              sortedInfo: sorter,
            }));
          }}
          onCompanyClick={({ company_id: _id }) => {
            if (!_id) return;
            openNewPage('companyDetail', { id: _id });
          }}
        />
      </div>
      <div className={styles.charts}>
        <div className={styles.top}>
          <div className={styles.chart}>
            <ChartTitle title="融资额度" />
            <div className={styles.module}>
              {
                barData && <BarChart yName="亿元" data={barData?.data} labelRotate={45} unit="亿元" left={40} />
              }
            </div>
          </div>
          <div className={styles.chart}>
            <ChartTitle title="融资事件" />
            <div className={styles.module}>
              {
                lineData && <LineChart data={lineData?.data} labelRotate={45} yName="件" />
              }
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.chart}>
            <ChartTitle title="融资轮次" />
            <div className={styles.module}>
              {
                stackBarData && (
                  <StackBarChart
                    yName="件"
                    labelRotate={45}
                    unit="个"
                    xAxis={stackBarData?.data?.xAxis}
                    series={stackBarData?.data?.yAxis}
                    colors={colors}
                    legendGap={16}
                  />
                )
              }
            </div>
          </div>
          <div className={styles.chart}>
            {
              transBarData && (
                <>
                  <ChartTitle title="区域分布" subtitle={`(${transBarData?.time_scope})`} />
                  <div className={styles.module}>
                    <TransBarChart yName="件" data={transBarData?.data} />
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquityCapital;
