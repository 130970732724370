import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';
import style from './index.module.less';

const color = ['#D5F0FF', '#CBECFE', '#C1E7FB', '#8DCFF8', '#65B7F3', '#3C9AE8', '#177DDC'];
function FunnelChart(props) {
  const { data } = props;
  const _data = cloneDeep(data)?.reverse();
  const [options, setOptions] = useState({});
  const { length: dataLength } = data ?? {};
  const paddingLeft = 35 / dataLength;

  useEffect(() => {
    if (!data.length) return;
    const { length } = data;
    const gap = 100 / length;
    const newData = _data?.map((item, index) => (
      {
        value: index * gap + gap,
        name: item.name,
        amount: item.value,
        itemStyle: {
          borderWidth: 1,
          color: color[index % color.length],
        },
      }
    ));
    const option = {
      tooltip: {
        formatter: params => {
          const str = `<div>${params.marker}${params.name}：${params.data.amount}家</div>`;
          return str;
        },
      },
      series: [
        {
          z: 5,
          type: 'funnel',
          left: 10,
          top: 13,
          bottom: 17,
          width: '50%',
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          sort: 'ascending',
          gap: 0,
          emphasis: {
            label: {
              show: false,
            },
          },
          label: {
            show: false,
          },
          labelLine: {
            length: 0,
          },
          data: newData,
        },
      ],
    };
    setOptions(option);
  }, [data]);

  return (
    <div className={style.all}>
      <ReactEcharts
        option={options}
        notMerge
        style={{ width: '100%', height: '100%' }}
      />
      <div className={style.right}>
        {
          _data.map((item, index) => (
            <div
              className={style.box}
              style={{ paddingLeft: `${(index + 1.5) * paddingLeft}%` }}
              key={index}
            >
              <div className={style.percent}>
                <div className={style.amount}>
                  <span style={{ marginRight: '6px' }}>{item.name}</span>
                  <span>{item.value}</span>
                  家
                </div>
                <div className={style.proportion}>{item.proportion}</div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default FunnelChart;

FunnelChart.defaultProps = {
  data: [],
};
