import React, { memo } from 'react';
import styles from './index.module.less';
import IndustrialProfile from '../IndustrialProfile';
import BenchmarkingAnalysis from '../BenchmarkingAnalysis';
import IndustrialMerits from '../IndustrialMerits';
import IndustrialDistribution from '../IndustrialDistribution';
import IndustryListing from '../IndustryListing';
import PolicyListing from '../PolicyListing';
import IndustryTabs from '../IndustryTab';

const Industry = memo(({
  cityCode,
  cityName,
  onClick,
  industryTabs,
  industryTabName,
  isProvinceDirectly,
  cityGraphGroup,
}) => (
  <div>
    <div className={styles.industryTabs}>
      <IndustryTabs tab={industryTabs} selectedKey={industryTabName} onClick={onClick} />
    </div>
    {
      cityGraphGroup?.includes('cityOverview') && (
        <div id="cityOverview">
          <IndustrialProfile
            name='产业概览'
            cityCode={cityCode}
            cityName={cityName}
          />
        </div>
      )
    }
    {
      cityGraphGroup?.includes('urbanBenchmarking') && (
        <div id="urbanBenchmarking">
          <BenchmarkingAnalysis
            name='城市对标'
            cityCode={cityCode}
            cityName={cityName}
          />
        </div>
      )
    }
    {
      cityGraphGroup?.includes('enterpriseDistribution') && !isProvinceDirectly && (
        <div id="enterpriseDistribution">
          <IndustrialDistribution
            name='企业分布'
            cityCode={cityCode}
            cityName={cityName}
          />
        </div>
      )
    }
    {
      cityGraphGroup?.includes('industryAnalysis') && (
        <div id="industryAnalysis">
          <IndustrialMerits
            name='产业分析'
            cityCode={cityCode}
            cityName={cityName}
          />
        </div>
      )
    }
    {
      cityGraphGroup?.includes('industrialCarrier') && (
        <div id="industrialCarrier">
          <IndustryListing
            name='产业载体'
            cityCode={cityCode}
            cityName={cityName}
          />
        </div>
      )
    }
    {
      cityGraphGroup?.includes('policyList') && (
        <div id="policyList">
          <PolicyListing
            name='政策清单'
            cityCode={cityCode}
            cityName={cityName}
          />
        </div>
      )
    }
  </div>
));

export default Industry;
