/*
 *Author: zhaochenyu
 *Description: 企业轮播表格
 *Date: 2021/09/29
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import CarouselTable from '../../BasicsCompoment/CarouselTable';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessCarouselTableProps } from '../businessType';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-company-carousel';
const styleGrade = classNames(`${prefixCls}-grade`);
const styleSpin = classNames(`${prefixCls}-spin`);
const styleTableTd = classNames(`${prefixCls}-table-td`);

const { useState, useMemo, useEffect, useCallback } = React;

function CompanyCarouselTable(props: BusinessCarouselTableProps) {
  const { mode, ip, conditions, speed = 3000, showAmount = 10, onNameClick } = props;
  const [carouselData, setCarouselData] = useState<any>({});

  const handleWithCompanyHead = useCallback((handleHead: any[]) => (
    handleHead.map(item => {
      let res;
      switch (item.dataIndex) {
        case 'index':
          res = {
            ...item,
            width: '15%',
            align: 'right',
          };
          break;
        case 'name':
          res = {
            ...item,
            width: '45%',
            align: 'left',
            render: (text: string, record: any) => (
              <div
                className={styleTableTd}
                onClick={() => {
                  if (isFunction(onNameClick)) {
                    onNameClick!(record.id);
                  }
                }}
              >
                {text}
              </div>
            ),
          };
          break;
        case 'label':
          res = {
            ...item,
            width: '25%',
            align: 'left',
          };
          break;
        case 'grade':
          res = {
            ...item,
            width: '15%',
            align: 'center',
            render: (text: string) => <div className={styleGrade}>{text}</div>,
          };
          break;
        default:
          res = {
            ...item,
          };
          break;
      }
      return res;
    })), []);

  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}:8902/companyChart`, {
        ...conditions,
      }).then(res => {
        if (res.status === '00000') {
          const { columns } = res.data;
          setCarouselData({
            ...res.data,
            columns: handleWithCompanyHead(columns),
          });
        } else {
          setCarouselData({
            columns: [],
            dataSource: [],
          });
        }
      });
    }
  }, [ip, conditions]);

  const table = useMemo(() => (
    <CarouselTable
      mode={mode || 'dark'}
      speed={speed}
      data={carouselData}
      showAmount={showAmount}
    />
  ), [carouselData, mode, speed, showAmount]);
  return (Object.keys(carouselData).length > 0 ? table : (
    <div className={styleSpin}>
      <Spin tip="加载中..." />
    </div>
  ));
}

export default CompanyCarouselTable;
