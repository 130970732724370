/*
* Author: zhangmeng
* Description: '树选择器'
* Date: 2022/06/22
*/

import React, { useRef, useCallback } from 'react';
import { TreeSelect } from 'antd';
import style from './index.module.less';

const { SHOW_PARENT } = TreeSelect;
function InputTreeSelect(props) {
  const { title, data, select, setSelect, ph } = props;
  const expandedKeys = data.map(item => item.value);
  const curNode = useRef([]);
  const changeSelect = useCallback((value, label, node) => {
    setSelect(value);
    curNode.current = node;
  }, [setSelect]);

  return (
    <div className={style.treeSelect}>
      <div
        className={style.label}
      >
        {title}
      </div>
      <div className={style.select}>
        <TreeSelect
          value={select}
          treeData={(data || [])}
          showCheckedStrategy={SHOW_PARENT}
          placeholder={ph}
          treeCheckable
          treeDefaultExpandedKeys={expandedKeys}
          onChange={changeSelect}
          maxTagCount={2}
          showArrow
          dropdownClassName={style.dropTreeSelect}
        />
      </div>
    </div>
  );
}

export default InputTreeSelect;
