/**
 * 横条图
 */
import React, { useEffect, useState } from 'react';
import style from './index.module.less';

const colorList = [
  '#0048DD',
  '#1961F5',
  '#4E88FF',
  '#6B9BFF',
  '#87AEFE',
  '#87AEFE',
  '#87AEFE',
];

/**
 *
 * @param { Array } data 一个数组套对象的数据
 * @param { Number } height 横条的高度
 * @param { Number } percent 每层横条的相差宽度的百分比
 * @param { Array } color 颜色数组
 * @param { Number } locationIndex 我的位置索引
 * @param { Function } setTop 函数
 * @returns
 */
const TrabeculaCharts = ({ data, height, locationIndex, percent, color = colorList, setTop }) => {
  const [middle, setMiddle] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) {
      setMiddle(Math.ceil(data.length / 2 - 1));
      if (setTop) {
        if (locationIndex === 0 || locationIndex) {
          setTop(locationIndex * height);
        } else {
          setTop((Math.ceil(data.length / 2 - 1)) * height);
        }
      }
    }
  }, [data]);

  // 计算每个图形的宽度
  const calculateWidth = (index) => {
    const location = Math.abs(index - middle);
    const proportion = `${((1 - (percent * location)) * 100).toFixed(2)}%`;
    return proportion;
  };

  return (
    <div className={style.charts}>
      {
        data?.map((item, index) => (
          <div
            key={index}
            title={`${`${item.name} ${item.proportion}`}%`}
            className={style.cell}
            style={{ width: calculateWidth(index), height: `${height}px`, backgroundColor: color[index] }}
          >
            <span>{item.name}</span>
            &nbsp;
            <span>
              {item.proportion}
              %
            </span>
          </div>
        ))
      }
    </div>
  );
};

export default TrabeculaCharts;
