import React from 'react';
import { Tree } from 'antd';
import styles from './index.module.less';

/**
 * 自定义的树形选择器（多选择）
 * @param filters
 * @param setSelectedKeys
 * @param selectedKeys
 * @param confirm
 * @returns {JSX.Element}
 * @constructor
 */
function CustomTreeFilter({
  filters,
  // prefixCls,
  // visible,
  setSelectedKeys,
  selectedKeys,
  confirm,
  multiple,
  // clearFilters,
}) {
  return (
    <div className={styles.filter}>
      <Tree
        checkable={multiple || false}
        defaultExpandedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        checkedKeys={selectedKeys}
        onSelect={keys => {
          setSelectedKeys(keys);
          // 如果想要点击筛选即时生效，不关闭窗口
          // confirm(false);
          // 筛选即时生效，关闭窗口
          // confirm();
          if (multiple) {
            confirm(false);
          } else {
            confirm();
          }
        }}
        onCheck={keys => {
          setSelectedKeys(keys);
          // 如果想要点击筛选即时生效，不关闭窗口
          // confirm(false);
          // 筛选即时生效，关闭窗口
          // confirm();
          if (multiple) {
            confirm(false);
          } else {
            confirm();
          }
        }}
        selectable
        treeData={filters}
        multiple={multiple || false}
        className={styles.tree}
      />
    </div>
  );
}

export default CustomTreeFilter;

// import { Tree } from 'antd';
// import React, { useState } from 'react';

// const treeData = [
//   {
//     title: '0-0',
//     key: '0-0',
//     children: [
//       {
//         title: '0-0-0',
//         key: '0-0-0',
//         disabled: true,
//         children: [
//           {
//             title: '0-0-0-0',
//             key: '0-0-0-0',
//           },
//           {
//             title: '0-0-0-1',
//             key: '0-0-0-1',
//           },
//           {
//             title: '0-0-0-2',
//             key: '0-0-0-2',
//           },
//         ],
//       },
//       {
//         title: '0-0-1',
//         key: '0-0-1',
//         children: [
//           {
//             title: '0-0-1-0',
//             key: '0-0-1-0',
//           },
//           {
//             title: '0-0-1-1',
//             key: '0-0-1-1',
//           },
//           {
//             title: '0-0-1-2',
//             key: '0-0-1-2',
//           },
//         ],
//       },
//       {
//         title: '0-0-2',
//         key: '0-0-2',
//       },
//     ],
//   },
//   {
//     title: '0-1',
//     key: '0-1',
//     children: [
//       {
//         title: '0-1-0-0',
//         key: '0-1-0-0',
//       },
//       {
//         title: '0-1-0-1',
//         key: '0-1-0-1',
//       },
//       {
//         title: '0-1-0-2',
//         key: '0-1-0-2',
//       },
//     ],
//   },
//   {
//     title: '0-2',
//     key: '0-2',
//   },
// ];
// const App = () => {
//   const [expandedKeys, setExpandedKeys] = useState(['0-0-0', '0-0-1']);
//   const [checkedKeys, setCheckedKeys] = useState(['0-0-0']);
//   const [selectedKeys, setSelectedKeys] = useState([]);
//   const [autoExpandParent, setAutoExpandParent] = useState(true);
//   const onExpand = (expandedKeysValue) => {
//     console.log('onExpand', expandedKeysValue);
//     // if not set autoExpandParent to false, if children expanded, parent can not collapse.
//     // or, you can remove all expanded children keys.
//     setExpandedKeys(expandedKeysValue);
//     setAutoExpandParent(false);
//   };
//   const onCheck = (checkedKeysValue) => {
//     console.log('onCheck', checkedKeysValue);
//     setCheckedKeys(checkedKeysValue);
//   };
//   const onSelect = (selectedKeysValue, info) => {
//     console.log('onSelect', info);
//     setSelectedKeys(selectedKeysValue);
//   };
//   return (
//     <Tree
//       checkable
//       onExpand={onExpand}
//       expandedKeys={expandedKeys}
//       autoExpandParent={autoExpandParent}
//       onCheck={onCheck}
//       checkedKeys={checkedKeys}
//       onSelect={onSelect}
//       selectedKeys={selectedKeys}
//       treeData={treeData}
//     />
//   );
// };
// export default App;
