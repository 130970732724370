import React from 'react';
import Banner from './Banner';
import Overview from './Overview';
import Ranking from './Ranking';
import Bottom from './Bottom';
// import Equity from './Equity';
// import Kinship from './Kinship';
import {
  useGetSpecialReportListQuery,
} from '../../../service/special';
import style from './index.module.less';

export default ({ name }) => {
  const reportData = useGetSpecialReportListQuery({
    label: name,
  });
  return (
    <div className={style.box}>
      <div className={style.banner}>
        <Banner name={name} reportData={reportData} />
      </div>
      <div className={style.overview}>
        <Overview name={name} isShowBorder />
      </div>
      <div className={style.ranking}>
        {/* <p className={style.rankingTitle}>榜单</p> */}
        <Ranking name={name} />
      </div>
      {/* <div>s
      <Equity />
    </div> */}
      {/* <Kinship /> */}
      <div>
        <Bottom name={name} reportData={reportData} />
      </div>
    </div>
  );
};
