/*
*Description: 样式文件
*/
import * as CONSTS from '../../consts';

const chartFontSize = {
  small: {
    legnedFontSize: 10,
    labelFontSize: 10,
    axisLabelSize: 10,
  },
  middle: {
    legnedFontSize: 12,
    labelFontSize: 12,
    axisLabelSize: 12,
  },
  large: {
    legnedFontSize: 14,
    labelFontSize: 14,
    axisLabelSize: 14,
  },
};

const modeStyle = {
  light: {
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    color: ['#3E5C76', '#EE964B', '#A4C3B2', '#C5D5EA', '#CCE3DE', '#EAD1C1', '#E9C582', '#CBC7DB', '#EE964B'],
    splitAreaColor: '#F2F7FB',
    splitLineColor: '#e9e9e9',
  },
  dark: {
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    color: ['#00F2FE', '#3E83F7', '#1D16F4', '#5170CA', '#390D97', '#E65226', '#FEC52A'],
    splitAreaColor: '#F2F7FB',
    splitLineColor: '#e9e9e9',
  },
};

export {
  chartFontSize,
  modeStyle,
};
