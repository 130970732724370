import { APIType } from './enum';

export const formatURL = (dataSource: any) => {
  if (!dataSource) return dataSource;
  const { type } = dataSource;
  if (type === APIType.API || type === APIType.API_SELECTION) {
    const { method = 'GET', headers, params = {}, body = {} } = dataSource;
    let url = dataSource.url;
    let paramsOrBodyKvs = Object.entries(method.toUpperCase() === 'GET' ? params : body);
    const omittedParamsOrBody = paramsOrBodyKvs.reduce<{[key: string]: any}>(
      (prev, next) => {
        if (url.indexOf(`:${next[0]}`) !== -1) {
          url = url.replaceAll(`:${next[0]}`, next[1]);
          return prev;
        }
        prev[next[0]] = next[1];
        return prev;
      }, {});

    if (method.toUpperCase() === 'GET') {
      return {
        url,
        type,
        method,
        headers,
        params: omittedParamsOrBody,
      };
    }
    return {
      url,
      type,
      method,
      headers,
      body: omittedParamsOrBody,
    };
  }
  return dataSource;
};
