/**
 * Author: tianye
 * Description: 迁出监测-路径地图,
 * @CyberRouteMap
 * @props
 * Date: 2020/10/10
 */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import ReactEcharts from 'echarts-for-react';
import ReactDOMServer from 'react-dom/server';
import CHINA_INSET_JSON from './100000_inset';
import style from './index.module.less';

// const MAP_NAME = 'china';
// const DEFAULT_ZOOM = 1.0;

const START_POINT_COLOR = '#F3BF00';
const COLORS = ['#59D700', '#FD6205', '#FD6205'].reverse();
const SYMBOL_SIZES = [8, 12, 16, 40];
const ARROW_SIZE = 5;
// const W0RD_DICT = {'institution': '设立分支机构', 'high_tech': '迁入企业'};
const LINE_OPACITY = 0.2;
const SYMBOL_OPACITY = 1;
const NAME_MAP = {
  广西省: '广西壮族自治区',
};

/**
 * @typedef {{
 *   name: string;
 *   code: string;
 *   center: number[];
 *   children: CityItem[];
 * }} CityItem
 */

/**
 * @typedef {{
 *    name: string,
 *    code: string,
 *    center: number[],
 * }} AnchorPoint
 */

/**
 * @type {[]}
 */
const EMPTY_ARR = [];

/**
 * 获取散点数据
 * @param {AnchorPoint} moveInAnchor
 * @param {AnchorPoint} moveOutAnchor
 * @param {CityItem[]} moveIn
 * @param {CityItem[]} moveOut
 * @param {string} [selectedCode]
 * @return {*[]}
 */
const getScatters = (
  { moveInAnchor, moveOutAnchor, moveIn, moveOut } = {},
  selectedCode,
) => {
  const transform = ({ name, code, center, head_office: value, molecular_company: branchValue }, isOut) => ({ name, code, value: [...center, value, branchValue], isOut });
  const arr = [];
  if (moveInAnchor) {
    arr.push(transform(moveInAnchor, false));
  }
  if (moveOutAnchor) {
    arr.push(transform(moveOutAnchor, true));
  }
  /* eslint-disable no-confusing-arrow */
  const extractPoints = (points, isOut) => selectedCode
    ? points.find(item => item.code === selectedCode)?.children.map(item => transform(item, isOut)) ?? []
    : points.map(item => transform(item, isOut));
  if (moveIn) {
    const _moveIn = extractPoints(moveIn, false);
    if (_moveIn) arr.push(..._moveIn);
  }
  if (moveOut) {
    const _moveOut = extractPoints(moveOut, true);
    if (_moveOut) arr.push(..._moveOut);
  }
  return arr;
};

/**
 * 获取迁入数据
 * @param {AnchorPoint} anchor
 * @param {CityItem[]} points
 * @param {string} [selectedCode]
 * @return {[number[], number[]][]}
 */
const getMoveInLinesData = (anchor, points, selectedCode) => {
  if (!anchor || !Array.isArray(points)) {
    return [];
  }
  const _moveIn = selectedCode ? (points.find(item => item.code === selectedCode)?.children ?? []) : points;
  return _moveIn.map(point => ([point.center, anchor.center]));
};

/**
 * 获取迁出数据
 * @param {AnchorPoint} anchor
 * @param {CityItem[]} points
 * @param {string} selectedCode
 * @return {[number[], number[]][]}
 */
const getMoveOutLinesData = (anchor, points, selectedCode) => {
  if (!anchor || !Array.isArray(points)) {
    return [];
  }
  const _moveOut = selectedCode ? (points.find(item => item.code === selectedCode)?.children ?? []) : points;
  return _moveOut.map(point => ([anchor.center, point.center]));
};

/**
 * 返回点的区域统计
 * @param {CityItem} points
 * @return {{min: number, max: number}}
 */
const getStatistics = points => {
  if (points && points.length > 0) {
    const values = points.map(item => item.value);
    return { min: Math.min(...values), max: Math.max(...values) };
  }
  return { min: 0, max: 0 };
};

/**
 * 计算区间序号
 * @param {number} value
 * @param {{max: number, min: number}} statistics
 * @returns {number}
 */
const getFallIndex = (value, statistics) => {
  const sizes = SYMBOL_SIZES.slice(0, SYMBOL_SIZES.length - 1);
  const { length: sizesLength } = sizes;
  const { min, max } = statistics;
  if (max === min) return sizes[sizes.length - 1];
  const gap = (max - min) / sizesLength;
  return Math.floor((value - min) / gap);
};

const getBaseOption = (map, initialSize) => ({
  geo: [
    {
      map,
      zoom: 1.2,
      roam: true, // 是否允许缩放
      scaleLimit: {
        min: 0.8,
        max: 4,
      },
      // zoom: DEFAULT_ZOOM, //默认显示级别
      layoutCenter: ['50%', '50%'],
      layoutSize: initialSize,
      selectedMode: true,
      // 标签，地图上的文字
      label: {
        show: true,
        color: '#000',
      },
      itemStyle: {
        borderColor: '#1192FF',
        borderWidth: 1,
        borderType: 'dashed',
        areaColor: '#BEE1FF',
        shadowColor: 'rgba(17, 146, 255, 0.6)',
      },
      emphasis: {
        label: {
          show: true,
          color: '#000',
        },
        itemStyle: {
          borderColor: '#1192FF',
          borderWidth: 1,
          borderType: 'dashed',
          areaColor: '#BEE1FF',
          shadowColor: 'rgba(17, 146, 255, 0.6)',
        },
      },
      select: {
        label: {
          show: true,
          color: '#000',
        },
        itemStyle: {
          borderColor: '#1192FF',
          borderWidth: 1,
          borderType: 'dashed',
          areaColor: '#BEE1FF',
          shadowColor: 'rgba(17, 146, 255, 0.6)',
        },
      },
      tooltip: {
        show: false,
      },
      nameMap: NAME_MAP,
      z: 1,
    },
  ],
  tooltip: {
    show: true,
    trigger: 'item',
    confine: true,
    position: (point, params, dom, rect, size) => {
      const { contentSize } = size;
      return [point[0] + 30, point[1] - contentSize[1] * 0.5];
    },
    extraCssText: 'background: none; border: none; box-shadow: none',
  },
  series: [
    {
      id: 'shadow',
      type: 'custom',
      coordinateSystem: 'geo',
      geoIndex: 0,
      silent: true,
      renderItem: (params, api) => {
        const index = params.dataIndex;
        params.context.rendered = true;
        const points = [];
        const data = [...CHINA_INSET_JSON.features[index].geometry.coordinates[0][0], ...CHINA_INSET_JSON.features[index].geometry.coordinates[1][0]];
        /* eslint-disable no-plusplus */
        for (let i = 0; i < data.length; i++) {
          points.push(api.coord(data[i]));
        }
        return {
          type: 'polygon',
          shape: {
            points,
          },
          style: {
            fill: '#BEE1FF',
            stroke: 'transparent',
            shadowBlur: 10,
            shadowColor: '#1192FF',
            opacity: 0.5,
          },
        };
      },
      data: CHINA_INSET_JSON.features,
      large: true,
      blendMode: 'source-atop',
      z: 2,
    },
    {
      id: 'scatters',
      type: 'effectScatter',
      coordinateSystem: 'geo',
      // symbol: 'circle',
      tooltip: {
        show: true,
      },
      // symbolSize: 4,
      effectType: 'ripple',
      rippleEffect: {
        scale: 1.8,
        brushType: 'stroke',
      },
      itemStyle: {
        opacity: 0.9,
        shadowColor: 'rgba(0,0,0,0.8)',
        shadowBlur: 24,
        shadowOffsetX: 0,
        shadowOffsetY: 8,
      },
      data: [],
      zlevel: 5,
    },
    {
      id: 'moveIn',
      type: 'lines',
      name: '迁入企业',
      coordinateSystem: 'geo',
      geoIndex: 0,
      symbol: 'none',
      tooltip: {
        show: false,
      },
      effect: {
        show: true,
        period: 5, // 箭头指向速度，值越小速度越快
        trailLength: 0.6, // 特效尾迹长度[0,1]值越大，尾迹越长重
        symbol: 'arrow', // 箭头图标
        symbolSize: ARROW_SIZE, // 图标大小
        color: `rgba(163, 255, 98, ${SYMBOL_OPACITY})`,
      },
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 1,
          x2: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#59D700',
            },
            {
              offset: 1,
              color: '#59D700',
            },
          ],
        },
        type: 'dashed',
        curveness: 0.3,
        opacity: LINE_OPACITY,
        width: 1,
      },
      data: [],
      silent: true,
      large: true,
      zlevel: 3,
    },
    {
      id: 'moveOut',
      type: 'lines',
      name: '迁出企业',
      coordinateSystem: 'geo',
      geoIndex: 0,
      symbol: 'none',
      tooltip: {
        show: false,
      },
      effect: {
        show: true,
        period: 5, // 箭头指向速度，值越小速度越快
        trailLength: 0.6, // 特效尾迹长度[0,1]值越大，尾迹越长重
        symbol: 'arrow', // 箭头图标
        delay: 500, // delay 500ms
        symbolSize: ARROW_SIZE, // 图标大小
        color: `rgba(255, 171, 121, ${SYMBOL_OPACITY})`,
      },
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 1,
          x2: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: '#FD6205',
            },
            {
              offset: 1,
              color: '#FD6205',
            },
          ],
        },
        type: 'dashed',
        curveness: 0.3,
        opacity: LINE_OPACITY,
        width: 1,
      },
      data: [],
      silent: true,
      large: true,
      zlevel: 2,
    },
  ],
  animation: true,
});

/**
 * @typedef {{
 *   actionName: string;
 *   from: string;
 *   to: string;
 *   content?: {name: string; value: string; unit?: string}[]
 * }} action
 */

/**
 * 悬浮窗口
 * @param {boolean} hideContent
 * @param {string} title
 * @param {action[]} actions
 * @return {JSX.Element}
 * @constructor
 */
function Tooltip({
  hideContent,
  title,
  actions,
}) {
  return (
    <div className={style.tooltip}>
      <div className={style.wrapper}>
        <div className={style.main}>
          <div className={style.title}>
            <span className={style.marker} />
            <span className={style.name}>{title}</span>
          </div>
          {
            !hideContent && Array.isArray(actions) && actions.length > 0 && (
              actions.map(({ from, actionName, to, content }) => (
                <div className={style.content}>
                  <div className={style.desc}>
                    <span className={style.special}>{from}</span>
                    <span className={style.action}>{actionName}</span>
                    <span className={style.special}>{to}</span>
                  </div>
                  <div className={style.values}>
                    {
                      Array.isArray(content) && content.map(item => (
                        <div>
                          <span className={style.name}>{item.name}</span>
                          <span className={style.value}>{item.value}</span>
                          <span className={style.unit}>{item.unit}</span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            )
          }
        </div>
      </div>
    </div>
  );
}

/**
 * @param {{
 *   anchor: {
 *     moveIn:AnchorPoint,
 *     moveOut: AnchorPoint,
 *   };
 *   data: {
 *     moveIn: CityItem[],
 *     moveOut: CityItem[],
 *    };
 *   initialSize?: number | string;
 * }} props
 * @return {JSX.Element}
 * @constructor
 */
function RouteMap(props) {
  const { anchor = {}, data, map, initialSize, pageType } = props;
  const { moveIn: moveInAnchor, moveOut: moveOutAnchor } = anchor;
  const { moveIn = EMPTY_ARR, moveOut = EMPTY_ARR } = data ?? {};
  // 直辖市或者省
  const [selectedRegionCode, setSelectedRegionCode] = useState('');
  const [isChartReady, setIsChartReady] = useState(0);
  const echartsRef = useRef(null);

  const option = useMemo(() => getBaseOption(map, initialSize), [map]);

  useEffect(() => {
    if (!isChartReady) return;
    // 如果echarts初始化完毕
    const instance = echartsRef.current?.getEchartsInstance();
    const scatters = getScatters(
      { moveInAnchor, moveOutAnchor, moveIn, moveOut },
      selectedRegionCode,
    );
    const moveInData = getMoveInLinesData(moveInAnchor, moveIn, selectedRegionCode);
    const moveOutData = getMoveOutLinesData(moveOutAnchor, moveOut, selectedRegionCode);
    const moveInStatistics = getStatistics(moveIn);
    const moveOutStatistic = getStatistics(moveOut);
    // 是否需要隐藏内容
    const needHideContent = ({ isOut, code }) => (
      !(!isOut && code !== moveInAnchor?.code) && !(isOut && code !== moveOutAnchor?.code)
    );

    instance.setOption({
      animation: false,
      series: [
        {
          id: 'scatters',
          data: scatters,
          symbol: (_, params) => {
            const { code } = params.data;
            if (code === moveInAnchor?.code || code === moveOutAnchor?.code) {
              return 'pin';
            }
            return 'circle';
          },
          symbolSize: (value, params) => {
            const { data: { code, isOut } } = params;
            if (code === moveInAnchor?.code || code === moveOutAnchor?.code) {
              return SYMBOL_SIZES[SYMBOL_SIZES.length - 1];
            }
            const sizes = SYMBOL_SIZES.slice(0, SYMBOL_SIZES.length - 1);
            const fallIndex = getFallIndex(value[2], isOut ? moveOutStatistic : moveInStatistics);
            return sizes[fallIndex < sizes.length ? fallIndex : (sizes.length - 1)];
          },
          itemStyle: {
            color: params => {
              const { isOut, value, code } = params.data;
              /* eslint-disable no-else-return */
              if (!isOut && code === moveInAnchor?.code) {
                return START_POINT_COLOR;
              } else if (isOut && code === moveOutAnchor?.code) {
                return START_POINT_COLOR;
              }
              // const fallIndex = getFallIndex(value[2], isOut);
              // return COLORS[fallIndex < COLORS.length ? fallIndex : (COLORS.length - 1)];
              return COLORS[isOut ? (COLORS.length - 2) : (COLORS.length - 1)];
            },
          },
        },
        { id: 'moveIn', data: moveInData },
        { id: 'moveOut', data: moveOutData },
      ],
      tooltip: {
        show: true,
        formatter: (params) => {
          const { data: _data } = params;
          const { name, code } = _data;
          const currentScatters = scatters.filter(item => item.code === code);

          // moveInData / moveOutData
          // 1. _moveInItem _moveOutItem 不存在，则只显示名称
          // 2. _moveInItem / _moveOutItem 任一存在，显示对应的窗口 1
          // 3. _moveInItem & _moveOutItem 同时存在，如何显示
          if (currentScatters.every(needHideContent)) {
            return ReactDOMServer.renderToString(
              <Tooltip title={name} hideContent={true} />,
            );
          }

          /* eslint-disable no-shadow */
          const actions = currentScatters.map(({ isOut, name, code, value }) => {
            const hideContent = needHideContent({ isOut, code });
            // const actionName = isOut ? '迁出至' : '迁入至';
            const actionName = '迁至';
            const from = isOut ? moveOutAnchor.name : name;
            const to = isOut ? name : moveInAnchor.name;
            const content = [
              { name: `${pageType === 'moveMonitor' ? '企业数量' : '总部迁入'}`, value: value[2], unit: '家' },
              { name: `${pageType === 'moveMonitor' ? '设立分子公司' : '分子公司迁入'}`, value: value[3], unit: '家' },
            ];
            if (hideContent) return null;
            return {
              actionName,
              from,
              to,
              content,
            };
          }).filter(Boolean);

          return ReactDOMServer.renderToString(
            <Tooltip
              title={name}
              actions={actions}
            />,
          );
        },
      },
    });
  }, [data, moveInAnchor, moveOutAnchor, moveIn, moveOut, selectedRegionCode, isChartReady, pageType]);

  useEffect(() => {
    const { geoJSON } = echarts.getMap(map);
    if (geoJSON) {
      const regionFeature = geoJSON.features.find(feature => feature.properties.id === selectedRegionCode);
      const selectedRegionName = regionFeature?.properties.name;
      const instance = echartsRef.current?.getEchartsInstance();
      if (selectedRegionName) {
        instance.dispatchAction({
          type: 'geoSelect',
          name: selectedRegionName,
        });
      } else {
        instance.dispatchAction({
          type: 'geoUnSelect',
        });
      }
    }
  }, [selectedRegionCode]);

  const onEvents = useMemo(() => ({
    click: e => {
      if (e.componentType === 'geo') {
        const { region: { name } } = e;
        const { geoJSON } = echarts.getMap(map);
        const regionFeature = geoJSON.features.find(feature => feature.properties.name === name);
        setSelectedRegionCode(code => {
          if (regionFeature && code !== regionFeature.id) {
            return regionFeature.id;
          }
          return '';
        });
      }
    },
  }), [map]);

  return useMemo(() => (
    <ReactEcharts
      option={option}
      // onEvents={onEvents}
      ref={echartsRef}
      style={{ width: '100%', height: '100%' }}
      onChartReady={() => {
        setIsChartReady(prev => prev + 1);
      }}
    />
  ), [option, onEvents]);
}

RouteMap.defaultProps = {
  initialSize: '100%',
};

export default RouteMap;
