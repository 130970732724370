/*
 *Author: zhaochenyu
 *Description: 专精特新活动页地图
 *Date: 2022/05/11
*/

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { getAllCity, getAllIndustry } from '../../../../api/knowledgeEngine';
import {
  getLevelList,
  getMapData,
  getAreaTableData,
} from '../../../../api/specialAndNews';
import {
  useLazyGetDistributionOfRegionsQuery,
  useLazyGetDistributionOfIndustryQuery,
  useLazyGetCompanyPyramidSpecializationQuery,
  useLazyGetEstablishAgePieQuery,
  useLazyGetCompanyCapitalDistributeQuery,
} from '../../../../service/special';
import { getScreenLabelList } from '../../../../api/atlasDetail';
import SpecialMap from '../SpecialMap';
import ChartList from '../../components/ChartList';
import SpecialTable from '../SpecialTable';
import style from './index.module.less';

function MapAndTable({ level, setLevel, areaCode, setAreaCode }) {
  const [loadings, setLoadings] = useState([false, false, false, false, false]);
  const [chartsListLoading, setChartsListLoading] = useState([false, false, false, false, false]);
  const [mapData, setMapData] = useState([]);
  const [tableData, setTableData] = useState({});
  const [allCityData, setAllCityData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [labelList, setLabelList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  // const [level, setLevel] = useState('');
  const [barData, setBarData] = useState(null);
  const [funnelData, setFunnelData] = useState(null);
  const [barCrosswiseData, setBarCrosswiseData] = useState(null);
  const [hollowPieData, setHollowPieData] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [drillCityNameList, setDrillCityNameList] = useState({
    '000000': '全国',
  });
  const loadingRef = useRef([false, false, false, false, false]);

  const [getDistributionOfRegionsQuery] = useLazyGetDistributionOfRegionsQuery();
  const [getDistributionOfIndustryQuery] = useLazyGetDistributionOfIndustryQuery();
  const [getCompanyPyramidSpecializationQuery] = useLazyGetCompanyPyramidSpecializationQuery();
  const [getEstablishAgePieQuery] = useLazyGetEstablishAgePieQuery();
  const [getCompanyCapitalDistributeQuery] = useLazyGetCompanyCapitalDistributeQuery();

  // 更新loading 状态
  const update = (num, state = false) => {
    const cloneLoadings = chartsListLoading.slice();
    cloneLoadings.splice(num, 1, state);
    setChartsListLoading(cloneLoadings);
  };

  const drillCityCodeList = useRef([]); // 地区下钻code
  const areaTableCondition = useRef({
    area_key: '000000',
    sort_column: 'count',
    industry_name_list: [],
    select_level: '',
  });
  // 获取专精特新企业区域分布柱状图数据
  const getDistributionOfRegions = () => {
    getDistributionOfRegionsQuery({
      select_level: level,
      area_key: areaCode,
    }).then(res => {
      if (res.status === 'fulfilled') {
        setBarData(res.data);
        update(0);
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        setBarData({});
        update(0);
        setLoadings(cloneDeep(loadingRef.current));
      }
    }).catch(err => {
      update(0);
      setLoadings(cloneDeep(loadingRef.current));
      console.log(err);
    });
  };
  // 获取专精特新企业产业分布
  const getDistributionOfIndustry = () => {
    getDistributionOfIndustryQuery({
      select_level: level,
      area_key: areaCode,
    }).then(res => {
      if (res.status === 'fulfilled') {
        setBarCrosswiseData(res.data || {});
        update(1);
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        update(1);
        setLoadings(cloneDeep(loadingRef.current));
      }
    }).catch(err => {
      update(1);
      setLoadings(cloneDeep(loadingRef.current));
      console.log(err);
    });
  };
  // 获取专精特新金字塔图数据
  const getCompanyPyramidSpecialization = () => {
    getCompanyPyramidSpecializationQuery({
      select_level: level,
      area_key: areaCode,
    }).then(res => {
      if (res.status === 'fulfilled') {
        setFunnelData(res.data || {});
        update(2);
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        update(2);
        setLoadings(cloneDeep(loadingRef.current));
      }
    }).catch(err => {
      update(2);
      setLoadings(cloneDeep(loadingRef.current));
      throw new Error(err);
    });
  };
  // 专精特新成立年限
  const getEstablishAgePie = () => {
    getEstablishAgePieQuery({
      select_level: level,
      area_key: areaCode,
    }).then(res => {
      if (res.status === 'fulfilled') {
        setHollowPieData(res.data || {});
        update(3);
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        update(3);
        setLoadings(cloneDeep(loadingRef.current));
      }
    }).catch(err => {
      update(3);
      setLoadings(cloneDeep(loadingRef.current));
      throw new Error(err);
    });
  };
  // 专精特新企业注册资本
  const getCompanyCapitalDistribute = () => {
    getCompanyCapitalDistributeQuery({
      select_level: level,
      area_key: areaCode,
    }).then(res => {
      if (res.status === 'fulfilled') {
        setPieData(res.data || {});
        update(4);
        setLoadings(cloneDeep(loadingRef.current));
      } else {
        update(4);
        setLoadings(cloneDeep(loadingRef.current));
      }
    }).catch(err => {
      update(4);
      setLoadings(cloneDeep(loadingRef.current));
      throw new Error(err);
    });
  };
  // 获取专精特新图形数据
  const getCharData = () => {
    setChartsListLoading([true, true, true, true, true]);
    getDistributionOfRegions();
    getDistributionOfIndustry();
    getCompanyPyramidSpecialization();
    getEstablishAgePie();
    getCompanyCapitalDistribute();
  };

  const hanldleCondition = useCallback((obj) => {
    areaTableCondition.current = {
      ...areaTableCondition.current,
      ...obj,
    };
    if (areaTableCondition.current.sort_column === '') {
      delete areaTableCondition.current.sort_column;
    }
  }, []);

  // 查询地图数据
  const getAreaMapCount = useCallback(
    (obj) => {
      loadingRef.current[0] = true;
      setLoadings(cloneDeep(loadingRef.current));
      getMapData(obj).then(res => {
        if (res.status === '00000') {
          setMapData(res.data);
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[0] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
    },
    [],
  );

  // 查询表格数据
  const getAreaCompnayCount = useCallback(
    (obj) => {
      loadingRef.current[1] = true;
      setLoadings(cloneDeep(loadingRef.current));
      hanldleCondition(obj);
      getAreaTableData(areaTableCondition.current).then(res => {
        if (res.status === '00000') {
          setTableData(res.data || {});
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        } else {
          loadingRef.current[1] = false;
          setLoadings(cloneDeep(loadingRef.current));
        }
      });
    },
    [],
  );

  useEffect(() => {
    setLoadings([true, true]);
    loadingRef.current = [true, true];
    getAllCity().then(res => {
      if (res.status === '00000') {
        setAllCityData(res.data);
      }
    });
    getAllIndustry().then(res => {
      if (res.status === '00000') {
        setIndustryList([res.data]);
      }
    });
    getLevelList().then(res => {
      if (res.status === '00000') {
        setLevelData(res.data);
      }
    });
    getScreenLabelList().then(res => {
      if (res.status === '00000') {
        setLabelList(res.data);
      }
    });
    getAreaMapCount({
      area_key: '000000',
    });
    getAreaCompnayCount();
  }, []);

  useEffect(() => {
    getCharData();
  }, [level, areaCode]);

  const returnPrevLevel = () => {
    if (drillCityCodeList.current.length) {
      const backCityCode = drillCityCodeList.current.pop();
      setAreaCode(backCityCode);
      getAreaMapCount({
        area_key: backCityCode,
      });
      getAreaCompnayCount({
        area_key: backCityCode,
        sort_column: 'count',
        industry_name_list: [],
        select_level: '',
      });
    }
  };

  const drillMap = useMemo(() => (
    <SpecialMap
      data={mapData}
      title="该区域企业数量"
      mapCode={areaCode}
      unit={mapData.length > 0 ? mapData[0].unit : ''}
      source="special"
      setDrillCityCode={setAreaCode}
      drillCityCodeList={drillCityCodeList}
      setDrillCityNameList={setDrillCityNameList}
      getAreaCompnayCount={getAreaCompnayCount}
      getAreaMapCount={getAreaMapCount}
    />
  ), [setAreaCode, areaCode, getAreaMapCount, getAreaCompnayCount, mapData]);

  const areaTable = useMemo(() => (
    Object.keys(tableData).length > 0 && (
    <SpecialTable
      data={tableData}
      getAreaCompnayCount={getAreaCompnayCount}
      areaCode={areaCode}
      allAreaData={allCityData}
      levelData={levelData}
      labelList={labelList}
      industryList={industryList}
      setLevel={setLevel}
      level={level}
    />)
  ), [tableData, getAreaCompnayCount, areaCode, allCityData, levelData,
    labelList, industryList]);
  return (
    <div style={{ width: '100%' }}>
      <div className={style.mapAndeTable}>
        <div className={style.map}>
          <Spin tip="搜索中，请稍候....." spinning={loadings[0]}>
            <>
              <div className={style.descTitle}>专精特新总览</div>
              <div className={style.mapBox}>
                {drillMap}
              </div>
              <div
                className={style.back}
                onClick={() => returnPrevLevel()}
                style={{ display: drillCityCodeList.current.length > 0 ? '' : 'none' }}
              >
                返回
              </div>
            </>
          </Spin>
        </div>
        <div className={style.table}>
          <div className={style.tableTitle}>
            <p>
              专精特新企业共计
              <span>{tableData?.all_company_count}</span>
              家
            </p>
            <p>
              筛选结果
              <span>{tableData?.selected_company_count}</span>
              家
            </p>
          </div>
          <div className={style.titleBox}>
            <Spin tip="搜索中，请稍候....." spinning={loadings[1]}>
              {areaTable}
            </Spin>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '24px' }}>
        <ChartList
          level={level && level[0] === '国' ? level.slice(0, 3) : level.slice(0, 2)}
          area={drillCityNameList[areaCode]}
          barData={barData}
          funnelData={funnelData}
          hollowPieData={hollowPieData}
          barCrosswiseData={barCrosswiseData}
          pieData={pieData}
          loadings={chartsListLoading}
        />
      </div>
    </div>
  );
}

export default MapAndTable;
