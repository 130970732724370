import { USER, oauthIp, WX, WX2, AUTH } from '../utils/url';
import { get, post, postFormLogin, getSource } from '../utils/request';

// 九鼎
const getHomePageLigin = () => get(`${AUTH}/nine_weighty_auth_token`, {});

// 获取微信二维码
const getWxCode = param => get(`${WX}/code`, {
  ...param,
  client_id: '71541734c44611ecae15fa163e7a6636',
});

// 获取微信token
const getWxToken = param => get(`${WX}/user/token`, {
  ...param,
});

// 解绑微信
const getUnbindWx = () => post(`${WX}/unbind`, {
  client_id: '71541734c44611ecae15fa163e7a6636',
});

// 查询微信绑定状态
const getWxBindStatus = param => get(`${USER}/wx_bind_status`, {
  ...param,
});

// 微信快速绑定账号
const getWxbindUser = param => post(`${WX}/bind/user`, {
  ...param,
});

// 申请试用
const useApplySubmit = (params) => post(`${USER}/apply_for_use_register`, {
  ...params,
});

// 申请使用短信验证码
const userNote = param => post(`${USER}/register_send_note`, {
  ...param,
});

// 手机号登录短信验证码
const getLoginNote = param => post(`${oauthIp}/captcha_sms`, {
  ...param,
});

// 获取token接口
const getToken = param => postFormLogin(`${oauthIp}/token`, {
  ...param,
});
// 钉钉用户
const authorization = param => post(`${oauthIp}/authorization`, {
  ...param,
});

// 退出登录
const logout = param => post(`${oauthIp}/logout`, {
  ...param,
});

// 获取用户资源列表
const getResource = () => getSource(`${USER}/find_role_resource_tree`, {});

// 获取用户信息
const getUserInfo = () => get(`${USER}/find_user_info`, {});

// 更新用户登录状态
const changeUserLoginStatus = () => post(`${USER}/update_user_account_status`, {});

// 获取用户邀请人的二维码
const getInviterUrl = params => get(`${USER}/inviter_url`, {
  ...params,
});

// 获取用户的邀请总人数,付费人数，分成总金额
const getDivideCount = params => get(`${USER}/find_user_invite_pay_divide_count`, {
  ...params,
});

// 获取二维码
const getTicketCode = param => get(`${WX2}/ticket_code`, {
  ...param,
});
// 获取二维码 https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=TICKET
// const getShowqrcode = param => get('https://mp.weixin.qq.com/cgi-bin/showqrcode', {
//   ...param,
// });
// 登录轮训接口
const loginApi = param => post(`${WX2}/login`, {
  ...param,
});
// 绑定用户
const bindUser = param => post(`${WX2}/bind`, {
  ...param,
});
// 查询绑定状态
const queryBindStatus = param => post(`${WX2}/bind_status`, {
  ...param,
});
// 查询绑定状态
const wxUnbind = param => post(`${WX2}/unbind`, {
  ...param,
});

export {
  getWxCode,
  changeUserLoginStatus,
  getWxBindStatus,
  getWxbindUser,
  getUnbindWx,
  getWxToken,
  getLoginNote,
  getToken,
  logout,
  userNote,
  getResource,
  getUserInfo,
  useApplySubmit,
  getHomePageLigin,
  getInviterUrl,
  getDivideCount,
  getTicketCode,
  loginApi,
  bindUser,
  queryBindStatus,
  wxUnbind,
  authorization,
  // getShowqrcode,
};
