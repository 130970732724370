import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Popconfirm, message } from 'antd';
import { Table } from '@sqke/design';
import { useGetAppraisalRecordQuery, useLazyDeleteRecordQuery } from '../../../../service/tool';
import TableNoData from '../../../../components/IndustryCompanyDetail/CompanyContent/TableNoData';
import style from './index.module.less';

const infoKeyMap = {
  companyId: 'company_id',
  patent: 'invent_patent',
  practicalPatent: 'practical_patent',
  designPatent: 'design_patent',
  copyright: 'software_copyright',
  YFRS: 'development_proportion',
  cnExpenditure: 'cn_expenditure',
  serviceCharge: 'service_charge',
  grossRevenue: 'gross_revenue',
  intellectualProperty: 'intellectual_property',
};

const EvaluationRecord = () => {
  const [deleteRecord] = useLazyDeleteRecordQuery();
  const params = new URLSearchParams(window.location.href.split('?')[1]);
  const goParams = new URLSearchParams();
  goParams.set('name', params.get('name'));
  goParams.set('id', params.get('id'));
  const history = useHistory();
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  // 获取记录列表
  const { isFetching, data, refetch } = useGetAppraisalRecordQuery({ page_size: pageSize, page_number: pageNumber });
  const { data_list: dataSource, count } = data || {};
  // 删除记录
  const deleteRecordFun = (id) => {
    deleteRecord({ record_id: id }).then(res => {
      const { isError } = res;
      if (!isError) {
        refetch();
        message.info('删除成功');
      }
    });
  };

  const pagination = {
    total: count || 0,
    current: pageNumber,
    pageSize,
    showQuickJumper: true,
    onChange: (page2, pageSize2) => {
      setPageSize(pageSize2);
      setPageNumber(page2);
    },
  };

  const returnInfo = (content) => {
    const infoData = cloneDeep(content);
    Object.keys(infoKeyMap).forEach(item => {
      if (infoData[infoKeyMap[item]]) {
        infoData[item] = infoData[infoKeyMap[item]];
        delete infoData[infoKeyMap[item]];
      }
    });
    if (infoData.violation === false && infoData.violation !== undefined) {
      infoData.violation = '否';
    } else if (infoData.violation) {
      infoData.violation = '是';
    }
    return infoData;
  };

  // 返回重新评测
  const goToDetailsPage = (content) => {
    localStorage.setItem('companyInfoForm', JSON.stringify(returnInfo(content)));
    history.push({
      pathname: '/tool/companyTest/companyInfo',
      search: params.toString(),
    });
  };

  // 查看详情
  const viewDetails = (content) => {
    localStorage.setItem('companyInfoForm', JSON.stringify(returnInfo(content)));
    history.push({
      pathname: '/tool/companyTest/testResult',
      search: goParams.toString(),
    });
  };

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 80,
    },
    {
      title: '企业名称',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 564,
    },
    {
      title: '高企评分',
      dataIndex: 'score',
      key: 'score',
      width: 184,
    },
    {
      title: '时间',
      dataIndex: 'update_time',
      key: 'tiupdate_timeme',
      width: 222,
    },
    {
      title: '操作',
      dataIndex: 'active',
      key: 'active',
      render: (text, row) => (
        <div className={style.active}>
          <button type="button" onClick={() => { viewDetails({ ...row.content, company_id: row.company_id }); }}>查看</button>
          <button type="button" onClick={() => { goToDetailsPage({ ...row.content, company_id: row.company_id }); }}>重新测评</button>
          <Popconfirm placement="top" title="是否确认删除" onConfirm={() => { deleteRecordFun(row.id); }} okText="是" cancelText="否">
            <button type="button">删除</button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className={style.evaluationRecord}>
      <header>
        <div>
          <div className={style.left}>
            <p>测评记录</p>
            <p>
              共搜索到
              {count || '-'}
              条数据
            </p>
          </div>
        </div>
        <button
          type="button"
          className={style.out}
          onClick={() => {
            history.push({
              pathname: '/tool/companyTest/companyInfo',
              search: params.toString(),
            });
          }}
        >
          <p />
          <p>返回</p>
        </button>
      </header>
      <div className={style.table}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          loading={isFetching}
          pagination={pagination}
          locale={{ emptyText: () => {
            if (!dataSource) {
              return '';
            }
            return <TableNoData />;
          } }}
        />
      </div>
    </div>
  );
};

export default EvaluationRecord;
