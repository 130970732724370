import { IModelMiddleware } from '../interface/base';
import { merge } from 'lodash';

const getDatasetOption = (dataset: any) => {
  if (!dataset) return {};
  return {
    dataset,
  };
};

export const dataset: IModelMiddleware = (option, material) => {
  return merge(
    option,
    getDatasetOption(material.data?.dataset),
  );
};
