/**
 * 产业频道页面相关接口
 */
import { apiSlice } from '../slices/api';

const PREFIX = '/industryChannel';

export const industryChannelApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getChannelIndustryInfo: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_basic_info`,
          params: arg,
        };
      },
    }),
    getChannelIndustryLink: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_link`,
          params: arg,
        };
      },
    }),
    getChannelLinkCompanyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_link_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getChannelProvinceCompanyCount: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/province_company_count`,
          params: arg,
        };
      },
    }),
    getChannelAreaDistributionCompanyRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/area_distribution_company_rank`,
          params: arg,
        };
      },
    }),
    // 榜单-上奇推荐
    getChannelCompanyScoreRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/company_score_rank`,
          params: arg,
        };
      },
    }),
    // 榜单-专利榜单
    getChannelCompanyPatentRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/patent_num_score_rank`,
          params: arg,
        };
      },
    }),
    // 投资价值榜单
    getChannelInvestmentWorthRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/investment_value_rank_list`,
          params: arg,
        };
      },
    }),
    // 专精特新潜力企业
    getChannelSRDIRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/specialization_rank_list`,
          params: arg,
        };
      },
    }),
    // 产业频道-数据模块统计
    getChannelDataStatistic: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/data_statistical`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 数据模块 ————————————
    // ———————————— 数据模块-企业 ————————————
    // 获取企业表格的筛选清单
    getChannelCompanyTags: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/get_company_type_list`,
          method: 'post',
          body: arg,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    // 获取企业表格
    getChannelDataCompanyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 根据企业ID获取其专利清单
    getChannelCompanyPatentList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_patent_list_by_company_id`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 数据模块-政策 ————————————
    // 政策层级
    getChannelPolicyLevels: builder.query({
      query() {
        return {
          url: `${PREFIX}/policy_level_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getChannelPolicyTypeList: builder.query({
      query() {
        return {
          url: `${PREFIX}/policy_type_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getChannelPolicySupportWayList: builder.query({
      query() {
        return {
          url: `${PREFIX}/policy_support_way_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getChannelPolicySupportObjectList: builder.query({
      query() {
        return {
          url: `${PREFIX}/policy_support_object_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getChannelPolicyDeclareList: builder.query({
      query() {
        return {
          url: `${PREFIX}/policy_declare_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getChannelPolicyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_policy_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 数据模块-舆情 ————————————
    getChannelNewsColorList: builder.query({
      query() {
        return {
          url: `${PREFIX}/news_color_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getChannelNewsTypeList: builder.query({
      query() {
        return {
          url: `${PREFIX}/news_type_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getChannelNewsSourceList: builder.query(({
      query() {
        return {
          url: `${PREFIX}/news_source_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    })),
    getChannelNewsList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_news_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 数据模块-专利 ————————————
    getChannelPatentList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_patent_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 数据模块-研报 ————————————
    getChannelReportTypeList: builder.query(({
      query() {
        return {
          url: `${PREFIX}/report_type_select_list`,
          method: 'post',
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    })),
    getChannelReportList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_report_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 数据模块-投资 ————————————
    getChannelInvestmentRoundList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/investment_round_select_list`,
          method: 'post',
          body: arg,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getChannelInvestmentList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_investment_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 数据模块-企业的专利清单 ————————————
    getCompanyPatentList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_patent_list_by_company_id`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 产业图谱-产业总览 ————————————
    getOverviewCompanyNumber: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_overview_company_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getOverviewCompanyCapitalDistribute: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_overview_company_capital_distribute`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getOverviewIndustryPyramid: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_overview_industry_pyramid`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getOverviewPatentType: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_overview_patent_type`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getOverviewStandardType: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_overview_standard_type`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getOverviewInstitutionsType: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_overview_institutions_type`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 产业图谱-股权融资 ————————————
    getFinancingList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getFinancingQuotaStatistics: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_quota_statistics`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getFinancingProjectQuantityStatistics: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_project_quantity_statistics`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getFinancingRoundStatistics: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_round_statistics`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getFinancingCityNumberStatistics: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_city_number_statistics`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 产业图谱-投资机构 ————————————
    getInvestmentInstitutionsChart: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_investment_institutions_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getInvestmentInstitutionsSumNumber: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_investment_institutions_sum_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getInvestmentEventTable: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_investment_event_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getInvestmentInstitutionsTable: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/equity_financing_investment_institutions_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // ———————————— 产业图谱-招商图谱 ————————————
    getChinaMerchantsMap: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/china_merchants_map`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getChinaMerchantsObjectRecommended: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/china_merchants_object_recommended`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

/**
 * @typedef {Object} TableQueryParam
 * @param {number} page_size
 * @param {number} page_number
 */

export const {
  useGetChannelIndustryLinkQuery,
  /**
   * 获取产业链公司名单（按环节）
   * @type {(arg: {industry_code: string, link_code: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelLinkCompanyListQuery,
  /**
   * 获取产业分布
   * @type {(arg: {industry_code: string, area_key: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelProvinceCompanyCountQuery,
  /**
   * 获取产业分布排行榜单
   * @type {(arg: {industry_code: string, area_key: string, sort_column: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelAreaDistributionCompanyRankQuery,
  /**
   * 获取上奇推荐榜单
   * @type {(arg: {industry_code: string, sort_column: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelCompanyScoreRankQuery,
  /**
   * 获取专利榜单
   * @type {(arg: {industry_code: string, sort_column: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelCompanyPatentRankQuery,
  /**
   * 获取专精特新榜单
   * @type {(arg: {industry_code: string, sort_column: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelSRDIRankQuery,
  /**
   * 获取投资价值榜单
   * @type {(arg: {industry_code: string, sort_column: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelInvestmentWorthRankQuery,
  /**
   * 获取产业信息
   * @type {(arg: {industry_code: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelIndustryInfoQuery,
  /**
   * 获取数据模块统计
   * @type {(arg: {industry_code: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelDataStatisticQuery,
  /**
   * 获取可选资质类别清单
   * @type {(arg?: undefined opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelCompanyTagsQuery,
  /**
   * 获取数据模块企业列表
   * @type {(arg: {
   *   industry_code: string, keyword?: string, sort_key?: string, sort_value?: string, company_type?: string[],
   *   page_size: number, page_number: number;
   * }, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelDataCompanyListQuery,
  /**
   * 获取数据模块企业的专利列表
   * @type {(arg: {
   *   company_id: string;
   *   page_size: number;
   *   page_number: number;
   * }, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelCompanyPatentListQuery,
  // ———————————— 数据模块-政策 ————————————
  useGetChannelPolicyLevelsQuery,
  useGetChannelPolicyTypeListQuery,
  useGetChannelPolicySupportWayListQuery,
  useGetChannelPolicySupportObjectListQuery,
  useGetChannelPolicyDeclareListQuery,
  /**
   * 获取数据模块政策的列表
   * @type {(arg: {
   *   page_size: number;
   *   page_number: number;
   * }, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelPolicyListQuery,
  // ———————————— 数据模块-舆情 ————————————
  useGetChannelNewsColorListQuery,
  useGetChannelNewsTypeListQuery,
  useGetChannelNewsSourceListQuery,
  /**
   * 获取数据模块舆情的列表
   * @type {(arg: {
   *   page_size: number;
   *   page_number: number;
   * }, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelNewsListQuery,
  // ———————————— 数据模块-专利 ————————————
  useGetChannelPatentListQuery,
  // ———————————— 数据模块-研报 ————————————
  useGetChannelReportTypeListQuery,
  /**
   * 获取数据模块研报的列表
   * @type {(arg: {
   *   page_size: number;
   *   page_number: number;
   * }, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelReportListQuery,
  // ———————————— 数据模块-投资 ————————————
  useGetChannelInvestmentRoundListQuery,
  /**
   * 获取数据模块投资的列表
   * @type {(arg: {
   *   page_size: number;
   *   page_number: number;
   * }, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetChannelInvestmentListQuery,
  // ———————————— 数据模块-企业的专利清单 ————————————
  /**
   * 获取数据模块投资的列表
   * @type {(arg: {
   *   page_size: number;
   *   page_number: number;
   * }, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetCompanyPatentListQuery,
  // ———————————— 产业图谱-产业总览 ————————————
  useGetOverviewCompanyNumberQuery,
  useGetOverviewCompanyCapitalDistributeQuery,
  useGetOverviewIndustryPyramidQuery,
  useGetOverviewPatentTypeQuery,
  useGetOverviewStandardTypeQuery,
  useGetOverviewInstitutionsTypeQuery,
  // ———————————— 产业图谱-股权融资 ————————————
  useGetFinancingListQuery,
  useGetFinancingQuotaStatisticsQuery,
  useGetFinancingProjectQuantityStatisticsQuery,
  useGetFinancingRoundStatisticsQuery,
  useGetFinancingCityNumberStatisticsQuery,
  // ———————————— 产业图谱-投资机构 ————————————
  useGetInvestmentInstitutionsChartQuery,
  useGetInvestmentInstitutionsSumNumberQuery,
  useGetInvestmentEventTableQuery,
  useGetInvestmentInstitutionsTableQuery,
  // ———————————— 产业图谱-招商图谱 ————————————
  useGetChinaMerchantsMapQuery,
  useGetChinaMerchantsObjectRecommendedQuery,
} = industryChannelApi;
