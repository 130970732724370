/**
 * 负责处理组件出错的情况
 */

import { ComponentType, ReactElement } from 'react';
import { merge } from 'lodash';

export interface WithErrorCatchProps {
  renderOnError?: (e: any, item: any) => ReactElement;
  [key: string]: any;
}

export function withErrorCatch<T extends WithErrorCatchProps>(WrappedComponent: ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  const Component: ComponentType<T>  = (props: T) => {
    const { renderOnError } = props;
    try {
      return <WrappedComponent {...merge({}, props) as T} />;
    } catch (e) {
      if (renderOnError) {
        return renderOnError(e, props) ?? null;
      }
      console.error(e);
      return (
        <div className={props.className} style={props.style}>
          组件渲染出错了！
        </div>
      );
    }
  };

  Component.displayName = `withErrorCatch(${displayName})`;

  return Component;
}
