/**
 * 产业频道报告相关接口
 */
import { apiSlice } from '../slices/api';

const PREFIX = '/fineSystemReport';

export const findSystemReportApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSystemReportCategory: builder.query({
      query() {
        return {
          url: `${PREFIX}/find_report_category`,
        };
      },
      providesTags: ['USER', 'REPORT'],
      keepUnusedDataFor: 0,
    }),
    getSystemReportList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_report_list_on_industry_channel`,
          params: arg,
        };
      },
      // TODO: 失效报告列表
      providesTags: ['USER', 'REPORT'],
      keepUnusedDataFor: 0,
    }),
    getCitySystemReportList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_report_list_on_city_channel`,
          params: arg,
        };
      },
      providesTags: ['USER', 'REPORT'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  /**
   * 获取报告类别
   * @type {(arg?: undefined, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetSystemReportCategoryQuery,
  /**
   * 获取报告列表
   * @type {(arg: {industry_code: string, category_name?: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetSystemReportListQuery,
  /**
   * 获取城市频道报告列表
   * @type {(arg: {industry_code: string, category_name?: string}, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetCitySystemReportListQuery,
} = findSystemReportApi;
