import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import Table from '../../../../../../components/Table';
import styles from './index.module.less';

export default function ModalTable({ state, title, columns, toCity, toEnterprise, isFetching, setPageState }) {
  const {
    companyName,
    cityName,
    inCompany,
    inCity,
    inScale,
    type,
    outCompany,
    outCity,
    outScale,
    pageNumber: current,
  } = state;
  const [modalTitle, setModalTitle] = useState('');
  const [describe, setDescribe] = useState('');

  useEffect(() => {
    let newTitle = '';
    let newDescribe = '';
    if (title) {
      if (title === '流入TOP10企业') {
        newTitle = type.title.slice(0, -3);
        newDescribe = newTitle === '流入城市'
          ? `资本流入${companyName}的城市共<span class=${styles.num}>${inCity}</span>个，流入规模<span class=${styles.num}>${(inScale - 0)?.toLocaleString()}</span>万元`
          : `资本流入${companyName}的企业共<span class=${styles.num}>${inCompany}</span>家，流入规模<span class=${styles.num}>${(inScale - 0)?.toLocaleString()}</span>万元`;
      } else if (title === '流出TOP10企业') {
        newTitle = type.title.slice(0, -3);
        newDescribe = newTitle === '流出城市'
          ? `${companyName}资本流出城市共<span class=${styles.num}>${outCity}</span>个，流出规模<span class=${styles.num}>${(outScale - 0)?.toLocaleString()}</span>万元`
          : `${companyName}资本流出企业共<span class=${styles.num}>${outCompany}</span>家，流出规模<span class=${styles.num}>${(outScale - 0)?.toLocaleString()}</span>万元`;
      } else if (title === '流入TOP10城市') {
        newTitle = `资本流入${cityName}企业`;
        newDescribe = `资本流入${cityName}的企业共<span class=${styles.num}>${inCompany}</span>家，流入规模<span class=${styles.num}>${(inScale - 0)?.toLocaleString()}</span>万元`;
      } else {
        newTitle = `${cityName}资本流出企业`;
        newDescribe = `${cityName}资本流出的企业共<span class=${styles.num}>${outCompany}</span>家，流入规模<span class=${styles.num}>${(outScale - 0)?.toLocaleString()}</span>万元`;
      }
    }
    setModalTitle(newTitle);
    setDescribe(newDescribe);
  }, [title]);

  return (
    <div className={styles.modalContent}>
      <div className={styles.title}>{modalTitle}</div>
      <div className={styles.describe} dangerouslySetInnerHTML={{ __html: describe }} />
      <div className={styles.sperateLine}></div>
      <Spin spinning={isFetching}>
        <Table
          columns={columns}
          hideOnSinglePage={true}
          dataSource={type?.title?.indexOf('企业') > -1 ? toEnterprise : toCity}
          // pageSize={10}
          // current={current}
          // onChange={(page) => {
          //   console.log(111);
          //   setPageState((prev) => ({
          //     ...prev,
          //     pageNumber: page,
          //   }));
          // }}
          noShowJumper
        />
      </Spin>
    </div>
  );
}
