/*
 *Description: data校验函数
 *Date: Tue Nov 30 2021 15:22:16 GMT+0800 (中国标准时间)
*/

export function checkData(data: any) {
  const ans: any[] = [];
  if (!data) {
    data = [];
  }
  // eslint-disable-next-line no-restricted-syntax
  data.forEach((item: any) => {
    if (item.name) {
      ans.push(item);
    }
  });
  return ans;
}
