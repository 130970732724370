/*
* Author: zhangmeng
* Description: ''
* Date: 2022/06/29
*/

import React, { useRef, useEffect } from 'react';
import { Input } from 'antd';
import style from './index.module.less';

function SingleInput(props) {
  const { name, value, changeInput, ph } = props;
  const firstLoad = useRef(false);

  useEffect(() => {
    // if (firstLoad.current) {
    //   const obj = {};
    //   obj[condKey] = value;
    //   onChange(obj);
    // } else {
    //   firstLoad.current = true;
    // }
  }, [value]);

  return (
    <div className={style.singleInput}>
      <div className={style.label}>
        <div className={style.point} />
        {name}
      </div>
      <div className={style.inputs}>
        <Input value={value} onChange={(e) => changeInput(e.target.value)} style={{ width: '373px' }} placeholder={ph} />
      </div>
    </div>
  );
}

export default SingleInput;
