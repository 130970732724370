import React, { forwardRef, LegacyRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';

import './index.less';
import '../../index.less';
import html2canvas from 'html2canvas';
import { addWaterMark, getDefaultCanvas } from '../../utils/tool';
import { NormalChartProps } from '../basicsType';

const prefixCls: string = 'cube-component-normal-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);
const tableWraper = classNames(`${prefixCls}-table-wraper`);
const cell = classNames(`${prefixCls}-cell`);
const title = classNames(`${prefixCls}-title`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
} as any;

const NormalChart = forwardRef((props: NormalChartProps, ref: any) => {
  const {
    mode = 'dark',
    data,
  } = props;
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef = useRef<HTMLElement>({} as HTMLElement);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setChartLoading(false);
  }, []);

  useImperativeHandle(ref, () => ({
    getCanvas() {
      // const instance = chartRef.current;
      // return html2canvas(instance as HTMLElement).then(
      //   (canvas) => (canvas),
      // ).catch(ex => {
      //   console.log('normalChart', ex);
      // });
      if (!data?.values.length) {
        const instance = chartRef.current;
        const clientRect = instance.getBoundingClientRect();
        return new Promise(res => res(
          (getDefaultCanvas(clientRect.width, clientRect.height)),
        ));
      }
      return new Promise(res => res(document.createElement('canvas')));
    },
    toDataURL(devicePixelRatio = 1) {
      const instance = chartRef.current;

      if (!data?.values?.length) {
        const clientRect = instance.getBoundingClientRect();
        return new Promise(res => res(
          (getDefaultCanvas(clientRect.width, clientRect.height)),
        ));
      }
      if (props.isSpanChart) {
        return html2canvas(instance as HTMLElement).then(
          (canvas) => (canvas),
        ).catch(ex => {
          console.log('normalChart', ex);
          return document.createElement('canvas');
        }).then(canvas => addWaterMark(canvas, devicePixelRatio));
      }

      return new Promise(res => res(addWaterMark(document.createElement('canvas'), devicePixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: (
          props.isSpanChart ? bodyRef.current.scrollHeight : bodyRef.current.clientHeight
        ) + 20,
      };
    },
  }));

  if (data?.values?.length === 1) {
    // 只有一行就是表头 就是没数据
    // eslint-disable-next-line no-unused-expressions
    data?.values.pop();
  }
  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {
        chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)
      }

      {
        !chartLoading && (!data?.values?.length) && (
          <div className={styleEmpty}>暂无数据</div>
        )
      }

      <div
        className={tableWraper}
        ref={chartRef as LegacyRef<HTMLDivElement>}
        style={data?.containerStyle}
      >
        {
          data?.values?.map((item, _index) => (
              item?.map((innerItem, innIndex) => (
                <div
                  className={`${cell} ${_index === 0 ? title : ''}`}
                  style={{ ...innerItem.style, transform: 'none', flex: 'initial' }}
                  key={_index + innIndex}
                >
                  <div
                    style={{ transform: innerItem.style.transform, flex: innerItem.style.flex }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: innerItem.text }}
                  />
                </div>
              ))
          ))
        }
      </div>
    </div>
  );
});
NormalChart.defaultProps = {
  data: {
    containerStyle: {},
    values: [],
  },
};
export default NormalChart;
