import { IGaugeVisualConfig } from '../interface/config';
import { omitUndefined } from './utils';
import { ISeriesGetter, SeriesTypes } from '../interface/base';

export const getSeriesGauge: ISeriesGetter<IGaugeVisualConfig> = (series, index, extra) => {
  const type: SeriesTypes = 'gauge';
  const {
    material,
    getMatchedItemStyle,
  } = extra;
  const { config } = material;
  const { visualConfig } = config;
  const {
    gauge = {},
  } = visualConfig as IGaugeVisualConfig;

  return omitUndefined({
    ...gauge,
    ...series,
    // 确保只存留一个数据
    data: series.data.slice(0, 1),
    type,
  });
};
