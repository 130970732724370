import { Matcher } from '../lib/echarts-option/interface/matchers';
import { buildJSONLogic } from '../lib/expression';
import jsonLogic, { RulesLogic } from 'json-logic-js';
import { merge } from 'lodash';

/**
 * 将 分析引擎中 的表达式转为 ECOptionEx 中适用的 jsonLogic Matchers
 * @param matchers
 */
export const buildMatchers = (matchers: Matcher[] | undefined) => {
  return matchers
    ? matchers
      .map(({ rules, result }) => {
        const r = rules
          .map(rule => {
            return buildJSONLogic(rule.conditions, rule.key);
          })
          .reduce<RulesLogic<any>>((prev, next) => {
            return { 'and': [prev, next] } as RulesLogic<any>;
          }, true);

        return {
          rules: r,
          result,
        };
      })
    : [];
}

/**
 * 为每个数据项调用匹配规则（很耗性能！）
 * @param jsonLogicRules
 * @param dataItem
 */
export const applyMatchers = (jsonLogicRules: {rules: RulesLogic<any>, result: any}[], dataItem: any) => {
  if (jsonLogicRules.length === 0) {
    return {};
  }
  const matchResult = jsonLogicRules
    .filter(({ rules }) => {
      return jsonLogic.apply(rules, dataItem);
    })
    .map(item => item.result);
  return merge({}, ...matchResult);
}
