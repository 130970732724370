import React, { useEffect, useRef, useState, useCallback, useMemo, useImperativeHandle, forwardRef } from 'react';
import QRcode from 'qrcode';
import classNames from 'classnames';
import { message, Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../hooks/url';
import {
  getCityListCanBuy,
  getBuyCityQrCode,
  getCityTradeOrderStatus,
  paySQcoinCity,
} from '../../../../api/mySelf';
import style from './index.module.less';

function CityChannelSimple(props, channelModalRef) {
  const { onClose, paySuccessCb, defaultSelectCity } = props;

  const history = useHistory();
  const query = useQuery();

  const [amount, setAmount] = useState({
    amount: '0',
    discount: '0',
  });
  const [loading, setLoading] = useState(false);
  const [expire, setExpire] = useState(false);
  const [active, setActive] = useState('');
  const [channelTree, setChannelTree] = useState([]);
  const [cityTree, setCityTree] = useState([]);
  const [sqcoin, setSqcoin] = useState(0);

  const channelData = useRef([]);
  const orderId = useRef(null);
  const timer = useRef(null);
  const timer2 = useRef(null);
  const expiredRef = useRef(null);

  useImperativeHandle(channelModalRef, () => (
    {
      loading,
    }
  ));

  const getCodeList = () => {
    const arr = [];
    channelData.current.forEach(item => {
      arr.push(item.key);
    });
    return arr;
  };

  const getOrderCurStatus = useCallback(() => {
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    const codeList = getCodeList();
    getCityTradeOrderStatus({
      order_id: orderId.current,
      city_code_list: codeList,
    }).then(res => {
      if (res.status === '00000') {
        Modal.success({
          centered: true,
          content: <div style={{ fontSize: '20px', lineHeight: 1 }}>已成功支付</div>,
          okText: '去查看用户权益',
          keyboard: false,
          maskStyle: {
            background: 'transparent',
          },
          onOk: () => {
            paySuccessCb(codeList);
            onClose();
          },
          onCancel: () => {
            paySuccessCb(codeList);
            onClose();
          },
          width: 450,
        });
      } else if (!expiredRef.current) {
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    }).catch(() => {
      if (!expiredRef.current) {
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    });
  }, [paySuccessCb, channelData.current]);

  const getQrCode = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    const codeList = getCodeList();
    setLoading(true);
    getBuyCityQrCode({
      city_code_list: codeList,
    }).then(res => {
      if (res.status === '00000') {
        orderId.current = res.data.order_id;
        QRcode.toCanvas(res.data.code_url, { width: 100, height: 100, margin: 1.5 }, (err, canvas) => {
          if (err) {
            console.log(err);
          }
          const contanier = document.getElementById('channelQrcode');
          if (contanier.childNodes[0]) {
            contanier.removeChild(contanier.childNodes[0]);
          }
          contanier.appendChild(canvas);

          const _amount = {
            amount: res.data.amount ?? '0',
            discount: res.data.discount ?? '0',
            goodsSQcoin: res.data.goods_sq_coin ?? '0',
          };
          setAmount(_amount);
          setLoading(false);
          setExpire(false);
          setSqcoin(res.data.user_sq_coin ?? 0);
          expiredRef.current = false;
          timer2.current = setTimeout(() => {
            getOrderCurStatus();
          }, 1000);
          timer.current = setTimeout(() => {
            setExpire(true);
            expiredRef.current = true;
          }, 120 * 1000);
        });
      }
    });
  }, [channelData.current]);

  const getCityList = useCallback(() => {
    getCityListCanBuy().then(res => {
      if (res.status === '00000') {
        const { data } = res;
        if (Array.isArray(data) && data.length > 0) {
          if (channelData.current.length === 0) {
            channelData.current.push({
              title: data[0].children[0]?.title,
              key: data[0].children[0]?.key,
            });
          }
          setChannelTree(data);
          setActive(data[0].key);
          setCityTree(data[0].children);
          getQrCode();
        } else {
          setChannelTree([]);
        }
      }
    });
  }, []);

  const selectChanne = (item) => {
    if (loading) return;
    if (channelData.current.findIndex((e) => item.key === e.key) !== -1) {
      message.warning('您已选择了该城市！');
      return;
    }
    channelData.current.push({
      key: item.key,
      title: item.title,
    });
    getQrCode();
  };

  const deleteCity = (item) => {
    if (loading) return;
    const len = channelData.current.length;
    if (len < 2) {
      message.warning('请至少选择一个城市！');
      return;
    }
    const index = channelData.current.findIndex((e) => item.key === e.key);
    channelData.current.splice(index, 1);
    getQrCode();
  };

  const paySQcoin = useCallback(() => {
    if (sqcoin >= amount?.goodsSQcoin) {
      const codeList = getCodeList();
      paySQcoinCity({
        city_code_list: codeList,
      }).then(res => {
        if (res.status === '00000') {
          Modal.success({
            centered: true,
            content: <div style={{ fontSize: '20px', lineHeight: 1 }}>已成功支付</div>,
            okText: '去查看用户权益',
            keyboard: false,
            maskStyle: {
              background: 'transparent',
            },
            onOk: () => {
              paySuccessCb(codeList);
              onClose();
            },
            onCancel: () => {
              paySuccessCb(codeList);
              onClose();
            },
            width: 450,
          });
        }
      });
    } else {
      message.warning('上奇币不足，请获取！');
    }
  }, [channelData, sqcoin, amount]);

  useEffect(() => {
    getCityList();
    if (defaultSelectCity) {
      channelData.current.push({
        title: defaultSelectCity.title,
        key: defaultSelectCity.key,
      });
    }
    return () => {
      expiredRef.current = true;
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  return (
    <>
      <div className={style.channel}>
        <div className={style.groupCityBody}>
          {
            channelTree.length > 0 && channelTree.map(item => (
              <div
                className={classNames(style.formatItem, { [style.active]: item.key === active })}
                key={item.key}
                onClick={() => {
                  setActive(item.key);
                  setCityTree(item.children);
                }}
              >
                {item.title}
              </div>
            ))
          }
        </div>
        {
          useMemo(() => (
            <div className={style.cityBody}>
              {
                cityTree.length > 0 && cityTree.map(item => (
                  <div
                    className={style.childItem}
                    key={item.key}
                    onClick={() => selectChanne(item)}
                  >
                    {item.title}
                  </div>
                ))
              }
            </div>
          ), [cityTree])
        }
        <div className={style.selectCity}>
          <div>
            已选
            {channelData.current.length ?? 0}
            个城市：
          </div>
          {
            channelData.current.length > 0 && channelData.current.map(item => (
              <div key={item.key} className={style.cityItem}>
                {item.title}
                <CloseOutlined className={style.close} onClick={() => deleteCity(item)} />
              </div>
            ))
          }
        </div>
      </div>
      <div className={style.payPanel}>
        <div className={style.wxPay}>
          <div className={style.left}>
            <div className={style.firstLine}>付款方式</div>
            <div className={style.secondLine}>
              <div className={style.wxIcon} />
              微信
            </div>
          </div>
          <div className={style.right}>
            <div className={style.payPanelText}>
              <div className={style.limitOffer}>
                限时优惠
              </div>
              <div
                className={style.firstLine}
                style={{ fontSize: amount.amount.toString().length > 6 ? 12 : '' }}
              >
                ￥
                {amount.amount}
              </div>
              <div className={style.secondLine}>
                城市：
                {channelData.current.length ?? 0}
                个
              </div>
              {
                amount.discount && amount.discount !== '0' && (
                  <div
                    className={style.discount}
                    style={{ fontSize: amount.discount.toString().length > 6 ? 12 : '' }}
                  >
                    <span>
                      原价：￥
                    </span>
                    <span
                      className={style.through}
                    >
                      {amount.discount}
                      <svg viewBox="0 0 60 18" preserveAspectRatio="none" className={style.lineThrough}>
                        <path d="M 1 1 L 59 17 z" stroke="currentColor" strokeWidth="2" />
                      </svg>
                    </span>
                  </div>
                )
              }
            </div>
            <div className={style.qrcode}>
              <div className={classNames(style.qrcodeCover, { [style.hide]: !loading && !expire })} onClick={() => getQrCode()}>
                {loading ? '加载中...' : '已失效'}
              </div>
              <div
                className={style.qrcodePicture}
                id="channelQrcode"
              />
              <div className={style.refresh} onClick={() => getQrCode()}>刷新二维码</div>
            </div>
          </div>
        </div>
        <div className={style.shangqiPay}>
          <div className={style.left}>
            <div className={style.firstLine}>付款方式</div>
            <div className={style.secondLine}>
              <div className={style.sqIcon} />
              上奇币
            </div>
          </div>
          <div className={style.right}>
            <div className={style.firstLine}>
              城市：
              {channelData.current.length ?? 0}
              个
            </div>
            <Button loading={!amount.goodsSQcoin} className={style.secondLine} onClick={() => paySQcoin()}>
              {amount.goodsSQcoin}
              上奇币
            </Button>
            {
              sqcoin >= amount?.goodsSQcoin && (
                <div className={style.thirdLine}>
                  当前余额
                  {sqcoin}
                  上奇币
                </div>
              )
            }
            {
              amount?.goodsSQcoin > sqcoin && (
                <div className={style.fourthLine}>
                  {/* 当前余额
                  {sqcoin}
                  上奇币， */}
                  <span
                    className={style.toget}
                    onClick={() => {
                      if (onClose) {
                        onClose();
                      }
                      query.set('tab', '个人中心');
                      query.set('detail', 'achieve');
                      history.push({ pathname: '/accountSetting', search: query.toString() });
                    }}
                  >
                    转发好友
                  </span>
                  赠上奇币，免费开通频道
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(CityChannelSimple);
