import React, { useEffect, useState } from 'react';
import { Input, Modal, message, Button, Pagination } from 'antd';
import classNames from 'classnames';
import blueIcon from '../../../../assets/images/dataAnalysisTool/downloadList/blueIcon.png';
import orangeIcon from '../../../../assets/images/dataAnalysisTool/downloadList/orangeIcon.png';
import greenIcon from '../../../../assets/images/dataAnalysisTool/downloadList/greenIcon.png';
import confirmIcon from '../../../../assets/images/dataAnalysisTool/myReport/confirmIcon.png';
import {
  useLazyGetDownloadReportListQuery,
  useLazyGetReportDeleteQuery,
} from '../../../../service/dataAnalysisTool';
import styles from './index.module.less';

const { Search } = Input;

const tabList = [
  {
    key: '全部',
    value: '全部',
  },
  {
    key: '私密',
    value: '私密',
  },
  {
    key: '公开',
    value: '公开',
  },
];

// item.authority, item.user_name, userName
const handleIcon = (authority, authors, currentUser) => {
  if (authority === '私密') {
    return orangeIcon;
  }
  if (authors?.split(',')?.filter(i => i === currentUser)?.length > 0) {
    return greenIcon;
  }
  return blueIcon;
};

const handleTag = (authority) => {
  if (authority === '') return '公开';
  return '私密';
};

export default function DownloadList() {
  const userName = localStorage.getItem('ph');
  const [selectTab, setSelectTab] = useState('全部');
  const [searchValue, setSearchValue] = useState('');
  const [selectId, setSelectId] = useState();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [params, setParams] = useState({
    keyword: '',
    page: 1,
    size: 10,
  });
  const [downloadList, setDownloadList] = useState([]);
  const [total, setTotal] = useState(0);

  const [getDownloadReportList] = useLazyGetDownloadReportListQuery();
  const [getReportDelete] = useLazyGetReportDeleteQuery();

  // 下载报告列表接口
  const fetchDownloadReportList = async () => {
    const { keyword, page, size } = params;
    const res = await getDownloadReportList({
      keyword,
      report_publicity_type: selectTab,
      page_size: size,
      page_number: page,
    });
    if (res.status === 'fulfilled') {
      setDownloadList(res.data?.dataSource);
      setTotal(res.data?.count || 0);
    }
  };

  // 点击删除按钮
  const handleDelete = (key) => {
    setSelectId(key);
    setIsDeleteOpen(true);
  };

  // 点击下载按钮
  const handleDownLoad = (node) => {
    if (node.pdf_link) {
      // 下载
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = node.pdf_link;
      document.body.appendChild(link);
      link.click();
    }
  };

  // 关闭删除确认弹窗
  const handleDeleteClose = () => {
    setIsDeleteOpen(false);
    setSelectId();
  };

  // 删除弹窗确认
  const handleDeleteConfirm = async () => {
    setIsDeleteOpen(false);
    const res = await getReportDelete({ report_id: selectId });
    if (res.status === 'fulfilled') {
      message.success('删除成功');
      fetchDownloadReportList();
    } else {
      message.error('删除失败');
    }
  };

  useEffect(() => {
    if (params) {
      fetchDownloadReportList();
    }
  }, [params, selectTab]);

  return (
    <div className={styles.downloadList}>
      <div className={styles.searchBox}>
        <div className={styles.title}>查报告</div>
        <Search
          placeholder='请输入您要查询的报告名称'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(e) => setParams(prev => ({ ...prev, keyword: e }))}
        />
      </div>
      <div className={styles.listBox}>
        <div className={styles.tabList}>
          {
            tabList && tabList.map(item => (
              <div
                className={classNames(styles.tabItem, { [styles.tabActive]: item.key === selectTab })}
                key={item.key}
                onClick={() => {
                  setSelectTab(item.key);
                  setParams({
                    keyword: '',
                    page: 1,
                    size: 10,
                  });
                  setDownloadList([]);
                }}
              >
                {item.value}
              </div>
            ))
          }
        </div>
        <div className={styles.list}>
          {
            downloadList?.length > 0 && downloadList.map(item => (
              <div className={styles.listItem} key={item.id}>
                <div className={styles.itemLeft}>
                  <img className={styles.cardIcon} src={handleIcon(item.authority, item.user_name, userName)} alt="" />
                  <div className={styles.content}>
                    <div className={styles.name}>
                      {item.report_name}
                      <span
                        className={
                          classNames(
                            styles.tag,
                            { [styles.greenTag]: item.authority === '公开' && item.user_name?.split(',')?.filter(i => i === userName)?.length > 0 },
                            { [styles.blueTag]: item.authority === '公开' && !(item.user_name?.split(',')?.filter(i => i === userName)?.length > 0) },
                            { [styles.orangeTag]: item.authority === '私密' },
                          )
                        }
                      >
                        {item.authority}
                      </span>
                    </div>
                    <div className={styles.details}>
                      <span>{item.user_name}</span>
                      <span>{item.time}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.itemRight}>
                  {
                    (item.user_name?.split(',')?.filter(i => i === userName)?.length > 0) && (
                      <div className={styles.operateBox} onClick={() => handleDelete(item.id)}>
                        <div className={styles.deleteIcon}></div>
                      </div>
                    )
                  }
                  <div className={styles.operateBox} onClick={() => handleDownLoad(item)}>
                    <div className={styles.downloadIcon}></div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className={styles.pagination}>
          <div className={styles.total}>{`共 ${total} 份报告`}</div>
          <Pagination
            current={params.page}
            pageSize={params.size}
            total={total}
            onChange={(page) => setParams(prev => ({ ...prev, page }))}
            showSizeChanger={false}
          />
        </div>
      </div>
      <Modal
        title={
          <div className={styles.deleteTitle}>
            <img src={confirmIcon} alt="" />
            是否确认删除报告？
          </div>
        }
        footer={null}
        open={isDeleteOpen}
        onCancel={handleDeleteClose}
        width={360}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.deleteContent}>
          <div className={styles.text}>删除报告后该报告报不可找回</div>
          <div className={styles.buttonBox}>
            <Button onClick={handleDeleteClose} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={handleDeleteConfirm}>
              确认
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
