/*
 *Author: zhaochenyu
 *Description: 投资事件详情页
 *Date: 2021/08/03
*/

import React, { useState, useEffect, useMemo } from 'react';
import { IconFont, tabMap } from '../../utils/mapIcon';
import DetailTop from './DetailTop';
import InvestmentOrganization from './InvestmentOrganization';
import InvestmentHistory from './InvestmentHistory';
import IndustryTrend from './IndustryTrend';
import NewsInfomation from '../IndustryCompanyDetail/CompanyContent/News/NewsInfomation';
import { investmentEventBaseInfo, exitTheTrend, industryPrivateTrend } from '../../api/InvestmentDetail';
import style from './index.module.less';

function InvestmentEventDetail(props) {
  const { detailParams } = props;
  const { href } = window.location;
  const [info, setInfo] = useState({});
  const [companyId, setCompanyId] = useState(null);
  const [releaseTrend, setReleaseTrend] = useState({});
  const [investmentTrend, setInvestmentTrend] = useState({});
  const params = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);

  useEffect(() => {
    investmentEventBaseInfo({
      investment_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setInfo(res.data);
        setCompanyId((res.data && res.data.company_id) || '');
      }
    });

    exitTheTrend({
      investment_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setReleaseTrend(res.data);
      }
    });

    industryPrivateTrend({
      investment_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setInvestmentTrend(res.data);
      }
    });
  }, []);

  const top = useMemo(() => (
    Object.keys(info).length > 0 && <DetailTop data={info} />
  ), [info]);

  const industryTrend = useMemo(() => (
    (Object.keys(investmentTrend).length > 0 || Object.keys(releaseTrend).length > 0)
    && <IndustryTrend releaseTrend={releaseTrend} investmentTrend={investmentTrend} />
  ), [investmentTrend, releaseTrend]);

  return (
    <div className={style.all}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.title}>
            <span className={style.before}>
              产业通
            &emsp;
              <span className={style.point} />
            &emsp;
            </span>
            <span className={style.later}>投资</span>
          </div>
          <div className={style.imgBox}>
            <IconFont type={tabMap.financing} />
          </div>
        </div>
      </div>
      {top}
      <InvestmentOrganization id={detailParams || params.get('id')} />
      <InvestmentHistory id={detailParams || params.get('id')} />
      {companyId && companyId !== -1 && <NewsInfomation parent="最新动态" companyId={companyId} />}
      {industryTrend}
    </div>
  );
}

export default InvestmentEventDetail;
