export default (canvasRef, name, textWidth = 200, textHeight = 6) => {
  const img = new Image();
  // pieMyChart1 要导出的图表
  img.src = canvasRef.current.getEchartsInstance().getDataURL({
    type: 'png',
    pixelRatio: 1, // 放大2倍
    backgroundColor: '#fff',
  });
  img.onload = function () {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    // 添加文字
    const text = '数据来源：上奇产业通';
    // 设置字体和大小
    ctx.font = '600 14px PingFangSC, PingFang SC';
    // 设置文本颜色
    ctx.fillStyle = '333333';
    //  设置文本
    ctx.fillText(text, canvas.width - textWidth, canvas.height - textHeight);
    const dataURL = canvas.toDataURL('image/png');

    const a = document.createElement('a');
    const event = new MouseEvent('click');
    a.download = `${name}.png` || '图表.png';
    // 将生成的URL设置为a.href属性
    a.href = dataURL;
    // 触发a的单击事件
    a.dispatchEvent(event);
    a.remove();
  };
};
