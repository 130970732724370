import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import style from './index.module.less';
import Underline from '../../../../../../../components/Underline';

const Index = (props) => {
  const { addUserCompanyContrastCollect, industryList } = props;
  const history = useHistory();
  const currentParam = props.location.search;
  return (
    <div className={style.box}>
      <div className={style.topBox}>
        <div className={style.leftBox}>
          <div className={style.cityTitle}>城市对比对标</div>
          <div className={style.industryBox}>
            <div className={style.nameBox}>产业</div>
            <div className={style.industryBoxStyle} />
            <div className={style.industryTitle}>
              {
                industryList.length > 0
                  ? industryList.map((item, index) => {
                    if (index === 7) {
                      return (<span>...</span>);
                    }
                    if (index > 7) {
                      return null;
                    }
                    return (
                      <>
                        <span key={index}>{item.label}</span>
                        {industryList.length - 1 === index ? null : <span>、</span>}
                      </>
                    );
                  })
                  : <span>全部产业</span>
              }
            </div>
          </div>
        </div>
        <div className={style.rightBox}>
          <div className={style.collectBox}>
            <button type="button" onClick={addUserCompanyContrastCollect}>收藏</button>
          </div>
          <div className={style.moreCollectBox}>
            <button type="button" onClick={() => { history.push(`/tool/city/comparison/table${currentParam}`); }}>查看更多收藏</button>
          </div>
        </div>
      </div>
      <Underline />
    </div>
  );
};

export default withRouter(Index);
