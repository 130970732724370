const ACCESS_TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'rft';
const LOGIN_QUERY_ID_KEY = 'login_query_id';
const USER_KEY = 'userName';

/**
 * 存储access_token，第二个参数配置是否存储localStorage
 * @param {string} token
 * @param {boolean?} isPermanent
 */
const setAccessToken = (token, isPermanent) => {
  if (isPermanent) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  } else {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }
};

/**
 * 存储access_token，第二个参数配置是否存储localStorage
 * @param {string} token
 * @param {boolean?} isPermanent
 */
const setRefreshToken = (token, isPermanent) => {
  if (isPermanent) {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  } else {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }
};

/**
 * 获取access_token
 * @return {string|undefined}
 */
const getAccessToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  return accessToken ?? undefined;
};

/**
 * 获取refresh_token
 * @return {string|undefined}
 */
const getRefreshToken = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)
    || localStorage.getItem(REFRESH_TOKEN_KEY);
  return refreshToken ?? undefined;
};

const isTokenPermanent = () => {
  const permanentValue = localStorage.getItem(ACCESS_TOKEN_KEY);
  return typeof permanentValue === 'string';
};

/**
 * 清理access_token
 */
const clearAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

/**
 * 清理refresh_token
 */
const clearRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

/**
 * 获取登录查询ID
 * @return {string}
 */
const getLoginQueryId = () => localStorage.getItem(LOGIN_QUERY_ID_KEY);

/**
 * 设置登录查询ID
 * @param {string} id
 */
const setLoginQueryId = id => {
  localStorage.setItem(LOGIN_QUERY_ID_KEY, id);
};

/**
 * 清除登录查询ID
 */
const clearLoginQueryId = () => {
  localStorage.removeItem(LOGIN_QUERY_ID_KEY);
};

const getUserName = () => {
  return localStorage.getItem('userName');
};

export {
  getAccessToken,
  getRefreshToken,
  clearAccessToken,
  clearRefreshToken,
  setAccessToken,
  setRefreshToken,
  isTokenPermanent,
  setLoginQueryId,
  getLoginQueryId,
  clearLoginQueryId,
  getUserName,
};
