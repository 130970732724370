import { AnyChartConfig } from '../interface/config';
import { IModelFunction, IModelMiddleware } from '../interface/base';

export const getTooltipOption: IModelFunction = (raw: AnyChartConfig) => {
  const { visualConfig } = raw;
  const {
    tooltip,
  } = visualConfig;

  if (!tooltip) {
    return {
      tooltip: {
        show: true,
      },
    };
  }
  return {
    tooltip: {
      ...tooltip,
    },
  };
}

export const tooltip: IModelMiddleware = (option, material) => {
  option.tooltip = getTooltipOption(material.config, material.data).tooltip;
  return option;
}
