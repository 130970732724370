import React from 'react';
import styles from './index.module.less';

function ChartTitle({ title, subtitle }) {
  return (
    <div className={styles.chartTitle}>
      <span>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  );
}

export default ChartTitle;
