import { Tree } from 'antd';
import { cloneDeep } from 'lodash';

const barCrosswiseOption = (data) => ({
  yAxis: {
    axisLine: {
      show: false, // 不显示坐标轴线
    },
    // name: '时间',
    type: 'category',
    data: cloneDeep(data?.x)?.reverse(),
  },
  tooltip: {
    axisPointer: {
      z: 1000,
    },
  },
  grid: {
    top: 20,
    right: 30,
    bottom: 10,
    left: 10,
  },
  xAxis: {
    type: 'value',
    name: '家',
    // 隐藏 x 轴
    show: false,
  },
  series: [
    {
      type: 'bar',
      barCategoryGap: '50%',
      coordinateSystem: 'cartesian2d',
      data: cloneDeep(data?.series[0]?.data)?.reverse(),
      // 隐藏坐标轴
      label: {
        show: true,
        position: 'right',
        valueAnimation: true,
      },
      itemStyle: {
        color: '#44C4F7',
      },
    },
  ],
});

const generateColumns = ({ item, areaFilter, setIndustryCode, setRegionCode, industryList, industryCode, setPage }) => {
  const onSelectRegion = (selectedKeys, info) => {
    setPage(1);
    setRegionCode(...selectedKeys);
  };
  const selectTreeKey = (selected) => {
    setPage(1);
    setIndustryCode(selected);
  };
  return {
    index: {
      ...item,
      width: 70,
      key: item?.dataIndex,
      ellipsis: true,
    },
    company_name: {
      ...item,
      key: item?.dataIndex,
      ellipsis: true,
    },
    industry_tag: {
      ...item,
      key: item?.dataIndex,
      ellipsis: true,
      filterDropdown: () => (
        <div style={{ maxHeight: '300px', overflow: 'auto' }}>
          <Tree
            onCheck={selectTreeKey}
            checkedKeys={industryCode}
            treeData={industryList}
            checkable
            defaultExpandedKeys={industryList.length > 0 ? [industryList[0].key] : []}
          />
        </div>
      ),
    },
    location: {
      ...item,
      key: item?.dataIndex,
      ellipsis: true,
      filterDropdown: () => (
        <div style={{ maxHeight: '300px', overflow: 'auto' }}>
          <Tree
            defaultExpandedKeys={areaFilter.length > 0 ? [areaFilter[0].key] : []}
            onSelect={onSelectRegion}
            treeData={areaFilter}
          />
        </div>
      ),
    },
  };
};

const mapConfig = {
  mapType: 'Gaode',
  logoVisible: false,
  mapOptions: {
    zoom: 2.8,
    token: '31e1ee90b55cae0838e65a2da758d2bd',
    style: 'light',
    center: [116.211004, 39.912758],
  },
};
const mapOptions = {
  autoFit: false,
  shape: {
    field: 'shape',
    value: ({ shape }) => shape,
    scale: { type: 'quantile' },
  },
  size: {
    field: 'size',
    value: ({ size }) => size,
  },
  color: {
    field: 'color',
    value: ({ color }) => color,
  },
  state: {
    active: true,
  },
  style: {
    opacity: 1,
    // stroke: 'rgba(16, 181, 162, 0.8)',
    // strokeWidth: 4,
    // strokeOpacity: 0.5,
    // blur: 4,
    // blur
  },
  // animate: {
  //   enable: true,
  //   rings: 2,
  // },
};
// 地图上的颜色
const colorData = {
  总部: '#FFF278',
  一级子公司: '#54A4FF',
  二级子公司: '#28DEC9',
  三级子公司: '#D52FD3',
};
export {
  barCrosswiseOption,
  mapConfig,
  mapOptions,
  colorData,
  generateColumns,
};
