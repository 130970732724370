import { ISankeySymbolConfig } from '../interface/config';
import { getLabel, omitUndefined } from './utils';
import { ISeriesGetter } from '../interface/base';
// import { merge } from 'lodash';

export const getEach = (series: any) => {
  const {
    label,
    lineStyle,
  } = series;

  return omitUndefined({
    label: getLabel(label),
    lineStyle,
  });
}

export const getSeriesSankey: ISeriesGetter<ISankeySymbolConfig> = (series, index, extra) => {
  const {
    material,
  } = extra;
  const { config, data: _data } = material;
  const { visualConfig } = config;

  return omitUndefined({
    ...series,
    ...getEach(visualConfig),
  })
};
