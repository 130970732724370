import { ReactNode, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { Macro } from '@sqke/parser';
import { applyMacro } from '../../../hoc/withMacro';
import { SchemeRenderContext } from '../../../context/scheme';
import { selectAll } from '../../../reducer';
import { convertHeading } from '../../../utils';

interface TableOfContentsProps {
  context?: any;
  [key: string]: any;
}

/**
 * 目录最外层容器
 * @param children
 * @constructor
 */
const Page = ({ children, className, style }: { children: ReactNode, [key: string]: any }) => (
  <div className={clsx('sqke-toc', className)} style={style}>{children}</div>
);

/**
 * 目录标题
 * @param children
 * @constructor
 */
const CatalogueTitle = ({ children }: { children: string }) => (
  <div className="sqke-toc-title">{children}</div>
);

/**
 * 目录
 * @param props
 * @constructor
 */
function TableOfContents(props: TableOfContentsProps) {
  const {
    macro,
    className,
    style,
  } = props;
  const [state] = useContext(SchemeRenderContext);
  const context = useMemo(() => {
    return selectAll(state).map(item => ({...item.component as any, ...item.metadata}))
  }, [state]);

  const headings = useMemo(() => {
    if (!Array.isArray(context)) {
      return [];
    }
    const map = new Map<string, number>();
    const path: any[] = [];
    const _headings = context.filter((item: any) => item.type === 'heading')
    return _headings.map(item => {
      path[item.properties.level - 1] = item.properties.text;
      map.set(item.properties.level, (map.get(item.properties.level) ?? 0) + 1);
      for (let i = item.properties.level + 1; i <= 6; i++) {
        map.set(i, 0);
        path[i - 1] = undefined;
      }
      const count = map.get(item.properties.level) as number;
      // path

      return {
        ...item,
        text: convertHeading(count, item.properties.level) + applyMacro(item.properties, new Macro(macro)).text,
        path: path.filter(Boolean).join('-'),
      }
    });
  }, [context]);

  const scrollPage = (path: string) => {
    const dom = document.getElementById(path);
    window?.scrollTo({
      top: dom?.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <Page>
      <CatalogueTitle>目录</CatalogueTitle>
      {Array.isArray(headings) && (
        headings.map((item) => (
          <div
            className={`sqke-toc-heading${item.properties.level}`}
            data-level={item.properties.level}
            key={item.path}
          >
            <a onClick={() => scrollPage(item.path)}>
              {item.text}
            </a>
          </div>
        ))
      )}
    </Page>
  );
}

export default TableOfContents;
