/*
 *Author: zhaochenyu
 *Description: 首页-我的关注
 *Date: 2022/06/23
*/

import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { openNewPage } from '../../../utils/urlMethods';
import { addAttentionData } from '../../../api/companyDetail';
import style from './index.module.less';

function FocusList(props) {
  const { data, setLoginShow, getMyFocusList } = props;
  const defaultFocus = (companyId) => {
    addAttentionData({
      attention_data_type_name: '企业',
      attention_data_id: companyId,
      attention_group_id_list: [1],
      is_empty_original_data: true,
    }).then(res => {
      if (res.status === '00000') {
        message.success('关注成功！');
        getMyFocusList();
      }
    });
  };
  const getWord = (list) => {
    const word = `发生了${list.reduce((pre, cur) => {
      let a = pre;
      a += cur.count;
      return a;
    }, 0)}条动态变更，包括${list.map((item2) => `${item2.name}(${item2.count})`)}`;
    return word;
  };
  return (
    <div className={style.all}>
      {data.map(((item, index) => (
        <div
          className={style.dataItem}
          key={`${index}focus`}
        >
          <div className={style.top}>
            <div
              className={style.companyName}
              onClick={() => {
                if (!localStorage.getItem('token')) {
                  setLoginShow(true);
                } else {
                  openNewPage('companyDetail', { id: item.id });
                }
              }}
            >
              {item.name}
            </div>
            <div
              className={style.isFocus}
              onClick={() => {
                if (!localStorage.getItem('token')) {
                  setLoginShow(true);
                }
              }}
            >
              <div
                className={item.attention ? style.focusedImg : style.focusImg}
                onClick={() => defaultFocus(item.id)}
              >
              </div>
              {item.attention ? '已关注' : '关注'}
            </div>
          </div>
          <div className={style.bottom}>
            <div className={style.desc} title={getWord(item.desc)}>
              发生了
              <span className={style.allCount}>
                {item.desc.reduce((pre, cur) => {
                  let a = pre;
                  a += cur.count;
                  return a;
                }, 0)}
              </span>
              条动态变更，包括
              {item.desc.map((item2, index2) => `${item2.name}(${item2.count})${index2 === item.desc.length - 1 ? '' : '、'}`)}
            </div>
            <div className={style.date}>{item.date}</div>
          </div>
        </div>
      )))}
    </div>
  );
}

export default FocusList;
