import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

/**
 * 产业频道标题
 * @param {string} title
 * @returns {JSX.Element}
 * @constructor
 */
function Title({ title, subtitle, className }) {
  return (
    <div className={classnames(styles.title, className)}>
      <span>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  );
}

export default Title;
