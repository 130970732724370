/*
*Description: Scatter业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ScatterChart from '../../BasicsCompoment/ScatterChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessScatterChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessScatterChart(props: BusinessScatterChartProps) {
  const {
    mode, label, legend = true, ip, conditions,
    onRequestComplete, title, componmentKey,
    handleItemStyle, fontSize,
    imgRef,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data;
          setData(resData.data);
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
         onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <ScatterChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            label,
            legend,
            ref: imgRef,
            chartDesc,
            fontSize,
            handleItemStyle,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BusinessScatterChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
};

// 11 . --综合行业地位
export function CommonChartmultiDimensionalComprehensiveIndustryGraph(
  props: BusinessScatterChartProps,
) {
  const componmentKey = {
    function_key: 'multi_dimensional_comprehensive_industry_graph',
    class_name: '/chart/company',
  };
  function handleItemStyle(dataItem: any) {
    const style = {
      color: dataItem.is_red ? '#EE964B' : '#4E7293',
    };
    return style;
  }
  return (
    <BusinessScatterChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
      handleItemStyle={handleItemStyle}
    />);
}

// 30 . --上市企业经营情况
export function CompanyChartmarketCompanyBusinessCircumstanceGraph(
  props: BusinessScatterChartProps,
) {
  const componmentKey = {
    function_key: 'market_company_business_circumstance_graph',
    class_name: '/chart/company',
  };
  const colors = ['#4E7293', '#73B28F', '#9070AA', '#C54646', '#EE964B'];
  const cL = colors.length;
  const colorMap = new Map();
  let colorIndex = 0;
  function handleItemStyle(dataItem: any) {
    let color = colorMap.get(dataItem.block);
    if (!color) {
      color = colors[colorIndex % cL];
      colorIndex += 1;
      colorMap.set(dataItem.block, color);
    }
    const style = {
      color,
    };
    return style;
  }
  return (
    <BusinessScatterChart
      {...props}
      componmentKey={componmentKey}
      legend
      handleItemStyle={handleItemStyle}
    />);
}

// 国家级专精特新企业授权发明专利及国家标准排名
export function CommonChartPatentStandardRankSpecialization(
  props: BusinessScatterChartProps,
) {
  const componmentKey = {
    function_key: 'patent_standard_rank_specialization',
    class_name: '/chart/common',
  };
  function handleItemStyle(dataItem: any) {
    const style = {
      color: dataItem.is_red ? '#EE964B' : '#4E7293',
    };
    return style;
  }
  return (
    <BusinessScatterChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
      handleItemStyle={handleItemStyle}
    />);
}
