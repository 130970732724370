import React from 'react';
import classnames from 'classnames';
import { Input as AntInput } from 'antd';
import styles from './index.module.less';
import { IconFont } from '../../../../utils/mapIcon';

const { Search } = AntInput;

/**
 * 输入框
 * @param {'large' | 'middle' | 'mini'} size
 * @param {string} value
 * @param {string} placeholder
 * @param {string} className
 * @param onChange
 * @param onPressEnter
 * @param onBlur
 * @param onFocus
 * @param onClick
 * @param {JSX.Element} trailingIcon
 * @returns {JSX.Element}
 * @constructor
 */
function Input({
  size = 'middle',
  value,
  placeholder,
  className,
  onChange,
  onPressEnter,
  onSearch,
  onBlur,
  onFocus,
  onClick,
  trailingIcon,
}) {
  return (
    <div className={classnames(styles.input, className)}>
      <AntInput
        value={value}
        allowClear
        onChange={onChange}
        placeholder={placeholder}
        onPressEnter={() => onPressEnter && onPressEnter(value)}
      />
      <span
        className={styles.trailing}
        onClick={() => onSearch && onSearch(value)}
      >
        <span className={styles.verticalLine}></span>
        <IconFont
          type="iconfenxiang"
          className={styles.icon}
        />
      </span>
    </div>
  );
  // return (
  //   <input
  //     type="text"
  //     className={classnames(
  //       styles.input,
  //       {
  //         large: size === 'large',
  //         middle: size === 'middle',
  //         mini: size === 'mini',
  //       },
  //       className,
  //     )}
  //     value={value}
  //     placeholder={placeholder}
  //     onChange={onChange}
  //     onFocus={onFocus}
  //     onBlur={onBlur}
  //     onClick={onClick}
  //     onKeyDown={e => {
  //       if (e.key === 'Enter' && onPressEnter) {
  //         onPressEnter(e);
  //       }
  //     }}
  //   />
  // );
}

export default Input;
