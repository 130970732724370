// 专利合作网络
import React, { useState, useMemo } from 'react';
import { ECharts, Table } from '@sqke/design';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';
import Container from '../../Container';
import style from './index.module.less';
import { useGetCooperativePatentApplicationQuery, useGetDistributionPatentTypesQuery } from '../../../../../service/companyDetail';

function PatentCooperationNetwork(props) {
  const { companyId, companyName } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data, isFetching } = useGetCooperativePatentApplicationQuery({
    company_id: companyId,
    node_limit_list: [null, null, 5],
  }, {
    skip: !companyId,
  });
  const option = useMemo(() => {
    const series = data?.series?.map(item => ({
      name: item.name,
      type: 'tree',
      layout: 'radial',
      roam: true,
      zoom: 1.5,
      center: ['18%', '18%'],
      scaleLimit: {
        min: 0.8,
        max: 4,
      },
      initialTreeDepth: 3,
      levels: [
        {
          label: {
            show: false,
            // distance: 300,
            // fontSize: 16,
            // padding: [4, 8, 4, 8],
            // backgroundColor: 'rgba(233,240,244,0.5)',
          },
        },
        {
          // label: {
          //   distance: -40,
          // },
          lineStyle: {
            color: '#1961F5',
          },
        },
        {
          lineStyle: {
            color: '#1961F5',
          },
        },
      ],
      lineStyle: {
        curveness: 0.5,
        width: 0.5,
      },
      symbolSize: 8,
      matchers: [
        {
          rules: { '==': [{ var: ['type'] }, '企业'] },
          result: {
            label: {
              color: '#000',
            },
            itemStyle: {
              color: '#fff',
              borderColor: '#000',
            },
          },
        },
        {
          rules: { '==': [{ var: ['type'] }, '高校'] },
          result: {
            label: {
              color: '#1961F5',
            },
            itemStyle: {
              color: '#fff',
              borderColor: '#1961F5',
            },
          },
        },
      ],
      label: {
        show: true,
        position: '',
        distance: 2,
        fontSize: 16,
      },
      data: item.data,
    }));

    return {
      tooltip: {},
      series,
    };
  }, [data]);
  const amplifyOption = useMemo(() => {
    if (!option.series) return {};
    const _option = cloneDeep(option);
    _option.series[0].levels[0].label.fontSize = 18;
    _option.series[0].label.fontSize = 14;
    return {
      option: _option,
      title: {
        title: '专利合作',
        question: '展示该企业在各业务领域与其他企业合作申请专利情况',
        companyName,
      },
    };
  }, [option]);

  const { data: tableData, isFetching: isTableFetching } = useGetDistributionPatentTypesQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  const chartEl = useMemo(() => {
    if (!data) return <div style={{ minHeight: '600px' }}><Spin /></div>;
    if (data.series?.length > 0) {
      return (
        <>
          <div className={style.subTitle}>
            {data?.pool?.company_name}
            申请专利涵盖
            {data?.pool?.industry_box}
            等产业，申请专利合作类型包括
            {data?.pool?.type_box}
            ，与
            {data?.pool?.com_box}
            均有合作。其中，
            {data?.pool?.most_type}
            与外部合作次数最多。
          </div>
          <ECharts
            showLoading={isFetching}
            className={style.chart}
            option={option}
          />
        </>
      );
    }
    return <></>;
  }, [data]);

  const tableEl = useMemo(() => {
    if (!tableData) return <div style={{ minHeight: '600px' }}><Spin /></div>;
    const newTableData = tableData?.dataSource.map((item, index) => ({
      key: index,
      ...item,
    }));
    if (!newTableData.length) return <></>;
    return (
      <Table
        dataSource={newTableData}
        columns={tableData?.columns}
        title={
          () => (
            <>
              <span>{tableData?.title}</span>
              <span className={style.count}>{tableData?.count}</span>
            </>
          )
        }
        strip
        loading={isTableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />
    );
  }, [tableData]);

  return (
    <Container id="专利合作" look="查看全图" question="展示该企业在各业务领域与其他企业合作申请专利情况" option={amplifyOption}>
      {
        chartEl
      }
      {
        tableEl
      }
    </Container>
  );
}

export default PatentCooperationNetwork;
