import React, { useEffect, useState } from 'react';
import { Input, Switch } from 'antd';
import { SyncOutlined, QuestionOutlined, CloseOutlined } from '@ant-design/icons';
import { uniqueId } from 'lodash';
import classnames from 'classnames';
import Hollowpie from '../../../../components/Hollowpie';
import downloadImg from '../../../../utils/downloadImg';
import Table from '../../../../components/Table';
import ComponentsTable from '../../../../components/ComponentsTable';
import { useGetStructuralTransferQuery, useLazyGetDistributionQuery } from '../../../../../../service/chartTableTool';
import { dataDownload } from '../../../../../../api/chartTableTooldataDownload';
import style from './index.module.less';

const { TextArea } = Input;

const PieTable = ({ data, url, title, chartType, radius, isFetching, delCrosswise, preview, setDescribeObj, id, setReportData, reportData, chartNumber, tableTitle }) => {
  const [getDistributionQuery] = useLazyGetDistributionQuery();
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [edit, setEdit] = useState(false);
  const [remake, setRemake] = useState(false);
  const [added, setAdded] = useState(1);
  const [series, setSeries] = useState(null);
  const [chartTitle, setChartTitle] = useState('');
  const [leftUnit, setLeftUnit] = useState('(家)');
  const [rightUnit, setRightUnit] = useState('%');
  const [numberFlat, setNumberFlat] = useState(true);
  const [numberTagFlat, setNumberTagFlat] = useState(true);
  const [describe, setDescribe] = useState('');
  const [tableColumsData, setTableColumnsData] = useState([]);
  const [tableDateSource, setTableDataSource] = useState([]);

  const dataDownloadTable = () => {
    dataDownload({ table_data: { dataSource, columns } }, `${title}(${uniqueId()})`);
  };
  const initChartData = () => {
    let newData = data;
    if (preview) {
      newData = reportData;
      setNumberFlat(newData.numberFlat);
      setNumberTagFlat(newData.numberTagFlat);
    }
    if (newData) {
      setSeries(newData.series[0]);
      setDescribe(newData.describe);
      setChartTitle(newData.title);
      setLeftUnit('家');
      setRightUnit('%');
      setTableColumnsData(newData?.tableData?.columns);
      setTableDataSource(newData?.tableData?.dataSource);

      if (setDescribeObj && !isFetching) {
        setDescribeObj(params => ({ ...params, [id]: { ok: true, describe: newData?.describe } }));
      }
    }
  };
  const GetDistribution = (seriesData = data) => {
    if (!data) return;
    getDistributionQuery({ data: { chat_data: seriesData }, url: url.split('/').pop() }).then(res => {
      if (res.data) {
        setDescribe(res.data);
        setDescribeObj(params => ({ ...params, [id]: { ...params[id], describe: res.data } }));
      }
    }).catch((err) => {
      throw new Error(err);
    });
  };
  const { data: tableTata } = useGetStructuralTransferQuery({
    original_data: data,
    original_structure: chartType,
  }, { skip: !data });

  const initTableData = () => {
    if (tableTata) {
      setColumns(tableTata.columns.map(item => {
        if (item.title) {
          return {
            ...item,
            key: uniqueId(),
          };
        }
        return {
          width: 118,
          ...item,
          key: uniqueId(),
        };
      }));
      setDataSource(tableTata.dataSource.map(item => ({ ...item, key: uniqueId() })));
    }
  };

  // 方法处理表格数据(头部)
  const onSetTableColumns = (val, source) => {
    const tableColums = [];
    tableColums.push({
      title: '排名',
      dataIndex: 'index',
    });
    tableColums.push({
      title: tableTitle,
      dataIndex: tableTitle,
      key: val?.[0]?.key,
    });
    source.forEach((item) => {
      tableColums.push({
        title: item?.name,
        dataIndex: item?.name,
        key: item?.key,
      });
    });
    return tableColums;
  };
  // 方法处理表格数据(内容)
  const onSetTableSource = (val, source) => {
    const tableSource = [];
    const valDataIndex = tableTitle;
    const valStringData = 'index';
    const valData = val.slice(1, val.length);
    const newValData = (valData || []).map((item, index) => (
      {
        [valDataIndex]: item.title,
        [valStringData]: index + 1,
      }
    ));
    (series?.data || []).forEach((item, index) => {
      source.forEach((value) => {
        tableSource[index] = {
          ...tableSource[index],
          ...newValData[index],
          [value.name]: value[item.name],
        };
      });
    });
    return tableSource;
  };
  // 更新报告数据
  const updateReportData = () => {
    // eslint-disable-next-line no-unused-expressions
    setReportData && setReportData(params => ({
      ...params,
      [id]: {
        ...params[id],
        text: title,
        contents: describe,
        content: chartTitle,
        data: {
          ...params[id]?.data,
          tableData: {
            columns: onSetTableColumns(columns, dataSource) || [],
            dataSource: onSetTableSource(columns, dataSource) || [],
            el: {
              columns: onSetTableColumns(columns, dataSource) || [],
              dataSource: onSetTableSource(columns, dataSource) || [],
            },
            type: 'table',
          },
          describe,
          numberFlat,
          numberTagFlat,
          series: [series],
          title: chartTitle,
          label: `${title}`,
        },
      },
    }));
  };

  useEffect(() => {
    updateReportData();
  }, [JSON.stringify(data), JSON.stringify(series), title, describe, numberFlat, numberTagFlat, chartTitle]);
  useEffect(() => {
    initChartData();
  }, [data, isFetching]);

  useEffect(() => {
    if (setDescribeObj && !isFetching) {
      setDescribeObj(params => ({ ...params, [id]: { ...params[id], describe } }));
    }
    // eslint-disable-next-line no-unused-expressions
    // setReportData && setReportData(params => ({ ...params, [id]: { ...params[id], text: title, contents: describe, content: chartTitle } }));
  }, [describe, isFetching]);

  useEffect(() => {
    if (data) {
      const list = [{ name: '企业数量', value: 'value' }, { name: '数量占比', value: 'proportion' }];
      const header = {
        title: '',
        dataIndex: 'name',
        key: 'name',
        width: 118,
      };
      const newColumns = data.series[0].data.map(item => ({
        title: item.name,
        dataIndex: item.name,
        key: uniqueId(),
      }));
      setColumns([header, ...newColumns]);
      const newDataSource = list.map(item => {
        const listObj = {};
        data.series[0].data.forEach(it => {
          listObj[it.name] = it[item.value];
        });
        return {
          name: item.name,
          ...listObj,
          key: uniqueId(),
        };
      });
      setDataSource(newDataSource);
    }
  }, [data]);

  useEffect(() => {
    if (dataSource.length && columns.length) {
      const newSeries = columns.filter(item => item.title).map(item => ({
        name: item.title,
        value: dataSource[0]?.[item.title] - 0 || 0,
        proportion: dataSource[1]?.[item.title] || 0,
      }));
      GetDistribution({ series: [{ data: newSeries }] });
      setSeries(params => ({ ...params, data: newSeries }));
    }
    // eslint-disable-next-line no-unused-expressions
    setReportData && setReportData(params => ({
      ...params,
      [id]: {
        ...params[id],
        data: {
          ...params[id]?.data,
          tableData: {
            columns: tableColumsData || [],
            dataSource: tableDateSource || [],
            el: {
              columns: tableColumsData || [],
              dataSource: tableDateSource || [],
            },
            type: 'table',
          },
        },
      },
    }));
  }, [columns, dataSource, tableColumsData, tableDateSource]);
  /**
   *
   * @param {string} value 更新后的值
   * @param {string | number} row 那行
   * @param {string} column 那列
   */
  const updateDataSource = (value, row, column) => {
    setDataSource(params => params.map(item => {
      if (item.key === row) {
        return {
          ...item,
          [column]: value,
        };
      }
      return item;
    }));
  };
  // 更新columns
  const updateColumns = (value, index) => {
    setColumns(parsms => parsms.map(item => {
      if (item.dataIndex === index) {
        return {
          ...item,
          title: value,
          dataIndex: value,
        };
      }
      return item;
    }));
  };

  // 添加单元格
  const addCell = () => {
    const isAdded = columns.some(item => item.title.slice(0, 2) === '新增');
    setColumns(params => ([
      ...params,
      {
        title: `新增${isAdded ? added : 1}`,
        dataIndex: `新增${isAdded ? added : 1}`,
        key: uniqueId(),
      },
    ]));
    if (isAdded) {
      setAdded(params => params + 1);
    } else {
      setAdded(1);
      setAdded(params => params + 1);
    }
  };
  // 删除列
  const delColumn = (key, dataIndex) => {
    setColumns(params => params.filter(item => item.key !== key));
    setDataSource(params => {
      if (!params.length || !params) { return []; }
      params.forEach(item => {
        delete item[dataIndex];
      });
      return params;
    });
  };
  // 删除行
  const delDataSource = (key) => {
    setDataSource(params => params.filter(item => item.key !== key));
  };

  // 自定义环形图显示的内容
  const labelName = (params) => {
    if (params.value && params.data.proportion) {
      return `${params.name}\n${params.value}${leftUnit}/${params.data.proportion}${rightUnit}`;
    }
    return `${params.name}\n${params.value}${params.value && leftUnit}${params.data.proportion && '/'}${params.data.proportion}${params.data.proportion && rightUnit}`;
  };
  // 方法关闭或者打开数据列表
  const onSetNunberList = (checked) => {
    setNumberFlat(checked);
  };
  // 方法关闭或者打开数据标签
  const onSetNunberTagList = (checked) => {
    setNumberTagFlat(checked);
  };
  return (
    <div id={id} className={classnames(style.box, { [style.noBorder]: preview })}>
      <div className={style.title}>
        <span>
          {title}
        </span>
        <button type="button" className={classnames(style.editBtn, { [style.edit]: edit })} onClick={(e) => { setEdit(params => !params); }}>
          {edit ? '确定' : '编辑'}
        </button>
      </div>
      <div className={style.describe}>
        {
          preview ? describe : (
            <TextArea
              value={describe}
              onChange={(e) => { setDescribe(e.target.value); }}
              // placeholder="textarea with clear icon"
              allowClear
              bordered={false}
              disabled={!edit}
            />
          )
        }
      </div>
      <div className={style.chartAndTable}>
        <div className={style.chartAndTableBox}>
          <div className={style.chartBox}>
            <div className={style.chartsTitle}>
              {preview ? `图${chartNumber}${chartTitle}` : chartTitle}
            </div>
            <div className={style.chart} key={rightUnit + leftUnit}>
              <Hollowpie
                data={series}
                isFetching={false}
                radius={radius}
                downloadImg={downloadImg}
                labelShow={numberTagFlat}
                labelName={labelName}
                dataDownload={dataDownloadTable}
                preview={preview}
                setReportData={preview && setReportData}
                title={title}
                fonstColor="#000"
                id={id}
              />
            </div>
            <div className={style.sourceTextStyle}>
              数据来源：上奇产业通
            </div>
          </div>
          {
            numberFlat && (
              <div className={style.tableStyle}>
                <ComponentsTable
                  columns={preview ? tableColumsData : onSetTableColumns(columns, dataSource)}
                  dataSource={preview ? tableDateSource : onSetTableSource(columns, dataSource)}
                />
              </div>
            )
          }
          {
            remake && (
              <div className={style.popup}>
                <div className={style.popupTop}>
                  <div className={style.popupTopLeft}>
                    <span>
                      <QuestionOutlined />
                    </span>
                    <span>
                      是否重制数据?
                    </span>
                  </div>
                  <div className={style.right} onClick={() => { setRemake(false); }}>
                    <CloseOutlined />
                  </div>
                </div>
                <div className={style.middle}>
                  重置数据仅对当前模块生效，重置后您编辑的数据无法保存并不可撤销
                </div>
                <div className={style.bottom}>
                  <button type="button" onClick={() => { setRemake(false); }}>取消</button>
                  <button type="button" onClick={() => { setRemake(false); initChartData(); GetDistribution(); initTableData(); }}>确定</button>
                </div>
              </div>
            )
          }
        </div>
        {
          edit && (
            <div className={style.tableCig}>
              <div className={style.tableTitle}>
                <span>图表配置</span>
                <div className={style.remake} onClick={() => { setRemake(true); }}>
                  <span>
                    <SyncOutlined />
                  </span>
                  <span>
                    重置数据
                  </span>
                </div>
              </div>
              <div className={style.chartTitleCig}>
                <div className={style.chartTitleLeft}>
                  <div className={style.chartTitle}>
                    <span>图表标题</span>
                    <div>
                      <Input value={chartTitle} onChange={(e) => { setChartTitle(e.target.value); }} />
                    </div>
                  </div>
                  <div className={style.chartUnit}>
                    <span>单位(左)</span>
                    <div>
                      <Input value={leftUnit} onChange={(e) => { setLeftUnit(e.target.value); }} />
                    </div>
                  </div>
                  <div className={style.chartUnit}>
                    <span>单位(右)</span>
                    <div>
                      <Input value={rightUnit} onChange={(e) => { setRightUnit(e.target.value); }} />
                    </div>
                  </div>
                </div>
                <div className={style.chartTitleRight}>
                  <div className={style.titleStyle}>
                    <span>数据列表</span>
                    <Switch onChange={onSetNunberList} checked={numberFlat} />
                  </div>
                  <div className={style.titleStyle}>
                    <span>数据标签</span>
                    <Switch onChange={onSetNunberTagList} checked={numberTagFlat} />
                  </div>
                </div>
              </div>

              <div className={style.table}>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  delCrosswise={delCrosswise}
                  updateDataSource={updateDataSource}
                  updateColumns={updateColumns}
                  delColumn={delColumn}
                  delDataSource={delDataSource}
                  addCell={addCell}
                />
              </div>
            </div>
          )
        }
      </div>
    </div>

  );
};

export default PieTable;
