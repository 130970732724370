import React from 'react';
import QRCode from 'qrcode.react';
import { CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import style from './index.module.less';

const WxShare = ({ url, id, openWxModal, setOpenWxModal }) => (
  <div className={classnames(style.wxModalMasking, { [style.wxModalMaskingNone]: openWxModal })}>
    <div className={style.wxModal}>
      <div key={id}>
        <QRCode value={url} size={142} />
      </div>
      <div>打开微信扫一扫发送助力邀请</div>
      <div onClick={() => {
        setOpenWxModal(true);
      }}
      >
        直接支付
      </div>
      <div className={style.close}>
        <span onClick={() => {
          setOpenWxModal(true);
        }}
        >
          <CloseOutlined />
        </span>
      </div>
    </div>
  </div>
);

export default WxShare;
