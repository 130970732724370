import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { withRouter } from 'react-router-dom';
import { Pagination, Table, message, Spin } from 'antd';
import classNames from 'classnames';
import { useQuery } from '../../hooks/url';
import Confirm from '../../components/Confirm';
import FollowEditTree from '../../components/MySelf/FollowEditTree';
import {
  getCurFollowTree,
  editFollowGroup,
  addFollowGroup,
  delFollowGroup,
  getFollowTableData,
  cancelFollowGroup,
  addFollow,
  getGenerateReport,
  getExportReport,
  downloadGenerateReport,
  deleteGenerateReport,
  myNoteTable,
  myLabelTable,
} from '../../api/mySelf';
import { getOrderList } from '../../api/apply';
import CompanyLabelTable from '../../components/MySelf/CompnayLabelTable';
import CompnayNoteTable from '../../components/MySelf/CompnayNoteTable';
import DynamicMonitor from '../../components/MySelf/DynamicMonitor';
import GenerateTable from '../../components/MySelf/GeneratedTable';
import ExportTable from '../../components/MySelf/ExportTable';
import RevokeTable from '../../components/MySelf/RevokeTable';
import ApplyTable from '../../components/MySelf/ApplyTable';
import MyselfModal from '../../components/MySelf/MyselfModal';
import PersonalCenter from '../../components/MySelf/PersonalCenter';
import RevenueDetail from '../../components/MySelf/RevenueDetail';
import { openNewPage } from '../../utils/urlMethods';
import { IconFont } from '../../utils/mapIcon';
import { requestFile } from '../../utils/request';
import noData from '../../assets/images/myself/nodynamicData.png';
import { useLazyRecordingSpotQuery } from '../../service/public';
import style from './index.module.less';

const operatorMap = {
  unfollow: '取消关注',
  move: '移动',
  copy: '复制',
  revoke: '撤销操作',
};

const curTabs = ['个人中心', '下载', '动态监测', '关注', '笔记', '标签', '支付订单'];

const iconMap = {
  关注: 'iconheart-fill',
  动态监测: 'iconfile-copy-fill',
  下载: 'icondowloadfile',
  笔记: 'iconedit-fill',
  标签: 'iconbiaoqian',
  支付订单: 'iconwx-pay',
};

function MySelf(props) {
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const EAPersonalCenter = menu.find(item => item === 'EAPersonalCenter');
  const [curTab, setCurTab] = useState(curTabs[0]); // 左侧tab
  const [datail, setDatail] = useState('');
  const [leftTreeData, setLeftTreeData] = useState([]); // 关注列表树
  const [selectTreeNode, setSelectTreeNode] = useState(''); // 选中节点
  const [selectTrunkNode, setSelectTrunkNode] = useState(''); // 选中节点的父节点
  const [nodeHandleModal, setNodeHandleModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 移动/复制
  const [tableData, setTableData] = useState({}); // 关注表格数据
  const [revokeTableData, setRevokeTableData] = useState([]); // 撤销操作表格数据
  const [handleAttetionId, setHandleAttetionId] = useState(''); // 移动/复制操作的对象id
  const [followLoading, setFollowLoading] = useState(true); // 关注对象表格
  const [generatedList, setGeneratedList] = useState([]); // 已下载报告列表
  const [generateConfirmVisible, setGenerateConfirmVisible] = useState(false); // 已下载报告列表删除弹窗
  const [notesTableData, setNotesTableData] = useState({}); // 笔记表格数据
  const [labelsTableData, setLabelsTableData] = useState({}); // 标签表格数据
  const [orderTableData, setOrdertableData] = useState({}); // 订单表格数据
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0 }); // 统一使用的分页
  const [tableLoading, setTableLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [tabKey, setTabKey] = useState('report');
  const [exportList, setExportList] = useState([]);
  const revokeData = useRef([]);
  const curNodeData = useRef({});
  const delGenerateId = useRef(-1);
  const query = useQuery();
  const [recordingSpotQuery] = useLazyRecordingSpotQuery();
  useEffect(() => () => {
    // 清空message 弹窗
    message.destroy();
  }, []);

  // 获取关注对象列表数据
  const getFollowTable = useCallback(
    (condition) => {
      const { node, trunkNode, pageSize, pageNum } = condition;
      curNodeData.current = {
        ...condition,
      };
      setPagination({
        current: pageNum,
        pageSize,
        total: 0,
      });
      setFollowLoading(true);
      getFollowTableData({
        attention_data_type_name: trunkNode,
        attention_group_id: node,
        page_size: pageSize,
        page_number: pageNum,
      }).then(res => {
        if (res.status === '00000') {
          if (curNodeData.current.node === node
            && curNodeData.current.trunkNode === trunkNode
            && curNodeData.current.pageNum === pageNum) {
            setTableData(res.data);
            setFollowLoading(false);
          }
        } else {
          setFollowLoading(false);
        }
      });
    },
    [],
  );

  // 获取已生成报告的列表
  const getGenerateTable = useCallback(
    (condition, key) => {
      setPagination({
        ...condition,
        total: 0,
      });
      setTableLoading(true);
      if (key === 'data') {
        getExportReport({
          pageSize: condition.pageSize,
          pageNumber: condition.current,
        }).then(res => {
          if (res.status === '00000') {
            const { data } = res;
            const { dataSource: _data, count: total } = data;
            setExportList(_data);
            setPagination({
              ...pagination,
              current: condition.current,
              total,
            });
            setTableLoading(false);
          }
        }).finally(() => {
          setTableLoading(false);
        });
      } else if (key === 'report') {
        getGenerateReport({
          pageSize: condition.pageSize,
          pageNumber: condition.current,
        }).then(res => {
          if (res.status === '00000') {
            const { data } = res;
            const { dataSource: _data, count: total } = data;
            setGeneratedList(_data);
            setPagination({
              ...pagination,
              current: condition.current,
              total,
            });
            setTableLoading(false);
          }
        }).finally(() => {
          setTableLoading(false);
        });
      }
    },
    [],
  );

  // 我的笔记表格
  const getNotesTable = useCallback(
    (condition) => {
      setPagination({
        ...condition,
        total: 0,
      });
      setTableLoading(true);
      myNoteTable({
        page_size: condition.pageSize,
        page_number: condition.current,
      }).then(res => {
        if (res.status === '00000') {
          setNotesTableData(res.data);
          setFollowLoading(false);
        }
      }).finally(() => {
        setTableLoading(false);
      });
    },
    [],
  );

  // 我的-订单
  const getOrderTableData = useCallback((condition) => {
    setPagination({
      ...condition,
      total: 0,
    });
    setTableLoading(true);
    getOrderList({
      page_size: condition.pageSize,
      page_number: condition.current,
    }).then(res => {
      if (res.status === '00000') {
        setOrdertableData(res.data);
        setFollowLoading(false);
      }
    }).finally(() => {
      setTableLoading(false);
    });
  }, []);

  // 我的标签表格
  const getLabelTable = useCallback(
    (condition) => {
      setPagination({
        ...condition,
        total: 0,
      });
      setTableLoading(true);
      myLabelTable({
        page_size: condition.pageSize,
        page_number: condition.current,
      }).then(res => {
        if (res.status === '00000') {
          setLabelsTableData(res.data);
          setFollowLoading(false);
        }
      }).finally(() => {
        setTableLoading(false);
      });
    },
    [],
  );

  // 当选中的节点变化时清空取消可回撤表格数据
  // 并重新请求表格数据（重置页码）
  useEffect(() => {
    setRevokeTableData([]);
    if (selectTreeNode && selectTrunkNode) {
      getFollowTable({
        node: selectTreeNode,
        trunkNode: selectTrunkNode,
        pageSize: pagination.pageSize,
        pageNum: 1,
      });
    }
  }, [selectTreeNode]);

  // 取消关注表格撤销操作后刷新表格数据
  const getCurTableData = useCallback(
    () => {
      getFollowTable(curNodeData.current);
    },
    [],
  );

  // 去数据搜索页
  const jumpToSearch = () => {
    props.history.push('/dataSearch?search=&page=all');
  };

  // 新开进入office页面
  const jumpToOffice = (rowData) => {
    openNewPage('office', {
      id: rowData.id,
    });
  };

  const downloadFile = (rowData, requestMsg) => requestFile('GET', `${window.location.origin}/${rowData.report_file}&version=${rowData.version}`, [], `${rowData.report_name}.docx`, requestMsg);

  // 下载报告
  const downloadGeneratedReport = (rowData) => {
    if (downloadLoading) return;
    if (rowData.type === '精品报告') {
      const link = document.createElement('a');
      link.href = rowData.pdf_link;
      link.target = '_blank';
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }
    setDownloadLoading(true);
    const requestMsg = rowData.version === 2;
    downloadGenerateReport({
      report_id: rowData.id,
    }).then(res => {
      if (res.status === '00000') {
        downloadFile(rowData, requestMsg).then(msg => {
          setDownloadLoading(false);
          if (requestMsg && msg?.data) {
            window.open(msg.data);
          }
          message.destroy();
          message.success('下载成功！', 1);
        });
      }
    });
  };

  // 删除已生成的报告
  const delGenerate = () => {
    if (delGenerateId.current > -1) {
      deleteGenerateReport({
        report_id: delGenerateId.current,
      }).then(res => {
        if (res.status === '00000') {
          message.success('删除成功！');
          getGenerateTable(pagination, 'report');
          setGenerateConfirmVisible(false);
        }
      });
    }
  };

  // 处理已生成报告表头
  const handleGnerateOperator = (key, curRow) => {
    const { id } = curRow;
    let element = <span />;
    switch (key) {
      // case 'view_and_edit':
      //   element = (
      //     <div
      //       key={`${id}_${key}`}
      //       className={style.viewButton}
      //       onClick={() => jumpToAnalysisModels(curRow)}
      //     >
      //       <span className={style.operatorBtn}>修改组件</span>
      //     </div>
      //   );
      //   break;
      // case 'view':
      //   element = (
      //     <div
      //       key={`${id}_${key}`}
      //       className={style.viewButton}
      //       onClick={() => jumpToOffice(curRow)}
      //     >
      //       <span className={style.operatorBtn}>查看与排版</span>
      //     </div>
      //   );
      //   break;
      case 'download':
        element = (
          <div className={style.dl} key={`${id}_${key}`}>
            <span className={style.downloadWord} onClick={() => downloadGeneratedReport(curRow)}>
              下载
            </span>
          </div>
        );
        break;
      case 'remove':
        element = (
          <span
            key={`${id}_${key}`}
            onClick={() => {
              delGenerateId.current = curRow.id;
              setGenerateConfirmVisible(true);
            }}
            className={style.operatorBtn}
          >
            删除
          </span>
        );
        break;
      default:
        break;
    }
    return element;
  };

  // 获取用户关注分组数据
  const getNewFollowTree = useCallback(
    (action) => {
      getCurFollowTree().then(res => {
        if (res.status === '00000') {
          setLeftTreeData(res.data);
          // 当删除的分组是当前选中的分组，默认选中分类下的第一个子项
          if (action === 'defaultSelect') {
            setSelectTreeNode(res.data[0].children[0].key);
          }
        }
      });
    },
    [],
  );

  // 对用户关注分组进行的操作
  // 新增/修改/删除
  const handleWithTreeNode = useCallback(
    (action, condition, curSelect) => {
      let handle;
      switch (action) {
        case 'add':
          handle = addFollowGroup;
          break;
        case 'edit':
          handle = editFollowGroup;
          break;
        case 'delete':
          handle = delFollowGroup;
          break;
        default: break;
      }
      handle(condition).then(res => {
        if (res.status === '00000') {
          // 删除了当前选中的分组
          if (action === 'delete' && condition.attention_group_id === curSelect) {
            getNewFollowTree('defaultSelect');
          } else {
            getNewFollowTree();
          }
        }
      });
    },
    [],
  );

  useEffect(() => {
    // tab变化时重置页码信息
    const newPagination = { pageSize: 10, current: 1, total: 0 };
    if (curTab === '关注') {
      getNewFollowTree();
      setPagination(newPagination);
    } else if (curTab === '下载' || curTab === '标签' || curTab === '笔记' || curTab === '支付订单') {
      setRevokeTableData([]);
      setLeftTreeData([]);
      switch (curTab) {
        case '下载':
          setTabKey('report');
          getGenerateTable(newPagination, 'report');
          break;
        case '标签': getLabelTable(newPagination); break;
        case '笔记': getNotesTable(newPagination); break;
        case '支付订单': getOrderTableData(newPagination); break;
        default: break;
      }
    }
  }, [curTab]);

  useEffect(() => {
    // 存储最新取消表格数据
    revokeData.current = revokeTableData;
  }, [revokeTableData]);

  // 关注表格移动复制操作
  const handleWithTableOperator = (type, rowData) => {
    // 当关注对象存在于当前所有分组当中时，禁止移动/复制操作
    if (rowData.is_full_group && type !== 'unfollow') return;
    const newRowData = {
      ...rowData,
      operator: '撤销操作',
    };
    switch (type) {
      case 'unfollow':
        cancelFollowGroup({
          attention_data_type_name: curNodeData.current.trunkNode,
          attention_data_id: rowData.attention_data_id,
          attention_group_id: rowData.group_id,
        }).then(res => {
          if (res.status === '00000') {
            revokeData.current.push(newRowData);
            setRevokeTableData(cloneDeep(revokeData.current));
            message.warning(
              <span style={{ color: '#919191', fontSize: '14px' }}>
                您已成功取关
                <span style={{ margin: '0 8px', color: '#3E5C76', fontWeight: 'bold' }}>
                  {rowData.name}
                </span>
              </span>,
            );
            getFollowTable(curNodeData.current);
          }
        });
        break;
      case 'move':
      case 'copy':
        setModalType(type);
        setHandleAttetionId(rowData.attention_data_id);
        setNodeHandleModal(true);
        break;
      default: break;
    }
  };

  // 跳转到xx详情页
  const pushToDetail = useCallback((rowData) => {
    const { attention_data_type_name: type, id } = rowData;
    switch (type) {
      case '企业':
        openNewPage('companyDetail', {
          id,
        });
        break;
      default: break;
    }
  }, []);

  // 处理关注表格 表头数据
  const handleWithColumns = useCallback(
    (columns) => {
      const newColumn = columns.map((item, index) => {
        let res;
        if (item.dataIndex === 'operator') {
          res = {
            ...item,
            width: '30%',
            render: (text, record) => (
              <div className={style.tableOperator}>
                {text.map((item1, index1) => (
                  <span
                    key={`${index1}${index}${item1}`}
                    className={record.is_full_group && item1 !== 'unfollow' ? style.noHanlde : style.followOperator}
                    onClick={() => handleWithTableOperator(item1, record)}
                  >
                    {operatorMap[item1]}
                  </span>
                ))}
              </div>
            ),
          };
        } else if (item.dataIndex === 'name') {
          res = {
            ...item,
            width: '35%',
            render: (text, record) => (
              <div className={style.pushToDetail} onClick={() => pushToDetail(record)}>
                {text}
              </div>
            ),
          };
        } else {
          res = {
            ...item,
            ellipsis: true,
          };
        }
        return res;
      });
      return newColumn;
    },
    [],
  );

  // 关注树组件
  const leftFollowTree = useMemo(() => (
    leftTreeData.length > 0
    && <FollowEditTree
      data={leftTreeData}
      selectTreeNode={selectTreeNode}
      setSelectTreeNode={setSelectTreeNode}
      setSelectTrunkNode={setSelectTrunkNode}
      handleWithTreeNode={handleWithTreeNode}
      setData={setLeftTreeData}
    />
  ), [leftTreeData, setLeftTreeData, handleWithTreeNode,
    selectTreeNode, setSelectTreeNode, setSelectTrunkNode]);

  // 取关表格组件
  const revoketable = useMemo(() => (
    revokeTableData.length > 0
    && <RevokeTable
      data={revokeTableData}
      setData={setRevokeTableData}
      addFollow={addFollow}
      selectTrunkNode={selectTrunkNode}
      getCurTableData={getCurTableData}
    />
  ), [revokeTableData, setRevokeTableData, selectTrunkNode, getCurTableData]);

  // 关注表格组件
  const followTable = useMemo(() => (
    Object.keys(tableData).length > 0 && tableData.tableBody.length > 0 ? (
      <div className={style.tableContainer}>
        {Object.keys(tableData).length > 0 && <Table
          rowKey={record => `${record.attention_data_id}${record.name}${record.groupName}`}
          columns={handleWithColumns(tableData.tableHead)}
          dataSource={tableData.tableBody}
          pagination={false}
        />}
        <Pagination
          className={style.pagination}
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={tableData.total}
          pageSizeOptions={[10]}
          showQuickJumper
          hideOnSinglePage
          onChange={(page, pageSize) => {
            getFollowTable({
              node: selectTreeNode,
              trunkNode: selectTrunkNode,
              pageSize,
              pageNum: page,
            });
          }}
        />
      </div>
    ) : (
      <div className={style.noData}>
        <img alt="无数据" src={noData} style={{ width: '50px', height: '52px' }} />
        <span>暂无数据，请前往企业详情页添加</span>
        <span
          style={{ color: '#1961F5', cursor: 'pointer', margin: '0 4px' }}
          onClick={() => jumpToSearch()}
        >
          关注
        </span>
      </div>
    )
  ), [tableData, pagination, getFollowTable, handleWithColumns, selectTreeNode,
    selectTrunkNode, jumpToSearch]);

  // 个人中心
  const personalCenter = useMemo(() => (
    <PersonalCenter EAPersonalCenter={EAPersonalCenter} />
  ), []);

  // 笔记表格
  const notesTable = useMemo(() => (
    Object.keys(notesTableData).length > 0
    && <CompnayNoteTable
      data={notesTableData}
      pagination={pagination}
      getNotesTable={getNotesTable}
    />
  ), [pagination, notesTableData, getNotesTable]);

  // 标签表格
  const labelTable = useMemo(() => (
    Object.keys(labelsTableData).length > 0
    && <CompanyLabelTable
      data={labelsTableData}
      pagination={pagination}
      getLabelTable={getLabelTable}
    />
  ), [pagination, labelsTableData, getLabelTable]);

  // 订单表格
  const orderTable = useMemo(() => (
    Object.keys(orderTableData).length > 0
    && <ApplyTable
      data={orderTableData}
      pagination={pagination}
      getOrderTableData={getOrderTableData}
    />
  ), [pagination, orderTableData, getOrderTableData]);

  // 已生成报告表格
  const generatedTable = useMemo(() => (
    <>
      {
        EAPersonalCenter && (
          <div className={style.tabs}>
            <div
              className={classNames(style.tabItem, { [style.active]: tabKey === 'report' })}
              onClick={() => {
                setTabKey('report');
                getGenerateTable({ pageSize: 10, current: 1, total: 0 }, 'report');
              }}
            >
              报告下载记录
            </div>
            <div
              className={classNames(style.tabItem, { [style.active]: tabKey === 'data' })}
              onClick={() => {
                setTabKey('data');
                getGenerateTable({ pageSize: 10, current: 1, total: 0 }, 'data');
              }}
            >
              数据导出记录
            </div>
          </div>
        )
      }
      {
        tabKey === 'report' && (
          <GenerateTable
            pagination={pagination}
            getGenerateTable={(e) => getGenerateTable(e, 'report')}
            generatedList={generatedList}
            handleGnerateOperator={handleGnerateOperator}
          />
        )
      }
      {
        tabKey === 'data' && (
          <ExportTable
            pagination={pagination}
            getGenerateTable={(e) => getGenerateTable(e, 'data')}
            exportList={exportList}
          />
        )
      }
    </>
  ), [generatedList, exportList, getGenerateTable, handleGnerateOperator]);

  // 动态监测
  const monitorDynamic = useMemo(() => (
    <DynamicMonitor />
  ), []);

  useEffect(() => {
    const _curTab = query.get('tab');
    const _detail = query.get('detail');
    setCurTab(_curTab);
    setDatail(_detail);
  }, [query]);

  return (
    <div className={style.all}>
      <div className={style.mainContainer}>
        {
          datail && (
            <RevenueDetail />
          )
        }
        {
          !datail && (
            <>
              <div className={style.leftSelect}>
                {
                  curTabs.map((item, index) => {
                    if (EAPersonalCenter && item === '支付订单') return false;
                    return (
                      <div
                        key={item}
                        className={style.leftItem}
                      >
                        <div
                          className={`${style.leftTab} ${curTab === item ? style.selectTab : ''}`}
                          onClick={() => {
                            if (curTab === item) return;
                            if (item === '个人中心') {
                              recordingSpotQuery({
                                spot_name: item,
                              });
                            } else {
                              recordingSpotQuery({
                                spot_name: `  个人中心-${item}`,
                              });
                            }
                            props.history.push({
                              pathname: 'accountSetting',
                              search: `tab=${item}`,
                            });
                            setCurTab(item);
                          }}
                        >
                          {item}
                        </div>
                        {item === '关注' && (
                          <div className={`${style.leftTree} ${curTab === '关注' ? style.followTree : style.hiddenTree}`}>
                            {leftFollowTree}
                          </div>
                        )}
                      </div>
                    );
                  })
                }
              </div>
              <div className={style.rightTable}>
                <div style={{ display: curTab === '个人中心' ? '' : 'none' }}>
                  {personalCenter}
                </div>
                <div className={style.followTable} style={{ display: curTab === '关注' ? '' : 'none' }}>
                  {revoketable}
                  {followLoading ? <Spin spinning tip="搜索中，请稍候....." /> : followTable}
                </div>
                {curTab === '动态监测' && (
                  <div className={style.monitorTable} style={{ display: curTab === '动态监测' ? '' : 'none' }}>
                    {monitorDynamic}
                  </div>)}
                <div className={style.followTable} style={{ display: curTab === '下载' ? '' : 'none' }}>
                  {tableLoading ? <Spin spinning tip="搜索中，请稍候....." /> : generatedTable}
                </div>
                <div className={style.followTable} style={{ display: curTab === '笔记' ? '' : 'none' }}>
                  <Spin spinning={tableLoading} tip="搜索中，请稍候.....">
                    {notesTable}
                  </Spin>
                </div>
                <div className={style.followTable} style={{ display: curTab === '标签' ? '' : 'none' }}>
                  <Spin spinning={tableLoading} tip="搜索中，请稍候.....">
                    {labelTable}
                  </Spin>
                </div>
                <div className={style.followTable} style={{ display: curTab === '支付订单' ? '' : 'none' }}>
                  <Spin spinning={tableLoading} tip="搜索中，请稍候.....">
                    {orderTable}
                  </Spin>
                </div>
              </div>
            </>
          )
        }
      </div>
      {generateConfirmVisible && (
        <Confirm
          icon={<IconFont type="iconfrown-fill" />}
          text="确定要删除该报告吗？"
          subText="删除后将不可恢复，请慎重选择"
          visible={generateConfirmVisible}
          onOK={() => delGenerate()}
          onCancel={() => setGenerateConfirmVisible(false)}
        />)}
      {nodeHandleModal && <MyselfModal
        visible={nodeHandleModal}
        setVisible={setNodeHandleModal}
        leftTreeData={leftTreeData}
        selectNode={selectTreeNode}
        selectTrunkNode={selectTrunkNode}
        handleAttetionId={handleAttetionId}
        multi={modalType === 'copy'}
        addFollow={addFollow}
        cancelFollowGroup={cancelFollowGroup}
        getCurTableData={getCurTableData}
      />}
    </div>
  );
}

export default withRouter(MySelf);
