/*
 *Author: zhaochenyu
 *Description: 区县画像
 *Date: 2022/01/28
*/

import React, { useMemo } from 'react';
import ReportChart from '../ReportChart';
import FrontCover from '../AreaPortraitReport/FrontCover';
import BackCover from '../AreaPortraitReport/BackCover';
import style from '../index.module.less';

function AreaPortraitReport(props) {
  const { chartConditions, chartTitlesRef, refList, chartTitles, pushToAchor,
    getChartFinishedPercent, buyStatus } = props;
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  return (
    <>
      {buyStatus ? (
        <>
          <div className={style.first}>
            {/* 首页封皮 */}
            <FrontCover area={urlReportMsg.area} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、对标分析')}>
              <div className={style.firstTitle}>一、对标分析</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）产业主体')}>
              <div className={style.secondTitle}>（一）产业主体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）创新资源')}>
              <div className={style.secondTitle}>（二）创新资源</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）融资概况')}>
              <div className={style.secondTitle}>（三）融资概况</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>12</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）产业载体')}>
              <div className={style.secondTitle}>（四）产业载体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>15</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、产业画像')}>
              <div className={style.firstTitle}>二、产业画像</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>16</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）市场主体')}>
              <div className={style.secondTitle}>（一）市场主体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>26</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）创新活力')}>
              <div className={style.secondTitle}>（二）创新活力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>18</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）融资能力')}>
              <div className={style.secondTitle}>（三）融资能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>23</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）产业载体')}>
              <div className={style.secondTitle}>（四）产业载体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>28</div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="一、对标分析">
              一、对标分析
            </div>
            <div className={style.secondaryTitle} id="（一）产业主体">（一）产业主体</div>
            {/* 存量企业数量对比 */}
            {chartConditions.length > 0 && (
            <ReportChart
              chartMsg={chartConditions[0]}
              chartIndex={0}
              ref={chartTitlesRef.current[0]}
              chartHeight={330}
              refList={refList}
              title={`图1 ${chartTitles[0] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 存量企业数量对标 */}
            {chartConditions.length > 1 && (
            <ReportChart
              chartMsg={chartConditions[1]}
              chartIndex={1}
              ref={chartTitlesRef.current[1]}
              chartHeight={460}
              refList={refList}
              title={`图2 ${chartTitles[1] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 新增企业数量对比 */}
            {chartConditions.length > 2 && (
            <ReportChart
              chartMsg={chartConditions[2]}
              chartIndex={2}
              ref={chartTitlesRef.current[2]}
              chartHeight={330}
              refList={refList}
              title={`图3 ${chartTitles[2] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 新增企业数量对标 */}
            {chartConditions.length > 3 && (
            <ReportChart
              chartMsg={chartConditions[3]}
              chartIndex={3}
              ref={chartTitlesRef.current[3]}
              chartHeight={460}
              refList={refList}
              title={`图4 ${chartTitles[3] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 头部企业数量 */}
            {chartConditions.length > 4 && (
            <ReportChart
              chartMsg={chartConditions[4]}
              chartIndex={4}
              ref={chartTitlesRef.current[4]}
              chartHeight={330}
              refList={refList}
              title={`图5 ${chartTitles[4] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 高技术企业数量 */}
            {chartConditions.length > 5 && (
            <ReportChart
              chartMsg={chartConditions[5]}
              chartIndex={5}
              ref={chartTitlesRef.current[5]}
              chartHeight={330}
              refList={refList}
              title={`图6 ${chartTitles[5] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）创新资源">（二）创新资源</div>
            {/* 存量专利对比 */}
            {chartConditions.length > 6 && (
            <ReportChart
              chartMsg={chartConditions[6]}
              chartIndex={6}
              ref={chartTitlesRef.current[6]}
              chartHeight={330}
              refList={refList}
              title={`图7 ${chartTitles[6] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 产业存量专利分布 */}
            {chartConditions.length > 7 && (
            <ReportChart
              chartMsg={chartConditions[7]}
              chartIndex={7}
              ref={chartTitlesRef.current[7]}
              chartHeight={460}
              refList={refList}
              title={`图8 ${chartTitles[7] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 新增专利对比 */}
            {chartConditions.length > 8 && (
            <ReportChart
              chartMsg={chartConditions[8]}
              chartIndex={8}
              ref={chartTitlesRef.current[8]}
              chartHeight={330}
              refList={refList}
              title={`图9 ${chartTitles[8] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 产业新增专利分布 */}
            {chartConditions.length > 9 && (
            <ReportChart
              chartMsg={chartConditions[9]}
              chartIndex={9}
              ref={chartTitlesRef.current[9]}
              chartHeight={460}
              refList={refList}
              title={`图10 ${chartTitles[9] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 研究机构数量对比 */}
            {chartConditions.length > 10 && (
            <ReportChart
              chartMsg={chartConditions[10]}
              chartIndex={10}
              ref={chartTitlesRef.current[10]}
              chartHeight={330}
              refList={refList}
              title={`图11 ${chartTitles[10] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 研究机构类型对比 */}
            {chartConditions.length > 11 && (
            <ReportChart
              chartMsg={chartConditions[11]}
              chartIndex={11}
              ref={chartTitlesRef.current[11]}
              chartHeight={460}
              refList={refList}
              title={`图12 ${chartTitles[11] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）融资概况">（三）融资概况</div>
            {/* 投融资事件分布 */}
            {chartConditions.length > 12 && (
            <ReportChart
              chartMsg={chartConditions[12]}
              chartIndex={12}
              ref={chartTitlesRef.current[12]}
              chartHeight={660}
              refList={refList}
              title={`图13 ${chartTitles[12] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 投融资金额分布 */}
            {chartConditions.length > 13 && (
            <ReportChart
              chartMsg={chartConditions[13]}
              chartIndex={13}
              ref={chartTitlesRef.current[13]}
              chartHeight={660}
              refList={refList}
              title={`图14 ${chartTitles[13] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：融资金额只统计企业对外公开的数额。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            {/* 融资事件产业分布 */}
            {chartConditions.length > 14 && (
            <ReportChart
              chartMsg={chartConditions[14]}
              chartIndex={14}
              ref={chartTitlesRef.current[14]}
              chartHeight={460}
              refList={refList}
              title={`图15 ${chartTitles[14] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）产业载体">（四）产业载体</div>
            {/* 产业载体城市分布 */}
            {chartConditions.length > 15 && (
            <ReportChart
              chartMsg={chartConditions[15]}
              chartIndex={15}
              ref={chartTitlesRef.current[15]}
              chartHeight={310}
              refList={refList}
              title={`图16 ${chartTitles[15] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="二、产业画像">
              二、产业画像
            </div>
            <div className={style.secondaryTitle} id="（一）市场主体">（一）市场主体</div>
            <div className={style.tertiaryTitle}>1. 企业总览</div>
            {/* 存量企业数量变化 */}
            {chartConditions.length > 16 && (
            <ReportChart
              chartMsg={chartConditions[16]}
              chartIndex={16}
              ref={chartTitlesRef.current[16]}
              chartHeight={320}
              refList={refList}
              title={`图17 ${chartTitles[16] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 企业金字塔模型 */}
            {chartConditions.length > 17 && (
            <ReportChart
              chartMsg={chartConditions[17]}
              chartIndex={17}
              ref={chartTitlesRef.current[17]}
              chartHeight={340}
              refList={refList}
              title={`图18 ${chartTitles[17] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高成长企业：近三年获得过融资的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>2. 产业分布</div>
            {/* 存量企业产业分布 */}
            {chartConditions.length > 18 && (
            <ReportChart
              chartMsg={chartConditions[18]}
              chartIndex={18}
              ref={chartTitlesRef.current[18]}
              chartHeight={400}
              refList={refList}
              title={`图19 ${chartTitles[18] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）创新活力">（二）创新活力</div>
            <div className={style.tertiaryTitle}>1. 存量专利</div>
            {/* 近年申请专利变化趋势 */}
            {chartConditions.length > 19 && (
            <ReportChart
              chartMsg={chartConditions[19]}
              chartIndex={19}
              ref={chartTitlesRef.current[19]}
              chartHeight={360}
              refList={refList}
              title={`图20 ${chartTitles[19] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 近年授权专利变化趋势 */}
            {chartConditions.length > 20 && (
            <ReportChart
              chartMsg={chartConditions[20]}
              chartIndex={20}
              ref={chartTitlesRef.current[20]}
              chartHeight={360}
              refList={refList}
              title={`图21 ${chartTitles[20] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 授权发明专利产业分布 */}
            {chartConditions.length > 21 && (
            <ReportChart
              chartMsg={chartConditions[21]}
              chartIndex={21}
              ref={chartTitlesRef.current[21]}
              chartHeight={380}
              refList={refList}
              title={`图22 ${chartTitles[21] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>2. 新增专利</div>
            {/* 新增授权发明专利产业分布 */}
            {chartConditions.length > 22 && (
            <ReportChart
              chartMsg={chartConditions[22]}
              chartIndex={22}
              ref={chartTitlesRef.current[22]}
              chartHeight={380}
              refList={refList}
              title={`图23 ${chartTitles[22] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>3. 研究机构</div>
            {/* 研究机构类型分布 */}
            {chartConditions.length > 23 && (
            <ReportChart
              chartMsg={chartConditions[23]}
              chartIndex={23}
              ref={chartTitlesRef.current[23]}
              chartHeight={350}
              refList={refList}
              title={`图24 ${chartTitles[23] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）融资能力">（三）融资能力</div>
            <div className={style.tertiaryTitle}>1. 融资概况</div>
            {/* 融资事件变化 */}
            {chartConditions.length > 24 && (
            <ReportChart
              chartMsg={chartConditions[24]}
              chartIndex={24}
              ref={chartTitlesRef.current[24]}
              chartHeight={300}
              refList={refList}
              title={`图25 ${chartTitles[24] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 融资金额变化 */}
            {chartConditions.length > 25 && (
            <ReportChart
              chartMsg={chartConditions[25]}
              chartIndex={25}
              ref={chartTitlesRef.current[25]}
              chartHeight={300}
              refList={refList}
              title={`图26 ${chartTitles[25] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：融资金额只统计企业对外公开的数额。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            {/* 平均单笔融资额变化 */}
            {chartConditions.length > 26 && (
            <ReportChart
              chartMsg={chartConditions[26]}
              chartIndex={26}
              ref={chartTitlesRef.current[26]}
              chartHeight={300}
              refList={refList}
              title={`图27 ${chartTitles[26] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* 融资轮次分布 */}
            {chartConditions.length > 27 && (
            <ReportChart
              chartMsg={chartConditions[27]}
              chartIndex={27}
              ref={chartTitlesRef.current[27]}
              chartHeight={330}
              refList={refList}
              title={`图28 ${chartTitles[27] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 融资事件产业分布 */}
            {chartConditions.length > 28 && (
            <ReportChart
              chartMsg={chartConditions[28]}
              chartIndex={28}
              ref={chartTitlesRef.current[28]}
              chartHeight={700}
              refList={refList}
              title={`图29 ${chartTitles[28] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            {/* 融资金额产业分布 */}
            {chartConditions.length > 29 && (
            <ReportChart
              chartMsg={chartConditions[29]}
              chartIndex={29}
              ref={chartTitlesRef.current[29]}
              chartHeight={700}
              refList={refList}
              title={`图30 ${chartTitles[29] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：融资金额只统计企业对外公开的数额。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle}>2. 上市企业</div>
            {/* 上市企业板块数量 */}
            {chartConditions.length > 30 && (
            <ReportChart
              chartMsg={chartConditions[30]}
              chartIndex={30}
              ref={chartTitlesRef.current[30]}
              chartHeight={360}
              refList={refList}
              title={`图31 ${chartTitles[30] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.tertiaryTitle}>3. 重点融资</div>
            {/* 近一年融资TOP5 */}
            {chartConditions.length > 31 && (
            <ReportChart
              chartMsg={chartConditions[31]}
              chartIndex={31}
              ref={chartTitlesRef.current[31]}
              chartHeight={330}
              refList={refList}
              title={`表1 ${chartTitles[31] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）产业载体">（四）产业载体</div>
            {/* 产业载体类型分布 */}
            {chartConditions.length > 32 && (
            <ReportChart
              chartMsg={chartConditions[32]}
              chartIndex={32}
              ref={chartTitlesRef.current[32]}
              chartHeight={320}
              refList={refList}
              title={`图32 ${chartTitles[32] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.last}>
            {/* 尾页 */}
            <BackCover />
          </div>
        </>
      ) : (
        <>
          <div className={style.first}>
            {/* 首页封皮 */}
            <FrontCover area={urlReportMsg.area} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、对标分析')}>
              <div className={style.firstTitle}>一、对标分析</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）产业主体')}>
              <div className={style.secondTitle}>（一）产业主体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）创新资源')}>
              <div className={style.secondTitle}>（二）创新资源</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）融资概况')}>
              <div className={style.secondTitle}>（三）融资概况</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>12</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）产业载体')}>
              <div className={style.secondTitle}>（四）产业载体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>15</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、产业画像')}>
              <div className={style.firstTitle}>二、产业画像</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>16</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）市场主体')}>
              <div className={style.secondTitle}>（一）市场主体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>26</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）创新活力')}>
              <div className={style.secondTitle}>（二）创新活力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>18</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）融资能力')}>
              <div className={style.secondTitle}>（三）融资能力</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>23</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）产业载体')}>
              <div className={style.secondTitle}>（四）产业载体</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>28</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AreaPortraitReport;
