import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Pagination, Modal, Radio, Space, Spin, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  useLazyIntelligentTestingTestRecordQuery,
  useLazyIntelligentTestingDeleteTestRecordQuery,
  useLazyIntelligentTestingTestRecordDetailQuery,
} from '../../../../../../../service/tool';
import isNewImg from '../../../../../../../assets/images/tool/isNewImg.png';
import style from './index.module.less';

const { confirm } = Modal;

const HistoryContent = () => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isFetching, setIsFetching] = useState(false);
  const [intelligentTestingTestRecordData, setIntelligentTestingTestRecordData] = useState();
  const [getIntelligentTestingDeleteTestRecord] = useLazyIntelligentTestingDeleteTestRecordQuery();
  const [getLazyIntelligentTestingTestRecord] = useLazyIntelligentTestingTestRecordQuery();
  const [getIntelligentTestingTestRecordDetail] = useLazyIntelligentTestingTestRecordDetailQuery();

  const onGetIntelligentTestFileRecord = async (pageNumVal, pageSizeVal) => {
    setIsFetching(true);
    const result = await getLazyIntelligentTestingTestRecord({
      page_number: pageNumVal,
      page_size: pageSizeVal,
    });
    setIntelligentTestingTestRecordData(result.data);
    setIsFetching(false);
  };

  const onGetDetail = async (companyId, url) => {
    const result = await getIntelligentTestingTestRecordDetail({
      record_id: companyId,
    });
    localStorage.setItem('formData', JSON.stringify(result.data.appraisal_content));
    history.push(url);
  };

  const onShowConfirm = (id) => {
    confirm({
      title: '删除提示',
      icon: <QuestionCircleOutlined />,
      content: '删除后，该记录无法恢复，是否继续?',
      closable: true,
      onOk() {
        getIntelligentTestingDeleteTestRecord({
          id,
        }).then(res => {
          if (res.data === '删除成功') {
            message.success(res.data);
            setPageNum(1);
            onGetIntelligentTestFileRecord(1, pageSize);
          }
        }).catch(err => {
          throw new Error(err);
        });
      },
    });
  };

  const onShowEvaluationConfirm = (item) => {
    let value = 1;
    const onChange = (e) => {
      value = e.target.value;
    };
    confirm({
      title: '重新测评',
      icon: null,
      closable: true,
      content: (
        <div className={style.showEvaluationBox}>
          <div className={style.evaluationTitle}>请选择您要重新测评的方式</div>
          <Radio.Group onChange={onChange} defaultValue={1}>
            <Space direction='vertical'>
              <Radio value={1}>
                <div className={style.radioContent}>
                  在当前测评基础上重新评价
                </div>
                <span className={style.radioInformation}>
                  最近测评时间:2023-10-08 16:22
                </span>
              </Radio>
              <Radio value={2}>
                <div className={style.radioContent}>重新测评（空白问卷）</div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
      onOk() {
        if (value === 1) {
          onGetDetail(item.id, `/companyIntelligentEvaluation/basicInformation?companyId=${item.company_id}`);
        } else {
          history.push(`/companyIntelligentEvaluation/basicInformation?companyId=${item.company_id}&name=${item.company_name}`);
        }
      },
    });
  };

  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
    onGetIntelligentTestFileRecord(n, s);
  };

  useEffect(() => {
    onGetIntelligentTestFileRecord(pageNum, pageSize);
  }, []);

  return (
    <Spin spinning={isFetching}>

      <div className={style.historyContentBox}>
        {intelligentTestingTestRecordData && (intelligentTestingTestRecordData?.dataSource || []).map((item, index) => (
          <div key={index} className={style.cardBox}>
            <div className={style.cardTop}>
              <div className={style.cardTopLeft}>
                {pageNum === 1 && index === 0 ? <img src={isNewImg} alt='' /> : null}
                <span>{item.company_name}</span>
              </div>
              <div className={style.cardTopRight}>
                <span>{item.preliminary_score}</span>
                <span>分</span>
              </div>
            </div>
            <div className={style.cardBottom}>
              <div className={style.cardBottomLeft}>
                测评时间：
                <span>{item.update_time}</span>
              </div>
              <div className={style.cardBottomRight}>
                <Button
                  style={{ marginRight: 10, color: '#666666' }}
                  onClick={() => {
                    history.push(`/companyIntelligentEvaluation/evaluationResult?id=${item.id}&companyId=${item.company_id}`);
                    // onGetDetail(item.id);
                  }}
                >
                  查看测评报告
                </Button>
                <Button
                  style={{ marginRight: 10, color: '#666666' }}
                  onClick={() => {
                    onGetDetail(item.id, `/companyIntelligentEvaluation/basicInformation?id=${item.id}&companyId=${item.company_id}`);
                  }}
                >
                  查看测评问卷
                </Button>
                <Button
                  style={{ marginRight: 10, color: '#666666' }}
                  onClick={() => onShowEvaluationConfirm(item)}
                >
                  重新测评
                </Button>
                <Button
                  style={{ color: '#666666' }}
                  onClick={() => onShowConfirm(item.id)}
                >
                  删除
                </Button>
              </div>
            </div>
          </div>
        ))}
        <div className={style.bottomContent}>
          <div className={style.bottomTitle}>
            共
            <span>{intelligentTestingTestRecordData?.count || 0}</span>
            项数据
          </div>
          <div className={style.pagination}>
            <Pagination
              showQuickJumper
              hideOnSinglePage
              showSizeChanger={true}
              pageSize={pageSize}
              current={pageNum}
              total={intelligentTestingTestRecordData?.count || 0}
              onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default HistoryContent;
