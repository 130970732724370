import React, { useState, useRef, useEffect } from 'react';
import { Button, Cascader, message, DatePicker } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { throttle } from 'lodash';
import downIcon from '../../../../assets/images/tool/downIcon.png';
import IndustrySelect from '../components/IndustrySelect';
import Graph from './components/Graph';
import {
  useGetAreaSelectBoxQuery,
  useGetSearchIndustryTreeQuery,
  useGetIndustryLinkThroughTheFigureQuery,
  useGetIndustryLinkThroughTheFigureLabelQuery,
} from '../../../../service/knowledgeCompoent';
import { getExportIndustryLinkThroughTheFigure } from '../../../../api/knowledgeComponents';
import pageStyles from './index.module.less';
import styles from '../index.module.less';

const { RangePicker } = DatePicker;

const handleDefaultTags = (list) => {
  const arr = [];
  list.forEach((item, index) => {
    if (index <= 5) {
      arr.push(item.key);
    }
  });
  return arr;
};

export const generateRegionTree = (tree) => {
  if (tree.length > 0) {
    return tree.map(item => ({
      ...item,
      label: item.title,
      disabled: (item.key === '000000' || item.key.substring(item.key.length - 2) !== '00') ? false : !item.auth,
      children: item.children ? generateRegionTree(item.children) : [],
    }));
  }
  return [];
};

export const addAllOption = (tree) => {
  if (tree.length > 0) {
    return tree.map(item => {
      if (item.children?.length > 0) {
        if (item.key !== '110000' && item.key !== '120000' && item.key !== '310000' && item.key !== '500000') {
          return ({
            ...item,
            children: addAllOption([
              {
                ...item,
                label: '全部',
                disabled: false,
                children: [],
              },
              ...item.children,
            ]),
          });
        }
        return ({
          ...item,
          children: addAllOption(item.children),
        });
      }
      return item;
    });
  }
  return [];
};

export default function IndustryChainAdvantageEnterprise({ box }) {
  const disabledDate = (current) => current && current > moment().endOf('day');
  const graphRef = useRef(null);
  const [selectIndustry, setSelectIndustry] = useState({
    group_name: '信息技术',
    title: '集成电路',
    value: 'INB1303',
    key: 'INB1303',
    disabled: false,
  });
  const [industryVisible, setIndustryVisible] = useState(false);
  const [currentAreaValue, setCurrentAreaValue] = useState();
  const [areaList, setAreaList] = useState([]);
  const [selectArea, setSelectArea] = useState({
    value: '110000',
    title: '北京市',
    label: '北京市',
  });
  const [tagList, setTagList] = useState([]);
  const [selectTags, setSelectTags] = useState(['央企', '国家级单项冠军企业']);
  const [areaLegend, setAreaLegend] = useState(true);  // 为 false 时，取消区域高亮
  const [allLegend, setAllLegend] = useState(true);  // 为 false 时，只展示高亮部分
  const [dateList, setDateList] = useState([]);
  const [searchParams, setSearchParams] = useState({
    industry_code_list: ['INB1303'],
    industry_name_list: ['集成电路'],
    area_key: '110000',
    area_name: '北京市',
    especial_tag_list: ['央企', '国家级单项冠军企业'],
    establish_date_list: [],
  });
  const { data: regionTree } = useGetAreaSelectBoxQuery();
  const { data: industryList } = useGetSearchIndustryTreeQuery();
  const { data: companyLabelList } = useGetIndustryLinkThroughTheFigureLabelQuery({
    industry_code_list: [selectIndustry.value],
    industry_name_list: [selectIndustry.title],
    area_key: [selectArea.value],
  });
  const { data: graphData, isFetching: isGraphFetching, isSuccess } = useGetIndustryLinkThroughTheFigureQuery({
    industry_code_list: searchParams.industry_code_list,
    industry_name_list: searchParams.industry_name_list,
    area_key: searchParams.area_key,
    especial_tag_list: searchParams.especial_tag_list,
    establish_date_list: searchParams.establish_date_list,
    legend_list: [areaLegend, allLegend],
  }, {
    skip: !searchParams,
  });

  // 区域选择变化
  const onCascaderChange = (value, selectOptions) => {
    setCurrentAreaValue(value);
    setSelectArea(selectOptions[selectOptions.length - 1]);
  };

  // 点击企业标签
  const handleTagClick = (node) => {
    let currentTags = [...selectTags];
    const index = currentTags.findIndex(i => i === node);
    if (selectTags.length === 0) {
      currentTags = ['全部企业'];
    } else if (node === '全部企业') {
      currentTags = ['全部企业'];
    } else if (currentTags.findIndex(i => i === '全部企业') > -1) {
      currentTags.splice(currentTags.findIndex(i => i === '全部企业'), 1);
      currentTags.push(node);
    } else if (index > -1 && currentTags.length === 1) {
      currentTags = ['全部企业'];
    } else if (index > -1) {
      currentTags.splice(index, 1);
    } else {
      currentTags.push(node);
    }
    setSelectTags(currentTags);
  };

  // 时间筛选
  const dateChange = (data) => {
    const startDate = data[0].format('YYYY-MM-DD');
    const endDate = data[1].format('YYYY-MM-DD');
    setDateList([startDate, endDate]);
  };

  // 导出数据
  const handleDownloadData = async () => {
    const fileName = `${selectArea?.label ? selectArea.title : '全国'}${selectIndustry.title}优势企业挂链`;
    await getExportIndustryLinkThroughTheFigure({
      industry_code_list: [selectIndustry.value],
      industry_name_list: [selectIndustry.title],
      area_key: selectArea.value,
      especial_tag_list: selectTags,
      file_name: fileName,
    }, fileName);
  };

  useEffect(() => {
    if (regionTree) {
      setCurrentAreaValue(['000000', '110000', '110100']);
      const tree = addAllOption(generateRegionTree(regionTree));
      setAreaList(tree);
    }
  }, [regionTree]);

  useEffect(() => {
    if (companyLabelList?.length) {
      setTagList([
        ...companyLabelList,
        {
          title: '全部企业',
          key: '全部企业',
          value: '全部企业',
        },
      ]);
    }
  }, [companyLabelList]);

  useEffect(() => {
    if (isSuccess && !isGraphFetching) {
      message.success(`查询成功，共找到${graphData?.pool?.所选区域}条数据`);
    }
  }, [isSuccess, isGraphFetching]);

  // const generateTagDescribe = () => {
  //   if (searchParams.especial_tag_list.findIndex(i => i === '全部企业') === -1) {
  //     return `优势企业定义：包括${searchParams.especial_tag_list.join('、')}`;
  //   }
  //   return '';
  // };

  // 点击重置
  const handleReset = () => {
    const setParams = throttle(() => {
      setSelectIndustry({
        group_name: '信息技术',
        title: '集成电路',
        value: 'INB1303',
        key: 'INB1303',
        disabled: false,
      });
      setSelectArea({
        value: '110000',
        title: '北京市',
        label: '北京市',
      });
      setCurrentAreaValue(['000000', '110000', '110100']);
      setSelectTags(['央企', '国家级单项冠军企业']);
      setDateList([]);
      setSearchParams({
        industry_code_list: ['INB1303'],
        industry_name_list: ['集成电路'],
        area_key: '110000',
        area_name: '北京市',
        especial_tag_list: ['央企', '国家级单项冠军企业'],
        establish_date_list: [],
      });
    }, 200);
    setParams();
  };

  // 点击查询
  const handleSearch = () => {
    const setParams = throttle(() => {
      setSearchParams({
        industry_code_list: [selectIndustry.value],
        industry_name_list: [selectIndustry.title],
        area_key: selectArea.value,
        area_name: selectArea.title,
        especial_tag_list: selectTags && selectTags.findIndex(i => i === '全部企业') > -1 ? [] : selectTags,
        establish_date_list: dateList,
      });
    }, 200);
    setParams();
  };

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.left}>优势企业挂链</div>
        <div className={styles.right}>
          <Button className={styles.primaryBtn} onClick={handleDownloadData}>导出数据</Button>
          <Button
            className={styles.primaryBtn}
            onClick={() => {
              const { download } = graphRef.current;
              download();
            }}
          >
            图片下载
          </Button>
        </div>
      </div>
      <div className={styles.filterBox}>
        <div className={styles.leftRow}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>产业选择</div>
              <div className={styles.select} onClick={() => { setIndustryVisible(prev => !prev); }}>
                <div className={styles.text}>{selectIndustry.title}</div>
                <img className={classNames(styles.downIcon, { [styles.upIcon]: industryVisible })} src={downIcon} alt='' />
                {
                  industryVisible && (
                    <div className={styles.downBox}>
                      <IndustrySelect
                        data={industryList?.children}
                        select={selectIndustry}
                        setSelect={setSelectIndustry}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>优势企业</div>
              <div className={styles.tagList}>
                {
                  tagList?.length ? tagList.map(item => (
                    <div
                      key={item.key}
                      className={classNames(styles.tagItem, { [styles.selectedItem]: selectTags.filter(i => i === item.key)?.length > 0 })}
                      onClick={() => handleTagClick(item.key)}
                    >
                      {item.title}
                    </div>
                  )) : ''
                }
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>成立时间</div>
              <RangePicker
                disabledDate={disabledDate}
                placeholder={['开始时间', '截止时间']}
                onChange={dateChange}
              />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>关注区域</div>
              <Cascader
                showSearch={true}
                options={areaList}
                value={currentAreaValue}
                // changeOnSelect={true}
                getPopupContainer={() => box?.current}
                onChange={onCascaderChange}
                placeholder='请选择区域'
              />
              <div className={styles.note}>注：关注区域的优势企业将在图中高亮显示</div>
            </div>
          </div>
        </div>
        <div className={styles.rightRow}>
          <div className={styles.btnBox}>
            <Button type='primary' onClick={handleSearch}>查询</Button>
            <Button type='default' onClick={handleReset}>重置</Button>
          </div>
        </div>
      </div>
      <div className={styles.graphBox}>
        <div className={styles.describe}>
          {searchParams?.industry_name_list}
          产业全国优势企业
          <span className={styles.num}>{graphData?.pool?.全国}</span>
          家
          {searchParams?.area_key !== '000000' ? (
            <span>
              ，其中
              {searchParams?.area_name}
              <span className={styles.num}>{graphData?.pool?.所选区域}</span>
              家, 占全国
              <span className={styles.num}>{`${graphData?.pool?.占比?.value || ''}${graphData?.pool?.占比?.unit || ''}`}</span>
              ，默认展开
              <span className={styles.num}>{graphData?.pool?.show_company_count}</span>
              家。
            </span>
          ) : '。'}
        </div>
        <div className={styles.treeGraph}>
          <Graph
            ref={graphRef}
            data={graphData?.chart || {}}
            selectIndustryName={searchParams.industry_name_list}
            selectAreaName={searchParams.area_name}
            isFetching={isGraphFetching}
            areaLegend={areaLegend}
            useDataColors
          />
        </div>
      </div>
      <div className={styles.legendBox}>
        <div className={styles.inner}>
          <div className={pageStyles.left}>
            {
              searchParams?.area_key !== '000000' ? (
                <div
                  className={classNames(pageStyles.areaLegend, { [pageStyles.disabledLegend]: !areaLegend })}
                  onClick={() => setAreaLegend(prev => !prev)}
                >
                  {`${searchParams?.area_name}优势企业`}
                </div>
              ) : ''
            }
            <div
              className={classNames(pageStyles.allLegend, { [pageStyles.disabledLegend]: !allLegend })}
              onClick={() => setAllLegend(prev => !prev)}
            >
              优势企业
            </div>
          </div>
          <div className={styles.right}>数据来源：上奇产业通</div>
        </div>
      </div>
    </div>
  );
}
