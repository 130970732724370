import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, message } from 'antd';
import { getModifyNote } from '../../../api/accountSetting';
import style from './index.module.less';

const REG = new RegExp("^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$");

function EnterpriseForget(props) {
  const { onFinish, loading, cancelClick } = props;

  const [btnValue, setBtnValue] = useState('获取验证码');
  const captchaRef = useRef({ timer: null });

  const getCaptcha = (curValue) => {
    if (curValue === '获取验证码') {
      const phone = document.querySelector('#phone').value;
      const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
      if (!phone) {
        message.error('手机号不能为空');
      } else if (!checkPhone.test(phone)) {
        message.error('手机号码格式错误');
      } else {
        getModifyNote({
          phone_no: phone,
        }).then(res => {
          if (res.status === '00000') {
            let second = 60;
            setBtnValue(`${second}s`);
            if (captchaRef.current && captchaRef.current.timer) {
              clearInterval(captchaRef.current.timer);
            }
            captchaRef.current.timer = setInterval(() => {
              if (second > 0) {
                second -= 1;
                setBtnValue(`${second}s`);
              } else {
                if (captchaRef.current) {
                  clearInterval(captchaRef.current.timer);
                }
                setBtnValue('获取验证码');
              }
            }, 1000);
          } else {
            message.error(res.message);
          }
        });
      }
    }
  };

  useEffect(() => () => {
    if (captchaRef.current && captchaRef.current.timer) {
      clearInterval(captchaRef.current.timer);
    }
  }, []);

  return (
    <div className={style.all}>
      <Form
        layout="vertical"
        onFinish={value => onFinish(value)}
      >
        <Form.Item
          label="您的手机号是"
          name="phone"
          className="username"
          rules={[
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号',
            },
            {
              required: true,
              message: '请输入手机号码',
            },
          ]}
        >
          <Input placeholder="请输入手机号码" />
        </Form.Item>

        <Form.Item label="" className="captchaBox">
          <Form.Item
            name="captcha"
            className="captcha"
            rules={[{ required: true, message: '请输入验证码' }]}
          >
            <Input placeholder="请输入验证码" maxLength={6} />
          </Form.Item>
          <div className="getCaptcha">
            <Button
              ref={captchaRef}
              type="text"
              onClick={() => getCaptcha(btnValue)}
              className={btnValue !== '获取验证码' ? 'btnClicked' : ''}
              disabled={btnValue !== '获取验证码'}
            >
              {btnValue}
            </Button>
          </div>
        </Form.Item>

        <Form.Item
          label="设置新密码"
          name="newPassword"
          className="password"
          rules={[
            {
              pattern: REG,
              message: '为保证安全，请输入8-20位大小写字母+数字+符号的组合',
            },
            {
              required: true,
              message: '请输入8-20位新密码',
            },
          ]}
        >
          <Input placeholder="请输入密码" type="password" autoComplete="new-password" />
        </Form.Item>

        <Form.Item
          label="确认密码"
          name="confirm"
          className="checkPassword"
          rules={[
            {
              required: true,
              message: '请输入8-20位新密码',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('两次密码不一致！');
              },
            }),
          ]}
        >
          <Input placeholder="请输入密码" type="password" autoComplete="new-password" />
        </Form.Item>

        <Form.Item className="submit">
          <Button className="cancel-btn" onClick={() => cancelClick()}>
            取消
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="submit-btn"
            loading={loading}
          >
            确认修改
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EnterpriseForget;
