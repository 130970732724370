import clsx from 'clsx';
import type { DeltaOperation } from 'quill';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { CSSProperties } from 'react';

interface QuillRenderProps {
  style?: CSSProperties;
  className?: string;
  contents?: DeltaOperation[];
}

function QuillRenderer({ contents, style, className, }: QuillRenderProps) {
  const renderText = JSON.stringify(contents)?.indexOf('{data.pool.') > -1;
  const _contents = !renderText ? contents : [];
  const converter = new QuillDeltaToHtmlConverter(_contents ?? [], {
    inlineStyles: {
      size: (value, op) => {
        return `font-size: ${value}`;
      },
    }
  });

  return (
    <div
      dangerouslySetInnerHTML={{ __html: converter.convert() }}
      className={clsx('ql-editor sqke-text', className)}
      style={style}
    />
  );
}

export default QuillRenderer;
