/**
 * 企业详情
 */
import { apiSlice } from '../slices/api';

const COMPANYDETAIL = '/companyDetail';

export const companyDetailApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 基本信息-股东信息
    getShareholderInfo: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/shareholder_info_list`,
          params: arg,
        };
      },
    }),
    // 基本信息-法人关系高管名单
    getLegalRepresentativeRelationTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/legal_representative_relation_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 基本信息-估值信息表
    getValuationInformationTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/valuation_information_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 上市企业 /industry_knowledge_engine/v2/companyDetail/listing_information
    getListingInformation: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/listing_information`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 市值图谱 /industry_knowledge_engine/v2/companyDetail/market_value_atlas
    getMarketValueAtlas: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/market_value_atlas`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 市值表格 /industry_knowledge_engine/v2/companyDetail/market_value_table
    getMarketValueTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/market_value_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 财务分析表 /industry_knowledge_engine/v2/companyDetail/financial_analysis
    getFinancialAnalysis: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/financial_analysis`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 财务分析图 /industry_knowledge_engine/v2/companyDetail/financial_analysis_atlas
    getFinancialAnalysisAtlas: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/financial_analysis_atlas`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 基本信息-财务分析表
    getFinancialInformationTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/financial_information_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 基本信息-企业年报
    getCompanyYearReport: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_year_report`,
          params: arg,
        };
      },
    }),
    // 基本信息-迁出变更
    getChangeRemoval: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/change_removal`,
          params: arg,
        };
      },
    }),
    // 基本信息-资本变更
    getCapitalChange: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/capital_change`,
          params: arg,
        };
      },
    }),
    // 基本信息-重要人员变更
    getKeyPersonnelChange: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/key_personnel_change`,
          params: arg,
        };
      },
    }),
    // 基本信息-经营范围变更
    getBusinessScopeChange: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/business_scope_change`,
          params: arg,
        };
      },
    }),
    // 成长活跃曲线
    getGrowthCurve: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_grow_up_active_curve_fenxi`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 资质奖励-荣誉资质
    getCompanyCredentialTag: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_credential_tag`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 资质奖励-所获奖项
    getCompanyAward: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_get_award`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 资质奖励-备案许可
    getPermissionFiling: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/permission_filing`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 经营能力-优质股东
    getQualityShareholdersList: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/quality_shareholders_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 经营能力-合作龙头
    getLeadingCooperativeEnterprises: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/list_leading_cooperative_enterprises`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 经营能力-中标目标
    getWinningProjects: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/list_winning_projects`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 经营能力-营收增长 /industry_knowledge_engine/v2/companyDetail/operating_income_table
    getOperatingIncomeTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/operating_income_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 营收增长图 /industry_knowledge_engine/v2/companyDetail/operating_income_graph
    getOperatingIncomeGraph: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/operating_income_graph`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新能力-重点项目
    getKeyProjects: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/list_key_projects`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新平台 地图 creative_platform_map
    getCreativePlatformMap: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/creative_platform_map`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新平台表 /industry_knowledge_engine/v2/companyDetail/creative_platform_table
    getCreativePlatformTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/creative_platform_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新能力-承建创新平台
    getResearchInstitution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/research_institution_level_type_graph`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新平台穿透 /industry_knowledge_engine/v2/companyDetail/innovation_platform_penetration
    getInnovationPlatformPenetration: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/innovation_platform_penetration`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 研发投入 /industry_knowledge_engine/v2/companyDetail/research_input_table
    getResearchInputTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/research_input_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 研发投入图 /industry_knowledge_engine/v2/companyDetail/research_input_chart getResearchInputChart getResearchInputTable
    getResearchInputChart: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/research_input_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新能力-专利详情
    getFindPatentList: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/find_patent_list`,
          params: arg,
        };
      },
    }),
    // 专利申请趋势 /industry_knowledge_engine/v2/companyDetail/patent_statistics_application_trend
    getPatentStatisticsApplicationTrend: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_application_trend`,
          params: arg,
        };
      },
    }),
    // 专利公开趋势 /industry_knowledge_engine/v2/companyDetail/patent_statistics_public_trend
    getPatentStatisticsPublicTrend: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_public_trend`,
          params: arg,
        };
      },
    }),
    // 专利类型分布 /industry_knowledge_engine/v2/companyDetail/patent_statistics_type_distribution
    getPatentStatisticsTypeDistribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_type_distribution`,
          params: arg,
        };
      },
    }),
    // 专利状态分布 /industry_knowledge_engine/v2/companyDetail/patent_statistics_status_distribution
    getPatentStatisticsStatusDistribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_status_distribution`,
          params: arg,
        };
      },
    }),
    // 专利发明人分布 /industry_knowledge_engine/v2/companyDetail/patent_statistics_inventor_distribution
    getPatentStatisticsInventorDistribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_inventor_distribution`,
          params: arg,
        };
      },
    }),
    // 专利IPC /industry_knowledge_engine/v2/companyDetail/patent_statistics_IPC_distribution
    getPatentStatisticsIPCDistribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_IPC_distribution`,
          params: arg,
        };
      },
    }),
    // 产业领域分布 /industry_knowledge_engine/v2/companyDetail/patent_statistics_industry_distribution
    getPatentStatisticsIndustryDistribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_industry_distribution`,
          params: arg,
        };
      },
    }),
    // 热点领域 /industry_knowledge_engine/v2/companyDetail/patent_statistics_key_tech_distribution
    getPatentStatisticsKeyTechDistribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_key_tech_distribution`,
          params: arg,
        };
      },
    }),
    // 价值统计 /industry_knowledge_engine/v2/companyDetail/patent_statistics_value_statistics
    getPatentStatisticsValueStatistics: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_value_statistics`,
          params: arg,
        };
      },
    }),
    // 专利详情 /industry_knowledge_engine/v2/companyDetail/patent_statistics_patent_detail
    getPatentStatisticsPatentDetail: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_statistics_patent_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新能力-产业标准明细
    getParticipateDraftingStandards: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/participate_drafting_standards`,
          params: arg,
        };
      },
    }),
    // 创新能力-起草标准详情
    getCompanyStandardDraftingIssue: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_standard_drafting_issue`,
          params: arg,
        };
      },
    }),
    // 创新能力-所获软著
    getDetailsSoftwarePublications: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/details_software_publications`,
          params: arg,
        };
      },
    }),

    // 融资历程
    getCompanyInvestmentExperience: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_investment_experience`,
          params: arg,
        };
      },
    }),

    // 技术驱动
    getTechnologyDriven: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/technology_driven`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 规模拓展
    getScaleExpansion: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/expansion_of_scale`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 市场反馈
    getMarketFeedback: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/feedback_from_the_market`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 利润增长
    getProfitGrowth: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/growth_in_profits`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 行业地位
    getIndustryPosition: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/position_in_industry`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 股权关系网络
    getEquityRelationship: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/equity_relationship_network`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 对外投资
    getOutwardInvestment: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/outward_investment`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 对外投资地图 industry_knowledge_engine/v2/companyDetail/company_invest_map
    getCompanyInvestMap: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_invest_map`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 对外投资表格 /industry_knowledge_engine/v2/companyDetail/company_invest_table
    getCompanyInvestTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_invest_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 对外投资产业布局 /industry_knowledge_engine/v2/companyDetail/invest_industry_distribute
    getInvestIndustryDistribute: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/invest_industry_distribute`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 对外投资股权比例分布 industry_knowledge_engine/v2/companyDetail/invest_stock_rights_distribute
    getInvestStockRightsDistribute: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/invest_stock_rights_distribute`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 对外投资--所投企业
    getStockStrikeSheet: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/stock_strike_sheet`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 对外投资 industry_knowledge_engine/v2/companyDetail/investment_penetration
    getInvestmentPenetration: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/investment_penetration`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 分支机构区域分布
    getBranchRegionalDistribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/regional_distribution_of_branches`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 分支机构地图对应的表 /industry_knowledge_engine/v2/companyDetail/regional_distribution_of_branches_table
    getRegionalDistributionOfBranchesTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/regional_distribution_of_branches_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 对外投资-分支机构
    getBranchInstitution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/branch_institution`,
          params: arg,
        };
      },
    }),
    // 合作申请专利
    getCooperativePatentApplication: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/cooperative_patent_application`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 申请专利网络类型分布
    getDistributionPatentTypes: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/distribution_of_patent_types`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 标准合作
    getStandardCooperation: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/standard_cooperation`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 起草标准网络类型分布
    getDistributionStandardTypes: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/distribution_of_standard_types`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 主导标准
    getLeadingStandards: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/leading_standards`,
          params: arg,
        };
      },
    }),
    // 供应关系网络
    getSupplyRelationship: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/supply_relationship_network`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 供应关系网络详情
    getSupplyRelationshipDetails: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/supply_relationship_details`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 就业人数
    getEmploymentNumber: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/employment_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 在招岗位
    getLookingForPositions: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/looking_for_positions`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 税务评级
    getTaxRating: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/tax_rating`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 所得税
    getTaxContribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/tax_contribution`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 经营风险 /industry_knowledge_engine/v2/companyDetail/operational_risk
    getOperationalRisk: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/operational_risk`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 股权出质
    getEquityOffering: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/equity_offering`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 动产抵押
    getMortgageMovableProperty: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/mortgage_movable_property`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 经营异常
    getAbnormalOperation: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/abnormal_operation`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 严重违法
    getSeriousViolationLaw: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/serious_violation_law`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 抽查结果
    getSpotCheckResults: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/spot_check_results`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 行政处罚
    getAdministrativePenalty: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/administrative_penalty`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 舆情预警
    getPublicOpinionWarning: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/public_opinion_warning`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 行业竞争力
    getIndustrialCompetitiveness: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/industrial_competitiveness`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 信用风险
    getCreditRisk: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/credit_risk`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 关联风险 /industry_knowledge_engine/v2/companyDetail/co_related_risks
    getCoRelatedRisks: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/co_related_risks`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业服务-政策推荐
    getPolicyRecommendations: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/policy_recommendations`,
          params: arg,
        };
      },
    }),
    // 企业服务-专利推荐
    getPatentRecommendations: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/patent_recommendations`,
          params: arg,
        };
      },
    }),

    // 工具列表
    getCompanyToolList: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_tool_list`,
          params: arg,
        };
      },
    }),
    // 查询企业所在产业列表
    getCompanyIndustryList: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_industry_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业环节分布
    getCompanyIndustryLinkDistribution: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_industry_link_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 资质穿透列表 quality_certification_table
    getQualityCertificationTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/quality_certification_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业入榜情况 industry_knowledge_engine/v2/companyDetail/company_rank_tag_list
    getCompanyRankTagList: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_rank_tag_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业入榜情况穿透  industry_knowledge_engine/v2/companyDetail/quality_rank_table
    getQualityRankTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/quality_rank_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 荣誉资质列表 company_credential_tag_list
    getCompanyCredentialTagList: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/company_credential_tag_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 合作关系列表 partnership_table
    getPartnershipTable: builder.query({
      query(arg) {
        return {
          url: `${COMPANYDETAIL}/partnership_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetLegalRepresentativeRelationTableQuery,
  useGetValuationInformationTableQuery,
  useGetFinancialInformationTableQuery,
  useGetCompanyYearReportQuery,
  useGetChangeRemovalQuery,
  useGetCapitalChangeQuery,
  useGetKeyPersonnelChangeQuery,
  useGetBusinessScopeChangeQuery,
  useGetShareholderInfoQuery,
  useGetGrowthCurveQuery,
  useGetCompanyCredentialTagQuery,
  useLazyGetCompanyCredentialTagQuery,
  useGetCompanyAwardQuery,
  useGetPermissionFilingQuery,
  useGetQualityShareholdersListQuery,
  useGetLeadingCooperativeEnterprisesQuery,
  useGetWinningProjectsQuery,
  useGetOperatingIncomeTableQuery,
  useGetOperatingIncomeGraphQuery,
  useGetKeyProjectsQuery,
  useGetResearchInstitutionQuery,
  // useGetResearchDevelopmentInvestmentQuery,
  useGetFindPatentListQuery,
  useGetParticipateDraftingStandardsQuery,
  useGetCompanyStandardDraftingIssueQuery,
  useGetDetailsSoftwarePublicationsQuery,
  useGetCompanyInvestmentExperienceQuery,
  useGetTechnologyDrivenQuery,
  useGetScaleExpansionQuery,
  useGetMarketFeedbackQuery,
  useGetProfitGrowthQuery,
  useGetIndustryPositionQuery,
  useGetEquityRelationshipQuery,
  useGetOutwardInvestmentQuery,
  useGetStockStrikeSheetQuery,
  useGetBranchRegionalDistributionQuery,
  useGetBranchInstitutionQuery,
  useGetCooperativePatentApplicationQuery,
  useGetDistributionPatentTypesQuery,
  useGetStandardCooperationQuery,
  useGetDistributionStandardTypesQuery,
  useGetLeadingStandardsQuery,
  useGetSupplyRelationshipQuery,
  useGetSupplyRelationshipDetailsQuery,
  useGetEmploymentNumberQuery,
  useGetLookingForPositionsQuery,
  useGetTaxRatingQuery,
  useGetTaxContributionQuery,
  useGetEquityOfferingQuery,
  useGetMortgageMovablePropertyQuery,
  useGetAbnormalOperationQuery,
  useGetSeriousViolationLawQuery,
  useGetSpotCheckResultsQuery,
  useGetAdministrativePenaltyQuery,
  useGetPublicOpinionWarningQuery,
  useGetIndustrialCompetitivenessQuery,
  useGetCreditRiskQuery,
  useGetPolicyRecommendationsQuery,
  useGetPatentRecommendationsQuery,
  useGetCompanyToolListQuery,
  useGetCompanyIndustryListQuery,
  useGetCompanyIndustryLinkDistributionQuery,
  useGetQualityCertificationTableQuery,
  useGetCompanyCredentialTagListQuery,
  useGetPartnershipTableQuery,
  useGetListingInformationQuery,
  useGetMarketValueAtlasQuery,
  useGetMarketValueTableQuery,
  useGetFinancialAnalysisAtlasQuery,
  useGetFinancialAnalysisQuery,
  useGetCreativePlatformMapQuery,
  useGetCreativePlatformTableQuery,
  useGetInnovationPlatformPenetrationQuery,
  useGetResearchInputChartQuery,
  useGetResearchInputTableQuery,
  useGetPatentStatisticsApplicationTrendQuery,
  useGetPatentStatisticsPublicTrendQuery,
  useGetPatentStatisticsTypeDistributionQuery,
  useGetPatentStatisticsStatusDistributionQuery,
  useGetPatentStatisticsInventorDistributionQuery,
  useGetPatentStatisticsIPCDistributionQuery,
  useGetPatentStatisticsIndustryDistributionQuery,
  useGetPatentStatisticsKeyTechDistributionQuery,
  useGetPatentStatisticsValueStatisticsQuery,
  useGetPatentStatisticsPatentDetailQuery,
  useGetOperationalRiskQuery,
  useGetCoRelatedRisksQuery,
  useGetCompanyInvestMapQuery,
  useGetCompanyInvestTableQuery,
  useGetInvestIndustryDistributeQuery,
  useGetInvestStockRightsDistributeQuery,
  useGetInvestmentPenetrationQuery,
  useGetRegionalDistributionOfBranchesTableQuery,
  useGetCompanyRankTagListQuery,
  useGetQualityRankTableQuery,
} = companyDetailApi;
