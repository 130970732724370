import { IFunnelSymbolConfig } from '../interface/config';
import { getLabel, omitUndefined, getDataItem } from './utils';
import { ISeriesGetter } from '../interface/base';
import { merge } from 'lodash';

export const getEach = (series: any) => {
  const {
    sort,
    minSize,
    maxSize,
    gap,
    label,
    emphasis,
  } = series;

  return omitUndefined({
    sort,
    minSize,
    maxSize,
    gap,
    label: getLabel(label),
    emphasis,
  });
}

export const getSeriesFunnel: ISeriesGetter<IFunnelSymbolConfig> = (series, index, extra) => {
  const {
    material,
  } = extra;
  const { config, data: _data } = material;
  const { visualConfig } = config;

  const { data, unit }  = series as any;
  // 金字塔图
  const { length } = data;
  const gap = 100 / length;
  const seriesData = data.map((d: any, index: number) => {
    const item: any = getDataItem(d);
    return {
      ...item,
      value: index * gap + gap,
      amount: item.amount || item.amount === 0 ? item.amount : item.value,
      unit: unit,
      label: {
        formatter: (params: any) => {
          const { name, amount, proportion, unit, } = params?.data ?? {};
          const str = `${name}\n\n${amount}${unit || ''}${proportion ? `，占比${proportion}%` : ''}`;
          return str;
        },
      },
    }
  });

  return omitUndefined(
    merge(
      series,
      { data: seriesData },
      getEach(visualConfig),
    )
  );
};
