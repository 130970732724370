/*
 *Author: zhaochenyu
 *Description: 校验数据格式类型
 *Date: 2021/09/28
*/

import { isObject, isArray, isString } from '../../utils/tool';

export function checkTableData(data: any): any {
  let resData: any = {
    columns: [],
    dataSource: [],
  };
  if (data && isObject(data)) {
    const { columns, dataSource } = data;
    let newColumns = [];
    let newDataSource = [];
    if (columns && isArray(columns)) {
      newColumns = columns;
    }
    if (dataSource && isArray(dataSource)) {
      newDataSource = dataSource;
    }
    resData = {
      ...resData,
      columns: newColumns,
      dataSource: newDataSource,
    };
  }
  return resData;
}

export function getCurColWidth(width: any): string | number {
  if (!width) return '';
  if (isString(width)) {
    return width.indexOf('%') > -1 ? width : parseInt(width);
  }

  return parseInt(width);
}
