// 政策推荐
import React, { useState } from 'react';
import style from './index.module.less';
import { useGetPolicyRecommendationsQuery } from '../../../../../service/companyDetail';
import NewsDynamic from '../../News/NewsDynamic';

function PolicyRecommend(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data } = useGetPolicyRecommendationsQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  return (
    <div className={style.all} id="政策推荐">
      <div className={style.title}>政策推荐</div>
      <div className={style.split} />
      <NewsDynamic
        data={{
          count: data?.auth_count,
          data: data?.data_list,
        }}
        type="policy"
        getList={({ page_number: pageNumber }) => {
          setPage({
            pageSize: 10,
            pageNumber,
          });
        }}
      />
    </div>
  );
}

export default PolicyRecommend;
