// 饼图
import React, { useMemo } from 'react';
import { Spin } from 'antd';
import { ECharts } from '@sqke/design';
import ChartNoData from './ChartNoData';
import style from './public/index.module.less';

const defaultColor = ['#0048DD', '#1961F5', '#4E88FF', '#00B3FB', '#00AD54', '#59D700', '#00E7B5', '#A6FFC2', '#FFC559', '#FD6205'];

/**
 *
 * @param {*} param0
 * @data Obj 数据坐标
 * @roseType Str 饼图类型 area/均匀玫瑰图  radius/按比例分配玫瑰图
 * @radius Arr 内外环比例
 * @position 文本在扇图的位置
 * @defaultPitch 默认选中第一个扇区
 * @labelShow labelk可见
 * @titleShow 中心标题是否可见
 * @title title 标题内容
 * @param { Function } labelName 接受到的参数是本区域的数据返回值是展示的数据
 * @showCount tooltip
 * @returns
 */
const Hollowpie = ({ data, titleShow = false, icon = 'circle', labelName = (params) => `${params.name}\n${params.data.proportion}%`, bottom = 40, color = defaultColor, showCount = true, title, labelShow = true, roseType, defaultPitch, legend = true, position = 'outside', center = ['50%', '50%'], fonstColor = '#919191', radius = ['60%', '80%'], isFetching, onClick }) => {
  const hollowPieOption = (hollowPieData) => ({
    tooltip: {
      formatter: params => (showCount ? `${params.marker}${params.name}<br/>${params.value.toLocaleString()}
      ${hollowPieData.unit}\u3000\u3000${params.data.proportion}%` : `${params.marker}${params.name}\u3000${params.data.proportion}%`),
      axisPointer: {
        z: 1000,
      },
    },
    legend: legend && {
      type: 'plain',
      orient: 'horizontal',
      icon,
      itemHeight: 8,
      itemWidth: 8,
      align: 'auto',
      bottom: 10,
      textStyle: {
        color: '#919191',
        fontSize: 10,
      },
      data: hollowPieData?.data?.map(item => item.name),
    },
    title: titleShow && {
      show: true,
      text: title,
      textStyle: {
        color: '#919191',
        fontSize: 12,
      },
      x: 'center',
      y: '40%',
    },
    series: [
      {
        name: hollowPieData.name,
        type: 'pie',
        roseType,
        center,
        // 无论占比是多少都不隐藏
        minShowLabelAngle: 0.1,
        radius,
        // 饼图伸出的线 1长度和2阶长度
        labelLine: {
          length: 10,
          length2: 10,
        },
        color,
        bottom,
        left: 0,
        label: {
          show: labelShow,
          position,
          fontSize: 10,
          color: fonstColor,
          formatter: labelName,
          overflow: 'none',
          textBorderColor: 'auto',
          textBorderWidth: position === 'inside' ? 2 : 0,
        },
        legendHoverLink: true,
        selectedMode: 'single',
        data: hollowPieData.data || [],
      },
    ],
  });
  const onEvents = useMemo(() => ({
    click: (e) => onClick && onClick(e),
  }), [data]);

  const chartEl = useMemo(() => {
    if (!data || data?.data?.length > 0) {
      return <ECharts
        style={{
          height: '100%',
          width: '100%',
        }}
        onEvents={onEvents}
        option={hollowPieOption(data || {})}
        onChartReady={(e) => {
          if (defaultPitch) {
            e.dispatchAction({
              type: 'select',
              seriesIndex: 0,
              dataIndex: 0,
            });
          }
        }}
      />;
    }
    return <ChartNoData />;
  }, [data]);

  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {
        chartEl
      }
    </Spin>
  );
};

export default Hollowpie;
