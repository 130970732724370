import React from 'react';
import { Tree } from 'antd';
import styles from './index.module.less';

/**
 * 自定义的树形选择器（单选）
 * @param filters
 * @param setSelectedKeys
 * @param selectedKeys
 * @param confirm
 * @returns {JSX.Element}
 * @constructor
 */
function CustomTreeFilter({
  filters,
  // prefixCls,
  // visible,
  setSelectedKeys,
  selectedKeys,
  confirm,
  multiple,
  // clearFilters,
}) {
  return (
    <div className={styles.filter}>
      <Tree
        checkable={multiple || false}
        defaultExpandedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        checkedKeys={selectedKeys}
        onSelect={keys => {
          setSelectedKeys(keys);
          // 如果想要点击筛选即时生效，不关闭窗口
          // confirm(false);
          // 筛选即时生效，关闭窗口
          // confirm();
          if (multiple) {
            confirm(false);
          } else {
            confirm();
          }
        }}
        onCheck={keys => {
          setSelectedKeys(keys);
          // 如果想要点击筛选即时生效，不关闭窗口
          // confirm(false);
          // 筛选即时生效，关闭窗口
          // confirm();
          if (multiple) {
            confirm(false);
          } else {
            confirm();
          }
        }}
        selectable={true}
        treeData={filters}
        multiple={multiple || false}
        className={styles.tree}
      />
    </div>
  );
}

export default CustomTreeFilter;
