// 短板诊断
import React, { useState, useEffect } from 'react';
import { Select, Tree } from 'antd';
import {
  useGetParkShortPlateDiagnosisQuery,
  useGetParkShortPlateDiagnosisSubgraphQuery,
} from '../../../../../../service/parkTool';
import { useGetIndustryTreeQuery } from '../../../../../../service/public';
import ChartList from '../components/ChartList';
import IndustrialChainGraph from './IndustrialChainGraph';
import style from './index.module.less';

const Weakness = (props) => {
  const { parkName, parkCode, parkTitle } = props;
  const [link, setLink] = useState('一级环节');
  const [level, setLevel] = useState(1);
  const [industryCode, setIndustryCode] = useState(null);
  const [industry, setIndustry] = useState({ title: '全部', code: 'all' });
  // 图形数据
  const { data, isFetching } = useGetParkShortPlateDiagnosisQuery({
    area_key: parkTitle === '园区总览' ? parkCode : undefined,
    park_id_list: parkTitle === '园区总览' ? undefined : [parkCode],
    industry_code: industryCode,
  }, { skip: !parkCode || !industryCode });
  // 环形子图
  const { data: childrenData } = useGetParkShortPlateDiagnosisSubgraphQuery({
    area_key: parkTitle === '园区总览' ? parkCode : undefined,
    park_id_list: parkTitle === '园区总览' ? undefined : [parkCode],
    industry_code: industryCode,
    level,
  }, { skip: !parkCode || !industryCode });

  // 产业列表
  const { data: indusrtyDatalist } = useGetIndustryTreeQuery();

  const onSelectRegion = (selectedKeys, info) => {
    setIndustry({
      title: info.node.title,
      code: selectedKeys[0] || 'all',
    });
    const indusrtyCode = selectedKeys[0];
    setIndustryCode(indusrtyCode);
  };

  // 点击母环环节
  const onClick = e => {
    setLevel(e.data.level);
    setLink(e.data.name);
    setLink(`${e.data.name} \n ${e.data.proportion}%`);
  };
  useEffect(() => {
    if (data) {
      const text = data[1].series[0].data[0];
      setLink(`${text.name} \n ${text.proportion}%`);
    }
  }, [indusrtyDatalist, data]);

  useEffect(() => {
    if (indusrtyDatalist) {
      setIndustryCode(indusrtyDatalist.children[0].children[0].value);
      setIndustry({
        title: indusrtyDatalist.children[0].children[0].title,
        code: indusrtyDatalist.children[0].children[0].value,
      });
    }
  }, [indusrtyDatalist]);

  return (
    <div className={style.box}>
      <div className={style.screen}>
        <div className={style.industry}>
          <p>产业</p>
          <Select
            placeholder="请选择行业"
            value={industry.title}
            style={{
              width: 280,
            }}
            dropdownRender={() => (
              indusrtyDatalist && (
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                  <Tree
                    onSelect={onSelectRegion}
                    treeData={[indusrtyDatalist]}
                    defaultExpandedKeys={indusrtyDatalist ? [indusrtyDatalist.key] : []}
                  />
                </div>
              )
            )}
          />
        </div>
      </div>
      <ChartList {...props} data={data} link={link} childrenData={childrenData} isFetching={isFetching} onClick={onClick} />
      <IndustrialChainGraph {...props} parkCode={parkCode} industryCode={industryCode} name="产业链图" parkName={parkName} industry={industry} />
    </div>
  );
};

export default Weakness;
