/*
 *Author: zhaochenyu
 *Description: 标准详情-溯源图
 *Date: 2021/05/07
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { standardColorMap } from '../../../utils/color';
import style from './index.module.less';

function TracingSourceChart(props) {
  const { chartData: { title, data } } = props;
  const [option, setOption] = useState({});

  useEffect(() => {
    const { nodes, links } = data;
    const newNodes = nodes.map(item => ({
      id: item.id,
      name: item.name,
      itemStyle: {
        color: standardColorMap[item.type],
      },
    }));
    const options = {
      animation: false,
      legend: {
        icon: 'circle',
        orient: 'vertical',
        selectMode: false,
        textStyle: {
          fontSize: 12,
          color: '#262D36',
        },
        right: 50,
        bottom: 40,
        itemWidth: 10,
        itemHeight: 10,
        itemGap: 10,
        data: Object.keys(standardColorMap),
      },
      series: {
        type: 'graph',
        layout: 'force',
        symbolSize: 32,
        edgeSymbol: ['none', 'arrow'],
        edgeSymbolSize: 8,
        data: newNodes,
        links,
        categories: Object.keys(standardColorMap).map(item => ({
          name: item,
          itemStyle: {
            color: standardColorMap[item],
          },
        })),
        roam: true,
        lineStyle: {
          width: 2,
          color: '#A9BDD5',
        },
        edgeLabel: {
          show: true,
          formatter: (param) => {
            const { data: { relation } } = param;
            return relation;
          },
        },
        label: {
          show: true,
          position: 'top',
          color: '#3E5C76',
          fontSize: 12,
          fontWeight: 700,
          formatter: (param) => {
            const { name } = param;
            let res = '';
            const copyName = name.split('');
            while (copyName.length > 7) {
              res += `${copyName.splice(0, 7).join('')}\n`;
            }
            if (copyName.length > 0) {
              res = `${res}${copyName.join('')}`;
            }
            return res;
          },
        },
        force: {
          gravity: 0,
          repulsion: 200,
          edgeLength: 150,
        },
      },
    };
    setOption(options);
  }, [data]);
  return (
    <div className={style.all}>
      <div className={style.title}>{title}</div>
      <ReactEcharts
        option={option}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}

export default TracingSourceChart;
