// 行业地位
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import style from './index.module.less';
import { useGetIndustryPositionQuery } from '../../../../../service/companyDetail';

function IndustryStatus(props) {
  const { companyId } = props;

  const { data, isFetching } = useGetIndustryPositionQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });
  const option = useMemo(() => {
    let max = 0;
    const dataList = data?.data?.data_list;
    for (let k = 0; k < dataList?.length; k += 1) {
      if (dataList[k].value > max) {
        max = dataList[k].value;
      }
    }
    const seriesData = dataList?.map(item => (
      {
        name: item.name,
        value: [item.x, item.y, item.value],
        label: {
          show: item.is_tag,
        },
        symbolSize: 8 + (item.value / max) * 40,
      }
    ));

    return {
      grid: {
        right: 60,
      },
      xAxis: {
        name: data?.data?.x_name,
        nameTextStyle: {
          padding: [0, 0, 0, -10],
        },
      },
      yAxis: {
        name: data?.data?.y_name,
        nameTextStyle: {
          padding: [0, 10, 0, 0],
        },
      },
      tooltip: {
        formatter: params => {
          const relVal = `${params.seriesName}<br/>${params.marker}${params.name}\u3000\u3000${params.value?.[2]?.toLocaleString()}`;
          return relVal;
        },
      },
      series: [
        {
          name: data?.title,
          type: 'scatter',
          data: seriesData,
        },
      ],
    };
  }, [data]);
  const chartEl = useMemo(() => {
    if (!data) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (data?.data?.data_list?.length > 0) {
      return (<ECharts
        showLoading={isFetching}
        className={style.chart}
        option={option}
      />);
    }
    return <div className={style.noData}>该企业无此数据图谱</div>;
  }, [data]);

  return (
    <div className={style.all} id="行业地位">
      <div className={style.title}>行业地位</div>
      {
        chartEl
      }
    </div>
  );
}

export default IndustryStatus;
