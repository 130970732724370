// 研发能力
import React, { useState, useEffect, useMemo } from 'react';
import { ECharts, echarts, Table } from '@sqke/design';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';
import style from './index.module.less';
import CompanyLevleTag from '../../../../../CompanyLevleTag';
import { useGetCreativePlatformMapQuery, useGetCreativePlatformTableQuery } from '../../../../../../service/companyDetail';
import { useLazyGetCityJSONQuery } from '../../../../../../service/static-resource';

const list = [
  { title: '全部', value: '0.3' },
  { title: '总部', value: '0' },
  { title: '子公司', value: '1.3' },
];

const MAP_COLOR = [
  '#8CCAFF', '#70BCFF', '#49AAFF', '#3088F5',
  '#2F73FF', '#1961F5', '#0051F7', '#0044D1',
];

function CreativePlatform(props) {
  const { companyId, preventPageto } = props;
  const [option, setOption] = useState({});
  const [active, setActive] = useState('0.3');
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [getCityJSON] = useLazyGetCityJSONQuery();

  const { data, isFetching } = useGetCreativePlatformMapQuery({
    company_id: companyId,
    penetration_grade: active,
  }, {
    skip: !companyId,
  });

  const { pool, series } = data ?? {};
  useEffect(async () => {
    const { data: geojson, isError: isGetJSONError } = await getCityJSON('000000', true);
    if (isGetJSONError) return;
    echarts.registerMap('china', cloneDeep(geojson));

    const _series = series?.map(item => ({
      name: item.name,
      type: 'map',
      geoIndex: 0,
      map: 'china',
      tooltip: {
        formatter: params => {
          const { data: _data } = params;
          if (!_data) return null;
          return `${_data.name}&emsp;&emsp;&emsp;${_data.value}`;
        },
      },
      data: item.data,
    }));
    const seriesData = series?.[0]?.data;
    const maxValue = seriesData?.length > 0 ? Math.max(...seriesData.map(item => item.value)) : 0;
    const minValue = seriesData?.length > 0 ? Math.min(...seriesData.map(item => item.value)) : 0;
    setOption({
      geo: {
        map: 'china',
        roam: true,
        scaleLimit: {
          min: 0.8,
        },
      },
      tooltip: {},
      visualMap: [{
        type: 'piecewise',
        min: minValue,
        max: maxValue,
        left: 60,
        bottom: 80,
        splitNumber: 8,
        inRange: {
          color: MAP_COLOR,
        },
        seriesIndex: 0,
        itemWidth: 22,
        itemHeight: 6,
        itemGap: 3,
        text: [maxValue, minValue],
        textStyle: {
          color: '#0F2849',
          fontSize: 12,
        },
      }],
      series: _series,
    });
  }, [series]);

  const { data: tableData, isFetching: isTableFetching } = useGetCreativePlatformTableQuery({
    company_id: companyId,
    penetration_grade: active,
  }, {
    skip: !companyId,
  });
  const chartEl = () => {
    if (!series) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (series?.length > 0) {
      return (
        <>
          <div className={style.title}>创新平台地图</div>
          <ECharts
            showLoading={isFetching}
            className={style.map}
            option={option}
          />
        </>
      );
    }
    return <></>;
  };
  const companyNumberColumns = () => tableData?.columns.map(item => {
    if (item.dataIndex === 'name') {
      return {
        ...item,
        render: (text, params) => <div className={params.level === 'province' ? style.province : style.city}>{text}</div>,
      };
    }
    return item;
  });

  const investCompanyTable = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <>
          <div className={style.tagBox}>
            <CompanyLevleTag
              list={list}
              active={active}
              setActive={setActive}
            />
          </div>
          <Table
            rowKey={(text, value) => value}
            dataSource={tableData?.dataSource}
            columns={companyNumberColumns()}
            loading={isTableFetching}
            locale={{ emptyText: ' ' }}
            scroll={{ y: 600 }}
            title={() => (
              <>
                <span>创新平台数量</span>
                <span className={style.count}>{tableData?.research_institution_count}</span>
                家
              </>
            )}
            strip
            pagination={false}
          />
        </>
      );
    }
    return null;
  }, [tableData, active]);

  return (
    <div className={style.all} id="分支机构">
      <div className={style.content}>
        <div>
          {
            chartEl()
          }
        </div>
        <div>
          {
            investCompanyTable
          }
        </div>
      </div>
    </div>
  );
}

export default CreativePlatform;
