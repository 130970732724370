/**
 * 工具页
*/
import { apiSlice } from '../slices/api';

const FINESYSTEMREPORT = '/tool/enterprises_contrast';
const COMANY_VALUATION = 'tool/enterprises_valuation';
const SEARCH_MAP = '/tool/search_map';
const ANCHOR_POINT_EXTENSION = '/tool/anchor_point_extension';
const HIGH_EVALUATION = '/tool/high_evaluation';
const CITY_CONTRAST = '/tool/city_contrast';
const LITTLE_GIANT_WISE_TEST = '/little_giant_wise_test/test_recording';
const LITTLE_GIANT_WISE_BASIC_TEST = '/little_giant_wise_test/basic_test';
// const LITTLE_GIANT_WISE_BASIC_TEST_DETAILS = '/little_giant_wise_test/basic_test';

const LITTLE_GIANT_WISE_TEST_TESTING_RANGE = '/little_giant_wise_test/testing_range';
const LITTLE_GIANT_WISE_TEST_BASIC_TES = '/little_giant_wise_test/basic_test';
const LITTLE_GIANT_WISE_TEST_TEST_RECORDING = '/little_giant_wise_test/test_recording';
const LITTLE_GIANT_WISE_TEST_TEST_REPORT = '/little_giant_wise_test/report';

// search

export const reportPageApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSearchFirmNameList: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/find_company_list_by_like_name`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getTree: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/find_index_tree`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getIndexValue: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/find_index_value`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getUserCompanyContrastCollect: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/find_user_company_contrast_collect`,
          method: 'get',
          params: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    addUserCompanyContrastCollect: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/add_user_company_contrast_collect`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getComprehensiveAssessmentResults: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/comprehensive_assessment_results`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    deleteUserCompanyContrastCollect: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/delete_user_company_contrast_collect`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    associationSearch: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/association_search`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getIndustrySelf: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/get_industry_self`,
          method: 'get',
          params: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getIncomeOperateRevenue: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/get_income_operate_revenue`,
          method: 'get',
          params: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getRadarRiskAssessment: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/radar_risk_assessment`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getAssessingTrends: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/assessing_trends`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getCompanyValuationState: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/company_valuation_state`,
          method: 'get',
          params: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getRiskAssessment: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/risk_assessment`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getValuation: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/valuation`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getSearchValuationRecordCompany: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/search_valuation_record_company`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getSearchValuationRecordMyself: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/search_valuation_record_myself`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    getAddValuationRecord: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/add_valuation_record`,
          method: 'post',
          body: arg,
          // headers: { username: '13384306481', id: 'd97f00f4cf5d11ecacb30242ac110002', roleIds: [122] },
        };
      },
    }),
    delValuationRecord: builder.query({
      query(arg) {
        return {
          url: `${COMANY_VALUATION}/del_valuation_record`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 寻企地图列表 company_list
    searchMapCompanyList: builder.query({
      query(arg) {
        return {
          url: `${SEARCH_MAP}/company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 统计图 statistical_chart
    getStatisticalChart: builder.query({
      query(arg) {
        return {
          url: `${SEARCH_MAP}/statistical_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 寻企地图企业列表总数 company_list_count
    getCompanyListCount: builder.query({
      query(arg) {
        return {
          url: `${SEARCH_MAP}/company_list_count`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 搜索企业
    getFindCompanyListByLikeName: builder.query({
      query(arg) {
        return {
          url: `${FINESYSTEMREPORT}/find_company_list_by_like_name`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 模糊查询企业 anchor_point_extension ANCHOR_POINT_EXTENSION
    fuzzyQuery: builder.query({
      query(arg) {
        return {
          url: `${ANCHOR_POINT_EXTENSION}/fuzzy_query`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 关联关系 association_relation
    getAssociationRelation: builder.query({
      query(arg) {
        return {
          url: `${ANCHOR_POINT_EXTENSION}/association_relation`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业列表 company_list
    getExpandCompanyList: builder.query({
      query(arg) {
        return {
          url: `${ANCHOR_POINT_EXTENSION}/company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业总数 company_list_count
    anchorPointExtensionCompanyListCount: builder.query({
      query(arg) {
        return {
          url: `${ANCHOR_POINT_EXTENSION}/company_list_count`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 成立年限
    getEstablishmentPeriod: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/establishment_period`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 提交企业值表评测表单
    putAssessCompanyIndex: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/assess_company_index`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 承担科研情况 undertake_research_institutes
    getUndertakeResearchInstitutes: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/undertake_research_institutes`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 近三年中标数量 bids_number
    getBidsNumber: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/bids_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 自主知识产权proprietary_intellectual_property_rights
    getProprietaryIntellectualPropertyRights: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/proprietary_intellectual_property_rights`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 近三年是否获得荣誉 get_financing_or_not
    getFinancingOrNot: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/get_financing_or_not`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专利授权总量 total_number_of_patents_granted
    getTotalNumberOfPatentsGranted: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/total_number_of_patents_granted`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 近三年专利总量 total_number_of_invent_patents_granted
    getTotalNumberOfInventPatentsGranted: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/total_number_of_invent_patents_granted`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 近三年外观专利 total_number_of_other_patents_granted
    getTotalNumberOfOtherPatentsGranted: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/total_number_of_other_patents_granted`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 近三年起草标准数量three_years_drafting_standard_number
    getThreeYearsDraftingStandardNumber: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/three_years_drafting_standard_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 近三年子公司数量 three_years_develop_branch_number
    getThreeYearsDevelopBranchNumber: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/three_years_develop_branch_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 近三年注册资本 registered_capital_increase
    getRegisteredCapitalIncrease: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/registered_capital_increase`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 社保人数 social_security_number
    getSocialSecurityNumber: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/social_security_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 评测记录 appraisal_record
    getAppraisalRecord: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/appraisal_record`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 删除评测记录 delete_record
    deleteRecord: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/delete_record`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 获取查询指标列表
    getFindIndexTree: builder.query({
      query(arg) {
        return {
          url: `${CITY_CONTRAST}/find_index_tree`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询城市指标
    findCityIndexValue: builder.query({
      query(arg) {
        return {
          url: `${CITY_CONTRAST}/find_index_value`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 收藏城市对比查询记录 add_user_city_contrast_record
    addUserCityContrastRecord: builder.query({
      query(arg) {
        return {
          url: `${CITY_CONTRAST}/add_user_city_contrast_record`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 城市对比收藏记录 find_user_company_contrast_collect
    getCityComparisonTable: builder.query({
      query(arg) {
        return {
          url: `${CITY_CONTRAST}/find_user_company_contrast_collect`,
          method: 'get',
          params: arg,
        };
      },
    }),
    // 删除收藏记录 delete_user_company_contrast_collect
    deleteUserCityContrastCollect: builder.query({
      query(arg) {
        return {
          url: `${CITY_CONTRAST}/delete_user_company_contrast_collect`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询企业指标find_company_index
    findCompanyIndex: builder.query({
      query(arg) {
        return {
          url: `${HIGH_EVALUATION}/find_company_index`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 小巨人智测-文件记录
    testFileRecord: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST}/file_record`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 条件测评
    conditionTesting: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST_TESTING_RANGE}/condition_testing`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 资质
    qualificationSelect: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST_TESTING_RANGE}/qualification_select`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 小巨人智测-删除文件记录
    testDeleteFileRecord: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST}/delete_file_record`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 条件倒出
    conditionTestingExport: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST_TESTING_RANGE}/condition_testing_export`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 小巨人智测-测评记录
    intelligentTestingTestRecord: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST}/test_record`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 上传列表后返回的表格
    uploadTestingDataList: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST_TESTING_RANGE}/upload_testing_data_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 小巨人智测-删除测评记录
    intelligentTestingDeleteTestRecord: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST}/delete_test_record`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 提交测评
    saveBasicTest: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST_BASIC_TES}/save_basic_test`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 小巨人智测-测评详情
    intelligentTestingTestRecordDetail: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST}/test_record_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 小巨人智测-保存测评结果
    intelligentTestingSaveBasicTest: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_BASIC_TEST}/save_basic_test`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 小巨人智测-雷达图 /industry_knowledge_engine/v2/little_giant_wise_test/basic_test/radar_chart
    radarChart: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST_TEST_RECORDING}/radar_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 详情雷达图
    detailsRadarChart: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_BASIC_TEST}/radar_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 雷达图描述 /industry_knowledge_engine/v2/little_giant_wise_test/basic_test/description
    description: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_BASIC_TEST}/description`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 测评结果
    getTestRecordDetail: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST_TEST_RECORDING}/test_record_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 保存查询条件 /industry_knowledge_engine/v2/little_giant_wise_test/report/save_report
    saveReport: builder.query({
      query(arg) {
        return {
          url: `${LITTLE_GIANT_WISE_TEST_TEST_REPORT}/save_report`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创建会话 /industry_knowledge_engine/chat/create_dialogue
    createDialogue: builder.query({
      query(arg) {
        return {
          url: 'chat/create_dialogue',
          method: 'get',
          params: arg,
        };
      },
    }),
    // 添加会话记录 /industry_knowledge_engine/chat/add_dialogue_record
    addDialogueRecord: builder.query({
      query(arg) {
        return {
          url: 'chat/add_dialogue_record',
          method: 'get',
          params: arg,
        };
      },
    }),
    // 点赞 /industry_knowledge_engine/chat/update_dialogue_like_status
    updateDialogueLikeStatus: builder.query({
      query(arg) {
        return {
          url: 'chat/update_dialogue_like_status',
          method: 'get',
          params: arg,
        };
      },
    }),
  }),
});

export const {
  // getSearchFirmNameList
  useLazyGetSearchFirmNameListQuery,
  useLazyGetTreeQuery,
  useLazyGetIndexValueQuery,
  useLazyGetUserCompanyContrastCollectQuery,
  useLazyAddUserCompanyContrastCollectQuery,
  useLazyGetComprehensiveAssessmentResultsQuery,
  useLazyDeleteUserCompanyContrastCollectQuery,
  useLazyAssociationSearchQuery,
  useLazyGetIndustrySelfQuery,
  useLazyGetIncomeOperateRevenueQuery,
  useLazyGetRadarRiskAssessmentQuery,
  useLazyGetAssessingTrendsQuery,
  useLazyGetCompanyValuationStateQuery,
  useLazyGetRiskAssessmentQuery,
  useLazyGetAddValuationRecordQuery,
  useLazyGetValuationQuery,
  useLazyGetSearchValuationRecordCompanyQuery,
  useLazyGetSearchValuationRecordMyselfQuery,
  useLazyDelValuationRecordQuery,
  useSearchMapCompanyListQuery,
  useGetStatisticalChartQuery,
  useGetCompanyListCountQuery,
  useGetFindCompanyListByLikeNameQuery,
  useLazyGetFindCompanyListByLikeNameQuery,
  useFuzzyQueryQuery,
  useGetAssociationRelationQuery,
  useGetExpandCompanyListQuery,
  useAnchorPointExtensionCompanyListCountQuery,
  useGetEstablishmentPeriodQuery,
  usePutAssessCompanyIndexQuery,
  useGetUndertakeResearchInstitutesQuery,
  useGetBidsNumberQuery,
  useGetProprietaryIntellectualPropertyRightsQuery,
  useGetFinancingOrNotQuery,
  useGetTotalNumberOfPatentsGrantedQuery,
  useGetTotalNumberOfInventPatentsGrantedQuery,
  useGetTotalNumberOfOtherPatentsGrantedQuery,
  useGetThreeYearsDraftingStandardNumberQuery,
  useGetThreeYearsDevelopBranchNumberQuery,
  useGetRegisteredCapitalIncreaseQuery,
  useGetSocialSecurityNumberQuery,
  useGetAppraisalRecordQuery,
  useLazyDeleteRecordQuery,
  useGetFindIndexTreeQuery,
  useLazyGetFindIndexTreeQuery,
  useLazyFindCityIndexValueQuery,
  useLazyAddUserCityContrastRecordQuery,
  useGetCityComparisonTableQuery,
  useLazyDeleteUserCityContrastCollectQuery,
  useFindCompanyIndexQuery,
  useLazyTestFileRecordQuery,
  useLazyTestDeleteFileRecordQuery,
  useLazyIntelligentTestingTestRecordQuery,
  useLazyIntelligentTestingDeleteTestRecordQuery,
  useLazyIntelligentTestingTestRecordDetailQuery,
  useLazyIntelligentTestingSaveBasicTestQuery,
  useLazyConditionTestingQuery,
  useLazyConditionTestingExportQuery,
  useLazyUploadTestingDataListQuery,
  useLazySaveBasicTestQuery,
  useGetTestRecordDetailQuery,
  useLazySaveReportQuery,
  useLazyCreateDialogueQuery,
  useLazyAddDialogueRecordQuery,
  useLazyUpdateDialogueLikeStatusQuery,
  useQualificationSelectQuery,
  useRadarChartQuery,
  useDetailsRadarChartQuery,
  useDescriptionQuery,
} = reportPageApi;
