import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Select, Tree, message } from 'antd';
import classNames from 'classnames';
import style from './index.module.less';
import Map from '../components/Map';
import { useQuery } from '../../../hooks/url';
import Table from '../../../components/TableV2';
import DataSearchButton from '../../Industry/components/DataSearchButton';
import Score from '../../../components/Score';
import TreeMap from '../../../components/Charts/TreeMap';
import FunnelChart from '../../../components/Charts/FunnelChart';
import HollowPie from '../../../components/Charts/HollowPie';
import { openNewPage } from '../../../utils/urlMethods';
import { dataDownload } from '../../../api/mySelf';
import ModalTable from '../components/ModalTable';
import Drawer from '../components/Drawer';
import { useGetCompanySpecialTagsQuery, useGetIndustryTreeQuery } from '../../../service/public';
import { useSearchMapCompanyListQuery, useGetStatisticalChartQuery, useGetCompanyListCountQuery } from '../../../service/tool';
// import { useTableData } from './useGetData';
const sortMap = {
  descend: 'desc',
  ascend: 'asc',
};
const SearchCompanyMap = () => {
  const mapRef = useRef();
  const modalRef = useRef();
  const query = useQuery();
  const [unfoldState, setUnfoldState] = useState(false);
  const [open, setOpen] = useState(false);
  const [btn, setBtn] = useState(false);
  const [active, setActive] = useState(['不限']);
  const [industry, setIndustry] = useState({ title: '全部', code: 'all' });
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState('');
  const [searchKey, setSearchKey] = useState([]);
  const [distance, setDistance] = useState(null);
  const [sortKey, setSortKey] = useState('');
  const [sortValue, setSortValue] = useState('descend');
  const [patentTableOpen, setPatentTableOpen] = useState(false);
  const [patentId, setPatentId] = useState(null);
  const [maxDistance, setMaxDistance] = useState(null);
  const [treeKey, setTreeKey] = useState(1);

  const menu = localStorage.getItem('pm') ? JSON.parse(localStorage.getItem('pm')) : {};
  const reqState = () => {
    if (searchType && searchKey?.length > 0) return false;
    if (searchType && distance) return false;
    return true;
  };
  const parameter = useMemo(() => {
    const cityCode = query.get('cityCode');
    if (cityCode) {
      return {
        cityCode: window.atob(query.get('cityCode')),
        title: query.get('title'),
        key: query.get('key'),
        cityName: query.get('cityName'),
        isProvinceDirectly: JSON.parse(query.get('is-province-directly')),
      };
    }
    return undefined;
  }, [query]);
  const { data: categoryList } = useGetCompanySpecialTagsQuery();
  const { data: industryTreeData } = useGetIndustryTreeQuery();
  const { data: tableData = {}, isFetching: tableIsFetching, error } = useSearchMapCompanyListQuery({
    search_type: searchType,
    search_key: searchKey,
    page_size: 10,
    page_number: page,
    distance,
    industry_code: industry.code,
    special_tag: active,
    sort_key: sortKey,
    sort_value: sortValue && sortMap?.[sortValue],
  }, { skip: reqState() });
  const { data: chartData, isFetching: chartIsFetching } = useGetStatisticalChartQuery({
    area_key: parameter.cityCode,
    search_type: searchType,
    search_key: searchKey,
    distance,
    industry_code: industry.code,
    special_tag: active,
  }, { skip: reqState() });
  const { data: listCompanyList } = useGetCompanyListCountQuery({
    search_type: searchType,
    search_key: searchKey,
    distance,
  }, { skip: reqState() });
  // 更新请求条件
  const updateSearch = ({ key, radius, type }) => {
    setSearchType(type);
    setSearchKey(key);
    setDistance(radius);
    setActive(['不限']);
    setIndustry({ title: '全部', code: 'all' });
    setPage(1);
  };
  const download = () => {
    dataDownload({
      data_key: 'tool_search_map_company_list',
      params: {
        search_type: searchType,
        search_key: searchKey,
        page_size: 10,
        page_number: page,
        distance,
        industry_code: industry.code,
        special_tag: active,
        sort_key: sortKey,
        sort_value: sortValue && sortMap?.[sortValue],
      },
      start_index: 1,
      end_index: 1000,
    }, '表格');
  };
  // 跳转企业详情
  const onCompanyClick = (companyId) => {
    if (!companyId) return;
    openNewPage('companyDetail', { id: companyId });
  };
  useEffect(() => {
    if (tableData.dataSource) {
      mapRef.current.generateCompanyLocation(tableData.dataSource);
      setBtn(true);
    }
  }, [tableData]);
  useEffect(() => {
    if (error) {
      message.warning(error.data.message);
    }
  }, [error]);
  const tagHandle = (value) => {
    setPage(1);
    if (value === '不限') {
      setActive(['不限']);
      return;
    }
    const newActive = active.slice();
    if (newActive.indexOf(value) > -1) {
      newActive.splice(newActive.indexOf(value), 1);
    } else {
      newActive.push(value);
    }
    if (newActive.length < 1) {
      setActive(['不限']);
      return;
    }
    if (newActive.length > 1 && newActive.includes('不限')) {
      setActive(newActive.filter(item => item !== '不限'));
      return;
    }
    setActive(newActive);
  };
  // const showDrawer = () => {
  //   setOpen(!open);
  //   setPatentTableOpen(false);
  // };
  // const onClose = () => {
  //   setOpen(false);
  //   setPatentTableOpen(false);
  // };
  const onSelectRegion = (selectedKeys, info) => {
    setIndustry({
      title: info.node.title,
      code: selectedKeys[0] || 'all',
    });
  };
  const Tablehandle = (item) => ({
    index: {
      width: 50,
      className: style.index,
    },
    company_name: {
      width: 200,
      ellipsis: true,
      className: style.name,
      onCell: (record, rowIndex) => ({
        onClick: () => { onCompanyClick(record.company_id); },
      }),
    },
    special_tag: {
      render: text => text || '-',
    },
    head_office: {
      width: 200,
      ellipsis: true,
      render: (text) => (
        <div className={style.headOffice}>
          {
            text.map(({ name }) => <span key={name} title={name}>{name}</span>)
          }
        </div>
      ),
    },
    reg_capital_std: {
      sortDirections: ['ascend', 'descend'],
      width: 100,
      ellipsis: true,
      sorter: true,
    },
    establish_date: {
      ellipsis: true,
      sorter: true,
      width: 100,
      sortDirections: ['ascend', 'descend'],
      className: style.number,
    },
    approval_patent_count: {
      ellipsis: true,
      sorter: true,
      width: 100,
      sortDirections: ['ascend', 'descend'],
      className: style.number,
      render: (text, record) => (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
          onClick={() => {
            setPatentId(record.company_id);
            setPatentTableOpen(true);
            modalRef.current?.setPage(1);
          }}
        />
      ),
    },
    total_point: {
      width: 100,
      render: text => <Score score={text} />,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
  });
  return (
    <div className={style.box}>
      <Map
        updateSearch={updateSearch}
        setSearchType={setSearchType}
        setSearchKey={setSearchKey}
        setDistance={setDistance}
        setOpen={setOpen}
        setMaxDistance={setMaxDistance}
        setTreeKey={setTreeKey}
        parameter={parameter}
        ref={mapRef}
      />
      <Drawer
        open={open}
        setOpen={setOpen}
        btn={btn}
        title={
          <div style={{ fontSize: '14px', color: '#0F2849', paddingLeft: '18px' }} >
            圈定范围
            <span style={{ color: '#1961F5' }}>
              {maxDistance > 20 ? 20 : maxDistance}
              {maxDistance ? 'km' : '--'}
            </span>
            以内公司
          </div>
        }
      >
        <div className={style.content}>
          <div className={style.industry}>
            <div>
              产 &nbsp; &nbsp; &nbsp; &nbsp; 业
            </div>
            <Select
              placeholder="请选择行业"
              value={industry.title}
              style={{
                width: 280,
              }}
              dropdownRender={() => (
                industryTreeData && (
                  <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <Tree
                      key={treeKey}
                      onSelect={onSelectRegion}
                      treeData={[industryTreeData]}
                      defaultExpandedKeys={industryTreeData ? [industryTreeData.key] : []}
                    />
                  </div>
                )
              )}
            />
          </div>
          <div className={style.category}>
            <p>资质类别</p>
            <div className={style.categoryList}>
              <div className={classNames({ [style.active]: active.includes('不限') })} onClick={() => { tagHandle('不限'); }}>不限</div>
              {
                categoryList?.map(item => <div className={classNames({ [style.active]: active.includes(item.value) })} key={item.value} onClick={() => { tagHandle(item.value); }}>{item.title}</div>)
              }
            </div>
          </div>
          <div className={style.table}>
            <div className={style.tableTitle}>
              <div>
                为您找到
                <span>
                  {(error || !tableData) ? '-' : tableData.count}
                </span>
                家符合条件的企业
              </div>
              <div>
                {
                  menu.searchCompanyMap && (
                    <>
                      <span className={style.vip} />
                      <span>企业用户/数据导出支持1000条</span>
                      <div className={style.download} onClick={download}>数据导出</div>
                    </>
                  )
                }
              </div>
            </div>
            <Table
              sortKey={sortKey}
              sortValue={sortValue}
              Tablehandle={Tablehandle}
              data={error ? {} : tableData}
              isFetching={tableIsFetching}
              onChange={(_, filters, sorter) => {
                setSortKey(sorter.field);
                setSortValue(sorter.order);
              }}
              pagination={{
                current: page,
                pageSize: 10,
                showQuickJumper: true,
                showSizeChanger: false,
                total: error ? 0 : tableData?.total,
                onChange: (current) => {
                  setPage(current);
                },
                // onShowSizeChange: (size) => {
                //   setPageSize(size);
                // },
              }}
            />
          </div>
          <div className={style.chartListTitle}>
            <span>企业分析图谱</span>
            <div onClick={() => { setUnfoldState(!unfoldState); }}>
              <span>{unfoldState ? '展开' : '收起'}</span>
              <span className={unfoldState ? style.unfold : style.packUp} />
            </div>
          </div>
          <div className={style.chartList} style={{ height: unfoldState ? '0px' : '', overflow: unfoldState ? 'hidden' : '' }}>
            <div className={style.chartBox}>
              <div className={style.chartTitle}>
                {chartData?.[0].title}
              </div>
              <div className={style.chart}>
                <TreeMap data={!error ? (chartData?.[0].series?.[0].data) : []} isFetching={chartIsFetching} />
              </div>
            </div>
            <div className={style.chartBox}>
              <div className={style.chartTitle}>
                {chartData?.[1].title}
              </div>
              <div className={style.chart}>
                <FunnelChart data={!error ? (chartData?.[1].series[0].data) : []} isFetching={chartIsFetching} />
              </div>
            </div>
            <div className={style.chartBox}>
              <div className={style.chartTitle}>
                {chartData?.[2].title}
              </div>
              <div className={style.chart}>
                <HollowPie data={!error && chartData?.[2].series?.[0]} radius={[0, '80%']} isFetching={chartIsFetching} />
              </div>
            </div>
            <div className={style.chartBox}>
              <div className={style.chartTitle}>
                {chartData?.[3].title}
              </div>
              <div className={style.chart}>
                <HollowPie data={!error && chartData?.[3].series?.[0]} radius={[0, '80%']} isFetching={chartIsFetching} />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      {
        patentTableOpen && (
          <div className={style.patent}>
            <ModalTable id={patentId} ref={modalRef} onClick={() => { setPatentTableOpen(false); }} />
          </div>
        )
      }
    </div>
  );
};

export default SearchCompanyMap;
