/**
 * 城市频道页面相关接口
 */
import { apiSlice } from '../slices/api';
// /industry_knowledge_engine/v2/dataAnalysis/catalogue/side_table_contents
const DATAANALYSIS = '/dataAnalysis';

export const chartTableToolApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 组件目录
    getSideTableContents: builder.query({
      query(arg) {
        return {
          url: `${DATAANALYSIS}/catalogue/side_table_contents`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 图形 industry_knowledge_engine/v2/dataAnalysis/describe/common_chart
    getCommonChart: builder.query({
      query(arg) {
        return {
          url: `${DATAANALYSIS}/describe/common_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业分布描述 industry_knowledge_engine/v2/dataAnalysis/describe/enterprise_provinces_distribution
    getDistribution: builder.query({
      query(arg) {
        return {
          url: `${DATAANALYSIS}/describe/${arg.url}`,
          method: 'post',
          body: arg.data,
        };
      },
    }),
    // 图转表 /industry_knowledge_engine/v2/dataAnalysis/analysis/structural_transfer
    getStructuralTransfer: builder.query({
      query(arg) {
        return {
          url: `${DATAANALYSIS}/analysis/structural_transfer`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetSideTableContentsQuery,
  useLazyGetSideTableContentsQuery,
  useLazyGetCommonChartQuery,
  useGetCommonChartQuery,
  useGetStructuralTransferQuery,
  useLazyGetStructuralTransferQuery,
  useGetDistributionQuery,
  useLazyGetDistributionQuery,
} = chartTableToolApi;
