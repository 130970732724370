import React, { useMemo, useState, useCallback, useRef, useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { useQuery } from '../../hooks/url';
import DataTab from '../../components/DataTab';
import Banner from '../../components/Bannner';
import SideNavi from '../../components/SideNavi';
import ReportScheme from './components/ReportScheme';
import RankingScheme from './components/RankingScheme';
import DataScheme from './components/DataScheme';
import Industry from './components/Industry';
import styles from './index.module.less';
import {
  useGetCityChannelStatisticQuery,
} from '../../service/city-channel';
// import { useRecordingSpotQuery } from '../../service/public';

const SIDE_NAVI_OPTIONS = [
  { key: 'graph', name: '产业' },
  { key: 'report', name: '报告' },
  { key: 'ranking', name: '榜单' },
  { key: 'data', name: '数据' },
];
/*
  产业概览、城市对标、产业分析、企业分布、创新机构、政策清单
*/
let INDUSTRY_TABS = [
  { key: 'cityOverview', name: '产业概览' },
  { key: 'urbanBenchmarking', name: '城市对标' },
  { key: 'enterpriseDistribution', name: '企业分布' },
  { key: 'industryAnalysis', name: '产业分析' },
  { key: 'industrialCarrier', name: '产业载体' },
  { key: 'policyList', name: '政策清单' },
];

function City() {
  const boxRef = useRef(null);
  const sectionRef = useRef();
  const query = useQuery();
  const history = useHistory();
  const tabIndex = query.get('type');
  const dataType = query.get('data-type');
  const permission = JSON.parse(localStorage.getItem('pm'));
  const cityGraphGroup = permission?.cityGraph || [];
  const city = useMemo(() => {
    const code = query.get('city-code');
    if (code) {
      const _code = window.atob(query.get('city-code'));
      const title = query.get('city-title');
      const isProvinceDirectly = JSON.parse(query.get('is-province-directly'));
      if (isProvinceDirectly) {
        INDUSTRY_TABS = INDUSTRY_TABS.filter(item => item.key !== 'enterpriseDistribution');
      } else {
        INDUSTRY_TABS = [
          { key: 'cityOverview', name: '产业概览' },
          { key: 'urbanBenchmarking', name: '城市对标' },
          { key: 'enterpriseDistribution', name: '企业分布' },
          { key: 'industryAnalysis', name: '产业分析' },
          { key: 'industrialCarrier', name: '产业载体' },
          { key: 'policyList', name: '政策清单' },
        ];
      }
      return {
        code: _code,
        title,
        isProvinceDirectly,
      };
    }
    return undefined;
  }, [query]);
  const graphTabs = useMemo(() => {
    const arr = [];
    INDUSTRY_TABS.forEach(item => {
      if (cityGraphGroup.find(tab => tab === item.key)) {
        arr.push(item);
      }
    });
    return arr;
  }, [INDUSTRY_TABS, cityGraphGroup]);
  const [industryTabIndex, setIndustryTabIndex] = useState(graphTabs[0]);

  const { data: dataTabs = [] } = useGetCityChannelStatisticQuery({
    area_key: city.code,
  }, {
    skip: !city.code,
  });

  const updateIndustryTabIndex = (index) => {
    const anchorElement = document.getElementById(`${graphTabs[index].key}`); // 需要定位看到的锚点元素
    const topOffsetTop = document.getElementById(graphTabs[0].key)?.offsetTop;
    setIndustryTabIndex(graphTabs[index]);
    boxRef?.current.scrollTo({
      top: anchorElement.offsetTop - topOffsetTop,
      behavior: 'smooth',
    });
  };

  const onDataTabsClick = useCallback(({ type, value }) => {
    query.set('type', 'data');
    query.set('data-type', type);
    query.set('value', value);
    history.push({ search: query.toString() });
  }, [query]);

  const dataTabsComponents = useMemo(() => dataTabs.map((
    {
      key,
      title,
      value,
      unit,
    },
  ) => (
    <DataTab
      key={key}
      type={key}
      name={title}
      value={value}
      unit={unit}
      active={key === dataType}
      onClick={onDataTabsClick}
      className={styles.dataTab}
    />
  )), [dataTabs, onDataTabsClick, dataType]);

  useLayoutEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollTo(0, 0);
    }
  }, [dataType]);
  if (!city) return null;
  useEffect(() => {
    const target1 = document.getElementById(graphTabs[0]?.key)?.offsetTop;
    const target2 = document.getElementById(graphTabs[1]?.key)?.offsetTop - target1;
    const target3 = document.getElementById(graphTabs[2]?.key)?.offsetTop - target1;
    const target4 = document.getElementById(graphTabs[3]?.key)?.offsetTop - target1;
    const target5 = document.getElementById(graphTabs[4]?.key)?.offsetTop - target1;
    const target6 = document.getElementById(graphTabs[5]?.key)?.offsetTop - target1;
    const scroll = () => {
      if (boxRef?.current?.scrollTop < target2) {
        setIndustryTabIndex(graphTabs[0]);
      } else if (boxRef?.current?.scrollTop > target2 && boxRef?.current?.scrollTop < target3) {
        setIndustryTabIndex(graphTabs[1]);
      } else if (boxRef?.current?.scrollTop > target3 && boxRef?.current?.scrollTop < target4) {
        setIndustryTabIndex(graphTabs[2]);
      } else if (boxRef?.current?.scrollTop > target4 && boxRef?.current?.scrollTop < target5) {
        setIndustryTabIndex(graphTabs[3]);
      } else if (boxRef?.current?.scrollTop > target5 && boxRef?.current?.scrollTop < target6) {
        setIndustryTabIndex(graphTabs[4]);
      } else if (boxRef?.current?.scrollTop > target6) {
        setIndustryTabIndex(graphTabs[5]);
      }
    };
    boxRef?.current?.addEventListener('scroll', debounce(scroll, 200));
    return () => {
      boxRef?.current?.removeEventListener('scroll', scroll);
      boxRef.current = null;
    };
  }, []);
  return (
    <div className={styles.page}>
      <Banner title={city.title} className={styles.banner} />
      {
        tabIndex === 'data' && (
          <div className={styles.dataTabs}>
            {dataTabsComponents}
          </div>
        )
      }
      <main className={styles.main} ref={boxRef}>
        <div className={styles.content}>
          <SideNavi
            options={SIDE_NAVI_OPTIONS}
            selectedKey={tabIndex}
            onClick={({ key, name }) => {
              query.set('type', key);
              if (key === 'data') {
                query.set('data-type', '企业');
                query.set('value', dataTabs[0].value);
              } else {
                query.delete('data-type');
                query.delete('value');
              }
              history.push({ search: query.toString() });
            }}
            clasName={styles.side}
            vLineClassName={styles.sideLine}
          />
          <section className={styles.section} ref={sectionRef} style={{ marginTop: tabIndex === 'data' ? '68px' : '0' }} >
            {
              tabIndex === 'graph' && (
                <Industry
                  cityCode={city.code}
                  cityName={city.title}
                  title={industryTabIndex?.key}
                  onClick={updateIndustryTabIndex}
                  industryTabs={graphTabs}
                  cityGraphGroup={cityGraphGroup} // 权限相关
                  industryTabName={industryTabIndex?.name}
                  isProvinceDirectly={city.isProvinceDirectly}
                />
              )
            }
            {
              tabIndex === 'report' && (
                <ReportScheme
                  cityCode={city.code}
                  cityName={city.title}
                />
              )
            }
            {
              tabIndex === 'ranking' && (
                <RankingScheme
                  cityCode={city.code}
                />
              )
            }
            {
              tabIndex === 'data' && (
                <DataScheme
                  cityCode={city.code}
                  title={dataTabs?.find(item => item.key === dataType)?.title}
                  type={dataType}
                />
              )
            }
          </section>
        </div>
      </main>
    </div>
  );
}

export default City;
