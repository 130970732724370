import React, { useMemo, useState, useEffect } from 'react';
import { Table } from '@sqke/design';
import Barandline from '../../../../../Charts/BarAndLine';
import CompanyLevleTag from '../../../../../CompanyLevleTag';
import { useGetFinancialAnalysisAtlasQuery, useGetFinancialAnalysisQuery } from '../../../../../../service/companyDetail';
import style from './index.module.less';

const list = [
  { value: '利润表', title: '利润表' },
  { value: '资产负债表', title: '资产负债表' },
  { value: '现金流量表', title: '现金流量表' },
];

const FinancialAnalysis = ({ companyId }) => {
  const [active, setActive] = useState('利润表');
  const [indexKey, setIndexKey] = useState('');
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  // 切换卡时
  const activeHandel = (value) => {
    setActive(value);
    setPage({
      pageSize: 10,
      pageNumber: 1,
    });
  };

  // 图
  const { data: chartData, isFetching: chartIsFetching } = useGetFinancialAnalysisAtlasQuery({
    company_id: companyId,
    index_key: indexKey,
  }, { skip: !companyId || !indexKey });
  // 表
  const { data: tableData, isFetching: tableIsFetching } = useGetFinancialAnalysisQuery({
    company_id: companyId,
    finance_type: active,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, { skip: !companyId });
  const chart = useMemo(() => {
    if (!chartData || chartData?.series?.length > 0) {
      const newData = chartData?.series.map(item => {
        if (item.name !== '增长率') {
          return {
            ...item,
          };
        }
        return {
          ...item,
          type: 'line',
        };
      });
      return (
        <div className={style.chart}>
          <div className={style.subTitle}>
            {chartData?.title}
          </div>
          <Barandline data={{ ...chartData, series: newData, legendBottom: 16, right: 60, left: 80, rotate: 40, bottom: 76 }} contentTop={76} isFetching={chartIsFetching} />
        </div>
      );
    }
    return <></>;
  }, [chartData]);
  const { columns, dataSource } = tableData || {};
  const _columns = useMemo(() => {
    if (!columns) return [];
    return columns.map(item => {
      if (item.dataIndex === 'index_name') {
        return {
          ...item,
          // 配置单元格点击事件
          onCell: (record, rowIndex) => ({
            onClick: () => setIndexKey(record.index_key),
          }),
        };
      }
      if (item.dataIndex === 'average_growth_rate') {
        return {
          ...item,
          width: 150,
        };
      }
      return { ...item, ellipsis: true };
    });
  }, [columns]);
  useEffect(() => {
    if (dataSource?.length && page.pageNumber === 1) {
      setIndexKey(dataSource[0].index_key);
    }
  }, [tableData]);

  const table = useMemo(() => {
    if (!dataSource || dataSource.length) {
      return (
        <div className={style.table}>
          <div className={style.tag}>
            <CompanyLevleTag
              list={list}
              active={active}
              setActive={activeHandel}
            />
          </div>
          <Table
            rowKey="index_key"
            dataSource={dataSource}
            columns={_columns}
            // title={() => tableData?.title}
            strip
            loading={tableIsFetching}
            pagination={{
              hideOnSinglePage: true,
              current: page.pageNumber,
              pageSize: page.pageSize,
              total: tableData?.auth_count,
              showSizeChanger: false,
              showQuickJumper: true,
              responsive: true,
              showLessItems: true,
            }}
            onChange={({ current, pageSize }) => {
              setPage({
                pageSize,
                pageNumber: current,
              });
            }}
          />
        </div>

      );
    }
    return null;
  }, [tableData]);
  return (
    <div className={style.markeValue}>
      {
        chart
      }
      {
        table
      }
    </div>
  );
};

export default FinancialAnalysis;
