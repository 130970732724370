import React, { useMemo } from 'react';
import Table from '../../../../components/Table';
import RankingOrder from '../../../../components/RankingOrder';
import { useGetCityChannelSRDIRankQuery } from '../../../../service/city-channel';

const THEAD_TABLE = {
  index: {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    render: text => <RankingOrder order={text} />,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    className: 'clickable',
  },
  type: {
    title: '可达级别',
    dataIndex: 'type',
    key: 'type',
    width: 72,
    ellipsis: true,
  },
};

const COLUMN_KEYS = ['index', 'name', 'type'];

function SRDIRanking(props) {
  const { pageSize, pageNumber, cityCode, onCompanyClick } = props;

  const { data, isFetching } = useGetCityChannelSRDIRankQuery({
    area_key: cityCode,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !cityCode,
  });

  const { total = 0, columns = [], dataSource = [] } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]);
    }
    return columns.map(column => {
      const rowConfig = {
        ...(THEAD_TABLE[column.dataIndex] ?? {}),
        ...column,
      };
      if (rowConfig.dataIndex === 'name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      return rowConfig;
    });
  }, [columns, onCompanyClick]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      current={pageNumber}
      pageSize={pageSize}
      loading={isFetching}
      total={total}
      {...props}
    />
  );
}

export default SRDIRanking;
