import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import { useGetChangeRemovalQuery } from '../../../../../../service/companyDetail';
import style from '../index.module.less';

const Emigration = ({ companyId }) => {
  const [removalPage, setRemovalPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: removalData, isFetching: isRemovalFetching } = useGetChangeRemovalQuery({
    company_id: companyId,
    page_size: removalPage.pageSize,
    page_number: removalPage.pageNumber,
  }, {
    skip: !companyId,
  });
  const migrationColumns = useMemo(() => (
    removalData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          width: 70,
          ...item,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'transition_date') {
        return {
          width: 140,
          ...item,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'type') {
        return {
          width: 264,
          ...item,
          ellipsis: true,
        };
      }
      return {
        ...item,
        // ellipsis: true,
      };
    })
  ), [removalData]);
  const migrationEl = useMemo(() => {
    if (!removalData || removalData?.dataSource.length) {
      return (<Table
        rowKey="index"
        dataSource={removalData?.dataSource}
        columns={migrationColumns}
        locale={{ emptyText: ' ' }}
        title={() => (
          <>
            <span>{removalData?.title}</span>
            <span className={style.count}>{removalData?.count}</span>
          </>
        )}
        strip
        loading={isRemovalFetching}
        pagination={{
          hideOnSinglePage: true,
          current: removalPage.pageNumber,
          pageSize: removalPage.pageSize,
          total: removalData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }) => {
          setRemovalPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />);
    }
    return null;
  }, [removalData]);
  return migrationEl;
};

export default Emigration;
