import { COMPANYDETAIL, USERCUSTOM, INTERACTION, ENTERPRESCONTRAST, baseIp } from '../utils/url';
import { get, post, postForm } from '../utils/request';

// 公司id查询公司名称
const getCompanyName = params => get(`${INTERACTION}/find_company_name`, {
  ...params,
});

// // 公司详情-企业评价-价值走势表格
// const getEvaluateExcel = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'evaluate_excel',
//   ...params,
// });

// // 公司详情-企业评价-价值对比
// const getLineCharMap = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'line_char_map',
//   ...params,
// });

// 公司详情-头部信息
const getTitleInfo = (params) => get(`${COMPANYDETAIL}/title_info`, {
  ...params,
});

// // 公司详情-基本信息
// const getBaseInfo = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'base_info',
//   ...params,
// });

// 最新动态-新闻动态
const getCompanyNews = param => get(`${COMPANYDETAIL}/correlation_news_list`, {
  ...param,
});

// 最新动态-高管变更
const getLegalPerson = params => get(`${COMPANYDETAIL}/change_legal_person`, {
  ...params,
});

// 最新动态-经营变更
const getOperateChange = params => get(`${COMPANYDETAIL}/change_of_business_scope`, {
  ...params,
});

// 基本信息-资本变更
const getCapitalChange = params => get(`${COMPANYDETAIL}/capital_change`, {
  ...params,
});
// 基本信息-重要人员变更
const getKeyPersonnelChange = params => get(`${COMPANYDETAIL}/key_personnel_change`, {
  ...params,
});

// 基本信息-迁出变更
const getRemovalChange = params => get(`${COMPANYDETAIL}/change_removal`, {
  ...params,
});
// 基本信息-经营范围变更
const getBusinessScopeChange = params => get(`${COMPANYDETAIL}/business_scope_change`, {
  ...params,
});

// 最新动态-新设机构
const getNewAgency = params => get(`${COMPANYDETAIL}/new_agency`, {
  ...params,
});

// 最新动态-新增专利
const getNewPatent = params => get(`${COMPANYDETAIL}/new_patent`, {
  ...params,
});

// 最新动态-主导标准
const getMainStandards = params => get(`${COMPANYDETAIL}/leading_standards`, {
  ...params,
});

// 最新动态-新获投资
const getNewInvestment = params => get(`${COMPANYDETAIL}/new_investment`, {
  ...params,
});

// 最新动态-风险提示-经营异常
const getWarningAbnormal = params => get(`${COMPANYDETAIL}/warning_abnormal`, {
  ...params,
});

// 最新动态-风险提示-严重违法
const getWarningIllegal = params => get(`${COMPANYDETAIL}/warning_illegal`, {
  ...params,
});

// 最新动态-风险提示-司法协助
const getWarningJudicialAssistance = params => get(`${COMPANYDETAIL}/warning_judicial_assistance`, {
  ...params,
});

// 最新动态-风险提示-动产抵押
const getWarningMortgage = params => get(`${COMPANYDETAIL}/warning_mortgage`, {
  ...params,
});

// 最新动态-风险提示-行政处罚
const getWarningPunishment = params => get(`${COMPANYDETAIL}/warning_punishment`, {
  ...params,
});

// 基本信息-工商信息
const getCommerceInfo = param => get(`${COMPANYDETAIL}/industry_commerce_info`, {
  ...param,
});

// 基本信息-股东信息
const getShareholderInfo = param => get(`${COMPANYDETAIL}/shareholder_info_list`, {
  ...param,
});

// 基本信息-科研项目
const getScientificSearchProject = param => get(`${COMPANYDETAIL}/scientific_research_project`, {
  ...param,
});

// 基本信息-企业年报
const getReportList = (params) => get(`${COMPANYDETAIL}/company_year_report`, {
  ...params,
});

// 基本信息-公司资质
const getCompanyQualification = (params) => get(`${COMPANYDETAIL}/company_qualification`, {
  ...params,
});

// 基本信息-创新平台
const getBaseInfoCreativePlatform = (params) => get(`${COMPANYDETAIL}/base_info_creative_platform`, {
  ...params,
});

// // 公司详情-行业地位-竞争格局
// const getCompetitionPattern = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'contend_pattern',
//   ...params,
// });

// // 公司详情-行业地位-全景图
// const getIndustryLinkMap = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'industry_link_map',
//   ...params,
// });

// // 公司详情-行业地位-企业名单
// const getCompanyList = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'industry_company_list',
//   ...params,
// });

// // 公司详情-行业地位-行业排名
// const getRankList = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'industry_rank_list',
//   ...params,
// });

// // 公司详情-供应链关系-关系图谱-伙伴关系
// const getPartner = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'relation_partner_map',
//   ...params,
// });

// // 公司详情-供应链关系-关系图谱-供应商关系
// const getSupplier = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'relation_supplier_map',
//   ...params,
// });

// // 公司详情-供应链关系-关系图谱-客户关系关系
// const getClient = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'relation_client_map',
//   ...params,
// });

// 对外投资-股权穿透
const getStockStrike = (params) => get(`${COMPANYDETAIL}/stock_strike`, {
  ...params,
});

const getMajorShareholder = (params) => post(`${baseIp}/mini/companyDetail/major_shareholder`, {
  ...params,
});

// 对外投资-股权穿透表格
const getStockStrikeTable = param => get(`${COMPANYDETAIL}/stock_strike_sheet`, {
  ...param,
});

// 知识产权-专利分析
// const getPatentStatistics = (params) => get(`${COMPANYDETAIL}/patent_statistics`, {
//   ...params,
// });

// 知识产权-备案许可
const getRecordLicense = param => get(`${COMPANYDETAIL}/for_the_record_permission`, {
  ...param,
});

// 基本信息-中标项目
const getTenderInfo = (params) => get(`${COMPANYDETAIL}/find_tender_info`, {
  ...params,
});

// // 公司详情-获得项目-重大项目
// const getGreatProject = (params) => get(`${COMPANYDETAIL}/`, {
//   command: 'find_great_project',
//   ...params,
// });

// 基本信息-融资信息
const getFinanceInfo = (params) => get(`${COMPANYDETAIL}/find_finance_info`, {
  ...params,
});

// 企业功能模块tab列表数据
const getAchorTab = param => get(`${COMPANYDETAIL}/module_list`, {
  ...param,
});

// 轮询企业功能模块接口
const getCompanyModuleList = params => post(`${COMPANYDETAIL}/find_company_module_status`, {
  ...params,
});

// 对外投资-分支机构
const getBranchInstitution = param => get(`${COMPANYDETAIL}/branch_institution`, {
  ...param,
});

// 关系图谱-伙伴关系
const getParenterShip = param => post(`${COMPANYDETAIL}/company_partnership`, {
  ...param,
});

// 基本信息-股权结构
const getCaptialStruture = param => post(`${COMPANYDETAIL}/principal_shareholder`, {
  ...param,
});

// 关系图谱-供应关系
const getSupplyRelation = param => post(`${COMPANYDETAIL}/supply_custom_relation`, {
  ...param,
});

// 基本信息-法人关系
const getGuardianship = param => post(`${COMPANYDETAIL}/legal_representative_relation`, {
  ...param,
});
// 基本信息-法人关系高管名单
const getGuardianshipTable = param => post(`${COMPANYDETAIL}/legal_representative_relation_table`, {
  ...param,
});
// 基本信息-估值信息表
const getValuationInformationTable = param => post(`${COMPANYDETAIL}/valuation_information_table`, {
  ...param,
});
// 基本信息-财务分析表
const getFinancialInformationTable = param => post(`${COMPANYDETAIL}/financial_information_table`, {
  ...param,
});

// 资质奖励-荣誉资质
const getCompanyCredentialTag = param => post(`${COMPANYDETAIL}/company_credential_tag`, {
  ...param,
});
// 资质奖励-资质穿透
const getQualityCertification = param => post(`${COMPANYDETAIL}/quality_certification`, {
  ...param,
});
// 资质奖励-所获奖项 company_get_award
const getCompanyGetAward = param => post(`${COMPANYDETAIL}/company_get_award`, {
  ...param,
});

// 资质奖励-备案许可
const getPermissionFiling = param => post(`${COMPANYDETAIL}/permission_filing`, {
  ...param,
});
// 经营能力-优质股东
const getQualityShareholdersList = param => post(`${COMPANYDETAIL}/quality_shareholders_list`, {
  ...param,
});
// 经营能力-合作龙头 list_leading_cooperative_enterprises
const getListLeadingCooperativeEnterprises = param => post(`${COMPANYDETAIL}/list_leading_cooperative_enterprises`, {
  ...param,
});
// 经营能力-中标目标 list_leading_cooperative_enterprises
const getListWinningProjects = param => post(`${COMPANYDETAIL}/list_winning_projects`, {
  ...param,
});
// 经营能力-营收增长 operating_income
const getOperatingIncome = param => post(`${COMPANYDETAIL}/operating_income`, {
  ...param,
});
// 创新能力-重点项目 list_key_projects
const getListKeyProjects = param => post(`${COMPANYDETAIL}/list_key_projects`, {
  ...param,
});
// 创新能力-科技研发 research_development_investment
const getResearchDevelopmentInvestment = param => post(`${COMPANYDETAIL}/research_development_investment`, {
  ...param,
});

// // 创新能力-创新平台 creative_platform
// const getListKeyProjects = param => post(`${COMPANYDETAIL}/list_key_projects`, {
//   ...param,
// });
// 创新能力-创新平台
const getCreativePlatform = (params) => post(`${COMPANYDETAIL}/creative_platform`, {
  ...params,
});
// 创新能力-承建创新平台 research_institution_level_type_graph
const getCreativePlatformTable = (params) => post(`${COMPANYDETAIL}/research_institution_level_type_graph`, {
  ...params,
});
// 创新能力-专利分析 patent_statistics
const getPatentStatistics = (params) => get(`${COMPANYDETAIL}/patent_statistics`, {
  ...params,
});
// 创新能力-专利详情
const getPatentList = (params) => get(`${COMPANYDETAIL}/find_patent_list`, {
  ...params,
});
// 创新能力-参研标准
const getStandardTypeDistribution = (params) => get(`${COMPANYDETAIL}/standard_type_distribution`, {
  ...params,
});
// 创新能力-产业标准明细 participate_drafting_standards
const getParticipateDraftingStandards = (params) => get(`${COMPANYDETAIL}/participate_drafting_standards`, {
  ...params,
});
// 创新能力-起草标准详情 company_standard_drafting_issue
const getCompanyStandardDraftingIssue = (params) => get(`${COMPANYDETAIL}/company_standard_drafting_issue`, {
  ...params,
});
// 创新能力-所获软著 details_software_publications
const getDetailsSoftwarePublications = (params) => get(`${COMPANYDETAIL}/details_software_publications`, {
  ...params,
});
// 融资历程 company_investment_experience
const getCompanyInvestmentExperience = (params) => get(`${COMPANYDETAIL}/company_investment_experience`, {
  ...params,
});
// 关系图谱-核心技术
const getCoreTechnology = (params) => post(`${COMPANYDETAIL}/core_technology`, {
  ...params,
});

// 基本信息-发展历程
const getDevelopmentHistory = (params) => post(`${COMPANYDETAIL}/development_history`, {
  ...params,
});
// 基本信息-成长轨迹
const getGrowthProcess = (params) => post(`${COMPANYDETAIL}/growth_process`, {
  ...params,
});

// 企业评级-条形图
const getBarCharMap = (params) => get(`${COMPANYDETAIL}/bar_char_map`, {
  ...params,
});

// 企业评级-雷达图
const getRadarMap = (params) => get(`${COMPANYDETAIL}/radar_map`, {
  ...params,
});

// 企业评价-五个仪表盘
const getValuationEnterprise = (params) => get(`${COMPANYDETAIL}/valuation_of_enterprise`, {
  ...params,
});

// 企业服务-政策推荐
const getPolicyRecomment = (params) => get(`${COMPANYDETAIL}/policy_recommendations`, {
  ...params,
});

// 企业服务-专利推荐
const getPatentRecomment = (params) => get(`${COMPANYDETAIL}/patent_recommendations`, {
  ...params,
});

// 按数据集查询用户分组列表
const findAttentionGroupList = (params) => get(`${USERCUSTOM}/find_attention_group_list`, {
  ...params,
});

// 用户添加关注数据（关注）
const addAttentionData = (params) => post(`${USERCUSTOM}/add_attention_data_group_list`, {
  ...params,
});

// 添加关注的分组
const addAttentionGroup = (params) => post(`${USERCUSTOM}/add_attention_group`, {
  ...params,
});

// 修改关注的分组
const updateAttentionGroup = (params) => post(`${USERCUSTOM}/update_attention_group`, {
  ...params,
});

// 查询笔记
const getNotes = (params) => get(`${USERCUSTOM}/note_find`, {
  ...params,
});

// 写入笔记
const noteInsert = (params) => post(`${USERCUSTOM}/note_insert`, {
  ...params,
});

// 修改笔记
const updateNote = (params) => get(`${USERCUSTOM}/update_note`, {
  ...params,
});

// 删除笔记
const noteDelete = (params) => get(`${USERCUSTOM}/note_del`, {
  ...params,
});

// 反馈
const userFeedback = (params) => postForm(`${USERCUSTOM}/user_feedback`, {
  ...params,
});

// 查询标签
const getUserTags = (params) => get(`${USERCUSTOM}/find_user_defined_tag`, {
  ...params,
});

// 更新标签
const updateUserTags = (params) => post(`${USERCUSTOM}/update_user_defined_tag`, {
  ...params,
});

// 根据标签查询企业
const getTabCompanys = params => post(`${COMPANYDETAIL}/find_company_list_by_tag`, {
  ...params,
});

// 行业地位
const getPositionIndustry = params => get(`${COMPANYDETAIL}/position_in_industry`, {
  ...params,
});

// 竞争格局
const getCompetitionPartten = params => get(`${COMPANYDETAIL}/competition_pattern`, {
  ...params,
});

// 全国布局
const getNationalLayout = params => get(`${COMPANYDETAIL}/national_layout`, {
  ...params,
});

// 资质穿透
const getCMAFData = params => post(`${COMPANYDETAIL}/penetration_of_qualification`, {
  ...params,
});

// 添加对比企业 add_user_contrast_company
const addUserContrastCompany = params => post(`${ENTERPRESCONTRAST}/add_user_contrast_company`, {
  ...params,
});

// 查询添加企业 find_user_contrast_company
const findUserContrastCompany = params => post(`${ENTERPRESCONTRAST}/find_user_contrast_company`, {
  ...params,
});

// 删除对比企业 delete_user_contrast_company
const deleteUserContrastCompany = params => post(`${ENTERPRESCONTRAST}/delete_user_contrast_company`, {
  ...params,
});
export {
  getCompanyModuleList,
  getOperateChange,
  getCompanyName,
  getCMAFData,
  getNationalLayout,
  getCompetitionPartten,
  getPositionIndustry,
  getTabCompanys,
  addAttentionData,
  addAttentionGroup,
  updateAttentionGroup,
  getNotes,
  noteInsert,
  updateNote,
  noteDelete,
  userFeedback,
  getUserTags,
  updateUserTags,
  getReportList,
  getRadarMap,
  getBarCharMap,
  getTitleInfo,
  getPatentList,
  getStandardTypeDistribution,
  getParticipateDraftingStandards,
  getCompanyStandardDraftingIssue,
  getDetailsSoftwarePublications,
  getCompanyInvestmentExperience,
  getFinanceInfo,
  getTenderInfo,
  getPatentStatistics,
  getAchorTab,
  getCaptialStruture,
  getStockStrike,
  getMajorShareholder,
  getRecordLicense,
  getStockStrikeTable,
  getCommerceInfo,
  getShareholderInfo,
  getScientificSearchProject,
  getCompanyNews,
  getLegalPerson,
  getCapitalChange,
  getKeyPersonnelChange,
  getRemovalChange,
  getBusinessScopeChange,
  getNewAgency,
  getNewPatent,
  getMainStandards,
  getNewInvestment,
  getBaseInfoCreativePlatform,
  getWarningAbnormal,
  getWarningIllegal,
  getWarningJudicialAssistance,
  getWarningMortgage,
  getWarningPunishment,
  getBranchInstitution,
  getParenterShip,
  getSupplyRelation,
  getCompanyQualification,
  getGuardianship,
  getGuardianshipTable,
  getValuationInformationTable,
  getFinancialInformationTable,
  getCompanyCredentialTag,
  getQualityCertification,
  getCompanyGetAward,
  getPermissionFiling,
  getQualityShareholdersList,
  getListLeadingCooperativeEnterprises,
  getListWinningProjects,
  getOperatingIncome,
  getListKeyProjects,
  getResearchDevelopmentInvestment,
  getCreativePlatform,
  getCreativePlatformTable,
  getCoreTechnology,
  getDevelopmentHistory,
  getGrowthProcess,
  getValuationEnterprise,
  getPolicyRecomment,
  getPatentRecomment,
  findAttentionGroupList,
  addUserContrastCompany,
  findUserContrastCompany,
  deleteUserContrastCompany,
};
