import React, { useMemo, useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { message } from 'antd';
import AMapLoader from '@amap/amap-jsapi-loader';
import classNames from 'classnames';
import { openNewPage } from '../../../../utils/urlMethods';
import { getRandLngLat } from '../../../../utils/tool';
import TopSearch from '../TopSearch';
import noIcon from '../../../../assets/images/tool/no.png';
import yesIcon from '../../../../assets/images/tool/yes.png';
import markIcon from '../../../../assets/images/tool/mark.png';
import style from './index.module.less';

const toolList = [
  { name: '拖动地图', value: 'drag' },
  { name: '圈选区域', value: 'distance' },
  { name: '勾选区域', value: 'polygon' },
  { name: '清空地图', value: 'remove' },
];
/**
 * setSearchType 设置事圈定范围还是勾选
 * setSearchKey 勾选的范围坐标
 * updateSearch 更新请求
 * setDistance 半径
 * setOpen 设置打开/关闭 抽屉
 * parameter 城市产业参数
 */

const Map = forwardRef(({ setSearchType, setSearchKey, updateSearch, setMaxDistance, setDistance, setOpen, parameter, setTreeKey }, ref) => {
  const topSearchRef = useRef();
  const [active, setActive] = useState(toolList[0]);
  const [searchResult, setSearchResult] = useState([]);
  const [center, setCenter] = useState([116.397428, 39.90923]);
  const [hintInfro, setHintInfro] = useState('按下开始绘制');
  const [companys, setCompanys] = useState([]);
  // 跳转企业详情
  const onCompanyClick = () => {
    const id = Map.companyId;
    if (!id) return;
    openNewPage('companyDetail', { id });
  };
  const hint = useMemo(() => (
    `<div style="width: 432px; height: 80px; background: #fff; color:#FF5A17; font-size:14px; position: relative; z-index: 1000">
      <div style=" padding: 16px; ">
        <span id=companyName></span> 
        <span id=particulars style="color: #1961F5"> 详情 > </span>
      </div>
      <div id=location style="padding: 0 0 16px 16px; color: #0F2849; fons-size: 14px "></div>
    </div>
    `
  ), [hintInfro]);
  const noBtn = [(
    `<div style= "width: 80px; height: 40px; display: flex; justify-content: center; align-items: center;">
      <div style= "background:url(${noIcon}) no-repeat; background-size:100% 100%; width: 19px; height: 19px"/>
    </div>`
  )];
  const yesBtn = [(
    `<div style= "width: 80px; height: 40px; display: flex; justify-content: center; align-items: center;">
      <div style= "background:url(${yesIcon}) no-repeat; background-size:100% 100%; width: 19px; height: 19px"/>
    </div>`
  )];

  // 绘制完毕展示操作标签
  function completeDrawing() {
    Map.noBtn = new Map.AMap.Text({
      text: noBtn,
      map: Map.map,
      position: center,
      offset: new Map.AMap.Pixel(80, -20),
      style: {
        'background-color': '#FFF',
        'border-color': '#DCDCDC',
      },
    });
    Map.yesBtn = new Map.AMap.Text({
      text: yesBtn,
      map: Map.map,
      position: center,
      offset: new Map.AMap.Pixel(180, -20),
      style: {
        'background-color': '#FFF',
        'border-color': '#DCDCDC',
      },
    });
  }
  // 实例化工具以及图形
  const instantiationTool = (AMap) => {
    // 鼠标工具类插件
    Map.mouseTool = new AMap.MouseTool(Map.map);
    // 定位搜索工具类
    Map.geocoder = new AMap.Geocoder();
    // 构造地点查询类
    Map.placeSearch = new AMap.PlaceSearch({
      map: Map.map,
    });
    // 实例化标记点
    Map.m1 = new AMap.Marker({
      map: Map.map,
      content: '<div style="height: 18px; width:40px; line-height: 14px;  box-sizing: border-box; font-size: 14px; color:#fff; text-align: center; background-color:#1961F5; border:2px solid #fff; border-radius:9px">>></div>',
      position: new AMap.LngLat(116.397428, 39.923423),
      offset: new AMap.Pixel(-20, -9),
    });
    Map.m2 = new AMap.Marker({
      map: Map.map,
      content: '<div style="height: 18px; width:18px; box-sizing: border-box; background-color:#1961F5; border:2px solid #fff; border-radius:9px"></div>',
      position: new AMap.LngLat(116.327271, 39.922501),
      offset: new AMap.Pixel(-9, -9),
    });
    Map.line = new AMap.Polyline({
      strokeColor: '#1961F5',
      borderWeight: 3,
    });
    Map.text = new AMap.Text({
      text: '按下确认中心点，拖拽确认半径',
      map: Map.map,
      position: center,
      style: {
        'background-color': '#FFF2C6',
        'border-color': '#e1f5fe',
        color: '#EE964B',
        'font-size': '12px',
      },
    });
    Map.infoWindow = new Map.AMap.InfoWindow({
      content: hint, // 使用默认信息窗体框样式，显示信息内容
    });
    completeDrawing();
    Map.line.setMap(Map.map);
    Map.text.hide();
    Map.m1.hide();
    Map.m2.hide();
    Map.line.hide();
    Map.yesBtn.hide();
    Map.noBtn.hide();
  };

  // 地理名称转坐标
  const cityOrCoordinate = (city) => {
    Map.geocoder?.getLocation(city, (status, result) => {
      if (status === 'complete' && result.geocodes.length) {
        const lnglat = result.geocodes[0].location;
        Map.map.setCenter(lnglat);
        Map.map.setZoom(12);
      } else {
        // log.error('根据地址查询位置失败');
        console.log('根据地址查询位置失败');
      }
    });
  };

  // 查询城市
  const placeSearch = (city) => {
    Map.placeSearch?.search(city, (status, result) => {
      if (status === 'complete' && result.poiList.pois.length) {
        setSearchResult(result.poiList.pois);
      } else {
        console.log('搜索查询位置失败');
      }
    });
  };
  // 查询具体地址
  const specificAddress = (city, place) => {
    Map.autoComplete = null;
    Map.autoComplete = new Map.AMap.AutoComplete({
      city,
    });
    Map.autoComplete.search(place, (status, result) => {
      // console.log(status);
      // console.log(result);
      if (status === 'complete' && result.tips.length) {
        topSearchRef.current.setLoading(false);
        setCompanys(result.tips);
      } else {
        console.log('搜索查询位置失败');
      }
    });
  };

  // 更新标记点和连线的位置
  function computeDis(m1, m2) {
    const textPos = m1.divideBy(2).add(m2.divideBy(2));
    const distance = Math.round(m1.distance(m2));
    const path = [m1, m2];
    Map.line.setPath(path);
    Map.m2.show();
    Map.m1.show();
    Map.line.show();
    Map.text.setText(`两点相距${distance}米`);
    Map.text.setPosition(textPos);
  }
  const handleMousedown = (e) => {
    Map.press = true;
    Map.circleCenter = e.lnglat;
  };
  const handleMousemove = (e) => {
    Map.text.setPosition(e.lnglat);
    Map.text.setOffset(new Map.AMap.Pixel(10, 10));
    // 选择了绘制工具才展示提示文本
    if (Map.operationState) {
      Map.text.show();
    }
    if (Map.press && Map.active === 'distance') {
      const distance = Math.round(Map.circleCenter.distance(e.lnglat));
      Map.text.setText(`半径 ：${distance} <br/> 松开完成绘制`);
    }

    if (Map.press && Map.active === 'polygon') {
      Map.text.setText('双击松开完成绘制');
    }
  };

  const confirm = (path) => {
    const clPath = path.slice();
    clPath.push(path[0]);
    Map.searchKey = clPath.map(item => ({ lng: item[0], lat: item[1] }));
  };

  // 图形绘制完毕触发
  const drawEnd = (e) => {
    // 关闭图形绘制并保留图形
    Map.mouseTool.close(false);
    // 初始化鼠标
    Map.map.setDefaultCursor('pointer');
    // 关闭随鼠标移动改变提示文字的开关
    Map.press = false;
    // 定位到中心
    // Map.map.setFitView();
    Map.map.off('mousemove', handleMousemove);
    Map.operationState = false;
    Map.distance = null;
    Map.searchKey = null;
    if (e.obj.className === 'Overlay.Circle') {
      const { center: { KL, kT, lng, lat }, radius } = e.obj._opts;
      const { lng: newLng } = getRandLngLat(lng, lat, radius);
      Map.searchKey = { lng: KL, lat: kT };
      Map.distance = radius - 0;
      const maxDistance = Math.ceil(Map.distance / 1000);
      setMaxDistance(maxDistance);
      // 重新设置中心点为圆心
      Map.map.setCenter(new Map.AMap.LngLat(KL, kT));
      setHintInfro(radius);
      Map.m1.setPosition(new Map.AMap.LngLat(newLng, kT));
      Map.m2.setPosition(new Map.AMap.LngLat(KL, kT));
      Map.yesBtn.show();
      Map.noBtn.show();
      Map.yesBtn.setPosition(Map.m1.getPosition());
      Map.noBtn.setPosition(Map.m1.getPosition());
      computeDis(Map.m1.getPosition(), Map.m2.getPosition());
    } else {
      // 定位到中心
      Map.map.setFitView();
      Map.text.hide();
      const { path } = e.obj._opts;
      // 点击对号
      confirm(path);
      // 计算两个距离最远的点
      let maxDistance = 0;
      path.forEach((item1, index1) => {
        path.forEach((item2, index2) => {
          if (Map.AMap.GeometryUtil.distance(item1, item2) > maxDistance) {
            maxDistance = Map.AMap.GeometryUtil.distance(item1, item2);
          }
        });
      });
      maxDistance = Math.ceil(maxDistance / 1000);
      setMaxDistance(maxDistance);
      // 计算纬度最大的点
      const lngLat = path.filter(item => path.map(item2 => item2[0]).sort().reverse()[0] === item[0])[0];
      Map.yesBtn.setPosition(lngLat);
      Map.noBtn.setPosition(lngLat);
      Map.yesBtn.show();
      Map.noBtn.show();
      Map.map.setZoom(12);
    }
  };

  const generateCompanyLocation = (companyList, eliminate) => {
    Map.m2?.hide();
    Map.m1?.hide();
    Map.line.hide();
    Map.text.setText('');
    Map.text.hide();
    Map.yesBtn.hide();
    Map.noBtn.hide();
    if (eliminate) {
      Map.mouseTool.close(true);
    }
    // 清除所有标记
    if (Map.overlayGroups) Map.map.remove(Map.overlayGroups);
    const markers = companyList.map((item, index) => (
      new Map.AMap.Marker({
        position: item.reg_geo_wgs84 ? new Map.AMap.LngLat(item.reg_geo_wgs84?.[0], item.reg_geo_wgs84?.[1]) : item.location,
        icon: markIcon,
        offset: new Map.AMap.Pixel(-10, -34),
        extData: {
          id: item.company_id || item._id,
          name: item.company_name || item.name,
          location: item.registered_add || '',
        },
      })
    ));
    // if (companyList.length === 1) { Map.map.setCenter(new Map.AMap.LngLat(companyList[0].reg_geo_wgs84.lon, companyList[0].reg_geo_wgs84.lat)); }
    if (companyList[0]?.location) {
      Map.map.setCenter(companyList[0]?.location);
    }
    Map.overlayGroups = new Map.AMap.OverlayGroup(markers);
    Map.map.add(Map.overlayGroups);
    if (companyList?.[0]?.id) return;
    Map.overlayGroups.on('click', (e2) => {
      Map.infoWindow.open(Map.map, e2.lnglat);
      const { id, location, name } = e2.target._originOpts.extData;
      Map.companyId = id;
      const companyNameSpan = document.getElementById('companyName');
      const locationSpan = document.getElementById('location');
      const particularsSpan = document.getElementById('particulars');
      locationSpan.innerHTML = (location);
      companyNameSpan.innerHTML = (name);
      particularsSpan.removeEventListener('click', onCompanyClick);
      particularsSpan.addEventListener('click', onCompanyClick);
    });
  };

  // 指定暴露的对象 返回值就是暴露的对象 ref 就是上面的参数
  useImperativeHandle(ref, () => ({
    generateCompanyLocation,
    cityOrCoordinate,
  }));
  // 确定范围发送请求
  const handleConfirm = () => {
    if (setTreeKey) {
      setTreeKey(Date.now());
    }
    if (Map.distance > 20000) {
      message.warning('范围过大，已为您呈现中心点20km范围内');
    }
    Map.yesBtn.hide();
    Map.noBtn.hide();
    Map.text.hide();
    updateSearch({
      key: Map.searchKey,
      radius: Map.distance > 20000 ? 20000 : Map.distance,
      type: Map.active,
    });
    setOpen(true);
    Map.yesBtn.off('click', handleConfirm);
  };
  const handleCancel = () => {
    Map.map.setDefaultCursor('pointer');
    // 清除图形
    Map.mouseTool.close(true);
    Map.m2.hide();
    Map.m1.hide();
    Map.line.hide();
    Map.text.setText('');
    Map.text.hide();
    Map.yesBtn.hide();
    Map.noBtn.hide();
    setSearchType('');
    setDistance(null);
    setSearchKey([]);
    if (Map.overlayGroups) Map.map.remove(Map.overlayGroups);
    Map.noBtn.on('click', handleCancel);
  };

  // 添加监听事件
  const testListener = () => {
    // 添加绘制完毕后的事件
    Map.mouseTool?.on('draw', drawEnd);
    Map.map?.on('mousemove', handleMousemove);
    Map.map?.on('mousedown', handleMousedown);
    Map.yesBtn.on('click', handleConfirm);
    Map.noBtn.on('click', handleCancel);
  };
  // 移除监听事件
  const removeListener = () => {
    // 添加绘制完毕后的事件
    Map.mouseTool?.off('draw', drawEnd);
    Map.map?.off('mousemove', handleMousemove);
    Map.map?.off('mousedown', handleMousedown);
    Map.yesBtn.off('click', handleConfirm);
    Map.noBtn.off('click', handleCancel);
  };

  useEffect(() => {
    AMapLoader.load({
      key: '31e1ee90b55cae0838e65a2da758d2bd', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.Scale', 'AMap.MouseTool', 'AMap.Map', 'AMap.RangingTool', 'AMap.AutoComplete', 'AMap.PlaceSearch', 'AMap.Geocoder', 'AMap.Pixel'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap) => {
      Map.map = new AMap.Map('container', { // 设置地图容器id
        viewMode: '2D', // 是否为3D地图模式
        zoom: 12, // 初始化地图级别
        center, // 初始化地图中心点位置
        // mapStyle: 'amap://styles/dark',
        defaultCursor: 'pointer', // 鼠标变小手
      });
      Map.AMap = AMap;
      // 实例化工具类
      instantiationTool(AMap);
    });
  }, []);

  const initState = (value) => {
    Map.map.setDefaultCursor('pointer');
    Map.m2.hide();
    Map.m1.hide();
    Map.line.hide();
    Map.text.setText('');
    Map.text.hide();
    Map.yesBtn.hide();
    Map.noBtn.hide();
    setSearchType('');
    setDistance(null);
    setSearchKey([]);
    Map.press = false;
    if (value !== 'drag') {
      if (Map.overlayGroups) Map.map.remove(Map.overlayGroups);
    }
  };

  const initOperationState = () => {
    Map.map.setDefaultCursor('crosshair');
    Map.m2.hide();
    Map.m1.hide();
    Map.line.hide();
    Map.yesBtn.hide();
    Map.noBtn.hide();
    // setSearchType('');
    setDistance(null);
    setSearchKey([]);
    Map.press = false;
    // 是否已经选择绘制
    Map.operationState = true;
    if (Map.overlayGroups) Map.map.remove(Map.overlayGroups);
  };

  // 绘制图形
  const operationMap = {
    distance: (item) => {
      Map.mouseTool.circle();
      Map.text.setText('按下确认中心点，拖拽确认半径');
      initOperationState();
      Map.press = false;
    },
    polygon: () => {
      Map.mouseTool.polygon();
      Map.text.setText('单击确认起点');
      initOperationState();
      Map.press = false;
    },
    drag: () => {
      initState('drag');
    },
    remove: () => {
      initState();
    },
  };

  // 工具的操作
  const operationTool = (value) => {
    // 圈定范围前先清除上一次的
    if (value !== 'drag') Map.mouseTool?.close(true);
    // 根据选中的操作圈定
    operationMap[value](value);
  };
  return (
    <div className={style.box}>
      <div id="container" style={{ width: '100%', height: '100%' }} />
      <div className={style.topSearch}>
        <TopSearch
          ref={topSearchRef}
          cityCode={parameter.cityCode}
          cityName={parameter.cityName}
          cityOrCoordinate={cityOrCoordinate}
          placeSearch={placeSearch}
          generateCompanyLocation={generateCompanyLocation}
          specificAddress={specificAddress}
          setCompanys={setCompanys}
          companys={companys}
        />
      </div>
      <div className={style.toolBox}>
        {
          toolList.map(item => (
            <div
              key={item.name}
              style={{ background: active?.value === item.value ? '#1961F5' : '' }}
              className={style.tool}
              onClick={() => {
                removeListener();
                testListener();
                Map.active = item.value;
                Map.text.hide();
                // 设置选中工具项
                setActive(item);
                // 工具操作
                operationTool(item.value);
              }}
            >
              <div className={classNames(style.icon, { [style[item.value]]: active?.value === item.value })} />
              <p>{item.name}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
});

export default Map;
