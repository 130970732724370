/*
 *Author: zhaochenyu
 *Description: 专精特新-报告
 *Date: 2022/05/16
*/

import React from 'react';
import { BoutiqueCard, OtherCard } from '../../../BookCard';
import dianzixinxi from '../../../../assets/images/report/digitalInfo.png';
// import {
//   downloadBoutiqueReport,
// } from '../../../api/reportGeneration';
import { openNewPage } from '../../../../utils/urlMethods';
import style from './index.module.less';

function ReportList(props) {
  const { data = [] } = props;
  return (
    <div className={style.all}>
      <div
        className={style.head}
      >
        报告推荐
      </div>
      <div className={style.body}>
        {data.map((item, index) => {
          let res;
          const { id, pdf_url: pdfUrl, type, Buy_state: isApply, price,
            cover_url: coverImg } = item;
          if (type === '精品报告') {
            res = <BoutiqueCard
              key={id}
              // id={id}
              coverImg={coverImg}
              className={style.bookCard}
              isApply={isApply}
              onView={() => {
                openNewPage('boutiqueDetail', {
                  msg: window.btoa(window.encodeURIComponent(JSON.stringify({
                    title: item.title,
                    subTitle: item.sub_title,
                    isApply,
                    price,
                    coverImg,
                    id,
                  }))),
                });
              }}
            />;
          } else {
            res = <OtherCard
              className={style.reportCard}
              background={dianzixinxi}
              onView={() => { if (pdfUrl) window.open(pdfUrl); }}
            />;
          }
          return (
            <div className={style.reportItem} key={`${index}${id}`}>
              <div className={style.left}>
                <div className={style.reportTitleImgBack}>
                  <div className={style.fTitle}>
                    {item.title}
                    {/* </div> */}
                    {/* <div className={style.sTitle}> */}
                    {item.sub_title}
                  </div>
                </div>
              </div>
              <div className={style.right}>
                {res}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ReportList;
