/*
* Author: zhangmeng
* Description: ''
* Date: 2022/06/29
*/

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Button, Pagination, Spin } from 'antd';
import moment from 'moment';
import TabSelect from '../SelectorComp/TabSelect';
import DateSelect from '../SelectorComp/DateSelect';
import SearchSortor from '../SearchSortor';
import IssuingAuthority from '../IssuingAuthority';
import ResearchReportListLine from '../ResearchReportListLine';
import LabelList from '../LabelList';
import style from './index.module.less';
import { useGetReportListQuery, useFindIndustryGroupListQuery } from '../../../../service/dataSearch';

const sortData1 = [
  {
    title: '发布日期',
    key: 'release_time',
  },
  {
    title: '报告页数',
    key: 'tags.pages',
  },
  {
    title: '阅读量',
    key: 'read_count',
  },
  {
    title: '下载量',
    key: 'download_count',
  },
];

const featureLabel = [
  { title: '新鲜出炉', key: '新鲜出炉' },
  { title: '热门', key: '热门' },
  { title: '深度研究', key: '深度研究' },
];

const fastTagList = [
  { title: '近三天', value: 3, type: 'days' },
  { title: '近一周', value: 7, type: 'days' },
  { title: '近一个月', value: 1, type: 'months' },
  { title: '近半年', value: 6, type: 'months' },
  { title: '近一年', value: 1, type: 'years' },
];

function ResearchReportPage(props) {
  const { filter, styles, participle, hideTitle = false } = props;
  const {
    research_report_type: researchReportTypeList,
    research_report_page_count: researchReportPageCountList,
  } = filter;
  const [researchReportType, setResearchReportType] = useState(['不限']);
  const [researchReportPageCount, setResearchReportPageCount] = useState('');
  const [date, setDate] = useState(['', '']);
  const [sortKey, setSort] = useState({ key: '', count: 0 });
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isConfirm, setIsConfirm] = useState(false);
  const [featureLabelActive, setFeatureLabelActive] = useState('');
  const [industryActive, setIndustryActive] = useState('');
  const [issuingAuthority, setIssuingAuthority] = useState([]);
  const [timeTagValue, setTimeTagValue] = useState('');

  /* eslint-disable no-nested-ternary */
  const { data: list, isFetching } = useGetReportListQuery(sortKey.count === 0 ? {
    research_report_type_list: researchReportType.length === 1 ? (researchReportType[0] === '不限' ? [] : researchReportType) : researchReportType,
    research_report_page_count: researchReportPageCount,
    release_date_list: (date?.[0] !== '' && date?.[1] !== '') ? [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')] : [],
    page_size: pageSize,
    page_number: pageNum,
    institution_name_list: issuingAuthority,
    industry_category_list: [industryActive].filter(Boolean),
    keyword: featureLabelActive === '深度研究' ? '深度' : participle.join(''),
  } : {
    research_report_type_list: researchReportType.length === 1 ? (researchReportType[0] === '不限' ? [] : researchReportType) : researchReportType,
    research_report_page_count: researchReportPageCount,
    institution_name_list: issuingAuthority,
    industry_category_list: [industryActive].filter(Boolean),
    release_date_list: (date?.[0] !== '' && date?.[1] !== '') ? [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')] : [],
    page_size: pageSize,
    page_number: pageNum,
    keyword: featureLabelActive === '深度研究' ? '深度' : participle.join(''),
    sort_column: sortKey.key,
    sort_order: sortKey.count === 1 ? 'asc' : 'desc',
  }, { skip: isConfirm });

  // institution_name_list 发布机构 industry_category_list 行业分类

  const { data: industryList } = useFindIndustryGroupListQuery();

  useEffect(() => {
    if (featureLabelActive) {
      if (featureLabelActive === '新鲜出炉') {
        const endDate = moment();
        const startDate = moment().subtract(7, 'days');
        setDate([startDate, endDate]);
      } else if (featureLabelActive === '热门') {
        setSort({ key: 'read_count', count: '2' });
      }
    }
  }, [featureLabelActive]);

  useEffect(() => {
    if (!isFetching) {
      setIsConfirm(true);
    }
  }, [isFetching]);

  useEffect(() => {
    setIsConfirm(false);
  }, [participle]);

  const resetClick = () => {
    setResearchReportType(['不限']);
    setResearchReportPageCount('');
    setDate(['', '']);
    setTimeTagValue('');
    setIndustryActive('');
    setIssuingAuthority([]);
    setFeatureLabelActive('');
    setIsConfirm(true);
  };
  const confirmSearch = () => {
    setIsConfirm(false);
    setPageNum(1);
  };

  const changeSort = useCallback(
    (sort) => {
      setSort(sort);
      setIsConfirm(false);
    },
    [],
  );

  // pagination-onChange
  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
    setIsConfirm(false);
  };

  return (
    <div className={style.all} style={styles}>
      <div className={style.topBox}>
        {
          !hideTitle && <div className={style.title}>研报</div>
        }
        <div className={style.selectorBox}>
          <div className={style.selectorBoxTop}>
            <div className={style.reportType}>
              {
                useMemo(() => (
                  <TabSelect
                    title="研报类型"
                    data={researchReportTypeList}
                    selected={researchReportType}
                    setSelected={p => {
                      setResearchReportType(p);
                      setIsConfirm(true);
                    }}
                  />
                ), [researchReportTypeList, researchReportType])
              }
            </div>
            <div className={style.issuingAuthority}>
              <IssuingAuthority title="发布机构" value={issuingAuthority} setValue={setIssuingAuthority} setIsConfirm={setIsConfirm} />
            </div>
          </div>
          <div className={style.reportMiddle}>
            <div className={style.singleTab}>
              <div className={style.label}>
                <div className={style.point} />
                <div className={style.title}>报告页数</div>
              </div>
              <div className={style.TabBox}>
                <div
                  className={`${style.tab} ${researchReportPageCount === '' ? style.tabSelected : ''}`}
                  onClick={() => { setResearchReportPageCount(''); setIsConfirm(true); }}
                >
                  不限
                </div>
                {
                  researchReportPageCountList.map((item, index) => (
                    <div
                      className={`${style.tab} ${researchReportPageCount === item.key ? style.tabSelected : ''}`}
                      key={item.title}
                      onClick={() => { setResearchReportPageCount(item.key); setIsConfirm(true); }}
                    >
                      {item.title}
                    </div>
                  ))
                }
              </div>
            </div>
            <div>
              <LabelList title="特色标签" list={featureLabel} active={featureLabelActive} setActive={setFeatureLabelActive} setIsConfirm={setIsConfirm} />
            </div>
          </div>
          <div className={style.reportBottom}>
            <div className={style.date}>
              {
                useMemo(() => (
                  <DateSelect
                    title="发布时间"
                    fastTagList={fastTagList}
                    select={date}
                    setActive={setTimeTagValue}
                    active={timeTagValue}
                    setSelect={p => {
                      if (p) {
                        setDate(p);
                      } else {
                        setDate(['', '']);
                      }
                      setIsConfirm(true);
                    }}
                  />
                ), [date, setDate])
              }
            </div>
            <div>
              <LabelList title="行业分类" list={industryList} active={industryActive} setActive={setIndustryActive} setIsConfirm={setIsConfirm} />
            </div>
          </div>

          <div className={style.btnGroup}>
            <Button type="primary" ghost onClick={() => resetClick()}>
              重置
            </Button>
            &emsp;
            <Button type="primary" className="confirmSearch" onClick={() => confirmSearch()}>
              查询
            </Button>
          </div>
        </div>
      </div>
      <div className={style.listBox}>
        <Spin tip="搜索中，请稍候..." spinning={isFetching}>
          <SearchSortor data={sortData1} sort={sortKey} total={list?.data_count || 0} onChange={changeSort} />
          <div className={style.list}>
            {
              list && list.data_list && list.data_list.length > 0 ? list.data_list.map((item, index) => (
                <ResearchReportListLine key={index} record={item} participle={participle} />
              )) : <div style={{ textAlign: 'center', marginTop: '50px' }}>暂无数据</div>
            }
            <div className={style.pagination}>
              <Pagination
                showQuickJumper
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={pageSize}
                current={pageNum}
                total={list?.auth_count || 0}
                onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default ResearchReportPage;
