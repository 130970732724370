import React from 'react';
import style from './index.module.less';
import Barandline from '../../../../../components/Charts/BarAndLine';
import { useGetCityBenchmarkQuery } from '../../../../../service/cityDetail';

const CityBenchmarking = ({ title, cityCode }) => {
  const { data, isFetching } = useGetCityBenchmarkQuery({ area_key: cityCode }, { skip: !cityCode });
  return (
    <div className={style.box} id={title}>
      <p className={style.title}>{title}</p>
      <ul>
        {data?.map((item, index) => (
          <li key={index}>
            <p className={style.chartTitle} >
              {data?.[index]?.title || ''}
              {data?.[index].subtitle && `(${data?.[index].subtitle})`}
            </p>
            <div className={style.chart}>
              <Barandline
                data={{
                  ...item,
                  leftYName: [0, 30, 0, 0],
                  highlight: item?.highlight[0],
                }}
                isFetching={isFetching}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CityBenchmarking;
