import React, { useMemo, useEffect } from 'react';
import PieTable from '../../../component/PieTable';
import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const StandardTypeDistribution = (props) => {
  const { url, dataFilterParams, ok } = props;
  const { data, isFetching } = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || !ok });
  const newData = useMemo(() => {
    if (!data) return undefined;
    if (!data.series.length) {
      const initData = {
        ...data,
        series: [
          { name: '企业参与制定标准类型结构', unit: '项', data: [0] },
        ],
      };
      return initData;
    }
    return data;
  }, [data]);
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <PieTable {...props} data={newData} isFetching={isFetching} chartType="饼图" radius={['0%', '80%']} delCrosswise />;
};

export default StandardTypeDistribution;
