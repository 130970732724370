import React, { useMemo, useEffect } from 'react';
import StackBarTable from '../../../component/StackBarTable';
import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const ValueStatistics = (props) => {
  const { url, dataFilterParams, reportData, ok } = props;
  const res = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData || !ok });
  const { data, isFetching } = res;
  const newData = useMemo(() => {
    if (!data) return undefined;
    if (!data.series.length) {
      const initData = {
        ...data,
        series: [
          { name: '发明', unit: '件', data: [0] },
          { name: '实用新型', unit: '件', data: [0] },
          { name: '外观设计', unit: '件', data: [0] },
        ],
      };
      return initData;
    }
    return data;
  }, [data]);
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <StackBarTable {...props} data={newData} isFetching={isFetching} chartType="柱线图" />;
};

export default ValueStatistics;
