import React from 'react';
// import TabTree from './TabTree';
import AptitudesStrike from './AptitudesStrike';
import HonorQualification from './HonorQualification';
import BeRanked from './BeRanked';
import BeRankedPenetrate from './BeRankedPenetrate';
// import Tree from './HonorQualification/Tree';

const HonorAptitudes = (props) => (
  <>
    {/* <TabTree {...props} /> */}
    <HonorQualification {...props} />
    <AptitudesStrike {...props} />
    <BeRanked {...props} />
    <BeRankedPenetrate {...props} />
  </>
);

export default HonorAptitudes;
