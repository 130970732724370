import { withConfig } from '../../../hoc/withConfig';
import { EnterpriseFaceTrend } from '@sqke/report-components';

const ConfigBusinessCard = withConfig(
  EnterpriseFaceTrend,
  {
    mapConfigToProps: (raw, data) => {
      return {
        ...(data.data ?? {}),
      };
    },
    empty: (_, data) => !data || Object.keys(data).length === 0,
  }
);

export default ConfigBusinessCard;
