/*
* Author: zhangmeng
* Description: ''
* Date: 2022/06/29
*/

import React, { useCallback, useState } from 'react';
import { Button } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { IconFont } from '../../../../utils/mapIcon';
// import emptyUp from '../../../../assets/images/dataSearch/emptyUp.svg';
// import emptyDown from '../../../../assets/images/dataSearch/emptyDown.svg';
// import fillUp from '../../../../assets/images/dataSearch/fillUp.svg';
// import fillDown from '../../../../assets/images/dataSearch/fillDown.svg';
import rectIcon from '../../../../assets/images/dataSearch/rectIcon.png';
import ExportModal from '../../../../components/ExportModal';
import style from './index.module.less';

function SearchSortor(props) {
  const { data, sort, onChange, total, exportType, dataKey, params } = props;
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const exportFlag = Array.isArray(enterpriseAccountExport)
    && enterpriseAccountExport?.length > 0
    && enterpriseAccountExport?.includes(exportType);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const clickSort = useCallback((lastSort, curSort) => {
    let count = 0;
    if (lastSort.key === curSort) {
      count = lastSort.count + 1 > 2 ? 0 : lastSort.count + 1;
    } else {
      count = 1;
    }
    const newSort = {
      key: curSort,
      count,
    };
    onChange(newSort);
  }, []);

  return (
    <div className={style.sort}>
      <div className={style.allCount}>
        <img src={rectIcon} alt="" />
        当前搜索结果共&nbsp;
        <span>{total}</span>
        &nbsp;条
      </div>
      <div className={style.rightSort}>
        {(data || []).map((item, index) => (
          <div
            key={`${index + item}`}
            className={style.sortItem}
          >
            {item.title === '阅读量' && <IconFont type="iconico_yueduliang" />}
            {item.title === '下载量' && <IconFont type="iconxiazai" />}
            {item.title}
            <div
              className={style.icons}
              onClick={() => clickSort(sort, item.key)}
            >
              <CaretUpOutlined
                style={{
                  color: sort.key === item.key && sort.count === 1 ? '#1961F5' : 'rgba(38, 45, 54, 0.3)',
                }}
              />
              <CaretDownOutlined
                style={{
                  color: sort.key === item.key && sort.count === 2 ? '#1961F5' : 'rgba(38, 45, 54, 0.3)',
                }}
              />
            </div>
          </div>
        ))}
        {
          exportFlag && (
            <Button className={style.exportButton} onClick={() => setExportModalVisible(true)}>数据导出</Button>
          )
        }
      </div>
      {
        exportModalVisible && (
          <div className={style.mask}>
            <ExportModal
              dataKey={dataKey}
              params={params}
              onClose={() => {
                setExportModalVisible(false);
              }}
            />
          </div>
        )
      }
    </div>
  );
}

export default SearchSortor;
