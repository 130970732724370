import React, { useState, useRef, useCallback, useEffect, memo } from 'react';
import { Spin } from 'antd';
import { debounce } from 'lodash';
import classnames from 'classnames';
import Tree from './components/Tree';
import Button from './components/Button';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import Underline from '../../../../components/Underline';
import SectorCompanyTable from './components/SectorCompanyTable';
import styles from './index.module.less';
import {
  useGetChannelLinkCompanyListQuery,
} from '../../../../service/industry-channel';
import { useGetIndustryLinkCurrentSituationQuery } from '../../../../service/city-channel';
import { openNewPage } from '../../../../utils/urlMethods';
import ZoomButton from './components/ZoomButton';

const getName = node => node.title;
const getValue = node => node.score;
const getId = node => node.value;
const getDisabled = node => !node.isBright;

const TREE_PADDING = [10, 520, 10, 30];

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

/**
 * 图谱 scheme
 * 所谓全展开，就是根节点
 * @param {string} industryCode
 * @returns {JSX.Element}
 * @constructor
 */
const GraphScheme = ({
  industryCode,
  cityCode,
  industry,
  cityName,
  // channelModalVisible,
  name,
}) => {
  // 当前产业/环节名称，产业同种需要根据产业名称进行查询
  const [selectedId, setSelectedId] = useState(industryCode);
  useEffect(() => {
    setSelectedId(industryCode);
  }, [industryCode]);
  // 树图缩放等级
  const [zoom, setZoom] = useState(1);
  const setZoomDebounced = useCallback(debounce(setZoom, 50), []);
  // 树的下拉是否展开
  const [isTreeDropdownOpen, setIsTreeDropdownOpen] = useState(true);
  // 树图 ref，负责调用内置方法
  const treeRef = useRef();
  const [treeListQueryState, setTreeListQueryState] = useState({
    pageSize: 20,
    pageNumber: 1,
    filteredInfo: { special_tag: [] },
  });
  const {
    data: chainData,
    isFetching: isChainFetching,
  } = useGetIndustryLinkCurrentSituationQuery({
    industry_code: industryCode,
    area_key: cityCode,
  }, {
    skip: !industryCode,
  });
  const {
    link_count: linkCount,
    isBright_count: isBrightCount,
    industry_data_count: industryCompanyCount,
  } = chainData ?? {};
  // 获取环节企业总数
  const [regionPath, setRegionPath] = useState(['000000']);
  const regionCode = regionPath[regionPath.length - 1];
  const {
    data: companyData,
    isFetching: isLinkFetching,
  } = useGetChannelLinkCompanyListQuery({
    area_key: getSingleOption(treeListQueryState.filteredInfo.in_area) ?? cityCode,
    industry_code: industryCode,
    link_code: selectedId !== industryCode ? selectedId : undefined,
    // first_special_tag: (treeListQueryState.filteredInfo.special_tag?.filter(tag => tag !== 'all') ?? [])[0],
    second_special_tag: treeListQueryState.filteredInfo.special_tag?.filter(tag => tag !== 'all'),
    page_size: treeListQueryState.pageSize,
    page_number: treeListQueryState.pageNumber,
  }, {
    skip: !industryCode || !selectedId,
  });
  const { count: linkCompanyCount } = companyData ?? {};
  return (
    <section className={styles.graphScheme}>
      {/* 产业链图，树图结合下拉表格的组合 */}
      <div
        className={classnames(
          styles.subScheme,
          {
            'grey-out': isChainFetching,
          },
        )}
        id={styles.tree}
      >
        {
          isChainFetching && <Spin className={styles.spin} delay={200} />
        }
        <div className={styles.header}>
          <Title title={name || '产业分析'} />
          <div
            className={styles.buttons}
          >
            <ZoomButton
              zoom={zoom}
              minZoom={0.1}
              maxZoom={2}
              className={styles.button}
              onChange={z => {
                const _z = Number(z?.toFixed(2));
                setZoom(_z);
                treeRef.current.setZoom(_z);
              }}
            />
            <Button
              className={styles.button}
              type="primary"
              text="展开"
              onClick={() => {
                const { setNodesCollapsedState } = treeRef.current;
                setNodesCollapsedState(false);
                setIsTreeDropdownOpen(false);
              }}
            />
            <Button
              className={styles.button}
              type="primary"
              text="收起"
              onClick={() => {
                const { setNodesCollapsedState } = treeRef.current;
                setNodesCollapsedState(true);
              }}
            />
            <Button
              className={styles.button}
              type="primary"
              text="下载"
              onClick={() => {
                treeRef.current.download();
              }}
            />
          </div>
        </div>
        <Tree
          name={cityName}
          data={chainData}
          minZoom={0.1}
          maxZoom={2}
          onClick={(item) => {
            const { id, disabled } = item;
            if (disabled) return;
            if (selectedId === id) {
              setSelectedId(industryCode);
            } else {
              setSelectedId(id);
            }
            setTreeListQueryState(prev => ({ ...prev, pageNumber: 1 }));
            setIsTreeDropdownOpen(true);
          }}
          config={{
            padding: isTreeDropdownOpen ? TREE_PADDING : 0,
          }}
          getId={getId}
          getName={getName}
          getValue={getValue}
          getDisabled={getDisabled}
          selectedId={selectedId}
          onInit={() => {
            const _zoom = treeRef.current.getZoom();
            if (typeof _zoom === 'number') {
              setZoom(_zoom);
            }
          }}
          onViewportChange={() => {
            const _zoom = treeRef.current.getZoom();
            if (typeof _zoom === 'number') {
              const _z = Number(_zoom?.toFixed(2));
              setZoomDebounced(_z);
            }
          }}
          disableZoom
          ref={treeRef}
        />
        <div
          className={classnames(
            styles.list,
            { [styles.hidden]: !isTreeDropdownOpen },
          )}
        >
          <div className={styles.overview}>
            {/* <Subtitle
              className={styles.industryName}
              description={`${cityName}${industry}`}
              // value={linkCount}
              // unit="个环节"
            /> */}
            {/* <p className={styles.industryName}>
              {cityName}
              {industry}
            </p> */}
            <Subtitle
              className={styles.subtitle}
              description="共"
              value={linkCount}
              unit="个环节，"
            />
            <Subtitle
              className={styles.subtitle}
              description={`${cityName}覆盖`}
              value={isBrightCount}
              unit=" 个环节"
            />
            {
              // TODO: 稍晚展示，以优化用户体验
              linkCompanyCount !== industryCompanyCount && !isLinkFetching && (
                <Subtitle
                  className={classnames(styles.subtitle, styles.lastSubtitle)}
                  description="筛选结果"
                  value={linkCompanyCount}
                  unit="家"
                />
              )
            }
          </div>
          <Underline />
          <SectorCompanyTable
            regionCode={cityCode}
            className={styles.table}
            industryCode={industryCode}
            linkCode={selectedId !== industryCode ? selectedId : undefined}
            pageSize={treeListQueryState.pageSize}
            pageNumber={treeListQueryState.pageNumber}
            onChange={({ current, pageSize }, filter) => {
              setTreeListQueryState({
                ...treeListQueryState,
                pageSize,
                pageNumber: current,
                filteredInfo: filter,
              });
            }}
            filteredInfo={treeListQueryState.filteredInfo}
            onCompanyClick={({ id: _id }) => {
              if (!_id) return;
              openNewPage('companyDetail', { id: _id });
            }}
          />
          <Button
            className={classnames(
              styles.toggleButton,
              {
                [styles.slim]: !isTreeDropdownOpen,
              },
            )}
            text={isTreeDropdownOpen ? '收起' : '展开'}
            onClick={() => setIsTreeDropdownOpen(!isTreeDropdownOpen)}
          />
        </div>
      </div>
      <ul className={styles.legend}>
        <li>
          <p />
          <p>优势环节</p>
        </li>
        <li>
          <p />
          <p>覆盖环节</p>
        </li>
        <li>
          <p />
          <p>缺失环节</p>
        </li>
      </ul>
    </section>
  );
};

export default GraphScheme;
