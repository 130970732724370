import React, { useState, useEffect } from 'react';
import { Modal, Input, message, Tree, Popover } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { useQuery } from '../../../../../hooks/url';
import { useLazyGetSearchFirmNameListQuery, useLazyGetTreeQuery, useLazyGetIndexValueQuery, useLazyAddUserCompanyContrastCollectQuery, useLazyGetComprehensiveAssessmentResultsQuery } from '../../../../../service/tool';
import BarChart from '../../../../../components/Industrial/BarChart';
import Bottom from '../Bottom';
import style from './index.module.less';

const { Search } = Input;
const tds = ['1', '2', '3', '4', '5'];
const content = (
  <p style={{ height: '16px', lineHeight: '16px', fontSize: '14px' }}>点击会删除掉此选项相关的数据信息，请谨慎</p>
);
const speedUpKey = ['patent_speed_up', 'market_value_speed_up', 'revenue_speed_up', 'standard_speed_up'];
const unitsMap = {
  registered_capital: '万元',
  branch_institution_num: '家',
  number_of_outward_investment: '家',
  country_standard_num: '项',
  industry_standard_num: '项',
  place_standard_num: '项',
  group_standard_num: '项',
  company_standard_num: '项',
  authorized_patent_num: '个',
  authorized_utility_patent_num: '个',
  authorized_appearance_design_patent_num: '个',
  province_innovation_institution: '家',
  country_innovation_institution: '家',
  financing_num: '轮',
  financing_money_num: '万元',
};
// const chart = (data) => {
//   return <div>hh</div>
// };
const chartTitle = (title) => (
  <div className={style.chartTitle}>
    <div>
      <span>{title}</span>
      <span>统计对比图</span>
    </div>
    {/* <button type="button">
      xiazai
    </button> */}
  </div>
);

const chartContent = ({ key, companyData, companyList, yName, iName }) => {
  const xAxis = [];
  const yAxis = [];
  if (companyList.length > 1) {
    companyData.forEach(item => {
      yAxis.push(item[key]);
      companyList.forEach(it => {
        if (item.company_id === it.id) {
          xAxis.push(it.name);
        }
      });
    });
  }
  return (
    <div style={{ height: '300px' }}>
      <BarChart
        data={{
          data: {
            xAxis,
            yAxis: [{
              value: yAxis,
              // type: 'bar',
              name: iName,
              // unit: '万家',
            }],
            yName,
          },
          bubble: true,
          gridBottom: 140,
        }}
        download
        disableLegend={false}
      />
    </div>
  );
};

const Index = (props) => {
  const { setCollectReq, setFun } = props;
  const query = useQuery();
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState('');
  const [firmList, setFirmList] = useState([]);
  const [tree, setTree] = useState([]);
  const [treeList, setTreeList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedIndex, setCheckedIndex] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [treeShow, setTreeShow] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [rank, setRank] = useState([]);
  const [recordId, setRecordId] = useState(null);
  const [radar, setRadar] = useState({ abscissa_list: ['区域拓展', '创新能力', '融资能力', '成长潜力', '信用风险'] });// ['区域拓展', '创新能力', '融资能力', '成长潜力', '信用风险']
  // const [autoExpandParent, setAutoExpandParent] = useState(true);

  const [getSearchFirmNameListQuery] = useLazyGetSearchFirmNameListQuery();
  const [getTreeQuery] = useLazyGetTreeQuery();
  const [getIndexValueQuery] = useLazyGetIndexValueQuery();
  const [addUserCompanyContrastCollectQuery] = useLazyAddUserCompanyContrastCollectQuery();
  const [getComprehensiveAssessmentResultsQuery] = useLazyGetComprehensiveAssessmentResultsQuery();

  const addUserCompanyContrastCollect = () => {
    if (companyList.length < 1) return;
    addUserCompanyContrastCollectQuery({
      index_key_list: treeList.map(item => item.key),
      company_id_list: companyList.map(item => item.id),
      company_name_list: companyList.map(item => item.name),
      record_id: recordId || null,
    }).then(res => {
      const { status } = res;
      if (status === 'fulfilled') {
        message.info('收藏成功');
      } else {
        message.error('收藏失败请稍后重试');
      }
    }).catch(err => console.log(err));
  };
  // setFun(() => addUserCompanyContrastCollect);
  useEffect(() => {
    setFun(() => addUserCompanyContrastCollect);
  }, [treeList, companyList]);
  const getListKey = (tr, keys) => {
    tr.forEach(it => {
      if (it.children?.length > 0) {
        getListKey(it.children, keys);
      } else {
        keys.push({ key: it.key, title: it.title, unit: it.unit });
      }
    });
  };
  const fixKey = (tr) => {
    if (tr) {
      if (typeof tr.key === 'object') {
        const [k] = tr.key;
        tr.key = k;
      }
      tr.children?.forEach(it => fixKey(it));
    }
  };
  const comprehensiveAssessmentResultsQuery = (list) => {
    getComprehensiveAssessmentResultsQuery({ company_id_list: list.map(it => it.id || it) }).then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        setRadar(data.radar);
        setRank(data.rank);
      } else {
        message.error('获取雷达图数据失败');
      }
    }).catch(err => {
      console.log(err);
    });
  };

  const covertTreeData = (res) => {
    // 将后端返回的treeData处理变形
    // 1. 最外层包上“全部”
    // 2. child 替换为 children
    // 3. key是数组的 提取出key来 !! 临时逻辑，需要让后端改
    if (!res) {
      return {};
    }
    let obj = {
      title: res[0].title,
      key: '',
      child: res.slice(1),
    };
    obj = JSON.parse(JSON.stringify(obj).replaceAll('child', 'children'));
    fixKey(obj);
    return obj;
  };

  const treeToList = (tr) => {
    const list = [];
    tr.children.forEach(it => {
      if (it.children?.length > 0) {
        getListKey(it.children, list);
      } else {
        list.push({ key: it.key, title: it.title, unit: it.unit });
      }
    });
    return list;
  };

  const getIndexValue = (req) => {
    if (req.company_id_list < 1) return;
    // console.log(req.company_id_list > 0);
    getIndexValueQuery(req).then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        setCompanyData(data);
      } else {
        message.error('获取企业信息失败');
      }
    }).catch(err => {
      console.log(err);
    });
    //   index_key_list: ['registered_capital', 'establish_date', 'lead_industry', 'listing_market', 'branch_institution_num', 'number_of_outward_investment', 'main_qualifications', 'country_standard_num', 'industry_standard_num', 'place_standard_num', 'group_standard_num', 'company_standard_num', 'authorized_patent_num', 'authorized_utility_patent_num', 'authorized_appearance_design_patent_num', 'province_innovation_institution', 'country_innovation_institution', 'financing_num', 'financing_money_num', 'capital_type', 'revenue_speed_up', 'patent_speed_up', 'standard_speed_up', 'market_value_speed_up'],
    //   company_id_list: ['646b2b716d4d7052426d714d4d6830684251434d63773d3d0a', '564d6177564865526865585a6a506d4b7967335544513d3d0a'],
  };

  const echoCompanyDetails = (idList, indexList) => {
    // if (companyList?.length > 0) {
    // const companyIdList = companyList.map(item => item.id);
    const indexKeyList = indexList.map(it => it.key);
    comprehensiveAssessmentResultsQuery(idList);
    getIndexValue({ index_key_list: indexKeyList, company_id_list: idList });
    // }
  };
  const getFindTree = (idList) => {
    getTreeQuery().then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        const treeData = covertTreeData(data);
        setTree([treeData]);
        const indexList = treeToList(treeData);
        setTreeList(indexList);
        setCheckedIndex(indexList);
        const defaultKeys = indexList.map(it => it.key);
        setDefaultCheckedKeys(defaultKeys);
        setCheckedKeys(defaultKeys);
        setExpandedKeys(['']);
        if (idList?.length > 0) {
          echoCompanyDetails(idList, indexList);
        }
      } else {
        message.error('获取对比指标失败');
      }
    }).catch(err => {
      console.log('获取对比指标失败', err);
    });
  };

  // 关闭指标选择弹窗
  const closeSelect = () => {
    setTreeShow(false);
  };
  useEffect(() => {
    const id = query.get('id');
    const companyName = query.get('name');
    if (id) {
      setCompanyList([{ id, name: companyName }]);
      getFindTree([id]);
    } else {
      let idList = localStorage.getItem('companyIdList');
      let nameList = localStorage.getItem('companyList');
      setRecordId(localStorage.getItem('recordId'));
      if (idList && nameList) {
        idList = idList.split('、');
        nameList = nameList.split('、');
        setCompanyList(nameList.map((item, index) => ({ id: idList[index], name: nameList[index] })));
      }
      getFindTree(idList);
    }
    window.addEventListener('click', closeSelect);
    return () => {
      localStorage.removeItem('companyIdList');
      localStorage.removeItem('companyList');
      localStorage.removeItem('recordId');
      window.removeEventListener('click', closeSelect);
    };
  }, []);

  const getSearchFirmNameList = () => {
    getSearchFirmNameListQuery({
      like_name: value,
    }).then(res => {
      const { status, data } = res;
      if (status === 'fulfilled') {
        setFirmList(data?.map(item => {
          const oRegExp = new RegExp(value, 'ig');
          const name = item.name.match(oRegExp);
          return {
            showName: item.name.replace(oRegExp, `<span>${name}</span>`),
            name: item.name,
            id: item.id,
          };
        }));
      } else {
        message.error('获取企业列表失败');
      }
    }).catch(err => {
      console.log('获取企业列表错误');
    });
  };
  const showModal = () => (companyList.length > 4 ? message.warning('最多选择五个企业') : setIsModalOpen(true));

  const handleCancel = () => {
    setIsModalOpen(false);
    setFirmList([]);
    setValue('');
  };
  const onSearch = (name) => {
    if (!name) return;
    setFirmList([]);
    getSearchFirmNameList();
  };
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };
  const onCheck = (checkedKeysValue, { checkedNodes }) => {
    setCheckedKeys(checkedKeysValue);
    setCheckedIndex(checkedNodes.filter(it => !it.children));
  };
  const onSelect = (selectedKeysValue, info) => {
    // console.log('onSelect', info);
    setSelectedKeys(selectedKeysValue);
  };
  const treeButOk = () => {
    setTreeShow(false);
    setTreeList(checkedIndex);
    const companyIdList = companyList.map(item => item.id);
    const indexKeyList = checkedIndex.map(it => it.key);
    getIndexValue({ index_key_list: indexKeyList, company_id_list: companyIdList });
  };

  const addCompany = (item) => {
    if (companyList.length > 4) {
      message.warning('最多选择五个企业');
      return;
    }
    const list = uniqBy([...companyList, item], 'id');
    setCompanyList(list);
    comprehensiveAssessmentResultsQuery(list);
    getIndexValue({ index_key_list: treeList.map(it => it.key), company_id_list: list.map(it => it.id) });
  };
  const delCompany = (id, name) => {
    setCompanyList(list => list.filter((item, index2) => !(item.id === id)));
    // setRank(list => list.filter((item, index2) => !(item.company_name === name)));
    // setRadar(obj => ({ abscissa_list: obj.abscissa_list, detail_list: obj.detail_list?.filter((item, index2) => !(item.name === name)) }));
    setCompanyData(list => list.filter(item => item.company_id !== id));
    comprehensiveAssessmentResultsQuery(companyList.filter((item, index2) => !(item.id === id)).map(it => it.id));
  };

  const delIndex = (key) => {
    setTreeList(list => list.filter(it => it.key !== key));
    setCheckedKeys(list => list.filter(it => it.split(',').indexOf(key) < 0));
    setCheckedIndex(list => list.filter(it => it.key.split(',').indexOf(key) < 0));
    // setCompanyData(list => list.filter(it => it.key !== key));
  };

  const getCompanyIndexValueFromData = (id) => companyData.filter(it => it.company_id === id)[0] || {};

  const companyShowIcon = (key) => companyData.filter(item => item[key] > 0);

  return (
    <div className={style.box}>
      <table border="1" width="1360" align="center">
        <tbody>
          <tr align="center">
            <td className={style.selectBut}>
              <button type="button" onClick={(e) => { e.stopPropagation(); setTreeShow(true); }}>
                <span>+</span>
                <span>选择指标</span>
              </button>
            </td>
            {
              tds.map((item, index) => (
                <td className={style.companyName} key={index}>

                  {
                    companyList[index]?.name && (
                      <div>
                        <p>{companyList[index]?.name}</p>
                        <button type="button" onClick={() => delCompany(companyList[index]?.id, companyList[index]?.name)}>
                          <Popover content={content} placement="right">
                            <p className={style.delIcon} />
                          </Popover>
                        </button>
                      </div>
                    )
                  }
                </td>))
            }
            <td className={style.addBut}>
              <button type="button" onClick={showModal}>+</button>
            </td>
          </tr>
          {
            treeList.map(item => (
              <tr align="center" key={item.key}>
                <td align="left">
                  <div>
                    <p>
                      {item.title}
                      <span onClick={() => { delIndex(item.key); }} />
                    </p>
                  </div>
                </td>
                {
                  tds.map((it, index) => {
                    const v = getCompanyIndexValueFromData(companyList[index]?.id)[item.key];
                    if (speedUpKey.indexOf(item.key) > -1 && companyList[index] && typeof v === 'number') {
                      return <td key={it}>{v > 0 ? <p className={style.up} /> : <p className={style.down} />}</td>;
                    }
                    return <td key={it}><div>{v > 0 ? `${v}${unitsMap[item.key] || item.unit}` : v}</div></td>;
                  })
                }
                <td className={style.chart}>
                  {
                    companyShowIcon(item.key).length > 1 && (unitsMap[item.key] || item.unit) && (
                      <Popover placement="left" title={() => chartTitle(item.title)} content={() => chartContent({ key: item.key, companyData, companyList, yName: unitsMap[item.key] || item.unit, iName: item.title })}>
                        <p />
                      </Popover>
                    )
                    // console.log(companyShowIcon(item.key))
                  }
                </td>
              </tr>
            ))
          }
          {
            treeList?.length > 0 && (
              <tr align="center">
                <td style={{ background: '#FFF8EA' }}>企业估值</td>
                {
                  tds.map((it, index) => (
                    <td key={it} className={style.appraisement}>
                      {
                        companyList[index] && (
                          <button
                            type="button"
                            onClick={() => {
                              message.warning('即将上线，敬请期待！');
                              // history.push(`/tool/companyAppraisement?id=${companyList[index]?.id}&name=${companyList[index]?.name}`);
                            }}
                          >
                            去估值
                          </button>
                        )
                      }
                    </td>
                  ))
                }
                <td style={{ border: 'node' }} />
              </tr>
            )
          }

        </tbody>
      </table>
      <Modal title="添加企业" footer={null} visible={isModalOpen} onCancel={handleCancel} >
        <div className={style.ModalContent}>
          <Search
            placeholder="请输入..."
            onSearch={onSearch}
            onChange={e => setValue(e.target.value)}
            value={value}
            allowClear
            style={{
              width: 480,
            }}
          />
          <p>搜索结果</p>
          <ul className={style.list}>
            {
              firmList.length > 0 && (
                firmList.map(item => <div key={item.id} dangerouslySetInnerHTML={{ __html: item.showName }} style={{ cursor: `${companyList.length > 4 ? 'not-allowed' : ''}` }} onClick={() => { addCompany({ name: item.name, id: item.id }); }} />)
              )
            }
          </ul>
        </div>
      </Modal>
      {
        treeShow && (
          <div className={style.treeModal}>
            <p>添加对比对标</p>
            <div className={style.tree}>
              <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                // autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                // defaultCheckedKeys={defaultCheckedKeys}
                // expandedKeys={['data[0].key']}
                onSelect={onSelect}
                selectedKeys={selectedKeys}
                treeData={tree}
              />
            </div>
            <div className={style.treeBut}>
              <button type="button" onClick={() => { setTreeShow(false); }}>取消</button>
              <button type="button" onClick={treeButOk}>确定</button>
            </div>
          </div>
        )
      }
      {
        companyList.length === 0 && (
          <div className={style.noData} style={{ height: `${treeList.length * 40}px` }}>
            <ul>
              <li />
              <li>暂无对比数据</li>
              <li>
                <button type="button" onClick={showModal}>去添加企业</button>
              </li>
            </ul>
          </div>
        )
      }
      <div>
        {
          companyList?.length > 0 && <Bottom radar={radar} rank={rank} />
        }
      </div>
    </div>
  );
};

export default Index;
