import { isArray } from '../../utils/tool';

export function checkMindMapChartData(data: any) {
  let resData: any = [];
  if (data && isArray(data)) {
    resData = data.map((item: any) => ({
      ...item,
      name: item.name || '',
      level: item.level || 0,
      children: item.children || [],
    }));
  }
  return resData;
}
