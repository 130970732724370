import React, { useState, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Evaluation from './components/Evaluation';
import Details from './components/Details';
import BasicInformation from './components/BasicInformation';
import EvaluationResult from './components/EvaluationResult';
import FilesRecording from './components/FilesRecording';
import PreviewPdf from './components/PreviewPdf';
import style from './index.module.less';

const Index = (props) => {
  const [tag, setTag] = useState([]);
  const { location: { pathname } } = props;
  // const userName = localStorage.getItem('userName');
  useEffect(() => {
    setTag(pathname.split('/')[pathname.split('/').length - 1]);
  }, [pathname]);
  const outPage = () => {
    props.history.goBack();
  };
  return (
    <div className={style.box}>
      <header>
        <div>
          <div className={style.headerLeft}>
            专精特新“小巨人”企业智测
          </div>
          <div className={style.headerRight}>
            {
              tag === 'companyIntelligentEvaluation' ? (
                <div
                  className={style.history}
                  onClick={() => {
                    props.history.push('/companyIntelligentEvaluation/filesRecording');
                  }}
                >
                  {/* <span /> */}
                  测评记录
                </div>
              ) : (
                <div className={style.out} onClick={outPage}>
                  <div />
                  <span>返回</span>
                </div>
              )
            }
          </div>
        </div>
      </header>
      <section>
        {/* <div> */}
        <Switch>
          <Route exact path="/companyIntelligentEvaluation" render={((p) => <Evaluation {...p} />)} />
          <Route exact path="/companyIntelligentEvaluation/details" render={((p) => <Details {...p} />)} />
          <Route exact path="/companyIntelligentEvaluation/basicInformation" render={((p) => <BasicInformation {...p} />)} />
          <Route exact path="/companyIntelligentEvaluation/evaluationResult" render={((p) => <EvaluationResult {...p} setTag={setTag} outPage={outPage} />)} />
          <Route exact path="/companyIntelligentEvaluation/filesRecording" render={((p) => <FilesRecording {...p} />)} />
          <Route exact path="/companyIntelligentEvaluation/previewPdf" render={((p) => <PreviewPdf {...p} />)} />
        </Switch>
        {/* </div> */}
      </section>
    </div>
  );
};

export default withRouter(Index);
