/**
  公共请求
 */

import { apiSlice } from '../slices/api';

const INTERACTION = '/interaction';

export const proIntroduce = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 获取验证码 /industry_knowledge_engine/v2/interaction/send_captcha
    getProIntroduceCaptcha: builder.query({
      query(arg) {
        return {
          url: `${INTERACTION}/send_captcha`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 提交 /industry_knowledge_engine/v2/c2b/business_cooperation
    businessCooperation: builder.query({
      query(arg) {
        return {
          url: '/c2b/business_cooperation',
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useLazyGetProIntroduceCaptchaQuery,
  useLazyBusinessCooperationQuery,
} = proIntroduce;
