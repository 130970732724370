/**
 * App全局状态，控制导航相关的一些状态显示
 */
import { createSlice } from '@reduxjs/toolkit';
import { setUser } from '../actions/app';
import { getAccessToken, getRefreshToken, getUserName } from '../utils/auth';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    user: getUserName(),
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    industryCode: '',
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    tokenReceived: (state, { payload }) => {
      const { refreshToken, accessToken } = payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
    setRefreshToken: (state, { payload }) => {
      state.refreshToken = payload;
    },
    logOut: state => {
      // 清理所有的状态
      state.accessToken = null;
      state.refreshToken = null;
    },
    updateIndustryCode: (state, code) => {
      state.industryCode = code;
    },
  },
  extraReducers: builder => {
    builder.addCase(setUser, (state, { payload }) => {
      state.user = payload;
    });
  },
  setReportDataStore: (data) => {
    console.log(data);
  },
});

// export const {
//   tokenReceived,
//   updateIndustryCode,
//   // setAccessToken,
//   // setRefreshToken,
//   // logOut,
// } = appSlice.actions;

export const appSelector = state => state.app;

export default appSlice.reducer;
