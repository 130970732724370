/*
* Author: zhangmeng
* Description: '主要图表组件'
* Date: 2021/07/13
*/

import React, { useRef } from 'react';
import { Image } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import TitleAndSubTitleComp from '../../../TitleAndSubTitleComp';
import style from './index.module.less';

function MainChart(props) {
  const { data } = props;
  const ctnRef = useRef(null);
  const scroll = useRef(0);

  const goLeft = () => {
    const { scrollLeft } = ctnRef.current;
    if (scrollLeft === 0) return;
    scroll.current = scrollLeft - 340 > 0 ? scrollLeft - 340 : 0;
    ctnRef.current.scrollTo({
      left: scroll.current,
      behavior: 'smooth',
    });
  };

  const goRight = () => {
    const { scrollLeft } = ctnRef.current;
    if (scrollLeft !== 0) {
      scroll.current = scrollLeft + 340;
    } else {
      scroll.current = scrollLeft + 340 + 214;
    }
    ctnRef.current.scrollTo({
      left: scroll.current,
      behavior: 'smooth',
    });
  };

  return (
    <div className={style.all}>
      <TitleAndSubTitleComp title="主要图表" subTitle="" />
      <div className={style.content}>
        {
          data.length > 0 ? (
            <>
              <LeftOutlined
                onClick={() => goLeft()}
                style={{ visibility: data.length > 3 ? 'visible' : 'hidden' }}
              />
              <div className={style.imgBox} ref={ctnRef}>
                <Image.PreviewGroup>
                  {
                    data.length > 0 && data.map((item, index) => (
                      <Image
                        key={index}
                        src={item.path}
                        alt=""
                      />
                    ))
                  }
                </Image.PreviewGroup>
              </div>
              <RightOutlined
                style={{ visibility: data.length > 3 ? 'visible' : 'hidden' }}
                onClick={() => goRight()}
              />
            </>
          ) : (
            <div className={style.noData}>暂无数据</div>
          )
        }
      </div>
    </div>
  );
}

export default MainChart;
