/*
 *Author: zhaochenyu
 *Description: 研究机构表格
 *Date: 2020/11/27
*/

import React, { useEffect, useState, useRef } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import CommonTable from './CommonTable';
import TitleAndSubTitleComp from '../../TitleAndSubTitleComp';
import { getInstitutionTableList } from '../../../api/institutionDetail';
import style from './index.module.less';

function InstitutionTable(props) {
  const { field, departments } = props;
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [depart, setDepart] = useState(''); // 依托单位
  const [industry, setIndustry] = useState('');
  const [data, setData] = useState({}); // 列表
  const curCondition = useRef(null);

  const getCondition = (obj) => {
    curCondition.current = {
      ...curCondition.current,
      ...obj,
    };
    getInstitutionTableList({
      ...curCondition.current,
    }).then(res => {
      if (res.status === '00000') {
        setData(res.data);
      }
    });
  };

  useEffect(() => {
    if (field.length > 0 && departments.length > 0) {
      getCondition({
        support_unit: departments,
        industry_name_list: field,
        page_number: curPage,
        page_size: pageSize,
      });
    }
  }, []);

  const changeDepart = name => {
    setDepart(name);
    getCondition({
      ...curCondition.current,
      page_number: 1,
      support_unit: [name],
    });
    setCurPage(1);
  };

  const changeIndustry = name => {
    setIndustry(name);
    getCondition({
      ...curCondition.current,
      page_number: 1,
      industry_name_list: [name],
    });
    setCurPage(1);
  };

  const dealWithTableHead = (tableData) => {
    const { tableHead, tableBody, total } = tableData;
    const newTableHead = tableHead.map(item => {
      let item1;
      if (item.dataIndex === 'index') {
        item1 = {
          ...item,
          width: '5%',
          ellipsis: true,
        };
      } else if (item.dataIndex === 'patent_type') {
        item1 = {
          ...item,
          width: '8%',
          ellipsis: true,
        };
      } else if (item.dataIndex === 'notice_no' || item.dataIndex === 'notice_date') {
        item1 = {
          ...item,
          width: '12%',
          ellipsis: true,
        };
      } else if (item.dataIndex === 'relying_unit') {
        if (departments.length > 0) {
          item1 = {
            ...item,
            width: '18%',
            ellipsis: true,
            filterDropdown: (
              <div className={style.areaDropdown}>
                { departments.length > 0 ? departments.map((item2, index) => (
                  <div
                    className={depart === item2 ? style.selectArea : style.areaItem}
                    key={`${index}relying_unit`}
                    onClick={() => changeDepart(item2)}
                    title={item2}
                  >
                    {item2}
                  </div>
                )) : <div>暂无数据</div>}
              </div>
            ),
            filterIcon: <CaretDownOutlined style={{ display: departments.length > 0 ? '' : 'none' }} />,
          };
        } else {
          item1 = {
            ...item,
            width: '18%',
            ellipsis: true,
          };
        }
      } else if (item.dataIndex === 'industry') {
        item1 = {
          ...item,
          width: '15%',
          ellipsis: true,
          filterDropdown: (
            <div className={style.areaDropdown}>
              { field.length > 0 ? field.map((item2, index) => (
                <div
                  className={industry === item2 ? style.selectArea : style.areaItem}
                  key={`${index}relying_unit`}
                  onClick={() => changeIndustry(item2)}
                  title={item2}
                >
                  {item2}
                </div>
              )) : <div>暂无数据</div>}
            </div>
          ),
          filterIcon: <CaretDownOutlined style={{ display: field.length > 0 ? '' : 'none' }} />,
        };
      } else {
        item1 = {
          ...item,
          ellipsis: true,
        };
      }

      return item1;
    });

    return {
      tableHead: newTableHead,
      tableBody,
      total,
    };
  };

  return (
    <div className={style.all}>
      <div className={style.title}>
        <TitleAndSubTitleComp title="相关专利" />
      </div>
      <CommonTable
        data={Object.keys(data).length > 0 && dealWithTableHead(data)}
        curPage={curPage}
        setCurPage={setCurPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        getCondition={getCondition}
      />
    </div>
  );
}

export default InstitutionTable;
