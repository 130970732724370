import React from 'react';
import { useLocation } from 'react-router';
import { resetTemplateReport } from '../../api/reportGeneration';

function MiniView(props) {
  const location = useLocation();
  const urlParam = location.search.replace('?', '').split('&');
  const result = {};
  urlParam.forEach(item => {
    const data = item.split('=');
    result[data[0]] = data[1] || '';
  });
  localStorage.setItem('token', `Bearer ${result.token}`);
  // resetTemplateReport({
  //   company_info_dict: {
  //     company_id: result.companyId,
  //     company_name: window.decodeURIComponent(result.name),
  //   },
  //   industry_code_list: result.industryKey ? [window.decodeURIComponent(result.industryKey)] : null,
  //   area_key_list: result.areaKey ? [window.decodeURIComponent(result.areaKey)] : null,
  //   template_id: result.id,
  // }).then(res => {
  const msg = window.btoa(window.encodeURIComponent(JSON.stringify({
    curTemplateId: result.curTemplateId,
    id: result.id,
    name: window.decodeURIComponent(result.name) || null,
    area: window.decodeURIComponent(result.area) || null,
    areaKey: result.areaKey ? [result.areaKey] : null,
    industry: window.decodeURIComponent(result.industry) || null,
    industryKey: result.industryKey ? [result.industryKey] : null,
  })));
  props.history.replace(`/reportPreviewForMini?msg=${msg}`);
  // });

  return (
    <div />
  );
}

export default MiniView;
