/**
 * 负责替换数据到文本中，类似 {data.*} 将会被替换
 */

import { ComponentType, useMemo } from 'react';
import { Macro } from '@sqke/parser';
import { merge } from 'lodash';
import { DeltaOperation } from 'quill';

export interface WithDataMacroProps {
  data?: {};
  [key: string]: any;
}

const applyMacro = (properties: any, macro: Macro) => {
  if (!macro) return properties;
  const _props = {} as any;
  const {
    title,
    contents,
    visualConfig,
    macro: _macro,
  } = properties;

  // echarts中series
  if (visualConfig?.series) {
    const visualMarco = new Macro(_macro);
    _props.visualConfig = {
      ...visualConfig,
      series: visualConfig.series.map((op: any) => {
        return {...op, map: visualMarco.replace(op.map)};
      }),
    }
  }

  // 图、表
  if (title) {
    _props.title = {
      ...title,
      content: macro.replace(title.content),
      contentL: macro.replace(title.contentL),
      contentR: macro.replace(title.contentR),
    };
  }
  // 富文本
  if (contents) {
    _props.contents = contents.map((op: DeltaOperation) => {
      const content = {
        ...op,
        insert: macro.replace(op.insert),
      };
      if (op?.attributes?.link) {
        content.attributes = {
          link: macro.replace(op?.attributes?.link),
        };
      }
      return content;
    });
  }

  return merge(
    {},
    properties,
    _props,
  );
}

export function withDataMacro<T extends WithDataMacroProps>(WrappedComponent: ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  const Component = (props: T) => {
    const replacedProps = useMemo(() => {
      if (!props.data) {
        return {};
      }
      return applyMacro(props, new Macro({ data: props.data }))
    }, [props]);

    return <WrappedComponent {...merge({}, props, replacedProps) as T} />;
  };

  Component.displayName = `withDataMacro(${displayName})`;

  return Component;
}
