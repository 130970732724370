import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import Container from '../../Container';
import MarkeValue from './MarketValue';
import FinancialAnalysis from './FinancialAnalysis';
import { useGetListingInformationQuery } from '../../../../../service/companyDetail';

function ListingInformation(props) {
  const { companyId } = props;
  const [ipoPage, setIpoPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  // 上市公司列表
  const { data: ipoData, isFetching: ipoIsFetching } = useGetListingInformationQuery({
    company_id: companyId,
    page_size: ipoPage.pageSize,
    page_number: ipoPage.pageNumber,
  }, { skip: !companyId });
  const { columns: ipoColumns, dataSource: ipoDataSource } = ipoData || {};
  const _ipoColumns = useMemo(() => {
    if (!ipoColumns) return [];
    return ipoColumns.map(item => {
      if (item.dataIndex === 'time_to_market') {
        return {
          ...item,
          width: 120,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'code') {
        return {
          ...item,
          width: 100,
          ellipsis: true,
        };
      }
      return { ...item, ellipsis: true };
    });
  }, [ipoColumns]);
  const ipoTable = useMemo(() => {
    if (!ipoDataSource || ipoDataSource.length) {
      return (
        <Table
          rowKey="code"
          dataSource={ipoDataSource}
          columns={_ipoColumns}
          title={() => ipoData?.title}
          strip
          loading={ipoIsFetching}
          pagination={{
            hideOnSinglePage: true,
            current: ipoPage.pageNumber,
            pageSize: ipoPage.pageSize,
            total: ipoData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setIpoPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [ipoData]);
  return (
    <Container id="上市信息">
      {
        ipoTable
      }
      <MarkeValue {...props} />
      <FinancialAnalysis {...props} />
    </Container>
  );
}

export default ListingInformation;
