/*
 *Author: zhaochenyu
 *Description: 首页底部
 *Date: 2022/06/22
*/

import React from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import logo from '../../../assets/images/nav/logo.png';
// import beian from '../../assets/images/homepage/beian.png';
import { changeSelfPageToOtherProject } from '../../../utils/urlMethods';
import qrcode from '../../../assets/images/homepage/chanyeQrcode.png';
import applet from '../../../assets/images/homepage/chanyeApplet.jpg';
import style from './index.module.less';

const routerlist = [
  {
    name: '产业',
    value: 'industry',
  },
  // {
  //   name: '城市',
  //   value: 'city',
  // },
  {
    name: '报告',
    value: 'report',
  },
  {
    name: '专题',
    value: 'special',
  },
  {
    name: '旧版',
    value: 'oldType',
  },
  // {
  //   name: '解决方案',
  //   value: 'solution',
  // },
  // {
  //   name: '帮助',
  //   value: 'helpCenter',
  // },
];

function HomeBottom(props) {
  const { setLoginShow } = props;
  const pushDetail = (msg) => {
    if (!localStorage.getItem('token')) {
      setLoginShow(true);
    } else if (msg.value === 'industry') {
      window.scrollTo({
        top: 670,
        behavior: 'smooth',
      });
    } else if (msg.value === 'oldType') {
      changeSelfPageToOtherProject('homePage', {}, 'industry_selection_web');
    } else if (msg) {
      props.history.push(`/${msg.value}`);
    }
  };
  const determineFiling = () => {
    if (window.location.host === 'chanyeos.com' || window.location.host === 'www.chanyeos.com') {
      return '京ICP备2020043005号';
    }
    return '京ICP备2020043005号';
  };

  return (
    <div className={style.all}>
      <div className={style.top}>
        <img alt="" src={logo} className={style.logo} />
        {routerlist.map((item, index) => (
          <div
            key={`${index}router`}
            className={style.routerItem}
            onClick={() => pushDetail(item)}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className={style.bottom}>
        <div className={style.bottomWord}>
          北京上奇数字科技有限公司
          <div className={style.line}></div>
        </div>
        <div className={style.bottomWord} style={{ paddingLeft: '30px' }}>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802035485" rel="noopener noreferrer">
            {/* <img src={beian} alt="" style={{ marginRight: '10px' }} /> */}
            <span>
              京公网安备 11010802035485号
            </span>
          </a>
          <div className={style.line}></div>
        </div>
        <div
          className={style.bottomWord}
          style={{ borderRight: 'none', cursor: 'pointer', paddingLeft: '30px' }}
          onClick={() => { window.open('https://beian.miit.gov.cn/'); }}
        >
          { determineFiling() }
        </div>
      </div>
      <img src={qrcode} alt="" className={style.qrcode} />
      <img src={applet} alt="" className={clsx(style.qrcode, style.applet)} />
    </div>
  );
}

export default withRouter(HomeBottom);
