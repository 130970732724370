// 城市详情工具
import React, { useMemo } from 'react';
import style from './index.module.less';
import { useQuery } from '../../../hooks/url';
import MigrationMonitoring from './IndustryMonitor/MigrationMonitoring';
import Cultivate from './IndustryMonitor/Cultivate';
import EarlyWarning from './IndustryMonitor/EarlyWarning';
import Weakness from './IndustryMonitor/Weakness';
import TargetMerchants from './IndustryMonitor/TargetMerchants';
import CityComparison from './IndustryMonitor/CityComparison';
import { openNewPage } from '../../../utils/urlMethods';

const dom = (props) => {
  const { key } = props;
  const onCompanyClick = ({ company_id: id }) => {
    if (!id) return;
    openNewPage('companyDetail', { id });
  };
  const cityToolMap = {
    weakLinks: <Weakness title="短板诊断" {...props} onCompanyClick={onCompanyClick} />,
    targetRecruitment: <TargetMerchants title="靶向招商" {...props} onCompanyClick={onCompanyClick} />,
    classificationBreed: <Cultivate title="分级培育" {...props} onCompanyClick={onCompanyClick} />,
    MigrationMonitoring: <MigrationMonitoring title="迁移监测" {...props} onCompanyClick={onCompanyClick} />,
    companyWarning: <EarlyWarning title="企业预警" {...props} onCompanyClick={onCompanyClick} />,
    CityComparison: <CityComparison title="城市对比对标" {...props} onCompanyClick={onCompanyClick} />,
  };
  return cityToolMap[key];
};

const CityTool = () => {
  const query = useQuery();
  const parameter = useMemo(() => {
    const cityCode = query.get('cityCode');
    if (cityCode) {
      return {
        cityCode: window.atob(query.get('cityCode')),
        title: query.get('title'),
        key: query.get('key'),
        cityName: query.get('cityName'),
        isProvinceDirectly: JSON.parse(query.get('is-province-directly')),
      };
    }
    return undefined;
  }, [query]);
  const { title } = parameter;
  return (
    <div className={style.box}>
      { title !== '城市对标' && <p className={style.title}>{title || ''}</p>}
      {
        parameter && dom(parameter)
      }
    </div>
  );
};

export default CityTool;
