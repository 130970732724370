/*
 * Author: zhangmeng
 * Description: 企业详情上半部分
 * Date: 2020/11/26
 */

import React from 'react';
import CompanyTab from '../../../../../../components/IndustryCompanyDetail/CompanyTab';
import ToolBox from './ToolBox';
import style from './index.module.less';

const DetailTopforwardRef = (props) => {
  const { data, id } = props;
  const {
    companyName,
    establish_date: establishDate,
    staff_num: staffNum,
    reg_capital: regCapital,
    address,
    tags,
  } = data;
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];

  return (
    <div className={style.detailBox}>
      <div className={style.logoDescBox}>
        <div className={style.descBox}>
          <div className={style.content}>
            <div className={style.companyInfo}>
              <div className={style.establishDate}>
                <span className={style.label}>成立日</span>
                <span className={style.value}>{establishDate}</span>
              </div>
              <div className={style.regCapital}>
                <span className={style.label}>注册额</span>
                <span className={style.value}>{regCapital}</span>
              </div>
              <div className={style.staffNum}>
                <span className={style.label}>员工数</span>
                <span className={style.value}>{staffNum}</span>
              </div>
              <div className={style.address}>
                <span className={style.label}>所在地</span>
                <span className={style.value}>{address}</span>
              </div>
            </div>
            {
              !menu.find(item => item === 'hiddenDetailTag') && (
                <div className={style.tags}>
                  {(tags || []).map((item, index) => (
                    item?.tabs.length > 0 && (
                      <div className={style.compantTabs} key={`${index}tab`}>
                        <CompanyTab data={item} />
                      </div>)
                  ))}
                </div>
              )
            }
            {/* <div className={style.btnChart}>
              <ToolButton id={id} name={companyName} />
            </div> */}
          </div>
        </div>
      </div>
      {
        !menu.find(item => item === 'hiddenDetailOption') && <ToolBox companyName={companyName} companyId={id} />
      }
    </div>
  );
};

export default DetailTopforwardRef;
