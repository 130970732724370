/*
 *Author: zhaochenyu
 *Description: 外部链入登录页-首页
 *Date: 2022/04/27
*/

import React, { useEffect } from 'react';
import { Spin, Modal } from 'antd';
import { handleWidthRolePermission } from '../../utils/tool';
import { changeSelfPage, changeSelfPageWithSearch } from '../../utils/urlMethods';
import {
  getResource,
  getToken,
  getUserInfo,
} from '../../api/login';
import store from '../../store';
import { setUser } from '../../actions/app';

function FreeLogin(props) {
  useEffect(() => {
    const nowTime = new Date().getTime();
    const maxTime = localStorage.getItem('cpMaxTime');
    // 源代码为 nowTime >= maxTime 现在让他每次都获取新的token
    if (nowTime) {
      getToken({
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('rft') || '',
      }).then(res => {
        if (res.access_token) {
          getUserInfo().then(info => {
            if (info.status === '00000') {
              if (Object.keys(info.data).length === 0) return;
              getResource().then(res1 => {
                const { phone_no: phone, real_name: _realName } = info.data;
                const realName = String(_realName);
                if (res1.status === '00000') {
                  if (res1.data && res1.data.length > 0) {
                    const permission = handleWidthRolePermission(res1.data);
                    localStorage.setItem('pm', JSON.stringify(permission));
                    localStorage.setItem('userName', realName);
                    localStorage.setItem('ph', phone);
                    store.dispatch(setUser(realName));
                    if (props.location.state) {
                      const { urlkey, urlPath } = props.location.state;
                      changeSelfPageWithSearch(urlPath === '/' ? '/homepage' : urlPath, urlkey);
                    } else {
                      changeSelfPage('homePage');
                    }
                  }
                }
              });
            }
          });
        } else {
          localStorage.setItem('token', '');
          Modal.warning({
            centered: true,
            content: '用户信息已过期，请重新登录！',
            okText: '重新登录',
            keyboard: false,
            onOk: () => {
              if (window.location.href.split('#')[1] === '/homePage') {
                window.location.reload();
              } else {
                store.dispatch(setUser(''));
                window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
              }
              localStorage.clear();
              localStorage.removeItem('rft');
              localStorage.removeItem('rftChanyeMax');
            },
            width: 416,
          });
        }
      });
    } else {
      const rft = localStorage.getItem('rft');
      // const cpToken = localStorage.getItem('cpToken');
      localStorage.setItem('maxTime', maxTime);
      // localStorage.setItem('token', cpToken);
      localStorage.setItem('rft', rft);
      getUserInfo().then(info => {
        if (info.status === '00000') {
          if (Object.keys(info.data).length === 0) return;
          getResource().then(res1 => {
            const { phone_no: phone, real_name: _realName } = info.data;
            const realName = String(_realName);
            if (res1.status === '00000') {
              if (res1.data && res1.data.length > 0) {
                const permission = handleWidthRolePermission(res1.data);
                localStorage.setItem('pm', JSON.stringify(permission));
                localStorage.setItem('userName', realName);
                localStorage.setItem('ph', phone);
                store.dispatch(setUser(realName));
                if (props.location.state) {
                  const { urlkey, urlPath } = props.location.state;
                  changeSelfPageWithSearch(urlPath === '/' ? '/homepage' : urlPath, urlkey);
                } else {
                  changeSelfPage('homePage');
                }
              }
            }
          });
        }
      });
    }
  }, []);
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin spinning></Spin>
    </div>
  );
}

export default FreeLogin;
