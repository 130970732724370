import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import { useGetCapitalChangeQuery } from '../../../../../../service/companyDetail';
import style from '../index.module.less';

const Capital = ({ companyId }) => {
  const [capitalPage, setCapitalPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const { data: capitalData, isFetching: isCapitalFetching } = useGetCapitalChangeQuery({
    company_id: companyId,
    page_size: capitalPage.pageSize,
    page_number: capitalPage.pageNumber,
  }, {
    skip: !companyId,
  });
  const { columns: capitalColumns, dataSource: capitalDataSource } = capitalData || {};
  const _capitalColumns = useMemo(() => capitalColumns?.map(item => {
    if (item.dataIndex === 'index') {
      return {
        width: 70,
        ...item,
      };
    }
    return {
      ...item,
      ellipsis: true,
    };
  }), [capitalColumns]);
  const capitalTable = useMemo(() => {
    if (!capitalDataSource || capitalDataSource.length) {
      return (
        <Table
          rowKey="after"
          dataSource={capitalDataSource}
          columns={_capitalColumns}
          locale={{ emptyText: ' ' }}
          title={() => (
            <>
              <span>{capitalData?.title}</span>
              <span className={style.count}>{capitalData?.count}</span>
            </>
          )}
          strip
          loading={isCapitalFetching}
          pagination={{
            hideOnSinglePage: true,
            current: capitalPage.pageNumber,
            pageSize: capitalPage.pageSize,
            total: capitalPage?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setCapitalPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [capitalData]);

  return capitalTable;
};

export default Capital;
