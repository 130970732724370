import React, { useMemo } from 'react';
import Table from '../../../../components/Table';
import RankingOrder from '../../../../components/RankingOrder';
import { useGetCityChannelInvestmentWorthRankQuery } from '../../../../service/city-channel';

const THEAD_TABLE = {
  index: {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    render: text => <RankingOrder order={text} />,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    className: 'clickable',
    ellipsis: true,
  },
  amount: {
    title: '上轮融资',
    dataIndex: 'amount',
    key: 'amount',
    width: 120,
    ellipsis: true,
  },
  unit: {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
    width: 76,
    ellipsis: true,
  },
  investor: {
    title: '领投方',
    dataIndex: 'investor',
    key: 'investor',
    width: 76,
    ellipsis: true,
  },
};

const COLUMN_KEYS = ['index', 'name', 'amount', 'unit', 'investor'];

function InvestmentWorthRanking(props) {
  const { pageSize, pageNumber, cityCode, onCompanyClick } = props;

  const { data, isFetching } = useGetCityChannelInvestmentWorthRankQuery({
    area_key: cityCode,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !cityCode,
  });

  const { total = 0, columns = [], dataSource = [] } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(column => {
      const rowConfig = {
        ...(THEAD_TABLE[column.dataIndex] ?? {}),
        ...column,
      };
      if (column.dataIndex === 'name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      return rowConfig;
    });
  }, [columns, onCompanyClick]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      loading={isFetching}
      {...props}
    />
  );
}

export default InvestmentWorthRanking;
