import React, { useMemo, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { getStockStrike } from '../../../../../api/companyDetail';
import { upwardFormat } from '../../../../../utils/constructionTree';
import D3ThroughChart from '../../../../Model/D3ThroughChart';
import Container from '../../Container';
import ChartNoData from '../../ChartNoData';

function EquityThrough(props) {
  const { companyId, companyName } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getStockStrike({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setLoading(false);
        // setData([constructionTree(res.data.series[0].links, companyName, 1)]);
        setData(upwardFormat(res.data.series[0].data));
      } else {
        setLoading(false);
      }
    });
  }, []);
  const content = useMemo(() => {
    if (!data) return '';
    const el = Object.keys(data).length > 0 ? (
      <div style={{ height: 720 }}>
        <D3ThroughChart data={data} rootName={companyName} />
      </div>
    ) : <ChartNoData />;
    return el;
  }, [data]);
  return (
    <Container id="股权穿透">
      <Spin tip="搜索中，请稍候...." spinning={loading}>
        {content}
      </Spin>
    </Container>
  );
}

export default EquityThrough;
