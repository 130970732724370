import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Input, Select, Tree, Spin } from 'antd';
import style from './index.module.less';
import { useGetAreaPlugMarqueeV2Query } from '../../../../service/interactive-v2';

const addonBefore = (
  <div className={style.addonBefore} />
);

const TopSearch = forwardRef(({ cityName, cityCode, cityOrCoordinate, setCompanys, generateCompanyLocation, companys, specificAddress }, ref) => {
  TopSearch.timer = null;
  const [area, setArea] = useState({ code: cityCode, name: cityName });
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState('true');
  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState([]);
  const { code, name = '北京市' } = area;
  // 获取地区
  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query();
  // 指定暴露的对象 返回值就是暴露的对象 ref 就是上面的参数
  useImperativeHandle(ref, () => ({
    setLoading,
  }));
  const upadteArea = (value, info) => {
    setArea({
      code: info.node.code,
      name: info.node.title,
    });
  };

  useEffect(() => {
    if (name) cityOrCoordinate(name);
  }, [name]);

  useEffect(() => {
    if (!keyword) setOpen(false);
  }, [keyword]);

  // 搜索企业
  const getPlaceSearch = () => {
    setOpen(true);
    setLoading(true);
    setCompanys([]);
    if (name && keyword) {
      specificAddress(name, keyword);
    }
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.keyCode === 13) {
        getPlaceSearch();
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [keyword, name]);

  const addonAfter = (
    <div
      className={style.addonAfter}
      onClick={getPlaceSearch}
      onKeyDown={(e) => {
        // console.log(e);
      }}
    >
      <span>|</span>
      <div />
    </div>
  );
  return (
    <div className={style.box}>
      <div className={style.select}>
        <Select
          value={name}
          style={{
            width: 160,
            marginRight: 12,
          }}
          dropdownRender={() => (
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
              <Tree
                // defaultExpandedKeys={['000000']}
                checkedKeys={[code]}
                onSelect={upadteArea}
                treeData={regionTree[0]?.children}
              />
            </div>
          )}
        />
      </div>
      <div className={style.input}>
        <Input
          placeholder="搜位置"
          value={keyword}
          onChange={(e) => { setKeyword(e.target.value); }}
          suffix={addonAfter}
          prefix={addonBefore}
        />
        <div className={style.selectList} style={{ display: open ? 'block' : 'none' }}>
          <Spin spinning={loading} wrapperClassName={style.loding} delay={200}>
            <div>
              {
                companys?.map(item => {
                  // const oRegExp = new RegExp(keyword, 'ig');
                  // const replace = item?.name?.match(oRegExp);
                  // const replaceName = item?.name?.replace(oRegExp, `<span>${replace}</span>`);
                  let replaceName = '';
                  try {
                    const oRegExp = new RegExp(keyword, 'ig');
                    const replace = item?.name?.match(oRegExp);
                    replaceName = item?.name?.replace(oRegExp, `<span>${replace}</span>`);
                  } catch {
                    replaceName = item?.name?.replace(keyword, `<span>${keyword}</span>`);
                  }
                  return (
                    <div
                      key={item?.id}
                      className={style.companyName}
                      onClick={() => {
                        // generateCompanyLocation([item]);
                        setCompany([item]);
                        setOpen(false);
                        setKeyword(item?.name);
                      }}
                    >
                      <span />
                      <div dangerouslySetInnerHTML={{ __html: replaceName }} />
                    </div>
                  );
                })
              }
            </div>
            <div className={style.specificAddress}>
              <p style={{ border: '1px solid #E9F0F4', margin: '20px' }} />
              {
                companys?.length && companys.map(item => {
                  if (item.address && typeof (item.address) === 'string') {
                    const replaceName = item?.address?.replace(keyword, `<span>${keyword}</span>`);
                    return (
                      <div
                        key={item?.id}
                        className={style.companyName}
                        onClick={() => {
                        // generateCompanyLocation([item]);
                          setCompany([item]);
                          setOpen(false);
                          setKeyword(item?.address);
                        }}
                      >
                        {/* <span /> */}
                        <div dangerouslySetInnerHTML={{ __html: replaceName }} />
                      </div>
                    );
                  }
                  return undefined;
                })
              }
              {/* {
                companys?.map(item => (
                  <div className={style.companyName}>
                    {item.address}
                  </div>
                ))
              } */}
            </div>
          </Spin>
        </div>
      </div>
      <div
        className={style.location}
        onClick={() => {
          setOpen(false);
          if (company.length > 0) { generateCompanyLocation(company, true); }
        }}
      />
    </div>
  );
});

export default TopSearch;
