import React, { useEffect, useMemo } from 'react';
import Table from '../../../../components/Table';
import DataSearchButton from '../DataSearchButton';
import Score from '../../../../components/Score';
import styles from './index.module.less';
import {
  useGetChannelDataCompanyListQuery,
} from '../../../../service/industry-channel';
import CustomTreeFilter from '../../../../components/CustomTreeFilter';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetCompanySpecialTagsQuery,
} from '../../../../service/interactive-v2';
import { findNode, getSingleOption } from '../../../../utils/tool';
import { getNameConfig, getSortKv } from '../../../../utils/table';
import SortTitle from '../SortTitle';
import { FilterIcon } from '../../../../components/CustomTableIcon';
import CustomListFilter from '../../../../components/CustomListFilter';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    width: 330,
    ellipsis: true,
    className: styles.name,
  },
  reg_capital_std: {
    title: '注册资本',
    dataIndex: 'reg_capital_std',
    key: 'reg_capital_std',
    width: 96,
    ellipsis: true,
    sorter: true,
  },
  establish_date: {
    title: '成立时间',
    dataIndex: 'establish_date',
    key: 'establish_date',
    width: 100,
    sorter: true,
    className: styles.number,
  },
  location: {
    title: '区域',
    dataIndex: 'location',
    key: 'location',
    width: 72,
    ellipsis: true,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
  },
  special_tag: {
    title: '资质类别',
    dataIndex: 'special_tag',
    key: 'special_tag',
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => (Array.isArray(text) ? text.join('、') : text),
  },
  industry_patent: {
    title: '本产业专利',
    dataIndex: 'industry_patent',
    key: 'industry_patent',
    width: 92,
    align: 'center',
    // sortDirections: ['descend'],
    // sorter: true,
    className: styles.number,
  },
  accumulation_monthly: {
    title: '全部专利',
    dataIndex: 'accumulation_monthly',
    key: 'accumulation_monthly',
    width: 92,
    align: 'center',
    sortDirections: ['descend'],
    sorter: true,
    className: styles.number,
  },
  total_point: {
    title: '上奇评价',
    dataIndex: 'total_point',
    key: 'total_point',
    width: 80,
    render: text => <Score score={text} />,
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
};

const COLUMN_KEYS = [
  'index', 'name', 'reg_capital_std', 'establish_date',
  'location', 'diy_tag', 'industry_patent', 'accumulation_monthly', 'total_point',
];

function CompanyTable(props) {
  const {
    keyword,
    industryCode,
    sortedInfo = {},
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    onClick,
    onData,
    setParams,
  } = props;

  const { data: tags = [] } = useGetCompanySpecialTagsQuery();
  const { regionTree = [] } = useGetAreaPlugMarqueeV2Query(
    undefined,
    {
      selectFromResult: ({ data }) => {
        const root = data ? findNode(data[0], node => node.key === '000000') : null;
        if (!root) return [];
        return {
          regionTree: [root],
        };
      },
    },
  );

  const {
    key: sortKey,
    value: sortValue,
  } = getSortKv(sortedInfo);

  const { data, isFetching, isSuccess } = useGetChannelDataCompanyListQuery({
    key_word: keyword,
    industry_code: industryCode,
    sort_key: sortKey,
    sort_value: sortValue,
    area_key: getSingleOption(filteredInfo.location) ?? '000000',
    company_type: (filteredInfo?.special_tag && filteredInfo.special_tag.filter(item => item !== 'all').length !== 0)
      ? filteredInfo.special_tag : undefined,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });
  const { total = 0, columns = [], dataSource } = data ?? {};
  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'name') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({ onClick: onCompanyClick, keyword })),
        };
      }
      if (item.dataIndex === 'location') {
        rowConfig.filters = regionTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
        rowConfig.filteredValue = filteredInfo?.location ?? ['000000'];
      }
      if (item.dataIndex === 'special_tag') {
        rowConfig.filters = tags;
        rowConfig.filteredValue = filteredInfo.special_tag?.length > 0 ? filteredInfo.special_tag : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if ([
        'industry_patent',
        'accumulation_monthly',
      ].includes(item.dataIndex)) {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item })}
          />
        );
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [onCompanyClick, columns, onClick, sortedInfo, filteredInfo, tags]);

  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        industry_code: industryCode,
        sort_key: sortKey,
        sort_value: sortValue,
        area_key: getSingleOption(filteredInfo.location) ?? '000000',
        company_type: (filteredInfo?.special_tag && filteredInfo.special_tag.filter(item => item !== 'all').length !== 0)
          ? filteredInfo.special_tag : undefined,
      });
    }
  }, [keyword, industryCode, sortedInfo, filteredInfo]);
  return (
    <Table
      columns={_columns}
      dataSource={isSuccess ? dataSource : []}
      loading={isFetching}
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      {...props}
    />
  );
}

export default CompanyTable;
