import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
// import { Popover } from 'antd';
// import { QuestionCircleOutlined } from '@ant-design/icons';
import Container from '../../../Container';
import { useGetCompanyRankTagListQuery } from '../../../../../../service/companyDetail';
import style from './index.module.less';

const BeRanked = ({ companyId }) => {
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: tableFetching } = useGetCompanyRankTagListQuery(
    {
      page_size: page.pageSize,
      page_number: page.pageNumber,
      company_id: companyId,
    },
    { skip: !companyId },
  );
  const columns = useMemo(() => tableData?.columns.map(item => {
    if (item.dataIndex === 'index') {
      return {
        width: 70,
        ...item,
      };
    }
    if (item.dataIndex === 'year') {
      return {
        width: 100,
        ...item,
      };
    }
    if (item.dataIndex === 'company_name') {
      return {
        width: 300,
        ...item,
      };
    }
    return item;
  }), [tableData]);
  const tableEl = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={columns}
          locale={{ emptyText: ' ' }}
          title={() => (
            <>
              <span className={style.tableTitle}>{tableData?.title}</span>
              {/* <Popover content='穿透该企业1、2、3级子公司资质情况' placement="right" overlayClassName={style.pop}>
                <QuestionCircleOutlined />
              </Popover> */}
              <span className={style.count}>{tableData?.count}</span>
            </>
          )}
          strip
          loading={tableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [tableData]);
  return (
    <Container>
      {
        tableEl
      }
    </Container>
  );
};

export default BeRanked;
