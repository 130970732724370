import * as CONSTS from '../../consts';

export const modeStyle = {
  light: {
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',
    series: {
      label: {
        color: '#fff',
      },
    },
    itemStyle: {
      borderColor: '#fff',
    },
    visualMap: {
      color: [
        'rgb(13, 59, 102)', 'rgb(78, 114, 147)', 'rgb(101, 145, 185)', 'rgb(137, 175, 210)', 'rgb(163, 194, 210)', 'rgb(180, 210, 214)', 'rgb(200, 237, 228)', 'rgb(171, 231, 219)', 'rgb(138, 219, 203)', 'rgb(109, 198, 180)', 'rgb(74, 178, 157)', 'rgb(73, 140, 103)', 'rgb(103, 161, 129)', 'rgb(115, 178, 143)', 'rgb(148, 197, 170)', 'rgb(181, 216, 192)', 'rgb(194, 216, 181)', 'rgb(219, 226, 183)', 'rgb(225, 222, 159)', 'rgb(255, 237, 176)', 'rgb(255, 218, 144)', 'rgb(233, 197, 130)', 'rgb(232, 174, 118)', 'rgb(227, 157, 117)', 'rgb(211, 134, 110)',
      ],
    },
  },
  dark: {
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
    itemStyle: {
      borderColor: '#fff',
    },
    series: {
      label: {
        color: '#fff',
      },
    },
    visualMap: {
      color: [
        'rgb(13, 59, 102)', 'rgb(78, 114, 147)', 'rgb(101, 145, 185)', 'rgb(137, 175, 210)', 'rgb(163, 194, 210)', 'rgb(180, 210, 214)', 'rgb(200, 237, 228)', 'rgb(171, 231, 219)', 'rgb(138, 219, 203)', 'rgb(109, 198, 180)', 'rgb(74, 178, 157)', 'rgb(73, 140, 103)', 'rgb(103, 161, 129)', 'rgb(115, 178, 143)', 'rgb(148, 197, 170)', 'rgb(181, 216, 192)', 'rgb(194, 216, 181)', 'rgb(219, 226, 183)', 'rgb(225, 222, 159)', 'rgb(255, 237, 176)', 'rgb(255, 218, 144)', 'rgb(233, 197, 130)', 'rgb(232, 174, 118)', 'rgb(227, 157, 117)', 'rgb(211, 134, 110)',
      ],
    },
  },
};
export const chartFontSize = {
  small: {
    legnedFontSize: 10,
  },
  middle: {
    legnedFontSize: 12,
  },
  large: {
    legnedFontSize: 14,
  },
};
