/*
 *Author: zhaochenyu
 *Description: 区域画像预览尾页
 *Date: 2021/11/17
*/

import React from 'react';
import style from './index.module.less';

function BackCover() {
  return (
    <div className={style.all}>
      <div className={style.desc}>
        本报告是基于全球首款的产业通SaaS平台生成，该SaaS平台已汇聚了5000万家企业、4000万条专利、200余万投资、100余万招标、
        20万个人才、10余万种产品、100多个产业图谱、4000多个区域统计信息等40余类产业数据集，可提供城市画像、产业画像、企业画像、
        招商资讯、股权投资、市场情报等数十类报告生成和下载，并提供强大的数据搜索、分析工具、内容订阅、情报推送等丰富的产业数据和
        知识图谱服务（www.chanyeos.com）。
      </div>
      <div className={style.qrWord}>关注“上奇”</div>
      <div className={style.chanyeWord}>关注“上奇产业通”</div>
      <div className={style.QRcode} />
      <div className={style.coverLog} />
      <div className={style.chanyeCode} />
      <div className={style.companyName}>北京上奇数字科技有限公司</div>
      <div className={style.zhushi}>
        <p>Beijing Shangqi Digital Technology Co., Ltd.</p>
        <p>北京市海淀区成府路150号智源大厦2层</p>
        <p>商务合作&nbsp;&nbsp;&nbsp;&nbsp;客服电话：17200309680</p>
      </div>
    </div>
  );
}

export default BackCover;
