import { createSlice } from '@reduxjs/toolkit';

const dataAnalysisSlice = createSlice({
  name: 'dataAnalysis',
  initialState: {
    reportDataStore: JSON.parse(localStorage.getItem('reportData')) || {},
    intelligentDataStore: JSON.parse(localStorage.getItem('intelligentWriting')) || [],
    reportDownLoadUrl: '',
  },
  reducers: {
    setReportDataStore: (state, { payload }) => {
      state.reportDataStore = payload;
    },
    setIntelligentDataStore: (state, { payload }) => {
      state.intelligentDataStore = payload;
    },
    setReportDownLoadUrl: (state, { payload }) => {
      state.reportDownLoadUrl = payload;
    },
  },
});

// export const dataAnalysisSelector = state => state.dataAnalysis;
export const { setReportDataStore, setIntelligentDataStore, setReportDownLoadUrl } = dataAnalysisSlice.actions;
export default dataAnalysisSlice.reducer;
