import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';

function TransBarChart(props) {
  const { data, yName } = props;
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!data) return;
    const { xAxis, yAxis } = data;
    if (!Array.isArray(yAxis) || !Array.isArray(xAxis)) return;
    const _xAxis = cloneDeep(xAxis).reverse();
    const newData = yAxis[0]?.value.map((item, index) => (
      {
        value: item,
        itemStyle: {
          color: '#1961F5',
        },
      }
    )) ?? [];

    const option = {
      tooltip: {
        formatter: params => {
          const str = `<div>${params.marker}${params.name}：${params.data.value}件</div>`;
          return str;
        },
      },
      grid: {
        top: 36,
        right: 20,
        bottom: 10,
        left: 60,
      },
      xAxis: [
        {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: '#EBEBEB',
              type: 'dashed',
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          name: yName,
          nameLocation: 'end',
          nameTextStyle: {
            align: 'right',
            padding: [0, 8, 0, 0],
          },
          type: 'category',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#7B8796',
            fontSize: 12,
          },
          splitLine: {
            show: false,
          },
          data: _xAxis,
        },
      ],
      series: [
        {
          name: xAxis?.[0]?.name,
          type: 'bar',
          barMaxWidth: 12,
          data: newData?.reverse(),
          label: {
            show: true,
            color: '#7B8796',
            position: 'right',
          },
        },
      ],
    };
    setOptions(option);
  }, [data]);

  return (
    <ReactEcharts
      option={options}
      notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default TransBarChart;
