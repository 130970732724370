import React, { useRef, useMemo } from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import bannerWord from '../../../../assets/images/specializedAndNew/zhuanjingtexin.png';
import bannerWord2 from '../../../../assets/images/specializedAndNew/zhongbangfabu.png';
import privateWord from '../../../../assets/images/specializedAndNew/private500Word.png';
import Fh from '../../../../assets/images/specializedAndNew/privateFh.png';
import centralWord from '../../../../assets/images/specializedAndNew/centralWord.png';
import listedWoed from '../../../../assets/images/specializedAndNew/listedWoed.png';
import chinaTop500Word from '../../../../assets/images/specializedAndNew/chinaTop500Word.png';
import { openNewPage } from '../../../../utils/urlMethods';
import style from './index.module.less';

const imgs = {
  中国民营企业500强: (
    <div className={style.private}>
      <img alt="" src={privateWord} className={style.privateWord} />
      <img src={Fh} alt="" className={style.privateFh} />
      <div className={style.privateDesc}>
        “中国民营企业500强”是中华全国工商业联合会2010年起主办的发布活动，“中国民营企业500强”是全国工商联对上规模民营企业调研的基础上，按照年营业收入总额降序排列产生，不是“评选”产生。
      </div>
    </div>
  ),
  央企: (
    <div className={style.central}>
      <img alt="" src={centralWord} className={style.centralWord} />
      <img src={Fh} alt="" className={style.centralFh} />
      <div className={style.centralDesc}>
        中央企业，为“中央管理企业”的简称，是指由中央人民政府（国务院）或委托国有资产监督管理机构行使出资人职责，领导班子由中央直接管理或委托中央组织部、国务院国资委或其他等中央部委（协会）管理的国有独资或国有控股企业。
      </div>
    </div>
  ),
  A股上市企业: (
    <div className={style.listed}>
      <img alt="" src={listedWoed} className={style.listedWoed} />
      <img src={Fh} alt="" className={style.listedFh} />
      <div className={style.listedDesc}>
        上市企业是指所发行的股票经过国务院或者国务院授权的证券管理部门批准在证券交易所上市交易的股份有限公司。
      </div>
    </div>
  ),
  中国企业500强: (
    <div className={style.listed}>
      <img alt="" src={chinaTop500Word} className={style.listedWoed} />
      <img src={Fh} alt="" className={style.listedFh} />
      <div className={style.listedDesc}>
        中国企业500强是 由中国企业联合会、中国企业家协会按国际惯例组织评选、发布的中国企业排行榜。自2002年起，每年向社会公布一次中国企业500强年度排行榜。
      </div>
    </div>
  ),
};
const bannerTitle = {
  央企: '央企画像',
  A股上市企业: '上交所上市公司画像',
  中国企业500强: '中国500强企业画像',
  中国民营企业500强: '中国民营企业500强画像',
};

const Banner = ({ name, reportData }) => {
  const carouselRef = useRef(null);

  const banner1 = useMemo(() => imgs[name], [name]);

  return (
    <div className={style.all}>
      <div className={style.banner}>
        <div
          className={style.toleft}
          onClick={() => carouselRef.current.prev()}
        >
          <LeftOutlined />
        </div>
        <div
          className={style.toright}
          onClick={() => carouselRef.current.next()}
        >
          <RightOutlined />
        </div>
        <Carousel autoplay dotPosition="bottom" ref={el => { carouselRef.current = el; }}>
          {/* <div className={style.banner1}>
            <img alt="" src={bannerWord} className={style.banner1Word} />
            <div className={style.banner1Desc}>
              “专精特新”中小企业是指具有“专业化、精细化、特色化、 新颖化”特征的中小企业，企业规模必须符合国家《中小企业划型标准》
            </div>
          </div> */}
          {banner1}
          <div className={style.banner2}>
            <div className={style.bangs}>
              <div className={style.desc1}>
                {['竞争力', '创新价值', '投资价值', '成长潜力百强榜'].map((item, index) => (
                  <div className={style.bangsItem} key={`${index}item`}>
                    {item}
                    <div className={style.rightLine} style={{ display: index === 3 ? 'none' : '' }} />
                  </div>
                ))}
              </div>
            </div>
            <div className={style.word} >
              <div className={style.desc2}>{bannerTitle[name]}</div>
              <div>
                <img alt="" src={bannerWord2} className={style.banner2Word} />
              </div>
              <div
                className={style.reportBtn}
                onClick={() => {
                  if (reportData?.data?.length > 0) {
                    const { id, title, sub_title: subTitle, Buy_state: isApply, price,
                      cover_url: coverImg } = reportData?.data?.[0];
                    openNewPage('boutiqueDetail', {
                      msg: window.btoa(window.encodeURIComponent(JSON.stringify({
                        title,
                        subTitle,
                        isApply,
                        price,
                        coverImg,
                        id,
                      }))),
                    });
                  }
                }}
              >
                点击查看报告
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Banner;
