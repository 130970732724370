import { getSeriesGauge } from './seriesGauge';
import { getSeriesGraph } from './seriesGraph';
import { getLineSeries } from './seriesLine';
import { getBarSeries } from './seriesBar';
import { getPieSeries } from './seriesPie';
import { getSeriesTree } from './seriesTree';
import { getWordCloudSeries } from './seriesWordCloud';
import {
  IModelMiddleware,
  ISeriesGetter,
  SeriesTypes,
} from '../interface/base';
import { merge } from 'lodash';
import { buildJSONLogic } from '../../expression';
import { OptionParser } from './optionParser';
import jsonLogic, { RulesLogic } from 'json-logic-js';
import { getRadarSeries } from './seriesRadar';
import { Matcher } from '../interface/matchers';
import { getSeriesMap } from './seriesMap';
import { getScatterSeries } from './seriesScatter';
import { getPiesSeries } from './seriesPies';
import { getSeriesTreeMap } from './seriesTreeMap';
import { getSeriesSankey } from './seriesSankey';
import { getSeriesFunnel } from './seriesFunnel';

const GET_SERIES_METHODS: {[key in SeriesTypes]: ISeriesGetter<any>}  = {
  line: getLineSeries,
  bar: getBarSeries,
  radar: getRadarSeries,
  pie: getPieSeries,
  pies: getPiesSeries,
  tree: getSeriesTree,
  treemap: getSeriesTreeMap,
  scatter: getScatterSeries,
  map: getSeriesMap,
  wordCloud: getWordCloudSeries,
  gauge: getSeriesGauge,
  graph: getSeriesGraph,
  sankey: getSeriesSankey,
  funnel: getSeriesFunnel,
};

/**
 * composeMatchers
 * 将自定义的逻辑对象数组，转换为 JSONLogic 对应的格式
 */
const composeMatchers = (matchers: Matcher[] | undefined) => {
  const jsonLogicRules = matchers
    ? matchers
      .map(({ rules, result }) => {
        const r = rules
          .map(rule => {
            return buildJSONLogic(rule.conditions, rule.key);
          })
          .reduce<RulesLogic<any>>((prev, next) => {
            return { 'and': [prev, next] } as RulesLogic<any>;
          }, true);

        return {
          rules: r,
          result,
        };
      })
    : [];


  return (dataItem: any) => {
    if (jsonLogicRules.length === 0) {
      return {};
    }
    const matchResult = jsonLogicRules
      .filter(({ rules }) => {
        return jsonLogic.apply(rules, dataItem);
      })
      .map(item => item.result);
    return merge({}, ...matchResult);
  };
};

export const getSeries: IModelMiddleware = (option, material) => {
  const { config: raw, data } = material;
  if (!data || Object.keys(data).length === 0) return {};
  const { type, visualConfig } = raw;

  const {
    itemMatchers,
    series: seriesStyles,
  } = visualConfig;

  const {
    series = [],
  } = material.data ?? {};

  const getMatchedItemStyle = composeMatchers(itemMatchers);
  const optionParser = new OptionParser(option);

  return {
    series: series.map((item, index) => {
      const seriesStyle = seriesStyles?.[index];
      const _type = (seriesStyle?.type ?? type) as SeriesTypes;
      const getter = GET_SERIES_METHODS[_type];
      if (!getter) {
        return { ...item, type: _type,  };
      }
      // 如果是特殊类型，则
      const _series = getter(
        { ...seriesStyle, ...item,  },
        index,
        { option, material, getMatchedItemStyle, optionParser }
      );
      if (Array.isArray(_series)) {
        return _series;
      }

      return {
        type: _type,
        ..._series,
      };
    }).flat(),
  };
};

export const series: IModelMiddleware = (option, material) => {
  return merge(option, getSeries(option, material));
}
