import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { SQData } from '@sqke/ts-interface';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';

let query: BaseQueryFn | null = null;

const baseQuery = fetchBaseQuery({
  baseUrl: '',
});

const customBaseQuery = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  if (!query) {
    const result = await baseQuery(args, api, extraOptions) as
      QueryReturnValue<SQData, FetchBaseQueryError, FetchBaseQueryMeta>;
    if (result.error) {
      const { status: _status } = result.error;
      if (typeof _status === 'number') {
        return result;
      }
      return {
        ...result,
        error: {
          status: _status,
          data: {
            status: _status,
            data: result.error,
          },
        },
      };
    }

    return {
      data: result.data?.data ?? result.data,
      meta: result.meta,
    };
  }
  return query(args, api, extraOptions);
};

export const api = createApi({
  reducerPath: '@sqke/report-render',
  baseQuery: customBaseQuery,
  tagTypes: ['JSON'],
  endpoints: (builder) => ({}),
});

export const setBaseQuery = (nextBaseQuery: BaseQueryFn) => {
  query = nextBaseQuery;
}

