import React, { useState } from 'react';
import { Button } from 'antd';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import {
  useGetIndustryCarrierOverlayNumberQuery,
} from '../../../../service/city-channel';
import Underline from '../../../../components/Underline';
import InnovativeTable from '../InnovativeTable';
import { openNewPage } from '../../../../utils/urlMethods';
import ExportModal from '../../../../components/ExportModal';
import styles from './index.module.less';

const IndustryListing = ({ name, cityCode }) => {
  // const [areaCode, setAreaCode] = useState(cityCode);
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const exportFlag = Array.isArray(enterpriseAccountExport)
  && enterpriseAccountExport?.length > 0
  && enterpriseAccountExport?.includes('exportCityGraphOrg');
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState({
    industryCode: '',
    industryName: '',
    filteredInfo: {},
  });
  const [exportModalVisible, setExportModalVisible] = useState(false);
  // const regionCode = useSelector(state => state.app.industryCode.payload);
  const { data: industryNumber } = useGetIndustryCarrierOverlayNumberQuery({
    area_key: cityCode,
  });
  const [params, setParams] = useState(null);
  return (
    <div id='inventory' className={styles.box}>
      <div className={styles.title}>
        <Title title={name} />
      </div>
      {/* <div className={styles.titleTwo}>
        <Title title='产业创新载体清单' />
      </div> */}
      <div className={styles.overview}>
        <Subtitle
          className={styles.subtitle}
          description="共计"
          value={industryNumber?.data_count}
          unit="条,"
        />
        <Subtitle
          className={styles.subtitle}
          description="覆盖"
          value={industryNumber?.industry_count}
          unit="个产业"
        />
        <Subtitle
          className={styles.subtitle}
          description="筛选结果"
          value={count}
          unit="条"
        />
        {
          exportFlag && (
            <Button className={styles.exportButton} onClick={() => setExportModalVisible(true)}>数据导出</Button>
          )
        }
      </div>
      <div className={styles.float}></div>
      <Underline />
      <InnovativeTable
        regionCode={cityCode}
        pageSize={mapCompanyListQueryState.pageSize}
        pageNumber={mapCompanyListQueryState.pageNumber}
        filteredInfo={mapCompanyListQueryState.filteredInfo}
        onChange={({ current, pageSize }, filter) => {
          setMapCompanyListQueryState({
            ...mapCompanyListQueryState,
            pageSize,
            pageNumber: current,
            filteredInfo: filter,
          });
        }}
        getLoading={e => setLoading(e)}
        getCount={setCount}
        onCompanyClick={({ _id }) => {
          if (!_id) return;
          openNewPage('companyDetail', { id: _id });
        }}
        setParams={setParams}
      />
      {
        exportModalVisible && (
          <ExportModal
            dataKey="city_channel_atlas_industry_carrier"
            params={params}
            onClose={() => {
              setExportModalVisible(false);
            }}
          />
        )
      }
    </div>
  );
};

export default IndustryListing;
