/*
* Author: zhangmeng
* Description: '企业详情'
* Date: 2020/11/25
*/

import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { Spin } from 'antd';
import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import style from './index.module.less';
import DetailTop from './DetailTop';
import CompanyContent from './CompanyContent';
import { getTitleInfo, getAchorTab, getCompanyModuleList } from '../../api/companyDetail';
import { useRecordingSpotQuery } from '../../service/public';

const levelColor = {
  S: {
    color: '#0D3B66',
  },
  AAA: {
    color: '#4E7293',
  },
  AA: {
    color: '#A9BDD5',
  },
  A: {
    color: '#A4C3B2',
  },
};
const tooltip = {
  title: '上奇企业评级指标说明分为4类',
  detail: [
    {
      level: 'S',
      desc: '排名在前10%',
    },
    {
      level: 'AAA',
      desc: '排名在30%—10%',
    },
    {
      level: 'AA',
      desc: '排名在50%—30%',
    },
    {
      level: 'A',
      desc: '排名在后50%',
    },
  ],
};

function IndustryCompanyDetail(props) {
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const { companyId, scrollContent } = props;
  const { href } = window.location;
  const [companyIdCopy, setCompanyIdCopy] = useState(companyId);
  const [headInfo, setHeadInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [achorFixHeight, setAchorFixHeight] = useState(596);
  const [moduleTabs, setModuleTabs] = useState({}); // 功能模块tab
  const contentRef = useRef(null);
  const timer = useRef(null);
  const topRef = useRef(null);
  const params = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);
  const timer1 = useRef(null);
  const timer2 = useRef(null);
  const closePoll = useRef(false);
  const detailTopRef = useRef(null);
  useRecordingSpotQuery({
    spot_name: '企业详情',
  });
  const otherPush = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (contentRef.current) {
      setTimeout(() => {
        contentRef.current.contentAnchorPush(window.decodeURIComponent(window.atob(params.get('anchor'))));
      }, 1000);
    } else {
      timer.current = setTimeout(() => {
        otherPush();
      }, 500);
    }
  }, []);

  const findCompnayStatus = (id) => {
    if (timer2.current) {
      clearTimeout(timer.current);
      timer2.current = null;
    }
    getCompanyModuleList({
      company_id: id,
    }).then(res => {
      if (res.status === '00000') {
        setModuleTabs(res.data.result);
        setContentLoading(false);
        if (res.data.close) {
          if (params.get('anchor')) {
            otherPush();
          }
        }
        if (!res.data.close && !closePoll.current) {
          timer2.current = setTimeout(() => {
            findCompnayStatus(id);
          }, 1000);
        }
      } else if (!closePoll.current) {
        timer2.current = setTimeout(() => {
          findCompnayStatus(id);
        }, 1000);
      }
    }).catch(() => {
      if (!closePoll.current) {
        timer2.current = setTimeout(() => {
          findCompnayStatus(id);
        }, 1000);
      }
    });
  };

  const heightListener = () => {
    const a = document.getElementById('companyAchorTab');
    if (a) {
      setAchorFixHeight(params.get('id') ? a.offsetTop - 52 : a.offsetTop + 64);
    }
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(heightListener);
    resizeObserver.observe(topRef.current);
    setLoading(true);
    const _companyId = params.get('id') || '';
    getTitleInfo({
      company_id: !companyId ? _companyId : companyId,
      credit_code: params.get('credit_code') || '',
    }).then(res => {
      if (res.status === '00000') {
        setHeadInfo(res.data);
        setLoading(false);
        setContentLoading(true);
        setCompanyIdCopy(res.data?.company_id);
        getAchorTab({
          // company_id: !companyId ? params.get('id') : companyId,
          company_id: res.data?.company_id,
          company_name: res.data?.companyName || '',
        });
        timer2.current = setTimeout(() => {
          findCompnayStatus(res.data?.company_id);
        }, 1000);
      } else {
        setLoading(false);
      }
    });
    timer1.current = setTimeout(() => {
      closePoll.current = true;
    }, 120 * 1000);
    return () => {
      closePoll.current = true;
      clearTimeout(timer.current);
      clearTimeout(timer1.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const compnayTop = useMemo(() => (
    <DetailTop
      ref={detailTopRef}
      briefInfo={headInfo}
      scrollContent={scrollContent}
      // companyId={!companyId ? params.get('id') : companyId}
      companyId={companyIdCopy}
      btnPower={Object.keys(moduleTabs).length > 0 ? moduleTabs['关系图谱'] : []}
    />
  ), [headInfo, companyIdCopy, params, moduleTabs]);
  const compantContent = useMemo(() => (
    Object.keys(moduleTabs).length > 0 && (
      <CompanyContent
        fixHeight={achorFixHeight}
        tabs={moduleTabs}
        // companyId={!companyId ? params.get('id') : companyId}
        companyId={companyIdCopy}
        companyName={headInfo?.companyName}
        newRouter={!!params.get('id')}
        ref={contentRef}
        scrollContent={scrollContent}
      />
    )
  ), [moduleTabs, achorFixHeight, companyIdCopy, params, headInfo]);

  return (
    <div className={style.all} ref={topRef}>
      <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <div className={style.header}>
          企业详情
          {
            menu.find(item => item === 'closeCompanyDetail') && <CloseOutlined className={style.close} onClick={() => window.close()} />
          }
        </div>
        <div className={style.companyNameRow}>
          <div className={style.companyName}>{headInfo?.companyName}</div>
          <div className={style.levelScore}>
            <div className={style.levelBox}>
              <div className={style.levelDiv}>
                <div className={style.word}>上奇评级</div>
                <div className={style.level}>{headInfo?.rate}</div>
              </div>
            </div>
            <div className={style.scoreBox}>
              <div className={style.scoreDiv}>
                <div className={style.word}>得分</div>
                <div className={style.score}>{headInfo?.score}</div>
              </div>
            </div>
            <div className={style.questionBox}>
              <span className={style.question}>
                <QuestionCircleOutlined />
                <div className={style.tipBox}>
                  <div className={style.title}>
                    {tooltip.title || ''}
                    :
                  </div>
                  {
                    Object.keys(tooltip).length > 0 && tooltip.detail.map((item, index) => (
                      <div className={style.tipDetail} key={`${index}tooltip`}>
                        <div
                          className={style.level}
                          style={levelColor[item.level]}
                        >
                          {item.level}
                        </div>
                        <div className={style.levelDesc}>{item.desc}</div>
                      </div>
                    ))
                  }
                </div>
              </span>
            </div>
          </div>
          {
            !menu.find(item => item === 'hiddenDetailTag') && (
              <div
                className={style.button}
                onClick={() => detailTopRef.current?.openReport()}
              >
                <div className={style.report} />
                尽调报告
              </div>
            )
          }
        </div>
      </div>

      {
        // eslint-disable-next-line no-nested-ternary
        loading ? (
          <div className={style.spin}>
            <Spin size="large" tip="加载中..." />
          </div>
        ) : (
          Object.keys(headInfo).length > 0 && companyIdCopy ? (
            <>
              {compnayTop}
              {contentLoading ? <Spin size="large" tip="加载中..." /> : compantContent}
            </>
          ) : (
            <div className={style.noData}>暂无数据</div>
          )
        )
      }
    </div>
  );
}

export default IndustryCompanyDetail;
