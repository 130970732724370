import React, { useMemo } from 'react';
import { Spin } from 'antd';
import RankingOrder from '../../../../../../components/RankingOrder';
import DataSearchButton from '../../../../../../components/DataSearchButton';
import Table from '../../../../../../components/Table';
import styles from './index.module.less';

const THEAD_TABLE = {
  index: {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    render: text => <RankingOrder order={text} />,
  },
  company_name: {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    width: 280,
    ellipsis: true,
  },
  city_name: {
    title: '城市名称',
    dataIndex: 'city_name',
    key: 'city_name',
    ellipsis: true,
  },
  in_scale: {
    title: '流入规模(万元)',
    dataIndex: 'in_scale',
    key: 'in_scale',
    render: (text) => <span>{text.toLocaleString()}</span>,
  },
  out_scale: {
    title: '流出规模(万元)',
    dataIndex: 'out_scale',
    key: 'out_scale',
    render: (text) => <span>{text.toLocaleString()}</span>,
  },
  in_company: {
    title: '流入企业(家)',
    dataIndex: 'in_company',
    key: 'in_company',
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  in_city: {
    title: '流入城市(个)',
    dataIndex: 'in_city',
    key: 'in_city',
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  out_company: {
    title: '流出企业(家)',
    dataIndex: 'out_company',
    key: 'out_company',
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  out_city: {
    title: '流出城市(个)',
    dataIndex: 'out_city',
    key: 'out_city',
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
};
export default function TopTable({ key, title, tableHead, COLUMN_KEYS, dataSource = [], onClick = () => { }, isFetching }) {
  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(keyItem => THEAD_TABLE[keyItem]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'amount') {
        rowConfig.render = (text) => <span>{(text - 0).toLocaleString()}</span>;
      }
      if ([
        'investor_count',
        'investor_province',
      ].includes(item.dataIndex)) {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item, title })}
          />
        );
      }
      return rowConfig;
    });
  }, [tableHead]);

  return (
    <Spin spinning={isFetching}>
      <div className={styles.tableBox}>
        <div className={styles.title}>{title}</div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            hideOnSinglePage: true,
            current: 1,
            pageSize: 10,
          }}
        />
      </div>
    </Spin>
  );
}
