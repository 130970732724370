/*
 *Author: zhaochenyu
 *Description: 同意用户协议
 *Date: 2022/06/30
*/

import React from 'react';
import { Checkbox } from 'antd';
import style from '../index.module.less';

function QrCodeDesc(props) {
  const { useAgreeCheck, setUserAgreeCheck } = props;
  return (
    <div style={{ color: '#7B8796' }}>
      <Checkbox
        checked={useAgreeCheck}
        onChange={(e) => setUserAgreeCheck(e.target.checked)}
      />
      &nbsp;
      已阅读并同意
      <span
        className={style.yonghuxieyi}
        onClick={() => { window.open('https://www.chanyeos.com/industry_engine_resource/industry_engine_Q_and_A_pdf/产业通用户服务协议.pdf'); }}
      >
        《用户协议》
      </span>
    </div>
  );
}

export default QrCodeDesc;
