import React from 'react';
import { Input } from 'antd';
import styles from './index.module.less';

export default function CapitalInput({ capitalScope, setCapitalScope }) {
  return (
    <div className={styles.inputBox}>
      <Input
        value={capitalScope?.[0] || ''}
        onChange={(e) => { setCapitalScope(prev => [e.target.value, prev?.[1] || '']); }}
      />
      <span className={styles.line}>-</span>
      <Input
        value={capitalScope?.[1] || ''}
        onChange={(e) => { setCapitalScope(prev => [prev?.[0] || '', e.target.value]); }}
      />
      <span className={styles.unit}>万元</span>
    </div>
  );
}
