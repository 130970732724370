import React, { useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { useGetParkIndustryDistributionTableQuery } from '../../../../../../../service/parkDetail';
import Table from '../../../../../../../components/Table';
import RankingOrder from '../../../../../../../components/RankingOrder';
import { getNameConfig } from '../../../../../../../utils/table';
import DataSearchButton from '../../DataSearchButton';
import SortTitle from '../../SortTitle';
import styles from './index.module.less';

const THEAD_TABLE = {
  index: {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    render: text => <RankingOrder order={text} />,
  },
  industry_name: {
    title: '产业',
    dataIndex: 'industry_name',
    key: 'industry_name',
    ellipsis: true,
  },
  company_count: {
    title: '企业',
    dataIndex: 'company_count',
    key: 'company_count',
    width: 90,
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  head_company_count: {
    title: '链主企业',
    dataIndex: 'head_company_count',
    key: 'head_company_count',
    width: 90,
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  market_company_count: {
    title: '上市企业',
    dataIndex: 'market_company_count',
    key: 'market_company_count',
    width: 90,
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  specialization_new_company_count: {
    title: '专精特新',
    dataIndex: 'specialization_new_company_count',
    key: 'specialization_new_company_count',
    width: 90,
    ellipsis: true,
    align: 'center',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  high_tech_company_count: {
    title: '高新技术',
    dataIndex: 'high_tech_company_count',
    key: 'high_tech_company_count',
    width: 90,
    ellipsis: true,
    align: 'center',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
};

const COLUMN_KEYS = [
  'index', 'industry_name', 'company_count', 'head_company_count',
  'market_company_count', 'specialization_new_company_count', 'high_tech_company_count',
];

function DistributionRanking(props) {
  const {
    regionCode,
    sortedInfo = {},
    onClick,
    className,
    getPool,
    onIndustryClick,
  } = props;

  const {
    data,
    isFetching,
  } = useGetParkIndustryDistributionTableQuery({
    park_id: regionCode,
    sort_key: sortedInfo?.columnKey,
    sort_value: sortedInfo?.order === 'descend' ? 'desc' : null,
  }, {
    skip: !regionCode,
  });
  const { columns: tableHead = [], dataSource: tableBody = [], pool } = data ?? {};
  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
        sortOrder: sortedInfo.columnKey === item.dataIndex ? (sortedInfo?.order ?? null) : null,
      });
      if (item.dataIndex === 'industry_name') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({
            // onClick: onIndustryClick,
            idField: 'industry_code',
            // className: 'overflow-multi-5 clickable-name',
          })),
        };
      }
      if ([
        'company_count',
        'head_company_count',
        'market_company_count',
        'specialization_new_company_count',
        'high_tech_company_count',
      ].includes(item.dataIndex)) {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item })}
          />
        );
      }
      if (item.dataIndex === 'company_count') {
        rowConfig.className = 'keep';
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [tableHead, sortedInfo, onClick]);

  useEffect(() => {
    getPool(pool);
  }, [getPool, pool]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isFetching}
      {...props}
      pageSize={Number.MAX_SAFE_INTEGER}
      pagination={false}
      className={clsx(styles.distributionRanking, className)}
    />
  );
}

export default DistributionRanking;
