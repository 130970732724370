// 信用风险
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import style from './index.module.less';
import { useGetCreditRiskQuery } from '../../../../../service/companyDetail';

function CreditRisks(props) {
  const { companyId } = props;

  const { data, isFetching } = useGetCreditRiskQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const option = useMemo(() => {
    const { series } = data ?? {};

    return {
      gauge: {},
      series: [
        {
          type: 'pie',
          min: 0,
          max: 100,
          center: ['50%', '75%'],
          startAngle: 180,
          clockwise: true,
          endAngle: 0,
          radius: '60%',
          data: [
            { value: 50, itemStyle: { color: '#d2eaff' } },
            { value: 50, itemStyle: { color: 'transparent' } },
          ],
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          z: 1,
          silent: true,
        },
        {
          type: 'pie',
          min: 0,
          max: 100,
          center: ['50%', '75%'],
          startAngle: 180,
          clockwise: true,
          endAngle: 0,
          radius: '58%',
          data: [
            { value: 50, itemStyle: { color: '#ecf4ff', shadowBlur: 2, shadowColor: '#d2eaff' } },
            { value: 50, itemStyle: { color: 'transparent' } },
          ],
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          z: 1,
          silent: true,
        },
        {
          type: 'pie',
          min: 0,
          max: 100,
          center: ['50%', '75%'],
          startAngle: 180,
          clockwise: true,
          endAngle: 0,
          radius: '5%',
          data: [
            { value: 50, itemStyle: { color: '#1961F5' } },
            { value: 50, itemStyle: { color: 'transparent' } },
          ],
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          z: 1,
          silent: true,
        },
        {
          name: '运行风险',
          type: 'gauge',
          min: 0,
          max: 100,
          center: ['50%', '75%'],
          startAngle: 180,
          endAngle: 0,
          radius: '100%',
          axisLabel: {
            distance: -46,
            fontSize: 14,
            fontWeight: 400,
            formatter: {
              if: [
                { '==': [{ var: '' }, 80] },
                '优',
                { '==': [{ var: '' }, 60] },
                '良',
                { '==': [{ var: '' }, 40] },
                '中',
                { '==': [{ var: '' }, 20] },
                '差',
                '',
              ],
            },
          },
          axisLine: {
            lineStyle: {
              width: 15,
              color: [
                [0.25, '#FD6205'],
                [0.5, '#FDAD61'],
                [0.75, '#1961F5'],
                [1, '#59D700'],
              ],
            },
          },
          axisTick: {
            show: true,
            distance: 0,
            lineStyle: {
              color: 'auto',
            },
          },
          splitLine: {
            show: true,
            length: 23,
            distance: -15,
            lineStyle: {
              color: 'auto',
            },
          },
          pointer: {
            width: 14,
            length: 14,
            offsetCenter: [0, '-70%'],
            icon: 'triangle',
          },
          title: {
            color: '#262D36',
            fontSize: 14,
            offsetCenter: [0, '-38%'],
          },
          detail: {
            offsetCenter: [0, '-18%'],
            formatter: '{title|{value}} {unit|分}',
            color: '#262D36',
            rich: {
              title: {
                fontSize: 24,
                color: '#1961f5',
                fontWeight: 'bold',
              },
              unit: {
                fontSize: 12,
              },
            },
            fontSize: 14,
          },
          data: series?.[0]?.data,
        },
      ],
    };
  }, [data]);

  return (
    <div className={style.all} id="信用评估">
      <div className={style.title}>信用评估</div>
      {
        data?.series?.length > 0 ? (
          <ECharts
            showLoading={isFetching}
            className={style.chart}
            option={option}
          />
        ) : (
          <div className={style.noData}>该企业无此数据图谱</div>
        )
      }
    </div>
  );
}

export default CreditRisks;
