/*
* Author: zhangmeng
* Description: ''
* Date: 2022/06/29
*/

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Button, Pagination, Spin } from 'antd';
import moment from 'moment';
import TabSelect from '../SelectorComp/TabSelect';
import InputTreeSelect from '../SelectorComp/InputTreeSelect';
import DateSelect from '../SelectorComp/DateSelect';
import SingleInput from '../SelectorComp/SingleInput';
import SearchSortor from '../SearchSortor';
import PatentListLine from '../PatentListLine';
import style from './index.module.less';
import { useGetPatentListQuery } from '../../../../service/dataSearch';

const sortData1 = [
  {
    title: '申请日',
    key: 'apply_date',
  },
  {
    title: '公告日',
    key: 'approval_date',
  },
  {
    title: '专利价值分',
    key: 'tags.patent_score',
  },
  {
    title: '被引次数',
    key: 'tags.reference',
  },
];

function PatentPage(props) {
  const { filter, styles, participle, hideTitle = false } = props;
  const {
    industryTree,
    areaTree,
    patent_type: patentTypeList,
    homdogy_patent: homdogyPatentList,
  } = filter;
  const [patentType, setPatentType] = useState(['不限']); // 专利类型
  const [industry, setIndustry] = useState([]);
  const [area, setArea] = useState([]);
  const [applyDate, setApplyDate] = useState(['', '']); // 申请日
  const [announcementDate, setAnnouncementDate] = useState(['', '']); // 公告日
  const [IPCNum, setIPCNum] = useState(''); // IPC分类号
  const [homdogyPatent, setHomdogyPatent] = useState(['不限']); // 同族专利
  const [applyNum, setApplyNum] = useState(''); // 申请号
  const [publicNum, setPublicNum] = useState(''); // 公开号
  const [announcementNum, setAnnouncementNum] = useState(''); // 公告号
  const [sortKey, setSort] = useState({ key: '', count: 0 });
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isConfirm, setIsConfirm] = useState(false);
  /* eslint-disable no-nested-ternary */

  const params = sortKey.count === 0 ? {
    patent_type_list: patentType.length === 1 ? (patentType[0] === '不限' ? [] : patentType) : patentType,
    filtering_area_list: area,
    apply_date_list: (applyDate?.[0] !== '' && applyDate?.[1] !== '') ? [moment(applyDate[0]).format('YYYY-MM-DD'), moment(applyDate[1]).format('YYYY-MM-DD')] : [],
    notice_date_list: (announcementDate?.[0] !== '' && announcementDate?.[1] !== '') ? [moment(announcementDate[0]).format('YYYY-MM-DD'), moment(announcementDate[1]).format('YYYY-MM-DD')] : [],
    IPC_code: IPCNum,
    filtering_industry_list: industry,
    homdogy_patent_list: homdogyPatent.length === 1 ? (homdogyPatent[0] === '不限' ? [] : homdogyPatent) : homdogyPatent,
    apply_code: applyNum,
    publish_code: publicNum,
    notice_code: announcementNum,
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
  } : {
    patent_type_list: patentType.length === 1 ? (patentType[0] === '不限' ? [] : patentType) : patentType,
    filtering_area_list: area,
    apply_date_list: (applyDate?.[0] !== '' && applyDate?.[1] !== '') ? [moment(applyDate[0]).format('YYYY-MM-DD'), moment(applyDate[1]).format('YYYY-MM-DD')] : [],
    notice_date_list: (announcementDate?.[0] !== '' && announcementDate?.[1] !== '') ? [moment(announcementDate[0]).format('YYYY-MM-DD'), moment(announcementDate[1]).format('YYYY-MM-DD')] : [],
    IPC_code: IPCNum,
    filtering_industry_list: industry,
    homdogy_patent_list: homdogyPatent.length === 1 ? (homdogyPatent[0] === '不限' ? [] : homdogyPatent) : homdogyPatent,
    apply_code: applyNum,
    publish_code: publicNum,
    notice_code: announcementNum,
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
    sort_column: sortKey.key,
    sort_order: sortKey.count === 1 ? 'asc' : 'desc',
  };
  const { data: list, isFetching } = useGetPatentListQuery(params, { skip: isConfirm });
  /* eslint-disable no-nested-ternary */

  useEffect(() => {
    if (!isFetching) {
      setIsConfirm(true);
    }
  }, [isFetching]);

  useEffect(() => {
    setIsConfirm(false);
  }, [participle]);

  const resetClick = () => {
    setPatentType(['不限']);
    setArea([]);
    setIndustry([]);
    setApplyDate(['', '']);
    setAnnouncementDate(['', '']);
    setIPCNum('');
    setHomdogyPatent(['不限']);
    setApplyNum('');
    setPublicNum('');
    setAnnouncementNum('');
    setIsConfirm(true);
  };
  const confirmSearch = () => {
    setIsConfirm(false);
    setPageNum(1);
  };

  const changeSort = useCallback(
    (sort) => {
      setSort(sort);
      setIsConfirm(false);
    },
    [],
  );

  // pagination-onChange
  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
    setIsConfirm(false);
  };

  return (
    <div className={style.all} style={styles}>
      <div className={style.topBox}>
        {
          !hideTitle && <div className={style.title}>专利</div>
        }
        <div className={style.selectorBox}>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                patentTypeList && patentTypeList.length > 0 && <TabSelect
                  title="专利类型"
                  data={patentTypeList}
                  selected={patentType}
                  setSelected={p => {
                    setPatentType(p);
                    setIsConfirm(true);
                  }}
                />
              ), [patentTypeList, patentType])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="区域"
                  data={areaTree}
                  select={area}
                  setSelect={p => {
                    setArea(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择区域"
                />
              ), [areaTree, area])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="产业"
                  data={industryTree}
                  select={industry}
                  setSelect={p => {
                    setIndustry(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择产业"
                />
              ), [industryTree, industry])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <DateSelect
                  title="申请日"
                  select={applyDate}
                  setSelect={p => {
                    if (p) {
                      setApplyDate(p);
                    } else {
                      setApplyDate(['', '']);
                    }
                    setIsConfirm(true);
                  }}
                />
              ), [applyDate])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <DateSelect
                  title="公告日"
                  select={announcementDate}
                  setSelect={p => {
                    if (p) {
                      setAnnouncementDate(p);
                    } else {
                      setAnnouncementDate(['', '']);
                    }
                    setIsConfirm(true);
                  }}
                />
              ), [announcementDate])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <SingleInput
                  name="IPC分类号"
                  value={IPCNum}
                  changeInput={p => {
                    setIPCNum(p);
                    setIsConfirm(true);
                  }}
                  ph="请输入IPC分类号"
                />
              ), [IPCNum])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                homdogyPatentList && homdogyPatentList.length > 0 && <TabSelect
                  title="同族专利"
                  data={homdogyPatentList}
                  selected={homdogyPatent}
                  setSelected={p => {
                    setHomdogyPatent(p);
                    setIsConfirm(true);
                  }}
                />
              ), [homdogyPatentList, homdogyPatent])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <SingleInput
                  name="申请号"
                  value={applyNum}
                  changeInput={p => {
                    setApplyNum(p);
                    setIsConfirm(true);
                  }}
                  ph="请输入申请号"
                />
              ), [applyNum])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <SingleInput
                  name="公开号"
                  value={publicNum}
                  changeInput={p => {
                    setPublicNum(p);
                    setIsConfirm(true);
                  }}
                  ph="请输入公开号"
                />
              ), [publicNum])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <SingleInput
                  name="公告号"
                  value={announcementNum}
                  changeInput={p => {
                    setAnnouncementNum(p);
                    setIsConfirm(true);
                  }}
                  ph="请输入公告号"
                />
              ), [announcementNum])
            }
          </div>
          <div className={style.btnGroup}>
            <Button type="primary" ghost onClick={() => resetClick()}>
              重置
            </Button>
            &emsp;
            <Button type="primary" className="confirmSearch" onClick={() => confirmSearch()}>
              查询
            </Button>
          </div>
        </div>
      </div>
      <div className={style.listBox}>
        <Spin tip="搜索中，请稍候..." spinning={isFetching}>
          <SearchSortor
            exportType="exportSearchPatent"
            dataKey="search_patent"
            params={params}
            data={sortData1}
            sort={sortKey}
            total={list?.data_count || 0}
            onChange={changeSort}
          />
          <div className={style.list}>
            {
              list && list.data_list && list.data_list.length > 0 ? list.data_list.map((item, index) => (
                <PatentListLine key={index} record={item} participle={participle} />
              )) : <div style={{ textAlign: 'center', marginTop: '50px' }}>暂无数据</div>
            }
            <div className={style.pagination}>
              <Pagination
                showQuickJumper
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={pageSize}
                current={pageNum}
                total={list?.auth_count || 0}
                onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default PatentPage;
