/*
 *Author: zhaochenyu
 *Description: 年报详情公用header
 *Date: 2021/08/24
*/

import React from 'react';
import style from './index.module.less';

function CommonTitle(props) {
  const { title } = props;
  return (
    <div className={style.all}>
      {title}
    </div>
  );
}

export default CommonTitle;
