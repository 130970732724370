import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';

const extraOption = [
  {
    z: 9,
    label: {
      position: 'right',
      fontSize: 14,
      fontWeight: 500,
      color: '#0F2849',
    },
    emphasis: {
      label: {
        fontWeight: 600,
        color: '#1961F5',
        textBorderWidth: 0.5,
        textBorderColor: '#1961F5',
      },
    },
  },
  {
    label: {
      fontSize: 14,
      fontWeight: 500,
      color: '#0F2849',
    },
    emphasis: {
      label: {
        fontWeight: 600,
        color: '#1961F5',
        textBorderWidth: 0.5,
        textBorderColor: '#1961F5',
      },
    },
  },
];

function OrientTree(props) {
  const { data, onEvents, select, zoom = 0.85, layout = 'radial', useDataColors } = props;
  const [isChartReady, setIsChartReady] = useState(0);
  const echartsRef = useRef(null);
  // const timer = useRef(null);
  // const preZoom = useRef(1.15);

  useEffect(() => {
    if (!isChartReady) return;
    const _data = cloneDeep(data);
    const handleChartData = param => {
      const result = [];
      param.forEach(oItem => {
        if (oItem.children && oItem.children.length > 0) {
          oItem.children = handleChartData(oItem.children);
        }
        const sOption = oItem.level >= 1 ? cloneDeep(extraOption[1]) : cloneDeep(extraOption[oItem.level]);
        if (select === oItem.code || select === oItem.value) {
          sOption.itemStyle = {
            color: '#1961F5',
            borderWidth: 8,
          };
          sOption.label.fontWeight = 600;
          sOption.label.color = '#1961F5';
          sOption.label.textBorderWidth = 0.5;
          sOption.label.textBorderColor = '#1961F5';
        }
        if (useDataColors) {
          oItem = {
            ...oItem,
          };
        } else {
          oItem = {
            ...oItem,
            ...sOption,
          };
        }
        result.push(oItem);
      });
      return result;
    };
    const instance = echartsRef.current?.getEchartsInstance();
    instance?.clear();
    instance?.setOption({
      tooltip: false,
      series: [
        {
          large: true,
          id: 'tree',
          type: 'tree',
          roam: 'move',
          zoom,
          data: handleChartData([_data]),
          top: '15%',
          left: '-5%',
          bottom: '-8%',
          right: '20%',
          layout,
          orient: 'TB',
          symbol: 'circle',
          symbolSize: 10,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#1961F5',
            color: '#fff',
          },
          lineStyle: {
            color: '#1961F5',
          },
          expandAndCollapse: false,
          initialTreeDepth: 5,
          animation: false,
          label: layout === 'radial' ? {
            color: '#0F2849',
            formatter: p => {
              const { data: d, name, data: { title } } = p;
              if (d.level === 0 || d.level === 1) {
                let n = '';
                const companyName = name.split('') || title.split('');
                while (companyName.length > 4) {
                  n += `${companyName.splice(0, 4).join('')}\n`;
                }
                return `{size0|${n}${companyName.join('')}}`;
              }
              if (d.level === 2 && d.children && d.children.length > 0) {
                let n = '';
                const companyName = name.split('') || title.split('');
                n += `${companyName.splice(0, 6).join('')}...`;
                return `{size1|${n}}`;
              }
              return `{size2|${name || title}}`;
            },
            rich: {
              size0: {
                align: 'center',
                fontSize: 14,
                fontWeight: 500,
              },
              size1: {
                fontSize: 14,
              },
              size2: {
                fontSize: 14,
              },
            },
          } : {
            color: '#0F2849',
            position: 'top',
            rotate: -90,
            verticalAlign: 'middle',
            align: 'right',
          },
          leaves: layout !== 'radial' ? {
            label: {
              color: '#0F2849',
              position: 'bottom',
              rotate: -90,
              verticalAlign: 'middle',
              align: 'left',
            },
          } : {},
        },
      ],
    });
  }, [data, isChartReady, select, zoom]);

  // const changeGraphFontSize = () => {
  //   const instance = echartsRef.current.getEchartsInstance();
  //   const curOption = instance.getOption();
  //   const curZoom = curOption.series[0].zoom;
  //   if (curZoom !== preZoom.current) {
  //     preZoom.current = curZoom;
  //     const centerFontSize = 18 * curZoom > 40 ? 40 : 18 * curZoom;
  //     const curFontSize = 14 * curZoom > 40 ? 40 : 14 * curZoom;
  //     curOption.series[0].label.rich.size0.fontSize = centerFontSize;
  //     curOption.series[0].label.rich.size1.fontSize = curFontSize;
  //     curOption.series[0].label.rich.size2.fontSize = curFontSize;
  //     instance.setOption(curOption);
  //   }
  // };

  const onEventsIN = useMemo(() => ({
    click: params => {
      onEvents(params);
    },
    // finished: (_, instance) => {
    //   instance.dispatchAction({
    //     seriesId: 'ec_1649236709650',
    //     type: 'data',
    //     zoom: 1,
    //     originX: 2000,
    //     originY: 600,
    //   });
    //   echartsRef.current.getEchartsInstance().getZr().on('mousewheel', (e) => {
    //     if (timer.current) {
    //       clearTimeout(timer.current);
    //       timer.current = null;
    //     }
    //     timer.current = setTimeout(() => {
    //       changeGraphFontSize(e);
    //     }, 50);
    //   });
    // },
  }), []);

  return (
    <ReactEcharts
      option={{}}
      notMerge
      onEvents={onEventsIN}
      style={{ width: '100%', height: '100%' }}
      ref={echartsRef}
      onChartReady={() => {
        setIsChartReady(prev => prev + 1);
      }}
    />
  );
}

export default OrientTree;
