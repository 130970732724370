/*
* Author: zhangmeng
* Description: '标准详情页'
* Date: 2021/05/07
*/

import React from 'react';
import { Pagination, Table } from 'antd';
import TitleAndSubTitleComp from '../../../TitleAndSubTitleComp';
import style from './index.module.less';

function DetailBottom(props) {
  const { data, getList, pageNum } = props;

  return (
    <div className={style.all}>
      <TitleAndSubTitleComp title="相关标准" />
      <Table
        rowKey="index"
        columns={data.tableHead}
        dataSource={data.tableBody}
        pagination={false}
      />
      {data.total > 0 && <Pagination
        className={style.pagination}
        current={pageNum}
        pageSize={10}
        total={data.total}
        pageSizeOptions={[10]}
        showQuickJumper
        hideOnSinglePage
        onChange={(page, size) => getList({ page_number: page, page_size: size })}
      />}
    </div>
  );
}

export default DetailBottom;
