/**
  专题
 */

import { apiSlice } from '../slices/api';

const SPECIALIZATION = '/specialization';
const SPECIAL_SUBJECT_DETAIL = '/specialSubjectDetail';
const SPECIAL_TOPIC = 'special_topic';

export const specialApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 专精特新企业区域分布
    getDistributionOfRegions: builder.query({
      query(arg) {
        return {
          url: `${SPECIALIZATION}/distribution_of_regions`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专精特新企业产业分布
    getDistributionOfIndustry: builder.query({
      query(arg) {
        return {
          url: `${SPECIALIZATION}/distribution_of_industry`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专精特新企业金字塔
    getCompanyPyramidSpecialization: builder.query({
      query(arg) {
        return {
          url: `${SPECIALIZATION}/company_pyramid_specialization`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专精特新企业成立年限
    getEstablishAgePie: builder.query({
      query(arg) {
        return {
          url: `${SPECIALIZATION}/establish_age_pie`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专精特新企业注册资本
    getCompanyCapitalDistribute: builder.query({
      query(arg) {
        return {
          url: `${SPECIALIZATION}/company_capital_distribute`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专精特新授权发明专利前100排行
    getGatentRankingTable: builder.query({
      query(arg) {
        return {
          url: `${SPECIALIZATION}/authorized_invention_patent_Top100_specialization`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业潜力前100
    getGrowthPotentialList: builder.query({
      query(arg) {
        return {
          url: `${SPECIALIZATION}/growth_potential_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 报告推荐企业
    getSqScoreList: builder.query({
      query(arg) {
        return {
          url: `${SPECIALIZATION}/sq_score_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询子公司统计数据
    getCompanySubsidiaryStatistics: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/company_subsidiary_statistics`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询子公司榜单
    getCompanySubsidiaryRank: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/company_subsidiary_rank`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 弹出表格
    getAreaSubsidiaryCompanyList: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/area_subsidiary_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专精特新总揽地图
    getAreaSubsidiaryCompanyMap: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/area_subsidiary_company_map`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 获取五个Echarts 图
    getSubsidiaryChart: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/subsidiary_chart`,
          method: 'get',
          params: arg,
        };
      },
    }),
    // 榜单
    getCompanyList: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询
    getCompanyFuzzy: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/company_fuzzy_query`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 最新动态
    getLatestNews: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/latest_news`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 政策列表
    getRelatedPolicy: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/related_policy`,
          method: 'get',
          params: arg,
        };
      },
    }),
    // 报告
    getSpecialReportList: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/special_report_list`,
          method: 'get',
          params: arg,
        };
      },
    }),
    // 公司布局
    getLayoutCondition: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/layout_condition`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 子公司布局
    getSubsidiaryLayout: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/subsidiary_layout`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 城市分布 柱状图
    getUrbanDistribution: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/urban_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分布 矩形树图
    getIndustrialDistribution: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_SUBJECT_DETAIL}/industrial_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询专题是否购买 SPECIAL_TOPIC
    findSpecialTopicCodeStatus: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_TOPIC}/find_special_topic_code_status`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 可购买专题列表 find_special_topic_can_buy
    findSpecialTopicCanBuy: builder.query({
      query() {
        return {
          url: `${SPECIAL_TOPIC}/find_special_topic_can_buy`,
        };
      },
    }),
    // 购买专题 二维码 buy_city_qr_code
    buySpecialTopicQrCode: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_TOPIC}/buy_special_topic_qr_code`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 轮询 find_trade_status_on_pay_special_topic
    findTradeStatusOnPaySpecialTopic: builder.query({
      query(arg) {
        return {
          url: `${SPECIAL_TOPIC}/find_trade_status_on_pay_special_topic`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});
// industrial_distribution
export const {
  useLazyGetDistributionOfRegionsQuery,
  useLazyGetDistributionOfIndustryQuery,
  useLazyGetCompanyPyramidSpecializationQuery,
  useLazyGetEstablishAgePieQuery,
  useLazyGetCompanyCapitalDistributeQuery,
  useLazyGetGatentRankingTableQuery,
  useLazyGetGrowthPotentialListQuery,
  useLazyGetSqScoreListQuery,
  useGetCompanySubsidiaryStatisticsQuery,
  useGetCompanySubsidiaryRankQuery,
  useGetAreaSubsidiaryCompanyListQuery,
  useGetAreaSubsidiaryCompanyMapQuery,
  useGetSubsidiaryChartQuery,
  useGetCompanyListQuery,
  useLazyGetCompanyFuzzyQuery,
  useGetLatestNewsQuery,
  useGetRelatedPolicyQuery,
  useGetSpecialReportListQuery,
  useGetLayoutConditionQuery,
  useGetSubsidiaryLayoutQuery,
  useGetUrbanDistributionQuery,
  useGetIndustrialDistributionQuery,
  useLazyFindSpecialTopicCodeStatusQuery,
  useFindSpecialTopicCanBuyQuery,
  useBuySpecialTopicQrCodeQuery,
  useLazyFindTradeStatusOnPaySpecialTopicQuery,
} = specialApi;
