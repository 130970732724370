import React, { useState, useRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { Button, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CapitalInput from '../components/CapitalInput';
import SankeyChart from '../components/SankeyChart';
import {
  useGetCompanyLabelQuery,
  useGetPenetrationIndustryLayoutLargeEnterpriseQuery,
  useGetPenetrationIndustryLayoutLargeEnterprisesDescribeQuery,
} from '../../../../service/knowledgeCompoent';
import {
  getExportPenetrationIndustryLayoutLargeEnterprises,
  getFindCompanyListByLikeName,
} from '../../../../api/knowledgeComponents';
import pageStyles from './index.module.less';
import styles from '../index.module.less';

export const companyList = [
  {
    id: '34557a5a7556484b7252424838784f514d6f757477513d3d0a',
    name: '中国航天科技集团有限公司',
  },
  {
    id: '6c6e795861453055666c426c6f552b563444445a67413d3d0a',
    name: '中国核工业集团有限公司',
  },
  {
    id: '496a54627963456f426b4233413346656964477535673d3d0a',
    name: '国电南瑞科技股份有限公司',
  },
  {
    id: '463853627264304a456833766a346c6a5a65346946413d3d0a',
    name: '中国煤炭科工集团有限公司',
  },
  {
    id: '716d376a2f76627041454a4d447653533547664f47673d3d0a',
    name: '国家电网有限公司',
  },
];

export const generateLevelList = () => {
  const list = [];
  for (let i = 1; i <= 10; i += 1) {
    if (i === 10) {
      list.push({
        value: i,
        label: '十级',
      });
    } else {
      list.push({
        value: i,
        label: `${i.toLocaleString('zh-Hans-CN-u-nu-hanidec')}级`,
      });
    }
  }
  return list;
};

const generateRelation = (num, levelCount) => {
  let str = '';
  for (let i = 1; i <= num; i += 1) {
    const countName = `${i - 1}-level`;
    str += `${i.toLocaleString('zh-Hans-CN-u-nu-hanidec')}级关联企业<span class=${styles.num}>${levelCount?.[countName] || 0}</span>家，`;
  }
  return str;
};

export default function LargeEnterpriseBusinessLayout({ box }) {
  const sankeyRef = useRef(null);
  const headerSearchRef = useRef(null);
  const [searchValue, setSearchValue] = useState('小米科技有限责任公司');
  const [focus, setFocus] = useState(false);
  const [likeCompanyList, setLikeCompanyList] = useState([]);
  const [selectCompany, setSelectCompany] = useState({
    id: '57355a734d6b512f2b64354b646738464b73516654513d3d0a',
    name: '小米科技有限责任公司',
  });
  const [selectLevel, setSelectLevel] = useState(2);
  const [selectTags, setSelectTags] = useState(['不限']);
  const [capitalScope, setCapitalScope] = useState([]);
  const [searchParams, setSearchParams] = useState({
    company_id: '57355a734d6b512f2b64354b646738464b73516654513d3d0a',
    company_name: '小米科技有限责任公司',
    level: 2,
    especial_tag_list: [],
    company_register_capital_list: [],
  });
  const [tagLegend, setTagLegend] = useState(true);
  const [allLegend, setAllLegend] = useState(true);
  const [tagList, setTagList] = useState([]);
  const { data: companyLabelList } = useGetCompanyLabelQuery();
  const { data: graphData, isFetching: isGraphFetching } = useGetPenetrationIndustryLayoutLargeEnterpriseQuery({
    ...searchParams,
    legend_list: [tagLegend, allLegend],
    flag_label: 1,
  }, {
    skip: !searchParams,
  });

  const { data: count } = useGetPenetrationIndustryLayoutLargeEnterprisesDescribeQuery({
    ...searchParams,
  }, {
    skip: !searchParams,
  });

  // input 聚焦
  const inputOnFocus = () => {
    setFocus(true);
  };

  const clickPoint = e => {
    if (!headerSearchRef.current) return;
    if (!headerSearchRef.current.contains(e.target) && headerSearchRef.current
      !== e.target && e.target.className !== 'headerSearch') {
      setFocus(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', clickPoint);
    return () => {
      window.removeEventListener('click', clickPoint);
    };
  }, []);

  // 点击企业标签
  const handleTagClick = (node) => {
    let currentTags = [...selectTags];
    const index = currentTags.findIndex(i => i === node);
    if (selectTags.length === 0) {
      currentTags = ['不限'];
    } else if (node === '不限') {
      currentTags = ['不限'];
    } else if (currentTags.findIndex(i => i === '不限') > -1) {
      currentTags.splice(currentTags.findIndex(i => i === '不限'), 1);
      currentTags.push(node);
    } else if (index > -1 && currentTags.length === 1) {
      currentTags = ['不限'];
    } else if (index > -1) {
      currentTags.splice(index, 1);
    } else {
      currentTags.push(node);
    }
    setSelectTags(currentTags);
  };

  // 导出数据
  const handleDownloadData = async () => {
    const fileName = `${selectCompany.name}企业业务布局`;
    await getExportPenetrationIndustryLayoutLargeEnterprises({
      company_id: selectCompany.id,
      company_name: selectCompany.name,
      level: selectLevel,
      especial_tag_list: selectTags.findIndex(i => i === '不限') > -1 ? [] : selectTags,
      company_register_capital_list: capitalScope,
      file_name: fileName,
    }, fileName);
  };

  useEffect(() => {
    getFindCompanyListByLikeName({
      like_name: searchValue,
    }).then(res => {
      if (res.status === '00000') {
        setLikeCompanyList(res.data);
      }
    });
  }, [searchValue]);

  useEffect(() => {
    if (companyLabelList) {
      setTagList([
        {
          key: '不限',
          title: '不限',
        },
        ...companyLabelList,
      ]);
    }
  }, [companyLabelList]);

  // 点击重置
  const handleReset = () => {
    const setParams = throttle(() => {
      setSelectCompany({
        id: '57355a734d6b512f2b64354b646738464b73516654513d3d0a',
        name: '小米科技有限责任公司',
      });
      setSelectLevel(2);
      setSelectTags(['不限']);
      setCapitalScope([]);
      setSearchValue('小米科技有限责任公司');
      setSearchParams({
        company_id: '57355a734d6b512f2b64354b646738464b73516654513d3d0a',
        company_name: '小米科技有限责任公司',
        level: 2,
        especial_tag_list: [],
        company_register_capital_list: [],
      });
    }, 200);
    setParams();
  };

  // 点击查询
  const handleSearch = () => {
    const setParams = throttle(() => {
      setSearchParams({
        company_id: selectCompany.id,
        company_name: selectCompany.name,
        level: selectLevel,
        especial_tag_list: selectTags.findIndex(i => i === '不限') > -1 ? [] : selectTags,
        company_register_capital_list: capitalScope,
      });
    }, 200);
    setParams();
  };

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.left}>企业业务布局</div>
        <div className={styles.right}>
          <Button className={styles.primaryBtn} onClick={handleDownloadData}>导出数据</Button>
          <Button
            className={styles.primaryBtn}
            onClick={() => {
              const { download } = sankeyRef.current;
              download();
            }}
          >
            图片下载
          </Button>
        </div>
      </div>
      <div className={styles.filterBox}>
        <div className={styles.leftRow}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>企业搜索</div>
              <div className={styles.headerSearch} ref={headerSearchRef}>
                <Input
                  placeholder='请输入总部名称关键词'
                  value={searchValue}
                  onClick={e => e.stopPropagation()}
                  onFocus={() => inputOnFocus()}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  suffix={<SearchOutlined />}
                />
                {
                  focus && (
                    <div className={styles.focusBox} onClick={e => e.stopPropagation()}>
                      {
                        likeCompanyList.length > 0 && (
                          <div className={styles.history}>
                            <div className={styles.itemBox}>
                              {
                                likeCompanyList.map(item => (
                                  <div
                                    key={item}
                                    onClick={() => {
                                      setSearchValue(item.name);
                                      setFocus(false);
                                      setSelectCompany(item);
                                    }}
                                    title={item.name}
                                  >
                                    {item.name}
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.recomand}>推荐企业：</div>
              <div className={styles.recomandList}>
                {
                  companyList?.length > 0 ? companyList.map(item => (
                    <span
                      className={styles.recommandItem}
                      key={item.id}
                      onClick={() => {
                        setSelectCompany(item);
                        setSearchValue(item.name);
                      }}
                    >
                      {item.name}
                    </span>
                  )) : ''
                }
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>穿透层级</div>
              <Select
                value={selectLevel}
                options={generateLevelList()}
                getPopupContainer={() => box?.current}
                onChange={(value) => setSelectLevel(value)}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>企业标签</div>
              <div className={styles.tagList}>
                {
                  tagList?.length && tagList.map(item => (
                    <div
                      key={item.key}
                      className={classNames(styles.tagItem, { [styles.selectedItem]: selectTags.filter(i => i === item.key)?.length > 0 })}
                      onClick={() => handleTagClick(item.key)}
                    >
                      {item.title}
                    </div>
                  ))
                }
                <div className={styles.label}>注册资本</div>
                <div className={styles.capital}>
                  <CapitalInput
                    capitalScope={capitalScope}
                    setCapitalScope={setCapitalScope}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightRow}>
          <div className={styles.btnBox}>
            <Button type='primary' onClick={handleSearch}>查询</Button>
            <Button type='default' onClick={handleReset}>重置</Button>
          </div>
        </div>
      </div>
      <div className={styles.graphBox}>
        <div className={styles.describe}>
          {searchParams.company_name}
          关联企业
          <span className={styles.num}>{count?.total_level_count}</span>
          家，其中
          <span dangerouslySetInnerHTML={{ __html: generateRelation(searchParams?.level, count?.level_count_map) }} />
          {(searchParams?.especial_tag_list?.findIndex(i => i === '不限') <= -1 || searchParams?.company_register_capital_list?.length > 0) && (
            <>
              优势企业
              <span className={styles.num}>{count?.mark_count}</span>
              家，
            </>
          )}
          {count?.province_desc_str}
        </div>
        <div className={styles.graph} style={{ height: 'auto' }}>
          {
            useMemo(() => (
              <SankeyChart
                data={graphData ? [graphData] : []}
                selectLevel={searchParams?.level}
                selectCompanyName={searchParams?.company_name}
                graphTitle='企业业务布局'
                isFetching={isGraphFetching}
                ref={sankeyRef}
              />
            ), [searchParams, isGraphFetching, sankeyRef, graphData])
          }
        </div>
      </div>
      <div className={styles.legendBox}>
        <div className={styles.inner}>
          <div className={pageStyles.left}>
            <div
              className={classNames(pageStyles.tagLegned, { [pageStyles.disabledLegend]: !tagLegend })}
              onClick={() => setTagLegend(prev => !prev)}
            >
              标签企业
            </div>
            <div
              className={classNames(pageStyles.allLegend, { [pageStyles.disabledLegend]: !allLegend })}
              onClick={() => setAllLegend(prev => !prev)}
            >
              全部优势企业
            </div>
          </div>
          <div className={styles.right}>数据来源：上奇产业通</div>
        </div>
      </div>
    </div>
  );
}
