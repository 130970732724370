import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
import styles from './index.module.less';
import DataSearchButton from '../DataSearchButton';
import { useGetInvestmentInstitutionsTableQuery } from '../../../../service/industry-channel';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  investor: {
    title: '投资方名称',
    dataIndex: 'investor',
    key: 'investor',
    ellipsis: true,
  },
  company_num: {
    title: '企业',
    dataIndex: 'company_num',
    key: 'company_num',
    width: 94,
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  specialization: {
    title: '专精特新',
    dataIndex: 'specialization',
    key: 'specialization',
    width: 94,
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  IPO: {
    title: 'IPO',
    dataIndex: 'IPO',
    key: 'IPO',
    width: 94,
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
};

const COLUMN_KEYS = ['index', 'investor', 'company_num', 'specialization', 'IPO'];

function InvestmentOrganTable(props) {
  const {
    industryCode,
    linkCode,
    investorName,
    pageNumber = 1,
    pageSize = 20,
    onClick,
    getCount,
  } = props;

  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetInvestmentInstitutionsTableQuery({
    industry_code: industryCode,
    link_code: industryCode === linkCode ? '' : linkCode,
    investor_name: investorName,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });

  const { columns: tableHead = [], datasource: tableBody, total = 0, count = 0 } = companyData ?? {};

  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if ([
        'company_num',
        'specialization',
        'IPO',
      ].includes(item.dataIndex)) {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item })}
          />
        );
      }
      return rowConfig;
    });
  }, [tableHead, onClick]);

  useEffect(() => {
    getCount(count);
  }, [getCount, count]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
    />
  );
}

export default InvestmentOrganTable;
