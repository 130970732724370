/*
 * Author: zhangmeng
 * Description: '企业页'
 * Date: 2022/06/29
 */

import React, { useMemo, useState } from 'react';
import { Pagination } from 'antd';
import {
  useGetSearchIndustryListQuery,
} from '../../../../service/dataSearch';
import TabSelect from '../SelectorComp/TabSelect';
import IndustryChartList from '../IndustryChartList';
import style from './index.module.less';

function IndustryChart(props) {
  const { filter, styles, hideTitle = false, participle } = props;
  const { industryTree } = filter;
  const [industryType, setIndustryType] = useState(['不限']);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const { data: industryChartList } = useGetSearchIndustryListQuery({
    keyword: participle.join(''),
    industry_category_list: industryType[0] === '不限' ? [] : industryType,
    page_size: pageSize,
    page_number: pageNum,
    kwargs: {},
  });

  // pagination-onChange
  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
  };

  return (
    <div className={style.all} style={styles}>
      <div className={style.topBox}>
        {!hideTitle && <div className={style.title}>产业</div>}
        <div className={style.selectorBox}>
          {useMemo(
            () => (
              <TabSelect
                title="产业类型"
                data={industryTree}
                selected={industryType}
                setSelected={(p) => {
                  setIndustryType(p);
                  setPageNum(1);
                }}
              />
            ), [industryType],
          )}
        </div>
      </div>
      <div className={style.searchTitle}>
        当前搜索结果
        <span style={{ color: '#0F2849', fontWeight: 'bold', paddingLeft: 2, paddingRight: 2 }}>{industryChartList?.data_count}</span>
        条
      </div>
      {
        industryChartList?.data_count === 0 ? (
          <div className={style.noDataBox}>
            <div className={style.noDataContent}>
              <div className={style.flexBoxStyle}>
                <div className={style.noDataBackground} />
              </div>
              <span>当前未搜索到相关的产业链图谱</span>
            </div>
          </div>
        ) : (
          <IndustryChartList industryData={industryChartList?.data_list} participle={participle} />
        )
      }
      <div className={style.pagination}>
        <Pagination
          showQuickJumper
          hideOnSinglePage
          showSizeChanger={false}
          pageSize={pageSize}
          current={pageNum}
          total={industryChartList?.auth_count || 0}
          onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
        />
      </div>
    </div>
  );
}

export default IndustryChart;
