import { DATASEARCH, STANDARDDETAIL } from '../utils/url';
import { post, get } from '../utils/request';

// 标准查询
const getStandardData = params => post(`${DATASEARCH}/search_standards_list`, {
  ...params,
});

// 标准详情
const getStandardDetail = params => get(`${STANDARDDETAIL}/standard_info`, {
  ...params,
});

// 标准详情-标准溯源和标准演进
const getStandardCharts = params => get(`${STANDARDDETAIL}/standard_originate_evolution`, {
  ...params,
});

// 标准详情-标准列表
const getStandardRelation = params => get(`${STANDARDDETAIL}/standard_relation`, {
  ...params,
});

export {
  getStandardData,
  getStandardDetail,
  getStandardRelation,
  getStandardCharts,
};
