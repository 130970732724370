import React from 'react';
import clsx from 'clsx';
import { IconFont } from '../../../../../../../utils/mapIcon';
import styles from './index.module.less';

const PlusIcon = props => <IconFont type="iconplus" {...props} />;
const MinusIcon = props => <IconFont type="iconminus" {...props} />;

const stepChange = (zoom, step, forward = true) => {
  // 比如: zoom .73, step .05  forward false 返回 70, forward true 返回 75
  // normalized zoom (int)
  const _zoom = Math.round(zoom * 100);
  const _step = Math.round(step * 100);
  const prevStepZoom = Math.floor(_zoom / _step) * _step;
  const nextStepZoom = Math.ceil(_zoom / _step) * _step;
  // case 1. 刚好为 step 的整数倍，那么直接计算
  if (prevStepZoom === _zoom || nextStepZoom === _zoom) {
    return (_zoom + (forward ? 1 : -1) * _step) / 100;
  }
  // case 2. 如果不为整数倍，则取最近的较大/较小的 step 整数倍值
  if (!forward) {
    return prevStepZoom / 100;
  }
  return nextStepZoom / 100;
};

/**
 * # 缩放按钮
 * 如果缩小或者放大，应该取步长距离最近的倍数值
 * @param zoom
 * @param step
 * @param minZoom
 * @param maxZoom
 * @param className
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
function ZoomButton({
  zoom = 1,
  step = 0.05,
  minZoom = 0,
  maxZoom = 2,
  className,
  onChange,
}) {
  return (
    <div className={clsx(styles.button, className)}>
      <span
        className={styles.operator}
        onClick={() => {
          if (onChange) {
            if (step === 0) return;
            const _zoom = stepChange(zoom, step, false);
            if (_zoom < minZoom) return;
            onChange(_zoom);
          }
        }}
      >
        <MinusIcon />
      </span>
      <span className={styles.percent}>
        {typeof zoom === 'number' ? `${Math.floor(zoom * 100).toString()}%` : zoom}
      </span>
      <span
        className={styles.operator}
        onClick={() => {
          if (onChange) {
            if (step === 0) return;
            const _zoom = stepChange(zoom, step, true);
            if (_zoom > maxZoom) return;
            onChange(_zoom);
          }
        }}
      >
        <PlusIcon />
      </span>
    </div>
  );
}

export default ZoomButton;
