import { IModelMiddleware } from '../interface/base';
import { getDataItemValue } from './utils';
import { OptionParser } from './optionParser';

export const getVisualMapOption: IModelMiddleware = (option, material) => {
  const { config: { visualConfig, type  }, data, } = material;
  const { visualMap } = visualConfig ?? {};

  // 如果 visualMap 设置，但目前没有数据，直接返回即可
  if (visualMap && !data) {
    return {
      visualMap,
    };
  }
  // 根据图形的类型，处理视觉映射，目前可能的分类有，type: map/heatmap
  // 仅处理系列中只有一个的情况
  if (visualMap && data && data.series) {
    const { series } = data;
    if (series.length > 0) {
      const currentSeries = series[0];
      const _data = currentSeries.data;
      if (Array.isArray(_data) && _data.length > 0) {
        // 如果是地图，那么值应该是
        if (type === 'map') {
          const minValue = Math.min(..._data.map(item => getDataItemValue(item, 0)));
          const maxValue = Math.max(..._data.map(item => getDataItemValue(item, 0)));
          return {
            visualMap: {
              ...visualMap,
              min: minValue,
              max: maxValue,
            },
          };
        }
        if (type === 'heatmap') {
          const optionParser = new OptionParser(option);
          const categoryAxis = Object.values(
            optionParser.getAxis({ coordinateSystem: visualConfig.coordinateSystem })
          ).flat().filter(OptionParser.isAxisCategory);
          const dimension = categoryAxis.length;
          const minValue = Math.min(..._data.map(item => getDataItemValue(item, dimension)));
          const maxValue = Math.max(..._data.map(item => getDataItemValue(item, dimension)));
          return {
            visualMap: {
              ...visualMap,
              min: minValue,
              max: maxValue,
            },
          };
        }
      }
    }
  }
  return {
    visualMap,
  };
}

export const visualMap: IModelMiddleware = (option, material) => {
  const { visualMap } = getVisualMapOption(option, material);
  if (visualMap) {
    option.visualMap = visualMap;
  }
  return option;
}
