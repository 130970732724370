/*
 *Author: zhaochenyu
 *Description: 我的-已生成报告-报告名称修改组件
 *Date: 2021/10/29
*/

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Input, message } from 'antd';
import { IconFont } from '../../../utils/mapIcon';
import {
  editReportName,
} from '../../../api/mySelf';
import style from './index.module.less';

function ReportNameEdit(props) {
  const { text, record, getAllList } = props;
  const [inputWord, setInputWord] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [nameError, setNameError] = useState(false);
  const inputRef = useRef(null);
  const mainRef = useRef(null);
  const timer = useRef(null);

  // 修改报告名称
  const editGenerateReportName = (id, oldName, newName) => {
    editReportName({
      report_id: id,
      report_old_name: oldName,
      report_new_name: newName,
    }).then(res => {
      if (res.status === '00000') {
        message.success('修改成功！');
        getAllList();
      } else if (res.status === 'C803') {
        setNameError(true);
      } else {
        message.warning(res.message);
      }
    });
  };

  const changeReportName = useCallback(
    (reportName) => {
      if (reportName === '' || reportName === text) {
        setIsEdit(false);
        setInputWord(text);
      } else {
        // 改名称
        editGenerateReportName(record.id, record.report_name, reportName);
      }
    },
    [text, record],
  );

  // 点击外部区域等同于修改完成
  const handleWidthInput = (e) => {
    let { parentNode } = e.target;
    while (parentNode !== null) {
      if (parentNode === mainRef.current) {
        break;
      }
      parentNode = parentNode.parentNode;
    }
    if (parentNode) {
      return;
    }
    changeReportName(inputRef.current.state.value.trim());
  };

  const onBlurInput = (e) => {
    if (inputRef.current) {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
      timer.current = setTimeout(() => {
        handleWidthInput(e);
      }, 50);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onBlurInput);
    return () => {
      document.removeEventListener('click', onBlurInput);
    };
  }, [text]);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    setInputWord(text);
  }, [text]);

  useEffect(() => {
    if (nameError) {
      setNameError(false);
    }
  }, [inputWord]);

  return (
    <div className={style.all} ref={mainRef} key={`${record.id}reportname`}>
      <div className={`${style.name} ${nameError && style.error}`}>
        {isEdit ? (
          <Input
            value={inputWord}
            style={{ width: `${mainRef.current.clientWidth - 40}px` }}
            ref={inputRef}
            onChange={(e) => setInputWord(e.target.value)}
            onPressEnter={() => changeReportName(inputWord)}
          />) : `${inputWord}`}
      </div>
      {nameError && (
      <div className={style.errorMsg}>
        报告名称已存在，请调整修改。
      </div>
      )}
      <div className={style.icon} onClick={() => setIsEdit(true)}>
        <div className={style.edit}></div>
      </div>
    </div>
  );
}

export default ReportNameEdit;
