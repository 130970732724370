/*
 *Author: zhaochenyu
 *Description: 产业画像-封底
 *Date: 2021/12/06
*/

import React from 'react';
import style from './index.module.less';

function BackCover() {
  return (
    <div className={style.all}>
      <div className={style.desc}>
        北京上奇数字科技有限公司（简称“上奇”）是北京智源人工智能研究院重点孵化支持的创新型AI企业，致力于产业要素和关系的数字化、
        模型化和服务化，研发出全球首款“产业知识计算引擎”，聚合了100多个产业链图谱，2000余个算法模型，5000万家优质企业，10余万种产品、
        1亿专利等40余类产业数据集，综合应用自然语言处理、知识演化与推理、群智融合与增强、深度搜索和交互、智能描述与生成等AI技术，
        针对城市园区、重点产业、目标企业提供多维度、穿透式的实时画像、关系挖掘和知识发现，支撑精准决策、服务招商投资。
      </div>
      <div className={style.QRcode} />
      <div className={style.coverLog} />
      <div className={style.companyName}>北京上奇数字科技有限公司</div>
      <div className={style.zhushi}>
        <p>Beijing Shangqi Digital Technology Co., Ltd.</p>
        <p>北京市海淀区成府路150号智源大厦2层</p>
        <p>商务合作&nbsp;&nbsp;&nbsp;&nbsp;客服电话：17200309680</p>
      </div>
    </div>
  );
}

export default BackCover;
