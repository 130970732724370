import React from 'react';
import StackBarChart from '../StackBarChart';

const colors = [
  '#1961F5',
  '#4E88FF',
  '#00E7B5',
  '#59D700',
  '#FFB64F',
];

function TransStackBarChart({ data }) {
  return <StackBarChart unit="" yName="" yAxis={data?.x} series={data?.series} colors={colors} />;
}

export default TransStackBarChart;
