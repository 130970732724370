import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import Container from '../../Container';
import Barandline from '../../../../Charts/BarAndLine';
import { useGetOperatingIncomeTableQuery, useGetOperatingIncomeGraphQuery } from '../../../../../service/companyDetail';
import style from './index.module.less';

const color = ['#1961F5', '#59D700', '#FDAD61'];

function RevenueGrowth(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: isTableFetching } = useGetOperatingIncomeTableQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  const { data: chartData, isFetching: isChartFetching } = useGetOperatingIncomeGraphQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });
  const columns = () => tableData?.columns.map(item => {
    if (item.dataIndex === 'index') {
      return {
        width: 70,
        ...item,
      };
    }
    if (item.dataIndex === 'non_operate_revenue') {
      return {
        ...item,
        render: (text) => text || '-',
      };
    }
    return item;
  });
  const table = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <div>
          <Table
            rowKey="index"
            dataSource={tableData?.dataSource}
            columns={columns()}
            strip
            locale={{ emptyText: ' ' }}
            loading={isTableFetching}
            pagination={{
              hideOnSinglePage: true,
              current: page.pageNumber,
              pageSize: page.pageSize,
              total: tableData?.auth_count,
              showSizeChanger: false,
              showQuickJumper: true,
              responsive: true,
              showLessItems: true,
            }}
            onChange={({ current, pageSize }) => {
              setPage({
                pageSize,
                pageNumber: current,
              });
            }}
          />
        </div>
      );
    }
    return null;
  }, [tableData]);
  const chart = useMemo(() => {
    if (!chartData || chartData?.series?.length > 0) {
      return (
        <div>
          <Barandline data={{ ...chartData, right: 28, left: 82, bottom: 80 }} contentTop={46} color={color} isFetching={isChartFetching} />
        </div>
      );
    }
    return <></>;
  }, [chartData]);
  return (
    <Container id="营业收入">
      <div className={style.box}>
        {
          chart
        }
        {
          table
        }
      </div>
    </Container>
  );
}

export default RevenueGrowth;
