/*
 *Author: zhaochenyu
 *Description: 柱状图业务组件demo
 *Date: 2021/09/30
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import BarChart from '../../BasicsCompoment/BarChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessBarChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessBarChart(props: BusinessBarChartProps) {
  const { mode, chartGrid, fontSize, label, legend, chartType,
    ip, conditions, onRequestComplete, title, componmentKey, imgRef, yMax,
    noYaxis } = props;
  const [chartData, setChartData] = useState<any>({});
  const [chartTitle, setChartTitle] = useState<string>('');
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  useEffect(() => {
    if (ip) {
      setChartLoading(true);
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },
      }).then(res => {
        if (res.status === '00000') {
          setChartData(res.data.data || {});
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        } else {
          setChartData({
            xAxis: [],
            yAxis: [],
          });
        }
        setChartLoading(false);
        if (isFunction(onRequestComplete)) {
          onRequestComplete!(res.data);
        }
      }).catch(() => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setChartLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (chartLoading ? (
    <div className={styleSpin}>
      <Spin tip="加载中..." />
    </div>
  ) : (
    <div className={styleMap[mode!] || styleDark}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <BarChart
          title={typeof title === 'string' ? title : chartTitle}
          data={chartData}
          fontSize={fontSize}
          mode={mode}
          chartGrid={chartGrid}
          noYaxis={noYaxis}
          ref={imgRef}
          label={label}
          legend={legend}
          chartType={chartType}
          chartDesc={chartDesc}
          yMax={yMax}
        />
      </div>
    </div>)
  );
}

BusinessBarChart.defaultProps = {
  mode: 'light',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
};

// export default BusinessBarChart;

// 投融资事件轮次分布柱状图
export function InvestmentRoundsBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'transaction_rotation_distribution_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 投融资时间交易轮次分布堆叠柱状图
export function InvestmentStackBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'financing_round_distribute_fenxi',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

InvestmentStackBarChart.defaultProps = {
  label: false,
  chartGrid: {
    bottom: 50,
  },
};

// 专利类型堆叠柱状图
export function PatentTypeStackBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'new_patent_chart_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 新增企业数量柱状图
export function CompanyCountBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'new_company_count_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 人均地区生产总值柱状图
export function PerCapitaGDPBarchart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'person_GDP_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

PerCapitaGDPBarchart.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    left: 50,
    right: 40,
  },
};

// 地均地区生产总值
export function AverageRegionalGDPBarchart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'per_square_kilometer_GDP_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

AverageRegionalGDPBarchart.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 地区生产总值结构
export function RegionalGDPStructureStackBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'regional_GDP_structure_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

RegionalGDPStructureStackBarChart.defaultProps = {
  chartType: 'highlight',
  yMax: 100,
  fontSize: 'small',
  chartGrid: {
    top: 50,
    right: 40,
    bottom: 50,
  },
};

// 地方一般公共预算收支
export function PublicBudgetRevenueExpenditureBarCharts(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'general_public_budget_income_and_expenses_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

PublicBudgetRevenueExpenditureBarCharts.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 社会消费品零售总额
export function TotalRetailSalesSocialConsumerGoodsBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'total_retail_sales_of_consumer_goods_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

TotalRetailSalesSocialConsumerGoodsBarChart.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    right: 40,
    left: 50,
  },
};

// 新增企业数量对比
export function ComparisonOfNewEnterprisesBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'new_company_count_benchmarking_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

ComparisonOfNewEnterprisesBarChart.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 专利对比
export function PatentComparisonBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'number_of_patents_benchmarking_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

PatentComparisonBarChart.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 研究机构数量对比
export function ComparisonOfNumberResearchInstitutionsBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'company_number_urban_research_institutions',
    class_name: '/chart/institution',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

ComparisonOfNumberResearchInstitutionsBarChart.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 地区生成总值构成
export function CompositionOfRegionalGDPBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'GDP_constitute_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

CompositionOfRegionalGDPBarChart.defaultProps = {
  chartGrid: {
    top: 50,
    right: 40,
    bottom: 40,
  },
  fontSize: 'small',
  yMax: 100,
};

// 人均地区生产总值变化
export function ChangesInPerCapitaGDPBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'per_capita_GDP_change_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

ChangesInPerCapitaGDPBarChart.defaultProps = {
  fontSize: 'small',
  chartGrid: {
    top: 50,
    left: 50,
    right: 40,
  },
};

// 地均地区生产总值变化
export function ChangeOfAverageRegionalGDPBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'per_square_kilometer_GDP_change_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

ChangeOfAverageRegionalGDPBarChart.defaultProps = {
  fontSize: 'small',
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 地方一般公共预算收支情况
export function RevenueAndExpenditureGeneralPublicBudgetBarChart(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'revenue_expenditure_local_general_public_budgets_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

RevenueAndExpenditureGeneralPublicBudgetBarChart.defaultProps = {
  fontSize: 'small',
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 社会消费品零售总额变化
export function ChangesTotalRetailSalesSocialConsumerGoods(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'changes_total_retail_sales_consumer_goods_fenxi',
    class_name: '/chart/macroEconomy',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

ChangesTotalRetailSalesSocialConsumerGoods.defaultProps = {
  fontSize: 'small',
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 27. 企业数量变化
export function CompanyChartHandlercompanyNumChangeFenxi(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'company_num_change_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

CompanyChartHandlercompanyNumChangeFenxi.defaultProps = {
  fontSize: 'small',
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 36. 申请专利变化趋势
export function PatentChartHandlerpatentApplyChangeFenxi(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'patent_apply_change_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

PatentChartHandlerpatentApplyChangeFenxi.defaultProps = {
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 37. 授权专利变化趋势
export function PatentChartHandlerpatentGrantChangeFenxi(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'patent_grant_change_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

PatentChartHandlerpatentGrantChangeFenxi.defaultProps = {
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 新增图和8号一样  图七. 存量企业数量对比
export function CompanyChartcompanyCountBenchmarkingFenxi(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'company_count_benchmarking_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

CompanyChartcompanyCountBenchmarkingFenxi.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 图14. 新增授权发明专利对比
export function PatentChartnewNumberOfPatentsBenchmarkingFenxi(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'new_number_of_patents_benchmarking_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
      mode="light"
    />);
}

PatentChartnewNumberOfPatentsBenchmarkingFenxi.defaultProps = {
  chartType: 'highlight',
  fontSize: 'small',
  label: false,
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 国家级专精特新企业产业分布
export function CompnayChartCompanyDistributedVariousIndustriesSpecialization(
  props: BusinessBarChartProps,
) {
  const componmentKey = {
    function_key: 'company_distributed_various_industries_specialization',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
      mode="light"
    />);
}

CompnayChartCompanyDistributedVariousIndustriesSpecialization.defaultProps = {
  fontSize: 'small',
  // label: false,
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 国家级专精特新企业申请专利变化趋势
export function PatentChartPatentApplyChangeSpecialization(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'patent_apply_change_specialization',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

PatentChartPatentApplyChangeSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 国家级专精特新企业授权专利变化趋势
export function PatentChartPatentGrantChangeSpecialization(props: BusinessBarChartProps) {
  const componmentKey = {
    function_key: 'patent_grant_change_specialization',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

PatentChartPatentGrantChangeSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 国家级专精特新企业授权发明专利产业分布
export function PatentChartDistributionOfPatentIndustrySpecialization(
  props: BusinessBarChartProps,
) {
  const componmentKey = {
    function_key: 'distribution_of_patent_industry_specialization',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

PatentChartDistributionOfPatentIndustrySpecialization.defaultProps = {
  fontSize: 'small',
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 国家级专精特新企业区域分布
export function CompanyChartEnterpriseRegionalDistributionSpecialization(
  props: BusinessBarChartProps,
) {
  const componmentKey = {
    function_key: 'enterprise_regional_distribution_specialization',
    class_name: '/chart/company',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

CompanyChartEnterpriseRegionalDistributionSpecialization.defaultProps = {
  fontSize: 'small',
  chartGrid: {
    top: 50,
    right: 40,
  },
};

// 国家级专精特新企业融资事件上市前轮次分布
export function InvestmentChartRoundDistributionFinancingEventsSpecialization(
  props: BusinessBarChartProps,
) {
  const componmentKey = {
    function_key: 'Round_distribution_financing_events_specialization',
    class_name: '/chart/investment',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

InvestmentChartRoundDistributionFinancingEventsSpecialization.defaultProps = {
  chartGrid: {
    top: 50,
    right: 70,
  },
};

// 授权发明专利技术关键词词频TOP5
export function PatentChartAuthorizedInventionPatentedTechnology(
  props: BusinessBarChartProps,
) {
  const componmentKey = {
    function_key: 'authorized_invention_patented_technology',
    class_name: '/chart/patent',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
      noYaxis
    />);
}

PatentChartAuthorizedInventionPatentedTechnology.defaultProps = {
  chartGrid: {
    top: 50,
    right: 70,
  },
};

// 标准变化趋势
export function StandardChartStandardVariationTrend(
  props: BusinessBarChartProps,
) {
  const componmentKey = {
    function_key: 'standard_variation_trend',
    class_name: '/chart/standard',
  };
  return (
    <BusinessBarChart
      {...props}
      componmentKey={componmentKey}
    />);
}

StandardChartStandardVariationTrend.defaultProps = {
  chartGrid: {
    top: 50,
    right: 70,
  },
};
