import React, { useState, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { getCommerceInfo } from '../../../../../api/companyDetail';
import Container from '../../Container';
import style from './index.module.less';

function BusinessInfo(props) {
  const { companyId } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCommerceInfo({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const content = useMemo(() => {
    if (Object.keys(data).length === 0) return null;
    const { registerCapital, setUpDate, companyType,
      representative, status, industry, businessScope } = data;
    return (
      <div className={style.all}>
        <ul>
          <li>
            <p>注册资本</p>
            <p>{registerCapital}</p>
          </li>
          <li>
            <p>成立日期</p>
            <p>{setUpDate}</p>
          </li>
          <li>
            <p>公司类型</p>
            <p>{companyType}</p>
          </li>
          <li>
            <p>法定代表人</p>
            <p>{representative}</p>
          </li>
          <li>
            <p>经营状态</p>
            <p>{status}</p>
          </li>
          <li>
            <p>行业</p>
            <p>{industry}</p>
          </li>
        </ul>
        <div>
          <p>经营范围</p>
          <p>
            {businessScope}
          </p>
        </div>
      </div>
    );
  }, [data]);

  return (
    <Container id="工商信息">
      <Spin tip="搜索中，请稍候...." spinning={loading}>
        {content}
      </Spin>
    </Container>
  );
}

export default BusinessInfo;
