/**
 * ## info:
 * 1. 环节后數字代表子环节数量，点击可进行展开
 * 2. 武器也布局环节置灰，且不可点击
 * 3. 能够响应点击事件
 * 4. 能够响应鼠标悬浮时间
 * 5. 悬浮后出现一个 + 符号或者 - 符号供展开/收起
 */

const ROOT_STYLE = {
  shadowColor: '#1C428C',
  shadowBlur: 10,
};

const TREE = {
  title: '数字经济',
  depth: 0,
  children: [
    {
      title: '数字经济核心产业',
      depth: 1,
      isBright: false,
      children: [
        {
          title: '数字产品制造业',
          depth: 2,
          collapsed: true,
          children: [
            {
              children: [],
              isBright: true,
              level: 2,
              link_cost: '低价值环节',
              link_data_count: { count: 11991 },
              score: 50,
              title: '设计服务',
              value: 'INB13030201',
            },
            {
              title: '通讯及雷达设备制造',
              depth: 3,
              children: [],
            },
            {
              title: '数字媒体设备制造',
              depth: 3,
              children: [],
            },
            {
              title: '智能设备制造',
              depth: 3,
              children: [],
            },
            {
              title: '电子元器件及设备制造',
              depth: 3,
              children: [],
            },
            {
              title: '其他数字产品制造业',
              depth: 3,
              children: [],
            },
          ],
        },
        {
          title: '数字产品服务业',
          depth: 2,
          children: [
            {
              title: '数字产品批发',
              depth: 3,
              disabled: true,
              children: [],
            },
            {
              title: '数字产品零售',
              depth: 3,
              children: [],
            },
            {
              title: '数字产品租赁',
              depth: 3,
              children: [],
            },
            {
              title: '数字产品维修',
              depth: 3,
              children: [],
            },
            {
              title: '其他数字产品服务业',
              depth: 3,
              children: [],
            },
          ],
        },
        {
          title: '数字技术应用业',
          depth: 2,
          children: [
            {
              title: '软件开发',
              children: [],
            },
            {
              title: '电信、广播电视和卫星传输服务',
              children: [],
            },
            {
              title: '互联网相关服务',
              children: [],
            },
            {
              title: '信息技术服务',
              children: [],
            },
            {
              title: '其他数字技术应用业',
              children: [],
            },
          ],
        },
        {
          title: '数字要素驱动业',
          children: [
            {
              title: '互联网平台',
              children: [],
            },
            {
              title: '互联网批发零售',
              children: [],
            },
            {
              title: '互联网金融',
              children: [],
            },
            {
              title: '数字内容与媒体',
              children: [],
            },
            {
              title: '信息基础设备建设',
              children: [],
            },
            {
              title: '数字资源与产权交易',
              children: [],
            },
            {
              title: '其他数字要素驱动业',
              children: [],
            },
          ],
        },
      ],
    },
    {
      title: '产业数字化',
      depth: 1,
      children: [
        {
          title: '数字化效率提升业',
          children: [
            {
              title: '智慧农业',
              children: [],
            },
            {
              title: '智能制造',
              children: [],
            },
            {
              title: '智能交通',
              children: [],
            },
            {
              title: '智慧物流',
              children: [],
            },
            {
              title: '数字金融',
              children: [],
            },
            {
              title: '其他数字金融',
              children: [],
            },
            {
              title: '数字商贸',
              children: [],
            },
            {
              title: '数字社会',
              children: [],
            },
            {
              title: '数字政府',
              children: [],
            },
            {
              title: '其他数字化效率提升业',
              children: [],
            },
          ],
        },
      ],
    },
  ],
};

export default TREE;
