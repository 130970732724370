import React, { useMemo, useEffect } from 'react';
import { useGetParkMigrationStatisticsChildTablesQuery } from '../../../../../../../service/parkTool';
import {
  useGetIndustryTreeQuery,
} from '../../../../../../../service/interactive-v2';
import CustomListFilter from '../../../../../../../components/CustomListFilter';
import CustomTreeFilter from '../../../../../../../components/MultipleChoiceTree';
import SortTitle from '../../../../ParkDetail/CityContent/SortTitle';
import { FilterIcon } from '../../../../../../../components/CustomTableIcon';
import Table from '../../../../../../../components/Table';
import { getSortKv } from '../../../../../../../utils/table';
import styles from './index.module.less';

const TYPELIST = [
  {
    text: '注册地变更',
    value: '实体变迁',
  },
  {
    text: '对外投资',
    value: '资本变迁',
  },
  // {
  //   text: '分支变迁',
  //   value: '分支变迁',
  // },
];

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  company_name: {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    ellipsis: true,
    className: styles.name,
  },
  af_establish_date: {
    title: '成立时间',
    dataIndex: 'af_establish_date',
    key: 'af_establish_date',
    width: 100,
    sorter: true,
    sortDirections: ['descend'],
  },
  tag_name: {
    title: '产业',
    dataIndex: 'tag_name',
    key: 'tag_name',
    width: 140,
    ellipsis: true,
    filters: ['all'],
    filterIcon: <FilterIcon />,
    filterMode: 'tree',
  },
  af_reg_capital_std: {
    title: '注册资本',
    dataIndex: 'af_reg_capital_std',
    key: 'af_reg_capital_std',
    width: 120,
    sorter: true,
    sortDirections: ['descend'],
  },
  special_tag: {
    title: '资质类别',
    dataIndex: 'special_tag',
    key: 'special_tag',
    width: 120,
    ellipsis: true,
    // filters: [],
    // defaultFilteredValue: ['all'],
    // filterIcon: <FilterIcon />,
  },
  modify_date: {
    title: '迁出时间',
    dataIndex: 'modify_date',
    key: 'modify_date',
    width: 100,
    sorter: true,
    sortDirections: ['descend'],
  },
  type: {
    title: '迁移类型',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  head_office: {
    title: '总部名称',
    dataIndex: 'head_office',
    key: 'head_office',
    ellipsis: true,
  },
  af_total_point: {
    title: '上奇评分',
    dataIndex: 'af_total_point',
    key: 'af_total_point',
    width: 80,
    sorter: true,
    sortDirections: ['descend'],
  },
};

const COLUMN_KEYS = ['index', 'company_name', 'af_establish_date', 'tag_name',
  'af_reg_capital_std', 'special_tag', 'modify_date', 'type', 'head_office', 'af_total_point'];

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

function SectorCompanyTable(props) {
  const {
    tab: moveType,
    parkCode,
    areaKey,
    pageNumber = 1,
    pageSize = 20,
    companyTypes,
    onCompanyClick,
    filteredInfo = {},
    sortedInfo = {},
    getCount,
    parkTitle,
  } = props;

  const {
    key: sortKey,
    value: sortValue,
  } = getSortKv(sortedInfo);

  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );

  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetParkMigrationStatisticsChildTablesQuery({
    area_key: parkTitle === '园区总览' ? parkCode : undefined,
    park_id_list: parkTitle === '园区总览' ? undefined : [parkCode],
    move_type: moveType,
    company_type: getSingleOption(companyTypes),
    city_code: areaKey, // 被迁移的城市code
    // second_special_tag: filteredInfo.special_tag?.filter(tag => tag !== 'all') ?? [],
    industry_code_list: filteredInfo.tag_name?.filter(tag => tag !== 'all') ?? [],
    type_list: filteredInfo.type?.filter(tag => tag !== 'all') ?? [],
    sort_key: sortKey,
    sort_value: sortValue,
    page_size: pageSize,
    page_number: pageNumber,
    project_type: 'park',
  }, {
    skip: !parkCode,
  });
  const { columns: tableHead = [], dataSource: tableBody = [], total = 0, count } = companyData ?? {};

  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      // 处理企业名称
      if (item.dataIndex === 'company_name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      // if (item.dataIndex === 'special_tag') {
      //   rowConfig.filteredValue = filteredInfo.special_tag?.length > 0 ? filteredInfo.special_tag : ['all'];
      //   rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
      //   rowConfig.filters = companyTags;
      //   rowConfig.filterResetToDefaultFilteredValue = true;
      // }
      if (item.dataIndex === 'tag_name') {
        rowConfig.filters = industryTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} multiple />;
        rowConfig.filteredValue = filteredInfo?.tag_name ?? ['all'];
      }
      if (item.dataIndex === 'type') {
        rowConfig.filteredValue = filteredInfo?.type?.length > 0 ? filteredInfo?.type : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = TYPELIST;
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [onCompanyClick, tableHead, industryTree, filteredInfo, sortedInfo, parkCode]);
  // companyTags

  useEffect(() => {
    getCount(count);
  }, [getCount, count]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      noShowJumper
      {...props}
    />
  );
}

export default SectorCompanyTable;
