/*
 *Author: zhaochenyu
 *Description: 登录弹窗
 *Date: 2022/06/22
*/

import React, { useState, useEffect, useCallback } from 'react';
import { Spin, Modal, message, Alert } from 'antd';
// import { CloseOutlined } from '@ant-design/icons';
// import UpgradeWindow from '../../components/';
import Login from '../Login';
import style from './index.module.less';

function LoginModal(props) {
  const { setLoginShow, loginShow, onClose } = props;
  const [expiredRenewal, setExpiredRenewal] = useState(false); // 账号过期显示的弹窗
  const [expiredAccount, setExpiredAccount] = useState(''); // 过期账号信息
  const [logModalShow, setLoginModalShow] = useState(false);

  useEffect(() => {
    setLoginModalShow(loginShow);
  }, [loginShow]);

  const loginSuccess = useCallback(
    () => {
      message.success('登录成功!');
      setTimeout(() => {
        setLoginShow(false);
        window.location.reload();
      }, 200);
    },
    [],
  );
  return (
    <>
      {logModalShow && (
        <Modal
          title=""
          visible={logModalShow}
          maskClosable={false}
          keyboard={false}
          closable={false}
          wrapClassName={style.loginModal}
          footer={null}
          // onCancel={() => setVisible(false)}
          style={{ top: 220 }}
          maskStyle={{ background: 'rgba(51, 51, 51, 0.2)' }}
        >
          <Login
            loginSuccess={loginSuccess}
            setExpiredAccount={setExpiredAccount}
            setExpiredRenewal={setExpiredRenewal}
            setLoginShow={setLoginShow}
            onClose={onClose}
            setLoginModalShow={setLoginModalShow}
          />
        </Modal>
      )}
      {/* {expiredRenewal && (
      <Modal
        title=""
        visible={expiredRenewal}
        maskClosable={false}
        keyboard={false}
        closable={false}
        wrapClassName={style.renewalModal}
        footer={null}
        style={{ top: 250 }}
        maskStyle={{ background: 'rgba(62, 92, 118, 0.2)' }}
      >
        <UpgradeWindow
          componentFrom="首页-过期续费"
          close={false}
          upType="up"
          accountUpType="expired"
          expiredAccount={expiredAccount}
          successPay={() => {
            setSuccessPay(true);
            setExpiredRenewal(false);
            setExpiredAccount('');
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            setTimeout(() => {
              setSuccessPay(false);
            }, 3000);
          }}
        />
      </Modal>)} */}
    </>
  );
}

export default LoginModal;
