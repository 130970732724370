/*
 *Author: zhaochenyu
 *Description: 工具类函数
 *Date: 2021/09/29
*/

// 处理ip地址
export function handleWithIPList(ipList: string[]): string {
  return ipList[0];
}

export function isNumber (data: any): boolean {
  return typeof data === 'number';
}

export function isString (data: any): boolean {
  return typeof data === 'string';
}

export function isArray (data: any): boolean {
  return Array.isArray(data);
}

export function isObject (data: any): boolean {
  return Object.prototype.toString.call(data) === '[object Object]';
}

export function isFunction (data: any): boolean {
  return typeof data === 'function';
}

export function getRoundingNumber (data: number): number {
  const ceillData = Math.ceil(data);
  let res;
  if (ceillData.toString().length === 1) {
    res = Math.ceil(ceillData);
  } else if (ceillData.toString().length === 2) {
    res = Math.round(ceillData / Math.pow(10, ceillData.toString().length - 1)) * Math.pow(10, ceillData.toString().length - 1);
  } else {
    res = Math.round(ceillData / Math.pow(10, ceillData.toString().length - 2)) * Math.pow(10, ceillData.toString().length - 2);
  }
  return res;
}

// 处理图表过长的tooltip
export function handleToolTipLongTitle (title: string): string {
  let newTitle = '';
  if (title.length > 30) {
    const titleList = title.split('');
    while (titleList.length > 30) {
      newTitle += `<div>${titleList.splice(0, 30).join('')}</div>`;
    }
    newTitle += `<div>${titleList.join('')}</div>`
  } else {
    newTitle = title;
  }
  return newTitle;
}

const drawWaterMark = (ctx: any, width: number, height: number, devicePixelRatio = 1, source = '上奇产业通') => {
  const sourceStr = `数据来源 ${source}`;
  const fontSize = 12 * devicePixelRatio;
  const letterSpacing = 1.5 * devicePixelRatio;
  const lineHeight = 28 * devicePixelRatio;
  ctx.save();
  ctx.lineWidth = 1;
  ctx.font = `${fontSize}px siyuan`;
  const msSource = ctx.measureText(sourceStr);
  const maxStrWidth = msSource.width + (sourceStr.length - 1) * letterSpacing;
  const padding = [0, 4 * 2];
  const waterMarkPos = [width - maxStrWidth - padding[1], height - lineHeight * 0.5];
  const rectSize = [maxStrWidth + padding[1] * 2, lineHeight]
  const rectPos = [width - rectSize[0], height - rectSize[1]];
  ctx.save();
  ctx.fillStyle = 'rgba(232,236,239,0.8)';
  ctx.fillRect(rectPos[0], rectPos[1], rectSize[0], rectSize[1]);
  ctx.stroke();
  ctx.fill();
  ctx.fillStyle = 'rgba(77,103,126,0.8)';
  ctx.textBaseline = 'middle';
  const len = sourceStr.length;
  let widthSum = waterMarkPos[0];
  for (let i = 0; i < len; i += 1) {
    ctx.fillText(sourceStr[i], widthSum, waterMarkPos[1]);
    widthSum += (ctx.measureText(sourceStr[i]).width + letterSpacing);
  }
  ctx.stroke();
  ctx.fill();
  ctx.restore();
}

// 增加水印
export const addWaterMark = (canvas: any, devicePixelRatio = 2, source = '上奇产业通') => {
  if (!canvas) return null;
  // const context = canvas.getContext('2d');
  const _canvas = document.createElement('canvas');
  const { width, height } = canvas;
  const _width = width;
  const scaleFactor = _width / width;
  const _height = height * scaleFactor;
  const footerHeight = 20 * devicePixelRatio;
  const _stretchHeight = _height + footerHeight;
  _canvas.width = _width;
  _canvas.height = _stretchHeight;
  _canvas.style.width = `${_width / devicePixelRatio}px`;
  _canvas.style.height = `${_stretchHeight / devicePixelRatio}px`;
  // const imageData = context.getImageData(0, 0, width, height);
  const ctx: any = _canvas.getContext('2d');
  ctx.clearRect(0, 0, _width, _stretchHeight);
  ctx.save();
  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, _width, _stretchHeight);
  ctx.fill();
  ctx.restore();
  ctx.save();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.drawImage(canvas, 0, 0);
  ctx.restore();
  drawWaterMark(ctx, _width, _stretchHeight, devicePixelRatio, source);
  return _canvas.toDataURL('image/png', 1 );
}
export const toolTipFormatter = (items: {name:string, value: string | number, unit: string}[],
  toolTipStyle: string[],
  title?: string
  ) => {
  let str = '<div style="padding: 9px 9px 4px 9px;">';
  if (title) {
    str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: ${toolTipStyle[0]};letter-spacing: 1.75px;">${handleToolTipLongTitle(title)}</div>`;
  }
  items.forEach(({name, value, unit}) => {
    str += `<div style="display: flex; justify-content: space-between; color: ${toolTipStyle[2]};">
    <span style="margin-right: 60px">${name|| ''}</span>
    <span>
      <span style="color: ${toolTipStyle[3]};font-weight: 600;font-size: 18px;margin-right: 4px;">${value || ''}</span>
      ${unit || ''}
    </span>
  </div>`;
  });
  str += '</div>';
  return str;
}

export function loadJS(src: string){
  return new Promise( res => {
    const script = document.createElement('script');
    script.src = src;
    document.body.append(script);
    script.onload = () => {
      res([false, () => {
        script.parentNode?.removeChild(script)
      }])
    }
    script.onerror = () => {
      res([true,() => {
        script.parentNode?.removeChild(script)
      }])
    }
  })
}

export function getDefaultCanvas(width: number, height: number, devicePixelRatio = 1) {
  const canvas = document.createElement('canvas');
  canvas.width = width * devicePixelRatio;
  canvas.height = height * devicePixelRatio;
  return canvas;

};
// 文字宽度
let ctx: CanvasRenderingContext2D;
export function getWordsWidth(words: string, wordStyle: {
  font: string
} = {
  font: '10px sans-serif',
}){
  if (!ctx) {
    const canvas = document.createElement('canvas');
    ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  }
  ctx.font = wordStyle.font;
  return ctx.measureText(words).width + 2
}
// 文字行数
export function getLines(words: string, curSize: number, containerWidth: number) {
  if (words.length === 1) {
    return 1;
  }
  let curStart = 0;
  let lines = 0;
  for (let i = 1, L = words.length; i < L + 1; i += 1) {
    const text = words.slice(curStart, i);
    const curWordWidth = getWordsWidth(text, { font: `${curSize}px sans-serif` });
    if (curWordWidth > containerWidth) {
      lines += 1;
      curStart = i - 1;
      // i -= 1;
      if (i === L) { // 最后一个元素一定要加一行
        lines += 1;
      }
    } else if (i === L) { // 最后一个元素一定要加一行
      lines += 1;
    }
  }
  if (lines === 0) {
    lines = 1;
  }
  return lines;
}
// 一行文字分割成几行
export function breakStringToLines(value: string, perletterInRow: number) {
  let ans = '';
  let lines = 1;
  for (let i = 0, L = value.length; i < L; i += 1) {
    // 非0  每perletterInRow个  不是最后一个
    if (i && !((i + 1) % perletterInRow) && (L - 1 - i)) {
      ans += (`${value[i]}\n`);
      lines += 1;
    } else {
      ans += value[i];
    }
  }
  return {
    str: ans,
    lines,
  };
}
