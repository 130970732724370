import React from 'react';
import classNames from 'classnames';
import logo from '../../../assets/images/dataAnalysisTool/logo.png';
import styles from './index.module.less';

const toolList = [
  {
    key: '生成报告',
    value: '生成报告',
  },
];

export default function Header({ selectTool, setSelectTool }) {
  return (
    <div className={styles.headBox}>
      <div className={styles.logoBox}>
        <img src={logo} alt='' />
      </div>
      <div className={styles.toolBox}>
        {
          toolList.map(item => (
            <div
              className={classNames(styles.toolItem, { [styles.currentTool]: item.key === selectTool })}
              key={item.key}
              onClick={() => setSelectTool(item.key)}
            >
              {item.value}
            </div>
          ))
        }
      </div>
    </div>
  );
}
