/*
 *Description: Waterfall
 *Date: Fri Dec 03 2021 13:18:49 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { WaterfallChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, getWordsWidth, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { handleData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-waterfall-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const WaterfallChart = forwardRef((props: WaterfallChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize } = props;
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);
  const maxYAxisNumber = useRef({ value: '' });

  useEffect(() => {
    setChartLoading(false);
  }, [data]);

  const labelFontSize = 10;

  useEffect(() => {
    if (!data) {
      return;
    }
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    const xName = data.xName || '';
    const xNameGap = 10;

    let xNameWidth = getWordsWidth(xName, { font: `${labelFontSize}px sans-serif` });
    if (xNameWidth) {
      xNameWidth += xNameGap;
    }
    const grid = {
      show: false,
      left: 10,
      right: 8 + xNameWidth,
      top: 38,
      bottom: 30,
      containLabel: true,
    };
    const { x, y } = handleData(data.xAxis, data.yAxis);
    let { yUnit } = data;
    if (data.yName) {
      yUnit = `\n{yUnit|(${yUnit})}`;
    }

    const yName = `${y.length ? `${data.yName}${yUnit}` : ''}`;
    const yNameFontSize = 10;
    const xAxis = [{
      name: xName,
      nameLocation: 'end',
      nameGap: xNameGap,
      nameTextStyle: {
        fontSize: labelFontSize,
        align: 'left',
      },
      type: 'category',
      splitLine: { show: false },
      data: x,
      show: x.length && y.length,
      axisLabel: {
        show: true,
        fontSize: labelFontSize,
        color: curStyle.xAxisLabelColor,
        interval: 0,
        rotate: 45,
      },
      axisTick: {
        show: false,
      },
    }];
    const yAxis = [{
      type: 'value',
      show: y.length,
      name: yName,
      nameTextStyle: {
        color: curStyle.yNameTextStyle.color,
        fontSize: yNameFontSize,
        align: 'left',
        padding: [0, 0, 0, -0],
        nameGap: 0,
        // backgroundColor: 'red',
        rich: {
          yUnit: {
            align: 'center',
            color: curStyle.yNameTextStyle.color,
            fontSize: yNameFontSize,
          },
        },
      },
      nameLocation: 'end',
      axisLabel: {
        fontSize: labelFontSize,
        interval: 0,
        margin: 8,
        color: curStyle.yAxisLabel.color,
        formatter(value: any) {
          value = String(value);
          if (value.length < 3) {
            // 理论上y轴是递增的 所以只保留最后一个就好？ 只针对value型的y轴
            maxYAxisNumber.current.value = value;
            return value;
          }
          let ans = '';
          for (let i = value.length - 1, L = value.length; i >= 0; i -= 1) {
            let prefix = '';
            if (i !== 0 && (L - i) % 3 === 0) {
              prefix = ',';
            }
            ans = prefix + value[i] + ans;
          }
          maxYAxisNumber.current.value = ans;
          return ans;
        },
      },
      position: 'left',
      axisLine: {
        show: true,
      },
      splitLine: {
        show: false,
        lineStyle: {
          type: 'dashed',
          color: curStyle.ySplitLine.color,
        },
      },
    }];
    // 存储堆叠数据
    const stackData = [] as Array<any>;
    y.forEach((item, index) => {
      const topData: any[] = [];
      // let count = 0;
      let sum = 0;
      item.value.forEach((innerItem, _index, arr) => {
        // count += Number(innerItem);
        // const beforeItem = topData[topData.length - 1] || 0;
        const realDataDiff = Number(innerItem) - Number(arr[_index - 1] || 0);
        // topData.push(0 + realDataDiff);
        const isLast = _index === arr.length - 1;
        if (!isLast) {
          sum += realDataDiff;
        }
        topData.push({
          name: item.name,
          value: realDataDiff,
          unit: item.unit,
          showData: {
            name: `${x[_index]} ${item.name}`,
            value: isLast ? sum : realDataDiff, // item.value[_index],
            unit: item.unit,
          },
        });
      });
      topData[topData.length - 1].value = (+topData[topData.length - 2].value) + (
        +item.value[item.value.length - 3]
      );
      // 真实数据
      const dataInBottom = [...item.value];
      // 除了最后一条 其余数据往后挪个1位 显示的数据i堆叠在i-1上据此形成阶梯感
      let before = dataInBottom[0];
      for (let i = 1, L = dataInBottom.length; i < L - 1; i += 1) {
        const temp = dataInBottom[i];
        dataInBottom[i] = before;
        before = temp;
      }
      dataInBottom[0] = 0;
      dataInBottom[dataInBottom.length - 1] = 0;
      stackData.push({
        type: 'bar',
        // name: item.name,
        stack: `Stack${index}`,
        dimensions: [item.unit || ''],
        data: dataInBottom,
        barMinWidth: 5,
        barMaxWidth: 12,
        itemStyle: {
          borderColor: 'transparent',
          color: 'transparent',
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent',
          },
        },
        tooltip: {
          show: false,
        },
      });
      // 堆叠展示
      stackData.push({
        type: 'bar',
        stack: `Stack${index}`,
        name: item.name,
        dimensions: [item.unit || '', item.value],
        barMinWidth: 5,
        barMaxWidth: 10,
        data: topData,
        itemStyle: {
          color: curStyle.color[index % curStyle.color.length],
        },
        label: {
          show: true,
          position: 'top',
          fontSize: 8,
          distance: 2,
          formatter(params: any) {
            return params?.data?.showData?.value || '';
          },
        },
        labelLayout: {
          // hideOverlap: true,
          moveOverlap: 'shiftY',
        },
      });
    });
    const series = stackData.map((item, _index) => ({
      ...item,
    }));

    const options = {
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          const args: any[] = [];
          params.forEach((item: any) => {
            args.push(item?.data?.showData || {});
          });
          return toolTipFormatter(args, toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      grid,
      xAxis,
      yAxis,
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };
    setOption(options);
  }, [data, fontSize, label, legend, mode, title]);

  // 坐标轴名字适应
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    const curOption = instance.getOption();
    if (!curOption.grid?.[0]) {
      return;
    }
    function adjustYAxisNameStyle(
      axis: any, paddingIndex: number, yMaxLabel: string, gridKey: string,
    ) {
      const distanceYtoEdge = curOption.grid[0][gridKey] + axis.axisLabel.margin + getWordsWidth(`${yMaxLabel}`, {
        font: `${axis.axisLabel.fontSize}px sans-serif`,
      });
      const yNameWords = (axis.name.split('\n')); // y轴名字坐标换行
      const yPurlyNameWidth = getWordsWidth(`${yNameWords[0]}`, {
        font: `${axis.nameTextStyle.fontSize}px sans-serif`,
      });
      const yUnit = yNameWords[1]?.match(/\{yUnit\|(\S*)\}/) && yNameWords[1].match(/\{yUnit\|(\S*)\}/)[1];
      const yUnitWidth = getWordsWidth(`${yUnit || ''}`, {
        font: `${axis.nameTextStyle.fontSize}px sans-serif`,
      });
      const yNameWidth = Math.max(yUnitWidth, yPurlyNameWidth);
      // console.log(yNameWidth);
      // 最好的效果是让y名字居中于y轴，前提是distanceYtoEdge可以容纳的下yNameWidth的一半
      const putSomeGap = 4;
      const namePadding = axis.nameTextStyle.padding;
      if (yNameWidth / 2 < distanceYtoEdge - putSomeGap) { // 可以居中展示
        namePadding[paddingIndex] = -yNameWidth / 2;
      } else { // 放不下 就尽量往中心点移动
        namePadding[paddingIndex] = -(
          (distanceYtoEdge - putSomeGap)
        );
      }
    }
    adjustYAxisNameStyle(curOption.yAxis[0], 3, maxYAxisNumber.current.value, 'left');
    instance.setOption({ ...curOption });
  }, [option]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default WaterfallChart;

WaterfallChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
