// 城市详情工具
import React, { useMemo } from 'react';
import style from './index.module.less';
import { jumpCompantDetail } from '../../../../utils/jumpCompantDetail';
import { useQuery } from '../../../../hooks/url';
import MigrationMonitoring from './IndustryMonitor/MigrationMonitoring';
import Cultivate from './IndustryMonitor/Cultivate';
import Weakness from './IndustryMonitor/Weakness';
import TargetMerchants from './IndustryMonitor/TargetMerchants';

const dom = (props) => {
  const { key } = props;
  const onCompanyClick = ({ company_id: id }) => {
    if (!id) return;
    jumpCompantDetail(id);
  };
  const cityToolMap = {
    weakLinks: <Weakness title="短板诊断" {...props} onCompanyClick={onCompanyClick} />,
    targetRecruitment: <TargetMerchants title="靶向招商" {...props} onCompanyClick={onCompanyClick} />,
    classificationBreed: <Cultivate title="分级培育" {...props} onCompanyClick={onCompanyClick} />,
    MigrationMonitoring: <MigrationMonitoring title="迁移监测" {...props} />,
  };
  return cityToolMap[key];
};

const ParkTool = () => {
  const query = useQuery();
  const parameter = useMemo(() => {
    const parkCode = query.get('parkCode');
    const cityCode = query.get('cityCode');
    if (parkCode && cityCode) {
      return {
        parkCode: window.atob(query.get('parkCode')),
        cityCode: window.atob(query.get('cityCode')),
        cityName: query.get('cityName'),
        title: query.get('title'),
        key: query.get('key'),
        isProvinceDirectly: JSON.parse(query.get('is-province-directly')),
        parkName: query.get('parkName'),
        parkTitle: query.get('parkTitle') || '园区详情',
      };
    }
    return undefined;
  }, [query]);
  return (
    <div className={style.box}>
      <p className={style.title}>
        <span className={style.nameStyle}>{parameter.parkName}</span>
        <span>{parameter.title}</span>
      </p>
      {
        parameter && dom(parameter)
      }
    </div>
  );
};

export default ParkTool;
