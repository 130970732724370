import { IWordCloudVisualConfig } from '../interface/config';
import { ISeriesGetter } from '../interface/base';
import { omitUndefined } from './utils';

export const getEach = (series: IWordCloudVisualConfig) => {
  const {
    shape,
    maskImage,
    left,
    top,
    width,
    height,
    right,
    bottom,
    sizeRange,
    rotationRange,
    rotationStep,
    gridSize,
    drawOutOfBound,
    layoutAnimation,
    label,
  } = series;

  return omitUndefined({
    type: 'wordCloud',
    shape,
    maskImage,
    left,
    top,
    width,
    height,
    right,
    bottom,
    sizeRange,
    rotationRange,
    rotationStep,
    gridSize,
    drawOutOfBound,
    layoutAnimation,
    textStyle: label,
  });
}

export const getWordCloudSeries: ISeriesGetter<IWordCloudVisualConfig> = (series, index, extra) => {
  return omitUndefined({
    ...series,
    ...getEach(extra.material.config.visualConfig as IWordCloudVisualConfig),
  })
};
