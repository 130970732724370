import { POLICYDETAIL } from '../utils/url';
import { get } from '../utils/request';

// 政策-详情
const getPolicyInfo = (params) => get(`${POLICYDETAIL}/policy_info`, {
  ...params,
});

export {
  getPolicyInfo,
};
