/*
 *Author: zhaochenyu
 *Description: 我的-笔记-表格
 *Date: 2021/12/28
*/

import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Pagination, Table, Badge, message } from 'antd';
import NotesDetail from '../../IndustryCompanyDetail/DetailTop/ToolBox/NotesDetail';
import { getNotes, noteInsert } from '../../../api/companyDetail';
import { openNewPage } from '../../../utils/urlMethods';
import style from './index.module.less';

function CompanyNoteTable(props) {
  const { data: { tableHead, tableBody, count }, pagination, getNotesTable } = props;
  const [noteCompanyName, setNoteCompanyName] = useState('');
  const [showNote, setShowNote] = useState(false);
  const [notesData, setNotesData] = useState({});
  const [noteSTyle, setNoteSTyle] = useState({});
  const compnayId = useRef(null);

  // 跳转到企业详情
  const pushToCompnay = useCallback(
    (rowData) => {
      const { id } = rowData;
      if (id) {
        openNewPage('companyDetail', {
          id,
        });
      }
    },
    [],
  );

  const getCurNoteData = useCallback(() => {
    getNotes({
      company_id: compnayId.current,
    }).then(res => {
      if (res.status === '00000') {
        if (res.data && res.data.data_list.length > 0) {
          setNotesData(res.data);
        } else {
          setShowNote(false);
        }
        getNotesTable(pagination);
      }
    });
  }, []);

  const showNotes = useCallback(
    (e, rowData) => {
      if (rowData.id === compnayId.current) {
        return;
      }
      compnayId.current = rowData.id;
      setNoteSTyle({
        position: 'fixed',
        top: e.clientY - e.target.offsetTop,
        left: e.clientX + 20,
      });
      setNoteCompanyName(rowData.company_name);
      getNotes({
        company_id: rowData.id,
      }).then(res => {
        if (res.status === '00000') {
          setNotesData(res.data);
          setShowNote(true);
        }
      });
    },
    [],
  );

  useEffect(() => {
    if (!showNote) {
      setNoteCompanyName('');
      setNotesData([]);
      compnayId.current = '';
    }
  }, [showNote]);

  const notesDetailEvent = (type, ctn) => {
    if (ctn === '') {
      message.destroy();
      message.info('请输入您需要记录的内容');
      return;
    }
    switch (type) {
      case 'confirm':
        noteInsert({
          company_id: compnayId.current,
          content: ctn,
        }).then(res => {
          if (res.status === '00000') {
            message.success('笔记添加成功！');
            getCurNoteData();
          } else {
            message.error('笔记添加失败！');
          }
        });
        break;
      case 'cancel':
        setShowNote(false);
        break;
      default:
        break;
    }
  };

  // 处理笔记表头
  const handleWithTableHead = useCallback(
    (columns) => {
      const newColumn = columns.map((item) => {
        let res;
        if (item.dataIndex === 'operation') {
          res = {
            ...item,
            width: '15%',
            render: (text, record) => (
              <div className={style.noteOperator} onClick={(e) => showNotes(e, record)}>
                {text}
                <Badge count={record.count} />
              </div>
            ),
          };
        } else if (item.dataIndex === 'company_name') {
          res = {
            ...item,
            width: '60%',
            render: (text, record) => (
              <div className={style.pushToDetail} onClick={() => pushToCompnay(record)}>
                {text}
              </div>
            ),
          };
        } else {
          res = {
            ...item,
            ellipsis: true,
          };
        }
        return res;
      });
      return newColumn;
    },
    [],
  );
  return (
    <div>
      <Table
        rowKey={record => record.id}
        columns={handleWithTableHead(tableHead)}
        dataSource={tableBody}
        pagination={false}
      />
      <Pagination
        className={style.pagination}
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={count}
        pageSizeOptions={[10]}
        showQuickJumper
        hideOnSinglePage
        onChange={(page, pageSize) => {
          getNotesTable({ current: page, pageSize });
        }}
      />
      {showNote && (
        <div style={{ ...noteSTyle }}>
          <NotesDetail
            companyName={noteCompanyName}
            notesData={notesData}
            closePop={() => setShowNote(false)}
            updateNoteList={() => getCurNoteData()}
            notesDetailEvent={notesDetailEvent}
          />
        </div>
      )}
    </div>
  );
}

export default CompanyNoteTable;
