import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

/**
 * 产业频道数据部分副标题
 * @param {string} description
 * @param {number | string} value
 * @param {string} unit
 * @returns {JSX.Element}
 * @constructor
 */
function Subtitle({ description, value, unit, selectName, className }) {
  return (
    <div className={classnames(className)}>
      {selectName && <span className={styles.value}>{selectName}</span>}
      {description && <span className={styles.name}>{description}</span>}
      {value && <span className={styles.value}>{value}</span>}
      {unit && <span className={styles.unit}>{unit}</span>}
    </div>
  );
}

export default Subtitle;
