/*
 *Description: HeapAndPieMap
 *Date: Thu Jan 06 2022 14:53:58 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
// @ts-ignore
import * as echarts from 'echarts';
// @ts-ignore
import { linearMap } from 'echarts/lib/util/number';
import { HeapAndPieMapChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, toolTipFormatter } from '../../utils/tool';
import { get } from '../../utils/request';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';

import { markImgs } from './static/markIcon';

import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-heap-and-pie-map-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);
const styleToolTip = classNames(`${prefixCls}-tool-tip`);
const styleToolTipTitle = classNames(`${prefixCls}-tool-tip-title`);
const styleToolTipSubTitle = classNames(`${prefixCls}-tool-tip-sub-title`);
const styleToolTipDesc = classNames(`${prefixCls}-tool-tip-desc`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;
const slotDiv = document.createElement('div');

const HeapAndPieMapChart = forwardRef((props: HeapAndPieMapChartProps, ref: any) => {
  const { mode, label, legend, title = '', fontSize, data } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);
  const geoId = 'geo';

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  const [registeredMap, setRegisterMap] = useState(false);

  // 九段线 echart自动加入。。。
  const mapName = data?.mapCode === '000000' ? 'china' : data?.mapCode;
  useEffect(() => {
    if (!data?.mapCode) {
      return;
    }
    const getMap = async () => {
      if (!echarts.getMap(mapName)) {
        const geoJson = await get(`${data.host}/${data.mapCode}.json`, {});
        echarts.registerMap(`${mapName}`, geoJson);
        setRegisterMap(true);
        setChartLoading(false);
      }
    };
    getMap();
    // get(`${data.host}/${data.mapCode}.json`, {}).then((code: any) => {
    // // get('http://localhost:9090/city_json/000000.json', {}).then((code: any) => {
    //   echarts.registerMap(`${mapName}`, code);
    //   // echarts.registerMap(`${data.mapCode}`, data.region_border);
    //   setRegisterMap(true);
    // }).finally(() => {
    //   setChartLoading(false);
    // });
  }, [data]);

  function genLegend(map: Map<string, string>) {
    const arrFromMap = (Array.from(map.entries())).reverse();
    const texts = arrFromMap.map(item => item[0]);
    const legendColor = arrFromMap.map(item => item[1]);

    const rectSize = 3;
    const textMarginLeft = 4;

    const legendGroupBotomStart = 8;
    const legendGroupLeftStart = 12;

    const gapBetweenLegend = 4;
    const { textFontSize } = chartFontSize[fontSize!];
    let width = 0;
    const elements = [];
    let curBottom;
    // 列表
    for (let i = 0, L = texts.length; i < L; i += 1) {
      curBottom = legendGroupBotomStart + i * (gapBetweenLegend + textFontSize);
      const group = {
        type: 'group',
        left: legendGroupLeftStart,
        bottom: curBottom,
        children: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            shape: {
              x: 0,
              y: (textFontSize - rectSize) / 2,
              width: rectSize,
              height: rectSize,
            },
            style: {
              fill: legendColor[i],
            },
          },
          {
            type: 'text',
            x: rectSize + textMarginLeft,
            y: 0,
            style: {
              text: texts[i],
              fill: '#0D3B66',
              font: `${textFontSize}px PingFang-SC-Regular, PingFang-SC`,
            },
          },
        ],
      };
      const curWidth = legendGroupLeftStart + (
        rectSize + textMarginLeft + texts[i].length * textFontSize
      );
      width = Math.max(curWidth, width);
      elements.push(group);
    }
    // 标题
    elements.push({
      type: 'text',
      bottom: (curBottom || 0) + textFontSize + 3,
      left: legendGroupLeftStart,
      style: {
        text: '行业',
        fill: '#0D3B66',
        font: `${textFontSize}px PingFang-SC-Regular, PingFang-SC`,
      },
    });
    return {
      elements,
      width,
      height: legendGroupBotomStart + elements.length * textFontSize + (
        elements.length - 1
      ) * gapBetweenLegend,
    };
  }

  useEffect(() => {
    if (!registeredMap) {
      return;
    }
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    let minHeap = Infinity;
    let maxHeap = -Infinity;
    const series: any[] = [{
      name: '',
      type: 'map',
      geoIndex: 0,
      data: data.heapData.map((item: { value: number; }) => {
        minHeap = Math.min(minHeap, item.value);
        maxHeap = Math.max(maxHeap, item.value);
        // itemStyle调整样式
        return item;
      }),
      label: { show: true },
      showLegendSymbol: false,
      tooltip: {
        show: true,
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // console.log(params);
          // 模板自动生成可能需要自己手动改改
          if (!params.data) {
            return null;
          }
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
    },
    ];
    const visualMap = [
      {
        show: data?.heapData?.length || false,
        // 直接隐藏掉后续利用其数据自己画
        top: 1000000000000,
        left: 10000000000,
        type: 'piecewise',
        min: minHeap,
        max: maxHeap,
        itemWidth: 22,
        itemHeight: 6,
        itemGap: 2,
        textGap: 2,
        padding: 0,
        align: 'left',
        text: ['  高', '  低'],
        showLabel: true,
        textStyle: {
          color: '#3E5C76',
          fontSize: 8,
        },
        formatter(from: number, to: number) {
          return `${from.toFixed(0)} - ${to.toFixed(0)}`;
        },
        realtime: false,
        splitNumber: 5,
        inRange: {
          color: ['#4E7293'].reverse(),
        },
        seriesIndex: series.findIndex(item => item.type === 'map'),
      },
    ];

    const geo = {
      id: geoId,
      layoutCenter: ['50%', '50%'],
      layoutSize: '80%',
      regions: data.heapData.map((item: any) => ({
        ...item,
        itemStyle: {
          areaColor: '#4E7293',
          borderColor: '#AAE5DC',
          borderType: 'solid',
          borderWidth: 2,
        },
      })),
      map: mapName,
      roam: false,
      itemStyle: {
        areaColor: '#FAFAFA',
        borderType: 'dashed',
        borderWidth: 1,
        borderColor: '#4E7293',
      },
      label: {
        show: false,
      },
    };
    const options: {[key: string]: any} = {
      tooltip: {
        trigger: 'item',
        triggerOn: 'none',
        confine: true,
        show: false,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: curFont.legendWidth,
        itemHeight: curFont.legendHeight,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      geo,
      series,
      visualMap,
    };
    // 左侧一系列图标
    if (data.heapData.length) {
      const mapColor = new Map();
      const L = curStyle.industryColors.length;
      data.allIndustry.forEach((item: any, i: number) => {
        mapColor.set(item, curStyle.industryColors[i % L]);
      });

      const graphics = genLegend(mapColor);
      options.graphic = {
        elements: graphics.elements,
      };
      const instance = chartRef.current.getEchartsInstance();
      const containerWidth = instance.getWidth();
      const percentLeft = (graphics.width / containerWidth) * 100;
      options.geo.layoutSize = `${((containerWidth - graphics.width) / containerWidth) * 100}%`;
      // 若是50% left正好是左侧图形的一半 这样是为了保证地图不会覆盖到左边部分图形
      options.geo.layoutCenter = [`${50 + (percentLeft / 2)}%`, `${50}%`];
    }
    setOption(options);
  }, [hanldeData, fontSize, label, legend, mode, title, registeredMap, data]);

  // 小饼图添加
  useEffect(() => {
    if (!registeredMap) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    const { scatterData } = data;
    const pieSeries = scatterData.map((item: any) => {
      // instance.convertToPixel('geo', [128.3324, 89.5344]);
      const center = instance.convertToPixel('geo', [item.y, item.x]);
      return {
        type: 'pie',
        name: 'pie',
        states: {
          item,
        },
        z: 10,
        center,
        label: {
          show: false,
        },
        data: item.pieData,
        radius: 5,
        tooltip: {
          show: true,
        },
        silent: false,
        selectedOffset: 0,
        emphasis: {
          scale: false,
        },
      };
    });
    option.series.push(...pieSeries);
    instance.setOption({ ...option });
  }, [option]);

  // graphic 排名添加
  useEffect(() => {
    if (!registeredMap) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    const { heapData } = data;
    const elements = heapData.slice(0, 3).map((item: any, id: any) => {
      // instance.convertToPixel('geo', [128.3324, 89.5344]);
      const [centerX, centerY] = instance.convertToPixel('geo', [item.y, item.x]);
      const size = 25;
      return {
        type: 'image',
        id,
        x: centerX - size / 2,
        y: centerY - size / 2,
        z: 20 - id,
        states: { id },
        scaleX: 1,
        scaleY: 1,
        style: {
          image: markImgs[id],
          width: size,
          height: size,
        },
      };
    });
    option.graphic = {
      id: 'graphic',
      elements,
    };
    instance.setOption({ ...option });
  }, [option]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  const toolTipRef = useRef(slotDiv);
  useEffect(() => {
    const instance = chartRef.current.getEchartsInstance();
    let isToolTipShow = false;
    const handleMove = (e: any) => {
      const wraperDiv = toolTipRef.current;
      if (isToolTipShow) {
        const divRect = wraperDiv.getBoundingClientRect();
        const { clientX, clientY } = e.event.event;
        const extensionDistance = 10;
        // 不在tooltip框内就隐藏
        if (!(
          (
            clientX >= divRect.left - extensionDistance && (
              clientX <= divRect.right + extensionDistance
            )
          ) && (
            clientY >= divRect.top - extensionDistance && (
              clientY <= divRect.bottom + extensionDistance
            )
          )
        )) {
          wraperDiv.style.display = 'none';
          isToolTipShow = false;
        }
      }
    };
    const handleClick = (e: any) => {
      if (1) {
        return;
      }
      console.log(e.seriesType);
      e.event.stop();
      const { target } = e.event;
      const { zrX, zrY } = e.event.event;
      // 控制自定义弹框
      if (target) {
        if (target.type === 'image') {
          const wraperDiv = toolTipRef.current;

          // const originIndex = target.states.id;
          // console.log(e);
          // console.log(data.heapData[originIndex]);
          wraperDiv.style.cssText = `
            top: ${zrY}px;
            left: ${zrX}px;
            display: block;
            `;
          isToolTipShow = true;
        }
      }
    };
    if (chartRef.current) {
      instance.on('click', handleClick);
      instance.on('mousemove', handleMove);
    }
    return () => {
      instance.off('click', handleClick);
      instance.off('mousemove', handleMove);
    };
  }, [option]);

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
      <div className={styleToolTip} ref={toolTipRef} style={{ display: 'none' }}>
        <div className={styleToolTipTitle}>
          浙江省
          <img src={markImgs[0]} alt="" />
        </div>
        <div className={styleToolTipSubTitle}>杭州海康威视技术股份有限公司</div>
        <div className={styleToolTipDesc}>
          投资或设立分支机构
          <span>24</span>
          家
        </div>
      </div>
    </div>);
});

export default HeapAndPieMapChart;

HeapAndPieMapChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
