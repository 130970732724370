import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import Container from '../../Container';
import { useGetWinningProjectsQuery } from '../../../../../service/companyDetail';
import TableNoData from '../../TableNoData';

function ContractAwardedProject(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: isTableFetching } = useGetWinningProjectsQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  const columns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          width: 70,
          ...item,
        };
      }
      if (item.dataIndex === 'purchaser') {
        return {
          width: 320,
          ...item,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'title') {
        return {
          width: 450,
          ...item,
          ellipsis: true,
        };
      }
      return {
        ...item,
        ellipsis: true,
      };
    })
  ), [tableData]);
  return (
    <Container id="中标项目" num={tableData?.count || 0}>
      <Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={columns}
        strip
        loading={isTableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />
    </Container>
  );
}

export default ContractAwardedProject;
