import { withConfig } from '../../../hoc/withConfig';
import { Timeline } from '@sqke/design';

const ConfigTimeline = withConfig(
  Timeline,
  {
    mapConfigToProps: (raw, data) => {
      const items = data?.series?.[0]?.data ?? [];
      const { visualConfig = {} } = raw;

      return {
        ...visualConfig,
        items: items.map((item: any) => ({ title: item.name, content: item.value })),
      };
    },
    empty: (_, data) => !data || data.length === 0,
  }
);

export default ConfigTimeline;
