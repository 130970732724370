import React from 'react';
import classnames from 'classnames';
import style from './index.module.less';

// 产业 头部tab
const IndustryTabs = ({ tab, selectedKey, onClick }) => (
  <div className={style.box}>
    {
      tab && tab.map((item, index) => (
        <button
          type='button'
          className={classnames(
            {
              [style.selected]: item.name === selectedKey,
            },
          )}
          key={item.name}
          onClick={() => { onClick(index); }}
        >
          {item.name}
        </button>
      ))
    }
  </div>
);

export default IndustryTabs;
