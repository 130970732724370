// 股权关系网络
import React, { useMemo } from 'react';
import { ECharts, Table } from '@sqke/design';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';
import Container from '../../Container';
import style from './index.module.less';
import { useGetEquityRelationshipQuery } from '../../../../../service/companyDetail';

function EquityRelationship(props) {
  const { companyId, companyName } = props;

  const { data, isFetching } = useGetEquityRelationshipQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const { chart, table } = data ?? {};

  const isGreaterThan50 = (lv3) => {
    let greaterThan50 = false;
    lv3.forEach(item => {
      if (item.children.length > 50) {
        greaterThan50 = true;
      }
    });
    return greaterThan50;
  };

  const option = useMemo(() => {
    if (!chart || !chart?.series.length) return [];
    const newChart = cloneDeep(chart);
    const { children } = newChart.series?.[0]?.data?.[0];
    if (isGreaterThan50(children)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < children.length; i++) {
        if (children[i].children.length > 0 && children[i].children.length < 50) {
          children[i] = { ...children[i], collapsed: false };
          break;
        }
      }
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < children.length; i++) {
        children[i] = { ...children[i], collapsed: false };
      }
    }
    const series = newChart?.series?.map(item => ({
      name: item.name,
      initialTreeDepth: 1,
      expandAndCollApse: true,
      roam: true,
      scaleLimit: {
        min: 0.5,
        max: 4,
      },
      type: 'tree',
      left: '-50%',
      right: '-50%',
      orient: 'TB',
      top: 120,
      data: item.data,
      label: {
        rotate: -90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'bottom',
        fontSize: 16,
        width: 120,
        overflow: 'truncate',
      },
      levels: [
        {
          label: {
            fontSize: 16,
          },
        },
        {
          label: {
            fontSize: 16,
          },
        },
      ],
    }));

    return {
      tooltip: {},
      series,
    };
  }, [chart]);
  const amplifyOption = useMemo(() => {
    if (!(option.series?.length)) return {};
    const _option = cloneDeep(option);
    _option.series[0].levels = [
      {
        label: {
          fontSize: 28,
        },
      },
      {
        label: {
          fontSize: 16,
        },
      },
    ];
    _option.series[0].label.fontSize = 14;
    _option.series[0].label.overflow = 'none';
    _option.series[0].top = 20;
    _option.series[0].lookAll = true;

    return {
      option: _option,
      title: {
        title: '股权关系网络',
        companyName,
      },
    };
  }, [option]);

  const dataSource = useMemo(() => (
    table?.dataSource.map((item, index) => ({
      key: index,
      ...item,
    }))
  ), [table]);

  const chartEl = useMemo(() => {
    if (!chart) return <div style={{ minHeight: '700px' }}><Spin size="large" /></div>;
    if (chart.series?.length > 0) {
      return (<ECharts
        showLoading={isFetching}
        className={style.chart}
        option={option}
      />);
    }
    return <></>;
    // return <div className={style.noData}>该企业无此数据图谱</div>;
  }, [chart]);

  return (
    <Container id="股权关系网络" title={chart?.title} look="查看全图" option={amplifyOption}>
      {
        chartEl
      }
      {
        dataSource?.length > 0 && (
          <Table
            dataSource={dataSource}
            columns={table?.columns}
            title={() => table?.title}
            locale={{
              emptyText: ' ',
            }}
            pagination={false}
            strip
          />
        )
      }

    </Container>
  );
}

export default EquityRelationship;
