import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, message } from 'antd';
import {
  useLazyTestFileRecordQuery,
  useLazyTestDeleteFileRecordQuery,
} from '../../../../../../../service/tool';
import CustomTable from '../../../CustomTable';
import style from './index.module.less';

const { confirm } = Modal;

const FilesContent = () => {
  const history = useHistory();
  const [fileListData, setFileListData] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isFetching, setIsFetching] = useState(false);
  const [getTestDeleteFileRecord] = useLazyTestDeleteFileRecordQuery();
  const [getTestFileRecord] = useLazyTestFileRecordQuery();

  const onGetTestFileRecord = async (pageNumVal, pageSizeVal) => {
    setIsFetching(true);
    const result = await getTestFileRecord({
      page_number: pageNumVal,
      page_size: pageSizeVal,
    });
    setFileListData(result.data);
    setIsFetching(false);
  };

  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
    onGetTestFileRecord(n, s);
  };

  // 点击下载按钮
  const handleDownLoad = (node) => {
    if (node.file_link) {
      // 下载
      const link = document.createElement('a');
      link.href = node.file_link;
      link.download = node.file_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onShowConfirm = (val) => {
    confirm({
      title: '删除提示',
      icon: <QuestionCircleOutlined />,
      content: '删除后，该记录无法恢复，是否继续?',
      onOk() {
        getTestDeleteFileRecord({
          id: val.id,
        }).then(res => {
          if (res.data === '删除成功') {
            message.success(res.data);
            setPageNum(1);
            onGetTestFileRecord(1, pageSize);
          }
        }).catch(err => {
          throw new Error(err);
        });
      },
    });
  };

  const columns = (data) => {
    if (!data) return [];
    return data.map((item) => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 80,
        };
      }
      if (item.dataIndex === 'file_name') {
        return {
          ...item,
          width: 600,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'operation') {
        return {
          ...item,
          align: 'center',
          render: (_, record) => (
            <div className={style.handle}>
              <button
                type="button"
                onClick={() => {
                  history.push(`/companyIntelligentEvaluation?type=uploading&uploadRecordId=${record.id}`);
                }}
              >
                查看
              </button>
              <button
                type="button"
                onClick={() => handleDownLoad(record)}
              >
                下载
              </button>
              <button
                type="button"
                onClick={() => onShowConfirm(record)}
              >
                删除
              </button>
            </div>
          ),
        };
      }
      return item;
    });
  };

  useEffect(() => {
    onGetTestFileRecord(pageNum, pageSize);
  }, []);

  return (
    <>
      {
        fileListData && <CustomTable
          data={fileListData}
          pageNumber={pageNum}
          pageSize={pageSize}
          // total={fileListData?.count || 0}
          customOption={{ columns: columns(fileListData.columns) }}
          isFetching={isFetching}
          onChange={({ current, pageSize: pageSizeData }) => paginationOnChange(current, pageSizeData)}
        />
      }
    </>
  );
};

export default FilesContent;
