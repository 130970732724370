import React from 'react';
import Container from '../../Container';
import Emigration from './Emigration';
import Capital from './Capital';
import Executives from './Executives';
import BusinessScope from './BusinessScope';
// import ChartNoData from '../../ChartNoData';

function BusinessChange(props) {
  return (
    <Container id="工商变更">
      <Emigration {...props} />
      <Capital {...props} />
      <Executives {...props} />
      <BusinessScope {...props} />
    </Container>
  );
}

export default BusinessChange;
