/*
 *Description: 饼图业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import PieChart from '../../BasicsCompoment/PieChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessPieChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessPieChart(props: BusinessPieChartProps) {
  const {
    mode, label, legend, type, ip, conditions,
    onRequestComplete, title, componmentKey,
    imgRef, center, radius,
  } = props;

  const [chartData, setChartData] = useState<any>([]);
  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          setChartData(res.data.pie_data || res.data.data || []);
          setChartTitle(res.data.title || '');
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        } else {
          setChartData([]);
        }
        if (isFunction(onRequestComplete)) {
          onRequestComplete!(res.data);
        }
      }).catch(() => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark} style={{ overflowY: 'hidden' }}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <PieChart
          title={typeof title === 'string' ? title : chartTitle}
          data={Array.isArray(chartData) ? chartData : []}
          {...{
            mode,
            label,
            legend,
            type,
            ref: imgRef,
            center,
            radius,
            chartDesc,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

// export default BusinessPieChart;
BusinessPieChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
};

// 企业注册资本分布饼图
export function EnterPriseRegisterPieChart(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'new_company_capital_distribute_fenxi',
    class_name: '/chart/company',
  };

  return (
    <BusinessPieChart
      {...props}
      type="radius"
      componmentKey={componmentKey}
    />);
}

// 法人类型分布图
export function LegalTypesPieChart(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'legal_person_type_distribute_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 产业载体分布
export function IndustryLoaderDistribute(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'industrial_carrier_distribution_fenxi',
    class_name: '/chart/carrier',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 产业载体类型分布
export function IndustryLoaderTypeDistribute(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'industrial_carrier_types_distribution_fenxi',
    class_name: '/chart/carrier',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 产业区域分布
export function IndustryAreaTypeDistribute(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'company_distribution_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 研究机构类型分布
export function ResearchTypeDistribute(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'research_institute_type_fenxi',
    class_name: '/chart/institution',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 研究机构区域分布
export function ResearchAreaTypeDistribute(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'research_institute_city_fenxi',
    class_name: '/chart/institution',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 18. 园区数量对比
export function ParkChartcomparisonOfParkQuantityFenxi(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'comparison_of_park_quantity_fenxi',
    class_name: '/chart/park',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 39. 授权发明专利区域分布
export function PatentChartauthorizedInventionPatentCityFenxi(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'authorized_invention_patent_city_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

//  . 138--新增授权发明专利区域分布
export function PatentChartnewAuthorizedInventionPatentCityFenxi(props: BusinessPieChartProps) {
  const componmentKey = {
    function_key: 'new_authorized_invention_patent_city_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

//  . 150--产业载体分布
export function CarrierChartindustrialDistributionFenxi(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'industrial_distribution_fenxi',
    class_name: '/chart/carrier',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 33 . 6--企业成立时间结构图
export function CompanyChartenterpriseEstablishmentTimeStructureChartFenxi(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'enterprise_establishment_time_structure_chart_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 35 . 16--标准类型结构图
export function StandardChartnumberOfAuthorizedInventionPatentsFenxi(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'standard_type_structure_diagram_fenxi',
    class_name: '/chart/standard',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// · . 19--研究机构结构图
export function InstitutionChartresearchOrganizationStructureChartFenxi(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'research_organization_structure_chart_fenxi',
    class_name: '/chart/institution',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 19 . --授权专利类型
export function PatentChartpatentTypeGraphFenxi(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'patent_type_graph_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业注册资本分布
export function CompanyChartCompanyCapitalDistributeSpecialization(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'company_capital_distribute_specialization',
    class_name: '/chart/company',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业成立时间分布
export function CompanyChartDateOfEstablishmentSpecialization(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'date_of_establishment_specialization',
    class_name: '/chart/company',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业授权专利类型分布
export function PatentChartDistributionAuthorizedPatentTypesSpecialization(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'distribution_authorized_patent_types_specialization',
    class_name: '/chart/patent',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业标准类型分布
export function StandardChartStandardTypeDistributionSpecialization(
  props: BusinessPieChartProps,
) {
  const componmentKey = {
    function_key: 'standard_type_distribution_specialization',
    class_name: '/chart/standard',
  };
  return (
    <BusinessPieChart
      {...props}
      componmentKey={componmentKey}
    />);
}
