/*
 *Author: zhaochenyu
 *Description: onlyoffice文档接口
 *Date: 2021/10/28
*/

import { get, formDataRequestPost, post, postForm, requestFormXHR } from '../utils/request';
import { ONLYOFFICE, REPORT, baseIp } from '../utils/url';

// 查询单个报告信息
const getReportMsg = param => get(`${REPORT}/find_report_info`, {
  ...param,
});

// 生成报告
const getReport = param => formDataRequestPost(`${ONLYOFFICE}/save_template_or_report`, {
  ...param,
  template_or_report: '1',
});

// 生成模板
const getTemplate = param => formDataRequestPost(`${ONLYOFFICE}/save_template_or_report`, {
  ...param,
  template_or_report: '0',
});

const downloadSystemTemplateReport = param => formDataRequestPost(`${ONLYOFFICE}/save_report_by_system_template`, {
  ...param,
});
// /industry_knowledge_engine/v2/little_giant_wise_test/testing_range/upload_testing
// 上传文件测评表格
const uploadinGappraisalTable = (param, uploadProgress) => requestFormXHR({ url: `${baseIp}/little_giant_wise_test/testing_range/upload_testing`, method: 'post', uploadProgress, data: param });

const analysisSaveReportByPic = param => formDataRequestPost('/report-engine-service/openapi/analysis/analysis_save_report_by_pic', {
  ...param,
});

const checkWordCreated = params => post(`${ONLYOFFICE}/judge_report_exit`, {
  ...params,
});

// 下载报告接口
const downloadReport = params => get(`${ONLYOFFICE}/download`, {
  ...params,
});

export {
  getReport,
  getReportMsg,
  getTemplate,
  downloadSystemTemplateReport,
  checkWordCreated,
  downloadReport,
  analysisSaveReportByPic,
  uploadinGappraisalTable,
};
