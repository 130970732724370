import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import QRcode from 'qrcode';
import { message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import PayAmountCard from '../../../components/PayAmountCard';
import SQPayAmountCard from '../../../components/SQPayAmountCard';
import ReportCover from '../../../components/ReportCover';
import {
  getSystemReportQrcode,
  getSystemReportQrcodeStatus,
  paySQcoinSystemTemplate,
} from '../../../api/apply';
import ChannelModal from '../../../components/MySelf/ChannelModal';
import style from './index.module.less';

function ReportLock({ urlReportMsg, coverScheme }) {
  const history = useHistory();
  const [amount, setAmount] = useState(18.8);
  const [applyExpired, setApplyExpired] = useState(false);
  const [successPayLoading, setSuccessPayLoading] = useState(false);
  const [sqcoin, setSqcoin] = useState(0);
  const [costSQcoin, setCostSQcoin] = useState(0);
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [channelType, setChannelType] = useState('industry');
  const [defaultSelect, setDefaultSelect] = useState('');

  const timer2 = useRef(null);
  const timer3 = useRef(null);
  const orderId = useRef(null);
  const expiredRef = useRef(null);

  const getOrderCurStatus = useCallback(() => {
    if (timer3.current) {
      clearTimeout(timer3.current);
      timer3.current = null;
    }
    getSystemReportQrcodeStatus({
      order_id: orderId.current,
      report_id: urlReportMsg.id,
    }).then(res => {
      if (res.status === '00000') {
        message.success('报告已购买成功！');
        window.location.reload();
      } else if (!expiredRef.current) {
        timer3.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    }).catch(() => {
      if (!expiredRef.current) {
        timer3.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    });
  }, []);

  const applyCountDown = useCallback(() => {
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    if (timer3.current) {
      clearTimeout(timer3.current);
      timer3.current = null;
    }
    getSystemReportQrcode({
      template_id: urlReportMsg.curTemplateId,
      report_id: urlReportMsg.id,
    }).then(res => {
      if (res.status === '00000') {
        orderId.current = res.data.order_id;
        setAmount(res.data.amount);
        setCostSQcoin(res.data.goods_sq_coin);
        setSqcoin(res.data.user_sq_coin);
        QRcode.toCanvas(res.data.code_url, { width: 50, margin: 1 }, (err, canvas) => {
          if (err) {
            console.log(err);
          }
          const contanier = document.getElementById('qrcode');
          if (contanier.childNodes[0]) {
            contanier.removeChild(contanier.childNodes[0]);
          }
          contanier.appendChild(canvas);
        });
        setApplyExpired(false);
        expiredRef.current = false;
        timer3.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
        timer2.current = setTimeout(() => {
          setApplyExpired(true);
          expiredRef.current = true;
        }, 120 * 1000);
      }
    });
  }, [getOrderCurStatus]);

  const paySuccess = () => {
    setSuccessPayLoading(true);
    paySQcoinSystemTemplate({
      report_id: urlReportMsg.id,
    }).then(res => {
      if (res.status === '00000') {
        message.success('报告已购买成功！');
        window.location.reload();
        setSuccessPayLoading(false);
      }
    });
  };

  const getCurParams = () => {
    if (urlReportMsg.area && urlReportMsg.industry) {
      return (
        <>
          {urlReportMsg.area}
          {urlReportMsg.industry}
        </>
      );
    }
    return urlReportMsg.area || urlReportMsg.industry || urlReportMsg.name || '';
  };

  useEffect(() => {
    if (urlReportMsg.industryKey) {
      setDefaultSelect(urlReportMsg.industryKey[0]);
      setChannelType('industry');
    } else if (urlReportMsg.areaKey) {
      setDefaultSelect({
        title: urlReportMsg.area,
        key: urlReportMsg.areaKey[0],
      });
      setChannelType('city');
    }
  }, [urlReportMsg]);

  useEffect(() => {
    applyCountDown();
    return () => {
      clearTimeout(timer2.current);
      clearTimeout(timer3.current);
    };
  }, []);

  return (
    <>
      <div className={style.lockCover}>
        <div className={style.lock} />
        <div className={style.desc}>
          扫码支付后查看并下载全文
          <DownOutlined />
        </div>
      </div>
      <div className={style.header}>
        <div className={style.headerContent}>
          <div className={style.reportCover}>
            <ReportCover
              coverScheme={coverScheme}
              textMap={{
                COMPANY_NAME: urlReportMsg.name,
                INDUSTRY_NAME: urlReportMsg.industry,
                AREA_NAME: urlReportMsg.area,
              }}
              scale="scale(0.31486, 0.31077)"
            />
          </div>
          <div className={style.applyMsg}>
            <div className={style.info}>
              <div className={style.title}>
                {getCurParams()}
              </div>
              <div className={style.title}>{urlReportMsg.title || ''}</div>
              <div className={style.payAmount}>
                <PayAmountCard count={amount || 0} />
                <div className={style.applyCode}>
                  <div className={style.payQrCode}>
                    {
                      applyExpired && (
                        <div className={style.qrcodeCover}>
                          二维码已失效
                        </div>
                      )
                    }
                    <div id="qrcode" className={style.qrcode} />
                  </div>
                  <div className={style.refresh} onClick={() => applyCountDown()}>
                    刷新二维码
                  </div>
                </div>
                <div className={style.SQPayAmount}>
                  <SQPayAmountCard sqcoin={sqcoin || 0} count={costSQcoin || 0} successPayLoading={successPayLoading} paySuccess={paySuccess} />
                </div>
                <div className={style.buyChannel} onClick={() => setChannelModalVisible(true)} />
              </div>
              <div className={style.portocol}>
                支付即代表您同意
                <span
                  style={{ color: '#fff', cursor: 'pointer' }}
                  onClick={() => { window.open('https://www.chanyeos.com/industry_engine_resource/industry_engine_Q_and_A_pdf/产业通用户服务协议.pdf'); }}
                >
                  《上奇用户协议》
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        useMemo(() => channelModalVisible && <ChannelModal
          defaultSelect={defaultSelect}
          type={channelType}
          onClose={() => setChannelModalVisible(false)}
          paySuccessCb={() => {
            const urlParams = new URLSearchParams();
            urlParams.set('tab', '个人中心');
            history.push({
              pathname: 'accountSetting',
              search: urlParams.toString(),
            });
          }}
        />, [channelModalVisible, setChannelModalVisible])
      }
    </>
  );
}

export default ReportLock;
