/*
* 园区详情
*/

import React, { useEffect, useMemo, useState, useRef } from 'react';
import DetailTop from './DetailTop';
import { useQuery } from '../../../../hooks/url';
import CityContent from './CityContent';
import styles from './index.module.less';

const moduleTabs = {
  经济运行: [
    { key: 1, name: '经济运行' },
  ],
  产业分析: [
    { key: 2, name: '产业概览' },
    { key: 3, name: '区域对比' },
    { key: 4, name: '产业分布' },
    { key: 5, name: '股权融资' },
    { key: 6, name: '创新能力' },
  ],
  产业载体: [{ key: 7, name: '产业载体' }],
  政策清单: [{ key: 8, name: '政策清单' }],
};

function CityDetails(props) {
  const query = useQuery();
  const { box } = props;
  const [achorFixHeight, setAchorFixHeight] = useState(0);
  const contentRef = useRef(null);
  const topRef = useRef(null);

  const { title } = useMemo(() => ({
    title: query.get('park-title'),
  }), [query]);
  const { code } = useMemo(() => ({
    code: window.atob(query.get('park-code')),
  }), [query]);
  const heightListener = () => {
    const a = document.getElementById('cityAchorTab')?.offsetTop;
    if (a) {
      setAchorFixHeight(a);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(heightListener);
    resizeObserver.observe(topRef.current);
  }, []);

  const parkDetailTop = useMemo(() => (
    <DetailTop title={title} />
  ), [title]);

  const parkDetailContent = useMemo(() => (
    Object.keys(moduleTabs).length > 0 && (
      <CityContent
        fixHeight={achorFixHeight}
        tabs={moduleTabs}
        ref={contentRef}
        parkCode={code}
        box={box}
      />
    )
  ), [box, moduleTabs, achorFixHeight, code]);

  return (
    <div className={styles.box} ref={topRef}>
      {parkDetailTop}
      {parkDetailContent}
    </div>
  );
}

export default CityDetails;
