/*
 *Author: zhaochenyu
 *Description: 专题页面
 *Date: 2022/06/27
*/

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/url';
import SpecializedAndNew from '../../components/Special/SpecializedAndNew';
import { useLazyFindSpecialTopicCodeStatusQuery } from '../../service/special';
import EnterpriseSpecialTopic from '../../components/Special/EnterpriseSpecialTopic';
import style from './index.module.less';

function Special() {
  const [jurisdiction, setJurisdiction] = useState(null);
  const [findSpecialTopicCodeStatusQuery] = useLazyFindSpecialTopicCodeStatusQuery();
  const query = useQuery();
  const history = useHistory();
  const name = decodeURIComponent(atob(query.get('name')));
  const code = atob(query.get('code'));
  useEffect(() => {
    setJurisdiction(null);
    if (code) {
      findSpecialTopicCodeStatusQuery({ code }).then(res => {
        if (res.data) {
          setJurisdiction(res.data);
        } else {
          history.push('/homePage');
        }
      });
    }
  }, [code]);
  return (
    <>
      {
        jurisdiction && (
          <div className={style.all}>
            {
              name === '专精特新' ? <SpecializedAndNew /> : <EnterpriseSpecialTopic name={name} />
            }
          </div>
        )
      }
    </>
  );
}

export default Special;
