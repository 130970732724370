import { IBarVisualConfig } from '../interface/config';
import { getDataItem, omitUndefined } from './utils';
import { ISeriesGetter, SeriesTypes } from '../interface/base';
import { OptionParser } from './optionParser';
import { merge } from 'lodash';
import { IDataItem, IDataItemObject } from '../interface/data';

const HIGHLIGHTCOLOR = '#FD6205';

const getEach =  (series: any) => {
  const {
    // 仅在 series 层生效
    colorBy,
    labelLine,
    barWidth,
    barMaxWidth,
    markPoint,
    markLine,
    markArea,
    stack,
    // series 层和 dataItem 层都可使用
    symbol,
    symbolSize,
    symbolOffset,
    label,
    lineStyle,
    itemStyle,
  } = series;

  return omitUndefined({
    colorBy,
    labelLine,
    barWidth,
    barMaxWidth,
    markPoint,
    markLine,
    markArea,
    stack,
    symbol,
    symbolSize,
    symbolOffset,
    label,
    lineStyle,
    itemStyle,
  });
}

export const getBarSeries: ISeriesGetter<IBarVisualConfig> = (series, index, extra) => {
  const type: SeriesTypes = (series.type as SeriesTypes) || 'bar';
  const {
    material,
    optionParser,
    getMatchedItemStyle,
  } = extra;
  const { config, data: _data } = material;
  // 高亮
  const highlight = _data?.highlight?.[0];
  const seat = _data?.x?.indexOf(highlight);

  const { visualConfig } = config;
  const { series: seriesStyles, coordinateSystem, seriesType } = visualConfig;
  const seriesStyle = seriesStyles ? seriesStyles[index]: undefined;
  const { name, data, xAxisIndex, yAxisIndex, polarIndex, geoIndex }  = series;
  const seriesProperty = {
    seriesName: name,
    seriesIndex: index,
  };
  const seriesItem = {
    name,
    type,
    coordinateSystem,
    xAxisIndex,
    yAxisIndex,
    polarIndex,
    geoIndex,
  };
  // TODO：注意 coordinateSystem 为 geo 的情况如何进行条件匹配
  // 类目轴 [['x'],['y']]
  const categoryAxis = Object.values(optionParser.getAxis(seriesItem as any)).filter(OptionParser.isAxisCategory);
  const seriesData: any = data.map((d, index) => {
    const item = getDataItem(d);
    const _d = {
      ...seriesProperty,
      type,
      category: categoryAxis.length !== 0 ? categoryAxis[0].data[index] as string : undefined,
      ...(item as IDataItemObject),
      index,
    };
    // TODO: 高亮,应该可以通过下面的jsonLogic实现,暂时先这样来
    if (seat === index) {
      return ({
        ...item,
        highlight: true,
        itemStyle: {
          color: HIGHLIGHTCOLOR,
        },
      });
    }

    if (series?.data_name) {
      return ({
        ...item,
        name: series?.data_name?.[index],
        label: {
          show: true,
          formatter: '{b}',
          color: '#000',
        },
      });
    }

    const matcherStyle = getMatchedItemStyle(_d);
    if (Object.keys(matcherStyle).length === 0) {
      return d;
    }
    return ({
      ...item,
      ...getEach(matcherStyle),
    });
  });

  // 高亮的markPoint
  let markPoint: any;
  if (typeof(seat) === 'number' && seat > -1) {
    const value = seriesData?.[seat].value;
    markPoint = {
      data: [{
        value: value ,
        xAxis: seat,
        yAxis: value,
      }],
      itemStyle: {
        color: HIGHLIGHTCOLOR,
      },
      label: {
        textBorderColor: HIGHLIGHTCOLOR,
        textBorderWidth: 3,
      },
    };
  }

  // 柱线图配置
  if (Array.isArray(seriesType)) {
    seriesItem.type = seriesType[index] ?? 'bar';
  }

  return omitUndefined(
    merge(
      seriesItem,
      { markPoint },
      getEach(visualConfig),
      { data: seriesData },
      seriesStyle ? getEach(seriesStyle) : {},
    )
  );
}
