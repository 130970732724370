/*
* Author: zhangmeng
* Description: '付费引导弹窗'
* Date: 2021/02/24
*/

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Tabs, Modal } from 'antd';
import QRcode from 'qrcode';
import { IconFont } from '../../utils/mapIcon';
import Vipinfo from '../Vip/VipInfo';
import hlsj from '../../assets/images/upgrade/hlsj.png';
import cytp from '../../assets/images/upgrade/cytp.png';
import zszj from '../../assets/images/upgrade/zszj.png';
import zxbj from '../../assets/images/upgrade/zxbj.png';
import jpbg from '../../assets/images/upgrade/jpbg.png';
import gxct from '../../assets/images/upgrade/gxct.png';
import dtjc from '../../assets/images/upgrade/dtjc.png';
import diybg from '../../assets/images/upgrade/diybg.png';
import PayAmountCardVip from './PayAmountCardVip';
import PayAmountCard from './PayAmountCard';
import { getQrCode, getOrderStatus, getVipPrice } from '../../api/apply';
import UpGradeSurvey from '../Vip/UpgradeSurvey';
import style from './index.module.less';

const { TabPane } = Tabs;

export const hoverCnt = [
  {
    name: '海量数据',
    img: hlsj,
  },
  {
    name: '产业图谱',
    img: cytp,
  },
  {
    name: '知识组件',
    img: zszj,
  },
  {
    name: '在线编辑',
    img: zxbj,
  },
  {
    name: '精品报告',
    img: jpbg,
  },
  {
    name: '关系穿透',
    img: gxct,
  },
  {
    name: '动态监测',
    img: dtjc,
  },
  {
    name: 'DIY报告',
    img: diybg,
  },
];

function UpgradeWindow(props) {
  const { upgrade, close, children, openNewReportPage, modalType, conditions, scroll,
    upType, successPay, industryChain, accountUpType, expiredAccount, componentFrom } = props;
  const [activeKey, setActiveKey] = useState('1');
  const [applyExpired, setApplyExpired] = useState(false);
  const [amount, setAmount] = useState(9.9);
  const timer = useRef(null);
  const timer2 = useRef(null);
  const orderId = useRef(null);
  const firstCheckApply = useRef(true);
  const expiredRef = useRef(null);
  const [upGradeShow, setUpgradeShow] = useState(false);
  const [vipPrice, setVipPrice] = useState({});
  const [detailShow, setDetailShow] = useState(false);

  const getOrderCurStatus = useCallback(() => {
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    getOrderStatus({
      order_id: orderId.current,
      ...conditions.params,
    }).then(res => {
      if (res.status === '00000') {
        Modal.success({
          centered: true,
          content: <div style={{ fontSize: '20px', lineHeight: 1 }}>已成功支付</div>,
          okText: '去查看报告',
          keyboard: false,
          maskStyle: {
            background: 'transparent',
          },
          onOk: () => {
            close();
            openNewReportPage(modalType, res.data, conditions.conditions);
          },
          onCancel: () => {
            close();
            openNewReportPage(modalType, res.data, conditions.conditions);
          },
          width: 450,
        });
      } else if (!expiredRef.current) {
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    }).catch(() => {
      if (!expiredRef.current) {
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    });
  }, [modalType, conditions, openNewReportPage]);

  const applyCountDown = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    getQrCode().then(res => {
      if (res.status === '00000') {
        orderId.current = res.data.order_id;
        setAmount(res.data.amount);
        QRcode.toCanvas(res.data.code_url, { width: 180, margin: 0.5 }, (err, canvas) => {
          if (err) {
            console.log(err);
          }
          const contanier = document.getElementById('qrcode');
          if (contanier.childNodes[0]) {
            contanier.removeChild(contanier.childNodes[0]);
          }
          contanier.appendChild(canvas);
        });
        setApplyExpired(false);
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
        timer.current = setTimeout(() => {
          setApplyExpired(true);
        }, 120 * 1000);
      }
    });
  }, [getOrderCurStatus]);
  useEffect(() => {
    expiredRef.current = applyExpired;
  }, [applyExpired]);

  useEffect(() => {
    if (upType === 'up' || (upType === 'report' && accountUpType === 'online') || accountUpType === 'expired') {
      setActiveKey('1');
    } else if (upType === 'report' && accountUpType === 'none') {
      setActiveKey('2');
    }
    return () => {
      expiredRef.current = true;
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  useEffect(() => {
    getVipPrice().then(res => {
      if (res.status === '00000') {
        setVipPrice(res.data);
      }
    });
    if (activeKey === '2' && firstCheckApply.current) {
      applyCountDown();
      firstCheckApply.current = false;
    }
  }, [activeKey]);

  return (
    <div
      className={`${style.all} ${upType === 'up' && accountUpType !== 'expired' && style.tableUp}`}
      style={{ paddingTop: scroll ? `calc(100px + ${window.scrollY}px)` : '100px' }}
    >
      <Tabs
        type="card"
        style={{ height: (accountUpType === 'online' || accountUpType === 'expired') ? `${children ? '580px' : '540px'}` : `${children ? '525px' : '485px'}` }}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        {(upType === 'up' || (upType === 'report' && accountUpType === 'online')) && (
        <TabPane tab="升级账号" key="1">
          <header>
            <CloseOutlined onClick={() => close()} style={{ visibility: close ? 'visible' : 'hidden' }} />
          </header>
          <div className={style.top} style={{ height: children ? '94px' : '54px' }}>
            <div className={style.tTop} style={{ display: children ? 'block' : 'none' }}>{children}</div>
            <div className={style.tBottom}>
              {accountUpType === 'expired' ? '您的账号已过期' : '升级账号，享受更多权益'}
              <span onClick={() => setDetailShow(true)}>
                <IconFont type="iconquestion-circle" style={{ color: '#D5671D', cursor: 'pointer' }} />
              </span>
            </div>
          </div>
          <div className={style.middle}>
            {
              hoverCnt.map(item => (
                <div className={style.itemBox} key={item.name}>
                  <div className={style.icon}>
                    <img src={item.img} alt={item.name} />
                  </div>
                  <div className={style.name}>{item.name}</div>
                </div>
              ))
            }
          </div>
          {(accountUpType === 'online' || accountUpType === 'expired') && (<PayAmountCardVip
            count={vipPrice.price || 99}
            orignalCount={vipPrice.originalPrice || 1999}
          />)}
          <div className={style.bottom}>
            <div
              className={style.upgradeBtn}
              onClick={() => {
                if (accountUpType === 'online' || accountUpType === 'expired') {
                  setUpgradeShow(true);
                } else if (accountUpType === 'contactCustomerService') {
                  upgrade();
                }
              }}
            >
              <span>立即升级</span>
            </div>
            <div className={style.serviceTel}>客服联系电话 172-0030-9680</div>
          </div>
        </TabPane>)}
        {upType === 'report' && (
        <TabPane tab="单个购买" key="2">
          <header>
            <CloseOutlined onClick={() => close()} style={{ visibility: close ? 'visible' : 'hidden' }} />
          </header>
          <div className={style.tTop} style={{ display: children ? 'block' : 'none' }}>{children}</div>
          <div className={style.applyQrCode}>
            <div className={style.qrcodeCover} style={{ display: applyExpired ? '' : 'none' }}>
              二维码已失效
            </div>
            <div id="qrcode" className={style.qrcode} />
          </div>
          <div className={style.refresh} onClick={() => applyCountDown()}>
            <IconFont type="iconsync" style={{ marginRight: '4px' }} />
            刷新二维码
          </div>
          <PayAmountCard count={amount} />
          <div className={style.desc}>
            支付即代表您同意
            <span
              style={{ color: '#3E5C76', cursor: 'pointer' }}
              onClick={() => { window.open('https://www.chanyeos.com/industry_engine_resource/industry_engine_Q_and_A_pdf/产业通用户服务协议.pdf'); }}
            >
              《用户协议》
            </span>
          </div>
          <div className={style.desc1}>联系客服开具发票</div>
          <div className={style.desc2}>客服联系电话 172-0030-9680</div>
        </TabPane>)}
      </Tabs>
      {upGradeShow && (
      <Modal
        footer={null}
        maskStyle={{ background: industryChain ? 'rgba(62, 92, 118, 0.79)' : 'transparent' }}
        keyboard={false}
        maskClosable={false}
        visible={upGradeShow}
        closable={false}
        wrapClassName={style.questionModal}
        width={760}
        centered
      >
        <UpGradeSurvey
          componentFrom={componentFrom}
          successPay={successPay}
          expiredAccount={expiredAccount}
        />
      </Modal>)}
      {detailShow && (
      <Modal
        footer={null}
        maskStyle={{ background: 'transparent' }}
        keyboard={false}
        maskClosable={false}
        visible={detailShow}
        onCancel={() => setDetailShow(false)}
        wrapClassName={style.msgModal}
        width={1280}
        centered
      >
        <Vipinfo />
      </Modal>)}
    </div>
  );
}

export default UpgradeWindow;
