/*
* Author: zhangmeng
* Description: 'tab栏'
* Date: 2022/06/29
*/

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../hooks/url';
import style from './index.module.less';
import { useGetEveryCountQuery } from '../../../../service/dataSearch';

// const tabArr = ['全部', '企业', '专利', '投资', '政策', '舆情', '研报'];
function Tabs(props) {
  const { setTabSelected, participle } = props;
  const [selected, setSelected] = useState('全部');
  const history = useHistory();
  const query = useQuery();
  const tabIndex = query.get('page');
  const { data: list } = useGetEveryCountQuery({
    keyword: participle.join(''),
  });

  const tabClick = p => {
    setSelected(p);
    setTabSelected(p);
    switch (p) {
      case '全部':
        query.set('page', 'all');
        history.push({ search: query.toString() });
        break;
      case '企业':
        query.set('page', 'company');
        history.push({ search: query.toString() });
        break;
      case '产业':
        query.set('page', 'industryChart');
        history.push({ search: query.toString() });
        break;
      case '专利':
        query.set('page', 'patent');
        history.push({ search: query.toString() });
        break;
      case '投资':
        query.set('page', 'investment');
        history.push({ search: query.toString() });
        break;
      case '政策':
        query.set('page', 'policy');
        history.push({ search: query.toString() });
        break;
      case '舆情':
        query.set('page', 'publicSentiment');
        history.push({ search: query.toString() });
        break;
      case '研报':
        query.set('page', 'researchReport');
        history.push({ search: query.toString() });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    switch (tabIndex) {
      case 'company':
        setSelected('企业');
        break;
      case 'industryChart':
        setSelected('产业');
        break;
      case 'patent':
        setSelected('专利');
        break;
      case 'investment':
        setSelected('投资');
        break;
      case 'policy':
        setSelected('政策');
        break;
      case 'publicSentiment':
        setSelected('舆情');
        break;
      case 'researchReport':
        setSelected('研报');
        break;
      default:
        setSelected('全部');
        break;
    }
  }, [tabIndex]);

  return (
    <div className={style.all}>
      <div className={`${selected === '全部' ? style.selected : style.tab}`} onClick={() => tabClick('全部')}>
        <span className={style.name}>全部</span>
        <span className={style.bar} />
      </div>
      {
        list && list.length > 0 && list.map(item => (
          <div
            key={item.key}
            className={`${selected === (item.key === 'financing' ? '投资' : item.name) ? style.selected : style.tab} ${!item.is_have_data && style.disabled}`}
            onClick={() => item.is_have_data && tabClick(item.key === 'financing' ? '投资' : item.name)}
          >
            <span className={style.name}>{item.key === 'financing' ? '投资' : item.name}</span>
            <span className={style.bar} />
          </div>
        ))
      }
    </div>
  );
}

export default Tabs;
