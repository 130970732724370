import { get, post, requestFile } from '../utils/request';
import { REPORT, USERCUSTOM, PURCHASEINDUSTRY, PUCHASECITY, WX2, SQCOIN, DATAOWNLOAD, baseIp } from '../utils/url';

// 获取已生成的报告
const getGenerateReport = param => get(`${REPORT}/report_my_list`, {
  ...param,
});

// 企业账号数据下载历史
const getExportReport = param => post(`${DATAOWNLOAD}/org_data_download_list`, {
  ...param,
});

// 统一的下载接口
// const dataDownload = param => post(`${DATAOWNLOAD}/data_download`, {
//   ...param,
// }, true);

// 统一的下载接口
const dataDownload = (param, name) => requestFile('post', `${DATAOWNLOAD}/data_download`, {
  ...param,
}, `${name}.xlsx`, false, {
  'Content-Type': 'application/json',
});
// 管理员下载接口
const administratorsDataDownload = (param, name) => requestFile('post', `${DATAOWNLOAD}/search_company_list_download`, {
  ...param,
}, `${name}.xlsx`, false, {
  'Content-Type': 'application/json',
});

// 下载已生成的报告
const downloadGenerateReport = param => get(`${REPORT}/add_download_record`, {
  ...param,
});

// 删除报告
const deleteGenerateReport = param => post(`${REPORT}/delete_report`, {
  ...param,
});

// 修改报告名称
const editReportName = param => post(`${REPORT}/update_report_info`, {
  ...param,
});

// 查询用户关注分组数据
const getCurFollowTree = () => get(`${USERCUSTOM}/find_attention_group_tree`, {
});

// 修改分组名称
const editFollowGroup = param => post(`${USERCUSTOM}/update_attention_group`, {
  ...param,
});

// 新增分组名称
const addFollowGroup = param => post(`${USERCUSTOM}/add_attention_group`, {
  ...param,
});

// 删除分组
const delFollowGroup = param => post(`${USERCUSTOM}/delete_attention_group`, {
  ...param,
});

// 获取用户关注分组的表格数据
const getFollowTableData = param => post(`${USERCUSTOM}/find_attention_data`, {
  ...param,
});

// 取消关注
const cancelFollowGroup = param => post(`${USERCUSTOM}/delete_attention_data`, {
  ...param,
});

// 添加关注
const addFollow = param => post(`${USERCUSTOM}/add_attention_data_group_list`, {
  ...param,
});

// 获取关注对象所在的分组数据
const getFollowDataGroupData = param => get(`${USERCUSTOM}/find_attention_group_list`, {
  ...param,
});

// 我的-笔记表格
const myNoteTable = params => get(`${USERCUSTOM}/my_node_list`, {
  ...params,
});

// 我的-标签表格
const myLabelTable = params => get(`${USERCUSTOM}/my_tags_list`, {
  ...params,
});

// 我的-动态监测筛选条件
const getMonitorCondition = () => post(`${USERCUSTOM}/dynamic_monitoring_menu`, {
});

// 我的-动态监测
const getMonitorDymic = params => post(`${USERCUSTOM}/dynamic_monitoring`, {
  ...params,
});

// 我的-动态监测-展开
const getDetailMonitor = params => post(`${USERCUSTOM}/company_dynamic_monitoring`, {
  ...params,
});

// 动态监测搜索企业
const getSearchCompany = params => post(`${USERCUSTOM}/dynamic_monitoring_search`, {
  ...params,
});

// 用户权益列表
const getAttentionIndustry = params => post(`${PURCHASEINDUSTRY}/my_attention_industry`, {
  ...params,
});

// 查询可购买产业列表
const getIndustryListCanBuy = params => get(`${PURCHASEINDUSTRY}/find_industry_list_can_buy`, {
  ...params,
});

// 购买产业二维码
const getBuyIndustryQrCode = params => post(`${PURCHASEINDUSTRY}/buy_industry_qr_code`, {
  ...params,
});

// 购买产业轮询接口
const getTradeOrderStatus = params => post(`${PURCHASEINDUSTRY}/find_trade_status_on_pay_industry`, {
  ...params,
});

// 查询可购买城市列表按城市
const getCityListCanBuy = params => get(`${PUCHASECITY}/find_city_list_can_buy`, {
  ...params,
});

// 查询可购买城市列表按省份
const getProvinceListCanBuy = params => get(`${PUCHASECITY}/find_city_list_can_buy_province_group`, {
  ...params,
});

// 购买城市二维码
const getBuyCityQrCode = params => post(`${PUCHASECITY}/buy_city_qr_code`, {
  ...params,
});

// 购买城市轮询接口
const getCityTradeOrderStatus = params => post(`${PUCHASECITY}/find_trade_status_on_pay_city`, {
  ...params,
});

// 查询用户上奇币数量
const getUserSQcoin = params => post(`${SQCOIN}/find_user_sq_coin_num`, {
  ...params,
});

// 查询用户上奇币数量-数值
const getUserSQcoinNum = params => post(`${SQCOIN}/sq_coin_num`, {
  ...params,
});

// 任务列表
const getTaskList = params => get(`${baseIp}/task/find_task_list`, {
  ...params,
});

// 上奇币明细
const getSQcoinList = params => post(`${SQCOIN}/find_user_sq_coin_record_list`, {
  ...params,
});

// 上奇币购买产业频道接口
const paySQcoinIndustry = params => post(`${SQCOIN}/SQCoin_pay_industry_channel`, {
  ...params,
});

// 上奇币购买产城市频道接口
const paySQcoinCity = params => post(`${SQCOIN}/SQCoin_pay_city_channel`, {
  ...params,
});

// 轮训关注服务号任务
const attentionServiceNumber = params => post(`${WX2}/attention_service_number`, {
  ...params,
});

export {
  getSearchCompany,
  getDetailMonitor,
  getMonitorCondition,
  getMonitorDymic,
  myNoteTable,
  myLabelTable,
  getCurFollowTree,
  editFollowGroup,
  addFollowGroup,
  delFollowGroup,
  getFollowTableData,
  cancelFollowGroup,
  addFollow,
  getFollowDataGroupData,
  getGenerateReport,
  getExportReport,
  downloadGenerateReport,
  deleteGenerateReport,
  editReportName,
  getAttentionIndustry,
  getIndustryListCanBuy,
  getBuyIndustryQrCode,
  getTradeOrderStatus,
  getCityListCanBuy,
  getProvinceListCanBuy,
  getBuyCityQrCode,
  getCityTradeOrderStatus,
  getUserSQcoin,
  getUserSQcoinNum,
  getTaskList,
  getSQcoinList,
  paySQcoinIndustry,
  paySQcoinCity,
  dataDownload,
  attentionServiceNumber,
  administratorsDataDownload,
};
