/**
* Author: tianye
* Description: 书本状卡片,
* Date: 2020/11/30
* */

import React, { forwardRef, useCallback, useRef, useState } from 'react';
import style from './index.module.less';
// import areaback from '../../assets/images/report/areaReport.png';
// import industryBack from '../../assets/images/report/industryReport.png';
// import compnayBack from '../../assets/images/report/companyReport.png';
// import countryBack from '../../assets/images/report/country.png';
// import industryCountryback from '../../assets/images/report/industryCountry.png';
// import investReport from '../../assets/images/report/investReport.png';
// import preciseInvest from '../../assets/images/report/preciseInvest.png';
// import equityInvest from '../../assets/images/report/equityInvestment.png';
// import systemLogo from '../../assets/images/report/systemLogo.png';
// import countryLogo from '../../assets/images/report/countryLabel.png';
// import { IconFont } from '../../utils/mapIcon';

// const backMap = {
//   16: areaback,
//   17: industryBack,
//   18: compnayBack,
//   22: industryCountryback,
//   23: countryBack,
//   498: equityInvest,
//   499: preciseInvest,
//   500: investReport,
// };

// const BookCard = forwardRef((props, ref) => {
//   const { empty, title, subtitle, heading, className, buttons, onClick,
//     onClickCapture, onDownload, onDelete, moreOperator, onMouseLeave, moreClick,
//     showDownload, info: { id }, system, homePage } = props;

//   return (
//     <div
//       className={`${style.mainContainer} ${className}`}
//       onClick={onClick}
//       onClickCapture={onClickCapture}
//       ref={ref}
//       onMouseLeave={onMouseLeave}
//     >
//       <div
//         className={`${empty ? style.emptyCard : style.card} ${backMap[id] && style.backNone} ${homePage && style.homePageCard}`}
//       >
//         {system && <img alt="" src={systemLogo} className={style.systemLogo} />}
//         {system && (id === 22 || id === 23) && <img alt="" src={countryLogo} className={style.countryLogo} />}
//         <div className={style.cardBack} style={{ background: `url(${backMap[id]})`, backgroundSize: '100% 100%' }}>
//           {!backMap[id] && (
//           <>
//             <div className={style.heading}>{heading}</div>
//             <div className={style.title}>{title}</div>
//             <div className={style.subtitle}>{subtitle}</div>
//           </>)}
//         </div>
//       </div>
//       <div className={`${style.cardCover} ${homePage && style.homePageCardHover}`}>
//         {buttons}
//       </div>
//       <div
//         className={style.more}
//         onClick={(e) => {
//           e.stopPropagation();
//           moreClick();
//         }}
//         style={{ display: moreOperator ? '' : 'none' }}
//       >
//         <IconFont type="iconellipsis" />
//       </div>
//       <div className={style.moreEdit} style={{ display: showDownload ? '' : 'none' }}>
//         <div className={style.button} onClick={onDelete}>删除</div>
//         <div className={style.button} onClick={onDownload}>下载</div>
//       </div>
//     </div>
//   );
// });

// BookCard.defaultProps = {
//   title: '无报告',
//   heading: '系统模板',
//   subtitle: '1970-10-10',
// };

// function EmptyCard(props) {
//   const { title, buttonText, onButtonClick, className, hideButton } = props;
//   const ref = useRef(null);

//   return (
//     <div
//       className={`${style.mainContainer} ${className}`}
//       ref={ref}
//     >
//       <div className={`${style.emptyCard}`}>
//         <div className={style.title}>{title}</div>
//         {buttonText && (
//         <div className={`${style.button} ${hideButton ? style.hidden : ''}`} onClick={() => onButtonClick()}>
//           {buttonText}
//         </div>
//         )}
//       </div>
//     </div>
//   );
// }

// EmptyCard.defaultProps = {
//   title: '您还未生成任何报告，\n快去试试吧',
//   // buttonText: '去搜索',
//   hideButton: false,
// };

// function SystemCard(props) {
//   const { title, subtitle, onView, onChose, className, info, onClick,
//     onClickCapture, homePage } = props;
//   const ref = useRef(null);

//   const click = useCallback(
//     e => onClick && onClick(e, { info, container: ref.current }),
//     [info, onClick],
//   );

//   const clickCapture = useCallback(
//     e => onClickCapture && onClickCapture(e, { info, container: ref.current }),
//     [info, onClickCapture],
//   );

//   return <BookCard
//     className={`${style.systemCard} ${className || ''}`}
//     heading="系统模板"
//     title={title}
//     system
//     subtitle={subtitle}
//     homePage={homePage}
//     info={info}
//     buttons={(
//       <>
//         <RectButton
//           className={style.cardButton}
//           onClick={onView}
//         >
//           样例预览
//         </RectButton>
//         <RectButton
//           className={style.cardButton}
//           onClick={onChose}
//         >
//           复用模板
//         </RectButton>
//       </>
//     )}
//     onClick={click}
//     onClickCapture={clickCapture}
//     ref={ref}
//   />;
// }

// function DIYCard(props) {
//   const {
//     title, subtitle, onView,
//     onDownload, onRemove, className,
//     info, onClick, onClickCapture, onEdit,
//   } = props;
//   const ref = useRef(null);
//   const [showDownload, setDownload] = useState(false);

//   // const downloadpdf = useCallback(
//   //   e => onDownload && onDownload(e, { info, container: ref.current }),
//   //   [info, onDownload],
//   // );

//   const remove = useCallback(
//     e => onRemove && onRemove(e, { info, container: ref.current }),
//     [info, onRemove],
//   );

//   const click = useCallback(
//     e => onClick && onClick(e, { info, container: ref.current }),
//     [info, onClick],
//   );

//   const clickCapture = useCallback(
//     e => onClickCapture && onClickCapture(e, { info, container: ref.current }),
//     [info, onClickCapture],
//   );

//   return <BookCard
//     className={`${style.diyCard} ${className || ''}`}
//     heading="DIY模板"
//     title={title}
//     subtitle={subtitle}
//     moreOperator
//     onMouseLeave={() => setDownload(false)}
//     moreClick={() => setDownload(true)}
//     showDownload={showDownload}
//     onDownload={onDownload}
//     onDelete={remove}
//     info={info}
//     buttons={(
//       <>
//         {/* <RectButton
//           className={style.cardButton}
//           onClick={view}
//         >
//           查看编辑
//         </RectButton> */}
//         <RectButton
//           className={style.cardButton}
//           onClick={onEdit}
//         >
//           修改组件
//         </RectButton>
//         <RectButton
//           className={style.cardButton}
//           onClick={onView}
//         >
//           复用模板
//         </RectButton>
//         {/* <RectButton
//           className={style.cardButton}
//           icon={<IconFont type="iconcloud-download" />}
//           // onClick={() => onDownload('pdf')}
//           onClick={() => setDownload(true)}
//         >
//           修改组件
//           <div
//             className={style.showCard}
//             style={{ display: showDownload ? '' : 'none' }}
//           >
//             {['下载Word', '下载PDF'].map((item, index) => (
//               <div
//                 className={style.showCardItem}
//                 onClick={(e) => {
//                   onDownload(item);
//                   setDownload(false);
//                   e.stopPropagation();
//                 }}
//                 key={`${index + item}`}
//               >
//                 {item}
//               </div>
//             ))}
//           </div>
//         </RectButton> */}
//         {/* <RectButton
//           className={style.cardButton}
//           icon={<IconFont type="iconcloud-download" />}
//           onClick={() => onDownload('word')}
//         >
//           下载WORD
//         </RectButton> */}
//       </>
//     )}
//     onClick={click}
//     onClickCapture={clickCapture}
//     ref={ref}
//   />;
// }

function BoutiqueCard(props) {
  const { homePage, isApply, onView, onDownload, className, coverImg } = props;

  return (
    <div
      className={`${style.mainContainer} ${style.systemCard} ${className || ''}`}
      onClick={onView}
    >
      <div
        className={`${style.card} ${coverImg && style.backNone} ${homePage && style.homePageCard}`}
      >
        <div className={style.cardBack} style={{ background: `url(${coverImg})`, backgroundSize: '100% 100%' }} />
      </div>
      {/* <div className={`${style.cardCover} ${homePage && style.homePageCardHover}`}>
        {isApply ? (
          <div className={`${style.lockLayout} ${homePage && style.homePageLock}`} onClick={onView}>
          </div>
        ) : (
          <div className={`${style.lockLayout} ${homePage && style.homePageLock}`}>
            <div className={style.lock} onClick={onView} />
          </div>
        )}
      </div> */}
    </div>
  );
}

function OtherCard(props) {
  const { onView, className, background } = props;

  return (
    <div
      className={`${style.mainContainer} ${style.systemCard} ${className || ''}`}
      onClick={onView}
    >
      <div
        className={`${style.card} ${background && style.specialCard}`}
      >
        <div className={style.cardBack} style={{ background: background ? `url(${background})` : 'none', backgroundSize: '100% 100%' }} />
      </div>
      {/* <div className={`${style.cardCover}`}>
        <RectButton
          className={style.cardButton}
          onClick={onView}
        >
          打开查看
        </RectButton>
      </div> */}
    </div>
  );
}

export { BoutiqueCard, OtherCard };
