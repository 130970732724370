import React, { useEffect, useReducer, useState } from 'react';
import aiIcon from '../../../../../../assets/images/dataAnalysisTool/aiIcon.png';
import ContentBox from './ContentBox';
import styles from './index.module.less';

export default function IntelligentWriting({ data, describeObj, setIntelligentWriting, refreshAble, setRefreshAble }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (data) {
      const writeList = data.filter(item => item.meta_data?.type === 'data_analysis');
      setList(writeList);
    }
  }, [data]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        智能撰写
        <img src={aiIcon} alt="" />
      </div>
      {
        list?.[0]?.child?.length > 0 && list[0].child.map((item, index) => (
          <ContentBox
            key={index}
            node={item}
            describeObj={describeObj}
            setIntelligentWriting={setIntelligentWriting}
            refreshAble={refreshAble}
            setRefreshAble={setRefreshAble}
          />
        ))
      }
    </div>
  );
}
