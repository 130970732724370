import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import { Radio, Space, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useGetInvestmentPenetrationQuery } from '../../../../../../service/companyDetail';
import { FilterIcon } from '../../../../../CustomTableIcon';
import { openNewPage } from '../../../../../../utils/urlMethods';
import style from './index.module.less';

function InvestPenetrateTable(props) {
  const { companyId, preventPageto } = props;
  const [sortValue, setSortValue] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState([0]);
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const updateLevelParams = () => {
    if (filteredInfo[0] === 0) {
      return [1, 2, 3];
    }
    return filteredInfo;
  };
  const { data: tableData, isFetching: isTableFetching } = useGetInvestmentPenetrationQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
    penetration_grade: filteredInfo.length === 1 ? updateLevelParams() : filteredInfo,
    sort_value: sortValue,
  }, {
    skip: !companyId,
  });
  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'investee') {
        if (preventPageto) {
          return {
            ...item,
            width: 350,
          };
        }
        return {
          ...item,
          width: 350,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('companyDetail', { id: record.investee_id })}
            >
              {text}
            </div>
          ),
        };
      }
      if (item.dataIndex === 'investor') {
        if (preventPageto) {
          return {
            ...item,
            width: 350,
          };
        }
        return {
          ...item,
          width: 350,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('companyDetail', { id: record.investor_id })}
            >
              {text}
            </div>
          ),
        };
      }
      if (item.dataIndex === 'level') {
        return {
          ...item,
          width: 130,
          filteredValue: filteredInfo,
          filterIcon: <FilterIcon />,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, filters, clearFilters }) => (
            <div className={style.list}>
              <Radio.Group
                value={selectedKeys[0]}
                options={filters}
                style={{ display: 'block' }}
                onChange={e => {
                  setSelectedKeys([e.target.value]);
                  confirm();
                }}
              >
                <Space direction="vertical">
                  {
                    filters.map(it => <Radio key={it.value} value={it.value}>{it.label}</Radio>)
                  }
                </Space>
              </Radio.Group>
            </div>
          ),
          filters: [{ label: '不限', value: 0, text: '不限' }, { label: '二级', value: 2, text: '二级' }, { label: '三级', value: 3, text: '三级' }],
          filterResetToDefaultFilteredValue: true,
        };
      }
      if (item.dataIndex === 'establish_date') {
        return {
          ...item,
          width: 120,
        };
      }
      if (item.dataIndex === 'legal_person') {
        return {
          ...item,
          width: 120,
          // sorter: true,
          ellipsis: true,
        };
      }
      return {
        ...item,
        ellipsis: true,
      };
    })
  ), [tableData, preventPageto, filteredInfo]);

  const table = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return <Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={tableColumns}
        title={() => (
          <>
            <span className={style.tableTitle}>{tableData?.title}</span>
            <Popover content='穿透该企业2、3级子公司情况' placement="right" overlayClassName={style.pop}>
              <QuestionCircleOutlined />
            </Popover>
            <span className={style.count}>{tableData?.count}</span>
          </>
        )}
        strip
        hideOnSinglePage
        locale={{ emptyText: ' ' }}
        loading={isTableFetching}
        pagination={{
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }, filters, stort) => {
          if (stort.order) {
            setSortValue('desc');
          } else {
            setSortValue(null);
          }
          if (filters.level) {
            setFilteredInfo(filters.level);
          }

          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />;
    }
    return null;
  }, [tableData, isTableFetching]);

  return table;
}

export default InvestPenetrateTable;
