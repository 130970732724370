/*
 *Author: zhaochenyu
 *Description: 轮播列表
 *Date: 2022/05/12
*/

import React, { useCallback, useState, useRef, useEffect } from 'react';
import { openNewPage } from '../../../../utils/urlMethods';
import { IconFont } from '../../../../utils/mapIcon';
import style from './index.module.less';

const titleMap = {
  new: '最新动态',
  policy: '相关政策',
};

function CarouselList(props) {
  const { data, speed = 3000, showAmount = 11, type, more = true } = props;
  const [tableData, setTableData] = useState([]);
  const [rowHeight, setHeight] = useState(0);
  const [mouseStatue, setMouse] = useState(false);
  const timer = useRef(null);
  const timer1 = useRef(null);
  const curData = useRef(null);
  const curHeight = useRef(10);
  const curWidth = useRef(10);
  const carouselBody = useRef(null);
  const pushToSearch = useCallback((curType) => {
    // const search = window.btoa(window.encodeURIComponent(JSON.stringify({
    //   keyword: '专精特新',
    // })));
    switch (curType) {
      case 'new':
        openNewPage('dataSearch', {
          page: 'publicSentiment',
          search: '专精特新',
        });
        break;
      case 'policy':
        openNewPage('dataSearch', {
          page: 'policy',
          search: '专精特新',
        });
        break;
      default: break;
    }
  }, []);

  const handleResize = useCallback(() => {
    if (carouselBody.current) {
      const curHeights = carouselBody.current.getBoundingClientRect().height;
      curWidth.current = carouselBody.current.getBoundingClientRect().width;
      curHeight.current = showAmount ? Math.floor(curHeights / Number(showAmount)) : 0;
      setHeight(curHeight.current);
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
      if (timer1.current) {
        clearTimeout(timer1.current);
        timer1.current = null;
      }
    };
  }, []);

  const stopScroll = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  const reStartScroll = useCallback(() => {
    const interval = Math.ceil(Number(speed) / curHeight.current);
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    let scroll = carouselBody.current.scrollTop;
    timer.current = setInterval(() => {
      const newScroll = scroll + 1;
      if (((carouselBody.current.scrollHeight - carouselBody.current.offsetHeight)
      - newScroll) <= 3) {
        carouselBody.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        clearInterval(timer.current);
        setTimeout(() => {
          reStartScroll();
        }, 2000);
      } else {
        scroll = newScroll;
        carouselBody.current.scrollTo(0, scroll);
      }
    }, interval < 100 ? 100 : interval);
  }, []);

  useEffect(() => {
    // 清空原有定时器
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    if (timer1.current) {
      clearTimeout(timer1.current);
      timer1.current = null;
    }
    if (data.length === 0) return undefined;
    setTableData(data);
    curData.current = data;
    carouselBody.current.scrollTo(0, 0);
    if (data.length > showAmount) {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
      if (timer1.current) {
        clearTimeout(timer1.current);
        timer1.current = null;
      }
      timer1.current = setTimeout(() => {
        reStartScroll();
      }, 1000);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
      if (timer1.current) {
        clearTimeout(timer1.current);
        timer1.current = null;
      }
    };
  }, [data]);
  return (
    <div className={style.all}>
      <div
        className={style.head}
      >
        {titleMap[type]}

        {
          more && (
            <div className={style.getMore} onClick={() => pushToSearch(type)}>
              {/* <IconFont type="iconswap-right" style={{ fontSize: '18px', marginRight: '4px' }} /> */}
              更多
            </div>
          )
        }
      </div>
      <div
        className={mouseStatue ? style.UnscrollBody : style.body}
        ref={carouselBody}
        onMouseOver={() => {
          setMouse(true);
          stopScroll();
        }}
        onMouseLeave={() => {
          setMouse(false);
          reStartScroll();
        }}
        onFocus={() => {}}
      >
        {tableData.map((item, index) => (
          <div
            className={style.bodyItem}
            key={`${index}carousel`}
            style={{ height: `${rowHeight}px` }}
            onClick={() => { if (item.url) window.open(item.url); if (item.link) window.open(item.link); }}
          >
            <div className={style.title} title={item.title}>
              {item.title}
            </div>
            <div className={style.msg}>
              {item.department && (
                <div title={item.department} style={{ marginRight: '12px', maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  发布单位&nbsp;&nbsp;&nbsp;
                  {item.department}
                </div>
              )}
              {(item.date || item.time) && (
                <div>{item.date || item.time}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CarouselList;
