import React from 'react';
import style from './index.module.less';
import Barandline from '../../../../../components/Charts/BarAndLine';
import StackBar from '../../../../../components/Charts/StackBar';
import { useGetEconomicDataQuery } from '../../../../../service/cityDetail';

const EconomicData = ({ title, cityCode }) => {
  const { data, isFetching } = useGetEconomicDataQuery({
    area_key: cityCode,
  }, { skip: !cityCode });
  const generateSeries = (newData) => newData?.map(item => (
    {
      ...item,
      type: item.name === '地区生产总值增长率' ? 'line' : 'bar',
      yAxisIndex: item.name === '地区生产总值增长率' ? 'line' : 'bar',
    }
  ));
  return (
    <div className={style.box} id={title}>
      <p className={style.title}>{title}</p>
      <ul>
        <li>
          <p className={style.chartTitle} >{data?.[0]?.title || ''}</p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[0],
                highlight: data?.[0]?.highlight[0],
                series: generateSeries(data?.[0]?.series),
                leftYName: [0, 40, 0, 0],
                rightYName: [0, 0, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[1]?.title || ''}</p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[1],
                series: generateSeries(data?.[1]?.series),
                leftYName: [0, 40, 0, 0],
                rightYName: [0, 0, 0, 0],
              }}
              isFetching={isFetching}
              showLabel
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[2]?.title || ''}</p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[2],
                highlight: data?.[2]?.highlight[0],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[3]?.title || ''}</p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[3],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
              showLabel
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[4]?.title || ''}</p>
          <div className={style.chart}>
            <StackBar
              data={{
                ...data?.[4],
                yMax: 100,
                leftYName: [0, 30, 0, 0],
                color: ['#1961F5', '#59D700', '#FD6205'],
              }}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[5]?.title || ''}</p>
          <div className={style.chart}>
            <StackBar
              data={{
                ...data?.[5],
                yMax: 100,
                leftYName: [0, 30, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default EconomicData;
