import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const COLOR_LIST = [
  '#0048DD', '#1961F5', '#4E88FF', '#00E7B5',
  '#59D700', '#FDAD61', '#FD6205',
];

function PieChart(props) {
  const { data, rose, unit } = props;
  const [option, setOption] = useState({});

  useEffect(() => {
    if (!data.length) return;
    const _option = {
      tooltip: {
        formatter: params => {
          const str = `<div>${params.marker}${params.name}：${params.data.value}${unit}</div>`;
          return str;
        },
      },
      legend: {
        orient: 'horizontal',
        bottom: '2%',
        left: '2%',
        itemHeight: 8,
        itemWidth: 8,
        itemGap: 18,
        textStyle: {
          color: '#7B8796',
        },
        icon: 'circle',
        padding: 0,
      },
      series: [
        {
          type: 'pie',
          roseType: rose ? 'radius' : false,
          color: COLOR_LIST,
          legendHoverLink: false,
          hoverAnimation: true,
          center: ['45%', '40%'],
          radius: ['0', '60%'],
          label: {
            color: '#7B8796',
            fontSize: 12,
            formatter: param => {
              const html = `${param.data.name}\n${param.data.value}${unit}`;
              return html;
            },
          },
          labelLine: {
            length: '2%',
            length2: '5%',
            lineStyle: {
              color: '#7B8796',
              type: 'dashed',
            },
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: rose ? 0 : 2,
          },
          zlevel: 5,
          data,
        },
      ],
    };
    setOption(_option);
  }, [data]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default PieChart;
