import React, { useEffect, useState } from 'react';
import {
  Form,
  Cascader,
  DatePicker,
  TreeSelect,
  Select,
  Input,
  Button,
  Row,
  Col,
} from 'antd';
import { cloneDeep } from 'lodash';
import { SwapRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import clsx from 'clsx';
import {
  useGetIndustrySelectQuery,
  useGetRegionSelectQuery,
  useGetParkSelectQuery,
  useGetCompanyScaleSelectQuery,
  useGetCompanyStatusSelectQuery,
  useGetFinanceRoundSelectQuery,
  useGetCompanyQualificationSelectQuery,
  useGetMarketSectorSelectQuery,
  useGetCapitalTypeSelectQuery,
} from '../../../../../../service/dataAnalysisTool';
import { onFlatArray } from '../../../../utils/public';
import styles from './index.module.less';

const { SHOW_PARENT } = TreeSelect;
const { RangePicker } = DatePicker;

const boolList = [
  {
    value: '不限',
    label: '不限',
  },
  {
    value: true,
    label: '有',
  },
  {
    value: false,
    label: '无',
  },
];
const areaListArray = [
  {
    label: '省',
    value: 1,
  },
  {
    label: '市',
    value: 2,
  },
  {
    label: '区',
    value: 3,
  },
];

const handleCasaderData = (list, generateValue, generateLabel) => {
  const tree = [...list];
  const generateFn = (arr) => {
    const optionList = arr.map((item) => {
      if (generateValue.length === 1) {
        return {
          ...item,
          value: item?.[generateValue[0]] || null,
          label: item?.[generateLabel[0]] || null,
          disabled: item?.isGrey,
          children: item?.children && generateFn(item.children),
        };
      }
      return {
        ...item,
        value: item[generateValue[0]] || item[generateValue[1]],
        label: item[generateLabel[0]] || item[generateLabel[1]],
        children: item.children && generateFn(item.children),
      };
    });
    return optionList;
  };
  const newTree = generateFn(tree);
  return newTree;
};

const handlelistData = (list) => {
  const arr = [
    {
      value: '不限',
      title: '不限',
    },
  ];
  if (list.length) {
    list.forEach((item) => {
      arr.push({
        value: item.key,
        title: item.title,
      });
    });
  }
  return arr;
};

const handelGardenData = (data) => data.map((item) => {
  if (item && typeof item._id === 'string') {
    item.disabled = true;
    item.checkable = false;
    item.id = item._id;
  } else if (item) {
    item.checkable = true;
    delete item.sub_id;
  }
  if (item?.children?.length && item.children.filter(Boolean).length) {
    handelGardenData(item.children);
  } else {
    delete item.children;
  }
  return item;
});

export default function FilterBox({
  filterParams,
  setFilterParams,
  setSearchPage,
}) {
  const [form] = Form.useForm();
  const dateFormat = 'YYYY-MM-DD';
  const [formValues, setFormValues] = useState();
  const [selectRound, setSelectRound] = useState(['不限']);
  const [selectCapital, setSelectCapital] = useState([]);
  const [selectScale, setSelectScale] = useState([]);
  const [selectQualification, setSelectQualification] = useState([]);
  const [selectStatus, setSelectStatus] = useState([]);
  const [selectSector, setSelectSector] = useState([]);
  const [registerCapitalList, setRegisterCapitalList] = useState([null, null]);
  const [selectFilter, setSelectFilter] = useState({});
  const [levelData, setLevelData] = useState(1);

  const { data: industryList } = useGetIndustrySelectQuery();
  const industryOptions = industryList
    ? handleCasaderData(
      industryList,
      ['industry_code', 'key'],
      ['name', 'title'],
    )
    : [];

  const { data: areaList } = useGetRegionSelectQuery({
    find_level: levelData,
  });
  const areaOptions = areaList
    ? handleCasaderData(areaList, ['key'], ['title'])
    : [];

  const { data: parkList } = useGetParkSelectQuery();
  const parkOptions = parkList
    ? handleCasaderData(parkList, ['_id'], ['name'])
    : [];

  const { data: companyScaleList } = useGetCompanyScaleSelectQuery();
  const companyScaleOptions = companyScaleList
    ? handlelistData(companyScaleList)
    : [];

  const { data: companyStatusList } = useGetCompanyStatusSelectQuery();
  const companyStatusOptions = companyStatusList
    ? handlelistData(companyStatusList)
    : [];

  const { data: financeRoundList } = useGetFinanceRoundSelectQuery();
  const financeRoundOptions = financeRoundList
    ? handlelistData(financeRoundList)
    : [];

  const { data: companyQualificationList } = useGetCompanyQualificationSelectQuery();
  const companyQualificationOptions = companyQualificationList
    ? handlelistData(companyQualificationList)
    : [];

  const { data: marketSectorList } = useGetMarketSectorSelectQuery();
  const marketSectorOptions = marketSectorList
    ? handlelistData(marketSectorList)
    : [];

  const { data: capitalTypeList } = useGetCapitalTypeSelectQuery();
  const capitalTypeOptions = capitalTypeList
    ? handlelistData(capitalTypeList)
    : [];

  const handleFormSubmit = (v) => {
    const {
      industryCodeList,
      regionCodeList,
      parkIdList,
      establishDateList,
      supportRsFlag,
      authorizedInventionPtFlag,
    } = v;
    setFilterParams({
      industry_code_list: industryCodeList,
      region_code_list: onFlatArray(regionCodeList),
      park_id_list: parkIdList,
      register_capital_list:
        registerCapitalList[0] || registerCapitalList[1]
          ? registerCapitalList
          : [],
      establish_date_list:
        establishDateList?.length > 0
          ? [
            establishDateList[0]?.format(dateFormat) || null,
            establishDateList[1]?.format(dateFormat) || null,
          ]
          : [],
      company_scale_list: selectScale[0] === '不限' ? [] : selectScale,
      company_status_list: selectStatus[0] === '不限' ? [] : selectStatus,
      financing_round_list: selectRound[0] === '不限' ? [] : selectRound,
      diy_tag_list:
        selectQualification[0] === '不限' ? [] : selectQualification,
      market_block_list: selectSector[0] === '不限' ? [] : selectSector,
      capital_type_list: selectCapital[0] === '不限' ? [] : selectCapital,
      support_rs_flag: supportRsFlag === '不限' ? null : supportRsFlag,
      authorized_invention_pt_flag:
        authorizedInventionPtFlag === '不限' ? null : authorizedInventionPtFlag,
      levelData,
    });
    setSearchPage({
      current: 1,
      pageSize: 15,
    });
    localStorage.setItem('selectFilters', JSON.stringify(selectFilter));
  };

  const handleReset = () => {
    setFilterParams({
      industry_code_list: [],
      region_code_list: [],
      park_id_list: [],
      register_capital_list: [],
      establish_date_list: [],
      company_scale_list: [],
      company_status_list: [],
      financing_round_list: [],
      diy_tag_list: [],
      market_block_list: [],
      capital_type_list: [],
      support_rs_flag: '不限',
      authorized_invention_pt_flag: '不限',
    });
    setSearchPage({
      current: 1,
      pageSize: 15,
    });
  };

  const treeMultipleChange = (values, setValues) => {
    const list = [];
    if (values.length > 0) {
      const currentIndex = values.length - 1;
      if (values[currentIndex] === '不限') {
        // 当前选中全部，清空原有数组，选择全部
        list.push('不限');
      } else if (values.filter((i) => i === '不限').length > 0) {
        // 当前选中其他，之前选中全部，去掉全部，选择其他
        list.push(values[currentIndex]);
      } else {
        // 当前选中其他，之前未选中全部，直接push
        values.forEach((i) => {
          list.push(i);
        });
      }
    } else {
      list.push('不限');
    }
    setValues(list);
  };

  const treeLabelChange = (labels) => {
    const list = [];
    if (labels.length > 0) {
      const currentIndex = labels.length - 1;
      if (labels[currentIndex] === '不限') {
        // 当前选中全部，清空原有数组，选择全部
        list.push('不限');
      } else if (labels.filter((i) => i === '不限').length > 0) {
        // 当前选中其他，之前选中全部，去掉全部，选择其他
        list.push(labels[currentIndex]);
      } else {
        // 当前选中其他，之前未选中全部，直接push
        labels.forEach((i) => {
          list.push(i);
        });
      }
    }
    return list;
  };

  const onSetAreaMenuValue = (data) => {
    setLevelData(data);
    form.setFieldsValue({
      regionCodeList: [],
    });
  };

  useEffect(() => {
    if (filterParams) {
      form.setFieldsValue({
        industryCodeList: filterParams.industry_code_list,
        regionCodeList: onFlatArray(filterParams.region_code_list),
        parkIdList: filterParams.park_id_list,
        establishDateList:
          filterParams.establish_date_list?.length > 0
            ? [
              moment(filterParams.establish_date_list[0], dateFormat),
              moment(filterParams.establish_date_list[1], dateFormat),
            ]
            : [],
        supportRsFlag:
          filterParams.support_rs_flag !== null
            ? filterParams.support_rs_flag
            : '不限',
        authorizedInventionPtFlag:
          filterParams.authorized_invention_pt_flag !== null
            ? filterParams.authorized_invention_pt_flag
            : '不限',
      });
      setLevelData(filterParams?.levelData || 1);
      setSelectRound(
        filterParams.financing_round_list?.length > 0
          ? filterParams.financing_round_list
          : ['不限'],
      );
      setSelectCapital(
        filterParams.capital_type_list?.length > 0
          ? filterParams.capital_type_list
          : ['不限'],
      );
      setSelectScale(
        filterParams.company_scale_list?.length > 0
          ? filterParams.company_scale_list
          : ['不限'],
      );
      setSelectQualification(
        filterParams.diy_tag_list?.length > 0
          ? filterParams.diy_tag_list
          : ['不限'],
      );
      setSelectStatus(
        filterParams.company_status_list?.length > 0
          ? filterParams.company_status_list
          : ['不限'],
      );
      setSelectSector(
        filterParams.market_block_list?.length > 0
          ? filterParams.market_block_list
          : ['不限'],
      );
      setRegisterCapitalList(
        filterParams.register_capital_list?.length > 0
          ? filterParams.register_capital_list
          : [null, null],
      );
    }
  }, [filterParams]);

  return (
    <div className={styles.filterBox}>
      <Form
        // layout='inline'
        // labelCol={{
        //   span: 6,
        // }}
        // wrapperCol={{
        //   span: 18,
        // }}
        onFinish={handleFormSubmit}
        initialValues={formValues}
        form={form}
      >
        <Row>
          <Col span={6}>
            <Form.Item name="industryCodeList" label="产业选择" colon={false}>
              <Cascader
                style={{
                  width: '87%',
                }}
                options={industryOptions}
                onChange={(v, options) => {
                  let str = '';
                  if (options.length > 0) {
                    options.forEach((item, index) => {
                      const industryStr = index === options.length - 1
                        ? item[item.length - 1].label
                        : `${item[item.length - 1].label}、`;
                      str += industryStr;
                    });
                  }
                  setSelectFilter((prev) => ({ ...prev, 产业选择: str }));
                }}
                placeholder="请选择产业"
                multiple
                maxTagCount="responsive"
                // loadData={}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="establishDateList" label="成立时间" colon={false}>
              <RangePicker
                placeholder={['开始时间', '截止时间']}
                style={{
                  width: '87%',
                }}
                onChange={(data, dataString) => {
                  let str = '';
                  if (dataString.length > 0) {
                    dataString.forEach((item, index) => {
                      str += `${item.split('-')[0]}年${item.split('-')[1]}月${item.split('-')[2]
                      }日`;
                    });
                  }
                  setSelectFilter((prev) => ({ ...prev, 成立时间: str }));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="融资轮次" colon={false}>
              <TreeSelect
                value={selectRound}
                onChange={(v) => treeMultipleChange(v, setSelectRound)}
                treeData={financeRoundOptions}
                placeholder="请选择融资轮次"
                treeCheckable
                maxTagCount="responsive"
                popupClassName={styles.dropTreeSelect}
                multiple
                style={{
                  width: '87%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="资本类型&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              colon={false}
            >
              <TreeSelect
                value={selectCapital}
                onChange={(v, label) => {
                  treeMultipleChange(v, setSelectCapital);
                  const str = treeLabelChange(label).join('，');
                  setSelectFilter((prev) => ({ ...prev, 资本类型: str }));
                }}
                treeData={capitalTypeOptions}
                placeholder="请选择资本类型"
                treeCheckable
                maxTagCount="responsive"
                popupClassName={styles.dropTreeSelect}
                multiple
                style={{
                  width: '87%',
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item
              label="区域选择"
              style={{ marginBottom: 0 }}
              colon={false}
            >
              <Row>
                <Col span={9}>
                  <Form.Item noStyle>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 20,
                      }}
                    >
                      {areaListArray.map((item, index) => (
                        <div
                          key={index}
                          className={clsx(styles.defaultStyle, {
                            [styles.activeStyle]: item.value === levelData,
                          })}
                          onClick={() => onSetAreaMenuValue(item.value)}
                        >
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="regionCodeList" colon={false}>
                    <TreeSelect
                      style={{
                        width: '98%',
                        marginLeft: 4,
                      }}
                      treeData={areaOptions || []}
                      showCheckedStrategy={SHOW_PARENT}
                      placeholder="请选择区域"
                      notFoundContent
                      maxTagCount="responsive"
                      onChange={(v, labels) => {
                        let str = '';
                        if (labels.length > 0) {
                          labels.forEach((item, index) => {
                            const industryStr = index === labels.length - 1 ? item : `${item}、`;
                            str += industryStr;
                          });
                        }
                        setSelectFilter((prev) => ({ ...prev, 区域选择: str }));
                      }}
                      treeNodeFilterProp="title"
                      treeCheckable
                      showSearch
                      showArrow
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="企业规模" colon={false}>
              <TreeSelect
                value={selectScale}
                onChange={(v) => treeMultipleChange(v, setSelectScale)}
                treeData={companyScaleOptions}
                placeholder="请选择企业规模"
                treeCheckable
                maxTagCount="responsive"
                popupClassName={styles.dropTreeSelect}
                multiple
                style={{
                  width: '87%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="企业资质" colon={false}>
              <TreeSelect
                value={selectQualification}
                onChange={(v, label) => {
                  treeMultipleChange(v, setSelectQualification);
                  const str = treeLabelChange(label).join('，');
                  setSelectFilter((prev) => ({ ...prev, 企业资质: str }));
                }}
                treeData={companyQualificationOptions}
                placeholder="请选择企业资质"
                treeCheckable
                maxTagCount="responsive"
                popupClassName={styles.dropTreeSelect}
                multiple
                style={{
                  width: '87%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="authorizedInventionPtFlag"
              label="授权发明专利"
              colon={false}
            >
              <Select
                placeholder="请选择有无授权发明专利"
                style={{
                  width: '87%',
                }}
                options={boolList}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item name="parkIdList" label="园区选择" colon={false}>
              <TreeSelect
                style={{
                  width: '87%',
                }}
                rootClassName={styles.garden}
                placeholder="请选择园区"
                treeData={handelGardenData(cloneDeep(parkList || []))}
                treeCheckStrictly
                treeCheckable
                multiple
                maxTagCount="responsive"
                treeNodeFilterProp="name"
                showSearch={false}
                fieldNames={{
                  label: 'name',
                  value: 'id',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="企业状态" colon={false}>
              <TreeSelect
                value={selectStatus}
                onChange={(v, label) => {
                  treeMultipleChange(v, setSelectStatus);
                  const str = treeLabelChange(label).join('，');
                  setSelectFilter((prev) => ({ ...prev, 企业状态: str }));
                }}
                treeData={companyStatusOptions}
                placeholder="请选择企业状态"
                treeCheckable
                maxTagCount="responsive"
                popupClassName={styles.dropTreeSelect}
                multiple
                style={{
                  width: '87%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="上市板块" colon={false}>
              <TreeSelect
                value={selectSector}
                onChange={(v) => treeMultipleChange(v, setSelectSector)}
                treeData={marketSectorOptions}
                placeholder="请选择上市板块"
                treeCheckable
                maxTagCount="responsive"
                popupClassName={styles.dropTreeSelect}
                multiple
                style={{
                  width: '87%',
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="supportRsFlag" label="承担研究机构" colon={false}>
              <Select
                placeholder="请选择是否承担研究机构"
                style={{
                  width: '87%',
                }}
                options={boolList}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item label="注册资本" colon={false} wrapperCol={{ span: 18 }}>
              <Input.Group compact>
                <Input
                  className={styles.leftInput}
                  style={{
                    width: '41%',
                    textAlign: 'left',
                  }}
                  suffix={<span style={{ color: '#999' }}>万元</span>}
                  placeholder="最低金额"
                  value={registerCapitalList?.[0]}
                  onChange={(e) => setRegisterCapitalList((prev) => [e.target.value, prev[1]])}
                />
                <div className={styles.midInput}>
                  <SwapRightOutlined />
                </div>
                <Input
                  className={styles.rightInput}
                  style={{
                    width: '41%',
                    textAlign: 'left',
                  }}
                  suffix={<span style={{ color: '#999' }}>万元</span>}
                  placeholder="最高金额"
                  value={registerCapitalList?.[1]}
                  onChange={(e) => setRegisterCapitalList((prev) => [prev[0], e.target.value])}
                />
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.btnBox}>
          <Button
            htmlType="button"
            onClick={handleReset}
            style={{ marginRight: 12 }}
          >
            重置
          </Button>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </div>
      </Form>
    </div>
  );
}
