/*
 *Author: zhaochenyu
 *Description: 公司详情内容部分
 *Date: 2021/05/11
*/

import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';

// 成长潜力
import TechnologyDriven from './GrowthPotential/TechnologyDriven';
import ScaleExpansion from './GrowthPotential/ScaleExpansion';
import MarketFeedback from './GrowthPotential/MarketFeedback';
import ProfitGrowth from './GrowthPotential/ProfitGrowth';
import IndustryStatus from './GrowthPotential/IndustryStatus';
// 关系穿透
import IndustrySector from './RelationshipPenetration/IndustrySector';
import EquityRelationship from './RelationshipPenetration/EquityRelationship';
import OutboundInvestment from './RelationshipPenetration/OutboundInvestment';
import Branch from './RelationshipPenetration/Branch';
import PatentCooperationNetwork from './RelationshipPenetration/PatentCooperationNetwork';
import StandardCooperation from './RelationshipPenetration/StandardCooperation';
import SupplyRelationship from './RelationshipPenetration/SupplyRelationship';
// 社会贡献
import TotalEmployment from './SocialContribution/TotalEmployment';
import TaxContribution from './SocialContribution/TaxContribution';
// 风险预警
import BusinessRisk from './RiskWarning/BusinessRisk';
import PublicOpinion from './RiskWarning/PublicOpinion';
// 关联风险
import RelatedRisks from './RiskWarning/RelatedRisks';
// 评价结果
import IndustryCompetitiveness from './EvaluationResults/IndustryCompetitiveness';
import CreditRisks from './EvaluationResults/CreditRisks';
// 企业服务
import PolicyRecommend from './EnterpriseServices/PolicyRecommend';
import PatentRecommend from './EnterpriseServices/PatentRecommend';
// 最新动态
// import NewsInfomation from './News/NewsInfomation';
// import ChangeLegalPerson from './News/ChangeLegalPerson';
// import ChangeEquity from './News/ChangeEquity';
// import OperateChange from './News/OperateChange';
// import ChangeMoveOut from './News/ChangeMoveOut';
// import NewOrganization from './News/NewOrganization';
// import NewBrand from './News/NewBrand';
// import NewPatent from './News/NewPatent';
// import NewInvestment from './News/NewInvestment';
// import RiskWarning from './News/RiskWarning';
// 基本信息
// import CaptialStructure from './BaseInfo/CaptialStructure';
// import BusinessInfo from './BaseInfo/BusinessInfo';
// import CreativePlatformInfo from './BaseInfo/CreativePlatformInfo';
// import ShareholderInfo from './BaseInfo/ShareholderInfo';
// import CompanyReport from './BaseInfo/CompanyReport';
// import ScientificResearchProject from './BaseInfo/ScientificResearchProject';
// import DominantStandard from './BaseInfo/DominantStandard';
// import CompanyQualification from './BaseInfo/CompanyQualification';
// import TrademarkName from './BaseInfo/TrademarkName';
// import FinancingHistory from './BaseInfo/FinancingHistory';
// import ContractAwardedProject from './BaseInfo/ContractAwardedProject';
// 知识产权
// import PatentDetail from './IntellectualProperty/PatentDetail';
// import PatentAnalysis from './IntellectualProperty/PatentAnalysis';
// import RecordLicense from './IntellectualProperty/RecordLicense';
// import TrademarkDetails from './IntellectualProperty/TrademarkDetails';
// 对外投资
// import InvestmentEvent from './OutboundInvestment/InvestmentEvent';
// import InvestedEnterprises from './OutboundInvestment/InvestedEnterprises';
// import EquityThrough from './OutboundInvestment/EquityThrough';
// import BranchOrganization from './OutboundInvestment/BranchOrganization';
// import NationalLayout from './OutboundInvestment/NationalLayout';
// 关系图谱
// import SupplyRelationship from './RelationshipMap/SupplyRelationship';
// import Partnership from './RelationshipMap/Partnership';
// import StockPenetrate from './RelationshipMap/StockPenetrate';
// import QualificationIdentification from './RelationshipMap/QualificationIdentification';
// import CreativePlatform from './RelationshipMap/CreativePlatform';
// import CoreTechnology from './RelationshipMap/CoreTechnology';
// import DevelopmentHistory from './RelationshipMap/DevelopmentHistory';
// import NewsInfomation from './News/NewsInfomation';
// import ChangeLegalPerson from './News/ChangeLegalPerson';
// import OperateChange from './News/OperateChange';
// import NewOrganization from './News/NewOrganization';
// import NewBrand from './News/NewBrand';
// import NewPatent from './News/NewPatent';
// import NewInvestment from './News/NewInvestment';
// import RiskWarning from './News/RiskWarning';
// 基本信息
// import CaptialStructure from './BaseInfo/CaptialStructure';
import BusinessInfo from './BaseInfo/BusinessInfo';
import EquityThrough from './OutboundInvestment/EquityThrough';
import MajorShareholders from './BaseInfo/MajorShareholders';
import CorporateExecutives from './BaseInfo/CorporateExecutives';
import ListingInformation from './BaseInfo/ListingInformation';
import BusinessChange from './BaseInfo/BusinessChange';
import CompanyReport from './BaseInfo/CompanyReport';
import DevelopmentHistory from './BaseInfo/DevelopmentHistory';

// import CreativePlatformInfo from './BaseInfo/CreativePlatformInfo';
// import ShareholderInfo from './BaseInfo/ShareholderInfo';
// import StockPenetrate from './RelationshipMap/StockPenetrate';
// import GuardianshipTable from './RelationshipMap/GuardianshipTable';
// import ValuationTable from './BaseInfo/ListedInfo/ValuationTable';
// import FinancialStatement from './BaseInfo/ListedInfo/FinancialStatement';
// import DevelopmentHistory from './BaseInfo/GrowthProcess/DevelopmentHistory';
// import GrowthTrack from './BaseInfo/GrowthProcess/GrowthTrack';
// import ActivityLine from './BaseInfo/GrowthProcess/ActivityLine';

// 资质奖励
import HonorAptitudes from './AptitudesAward/HonorAptitudes';
import Award from './AptitudesAward/Award';
import ForReference from './AptitudesAward/ForReference';
// 经营能力
import QualityShareholder from './ManageCapacity/QualityShareholder';
// import CooperativeFaucet from './ManageCapacity/CooperativeFaucet';
import ContractAwardedProject from './ManageCapacity/ContractAwardedProject';
import RevenueGrowth from './ManageCapacity/RevenueGrowth';
// 创新能力
import ResearchAndDevelopment from './InnovationAbility/ResearchAndDevelopment';
import ResearchInput from './InnovationAbility/ResearchInput';
// import PatentAnalysis from './InnovationAbility/PatentAnalysis';
import PerformanceCriteria from './InnovationAbility/PerformanceCriteria';
import SoftwareLiterature from './InnovationAbility/SoftwareLiterature';
// 融资历程
import FinancingHistory from './FinancingHistory';

// import ScientificResearchProject from './BaseInfo/ScientificResearchProject';
// import DominantStandard from './BaseInfo/DominantStandard';
// import CompanyQualification from './BaseInfo/CompanyQualification';
// import TrademarkName from './BaseInfo/TrademarkName';
// import ContractAwardedProject from './BaseInfo/ContractAwardedProject';
// 知识产权
// import PatentDetail from './IntellectualProperty/PatentDetail';
import PatentAnalysis2 from './IntellectualProperty/PatentAnalysis';
// import RecordLicense from './IntellectualProperty/RecordLicense';
// import TrademarkDetails from './IntellectualProperty/TrademarkDetails';
// 对外投资
// import InvestmentEvent from './OutboundInvestment/InvestmentEvent';
// import InvestedEnterprises from './OutboundInvestment/InvestedEnterprises';
// import EquityThrough from './OutboundInvestment/EquityThrough';
// import BranchOrganization from './OutboundInvestment/BranchOrganization';
// import NationalLayout from './OutboundInvestment/NationalLayout';
// 关系图谱
// import SupplyRelationship from './RelationshipMap/SupplyRelationship';
// import Partnership from './RelationshipMap/Partnership';
// import QualificationIdentification from './RelationshipMap/QualificationIdentification';
// import CreativePlatform from './RelationshipMap/CreativePlatform';
// import CoreTechnology from './RelationshipMap/CoreTechnology';
// import CMAF from './RelationshipMap/CMAF';
// 企业评价
// import ComprehensiveRating from './EnterpriseEvaluation/ComprehensiveRating';
// import IndustryStatus from './EnterpriseEvaluation/IndustryStatus';
// import CompetitionPattern from './EnterpriseEvaluation/CompetitionPattern';
// import FiveIndexChart from './EnterpriseEvaluation/FiveIndexChart';
// 企业服务
// import ProjectRecommend from './EnterpriseServices/ProjectRecommend';
// import PatentRecommend from './EnterpriseServices/PatentRecommend';
// 企业估值
// import CompanyAnnualReport from './CompanyAppraisement';
import style from './index.module.less';

const orderTab = ['评价结果', '关系穿透', '资质奖励', '基本信息', '经营能力', '创新能力', '融资能力', '成长潜力', '社会贡献', '风险预警', '企业服务'];

const CompanyContent = forwardRef(((props, ref) => {
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const preventPageto = menu.find(item => item === 'companyDetailPageTo');
  const { fixHeight, tabs, companyId, newRouter, companyName, scrollContent } = props;
  const [fix, setFix] = useState(false);
  const [selectAchor, setAchor] = useState('基本信息');
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [tabBottom, setTabBottom] = useState(false);
  const positionRef = useRef([]); // 位置数组
  const timer = useRef(null);
  const timer1 = useRef(null);
  const timer2 = useRef(null);
  const topRef = useRef(null);
  const mainRef = useRef();
  const achorPush = useCallback((node) => {
    const achorName = document.querySelector(`#${node}`);
    if (achorName) {
      // document.documentElement.scrollTo
      scrollContent.current.scrollTo({
        top: newRouter ? (achorName.offsetTop - 104) : (achorName.offsetTop - 55 + 64),
        behavior: 'smooth',
      });
    }
  }, []);
  useImperativeHandle(ref, () => ({
    contentAnchorPush(node) {
      achorPush(node);
    },
  }));
  useEffect(() => {
    topRef.current = fixHeight;
  }, [fixHeight]);

  const changeAchor = useCallback(() => {
    // const scroll = document.documentElement.scrollTop + 50; // scrollContent.current.scrollTo;
    const scroll = scrollContent.current.scrollTop + 50;
    // setFix(scroll >= topRef.current);
    const len = positionRef.current.length;
    for (let i = 0; i < len; i += 1) {
      if (i === (len - 1) && (positionRef.current[i] <= scroll)) {
        setAchor(orderTab[i]);
        break;
      } else if ((positionRef.current[i] <= scroll)
        && (positionRef.current[i + 1] > scroll)) {
        setAchor(orderTab[i]);
        break;
      } else if ((scroll < positionRef.current[0])) {
        setAchor(orderTab[0]);
        break;
      }
    }
  }, []);
  const scrollLisener = useCallback(() => {
    if (!timer.current) {
      timer.current = setTimeout(() => {
        changeAchor();
        timer.current = null;
      }, 50);
    }
  }, []);

  const getCurPostion = () => {
    const arr = [];
    orderTab.forEach(item => {
      if (newRouter) {
        arr.push(document.getElementById(item).offsetTop - 55);
      } else {
        arr.push(document.getElementById(item).offsetTop - 55 + 64);
      }
    });
    positionRef.current = arr;
    // 高度变化后重新计算当前位置所在区间
    changeAchor();
  };

  const heightListener = useCallback(
    () => {
      if (timer1.current) {
        clearTimeout(timer1.current);
        timer1.current = null;
      }
      timer1.current = setTimeout(() => {
        getCurPostion();
      }, 50);
    },
    [],
  );

  useEffect(() => {
    changeAchor();
    // 监听页面高度变化重新计算锚点高度
    const resizeObserver = new ResizeObserver(heightListener);
    resizeObserver.observe(mainRef.current);
    // 监听页面滚动事件，改变锚点选中状态
    scrollContent.current.addEventListener('scroll', scrollLisener);
    return () => {
      scrollContent.current.removeEventListener('scroll', scrollLisener);
      resizeObserver.unobserve(mainRef.current);
      clearTimeout(timer.current);
      clearTimeout(timer1.current);
      clearTimeout(timer2.current);
    };
  }, []);
  const enterTab = useCallback(
    () => {
      if (!timer2.current) {
        timer2.current = setTimeout(() => {
          setTabBottom(true);
          timer2.current = null;
        }, 500);
      }
    },
    [],
  );
  return (
    <div className={style.all} ref={mainRef} id="companyAchorTab">
      <div className={style.achorTab} style={{ display: fix ? '' : 'none', visibility: 'hidden' }}>
        {orderTab.map((item, index) => (
          <div
            key={`${index}hidden`}
            className={style.achorItem}
            onClick={() => achorPush(item)}
          >
            {item}
          </div>
        ))}
      </div>
      {/* <Affix offsetTop={0}> */}
      <div
        // className={fix ? style.fix : style.achorTab}
        className={style.fix}
      >
        <div
          style={{ display: 'flex' }}
          onFocus={() => {
          }}
          onMouseOver={enterTab}
          onMouseLeave={() => {
            clearTimeout(timer2.current);
            timer2.current = null;
            setTabBottom(false);
          }}
        >
          {orderTab.map((item, index) => (
            <div
              key={`${item}${index}`}
              className={style.achorBorder}
              onClick={() => {
                achorPush(item);
                setTabBottom(false);
                setAchor(item);
              }}
              onFocus={() => {
              }}
              onMouseOver={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(-1)}
            >
              <div className={selectAchor === item ? style.selectAchor : style.achorItem}>
                {item}
              </div>
            </div>
          ))}
          <div
            className={style.bottomTab}
            style={{ display: tabBottom ? '' : 'none' }}
          >
            {orderTab.map((item, index) => (
              <div
                key={`${index}tabItem`}
                className={style.tabItem}
                onFocus={() => { }}
                onMouseOver={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(-1)}
                style={{ background: hoverIndex === index ? '#F9FAFB' : '#fff' }}
              >
                {tabs[item]?.map((item1, index1) => (
                  <div
                    className={classNames(style.tabItemItem, { [style.disabled]: item1.disabled === true })}
                    key={`${index1}tabItemItem${item1.name}`}
                    onClick={() => {
                      if (item1.disabled) return;
                      if (item1.name) {
                        achorPush(item1.name?.split('(')?.[0]);
                        setTabBottom(false);
                      }
                    }}
                  >
                    {item1.name}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* </Affix> */}

      <div className={style.outside} id="评价结果">
        {tabs['评价结果'].find(item => item.name.indexOf('行业竞争力') !== -1 && item.disabled === false) && <IndustryCompetitiveness companyId={companyId} />}
        {/* {tabs['评价结果'].find(item => item.name.indexOf('信用评估') !== -1 && item.disabled === false) && <CreditRisks companyId={companyId} />} */}
      </div>
      <div className={style.outside} id="关系穿透">
        {tabs['关系穿透'].find(item => item.name.indexOf('股权关系网络') !== -1 && item.disabled === false) && <EquityRelationship companyId={companyId} companyName={companyName} />}
        {tabs['关系穿透'].find(item => item.name.indexOf('对外投资') !== -1 && item.disabled === false) && <OutboundInvestment preventPageto={preventPageto} companyId={companyId} />}
        {tabs['关系穿透'].find(item => item.name.indexOf('分支机构') !== -1 && item.disabled === false) && <Branch preventPageto={preventPageto} companyId={companyId} />}
        {tabs['关系穿透'].find(item => item.name.indexOf('专利合作') !== -1 && item.disabled === false) && <PatentCooperationNetwork companyId={companyId} companyName={companyName} />}
        {tabs['关系穿透'].find(item => item.name.indexOf('标准合作') !== -1 && item.disabled === false) && <StandardCooperation preventPageto={preventPageto} companyId={companyId} companyName={companyName} />}
        {tabs['关系穿透'].find(item => item.name.indexOf('供应关系') !== -1 && item.disabled === false) && <SupplyRelationship preventPageto={preventPageto} companyId={companyId} companyName={companyName} />}
        {tabs['关系穿透'].find(item => item.name.indexOf('业务领域') !== -1 && item.disabled === false) && <IndustrySector preventPageto={preventPageto} companyId={companyId} companyName={companyName} />}
      </div>
      <div className={style.outside} id="资质奖励">
        {tabs['资质奖励'].find(item => item.name.indexOf('荣誉资质') !== -1 && item.disabled === false) && <HonorAptitudes companyId={companyId} companyName={companyName} />}
        {tabs['资质奖励'].find(item => item.name.indexOf('所获奖项') !== -1 && item.disabled === false) && <Award companyId={companyId} />}
        {tabs['资质奖励'].find(item => item.name.indexOf('备案许可') !== -1 && item.disabled === false) && <ForReference companyId={companyId} />}
      </div>
      <div className={style.outside} id="基本信息">
        {tabs['基本信息'].find(item => item.name.indexOf('工商信息') !== -1 && item.disabled === false) && <BusinessInfo companyId={companyId} />}
        {tabs['基本信息'].find(item => item.name.indexOf('股权穿透') !== -1 && item.disabled === false) && <EquityThrough companyId={companyId} companyName={companyName} />}
        {tabs['基本信息'].find(item => item.name.indexOf('股东信息') !== -1 && item.disabled === false) && <MajorShareholders companyId={companyId} companyName={companyName} />}
        {tabs['基本信息'].find(item => item.name.indexOf('企业高管') !== -1 && item.disabled === false) && <CorporateExecutives companyId={companyId} companyName={companyName} />}
        {tabs['基本信息'].find(item => item.name.indexOf('上市信息') !== -1 && item.disabled === false) && <ListingInformation companyId={companyId} />}
        {tabs['基本信息'].find(item => item.name.indexOf('企业年报') !== -1 && item.disabled === false) && <CompanyReport preventPageto={preventPageto} companyId={companyId} />}
        {tabs['基本信息'].find(item => item.name.indexOf('工商变更') !== -1 && item.disabled === false) && <BusinessChange companyId={companyId} />}
        {tabs['基本信息'].find(item => item.name.indexOf('发展历程') !== -1 && item.disabled === false) && <DevelopmentHistory companyId={companyId} />}
      </div>
      <div className={style.outside} id="经营能力">
        {tabs['经营能力'].find(item => item.name.indexOf('优质股东') !== -1 && item.disabled === false) && <QualityShareholder companyId={companyId} />}
        {tabs['经营能力'].find(item => item.name.indexOf('中标项目') !== -1 && item.disabled === false) && <ContractAwardedProject companyId={companyId} />}
        {tabs['经营能力'].find(item => item.name.indexOf('营业收入') !== -1 && item.disabled === false) && <RevenueGrowth companyId={companyId} />}
      </div>
      <div className={style.outside} id="创新能力">
        {tabs['创新能力'].find(item => item.name.indexOf('创新平台') !== -1 && item.disabled === false) && <ResearchAndDevelopment companyId={companyId} companyName={companyName} />}
        {tabs['创新能力'].find(item => item.name.indexOf('研发投入') !== -1 && item.disabled === false) && <ResearchInput preventPageto={preventPageto} companyId={companyId} />}
        {tabs['创新能力'].find(item => item.name.indexOf('专利分析') !== -1 && item.disabled === false) && <PatentAnalysis2 preventPageto={preventPageto} companyId={companyId} />}
        {tabs['创新能力'].find(item => item.name.indexOf('参研标准') !== -1 && item.disabled === false) && <PerformanceCriteria preventPageto={preventPageto} companyId={companyId} />}
        {tabs['创新能力'].find(item => item.name.indexOf('申请软著') !== -1 && item.disabled === false) && <SoftwareLiterature companyId={companyId} />}
      </div>
      <div className={style.outside} id="融资能力">
        {tabs['融资能力'].find(item => item.name.indexOf('融资历程') !== -1 && item.disabled === false) && <FinancingHistory companyId={companyId} />}
      </div>
      <div className={style.outside} id="成长潜力">
        {tabs['成长潜力'].find(item => item.name.indexOf('技术驱动') !== -1 && item.disabled === false) && <TechnologyDriven companyId={companyId} />}
        {tabs['成长潜力'].find(item => item.name.indexOf('规模拓展') !== -1 && item.disabled === false) && <ScaleExpansion companyId={companyId} />}
        {tabs['成长潜力'].find(item => item.name.indexOf('市场反馈') !== -1 && item.disabled === false) && <MarketFeedback companyId={companyId} />}
        {tabs['成长潜力'].find(item => item.name.indexOf('利润增长') !== -1 && item.disabled === false) && <ProfitGrowth companyId={companyId} />}
        {/* {tabs['成长潜力'].find(item => item.name.indexOf('行业地位') !== -1 && item.disabled === false) && <IndustryStatus companyId={companyId} />} */}
      </div>
      <div className={style.outside} id="社会贡献">
        {tabs['社会贡献'].find(item => item.name.indexOf('就业人数') !== -1 && item.disabled === false) && <TotalEmployment companyId={companyId} />}
        {tabs['社会贡献'].find(item => item.name.indexOf('税收贡献') !== -1 && item.disabled === false) && <TaxContribution companyId={companyId} />}
      </div>
      <div className={style.outside} id="风险预警">
        {tabs['风险预警'].find(item => item.name.indexOf('经营风险') !== -1 && item.disabled === false) && <BusinessRisk companyId={companyId} />}
        {tabs['风险预警'].find(item => item.name.indexOf('关联风险') !== -1 && item.disabled === false) && <RelatedRisks companyId={companyId} />}
        {tabs['风险预警'].find(item => item.name.indexOf('舆情预警') !== -1 && item.disabled === false) && <PublicOpinion companyId={companyId} />}
      </div>
      <div className={style.outside} id="企业服务">
        {tabs['企业服务'].find(item => item.name.indexOf('政策推荐') !== -1 && item.disabled === false) && <PolicyRecommend companyId={companyId} />}
        {tabs['企业服务'].find(item => item.name.indexOf('专利推荐') !== -1 && item.disabled === false) && <PatentRecommend preventPageto={preventPageto} companyId={companyId} />}
      </div>
    </div>
  );
}));

export default CompanyContent;
