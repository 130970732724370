import React, { useState, useEffect } from 'react';
import style from './index.module.less';
import CrosswiseDar from '../../../../../../components/Charts/CrosswiseDar';
import DataSearchButton from '../../../../../Industry/components/DataSearchButton';
import { FilterIcon } from '../../../../../../components/CustomTableIcon';
import CustomTreeFilter from '../../../../../../components/CustomTreeFilter';
import CustomListFilter from '../../../../../../components/CustomListFilter';
import Score from '../../../../../../components/Score';
import HollowPie from '../../../../../../components/Charts/HollowPie';
import Subtitle from '../../../../../../components/Subtitle';
import Barandline from '../../../../../../components/Charts/BarAndLine';
import Tabs from '../../components/Tabs';
import Table from '../../components/Table';
import ChildTable from './ChildTable';
import { StandardModal } from '../../../../../Industry/components/Modal';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetCompanySpecialTagsQuery,
  useGetIndustryTreeQuery,
} from '../../../../../../service/interactive-v2';
import {
  useAlreadyHaveCompanyTypeListQuery,
} from '../../../../../../service/cityTool';
import { useChartData, useGetTable, useGetCountTitle } from './useGetData';

const levelData = [
  { title: '全部', value: '全部', text: '全部' },
  { title: '总部', value: '总部', text: '总部' },
  { title: '一级子公司', value: '一级子公司', text: '一级子公司' },
  { title: '二级子公司', value: '二级子公司', text: '二级子公司' },
  { title: '三级及以下', value: '三级及以下', text: '三级及以下' },
];
const sortMap = {
  descend: 'desc',
  ascend: 'asc',
};
const HighGradeCompany = (props) => {
  const { title, isProvinceDirectly, area: { code }, onCompanyClick } = props;
  const [checkedTab, setCheckedTab] = useState({});
  const [level, setLevel] = useState('全部');
  const [sortKey, setSortKey] = useState('');
  const [sortValue, setSortValue] = useState('descend');
  const [industryCodeList, setIndustryCodeList] = useState(['all']);
  const [specialTag, setSpecialTag] = useState(['all']);
  const [location, setLocation] = useState(code);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [childTableShow, setChildTableShow] = useState(false);
  const [childTableId, setChildTableId] = useState('');
  const [patentNum, setPatentNum] = useState('-');
  const { data: tabsData } = useAlreadyHaveCompanyTypeListQuery();
  const { key, type, title: tableTitle } = checkedTab;
  const { data: chartData, chartIsFetching } = useChartData({ code, key, type });
  useEffect(() => {
    if (tabsData) {
      setCheckedTab(tabsData[0]);
    }
    setChildTableShow(false);
  }, [tabsData]);
  useEffect(() => {
    setLocation(code);
    setLevel('全部');
    setSortKey('');
    setSortValue('descend');
    setIndustryCodeList(['all']);
    setSpecialTag(['all']);
    setPage(1);
    setChildTableShow(false);
  }, [code, key]);

  const onClick = (e) => {
    setChildTableShow(true);
    setChildTableId(e.record._id);
  };
  const { data: tableTitleData } = useGetCountTitle(
    {
      code,
      key,
    },
    type,
  );
  const updateFiltrate = (columnKey, FiltrateData) => {
    const filtrateMap = {
      industry: () => { setIndustryCodeList(FiltrateData); },
      level: () => { setLevel(FiltrateData ? FiltrateData?.[0] : '全部'); },
      location: () => { setLocation(FiltrateData?.[0] || code); },
      tag_name: () => { setIndustryCodeList(FiltrateData); },
      special_tag: () => { setSpecialTag(FiltrateData); },
    };
    if (filtrateMap[columnKey]) {
      filtrateMap[columnKey]();
    }
  };
  const { data: tableData = {}, tableIsFetching } = useGetTable({
    area_key: location,
    label: key,
    level,
    special_tag: specialTag,
    industry_code_list: industryCodeList,
    sort_key: sortKey,
    sort_value: sortValue && sortMap?.[sortValue],
    page_size: pageSize,
    page_number: page,
  }, type);
  const { data: tags = [] } = useGetCompanySpecialTagsQuery();
  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query({
    area_code: code,
  });
  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data: TreeData }) => {
        if (!TreeData) return [];
        return {
          industryTree: [TreeData],
        };
      },
    },
  );
  const Tablehandle = (item) => ({
    index: {
      width: 50,
      className: style.index,
    },
    company_name: {
      width: 230,
      ellipsis: true,
      className: style.name,
      onCell: (record, rowIndex) => ({
        onClick: () => { onCompanyClick(record, rowIndex); },
      }),
    },
    name: {
      width: 330,
      ellipsis: true,
      className: style.name,
      onCell: (record, rowIndex) => ({
        onClick: () => { onCompanyClick(record, rowIndex); },
      }),
    },
    head_office: {
      width: 230,
      ellipsis: true,
      render: (text) => (
        <div className={style.headOffice}>
          {
            text.map(({ name }) => <span key={name} title={name}>{name}</span>)
          }
        </div>
      ),
    },
    level: {
      filterIcon: <FilterIcon />,
      filters: levelData,
      ellipsis: true,
      filteredValue: [level],
      filterMultiple: false,
    },
    reg_capital_std: {
      sortDirections: ['ascend', 'descend'],
      width: 120,
      ellipsis: true,
      sorter: true,
    },
    establish_date: {
      ellipsis: true,
      sorter: true,
      width: 120,
      sortDirections: ['ascend', 'descend'],
      className: style.number,
    },
    location: {
      ellipsis: true,
      filterMode: 'tree',
      filterIcon: <FilterIcon />,
      filters: regionTree,
      filterDropdown: _props => <CustomTreeFilter {..._props} />,
      filteredValue: [location],
    },
    industry: {
      ellipsis: true,
      filterMode: 'tree',
      filterIcon: <FilterIcon />,
      filters: industryTree,
      filterDropdown: _props => <CustomTreeFilter {..._props} />,
      filteredValue: industryCodeList,
    },
    tag_name: {
      width: 138,
      ellipsis: true,
      filterMode: 'tree',
      filterIcon: <FilterIcon />,
      filters: industryTree,
      filterDropdown: _props => <CustomTreeFilter {..._props} />,
      filteredValue: industryCodeList,
      render: text => (Array.isArray(text) ? text.join('、') : text),
    },
    special_tag: {
      ellipsis: true,
      filterIcon: <FilterIcon />,
      filters: tags,
      filteredValue: specialTag,
      filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
      filterResetToDefaultFilteredValue: true,
      render: text => (Array.isArray(text) ? text.join('、') : text),
    },
    accumulation_monthly: {
      // align: 'center',
      width: 100,
      sortDirections: ['descend'],
      sorter: true,
      // className: style.number,
      render: (text, record) => (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
          onClick={() => onClick && onClick({ record, column: item })}
        />
      ),
    },
    total_point: {
      width: 100,
      render: text => <Score score={text} />,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
  });
  return (
    <div className={style.box} id={title}>
      <Tabs
        checkedTab={checkedTab}
        setCheckedTab={setCheckedTab}
        data={tabsData}
      />
      <ul className={style.chartList}>
        <li>
          <p className={style.chartTitle} >
            {chartData?.[0].title || ''}
          </p>
          <div className={style.chart}>
            <HollowPie
              data={chartData?.[0].series[0]}
              isFetching={chartIsFetching}
              radius={['40%', '60%']}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >
            {chartData?.[1].title || ''}
          </p>
          <CrosswiseDar
            data={chartData?.[1]}
            isFetching={chartIsFetching}
          />
        </li>
        <li>
          <p className={style.chartTitle} >
            {chartData?.[2].title || ''}
          </p>
          <div className={style.chart}>
            <HollowPie
              data={chartData?.[2].series[0]}
              roseType="radius"
              radius={['20%', '70%']}
              isFetching={chartIsFetching}
            />
          </div>
        </li>
        {
          code?.slice(4, 6) === '00' && !isProvinceDirectly && (
          <li>
            <p className={style.chartTitle} >
              {chartData?.[3].title || ''}
              {chartData?.[3].subtitle && `(${chartData?.[3].subtitle})`}
            </p>
            <div className={style.chart}>
              <Barandline
                data={{
                  ...chartData?.[3],
                  leftYName: [0, 40, 0, 0],
                }}
                isFetching={chartIsFetching}
              />
            </div>
          </li>
          )
        }
      </ul>
      <div className={style.table}>
        <div className={style.tableTitle}>
          <Subtitle
            description={`${type === '一类专题' ? `${tableTitle}及其子公司共计` : tableTitle}` || '-'}
            value={tableTitleData ?? '-'}
            unit="家"
            className={style.subtitle}
          />
          <Subtitle
            description="筛选结果"
            value={tableData?.count ?? '-'}
            unit="家"
            className={style.subtitle}
          />
        </div>
        <Table
          {...props}
          sortKey={sortKey}
          sortValue={sortValue}
          Tablehandle={Tablehandle}
          data={tableData}
          isFetching={tableIsFetching}
          onChange={(_, filters, sorter) => {
            Object.keys(filters).forEach(item => {
              updateFiltrate(item, filters[item]);
            });
            setSortKey(sorter.field);
            setSortValue(sorter.order);
          }}
          pagination={{
            current: page,
            pageSize,
            showQuickJumper: true,
            showSizeChanger: false,
            total: tableData?.total,
            onChange: (current) => {
              setPage(current);
            },
            onShowSizeChange: (size) => {
              setPageSize(size);
            },
          }}
        />
        {/* <div className={style.childTable}> */}
        {
          childTableShow && (
            <div className={style.childTable}>
              <StandardModal
                line={false}
                header={
                  <div className={style.modalHeader}>
                    <span className={style.title}>全部专利</span>
                    <Subtitle
                      className={style.subtitle}
                      description="共"
                      value={patentNum}
                      unit="条"
                    />
                  </div>
                }
                onClose={() => {
                  setChildTableShow(false);
                }}
                className={style.modal}
              >
                <ChildTable id={childTableId} setPatentNum={setPatentNum} />
              </StandardModal>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default HighGradeCompany;
