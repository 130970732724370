import React, { useEffect, useReducer } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom'; // Redirect
import { Helmet } from 'react-helmet';
import BasicLayout from './layout/basic';
import { Context, menus } from './utils/context';
import Scheme from './pages/Scheme';

const BasicRoute = () => {
  const initValue = {
    username: '',
    uid: '',
    menus,
    key: '',
    key2: '',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'setState':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(reducer, initValue);

  useEffect(() => {
    // process.env.NODE_ENV
    if (process.env.NODE_ENV === 'development') {
      window._AMapSecurityConfig = {
        securityJsCode: '4123a266e8def6b673e79e195ca0e2cb',
      };
    } else {
      window._AMapSecurityConfig = {
        serviceHost: 'https://chanyeos.com/_AMapService',
      };
    }
  }, []);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <HashRouter>
        <Helmet>
          <meta charSet="utf-8" />
          <title>产业通 - 百条产业链一键查询、百万份报告一键生成，服务行研、招商与投资</title>
        </Helmet>
        <Switch>
          <Route path="/scheme" component={Scheme} exact />
          <Route path="/" component={BasicLayout} />
          {/* {localStorage.getItem('token') && <Route exact path="/dashboard" component={Dashboard} />} */}
        </Switch>
      </HashRouter>
    </Context.Provider>);
};

export default BasicRoute;
