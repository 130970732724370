/*
*Description: Sanky业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import SankyChart from '../../BasicsCompoment/SankyChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessSankyChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessSankyChart(props: BusinessSankyChartProps) {
  const {
    mode, label, legend = true, ip, conditions,
    onRequestComplete, title, componmentKey,
    imgRef,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data;
          if (!Array.isArray(resData.data)) {
            resData.data = [resData.data];
          }
          setData(resData.data);
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
         onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark} style={{ overflowY: 'scroll' }}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <SankyChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            label,
            legend,
            ref: imgRef,
            chartDesc,

          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BusinessSankyChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
};

// 1 . 151--产业链
export function IndustryChartindustryLinkFenxi(
  props: BusinessSankyChartProps,
) {
  const componmentKey = {
    function_key: 'industry_link_fenxi',
    class_name: '/chart/industry',
  };
  return (
    <BusinessSankyChart
      {...props}
      componmentKey={componmentKey}
    />);
}
