/**
 * 城市详情工具
*/
import { apiSlice } from '../slices/api';

const TOOL_CITY_MIGRATION = 'tool/city_migration';
const TOOL_CITY_DIAGNOSIS = 'tool/city_diagnosis';
const TOOL_ATTRACT_INVESTMENT = 'tool/attract_investment';
const TOOL_DRADED_CULTIVATION = 'tool/graded_cultivation';

export const cityToolApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 时间筛选框
    getTimeScreeningBox: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_MIGRATION}/time_screening_box`,
          params: arg,
        };
      },
    }),
    // 迁移监测-地图
    getMigrationMonitoringMap: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_MIGRATION}/migration_monitoring_map`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 迁移分析
    getMigrationAnalysisTable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_MIGRATION}/migration_analysis`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 迁移统计
    getMigrationStatistics: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_MIGRATION}/migration_statistics`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 迁移统计-子表
    getMigrationStatisticsChildTables: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_MIGRATION}/migration_statistics_child_tables`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 迁出预警
    getEmigrationWarning: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_MIGRATION}/emigration_warning`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 短板诊断
    shortPlateDiagnosis: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_DIAGNOSIS}/short_plate_diagnosis`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 环形子图 short_plate_diagnosis_subgraph
    shortPlateDiagnosisSubgraph: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_DIAGNOSIS}/short_plate_diagnosis_subgraph`,
          method: 'post',
          body: arg,
        };
      },
    }),
    //  产业链图 industry_link_current_situation
    industryLinkCurrentSituation: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_DIAGNOSIS}/industry_link_current_situation`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 短板诊断表格 industry_link_distribution_subtable
    industryLinkDistributionSubtable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_CITY_DIAGNOSIS}/industry_link_distribution_subtable`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 靶向招商 targeted_investment_promotion
    targetedInvestmentPromotion: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/targeted_investment_promotion`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 靶向招商环形子图 targeted_investment_promotion_subgraph
    targetedInvestmentPromotionSubgraph: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/targeted_investment_promotion_subgraph`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 靶向招商链图 industry_link_current_situation
    targetiIndustryLinkCurrentSituation: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/industry_link_current_situation`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 推荐企业 recommended_enterprise
    recommendedEnterprise: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/recommended_enterprise`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 已获资质tabs already_have_company_type_list
    alreadyHaveCompanyTypeList: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/already_have_company_type_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 已获资质标签前四个
    firstSpecialSubjectChart: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/first_special_subject_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 已获资质标签后6个 second_special_subject_chart
    secondSpecialSubjectChart: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/second_special_subject_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 已获资质标签前4个表格 first_special_subject_table
    firstSpecialSubjectTable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/first_special_subject_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 已获资质标签后6个表格 second_special_subject_table
    secondSpecialSubjectTable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/second_special_subject_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 一类产业总数 也就是前四个表格
    firstSpecialSubjectCount: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/first_special_subject_count`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 二类产业总数 也就是后6个表格
    secondSpecialSubjectCount: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/second_special_subject_count`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 培育企业列表 list_of_nurturing_enterprises
    listOfNurturingEnterprises: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/list_of_nurturing_enterprises`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 潜在专精特新 potential_specialized_and_very_new
    potentialSpecializedAndVeryNew: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_specialized_and_very_new`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 潜在上市企业 potential_listed_enterprise
    potentialListedEnterprise: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_listed_enterprise`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 潜在高新技术 potential_high_technology
    potentialHighTechnology: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_high_technology`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 潜在风险 potential_risk
    potentialRisk: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_risk`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 分级培育企业数量 graded_cultivation
    potentialNumber: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetTimeScreeningBoxQuery,
  useGetMigrationMonitoringMapQuery,
  useGetMigrationAnalysisTableQuery,
  useGetMigrationStatisticsQuery,
  useGetMigrationStatisticsChildTablesQuery,
  useGetEmigrationWarningQuery,
  useShortPlateDiagnosisQuery,
  useShortPlateDiagnosisSubgraphQuery,
  useIndustryLinkCurrentSituationQuery,
  useIndustryLinkDistributionSubtableQuery,
  useTargetedInvestmentPromotionQuery,
  useTargetedInvestmentPromotionSubgraphQuery,
  useTargetiIndustryLinkCurrentSituationQuery,
  useRecommendedEnterpriseQuery,
  useAlreadyHaveCompanyTypeListQuery,
  useListOfNurturingEnterprisesQuery,
  usePotentialNumberQuery,
  useLazyFirstSpecialSubjectChartQuery,
  useLazySecondSpecialSubjectChartQuery,
  useLazyFirstSpecialSubjectTableQuery,
  useLazySecondSpecialSubjectTableQuery,
  useLazyFirstSpecialSubjectCountQuery,
  useLazySecondSpecialSubjectCountQuery,
  useLazyPotentialSpecializedAndVeryNewQuery,
  useLazyPotentialListedEnterpriseQuery,
  useLazyPotentialHighTechnologyQuery,
  useLazyPotentialRiskQuery,
} = cityToolApi;
