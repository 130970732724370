import React, { useEffect, useMemo, useState } from 'react';
import { ECharts } from '@sqke/design';
import { Spin, Timeline } from 'antd';
import classNames from 'classnames';
import Container from '../../Container';
import { getGrowthProcess } from '../../../../../api/companyDetail';
// import ChartNoData from '../../ChartNoData';
import { useGetGrowthCurveQuery } from '../../../../../service/companyDetail';
import style from './index.module.less';

const colorList = [
  { color: '#1961F5', bgColor: 'rgba(217, 232, 255, 1)' },
  { color: '#3B8E00', bgColor: 'rgba(229, 249, 214, 1)' },
];

function GrowthTrack(props) {
  const { companyId } = props;
  const [growthdata, setGrowthData] = useState(null);
  const [growthTitle, setGrowthTitle] = useState('');

  const question = () => (
    <div>
      成长活跃曲线计分规则：
      <br />
      1.企业设立分公司加2分；
      <br />
      2.授权发明专利增长率&gt;5%加3分；
      <br />
      3.参与起草国际标准或国家标准加2分；
      <br />
      4.获得融资加4分；
      <br />
      5.获得国家级标签加5分，获得省级标签加3分，获得市级标签加2分，同年获得多个标签仅选择最大分值。
    </div>
  );

  useEffect(() => {
    getGrowthProcess({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setGrowthData(res.data?.series?.[0]?.data);
        setGrowthTitle(res.data?.title);
      }
    });
  }, []);
  const { data: curveData, isFetching } = useGetGrowthCurveQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });
  const curveOption = useMemo(() => {
    const series = curveData?.series?.map(item => (
      {
        ...item,
        type: 'line',
        areaStyle: {},
        data: item.data.map(val => ({
          value: val,
          unit: item.unit,
        })),
      }
    ));
    return {
      xAxis: {
        // name: curveData?.xName,
        nameTextStyle: {
          padding: [0, 0, 0, -10],
        },
        axisLabel: {
          rotate: 45,
        },
        type: 'category',
        data: curveData?.x,
      },
      tooltip: {
        formatter: (params) => (
          `${params.marker}${params.name}\u3000\u3000${params.value}${params.data.unit}`
        ),
      },
      yAxis: {
        name: curveData?.yName,
        nameTextStyle: {
          padding: [0, 30, 0, 0],
        },
      },
      series,
    };
  }, [curveData]);

  const linChartEl = useMemo(() => {
    if (!curveData) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (curveData.series?.length > 0) {
      return (<ECharts
        showLoading={isFetching}
        className={style.chart}
        option={curveOption}
      />);
    }
    return null;
  }, [curveData]);
  return (
    <>
      <Container id="发展历程" title={growthTitle}>
        {
          useMemo(() => {
            if (!growthdata) return <div style={{ minHeight: '100px' }}><Spin size="large" /></div>;
            if (growthdata.length > 0) {
              return (
                <Timeline mode="alternate">
                  {
                    growthdata?.map((item, index) => (
                      <Timeline.Item key={index}>
                        <div className={style.cell}>
                          <div className={classNames(style.title, { [style.end]: (index + 1) % 2 === 0 })} >
                            <span>{item.name}</span>
                            {
                              item.tags.map(it => (
                                <span
                                  key={it}
                                  className={style.tag}
                                  style={{
                                    color: index > 0 ? colorList[0].color : colorList[1].color,
                                    background: index > 0 ? colorList[0].bgColor : colorList[1].bgColor,
                                  }}
                                >
                                  {it}
                                </span>
                              ))
                            }
                          </div>
                          <div className={classNames(style.content, { [style.end]: (index + 1) % 2 === 0 })}>
                            <div className={style.text}>
                              {item.value}
                            </div>
                            <div className={style.popup}>
                              <span className={style.date}>{item.name}</span>
                              <span className={style.name}>{item.value}</span>
                              <div>
                                {
                                  item.honor_list.map((it, index2) => (
                                    <div key={index2} className={style.list}>
                                      <span className={style.icon} />
                                      <span className={style.text}>{it}</span>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </Timeline.Item>
                    ))
                  }
                </Timeline>
              );
            }
            return null;
          }, [growthdata])
        }
      </Container >
      <Container title={curveData?.title} question={question}>
        {linChartEl}
      </Container>
    </>
  );
}

export default GrowthTrack;
