import React, { useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { Popover } from 'antd';
import Table from '../../../../components/Table';
import styles from './index.module.less';
import {
  useGetChannelNewsColorListQuery,
  useGetChannelNewsSourceListQuery,
  useGetChannelNewsTypeListQuery,
  useGetChannelNewsListQuery,
} from '../../../../service/industry-channel';
import { openNewPage } from '../../../../utils/urlMethods';
import { useGetAreaPlugMarqueeV2Query } from '../../../../service/interactive-v2';
import { findNode } from '../../../../utils/tool';
import CustomTreeFilter from '../../../../components/CustomTreeFilter';
import { getNameConfig } from '../../../../utils/table';
import { FilterIcon } from '../../../../components/CustomTableIcon';

const Cell = ({ text }) => {
  const _text = (Array.isArray(text) ? text.join('、') : text);
  return <div className={styles.format} title={_text}>{_text}</div>;
};
const content = (item, record) => item?.map(item2 => <div className={styles.companyList} onClick={() => { openNewPage('companyDetail', { id: item2.code }); }}>{item2.name}</div>);

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: 'table-index',
  },
  title: {
    title: '舆情名称',
    dataIndex: 'title',
    key: 'title',
    // width: 330,
    render: text => <Cell text={text} />,
    className: 'table-name',
  },
  color: {
    title: '情感类型',
    dataIndex: 'color',
    key: 'color',
    width: 86,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  name: {
    title: '企业',
    dataIndex: 'name',
    key: 'name',
    width: 292,
    render: (text, record) => text.map(item => (
      <Popover content={content(text, record)} title="企业列表">
        <span>
          {item.name}
          , &nbsp;
        </span>
      </Popover>
    )),
  },
  location: {
    title: '区域',
    dataIndex: 'location',
    key: 'location',
    width: 78,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    filterMode: 'tree',
    render: text => <Cell text={text} />,
  },
  type: {
    title: '舆情类型',
    dataIndex: 'type',
    key: 'type',
    width: 180,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  source_type: {
    title: '来源类型',
    dataIndex: 'source_type',
    key: 'source_type',
    width: 100,
    filters: [],
    filterIcon: <FilterIcon />,
    ellipsis: true,
    render: text => <Cell text={text} />,
  },
  time: {
    title: '日期',
    dataIndex: 'time',
    key: 'time',
    width: 96,
    // sortDirections: ['ascend', 'descend'],
    // sorter: true,
    render: text => <Cell text={text} />,
  },
};

const COLUMN_KEYS = [
  'index', 'title', 'color', 'name', 'location', 'type', 'source_type', 'time',
];

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

function NewsTable(props) {
  const {
    keyword,
    industryCode,
    // sortedInfo = {},
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onNewsClick,
    onData,
    className,
  } = props;

  const { data: colors = [] } = useGetChannelNewsColorListQuery();
  const { data: types = [] } = useGetChannelNewsTypeListQuery();
  const { data: sources = [] } = useGetChannelNewsSourceListQuery();
  const { regionTree = [] } = useGetAreaPlugMarqueeV2Query(
    undefined,
    {
      selectFromResult: ({ data }) => {
        const root = data ? findNode(data[0], node => node.key === '000000') : null;
        if (!root) return [];
        return {
          regionTree: [root],
        };
      },
    },
  );

  const { data, isFetching } = useGetChannelNewsListQuery({
    key_word: keyword,
    industry_code: industryCode,
    page_size: pageSize,
    page_number: pageNumber,
    area_key: getSingleOption(filteredInfo.location) ?? '000000',
    news_color: getSingleOption(filteredInfo.color),
    news_type: getSingleOption(filteredInfo.type),
    news_source: getSingleOption(filteredInfo.source_type),
  }, { skip: !industryCode });
  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      const { dataIndex } = item;
      let rowConfig = ({
        ...(THEAD_TABLE[dataIndex] ?? {}),
        ...item,
      });
      switch (dataIndex) {
        case 'title':
          rowConfig = {
            ...rowConfig,
            ...getNameConfig({
              onClick: onNewsClick,
              keyword,
              // className: 'clickable-name overflow-multi-2',
              className: 'overflow-multi-2 clickable-name',
              idField: 'link',
            }),
          };
          break;
        case 'location':
          if (item.dataIndex === 'location') {
            const _code = getSingleOption(filteredInfo?.location);
            rowConfig.filters = regionTree;
            rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
            rowConfig.filtered = !!(_code && _code !== '000000');
            rowConfig.filteredValue = filteredInfo?.location ?? ['000000'];
          }
          break;
        case 'color':
          rowConfig.filters = colors;
          rowConfig.filterMultiple = false;
          rowConfig.filteredValue = filteredInfo?.color ?? [];
          break;
        case 'type':
          rowConfig.filters = types;
          rowConfig.filterMultiple = false;
          rowConfig.filteredValue = filteredInfo?.type ?? [];
          break;
        case 'source_type':
          rowConfig.filters = sources;
          rowConfig.filterMultiple = false;
          rowConfig.filteredValue = filteredInfo?.source_type ?? [];
          break;
        default:
          break;
      }
      return rowConfig;
    });
  }, [columns, columns, types, sources, regionTree, onNewsClick, filteredInfo]);

  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      current={pageNumber}
      {...props}
      className={classnames(styles.table, className)}
    />
  );
}

export default NewsTable;
