import React, { useMemo } from 'react';
import classnames from 'classnames';
import Table from '../../../../components/Table';
import {
  useGetFinancingListQuery,
  useGetChannelInvestmentRoundListQuery,
} from '../../../../service/industry-channel';
import { FilterIcon } from '../../../../components/CustomTableIcon';
import CustomListFilter from '../../../../components/CustomListFilter';
import SortTitle from '../SortTitle';
import { getSortKv } from '../../../../utils/table';
import styles from './index.module.less';

const MultiLineEllipsis = text => {
  const _text = Array.isArray(text) ? text.join('、') : text;
  return <div className="overflow-multi-5" title={_text}>{_text}</div>;
};

const THEAD_TABLE = {
  index: {
    title: '',
    dataIndex: 'index',
    key: 'index',
    width: 36,
  },
  company_name: {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    ellipsis: true,
    className: styles.name,
  },
  total_money: {
    title: '融资金额',
    dataIndex: 'total_money',
    key: 'total_money',
    width: 86,
    sortDirections: ['descend'],
    sorter: true,
  },
  round: {
    title: '融资轮次',
    dataIndex: 'round',
    key: 'round',
    width: 86,
    filters: [],
    filterIcon: <FilterIcon />,
    render: MultiLineEllipsis,
  },
  short: {
    title: '投资方',
    dataIndex: 'short',
    key: 'short',
    width: 82,
    ellipsis: true,
    render(text) {
      return (
        <span title={text?.join('\n')}>{text}</span>
      );
    },
  },
  invest_date: {
    title: '时间',
    dataIndex: 'invest_date',
    key: 'invest_date',
    width: 88,
    sortDirections: ['descend'],
    sorter: true,
  },
};

const COLUMN_KEYS = [
  'index', 'company_name', 'total_money', 'round', 'short',
];

function EquityCapitalTable(props) {
  const {
    industryCode,
    onCompanyClick,
    className,
    filteredInfo = {},
    sortedInfo = {},
  } = props;

  const {
    key: sortKey,
  } = getSortKv(sortedInfo);

  const { data: roundData } = useGetChannelInvestmentRoundListQuery();

  const { data: tableData, isFetching } = useGetFinancingListQuery({
    industry_code: industryCode,
    sort_key: sortKey ?? 'invest_date',
    round_list: (filteredInfo?.round && filteredInfo.round.filter(item => item !== 'all').length !== 0)
      ? filteredInfo.round : undefined,
  }, {
    skip: !industryCode,
  });

  const { columns = [], dataSource } = tableData?.data ?? {};
  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'company_name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      if (item.dataIndex === 'round') {
        rowConfig.filters = roundData;
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filteredValue = filteredInfo.round?.length > 0 ? filteredInfo.round : ['all'];
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [columns, onCompanyClick, filteredInfo, sortedInfo, roundData]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      {...props}
      pageSize={Number.MAX_SAFE_INTEGER}
      pagination={false}
      className={classnames(styles.equityCapitalTable, className)}
    />
  );
}

export default EquityCapitalTable;
