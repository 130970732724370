import React, { useState, useEffect } from 'react';
import style from './index.module.less';
import { FilterIcon } from '../../../../../../components/CustomTableIcon';
import CustomTreeFilter from '../../../../../../components/CustomTreeFilter';
import Score from '../../../../../../components/Score';
import { getNameConfig } from '../../../../../../utils/table';
import Subtitle from '../../../../../../components/Subtitle';
import Table from '../../components/Table';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetIndustryTreeQuery,
} from '../../../../../../service/interactive-v2';
import { usePotentialNumberQuery } from '../../../../../../service/cityTool';
import { useGetData } from './useGetData';

const sortMap = {
  descend: 'desc',
  ascend: 'asc',
};

const yesAndNo = [
  { text: '是', value: '是' },
  { text: '否', value: '否' },
];

const PotentialCompany = (props) => {
  const { title, area: { code }, isProvinceDirectly, onCompanyClick } = props;
  const [location, setLocation] = useState([code]);
  const [industryCodeList, setIndustryCodeList] = useState(['all']);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortKey, setSortKey] = useState('');
  const [sortValue, setSortValue] = useState('descend');
  const [selectTypeDict, setSelectTypeDict] = useState({
    capital_reduction: '',
    serious_violation: '',
    executive_turnover: '',
    operating_anomaly: '',
    company_downsizing: '',
    capital_chain_break: '',
  });

  // 获取表格数据
  const { data, chartIsFetching, refetch } = useGetData({
    area_key: location[0],
    sort_key: sortKey,
    industry_code_list: industryCodeList,
    sort_value: sortValue && sortMap?.[sortValue],
    select_type_dict: selectTypeDict,
    page_number: page,
  }, '潜在风险');

  // 获取总数
  const { data: count } = usePotentialNumberQuery(
    {
      area_key: code,
      label: '潜在风险',
    },
  );

  // 筛选时重置页码
  useEffect(() => {
    setPage(1);
  }, [location, industryCodeList, code]);

  // 切换城市和table时重置请求条件
  useEffect(() => {
    setPage(1);
    setLocation([code]);
    setIndustryCodeList(['all']);
    setSortKey('');
    setSortValue('descend');
  }, [code]);

  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query({
    area_code: code,
  });
  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data: TreeData }) => {
        if (!TreeData) return [];
        return {
          industryTree: [TreeData],
        };
      },
    },
  );
  const updateFiltrate = (columnKey, FiltrateData) => {
    const filtrateMap = {
      industry: () => { setIndustryCodeList(FiltrateData || ['all']); },
      location: () => { setLocation(FiltrateData || [code]); },
    };
    if (filtrateMap[columnKey]) {
      filtrateMap[columnKey]();
    }
    if (!Object.keys(selectTypeDict).includes(columnKey)) return;
    setSelectTypeDict(_props => (
      {
        ..._props,
        [columnKey]: FiltrateData?.[0],
      }
    ));
  };
  const Tablehandle = (item) => ({
    index: {
      width: 50,
      className: style.index,
    },
    company_name: {
      width: 230,
      ellipsis: true,
      className: style.name,
      ...getNameConfig(({ onClick: onCompanyClick, keyword: 'city' })),
    },
    capital_reduction: {
      ellipsis: true,
      filterIcon: <FilterIcon />,
      filters: yesAndNo,
      filterMultiple: false,
      filteredValue: [selectTypeDict.capital_reduction],
    },
    serious_violation: {
      ellipsis: true,
      filterIcon: <FilterIcon />,
      filters: yesAndNo,
      filterMultiple: false,
      filteredValue: [selectTypeDict.serious_violation],
    },
    executive_turnover: {
      ellipsis: true,
      filterIcon: <FilterIcon />,
      filters: yesAndNo,
      filterMultiple: false,
      filteredValue: [selectTypeDict.executive_turnover],
    },
    operating_anomaly: {
      ellipsis: true,
      filterIcon: <FilterIcon />,
      filters: yesAndNo,
      filterMultiple: false,
      filteredValue: [selectTypeDict.operating_anomaly],
    },
    company_downsizing: {
      ellipsis: true,
      filterIcon: <FilterIcon />,
      filters: yesAndNo,
      filterMultiple: false,
      filteredValue: [selectTypeDict.company_downsizing],
    },
    capital_chain_break: {
      width: 100,
      ellipsis: true,
      filterIcon: <FilterIcon />,
      filters: yesAndNo,
      filterMultiple: false,
      filteredValue: [selectTypeDict.capital_chain_break],
    },
    reg_capital_std: {
      width: 120,
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      sorter: true,
    },
    establish_date: {
      width: 100,
      ellipsis: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: style.number,
    },
    industry: {
      ellipsis: true,
      filterMode: 'tree',
      filterIcon: <FilterIcon />,
      filters: industryTree,
      filterDropdown: _props => <CustomTreeFilter {..._props} />,
      filteredValue: industryCodeList,
    },
    location: {
      ellipsis: true,
      filterMode: 'tree',
      filterIcon: <FilterIcon />,
      filters: regionTree,
      filterDropdown: _props => <CustomTreeFilter {..._props} />,
      filteredValue: location,
    },
    total_point: {
      width: 100,
      render: text => <Score score={text} />,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
  });
  return (
    <div className={style.box} id={title}>
      <div className={style.tableTitle}>
        <Subtitle
          description="预警企业共计"
          value={count ?? '-'}
          unit="家"
          className={style.subtitle}
        />
        <Subtitle
          description="筛选结果"
          value={data?.count ?? '-'}
          unit="家"
          className={style.subtitle}
        />
      </div>
      <Table
        {...props}
        sortKey={sortKey}
        sortValue={sortValue}
        selectTypeDict={selectTypeDict}
        Tablehandle={Tablehandle}
        data={data}
        isFetching={chartIsFetching}
        onChange={(_, filters, sorter) => {
          Object.keys(filters).forEach(item => {
            updateFiltrate(item, filters[item]);
          });
          setSortKey(sorter.field);
          setSortValue(sorter.order);
        }}
        pagination={{
          current: page,
          pageSize,
          showQuickJumper: true,
          showSizeChanger: false,
          total: data?.total,
          onChange: (current) => {
            setPage(current);
          },
          onShowSizeChange: (size) => {
            setPageSize(size);
          },
        }}
      />
    </div>
  );
};

export default PotentialCompany;
