import React, { useMemo, useEffect } from 'react';
import classnames from 'classnames';
import Table from '../../../../components/Table';
import styles from './index.module.less';
import {
  useGetCityChannelInstitutionsListQuery,
} from '../../../../service/city-channel';
import { getSingleOption } from '../../../../utils/tool';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetIndustryTreeQuery,
  useGetCityChannelInstitutionsLevelQuery,
  useGetCityChannelInstitutionsTypeQuery,
} from '../../../../service/interactive-v2';
import CustomTreeFilter from '../../../../components/CustomTreeFilter';
import { FilterIcon } from '../../../../components/CustomTableIcon';
import { getNameConfig } from '../../../../utils/table';

const Cell = ({ text }) => {
  const _text = Array.isArray(text) ? text.join('、') : text;
  return <div className="overflow-multi-2" title={_text}>{_text}</div>;
};

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  name: {
    title: '机构名称',
    dataIndex: 'name',
    key: 'name',
    render: text => <Cell text={text} />,
  },
  location: {
    title: '区域',
    dataIndex: 'location',
    key: 'location',
    width: 100,
    ellipsis: true,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
  },
  support_unit: {
    title: '依托单位',
    dataIndex: 'support_unit',
    key: 'support_unit',
    render: text => <Cell text={text} />,
  },
  tag_name: {
    title: '产业',
    dataIndex: 'tag_name',
    key: 'tag_name',
    width: 224,
    ellipsis: true,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  level: {
    title: '级别',
    dataIndex: 'level',
    key: 'level',
    width: 110,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
  type: {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    width: 210,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => <Cell text={text} />,
  },
};

const COLUMN_KEYS = ['index', 'name', 'location', 'support_unit', 'tag_name', 'level', 'type'];

function ResearchUnitTable(props) {
  const {
    keyword,
    cityCode,
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onData,
    className,
    onUnitClick,
    setParams,
  } = props;

  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query({
    area_code: cityCode,
  });
  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );
  const { data: levels = [] } = useGetCityChannelInstitutionsLevelQuery();
  const { data: types = [] } = useGetCityChannelInstitutionsTypeQuery();
  const { data, isFetching } = useGetCityChannelInstitutionsListQuery({
    key_word: keyword,
    area_key: getSingleOption(filteredInfo.location) ?? cityCode,
    industry_code: getSingleOption(filteredInfo.tag_name) ?? 'all',
    page_size: pageSize,
    page_number: pageNumber,
    institutions_level: getSingleOption(filteredInfo.level),
    institutions_type: getSingleOption(filteredInfo.type),
  }, { skip: !cityCode });
  const { total = 0, columns = [], dataSource = [] } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      const { dataIndex } = item;
      let rowConfig = ({
        ...(THEAD_TABLE[dataIndex] ?? {}),
        ...item,
      });
      switch (dataIndex) {
        case 'name':
          rowConfig = {
            ...rowConfig,
            ...getNameConfig({
              onClick: onUnitClick,
              keyword,
              className: 'clickable-name overflow-multi-2',
              idField: '_id',
            }),
          };
          break;
        case 'location':
          rowConfig.filters = regionTree;
          rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
          rowConfig.filteredValue = filteredInfo?.location ?? [cityCode];
          break;
        case 'tag_name':
          rowConfig.filters = industryTree;
          rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
          rowConfig.filteredValue = filteredInfo?.tag_name ?? ['all'];
          break;
        case 'level':
          rowConfig.filters = levels;
          rowConfig.filteredValue = filteredInfo?.level ?? [];
          rowConfig.filterMultiple = false;
          break;
        case 'type':
          rowConfig.filters = types;
          rowConfig.filteredValue = filteredInfo?.type ?? [];
          rowConfig.filterMultiple = false;
          break;
        default:
          break;
      }
      return rowConfig;
    });
  }, [onUnitClick, columns, levels, types, regionTree, industryTree, filteredInfo]);
  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        area_key: getSingleOption(filteredInfo.location) ?? cityCode,
        industry_code: getSingleOption(filteredInfo.tag_name) ?? 'all',
        institutions_level: getSingleOption(filteredInfo.level),
        institutions_type: getSingleOption(filteredInfo.type),
      });
    }
  }, [keyword, cityCode, filteredInfo]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      current={pageNumber}
      {...props}
      className={classnames(styles.table, className)}
    />
  );
}

export default ResearchUnitTable;
