/**
 * 城市详情工具
*/
import { apiSlice } from '../slices/api';

const TOOL_PREFIX = '/industryChannel';

// 靶向招商
const TOOL_ATTRACT_INVESTMENT = 'tool/park/attract_investment';
// 迁移监测
const TOOL_COMPANY_MIGRATION = 'tool/park/company_migration';
// 短板诊断
const TOOL_PARK_DIAGNOSIS = 'tool/park/park_diagnosis';
// 分级培育
const TOOL_DRADED_CULTIVATION = 'tool/park/graded_cultivation';

export const parkToolApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    /**
    * 迁移监测
    */
    // 时间筛选框
    getParkTimeScreeningBox: builder.query({
      query(arg) {
        return {
          url: `${TOOL_COMPANY_MIGRATION}/time_screening_box`,
          params: arg,
        };
      },
    }),
    // 迁移监测-地图
    getParkMigrationMonitoringMap: builder.query({
      query(arg) {
        return {
          url: `${TOOL_COMPANY_MIGRATION}/migration_monitoring_map`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 迁移分析
    getParkMigrationAnalysis: builder.query({
      query(arg) {
        return {
          url: `${TOOL_COMPANY_MIGRATION}/migration_analysis`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 迁移统计
    getParkMigrationStatistics: builder.query({
      query(arg) {
        return {
          url: `${TOOL_COMPANY_MIGRATION}/migration_statistics`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 迁移统计-子表
    getParkMigrationStatisticsChildTables: builder.query({
      query(arg) {
        return {
          url: `${TOOL_COMPANY_MIGRATION}/migration_statistics_child_tables`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 迁出预警
    getParkEmigrationWarning: builder.query({
      query(arg) {
        return {
          url: `${TOOL_COMPANY_MIGRATION}/emigration_warning`,
          method: 'post',
          body: arg,
        };
      },
    }),
    /**
     * 短板诊断
     */
    // 短板诊断
    getParkShortPlateDiagnosis: builder.query({
      query(arg) {
        return {
          url: `${TOOL_PARK_DIAGNOSIS}/short_plate_diagnosis`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 环形子图 short_plate_diagnosis_subgraph
    getParkShortPlateDiagnosisSubgraph: builder.query({
      query(arg) {
        return {
          url: `${TOOL_PARK_DIAGNOSIS}/short_plate_diagnosis_subgraph`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业链-图
    getParkIndustryLinkCurrentSituation: builder.query({
      query(arg) {
        return {
          url: `${TOOL_PARK_DIAGNOSIS}/industry_link_current_situation`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 短板诊断表格 industry_link_distribution_subtable
    getParkIndustryLinkDistributionSubtable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_PARK_DIAGNOSIS}/industry_link_distribution_subtable`,
          method: 'post',
          body: arg,
        };
      },
    }),
    /**
     * 靶向招商接口
     */
    // 靶向招商链图 industry_link_current_situation
    getParkTargetParkiIndustryLinkCurrentSituation: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/industry_link_current_situation`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 推荐企业 recommended_enterprise
    getParkRecommendedEnterpriseList: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/recommended_enterprise`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 投资机构招商-图表 equity_financing_investment_institutions_chart
    getParkEquityFinaacingChart: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/equity_financing_investment_institutions_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 投资机构招商-表格 equity_financing_investment_institutions_table
    getParkEquityFinaacingTable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/equity_financing_investment_institutions_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 投资机构招商-表格 equity_financing_investment_institutions_sum_number
    getParkEquityFinaacingNumber: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/equity_financing_investment_institutions_sum_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 投资机构招商-子表 equity_financing_investment_event_table
    getParkEquityFinaacingEventTable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/equity_financing_investment_event_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业频道投资列表 find_investment_list
    getParkFindInvestmentList: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/find_investment_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业集群-区域画像 province_company_count
    getParkProvinceCompanyCount: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/province_company_count`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业集群-区域画像-右侧表格 area_distribution_company_rank
    getParkAreaDistributionCompanyRank: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/area_distribution_company_rank`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询子公司统计数据 company_subsidiary_statistics
    getParkCompanySubsidiaryStatistics: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/company_subsidiary_statistics`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询子公司榜单 company_subsidiary_rank
    getParkCompanySubsidiaryRank: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/company_subsidiary_rank`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询子公司榜单 company_subsidiary_rank
    getParkCompanySubsidiaryCompanyList: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/area_subsidiary_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询子公司榜单 company_subsidiary_rank
    getParkCompanySubsidiaryCompanyMap: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/area_subsidiary_company_map`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 五个分公司图表 subsidiary_chart
    getParkSubsidiaryChart: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/subsidiary_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 模糊查询企业 company_fuzzy_query
    getParkCompanyFuzzyQuery: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/company_fuzzy_query`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业列表 company_list
    getParkCompanyList: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 布局情况 layout_condition
    getParkLayoutCondition: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/layout_condition`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 子公司布局 subsidiary_layout
    getParkSubsidiaryLayout: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/subsidiary_layout`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 城市分布 urban_distribution
    getParkUrbanDistribution: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/urban_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分布 industrial_distribution
    getParkIndustrialDistribution: builder.query({
      query(arg) {
        return {
          url: `${TOOL_ATTRACT_INVESTMENT}/industrial_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    /**
     * 招商培育
     */
    // 已获资质企业-类型列表 tabs already_have_company_type_list
    getParkAlreadyHaveCompanyTypeList: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/already_have_company_type_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 已获资质标签前四个（一类专题-四张图）
    getParkFirstSpecialSubjectChart: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/first_special_subject_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 二类专题-四张图
    getParkSecondSpecialSubjectChart: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/second_special_subject_chart`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 一类专题-表格
    getParkFirstSpecialSubjectTable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/first_special_subject_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 二类专题-表格
    getParkSecondSpecialSubjectTable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/second_special_subject_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 一类专题-总数
    getParkFirstSpecialSubjectCount: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/first_special_subject_count`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 二类专题-总数
    getParkSecondSpecialSubjectCount: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/second_special_subject_count`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 培育企业列表 list_of_nurturing_enterprises
    getParkListOfNurturingEnterprises: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/list_of_nurturing_enterprises`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 潜在专精特新 potential_specialized_and_very_new
    getParkPotentialSpecializedAndVeryNew: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_specialized_and_very_new`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 潜在上市企业 potential_listed_enterprise
    getParkPotentialListedEnterprise: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_listed_enterprise`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 潜在高新技术 potential_high_technology
    getParkPotentialHighTechnology: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_high_technology`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 潜在风险 potential_risk
    getParkPotentialRisk: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_risk`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 分级培育企业数量 graded_cultivation
    getParkPotentialNumber: builder.query({
      query(arg) {
        return {
          url: `${TOOL_DRADED_CULTIVATION}/potential_number`,
          method: 'post',
          body: arg,
        };
      },
    }),

    getParkChannelProvinceCompanyCount: builder.query({
      query(arg) {
        return {
          url: `${TOOL_PREFIX}/province_company_count`,
          params: arg,
        };
      },
    }),
    getParkChannelIndustryInfo: builder.query({
      query(arg) {
        return {
          url: `${TOOL_PREFIX}/industry_basic_info`,
          params: arg,
        };
      },
    }),
    getParkInvestmentInstitutionsTable: builder.query({
      query(arg) {
        return {
          url: `${TOOL_PREFIX}/equity_financing_investment_institutions_table`,
          method: 'post',
          body: arg,
        };
      },
    }),

  }),
});

export const {
  useGetParkTimeScreeningBoxQuery,
  useGetParkMigrationMonitoringMapQuery,
  useGetParkMigrationAnalysisQuery,
  useGetParkMigrationStatisticsQuery,
  useGetParkMigrationStatisticsChildTablesQuery,
  useGetParkEmigrationWarningQuery,
  useGetParkShortPlateDiagnosisQuery,
  useGetParkShortPlateDiagnosisSubgraphQuery,
  useGetParkIndustryLinkCurrentSituationQuery,
  useGetParkIndustryLinkDistributionSubtableQuery,
  useGetParkAlreadyHaveCompanyTypeListQuery,
  useGetParkListOfNurturingEnterprisesQuery,
  useGetParkPotentialNumberQuery,
  useLazyGetParkFirstSpecialSubjectChartQuery,
  useLazyGetParkSecondSpecialSubjectChartQuery,
  useLazyGetParkFirstSpecialSubjectTableQuery,
  useLazyGetParkSecondSpecialSubjectTableQuery,
  useLazyGetParkFirstSpecialSubjectCountQuery,
  useLazyGetParkSecondSpecialSubjectCountQuery,
  useLazyGetParkPotentialSpecializedAndVeryNewQuery,
  useLazyGetParkPotentialListedEnterpriseQuery,
  useLazyGetParkPotentialHighTechnologyQuery,
  useLazyGetParkPotentialRiskQuery,
  useGetParkChannelProvinceCompanyCountQuery,
  useGetParkChannelIndustryInfoQuery,
  useGetParkInvestmentInstitutionsTableQuery,

  // 靶向招商
  useGetParkTargetParkiIndustryLinkCurrentSituationQuery,
  useGetParkRecommendedEnterpriseListQuery,
  useGetParkEquityFinaacingChartQuery,
  useGetParkEquityFinaacingTableQuery,
  useGetParkEquityFinaacingNumberQuery,
  useGetParkEquityFinaacingEventTableQuery,
  useGetParkFindInvestmentListQuery,
  useGetParkProvinceCompanyCountQuery,
  useGetParkAreaDistributionCompanyRankQuery,
  useGetParkCompanySubsidiaryStatisticsQuery,
  useGetParkCompanySubsidiaryRankQuery,
  useGetParkCompanySubsidiaryCompanyListQuery,
  useGetParkCompanySubsidiaryCompanyMapQuery,
  useGetParkSubsidiaryChartQuery,
  useGetParkCompanyFuzzyQueryQuery,
  useGetParkCompanyListQuery,
  useGetParkLayoutConditionQuery,
  useGetParkSubsidiaryLayoutQuery,
  useGetParkUrbanDistributionQuery,
  useGetParkIndustrialDistributionQuery,
} = parkToolApi;
