import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import { Spin } from 'antd';
import {
  SyncOutlined,
  FileImageOutlined,
  QuestionOutlined,
  CloseOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import Table from '../../../../components/Table';
import downloadElementAsImage from '../../../../utils/downloadElementAsImage';
import { dataDownload } from '../../../../../../api/chartTableTooldataDownload';
import style from './index.module.less';

const DynamicTable = ({ data, isFetching, title, preview, id, setDescribeObj, setReportData, reportData }) => {
  const tableEl = useRef();
  const [remake, setRemake] = useState(false);
  const [edit, setEdit] = useState(false);
  const [added, setAdded] = useState(1);
  const [describe, setDescribe] = useState('');
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const dataDownloadTable = () => {
    dataDownload({ table_data: { dataSource, columns } }, `${title}(${uniqueId()})`);
  };

  // 更新报告数据
  const updateReportData = () => {
    // eslint-disable-next-line no-unused-expressions
    setReportData && setReportData(params => ({
      ...params,
      [id]: {
        ...params[id],
        text: title,
        el: { columns: columns || [], dataSource: dataSource || [] },
        contents: describe,
        data: {
          ...params[id]?.data,
          describe,
          columns: columns || [],
          dataSource: dataSource || [],
          label: `${title}`,
        },
      },
    }));
  };
  useEffect(() => {
    updateReportData();
  }, [JSON.stringify(columns), JSON.stringify(dataSource), title, describe]);

  const initData = () => {
    let newData = data;
    if (preview) {
      newData = reportData;
    }
    if (newData) {
      setColumns(newData.columns.map(item => {
        if (item.dataIndex === 'index') {
          return {
            ...item,
            width: 70,
            key: uniqueId(),
          };
        }
        if (item.dataIndex === 'name') {
          return {
            ...item,
            width: 300,
            key: uniqueId(),
          };
        }
        return { ...item, key: uniqueId() };
      }));
      setDataSource(newData.dataSource.map(item => ({ ...item, key: uniqueId() })));
    }
  };

  useEffect(() => {
    initData();
    let newData = data;
    if (preview) {
      newData = reportData;
    }
    if (!isFetching) {
      let newDataSource = '';
      if (newData?.dataSource.length) {
        newDataSource = newData.dataSource.slice(0, 3).map(item => {
          let obj = '';
          newData.columns.forEach(it => { obj += `${it.title} ${item[it.dataIndex]}`; });
          return obj;
        });
      }
      setDescribe(newDataSource);
      // eslint-disable-next-line no-unused-expressions
      setDescribeObj && setDescribeObj(params => ({ ...params, [id]: { ok: true, describe: newDataSource } }));
    }
  }, [data, isFetching, reportData]);

  useEffect(() => {
    let newDataSource;
    if (columns && dataSource && setReportData && setReportData) {
      newDataSource = dataSource.slice(0, 3).map(item => {
        let obj = '';
        columns.forEach(it => { obj += `${it.title} ${item[it.dataIndex]}`; });
        return obj;
      });
      setDescribeObj(params => ({ ...params, [id]: { ...params[id], describe: newDataSource } }));
    }
    // eslint-disable-next-line no-unused-expressions
    setReportData && setReportData(params => ({ ...params, [id]: { ...params[id], el: { columns: columns || [], dataSource: dataSource || [] }, text: title, contents: newDataSource?.join(), type: 'table', content: '' } }));
  }, [columns, dataSource]);

  /**
 *
 * @param {string} value 更新后的值
 * @param {string | number} row 那行
 * @param {string} column 那列
 */
  const updateDataSource = (value, row, column) => {
    setDataSource(params => params.map(item => {
      if (item.key === row) {
        return {
          ...item,
          [column]: value,
        };
      }
      return item;
    }));
  };
  // 更新columns
  const updateColumns = (value, index) => {
    setColumns(parsms => parsms.map(item => {
      if (item.dataIndex === index) {
        return {
          ...item,
          title: value,
          dataIndex: value,
        };
      }
      return item;
    }));
  };

  // 添加列
  const addCell = () => {
    const isAdded = columns.some(item => item.title.slice(0, 2) === '新增');
    setColumns(params => ([
      ...params,
      {
        title: `新增${isAdded ? added : 1}`,
        dataIndex: `新增${isAdded ? added : 1}`,
        key: uniqueId(),
      },
    ]));
    if (isAdded) {
      setAdded(params => params + 1);
    } else {
      setAdded(1);
      setAdded(params => params + 1);
    }
  };
  // 添加行
  const addDataSource = () => {
    setDataSource(params => {
      const newRow = { index: Math.max(...params.map(item => item.index - 0)) + 1 };
      newRow.key = uniqueId();
      columns.forEach(item => {
        if (item.dataIndex !== 'index') {
          newRow[item.dataIndex] = '';
        }
      });
      return [
        ...params,
        newRow,
      ];
    });
  };
  // 删除列
  const delColumn = (key, dataIndex) => {
    setColumns(params => params.filter(item => item.key !== key));
    setDataSource(params => {
      if (!params.length || !params) { return []; }
      params.forEach(item => {
        delete item[dataIndex];
      });
      return params;
    });
  };
  // 删除行
  const delDataSource = (key) => {
    setDataSource(params => params.filter(item => item.key !== key));
  };

  return (
    <Spin id={id} spinning={isFetching} wrapperClassName={style.loding}>
      <div id={id} className={classnames(style.box, { [style.noBorder]: preview })} >
        <div className={style.title}>
          <span>
            {title}
          </span>
          <button type="button" className={classnames(style.editBtn, { [style.edit]: edit })} onClick={(e) => { setEdit(params => !params); }}>
            {edit ? '确定' : '编辑'}
          </button>
        </div>
        <div className={style.centent}>
          <div className={style.header}>
            <div className={style.remake} onClick={() => { setRemake(true); }}>
              <span>
                <SyncOutlined />
              </span>
              <span>
                重置数据
              </span>
            </div>
            <div className={style.tool}>
              <div onClick={dataDownloadTable}>
                <span>
                  <VerticalAlignBottomOutlined />
                </span>
                <span>下载数据</span>
              </div>
              <div>
                <span>
                  <FileImageOutlined />
                </span>
                <span onClick={() => { downloadElementAsImage(tableEl.current, `${title}(${uniqueId()})`); }}>保存图片</span>
              </div>
            </div>
            {
              remake && (
                <div className={style.popup}>
                  <div className={style.popupTop}>
                    <div className={style.popupTopLeft}>
                      <span>
                        <QuestionOutlined />
                      </span>
                      <span>
                        是否重制数据?
                      </span>
                    </div>
                    <div className={style.right} onClick={() => { setRemake(false); }}>
                      <CloseOutlined />
                    </div>
                  </div>
                  <div className={style.middle}>
                    重置数据仅对当前模块生效，重置后您编辑的数据无法保存并不可撤销
                  </div>
                  <div className={style.bottom}>
                    <button type="button" onClick={() => { setRemake(false); }}>取消</button>
                    <button type="button" onClick={() => { initData(); setRemake(false); }} >确定</button>
                  </div>
                </div>
              )
            }
          </div>

          <div className={style.table}>
            <div ref={tableEl}>
              <Table
                columns={columns}
                dataSource={dataSource}
                delRow={!edit}
                delCrosswise={!edit}
                noString
                addDataSourceState={edit}
                addColumnState={edit}
                addDataSource={addDataSource}
                updateDataSource={updateDataSource}
                updateColumns={updateColumns}
                delColumn={delColumn}
                delDataSource={delDataSource}
                addCell={addCell}
              />
            </div>
          </div>
        </div>
      </div >
    </Spin>
  );
};

export default DynamicTable;
