import React, { useState } from 'react';
import { Radio } from 'antd';
import { useGetParkParkBenchmarkQuery } from '../../../../../../../service/parkDetail';
import Barandline from '../../../../../../../components/Charts/BarAndLine';
import { getSelectCompareVlaue } from '../../../../../../../utils/allMethod';
import style from './index.module.less';

const compareList = [
  {
    title: '企业数量',
    key: '企业数量',
    value: 'company_number',
  },
  {
    title: '新增企业数量',
    key: '新增企业数量',
    value: 'new_company_number',
  },
  {
    title: '上市企业数量',
    key: '上市企业数量',
    value: 'listed_company_number',
  },
  {
    title: '高新技术企业数量',
    key: '高新技术企业数量',
    value: 'high_tech_company_number',
  },
  {
    title: '专精特新企业数量',
    key: '专精特新企业数量',
    value: 'specialized_company_number',
  },
  {
    title: '单项冠军企业数量',
    key: '单项冠军企业数量',
    value: 'champion_company_number',
  },
];

const AreaCompare = ({ title, parkCode }) => {
  const [chooseType, setChooseType] = useState('企业数量');
  const { data, isFetching } = useGetParkParkBenchmarkQuery({
    park_id: parkCode,
    benchmark_type: getSelectCompareVlaue(chooseType, compareList),
  }, { skip: !parkCode });

  const onGetGroupValue = (e) => {
    setChooseType(e.target.value);
  };
  return (
    <div className={style.box} id={title}>
      <p className={style.title}>{title}</p>
      <Radio.Group
        value={chooseType}
        buttonStyle="solid"
        onChange={onGetGroupValue}
        style={{ marginTop: 14 }}
      >
        {
          (compareList || []).map((item, index) => (
            <Radio.Button
              key={index}
              value={item.key}
              style={{ marginRight: 9, fontSize: 14, fontFamily: 'AppleSystemUIFont' }}
            >
              {item.title}
            </Radio.Button>))
        }
      </Radio.Group>
      <div className={style.chart}>
        <Barandline
          data={{
            ...data,
            leftYName: [0, 30, 0, 0],
            highlight: data?.highlight[0],
          }}
          isFetching={isFetching}
          scoreShow
          showNumber={20}
          contentTop={60}
        />
      </div>
    </div>
  );
};

export default AreaCompare;
