import React from 'react';
import RectangularTreeGraph from './RectangularTreeGraph';
import AnnotateDetails from './AnnotateDetails';
import StandardDetails from './StandardDetails';

const PerformanceCriteria = (props) => (
  <>
    <RectangularTreeGraph {...props} />
    <AnnotateDetails {...props} />
    <StandardDetails {...props} />
  </>
);

export default PerformanceCriteria;
