/*
 *Description: Graph
 *Date: Thu Dec 02 2021 11:37:52 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { GraphChartProps } from '../basicsType';
import { addWaterMark, breakStringToLines, getWordsWidth, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-graph-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const GraphChart = forwardRef((props: GraphChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize } = props;
  const [hanldeData, setHanldeData] = useState<any>({
    nodes: [],
    links: [],
  });
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;
    const colors = curStyle.color;
    const cL = colors.length;

    const { links, nodes, maxNodeVal, minNodeVal } = hanldeData;
    let maxSymbolSize = -Infinity;
    nodes.forEach((item: any) => {
      const colorIndex = Math.round((item.value * (cL - 1)) / maxNodeVal);
      item.symbolSize = (8 * (item.value - minNodeVal))
        / (maxNodeVal - minNodeVal === 0 ? 1 : maxNodeVal - minNodeVal) + 4;
      maxSymbolSize = Math.max(maxSymbolSize, item.symbolSize);
      item.itemStyle = {
        color: colors[cL - 1 - colorIndex],
      };
      item.label = {
        color: colors[cL - 1 - colorIndex],
      };
    });
    const series = [{
      name: 'graph',
      type: 'graph',
      layout: 'circular',
      width: 'auto',
      height: 'auto',
      top: 'middle',
      left: 'center',
      circular: {
        rotateLabel: true,
      },
      lineStyle: {
        color: '#C5D5EA',
        curveness: 0.2,
      },
      // zoom: 0.5,
      data: nodes,
      links,
      label: {
        distance: 4,
        show: label,
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
        position: 'top',
        formatter(params: any) {
          const { name } = params;
          return breakStringToLines(name, 100).str;
        },
      },
    }];

    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };
    setOption(options);

    if (!chartRef.current) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    const conWidth = instance.getWidth();
    const conHeight = instance.getHeight();
    const validWidth = conWidth;
    const validHeight = conHeight;

    const r = Math.min(validWidth, validHeight) / 2;

    let maxlabelRadius = -Infinity;
    let maxNode = {
      name: '',
      symbolSize: 0,
    };
    nodes.forEach((node: any, _index: number) => {
      const { name } = node;
      const curlabelLong = getWordsWidth(name, { font: `${series[0].label.fontSize}px sans-serif` });
      const projOnX = (curlabelLong + series[0].label.distance + node.symbolSize / 2 + 10);
      if (projOnX > maxlabelRadius) {
        maxNode = node;
      }
      maxlabelRadius = Math.max(maxlabelRadius, projOnX);
    });
    // 思路， 最长label的边界触及到 包围块的最大内切圆
    // to do 可能不够精确
    let shirnkSize = (r - maxlabelRadius) * 2 * 100;
    while (shirnkSize <= 10000) { // 最小直径为100 当前容器的尺寸已经不足以容纳该图形了
      if (series[0].label.distance !== 1) {
        series[0].label.distance -= 1;
      }
      if (series[0].label.fontSize !== 1) {
        series[0].label.fontSize -= 1;
      }
      maxlabelRadius = getWordsWidth(maxNode.name, {
        font: `${series[0].label.fontSize}px sans-serif`,
      }) + series[0].label.distance + (maxSymbolSize / 2); // maxSymbolSize取节点中最大的值
      shirnkSize = (r - (maxlabelRadius)) * 2 * 100;
      if (series[0].label.fontSize === series[0].label.distance && (
        series[0].label.distance === 1
      )) { // 这回真没救了
        break;
      }
    }
    if (conWidth < conHeight) {
      series[0].width = `${shirnkSize / conWidth}%`;
    } else {
      series[0].height = `${shirnkSize / conHeight}%`;
      // series[0].top = maxlabelRadius;
    }
    // series[0].left = (conWidth - 2 * r) / 2;

    if (series[0].data.length) {
      // console.log(series[0]);
    }
  }, [hanldeData, fontSize, label, legend, mode, title]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default GraphChart;

GraphChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
