import { highlightKeyword } from "./tool";
import classnames from 'classnames';

export const SORT_KEY = {
  ascend: 'asc',
  descend: 'desc',
};

export const getSortKv = sortedInfo => {
  const sortValue = SORT_KEY[sortedInfo?.order];
  const sortKey = sortValue ? sortedInfo?.columnKey : undefined;
  return {
    key: sortKey,
    value: sortValue,
  };
}

export const getNameConfig = ({ onClick, keyword, className, idField, joinSyntax = '、' }) => {
  return ({
    render: (text, record, index) => {
      const _text = Array.isArray(text) ? text.join(joinSyntax) : text;
      return (
        <div
          className={classnames('overflow-line', className)}
          data-id={(idField && record[idField]) ? record[idField] : undefined}
          title={_text}
          onClick={() => onClick && onClick(record, index)}
        >
          {highlightKeyword(_text, keyword, 'table-emphasis')}
        </div>
      );
    },
  });
};


export const getHighlightConfig = ({ keyword, className, joinSyntax = '、' }) => {
  return ({
    render: text => {
      const _text = Array.isArray(text) ? text.join(joinSyntax) : text;
      return (
        <div className={classnames('overflow-line', className)} title={_text}>
          {highlightKeyword(
            _text,
            keyword,
            'table-emphasis'
          )}
        </div>
      )
    },
  });
};

export const getSorterConfig = () => {
  return ({
    title: ({ sortOrder, sortColumns, filters }) => {
      const sortedColumn = sortColumns?.find(({ column }) => column.key === "name");
      return (
        <div>
          Name Title
          {sortedColumn ? (
            sortedColumn.order === "ascend" ? (
              // <SortUpIcon />12
              12
            ) : (
              23
              // <SortDownIcon />23
            )
          ) : null}
        </div>
      );
    }
  })
};