/*
 *Author: zhaochenyu
 *Description: 招商报告
 *Date: 2022/02/14
*/
import React, { useMemo } from 'react';
import FrontCover from './FrontCover';
import BackCover from './BackCover';
import ReportChart from '../ReportChart';
import style from '../index.module.less';

function InvestmentPromotionReport(props) {
  const { chartConditions, chartTitlesRef, refList, chartTitles, pushToAchor,
    getChartFinishedPercent, buyStatus } = props;
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  return (
    <>
      {buyStatus ? (
        <>
          <div className={style.first}>
            {/* 首页封皮 */}
            <FrontCover area={urlReportMsg.area} industry={urlReportMsg.industry} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor(`一、${urlReportMsg.industry}产业发展现状`)}>
              <div className={style.firstTitle}>{`一、${urlReportMsg.industry}产业发展现状`}</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）全国产业规模')}>
              <div className={style.secondTitle}>（一）全国产业规模</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor(`（二）${urlReportMsg.area}产业规模`)}>
              <div className={style.secondTitle}>{`（二）${urlReportMsg.area}产业规模`}</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>3</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor(`二、${urlReportMsg.industry}产业全景`)}>
              <div className={style.firstTitle}>{`二、${urlReportMsg.industry}产业全景`}</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）全国产业链全景')}>
              <div className={style.secondTitle}>（一）全国产业链全景</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor(`（二）${urlReportMsg.area}产业基础`)}>
              <div className={style.secondTitle}>{`（二）${urlReportMsg.area}产业基础`}</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、招商地图')}>
              <div className={style.firstTitle}>三、招商地图</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）产业集群')}>
              <div className={style.secondTitle}>（一）产业集群</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）集群城市 TOP10')}>
              <div className={style.secondTitle}>（二）集群城市 TOP10</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、产业招商长名单')}>
              <div className={style.firstTitle}>四、产业招商长名单</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
          </div>
          {/* 第一页 */}

          <div className={style.page}>
            <div className={style.primaryTitle} id={`一、${urlReportMsg.industry}产业发展现状`}>{`一、${urlReportMsg.industry}产业发展现状`}</div>
            <div className={style.secondaryTitle} id="（一）全国产业规模">（一）全国产业规模</div>
            <div className={style.tertiaryTitle} id="1. 企业总量">1. 企业总量</div>
            {/* 下钻三层股权穿透图 */}
            {chartConditions.length > 0 && (
            <ReportChart
              chartMsg={chartConditions[0]}
              chartIndex={0}
              ref={chartTitlesRef.current[0]}
              chartHeight={460}
              refList={refList}
              title={`图1 ${chartTitles[0] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle} id="2. 产业金字塔">2. 产业金字塔</div>
            {/* 对外投资及控股企业数量 */}
            {chartConditions.length > 1 && (
            <ReportChart
              chartMsg={chartConditions[1]}
              chartIndex={1}
              ref={chartTitlesRef.current[1]}
              chartHeight={500}
              refList={refList}
              title={`图2 ${chartTitles[1] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高成长企业：近三年获得过融资的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id={`（二）${urlReportMsg.area}产业规模`}>{`（二）${urlReportMsg.area}产业规模`}</div>
            <div className={style.tertiaryTitle} id="1. 企业总量">1. 企业总量</div>
            {/* 所获资质标签名称及获得年份 */}
            {chartConditions.length > 2 && (
            <ReportChart
              chartMsg={chartConditions[2]}
              chartIndex={2}
              ref={chartTitlesRef.current[2]}
              chartHeight={560}
              refList={refList}
              title={`图3 ${chartTitles[2] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle} id="2. 产业金字塔">2. 产业金字塔</div>
            {/* 对外投资及控股企业数量 */}
            {chartConditions.length > 3 && (
            <ReportChart
              chartMsg={chartConditions[3]}
              chartIndex={3}
              ref={chartTitlesRef.current[3]}
              chartHeight={500}
              refList={refList}
              title={`图4 ${chartTitles[3] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高成长企业：近三年获得过融资的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id={`二、${urlReportMsg.industry}产业全景`}>{`二、${urlReportMsg.industry}产业全景`}</div>
            <div className={style.secondaryTitle} id="（一）全国产业链全景">（一）全国产业链全景</div>
            {/* 创新融资能力行业地位 */}
            {chartConditions.length > 4 && (
            <ReportChart
              chartMsg={chartConditions[4]}
              chartIndex={4}
              ref={chartTitlesRef.current[4]}
              chartHeight={600}
              refList={refList}
              title={`图5 ${chartTitles[4] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：图中展示产业链及全国综合排名靠前的企业，黄色字体的为本区域企业。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id={`（二）${urlReportMsg.area}产业基础`}>{`（二）${urlReportMsg.area}产业基础`}</div>
            {/* 公司参与起草相关产业标准类型分布 */}
            {chartConditions.length > 5 && (
            <ReportChart
              chartMsg={chartConditions[5]}
              chartIndex={5}
              ref={chartTitlesRef.current[5]}
              chartHeight={600}
              refList={refList}
              title={`图6 ${chartTitles[5] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：图中展示产业链及区域综合排名靠前的企业，灰色字体代表暂未有企业明确从事该环节。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            {/* 公司参与起草相关产业标准明细 */}
            {chartConditions.length > 6 && (
            <ReportChart
              chartMsg={chartConditions[6]}
              chartIndex={6}
              ref={chartTitlesRef.current[6]}
              chartHeight={500}
              refList={refList}
              title={`图7 ${chartTitles[6] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="三、招商地图">三、招商地图</div>
            <div className={style.secondaryTitle} id="（一）产业集群">（一）产业集群</div>
            {/* 公司最新起草标准详情 */}
            {chartConditions.length > 7 && (
            <ReportChart
              chartMsg={chartConditions[7]}
              chartIndex={7}
              ref={chartTitlesRef.current[7]}
              chartHeight={600}
              refList={refList}
              title={`图8 ${chartTitles[7] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）集群城市 TOP10">（二）集群城市 TOP10</div>
            {/* 公司专利情况 */}
            {chartConditions.length > 8 && (
            <ReportChart
              chartMsg={chartConditions[8]}
              chartIndex={8}
              ref={chartTitlesRef.current[8]}
              chartHeight={400}
              refList={refList}
              title={`表1 ${chartTitles[8] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="四、产业招商长名单">四、产业招商长名单</div>
            {/* 公司授权专利类型 */}
            {chartConditions.length > 9 && (
            <ReportChart
              chartMsg={chartConditions[9]}
              chartIndex={9}
              ref={chartTitlesRef.current[9]}
              chartHeight={800}
              refList={refList}
              title={`表2 ${chartTitles[9] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.last}>
            {/* 尾页 */}
            <BackCover />
          </div>
        </>
      ) : (
        <>
          <div className={style.first}>
            {/* 首页封皮 */}
            <FrontCover area={urlReportMsg.area} industry={urlReportMsg.industry} />
          </div>
          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor(`一、${urlReportMsg.industry}产业发展现状`)}>
              <div className={style.firstTitle}>{`一、${urlReportMsg.industry}产业发展现状`}</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）全国产业规模')}>
              <div className={style.secondTitle}>（一）全国产业规模</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor(`（二）${urlReportMsg.area}产业规模`)}>
              <div className={style.secondTitle}>{`（二）${urlReportMsg.area}产业规模`}</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>3</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor(`二、${urlReportMsg.industry}产业全景`)}>
              <div className={style.firstTitle}>{`二、${urlReportMsg.industry}产业全景`}</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）全国产业链全景')}>
              <div className={style.secondTitle}>（一）全国产业链全景</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor(`（二）${urlReportMsg.area}产业基础`)}>
              <div className={style.secondTitle}>{`（二）${urlReportMsg.area}产业基础`}</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、招商地图')}>
              <div className={style.firstTitle}>三、招商地图</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）产业集群')}>
              <div className={style.secondTitle}>（一）产业集群</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）集群城市 TOP10')}>
              <div className={style.secondTitle}>（二）集群城市 TOP10</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、产业招商长名单')}>
              <div className={style.firstTitle}>四、产业招商长名单</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default InvestmentPromotionReport;
