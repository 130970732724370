/*
* Author: zhangmeng
* Description: '发展历程'
* Date: 2021/05/19
*/

import React, { useCallback, useState } from 'react';
import style from './index.module.less';

function TimeLine(props) {
  const { data } = props;
  const [scroll, setScroll] = useState(false);
  const ctnRef = useCallback(node => {
    if (node) {
      if (node.scrollWidth > node.clientWidth) {
        setScroll(true);
      }
    }
  }, []);

  return (
    <div className={style.all}>
      <div className={style.timeLineBox}>
        <div className={style.backImg}>
          <div className={style.timeLine} />
        </div>
        <div className={style.lineBox}>
          <div className={style.line} />
        </div>
        <div className={style.content} ref={ctnRef} style={{ marginTop: scroll ? '5px' : '0' }}>
          {
            data && data.map((item, index) => (
              <div className={style.item} key={index}>
                <div className={style.top}>
                  {index % 2 ? <span title={item.desc}>{item.desc}</span> : item.year}
                </div>
                <div className={style.circleBox}>
                  <div className={style.circle} />
                </div>
                <div className={style.bottom} style={{ textAlign: index % 2 ? 'center' : '' }}>
                  {index % 2 ? item.year : <span title={item.desc}>{item.desc}</span>}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default TimeLine;
