/**
数据搜索
*/

import { apiSlice } from '../slices/api';

const DATASEARCHV2 = '/dataSearch';
const INTERACTIONV2 = '/interaction';
const HOME_PAGE = '/homePage';

export const dataSearchApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEveryCount: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_every_count`,
          params: arg,
        };
      },
    }),
    getAllFilter: builder.query({
      query(arg) {
        return {
          url: `${INTERACTIONV2}/find_all_filtrate_list`,
          params: arg,
        };
      },
    }),
    postCityTree: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_city_tree`,
          method: 'post',
          body: arg,
        };
      },
    }),
    postIndustryTree: builder.query({
      query(arg) {
        return {
          // url: `${DATASEARCHV2}/search_industry_tree`,
          url: `${INTERACTIONV2}/search_industry_tree`,
          params: arg,
        };
      },
    }),
    getAllData: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_all_data`,
          params: arg,
        };
      },
    }),
    getSearchIndustryList: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_industry_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getSearchCompanyList: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getPatentList: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_patent_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getSearchFinancingList: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_financing_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getPolicyList: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_policy_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getNewsList: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_news_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getReportList: builder.query({
      query(arg) {
        return {
          url: `${DATASEARCHV2}/search_research_report_list`,
          method: 'post',
          body: arg,
        };
      },
      // keepUnusedDataFor: 0,
    }),
    // 行业分类列表 /industry_knowledge_engine/v2/homePage/find_industry_group_list
    findIndustryGroupList: builder.query({
      query(arg) {
        return {
          url: `${HOME_PAGE}/find_industry_group_list`,
          params: arg,
        };
      },
    }),
    // 研报发布机构 /industry_knowledge_engine/v2/interaction/find_report_publisher
    findReportPublisher: builder.query({
      query(arg) {
        return {
          url: `${INTERACTIONV2}/find_report_publisher`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 统计研报查看数量 /industry_knowledge_engine/v2/researchReportDetail/research_report_count
    researchReportCount: builder.query({
      query(arg) {
        return {
          url: 'researchReportDetail/research_report_count',
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetAllFilterQuery,
  usePostCityTreeQuery,
  usePostIndustryTreeQuery,
  useLazyPostIndustryTreeQuery,
  useGetAllDataQuery,
  useGetSearchIndustryListQuery,
  useGetSearchCompanyListQuery,
  useGetPatentListQuery,
  useGetSearchFinancingListQuery,
  useGetPolicyListQuery,
  useGetNewsListQuery,
  useGetReportListQuery,
  useGetEveryCountQuery,
  useFindIndustryGroupListQuery,
  useFindReportPublisherQuery,
  useLazyResearchReportCountQuery,
} = dataSearchApi;
