import React, { useEffect, useState, useRef } from 'react';
import { message, Modal, Button, Form, Input } from 'antd';
import { EyeOutlined, EnterOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import confirmIcon from '../../../../assets/images/dataAnalysisTool/myReport/confirmIcon.png';
import { setReportDataStore, setIntelligentDataStore } from '../../store';
import Content from './Content';
import Catalogue from './Catalogue';
import { useGetSideTableContentsQuery } from '../../../../service/chartTableTool';
import { isArrayMultiDimensional } from '../../utils/public';
import style from './index.module.less';

const ChartTableTool = (props) => {
  const commonRef = useRef(null);
  const contentRef = useRef(null);
  const { data } = useGetSideTableContentsQuery();
  const [reportData, setReportData] = useState({});
  const [describeObj, setDescribeObj] = useState({});
  // 存储智能撰写
  const [intelligentWriting, setIntelligentWriting] = useState([]);
  const [reportTitle, setReportTitle] = useState({});
  const [dataFilterParams, setDataFilterParams] = useState(null);
  const [projectId, setProjectId] = useState();
  const [refreshAble, setRefreshAble] = useState({});
  const [targetLocation, setTargetLocation] = useState();
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newReportTitle, setNewReportTitle] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { params = {}, projectDetails = {}, id } = JSON.parse(localStorage.getItem('dataFilterParams')) || {};
    setReportTitle(projectDetails);
    setProjectId(id);
    const toServerData = (list) => {
      const arr = [];
      if (list?.length > 0) {
        list.forEach(item => {
          arr.push(item[item.length - 1]);
        });
      }
      return arr;
    };
    if (JSON.stringify(params) === '{}') {
      params.project_id = id - 0;
    }
    params.industry_code_list = toServerData(params?.industry_code_list);
    params.park_id_list = toServerData(params?.park_id_list);
    params.region_code_list = isArrayMultiDimensional(params?.region_code_list) ? toServerData(params?.region_code_list) : params?.region_code_list;
    setDataFilterParams(params);
  }, []);
  const generateReportData = () => {
    localStorage.setItem('reportData', JSON.stringify(reportData));
    localStorage.setItem('intelligentWriting', JSON.stringify(intelligentWriting));
    localStorage.setItem('reportTitle', JSON.stringify(newReportTitle));
    dispatch(setReportDataStore(reportData));
    const storeWriting = intelligentWriting?.filter(i => i.text) || [];
    dispatch(setIntelligentDataStore(storeWriting));
    const go = Object.keys(describeObj).every((value, index) => describeObj[value].ok);
    const refreshStatus = Object.keys(refreshAble).every((value) => refreshAble[value]) || JSON.stringify(refreshAble) === '{}';
    if (!go || !refreshStatus) {
      message.warning('请在数据加载完毕后预览');
      return;
    }
    // 跳转的时候将撰写存入rudex
    history.block(location => true);
    history.push('/dataAnalysisTool/reportPreview');
  };

  // 点击退出弹窗取消
  const handleExitClose = () => {
    setIsExitModalOpen(false);
    history.block(location => true);
    history.push('/dataAnalysisTool/chartTableTool');
    setTargetLocation();
  };

  // 点击退出弹窗确定
  const handleExitConfirm = () => {
    setIsExitModalOpen(false);
    history.block(location => true);
    history.push(targetLocation);
  };

  // 点击编辑报告弹窗取消
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  // 点击编辑弹窗确定
  const handleEditConfirm = (values) => {
    setNewReportTitle(values.title);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    const cancel = history.block(location => {
      setIsExitModalOpen(true);
      setTargetLocation(location);
      return false;
    });
    return () => cancel();
  }, [isExitModalOpen]);

  const beforeunloadListener = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeunloadListener);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const writeList = data.filter(item => item.meta_data?.type === 'data_analysis');
      const list = [];
      if (writeList?.[0]?.child?.length > 0) {
        writeList[0].child.forEach(item => {
          list.push({ id: item.id });
        });
      }
      setIntelligentWriting(list);
    }
  }, [data]);

  return (
    <div className={style.box} ref={commonRef}>
      <div className={style.catalogue}>
        <Catalogue data={data} contentRef={contentRef} />
      </div>
      <div className={style.content} ref={contentRef}>
        <div className={style.titleBox}>
          <div className={style.title}>
            <span>
              {newReportTitle || reportTitle.title}
              <span className={style.editBtn} onClick={() => setIsEditModalOpen(true)} />
            </span>
            <div className={style.tool}>
              <div onClick={() => { history.push(`/dataAnalysisTool/dataFilter?id=${projectId}`); }}>
                <span className={style.out}>
                  <EnterOutlined />
                </span>
                <span>返回</span>
              </div>
              <div onClick={generateReportData}>
                <span>
                  <EyeOutlined />
                </span>
                <span>预览</span>
              </div>
            </div>
          </div>
        </div>
        <Content
          data={data}
          setReportData={setReportData}
          dataFilterParams={dataFilterParams}
          setIntelligentWriting={setIntelligentWriting}
          describeObj={describeObj}
          setDescribeObj={setDescribeObj}
          refreshAble={refreshAble}
          setRefreshAble={setRefreshAble}
        />
      </div>
      <Modal
        title={
          <div className={style.exitTitle}>
            <img src={confirmIcon} alt="" />
            是否确认退出当前页面？
          </div>
        }
        footer={null}
        open={isExitModalOpen}
        onCancel={handleExitClose}
        width={360}
        wrapClassName={style.dataToolModal}
      >
        <div className={style.exitContent}>
          <div className={style.text}>关闭当前页面将导致您手动修改的图表和数据无法保存</div>
          <div className={style.buttonBox}>
            <Button onClick={handleExitClose} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={handleExitConfirm}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title='编辑报告'
        footer={null}
        open={isEditModalOpen}
        onCancel={handleEditCancel}
        destroyOnClose
        width={380}
        wrapClassName={style.dataToolModal}
      >
        <div className={style.modalContent}>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={handleEditConfirm}
            initialValues={newReportTitle ? { title: newReportTitle } : (reportTitle || null)}
          >
            <Form.Item
              name='title'
              label='报告名称'
              rules={[
                {
                  validator: (rule, value, callback) => {
                    const reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
                    if (!reg.test(value)) {
                      callback(new Error('只能输入汉字字母和数字'));
                    } else {
                      callback();
                    }
                  },
                  trigger: 'blur',
                },
              ]}
            >
              <Input
                placeholder='请输入报告名称'
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{ marginBottom: 0 }}>
              <Button htmlType="button" onClick={handleEditCancel} style={{ marginRight: 12 }}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ChartTableTool;
