/*
 *Author: zhaochenyu
 *Description: 产业频道
 *Date: 2022/06/21
*/

import React, { useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import Banner from '../../components/Bannner';
import SideNavi from '../../components/SideNavi';
import styles from './index.module.less';
import DataScheme from './components/DataScheme';
import RankingScheme from './components/RankingScheme';
import ReportScheme from './components/ReportScheme';
import { useQuery } from '../../hooks/url';
import GraphScheme from './components/GraphScheme';
import {
  useGetChannelDataStatisticQuery,
  useGetChannelIndustryInfoQuery,
} from '../../service/industry-channel';

const SIDE_NAVI_OPTIONS = [
  { key: 'graph', name: '图谱' },
  { key: 'industryReport', name: '报告' },
  { key: 'industryRanking', name: '榜单' },
  { key: 'industryDataV2', name: '数据' },
];

/**
 * # 产业频道页面
 * 通过 url 中定义 type、data-type、industry-code 改变其中显示的内容
 * @returns {JSX.Element}
 * @constructor
 */
function Industry() {
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const hiddenIndustryTopLeftTab = menu.find(item => item === 'hiddenIndustryTopLeftTab');
  const sideNaviOption = useMemo(() => {
    const arr = [];
    SIDE_NAVI_OPTIONS.forEach(opt => {
      if (menu.find(item => item === opt.key)) {
        arr.push(opt);
      }
    });
    return arr;
  }, [menu]);
  const mainRef = useRef();
  const query = useQuery();
  const tabIndex = query.get('type');
  const industryCode = useMemo(() => {
    const code = query.get('industry-code');
    if (code) {
      const _code = window.atob(query.get('industry-code'));
      return _code;
    }
    if (!code && process.env.NODE_ENV === 'development') {
      return 'INB1309';
    }
    return undefined;
  }, [query]);
  const { data: industryInfo } = useGetChannelIndustryInfoQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });
  const { name: industryName } = industryInfo ?? {};
  const history = useHistory();

  const { data: dataTabs = [] } = useGetChannelDataStatisticQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  if (!industryCode) return null;

  return (
    <div className={styles.page}>
      {
        !hiddenIndustryTopLeftTab && <Banner title={industryName} className={styles.banner} />
      }
      <main className={styles.main} ref={mainRef} style={hiddenIndustryTopLeftTab ? { height: 'calc(100vh - 20px)' } : {}}>
        <div className={styles.content}>
          {
            !hiddenIndustryTopLeftTab && (
              <SideNavi
                options={sideNaviOption}
                selectedKey={tabIndex}
                onClick={({ key, name }) => {
                  mainRef.current?.scrollTo({ top: 0 });
                  if (!dataTabs[0]) {
                    message.warning('数据加载中，请稍后！');
                    return;
                  }
                  query.set('type', key);
                  if (key === 'industryDataV2') {
                    query.set('data-type', dataTabs[0]?.title);
                    query.set('value', dataTabs[0]?.value);
                  } else {
                    query.delete('data-type');
                    query.delete('value');
                  }
                  history.push({ search: query.toString() });
                }}
                clasName={styles.side}
                vLineClassName={styles.sideLine}
              />
            )
          }
          <section className={styles.section} style={hiddenIndustryTopLeftTab ? { margin: '0 auto', height: '100%' } : {}}>
            {
              tabIndex === 'graph' && (
                <GraphScheme
                  industryCode={industryCode}
                  mainRef={mainRef}
                />
              )
            }
            {
              tabIndex === 'industryReport' && (
                <ReportScheme
                  industryCode={industryCode}
                  industryName={industryName}
                />
              )
            }
            {
              tabIndex === 'industryRanking' && (
                <RankingScheme
                  industryCode={industryCode}
                />
              )
            }
            {
              tabIndex === 'industryDataV2' && (
                <DataScheme
                  industryCode={industryCode}
                />
              )
            }
          </section>
        </div>
      </main>
    </div>
  );
}

export default Industry;
