/*
 *Author: zhaochenyu
 *Description: 专利轮播表格
 *Date: 2021/09/29
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import CarouselTable from '../../BasicsCompoment/CarouselTable';
import { post } from '../../utils/request';
import { handleWithIPList } from '../../utils/tool';
import { BusinessCarouselTableProps } from '../businessType';
import './index.less';
import '../../index.less';

const sortImg = ['https://i.niupic.com/images/2021/09/29/9DtH.png', 'https://i.niupic.com/images/2021/09/29/9DtI.png', 'https://i.niupic.com/images/2021/09/29/9DtF.png'];

const prefixCls: string = 'cube-component-patent-carousel';
const styleImgOrder = classNames(`${prefixCls}-img-order`);
const styleSpin = classNames(`${prefixCls}-spin`);

const { useState, useMemo, useEffect, useCallback } = React;

function PatentCarouselTable(props: BusinessCarouselTableProps) {
  const { mode, ip, conditions, speed = 3000, showAmount = 10 } = props;
  const [carouselData, setCarouselData] = useState<any>({});

  const handleWithCompanyHead = useCallback((handleHead: any[]) => (
    handleHead.map(item => {
      let res;
      switch (item.dataIndex) {
        case 'index':
          res = {
            ...item,
            width: '15%',
            align: 'right',
            render: (text: any, _: any, index: number) => (
              <>
                {index < 3 ? <img className={styleImgOrder} src={sortImg[index]} alt="" /> : ''}
                {text}
              </>),
          };
          break;
        case 'name':
          res = {
            ...item,
            width: '50%',
            align: 'left',
          };
          break;
        default:
          res = {
            ...item,
            align: 'left',
          };
          break;
      }
      return res;
    })), []);

  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}:8902/patentChart`, {
        ...conditions,
      }).then(res => {
        if (res.status === '00000') {
          const { columns } = res.data;
          setCarouselData({
            ...res.data,
            columns: handleWithCompanyHead(columns),
          });
        } else {
          setCarouselData({
            columns: [],
            dataSource: [],
          });
        }
      });
    }
  }, [ip, conditions]);

  const table = useMemo(() => (
    <CarouselTable
      mode={mode || 'dark'}
      speed={speed}
      data={carouselData}
      showAmount={showAmount}
    />
  ), [carouselData, mode, speed, showAmount]);
  return (Object.keys(carouselData).length > 0 ? table : (
    <div className={styleSpin}>
      <Spin tip="加载中..." />
    </div>
  ));
}

export default PatentCarouselTable;
