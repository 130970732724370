import G6 from '@antv/g6';
import { clamp } from './utils';

const COLORS = {
  B: '#5B8FF9',
  R: '#F46649',
  Y: '#EEBC20',
  G: '#5BD8A6',
  DI: '#A7A7A7',
};

const RECT_SELECT_STYLE = {
  backgroundColor: '#0646C9',
  borderColor: '#1961F5',
  color: '#fff',
  shadowColor: '#D9DFE5',
  shadowBlur: 8,
  shadowOffsetX: 2,
  shadowOffsetY: 4,
};

const RECT_HOVER_STYLE = {
  backgroundColor: '#4582FF',
  borderColor: '#1961F5',
  color: '#fff',
  shadowColor: '#D9DFE5',
  shadowBlur: 8,
  shadowOffsetX: 2,
  shadowOffsetY: 4,
};

const RECT_ACTIVE_STYLE = {
  backgroundColor: '#0646C9',
  borderColor: '#1961F5',
  color: '#fff',
  shadowColor: '#D9DFE5',
  shadowBlur: 8,
  shadowOffsetX: 2,
  shadowOffsetY: 4,
};

const RECT_DISABLED_STYLE = {
  backgroundColor: '#e6e6e6',
  borderColor: '#dcdcdc',
  color: '#969696',
  shadowColor: 'transparent',
  shadowBlur: 0,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
};

const LIMIT_DEPTH = 1;

const measureTextWidth = (ctx, size, text) => {
  ctx.save();
  ctx.font = `${size}px "Pingfang SC", "Microsoft Yahei", sans-serif`;
  const textWidth = ctx.measureText(text).width;
  ctx.restore();
  return textWidth;
};

const onStateChange = (name, value, item) => {
  const model = item.getModel();
  const states = item.getStates();
  const _group = item.getContainer();
  const nodeRect = _group.find(e => e.get('name') === 'node-rect');
  const nodeName = _group.find(e => e.get('name') === 'node-name');
  // selected > active > hover > default
  const isSelected = states.includes('selected') || model.selected;
  const isActive = states.includes('active');
  const isHover = states.includes('hover');
  if (isSelected) {
    nodeRect.attr({
      fill: item._cfg.model.is_good === '1' ? '#E67C21' : RECT_SELECT_STYLE.backgroundColor,
      shadowBlur: RECT_SELECT_STYLE.shadowBlur,
      shadowColor: RECT_SELECT_STYLE.shadowColor,
    });
    nodeName.attr({
      fill: RECT_SELECT_STYLE.color,
    });
  } else if (isActive) {
    nodeRect.attr({
      fill: item._cfg.model.is_good === '1' ? '#E67C21' : RECT_ACTIVE_STYLE.backgroundColor,
      shadowBlur: RECT_ACTIVE_STYLE.shadowBlur,
      shadowColor: RECT_ACTIVE_STYLE.shadowColor,
    });
    nodeName.attr({
      fill: RECT_ACTIVE_STYLE.color,
    });
  } else if (isHover) {
    nodeRect.attr({
      fill: item._cfg.model.is_good === '1' ? '#FDAD61' : RECT_HOVER_STYLE.backgroundColor,
      shadowBlur: item._cfg.model.is_good === '1' ? '#EE964B' : RECT_HOVER_STYLE.shadowBlur,
      shadowColor: item._cfg.model.is_good === '1' ? '##D9DFE5' : RECT_HOVER_STYLE.shadowColor,
    });
    nodeName.attr({
      fill: RECT_HOVER_STYLE.color,
    });
  } else {
    const {
      backgroundColor,
      shadowBlur = 0,
      shadowColor = 'transparent',
      color,
    } = item._cfg.model;
    nodeRect.attr({
      fill: backgroundColor,
      shadowBlur,
      shadowColor,
    });
    nodeName.attr({
      fill: color,
    });
  }
};

const onButtonStateChange = (name, value, item) => {
  const states = item.getStates();
  const _group = item.getContainer();
  const button = _group.find(e => e.get('name') === 'collapse-back');
  const text = _group.find(e => e.get('name') === 'collapse-text');
  const minus = _group.find(e => e.get('name') === 'collapse-minus');
  // active > hover > default
  const isActive = states.includes('button:active');
  const isHover = states.includes('button:hover');
  if (isActive) {
    button.attr({
      fill: RECT_ACTIVE_STYLE.backgroundColor,
      shadowBlur: RECT_ACTIVE_STYLE.shadowBlur,
      shadowColor: RECT_ACTIVE_STYLE.shadowColor,
    });
    text.attr({
      fill: RECT_ACTIVE_STYLE.color,
    });
    minus.attr({
      fill: RECT_ACTIVE_STYLE.color,
    });
  } else if (isHover) {
    button.attr({
      fill: RECT_HOVER_STYLE.backgroundColor,
      shadowBlur: RECT_HOVER_STYLE.shadowBlur,
      shadowColor: RECT_HOVER_STYLE.shadowColor,
    });
    text.attr({
      fill: RECT_HOVER_STYLE.color,
    });
    minus.attr({
      fill: RECT_HOVER_STYLE.color,
    });
  } else {
    const {
      backgroundColor,
      shadowBlur = 0,
      shadowColor = 'transparent',
      color,
    } = item._cfg.model;
    button.attr({
      fill: '#fff',
      shadowBlur,
      shadowColor,
    });
    text.attr({
      fill: '#333',
    });
    minus.attr({
      fill: '#333',
    });
  }
};

const onSelectedStateChange = (name, value, item) => {
  const _group = item.getContainer();
  const nodeRect = _group.find(e => e.get('name') === 'node-rect');
  const nodeName = _group.find(e => e.get('name') === 'node-name');
  if (value) {
    nodeRect.attr({
      fill: RECT_SELECT_STYLE.backgroundColor,
      shadowBlur: RECT_SELECT_STYLE.shadowBlur,
      shadowColor: RECT_SELECT_STYLE.shadowColor,
    });
    nodeName.attr({
      fill: RECT_SELECT_STYLE.color,
    });
  } else {
    const {
      backgroundColor,
      shadowBlur = 0,
      shadowColor = 'transparent',
      color,
    } = item._cfg.model;
    nodeRect.attr({
      fill: backgroundColor,
      shadowBlur,
      shadowColor,
    });
    nodeName.attr({
      fill: color,
    });
  }
};

const onHoverStateChange = (name, value, item) => {
  const _group = item.getContainer();
  const nodeRect = _group.find(e => e.get('name') === 'node-rect');
  const nodeName = _group.find(e => e.get('name') === 'node-name');
  if (value) {
    nodeRect.attr({
      fill: RECT_HOVER_STYLE.backgroundColor,
      shadowBlur: RECT_HOVER_STYLE.shadowBlur,
      shadowColor: RECT_HOVER_STYLE.shadowColor,
    });
    nodeName.attr({
      fill: RECT_HOVER_STYLE.color,
    });
  } else {
    const {
      backgroundColor,
      shadowBlur = 0,
      shadowColor = 'transparent',
      color,
    } = item._cfg.model;
    nodeRect.attr({
      fill: backgroundColor,
      shadowBlur,
      shadowColor,
    });
    nodeName.attr({
      fill: color,
    });
  }
};

const onActiveStateChange = (name, value, item) => {
  const _group = item.getContainer();
  const nodeRect = _group.find(e => e.get('name') === 'node-rect');
  const nodeName = _group.find(e => e.get('name') === 'node-name');
  if (value) {
    nodeRect.attr({
      fill: RECT_ACTIVE_STYLE.backgroundColor,
      shadowBlur: RECT_ACTIVE_STYLE.shadowBlur,
      shadowColor: RECT_ACTIVE_STYLE.shadowColor,
    });
    nodeName.attr({
      fill: RECT_ACTIVE_STYLE.color,
    });
  } else {
    const {
      backgroundColor,
      shadowBlur = 0,
      shadowColor = 'transparent',
      color,
    } = item._cfg.model;
    nodeRect.attr({
      fill: backgroundColor,
      shadowBlur,
      shadowColor,
    });
    nodeName.attr({
      fill: color,
    });
  }
};

const onCollapsedStateChange = (name, value, item) => {
  const _group = item.getContainer();
  const text = _group.find(e => e.get('name') === 'collapse-text');
  const minus = _group.find(e => e.get('name') === 'collapse-minus');
  if (!text || !minus) return;

  if (value) {
    text.attr({
      opacity: 1,
    });
    minus.attr({
      opacity: 0,
    });
  } else {
    text.attr({
      opacity: 0,
    });
    minus.attr({
      opacity: 1,
    });
  }
};

/**
 * shape
 * {
 *   fontSize,
 *   fontColor,
 *   fontColor = '#fff',
 *   color = '#1C428C',
 *   borderWidth = 1,
 *   borderRadius = 0,
 *   borderColor = '#ced4d9',
 *   shadowColor,
 *   shadowOffsetX,
 *   shadowOffsetY,
 *   shadowBlur,
 *   opacity = 1,
 *   width,
 *   height,
 * }
 */

// 自定义节点、边
const registerFn = () => {
  /**
   * 自定义节点
   */
  G6.registerNode(
    'rounded-rect',
    {
      draw(cfg, group) {
        const ctx = group.getCanvas().cfg.context;
        const _cfg = {
          ...cfg,
          ...(cfg.selected ? RECT_SELECT_STYLE : {}),
          ...(cfg.disabled ? RECT_DISABLED_STYLE : {}),
        };
        const {
          name = '',
          depth,
          disabled,
          color = '#1C428C',
          fontSize,
          borderWidth = 1,
          borderRadius = 0,
          borderColor = '#ced4d9',
          shadowColor,
          shadowOffsetX,
          shadowOffsetY,
          shadowBlur,
          opacity = 1,
          padding = [0, 0],
          width,
          height,
          backgroundColor,
        } = _cfg;
        // 每个矩形的形状
        const rectConfig = {
          width,
          height,
          lineWidth: borderWidth,
          fontSize,
          fill: backgroundColor,
          radius: borderRadius,
          stroke: borderColor,
          opacity,
          shadowColor,
          shadowBlur,
          shadowOffsetX,
          shadowOffsetY,
        };

        const hPadding = padding[1] * 2;
        const textWidth = measureTextWidth(ctx, rectConfig.fontSize, name);
        const dotWidth = measureTextWidth(ctx, rectConfig.fontSize, '...');
        const w = hPadding + textWidth;
        const _w = clamp(w, 80, 200);
        let _name = '';
        // 如果判断文字长度超过最大的宽度，那么进行裁剪，补充一个'...'进去
        if (w > _w) {
          for (let i = 0; i < name.length; i += 1) {
            _name = `${_name}${name[i]}`;
            if (measureTextWidth(ctx, rectConfig.fontSize, _name) >= (200 - hPadding - dotWidth) && _name.length > 1) {
              _name = `${_name.substring(0, _name.length - 1)}...`;
              break;
            }
          }
        } else {
          _name = name;
        }

        // expose size to layout
        // rectConfig.width = _w;
        cfg.width = rectConfig.width;
        cfg.height = rectConfig.height;
        cfg.expandWidth = 0;

        const cursor = disabled ? 'default' : 'pointer';
        const nodeOrigin = {
          x: 0,
          y: 0,
        };

        const textConfig = {
          textAlign: 'left',
          textBaseline: 'bottom',
        };

        const rect = group.addShape('rect', {
          attrs: {
            x: nodeOrigin.x,
            y: nodeOrigin.y,
            ...rectConfig,
            cursor,
          },
          name: 'node-rect',
          modelId: cfg.id,
        });

        const rectBBox = rect.getBBox();

        // label title
        group.addShape('text', {
          attrs: {
            ...textConfig,
            x: rectBBox.width / 2 + nodeOrigin.x,
            y: rectBBox.height / 2 + nodeOrigin.y,
            text: _name,
            fontSize,
            fill: color,
            textAlign: 'center',
            textBaseline: 'middle',
            cursor,
          },
          name: 'node-name',
          modelId: cfg.id,
        });

        // 绘制点击关闭的按钮，展开情况下，文字为单个 icon，收起情况下，文字为子节点的数字
        if (cfg.children && cfg.children.length && depth >= LIMIT_DEPTH) {
          // 添加尾线
          const trailingLineWidth = 6;
          const path = [
            ['M', rectConfig.width + 1, rectConfig.height / 2],
            ['L', rectConfig.width + trailingLineWidth, rectConfig.height / 2],
          ];
          group.addShape('path', {
            attrs: {
              path,
              stroke: '#3E84A7',
              lineWidth: 3,
            },
            name: 'path-shape',
          });
          const { collapsed: collapse } = cfg;
          const count = cfg.children.length;
          const numberWidth = measureTextWidth(ctx, rectConfig.fontSize, count.toString());
          const trailingWidth = numberWidth < 16 ? 16 : Math.ceil(numberWidth);
          group.addShape('circle', {
            attrs: {
              x: rectConfig.width + trailingLineWidth + 10,
              y: rectConfig.height / 2,
              r: 10,
              stroke: '#1961F5',
              fill: '#fff',
              cursor: 'pointer',
              radius: 4,
            },
            name: 'collapse-back',
            modelId: cfg.id,
          });
          group.addShape('text', {
            attrs: {
              x: rectConfig.width + trailingLineWidth + 10,
              y: rectConfig.height / 2 + 1, // + borderWidth
              textAlign: 'center',
              textBaseline: 'middle',
              text: count.toString(),
              fontSize: 14,
              fontFamily: 'Arial',
              fontWeight: 400,
              cursor: 'pointer',
              fill: '#333',
              opacity: cfg.collapsed ? 1 : 0,
            },
            name: 'collapse-text',
            modelId: cfg.id,
          });
          group.addShape('rect', {
            attrs: {
              x: rectConfig.width + trailingLineWidth + 10 - 4,
              y: rectConfig.height / 2 - 1, // + borderWidth
              width: 8,
              height: 2,
              cursor: 'pointer',
              fill: '#333',
              opacity: cfg.collapsed ? 0 : 1,
            },
            name: 'collapse-minus',
            modelId: cfg.id,
            hide: true,
          });
          const expandWidth = trailingLineWidth + trailingWidth + 3;
          cfg.expandWidth = expandWidth;
        }

        this.drawLinkPoints(cfg, group);
        return rect;
      },
      update(cfg, item) {
        // console.log(cfg);
      },
      setState(name, value, item) {
        switch (name) {
          case 'selected':
          case 'hover':
          case 'active':
            onStateChange(name, value, item);
            break;
          case 'button:hover':
          case 'button:active':
            onButtonStateChange(name, value, item);
            break;
          case 'collapsed':
            onCollapsedStateChange(name, value, item);
            break;
          default:
            break;
        }
        // // 1. 对于 hover 如果当前 state 不处于 collapse，不进行任何改变
        // // 2. 对于 active 如果当前 state 不处于 collapse，不进行任何改变
        // const nodeModel = item.getModel();
        // const isCollapse = nodeModel.collapsed;
        // const group = item.getContainer();
        // const collapseBack = group.find(e => e.get('name') === 'collapse-back');
        // const collapseText = group.find(e => e.get('name') === 'collapse-text');
      },
      getAnchorPoints() {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },
    'rect',
  );

  G6.registerEdge('industry-polyline', {
    itemType: 'edge',
    draw: function draw(cfg, group) {
      // 获取起点以及终点，在这中间我们进行绘制
      const { startPoint } = cfg; // 添加边界逻辑判断
      const { endPoint } = cfg;

      // predefined
      // 我们构建的边为折线边，至少有4个途径点，同一深度的节点的第一段节点应该是彼此覆盖完全一致的
      const line1Width = 32 + (cfg?.sourceNode?._cfg.model?.expandWidth ?? 0); // TODO:
      const line1EndPoint = {
        x: startPoint.x + line1Width,
        y: startPoint.y,
      };
      const line2StartPoint = {
        x: line1EndPoint.x,
        y: endPoint.y,
      };

      const path = [
        ['M', startPoint.x, startPoint.y],
        ['L', line1EndPoint.x, line1EndPoint.y],
        ['L', line1EndPoint.x, line2StartPoint.y],
        ['L', endPoint.x, endPoint.y],
      ];

      const endArrow = cfg?.style && cfg.style.endArrow ? cfg.style.endArrow : false;
      if (typeof endArrow === 'object') endArrow.fill = endArrow.stroke;
      const line = group.addShape('path', {
        attrs: {
          path,
          stroke: '#3E84A7',
          lineWidth: 3,
          endArrow,
        },
        name: 'path-shape',
      });
      return line;
    },
  });
};

registerFn();
