/**
 * 多柱状图
*/
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import ChartNoData from './ChartNoData';
import style from './public/index.module.less';

const color = ['#1961F5', '#59D700'];
const highlightColor = ['#FD6205', '#FFB64F'];

const Morebar = ({ data, isFetching, showLabel = false }) => {
  const { x, series, yName, highlight, leftYName = 0 } = data || {};
  const vesicle = (yData) => {
    const seat = x?.indexOf(highlight);
    return [{ value: yData[seat], xAxis: seat, yAxis: yData[seat] }];
  };
  const highlightBar = (yData, index) => {
    const seat = x?.indexOf(highlight);
    return yData.map((item, index2) => {
      if (seat === index2) {
        return {
          value: item,
          itemStyle: {
            color: highlightColor[index],
          },
        };
      }
      return item;
    });
  };
  const seriesNew = series?.map((item, index) => ({
    name: item.name,
    type: 'bar',
    data: highlight ? highlightBar(item.data, index) : item.data,
    itemStyle: {
      color: color[index],
    },
    markPoint: {
      data: highlight ? vesicle(item.data) : '',
      itemStyle: {
        color: highlightColor[index],
      },
      label: {
        show: true,
        position: 'inside',
        color: '#fff',
        fontWeight: 600,
        fontSize: 12,
        lineHeight: 10,
        textBorderColor: highlightColor[index],
        textBorderWidth: 3,
      },
    },
    label: {
      show: showLabel,
      position: 'top',
      color: '#000',
      fontSize: 12,
    },
  }));
  const option = useMemo(() => ({
    xAxis: {
      type: 'category',
      axisLabel: {
        // rotate: 30,
        // fontSize: 10,
        interval: 0,
        rotate: x?.length > 10 && 60,
        fontSize: x?.length > 12 ? 9 : 12,
      },
      data: x,
    },
    tooltip: {
      trigger: 'axis',
      formatter: params => {
        let relVal = params[0].axisValueLabel;
        for (let i = 0; i < params.length; i += 1) {
          relVal += `<br/>${params[i].marker}${params[i].seriesName}\u3000\u3000${params[i].value.toLocaleString()}${series[i].unit}`;
        }
        return relVal;
      },
    },
    yAxis: {
      name: yName,
      type: 'value',
      nameTextStyle: {
        padding: leftYName,
      },
    },
    grid: {
      top: 28,
      right: 40,
      bottom: 56,
      left: 50,
      containLabel: false,
    },
    legend: {
      show: true,
      bottom: 0,
      itemGap: 60,
      itemHeight: 7,
      itemWidth: 12,
    },
    series: seriesNew,
  }), [data]);

  const cheartEl = useMemo(() => {
    if (!series || series.length > 0) {
      return <ECharts
        style={{
          height: '100%',
          width: '100%',
        }}
        option={option}
      />;
    }
    return <ChartNoData />;
  }, [data]);

  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {
       cheartEl
      }
    </Spin>
  );
};

export default Morebar;
