import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Checkbox, Modal, Form, Input, message } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import outIcon from '../../../../assets/images/dataAnalysisTool/dataFilter/outIcon.png';
import analysisIcon from '../../../../assets/images/dataAnalysisTool/dataFilter/analysisIcon.png';
import saveIcon from '../../../../assets/images/dataAnalysisTool/dataFilter/saveIcon.png';
import downloadIcon from '../../../../assets/images/dataAnalysisTool/dataFilter/downloadIcon.png';
import confirmIcon from '../../../../assets/images/dataAnalysisTool/myReport/confirmIcon.png';
import errorIcon from '../../../../assets/images/dataAnalysisTool/dataFilter/errorIcon.png';
import FilterBox from './compnents/FilterBox';
import FilterResult from './compnents/FilterResult';
import UploadData from './compnents/UploadData';
import {
  useGetQueryProjectQuery,
  useLazyGetUpdateProjectQuery,
  useLazyGetCreateProjectQuery,
  useExportDataCatalogQuery,
} from '../../../../service/dataAnalysisTool';
import { isArrayMultiDimensional } from '../../utils/public';
import styles from './index.module.less';
import dataDownload from './compnents/DownloadExcel';

const tabList = [
  {
    key: '查数据',
    value: '查数据',
  },
  {
    key: '传数据',
    value: '传数据',
  },
];

const toServerData = (list) => {
  const arr = [];
  if (list?.length > 0) {
    list.forEach(item => {
      arr.push(item[item.length - 1]);
    });
  }
  return arr;
};

export default function DataFilter() {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [total, setTotal] = useState(0);
  const projectId = query.get('id');
  const [id, setId] = useState(projectId);
  const [selectTab, setSelectTab] = useState('查数据');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportType, setExportType] = useState([]);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [cbfn, setCbfn] = useState();
  const [isAnalysisOpen, setIsAnalysisOpen] = useState(false);
  const [searchPage, setSearchPage] = useState({
    current: 1,
    pageSize: 15,
  });
  const [filterParams, setFilterParams] = useState({
    industry_code_list: [],
    region_code_list: [],
    park_id_list: [],
    register_capital_list: [],
    establish_date_list: [],
    company_scale_list: [],
    company_status_list: [],
    financing_round_list: [],
    diy_tag_list: [],
    market_block_list: [],
    capital_type_list: [],
    support_rs_flag: null,
    authorized_invention_pt_flag: null,
  });

  const [getUpdateProject] = useLazyGetUpdateProjectQuery();
  const [getCreateProject] = useLazyGetCreateProjectQuery();

  const { data: projectData } = useGetQueryProjectQuery({
    project_id: id,
  }, {
    skip: !id,
  });
  const { condition } = projectData ?? {};
  const { data: exportDataCatal } = useExportDataCatalogQuery();

  // 新建项目接口
  const fetchCreateProject = async (callback) => {
    const res = await getCreateProject({
      title: '数据分析',
    });
    if (res.status === 'fulfilled') {
      setIsCreateOpen(false);
      // message.success('新建项目成功');
      setId(res.data);
      callback.callbackFn();
    }
  };

  // 更新项目内容
  const fetchUpdateProject = async () => {
    let params = {};
    if (selectTab === '传数据') {
      params = {
        project_id: id,
        upload_data: 1,
      };
    } else {
      params = {
        project_id: id,
        condition: {
          filterParams,
          selectFilters: localStorage.getItem('selectFilters'),
        },
        upload_data: 0,
      };
    }
    const res = await getUpdateProject(params);
    if (res.status === 'fulfilled') {
      message.success('保存成功');
    }
  };

  // 筛选数据导出接口
  const fetchFilterDataExport = async () => {
    const head = exportDataCatal.filter(item => exportType.includes(item.dataIndex));
    dataDownload({
      columns: head,
      ...filterParams,
      industry_code_list: toServerData(filterParams.industry_code_list),
      region_code_list: isArrayMultiDimensional(filterParams?.region_code_list) ? toServerData(filterParams.region_code_list) : filterParams.region_code_list,
      park_id_list: filterParams.park_id_list?.map(item => item.value),
    }, `${projectData?.title}导出数据`, '/dataSelect/filter_data_export');
  };

  // 上传数据导出
  const fetchExportData = async () => {
    const head = exportDataCatal.filter(item => exportType.includes(item.dataIndex));
    dataDownload({
      columns: head,
      project_id: id,
    }, `${projectData?.title}导出数据`, '/dataSelect/export_data');
  };

  /**
   * 判断是否有项目id，来确定点击导出分析保存时是否弹出新建项目弹窗
   * @param {string} pId
   * @param {Function} callback
   */
  const handleIfCreate = (pId, callback) => {
    if (pId) {
      callback.callbackFn();
    } else {
      // setCbfn(callback);
      // setIsCreateOpen(true);
      fetchCreateProject(callback);
    }
  };

  // 新建表单提交
  const handleFormSubmit = e => {
    fetchCreateProject(e);
  };

  // 关闭表单弹窗
  const handleModalClose = () => {
    setIsCreateOpen(false);
    setCbfn();
  };

  // 点击导出按钮
  const handleClickExport = () => {
    if (total) {
      setIsExportModalOpen(true);
    } else {
      message.error({
        content: '暂无数据可以导出',
        icon: <img src={errorIcon} alt="" />,
        className: styles.errMessage,
      });
    }
  };

  // 点击分析按钮
  const handleClickAnalysis = () => {
    if (total) {
      setIsAnalysisOpen(true);
    } else {
      message.error({
        content: selectTab === '传数据' ? '请上传数据后再对数据进行分析' : '暂无数据可进行分析',
        icon: <img src={errorIcon} alt="" />,
        className: styles.errMessage,
      });
    }
  };

  // 下载模板
  const handleDownload = () => {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = 'https://industry-engine.ks3-cn-beijing.ksyuncs.com/data-analyze/upload_data.xlsx';
    link.download = '上传数据模板.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // 保存当前筛选项
  const handleSave = () => {
    // 查数据-调保存筛选项接口
    if (selectTab === '查数据') {
      localStorage.setItem('dataFilterParams', JSON.stringify({ id, projectDetails: projectData, params: filterParams }));
    } else {
      localStorage.setItem('dataFilterParams', JSON.stringify({ id, projectDetails: projectData, params: {} }));
    }
    fetchUpdateProject();
  };

  // 导出数据弹窗关闭
  const handleExportClose = () => {
    setIsExportModalOpen(false);
    // 还原选中状态
  };

  // 导出数据弹窗确认
  const handleExportConfirm = () => {
    setIsExportModalOpen(false);
    // message.loading('数据导出中', 0);
    if (selectTab === '查数据') {
      fetchFilterDataExport();
    } else {
      fetchExportData();
    }
    // handleSave();
  };

  // 分析弹窗关闭
  const handleAnalysisClose = () => {
    setIsAnalysisOpen(false);
  };

  // 分析弹窗确定
  const handleAnalysisConfirm = () => {
    setIsAnalysisOpen(false);
    handleSave();
    // 跳转到分析页面 history.push
    history.push('/dataAnalysisTool/chartTableTool');
  };

  useEffect(() => {
    if (condition) {
      const obj = JSON.parse(condition)?.filterParams;
      setFilterParams(obj);
      const selectText = JSON.parse(condition)?.selectFilters ? JSON.stringify(JSON.parse(condition)?.selectFilters) : '';
      localStorage.setItem('selectFilters', selectText);
    }
  }, [condition, selectTab]);

  useEffect(() => {
    if (exportDataCatal) {
      setExportType(exportDataCatal.map(item => item.dataIndex));
    }
  }, [JSON.stringify(exportDataCatal)]);

  return (
    <div className={styles.dataFilter}>
      <div className={styles.top}>
        <div className={styles.tabBox}>
          <div className={styles.tabs}>
            {
              tabList && tabList.map(item => (
                <div
                  key={item.key}
                  className={classNames(styles.tabItem, { [styles.tabActive]: selectTab === item.key })}
                  onClick={() => {
                    setSelectTab(item.key);
                    setExportType(exportDataCatal?.map(it => it.dataIndex));
                    setTotal(0);
                    const obj = condition ? JSON.parse(condition) : {
                      industry_code_list: [],
                      region_code_list: [],
                      park_id_list: [],
                      register_capital_list: [],
                      establish_date_list: [],
                      company_scale_list: [],
                      company_status_list: [],
                      financing_round_list: [],
                      diy_tag_list: [],
                      market_block_list: [],
                      capital_type_list: [],
                      support_rs_flag: null,
                      authorized_invention_pt_flag: null,
                    };
                    setFilterParams(obj);
                  }}
                >
                  {item.value}
                </div>
              ))
            }
          </div>
          <div className={styles.btnBox}>
            {
              id && (
                <Button
                  type="primary"
                  onClick={() => {
                    // handleSave();
                    history.push('/dataAnalysisTool/myReport');
                  }}
                >
                  <RollbackOutlined />
                  返回
                </Button>
              )
            }
            {
              selectTab === '传数据' && (
                <Button type="primary" onClick={handleDownload}>
                  <img src={downloadIcon} alt="" />
                  下载模板
                </Button>
              )
            }
            <Button type="primary" onClick={() => handleIfCreate(id, { callbackFn: handleClickExport })}>
              <img src={outIcon} alt="" />
              导出
            </Button>
            <Button type="primary" onClick={() => handleIfCreate(id, { callbackFn: handleClickAnalysis })}>
              <img src={analysisIcon} alt="" />
              分析
            </Button>
            <Button type="primary" onClick={() => handleIfCreate(id, { callbackFn: handleSave })}>
              <img src={saveIcon} alt="" />
              保存
            </Button>
          </div>
        </div>
        {
          selectTab === '传数据'
            ? <UploadData handleIfCreate={handleIfCreate} id={id} setTotal={setTotal} />
            : <FilterBox filterParams={filterParams} setFilterParams={setFilterParams} setSearchPage={setSearchPage} />
        }
      </div>
      {
        selectTab !== '传数据' && (
          <FilterResult
            filterParams={filterParams}
            searchPage={searchPage}
            setSearchPage={setSearchPage}
            // setExportType={setExportType}
            setTotal={setTotal}
          />
        )
      }
      <Modal
        title={<div className={styles.exportTitle}>导出数据</div>}
        footer={null}
        open={isExportModalOpen}
        onCancel={handleExportClose}
        width={520}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.exportContent}>
          <div className={styles.tipText}>选择想要导出的字段</div>
          <div className={styles.checkBox}>
            {
              exportDataCatal?.length > 0 && exportDataCatal.map(item => (
                <Checkbox
                  checked={exportType.includes(item.dataIndex)}
                  key={item.title}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setExportType(prev => ([...prev, item.dataIndex]));
                    } else {
                      setExportType(prev => prev.filter(it => it !== item.dataIndex));
                    }
                  }}
                >
                  {item.title}
                </Checkbox>
              ))
            }
          </div>
          <div className={styles.buttonBox}>
            <Button onClick={handleExportClose} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={handleExportConfirm}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title="新建报告"
        footer={null}
        open={isCreateOpen}
        onCancel={handleModalClose}
        destroyOnClose
        width={425}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.modalContent}>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={handleFormSubmit}
          >
            <Form.Item
              name="title"
              label="报告名称"
              rules={[
                {
                  required: true,
                  message: '请输入报告名称',
                },
              ]}
            >
              <Input
                placeholder="请输入报告名称"
              />
            </Form.Item>
            <Form.Item
              name="subheading"
              label="副标题"
              rules={[
                {
                  // required: true,
                  message: '请输入报告副标题',
                },
              ]}
            >
              <Input
                placeholder="请输入报告副标题"
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="报告描述"
              rules={[
                {
                  // required: true,
                  message: '请输入报告描述',
                },
              ]}
            >
              <Input.TextArea
                placeholder="请输入报告描述"
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button htmlType="button" onClick={handleModalClose} style={{ marginRight: 12 }}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title={
          <div className={styles.analysisTitle}>
            <img src={confirmIcon} alt="" />
            是否确认分析？
          </div>
        }
        footer={null}
        open={isAnalysisOpen}
        onCancel={handleAnalysisClose}
        width={360}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.analysisContent}>
          <div className={styles.text}>{`当前共筛选出${total}条数据，请检查是否是您想要分析的数据，这将影响分析结果`}</div>
          <div className={styles.buttonBox}>
            <Button onClick={handleAnalysisClose} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={handleAnalysisConfirm}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
