import React, { useMemo } from 'react';
import { Table } from '@sqke/design';
import { merge } from 'lodash';
import style from './index.module.less';

const columns = (data) => data?.map(item => {
  if (item.dataIndex === 'index') {
    return {
      ...item,
      width: 70,
    };
  }
  return item;
});

/**
 * @param {object} customOption 自定义配置
 * @param {object} data 表格数据
 * @param {boolean} fetching 是否正在加载
 * @param {number} pageNumber 当前页
 * @param {number} pageSize 每页的条数
 * @param {number} total 总条数
 * @param {function} onChange 事件函数
 * @returns
 */
const CustomTable = ({ customOption, data = {}, isFetching, pageNumber, pageSize, total = 'count', onChange, key = 'index' }) => {
  const option = useMemo(() => {
    if (!data) return {};
    const defaultOption = {
      rowKey: key,
      dataSource: data.dataSource,
      columns: columns(data.columns),
      loading: isFetching,
      locale: { emptyText: ' ' },
      pagination: {
        current: pageNumber,
        pageSizeOptions: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
        pageSize,
        total: data[total],
        showSizeChanger: true,
        showQuickJumper: true,
        responsive: true,
        showLessItems: true,
      },
      onChange: onChange && onChange,
    };
    if (customOption) {
      return { ...defaultOption, ...customOption };
    }
    return defaultOption;
  }, [customOption, data]);
  return (
    <div className={style.box}>
      <Table
        {
        ...option
        }
      />
    </div>

  );
};

export default CustomTable;
