import { useEffect, useState } from 'react';
import {
  useLazyFirstSpecialSubjectChartQuery,
  useLazySecondSpecialSubjectChartQuery,
  useLazyFirstSpecialSubjectTableQuery,
  useLazySecondSpecialSubjectTableQuery,
  useLazyFirstSpecialSubjectCountQuery,
  useLazySecondSpecialSubjectCountQuery,
} from '../../../../../../service/cityTool';

const useChartData = ({ code, key, type }) => {
  const [firstSpecialSubjectChart] = useLazyFirstSpecialSubjectChartQuery();
  const [secondSpecialSubjectChart] = useLazySecondSpecialSubjectChartQuery();
  const [resData, setResData] = useState({});
  const [chartIsFetching, setChartIsFetching] = useState(true);
  const getChartData = () => {
    if (type === '一类专题') {
      return firstSpecialSubjectChart({
        area_key: code,
        label: key,
      });
    }
    return secondSpecialSubjectChart({
      area_key: code,
      label: key,
    });
  };
  useEffect(() => {
    setChartIsFetching(true);
    if (code && key) {
      getChartData(code, key).then(res => {
        setResData(res);
        setChartIsFetching(false);
      }).catch(err => {
        setChartIsFetching(false);
        setResData({});
      });
    }
  }, [code, key, type]);

  return { ...resData, chartIsFetching };
};

const useGetTable = (tableReq, type) => {
  const {
    area_key: code,
    label: key,
    level,
    special_tag: specialTag,
    industry_code_list: industryCodeList,
    sort_key: sortKey,
    sort_value: sortValue,
    page_size: pageSize,
    page_number: pageNumber,
  } = tableReq;
  const [firstSpecialSubjectTable] = useLazyFirstSpecialSubjectTableQuery();
  const [secondSpecialSubjectTable] = useLazySecondSpecialSubjectTableQuery();
  const [tableData, setTableData] = useState({});
  const [tableIsFetching, setTableIsFetching] = useState(true);
  const getTableData = () => {
    if (type === '一类专题') {
      return firstSpecialSubjectTable(tableReq);
    }
    return secondSpecialSubjectTable(tableReq);
  };
  useEffect(() => {
    setTableIsFetching(true);
    const time = setTimeout(() => {
      if (code && key && type) {
        getTableData().then(res => {
          setTableData(res);
          setTableIsFetching(false);
        }).catch(err => {
          setTableIsFetching(false);
          setTableData({});
        });
      }
    }, 10);
    return () => {
      clearInterval(time);
    };
  }, [code, key, type, level, specialTag, industryCodeList, sortKey, sortValue, pageSize, pageNumber]);

  return { ...tableData, tableIsFetching };
};

const useGetCountTitle = (tableCountReq, type) => {
  const [firstSpecialSubjectCount] = useLazyFirstSpecialSubjectCountQuery();
  const [secondSpecialSubjectCount] = useLazySecondSpecialSubjectCountQuery();
  const { code, key } = tableCountReq;
  const [data, setData] = useState('');
  const getCount = () => {
    if (type === '一类专题') {
      return firstSpecialSubjectCount({
        area_key: code,
        label: key,
      });
    }
    return secondSpecialSubjectCount({
      area_key: code,
      label: key,
    });
  };
  useEffect(() => {
    if (code && key && type) {
      getCount().then(res => {
        setData(res.data);
      });
    }
  }, [type, code, key]);
  return {
    data,
  };
};
export {
  useChartData,
  useGetTable,
  useGetCountTitle,
};
