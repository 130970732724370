// 严重违法
import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import { useGetSeriousViolationLawQuery } from '../../../../../service/companyDetail';
import style from '../index.module.less';

const IllegalSituationTable = ({ companyId }) => {
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const { data: tableData, isFetching: tableFetching } = useGetSeriousViolationLawQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const _columns = (columns) => {
    if (!columns) return [];
    return columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      return item;
    });
  };
  const illegalSituationTable = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={_columns(tableData?.columns)}
          title={() => (
            <>
              <span>{tableData?.title}</span>
              <span className={style.count}>{tableData?.count}</span>
            </>
          )}
          strip
          loading={tableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          locale={{
            emptyText: ' ',
          }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [tableData]);
  return illegalSituationTable;
};

export default IllegalSituationTable;
