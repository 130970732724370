import React, { useState, useEffect } from 'react';
import { Modal, message, Tree, Popover, TreeSelect, Spin, Select } from 'antd';
import { cloneDeep } from 'lodash';
import StackBar from '../../../../../../../components/Charts/StackBar';
import { useLazyFindCityIndexValueQuery, useLazyAddUserCityContrastRecordQuery, useLazyGetFindIndexTreeQuery } from '../../../../../../../service/tool';
import { useLazyGetParkAreaPlugMarqueeV2Query, useGetCityIndustryLinkSelectQuery } from '../../../../../../../service/parkDetail';
import BarChart from '../../../../../../../components/Industrial/BarChart';
import style from './index.module.less';

const tds = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];
const content = (
  <p style={{ height: '16px', lineHeight: '16px', fontSize: '14px' }}>点击会删除掉此选项相关的数据信息，请谨慎</p>
);

const chartTitle = (title) => (
  <div className={style.chartTitle}>
    <div>
      <span>{title}</span>
      <span>统计对比图</span>
    </div>
  </div>
);

const chartContent = ({ key, cityData, cityInfoList, yName, iName, unit }) => {
  const xAxis = [];
  let yAxis = [];
  cityInfoList.forEach(item => {
    xAxis.push(item.name);
  });
  // if (cityData.length > 0) {
  yAxis = cityData.map(item => item[key]);

  if (typeof yAxis[0] === 'number') {
    return (
      <div style={{ height: '300px' }}>
        <BarChart
          data={{
            data: {
              xAxis,
              yAxis: [{
                value: yAxis,
                name: iName,
              }],
              yName,
            },
            // gridBottom: 5,
          }}
          download
          disableLegend={false}
        />
      </div>
    );
  }
  const series = [];
  const regexNumber = /\d+/g;
  const regexText = /[\d.%]+.*/g;
  yAxis?.forEach((item2) => (
    item2.forEach((item3, index) => {
      const data = series[index]?.data || [];
      series[index] = {
        name: item3.replace(regexText, ''),
        unit,
        data: [...data, ...item3.match(regexNumber)],
      };
    })
  ));
  return (
    <div style={{ height: '300px' }}>
      <StackBar
        data={{
          color: ['#1961F5', '#59D700', '#FD6205'],
          series,
          yName,
          bottom: 86,
          download: true,
          x: xAxis,
        }}
        isFetching={false}
      />
    </div>
  );
};

const Index = (props) => {
  const { setFun, cityName, cityCode, setIndustryList } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tree, setTree] = useState([]);
  const [treeList, setTreeList] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedIndex, setCheckedIndex] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [citySelect, setCitySelect] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [treeShow, setTreeShow] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [cityInfoList, setCityInfoList] = useState([]);
  const [recordId, setRecordId] = useState(null);
  const [unitsMap, setUnitsMap] = useState({});
  const [cityDataLoading, setCityDataLoading] = useState(true);
  const [industry, setIndustry] = useState([]); // 产业
  // 区域列表可用级别
  const [cityList, setCityList] = useState([]);

  const [getFindIndexTree] = useLazyGetFindIndexTreeQuery();
  const [getIndexValueQuery] = useLazyFindCityIndexValueQuery();
  const [addUserCityContrastRecord] = useLazyAddUserCityContrastRecordQuery();
  const [getParkAreaPlugMarqueeV2Query] = useLazyGetParkAreaPlugMarqueeV2Query();
  const { data: industryLinkData } = useGetCityIndustryLinkSelectQuery({});

  // 判断出是状态是市还是区
  const isCityAndArea = async (data) => {
    const enable = {
      province: '0',
      district: '0',
    };
    if (cityCode[cityCode.length - 1] - 0) {
      enable.district = '1';
      enable.city = '0';
    }
    data = await getParkAreaPlugMarqueeV2Query({ enable });
    setCityList(data.data);
  };

  /**
* 方法获取宏观经济的key值并把左侧树图和树形结构选择状态剔除掉
* 参数  tree treeTitle (唯一标识) treeList或者checkedKeys 区分不同传入数据的标识
*/
  const onFilterTree = (data, dataTitle, filterData, filterType) => {
    let key = [];
    let newFilterData;
    function findInChildren(nodes) {
      nodes.forEach(node => {
        if (node.title === dataTitle) {
          key = node.key;
          return;
        }
        if (node.children) {
          findInChildren(node.children);
        }
      });
    }
    findInChildren(data);
    const keyList = key.length > 0 && key.split(',');
    if (filterType === 1 && keyList) {
      newFilterData = filterData.filter((item) => !keyList.includes(item.key));
    }
    if (filterType === 2 && keyList) {
      newFilterData = filterData.filter((item) => !keyList.includes(item));
    }
    return newFilterData;
  };

  // 获取相对应的节点数据
  const findNode = (treeData, targetValue) => {
    let foundNode;
    function findInChildren(nodes) {
      (nodes || []).forEach(node => {
        if (node.key === targetValue) {
          foundNode = node;
          return;
        }
        if (node.children) {
          findInChildren(node.children);
        }
      });
    }
    findInChildren(treeData);
    return foundNode;
  };

  // 获取区域列表
  const getParkAreaPlugMarqueeV2 = async (enable) => {
    let data = {};
    if (enable) {
      data = await getParkAreaPlugMarqueeV2Query({ enable });
    } else {
      data = await getParkAreaPlugMarqueeV2Query();
    }
    setCityList(data.data);
    isCityAndArea(data.data[0]);
  };

  const addUserCompanyContrastCollect = () => {
    if (cityInfoList.length < 1) return;
    let resData = {};
    const indexKeyList = checkedIndex.map(it => it.key);
    if (industry.length === 0) {
      resData = {
        index_key_list: indexKeyList,
        industry_code_list: industry,
      };
    }
    if (industry.length > 0) {
      const newIndexKeyList = onFilterTree(tree, '宏观经济', indexKeyList, 2);
      const industryCodeList = (industry.filter(val => val.level === 0)).map((data) => data.value);
      const linkCodeList = industry.filter(val => val.level !== 0).map((data) => data.value);
      resData = {
        index_key_list: newIndexKeyList,
        industry_code_list: industryCodeList,
        link_code_list: linkCodeList,
      };
    }
    addUserCityContrastRecord({
      ...resData,
      region_code_list: cityInfoList.map(item => item.code),
      region_name_list: cityInfoList.map(item => item.name),
      record_id: recordId || null,
    }).then(res => {
      const { status } = res;
      if (status === 'fulfilled') {
        message.info('收藏成功');
      } else {
        message.error('收藏失败请稍后重试');
      }
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    setFun(() => addUserCompanyContrastCollect);
  }, [treeList, cityInfoList]);

  const fixKey = (tr) => {
    if (tr) {
      if (typeof tr.key === 'object') {
        const [k] = tr.key;
        tr.key = k;
      }
      tr.children?.forEach(it => fixKey(it));
    }
  };

  const covertTreeData = (res) => {
    // 将后端返回的treeData处理变形
    // 1. 最外层包上“全部”
    // 2. child 替换为 children
    // 3. key是数组的 提取出key来 !! 临时逻辑，需要让后端改
    if (!res) {
      return {};
    }
    let obj = {
      title: res[0].title,
      key: '',
      child: res.slice(1),
    };
    obj = JSON.parse(JSON.stringify(obj).replaceAll('child', 'children'));
    fixKey(obj);
    return obj;
  };

  const getListKey = (tr, keys) => {
    tr.forEach(it => {
      if (it.children?.length > 0) {
        getListKey(it.children, keys);
      } else {
        keys.push({ key: it.key, title: it.title, unit: it.unit, quantitativeIndex: it.quantitative_index });
      }
    });
  };

  const treeToList = (tr) => {
    const list = [];
    tr.children.forEach(it => {
      if (it.children?.length > 0) {
        getListKey(it.children, list);
      } else {
        list.push({ key: it.key, title: it.title, unit: it.unit, quantitativeIndex: it.quantitative_index });
      }
    });
    return list;
  };

  const getIndexValue = (req) => {
    if (req.region_code_list < 1) return;
    getIndexValueQuery(req).then(res => {
      const { data, status, isLoading } = res;
      setCityDataLoading(isLoading);
      if (status === 'fulfilled') {
        setCityData(data);
      } else {
        message.error('获取城市信息失败');
      }
    }).catch(err => {
      console.log(err);
    });
  };
  const echoCompanyDetails = (codeList, indexList) => {
    const indexKeyList = indexList.map(it => it.key);
    if (industry.length === 0) {
      getIndexValue({ index_key_list: indexKeyList, region_code_list: codeList, area_level: 'city', industry_code_list: industry });
    }
    if (industry.length > 0) {
      const newIndexKeyList = onFilterTree(tree, '宏观经济', indexKeyList, 2);
      const industryCodeList = (industry.filter(val => val.level === 0)).map((data) => data.value);
      const linkCodeList = industry.filter(val => val.level !== 0).map((data) => data.value);
      getIndexValue({ index_key_list: newIndexKeyList, region_code_list: codeList, area_level: 'city', industry_code_list: industryCodeList, link_code_list: linkCodeList });
    }
  };

  // 生成单位映射
  const generateUnitMap = (list) => {
    const obj = {};
    list.forEach(item => {
      obj[item.key] = item.unit;
    });
    return obj;
  };
  const getFindTree = (codeList, cityComparisonRecord) => {
    getFindIndexTree().then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        const treeData = covertTreeData(data);
        setTree([treeData]);
        const indexList = treeToList(treeData);
        setUnitsMap(generateUnitMap(indexList));
        if (cityComparisonRecord) {
          const indexKeyList = cityComparisonRecord.index_key_str.split('、').map((item) => (
            {
              ...findNode(indexList, item),
            }
          ));
          const defaultKeys = indexKeyList.map(it => it.key);
          setCheckedIndex(indexKeyList);
          setCheckedKeys(defaultKeys);
        } else {
          const defaultKeys = indexList.map(it => it.key);
          setCheckedIndex(indexList);
          setCheckedKeys(defaultKeys);
        }
        setTreeList(indexList);
        setExpandedKeys(['']);
        if (codeList?.length > 0) {
          echoCompanyDetails(codeList, indexList);
        }
      } else {
        message.error('获取对比指标失败');
      }
    }).catch(err => {
      console.log('获取对比指标失败', err);
    });
  };
  useEffect(() => {
    let cityComparisonRecord = localStorage.getItem('cityComparisonRecord');
    getParkAreaPlugMarqueeV2();
    if (!cityComparisonRecord) {
      if (cityCode && cityName) {
        setCitySelect([cityCode]);
        setCityInfoList([{ code: cityCode, name: cityName }]);
        getFindTree([cityCode], cityComparisonRecord);
      }
    } else {
      cityComparisonRecord = JSON.parse(cityComparisonRecord);
      const cityCodeList = cityComparisonRecord.region_code_str.split('、');
      const cityNameList = cityComparisonRecord.region_name_str.split('、');
      const industryCodeList = cityComparisonRecord.industry_code_str.split('、');
      const linkCodeList = cityComparisonRecord.link_code_str.split('、');
      let newIndustryCodeList = [];
      if (industryCodeList.length > 0 && industryCodeList?.[0] !== '') {
        newIndustryCodeList = [...industryCodeList];
      }
      if (linkCodeList.length > 0 && linkCodeList?.[0] !== '') {
        newIndustryCodeList = [...newIndustryCodeList, ...linkCodeList];
      }
      const industryList = newIndustryCodeList.map((item) => (
        {
          level: findNode(industryLinkData, item)?.level,
          label: findNode(industryLinkData, item)?.title,
          value: findNode(industryLinkData, item)?.key,
        }
      ));
      setCitySelect(cityCodeList);
      getFindTree(cityCodeList, cityComparisonRecord);
      setRecordId(cityComparisonRecord.id);
      setIndustry(industryList);
      setIndustryList(industryList);
      setCityInfoList(cityNameList.map((item, index) => ({
        name: item,
        code: cityCodeList[index],
      })));
    }
    return () => {
      localStorage.removeItem('cityComparisonRecord');
    };
  }, [industryLinkData]);
  const showModal = () => { setIsModalOpen(true); };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  const onCheck = (checkedKeysValue, { checkedNodes }) => {
    setCheckedKeys(checkedKeysValue);
    setCheckedIndex(checkedNodes.filter(it => !it.children));
  };
  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  // 设置节点为不可选中或者可以
  const setDisableNode = (treeData, targetitle) => {
    const newTreeData = cloneDeep(treeData);
    function findInChildren(nodes) {
      (nodes || []).forEach(node => {
        if (node.title === targetitle) {
          node.disabled = true;
          node.children.forEach((item) => {
            item.disabled = true;
          });
          return;
        }
        if (node.children) {
          findInChildren(node.children);
        }
      });
    }
    findInChildren(newTreeData);
    return newTreeData;
  };

  // 确认选中的指标
  const treeBtnOk = () => {
    setTreeShow(false);
    setTreeList(paramList => (paramList || []).map(item => {
      if (checkedKeys.includes(item.key)) {
        return {
          ...item,
          disabled: false,
        };
      }
      return { ...item, disabled: true };
    }));
    const companyIdList = (cityInfoList || []).map(item => item.code);
    const indexKeyList = (checkedIndex || []).map(it => it.key);
    if (industry.length === 0) {
      getIndexValue({ index_key_list: indexKeyList, region_code_list: companyIdList, area_level: 'city', industry_code_list: industry });
    }
    if (industry.length > 0) {
      const newIndexKeyList = onFilterTree(tree, '宏观经济', indexKeyList, 2);
      const industryCodeList = (industry.filter(val => val.level === 0)).map((data) => data.value);
      const linkCodeList = industry.filter(val => val.level !== 0).map((data) => data.value);
      getIndexValue({ index_key_list: newIndexKeyList, region_code_list: companyIdList, area_level: 'city', industry_code_list: industryCodeList, link_code_list: linkCodeList });
    }
  };

  const onNewData = () => {
    const indexKeyList = (checkedIndex || []).map(it => it.key);
    let cityComparisonRecord = localStorage.getItem('cityComparisonRecord');
    cityComparisonRecord = JSON.parse(cityComparisonRecord);
    let newTreeList = null;
    if (cityComparisonRecord) {
      const newTreeListData = treeList.filter((item) => indexKeyList.includes(item.key));
      if (industry.length > 0) {
        newTreeList = onFilterTree(tree, '宏观经济', newTreeListData, 1);
      } else {
        newTreeList = newTreeListData;
      }
    }
    if (!cityComparisonRecord && industry.length > 0) {
      newTreeList = onFilterTree(tree, '宏观经济', treeList, 1);
    }
    return newTreeList;
  };

  // 取消选中
  const treeBtnNo = () => {
    setTreeShow(false);

    setCheckedKeys(treeList.map(item => {
      if (item.disabled) {
        return '';
      }
      return item.key;
    }).filter(it => it));
    setCheckedIndex(treeList.map(item => {
      if (item.disabled) {
        return '';
      }
      return item;
    }).filter(it => it));
  };

  const delCompany = (code, name) => {
    setCityInfoList(list => list.filter((item, index2) => !(item.code === code)));
    setCityData(list => list.filter(item => item.region_code !== code));
    setCitySelect(list => list.filter(it => it !== code));
  };

  const delIndex = (key) => {
    setTreeList(list => list.map(it => {
      if (it.key === key) {
        return { ...it, disabled: true };
      }
      return it;
    }));
    const newKeysList = treeList.map(item => {
      if (!item.disabled && item.key !== key) {
        return item.key;
      }
      return '';
    }).filter(item2 => item2);
    setCheckedKeys(newKeysList);
    setCheckedIndex(list => list.filter(it => it.key !== key));
  };
  // 选中的城市的数据
  const getCompanyIndexValueFromData = (code) => cityData.filter(it => it.region_code === code)[0] || {};
  // 选中产业
  const changeSelect = (val) => {
    const newVal = val.map((item) => (
      {
        ...item,
        level: findNode(industryLinkData, item.value).level,
      }
    ));
    setIndustry(newVal);
    setIndustryList(newVal);
  };
  return (
    <>
      <div className={style.box} >
        <Spin spinning={cityDataLoading}>
          <table border="1" align="center">
            <tbody>
              <tr align="center">
                <td className={style.selectBut}>
                  <div className={style.btnBox}>
                    <button type="button" onClick={() => { setTreeShow(true); }}>
                      <span>+</span>
                      <span>选择对比指标</span>
                    </button>
                    <div>
                      共
                      &nbsp;
                      {citySelect.length || 0}
                      &nbsp;
                      个城市参与对比
                    </div>
                  </div>
                </td>
                {
                  (tds || []).map((item, index) => (
                    <td className={style.companyName} key={index}>
                      {
                        cityInfoList[index]?.name && (
                          <div>
                            <p>{cityInfoList[index]?.name}</p>
                            <button type="button" onClick={() => delCompany(cityInfoList[index]?.code, cityInfoList[index]?.name)}>
                              <Popover content={content} placement="right">
                                <p className={style.delIcon} />
                              </Popover>
                            </button>
                          </div>
                        )
                      }
                    </td>
                  ))
                }
                <td className={style.addBut}>
                  <button type="button" onClick={showModal}>+</button>
                </td>
              </tr>
              {
                (onNewData() || treeList).map(item => (
                  <tr align="center" key={item.key} style={{ display: item.disabled ? 'none' : '' }}>
                    <td align="left" className={style.left}>
                      <div>
                        <p>
                          {item.title}
                          <span onClick={() => { delIndex(item.key); }} />
                        </p>
                      </div>
                    </td>
                    {
                      tds.map((it, index) => {
                        const v = getCompanyIndexValueFromData(cityInfoList[index]?.code)[item.key];
                        if (typeof v !== 'object') {
                          return (
                            <td style={{ minWidth: '200px' }} key={it} >
                              <div>
                                {v === undefined ? v : v + item.unit}
                                {/* {item.unit} */}
                              </div>
                            </td>
                          );
                        }
                        return <td style={{ minWidth: '200px', padding: '4px 6px' }} key={it}><div style={{ whiteSpace: 'pre' }}>{v.join('\n')}</div></td>;
                      })
                    }
                    <td className={style.chart}>
                      {
                        item.quantitativeIndex === '1' && (
                          <Popover placement="left" title={() => chartTitle(item.title)} content={() => chartContent({ key: item.key, cityData, unit: item.unit, cityInfoList, yName: unitsMap[item.key], iName: item.title })}>
                            <p />
                          </Popover>
                        )
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </Spin>
        <Modal title="添加城市" footer={null} visible={isModalOpen} onCancel={handleCancel} >
          <div className={style.ModalContent}>
            <TreeSelect
              placeholder="请选择想要对比的城市"
              allowClear
              multiple
              style={{ width: '100%' }}
              value={citySelect}
              popupClassName={style.conditionSelect}
              // treeDefaultExpandedKeys={[industryData.length > 0 && industryData[0].key]}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={cityList?.[0]?.children}
              listHeight={320}
              onChange={(e, name) => {
                const newcodeData = cloneDeep(e);
                const newNameList = cloneDeep(name);
                if (e.length > 15) {
                  newcodeData.shift();
                  newNameList.shift();
                }
                setCitySelect(newcodeData);
                echoCompanyDetails(newcodeData, checkedIndex);
                setCityDataLoading(true);
                setCityInfoList(newNameList.map((item, index) => ({ code: newcodeData[index], name: item })));
              }}
            />
          </div>
        </Modal>
        {
          cityInfoList.length === 0 && (
            <div className={style.noData} style={{ height: `${treeList.length * 40}px` }}>
              <ul>
                <li />
                <li>暂无对比数据</li>
                <li>
                  <button type="button" onClick={showModal}>去添加城市</button>
                </li>
              </ul>
            </div>
          )
        }
      </div>
      {
        treeShow && (
          <div className={style.treeModal}>
            <p>添加对比对标</p>
            <div className={style.industryTitle}>产业选择</div>
            <div className={style.industrystBox}>
              {/* 城市对比对标添加产业 */}
              <TreeSelect
                style={{ width: '100%' }}
                value={industry}
                treeData={(industryLinkData || [])}
                treeCheckable
                treeDefaultExpandedKeys={[industryLinkData && industryLinkData.length > 0 && industryLinkData[0].key]}
                onChange={changeSelect}
                maxTagCount="responsive"
                treeCheckStrictly={true}
                dropdownClassName={style.dropTreeSelect}
                fieldNames={{
                  label: 'title',
                  value: 'key',
                  children: 'children',
                }}
                treeNodeFilterProp="title"
                showArrow
              />
            </div>
            <div className={style.tree}>
              <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                onCheck={onCheck}
                checkedKeys={(industry.length > 0 ? onFilterTree(tree, '宏观经济', checkedKeys, 2) : checkedKeys)}
                onSelect={onSelect}
                selectedKeys={selectedKeys}
                treeData={industry.length > 0 ? setDisableNode(tree, '宏观经济') : tree}
              />
            </div>
            <div className={style.treeBut}>
              <button type="button" onClick={treeBtnNo}>取消</button>
              <button type="button" onClick={treeBtnOk}>确定</button>
            </div>
          </div>
        )
      }
    </>
  );
};

export default Index;
