import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import RanKing from '../Content/Ranking';
import ArealDistribution from '../Content/EnterpriseDominant/ArealDistribution';
import RegisteredCapital from '../Content/EnterpriseDominant/RegisteredCapital';
import EstablishedTime from '../Content/EnterpriseDominant/EstablishedTime';
import IndustrialDistribution from '../Content/EnterpriseDominant/IndustrialDistribution';
import ListedSector from '../Content/StockFunding/ListedSector/index';
import FinancingScale from '../Content/StockFunding/FinancingScale';
import FinancingRound from '../Content/StockFunding/FinancingRound';
import HotTrack from '../Content/StockFunding/HotTrack';
import HotArea from '../Content/StockFunding/HotArea';
import ValueStatistics from '../Content/InnovationVitality/ValueStatistics';
import PatentDistribution from '../Content/InnovationVitality/PatentDistribution';
import PatentIndustryDistribution from '../Content/InnovationVitality/PatentIndustryDistribution';
import StandardTypeDistribution from '../Content/InnovationVitality/StandardTypeDistribution';
import NationalStandardDistribution from '../Content/InnovationVitality/NationalStandardDistribution';
import NationalStandardTypeDistribution from '../Content/InnovationVitality/NationalStandardTypeDistribution';
import { isArrayMultiDimensional } from '../../../utils/public';
import style from './index.module.less';
import IntelligentPreview from '../Content/IntelligentWriting/IntelligentPreview';

const ComponentList = {
  490: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <ArealDistribution key={key} id={490} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  491: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <RegisteredCapital key={key} id={491} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  492: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <EstablishedTime key={key} id={492} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  493: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <IndustrialDistribution key={key} id={493} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  494: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <ListedSector key={key} id={494} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  495: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <FinancingScale key={key} id={495} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  496: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <FinancingRound key={key} id={496} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  497: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <HotTrack key={key} id={497} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  498: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <HotArea key={key} id={498} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  499: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <ValueStatistics key={key} id={499} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  500: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <PatentDistribution key={key} id={500} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  501: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <PatentIndustryDistribution key={key} id={501} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  502: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <StandardTypeDistribution key={key} id={502} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  503: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <NationalStandardDistribution key={key} id={503} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  504: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <NationalStandardTypeDistribution key={key} id={504} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  505: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <RanKing key={key} id={505} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  506: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <RanKing key={key} id={506} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  507: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <RanKing key={key} id={507} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  508: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <RanKing key={key} id={508} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
  509: ({ key, url, title, setDescribeObj, dataFilterParams, reportData, setReportData, chartNumber }) => <RanKing key={key} id={509} url={url} title={title} preview setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} reportData={reportData} setReportData={setReportData} chartNumber={chartNumber} />,
};

const Preview = ({ data, setReportData }) => {
  const directoryData = localStorage.getItem('directoryFinal') ? JSON.parse(localStorage.getItem('directoryFinal')) : [];
  const reportData = useSelector(store => store.dataAnalysis.reportDataStore);
  const writingArr = localStorage.getItem('intelligentWriting') ? JSON.parse(localStorage.getItem('intelligentWriting')) : [];
  const writingDataArr = writingArr.filter(item => item.text);
  const [describeObj, setDescribeObj] = useState({});
  const [dataFilterParams, setDataFilterParams] = useState(null);
  useEffect(() => {
    const { params = {} } = JSON.parse(localStorage.getItem('dataFilterParams')) || {};
    const toServerData = (list) => {
      const arr = [];
      if (list?.length > 0) {
        list.forEach(item => {
          arr.push(item[item.length - 1]);
        });
      }
      return arr;
    };
    params.industry_code_list = toServerData(params?.industry_code_list);
    params.park_id_list = toServerData(params?.park_id_list);
    params.region_code_list = isArrayMultiDimensional(params?.region_code_list) ? toServerData(params?.region_code_list) : params?.region_code_list;
    setDataFilterParams(params);
  }, []);

  let chartNumber = 0;
  return (
    <div className={style.box}>
      {
        data?.filter(item => item.child.length).map((item, index) => {
          if (index > 0) {
            chartNumber += data?.filter(key => key.child.length)[index - 1].child.length;
          }
          if (item?.meta_data?.type === 'data_analysis') {
            // const newChild = [];
            // writingDataArr.forEach(i => {
            //   if (item.child?.filter(i => i.id === writingItem.id)?.length > 0) {
            //     newChild.push(item.child?.filter(i => i.id === writingItem.id)[0]);
            //   }
            // });
            return (
              <div
                className={
                  classNames(
                    style.content,
                    { [style.disableContent]: directoryData.filter(i => i.id === item.id)?.[0]?.child.every(i => i.disable) || writingDataArr?.length === 0 },
                  )
                }
                id={item?.child?.[0]?.id}
                key={item.name}
              >
                <div className={style.title}>
                  <span>
                    {(index + 1).toLocaleString('zh-Hans-CN-u-nu-hanidec')}
                    、
                  </span>
                  智能撰写
                </div>
                <IntelligentPreview displayData={directoryData.filter(i => i.id === item.id)?.[0]} />
              </div>
            );
          }
          return (
            <div className={style.content} key={item.name} id={item?.child?.[0]?.id}>
              <div className={style.title}>
                <span>
                  {(index + 1).toLocaleString('zh-Hans-CN-u-nu-hanidec')}
                  、
                </span>
                {item.name}
              </div>
              {
                item?.meta_data?.type !== 'data_analysis' && item.child.filter(item2 => !item2.disabel).map((item2, index2) => {
                  if (item.disable) {
                    return null;
                  }
                  try {
                    return ComponentList[`${item2.child[0]?.id}`]({
                      key: item.id + item.catalogue_id + item2.id,
                      url: item2.child[0]?.component_url,
                      title: `（${(index2 + 1).toLocaleString('zh-Hans-CN-u-nu-hanidec')}） ${reportData[item2.child[0]?.id].data.label}`,
                      setDescribeObj,
                      dataFilterParams,
                      reportData: reportData[item2.child[0]?.id].data,
                      setReportData,
                      chartNumber: chartNumber + index2 + 1,
                    });
                  } catch {
                    return null;
                  }
                })
              }
            </div>
          );
        })
      }
    </div>
  );
};

export default Preview;
