import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Spin, Input, message } from 'antd';
import classNames from 'classnames';
import style from './index.module.less';
import Map from '../components/Map';
import { openNewPage } from '../../../utils/urlMethods';
import { useQuery } from '../../../hooks/url';
import Table from '../../../components/TableV2';
import DataSearchButton from '../../Industry/components/DataSearchButton';
import Score from '../../../components/Score';
import ModalTable from '../components/ModalTable';
import Drawer from '../components/Drawer';
import { dataDownload } from '../../../api/mySelf';
import { useGetAssociationRelationQuery, useGetExpandCompanyListQuery, useFuzzyQueryQuery } from '../../../service/tool';

const sortMap = {
  descend: 'desc',
  ascend: 'asc',
};
const AnchorExpande = () => {
  const mapRef = useRef();
  const modalRef = useRef();
  const query = useQuery();
  const [open, setOpen] = useState(false);
  const [btn, setBtn] = useState(false);
  const [companyListOpen, setCompanyListOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [patentTableOpen, setPatentTableOpen] = useState(false);
  const [active, setActive] = useState(['不限']);
  // const [industry, setIndustry] = useState({ title: '不限', code: 'all' });
  const [page, setPage] = useState(1);
  const [searchType, setSearchType] = useState('');
  const [searchKey, setSearchKey] = useState([]);
  const [distance, setDistance] = useState('-');
  const [sortKey, setSortKey] = useState('');
  const [sortValue, setSortValue] = useState('descend');
  const [maxDistance, setMaxDistance] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [id, setId] = useState(query.get('id'));
  const [patentId, setPatentId] = useState(null);
  const [name, setName] = useState(query.get('name'));

  const menu = localStorage.getItem('pm') ? JSON.parse(localStorage.getItem('pm')) : {};

  const reqState = () => {
    if (searchType && searchKey?.length > 0) return false;
    if (searchType && distance) return false;
    return true;
  };
  const parameter = useMemo(() => {
    if (query.get('id')) {
      return {
        id,
        name: query.get('name'),
      };
    }
    return undefined;
  }, [query]);
  const addonAfter = (<div
    className={style.addonAfter}
    onClick={(e) => {
      e.stopPropagation();
      setKeyword(name);
      setCompanyListOpen(true);
    }}
  />
  );
  const { data: categoryList } = useGetAssociationRelationQuery();
  const { data: companyList, isFetching: companyIsFetching } = useFuzzyQueryQuery({
    keyword,
    size: 5,
  }, { skip: !keyword });
  const download = () => {
    dataDownload({
      data_key: 'tool_anchor_point_extension_company_list',
      params: {
        company_id: id,
        search_type: searchType,
        search_key: searchKey,
        page_size: 10,
        page_number: page,
        distance,
        relationships: active,
        sort_key: sortKey,
        sort_value: sortValue && sortMap?.[sortValue],
      },
      start_index: 1,
      end_index: 1000,
    }, '表格');
  };
  const { data: tableData = {}, isFetching: tableIsFetching, error } = useGetExpandCompanyListQuery({
    company_id: id,
    search_type: searchType,
    search_key: searchKey,
    page_size: 10,
    page_number: page,
    distance,
    relationships: active,
    sort_key: sortKey,
    sort_value: sortValue && sortMap?.[sortValue],
  }, { skip: reqState() });
  // const { data: listCompanyList } = useAnchorPointExtensionCompanyListCountQuery({
  //   company_id: id,
  //   search_type: searchType,
  //   search_key: searchKey,
  //   distance,
  // }, { skip: reqState() });
  // 跳转企业详情
  const onCompanyClick = (companyId) => {
    if (!companyId) return;
    openNewPage('companyDetail', { id: companyId });
  };
  // 更新请求条件
  const updateSearch = ({ key, radius, type }) => {
    setSearchType(type);
    setSearchKey(key);
    setDistance(radius);
    setActive(['不限']);
    // setIndustry({ title: '不限', code: 'all' });
    setPage(1);
  };
  useEffect(() => {
    if (tableData.dataSource) {
      mapRef.current.generateCompanyLocation(tableData.dataSource);
      setBtn(true);
    }
  }, [tableData]);
  useEffect(() => {
    if (error) {
      message.warning(error.data.message);
    }
  }, [error]);
  const tagHandle = (key) => {
    setPage(1);
    if (key === '不限') {
      setActive(['不限']);
      return;
    }
    const newActive = active.slice();
    if (newActive.indexOf(key) > -1) {
      newActive.splice(newActive.indexOf(key), 1);
    } else {
      newActive.push(key);
    }
    if (newActive.length < 1) {
      setActive(['不限']);
      return;
    }
    if (newActive.length > 1 && newActive.includes('不限')) {
      setActive(newActive.filter(item => item !== '不限'));
      return;
    }
    setActive(newActive);
  };
  const selectCompany = (company) => {
    setCompanyListOpen(false);
    setName(company.name);
    setId(company._id);
  };
  // const showDrawer = () => {
  //   setPatentTableOpen(false);
  //   setOpen(!open);
  // };
  // const onClose = () => {
  //   setOpen(false);
  // };
  const Tablehandle = (item) => ({
    index: {
      width: 50,
      className: style.index,
    },
    company_name: {
      width: 120,
      ellipsis: true,
      className: style.name,
      onCell: (record, rowIndex) => ({
        onClick: () => { onCompanyClick(record.company_id); },
      }),
    },
    relationship: {
      width: 100,
      ellipsis: true,
      render: (record, row) => (
        <div className={style.relevance} title={row.relationship ? row.relationship.join(',') : '不限'}>
          {
            row.relationship ? row.relationship.map(relevance => <span key={relevance}>{relevance}</span>) : <span>不限</span>
          }
        </div>
      ),
    },
    reg_capital_std: {
      sortDirections: ['ascend', 'descend'],
      width: 100,
      ellipsis: true,
      sorter: true,
    },
    establish_date: {
      sortDirections: ['ascend', 'descend'],
      width: 100,
      ellipsis: true,
      sorter: true,
    },
    approval_patent_count: {
      ellipsis: true,
      sorter: true,
      width: 100,
      sortDirections: ['ascend', 'descend'],
      className: style.number,
      render: (text, record) => (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
          onClick={() => {
            setPatentId(record.company_id);
            setPatentTableOpen(true);
            modalRef.current?.setPage(1);
          }}
        />
      ),
    },
    total_point: {
      width: 90,
      render: text => <Score score={text} />,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
  });
  return (
    <div className={style.box} onClick={() => { setCompanyListOpen(false); setHelpOpen(false); }}>
      <Map
        updateSearch={updateSearch}
        setSearchType={setSearchType}
        setSearchKey={setSearchKey}
        setDistance={setDistance}
        setMaxDistance={setMaxDistance}
        setOpen={setOpen}
        parameter={parameter}
        ref={mapRef}
      />
      <Drawer
        className={style.drawer}
        open={open}
        btn={btn}
        setOpen={setOpen}
        title={
          <div style={{ fontSize: '14px', color: '#0F2849', paddingLeft: '18px' }} >
            圈定范围
            <span style={{ color: '#1961F5' }}>
              {maxDistance > 20 ? 20 : maxDistance}
              {maxDistance ? 'km' : '--'}
            </span>
            以内公司
          </div>
          }
      >
        <div className={style.content}>
          <div className={style.industry}>
            <div>
              目标企业
            </div>
            <div className={style.input}>
              <Input
                value={name}
                onChange={(e) => { setName(e.target.value); }}
                suffix={addonAfter}
              />
              <div className={style.companyList} style={{ display: companyListOpen ? 'block' : 'none' }}>
                <Spin spinning={companyIsFetching} wrapperClassName={style.loading}>
                  <div className={style.companyBox}>
                    {
                      companyList?.map(item => <div key={item._id} onClick={() => { selectCompany(item); }}>{item.name}</div>)
                    }
                  </div>
                </Spin>
              </div>
            </div>
            <div className={style.help} onClick={(e) => { e.stopPropagation(); setHelpOpen(!helpOpen); }}>
              <div className={style.helpText} style={{ display: helpOpen ? 'block' : 'none' }}>以该公司作为目标企业，可根据条件查询区域关联关系企业，支持切换目标企业</div>
            </div>
          </div>
          <div className={style.category}>
            <p>关联关系</p>
            <div className={style.categoryList}>
              {
                  categoryList?.map(item => <div className={classNames({ [style.active]: active.includes(item.key) })} key={item.key} onClick={() => { tagHandle(item.key); }}>{item.title}</div>)
                }
            </div>
          </div>
          <div className={style.table}>
            <div className={style.tableTitle}>
              <div>
                为您找到 &nbsp;
                <span style={{ color: '#1961F5' }}>
                  { (error || !tableData) ? '-' : tableData.count }
                </span>
                &nbsp;
                家符合条件的企业
              </div>
              <div>
                {
                  menu.anchorExpande && (
                    <>
                      <span className={style.vip} />
                      <span>企业用户/数据导出支持1000条</span>
                      <div className={style.download} onClick={download}>数据导出</div>
                    </>
                  )
                }
              </div>
            </div>
            <Table
              sortKey={sortKey}
              sortValue={sortValue}
              Tablehandle={Tablehandle}
              data={error ? {} : tableData}
              isFetching={tableIsFetching}
              onChange={(_, filters, sorter) => {
                setSortKey(sorter.field);
                setSortValue(sorter.order);
              }}
              pagination={{
                current: page,
                pageSize: 10,
                showQuickJumper: true,
                showSizeChanger: false,
                total: error ? 0 : tableData?.total,
                onChange: (current) => {
                  setPage(current);
                },
                // onShowSizeChange: (size) => {
                //   setPageSize(size);
                // },
              }}
            />
          </div>
        </div>
      </Drawer>
      {/* </div> */}
      {
        patentTableOpen && (
        <div className={style.patent}>
          <ModalTable id={patentId} ref={modalRef} onClick={() => { setPatentTableOpen(false); }} />
        </div>
        )
      }
      {/* <DD /> */}
    </div>
  );
};

export default AnchorExpande;
