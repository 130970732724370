/*
* Author: zhangmeng
* Description: '关注、笔记、反馈组件'
* Date: 2021/07/28
*/

import React, { useEffect, useState } from 'react';
import { Badge, message } from 'antd';
import { IconFont } from '../../../../utils/mapIcon';
import FocusDetail from './FocusDetail';
import NotesDetail from './NotesDetail';
import FeedbackDetail from './FeedbackDetail';
import { addAttentionData, findAttentionGroupList,
  getNotes, noteInsert, userFeedback } from '../../../../api/companyDetail';
import style from './index.module.less';

function ToolBox(props) {
  const { companyName, companyId, isAdd } = props;
  const [focusData, setFocusData] = useState([]); // 关注分组列表
  const [notesData, setNotesData] = useState({}); // 笔记数据 count data_list
  const [isHover, setIsHover] = useState([false, false, false]);
  const [compClick, setCompClick] = useState('');
  const [focusIcon, setFocusIcon] = useState('iconheart');
  const [focusClick, setFocusClick] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  // const [hasChecked, setHasChecked] = useState(false);

  // 关注分组列表
  const getFocusList = () => {
    findAttentionGroupList({
      attention_data_type_name: '企业',
      attention_data_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        const { group_list: list, is_have_attention_data: isHasChecked } = res.data;
        if (isHasChecked) {
          // setHasChecked(true);
          setFocusIcon('iconheart-fill');
        }
        setFocusData(list);
      }
    });
  };

  // 获取笔记列表
  const getNotesList = () => {
    getNotes({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setNotesData(res.data);
      }
    });
  };

  const focusDetailEvent = (type, params) => {
    switch (type) {
      case 'confirm':
        addAttentionData({
          attention_data_type_name: '企业',
          attention_data_id: companyId,
          attention_group_id_list: params,
          is_empty_original_data: true,
        }).then(res => {
          if (res.status === '00000') {
            if (params.length) {
              setFocusIcon('iconheart-fill');
              message.success('关注成功！');
            } else {
              const config = {
                className: style.cancelFocus,
                content: (
                  <span className={style.box}>
                    您已取消对&emsp;
                    <span className={style.name}>{companyName}</span>
                    &emsp;的关注
                  </span>
                ),
                duration: 2,
              };
              setFocusIcon('iconheart');
              message.info(config);
            }
            getFocusList();
          }
        });
        break;
      case 'cancel':
        break;
      default:
        break;
    }
    setCompClick('');
    setFocusClick(false);
  };

  const notesDetailEvent = (type, ctn) => {
    if (ctn === '') {
      message.destroy();
      message.info('请输入您需要记录的内容');
      return;
    }
    switch (type) {
      case 'confirm':
        noteInsert({
          company_id: companyId,
          content: ctn,
        }).then(res => {
          if (res.status === '00000') {
            message.success('笔记添加成功！');
            getNotesList();
            getFocusList();
          } else {
            message.error('笔记添加失败！');
          }
        });
        break;
      case 'cancel':
        setCompClick('');
        break;
      default:
        break;
    }
  };

  const feedbackDetailEvent = (type, params) => {
    switch (type) {
      case 'confirm':
        userFeedback({
          company_id: companyId,
          ...params,
        }).then(res => {
          if (res.status === '00000') {
            message.success('您反馈的问题已收到，我们将努力优化！');
          }
        });
        break;
      case 'cancel':
        break;
      default:
        break;
    }
    setCompClick('');
  };

  useEffect(() => {
    if (firstRender) {
      // 获取关注分组列表
      getFocusList();
    }
  }, [isAdd]);

  useEffect(() => {
    setFirstRender(true);
    // 获取分组列表
    getFocusList();
    // 获取笔记列表
    getNotesList();
  }, []);

  return (
    <div className={style.all}>
      <div
        className={style.focus}
        // onMouseOver={() => setFocusIcon('iconheart-fill')}
        // onMouseLeave={() => !hasChecked && setFocusIcon('iconheart')}
        // onFocus={() => null}
        onClick={() => { setCompClick('focus'); }}
      >
        <IconFont type={focusClick ? 'iconheart-fill' : focusIcon} />
        <span className={style.word}>关注</span>
      </div>
      <div
        className={style.notes}
        onMouseOver={() => setIsHover([false, true, false])}
        onMouseLeave={() => setIsHover([false, false, false])}
        onFocus={() => null}
        onClick={() => setCompClick('notes')}
      >
        <IconFont type={isHover[1] || compClick === 'notes' ? 'iconedit-fill' : 'iconedit'} />
        <Badge size="small" count={notesData.count} overflowCount={99} offset={[5, 0]}>
          <span className={style.word}>笔记</span>
        </Badge>
      </div>
      <div
        className={style.feedback}
        onMouseOver={() => setIsHover([false, false, true])}
        onMouseLeave={() => setIsHover([false, false, false])}
        onFocus={() => null}
        onClick={() => setCompClick('feedback')}
      >
        <IconFont type={isHover[2] || compClick === 'feedback' ? 'iconmail-fill' : 'iconmail'} />
        <span className={style.word}>反馈</span>
      </div>
      {
        compClick === 'focus' && focusData.length > 0 && <FocusDetail
          companyName={companyName}
          companyId={companyId}
          focusData={focusData}
          updateList={() => getFocusList()}
          focusDetailEvent={(t, p) => focusDetailEvent(t, p)}
        />
      }
      {
        compClick === 'notes' && <NotesDetail
          companyName={companyName}
          notesData={notesData}
          closePop={() => setCompClick('')}
          updateNoteList={() => getNotesList()}
          notesDetailEvent={(t, p) => notesDetailEvent(t, p)}
        />
      }
      {
        compClick === 'feedback' && <FeedbackDetail
          companyName={companyName}
          closePop={() => setCompClick('')}
          feedbackDetailEvent={(t, p) => feedbackDetailEvent(t, p)}
        />
      }
    </div>
  );
}

export default ToolBox;
