import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { message } from 'antd';
import intelligentIcon from '../../../../../../../assets/images/dataAnalysisTool/intelligentIcon.gif';
import refreshIcon from '../../../../../../../assets/images/dataAnalysisTool/refreshIcon.png';
import disableRefreshIcon from '../../../../../../../assets/images/dataAnalysisTool/disableRefreshIcon.png';
import likeIcon from '../../../../../../../assets/images/dataAnalysisTool/likeIcon.png';
import selectLikeIcon from '../../../../../../../assets/images/dataAnalysisTool/selectLikeIcon.png';
import disableLikeIcon from '../../../../../../../assets/images/dataAnalysisTool/disableLikeIcon.png';
import { useLazyCreateDialogueQuery, useLazyAddDialogueRecordQuery, useLazyUpdateDialogueLikeStatusQuery } from '../../../../../../../service/tool';
import styles from './index.module.less';

// const wsLink = process.env.NODE_ENV === 'production' ? 'wss://chat.chanyeos.com/chat/ws' : 'ws://192.168.15.181:20710/chat/ws';
// const wsLink = 'ws://192.168.15.181:20711/chat/ws';

const wsLink = 'wss://chat.chanyeos.com/chat/ws';

const addIntelligentWrittingText = (id, list, text, content) => {
  const arr = [];
  if (list.length > 0) {
    list.forEach(item => {
      if (item.id === id) {
        arr.push({
          ...item,
          text,
          message: content,
        });
      } else {
        arr.push(item);
      }
    });
  } else {
    arr.push({
      id,
      text,
      message: content,
    });
  }
  return arr;
};

const strToObj = (str) => {
  let obj;
  if (typeof (str) === 'object') {
    obj = str;
  } else {
    obj = JSON.parse(str);
    if (typeof (obj) === 'string') {
      obj = strToObj(obj);
    }
  }
  return obj;
};

const ContentBox = ({ node, key, preview, describeObj, refreshAble, setRefreshAble, setIntelligentWriting }) => {
  const filterObj = localStorage.getItem('selectFilters') ? strToObj(localStorage.getItem('selectFilters')) : {};
  const [generateContent, setGenerateContent] = useState([]);
  const [addDialogueRecorId, setAddDialogueRecorId] = useState('');
  const [isLike, setIsLike] = useState(false);
  const [isListen, setIsListen] = useState(false);
  const [go, setGo] = useState(false);
  const [likeImg, setLikeImg] = useState(disableLikeIcon);
  const [isStartWriting, setIsStartWriting] = useState(false);  // 是否可以点击开始撰写按钮
  const [startWriting, setStartWriting] = useState(false);  // 点击开始撰写按钮
  const ws = useRef();
  const getDate = new Date();
  const currentYear = getDate.getFullYear();
  const currentMonth = getDate.getMonth() + 1;
  const currentDate = getDate.getDate();
  const [createDialogueQuery] = useLazyCreateDialogueQuery();
  const [addDialogueRecordQuery] = useLazyAddDialogueRecordQuery();
  const [updateDialogueLikeStatusQuery] = useLazyUpdateDialogueLikeStatusQuery();

  const generateParams = (child) => {
    let str = filterObj ? `研究对象：本报告以${filterObj.成立时间 ? `成立时间在${filterObj.成立时间}的` : ''}${filterObj.产业选择 ? `${filterObj.产业选择}产业` : ''}${filterObj.资本类型 ? filterObj.资本类型 : ''}${filterObj.企业资质 ? filterObj.企业资质 : ''}为研究对象，企业状态为${filterObj.企业状态 ? filterObj.企业状态 : ''}，数据来源为上奇产业通，数据统计时间截止到${currentYear}年${currentMonth}月${currentDate}日。` : '';
    if (child?.child.length > 0) {
      child.child.forEach(item => {
        if (Object.keys(describeObj).filter(i => i - 0 === item.id).length > 0) {
          const plusStr = JSON.stringify(describeObj[item.id].describe).slice(1, -1);
          str += plusStr;
        }
      });
    }
    str += child?.meta_data?.promote;
    return str;
  };

  const handleOpen = () => {
    console.log('连接建立时触发');
    setGo(true);
  };

  const handleClose = () => {
    console.log('连接关闭时触发');
    setGo(false);
  };

  const handleError = () => {
    console.log('通信发生错误时触发');
  };

  // 添加会话记录
  const addConversation = async (text) => {
    const conversation = await createDialogueQuery({ dialogue_name: node.name, dialogue_type: node.id });
    const addDialogueRecor = await addDialogueRecordQuery({ dialogue_id: conversation.data, question: generateParams(node), answer: text });
    setAddDialogueRecorId(addDialogueRecor.data);
  };

  const handleFirstMessage = (e, setDialogueRecordId) => {
    const returnMessage = [];
    const returnData = JSON.parse(e.data);
    returnMessage.push(returnData.message);
    setGenerateContent([...returnMessage]);
    if (returnData.status === 'process_completed') {
      addConversation(returnData.message);
      ws.current.removeEventListener('message', handleFirstMessage);
      setIsListen(false);
      setIntelligentWriting(prev => addIntelligentWrittingText(node?.id, prev, node?.name, returnData.message));
      setRefreshAble(prev => ({
        ...prev,
        [node?.id]: true,
      }));
      // setDialogueRecordId(returnData.id);
    }
  };

  const handleRefreshBtnClick = (id) => {
    setRefreshAble(prev => ({
      ...prev,
      [id]: false,
    }));
    setGenerateContent([]);
    ws.current.send(JSON.stringify({
      prompt: generateParams(node),
      // dialogue_type: id,
    }));
    setIsListen(true);
    setIsLike(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    ws.current = new WebSocket(wsLink, `Authorization_Bearer_${token.split(' ')[1]}`);
    ws.current.addEventListener('open', handleOpen, false);
    ws.current.addEventListener('close', handleClose, false);
    ws.current.addEventListener('error', handleError, false);
    return () => { ws.current.close(); };
  }, []);
  useEffect(() => {
    if (describeObj && Object.values(describeObj).every(item => item.ok) && go) {
      setIsStartWriting(true);
    }
  }, [JSON.stringify(describeObj), JSON.stringify(node), go]);

  useEffect(() => {
    if (startWriting && node && ws.current.readyState === 1 && generateContent.length < 1) {
      ws.current.send(JSON.stringify({
        prompt: generateParams(node),
        // dialogue_type: node?.id,
      }));
      setIsListen(true);
    }
  }, [startWriting]);

  useEffect(() => {
    if (isListen) {
      ws.current.addEventListener('message', handleFirstMessage, false);
    }
  }, [isListen]);

  useEffect(() => {
    if (!isStartWriting) {
      setLikeImg(disableLikeIcon);
    } else if (isLike) {
      setLikeImg(selectLikeIcon);
    } else {
      setLikeImg(likeIcon);
    }
  }, [isStartWriting, isLike]);

  // 点喜欢
  const handleLikeClick = () => {
    if (!addDialogueRecorId) {
      message.success('请等待撰写完成');
      return;
    }
    updateDialogueLikeStatusQuery({ dialogue_record_id: addDialogueRecorId, like: isLike ? 0 : 1 }).then(res => {
      setIsLike(prev => !prev);
    });
  };

  return (
    <div className={styles.box} key={key} id={node?.id}>
      <div className={styles.top}>
        <img src={intelligentIcon} alt="" />
        <div className={classNames(styles.btnBox, { [styles.disableBtnBox]: !isStartWriting })} style={preview && { display: 'none' }}>
          {/* <span className={classNames(styles.likeBtn, { [styles.disableLikeBtn]: !refreshAble?.[node?.id] })} onClick={handleLikeClick}>
            <img src={likeImg} alt="" />
          </span> */}
          {/* <div className={styles.sperateLine}></div> */}
          <span className={classNames(styles.refreshBtn, { [styles.disableRefresh]: !refreshAble?.[node?.id] })} onClick={() => refreshAble?.[node?.id] && handleRefreshBtnClick(node?.id, setGenerateContent)}>
            <img src={!isStartWriting ? disableRefreshIcon : refreshIcon} alt="" />
            重新撰写
          </span>
        </div>
      </div>
      <div className={styles.content}>
        {
          startWriting ? (
            <>
              <div className={styles.title}>{node?.name}</div>
              <div className={styles.reply}>
                {generateContent}
              </div>
            </>
          ) : (
            <div className={styles.btnContent}>
              <div
                className={classNames(styles.startBtn, { [styles.disableBtn]: !isStartWriting })}
                onClick={() => {
                  if (!isStartWriting) return;
                  setStartWriting(true);
                }}
              >
                开始撰写
              </div>
              {/* {
                !isStartWriting && (
                  <div className={styles.btnTip}>请等待数据加载完毕</div>
                )
              } */}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ContentBox;
