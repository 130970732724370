import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
import styles from './index.module.less';
import DataSearchButton from '../../../Industry/components/DataSearchButton';
import { useGetCityChannelPatentListQuery } from '../../../../service/city-channel';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetIndustryTreeQuery,
} from '../../../../service/interactive-v2';
import { getNameConfig, getSortKv } from '../../../../utils/table';
import SortTitle from '../../../Industry/components/SortTitle';
import { FilterIcon } from '../../../../components/CustomTableIcon';
import CustomTreeFilter from '../../../../components/CustomTreeFilter';
import { getSingleOption } from '../../../../utils/tool';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  name: {
    title: '单位名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  location: {
    title: '区域',
    dataIndex: 'location',
    key: 'location',
    width: 120,
    ellipsis: true,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
  },
  tag_name: {
    title: '产业',
    dataIndex: 'tag_name',
    key: 'tag_name',
    width: 180,
    ellipsis: true,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
    render: text => (Array.isArray(text) ? text.join('、') : text),
  },
  approval_patent_count: {
    title: '专利',
    dataIndex: 'approval_patent_count',
    key: 'approval_patent_count',
    width: 120,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  possess_patent_sum: {
    title: '发明',
    dataIndex: 'possess_patent_sum',
    key: 'possess_patent_sum',
    width: 120,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  utility_patent_num: {
    title: '实用',
    dataIndex: 'utility_patent_num',
    key: 'utility_patent_num',
    width: 120,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  design_patent_num: {
    title: '外观',
    dataIndex: 'design_patent_num',
    key: 'design_patent_num',
    width: 120,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  national_standards_count: {
    title: '起草国家标准',
    dataIndex: 'national_standards_count',
    key: 'national_standards_count',
    width: 120,
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
};

const COLUMN_KEYS = [
  'index', 'name', 'location', 'tag_name', 'approval_patent_count',
  'possess_patent_sum', 'utility_patent_num', 'design_patent_num', 'national_standards_count',
];

function PatentTable(props) {
  const {
    keyword,
    cityCode,
    sortedInfo = {},
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    onClick,
    onData,
    setParams,
  } = props;

  const {
    key: sortedKey,
    value: sortedValue,
  } = getSortKv(sortedInfo);

  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query({
    area_code: cityCode,
  });

  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );

  const { data, isFetching } = useGetCityChannelPatentListQuery({
    key_word: keyword,
    area_key: getSingleOption(filteredInfo.location) ?? cityCode,
    industry_code: getSingleOption(filteredInfo.tag_name) ?? 'all',
    sort_key: sortedKey,
    sort_value: sortedValue,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !cityCode,
  });
  const { total = 0, columns = [], dataSource = [] } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'location') {
        rowConfig.filters = regionTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
        rowConfig.filteredValue = filteredInfo?.location ?? [cityCode];
      }
      if (item.dataIndex === 'tag_name') {
        rowConfig.filters = industryTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
        rowConfig.filteredValue = filteredInfo?.tag_name ?? ['all'];
      }
      if (item.dataIndex === 'name') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({
            onClick: onCompanyClick,
            keyword,
            idField: '_id',
            className: 'clickable-name',
          })),
        };
      }
      if ([
        'industry_patent', 'approval_patent_count', 'possess_patent_sum', 'utility_patent_num', 'design_patent_num',
      ].includes(item.dataIndex)) {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item })}
          />
        );
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [onCompanyClick, onClick, columns, industryTree, regionTree]);

  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        area_key: getSingleOption(filteredInfo.location) ?? cityCode,
        industry_code: getSingleOption(filteredInfo.tag_name) ?? 'all',
        sort_key: sortedKey,
        sort_value: sortedValue,
      });
    }
  }, [keyword, cityCode, sortedInfo, filteredInfo]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      {...props}
    />
  );
}

export default PatentTable;
