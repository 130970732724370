import React, { useState } from 'react';
import { Tree, Select } from 'antd';
import { useGetAreaPlugMarqueeV2Query } from '../../../../../../service/interactive-v2';
import HighGradeCompany from './HighGradeCompany';
import PotentialCompany from './PotentialCompany';
import Tabs from '../../../../../../components/Tabs';
import styles from './index.module.less';

const tabs = [
  { title: '已获资质企业' },
  { title: '培育企业' },
];

const Cultivate = (props) => {
  const [checkedTab, setCheckedTab] = useState({ title: '已获资质企业' });

  return (
    <div>
      <div className={styles.tabsBox}>
        <Tabs
          checkedTab={checkedTab}
          setCheckedTab={setCheckedTab}
          data={tabs}
        />
      </div>
      {
        checkedTab.title === '已获资质企业' && <HighGradeCompany {...props} />
      }
      {
        checkedTab.title === '培育企业' && <PotentialCompany {...props} />
      }
    </div>
  );
};

export default Cultivate;
