import React, { useState, useMemo, useCallback, useEffect } from 'react';
import QRcode from 'qrcode';
import classNames from 'classnames';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Table, message, Modal, Spin } from 'antd';
import { useQuery } from '../../../hooks/url';
import ChangePassword from './ChangePassword';
import ChannelModal from '../ChannelModal';
import BindWx from '../BindWx';
import PhoneVerify from '../PhoneVerify';
import style from './index.module.less';
import {
  getModifyPassword,
  getValidatorPhone,
} from '../../../api/accountSetting';
import {
  getUserInfo,
  getUnbindWx,
  getInviterUrl,
  getDivideCount,
  queryBindStatus,
  wxUnbind,
} from '../../../api/login';
import {
  getAttentionIndustry,
  getUserSQcoin,
} from '../../../api/mySelf';
import { openNewPage } from '../../../utils/urlMethods';
import { useLazyRecordingSpotQuery } from '../../../service/public';
import TableNoData from '../../IndustryCompanyDetail/CompanyContent/TableNoData';

function PersonalCenter({ EAPersonalCenter = false }) {
  const columns = [
    {
      title: '频道',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      width: '50%',
      render: (text, records) => (
        <span
          className={classNames(style.tableAction, { [style.cityType]: records.type === 'city' })}
          onClick={() => {
            if (records.type === 'city') {
              openNewPage('cityDetails', {
                type: 'graph',
                'city-code': window.btoa(records.value),
                'city-title': records.name,
                'is-province-directly': records.is_province_directly || false,
              });
            } else if (records.type === 'industry') {
              openNewPage('industry', {
                type: 'graph',
                'industry-code': window.btoa(records.value),
              });
            } else {
              openNewPage('special', {
                name: window.btoa(encodeURIComponent(records.value)),
                code: window.btoa(records.key),
              });
            }
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: '到期时间',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '50%',
    },
  ];

  const history = useHistory();
  const query = useQuery();
  const [recordingSpotQuery] = useLazyRecordingSpotQuery();
  const phone = localStorage.getItem('ph');
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [type, setType] = useState('');
  const [info, setInfo] = useState({});
  const [wxBind, setWxBind] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [inviteNum, setInviteNum] = useState({
    inviteNumberCount: 0,
    invitePayNumberCount: 0,
    inviteStatisticsAmountCount: 0,
  });
  const [sqcoin, setSqcoin] = useState(0);

  const handleWithPhone = useCallback(
    (value) => (Number.isNaN(Number(value)) ? value : `${value.split('').slice(0, 3).join('')}****${value.split('').slice(-4).join('')}`),
    [],
  );
  const getInfo = () => {
    getUserInfo().then(res => {
      if (res.status === '00000') {
        setInfo(res.data);
      }
    });
  };

  const getInviter = () => {
    getInviterUrl().then(res => {
      if (res.status === '00000') {
        QRcode.toCanvas(res.data, { width: 110, height: 110 }, (err, canvas) => {
          if (err) {
            console.log(err);
          }
          const contanier = document.getElementById('qrcode');
          if (contanier?.childNodes?.[0]) {
            contanier.removeChild(contanier.childNodes[0]);
          }
          contanier?.appendChild(canvas);
        });
      }
    });
  };

  const getDivide = () => {
    getDivideCount().then(res => {
      if (res.status === '00000') {
        const {
          invite_number_count: inviteNumberCount,
          invite_pay_number_count: invitePayNumberCount,
          invite_statistics_amount_count: inviteStatisticsAmountCount,
        } = res.data;
        setInviteNum({
          inviteNumberCount: inviteNumberCount ?? 0,
          invitePayNumberCount: invitePayNumberCount ?? 0,
          inviteStatisticsAmountCount: inviteStatisticsAmountCount ?? 0,
        });
      }
    });
  };

  // 查询微信绑定状态
  const queryBindStatusFun = async () => {
    try {
      const res = await queryBindStatus();
      const { status, data, message: text } = res;
      if (status === '00000') {
        setWxBind(data);
      } else {
        message.warning(text);
      }
    } catch {
      throw new Error('获取绑定状态失败');
    }
  };

  const getSQcoin = () => {
    getUserSQcoin().then(res => {
      if (res.status === '00000') {
        setSqcoin(res.data);
      }
    });
  };

  const getAttention = () => {
    getAttentionIndustry().then(res => {
      if (res.status === '00000') {
        setDataSource(res.data);
      }
    });
  };

  // 微信解绑
  const unbindWx = () => {
    wxUnbind().then(res => {
      if (res.status === '00000') {
        setModalVisible(false);
        getInfo();
        queryBindStatusFun();
        message.success('解绑成功！');
      }
    });
  };

  // 修改密码-确认修改
  const changeConfirm = (params) => {
    const { captcha, newPassword } = params;
    getModifyPassword({
      new_password: newPassword,
      confirm_password: newPassword,
      phone_no: phone,
      captcha,
    }).then(res => {
      if (res.status === '00000') {
        message.success('修改密码成功！');
        setModalVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 手机号验证
  const phoneVerifyOnFinish = (params) => {
    getValidatorPhone({
      phone_no: phone,
      captcha: params.code,
    }).then(res => {
      if (res.status === '00000') {
        if (res.data) {
          setModalTitle('绑定');
          setType('绑定');
        } else {
          message.error('验证码错误，请重试！');
        }
      } else {
        message.error(res.message);
      }
    });
  };

  const compRender = (ty) => {
    let comp = null;
    switch (ty) {
      case '修改密码':
        comp = (
          <ChangePassword
            encryptPhone={handleWithPhone(phone)}
            onFinish={(p) => changeConfirm(p)}
            cancelClick={() => setModalVisible(false)}
            phone={phone}
          />
        );
        break;
      case '解绑':
        comp = (
          <div className="unbindWx">
            <div className="infoWord">解绑微信后将无法使用微信登录该产业通帐号！</div>
            <div className="btnGroup">
              <Button className="cancelBtn" onClick={() => setModalVisible(false)}>
                取消
              </Button>
              <Button type="primary" className="submitBtn" onClick={() => unbindWx()}>
                确认
              </Button>
            </div>
          </div>
        );
        break;
      case '绑定':
        comp = <BindWx
          getInfo={() => getInfo()}
          queryBindStatusFun={queryBindStatusFun}
          setModalVisible={() => setModalVisible(false)}
        />;
        break;
      case '手机号验证':
        comp = <PhoneVerify
          phone={phone}
          encryptPhone={handleWithPhone(phone)}
          onFinish={(p) => phoneVerifyOnFinish(p)}
        />;
        break;
      default:
        break;
    }
    return comp;
  };

  const pushDetailPage = useCallback((key) => {
    setChannelModalVisible(false);
    query.set('tab', '个人中心');
    query.set('detail', key);
    history.push({ search: query.toString() });
    recordingSpotQuery({
      spot_name: '个人中心-上奇币',
    });
  }, [query]);

  useEffect(() => {
    getInfo();
    getInviter();
    getAttention();
    getDivide();
    getSQcoin();
    queryBindStatusFun();
  }, []);

  return (
    <div className={style.all}>
      {
        EAPersonalCenter && (
          <>
            <div className={style.enterpriseLevel}>
              <div className={style.enterpriseIcon} />
              <div className={style.enterpriseText}>账号管理</div>
              <div className={style.enterprisePhone}>{handleWithPhone(phone)}</div>
              <div
                className={style.enterpriseAction}
                onClick={() => {
                  setModalTitle('修改密码');
                  setType('修改密码');
                  setModalVisible(true);
                }}
              >
                修改密码
              </div>
            </div>
          </>
        )
      }
      {
        !EAPersonalCenter && (
          <>
            <div className={classNames(style.card, style.personal)}>
              <div className={style.portrait}>
                <div className={style.avatar} />
                <div className={style.name}>{info.real_name ?? 'NULL'}</div>
              </div>
              <div className={style.line} />
              <div className={classNames(style.personalBox, style.pay)} onClick={() => pushDetailPage('achieve')}>
                <div className={style.icon}>
                  <div className={style.picture} />
                  <div className={style.num}>{'上奇币>'}</div>
                </div>
                <div className={style.text}>{sqcoin}</div>
              </div>
              <div className={style.line} />
              <div
                className={classNames(style.personalBox, style.share)}
                onClick={() => {
                  message.destroy();
                  message.info('收益明细即将上线，敬请期待');
                  // pushDetailPage('detail');
                }}
              >
                <div className={style.icon}>
                  <div className={style.picture} />
                  <div className={style.num}>分享收益</div>
                </div>
                <div className={style.text}>{inviteNum.inviteStatisticsAmountCount}</div>
              </div>
              <div className={style.line} />
              <div className={style.qrcodeBox}>
                <div className={style.qrcode} id="qrcode" />
                <div className={style.qrtext}>分享内容，邀请好友</div>
              </div>
            </div>

            <div className={style.action}>
              <div className={classNames(style.card, style.actionItem)}>
                <div className={style.content}>
                  <div className={style.title}>账号管理</div>
                  <div className={style.value}>{handleWithPhone(phone)}</div>
                </div>
                <div className={classNames(style.topleftBg, style.userSamll)} />
                <div className={style.bottomrightBg}>
                  {/* <div
                    className={style.actionText}
                    onClick={() => {
                      setModalTitle('修改密码');
                      setType('修改密码');
                      setModalVisible(true);
                    }}
                  >
                    修改密码
                  </div> */}
                  <div className={style.userLarge} />
                </div>
              </div>
              <div className={classNames(style.card, style.actionItem)}>
                <div className={style.content}>
                  <div className={style.title}>快捷登录</div>
                  <div className={style.value}>{wxBind ? '已绑定微信' : '绑定微信扫码登陆'}</div>
                </div>
                <div className={classNames(style.topleftBg, style.wxSamll)} />
                <div className={style.bottomrightBg}>
                  {
                    wxBind && (
                      <div
                        className={classNames(style.actionText, style.unbind)}
                        onClick={() => {
                          setModalTitle('解绑');
                          setType('解绑');
                          setModalVisible(true);
                        }}
                      >
                        解绑
                      </div>
                    )
                  }
                  {
                    !wxBind && (
                      <div
                        className={classNames(style.actionText)}
                        onClick={() => {
                          if (wxBind) return;
                          setModalTitle('手机号验证');
                          setModalVisible(true);
                          setType('手机号验证');
                        }}
                      >
                        绑定
                      </div>
                    )
                  }
                  <div className={style.wxLarge} />
                </div>
              </div>
              <div className={classNames(style.card, style.open)}>
                <div className={style.content}>
                  <div className={style.title}>更多频道</div>
                  <div className={style.value} />
                </div>
                <div className={classNames(style.topleftBg, style.openSmall)} />
                <Button className={style.openButton} onClick={() => setChannelModalVisible(true)}>我要开通</Button>
              </div>
            </div>
          </>
        )
      }
      <div className={classNames(style.card, style.equity, { [style.enterprise]: EAPersonalCenter })}>
        <div className={style.equityTitle}>
          <div className={style.equityPicture} />
          <div className={style.equityText}>我的频道</div>
        </div>
        <div className={style.table}>
          <Table
            rowKey={(item) => item.value + item.date}
            columns={columns}
            dataSource={dataSource}
            locale={{
              emptyText: () => {
                if (!dataSource) {
                  return <Spin spinning tip="搜索中，请稍候....." />;
                }
                return <TableNoData />;
              },
            }}
            pagination={false}
            scroll={{
              y: EAPersonalCenter ? 'calc(100vh - 420px)' : 'calc(100vh - 576px)',
            }}
          />
        </div>
      </div>

      {
        useMemo(() => channelModalVisible && <ChannelModal
          onClose={() => setChannelModalVisible(false)}
          pushDetailPage={pushDetailPage}
          paySuccessCb={() => {
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }}
        />, [channelModalVisible])
      }

      {
        modalVisible && (
          <Modal
            title={modalTitle}
            visible={modalVisible}
            maskClosable={false}
            footer={null}
            onOk={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            wrapClassName={style.accountModal}
          >
            {compRender(type)}
          </Modal>
        )
      }
    </div>
  );
}

export default withRouter(PersonalCenter);
