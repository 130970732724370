import { APIType } from './enum';

/**
 * 判断数据是否合法
 */
export const isDataSourceLegal = (dataSource: any) => {
  if (!dataSource || typeof dataSource !== 'object') return false;
  const { type } = dataSource;
  if (!type || !Object.values(APIType).includes(type)) {
    return false;
  }
  if (type === APIType.JSON && !dataSource.jsonId) {
    return false;
  }
  return !((type === APIType.API || type === APIType.API_SELECTION) && !dataSource.url);
};
