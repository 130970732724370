/*
 *Description: TreeTb
 *Date: Thu Jan 13 2022 15:01:23 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { TreeTbChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-tree-tb-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const TreeTbChart = forwardRef((props: TreeTbChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize, nodeStyle, roam, collapse } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;
    if (!data) {
      return;
    }

    // 处理样式
    function dfsHandleStyleAndData(node: any, depth = 0) {
      if (!node) {
        return;
      }
      if (!node.name) {
        node.name = node.title;
      }
      const curNodeStyle = nodeStyle?.[depth] || {};
      const keysOfcurNodeStyle = Object.keys(curNodeStyle);
      keysOfcurNodeStyle.forEach(key => {
        if (curNodeStyle[key]) {
          node[key] = curNodeStyle[key];
        }
      });
      // eslint-disable-next-line no-unused-expressions
      node?.children?.forEach((item: any) => {
        dfsHandleStyleAndData(item, depth + 1);
      });
    }
    dfsHandleStyleAndData(data);
    const series: any[] = [{
      name: 'name',
      type: 'tree',
      orient: 'TB',
      top: 20,
      left: 0,
      right: 0,
      bottom: 6,
      data: [data],
      symbolSize: 5,
      roam,
      expandAndCollapse: collapse,
      initialTreeDepth: -1,
      scaleLimit: {
        min: 0.5,
        max: 8,
      },
      label: {
        distance: 0,
        show: label,
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
        position: 'top',
      },
    }];

    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };
    setOption(options);
  }, [hanldeData, fontSize, label, legend, mode, title, data]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default TreeTbChart;

TreeTbChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
