/*
 *Author: zhaochenyu
 *Description: 公司详情-图表按钮页面
 *Date: 2021/05/31
*/

import React, { useMemo, useState } from 'react';
import { IconFont, tabMap } from '../../utils/mapIcon';
import BtnChartComp from './BtnChartComp';
import style from './index.module.less';

function IndustryCompanyChart() {
  const { href } = window.location;
  const params = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);
  const [chartDesc, setChartDesc] = useState('');
  const btnChartComp = useMemo(() => (
    <BtnChartComp
      type={params.get('chartType')}
      companyId={params.get('id')}
      companyName={params.get('cname')}
      setChartDesc={setChartDesc}
    />
  ), [params]);

  return (
    <div className={style.all}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.title}>
            <span className={style.before}>
              产业通
              &emsp;
              <span className={style.point} />
              &emsp;
            </span>
            <span className={style.later}>企业</span>
          </div>
          <div className={style.imgBox}>
            <IconFont type={tabMap.industry} />
          </div>
        </div>
        <div className={style.right} onClick={() => { window.close(); }}>
          <span className={style.back}>返回上一页</span>
          <IconFont type="iconundo" />
        </div>
      </div>
      <div className={style.main}>
        <div className={style.desc}>
          {chartDesc}
        </div>
        {btnChartComp}
      </div>
    </div>
  );
}

export default IndustryCompanyChart;
