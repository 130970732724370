import React, { useMemo } from 'react';
import classnames from 'classnames';
import Table from '../../../../components/Table';
import RankingOrder from '../../../../components/RankingOrder';
import DataSearchButton from '../DataSearchButton';
import styles from './index.module.less';
import { useGetChannelAreaDistributionCompanyRankQuery } from '../../../../service/industry-channel';
import SortTitle from '../SortTitle';

const THEAD_TABLE = {
  index: {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    render: text => <RankingOrder order={text} />,
  },
  area: {
    title: '区域',
    dataIndex: 'area',
    key: 'area',
    ellipsis: true,
  },
  company_count: {
    title: '企业',
    dataIndex: 'company_count',
    key: 'company_count',
    width: 90,
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  head_company_count: {
    title: '链主企业',
    dataIndex: 'head_company_count',
    key: 'head_company_count',
    width: 75,
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  market_company_count: {
    title: '上市企业',
    dataIndex: 'market_company_count',
    key: 'market_company_count',
    width: 75,
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  specialization_new_company_count: {
    title: '专精特新',
    dataIndex: 'specialization_new_company_count',
    key: 'specialization_new_company_count',
    width: 75,
    ellipsis: true,
    align: 'center',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  high_tech_company_count: {
    title: '高新技术',
    dataIndex: 'high_tech_company_count',
    key: 'high_tech_company_count',
    width: 72,
    ellipsis: true,
    align: 'center',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
};

const COLUMN_KEYS = [
  'index', 'name', 'company_count', 'head_company_count',
  'market_company_count', 'specialization_new_company_count', 'high_tech_company_count',
];

/**
 * 专精特新榜单
 * @param {{
 *   regionCode?: string;
 *   industryCode?: string;
 *   onClick?: ({ record, column }) => void;
 *   className?: string;
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function DistributionRanking(props) {
  const {
    regionCode,
    industryCode,
    sortedInfo = {},
    onClick,
    className,
  } = props;

  const {
    data,
    isFetching,
  } = useGetChannelAreaDistributionCompanyRankQuery({
    industry_code: industryCode,
    area_key: regionCode,
    sort_column: sortedInfo?.order ? sortedInfo?.field : undefined,
  }, {
    skip: !regionCode || !industryCode,
  });

  const { columns: tableHead = [], dataSource } = data ?? {};
  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
        sortOrder: sortedInfo.columnKey === item.dataIndex ? (sortedInfo?.order ?? null) : null,
      });
      if ([
        'company_count',
        'head_company_count',
        'market_company_count',
        'specialization_new_company_count',
        'high_tech_company_count',
      ].includes(item.dataIndex)) {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item })}
          />
        );
      }
      if (item.dataIndex === 'company_count') {
        rowConfig.className = 'keep';
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [tableHead, sortedInfo, onClick]);
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      loading={isFetching}
      {...props}
      pageSize={Number.MAX_SAFE_INTEGER}
      pagination={false}
      className={classnames(styles.distributionRanking, className)}
    />
  );
}

export default DistributionRanking;
