import React from 'react';
import Treemap from '../../../../../Charts/TreeMap';
import { useGetPatentStatisticsIPCDistributionQuery } from '../../../../../../service/companyDetail';

const IPCDistribution = ({ companyId, grade }) => {
  const { data, isFetching } = useGetPatentStatisticsIPCDistributionQuery({
    company_id: companyId,
    penetration_grade: grade,
  });
  return (
    <div style={{ width: '662px', height: '344px' }}>
      <Treemap
        data={data?.series}
        isFetching={isFetching}
      />
    </div>
  );
};

export default IPCDistribution;
