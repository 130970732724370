import React, { useState } from 'react';
import Catalogue from './Catalogue';
import Centre from './Centre';
import { allForm } from '../../BasicInformation/data';
import style from './index.module.less';

const Content = (props) => {
  const { boxEl } = props;
  const [active, setActive] = useState();
  const handelClick = (value) => {
    setActive(value);
    const el = document.getElementById(value);
    boxEl.current.scrollTo({
      top: el.offsetTop - 80,
      behavior: 'smooth',
    });
  };
  return (
    <div className={style.box}>
      <div className={style.left}>
        <div className={style.catalogue}>
          <Catalogue
            handelClick={handelClick}
            active={active}
            list={allForm.map(item => ({ title: item.title, value: item.value })).filter(item => item.value !== 'basicInfo')}
          />
        </div>
      </div>
      <div className={style.middle}>
        <Centre
          allForm={allForm.filter(item => item.value !== 'basicInfo')}
          {...props}
        />
      </div>
      <div className={style.right}>
        <div>
          <div>专业化</div>
          <p>
            1、深耕于产业链某一环节或某一产品
            <br />
            2、从事特定细分领域≥3年。
            <br />
            3、主营业务收入总额占营业收入总额比重≥70%。
            <br />
            4、近2年主营业务收入平均增长率≥5%。
          </p>
        </div>

        <div>
          <div>精细化</div>
          <p>
            ① 资产 负债率≤70%。
            <br />
            ② 核心业务采用的信息系统包括但不限于研发设计CAX、生产制造CAM、经营管理ERP/OA、运维服务CRM、供应链管理SRM等。
            <br />
            ③ 取得相关管理体系认证， 或产品通过国家和地区产品认证。如质量、环境、职业健康安全管理体系认证或产品/服务等国家地区或行业标准认证等
            国际标准协会行业认证、相关管理体系认证包括ISO9000质量管理体系认证、 ISO14000环境管理体系认证、OHSAS18000职业安全健康管理体系认证等、发达国家和地区产品认证包括UL认证、CSA认证、ETL认证、GS认证等。
          </p>
        </div>

        <div>
          <div>特色化</div>
          <p>
            ① 技术和产品优势、主导产品在全国细分市场占有率达到10%以上。
            <br />
            ② 主营产品/服务拥有自主知识产权， 且产品已注册商标或已实现收入。
          </p>
        </div>

        <div>
          <div>创新能力</div>
          <p>
            1、研发费用、人员占比指标：
            <br />
            （1）营收≥1亿，近2年研发费用/总营收≥3%;
            <br />
            （2）营收0.5-1亿，近2年研发费用/总营收≥6%；
            <br />
            （3）营收
            {'<'}
            0.5亿，近2年新增股权融资总额≥8000万元+且研发费用≥3000万元，研发人员/职工总数≥50%。
            <br />
            2、自建或者与科研所合建研发机构，设立技术研究院、企业技术中心、企业工程中心、院士专家工作站、博士后工作站等。需提供合作协议、证书、立项报告等材料佐证。
            <br />
            3、两项以上与主导产品相关I类知识产权，实际应用且已产生经济效益。
            <br />
            4、创新直通条件，满足以下一项即可：
            <br />
            （1）获得国家级科技奖励，排名前三；
            <br />
            （2）进入“创客中国”全国大赛50强。
          </p>
        </div>

        <div>
          <div>产业链配套</div>
          <p>
            位于产业链关键环节，发挥“补短板”“锻长板”“填空白”等重要作用
          </p>
        </div>

        <div>
          <div>主导产品</div>
          <p>
            ① 制造业核心基础零部件、元器件、关键软件、先进基础工艺、关键基础材料和产业技术基础；
            <br />
            ② 制造强国十大重点产业领域；（新一代信息技术、高档数控机床和机器人、航空航天装备、海洋工程装备及技术船舶、先进轨道交通装备、节能与新能源汽车；、电力装备、新材料、生物医药及高性能医疗器械、农业机械装备）
            <br />
            ③ 属于网络强国建设的信息基础设施、关键核心技术、网络安全、数据安全领域等产品。
          </p>
        </div>

      </div>
    </div>
  );
};

export default Content;
