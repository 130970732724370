/*
 *Author: zhaochenyu
 *Description: 动态监测表格
 *Date: 2022/03/03
*/

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import MonitorTable from './MonitorTable';
import { openNewPage } from '../../../utils/urlMethods';
import {
  getMonitorCondition,
  getMonitorDymic,
} from '../../../api/mySelf';
import { IconFont } from '../../../utils/mapIcon';
import noData from '../../../assets/images/myself/nodynamicData.png';
import FocusCompany from './FocusCompany';
import style from './index.module.less';

function SearchList(props) {
  const { name, selectList, condKey, onChange, value, onSelect, multi } = props;
  const firstLoad = useRef(false);

  const selectListItem = useCallback((chose, cur) => {
    if (!multi) {
      onSelect([cur]);
      return;
    }
    let res = [];
    if (chose.length) {
      if (chose.includes(cur)) {
        res = chose.filter(item => item !== cur);
      } else {
        res = [...chose, cur];
      }
    } else {
      res.push(cur);
    }
    onSelect(res);
  }, []);

  useEffect(() => {
    if (!multi && selectList.length > 0) {
      onSelect([selectList[0].key]);
    }
  }, [selectList]);

  useEffect(() => {
    if (firstLoad.current) {
      const obj = {};
      obj[condKey] = value;
      // const obj1 = {};
      // const arr = [];
      // 存储列表名称
      // value.forEach(item => {
      //   selectList.forEach(item1 => {
      //     if (item === item1.key) {
      //       arr.push(item1.title);
      //     }
      //   });
      // });
      // obj1[condKey] = arr;
      onChange(obj);
      // changeName(obj1);
    } else {
      firstLoad.current = true;
    }
  }, [value]);

  return (
    <div className={style.list}>
      <div
        className={style.label}
      >
        <div className={style.point} />
        {name}
      </div>
      <div
        className={value.length > 0 ? style.normalAny : style.selectAny}
        onClick={() => onSelect([])}
        style={{ display: multi ? '' : 'none' }}
      >
        全部
      </div>
      <div className={style.others}>
        {(selectList || []).map((item, index) => (
          <div
            className={value.includes(item.key) ? style.selectItem : style.otherItem}
            key={`${item.key + index}`}
            onClick={() => selectListItem(value, item.key)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
}

function CompanyChangeDetail(props) {
  const { dataItem, date, monitorType } = props;
  const [getMore, setGetMore] = useState(false);
  const word = `发生了${dataItem.desc.reduce((pre, cur) => {
    let a = pre;
    a += cur.count;
    return a;
  }, 0)}条动态变更，包括${dataItem.desc.map((item2) => `${item2.name}(${item2.count})`)}`;
  const groupWord = dataItem.group.reduce((pre, cur) => {
    let res = pre;
    res += `${res === '' ? '' : '、'}${cur}`;
    return res;
  }, '');
  return (
    <div className={style.tableRow}>
      <div className={style.itemLeft}>
        <span
          className={style.compnayName}
          onClick={() => openNewPage('companyDetail', { id: dataItem.id })}
        >
          {dataItem.name}
        </span>
        <span
          className={style.changeCount}
          title={word}
        >
          发生了
          <span className={style.allCount}>
            {dataItem.desc.reduce((pre, cur) => {
              let a = pre;
              a += cur.count;
              return a;
            }, 0)}
          </span>
          条动态变更，包括
          {dataItem.desc.map((item2, index2) => `${item2.name}(${item2.count})${index2 === dataItem.desc.length - 1 ? '' : '、'}`)}
        </span>
        <span className={style.getMore} onClick={() => setGetMore(!getMore)}>{getMore ? '收起' : '展开'}</span>
      </div>
      <div className={style.itemRight} title={groupWord}>{groupWord}</div>
      {getMore && (
      <div
        className={style.moreChangeDetail}
      >
        <MonitorTable
          id={dataItem.id}
          name={dataItem.name}
          date={date}
          monitorType={monitorType}
        />
      </div>)}
    </div>
  );
}

function DynamicMonitor() {
  const [monitorTypeList, setMonitorTypeList] = useState([]);
  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [monitorType, setMonitorType] = useState([]);
  const [companyType, setCompanyType] = useState([]);
  const [timeRange, setTimeRange] = useState([]);
  const [monitorData, setMonitorData] = useState([]);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [loading, setLoading] = useState([false, false]);
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  const loadingRef = useRef([false, false]);
  const paramsCondition = useRef(null);
  const curCondition = useRef({
    detection_type_list: [],
    company_type_key_list: [],
    date_list: [],
  });

  const getMonitorList = useCallback(() => {
    const params = {
      date_list: curCondition.current.date_list,
      detection_type_list: curCondition.current.detection_type_list.length === 0
        ? monitorTypeList.map(item => item.key) : curCondition.current.detection_type_list,
      company_type_key_list: curCondition.current.company_type_key_list.length === 0
        ? companyTypeList.map(item => item.key) : curCondition.current.company_type_key_list,
    };
    loadingRef.current[1] = true;
    setLoading(cloneDeep(loadingRef.current));
    paramsCondition.current = cloneDeep(params);
    getMonitorDymic(params).then(res => {
      if (res.status === '00000') {
        if (JSON.stringify(paramsCondition.current) === JSON.stringify(params)) {
          setMonitorData(res.data);
        }
      }
    }).finally(() => {
      loadingRef.current[1] = false;
      setLoading(cloneDeep(loadingRef.current));
    });
  }, [monitorTypeList, companyTypeList]);

  const changeCodition = useCallback((obj) => {
    curCondition.current = {
      ...curCondition.current,
      ...obj,
    };
    getMonitorList();
  }, [getMonitorList]);

  useEffect(() => {
    loadingRef.current = [true, true];
    setLoading(cloneDeep(loadingRef.current));
    getMonitorCondition().then(res => {
      if (res.status === '00000') {
        setMonitorTypeList(res.data.detection_of_type || []);
        setCompanyTypeList(res.data.company_type_list || []);
        setDateList(res.data.date_list || []);
      }
    }).finally(() => {
      loadingRef.current[0] = false;
      setLoading(cloneDeep(loadingRef.current));
    });
  }, []);

  const moniterCondition = useMemo(() => (
    <SearchList
      name="监测类型"
      condKey="detection_type_list"
      selectList={monitorTypeList}
      value={monitorType}
      onSelect={setMonitorType}
      onChange={changeCodition}
      multi
    />
  ), [monitorTypeList, monitorType, setMonitorType, changeCodition]);

  const companyCondition = useMemo(() => (
    <SearchList
      name="企业类别"
      condKey="company_type_key_list"
      selectList={companyTypeList}
      value={companyType}
      onSelect={setCompanyType}
      onChange={changeCodition}
      multi
    />
  ), [companyTypeList, companyType, setCompanyType, changeCodition]);

  const timeCodiyion = useMemo(() => (
    <SearchList
      name="时间范围"
      condKey="date_list"
      selectList={dateList}
      value={timeRange}
      onSelect={setTimeRange}
      onChange={changeCodition}
    />
  ), [dateList, timeRange, setTimeRange, changeCodition]);

  return (
    <div className={style.all}>
      <div className={style.condition}>
        <Spin tip="搜索中，请稍候..." spinning={loading[0]}>
          {moniterCondition}
          {companyCondition}
          {timeCodiyion}
        </Spin>
      </div>
      <div className={style.content}>
        <Spin tip="搜索中，请稍候..." spinning={loading[1]} delay={200}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <div className={style.addBtn} onClick={() => setShowAddCompany(true)} style={{ display: monitorData.length > 0 ? '' : 'none' }}>
              <IconFont type="iconplus-circle" style={{ marginRight: '2px' }} />
              添加企业
            </div>
          </div>
          {monitorData.length > 0 ? monitorData.map((item, index) => (
            <div className={style.monitorItem} key={`${index}monitorItem`}>
              <div className={style.tableHeader}>
                <div className={style.itemLeft}>
                  <span className={style.date}>
                    {item.date === today ? '今天' : item.date}
                  </span>
                </div>
                <div className={style.itemRight}>分组</div>
              </div>
              {item.company_list.map((item1, index1) => (
                <CompanyChangeDetail
                  key={`${index1}rowItem${item.date}`}
                  dataItem={item1}
                  date={item.date}
                  monitorType={monitorType.length === 0
                    ? monitorTypeList.map(item2 => item2.key) : monitorType}
                />
              ))}
            </div>
          )) : (
            !loading[1] && (
            <div className={style.noData}>
              <img alt="" src={noData} className={style.noDataImg} />
              <div className={style.addDesc}>
                暂无最新动态，去
                <div className={style.addBtn} onClick={() => setShowAddCompany(true)}>
                  <IconFont type="iconplus-circle" style={{ marginRight: '2px' }} />
                  添加企业
                </div>
              </div>
            </div>
            )
          )}
        </Spin>
      </div>
      {showAddCompany && (
      <div className={style.focusCompany}>
        <FocusCompany setShowAddCompany={setShowAddCompany} getMonitorList={getMonitorList} />
      </div>)}
    </div>
  );
}

export default DynamicMonitor;
