import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import { useGetParticipateDraftingStandardsQuery } from '../../../../../../service/companyDetail';
// import TableNoData from '../../../TableNoData';
import style from './index.module.less';

function AnnotateDetails(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const { data: tableData, isFetching: isTableFetching } = useGetParticipateDraftingStandardsQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  const dataSource = useMemo(() => (
    tableData?.dataSource?.map((item, index) => ({
      key: index,
      ...item,
    }))
  ), [tableData]);

  const tabelEl = useMemo(() => {
    if (!dataSource || dataSource.length) {
      return (
        <Table
          dataSource={dataSource}
          columns={tableData?.columns}
          title={() => (
            <>
              <span>{tableData?.title}</span>
              <span className={style.count}>{tableData?.count}</span>
            </>
          )}
          strip
          loading={isTableFetching}
          pagination={{
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [dataSource]);

  return tabelEl;
}

export default AnnotateDetails;
