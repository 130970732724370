import { createElement } from 'react';
import { convertHeading } from '../../../utils';

/**
 * 基础标题组件
 * @param num
 * @param level
 * @constructor
 */


function Heading({ level, text, path, position, className, style }: any) {
  return createElement(
    `h${level}`,
    { id: path ? `${path}` : undefined, className, style },
    `${typeof position === 'number' ? convertHeading(position, level) : ''}${text}`,
  );
}

export default Heading;
