import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import OrthogonalOrientTree from '../OrthogonalOrientTree';
import ZoomButton from '../ZoomButton';
import Button from '../Button';
import styles from './index.module.less';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import Underline from '../../../../components/Underline';
import InvestmentMapTable from '../InvestmentMapTable';
import {
  useGetChinaMerchantsMapQuery,
} from '../../../../service/industry-channel';
import { openNewPage } from '../../../../utils/urlMethods';

function InvestmentMap({ industryCode, industryName }) {
  const [select, setSelect] = useState('');
  // const [selectName, setSelectName] = useState(industryName);
  const [zoom, setZoom] = useState(0.8);
  const [isTreeDropdownOpen, setIsTreeDropdownOpen] = useState(true);
  const [screenCount, setScreenCount] = useState(0);
  const [companyId, setCompanyId] = useState('');
  const [organTableState, setOrganTableState] = useState({
    pageSize: 20,
    pageNumber: 1,
  });

  const { data: orientData, isFetching } = useGetChinaMerchantsMapQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const onEvents = params => {
    if (params.data?.value) {
      setSelect(params.data?.value);
      // setSelectName(params.data?.name);
      setCompanyId('');
      setIsTreeDropdownOpen(true);
    }
    if (params.data?.company_id) {
      setSelect('');
      // setSelectName('');
      setCompanyId(params.data?.company_id);
      setIsTreeDropdownOpen(true);
    }
  };

  useEffect(() => {
    setSelect(industryCode);
  }, [industryCode]);

  return (
    <>
      <div className={styles.header}>
        <Title title="招商图谱" />
        <div
          className={styles.buttons}
        >
          <ZoomButton
            zoom={zoom}
            minZoom={0.1}
            maxZoom={2}
            className={styles.button}
            onChange={z => {
              const _z = Number(z?.toFixed(2));
              setZoom(_z);
            }}
          />
        </div>
      </div>
      <div className={classnames(styles.investmentOrgan, { 'grey-out': isFetching })}>
        {
          orientData && <OrthogonalOrientTree data={orientData} onEvents={onEvents} select={select} zoom={zoom} companyId={companyId} />
        }
        <div
          className={classnames(
            styles.list,
            { [styles.hidden]: !isTreeDropdownOpen },
          )}
        >
          <div className={styles.overview}>
            <Subtitle
              className={styles.subtitle}
              description="推荐企业"
            />
            <Subtitle
              className={styles.subtitle}
              // selectName={selectName}
              description="筛选结果"
              value={screenCount}
              unit="家"
            />
          </div>
          <Underline />
          <InvestmentMapTable
            className={styles.table}
            industryCode={industryCode}
            linkCode={select}
            companyId={companyId}
            pageSize={organTableState.pageSize}
            pageNumber={organTableState.pageNumber}
            getCount={setScreenCount}
            onChange={({ current, pageSize }) => {
              setOrganTableState({
                ...organTableState,
                pageSize,
                pageNumber: current,
              });
            }}
            onCompanyClick={({ company_id: _id }) => {
              if (!_id) return;
              openNewPage('companyDetail', { id: _id });
            }}
          />
          <Button
            className={classnames(
              styles.toggleButton,
              {
                [styles.slim]: !isTreeDropdownOpen,
              },
            )}
            text={isTreeDropdownOpen ? '收起' : '展开'}
            onClick={() => setIsTreeDropdownOpen(!isTreeDropdownOpen)}
          />
        </div>
      </div>
    </>
  );
}

export default InvestmentMap;
