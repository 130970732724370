/*
 *Author: zhaochenyu
 *Description: 区域画像预览报告首页
 *Date: 2021/11/17
*/

import React from 'react';
import style from './index.module.less';

function FrontCover(props) {
  const { area, title } = props;
  const myDate = new Date();
  // const getletterSpacing = (word) => {
  //   if (!word) return 16;
  //   let res;
  //   if (word.length > 8) {
  //     res = 5;
  //   } else if (word.length > 6) {
  //     res = 12;
  //   } else {
  //     res = 16;
  //   }
  //   return res;
  // };

  return (
    <div className={style.all}>
      {/* <div className={style.coverLogo} /> */}
      {/* <div className={style.bigTitle}>报告</div> */}
      {/* <div className={style.splitLine} /> */}
      <div
        className={style.city}
        // style={{ letterSpacing: `${getletterSpacing(area)}px` }}
      >
        {area}
      </div>
      <div className={style.subTitle}>{title}</div>
      <div className={style.companyName}>上奇研究院</div>
      <div className={style.date}>
        {`${myDate.getFullYear()}年${myDate.getMonth() + 1}月`}
      </div>
    </div>
  );
}

export default FrontCover;
