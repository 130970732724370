import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import {
  useGetBusinessScopeChangeQuery,
} from '../../../../../../service/companyDetail';
import style from '../index.module.less';

function BusinessScope(props) {
  const { companyId } = props;
  const [beforeActive, setBeforeActive] = useState(null);
  // const [afterActive, setAfterActive] = useState(null);
  const [businessPage, setBusinessPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: businessData, isFetching: isBusinessFetching } = useGetBusinessScopeChangeQuery({
    company_id: companyId,
    page_size: businessPage.pageSize,
    page_number: businessPage.pageNumber,
  }, {
    skip: !companyId,
  });

  const businessColumns = useMemo(() => (
    businessData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          width: 70,
          ...item,
        };
      }
      if (item.dataIndex === 'before') {
        return {
          ...item,
          width: 510,
          render: (text, row) => {
            if (!text) return '';
            let str = text.replace(/\[\+/g, '');
            str = str.replace(/\+\]/g, '');
            let newText = text.replace(/\[\+/g, '<span style=\'color:red\'>');
            newText = newText.replace(/\+\]/g, '</span>');
            if (beforeActive === row.index) {
              return (
                <div className={style.activeBefore}>
                  <div dangerouslySetInnerHTML={{ __html: newText }} />
                  <div className={style.packUp} onClick={() => { setBeforeActive(); }}>收起</div>
                </div>
              );
            }
            return (
              <div className={style.before}>
                <div dangerouslySetInnerHTML={{ __html: newText }} />
                <span
                  className={style.more}
                  style={{ display: str.length > 31 ? 'block' : 'none' }}
                  onClick={() => {
                    setBeforeActive(row.index);
                  }}
                >
                  更多
                </span>
              </div>
            );
          },
        };
      }
      if (item.dataIndex === 'after') {
        return {
          ...item,
          width: 510,
          render: (text, row) => {
            if (!text) return '';
            let str = text.replace(/\[-/g, '');
            str = str.replace(/-\]/g, '');
            let newText = text.replace(/\[-/g, '<span style=\'color:red\'>');
            newText = newText.replace(/-\]/g, '</span>');
            if (beforeActive === row.index) {
              return (
                <div className={style.activeAfter}>
                  <div dangerouslySetInnerHTML={{ __html: newText }} />
                  <div className={style.packUp} onClick={() => { setBeforeActive(); }}>收起</div>
                </div>
              );
            }
            return (
              <div className={style.after}>
                <div dangerouslySetInnerHTML={{ __html: newText }} />
                <span
                  className={style.more}
                  style={{ display: str.length > 31 ? 'block' : 'none' }}
                  onClick={() => {
                    setBeforeActive(row.index);
                  }}
                >
                  更多
                </span>
              </div>
            );
          },
        };
      }
      if (item.dataIndex === 'date') {
        return {
          width: 120,
          ellipsis: true,
          ...item,
        };
      }
      return {
        ...item,
        ellipsis: true,
      };
    })
  ), [businessData, beforeActive]);
  const manageTable = () => {
    if (!businessData || businessData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={businessData?.dataSource}
          columns={businessColumns}
          locale={{ emptyText: ' ' }}
          title={() => (
            <>
              <span>{businessData?.title}</span>
              <span className={style.count}>{businessData?.count}</span>
            </>
          )}
          strip
          loading={isBusinessFetching}
          pagination={{
            hideOnSinglePage: true,
            current: businessPage.pageNumber,
            pageSize: businessPage.pageSize,
            total: businessData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setBusinessPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  };

  return manageTable();
}

export default BusinessScope;
