// 产业长短板
import React, { useState, useEffect, useMemo, useCallback } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import styles from './index.module.less';
import GraphScheme from '../GraphScheme';
// import { updateIndustryCode } from '../../../../slices/app';
import { useGetFindAuthIndustryQuery } from '../../../../service/city-channel';
// import { getIsBuyIndustry } from '../../../../api/homepage';
// import ChannelModal from '../../../../components/MySelf/ChannelModal';

const { Option } = Select;
const IndustrialMerits = ({ name, cityCode, cityName }) => {
  const [industryCode, setIndustryCode] = useState({});
  // const [nowIndustryCode, setNowIndustryCode] = useState({});
  // const [channelModalVisible, setChannelModalVisible] = useState(false);
  // const dispatch = useDispatch();
  // const jurisdictionQuery = (e) => {
  //   getIsBuyIndustry({
  //     industry_code: e.value,
  //   }).then(res => {
  //     if (res.status === '00000') {
  //       if (res.data) {
  //         setChannelModalVisible(false);
  //         setIndustryCode(e);
  //       } else {
  //         setChannelModalVisible(true);
  //       }
  //     }
  //   });
  // };
  const handleChange = (value, e) => {
    // jurisdictionQuery(e);
    setIndustryCode(e);
    // setNowIndustryCode(e);
  };
  const { data } = useGetFindAuthIndustryQuery({
    area_key: cityCode,
  }, {
    skip: !cityCode,
  });
  useEffect(() => {
    if (data) {
      setIndustryCode(data[0]);
      // setNowIndustryCode(data[0]);
    }
  }, [data]);

  // useEffect(() => {
  //   dispatch(updateIndustryCode(industryCode));
  //   jurisdictionQuery();
  // }, [industryCode]);
  return (
    <>
      <div id='merits' className={styles.box}>
        <div className={styles.select} >
          {
            data && (
              <Select
                value={industryCode.title}
                style={{
                  width: 180,
                  height: 32,
                  borderRadius: 4,
                }}
                onChange={handleChange}
              >
                {
                  data.map(item => <Option key={item.value} value={item.value} title={item.title}>{item.title}</Option>)
                }
              </Select>
            )
          }
        </div>
        <GraphScheme
          // industryCode={channelModalVisible ? '' : industryCode}
          industryCode={industryCode.value}
          cityCode={cityCode}
          cityName={cityName}
          name={name}
          // channelModalVisible={channelModalVisible}
          industry={data?.filter(item => item.value === industryCode)[0]?.title}
        />
      </div>
      {/* {
        channelModalVisible && <ChannelModal
          onClose={() => setChannelModalVisible(false)}
          defaultSelect={nowIndustryCode.value}
          paySuccessCb={(curBuyIndustrList) => {
            setChannelModalVisible(false);
            setIndustryCode(nowIndustryCode);
          }}
        />
      } */}
    </>
  );
};

export default IndustrialMerits;
