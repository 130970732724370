import { post, get } from '../utils/request';
import { USERMANAGE, USER } from '../utils/url';

// 验证手机号验证码
const getValidatorNote = param => post(`${USER}/validator_user_sms`, {
  ...param,
});

// 验证手机号
const getValidatorPhone = param => post(`${USER}/validator_user_captcha`, {
  ...param,
});

// 修改密码验证码
const getModifyNote = param => post(`${USER}/change_user_password_sms`, {
  ...param,
});

// 修改密码
const getModifyPassword = param => post(`${USER}/change_user_password`, {
  ...param,
});

const FindUserInfo = (params) => get(`${USERMANAGE}/find_user_info`, {
  ...params,
});

// 立即升级&申请续费
const userApply = (params) => get(`${USERMANAGE}/user_application`, {
  ...params,
});

export {
  getValidatorNote,
  getValidatorPhone,
  getModifyNote,
  getModifyPassword,
  FindUserInfo,
  userApply,
};
