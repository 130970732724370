import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import Subtitle from '../../../../../../components/Subtitle';
import Input from '../../../../../Industry/components/Input';
import CompanyTable from '../CompanyTable';
import Underline from '../../../../../../components/Underline';
import styles from './index.module.less';
import { openNewPage } from '../../../../../../utils/urlMethods';
import { StandardModal } from '../../../../../Industry/components/Modal';
import ExportModal from '../../../../../../components/ExportModal';
import CompanyPatentList from '../../../../../Industry/components/CompanyPatentList';

const DEFAULT_TABLE_STATE = {
  pageSize: 20,
  pageNumber: 1,
  filteredInfo: {},
  sortedInfo: {},
};

const PATENT_KEY = {
  industry_patent: undefined,
  approval_patent_count: undefined,
  possess_patent_sum: '发明',
  utility_patent_num: '实用新型',
  design_patent_num: '外观设计',
};

function DataScheme({ cityCode, total }) {
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const [keyword, setKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tableState, setTableState] = useState(DEFAULT_TABLE_STATE);
  const [count, setCount] = useState(0);
  const [companyPatentModalState, setCompanyPatentModalState] = useState({
    pageSize: 20,
    pageNumber: 1,
    type: undefined,
    visible: false,
    title: '',
    value: '-',
    companyId: '',
  });
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [params, setParams] = useState(null);

  const exportFlag = Array.isArray(enterpriseAccountExport)
  && enterpriseAccountExport?.length > 0
  && enterpriseAccountExport?.includes('exportCityDataCompany');

  useEffect(() => {
    setTableState(DEFAULT_TABLE_STATE);
  }, [cityCode]);

  const onData = useCallback((data = {}) => {
    const { count: _count } = data;
    setCount(_count);
  }, []);
  return (
    <section className={styles.scheme}>
      <div className={styles.header}>
        <div className={styles.title}>企业</div>
        <Subtitle
          description="共计"
          value={total ?? '-'}
          unit="家"
          className={styles.subtitle}
        />
        {
          count !== Number(total) && <Subtitle
            description="筛选结果"
            value={count ?? '-'}
            unit="家"
            className={styles.subtitle}
          />
        }
        <Input
          className={classNames(styles.search, { [styles.right]: !exportFlag })}
          value={keyword}
          placeholder="输入企业名称"
          onChange={e => {
            setKeyword(e.target.value);
            if (e.target.value === '') {
              setSearchKeyword('');
              setTableState({
                ...DEFAULT_TABLE_STATE,
                sortedInfo: tableState?.sortedInfo,
              });
            }
          }}
          onPressEnter={value => {
            setSearchKeyword(value);
            setTableState({
              ...DEFAULT_TABLE_STATE,
              sortedInfo: tableState?.sortedInfo,
            });
          }}
          onSearch={value => {
            setSearchKeyword(value);
            setTableState(DEFAULT_TABLE_STATE);
          }}
        />
        {
          exportFlag && (
            <Button className={styles.exportButton} onClick={() => setExportModalVisible(true)}>数据导出</Button>
          )
        }
      </div>
      <Underline />
      <div className={styles.table}>
        <CompanyTable
          keyword={searchKeyword}
          cityCode={cityCode}
          pageNumber={tableState.pageNumber}
          pageSize={tableState.pageSize}
          sortedInfo={tableState.sortedInfo}
          filteredInfo={tableState.filteredInfo}
          onCompanyClick={({ _id }) => {
            if (!_id) return;
            openNewPage('companyDetail', { id: _id });
          }}
          onChange={({ current }, filters, sorter) => {
            setTableState(prev => ({
              ...prev,
              pageNumber: current,
              filteredInfo: filters,
              sortedInfo: sorter,
            }));
          }}
          onClick={({ record, record: { _id }, column, column: { title: _title, dataIndex } }) => {
            setCompanyPatentModalState(prev => ({
              ...prev,
              pageNumber: 1,
              visible: true,
              companyId: _id,
              title: _title,
              value: '-',
              type: PATENT_KEY[dataIndex],
            }));
          }}
          onData={onData}
          setParams={setParams}
        />
        {
          // 企业专利弹窗
          companyPatentModalState.visible && (
            <StandardModal
              header={
                <div className={styles.modalHeader}>
                  <span className={styles.title}>{companyPatentModalState.title}</span>
                  <Subtitle
                    className={styles.subtitle}
                    description="共"
                    value={companyPatentModalState.value ?? '-'}
                    unit="条"
                  />
                </div>
              }
              onClose={() => {
                setCompanyPatentModalState(prev => ({
                  ...prev,
                  visible: false,
                }));
              }}
              className={styles.modal}
            >
              <CompanyPatentList
                patentType={companyPatentModalState.type}
                companyId={companyPatentModalState.companyId}
                pageSize={companyPatentModalState.pageSize}
                pageNumber={companyPatentModalState.pageNumber}
                onChange={({ current }) => {
                  setCompanyPatentModalState(prev => ({
                    ...prev,
                    pageNumber: current,
                  }));
                }}
                onData={(data = {}) => {
                  const { count: _count } = data;
                  setCompanyPatentModalState(prev => ({ ...prev, value: _count }));
                }}
                onPatentClick={({ _id }) => {
                  openNewPage('patentDetail', {
                    id: _id,
                  });
                }}
              />
            </StandardModal>
          )
        }
      </div>
      {
        exportModalVisible && (
          <div className={styles.mask}>
            <ExportModal
              dataKey="industry_overview_company_list"
              params={params}
              onClose={() => {
                setExportModalVisible(false);
              }}
            />
          </div>
        )
      }
    </section>
  );
}

export default DataScheme;
