/*
* Author: zhangmeng
* Description: '时间范围选择器'
* Date: 2022/06/22
*/

import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import moment from 'moment';
import style from './index.module.less';

const { RangePicker } = DatePicker;
function DateSelect(props) {
  const { title, select, setSelect, fastTagList, active, setActive } = props;
  const disabledDate = useCallback((current) => current && current > new Date().getTime(), []);
  const changeData = useCallback((date, dateString) => {
    setSelect(date);
    setActive('');
  }, []);

  const fastTagClick = (item) => {
    const { value, type, title: tagTitle } = item;
    setActive(tagTitle);
    const endDate = moment();
    const startDate = moment().subtract(value, type);
    setSelect([startDate, endDate]);
  };

  return (
    <div className={style.time}>
      <div className={style.point} />
      <div className={style.label}>
        {title}
      </div>
      {
        fastTagList && (
          <div className={style.fastTag}>
            {
              fastTagList.map(item => (
                <div
                  key={item.title}
                  className={classNames(style.tag, { [style.active]: item.title === active })}
                  onClick={() => { fastTagClick(item); }}
                >
                  {item.title}
                </div>
              ))
            }
          </div>
        )
      }
      <div className={fastTagList ? style.fastTagDate : style.date}>
        <RangePicker
          value={select}
          placeholder={['开始时间', '截止时间']}
          disabledDate={disabledDate}
          onChange={changeData}
          dropdownClassName={style.dateDrop}
        />
      </div>
    </div>
  );
}

export default DateSelect;
