import React, { useMemo } from 'react';
import moment from 'moment';
import { Macro } from '@sqke/parser';
import { merge } from 'lodash';
import style from './index.module.less';

const TEXTMAP = {
  YEAR: moment().format('YYYY'),
  MONTH: moment().format('MM'),
  COMPANY_NAME: '杭州海康威视数字技术股份有限公司',
  INDUSTRY_NAME: '人工智能',
  AREA_NAME: '北京市',
};

function ReportCover({ coverScheme, textMap, scale = 'scale(0.14861, 0.14871)' }) {
  const _macro = new Macro({ ...TEXTMAP, ...textMap });
  const replaceMacro = (obj) => {
    const _nextObj = {};
    // eslint-disable-next-line
    for (const key in obj) {
      const strOrObj = obj[key];
      if (strOrObj && typeof strOrObj === 'object') {
        _nextObj[key] = replaceMacro(strOrObj);
      } else if (typeof strOrObj === 'string') {
        _nextObj[key] = _macro.replace(strOrObj);
      } else {
        _nextObj[key] = strOrObj;
      }
    }
    return _nextObj;
  };
  const _coverScheme = useMemo(() => merge({}, coverScheme, replaceMacro(coverScheme)), [coverScheme]);
  const { children, src } = _coverScheme?.properties;

  return (
    <div className={style.all}>
      <div className={style.pic} style={{ transform: scale }} >
        <img src={src} alt=""></img>
        {
          children && children.map((child, childIndex) => (
            <div
              className={style.text}
              key={`key${childIndex}`}
              style={{
                width: `${parseFloat(child.style?.width) * 37.75}px`,
                height: `${parseFloat(child.style?.height) * 37.7}px`,
                left: `${parseFloat(child.style?.left) * 37.75}px`,
                top: `${parseFloat(child.style?.top) * 37.7}px`,
                textAlign: child.properties?.contents?.[child.properties?.contents?.length - 1].attributes?.align,
              }}
            >
              {
                child.properties?.contents?.map((item, index) => (
                  <span
                    key={`key${index}`}
                    style={{
                      fontSize: item.attributes?.size,
                      color: item.attributes?.color,
                    }}
                  >
                    {item.insert}
                  </span>
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default ReportCover;
