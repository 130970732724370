import { AnyDataSource, IApiDataSource } from '@sqke/parser';
import { api } from '../store/api.slice';

const getApiSourceArgs = (arg: Omit<IApiDataSource, 'type'>) => {
  const {
    url,
    method = 'GET',
    params,
    headers,
    body,
  } = arg;

  const baseArgs = {
    url,
    method,
    headers,
  } as Omit<IApiDataSource, 'bodyType'>;
  if (method.toUpperCase() === 'GET') {
    baseArgs.params = params;
  } else if (method.toUpperCase() === 'POST') {
    baseArgs.body = body;
  }

  return baseArgs;
};

export const dataApi = api.injectEndpoints({
  endpoints: builder => {
    return {
      anyDataSource: builder.query({
        query(arg: AnyDataSource) {
          const { type } = arg;
          switch (type) {
            case 'api':
            case 'api-selection':
              return getApiSourceArgs(arg);
            case 'json':
              return {
                url: `/report-engine-service/reportTemplateStaticData/find_template_chart_data`,
                method: 'post',
                body: {
                  static_data_id: arg.jsonId,
                },
              };
            case 'model':
            default:
              return { url: '' };
          }
        },
        transformResponse(response, meta, arg) {
          const { type } = arg;
          if (type !== 'json') {
            return response;
          }
          const json = (response as any)?.static_data_json;
          if (!json && json !== '') {
            return response;
          }
          return typeof json === 'string' ? JSON.parse(json) : json;
        }
      })
    };
  },
  overrideExisting: true,
});

export const {
  useLazyAnyDataSourceQuery,
  useAnyDataSourceQuery,
} = dataApi;

