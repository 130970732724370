import React from 'react';
import { cloneDeep } from 'lodash';
import { Spin } from 'antd';
import BarChart from '../../../../components/Industrial/BarChart';
import StackBarChart from '../../../../components/Industrial/StackBarChart';
import HorizontalBarChart from '../../../../components/Industrial/HorizontalBarChart';
import PieChart from '../../../../components/Industrial/PieChart';
import FunnelChart from '../../../../components/Industrial/FunnelChart';
import Title from '../../../../components/Title';
import { useGetIndustrialProfileQuery } from '../../../../service/city-channel';
import styles from './index.module.less';

const handleBerData = (data) => {
  const cloneData = cloneDeep(data);
  return (
    {
      xAxis: cloneData.data.abscissa_list,
      yAxis: cloneData.data.detail_list.map(item => {
        if (cloneData.data.detail_list.length === 1) {
          return ({
            value: item.ordinate,
            name: item.target,
            type: 'bar',
            unit: item.unit,
          });
        }

        return ({
          data: item.ordinate,
          name: item.target,
          type: 'bar',
          unit: item.unit,
        });
      }),
      yName: cloneData.data.detail_list[0].unit,
    }
  );
};

const charts = [
  { title: '企业数量', charts: (chartData) => <BarChart data={{ data: handleBerData(chartData) }} /> },
  {
    title: '企业区域分布情况',
    charts: (chartData) => {
      if (Object.keys(chartData?.data).length > 0) {
        return <BarChart data={{ bubble: true, data: handleBerData(chartData) }} />;
      }
      return <div>暂无数据</div>;
    },
  },
  { title: '企业金字塔机构', charts: (chartData) => <FunnelChart data={cloneDeep(chartData?.data_list).reverse()} /> },
  { title: '专利类型', charts: (chartData) => <StackBarChart data={{ data: handleBerData(chartData) }} /> },
  { title: '标准类型', charts: (chartData) => <HorizontalBarChart data={handleBerData(chartData)} /> },
  {
    title: '创新机构分布',
    charts: (chartData) => {
      if (chartData?.data?.length > 0) {
        return <PieChart type='trends' isFull={true} data={chartData?.data} />;
      }
      return <div>暂无数据</div>;
    },
  },
];

const IndustrialProfile = ({ name, cityCode }) => {
  const { data: chartData } = useGetIndustrialProfileQuery({
    area_key: cityCode,
  });
  return (
    <div className={styles.box} id='generalize'>
      <div className={styles.title}>
        <Title title={name} />
        <p className={styles.timeScope}>{`(${chartData?.time_scope || ''})`}</p>
      </div>
      <ul>
        {
          charts.map((item, index) => (
            <li key={item.title}>
              <p className={styles.subtitle} >
                <span>{chartData && chartData?.result_list[index] ? chartData?.result_list[index].title : ''}</span>
              </p>
              <div className={styles.charts}>
                {
                  chartData && chartData.result_list.length > 0 ? item.charts(chartData.result_list[index]) : <Spin />
                }
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default IndustrialProfile;
