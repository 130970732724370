import React from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import ReportCover from '../../../components/ReportCover';
import { IconFont } from '../../../utils/mapIcon';
import { reportCoverMap } from '../../../utils/reportCoverMap';
import style from './index.module.less';

const currentTime = moment().format('YYYY-MM');

const Index = ({ reportList, pushReportDetail }) => (
  <>
    {reportList && reportList.length > 0 && reportList.map((item, index) => (
      <div className={style.report} key={index} onClick={() => pushReportDetail(item)}>
        {
          item.cover_scheme && Object.keys(item.cover_scheme).length > 0 && <ReportCover coverScheme={item.cover_scheme} />
        }
        {
          (!item.cover_scheme || Object.keys(item.cover_scheme).length === 0) && (
            <img
              src={item.template_source === 1 ? reportCoverMap[item.id] : item.cover_url || item.static_cover_url}
              alt=""
            />
          )
        }
        <div className={style.ctn}>
          <div className={style.top}>
            <div className={style.title} title={item.template_name}>{item.template_name}</div>
            <span className={style.subTitle}>{item.category_list}</span>
            &nbsp;
            {
              item.template_date_type && (
                <>
                  &nbsp;
                  <span className={style.subTitle}>{item.template_date_type}</span>
                </>
              )
            }
          </div>
          <div className={style.bottom}>
            <div className={style.row}>
              <span>作&emsp;&emsp;者</span>
              <span>{item.author || ''}</span>
            </div>
            <div className={style.row}>
              <span>{item.template_type === '系统模版' ? '数据截止' : '发表时间'}</span>
              <span>{item.template_type === '系统模版' ? currentTime : item.date}</span>
            </div>
            <div className={style.row}>
              <span>
                <IconFont type="iconico_yueduliang" />
                <span>{item.read_the_number || 0}</span>
              </span>
              <span>
                <ArrowDownOutlined />
                <span>{item.download_the_number || 0}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

export default Index;
