/*
* Author: zhangmeng
* Description: '柱线图'
* Date: 2022/04/11
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const bar = ['#1961F5', '#C0D9FF', '#00CA72'];
const barStop = ['#01193D', '#011A3C', '#011A3C'];
const line = [
  {
    lineC: '#59D700',
    borderC: 'rgba(89, 215, 0, 0.7)',
    centerC: 'rgba(255, 255, 255, 0.1)',
  },
  {
    lineC: '#ECD570',
    borderC: '#FEFAEB',
    centerC: '#ECD570',
  },
  {
    lineC: '#F0461E',
    borderC: '#c58272',
    centerC: '#f5edec',
  },
];
function BarAndLine(props) {
  const { data, rotate } = props;
  const [options, setOptions] = useState({});
  useEffect(() => {
    const { yUnit, yName, yRightName, yRightUnit, hideMinimum, xName, xAxis, yAxis, curLocation = 0, bubble } = data;
    const legend = yAxis?.map(item => item.name);
    let barI = -1;
    let lineI = -1;
    const bubbleHeight = (index) => yAxis[0]?.value[index];
    /* eslint-disable  no-else-return */
    const series = yAxis?.map((item, index) => {
      if (item.isType === '0') {
        barI += 1;
        return {
          name: item.name,
          type: 'bar',
          yAxisIndex: item.yIndex,
          // barWidth: bubble ? '' : 12,
          itemStyle: {
            color: bar[barI],
          },
          markPoint: {
            data: [
              { value: bubbleHeight(xAxis.indexOf(curLocation)), xAxis: xAxis.indexOf(curLocation), yAxis: bubbleHeight(xAxis.indexOf(curLocation)) },
            ],
            itemStyle: {
              color: '#FD6205',
            },
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              fontWeight: 600,
              fontSize: 12,
              lineHeight: 10,
              textBorderColor: '#FD6205',
              textBorderWidth: 3,
            },
          },
          // markPoint: bubble ? {
          //   data: [
          //     { value: bubbleHeight(xAxis.indexOf(curLocation)), xAxis: xAxis.indexOf(curLocation), yAxis: bubbleHeight(xAxis.indexOf(curLocation)) },
          //   ],
          //   itemStyle: {
          //     color: '#FD6205',
          //   },
          //   label: {
          //     show: true,
          //     position: 'inside',
          //     color: '#fff',
          //     fontWeight: 600,
          //     fontSize: 12,
          //     lineHeight: 10,
          //     textBorderColor: '#FD6205',
          //     textBorderWidth: 3,
          //   },
          // } : '',
          data: item?.value?.map((item2, index2) => (
            {
              value: item2,
              itemStyle: {
                color: xAxis[index2] === curLocation ? '#FD6205' : '#1961F5',
              },
            }
          )),
        };
      } else {
        lineI += 1;
        // barStop[barI]
        return (
          {
            name: item.name,
            type: 'line',
            symbolSize: 8,
            color: line[lineI].lineC,
            smooth: true,
            symbol: 'circle',
            // itemStyle: {
            //   borderColor: line[lineI].borderC,
            //   color: line[lineI].centerC,
            //   borderWidth: 1,
            // },
            // 折线颜色和线型
            lineStyle: {
              type: 'solid',
              color: line[lineI].lineC,
            },
            // axisLabel: {
            //   show: true, // 不显示坐标轴上的文字
            // },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [{
                  offset: 0, color: 'rgba(255, 255, 255, 0.1)',
                }, {
                  offset: 1, color: 'rgba(89, 215, 0, 0.7)',
                }],
              },
            },
            yAxisIndex: item.yIndex,
            data: item.value,
          }
        );
      }
    });
    const option = {

      legend: [{
        show: true,
        bottom: 10,
        left: 240,
        itemWidth: 8,
        itemHeight: 10,
        symbol: 'rect',
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: legend?.slice(0, 1),
      },
      {
        show: true,
        bottom: 10,
        left: 80,
        itemWidth: 20,
        itemHeight: 10,
        symbol: 'rect',
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: legend?.slice(1),
      },
      ],
      // {
      //   data: legend.slice(1),
      //   padding: [0, 0, 0, 0],
      //   bottom: 8,
      //   left: 50,
      //   align: 'auto',
      //   itemWidth: 20,
      //   itemHeight: 10,
      //   selectedMode: false,
      //   textStyle: {
      //     color: '#7B8796',
      //     fontSize: 12,
      //   },
      // },

      // ],
      grid: {
        top: 30,
        right: 40,
        bottom: 86,
        left: 60,
        // containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        backgroundColor: '#fff',
        borderWidth: 0,
        borderColor: '#7B8796',
        textStyle: {
          color: '#7B8796',
        },
      },
      xAxis: [
        {
          name: xName || '',
          type: 'category',
          axisLabel: {
            color: '#7B8796',
            interval: 0,
            fontSize: 8,
            rotate: 55,
          },
          axisLine: {
            lineStyle: {
              color: '#000000',
            },
          },
          axisTick: {
            show: false,
          },
          data: xAxis,
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: `(${yUnit || ''})`,
          nameTextStyle: {
            color: '#7B8796',
          },
          axisLabel: {
            formatter: '{value}',
            color: '#7B8796',
            fontSize: 12,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#EBEBEB',
              type: 'dashed',
            },
          },
        },
        {
          type: 'value',
          name: `(${yRightUnit || ''})`,
          nameTextStyle: {
            color: '#7B8796',
            fontSize: 12,
          },
          axisLabel: {
            // 显示百分比
            show: true,
            formatter: hideMinimum ? (value) => (value === 0 ? '' : value) : 0,
            color: '#7B8796',
            fontSize: 12,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      series,
    };
    setOptions(option);
  }, [data]);

  return (
    <ReactEcharts
      option={options}
      // notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default BarAndLine;
