/*
 *Description: Cell
 *Date: Tue Jan 04 2022 15:10:40 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { CellChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-cell-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const CellChart = forwardRef((props: CellChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;
    const handledData = data;
    const { color } = curStyle;
    let i = 0;
    const gapBetweenTwo = 13.5;
    const biggestOneRadius = 95;
    const series: any[] = [];

    const instance = chartRef.current.getEchartsInstance();
    const conHeight = instance.getHeight();
    const conWidth = instance.getWidth();
    const r = Math.min(conHeight, conWidth);
    const spanPercentToHeightAccount = ((r * gapBetweenTwo)) / conHeight;

    while (i < handledData.length) {
      const curRadius = biggestOneRadius - i * gapBetweenTwo;
      const dataItem = handledData[i];
      const index = i;
      const curCenterY = 50 + ((i * spanPercentToHeightAccount) / 2);
      series.push(
        {
          name: 'name',
          type: 'pie',
          center: ['50%', `${curCenterY}%`],
          radius: [0, `${curRadius}%`],
          z: i,
          data: [{
            name: handledData[i].name,
            value: handledData[i].value,
            itemStyle: {
              color: color[i % color.length],
              borderWidth: 2,
              borderColor: '#FFF',
            },
          }],
          label: {
            position: 'center',
            show: true,
            fontSize: 12,
            color: '#fff',
            formatter(_params: any) {
              const desc = dataItem.desc ? `{small|\n${dataItem.desc}}` : '';
              const str = `${dataItem.name}\n{bold|${dataItem.value}}{small|${dataItem.unit}}${desc}`;
              return str;
            },
            rich: {
              small: {
                fontSize: 10,
              },
              bold: {
                fontWeight: 'bold',
              },
            },
          },
          labelLayout() {
            if (index === handledData.length - 1) {
              return {};
            }
            const curPieRadius = ((curRadius / 100) * r) / 2;
            const curPieCenterY = ((conHeight) * curCenterY) / 100;
            const curPieTop = curPieCenterY - curPieRadius;
            // console.log(curPieCenterY);
            const distanceBetweenPies = (r * gapBetweenTwo) / 100;
            return {
              y: curPieTop + 14 + (distanceBetweenPies / 4),
            };
          },
        },
      );

      i += 1;
    }
    // series.reverse();

    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };
    setOption(options);
  }, [hanldeData, fontSize, label, legend, mode, title, data]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default CellChart;

CellChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
  data: [],
};
