/*
* Author: zhangmeng
* Description: '标签类选择'
* Date: 2022/06/22
*/

import React from 'react';
import style from './index.module.less';

function TabSelect(props) {
  const { data, title, selected, setSelected } = props;

  const itemClick = p => {
    const arr = [...selected];
    if (p === '不限' && !arr.includes('不限')) {
      setSelected(['不限']);
      return;
    }
    if (arr.length > 0 && arr.includes('不限')) {
      arr.splice(arr.indexOf('不限'), 1);
    }
    if (!arr.includes(p)) {
      arr.push(p);
    } else {
      const i = arr.indexOf(p);
      arr.splice(i, 1);
    }
    if (arr.length === 0) {
      arr.push('不限');
    }
    setSelected(arr);
  };

  return (
    <div className={style.all}>
      <div className={style.point} />
      <div className={style.title}>{title}</div>
      <div style={{ display: 'flex', whiteSpace: 'nowrap' }} className={style.itemBox}>
        <div
          className={`${style.item} ${selected.includes('不限') ? style.selected : ''}`}
          onClick={() => itemClick('不限')}
        >
          不限
        </div>
        {
          data.map(item => (
            <div
              className={`${style.item} ${selected.includes(item.key) ? style.selected : ''}`}
              key={item.title}
              onClick={() => itemClick(item.key)}
            >
              {item.title}
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default TabSelect;
