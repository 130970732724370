import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import clsx from 'clsx';
import Map from './Map';
import CompanyTable from './CompanyTable';
import ChartList from '../../components/ChartList';
import {
  useGetCompanySubsidiaryStatisticsQuery,
  useGetSubsidiaryChartQuery,
} from '../../../../service/special';
import style from './index.module.less';

const Overview = ({ name, isShowBorder }) => {
  const [area, setArea] = useState('000000');
  const [selectedCity, setSelectedCity] = useState({ '000000': { parent: '' } });
  const [titleKey, setTitleKey] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [mapLoading, setMapLoading] = useState(true);

  const { data: title } = useGetCompanySubsidiaryStatisticsQuery({ label: name, area_key: area }, {
    skip: !name,
  });
  // 返回
  const returnPrevLevel = () => {
    setArea(selectedCity[area].parent);
  };
  const { data = [], isFetching: loadings } = useGetSubsidiaryChartQuery({
    area_key: area,
    label: name,
    title_key: sortKey,
  }, { skip: !name });

  useEffect(() => {
    setArea('000000');
    setSelectedCity({ '000000': { parent: '' } });
  }, [name]);
  return (
    <>
      <div className={style.mapAndTable}>
        <div className={clsx(style.map, { [style.noBorderStyle]: !isShowBorder })}>
          {
            isShowBorder ? (
              <p>
                {name}
                总览
              </p>
            ) : null
          }
          <div>
            <Spin spinning={mapLoading}>
              <Map name={name} area={area} setArea={setArea} setSelectedCity={setSelectedCity} setMapLoading={setMapLoading} />
              <div
                className={style.back}
                onClick={() => returnPrevLevel()}
                style={{ display: area === '000000' ? 'none' : '' }}
              >
                返回
              </div>
            </Spin>
          </div>
        </div>
        <div className={style.table}>
          <p>{title}</p>
          <div style={{ position: 'relative' }}>
            <CompanyTable name={name} area={area} setArea={setArea} titleKey={titleKey} setTitleKey={setTitleKey} sortKey={sortKey} setSortKey={setSortKey} />
          </div>
        </div>
      </div>
      <div className={style.ranking}>
        <ChartList loadings={loadings} barData={data[0]} barCrosswiseData={data[1]} funnelData={data[2]} hollowPieData={data[3]} pieData={data[4]} />
      </div>
    </>
  );
};

export default Overview;
