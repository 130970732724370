import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import TopTable from '../TopTable';
import Subtitle from '../../../../../../components/Subtitle';
import RankingOrder from '../../../../../../components/RankingOrder';
import DataSearchButton from '../../../../../../components/DataSearchButton';
import ModalTable from '../ModalTable';
import {
  useGetCapitalInflowsIntoTop10EnterprisesQuery,
  useGetCapitalOutflowsIntoTop10EnterprisesQuery,
  useGetCapitalInflowsIntoTop10CitiesQuery,
  useGetCapitalOutflowsIntoTop10CitiesQuery,
  useGetCaitalInflowsIntoCityDetailQuery,
  useGetCaitalInflowsIntoEnterpriseDetailQuery,
  useGetCaitalOutflowsFromCityDetailQuery,
  useGetCaitalOutflowsFromEnterpriseDetailQuery,
  useGetCityCapitalInflowsIntoEnterpriseDetailQuery,
  useGetCityCapitalOutflowsFromEnterpriseDetailQuery,
} from '../../../../../../service/knowledgeCompoent';
import styles from '../../../index.module.less';

const tableList = [
  {
    id: 1,
    title: '流入TOP10企业',
    COLUMN_KEYS: ['index', 'company_name', 'amount', 'investor_count', 'investor_province'],
  },
  {
    id: 2,
    title: '流出TOP10企业',
    COLUMN_KEYS: ['index', 'company_name', 'amount', 'investor_count', 'investor_province'],
  },
  {
    id: 3,
    title: '流入TOP10城市',
    COLUMN_KEYS: ['index', 'city_name', 'amount', 'investor_count'],
  },
  {
    id: 4,
    title: '流出TOP10城市',
    COLUMN_KEYS: ['index', 'city_name', 'amount', 'investor_count'],
  },
];

const inCompanyColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
  },
  {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    ellipsis: true,
  },
  {
    title: '流入规模(万元)',
    dataIndex: 'amount',
    key: 'amount',
    width: 166,
    render: (text) => <span>{(text - 0).toLocaleString()}</span>,
  },
];

const outCompanyColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
  },
  {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    ellipsis: true,
  },
  {
    title: '流出规模(万元)',
    dataIndex: 'amount',
    key: 'amount',
    width: 166,
    render: (text) => <span>{(text - 0).toLocaleString()}</span>,
  },
];

const inCityColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
  },
  {
    title: '城市名称',
    dataIndex: 'city_name',
    key: 'city_name',
    ellipsis: true,
  },
  {
    title: '流入规模(万元)',
    dataIndex: 'amount',
    key: 'amount',
    width: 226,
    render: (text) => <span>{(text - 0).toLocaleString()}</span>,
  },
];

const outCityColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
  },
  {
    title: '城市名称',
    dataIndex: 'city_name',
    key: 'city_name',
    ellipsis: true,
  },
  {
    title: '流出规模(万元)',
    dataIndex: 'amount',
    key: 'amount',
    width: 226,
    render: (text) => <span>{(text - 0).toLocaleString()}</span>,
  },
];

export default function Tables(props) {
  const {
    searchParams,
  } = props;
  const [selectCompany, setSelectCompany] = useState('');
  const [selectCity, setSelectCity] = useState('');
  const [inCompanyState, setInCompanyState] = useState({
    visible: false,
    companyName: '',
    inCompany: '',
    inCity: '',
    inScale: '',
    type: '',
    pageNumber: 1,
    dataSource: [],
  });
  const [outCompanyState, setOutCompanyState] = useState({
    visible: false,
    companyName: '',
    outCompany: '',
    outCity: '',
    outScale: '',
    type: '',
    pageNumber: 1,
    dataSource: [],
  });
  const [capitalInCompanyState, setCapitalInCompanyState] = useState({
    visible: false,
    cityName: '',
    inCompany: '',
    inScale: '',
    pageNumber: 1,
    dataSource: [],
  });
  const [capitalOutCompanyState, setCapitalOutCompanyState] = useState({
    visible: false,
    cityName: '',
    outCompany: '',
    outScale: '',
    pageNumber: 1,
    dataSource: [],
  });

  const { data: intoTableData, isFetching: isIntoTableFetching } = useGetCapitalInflowsIntoTop10EnterprisesQuery({
    area_key: searchParams.area_key,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  });

  const { data: outflowTableData, isFetching: isOutflowTableFetching } = useGetCapitalOutflowsIntoTop10EnterprisesQuery({
    area_key: searchParams.area_key,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  });

  const { data: intoCityTableData, isFetching: isIntoCityTableFetching } = useGetCapitalInflowsIntoTop10CitiesQuery({
    area_key: searchParams.area_key,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  });

  const { data: outflowCityTableData, isFetching: isOutflowCityTableFetching } = useGetCapitalOutflowsIntoTop10CitiesQuery({
    area_key: searchParams.area_key,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  });

  const { data: intoEnterpriseDetails, isFetching: isIntoEnterpriseFetching } = useGetCaitalInflowsIntoEnterpriseDetailQuery({
    company_id: selectCompany,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  }, {
    skip: !(selectCompany && inCompanyState.visible),
  });

  const { data: outflowsEnterpriseDetails, isFetching: isOutflowsEnterpriseFetching } = useGetCaitalOutflowsFromEnterpriseDetailQuery({
    company_id: selectCompany,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  }, {
    skip: !(selectCompany && outCompanyState.visible),
  });

  const { data: outflowsCityDetailsTableData, isFetching: isOutflowsCityFetching } = useGetCaitalOutflowsFromCityDetailQuery({
    company_id: selectCompany,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  }, {
    skip: !(selectCompany && outCompanyState.visible),
  });

  const { data: intoCityDetailsTableData, isFetching: isIntoCityFetching } = useGetCaitalInflowsIntoCityDetailQuery({
    company_id: selectCompany,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  }, {
    skip: !(selectCompany && inCompanyState.visible),
  });

  const { data: cityIntoEnterpriseDetails, isFetching: isCityIntoEnterpriseFetching } = useGetCityCapitalInflowsIntoEnterpriseDetailQuery({
    city_name: selectCity,
    area_key: searchParams.area_key,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  }, {
    skip: !(selectCity && capitalInCompanyState.visible),
  });

  const { data: cityOutflowsEnterpriseDetails, isFetching: isCityOutflowsEnterpriseFetching } = useGetCityCapitalOutflowsFromEnterpriseDetailQuery({
    city_name: selectCity,
    area_key: searchParams.area_key,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  }, {
    skip: !(selectCity && capitalOutCompanyState.visible),
  });

  useEffect(() => {
    tableList[0].isFetching = isIntoTableFetching;
    tableList[1].isFetching = isOutflowTableFetching;
    tableList[2].isFetching = isIntoCityTableFetching;
    tableList[3].isFetching = isOutflowCityTableFetching;
    if (intoTableData) {
      tableList[0].tableHead = intoTableData.columns;
      tableList[0].dataSource = intoTableData.dataSource;
    }
    if (outflowTableData) {
      tableList[1].tableHead = outflowTableData.columns;
      tableList[1].dataSource = outflowTableData.dataSource;
    }
    if (intoCityTableData) {
      tableList[2].tableHead = intoCityTableData.columns;
      tableList[2].dataSource = intoCityTableData.dataSource;
    }
    if (outflowCityTableData) {
      tableList[3].tableHead = outflowCityTableData.columns;
      tableList[3].dataSource = outflowCityTableData.dataSource;
    }
  }, [
    intoTableData,
    outflowTableData,
    intoCityTableData,
    outflowCityTableData,
    isIntoTableFetching,
    isOutflowTableFetching,
    isIntoCityTableFetching,
    isOutflowCityTableFetching,
  ]);

  return (
    <div className={styles.tables}>
      {
        tableList && tableList.map(item => (
          <div className={styles.tableItem}>
            <TopTable
              key={item.id}
              title={item.title}
              COLUMN_KEYS={item.COLUMN_KEYS}
              tableHead={item.tableHead}
              dataSource={item.dataSource}
              isFetching={item.isFetching === undefined ? true : item.isFetching}
              onClick={({ record, column, title }) => {
                if (title === '流入TOP10企业') {
                  const {
                    company_name: companyName,
                    investor_count: inCompany,
                    investor_province: inCity,
                    amount: inScale,
                    company_id: companyId,
                  } = record;
                  setSelectCompany(companyId);
                  setInCompanyState({
                    visible: true,
                    companyName,
                    inCompany,
                    inCity,
                    inScale,
                    type: column,
                  });
                } else if (title === '流出TOP10企业') {
                  const {
                    company_name: companyName,
                    investor_count: outCompany,
                    investor_province: outCity,
                    amount: outScale,
                    company_id: companyId,
                  } = record;
                  setSelectCompany(companyId);
                  setOutCompanyState({
                    visible: true,
                    companyName,
                    outCompany,
                    outCity,
                    outScale,
                    type: column,
                  });
                } else if (title === '流入TOP10城市') {
                  const {
                    city_name: cityName,
                    investor_count: inCompany,
                    amount: inScale,
                  } = record;
                  setSelectCity(cityName);
                  setCapitalInCompanyState({
                    visible: true,
                    cityName,
                    inCompany,
                    inScale,
                    type: column,
                  });
                } else {
                  const {
                    city_name: cityName,
                    investor_count: outCompany,
                    amount: outScale,
                  } = record;
                  setSelectCity(cityName);
                  setCapitalOutCompanyState({
                    visible: true,
                    cityName,
                    outCompany,
                    outScale,
                    type: column,
                  });
                }
              }}
            />
          </div>
        ))
      }
      <Modal
        footer={null}
        open={inCompanyState.visible}
        wrapClassName={styles.modalTable}
        onCancel={() => {
          setSelectCompany('');
          setInCompanyState(prev => ({
            ...prev,
            pageNumber: 1,
            visible: false,
          }));
        }}
        destroyOnClose={true}
      >
        <ModalTable
          state={inCompanyState}
          title='流入TOP10企业'
          columns={inCompanyState?.type?.title === '流入企业（家）' ? inCompanyColumns : inCityColumns}
          setPageState={setInCompanyState}
          toEnterprise={intoEnterpriseDetails?.dataSource}
          toCity={intoCityDetailsTableData?.dataSource}
          isFetching={inCompanyState?.type?.title === '流入企业（家）' ? isIntoEnterpriseFetching : isIntoCityFetching}
        />
      </Modal>
      <Modal
        footer={null}
        open={outCompanyState.visible}
        wrapClassName={styles.modalTable}
        onCancel={() => {
          setSelectCompany('');
          setOutCompanyState(prev => ({
            ...prev,
            pageNumber: 1,
            visible: false,
          }));
        }}
        destroyOnClose={true}
      >
        <ModalTable
          state={outCompanyState}
          title='流出TOP10企业'
          columns={outCompanyState?.type?.title === '流出企业（家）' ? outCompanyColumns : outCityColumns}
          toEnterprise={outflowsEnterpriseDetails?.dataSource}
          toCity={outflowsCityDetailsTableData?.dataSource}
          isFetching={outCompanyState?.type?.title === '流出企业（家）' ? isOutflowsEnterpriseFetching : isOutflowsCityFetching}
        />
      </Modal>
      <Modal
        footer={null}
        open={capitalInCompanyState.visible}
        wrapClassName={styles.modalTable}
        onCancel={() => {
          setSelectCity('');
          setCapitalInCompanyState(prev => ({
            ...prev,
            pageNumber: 1,
            visible: false,
          }));
        }}
        destroyOnClose={true}
      >
        <ModalTable
          state={capitalInCompanyState}
          title='流入TOP10城市'
          columns={inCompanyColumns}
          toEnterprise={cityIntoEnterpriseDetails?.dataSource}
          isFetching={isCityIntoEnterpriseFetching}
        />
      </Modal>
      <Modal
        footer={null}
        open={capitalOutCompanyState.visible}
        wrapClassName={styles.modalTable}
        onCancel={() => {
          setSelectCity('');
          setCapitalOutCompanyState(prev => ({
            ...prev,
            pageNumber: 1,
            visible: false,
          }));
        }}
        destroyOnClose={true}
      >
        <ModalTable
          state={capitalOutCompanyState}
          title='流出TOP10城市'
          columns={outCompanyColumns}
          toEnterprise={cityOutflowsEnterpriseDetails?.dataSource}
          isFetching={isCityOutflowsEnterpriseFetching}
        />
      </Modal>
    </div>
  );
}
