import { INVESTMENTEVENTDETAIL } from '../utils/url';
import { get } from '../utils/request';

// 投资事件-基本信息
const investmentEventBaseInfo = param => get(`${INVESTMENTEVENTDETAIL}/investment_event_base_info`, {
  ...param,
});

// 投资事件-投资机构
const institutionalInvestorInfo = param => get(`${INVESTMENTEVENTDETAIL}/institutional_investor_info`, {
  ...param,
});

// 投资事件-融资历史
const historyFinancing = param => get(`${INVESTMENTEVENTDETAIL}/history_financing`, {
  ...param,
});

// 投资事件-行业推出趋势
const exitTheTrend = param => get(`${INVESTMENTEVENTDETAIL}/exit_the_trend`, {
  ...param,
});

// 投资事件-行业私募趋势
const industryPrivateTrend = param => get(`${INVESTMENTEVENTDETAIL}/industry_private_placement_trend`, {
  ...param,
});

export {
  investmentEventBaseInfo,
  institutionalInvestorInfo,
  historyFinancing,
  exitTheTrend,
  industryPrivateTrend,
};
