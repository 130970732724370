import React from 'react';
import classNames from 'classnames';
import style from './index.module.less';

const Catalogue = ({ list, active, handelClick }) => (
  <div className={style.catalogue}>
    <div className={style.title}>认定条件</div>
    {
      list.map(item => (
        <div
          key={item.value}
          className={classNames(style.cell, { [style.active]: active === item.value })}
          onClick={() => {
            handelClick(item.value);
          }}
        >
          {item.title}
        </div>
      ))
    }
  </div>
);

export default Catalogue;
