import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';
import G6 from '@antv/g6';
import { useLazyGetCompanyCredentialTagQuery } from '../../../../../../../service/companyDetail';
import style from './index.module.less';

const Tree = ({ companyId }) => {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(null);
  const [getCompanyCredentialTagQuery] = useLazyGetCompanyCredentialTagQuery();
  useEffect(() => {
    getCompanyCredentialTagQuery({ company_id: companyId }).then(res => {
      setTitle(res.data.pool);
      setLoading(false);
      const tData = cloneDeep(res.data.series[0].data[0]);
      if (tData) {
        const container = document.getElementById('mindMapTree');
        const width = container.scrollWidth;
        const height = container.scrollHeight || 500;
        G6.registerEdge('line-arrow', {
          getPath: function getPath(points) {
            const startPoint = points[0];
            const endPoint = points[1];
            // eslint-disable-next-line no-mixed-operators
            return [['M', startPoint.x, startPoint.y], ['L', endPoint.x / 3 + 2 / 3 * startPoint.x, startPoint.y], ['L', endPoint.x / 3 + 2 / 3 * startPoint.x, endPoint.y], ['L', endPoint.x, endPoint.y]];
          },
        }, 'line');
        const tooltip = new G6.Tooltip({
          offsetX: 20,
          offsetY: 30,
          itemTypes: ['node'],
          getContent: (e) => {
            const obj = e.item.getModel();
            let name = '';
            if (obj.level && obj.year) {
              const year = obj.year.map(item => `【${item}】`);
              name = `【${obj.level}】${year.join('')}${obj.name}`;
            } else if (obj.year) {
              const year = obj.year.map(item => `【${item}】`);
              name = `${year.join('')}${obj.name}`;
            } else {
              name = obj.name;
            }
            const outDiv = document.createElement('div');
            // outDiv.style.width = 'fit-content';
            outDiv.innerHTML = `
            <h4 style="">${name}</h4>
           `;
            return outDiv;
          },
        });
        const graph = new G6.TreeGraph({
          container: 'mindMapTree',
          width,
          height,
          plugins: [tooltip],
          // 设置最大缩放比例为2
          maxZoom: 2,
          modes: {
            default: [
              {
                type: 'collapse-expand',
                onChange: function onChange(item, collapsed) {
                  const data = item.get('model');
                  data.collapsed = collapsed;
                  return true;
                },
              },
              'drag-canvas',
              'zoom-canvas',
            ],
          },
          defaultEdge: {
            type: 'line-arrow',
          },
          layout: {
            type: 'mindmap',
            direction: 'H',
            getHeight: () => 16,
            getWidth: () => 16,
            getVGap: () => 10,
            getHGap: () => 120,
            getSide: (d) => {
              if (d.data.name === '入榜情况') {
                return 'left';
              }
              return 'right';
            },
          },
        });
        graph.setMinZoom(1);
        graph.zoomTo(2);
        graph.node((node) => {
          const year = node.year?.map(item => `【${item}】`);
          const levle = node.depth;

          if (levle === 0) {
            let name = '';
            if (name.length > 15) {
              name = `${node.name.slice(0, 16)}...`;
            } else {
              name = node.name;
            }
            return {
              label: name,
              type: 'rect',
              style: {
                fill: '#1961F5',
                width: name.length * 18 + 20,
                height: 32,
                radius: 16,
                // ... 其他属性
              },
              labelCfg: {
                position: 'center',
                style: {
                  fill: '#fff',
                  fontSize: 18,
                  fontWeight: 'bold',
                },
                offset: 5,
              },
            };
          }
          if (levle === 1) {
            return {
              label: node.name,
              type: 'rect',
              style: {
                fill: '#4582FF',
                width: 100,
                height: 20,
                radius: 10,
                // ... 其他属性
              },
              labelCfg: {
                position: 'center',
                style: {
                  fill: '#fff',
                  fontSize: 18,
                  fontWeight: 'bold',
                },
                offset: 5,
              },
            };
          }
          if (levle === 2 && node.x > -108) {
            let name = `【${node.level}】${year.join()}${node.name}`;
            if (name.length > 50) {
              name = `${name.slice(0, 50)}...`;
            }
            return {
              label: name,
              style: {
                width: name.length * 18 + 20,
                opacity: 0,
                fontSize: 28,
              },
              labelCfg: {
                position: 'right',
                offset: -15,
                style: {
                  fontSize: 16,
                },
              },
            };
          }
          if (node.x < -108) {
            let name = year.join() + node.name;
            if (name.length > 50) {
              name = `${name.slice(0, 50)}...`;
            }
            return {
              label: name,
              style: {
                width: name.length * 18 + 20,
                opacity: 0,
              },
              labelCfg: {
                position: 'left',
                offset: -15,
                style: {
                  fontSize: 16,
                },
              },
            };
          }
          return {
            label: node.name,
            style: {
              opacity: 0,
            },
            labelCfg: {
              position: 'right',
              offset: -15,
            },
          };
        });
        graph.data(tData);
        graph.render();
        graph.fitView();

        if (typeof window !== 'undefined') {
          window.onresize = () => {
            if (!graph || graph.get('destroyed')) return;
            if (!container || !container.scrollWidth || !container.scrollHeight) return;
            graph.changeSize(container.scrollWidth, container.scrollHeight);
          };
        }
      }
    }).catch((err) => {
      throw new Error(err);
    });
  }, []);
  return (
    <div className={style.box}>
      <div className={style.title}>
        {
          title && title.desc.split(' ').map((item, index) => {
            if (index % 2 === 0) {
              return <span key={index}>{item}</span>;
            }
            return item;
          })
        }
      </div>
      <Spin spinning={loading} wrapperClassName={style.box}>
        <div id="mindMapTree" style={{ width: '100%', height: '100%', userSelect: 'none' }} />
      </Spin>
    </div>
  );
};

export default Tree;
