import { ComponentType, forwardRef, ForwardedRef } from 'react';
import clsx from 'clsx';

interface WithClassnameProps {
  [key: string]: any;
}

export default function withClassname<T extends WithClassnameProps, R>(
  WrappedComponent: ComponentType<T>, classname?: string,
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  const Component = forwardRef(function(props: T, ref: ForwardedRef<R>) {
    return <WrappedComponent {...props} className={clsx(classname, props.className)} ref={ref} />;
  });

  Component.displayName = `withClassname(${displayName})`;

  return Component;
}
