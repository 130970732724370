/*
* Author: zhangmeng
* Description: '金额选择器'
* Date: 2022/06/22
*/

import React, { useRef, useCallback, useEffect } from 'react';
import { Input } from 'antd';
import style from './index.module.less';

function MoneySelect(props) {
  const { title, select, setSelect } = props;

  const changeInputWord = useCallback((val, old, index) => {
    const newVal = parseInt(val);
    const copy = JSON.parse(JSON.stringify(old));
    if (newVal >= 0) {
      copy[index] = newVal;
    } else {
      copy[index] = '';
    }
    setSelect(copy);
  }, []);

  return (
    <div className={style.all}>
      <div className={style.point} />
      <div className={style.label}>
        {title}
      </div>
      <div
        className={select[0] === '' && select[1] === '' ? style.selectAny : style.normalAny}
        onClick={() => setSelect(['', ''])}
      >
        不限
      </div>
      <div className={style.inputs}>
        <Input value={select[0]} onChange={(e) => changeInputWord(e.target.value, select, 0)} style={{ width: '140px' }} placeholder="最低金额" />
        至
        <Input value={select[1]} onChange={(e) => changeInputWord(e.target.value, select, 1)} style={{ width: '140px' }} placeholder="最高金额" />
        万人民币
      </div>
    </div>
  );
}

export default MoneySelect;
