import React, { useMemo, useEffect } from 'react';
import Table from '../../../../../../../components/Table';
import styles from './index.module.less';
import {
  useGetIndustryTreeQuery,
  useGetCompanySpecialTagsQuery,
} from '../../../../../../../service/interactive-v2';
import { useGetRegionalDistributionSubtableQuery } from '../../../../../../../service/cityDetail';
import CustomTreeFilter from '../../../../../../../components/MultipleChoiceTree';
import CustomListFilter from '../../../../../../../components/CustomListFilter';
import { FilterIcon } from '../../../../../../../components/CustomTableIcon';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  company_name: {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    ellipsis: true,
    className: styles.name,
  },
  special_tag: {
    title: '资质类别',
    dataIndex: 'special_tag',
    key: 'special_tag',
    width: 120,
    ellipsis: true,
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  industry: {
    title: '产业',
    dataIndex: 'industry',
    key: 'industry',
    width: 150,
    ellipsis: true,
    filters: ['all'],
    filterIcon: <FilterIcon />,
    filterMode: 'tree',
  },
};

const COLUMN_KEYS = ['index', 'company_name', 'special_tag', 'industry'];

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

function SectorCompanyTable(props) {
  const {
    regionCode = '000000',
    pageNumber = 1,
    pageSize = 20,
    companyTypes,
    onCompanyClick,
    filteredInfo = {},
    getCount,
  } = props;

  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetRegionalDistributionSubtableQuery({
    area_key: regionCode,
    company_type: getSingleOption(companyTypes),
    special_tag: filteredInfo.special_tag?.filter(tag => tag !== 'all') ?? [],
    industry_code_list: filteredInfo.industry?.filter(tag => tag !== 'all') ?? [],
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !regionCode,
  });
  const { columns = [], dataSource, total = 0, count } = companyData ?? {};

  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );
  const { data: companyTags = [] } = useGetCompanySpecialTagsQuery();

  const newColumns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      // 处理企业名称
      if (item.dataIndex === 'company_name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      if (item.dataIndex === 'special_tag') {
        rowConfig.filteredValue = filteredInfo.special_tag?.length > 0 ? filteredInfo.special_tag : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = companyTags;
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (item.dataIndex === 'industry') {
        rowConfig.filters = industryTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} multiple />;
        rowConfig.filteredValue = filteredInfo?.industry ?? ['all'];
      }
      return rowConfig;
    });
  }, [onCompanyClick, columns, industryTree, companyTags, filteredInfo, regionCode]);

  useEffect(() => {
    getCount(count);
  }, [getCount, count]);

  return (
    <Table
      columns={newColumns}
      dataSource={dataSource}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
    />
  );
}

export default SectorCompanyTable;
