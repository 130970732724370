/**
* Author: tianye
* Description: 报告生成辅助函数,
* Date: 2020/12/3
**/
import React from 'react';

// 图表还未加载完成时生成报告返回的图片
export function getDefaultCanvas(width, height, devicePixelRatio = 1) {
  const canvas = document.createElement('canvas');
  canvas.width = width * devicePixelRatio;
  canvas.height = height * devicePixelRatio;
  return canvas.toDataURL('image/png', 1);
};

// base64图片转换为文件流
function base64toFile(dataurl, filename) {
  if (!dataurl) {
    dataurl = getDefaultCanvas(8, 8);
  }
  if (dataurl.nodeName === 'CANVAS') {
    dataurl = getDefaultCanvas(8, 8);
  }
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const suffix = mime.split('/')[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-cond-assign
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, { type: mime });
}

const drawWaterMark = (ctx, width, height, devicePixelRatio = 1, source = '上奇知识计算引擎') => {
  const sourceStr = `数据来源 ${source}`;
  const fontSize = 12 * devicePixelRatio;
  const letterSpacing = 1.5 * devicePixelRatio;
  const lineHeight = 28 * devicePixelRatio;
  ctx.save();
  ctx.lineWidth = 1;
  ctx.font = `${fontSize}px siyuan`;
  const msSource = ctx.measureText(sourceStr);
  const maxStrWidth = msSource.width + (sourceStr.length - 1) * letterSpacing;
  const padding = [0, 4 * 2];
  const waterMarkPos = [width - maxStrWidth - padding[1], height - lineHeight * 0.5];
  const rectSize = [maxStrWidth + padding[1] * 2, lineHeight]
  const rectPos = [width - rectSize[0], height - rectSize[1]];
  ctx.save();
  ctx.fillStyle = 'rgba(232,236,239,0.8)';
  ctx.fillRect(rectPos[0], rectPos[1], rectSize[0], rectSize[1]);
  ctx.stroke();
  ctx.fill();
  ctx.fillStyle = 'rgba(77,103,126,0.8)';
  ctx.textBaseline = 'middle';
  const len = sourceStr.length;
  let widthSum = waterMarkPos[0];
  for (let i = 0; i < len; i += 1) {
    ctx.fillText(sourceStr[i], widthSum, waterMarkPos[1]);
    widthSum += (ctx.measureText(sourceStr[i]).width + letterSpacing);
  }
  ctx.stroke();
  ctx.fill();
  ctx.restore();
}

const addWaterMarkToImage = (dataURL, width, height, devicePixelRatio = 2, source = '上奇知识计算引擎') => {
  const _width = width;
  const _height = height * (_width / width);
  const footerHeight = 28 * devicePixelRatio;
  return new Promise(resolve => {
    const image = new Image(_width, _height);
    const _canvas = document.createElement('canvas');
    const ctx = _canvas.getContext('2d');
    const _stretchHeight = _height + footerHeight;
    image.style.width = `${_width}px`;
    image.style.height = `${_height}px`;
    _canvas.width = _width;
    _canvas.height = _stretchHeight;
    _canvas.style.width = `${_width / devicePixelRatio}px`;
    _canvas.style.height = `${_stretchHeight / devicePixelRatio}px`;
    image.onload = () => {
      ctx.clearRect(0, 0, _width, _stretchHeight);
      ctx.save();
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, _width, _stretchHeight);
      ctx.fill();
      ctx.restore();
      ctx.save();
      ctx.drawImage(image, 0, 0, _width, _height);
      drawWaterMark(ctx, _width, _stretchHeight, devicePixelRatio, source);
      resolve(_canvas.toDataURL());
    }
    image.src = dataURL;
  })
};

const addWaterMark = (canvas, devicePixelRatio = 2, source = '上奇知识计算引擎') => {
  if (!canvas) return null;
  // const context = canvas.getContext('2d');
  const _canvas = document.createElement('canvas');
  const { width, height } = canvas;
  const _width = width;
  const scaleFactor = _width / width;
  const _height = height * scaleFactor;
  const footerHeight = 20 * devicePixelRatio;
  const _stretchHeight = _height + footerHeight;
  _canvas.width = _width;
  _canvas.height = _stretchHeight;
  _canvas.style.width = `${_width / devicePixelRatio}px`;
  _canvas.style.height = `${_stretchHeight / devicePixelRatio}px`;
  // const imageData = context.getImageData(0, 0, width, height);
  const ctx = _canvas.getContext('2d');
  ctx.clearRect(0, 0, _width, _stretchHeight);
  ctx.save();
  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, _width, _stretchHeight);
  ctx.fill();
  ctx.restore();
  ctx.save();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.drawImage(canvas, 0, 0);
  ctx.restore();
  drawWaterMark(ctx, _width, _stretchHeight, devicePixelRatio, source);
  return _canvas.toDataURL('image/png', 1 );
}
export {
  addWaterMark,
  addWaterMarkToImage,
  base64toFile,
};
