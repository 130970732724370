/**
 *Author: zhangmeng
 *Description: 公司详情法人关系
 *Date: 2021/06/15
* */

import React, { useState, useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';

// const colorList = ['#3E5C76', '#A4C3B2', '#A9BDD5', '#EAD1C1', '#CBC7DB ', '#A5A58D', '#B5BBC4'];

let nodeMap = {};

const firstStyle = {
  symbolSize: 80,
  itemStyle: {
    normal: {
      color: '#1961F5',
      borderColor: 'rgba(25, 97, 245, 0.3)',
      borderWidth: 10,
      shadowBlur: 10,
      shadowColor: 'rgba(25, 97, 245, 0.5)',
    },
  },
  label: {
    normal: {
      show: true,
      color: '#fff',
      position: 'inside',
      formatter(param) {
        let name = '';
        const companyName = param.data.name.split('');
        while (companyName.length > 5) {
          name += `${companyName.splice(0, 5).join('')}\n`;
        }
        return `${name}${companyName.join('')}`;
      },
    },
  },
};

const labelStyle = {
  symbolSize: 70,
  label: {
    normal: {
      show: true,
      position: 'inside',
      // ellipsis: '...',
      // overflow: 'truncate',
      // lineOverflow: 'truncate',
      color: '#333333',
      formatter(param) {
        // const { hasAppend, nodes } = param.data;
        let name = '';
        const companyName = param.data.name.split('');
        while (companyName.length > 4) {
          name += `${companyName.splice(0, 4).join('')}\n`;
        }
        const str = `${name}${companyName.join('')}`;
        // return Object.keys(nodes).length > 0 ? `${str}{b|${hasAppend ? '-' : '+'}}` : str;
        return str.length > 13 ? `${str.slice(0, 13)}...` : str;
      },
      rich: {
        b: {
          color: '#fff',
          backgroundColor: '#0D3B66',
          width: 10,
          height: 10,
          align: 'center',
        },
      },
    },
  },
};

function GraphForceOriented(props) {
  const { data } = props;
  const [option, setOption] = useState({});
  const myRef = useRef(null);
  // const nodeMap = useRef({});
  const currentGraph = useRef({
    nodes: {},
    links: {},
  });
  const getOption = () => {
    const curNodes = Object.values(currentGraph.current.nodes).map((item) => ({
      name: item.name,
      level: item.level,
      hasAppend: item.hasAppend,
      itemStyle: item.itemStyle,
      label: item.label,
      symbolSize: item.symbolSize,
      nodes: Object.keys(item.nodes).length > 0 ? { has: true } : {},
    }));
    const opt = {
      animation: false,
      tooltip: {},
      series: [{
        type: 'graph',
        layout: 'force',
        data: curNodes,
        links: Object.values(currentGraph.current.links),
        roam: true,
        scaleLimit: {
          min: 0.85,
        },

        label: {
          fontSize: 16,
          color: 'yellow',
        },
        // focusNodeAdjacency: false,
        emphasis: {
          focus: 'adjacency',
        },
        lineStyle: {
          color: '#AAA',
          opacity: 0.6,
          fontSize: 14,
          curveness: 0.3,
        },
        edgeSymbol: ['none', 'arrow'],
        edgeSymbolSize: 12,
        nodeScaleRatio: 0.8,
        force: {
          layoutAnimation: false,
          repulsion: 1000,
          friction: 0.5,
        },
      }],
    };
    setOption(opt);
  };

  // 页面加载时，第一次初始化图
  function init(graph) {
    // 根据定义的常量，产生currentGraph的默认数据
    // 遍历全部nodes和links，产生node映射map
    for (let i = 0; i < graph.nodes.length; i += 1) {
      if (graph.nodes[i].level === 0) {
        currentGraph.current.nodes[graph.nodes[i].name] = graph.nodes[i];
      }
      nodeMap[graph.nodes[i].name] = graph.nodes[i];
      nodeMap[graph.nodes[i].name].links = {};
      nodeMap[graph.nodes[i].name].nodes = {};
      nodeMap[graph.nodes[i].name].hasAppend = false;
    }
    for (let i = 0; i < graph.links.length; i += 1) {
      const link = graph.links[i];
      if (nodeMap[link.source] !== undefined && nodeMap[link.target] !== undefined) {
        nodeMap[link.source].links[link.target] = link;
        nodeMap[link.source].nodes[nodeMap[link.target].name] = nodeMap[link.target];
      }
    }
    const keys = Object.keys(nodeMap);
    keys.forEach((key) => {
      currentGraph.current.nodes[key] = nodeMap[key];
      currentGraph.current.nodes[key].hasAppend = Object.keys(nodeMap[key].nodes).length > 0;
      Object.values(nodeMap[key].links).forEach((item) => {
        currentGraph.current.links[`${item.source}_${item.target}`] = item;
      });
    });
    getOption();
  }

  useEffect(() => {
    nodeMap = {};
    const nodes = [];
    const links = [];
    function dealWithData(newData) {
      newData.forEach((item) => {
        nodes.push(item.level === 0 ? {
          name: item.title ?? '',
          level: item.level,
          ...firstStyle,
        } : {
          name: item.title ?? '',
          level: item.level,
          ...labelStyle,
          itemStyle: {
            normal: {
              color: item.type === 'Human' ? '#f1d196' : '#FDAD61',
              borderColor: item.type === 'Human' ? '#FFECC8' : '#FBE2CB',
              borderWidth: 1,
              shadowBlur: 2,
              shadowColor: item.type === 'Human' ? '#CCCCBA' : 'rgba(0, 0, 0, 0.22)',
            },
          },
        });
        if (item.children.length) {
          item.children.forEach((item2) => {
            links.push({
              source: item.title ?? '',
              target: item2.title ?? '',
              symbol: [`${item2.reverse === 1 ? 'arrow' : 'none'}`, `${item2.reverse === 0 ? 'arrow' : 'none'}`],
              label: {
                show: true,
                formatter: item2.relation,
              },
            });
          });
          dealWithData(item.children);
        }
      });
    }
    dealWithData(data);
    init({
      nodes,
      links,
    });
  }, [data]);

  const modifyOption = () => {
    const instance = myRef.current.getEchartsInstance();
    const curNodes = Object.values(currentGraph.current.nodes).map((item) => ({
      name: item.name,
      level: item.level,
      hasAppend: item.hasAppend,
      itemStyle: item.itemStyle,
      label: item.label,
      symbolSize: item.symbolSize,
      nodes: Object.keys(item.nodes).length > 0 ? { has: true } : {},
    }));
    option.series[0].data = curNodes;
    option.series[0].links = Object.values(currentGraph.current.links);
    instance.setOption(option);
  };

  // 处理点击节点展开
  function append(nodeName) {
    // 根据nodeName从nodeMap里拿出对应的nodes和links，并append到currentGraph.nodes currentGraph.links
    const node = nodeMap[nodeName];
    if (node.hasAppend === true
      || Object.keys(node.nodes).length === 0
      || Object.keys(node.links).length === 0) {
      return;
    }
    Object.values(node.nodes).forEach((n) => {
      currentGraph.current.nodes[n.name] = n;
    });
    Object.values(node.links).forEach((l) => {
      currentGraph.current.links[`${l.source}_${l.target}`] = l;
    });
    node.hasAppend = true;
    modifyOption();
  }

  // 处理点击节点收缩
  function remove(nodeName, level) {
    // 根据nodeName从nodeMap里拿出对应的nodes和links，从
    // currentGraph.nodes currentGraph.links删除当前节点的nodes和links并且递归
    const node = nodeMap[nodeName];
    if (level === 0) {
      currentGraph.current.nodes = {};
      const keys = Object.keys(nodeMap);
      keys.forEach(key => {
        if (nodeMap[key].level === 0) {
          currentGraph.current.nodes[nodeMap[key].name] = nodeMap[key];
        }
        nodeMap[key].hasAppend = false;
      });
      currentGraph.current.links = {};
    } else {
      Object.values(node.nodes).forEach((n) => {
        delete currentGraph.current.nodes[n.name];
        if (n.hasAppend === true && Object.keys(n.nodes).length > 0) {
          remove(n.name);
        }
      });
      Object.values(node.links).forEach((l) => {
        delete currentGraph.current.links[`${l.source}_${l.target}`];
      });
    }
    // 设置flag 等于false
    node.hasAppend = false;
    modifyOption();
  }

  const onEvents = {
    click: (params) => {
      const { level } = params.data;
      if (params.dataType === 'node') {
        const node = nodeMap[params.data.name];
        if (node.hasAppend === true) {
          remove(node.name, level);
        } else {
          append(node.name);
        }
      }
    },
  };

  return <ReactEcharts
    option={option}
    ref={myRef}
    style={{ width: '100%', height: '100%' }}
    onEvents={onEvents}
  />;
}

export default GraphForceOriented;
