import React, { useRef, useEffect, useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { useGetPatentStatisticsInventorDistributionQuery } from '../../../../../../service/companyDetail';

const InventorDistribution = ({ companyId, grade }) => {
  const { data, isFetching } = useGetPatentStatisticsInventorDistributionQuery({
    company_id: companyId,
    penetration_grade: grade,
  });
  // eslint-disable-next-line consistent-return
  const optionCig = useMemo(() => {
    if (!data) return {};
    const { x: hours, y: days, data: seriesData } = data.series[0];
    const option = {
      tooltip: {
        position: 'top',
        formatter: (params) => {
          const tipDom = `
          <div style="color:#033068"> 
            <div style="color:#033068; font-weigh:700; line-height: 17px ">${hours[params.data[0]]}</div> 
            <div style="display: flex;  align-items: center;">
             <span style="width:8px; height: 8px; border-radius: 4px; background-color: #4E88FF"></span> 
              &nbsp; 
              ${days[params.data[1]]}
              </div>  
            <div>
              &nbsp;&nbsp
              <span>专利数量</span>
              &nbsp;
              <span style="color:#033068; font-weigh:700; line-height: 17px ">${params.data[2]}</span>
            </div> 
          </div>
          `;
          return tipDom;
        },
      },
      grid: {
        height: '90%',
        top: '10%',
      },
      emphasis: {
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
        },
      },
      xAxis: {
        type: 'category',
        data: hours,
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: 'category',
        data: days,
        splitArea: {
          show: true,
        },
      },
      visualMap: {
        show: false,
        min: 0,
        max: 10,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '15%',
      },
      series: [
        {
          name: 'Punch Card',
          type: 'heatmap',
          data: seriesData,
          label: {
            show: true,
          },
          itemStyle: {
            borderWidth: 1,
            borderColor: '#fff',
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
    return option;
  }, [data]);

  return (
    <div style={{ width: '662px', height: '344px' }}>
      <ECharts
        style={{ width: '100%', height: '100%' }}
        option={optionCig}
      />
    </div>
  );
};

export default InventorDistribution;
