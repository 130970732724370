import { IMapVisualConfig } from '../interface/config';
import { IDataItemObject } from '../interface/data';
import { ISeriesGetter, SeriesTypes } from '../interface/base';
import { OptionParser } from './optionParser';
import { getDataItem, omitUndefined } from './utils';
import { merge } from 'lodash';

const getEach = (config: any) => {
  const {
    colorBy,
    map,
    roam,
    projection,
    center,
    aspectScale,
    boundingCoords,
    zoom,
    scaleLimit,
    nameMap,
    nameProperty,
    selectedMode,
    label,
    itemStyle,
    emphasis,
    select,
    zlevel,
    z,
    left,
    top,
    right,
    bottom,
    layoutCenter,
    layoutSize,
    geoIndex,
    mapValueCalculation,
    showLegendSymbol,
    seriesLayoutBy,
    datasetIndex,
    dataGroupId,
    labelLayout,
    labelLine,
    markPoint,
    markLine,
    markArea,
    tooltip,
    universalTransition,
  } = config;

  return omitUndefined({
    colorBy,
    map,
    roam,
    projection,
    center,
    aspectScale,
    boundingCoords,
    zoom,
    scaleLimit,
    nameMap,
    nameProperty,
    selectedMode,
    label,
    itemStyle,
    emphasis,
    select,
    zlevel,
    z,
    left,
    top,
    right,
    bottom,
    layoutCenter,
    layoutSize,
    geoIndex,
    mapValueCalculation,
    showLegendSymbol,
    seriesLayoutBy,
    datasetIndex,
    dataGroupId,
    labelLayout,
    labelLine,
    markPoint,
    markLine,markArea,
    tooltip,
    universalTransition,
  });
}

export const getSeriesMap: ISeriesGetter<IMapVisualConfig> = (series, index, extra) => {
  const type: SeriesTypes = 'map';
  const {
    material,
    optionParser,
    getMatchedItemStyle,
  } = extra;
  const { config } = material;
  const { visualConfig } = config;
  const { series: seriesStyles, coordinateSystem, geoIndex = 0 } = visualConfig;
  const seriesStyle = seriesStyles ? seriesStyles[index]: undefined;
  const { name, data }  = series;
  const seriesProperty = {
    seriesName: name,
    seriesIndex: index,
  };
  const seriesItem = {
    name,
    type,
    coordinateSystem,
    geoIndex,
  };
  const categoryAxis = Object.values(optionParser.getAxis(seriesItem as any)).flat().filter(OptionParser.isAxisCategory);
  const seriesData = data.map((d, index) => {
    const item = getDataItem(d);
    const _d = {
      ...seriesProperty,
      type,
      category: categoryAxis.length !== 0 ? categoryAxis[0].data[index] as string : undefined,
      ...(item as IDataItemObject),
      index,
    };
    const matcherStyle = getMatchedItemStyle(_d);

    if (Object.keys(matcherStyle).length === 0) {
      return d;
    }
    return ({
      ...item,
      ...getEach(matcherStyle),
    });
  });

  return omitUndefined(
    merge(
      seriesItem,
      getEach(visualConfig),
      { data: seriesData },
      seriesStyle ? getEach(seriesStyle) : {},
    )
  );
}
