import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

/**
 * 按钮
 * @param text
 * @param onClick
 * @param {'primary' | 'normal'} type
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
function Button({
  text,
  onClick,
  type,
  className,
}) {
  return (
    <button
      type="button"
      className={classnames(
        styles.button,
        {
          [styles.primary]: type === 'primary',
        },
        className,
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default Button;
