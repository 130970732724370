/*
* Author: zhangmeng
* Description: 'touziren'
* Date: 2021/07/29
*/

import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import style from './index.module.less';

function PopOverComp(props) {
  const { data } = props;
  const [text, setText] = useState([]);
  const [len, setLen] = useState(null);

  useEffect(() => {
    const l = data.length;
    setLen(l);
    let arr = [];
    if (l > 5) {
      arr = data.slice(0, 5);
    } else {
      arr = [...data];
    }
    setText(arr);
  }, [data]);

  return (
    <div className={style.all}>
      <div className="financingHistory">
        <div className="ctn">
          {
            text.map((item1, index1) => (
              <div key={index1} className="item">{item1.short}</div>
            ))
          }
        </div>
        <Popover
          placement="bottomLeft"
          overlayClassName={style.pop}
          content={
            data.slice(5).map((item1, index1) => (
              <div key={index1} className={style.item}>{item1.short}</div>
            ))
          }
          trigger="click"
        >
          <div className="btn" style={{ display: len > 5 ? 'flex' : 'none' }}>
            <DownCircleOutlined />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default PopOverComp;
