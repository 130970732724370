import React, { useEffect, useState, useRef } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { UpOutlined } from '@ant-design/icons';
// import { useDispatch } from 'react-redux';
import { Layout } from 'antd';
// import { Context } from '../utils/context';
// import { changeSelfPageToOtherProject } from '../utils/urlMethods';
import style from './page.module.less';
import Header from './header';
// import NoPage from '../component/NoPage';
// import KnowledgeEngine from '../pages/KnowledgeEngine';
// import IndustryData from '../pages/IndustryData';
// import HelpCenter from '../pages/HelpCenter';
// import ReportGeneration from '../pages/ReportGeneration';
// import Login from '../pages/Login';
// import AccountSetting from '../pages/AccountSetting';
import IndustryPatentDetail from '../components/IndustryPatentDetail';
import IndustryCompanyDetail from '../components/IndustryCompanyDetail';
import IndustryPolicyDetail from '../components/IndustryPolicyDetail';
import CompanyIntelligentEvaluation from '../pages/Tool/CompanyIntelligentEvaluation';
// import AtlasDetail from '../component/AtlasDetail';
import IndustryCompanyChart from '../components/IndustryCompanyChart';
import ViewAll from '../components/IndustryCompanyDetail/ViewAll';
import InstitutionDetail from '../components/IndustryCompanyDetail/CompanyContent/BaseInfo/CreativePlatformInfo/InstitutionDetailPage';
import StandardDetail from '../components/Standard/StandardDetail';
import ResearchReportDetail from '../components/ResearchReport/ResearchReportDetail';
import InvestmentEventDetail from '../components/InvestmentEventDetail';
import CompanyAnnualReport from '../components/CompanyAnnualReport';
import Special from '../pages/Special';
// import SQListDetail from '../component/SQListDetail';
// import Office from '../pages/Office';
// import DigitalPark from '../component/DigitalPark';
// import IndustryMap from '../component/IndustryMap';
import RegionalPortraitReport from '../components/RegionalPortraitReport';
import HomePage from '../pages/HomePage';
import Industry from '../pages/Industry';
import City from '../pages/City';
import LoginModal from '../pages/LoginModal';
import DataSearch from '../pages/DataSearch';
// import BeginnerGuide from '../component/BeginnerGuide';
// import VipPover from '../pages/VIPPower';
import BoutiqueReportDetail from '../components/BoutiqueReportDetail';
import FreeLogin from '../pages/FreeLogin';
import ComeFromDD from '../pages/ComeFromDD';
import Report from '../pages/Report';
// import companyWeChart from '../assets/images/companyWeChat.png';
// import AtlasLogin from '../pages/AtlasLogin';
import HomePageLogin from '../pages/HomePageLogin';
import MySelf from '../pages/MySelf';
import miniView from '../components/MiniView';
import miniScheme from '../pages/SchemeMini';
import RegionalPortraitReportForMini from '../components/RegionalPortraitReportForMini';
import FirmComparison from '../pages/Tool/FirmComparison';
import Collect from '../pages/Tool/FirmComparison/components/Collect';
import CompanyAppraisement from '../pages/Tool/CompanyAppraisement';
import CompanyTest from '../pages/Tool/CompanyTest';
import CompanyInfo from '../pages/Tool/CompanyTest/CompanyInfo';
import EvaluationRecord from '../pages/Tool/CompanyTest/EvaluationRecord';
import TestResult from '../pages/Tool/CompanyTest/TestResult';
import Appraisement from '../pages/Tool/CompanyAppraisement/Appraisement';
import CollectTable from '../pages/CityDetails/CityTool/IndustryMonitor/CityComparison/CollectTable';
import AppraisementRecord from '../pages/Tool/CompanyAppraisement/AppraisementRecord';
import CityDetails from '../pages/CityDetails';
import CityTool from '../pages/CityDetails/CityTool';
import PermissionDenied from '../components/Special/PermissionDenied';
import SearchCompanyMap from '../pages/Tool/SearchCompanyMap';
import AnchorExpande from '../pages/Tool/AnchorExpande';
import ParkDetail from '../pages/ParkCompany/components/ParkDetail';
import ParkDetailTool from '../pages/ParkCompany/components/ParkDetailTool';
import DataAnalysisTool from '../pages/DataAnalysisTool';
import ReportDownLoad from '../pages/ReportDownLoad';
import AllTool from '../pages/AllTool';
import IndustryChainAdvantageEnterprise from '../pages/Tool/PenetrateGraph/IndustryChainAdvantageEnterprise';
import LargeEnterpriseBusinessLayout from '../pages/Tool/PenetrateGraph/LargeEnterpriseBusinessLayout';
import LargeEnterpriseRegionLayout from '../pages/Tool/PenetrateGraph/LargeEnterpriseRegionLayout';
import RegionCapitalTrend from '../pages/Tool/PenetrateGraph/RegionCapitalTrend';
import ProIntroduce from '../pages/ProIntroduce';

function BasicLayout(props) {
  const [scroll, setScroll] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loginShow, setLoginShow] = useState(false); // 登录框显示隐藏
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [payType, setPayType] = useState('industry');
  const [defaultBuyIndustryKey, setDefaultBuyIndustryKey] = useState(false);
  const [showOut, setOut] = useState(false);
  const timer = useRef(null);
  const box = useRef(null);
  const scrollContent = useRef(null);
  // const dispatch = useDispatch();

  const redirectUrl = window.location.href.split('?')[0];
  const redirectUrlArr = redirectUrl.split('/');
  const _redirectUrl = redirectUrlArr[redirectUrlArr.length - 1];

  useEffect(() => {
    if (localStorage.getItem('rft')
      && !localStorage.getItem('token')
      && props.location.pathname !== '/freeLogin'
      && props.location.pathname !== '/homePageLogin') {
      const maxTime = localStorage.getItem('rftChanyeMax');
      const nowTime = new Date().getTime();
      if (nowTime >= maxTime) {
        localStorage.removeItem('rft');
        localStorage.removeItem('rftChanyeMax');
        props.history.push('/homePage');
      } else {
        // const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo'));
        // dispatch(setUser(sessionInfo?.userName));
        // if (sessionInfo) {
        //   Object.keys(sessionInfo).forEach(item => {
        //     if (typeof sessionInfo[item] === 'object') {
        //       localStorage.setItem(item, JSON.stringify(sessionInfo[item]));
        //     } else {
        //       localStorage.setItem(item, sessionInfo[item]);
        //     }
        //   });
        // } else {
        props.history.push({
          pathname: '/freeLogin',
          state: {
            urlkey: props.location.search,
            urlPath: props.location.pathname,
          },
        });
        // }
      }
    } else if (!localStorage.getItem('token')
      && props.location.pathname !== '/homePage'
      && props.location.pathname !== '/freeLogin'
      && props.location.pathname !== '/homePageLogin') {
      props.history.push('/homePage');
    }
  }, []);

  const scrollEvent = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      setScroll(scrollContent.current?.scrollTop > document.body.clientHeight * 2);
    }, 50);
  };

  useEffect(() => {
    scrollContent.current?.addEventListener('scroll', scrollEvent);
    return () => {
      scrollContent.current?.removeEventListener('scroll', scrollEvent);
    };
  }, [scrollContent]);

  const scrollToTop = () => {
    scrollContent.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Layout className={style.basic} id="myRef">
      <Switch>
        <Route path="/freeLogin" component={FreeLogin} />
        <Route path="/comeFromDD" component={ComeFromDD} />
        <Route path="/homePageLogin" component={HomePageLogin} />
        <Route path="/miniView" component={miniView} />
        <Route path="/miniScheme" component={miniScheme} />
        {
          localStorage.getItem('token') && (
            <Route path="/proIntroduce">
              <div className={style.scrollContent}>
                <Switch>
                  <Route path="/" component={ProIntroduce} />
                </Switch>
              </div>
            </Route>
          )
        }
        {localStorage.getItem('token') && (
          <Route path="/reportPreviewForMini">
            <div className={style.scrollContent} ref={scrollContent}>
              <Switch>
                <Route path="/" component={RegionalPortraitReportForMini} />
              </Switch>
            </div>
          </Route>)}
        {localStorage.getItem('token') && (
          <Route path="/reportPreview">
            <div className={style.scrollContent} ref={scrollContent}>
              <Switch>
                <Route path="/" component={RegionalPortraitReport} />
              </Switch>
            </div>
          </Route>)}
        {localStorage.getItem('token') && (
          <Route path="/boutiqueDetail">
            <div className={style.scrollContent} ref={scrollContent}>
              <div className={style.getAll}>
                <Switch>
                  <Route path="/" component={BoutiqueReportDetail} />
                </Switch>
              </div>
            </div>
          </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/institutionDetail">
              <div className={style.secondary}>
                <Switch>
                  <Route path="/" component={InstitutionDetail} />
                </Switch>
              </div>
            </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/patentDetail">
              <div className={style.scrollContent} ref={scrollContent}>
                <div className={style.secondary}>
                  <Switch>
                    <Route path="/" component={IndustryPatentDetail} />
                  </Switch>
                </div>
              </div>
            </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/policyDetail">
              <div className={style.secondary}>
                <Switch>
                  <Route path="/" component={IndustryPolicyDetail} />
                </Switch>
              </div>
            </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/standardDetail">
              <div className={style.scrollContent} ref={scrollContent}>
                <div className={style.secondary}>
                  <Switch>
                    <Route path="/" component={StandardDetail} />
                  </Switch>
                </div>
              </div>
            </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/researchReportDetail">
              <div className={style.secondary}>
                <Switch>
                  <Route path="/" component={ResearchReportDetail} />
                </Switch>
              </div>
            </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/investmentDetail">
              <div className={style.scrollContent} ref={scrollContent}>
                <div className={style.secondary}>
                  <Switch>
                    <Route path="/" component={InvestmentEventDetail} />
                  </Switch>
                </div>
              </div>
            </Route>)}
        {
          localStorage.getItem('token') && (
            <Route path="/companyDetail">
              <div className={style.scrollContent} ref={scrollContent}>
                <div className={style.secondary}>
                  <Switch>
                    <Route path="/" render={((p) => <IndustryCompanyDetail {...p} scrollContent={scrollContent} />)} />
                  </Switch>
                </div>
              </div>
            </Route>
          )
        }
        {/* {
        localStorage.getItem('token')
        && (
        <Route path="/companyIntelligentEvaluation">
          <CompanyIntelligentEvaluation />
        </Route>)
        } */}
        {localStorage.getItem('token')
        && (
        <Route path="/dataAnalysisTool">
          <div className={style.scrollContent} ref={scrollContent}>
            <div>
              <Switch>
                <Route path="/" render={((p) => <DataAnalysisTool {...p} scrollContent={scrollContent} />)} />
                {/* <Route path="/" render={((p) => <DataAnalysisTool {...p} scrollContent={scrollContent} />)} /> */}
              </Switch>
            </div>
          </div>
        </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/reportDownLoad">
              <div className={style.scrollContent} ref={scrollContent}>
                <div>
                  <Switch>
                    <Route path="/" render={((p) => <ReportDownLoad {...p} scrollContent={scrollContent} />)} />
                  </Switch>
                </div>
              </div>
            </Route>)}
        {localStorage.getItem('token') && (
          <Route path="/companyReport">
            <div className={style.scrollContent} ref={scrollContent}>
              <div className={style.secondary}>
                <Switch>
                  <Route path="/" component={CompanyAnnualReport} />
                </Switch>
              </div>
            </div>
          </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/viewAll">
              <Switch>
                <Route path="/" component={ViewAll} />
              </Switch>
            </Route>)}
        {localStorage.getItem('token')
          && (
            <Route path="/industryCompanyChart">
              <div className={style.secondary}>
                <Switch>
                  <Route path="/" component={IndustryCompanyChart} />
                </Switch>
              </div>
            </Route>)}
        <Route path="/">
          {
            _redirectUrl !== 'industryLogin'
            && _redirectUrl !== 'dataSearchLogin'
            && _redirectUrl !== 'reportLogin'
            && (
              <Header
                setLoginShow={setLoginShow}
                modalShow={modalShow}
                setModalShow={setModalShow}
                setModal={setChannelModalVisible}
                setPayType={setPayType}
                setDefaultBuyIndustryKey={setDefaultBuyIndustryKey}
                showOut={showOut}
                setOut={setOut}
              />
            )
          }
          <div className={style.center}>
            <div className={style.context} ref={box}>
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/homePage" push />} />
                <Route
                  exact
                  path="/homePage"
                  render={() => (
                    <HomePage
                      setLoginShow={setLoginShow}
                      modalShow={modalShow}
                      setModalShow={setModalShow}
                      channelModalVisible={channelModalVisible}
                      setChannelModalVisible={setChannelModalVisible}
                      payType={payType}
                      setPayType={setPayType}
                      defaultBuyIndustryKey={defaultBuyIndustryKey}
                      setDefaultBuyIndustryKey={setDefaultBuyIndustryKey}
                      setOut={setOut}
                    // special,
                    // setSpecial
                    />
                  )}
                />
                {localStorage.getItem('token') && <Route exact path="/industry" component={Industry} />}
                <Route exact path="/industryLogin" component={Industry} />
                {localStorage.getItem('token') && <Route exact path="/toolBox" component={AllTool} />}
                {localStorage.getItem('token') && <Route exact path="/city" component={City} />}
                {localStorage.getItem('token') && <Route exact path="/cityDetails" render={((p) => <CityDetails {...p} box={box} />)} />}
                {localStorage.getItem('token') && <Route exact path="/cityTool" component={CityTool} />}
                {localStorage.getItem('token') && <Route path="/companyIntelligentEvaluation" component={CompanyIntelligentEvaluation} />}
                {localStorage.getItem('token') && <Route exact path="/tool/city/comparison/table" component={CollectTable} />}
                {localStorage.getItem('token') && <Route exact path="/special" component={Special} />}
                {localStorage.getItem('token') && <Route exact path="/dataSearch" component={DataSearch} />}
                <Route exact path="/dataSearchLogin" component={DataSearch} />
                {localStorage.getItem('token') && <Route exact path="/report" component={Report} />}
                <Route exact path="/reportLogin" component={Report} />
                {localStorage.getItem('token') && <Route exact path="/parkDetail" render={((p) => <ParkDetail {...p} box={box} />)} />}
                {localStorage.getItem('token') && <Route exact path="/parkDetailTool" component={ParkDetailTool} />}
                {localStorage.getItem('token') && <Route exact path="/accountSetting" component={MySelf} />}
                {localStorage.getItem('token') && <Route exact path="/tool/firmComparison" component={FirmComparison} />}
                {localStorage.getItem('token') && <Route exact path="/tool/firmComparison/collect" component={Collect} />}
                {localStorage.getItem('token') && <Route exact path="/tool/companyAppraisement" component={CompanyAppraisement} />}
                {localStorage.getItem('token') && <Route exact path="/tool/companyAppraisement/appraisement" component={Appraisement} />}
                {localStorage.getItem('token') && <Route exact path="/tool/companyAppraisement/appraisement/appraisementRecord" component={AppraisementRecord} />}
                {localStorage.getItem('token') && <Route exact path="/tool/searchCompanyMap" component={SearchCompanyMap} />}
                {localStorage.getItem('token') && <Route exact path="/tool/anchorExpande" component={AnchorExpande} />}
                {localStorage.getItem('token') && <Route exact path="/tool/companyTest" component={CompanyTest} />}
                {localStorage.getItem('token') && <Route exact path="/tool/companyTest/companyInfo" component={CompanyInfo} />}
                {localStorage.getItem('token') && <Route exact path="/tool/companyTest/testResult" component={TestResult} />}
                {localStorage.getItem('token') && <Route exact path="/tool/companyTest/evaluationRecord" component={EvaluationRecord} />}
                {localStorage.getItem('token') && <Route exact path="/tool/penetrateGraph/industryChainAdvantageEnterprise" render={((p) => <IndustryChainAdvantageEnterprise {...p} box={box} />)} />}
                {localStorage.getItem('token') && <Route exact path="/tool/penetrateGraph/largeEnterpriseBusinessLayout" render={((p) => <LargeEnterpriseBusinessLayout {...p} box={box} />)} />}
                {localStorage.getItem('token') && <Route exact path="/tool/penetrateGraph/largeEnterpriseRegionLayout" render={((p) => <LargeEnterpriseRegionLayout {...p} box={box} />)} />}
                {localStorage.getItem('token') && <Route exact path="/tool/penetrateGraph/regionCapitalTrend" render={((p) => <RegionCapitalTrend {...p} box={box} />)} />}
              </Switch>
            </div>
          </div>
          {loginShow && (
            <LoginModal
              loginShow={loginShow}
              setLoginShow={setLoginShow}
              onClose={() => setLoginShow(false)}
            />
          )}
        </Route>
      </Switch>
      <div className={style.scrollTop} title="返回顶部" style={{ display: scroll ? '' : 'none' }} onClick={() => scrollToTop()}>
        <UpOutlined />
      </div>
      {
        modalShow && <PermissionDenied close={setModalShow} />
      }
    </Layout>
  );
}

export default withRouter(BasicLayout);
