import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import Input from '../../../Industry/components/Input';
import CompanyTable from '../CompanyTable';
import PatentTable from '../PatentTable';
import InvestmentTable from '../InvestmentTable';
import PolicyTable from '../PolicyTable';
import ResearchUnitTable from '../ResearchUnitTable';
import Underline from '../../../../components/Underline';
import styles from './index.module.less';
import { useQuery } from '../../../../hooks/url';
import { openNewPage } from '../../../../utils/urlMethods';
import { StandardModal } from '../../../Industry/components/Modal';
import ExportModal from '../../../../components/ExportModal';
import CompanyPatentList from '../../../Industry/components/CompanyPatentList';

const DEFAULT_TABLE_STATE = {
  pageSize: 20,
  pageNumber: 1,
  filteredInfo: {},
  sortedInfo: {},
};

const PATENT_KEY = {
  industry_patent: undefined,
  approval_patent_count: undefined,
  possess_patent_sum: '发明',
  utility_patent_num: '实用新型',
  design_patent_num: '外观设计',
};

const PLACEHOLDERS = {
  企业: '搜索企业名称',
  专利: '搜索单位名称',
  投资: '搜索企业名称、投资方',
  政策: '搜索政策',
  研究机构: '搜索研究机构',
};

function DataScheme({ title, type, cityCode }) {
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const query = useQuery();
  const total = query.get('value');
  const [keyword, setKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tableState, setTableState] = useState(DEFAULT_TABLE_STATE);
  const [count, setCount] = useState(0);
  const [companyPatentModalState, setCompanyPatentModalState] = useState({
    pageSize: 20,
    pageNumber: 1,
    type: undefined,
    visible: false,
    title: '',
    value: '-',
    companyId: '',
  });
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [exportKey, setExportKey] = useState('');
  const [dataKey, setDataKey] = useState('');
  const [params, setParams] = useState(null);

  const exportFlag = Array.isArray(enterpriseAccountExport)
  && enterpriseAccountExport?.length > 0
  && enterpriseAccountExport?.includes(exportKey);

  useEffect(() => {
    setKeyword('');
    setSearchKeyword('');
    setTableState(DEFAULT_TABLE_STATE);
    setCompanyPatentModalState(prev => ({ ...prev, visible: false }));
    switch (type) {
      case '企业':
        setExportKey('exportCityDataCompany');
        setDataKey('city_channel_data_company');
        break;
      case '专利':
        setExportKey('exportCityDataPatent');
        setDataKey('city_channel_data_patent');
        break;
      case '投资':
        setExportKey('exportCityDataInvest');
        setDataKey('city_channel_data_investment');
        break;
      case '政策':
        setExportKey('exportCityDataPolicy');
        setDataKey('city_channel_data_policy');
        break;
      case '研究机构':
        setExportKey('exportCityDataOrgan');
        setDataKey('city_channel_data_institution');
        break;
      default:
        setExportKey('');
        break;
    }
  }, [type]);

  const onData = useCallback((data = {}) => {
    const { count: _count } = data;
    setCount(_count);
  }, []);

  return (
    <section className={styles.scheme}>
      <div className={styles.header}>
        <Title title={title} className={styles.title} />
        <Subtitle
          description="共计"
          value={total ?? '-'}
          unit="条"
          className={styles.subtitle}
        />
        {
          count !== Number(total) && <Subtitle
            description={type === '专利' ? '筛选主体' : '筛选结果'}
            value={count ?? '-'}
            unit={type === '专利' ? '家' : '条'}
            className={styles.subtitle}
          />
        }
        <Input
          className={classNames(styles.search, { [styles.right]: !exportFlag })}
          value={keyword}
          placeholder={PLACEHOLDERS[type] ?? '输入企业名称'}
          onChange={e => {
            setKeyword(e.target.value);
            if (e.target.value === '') {
              setSearchKeyword('');
              setTableState({
                ...DEFAULT_TABLE_STATE,
                sortedInfo: tableState?.sortedInfo,
              });
            }
          }}
          onPressEnter={value => {
            setSearchKeyword(value);
            setTableState({
              ...DEFAULT_TABLE_STATE,
              sortedInfo: tableState?.sortedInfo,
            });
          }}
          onSearch={value => {
            setSearchKeyword(value);
            setTableState(DEFAULT_TABLE_STATE);
          }}
        />
        {
          exportFlag && (
            <Button className={styles.exportButton} onClick={() => setExportModalVisible(true)}>数据导出</Button>
          )
        }
      </div>
      <Underline />
      <div className={styles.table}>
        {
          type === '企业' && (
            <CompanyTable
              keyword={searchKeyword}
              cityCode={cityCode}
              pageNumber={tableState.pageNumber}
              pageSize={tableState.pageSize}
              sortedInfo={tableState.sortedInfo}
              filteredInfo={tableState.filteredInfo}
              onCompanyClick={({ _id }) => {
                if (!_id) return;
                openNewPage('companyDetail', { id: _id });
              }}
              onChange={({ current }, filters, sorter) => {
                setTableState(prev => ({
                  ...prev,
                  pageNumber: current,
                  filteredInfo: filters,
                  sortedInfo: sorter,
                }));
              }}
              onClick={({ record, record: { _id }, column, column: { title: _title, dataIndex } }) => {
                setCompanyPatentModalState(prev => ({
                  ...prev,
                  pageNumber: 1,
                  visible: true,
                  companyId: _id,
                  title: _title,
                  value: '-',
                  type: PATENT_KEY[dataIndex],
                }));
              }}
              onData={onData}
              setParams={setParams}
            />
          )
        }
        {
          type === '专利' && (
            <PatentTable
              keyword={searchKeyword}
              cityCode={cityCode}
              pageNumber={tableState.pageNumber}
              pageSize={tableState.pageSize}
              sortedInfo={tableState.sortedInfo}
              filteredInfo={tableState.filteredInfo}
              onCompanyClick={({ _id }) => {
                if (!_id) return;
                openNewPage('companyDetail', { id: _id });
              }}
              onChange={({ current }, filters, sorter) => {
                setTableState(prev => ({
                  ...prev,
                  pageNumber: current,
                  filteredInfo: filters,
                  sortedInfo: sorter,
                }));
              }}
              onClick={({ record, record: { _id }, column, column: { title: _title, dataIndex } }) => {
                // 五种类型的授权发明，undefined、发明、实用新型、外观设计
                setCompanyPatentModalState(prev => ({
                  ...prev,
                  pageNumber: 1,
                  visible: true,
                  companyId: _id,
                  title: _title,
                  value: '-',
                  type: PATENT_KEY[dataIndex],
                }));
              }}
              onData={onData}
              setParams={setParams}
            />
          )
        }
        {
          type === '投资' && (
            <InvestmentTable
              keyword={searchKeyword}
              cityCode={cityCode}
              pageNumber={tableState.pageNumber}
              pageSize={tableState.pageSize}
              sortedInfo={tableState.sortedInfo}
              filteredInfo={tableState.filteredInfo}
              onData={onData}
              onCompanyClick={({ _id }) => {
                if (!_id) return;
                openNewPage('companyDetail', { id: _id });
              }}
              onInvestmentClick={({ invest_id: investId }) => {
                if (!investId) return;
                openNewPage('investmentDetail', { id: investId });
              }}
              onChange={({ current }, filters, sorter) => {
                setTableState(prev => ({
                  ...prev,
                  pageNumber: current,
                  filteredInfo: filters,
                  sortedInfo: sorter,
                }));
              }}
              setParams={setParams}
            />
          )
        }
        {
          type === '政策' && (
            <PolicyTable
              keyword={searchKeyword}
              cityCode={cityCode}
              pageNumber={tableState.pageNumber}
              pageSize={tableState.pageSize}
              sortedInfo={tableState.sortedInfo}
              filteredInfo={tableState.filteredInfo}
              onData={onData}
              onPolicyClick={({ _id }) => {
                if (!_id) return;
                openNewPage('policyDetail', {
                  id: _id,
                });
              }}
              onChange={({ current }, filters, sorter) => {
                setTableState(prev => ({
                  ...prev,
                  pageNumber: current,
                  filteredInfo: filters,
                  sortedInfo: sorter,
                }));
              }}
              setParams={setParams}
            />
          )
        }
        {
          type === '研究机构' && (
            <ResearchUnitTable
              keyword={searchKeyword}
              cityCode={cityCode}
              pageNumber={tableState.pageNumber}
              pageSize={tableState.pageSize}
              sortedInfo={tableState.sortedInfo}
              filteredInfo={tableState.filteredInfo}
              onData={onData}
              onUnitClick={({ _id }) => {
                if (!_id) return;
                openNewPage('institutionDetail', {
                  id: _id,
                });
              }}
              onChange={({ current }, filters, sorter) => {
                setTableState(prev => ({
                  ...prev,
                  pageNumber: current,
                  filteredInfo: filters,
                  sortedInfo: sorter,
                }));
              }}
              setParams={setParams}
            />
          )
        }
        {
          // 企业专利弹窗
          companyPatentModalState.visible && (
            <StandardModal
              header={
                <div className={styles.modalHeader}>
                  <span className={styles.title}>{companyPatentModalState.title}</span>
                  <Subtitle
                    className={styles.subtitle}
                    description="共"
                    value={companyPatentModalState.value ?? '-'}
                    unit="条"
                  />
                </div>
              }
              onClose={() => {
                setCompanyPatentModalState(prev => ({
                  ...prev,
                  visible: false,
                }));
              }}
              className={styles.modal}
            >
              <CompanyPatentList
                patentType={companyPatentModalState.type}
                companyId={companyPatentModalState.companyId}
                pageSize={companyPatentModalState.pageSize}
                pageNumber={companyPatentModalState.pageNumber}
                onChange={({ current }) => {
                  setCompanyPatentModalState(prev => ({
                    ...prev,
                    pageNumber: current,
                  }));
                }}
                onData={(data = {}) => {
                  const { count: _count } = data;
                  setCompanyPatentModalState(prev => ({ ...prev, value: _count }));
                }}
                onPatentClick={({ _id }) => {
                  openNewPage('patentDetail', {
                    id: _id,
                  });
                }}
              />
            </StandardModal>
          )
        }
      </div>
      {
        exportModalVisible && (
          <div className={styles.mask}>
            <ExportModal
              dataKey={dataKey}
              params={params}
              onClose={() => {
                setExportModalVisible(false);
              }}
            />
          </div>
        )
      }
    </section>
  );
}

export default DataScheme;
