/*
*Description: 样式文件
*/
import * as CONSTS from '../../consts';

const chartFontSize = {
  small: {
    legnedFontSize: 10,
    labelFontSize: 10,
  },
  middle: {
    legnedFontSize: 6,
    labelFontSize: 6,
  },
  large: {
    legnedFontSize: 14,
    labelFontSize: 14,
  },
};

const modeStyle = {
  light: {
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    color: ['rgb(13, 59, 102)', 'rgb(48, 96, 140)', 'rgb(16, 129, 167)', 'rgb(27, 143, 152)', 'rgb(66, 164, 172)', 'rgb(74, 178, 157)', 'rgb(103, 161, 129)', 'rgb(115, 178, 143)', 'rgb(148, 197, 170)', 'rgb(181, 216, 192)', 'rgb(194, 216, 181)', 'rgb(219, 226, 183)', 'rgb(230, 228, 172)'],
    labelColor: '#fff',
    labelBgdColor: '#E2EEFF',
    labelBorderColor: '#C5D5EA',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',
    xAxis: {
      axisLabel: {
        color: '#777A7A',
        interval: 0,
        margin: 4,
        rotate: 50,
        fontSize: 8,
      },
      nameTextStyle: {
        color: '#333',
        fontWeight: 400,
        fontSize: 10,
        padding: [0, 0, -8, -6],
      },
    },
    yAxis: {
      axisLabel: {
        interval: 0,
        margin: 4,
        fontSize: 8,
        color: '#262D36',
        formatter: (param: any) => (param.length > 6 ? `${param.slice(0, 6)}...` : param),
      },
      nameTextStyle: {
        color: '#333',
        fontWeight: 400,
        fontSize: 10,
        padding: [0, 0, 4, 0],
      },
    },
  },
  dark: {
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    color: ['rgb(13, 59, 102)', 'rgb(48, 96, 140)', 'rgb(16, 129, 167)', 'rgb(27, 143, 152)', 'rgb(66, 164, 172)', 'rgb(74, 178, 157)', 'rgb(109, 198, 180)', 'rgb(103, 161, 129)', 'rgb(115, 178, 143)', 'rgb(148, 197, 170)', 'rgb(181, 216, 192)', 'rgb(194, 216, 181)', 'rgb(219, 226, 183)', 'rgb(230, 228, 172)'],
    labelColor: '#fff',
    labelBgdColor: 'rgba(27, 2, 253, 0.3)',
    labelBorderColor: 'transparent',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
    xAxis: {
      axisLabel: {
        color: '#777A7A',
        interval: 0,
        rotate: 50,
        fontSize: 8,
      },
      nameTextStyle: {
        color: '#333',
        fontWeight: 400,
        fontSize: 10,
        padding: [0, 0, -8, -6],
      },
    },
    yAxis: {
      axisLabel: {
        fontSize: 8,
        color: '#262D36',
        formatter: (param: any) => (param.length > 6 ? `${param.slice(0, 6)}...` : param),
      },
      nameTextStyle: {
        color: '#333',
        fontWeight: 400,
        fontSize: 10,
        padding: [0, 0, -8, 0],
      },
    },
  },
};

export {
  chartFontSize,
  modeStyle,
};
