import React, { useCallback, useState, useRef } from 'react';
import { message, Select } from 'antd';
import styles from './index.module.less';
import ReportCard from '../../../../components/ReportCard';
import ReportModal from '../../../../components/ReportModal';
import {
  useGetSystemReportCategoryQuery,
  useGetSystemReportListQuery,
} from '../../../../service/find-system-report';
import { useRecordingSpotQuery } from '../../../../service/public';
import { openNewPage } from '../../../../utils/urlMethods';
import { judgeArea } from '../../../../utils/tool';
import {
  getCompnayId,
  resetTemplateReport,
  getRegionName,
} from '../../../../api/reportGeneration';

const { Option } = Select;

const DEFAULT_SCENES = [{ title: '全部', value: 'all' }];

function ReportScheme(props) {
  const { industryCode, industryName } = props;
  const { data: scenes = DEFAULT_SCENES } = useGetSystemReportCategoryQuery();
  const [selectedScene, setSelectedScene] = useState('all');
  const [choseModel, setChoseModel] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [templateType, setTemplateType] = useState('');
  const choseTemplateMsg = useRef(null);
  const [choseLoading, setChoseLoading] = useState(false);
  const {
    data: reports = [],
  } = useGetSystemReportListQuery({
    industry_code: industryCode,
    category_name: selectedScene,
  }, {
    skip: !industryCode,
  });

  useRecordingSpotQuery({
    spot_name: '产业-报告',
  });

  // 打开报告预览页
  const openNewReportPage = useCallback((curTemplateId, data, curType, conditions) => {
    let obj = {
      curTemplateId,
      title: choseTemplateMsg.current.template_name,
      id: data,
    };
    curType.forEach(item => {
      if (item === 'area') {
        obj = {
          ...obj,
          area: conditions.cityName,
          areaKey: conditions.cityValue,
        };
      }
      if (item === 'industry') {
        obj = {
          ...obj,
          industry: conditions.industryName,
          industryKey: conditions.industryValue,
        };
      }
      if (item === 'company') {
        obj = {
          ...obj,
          name: conditions.companyName,
        };
      }
    });
    const msg = window.btoa(window.encodeURIComponent(JSON.stringify(obj)));
    // template_source区分精品(3),新模板(2),旧模板(1)
    const templateSource = choseTemplateMsg.current.template_source;
    if (templateSource === 1) {
      openNewPage('reportPreview', {
        msg,
      });
    } else if (templateSource === 2) {
      openNewPage('scheme', {
        msg,
      });
    }
  }, []);

  // 各种模板-复用模板逻辑
  const analysisReportConditionChange = async (curTemplateId, curType, conditions) => {
    setChoseLoading(true);
    let companymsg = '';
    if (curType.includes('company')) {
      await getCompnayId({
        company_name: conditions.companyName,
      }).then(res => {
        if (res.status === '00000') {
          companymsg = res.data;
        }
        if (res.status === 'c0401') {
          companymsg = 'error';
          message.error(res.message);
          setChoseLoading(false);
        }
      });
    }
    if (curType.includes('area')) {
      getRegionName({
        code: conditions.cityValue?.[0],
        duplicate: true,
        separator: '',
      }).then(res => {
        if (res.status === '00000') {
          conditions.cityName = res.data;
        }
      });
    }
    if (companymsg === 'error') return;
    let params = {
      template_id: curTemplateId,
      template_source: choseTemplateMsg.current.template_source,
    };
    curType.forEach(item => {
      if (item === 'area') {
        if (curTemplateId === '16,23') {
          params = {
            ...params,
            template_id: !conditions.is_province_directly ? judgeArea(conditions.cityValue, params.template_id) : '16',
            area_key_list: conditions.cityValue,
          };
        } else {
          params = {
            ...params,
            template_id: judgeArea(conditions.cityValue, curTemplateId),
            area_key_list: conditions.cityValue,
          };
        }
      }
      if (item === 'industry') {
        params = {
          ...params,
          industry_code_list: conditions.industryValue,
        };
      }
      if (item === 'company') {
        params = {
          ...params,
          company_info_dict: companymsg || {},
        };
      }
      if (item === 'diy_tag') {
        params = {
          ...params,
          diy_tag_list: conditions.diyTagCode || [],
        };
      }
    });
    resetTemplateReport(params).then(res => {
      if (res.status === '00000') {
        setChoseLoading(false);
        openNewReportPage(params.template_id, res.data, curType, conditions);
        setChoseModel(false);
      } else {
        setChoseLoading(false);
        message.error(res.message);
      }
    }).catch(() => {
      setChoseLoading(false);
    });
  };

  const onReportClick = useCallback(report => {
    const { template_source: source } = report;
    if (source === 3) {
      const { id, Buy_state: isApply, template_price: price, cover_url: coverImg,
        template_name: title, sub_template_name: subTitle } = report;
      openNewPage('boutiqueDetail', {
        msg: window.btoa(window.encodeURIComponent(JSON.stringify({
          title,
          subTitle,
          isApply,
          price,
          coverImg,
          id,
        }))),
      });
    } else if (report.outer_macro.length === 1 && report.outer_macro[0] === 'industry') {
      // 要选产业的报告,因为已经知道产业,所以不弹窗
      choseTemplateMsg.current = report;
      analysisReportConditionChange(report.id, report.outer_macro, {
        industryValue: [industryCode],
        industryName,
      });
    } else {
      choseTemplateMsg.current = report;
      setTemplateId(report.id);
      setTemplateType(report.outer_macro);
      setChoseModel(true);
    }
  }, [industryCode, industryName]);

  return (
    <div className={styles.reportScheme}>
      <div className={styles.selector}>
        <div className={styles.leading}>
          类别：
        </div>
        <Select defaultValue="all" value={selectedScene} style={{ width: 300 }} onChange={val => setSelectedScene(val)}>
          {
            Array.isArray(scenes) && scenes.map((item, index) => (
              <Option value={item.value} key={index}>{item.title}</Option>
            ))
          }
        </Select>
      </div>
      <div className={styles.reports}>
        {
          reports?.map(report => {
            const {
              id,
              type,
              template_name: templateName,
              title,
              download_the_number: downloadCount = 0,
              read_the_number: readCount = 0,
              template_type: tag,
              cover_url: coverURL,
              cover_scheme: coverScheme,
              author,
              date,
            } = report;
            return (
              <ReportCard
                title={title ?? templateName}
                key={id}
                tag={tag}
                author={author}
                date={date}
                readCount={readCount}
                downloadCount={downloadCount}
                className={styles.card}
                onClick={() => onReportClick(report)}
                coverId={id}
                coverURL={coverURL}
                coverScheme={coverScheme}
                coverTitle={industryName}
                coverSubtitle={templateName}
                coverArea="北京市"
              />
            );
          })
        }
      </div>
      {choseModel && (
        <ReportModal
          industryCode={industryCode}
          industryName={industryName}
          templateId={templateId}
          templateType={templateType}
          visible={choseModel}
          setVisible={setChoseModel}
          startAnalysis={analysisReportConditionChange}
          loading={choseLoading}
        />
      )}
    </div>
  );
}

export default ReportScheme;
