/**
 * 取出并生成 title 对应的元素，图表使用
 */

import { ComponentType, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

export interface WithDetectionProps {
  title?: {
    show?: boolean;
    content?: string;
  };
  [key: string]: any;
}

export function withDetection<T extends WithDetectionProps>(
  WrappedComponent: ComponentType<T>,
  option?: { threshold?: number },
  ) {
  const { threshold } = option ?? {};
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  const Component = (props: T) => {
    const [isInView, setIsInView] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handler = throttle(() => {
        if (ref.current) {
          const top = ref.current.getBoundingClientRect().top ?? 0;
          const _threshold = window.innerHeight + (threshold ?? window.innerHeight * 1.5);
          if (Math.abs(top) <= _threshold) {
            setIsInView(true);
          }
        }
      }, 80, { trailing: true });

      handler();
      // 适配编辑器的dom
      const dom = document.querySelector('#sqke-editoor');
      if (dom) {
        dom.addEventListener('scroll', handler, { passive: true });
      } else {
        document.addEventListener('scroll', handler, { passive: true });
      }
      return () => {
        document.removeEventListener('scroll', handler);
      };
    }, []);

    return (
      <>
        <div
          className="sqke-detection-holder"
          style={{ visibility: 'hidden', height: 0, overflow: 'hidden', pointerEvents: 'none' }}
          ref={ref}
        />
        <WrappedComponent {...{...props, skip: !isInView} as T} />
      </>
    );
  };

  Component.displayName = `withDetection(${displayName})`;

  return Component;
}
