/*
* Author: zhangmeng
* Description: ''
* Date: 2022/06/29
*/

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Button, Pagination, Spin } from 'antd';
import { useGetPolicyListQuery } from '../../../../service/dataSearch';
import TabSelect from '../SelectorComp/TabSelect';
import InputTreeSelect from '../SelectorComp/InputTreeSelect';
import SearchSortor from '../SearchSortor';
import PolicyListLine from '../PolicyListLine';
import style from './index.module.less';

const sortData1 = [
  {
    title: '发布时间',
    key: 'publish_date',
  },
];

function PolicyPage(props) {
  const { filter, styles, participle, hideTitle = false } = props;
  const {
    policy_level: institutionLevelList,
    declare_type: declareTypeList,
    policy_type: institutionTypeList,
    support_object: supportObjectList,
    support_behavior: supportBehaviorList,
    support_way: supportWayList,
    industryTree,
    areaTree,
  } = filter;
  const [institutionLevel, setInstitutionLevel] = useState(['不限']);
  const [declareType, setDeclareType] = useState(['不限']);
  const [institutionType, setInstitutionType] = useState([]);
  const [supportObject, setSupportObject] = useState([]);
  const [supportBehavior, setSupportBehavior] = useState([]);
  const [supportWay, setSupportWay] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [area, setArea] = useState([]);
  const [sortKey, setSort] = useState({ key: '', count: 0 });
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isConfirm, setIsConfirm] = useState(false);

  /* eslint-disable no-nested-ternary */
  const params = sortKey.count === 0 ? {
    policy_level_list: institutionLevel.length === 1 ? (institutionLevel[0] === '不限' ? [] : institutionLevel) : institutionLevel,
    declare_type_list: declareType.length === 1 ? (declareType[0] === '不限' ? [] : declareType) : declareType,
    policy_type_list: institutionType,
    support_object_list: supportObject,
    support_behavior_list: supportBehavior,
    support_way_list: supportWay,
    filtering_industry_list: industry,
    filtering_area_list: area,
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
  } : {
    policy_level_list: institutionLevel.length === 1 ? (institutionLevel[0] === '不限' ? [] : institutionLevel) : institutionLevel,
    declare_type_list: declareType.length === 1 ? (declareType[0] === '不限' ? [] : declareType) : declareType,
    policy_type_list: institutionType,
    support_object_list: supportObject,
    support_behavior_list: supportBehavior,
    support_way_list: supportWay,
    filtering_industry_list: industry,
    filtering_area_list: area,
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
    sort_column: sortKey.key,
    sort_order: sortKey.count === 1 ? 'asc' : 'desc',
  };
  const { data: list, isFetching } = useGetPolicyListQuery(params, { skip: isConfirm });

  useEffect(() => {
    if (!isFetching) {
      setIsConfirm(true);
    }
  }, [isFetching]);

  useEffect(() => {
    setIsConfirm(false);
  }, [participle]);

  const resetClick = () => {
    setInstitutionLevel(['不限']);
    setDeclareType(['不限']);
    setInstitutionType([]);
    setSupportObject([]);
    setSupportBehavior([]);
    setSupportWay([]);
    setIndustry([]);
    setArea([]);
    setIsConfirm(true);
  };
  const confirmSearch = () => {
    setIsConfirm(false);
    setPageNum(1);
  };

  const changeSort = useCallback(
    (sort) => {
      setSort(sort);
      setIsConfirm(false);
    },
    [],
  );

  // pagination-onChange
  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
    setIsConfirm(false);
  };

  return (
    <div className={style.all} style={styles}>
      <div className={style.topBox}>
        {
          !hideTitle && <div className={style.title}>政策</div>
        }
        <div className={style.selectorBox}>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <TabSelect
                  title="政策级别"
                  data={institutionLevelList}
                  selected={institutionLevel}
                  setSelected={p => {
                    setInstitutionLevel(p);
                    setIsConfirm(true);
                  }}
                />
              ), [institutionLevelList, institutionLevel])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <TabSelect
                  title="申报类型"
                  data={declareTypeList}
                  selected={declareType}
                  setSelected={p => {
                    setDeclareType(p);
                    setIsConfirm(true);
                  }}
                />
              ), [declareTypeList, declareType])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="政策类型"
                  data={institutionTypeList}
                  select={institutionType}
                  setSelect={p => {
                    setInstitutionType(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择政策类型"
                />
              ), [institutionTypeList, institutionType])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="支持对象"
                  data={supportObjectList}
                  select={supportObject}
                  setSelect={p => {
                    setSupportObject(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择支持对象"
                />
              ), [supportObjectList, supportObject])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="支持行为"
                  data={supportBehaviorList}
                  select={supportBehavior}
                  setSelect={p => {
                    setSupportBehavior(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择支持行为"
                />
              ), [supportBehaviorList, supportBehavior])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="支持方式"
                  data={supportWayList}
                  select={supportWay}
                  setSelect={p => {
                    setSupportWay(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择支持方式"
                />
              ), [supportWayList, supportWay])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="区域"
                  data={areaTree}
                  select={area}
                  setSelect={p => {
                    setArea(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择区域"
                />
              ), [areaTree, area, setArea])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="产业"
                  data={industryTree}
                  select={industry}
                  setSelect={p => {
                    setIndustry(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择产业"
                />
              ), [industryTree, industry, setIndustry])
            }
          </div>
          <div className={style.btnGroup}>
            <Button type="primary" ghost onClick={() => resetClick()}>
              重置
            </Button>
            &emsp;
            <Button type="primary" className="confirmSearch" onClick={() => confirmSearch()}>
              查询
            </Button>
          </div>
        </div>
      </div>
      <div className={style.listBox}>
        <Spin tip="搜索中，请稍候..." spinning={isFetching}>
          <SearchSortor
            exportType="exportSearchPolicy"
            dataKey="search_policy"
            params={params}
            data={sortData1}
            sort={sortKey}
            total={list?.data_count || 0}
            onChange={changeSort}
          />
          <div className={style.list}>
            {
              list && list.data_list && list.data_list.length > 0 ? list.data_list.map((item, index) => (
                <PolicyListLine key={index} record={item} participle={participle} />
              )) : <div style={{ textAlign: 'center', marginTop: '50px' }}>暂无数据</div>
            }
            <div className={style.pagination}>
              <Pagination
                showQuickJumper
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={pageSize}
                current={pageNum}
                total={list?.auth_count || 0}
                onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default PolicyPage;
