/**
 * 柱状图
 * 柱线图
 * 高亮柱状图
 * 可通过不同参数控制
*/
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Spin } from 'antd';
import { VerticalAlignBottomOutlined, FileImageOutlined } from '@ant-design/icons';
import { ECharts } from '@sqke/design';
// import ChartNoData from '../ChartNoData';
import style from '../public/index.module.less';

/**
 * @param {*} param0
 * @returns
 * @leftYName 左侧y轴标题边距 可以是 [] 和 number 遵循上右下左
 * @highlight 高亮
 * @series 数据轴数据 y轴数据
 * @x x轴刻度
 * @yName 左侧y轴name
 * @yName1 右侧y轴name
 * @xName x轴名字
 */
const Barandline = ({ data, isFetching, showLabel = false, scoreShow, showNumber, textLength, wrap, showBottom, contentTop = 28, color, downloadImg, dataDownload, preview, setReportData, title, id }) => {
  const canvasRef = useRef();
  const [highlightX, setHighlightX] = useState('');
  const [highlightY, setHighlightY] = useState('');
  const {
    x,
    series = [],
    highlight,
    yName1,
    yName,
    leftYName = 0,
    rightYName = 0,
    left = 60,
    right = 40,
    bottom = 56,
    rotate = 60,
    legendBottom = 0,
  } = data || {};

  const toImg = () => {
    if (!canvasRef.current) return;
    const img = new Image();
    // pieMyChart1 要导出的图表
    img.src = canvasRef.current.getEchartsInstance().getDataURL({
      type: 'png',
      pixelRatio: 1, // 放大2倍
      backgroundColor: '#fff',
    });
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      // 添加文字
      const text = '数据来源：上奇产业通';
      // 设置字体和大小
      ctx.font = '600 14px PingFangSC, PingFang SC';
      // 设置文本颜色
      ctx.fillStyle = '333333';
      //  设置文本
      ctx.fillText(text, canvas.width - 160, canvas.height - 5);
      const dataURL = canvas.toDataURL();
      setReportData(params => ({ ...params, [id]: { ...params[id], el: dataURL } }));
    };
  };

  const onChartFinished = () => {
    if (setReportData) {
      toImg();
    }
  };
  const option = useMemo(() => {
    // canvasRef.current?.getEchartsInstance().clear();
    const highlightBar = (index) => {
      const seat = x?.indexOf(highlight);
      setHighlightX(seat);
      return series?.[index]?.data?.map((item, index2) => {
        if (index2 === seat) {
          setHighlightY(item);
          return {
            value: item,
            itemStyle: {
              color: '#FD6205',
            },
          };
        }
        return item;
      });
    };
    const newSeries = series?.map((item, index) => {
      if (item.type === 'bar' || !item.type) {
        return {
          name: `${item.name}`,
          type: 'bar',
          // barGap: 0,
          // coordinateSystem: 'cartesian2d',
          data: highlight ? highlightBar(index) : item.data,
          markPoint: {
            data: [{ value: highlightY, xAxis: highlightX, yAxis: highlightY }],
            itemStyle: {
              color: '#FD6205',
            },
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              fontWeight: 600,
              fontSize: 12,
              lineHeight: 10,
              textBorderColor: '#FD6205',
              textBorderWidth: 3,
            },
          },
          label: {
            show: showLabel,
            position: 'top',
            color: '#000',
            fontSize: 12,
          },
        };
      }
      if (item.type === 'line') {
        return {
          name: `${item.name}`,
          type: 'line',
          label: {
            show: showLabel,
            position: 'top',
            color: '#000',
            fontSize: 12,
          },
          symbolSize: 8,
          itemStyle: { color: '#FFA02A' },
          yAxisIndex: 1,
          // coordinateSystem: 'cartesian2d',
          // areaStyle: {},
          data: item?.data,
        };
      }
      return [];
    });
    return {
      color, // 柱图的颜色数组，可以设置多个颜色
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: x?.length >= 8 && rotate,
          fontSize: x?.length >= 22 ? 9 : 12,
          color: '#333',
          formatter: wrap ? (value) => {
            const len = value.length;
            const length = textLength || 5; // 控制一行显示个数
            const num = Math.ceil(len / length);// 循环次数
            if (num > 1) {
              let str = '';
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < num; i++) {
                str += `${value.substring(i * length, (i + 1) * length)}\n`;
              }
              return str;
            }
            return value;
          } : null,
        },
        data: x,
      },
      tooltip: {
        trigger: 'axis',
        formatter: params => {
          let relVal = `${params[0].axisValueLabel}`;
          params.forEach((item, index) => {
            relVal = `${relVal}<br/>${item.marker}${item.seriesName}\u3000\u3000${item.value.toLocaleString()}${series[index].unit}`;
          });
          return relVal;
        },
      },
      yAxis: [
        {
          name: yName,
          type: 'value',
          nameTextStyle: {
            padding: leftYName,
          },
          axisLabel: {
            color: '#333',
          },
        },
        {
          name: yName1,
          type: 'value',
          alignTicks: true,
          nameTextStyle: {
            padding: rightYName,
          },
          axisLabel: {
            color: '#333',
          },
        },
      ],
      grid: {
        top: contentTop || 28,
        right,
        bottom,
        left,
        // 坐标系不跟随内容改变
        containLabel: false,
      },
      legend: series?.length > 1 && {
        show: true,
        bottom: legendBottom || 0,
        itemGap: 60,
        itemHeight: 8,
        itemWidth: 12,
      },
      dataZoom: [
        scoreShow
          ? {
            xAxisIndex: 0,
            show: scoreShow,
            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: showNumber || 5, // 一次性展示6个
            height: 4,
            bottom: showBottom || 0,
            showDetail: false,
            // borderColor: 'rgba(43,48,67,.1)',
            // fillerColor: 'rgb(255,255,255)',
          } : null,
      ],
      series: newSeries,
    };
  }, [JSON.stringify(data), showLabel]);

  const cheartEl = useMemo(() => (
    <div className={style.chart}>
      <ECharts
        style={{
          height: '100%',
          width: '100%',
        }}
        option={option}
        onEvents={{
          finished: onChartFinished,
        }}
        ref={canvasRef}
      />
    </div>
  ),
  [JSON.stringify(option)]);

  useEffect(() => {
    if (canvasRef.current && option) {
      canvasRef.current.getEchartsInstance().clear();
      canvasRef.current.getEchartsInstance().setOption(option);
    }
  }, [data.length]);
  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {
        !preview && (
          <div className={style.tool}>
            <div onClick={dataDownload}>
              <span>
                <VerticalAlignBottomOutlined />
              </span>
              <span>下载数据</span>
            </div>
            <div onClick={() => {
              downloadImg(canvasRef, title);
            }}
            >
              <span>
                <FileImageOutlined />
              </span>
              <span>保存图片</span>
            </div>
          </div>
        )
      }
      {
        cheartEl
      }
    </Spin>
  );
};

export default Barandline;
