import React, { useMemo } from 'react';
import Table from '../../../../components/Table';
import RankingOrder from '../../../../components/RankingOrder';
import Score from '../../../../components/Score';
import { useGetCityChannelCompanyScoreRankQuery } from '../../../../service/city-channel';
import styles from './index.module.less';

const THEAD_TABLE = {
  index: {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    render: text => <RankingOrder order={text} />,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    className: 'clickable',
  },
  total_point: {
    title: '上奇评价',
    dataIndex: 'total_point',
    key: 'total_point',
    width: 80,
    render: text => <Score score={text} />,
    align: 'center',
    className: styles.noPadding,
  },
};

const COLUMN_KEYS = ['index', 'name', 'total_point'];

function RecommendRanking(props) {
  const { pageNumber, pageSize, cityCode, onCompanyClick } = props;
  const { data, isFetching } = useGetCityChannelCompanyScoreRankQuery({
    area_key: cityCode,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !cityCode,
  });

  const { total = 0, columns = [], dataSource = [] } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) return COLUMN_KEYS.map(key => THEAD_TABLE[key]);
    return columns.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      return rowConfig;
    });
  }, [columns, onCompanyClick]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      current={pageNumber}
      total={total}
      loading={isFetching}
      {...props}
    />
  );
}

export default RecommendRanking;
