/*
 *Author: zhaochenyu
 *Description: 区域画像预览页
 *Date: 2021/11/17
*/

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Spin, Button, message, Progress, Alert } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QRcode from 'qrcode';
import cloneDeep from 'lodash/cloneDeep';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useQuery } from '../../hooks/url';
// import dayjs from 'dayjs';
import { getReportMsg, downloadSystemTemplateReport, checkWordCreated, downloadReport } from '../../api/office';
import { base64toFile } from '../../utils/report';
import { openNewPage } from '../../utils/urlMethods';
import {
  getSystemReportQrcode,
  getSystemReportQrcodeStatus,
  paySQcoinSystemTemplate,
} from '../../api/apply';
import { getUserSQcoinNum } from '../../api/mySelf';
import AreaPortraitReport from './AreaPortraitReport';
import CountryPortraitReport from './CountryPortraitReport';
import IndustryPortraitReport from './IndustryPortraitReport';
import CompanyPortraitReport from './CompanyPortraitReport';
import IndustryPortraitCountryReport from './IndustryPortraitCountryReport';
import InvestmentPromotionReport from './InvestmentPromotionReport';
import PreciseInvestReport from './PreciseInvestmentReport';
import EquityInvestmentReport from './EquityInvestmentReport';
import SpecialNewsReport from './SpecialNewsReport';
import SpecialNewsReportCountry from './SpecialNewsReportCountry';
import HighTechnologyReport from './HighTechnologyReport';
import HighTechnologyReportCountry from './HighTechnologyReportCountry';
import IntellectualPropertyReport from './IntellectualPropertyReport';
import IntellectualPropertyReportCountry from './IntellectualPropertyReportCountry';
import { reportCoverMap } from '../../utils/reportCoverMap';
// import { IconFont } from '../../utils/mapIcon';
import PayAmountCard from '../PayAmountCard';
import SQPayAmountCard from '../SQPayAmountCard';
import ChannelModal from '../MySelf/ChannelModal';
import WxShare from '../WxShare';
import style from './index.module.less';

const defaultTip = '计算模型分析中，请稍后...';
// const collectTip = '数据转换中，请稍后...';
const requestTip = '报告生成中，请稍后...';

const allCitys = [
  '北京市',
  '天津市',
  '河北省',
  '山西省',
  '内蒙古自治区',
  '辽宁省',
  '吉林省',
  '黑龙江省',
  '上海市',
  '江苏省',
  '浙江省',
  '安徽省',
  '福建省',
  '江西省',
  '山东省',
  '河南省',
  '湖北省',
  '湖南省',
  '广东省',
  '广西壮族自治区',
  '海南省',
  '重庆市',
  '四川省',
  '贵州省',
  '云南省',
  '西藏自治区',
  '陕西省',
  '甘肃省',
  '青海省',
  '宁夏回族自治区',
  '新疆维吾尔自治区',
];

function RegionalPortraitReport(props) {
  const query = useQuery();
  const history = useHistory();
  const [chartConditions, setChartConditions] = useState([]);
  const [chartTitles, setChartTitles] = useState([]);
  const [openWxModal, setOpenWxModal] = useState(true);
  const [loading, setLoading] = useState(false); // 页面loading
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadingDisable, setDownloadingDisable] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [editDisable, setEditDisable] = useState(true);
  const [tip, setTip] = useState(defaultTip); // 页面loading提示信息
  const [percent, setPercent] = useState(0);
  const [percentShow, setPercentShow] = useState(true);
  // const [loadingComplete, setLoadingComplete] = useState(false);
  const [buyStatus, setBuyStatus] = useState(false);
  const [applyExpired, setApplyExpired] = useState(false);
  const [successPay, setSuccessPay] = useState(false);
  const [successPayLoading, setSuccessPayLoading] = useState(false);
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [amount, setAmount] = useState(18.8);
  const [costSQcoin, setCostSQcoin] = useState(0);
  const [defaultSelect, setDefaultSelect] = useState('');
  const [channelType, setChannelType] = useState('industry');
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [cost, setCost] = useState(0);
  const [sqcoin, setSqcoin] = useState(0);
  const [fileName, setFileName] = useState('');
  const refList = useRef([]);
  const chartTitlesRef = useRef([]);
  const mainRef = useRef(null);
  const chartReady = useRef(null);
  const timer = useRef(null);
  const timer1 = useRef(null);
  const timer2 = useRef(null);
  const timer3 = useRef(null);
  const orderId = useRef(null);
  const expiredRef = useRef(null);
  // const commonRef = useRef({ blockUnregister: null, nextLocation: {}, lastChartCount: 0 });
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  const pushToAchor = (name) => {
    const achorName = document.getElementById(name);
    if (achorName) {
      let dom = achorName;
      let y = 0;
      do {
        y += dom.offsetTop;
        dom = dom.parentElement;
      } while (dom.parentElement);
      mainRef.current.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    }
  };

  // const beforeunloadListener = (e) => {
  //   // console.log(e);
  //   e.preventDefault();
  //   e.returnValue = '';
  // };

  const getChartFinishedPercent = (index) => {
    chartReady.current[index] = true;
    const total = chartReady.current.length;
    const ready = chartReady.current.filter(item => item).length;
    const curPercent = (ready / total) * 100;
    if (curPercent !== 100) {
      setPercent(curPercent.toFixed(2));
    } else {
      timer.current = setTimeout(() => {
        setPercent(curPercent);
        setDownloadingDisable(false);
        setEditDisable(false);
        setLoading(false);
        // setLoadingComplete(true);
        timer1.current = setTimeout(() => {
          setPercentShow(false);
        }, 3000);
      }, 1500);
    }
  };

  // useEffect(() => {
  // }, [chartConditions]);
  // 生成并下载
  const getReportWord = async (type, urlData) => {
    let created = false;
    message.loading('文件生成中...', 0);
    await checkWordCreated({
      report_id: urlData.id,
    }).then(res => {
      if (res.status === '00000') {
        if (res.data && type === 'down') {
          // if (res.data?.need_SQCoin_download) {
          if (res.data?.need_SQCoin_download) {
            setCost(res.data?.goods_sq_coin);
          } else {
            setCost(0);
          }
          setFileName(res.data?.file_name);
          message.destroy();
          setDownloadModalVisible(true);
          // }
          // const downFilename = new URLSearchParams(res.data.split('?')[1]);
          // requestFile('GET', `${res.data}`, {}, downFilename.get('fileName')).then(() => {
          //   setLoading(false);
          //   setDownloadLoading(false);
          //   setEditDisable(false);
          // });
          // window.open(res.data);
          // setLoading(false);
          // setDownloadLoading(false);
          // setEditDisable(false);
          created = true;
        } else if (res.data && type === 'edit') {
          setLoading(false);
          setEditLoading(false);
          setDownloadingDisable(false);
          openNewPage('office', {
            id: urlData.id,
          });
          created = true;
        }
      }
    });
    if (created) return;
    let imgList = [];
    const promises = [];
    const descs = [];
    const titles = [];
    const sizes = [];
    refList.current.forEach(item => {
      promises.push(item.current
        ? item.current.toDataURL() : Promise.resolve(null));
      descs.push(item.current ? item.current.getChartDesc() : '');
      sizes.push(item.current ? item.current.getChartSize() : { width: 0, height: 0 });
    });
    imgList = await Promise.all(promises);
    imgList.forEach((item, index) => {
      if (item && typeof item !== 'string') {
        sizes[index] = { width: 0, height: 0 };
      }
    });
    chartTitlesRef.current.forEach(item => {
      titles.push(item.current ? item.current.getChartTitle() : '');
    });
    const fileList = imgList.map((item, index) => base64toFile(item, chartConditions[index].title || ''));
    // 校验报告名称是否存在
    // const picName = selectMode.map(item => item.title).join(',');
    const selectModeList = chartConditions.map((item, index) => {
      let copyItem = cloneDeep(item);
      copyItem = {
        ...copyItem,
        before_describe_value_dict: descs[index].before_describe_value_dict || '',
        after_describe_value_dict: descs[index].after_describe_value_dict || '',
        title: titles[index],
        size: sizes[index],
      };
      return copyItem;
    });
    const reportMsg = {
      report_id: urlData.id,
      query_params: JSON.stringify(selectModeList),
      area_name: urlData.area || '',
      industry_name: urlData.industry || '',
      company_name: urlData.name || '',
      pic_list: fileList,
    };
    setTip(requestTip);
    downloadSystemTemplateReport(reportMsg).then(res => {
      if (res.status === '00000') {
        if (type === 'down') {
          if (res.data?.need_SQCoin_download) {
            setCost(res.data?.goods_sq_coin);
          } else {
            setCost(0);
          }
          setFileName(res.data?.file_name);
          message.destroy();
          setDownloadModalVisible(true);
          // const downFilename = new URLSearchParams(res.data.split('?')[1]);
          // requestFile('GET', `${res.data}`, {}, downFilename.get('fileName')).then(() => {
          // });
          // window.open(res.data);
          // setLoading(false);
          // setDownloadLoading(false);
          // setEditDisable(false);
        } else {
          setLoading(false);
          setEditLoading(false);
          setDownloadingDisable(false);
          openNewPage('office', {
            id: urlData.id,
          });
        }
      } else {
        message.error(res.message);
      }
    }).finally(() => {
      setLoading(false);
      setDownloadLoading(false);
      setEditDisable(false);
      setEditLoading(false);
      setDownloadingDisable(false);
    });
  };

  const getCurReport = () => {
    const reportType = urlReportMsg.curTemplateId;
    let res = null;
    switch (reportType) {
      case '16':
        res = <AreaPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          allCitys={allCitys}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '23':
        res = <CountryPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '17':
        res = <IndustryPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '22':
        res = <IndustryPortraitCountryReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '18':
        res = <CompanyPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '500':
        res = <InvestmentPromotionReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '499':
        res = <PreciseInvestReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '498':
        res = <EquityInvestmentReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '492':
        res = <SpecialNewsReportCountry
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '493':
        res = <SpecialNewsReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '494':
        res = <HighTechnologyReportCountry
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '495':
        res = <HighTechnologyReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '496':
        res = <IntellectualPropertyReportCountry
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case '497':
        res = <IntellectualPropertyReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      default:
        res = <AreaPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
    }
    return res;
  };

  const getCurParams = () => {
    if (urlReportMsg.area && urlReportMsg.industry) {
      return (
        <>
          {urlReportMsg.area}
          {urlReportMsg.industry}
        </>
      );
    }
    return urlReportMsg.area || urlReportMsg.industry || urlReportMsg.name || '';
  };

  const getOrderCurStatus = useCallback(() => {
    if (timer3.current) {
      clearTimeout(timer3.current);
      timer3.current = null;
    }
    getSystemReportQrcodeStatus({
      order_id: orderId.current,
      report_id: urlReportMsg.id,
    }).then(res => {
      if (res.status === '00000') {
        setSuccessPay(true);
        // setTimeout(() => {
        window.location.reload();
        // }, 2000);
      } else if (!expiredRef.current) {
        timer3.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    }).catch(() => {
      if (!expiredRef.current) {
        timer3.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    });
  }, []);

  const applyCountDown = useCallback(() => {
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    if (timer3.current) {
      clearTimeout(timer3.current);
      timer3.current = null;
    }
    getSystemReportQrcode({
      template_id: urlReportMsg.curTemplateId,
      report_id: urlReportMsg.id,
    }).then(res => {
      if (res.status === '00000') {
        orderId.current = res.data.order_id;
        setAmount(res.data.amount);
        setCostSQcoin(res.data.goods_sq_coin);
        setSqcoin(res.data.user_sq_coin);
        QRcode.toCanvas(res.data.code_url, { width: 50, margin: 1 }, (err, canvas) => {
          if (err) {
            console.log(err);
          }
          const contanier = document.getElementById('qrcode');
          if (contanier.childNodes[0]) {
            contanier.removeChild(contanier.childNodes[0]);
          }
          contanier.appendChild(canvas);
        });
        setApplyExpired(false);
        expiredRef.current = false;
        timer3.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
        timer2.current = setTimeout(() => {
          setApplyExpired(true);
          expiredRef.current = true;
        }, 120 * 1000);
      }
    });
  }, [getOrderCurStatus]);

  const paySuccess = () => {
    setSuccessPayLoading(true);
    paySQcoinSystemTemplate({
      report_id: urlReportMsg.id,
    }).then(res => {
      if (res.status === '00000') {
        setSuccessPay(true);
        // setTimeout(() => {
        window.location.reload();
        setSuccessPayLoading(false);
        // }, 2000);
      }
    });
  };

  const download = (file) => {
    if (cost > sqcoin) {
      message.warning('上奇币不足，请获取！');
      return;
    }
    message.loading('文件下载中...', 0);
    downloadReport({
      fileName: file,
      version: 2,
    }).then(res => {
      if (res.status === '00000') {
        message.destroy();
        window.open(res.data);
        setLoading(false);
        setDownloadLoading(false);
        setEditDisable(false);
        setDownloadModalVisible(false);
      } else {
        message.destroy();
        message.warning(res?.message);
      }
    });
  };

  const pushDetailPage = useCallback((key) => {
    query.set('tab', '个人中心');
    query.set('detail', key);
    history.push({
      pathname: 'accountSetting',
      search: query.toString(),
    });
  }, [query]);

  const getUserSQcoin = () => {
    getUserSQcoinNum().then(res => {
      if (res.status === '00000') {
        setSqcoin(res.data);
      }
    });
  };
  useEffect(() => {
    getUserSQcoin();
    setTip(defaultTip);
    setPercent(0);
    setLoading(true);
    getReportMsg({
      report_id: urlReportMsg.id,
      industry_code: urlReportMsg.industryKey,
      city_code: urlReportMsg.areaKey,
    }).then(res => {
      if (res.status === '00000') {
        setOpenWxModal(!res.data.pay_status);
        if (!res.data.pay_status) {
          setBuyStatus(!res.data.pay_status);
          const curCharts = JSON.parse(res.data.query_params);

          const titles = curCharts.map(item => item.title);
          setChartTitles(titles);

          refList.current = curCharts.map(() => React.createRef());
          chartTitlesRef.current = curCharts.map(() => React.createRef());
          chartReady.current = curCharts.map(() => false);
          // 查询图表标题
          setChartConditions(curCharts);
          // setDownloadingDisable(false);
          // setEditDisable(false);
        } else {
          setBuyStatus(false);
          setLoading(false);
          applyCountDown();
        }
      } else {
        setBuyStatus(false);
        setLoading(false);
        applyCountDown();
      }
    });
    // window.addEventListener('beforeunload', beforeunloadListener);
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer1.current);
      clearTimeout(timer2.current);
      clearTimeout(timer3.current);
      // window.removeEventListener('beforeunload', beforeunloadListener);
    };
  }, []);
  useEffect(() => {
    if (urlReportMsg.industryKey) {
      setDefaultSelect(urlReportMsg.industryKey[0]);
      setChannelType('industry');
    } else if (urlReportMsg.areaKey) {
      setDefaultSelect({
        title: urlReportMsg.area,
        key: urlReportMsg.areaKey[0],
      });
      setChannelType('city');
    }
  }, []);

  const isSqCoinNotEnough = cost > sqcoin;

  return (
    <div className={style.all} ref={mainRef}>
      {buyStatus && percentShow && (
      <div className={style.process}>
        <Progress percent={percent} showInfo={false} type="line" strokeWidth={6} />
      </div>
      )}
      {buyStatus && (
      <Button
        className={downloadingDisable ? style.disableDownload : style.downLoad}
        loading={downloadLoading}
        // disabled={downloadingDisable}
        onClick={() => getReportWord('down', urlReportMsg)}
      >
        生成并下载
      </Button>)}
      {/* <Button
        className={editDisable ? style.disableEdit : style.edit}
        loading={editLoading}
        // disabled={editDisable}
        onClick={() => {
          if (loading || editDisable) {
            return;
          }
          setEditLoading(true);
          setDownloadingDisable(true);
          setTip(collectTip);
          setLoading(true);
          setTimeout(() => {
            getReportWord('edit', urlReportMsg);
          }, 500);
        }}
      >
        在线编辑
      </Button> */}
      {loading && (
      <div className={style.loadingCover}>
        <Spin spinning={loading} tip={tip} />
      </div>
      )}
      {getCurReport()}
      {!buyStatus && <div style={{ height: '100px' }} />}
      {!buyStatus && (
      <div className={style.lockCover}>
        <div className={style.lock} />
        <div className={style.desc}>
          扫码支付后查看并下载全文
          <DownOutlined />
        </div>
      </div>)}
      {!buyStatus && (
      <div className={style.header}>
        <div className={style.headerContent}>
          <div className={style.reportCover}>
            <img alt="" src={reportCoverMap[urlReportMsg.curTemplateId]} className={style.cover} />
          </div>
          <div className={style.applyMsg}>
            <div className={style.info}>
              <div className={style.title}>
                {getCurParams()}
              </div>
              <div className={style.title}>{urlReportMsg.title || ''}</div>
              <div className={style.payAmount}>
                <PayAmountCard count={amount || 0} />
                <div className={style.applyCode}>
                  <div className={style.payQrCode}>
                    <div className={style.qrcodeCover} style={{ display: applyExpired ? '' : 'none' }}>
                      二维码已失效
                    </div>
                    <div id="qrcode" className={style.qrcode} />
                  </div>
                  <div className={style.refresh} onClick={() => applyCountDown()}>
                    {/* <IconFont type="iconsync" style={{ marginRight: '4px' }} /> */}
                    刷新二维码
                  </div>
                </div>
                <div className={style.SQPayAmount}>
                  <SQPayAmountCard sqcoin={sqcoin || 0} successPayLoading={successPayLoading} count={costSQcoin || 0} paySuccess={paySuccess} />
                </div>
                <div className={style.buyChannel} onClick={() => setChannelModalVisible(true)} />
              </div>
              <div className={style.portocol}>
                支付即代表您同意
                <span
                  style={{ color: '#fff', cursor: 'pointer' }}
                  onClick={() => { window.open('https://www.chanyeos.com/industry_engine_resource/industry_engine_Q_and_A_pdf/产业通用户服务协议.pdf'); }}
                >
                  《上奇用户协议》
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>)}
      {successPay && (
      <div className={style.successAlert}>
        <Alert
          message={(
            <span>
              此报告
              <span style={{ margin: '0 10px' }}>已购买成功</span>
            </span>)}
          type="success"
          showIcon
        />
      </div>)}
      {
        useMemo(() => channelModalVisible && <ChannelModal
          defaultSelect={defaultSelect}
          type={channelType}
          onClose={() => setChannelModalVisible(false)}
          paySuccessCb={(buyCOdeList) => {
            // if (buyCOdeList.includes(urlReportMsg.industryKey[0])) {
            //   setTimeout(() => {
            //     window.location.reload();
            //   }, 2000);
            // }
            const urlParams = new URLSearchParams();
            urlParams.set('tab', '个人中心');
            props.history.push({
              pathname: 'accountSetting',
              search: urlParams.toString(),
            });
          }}
        />, [channelModalVisible, setChannelModalVisible])
      }
      {
        downloadModalVisible && (
          <>
            <div className={style.modal}>
              <div className={style.top}>
                <div className={style.title}>报告已生成</div>
                <div className={classnames(style.cost, { [style.alert]: isSqCoinNotEnough })}>
                  {
                    cost === 0
                      ? (
                        <>
                          是否下载
                        </>
                      ) : (
                        <>
                          是否消耗
                          {cost}
                          上奇币下载（余额
                          {sqcoin}
                          个）
                          {
                            isSqCoinNotEnough && (
                              <span
                                onClick={() => pushDetailPage('achieve')}
                              >
                                去获取
                              </span>
                            )
                          }
                        </>
                      )
                  }
                </div>
              </div>
              <div className={style.buttons}>
                <Button className={style.left} onClick={() => setDownloadModalVisible(false)}>稍后再试</Button>
                <Button
                  className={style.right}
                  onClick={() => {
                    setDownloadModalVisible(false);
                    download(fileName);
                  }}
                >
                  现在下载
                </Button>
              </div>
            </div>
            <div className={style.modalCover} />
          </>
        )
      }
      {/* {loadingComplete && (
      <Modal
        title={null}
        visible={loadingComplete}
        footer={null}
        closable={false}
        keyboard={false}
        maskClosable={false}
        maskStyle={{
          background: 'rgba(62, 92, 118, 0.79)',
        }}
        wrapClassName={style.loadingModal}
      >
        <div className={style.emptyHeader}></div>
        <img src={downloadComplete} alt="" className={style.loadingComplete} />
        <div className={style.desc}>已加载完毕</div>
        <div className={style.operator}>
          请选择
          <div
            className={style.modalBtn}
            onClick={() => {
              if (loading || downloadingDisable) {
                return;
              }
              setLoadingComplete(false);
              setDownloadLoading(true);
              setEditDisable(true);
              setTip(collectTip);
              setLoading(true);
              setTimeout(() => {
                getReportWord('down');
              }, 500);
            }}
          >
            生成并下载
          </div>
          或
          <div
            className={style.modalBtn}
            onClick={() => {
              if (loading || editDisable) {
                return;
              }
              setLoadingComplete(false);
              setEditLoading(true);
              setDownloadingDisable(true);
              setTip(collectTip);
              setLoading(true);
              setTimeout(() => {
                getReportWord('edit');
              }, 500);
            }}
          >
            在线编辑
          </div>
          此报告
        </div>
        <div className={style.closeBtn} onClick={() => setLoadingComplete(false)}>确定</div>
      </Modal>)} */}
      {/* <div className={classnames(style.wxModalMasking, { [style.wxModalMaskingNone]: openWxModal })}>
        <div className={style.wxModal}>
          <div key={urlReportMsg.id}>
            <QRCode value={`https://chanyeos.com/smart-ke/#/report?id=${urlReportMsg.id}`} size={142} />
          </div>
          <div>打开微信扫一扫发送助力邀请</div>
          <div onClick={() => {
            setOpenWxModal(true);
          }}
          >
            直接支付
          </div>
          <div className={style.close}>
            <span onClick={() => {
              setOpenWxModal(true);
            }}
            >
              <CloseOutlined />
            </span>
          </div>
        </div>
      </div> */}
      <WxShare
        url={`${window.location.origin}${window.location.pathname}#/report?id=${urlReportMsg.id}`}
        id={urlReportMsg.id}
        openWxModal={openWxModal}
        setOpenWxModal={setOpenWxModal}
      />
    </div>
  );
}

export default RegionalPortraitReport;
