import React, { useEffect } from 'react';
import BarTable from '../../../component/BarTable';
import NoData from '../../../../../components/Nodata';
import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const nullDataInit = {
  x: ['2023-01'],
  series: [
    { name: '融资金额(万元)', unit: '万元', data: [0] },
    { name: '融资事件(笔)', unit: '笔', data: [0] },
  ],
};
const FinancingScale = (props) => {
  const { url, dataFilterParams, reportData, ok } = props;
  const { data, isFetching } = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData || !ok });

  const newMap = () => {
    if (!data) return;
    let newData = {};
    if (!data.series.length) {
      newData = { ...data, ...nullDataInit };
    } else {
      newData = data;
    }
    // eslint-disable-next-line consistent-return
    return newData.series.map(item => {
      if (item.unit === '笔') {
        return { ...item, type: 'line' };
      }
      return item;
    });
  };
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  // console.log(data);
  // return <NoData label="noData" />;
  return <BarTable {...props} data={{ ...data, series: newMap() }} isFetching={isFetching} chartType="柱线图" />;
};

export default FinancingScale;
