/*
* Author: zhangmeng
* Description: '手机号验证'
* Date: 2021/01/21
*/

import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { getValidatorNote } from '../../../api/accountSetting';
import style from './index.module.less';

function PhoneVerify(props) {
  const { encryptPhone, onFinish, phone } = props;
  const [btnValue, setBtnValue] = useState('发送验证码');
  const captchaRef = useRef({ timer: null });

  useEffect(() => clearInterval(captchaRef.current.timer), []);

  const getCaptcha = () => {
    if (btnValue === '发送验证码') {
      const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
      if (!phone) {
        message.error('手机号不能为空');
      } else if (!checkPhone.test(phone)) {
        message.error('手机号码格式错误');
      } else {
        getValidatorNote({
          phone_no: phone,
        }).then(res => {
          if (res.status === '00000') {
            let second = 60;
            setBtnValue(`已发送(${second}s)`);
            if (captchaRef.current && captchaRef.current.timer) {
              clearInterval(captchaRef.current.timer);
            }
            captchaRef.current.timer = setInterval(() => {
              if (second > 0) {
                second -= 1;
                setBtnValue(`已发送(${second}s)`);
              } else {
                if (captchaRef.current) {
                  clearInterval(captchaRef.current.timer);
                }
                setBtnValue('发送验证码');
              }
            }, 1000);
          } else {
            message.error(res.message);
          }
        });
      }
    }
  };

  return (
    <div className={style.all}>
      <p>为了您的账号安全，请进行短信验证，验证成功后可继续绑定操作。</p>
      <div className={style.phoneBox}>
        <span>
          +
          {encryptPhone}
        </span>
      </div>
      <div className={style.codeBox}>
        <Form
          onFinish={(p) => onFinish(p)}
        >
          <Form.Item>
            <Form.Item
              label=""
              className="codeBox"
              name="code"
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input placeholder="请输入正确的验证码" />
            </Form.Item>
            <Button
              ref={captchaRef}
              type="text"
              onClick={() => getCaptcha()}
              className={btnValue !== '发送验证码' ? 'btnClicked' : ''}
              disabled={btnValue !== '发送验证码'}
            >
              {btnValue}
            </Button>
          </Form.Item>
          <Form.Item className="submit">
            <Button type="primary" htmlType="submit" className="submit-btn">
              验证
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default PhoneVerify;
