import React, { useMemo, useEffect, memo } from 'react';
import { Tooltip } from 'antd';
import Table from '../../../../../components/Table';
import styles from './index.module.less';
import {
  useGetIndustryTreeQuery,
  useGetCompanySpecialTagsQuery,
} from '../../../../../service/interactive-v2';
import {
  useGetCityDetailFindIndustryPolicyListQuery,
} from '../../../../../service/cityDetail';
import CustomTreeFilter from '../../../../../components/MultipleChoiceTree';
import CustomListFilter from '../../../../../components/CustomListFilter';
import { FilterIcon } from '../../../../../components/CustomTableIcon';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 40,
    className: styles.index,
  },
  title: {
    title: '政策名称',
    dataIndex: 'title',
    key: 'title',
    width: 400,
    ellipsis: true,
    className: styles.name,
  },
  abstract: {
    title: '摘要',
    dataIndex: 'abstract',
    key: 'abstract',
    width: 480,
    render: (text, record) => (
      <Tooltip title={text} placement='rightBottom' arrowPointAtCenter={true} overlayInnerStyle={{ width: '380px', minHeight: '20px', maxHeight: '400px', overflow: 'auto' }}>
        <div className={styles.abstract}>
          {text}
        </div>
      </Tooltip>
    ),
  },
  source: {
    title: '政策来源',
    dataIndex: 'source',
    key: 'source',
    ellipsis: true,
    width: 120,
  },
  publish_date: {
    title: '发布时间',
    dataIndex: 'publish_date',
    key: 'publish_date',
    ellipsis: true,
    width: 120,
  },
  industry: {
    title: '产业领域',
    dataIndex: 'industry',
    key: 'industry',
    width: 120,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    filterMode: 'tree',
  },
};

const COLUMN_KEYS = ['index', 'title', 'abstract', 'source', 'publish_date', 'industry'];

const InnovativeTable = memo((props) => {
  const {
    keyword,
    regionCode,
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    filteredInfo = {},
    getCount,
    setParams,
  } = props;
  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetCityDetailFindIndustryPolicyListQuery({
    key_word: keyword,
    industry_code_list: filteredInfo.industry?.filter(tag => tag !== 'all') ?? [],
    area_key: regionCode,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !regionCode,
  });
  const { tableHead = [], tableBody = [], total = 0, count = 0 } = companyData ?? {};
  const { data: companyTags = [] } = useGetCompanySpecialTagsQuery();
  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );
  const columns = useMemo(() => {
    // 这里 key 警告
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      // 处理企业名称
      if (item.dataIndex === 'title') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      if (item.dataIndex === 'special_tag') {
        rowConfig.filteredValue = filteredInfo.special_tag?.length > 0 ? filteredInfo.special_tag : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = companyTags;
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (item.dataIndex === 'industry') {
        rowConfig.filters = industryTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} multiple={true} />;
        rowConfig.filteredValue = filteredInfo?.industry ?? ['all'];
      }
      return rowConfig;
    });
  }, [onCompanyClick, tableHead, companyTags, filteredInfo, regionCode, industryTree]);

  useEffect(() => {
    getCount(count);
  }, [getCount, count]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        industry_code_list: filteredInfo.industry?.filter(tag => tag !== 'all') ?? [],
        area_key: regionCode,
      });
    }
  }, [keyword, regionCode, filteredInfo]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
    />
  );
});

export default InnovativeTable;
