import { Modal, message } from 'antd';
import { oauthIp } from './url';
import mutex from './mutex';
// import { synchronizationUserInfo } from '../utils/sessionToLocal';
import { setUser } from '../actions/app';
import store from '../store';

const cToken = 'Basic TkpwNWdBZkZEV0ZLdUZWeXVGQ1A0QkJLOkNIc01FRVFXdjBZYkQ5bnRxdkU0R2ZNNTk1SlNQTDc3VkxDT2VpQmtmYnpjM3BhRw==';
const bToken = 'Basic ZFkzZ0pMUUh6VmIxTDlNYXp6Zk1VazBqOm03NGpHSmVKTExTa0x0U0ROd1NqNWtHZUNUWHRJWE1UN3RZOUQybEV6UHVwOThQMw==';

const utf8_decode = (str_data) => {
  const tmp_arr = []; let i = 0; let ac = 0; let c1 = 0; let c2 = 0; let
    c3 = 0;
  str_data += '';
  while (i < str_data.length) {
    c1 = str_data.charCodeAt(i);
    if (c1 < 128) {
      tmp_arr[ac++] = String.fromCharCode(c1);
      i++;
    } else if (c1 > 191 && c1 < 224) {
      c2 = str_data.charCodeAt(i + 1);
      tmp_arr[ac++] = String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
      i += 2;
    } else {
      c2 = str_data.charCodeAt(i + 1);
      c3 = str_data.charCodeAt(i + 2);
      tmp_arr[ac++] = String.fromCharCode(
        ((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63),
      );
      i += 3;
    }
  }
  return tmp_arr.join('');
};


export const request_form_login = (method, uri, body, release) => {
  const url = uri;
  const { type } = body;
  method = method.toUpperCase();
  const formData = new FormData();
  for (const key in body) {
    if(key === 'type') break;
    formData.append(key, body[key]);
  }
  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      Authorization: type === 'B' ? bToken : cToken,
    },
    body: formData,
  }).then((res) => {
    if (res.status === 200) {
      const datas = res.json().then((datas) => {
        if (datas.status === 'A0317' || datas.status === 'A0303' || datas.status === 'A0207' || datas.status === 'A0305'|| datas.status === 'A0331') {
          if (localStorage.getItem('token')) {
            localStorage.setItem('token', '');
            Modal.warning({
              centered: true,
              content: '用户信息已过期，请重新登录！',
              okText: '重新登录',
              keyboard: false,
              onOk: () => {
                if (window.location.href.split('#')[1] === '/homePage') {
                  window.location.reload();
                } else {
                  store.dispatch(setUser(''));
                  window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
                }
                localStorage.clear();
                localStorage.removeItem('rft');
                localStorage.removeItem('rftChanyeMax');
              },
              width: 416,
            });
          }
          return Promise.reject('Unauthorized.');
        }
        if (datas.access_token) {
          const maxTime = new Date().getTime() + (datas.expires_in * 1000 - 600 * 1000);
          localStorage.setItem('maxTime', maxTime);
          localStorage.setItem('token', `${datas.token_type} ${datas.access_token}`);
          localStorage.setItem('rft', `${datas.refresh_token}`);
          // synchronizationUserInfo({ token: `${datas.token_type} ${datas.access_token}`, maxTime, rft: datas.refresh_token });
          if (localStorage.getItem('rft')) {
            localStorage.setItem('rft', `${datas.refresh_token}`);
            // localStorage.setItem('cpToken', `${datas.token_type} ${datas.access_token}`);
            localStorage.setItem('cpMaxTime', maxTime);
          }
        }
        if (release) {
          release();
        }
        return datas;
      });
      return datas;
    }
    localStorage.setItem('token', '');

    Modal.warning({
      centered: true,
      content: '用户信息已过期，请重新登录！',
      okText: '重新登录',
      keyboard: false,
      onOk: () => {
        if (window.location.href.split('#')[1] === '/homePage') {
          window.location.reload();
        } else {
          store.dispatch(setUser(''));
          window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
        }
        localStorage.clear();
        localStorage.removeItem('rft');
        localStorage.removeItem('rftChanyeMax');
      },
      width: 416,
    });
    return undefined;
  }).catch((error) => {
    localStorage.setItem('token', '');

    Modal.warning({
      centered: true,
      content: '用户信息已过期，请重新登录！',
      okText: '重新登录',
      keyboard: false,
      onOk: () => {
        if (window.location.href.split('#')[1] === '/homePage') {
          window.location.reload();
        } else {
          store.dispatch(setUser(''));
          window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
        }
        localStorage.clear();
        localStorage.removeItem('rft');
        localStorage.removeItem('rftChanyeMax');
      },
      width: 416,
    });
    return undefined;
  });
};

export const postFormLoginRefresh = (url, body, release) => request_form_login('POST', url, body, release);

export const postFormLogin = (url, body) => request_form_login('POST', url, body);

function refreshToken(release) {
  postFormLoginRefresh(`${oauthIp}/token`, {
    grant_type: 'refresh_token',
    refresh_token: localStorage.getItem('rft') || '',
  }, release);
}

export default async function request(method, uri, body) {
  let url = uri;
  method = method.toUpperCase();
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key}=${body[key]}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  let credentials = 'same-origin';

  if (process.env.NODE_ENV === 'production') {
    credentials = 'include';
  }
  let result;
  await mutex.waitForUnlock();
  const maxTime = localStorage.getItem('maxTime');
  const nowTime = new Date().getTime();
  const token = localStorage.getItem('token');
  const rft = localStorage.getItem('rft');
  if (nowTime >= maxTime && !mutex.isLocked() && token && rft) {
    const release = await mutex.acquire();
    await refreshToken(release);
  } 
  // else {
    await mutex.waitForUnlock();
    result = await fetch(url, {
      method,
      mode: 'cors',
      credentials,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json,text/plain,*/*',
        Authorization: localStorage.getItem('token') || '',
      },
      body,
    }).then((res) => {
      // 先单独加了401判断,原先本身是有的,后面不知道为啥删掉了这个,猜测可能是因为文件流的接口调用会影响
      if (res.status === 401) {
        if (localStorage.getItem('token')) {
          localStorage.setItem('token', '');

          Modal.warning({
            centered: true,
            content: '用户信息已过期，请重新登录！',
            okText: '重新登录',
            keyboard: false,
            onOk: () => {
              if (window.location.href.split('#')[1] === '/homePage') {
                window.location.reload();
              } else {
                store.dispatch(setUser(''));
                window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
              }
              localStorage.clear();
              localStorage.removeItem('rft');
              localStorage.removeItem('rftChanyeMax');
            },
            width: 416,
          });
        }
        return Promise.reject('Unauthorized.');
      }
      const datas = res.json().then((datas) =>
        datas,
      );
      return datas;
    }).catch((error) => ({ status: -1, message: '数据加载异常...' }));
  // }
  return result;
}

export const get = (url, body) => request('GET', url, body);
export const getSource = (url, body) => request('GET', url, body, true);
export const post = (url, body) => request('POST', url, body);
export const put = (url, body) => request('PUT', url, body);
export const del = (url, body) => request('DELETE', url, body);

// FormData
export const request_form = async ({method, uri, body, fixed}) => {
  const url = uri;
  method = method.toUpperCase();
  const formData = new FormData();
  for (const key in body) {
    formData.append(key, body[key]);
  }

  let header;

  if (fixed) {
    header = {
      Authorization: 'Basic TkpwNWdBZkZEV0ZLdUZWeXVGQ1A0QkJLOkNIc01FRVFXdjBZYkQ5bnRxdkU0R2ZNNTk1SlNQTDc3VkxDT2VpQmtmYnpjM3BhRw==',
    };
  }
  let result;
  await mutex.waitForUnlock();
  const maxTime = localStorage.getItem('maxTime');
  const nowTime = new Date().getTime();
  const token = localStorage.getItem('token');
  const rft = localStorage.getItem('rft');
  if (nowTime >= maxTime && !mutex.isLocked() && token && rft) {
    const release = await mutex.acquire();
    await refreshToken(release);
  } 
  // else {
    await mutex.waitForUnlock();
    result = await fetch(url, {
      method,
      mode: 'cors',
      headers: {
        Authorization: localStorage.getItem('token') || '',
        ...header,
      },
      body: formData,
    }).then((res) => {
      const datas = res.json().then((datas) => {
        if (datas.status === 'A0317' || datas.status === 'A0303' || datas.status === 'A0207' || datas.status === 'A0331') {
          if (localStorage.getItem('token')) {
            localStorage.setItem('token', '');

            Modal.warning({
              centered: true,
              content: '用户信息已过期，请重新登录！',
              okText: '重新登录',
              keyboard: false,
              onOk: () => {
                if (window.location.href.split('#')[1] === '/homePage') {
                  window.location.reload();
                } else {
                  store.dispatch(setUser(''));
                  window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
                }
                localStorage.clear();
                localStorage.removeItem('rft');
                localStorage.removeItem('rftChanyeMax');
              },
              width: 416,
            });
          }
          return Promise.reject('Unauthorized.');
        }
        return datas;
      });
      return datas;
    }).catch((error) => ({ status: -1, message: '数据加载异常...' }));
  // }
  return result;
};
export function requestFormXHR(options = {}, ) {
  const { url, method = 'GET', data = null, uploadProgress } = options;
  const token = localStorage.getItem('token');
  
  return new Promise((resolve, reject) => {
    const handler = (res) => {
      const { currentTarget } = res;
      const {readyState, status} = currentTarget;
      if(readyState === 4){ 
        if (status === 'A0317' || status === 'A0303' || status === 'A0207'|| status.status === 'A0331') {
          if (localStorage.getItem('token')) {
            localStorage.setItem('token', '');

            Modal.warning({
              centered: true,
              content: '用户信息已过期，请重新登录！',
              okText: '重新登录',
              keyboard: false,
              onOk: () => {
                if (window.location.href.split('#')[1] === '/homePage') {
                  window.location.reload();
                } else {
                  store.dispatch(setUser(''));
                  window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
                }
                localStorage.clear();
                localStorage.removeItem('rft');
                localStorage.removeItem('rftChanyeMax');
              },
              width: 416,
            });
          }
          reject('token失效');
        }else{
          resolve(currentTarget.response);
        }
      }
    };
    const xhr = new XMLHttpRequest ();
    xhr.open(method, url);
    xhr.upload.addEventListener('progress', uploadProgress)
    xhr.responseType = 'json';
    xhr.setRequestHeader('Authorization', token);
    xhr.onreadystatechange = handler;
    xhr.send(data);
  })
};
export const postForm = (url, body) => request_form({method:'POST', uri:url, body});

// 分析引擎新建报告
export const formData_request = async (method, uri, body) => {
  const url = uri;
  method = method.toUpperCase();
  const formData = new FormData();
  body.pic_list.forEach(item => {
    formData.append('pic_list', item, item.name);
  });
  delete body.pic_list;
  for (const key in body) {
    formData.append(key, body[key]);
  }

  let result;
  await mutex.waitForUnlock();
  const maxTime = localStorage.getItem('maxTime');
  const nowTime = new Date().getTime();
  const token = localStorage.getItem('token');
  const rft = localStorage.getItem('rft');
  if (nowTime >= maxTime && !mutex.isLocked() && token && rft) {
    const release = await mutex.acquire();
    await refreshToken(release);
  } 
  // else {
    await mutex.waitForUnlock();
    result = await fetch(url, {
      method,
      mode: 'cors',
      headers: {
        Authorization: localStorage.getItem('token') || '',
      },
      body: formData,
    }).then((res) => {
      const datas = res.json().then((datas) => {
        if (datas.status === 'A0317' || datas.status === 'A0303' || datas.status === 'A0207'|| datas.status === 'A0331') {
          if (localStorage.getItem('token')) {
            localStorage.setItem('token', '');
            Modal.warning({
              centered: true,
              content: '用户信息已过期，请重新登录！',
              okText: '重新登录',
              keyboard: false,
              onOk: () => {
                if (window.location.href.split('#')[1] === '/homePage') {
                  window.location.reload();
                } else {
                  store.dispatch(setUser(''));
                  window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
                }
                localStorage.clear();
                localStorage.removeItem('rft');
                localStorage.removeItem('rftChanyeMax');
              },
              width: 416,
            });
          }
          return Promise.reject('Unauthorized.');
        }
        return datas;
      });
      return datas;
    }).catch((error) => ({ status: -1, message: '数据加载异常...' }));
  // }
  return result;
};

export const formDataRequestPost = (url, body) => formData_request('POST', url, body);

export const request_binary = (method, uri, body) => {
  let url = uri;
  method = method.toUpperCase();
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key.toString()}=${body[key].toString()}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  return fetch(url, {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/octet-stream,text/plain,*/*',
      Authorization: localStorage.getItem('token') || '',
    },
    body,
  }).then(res => {
    if (res.status === 401) {
      if (localStorage.getItem('token')) {
        localStorage.setItem('token', '');
        Modal.warning({
          centered: true,
          content: '用户信息已过期，请重新登录！',
          okText: '重新登录',
          keyboard: false,
          onOk: () => {
            if (window.location.href.split('#')[1] === '/homePage') {
              window.location.reload();
            } else {
              store.dispatch(setUser(''));
              window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
            }
            localStorage.clear();
            localStorage.removeItem('rft');
            localStorage.removeItem('rftChanyeMax');
          },
          width: 416,
        });
      }
      return Promise.reject('Unauthorized.');
    }
    return res.arrayBuffer();
  });
};

export const protoGet = (url, body) => request_binary('GET', url, body);
export const protoPost = (url, body) => request_binary('POST', url, body);

export const requestFile = async (method, uri, body, filename, requestMsg, header, text = '文件下载中...') => {
  message.loading(text, 0);
  let url = uri;
  method = method.toUpperCase();
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key.toString()}=${body[key].toString()}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  let result;
  await mutex.waitForUnlock();
  const maxTime = localStorage.getItem('maxTime');
  const nowTime = new Date().getTime();
  const token = localStorage.getItem('token');
  const rft = localStorage.getItem('rft');
  if (nowTime >= maxTime && !mutex.isLocked() && token && rft) {
    const release = await mutex.acquire();
    await refreshToken(release);
  } 
  // else {
    await mutex.waitForUnlock();
    result = await fetch(url, {
      method,
      mode: 'cors',
      headers: {
        Accept: 'application/json,text/plain,*/*',
        Authorization: localStorage.getItem('token') || '',
        ...header,
      },
      body,
    }).then((res) => {
      if (res.status === 401) {
        if (localStorage.getItem('token')) {
          localStorage.setItem('token', '');
          Modal.warning({
            centered: true,
            content: '用户信息已过期，请重新登录！',
            okText: '重新登录',
            keyboard: false,
            onOk: () => {
              if (window.location.href.split('#')[1] === '/homePage') {
                window.location.reload();
              } else {
                store.dispatch(setUser(''));
                window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
              }
              localStorage.clear();
              localStorage.removeItem('rft');
              localStorage.removeItem('rftChanyeMax');
            },
            width: 416,
          });
        }
        return Promise.reject('Unauthorized.');
      }
      if (res.status === 402) {
        return res.json();
      }
      if (requestMsg) {
        return res.json();
      }
      res.blob().then((blob) => {
        // const fileNameEncode = res.headers.get('content-disposition').split('filename=b')[1];
        // const _fileName = utf8_decode(fileNameEncode);
        // console.log(_fileName);
        // const objectUrl = window.URL.createObjectURL(blob);
        // var win = window.open(objectUrl, '_blank');
        // win.focus();
        // return;

        // document.open();
        // document.write(blob);
        // document.close();
        // return;

        const aLink = document.createElement('a');
        document.body.appendChild(aLink);
        aLink.style.display = 'none';
        const objectUrl = window.URL.createObjectURL(blob);
        aLink.href = objectUrl;
        aLink.download = filename;
        aLink.click();
        document.body.removeChild(aLink);
        message.destroy();
        message.success('下载成功！', 1);
      }).catch(err => {
        message.warning('下载失败！', 1);
      });
    });
  // }
  return result;
};

export const request_html = (method, uri, body) => {
  let url = uri;
  method = method.toUpperCase();
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key.toString()}=${body[key].toString()}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      Accept: 'application/json,text/plain,*/*',
      Authorization: localStorage.getItem('token') || '',
    },
    body,
  }).then((res) => {
    if (res.status === 401) {
      if (localStorage.getItem('token')) {
        localStorage.setItem('token', '');
        Modal.warning({
          centered: true,
          content: '用户信息已过期，请重新登录！',
          okText: '重新登录',
          keyboard: false,
          onOk: () => {
            if (window.location.href.split('#')[1] === '/homePage') {
              window.location.reload();
            } else {
              store.dispatch(setUser(''));
              window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
            }
            localStorage.clear();
            localStorage.removeItem('rft');
            localStorage.removeItem('rftChanyeMax');
          },
          width: 416,
        });
      }
      return Promise.reject('Unauthorized.');
    } if (res.status === 402) {
      return res.json();
    }
    res.blob().then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob);
      const win = window.open(objectUrl, '_blank');
      win.focus();
    });
  });
};

export const request_catpcha = (method, uri, body) => {
  let url = uri;
  method = method.toUpperCase();
  if (method === 'GET') {
    const a = [];
    for (const key in body) {
      a.push(`${key.toString()}=${body[key].toString()}`);
    }
    if (a.length > 0) {
      url += `?${a.join('&')}`;
    }
    body = undefined;
  } else {
    body = body && JSON.stringify(body);
  }

  return fetch(url, {
    method,
    mode: 'cors',
    headers: {
      Accept: 'application/json,text/plain,*/*',
    },
    body,
  }).then((res) => {
    if (res.status === 401) {
      if (localStorage.getItem('token')) {
        localStorage.setItem('token', '');
        Modal.warning({
          centered: true,
          content: '用户信息已过期，请重新登录！',
          okText: '重新登录',
          keyboard: false,
          onOk: () => {
            if (window.location.href.split('#')[1] === '/homePage') {
              window.location.reload();
            } else {
              store.dispatch(setUser(''));
              window.location.href = `${window.location.href.split('#')[0]}#/homePage`;
            }
            localStorage.clear();
            localStorage.removeItem('rft');
            localStorage.removeItem('rftChanyeMax');
          },
          width: 416,
        });
      }
      return Promise.reject('Unauthorized.');
    }
    return res;
  });
};

export const url_has_key = (props, key) => {
  const { search } = props.history.location;
  if (search.length > 0 && search[0] === '?') {
    const kvList = search.substr(1).split('&');
    for (let i = 0; i < kvList.length; ++i) {
      const kv = kvList[i].split('=');
      if (kv.length === 2 && kv[0] === key) {
        return true;
      }
    }
  }
  return false;
};

export const url_get_value = (props, key, defaultValue) => {
  const { search } = props.history.location;
  if (search.length > 0 && search[0] === '?') {
    const kvList = search.substr(1).split('&');
    for (let i = 0; i < kvList.length; ++i) {
      const kv = kvList[i].split('=');
      if (kv.length === 2 && kv[0] === key) {
        return kv[1];
      }
    }
  }
  return defaultValue;
};

export const url_get_int = (props, key, defaultValue = 0) => {
  const { search } = props.history.location;
  if (search.length > 0 && search[0] === '?') {
    const kvList = search.substr(1).split('&');
    for (let i = 0; i < kvList.length; ++i) {
      const kv = kvList[i].split('=');
      if (kv.length === 2 && kv[0] === key) {
        return parseInt(kv[1]);
      }
    }
  }
  return defaultValue;
};

export const form_url = (uri, obj) => {
  const a = [];
  for (const key in obj) {
    a.push(`${key.toString()}=${obj[key].toString()}`);
  }
  if (a.length > 0) {
    return `${uri}?${a.join('&')}`;
  }
  return uri;
};

export const getUserId = () => {
  const userId = localStorage.getItem('star_user_id');
  if (userId) {
    return userId;
  }
  return 0;
};
