/*
 *Description: TimeLine
 *Date: Mon Jan 10 2022 11:11:22 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import { TimeLineChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas } from '../../utils/tool';
import { modeStyle } from './style';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-time-line-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const TimeLineChart = forwardRef((props: TimeLineChartProps, ref: any) => {
  const { mode, data = [] } = props;
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setChartLoading(false);
  }, [data]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current;
      return html2canvas(instance as HTMLElement).then(
        (canvas) => (canvas),
      ).catch(ex => {
        console.log('normalChart', ex, pixelRatio);
        return document.createElement('canvas');
      }).then(canvas => addWaterMark(canvas, devicePixelRatio));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.scrollHeight + 20,
      };
    },
  }));

  const lineStyle = {
    position: 'absolute',
    width: 1,
    top: 0,
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    background: '#A3C2D2',
  };
  const circleStyle = {
    boxSizing: 'border-box',
    position: 'absolute',
    top: 16,
    left: '50%',
    transform: 'translate(-50%)',
    width: 8,
    height: 8,
    background: '#FFFFFF',
    border: '1px solid #0D3B66',
    borderRadius: '50%',
  };
  const yearStyle = {
    fontSize: 14,
    lineHeight: '14px',
    color: '#0D3B66',
  };
  const descStyle = {
    fontSize: 12,
    color: '#333',
    transform: `scale(${10 / 12})`,
    transformOrigin: 'top left',
    width: `${100 * (12 / 10)}%`,
    wordBreak: 'break-all',
  };
  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <div style={{ flex: 1 }} ref={chartRef}>
        {
          data.map && data.map((item: any, index: number) => {
            const rowStyle = {
              position: 'relative',
              boxSizing: 'border-box',
              padding: '0px 5px 0 5px',
              textAlign: index % 2 ? 'left' : 'right',
              // marginTop: -30,
            };
            const innerRowStyle = {
              boxSizing: 'border-box',
              display: 'inline-block',
              width: '50%',
              [(index % 2) ? 'paddingRight' : 'paddingLeft']: 10,
              textAlign: !(index % 2) ? 'left' : 'right',
            };
            const jsx = (
              <div key={index} style={rowStyle as any}>
                <div style={innerRowStyle as any}>
                  <div style={yearStyle}>
                    {item.year}
                    年
                  </div>
                  <div style={descStyle as any}>{item.desc}</div>
                </div>
                <div style={lineStyle as any}>
                  <div style={circleStyle as any} />
                </div>
              </div>
            );
            return jsx;
          })
        }
      </div>
    </div>);
});

export default TimeLineChart;

TimeLineChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
  data: [],
};
