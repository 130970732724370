/*
* Author: zhangmeng
* Description: '专利列表一行'
* Date: 2022/06/29
*/

import React, { useCallback } from 'react';
import TabsComp from '../TabsComp';
import style from './index.module.less';
import { openNewPage } from '../../../../utils/urlMethods';

function PatentListLine(props) {
  const { record, participle } = props;

  const highLightWord = useCallback((value) => {
    if (!value || !participle?.length) return value;
    let word = value;
    participle.forEach(item => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(item)) {
        word = word.split(item).join(`<span style="color: #D70000;">${item}</span>`);
      } else {
        const arr = word.split(item);
        const last = arr.pop();
        word = `${arr.join(`<span style="color: #D70000;">${item}</span>`)}${last}`;
      }
    });
    return word;
  }, [participle]);

  return (
    <div className={style.all} onClick={() => openNewPage('patentDetail', { id: record.id })}>
      <div className={style.topLine}>
        <div className={style.left}>
          <div
            className={style.title}
            title={record.title}
            style={{
              maxWidth: `calc(100% - ${record.patent_score > 0 ? '60px' : '0px'})`,
            }}
            dangerouslySetInnerHTML={{
              __html: highLightWord(record.title),
            }}
          />
          {
            record.patent_score > 0 && (
              <div className={style.score}>
                {record.patent_score}
                &nbsp;
                <span className={style.unit}>分</span>
                <div className={style.bar} />
              </div>
            )
          }
        </div>
        <div className={style.right}>
          <div className={style.row}>
            <span>申请日：</span>
            <span>{record.apply_date}</span>
          </div>
          <div className={style.row}>
            <span>公告日：</span>
            <span>{record.publish_date}</span>
          </div>
        </div>
      </div>
      <div className={style.bottom}>
        <TabsComp labels={record.tabs} />
      </div>
    </div>
  );
}

export default PatentListLine;
