/*
 *Description: 漏斗图样式文件
*/
import * as CONSTS from '../../consts';

const chartFontSize = {
  small: {
    bodyWidth: 52,
    bodyFontSize: 10,
    numFontSize: 14,
    descFontSize: 8,
    descLeft: 6,
    descTop: 4,
    bodyLeft: 2,
  },
  middle: {
    bodyWidth: 60,
    bodyFontSize: 12,
    numFontSize: 16,
    descFontSize: 10,
    descLeft: 10,
    descTop: 8,
    bodyLeft: 5,
  },
  large: {
    bodyWidth: 66,
    bodyFontSize: 14,
    numFontSize: 20,
    descFontSize: 11,
    descLeft: 18,
    descTop: 12,
    bodyLeft: 8,
  },
};
const modeStyle = {
  light: {
    colorList: ['#ABE7DB', '#C8EDE4', '#CCE3DE', '#A4C3B2', '#89AFD2', '#6591B9', '#4E7293'],
    colorList1: ['#ABE7DB', '#C8EDE4', '#CCE3DE', '#A4C3B2', '#89AFD2', '#6591B9', '#4E7293'],
    mainOffsetColor0: '#fff',
    offsetColor0: '#fff',
    offsetColor1: '#EAF3FF',
    titleColor: '#333',
    numColor: '#333',
    percentColor: '#4E7293',
    tipMainColor: '#3E5C76',
    tipUnitColor: '#262D36',
    borderObj: {
      borderTop: '1px solid #DCDCDC',
      borderRight: '1px solid #DCDCDC',
      borderBottom: '1px solid #DCDCDC',
      borderLeft: '1px solid #DCDCDC',
    },
    backgC: '#fff',

    labelColor: '#0D3B66',
    labelBgdColor: '#E2EEFF',
    labelBorderColor: '#C5D5EA',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',

    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    middleColor: '#fff',
  },
  dark: {
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    colorList: ['#00F2FE', '#24B2BB', '#0083FF', '#1B02FD'],
    colorList1: ['rgba(0,0,0,0)', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)', 'rgba(0,0,0,0)'],

    mainOffsetColor0: '#03001E',
    offsetColor0: '#030061',
    offsetColor1: '#03001E',
    titleColor: '#fff',
    numColor: '#B4B4B4',
    percentColor: '#B4B4B4',
    tipMainColor: '#fff',
    tipUnitColor: '#B4B4B4',
    borderObj: {
      borderTop: 'none',
      borderRight: '2px solid #1C16F4',
      borderBottom: 'none',
      borderLeft: '2px solid #1C16F4',
    },
    backgC: '#030061',
    middleColor: '#03001E',

    labelColor: '#fff',
    labelBgdColor: 'rgba(27, 2, 253, 0.3)',
    labelBorderColor: 'transparent',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
  },
};

export {
  chartFontSize,
  modeStyle,
};
