import { AnyDataSource, getByPath } from '@sqke/parser';
import {
  formatURL,
  isDataSourceLegal,
} from '@sqke/utils';
import type { ComponentType, ReactElement } from 'react';
import { useAnyDataSourceQuery } from '../services/data';

export interface WithFetchProps {
  dataConfig: {
    dataSource: AnyDataSource;
    dataMapping: Record<string, string>;
    [key: string]: any;
  };
  renderOnFetching?: (item: any, fetchingState: any) => React.ReactElement;
  [key: string]: any;
}

export function withFetch<T extends WithFetchProps>(WrappedComponent: ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const Component = (props: T) => {
    const { dataConfig, skip } = props;
    const { dataSource, dataMapping } = dataConfig ?? {};
    /**
     * 根据定义的数据获取方式，获取数据
     */
    const fetchingState = useAnyDataSourceQuery(
      formatURL(dataSource),
      { skip: skip || !dataSource || !isDataSourceLegal(dataSource) || dataSource?.type === 'local', },
    );
    const {
      isFetching,
      currentData,
      isError,
    } = fetchingState;

    let data: Record<string, any> | undefined = undefined;
    if (dataSource) {
      // 如果设置了 type=local，那么直接取 scheme 中提供的数据；当数据不存在或者 type !== local，则取数据源的数据
      if (dataSource.type !== 'local' || (dataSource?.type === 'local' && !dataSource.data)) {
        if (currentData) {
          data = currentData;
        }
      } else {
        data = dataSource;
      }

      if (data && dataMapping && typeof dataMapping === 'object') {
        const _data: Record<string, any> = {};
        const kvs = Object.entries(dataMapping);
        kvs.forEach(([k, v]) => {
          try {
            _data[k] = getByPath(data as Record<string, any>, v || k);
          } catch (e) {
            console.warn('error when mapping from data');
          }
        });

        data = { ...data, ..._data };
      }
    }

    // 如果该函数返回 undefined，则继续执行，否则渲染返回值
    const { renderOnFetching } = props;
    if (renderOnFetching) {
      const nextElement = renderOnFetching(props, fetchingState);
      if (nextElement !== undefined) {
        return nextElement;
      }
    }

    if (!renderOnFetching && isFetching) {
      return (
        <div
          style={{
            ...(props.style ? props.style : {}),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 40,
            textAlign: 'center',
          }}
        >
          数据加载中……
        </div>
      )
    }

    if (!renderOnFetching && isError) {
      return (
        <div
          style={{
            ...(props.style ? props.style : {}),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 40,
            overflow: 'hidden',
          }}
        >
          <span style={{ overflow: 'hidden', wordBreak: 'break-all', whiteSpace: 'pre-wrap', textAlign: 'left' }}>
            {`数据请求错误！`}
          </span>
        </div>
      )
    }

    return <WrappedComponent {...({ ...props, isFetching, data } as T)} />;
  };

  Component.displayName = `withFetch(${displayName})`;

  return Component;
}
