import React, { useMemo, useState } from 'react';
import { InputNumber, Button, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { useFindDownloadRemainingNumberQuery, useAdministratorPermissionQuery } from '../../service/enterprise-export';
import { dataDownload, administratorsDataDownload } from '../../api/mySelf';

function ExportModal({ onClose, dataKey, params, filename = '导出' }) {
  const [minNum, setMinNum] = useState(1);
  const [maxNum, setMaxNum] = useState('');
  const [minStatus, setMinStatus] = useState('');
  const [maxStatus, setMaxStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [normal, setNormal] = useState(true);

  const { data: surplus, isFetching } = useFindDownloadRemainingNumberQuery();
  const { data: administratorDownload } = useAdministratorPermissionQuery();
  const menu = administratorDownload?.menu?.[0].value;
  const onExport = async () => {
    setNormal(false);
    setLoading(true);
    console.log(params);
    if (menu === '1') {
      await administratorsDataDownload({
        // data_key: dataKey,
        params,
        start_index: minNum,
        end_index: maxNum,
      }, filename);
    } else {
      if (!minNum || !maxNum) return;
      if (maxNum && minNum && maxNum - minNum + 1 > surplus?.remaining) return;
      if (!dataKey || !params) return;
      await dataDownload({
        data_key: dataKey,
        params,
        start_index: minNum,
        end_index: maxNum,
      }, filename);
    }

    setLoading(false);
    onClose();
  };

  const switchDate = (cycle) => {
    let text;
    switch (cycle) {
      case 'day':
        text = '日';
        break;
      case 'month':
        text = '月';
        break;
      case 'year':
        text = '年';
        break;
      default: break;
    }
    return text;
  };

  return (
    <div className={styles.modal}>
      <div className={styles.title}>
        <span>
          <span>数据导出</span>
          {
            menu !== '1' && <span className={styles.subtitle}>全平台共享导出条数</span>
          }
        </span>
        <CloseOutlined onClick={onClose} />
      </div>
      <div className={styles.content}>
        {
          (!minNum || !maxNum) && !normal && <div className={styles.error}>·请输入导出范围</div>
        }
        <div className={styles.inputs}>
          导出
          <InputNumber
            className={styles.inputNumber}
            status={minStatus}
            min={1}
            max={maxNum}
            precision={0}
            value={minNum}
            placeholder="请输入"
            onChange={(val) => {
              if (!val) {
                setMinStatus('error');
              } else {
                setMinStatus('');
              }
              setNormal(false);
              setMinNum(val);
            }}
          />
          一
          <InputNumber
            className={styles.inputNumber}
            status={maxStatus}
            min={minNum}
            precision={0}
            value={maxNum}
            placeholder="请输入"
            onChange={(val) => {
              if (!val) {
                setMaxStatus('error');
              } else {
                setMaxStatus('');
              }
              setNormal(false);
              setMaxNum(val);
            }}
          />
        </div>
        <div className={styles.nums}>
          {
            maxNum && minNum && (
              <div className={styles.left}>
                本次导出
                <span className={styles.num}>{maxNum - minNum + 1}</span>
                条
              </div>
            )
          }
          {
            menu !== '1' && (
              <>
                <div className={styles.right}>
                  本
                  {switchDate(surplus?.cycle)}
                  剩余
                  <span className={styles.num}>{surplus?.remaining}</span>
                  条
                </div>
              </>
            )
          }
        </div>
        <div className={styles.buttons}>
          <Button onClick={onClose}>取消</Button>
          <Button loading={isFetching || loading} className={styles.export} onClick={() => onExport()}>确定导出</Button>
        </div>
        {
          useMemo(() => maxNum && minNum && maxNum - minNum + 1 > surplus?.remaining && menu !== '1' && (
            <div className={styles.bottom}>
              全平台共享导出额度不足，本月还可导出
              {surplus?.remaining}
              条
            </div>
          ), [maxNum, minNum, surplus])
        }
      </div>
    </div>
  );
}

export default ExportModal;
