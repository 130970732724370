import React from 'react';
import classNames from 'classnames';
import style from './index.module.less';

const LabelList = ({ title, list, active, setActive, setIsConfirm }) => (
  <div className={style.labelList}>
    <div className={style.point} />
    <span>
      {title}
    </span>
    <div className={style.list}>
      {
        list?.map(item => (
          <div
            key={item.title}
            className={classNames(style.tag, { [style.active]: item.key === active })}
            onClick={() => {
              setIsConfirm(true);
              // eslint-disable-next-line no-unused-expressions
              setActive && setActive(params => {
                if (params === item.key) {
                  setActive('');
                  return;
                }
                setActive(item.key);
              });
            }}
          >
            {item.title}
          </div>
        ))
      }
    </div>
  </div>
);

export default LabelList;
