import React, { useMemo, useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import html2pdf from 'html2pdf.js';
import Header from './Header';
import Content from './Content';
import { useGetTestRecordDetailQuery } from '../../../../../service/tool';
import style from './index.module.less';

const EvaluationResult = () => {
  const { href } = window.location;
  const boxEl = useRef();
  const routeParams = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);
  const id = routeParams.get('id');
  const companyId = routeParams.get('companyId');
  const { data } = useGetTestRecordDetailQuery({ record_id: id });
  const [download, setDownload] = useState(0);
  useEffect(() => {
    if (data) {
      localStorage.setItem('formData', JSON.stringify(data.appraisal_content));
    }
  }, [data]);

  const downloadPdf = () => {
    if (download) {
      return;
    }
    message.loading('下载中');
    setDownload(1);
    html2pdf(boxEl.current, {
      margin: 10,
      filename: `${data?.company_name}测评结果.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { dpi: 300, letterRendering: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }).then(() => {
      setDownload(0);
      message.success('下载完成');
    }).catch(() => {
      setDownload(0);
    });
  };
  return (
    <div className={style.box}>
      <div
        className={style.content}
        ref={boxEl}
      >
        {
          data && (
            <>
              <Header id={id} data={data} companyId={companyId} downloadPdf={downloadPdf} />
              <Content id={id} data={data} boxEl={boxEl} />
            </>
          )
        }
        {/* <div className={style.rect}>
        <div></div>
      </div> */}
      </div>
      {/* <EvaluationResult /> */}
      <div className={style.download}>
        <button type="button" onClick={downloadPdf}>
          导出PDF报告
        </button>
      </div>
    </div>
  );
};

export default EvaluationResult;
