export const isArrayMultiDimensional = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  }
  for (let i = 0; i < arr.length; i += 1) {
    if (Array.isArray(arr[i])) {
      return true; // 数组中存在子数组，说明是多维数组
    }
  }

  return false; // 数组中没有子数组，说明是一维数组
};

export const onFlatArray = (arr) => {
  const array = (arr || []).flat();
  return array;
};
