import React, { useEffect, useMemo } from 'react';
import { Table } from '@sqke/design';
import style from './index.module.less';
import SortTitle from '../../../../../Industry/components/SortTitle';

/**
 * @Tablehandle columns自定义部分对象
 * @data 表格数据
 * @isFetching logding
 * @pagination 分页配置
 * @sortedInfo 排序使用自带排序时用到
 * @onChange 顶部筛选点击事件
 * @filtrate 筛选内容
 * @sortKey 排序字段
 * @sortValue 升序 || 降序
*/
function CompanyTable(props) {
  const {
    sortKey,
    sortValue,
    Tablehandle,
    selectTypeDict,
    data,
    isFetching,
    pagination,
    onChange,
    regionTree,
    companyTags,
    filtrate,
    className,
  } = props;
  const { columns = [], dataSource } = data ?? {};
  const _columns = useMemo(() => columns?.map(item => {
    if (Tablehandle()[item.dataIndex]) {
      const initialColumns = {
        title: item.title,
        dataIndex: item.dataIndex,
        key: item.dataIndex,
        ...Tablehandle(item)[item.dataIndex],
      };
      if (initialColumns.sorter) {
        if (item.dataIndex === sortKey) {
          initialColumns.sortOrder = sortValue;
        } else {
          initialColumns.sortOrder = undefined;
        }
        initialColumns.title = () => (
          <SortTitle
            name={item.title}
            {...initialColumns}
          />
        );
      }
      return initialColumns;
    }
    return {
      title: item.title,
      dataIndex: item.dataIndex,
      key: item.dataIndex,
      ellipsis: true,
    };
  }), [columns, regionTree, companyTags, filtrate, sortKey, sortValue, selectTypeDict]);
  return (
    <div className={style.table}>
      <Table
        rowKey="index"
        className={className}
        columns={_columns}
        dataSource={dataSource}
        locale={{ emptyText: () => {
          if (!dataSource) {
            return '';
          }
          return '暂无此类数据';
        } }}
        loading={isFetching}
        responsive
        pagination={pagination}
        onChange={onChange && onChange}
      />
    </div>
  );
}

export default CompanyTable;
