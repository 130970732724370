import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import { useGetCompanyStandardDraftingIssueQuery } from '../../../../../../service/companyDetail';
// import TableNoData from '../../../TableNoData';
import { openNewPage } from '../../../../../../utils/urlMethods';
import style from './index.module.less';

function AnnotateDetails(props) {
  const { companyId, preventPageto } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: isTableFetching } = useGetCompanyStandardDraftingIssueQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  const columns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'ch_name' && !preventPageto) {
        return {
          ...item,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => {
                if (record.operation) {
                  openNewPage('standardDetail', {
                    id: record.operation,
                  });
                }
              }}
            >
              {text}
            </div>
          ),
        };
      }
      return {
        ...item,
      };
    })
  ), [tableData, preventPageto]);

  const tableEl = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      <Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={columns}
        title={() => (
          <>
            <span>{tableData?.title}</span>
            <span className={style.count}>{tableData?.count}</span>
          </>
        )}
        strip
        loading={isTableFetching}
        pagination={{
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />;
    }
    return null;
  }, [tableData]);

  return tableEl;
}

export default AnnotateDetails;
