import { get, post } from '../utils/request';
import { ANALYSISENGINE, DATASEARCH, INTERACTION } from '../utils/url';

// 分析引擎-全部产业
const getAllIndustry = param => post(`${INTERACTION}/search_industry_tree`, {
  ...param,
});

// 分析引擎-全部区域
const getAllCity = param => post(`${DATASEARCH}/search_city_tree`, {
  ...param,
});

export {
  getAllCity,
  getAllIndustry,
};
