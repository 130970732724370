import { getLabel, ITreeStyle, ITreeVisualConfig, omitUndefined } from '../lib/echarts-option';

export interface INode {
  [index: string | symbol]: any;
  children?: INode[];
}
export type IPicker = (node: INode, extra: { depth: number; parent: INode | null, index: number }) => INode;
type Iterator = (root: INode[], depth: number, parent: null | INode) => INode[];
type IteratorGenerator = (picker: IPicker) => Iterator;

/**
 * 递归
 */
export const iteratorGenerator: IteratorGenerator = (picker) => {
  const iterator: Iterator = (forest, depth, parent) => {
    if (!Array.isArray(forest) || forest.length === 0) {
      return [];
    }
    return forest.map((node, index) => {
      const { children } = node;
      let _children: typeof children = [];
      const _node = picker(node, { depth, parent, index });
      if (Array.isArray(children)) {
        _children = iterator(children, depth + 1, node);
      }
      return { ..._node, children: _children };
    });
  };

  return iterator;
};

type colorHandler = (item: any, parent: any) => any;
export const getParentColor: colorHandler = (itemStyle, parentItemStyle) => {
  if (!parentItemStyle) {
    return itemStyle;
  }
  if (!itemStyle) {
    return itemStyle;
  }
  return omitUndefined({
    ...itemStyle,
    color: itemStyle.color === 'parent' ? parentItemStyle.color : itemStyle.color,
    borderColor: itemStyle.borderColor === 'parent' ? parentItemStyle.borderColor : itemStyle.borderColor,
  });
};

/**
 * 获取单个节点的样式
 * @param config
 * @param parentStyle
 * @param commonStyle
 */
export const getEachNode = (config: ITreeVisualConfig, {
  parentStyle,
  commonStyle,
}: { parentStyle: ITreeStyle, commonStyle: ITreeStyle, }) => {
  const {
    itemStyle,
    lineStyle,
  } = config ?? {};

  return ({
    ...config,
    itemStyle: getParentColor({ ...(commonStyle?.itemStyle ?? {}), ...itemStyle }, parentStyle?.itemStyle),
    lineStyle: getParentColor({ ...(commonStyle?.lineStyle ?? {}), ...lineStyle }, parentStyle?.lineStyle),
  });
};
