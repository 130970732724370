/*
 *Author: zhaochenyu
 *Description: 区域画像预览报告首页
 *Date: 2021/11/17
*/

import React from 'react';
import style from './index.module.less';

function FrontCover(props) {
  const { area, industry } = props;
  const myDate = new Date();
  return (
    <div className={style.all}>
      <div className={style.yanjiubaogao} />
      <div className={style.area}>{area}</div>
      <div className={style.industry}>{industry}</div>
      <div className={style.desc}>
        高新技术企业画像
      </div>
      <div className={style.companyName}>
        {`${myDate.getFullYear()}年${myDate.getMonth() + 1}月`}
      </div>
    </div>
  );
}

export default FrontCover;
