/*
 *Author: zhaochenyu
 *Description: 动态监测-快速关注企业
 *Date: 2022/04/19
*/

import React, { useState, useCallback, useRef } from 'react';
import { Input, Pagination, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { IconFont } from '../../../../utils/mapIcon';
import FocusDetail from '../../../IndustryCompanyDetail/DetailTop/ToolBox/FocusDetail';
import { addAttentionData, findAttentionGroupList } from '../../../../api/companyDetail';
import { getSearchCompany } from '../../../../api/mySelf';
import style from './index.module.less';

// const data1 = {
//   data_list: [
//     {
//       name: '浙江大华技术股份有限公司',
//       id: '5649382b426b5976686e3245676f42585570637075773d3d0a',
//       attention: false,
//       highlight: ['大华'],
//     },
//     {
//       name: '金发科技股份有限公司',
//       id: '73417a41727a37714d6c4366523367484838797961413d3d0a',
//       attention: false,
//       highlight: ['科技'],
//     },
//     {
//       name: '中航光电科技股份有限公司',
//       id: '3857624946624d726c6e6b58777271776f69354e4c673d3d0a',
//       attention: true,
//       highlight: ['中'],
//     },
//     {
//       name: '浙江大华技术股份有限公司',
//       id: '5649382b426b5976686e3245676f42585570637075773d3d0a',
//       attention: false,
//       highlight: ['大华'],
//     },
//     {
//       name: '金发科技股份有限公司',
//       id: '73417a41727a37714d6c4366523367484838797961413d3d0a',
//       attention: false,
//       highlight: ['科技'],
//     },
//     {
//       name: '中航光电科技股份有限公司',
//       id: '3857624946624d726c6e6b58777271776f69354e4c673d3d0a',
//       attention: true,
//       highlight: ['中'],
//     },
//     {
//       name: '浙江大华技术股份有限公司',
//       id: '5649382b426b5976686e3245676f42585570637075773d3d0a',
//       attention: false,
//       highlight: ['大华'],
//     },
//     {
//       name: '金发科技股份有限公司',
//       id: '73417a41727a37714d6c4366523367484838797961413d3d0a',
//       attention: false,
//       highlight: ['科技'],
//     },
//     {
//       name: '中航光电科技股份有限公司',
//       id: '3857624946624d726c6e6b58777271776f69354e4c673d3d0a',
//       attention: true,
//       highlight: ['中'],
//     },
//     {
//       name: '浙江大华技术股份有限公司',
//       id: '5649382b426b5976686e3245676f42585570637075773d3d0a',
//       attention: false,
//       highlight: ['大华'],
//     },
//     {
//       name: '金发科技股份有限公司',
//       id: '73417a41727a37714d6c4366523367484838797961413d3d0a',
//       attention: false,
//       highlight: ['科技'],
//     },
//     {
//       name: '中航光电科技股份有限公司',
//       id: '3857624946624d726c6e6b58777271776f69354e4c673d3d0a',
//       attention: true,
//       highlight: ['中'],
//     },
//     {
//       name: '浙江大华技术股份有限公司',
//       id: '5649382b426b5976686e3245676f42585570637075773d3d0a',
//       attention: false,
//       highlight: ['大华'],
//     },
//     {
//       name: '金发科技股份有限公司',
//       id: '73417a41727a37714d6c4366523367484838797961413d3d0a',
//       attention: false,
//       highlight: ['科技'],
//     },
//     {
//       name: '中航光电科技股份有限公司',
//       id: '3857624946624d726c6e6b58777271776f69354e4c673d3d0a',
//       attention: true,
//       highlight: ['中'],
//     },
//   ],
//   count: 12300,
// };

const { Search } = Input;

function FocusCompany(props) {
  const { setShowAddCompany, getMonitorList } = props;
  const [companyList, setCompanyList] = useState({});
  const [inputText, setInputText] = useState('');
  const [focusData, setFocusData] = useState([]); // 关注分组列表
  const [focusGroupShow, setFocusShow] = useState(false);
  const [curPagination, setCurPagination] = useState({ pageNum: 1, pageSize: 15 });
  const companyDetail = useRef(null);
  const curCondition = useRef({
    page_number: 1,
    page_size: 15,
    keyword: '',
  });

  const getCompanyList = () => {
    getSearchCompany(curCondition.current).then(res => {
      if (res.status === '00000') {
        setCompanyList(res.data);
      }
    });
  };

  const hanldleCondition = useCallback((obj) => {
    curCondition.current = {
      ...curCondition.current,
      ...obj,
    };
  }, []);

  const searchVagueCompany = (searchValue) => {
    if (searchValue.trim()) {
      hanldleCondition({
        keyword: searchValue.trim(),
      });
      getCompanyList();
    }
  };
  const handleHighWord = useCallback((word) => {
    if (word.highlight.length === 0) return word.name;
    let res = word.name;
    word.highlight.forEach(item => {
      if (item.trim()) {
        res = res.split(item).join(`<span style="color: #FF0101; font-weight: bold">${item}</span>`);
      }
    });
    return res;
  }, []);

  const paginationChange = (pageNumber, pageSize) => {
    setCurPagination({
      pageNum: pageNumber,
      pageSize,
    });
    hanldleCondition({
      page_number: pageNumber,
      page_size: pageSize,
    });
    getCompanyList();
  };

  const getFocusList = () => {
    findAttentionGroupList({
      attention_data_type_name: '企业',
      attention_data_id: companyDetail.current.id,
    }).then(res => {
      if (res.status === '00000') {
        const { group_list: list } = res.data;
        setFocusData(list);
      }
    });
  };

  const showCompanyFocusGroup = useCallback((companyMsg) => {
    companyDetail.current = companyMsg;
    setFocusShow(true);
    getFocusList();
  }, []);

  const focusDetailEvent = (type, params) => {
    switch (type) {
      case 'confirm':
        addAttentionData({
          attention_data_type_name: '企业',
          attention_data_id: companyDetail.current.id,
          attention_group_id_list: params,
          is_empty_original_data: true,
        }).then(res => {
          if (res.status === '00000') {
            if (params.length) {
              message.success('关注成功！');
              // 刷新当前企业列表
              getCompanyList();
              // 刷新动态监测
              getMonitorList();
            } else {
              const config = {
                className: style.cancelFocus,
                content: (
                  <span className={style.box}>
                    您已取消对&emsp;
                    <span className={style.name}>{companyDetail.current.name}</span>
                    &emsp;的关注
                  </span>
                ),
                duration: 2,
              };
              message.info(config);
            }
            getCompanyList();
            getFocusList();
          }
        });
        break;
      case 'cancel':
        break;
      default:
        break;
    }
    setFocusShow(false);
    setFocusData([]);
  };

  return (
    <div className={style.all}>
      <header className={style.header}>
        关注企业
        <div className={style.close} onClick={() => setShowAddCompany(false)}>X</div>
      </header>
      <div className={style.searchInput}>
        <Search
          placeholder="请输入您要关注的企业名称等关键词，如“百度”"
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          onSearch={() => searchVagueCompany(inputText)}
          enterButton={
            <span>
              <SearchOutlined />
              搜索
            </span>
          }
        />
      </div>
      <div className={style.content}>
        {Object.keys(companyList).length > 0 && (
        <div className={style.totalDesc}>
          为您找到
          <span style={{ fontWeight: 'bold', margin: '0 6px' }}>{companyList.count}</span>
          家符合条件的企业
        </div>)}
        <div className={style.splitLine} />
        <div className={style.table}>
          {(companyList.data_list || []).map((item, index) => (
            <div
              className={style.tableItem}
              key={`${index}tableItem`}
            >
              <div
                className={style.companyName}
                dangerouslySetInnerHTML={{
                  __html: handleHighWord(item),
                }}
              />
              {item.attention ? (
                <div className={style.attentioned}>
                  <IconFont className={`${style.attentionIcon} ${style.selectIcon}`} type="iconheart-fill" />
                  已关注
                </div>
              ) : (
                <div className={style.attention} onClick={() => showCompanyFocusGroup(item)}>
                  <IconFont className={style.attentionIcon} type="iconheart" />
                  关注
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={style.pagination}>
          <Pagination
            showQuickJumper
            hideOnSinglePage
            total={(companyList?.count || 0) > 10000 ? 10000 : (companyList?.count || 0)}
            // pageSizeOptions={false}
            current={curPagination.pageNum}
            showSizeChanger={false}
            pageSize={curPagination.pageSize}
            onChange={paginationChange}
          />
        </div>
      </div>
      {focusGroupShow && (
      <div className={style.focusDetail}>
        <FocusDetail
          focusData={focusData}
          updateList={() => getFocusList()}
          focusDetailEvent={(t, p) => focusDetailEvent(t, p)}
        />
      </div>)}
    </div>
  );
}

export default FocusCompany;
