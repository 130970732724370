import clsx from 'clsx';
import styles from './index.module.scss';

const TableTitle = (props: any) => {
  const { children, style, } = props;
  return <div className={styles.title} style={style}>{children}</div>;
}
const Row = (props: any) => {
  const { children, style, } = props;
  return <div className={styles.row} style={style}>{children}</div>;
}
const Label = (props: any) => <div className={clsx(styles.label, styles.pin)}>{props.children}</div>;
const Cell = (props: any) => {
  const { className, children, style, } = props;
  return <div className={clsx(styles.cell, className)} style={style}>{children}</div>;
}

function EnterpriseFaceCard(props: any) {
  const {
    info = {},
    financingCapacity = {},
    growthPotential = {},
    industryStrength = {},
    innovationAbility = {},
    className,
    style,
  } = props;

  return (
    <div
      className={clsx(styles.card, className)}
      style={style}
    >
      <TableTitle>工商信息</TableTitle>
      <Row>
        <Cell style={{ padding: 0, borderLeft: 'none' }}>
          <Row>
            <Label>名称</Label>
            <Cell>{info?.name || ''}</Cell>
          </Row>
          <Row>
            <Label>曾用名</Label>
            <Cell>{info?.history_name}</Cell>
          </Row>
          <Row>
            <Label>统一社会信用代码</Label>
            <Cell>{info?.code || ''}</Cell>
          </Row>
          <Row>
            <Label>组织机构代码</Label>
            <Cell>{info?.organizationCode || ''}</Cell>
          </Row>
          <Row>
            <Label>注册时间</Label>
            <Cell>{info?.registerTime || ''}</Cell>
          </Row>
          <Row>
            <Label>法人姓名</Label>
            <Cell>{info?.legalPerson || ''}</Cell>
          </Row>
          <Row>
            <Label>注册地址</Label>
            <Cell>{info?.address || ''}</Cell>
          </Row>
          <Row>
            <Label>注册资本</Label>
            <Cell>{info?.registerCapital || ''}</Cell>
          </Row>
          <Row>
            <Label>主要股东</Label>
            <Cell>{info?.shareholders || ''}</Cell>
          </Row>
          <Row>
            <Label>公司类型</Label>
            <Cell>{info?.detailType || ''}</Cell>
          </Row>
          <Row>
            <Label>社保人数</Label>
            <Cell>{info?.ssfCount || ''}</Cell>
          </Row>
          <Row>
            <Label>电话</Label>
            <Cell>{info?.tel || ''}</Cell>
          </Row>
          <Row>
            <Label>邮箱</Label>
            <Cell>{info?.email || ''}</Cell>
          </Row>
          <Row>
            <Label>国民经济行业</Label>
            <Cell>{info?.industryNationalEconomy || ''}</Cell>
          </Row>
        </Cell>
        <Label>上奇评级</Label>
        <Cell
          className={styles.pin}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {info?.grade || ''}
        </Cell>
      </Row>
      <Row>
        <Label>经营范围</Label>
        <Cell>{info?.scope || ''}</Cell>
      </Row>
      <Row>
        <Label>产品</Label>
        <Cell>{info?.productionCleaning || ''}</Cell>
      </Row>
      <Row>
        <Label>企业简介</Label>
        <Cell>{info?.brief || ''}</Cell>
      </Row>
      <Row>
        <Label>税务评级</Label>
        <Cell>{info?.taxGrade || ''}</Cell>
      </Row>
      <TableTitle>行业实力</TableTitle>
      <Row>
        <Label>上市/挂牌</Label>
        <Cell>{industryStrength.listing}</Cell>
      </Row>
      <Row>
        <Label>分子公司</Label>
        <Cell>设立分支机构{industryStrength.institutionCount || 0}家，对外投资{industryStrength.investmentCount || 0}家</Cell>
      </Row>
      <Row>
        <Label>前三股东</Label>
        <Cell>{industryStrength.top3Shareholders}</Cell>
      </Row>
      <Row>
        <Label>主要资质</Label>
        <Cell>{industryStrength.mainQualifications || ''}</Cell>
      </Row>
      <Row>
        <Label>所获荣誉</Label>
        <Cell>{industryStrength.honorsReceived}</Cell>
      </Row>
      <Row>
        <Label>行业排名</Label>
        <Cell>标准行业内排名{industryStrength.standardSort}/{industryStrength.sortTotal}</Cell>
        <Cell>专利行业内排名{industryStrength.patentSort}/{industryStrength.sortTotal}</Cell>
      </Row>
      <TableTitle>创新能力</TableTitle>
      <Row>
        <Label>主导标准</Label>
        <Cell>国家标准{innovationAbility.nationalStandardCount || 0}项、行业标准{innovationAbility.industryStandardCount || 0}项、地方标准{industryStrength.localStandardsCount || 0}项、团体标准{industryStrength.teamStandardsCount || 0}项</Cell>
      </Row>
      <Row>
        <Label>所有专利</Label>
        <Cell>已授权专利{innovationAbility.authorizedPatentCount || 0}项，申请中专利{innovationAbility.patentApplicationCount || 0}项</Cell>
      </Row>
      <Row>
        <Label>创新平台</Label>
        <Cell>国家级创新平台{innovationAbility.bationalInnovationPlatformCount || 0}个，省级创新平台{innovationAbility.provincialInnovationPlatformCount || 0}个</Cell>
      </Row>
      <Row>
        <Label>合作关系</Label>
        <Cell>{innovationAbility.partnership}</Cell>
      </Row>
      <TableTitle>融资能力</TableTitle>
      <Row>
        <Label>融资次数</Label>
        <Cell>{financingCapacity.rounds || ''}</Cell>
      </Row>
      <Row>
        <Label>融资总额</Label>
        <Cell>{financingCapacity.total || ''}</Cell>
      </Row>
      <Row>
        <Label>平均额度</Label>
        <Cell>{financingCapacity.average || ''}</Cell>
      </Row>
      <Row>
        <Label>融资历程</Label>
        <Cell>{financingCapacity.process}</Cell>
      </Row>
      <Row>
        <Label>投资机构</Label>
        <Cell>{financingCapacity.institutions || ''}</Cell>
      </Row>
      <TableTitle>成长潜力</TableTitle>
      <Row>
        <Label>营收趋势</Label>
        <Cell>{growthPotential.revenueGrowth || ''}</Cell>
      </Row>
      <Row>
        <Label>专利趋势</Label>
        <Cell>{growthPotential.patentGrowth || ''}</Cell>
      </Row>
      <Row>
        <Label>标准趋势</Label>
        <Cell>{growthPotential.standardGrowth || ''}</Cell>
      </Row>
      <Row>
        <Label>市值趋势</Label>
        <Cell>{growthPotential.marketValueGrowth || ''}</Cell>
      </Row>
    </div>
  );
}

export {
  EnterpriseFaceCard,
};
