/*
* Author: zhangmeng
* Description: '绑定微信'
* Date: 2021/1/12
*/

import React, { useState, useMemo, useRef } from 'react';
import { Button, Form, Input, message } from 'antd';
import { getLoginNote } from '../../../api/login';
// import QrCodeDesc from '../QrCodeDesc';
import style from './index.module.less';

function BindWx(props) {
  const { onFinish, isLoading } = props;
  const [btnValue, setBtnValue] = useState('获取验证码');
  const captchaRef = useRef({ timer: null });
  const getCaptcha = (curValue) => {
    if (curValue === '获取验证码') {
      const phone = document.querySelector('#phone').value;
      const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
      if (!phone) {
        message.error('手机号不能为空');
      } else if (!checkPhone.test(phone)) {
        message.error('手机号码格式错误');
      } else {
        getLoginNote({
          phone_no: phone,
        }).then(res => {
          if (res.status === '00000') {
            let second = 60;
            setBtnValue(`${second}s`);
            if (captchaRef.current && captchaRef.current.timer) {
              clearInterval(captchaRef.current.timer);
            }
            captchaRef.current.timer = setInterval(() => {
              if (second > 0) {
                second -= 1;
                setBtnValue(`${second}s`);
              } else {
                if (captchaRef.current) {
                  clearInterval(captchaRef.current.timer);
                }
                setBtnValue('获取验证码');
              }
            }, 1000);
          } else {
            message.error(res.message);
          }
        });
      }
    }
  };

  const onFinishFailed = (params) => {
    // console.log(params, 'onFinishFailed');
  };

  return (
    <div className={style.all}>
      <p className={style.title}></p>
      <div className={style.form}>
        <Form
          name="basic"
          labelAlign="left"
          onFinish={(p) => onFinish(p)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label=""
            name="phone_no"
            className="username"
            rules={[
              {
                pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                message: '请输入正确的手机号',
              },
              {
                required: true,
                message: '请输入手机号码',
              },
            ]}
          >
            <Input placeholder="请输入手机号码" id="phone" />
          </Form.Item>

          <Form.Item label="" className="captchaBox">
            <Form.Item
              name="sms"
              className="captcha"
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input placeholder="6位短信验证码" maxLength={6} />
            </Form.Item>
            <div className="getCaptcha">
              <Button
                ref={captchaRef}
                type="text"
                onClick={() => getCaptcha(btnValue)}
                className={btnValue !== '获取验证码' ? 'btnClicked' : ''}
                disabled={btnValue !== '获取验证码'}
              >
                {btnValue}
              </Button>
            </div>
          </Form.Item>
          {/* <div className={style.desc}>
            <QrCodeDesc />
          </div> */}

          <Form.Item className="submit">
            <Button type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default BindWx;
