/*
 *Author: zhaochenyu
 *Description: 企业详情-资质认定
 *Date: 2022/01/21
*/

import React, { useState, useEffect, useMemo } from 'react';
import { TreeChart } from '@shangqi/cube-component';
import Container from '../../Container';
import { getCompanyCredentialTag } from '../../../../../api/companyDetail';
import { getWordsWidth } from '../../../../../utils/charts';

export const nodeStyle = [
  {
    label: {
      color: '#fff',
      fontSize: 16,
      backgroundColor: '#1961F5',
      padding: [6, 2, 4],
      fontWeight: 'normal',
      borderRadius: 32,
      width: 300,
      align: 'center',
      distance: 58,
      position: 'insideRight',
      overflow: 'break',
    },
    lineStyle: {
      width: 1,
    },
  },
  {
    label: {
      padding: 4,
      fontSize: 14,
      lineHeight: 10,
      borderRadius: 32,
      color: '#fff',
      backgroundColor: '#1961F5',
      overflow: 'breakAll',
      fontWeight: 'normal',
      distance: 2,
      align: 'right',
      position: 'left',
    },
    itemStyle: {
      // opacity:
    },
    symbolSize: 0,
  },
  {
    label: {
      // padding: [-9, 0, 0, 0],
      lineHeight: 8,
      fontSize: 12,
      backgroundColor: '#fff',
      color: '#0F2849',
      distance: -28,
      position: 'right',
      overflow: 'breakAll',
    },
  },
  {
    label: {
      fontSize: 12,
      color: '#0F2849',
      distance: -4,
      position: 'right',
      overflow: 'breakAll',
    },
  },
];
export const gridOption = {
  top: 0.01,
  left: 0.19,
  bottom: 0.01,
  right: 0.142,
};

export function extraStyleHanlder(data, chartInstance) {
  const instance = chartInstance;
  const containerWidth = instance.getWidth();
  const treeHeight = containerWidth * (1 - (gridOption.left + gridOption.right));// 减去left， right
  // 第2层的最大宽度还是基于某个百分比
  const maxSecondLevelLabelWidth = treeHeight * 0.4;
  const leafLabelWidth = treeHeight * 0.2;
  function dfsStyle(node, targetDepth, depth = 0, maxLabelWidth) {
    if (!node) {
      return;
    }
    // eslint-disable-next-line no-unused-expressions
    node?.children?.forEach((item) => {
      if (depth === targetDepth) {
        const curWidth = getWordsWidth(item.name, { font: `${nodeStyle[targetDepth + 1].label.fontSize}px sans-serif` });
        // (item).label.width = curWidth;
        if (curWidth > maxLabelWidth) {
          // console.log(Object.getOwnPropertyDescriptor((item).label, 'width'));
          // eslint-disable-next-line no-param-reassign
          (item).label = {
            ...(item).label,
            width: maxLabelWidth,
          };
        }
      } else {
        dfsStyle(item, targetDepth, depth + 1, maxLabelWidth);
      }
    });
  }
  dfsStyle(data, 1, 0, maxSecondLevelLabelWidth);
  dfsStyle(data, 2, 0, leafLabelWidth);
}
export function symbolSize(_value, param) {
  if (param.treeAncestors.length === 2 || param.treeAncestors.length === 3) {
    return 8;
  }
  return 0;
}

function QualificationIdentification(props) {
  const { companyId, companyName } = props;
  const [data, setData] = useState({});
  const [title, setTitle] = useState('');

  useEffect(() => {
    getCompanyCredentialTag({
      company_id: companyId,
      company_name: companyName,
    }).then(res => {
      if (res.status === '00000') {
        const { data: data2 } = res;
        setTitle(data2.pool.title);
        setData(data2.series);
        setData({
          name: data2.series[0].name,
          children: data2.series[0].data,
        });
      }
    });
  }, []);
  const chart = useMemo(() => (
    Object.keys(data).length > 0
    && <TreeChart
      data={data}
      mode="light"
      fontSize="large"
      nodeStyle={nodeStyle}
      extraStyleHanlder={extraStyleHanlder}
      gridOption={gridOption}
      symbolSize={symbolSize}
      roam
      collapse
    />
  ), [data]);
  return (
    <Container id="荣誉资质" height="500px" look="查看全图" question={data.desc || ''} companyId={companyId} companyName={companyName}>
      <div style={{ paddingLeft: '70px', lineHeight: '20px', color: '#0F2849' }}>
        {title}
      </div>
      {chart}
    </Container>
  );
}

export default QualificationIdentification;
