import jsonLogic from 'json-logic-js';

export const getFormatter = (formulate: (object & { type: 'option'; eachLine?: number; expression?: object }) | string | undefined) => {
  if (typeof formulate === 'string' || typeof formulate === 'function') {
    return formulate;
  }
  if (typeof formulate === 'object' && formulate.type !== 'option') {
    return (value: any) => {
      return jsonLogic.apply(formulate, value);
    };
  }
  if (typeof formulate === 'object' && formulate.type === 'option') {
    const { expression, eachLine } = formulate;
    return (value: any, ...rest: any[]) => {
      if (!expression) {
        return value;
      }
      const _value = jsonLogic.apply(expression, rest.length > 0 ? [value, ...rest] : value);
      if (!eachLine) {
        return _value;
      }
      if (typeof _value === 'string') {
        return  Array.from(_value).reduce((previousValue, currentValue, currentIndex) => {
          if (currentIndex >= (_value.length - 1)) {
            return previousValue + currentValue;
          }
          return previousValue + currentValue + (((currentIndex + 1) % eachLine === 0) ? '\n' : '');
        }, '');
      }
      return _value;
    };
  }
  return formulate;
};

export const getLabel = (label: any) => {
  if (!label) return undefined;
  return { ...label, formatter: getFormatter(label.formatter) };
};
