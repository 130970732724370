import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
import { useGetCompanyPatentListQuery } from '../../../../service/industry-channel';
import { getNameConfig, getSortKv } from '../../../../utils/table';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: 'table-index',
  },
  title: {
    title: '专利名称',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  tag_name: {
    title: '所属产业',
    dataIndex: 'tag_name',
    key: 'tag_name',
    width: 84,
    render: text => (Array.isArray(text) ? text.join('、') : text),
    ellipsis: true,
  },
  patent_score: {
    title: '专利价值分',
    dataIndex: 'patent_score',
    key: 'patent_score',
    width: 94,
    ellipsis: true,
  },
  approval_date: {
    title: '公告日',
    dataIndex: 'approval_date',
    key: 'approval_date',
    width: 88,
    ellipsis: true,
  },
};

const COLUMN_KEYS = [
  'index', 'title', 'tag_name', 'patent_score', 'approval_date',
];

function CompanyPatentTable(props) {
  const {
    patentType,
    companyId,
    industryCode,
    pageNumber = 1,
    pageSize = 20,
    onPatentClick,
    onData,
  } = props;

  const { data, isFetching } = useGetCompanyPatentListQuery({
    industry_code: industryCode,
    patent_type: patentType,
    company_id: companyId,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !companyId,
  });
  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'title') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({
            onClick: onPatentClick,
            idField: '_id',
            className: 'clickable-name',
          })),
        };
      }
      return rowConfig;
    });
  }, [columns, onPatentClick]);

  // tip: 不要把 onData 放到依赖中，如果函数未稳定，则会无限渲染
  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      {...props}
    />
  );
}

export default CompanyPatentTable;
