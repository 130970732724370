import { ComponentType, forwardRef, memo, useMemo } from 'react';
import { Table as DTable } from '@sqke/design';
import { withConfig } from '../../../hoc/withConfig';

function Table(props: any, ref: any) {

  return (
    useMemo(() => {
      return (
        <DTable
          {...props}
          ref={ref}
        />
      )
    }, [props])
  );
}
/**
 * 数据映射后的表格
 */
const ConfigTable = withConfig(
  forwardRef(Table),
  {
    mapConfigToProps: (raw, data) => {
      const { columns = [], dataSource = [] } = data ?? {};
      const { table_scale: scale } = raw;
      let _columns = columns;
      if (scale) {
        const total = scale.reduce((a: number, b: number) => a + b);
        _columns = columns.map((item: any, index: number) => ({
          ...item,
          width: `${(scale[index])/total * 100}%`,
        }))
      }
      return {
        columns: _columns?.map((column: any, index: number) => ({
          ...column,
          key: column.key ?? index,
        })),
        dataSource: dataSource?.map((row: any, index: number) => ({...row, key: row.key ?? index})),
        pagination: false as false,
        strip: true,
      };
    },
    empty: (_, data) => false,
  }
) as ComponentType;

export default memo(ConfigTable);
