import React, { useMemo, useEffect } from 'react';
import BarTable from '../../../component/BarTable';
import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const PatentIndustryDistribution = (props) => {
  const { url, dataFilterParams, reportData, ok } = props;
  const { data, isFetching } = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData || !ok });
  const newData = useMemo(() => {
    if (!data) return undefined;
    if (!data.series.length) {
      const initData = {
        ...data,
        series: [
          { name: '授权发明专利(件)', unit: '件', data: [0] },
        ],
      };
      return initData;
    }
    return data;
  }, [data]);
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <BarTable {...props} data={newData} isFetching={isFetching} chartType="柱线图" delCrosswise />;
};

export default PatentIndustryDistribution;
