/*
 *Author: zhaochenyu
 *Description: 我的-订单
 *Date: 2022/03/07
*/

import React, { useCallback } from 'react';
import { Pagination, Table } from 'antd';
import apply from '../../../assets/images/myself/apply.png';
import style from './index.module.less';

function ApplyTable(props) {
  const { data: { tableHead, tableBody, count }, pagination, getOrderTableData } = props;

  const handleWithTableHead = useCallback(
    (col) => {
      const newColumn = col.map(item => {
        let res;
        if (item.dataIndex === 'order_id') {
          res = {
            ...item,
            width: '15%',
            ellipsis: true,
          };
        } else if (item.dataIndex === 'report_name') {
          res = {
            ...item,
            width: '35%',
            ellipsis: true,
          };
        } else if (item.dataIndex === 'pay_money') {
          res = {
            ...item,
            width: '12%',
            render: (text, record) => (
              <div style={{ color: '#EE6000', fontWeight: 'bold' }}>
                {
                  record.pay_way !== '上奇币' && <span>￥</span>
                }
                {`${text}`}
              </div>
            ),
          };
        } else if (item.dataIndex === 'pay_way') {
          res = {
            ...item,
            width: '15%',
            render: (text) => (
              <div style={{ display: 'flex', alignItems: 'center', color: '#262D36' }}>
                <img alt="" src={apply} style={{ width: '18px', height: '18px', marginRight: '8px' }} />
                {text}
              </div>
            ),
          };
        } else {
          res = {
            ...item,
            ellipsis: true,
          };
        }
        return res;
      });
      return newColumn;
    },
    [],
  );
  return (
    <div className={style.all}>
      <Table
        rowKey={record => record.id}
        columns={handleWithTableHead(tableHead)}
        dataSource={tableBody}
        pagination={false}
      />
      <Pagination
        className={style.pagination}
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={count}
        pageSizeOptions={[10]}
        showQuickJumper
        hideOnSinglePage
        onChange={(page, pageSize) => {
          getOrderTableData({ current: page, pageSize });
        }}
      />
      <div className={style.applyNote}>注：开具发票请微信联系客服</div>
    </div>
  );
}

export default ApplyTable;
