/*
* Author: zhangmeng
* Description: '专利分析'
* Date: 2021/05/28
*/

import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
// import { getPatentStatistics } from '../../../../../api/companyDetail';
// import CompanyLevleTag from '../../../../CompanyLevleTag';
import Container from '../../Container';
// import PatentStatistics from '../../PatentStatistics';
// import { useGetFindPatentListQuery } from '../../../../../service/companyDetail';
// import { openNewPage } from '../../../../../utils/urlMethods';
import ApplicationTrend from './ApplicationTrend';
import PatentType from './PatentType';
import OpenTrend from './OpenTrend';
import LegalStatus from './LegalStatus';
import InventorDistribution from './InventorDistribution';
import IPCDistribution from './IPCDistribution';
import IndustrialDistribution from './IndustrialDistribution';
import HotArea from './HotArea';
import ValueStatistics from './ValueStatistics';
import PatentParticulars from './PatentParticulars';
import style from './index.module.less';

// const list = [
//   { title: '全部', value: '0.3' },
//   { title: '总部', value: '0' },
//   { title: '子公司', value: '1.3' },
// ];
const chartTagList = [
  { name: '申请趋势', value: 'ApplicationTrend' },
  { name: '公开趋势', value: 'OpenTrend' },
  { name: '专利类型分布', value: 'PatentType' },
  { name: '法律状态分布', value: 'LegalStatus' },
  { name: '发明人分布', value: 'InventorDistribution' },
  { name: 'IPC分类分布', value: 'IPCDistribution' },
  { name: '产业领域分布', value: 'IndustrialDistribution' },
  { name: '热点领域', value: 'HotArea' },
  { name: '价值统计', value: 'ValueStatistics' },
];
function PatentAnalysis(props) {
  const { companyId, preventPageto } = props;
  const [gradeActive, setGradeActive] = useState('0.3');
  const [chartActive, setChartActive] = useState('ApplicationTrend');
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const chartList = {
    ApplicationTrend: <ApplicationTrend {...props} grade={gradeActive} />,
    OpenTrend: <OpenTrend {...props} grade={gradeActive} />,
    PatentType: <PatentType {...props} grade={gradeActive} />,
    LegalStatus: <LegalStatus {...props} grade={gradeActive} />,
    InventorDistribution: <InventorDistribution {...props} grade={gradeActive} />,
    IPCDistribution: <IPCDistribution {...props} grade={gradeActive} />,
    IndustrialDistribution: <IndustrialDistribution {...props} grade={gradeActive} />,
    HotArea: <HotArea {...props} grade={gradeActive} />,
    ValueStatistics: <ValueStatistics {...props} grade={gradeActive} />,
  };

  return (
    <Container id="专利分析">
      {/* <div className={style.tagBox}>
        <CompanyLevleTag
          list={list}
          active={gradeActive}
          setActive={setGradeActive}
        />
      </div> */}

      <div className={style.chartsTag} >
        {
          chartTagList.map(item => (
            <div
              key={item.name}
              onClick={() => { setChartActive(item.value); }}
              className={classnames({ [style.chartActive]: item.value === chartActive })}
            >
              {item.name}
            </div>
          ))
        }
      </div>
      <div className={style.chartBox}>
        {
          chartList[chartActive]
        }
      </div>
      <PatentParticulars {...props} grade={gradeActive} />
    </Container>
  );
}

export default PatentAnalysis;
