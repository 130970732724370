import React from 'react';
import { Spin } from 'antd';
import Title from '../../../../components/Title';
import styles from './index.module.less';
import BubbleBarChart from '../../../../components/Industrial/BubbleBarChart';
import BarAndLine from '../../../../components/Industrial/BarAndLine';
import { useGetBenchmarkingAnalyseQuery } from '../../../../service/city-channel';

const charts = [
  { title: '生产总值及增长率', charts: (chartData) => <BarAndLine data={{ ...chartData?.data, bubble: true, curLocation: chartData?.data?.highlight[0], hideMinimum: true }} /> },
  { title: '存量企业数量', charts: (chartData) => <BubbleBarChart data={{ data: chartData?.data, bubble: true, curLocation: chartData?.data?.highlight[0] }} /> },
  { title: '新增企业数量', charts: (chartData) => <BubbleBarChart data={{ data: chartData?.data, bubble: true, curLocation: chartData?.data?.highlight[0] }} /> },
  { title: '高新技术企业数量', charts: (chartData) => <BubbleBarChart data={{ data: chartData?.data, bubble: true, curLocation: chartData?.data?.highlight[0] }} /> },
  { title: '授权发明专利数量', charts: (chartData) => <BubbleBarChart data={{ data: chartData?.data, bubble: true, curLocation: chartData?.data?.highlight[0] }} /> },
  { title: '股权融资情况对比', charts: (chartData) => <BarAndLine data={{ ...chartData?.data, bubble: true, curLocation: chartData?.data?.highlight[0] }} /> },
];

const BenchmarkingAnalysis = ({ name, cityCode, cityName }) => {
  const { data: chartData } = useGetBenchmarkingAnalyseQuery({
    area_key: cityCode,
  });

  const setTitle = ({ cityName2, chartData2 }) => {
    const Municipality = ['北京市', '上海市', '天津市', '重庆市'];
    const year = chartData2?.before_describe_value_dict?.year ? `${chartData2.before_describe_value_dict.year}年` : '';
    const city = Municipality.indexOf(cityName2) > 0 ? `${cityName2}各城市` : cityName2;
    const date = chartData2?.before_describe_value_dict?.date?.length > 20 ? '近一年' : '';
    // return `${year}${date}${city}`;
    return `${year}${date}`;
  };
  return (
    <div id='analyse' className={styles.box}>
      <div className={styles.title}>
        <Title title={name} />
      </div>
      <ul>
        {
          charts.map((item, index) => (
            <li key={item.title}>
              <p className={styles.subtitle}>
                <span>{chartData && chartData[index] ? chartData[index].title : ''}</span>
                <span className={styles.minTitle} >{chartData && chartData[index] ? `(${chartData[index].time_scope})` : ''}</span>
              </p>
              <div className={styles.charts}>
                {
                  chartData && chartData.length > 0 ? item.charts(chartData[index]) : <Spin />
                }
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default BenchmarkingAnalysis;
