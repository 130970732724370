import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import Container from '../../Container';
import { useGetShareholderInfoQuery } from '../../../../../service/companyDetail';

// const tagList = ['ipoShareholder', 'commercialRegistration'];

function MajorShareholders(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  // const [active, setActive] = useState('ipoShareholder');

  const { data: tableData, isFetching: isTableFetching } = useGetShareholderInfoQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const { dataSource, columns } = tableData || {};
  const _columns = () => columns?.map(item => {
    if (item.dataIndex === 'index') {
      return {
        ...item,
        width: 70,
      };
    }
    return item;
  });
  return (
    <Container id="股东信息" title="">
      <Table
        rowKey="index"
        dataSource={dataSource}
        columns={_columns()}
        // title={() => tableData?.title}
        strip
        loading={isTableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />
    </Container>
  );
}

export default MajorShareholders;
