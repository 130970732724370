import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
import styles from './index.module.less';
import { useGetChinaMerchantsObjectRecommendedQuery } from '../../../../service/industry-channel';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 40,
    className: styles.index,
  },
  company_name: {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    ellipsis: true,
    className: styles.name,
  },
  regional_development: {
    title: '区域拓展快',
    dataIndex: 'regional_development',
    key: 'regional_development',
    className: styles.overBox,
    width: 65,
    render(text) {
      return (
        text ? <div className={styles.confirm}></div> : '-'
      );
    },
  },
  many_new_patents: {
    title: '新增专利多',
    dataIndex: 'many_new_patents',
    key: 'many_new_patents',
    className: styles.overBox,
    width: 65,
    render(text) {
      return (
        text ? <div className={styles.confirm}></div> : '-'
      );
    },
  },
  many_new_investment: {
    title: '新获投资多',
    dataIndex: 'many_new_investment',
    key: 'many_new_investment',
    className: styles.overBox,
    width: 65,
    render(text) {
      return (
        text ? <div className={styles.confirm}></div> : '-'
      );
    },
  },
  rapid_staff_growth: {
    title: '员工增长快',
    dataIndex: 'rapid_staff_growth',
    key: 'rapid_staff_growth',
    className: styles.overBox,
    width: 65,
    render(text) {
      return (
        text ? <div className={styles.confirm}></div> : '-'
      );
    },
  },
  // recommended: {
  //   title: '推荐理由',
  //   dataIndex: 'recommended',
  //   key: 'recommended',
  //   width: 152,
  //   ellipsis: true,
  //   render(text) {
  //     return (
  //       <span title={text?.join('\n')}>{text?.join(',')}</span>
  //     );
  //   },
  // },
  score: {
    title: '上奇评级',
    dataIndex: 'score',
    key: 'score',
    width: 52,
    className: styles.overBox,
  },
};

const COLUMN_KEYS = ['index', 'company_name', 'regional_development', 'many_new_patents', 'many_new_investment', 'rapid_staff_growth', 'score'];

function InvestmentMapTable(props) {
  const {
    industryCode,
    linkCode,
    companyId,
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    getCount,
  } = props;

  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetChinaMerchantsObjectRecommendedQuery({
    industry_code: industryCode,
    link_code: industryCode === linkCode ? '' : linkCode,
    company_id: companyId,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });

  const { columns: tableHead = [], datasource: tableBody = [], total = 0, count = 0 } = companyData ?? {};

  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'company_name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      return rowConfig;
    });
  }, [tableHead, onCompanyClick]);

  useEffect(() => {
    getCount(count);
  }, [getCount, count]);

  return (
    <div className={styles.all}>
      <Table
        columns={columns}
        dataSource={tableBody}
        loading={isCompanyListFetching}
        current={pageNumber}
        pageSize={pageSize}
        total={total}
        {...props}
      />
    </div>
  );
}

export default InvestmentMapTable;
