import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { debounce } from 'lodash';
import classnames from 'classnames';
import Tree from '../Tree';
import Button from '../Button';
import Title from '../../../../components/Title';
import GraphTab from '../GraphTab';
import Subtitle from '../../../../components/Subtitle';
import Underline from '../../../../components/Underline';
import DrillableHeatmap from '../DrillableHeatmap';
import DistributionRanking from '../DistributionRanking';
import SectorCompanyTable from '../SectorCompanyTable';
import Overview from '../Overview';
import EquityCapital from '../EquityCapital';
import InvestmentOrgan from '../InvestmentOrgan';
import InvestmentMap from '../InvestmentMap';
import { StandardModal } from '../Modal';
import styles from './index.module.less';
import {
  useGetChannelIndustryInfoQuery,
  useGetChannelIndustryLinkQuery,
  useGetChannelLinkCompanyListQuery,
} from '../../../../service/industry-channel';
import { useRecordingSpotQuery } from '../../../../service/public';
import { openNewPage } from '../../../../utils/urlMethods';
import ZoomButton from '../ZoomButton';

const getName = node => node.title;
const getValue = node => node.score;
const getId = node => node.value;
const getDisabled = node => !node.isBright;
const isDistrict = regionCode => regionCode && regionCode.lastIndexOf('00') !== 4;

const TREE_PADDING = [10, 520, 10, 30];

const GRAPHTABS = [
  {
    title: '产业链图',
    key: 'industrialChain',
  },
  {
    title: '产业总览',
    key: 'industrialOverview',
  },
  {
    title: '产业分布',
    key: 'industrialDistribution',
  },
  {
    title: '股权融资',
    key: 'equityFinancing',
  },
  {
    title: '投资机构',
    key: 'investmentInstitutions',
  },
  {
    title: '招商图谱',
    key: 'investmentMap',
  },
];

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

/**
 * 图谱 scheme
 * 所谓全展开，就是根节点
 * @param {string} industryCode
 * @returns {JSX.Element}
 * @constructor
 */
function GraphScheme({
  industryCode,
  mainRef,
}) {
  const permission = JSON.parse(localStorage.getItem('pm'));
  const industryGraphGroup = permission?.graph || [];
  const graphTabs = useMemo(() => {
    const arr = [];
    GRAPHTABS.forEach(item => {
      if (industryGraphGroup.find(tab => tab === item.key)) {
        arr.push(item);
      }
    });
    return arr;
  }, [industryGraphGroup]);
  // 产业信息
  const { data: industryInfo } = useGetChannelIndustryInfoQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });
  const { name: industryName } = industryInfo ?? {};
  // 获取环节企业总数
  const [regionPath, setRegionPath] = useState(['000000']);
  const regionCode = regionPath[regionPath.length - 1];

  // 产业筛选结果
  const [linkCompanyCount, setLinkCompanyCount] = useState();

  // —————— 产业链图
  // 当前产业/环节名称，产业同种需要根据产业名称进行查询
  const [selectedId, setSelectedId] = useState(industryCode);
  useEffect(() => {
    setSelectedId(industryCode);
  }, [industryCode]);
  // 树图缩放等级
  const [zoom, setZoom] = useState(1);
  const setZoomDebounced = useCallback(debounce(setZoom, 50), []);
  // 树的下拉是否展开
  const [isTreeDropdownOpen, setIsTreeDropdownOpen] = useState(true);
  // 树图 ref，负责调用内置方法
  const treeRef = useRef();
  const [treeListQueryState, setTreeListQueryState] = useState({
    pageSize: 20,
    pageNumber: 1,
    filteredInfo: { special_tag: [] },
  });
  const {
    data: chainData,
    isFetching: isChainFetching,
  } = useGetChannelIndustryLinkQuery({
    industry_code: industryCode,
    area_key: '000000',
    // area_key: getSingleOption(treeListQueryState.filteredInfo.in_area) ?? regionCode,
    special_tag: treeListQueryState.filteredInfo.special_tag?.filter(tag => tag !== 'all'),
  }, {
    skip: !industryCode,
  });
  const {
    link_count: linkCount,
    industry_data_count: industryCompanyCount,
  } = chainData ?? {};
  const {
    data: companyData,
    isFetching: isLinkFetching,
  } = useGetChannelLinkCompanyListQuery({
    // area_key: getSingleOption(treeListQueryState.filteredInfo.in_area) ?? regionCode,
    area_key: '000000',
    industry_code: industryCode,
    link_code: selectedId !== industryCode ? selectedId : undefined,
    // first_special_tag: (treeListQueryState.filteredInfo.special_tag?.filter(tag => tag !== 'all') ?? [])[0],
    second_special_tag: treeListQueryState.filteredInfo.special_tag?.filter(tag => tag !== 'all'),
    page_size: treeListQueryState.pageSize,
    page_number: treeListQueryState.pageNumber,
  }, {
    skip: !industryCode || !selectedId,
  });
  // const { count } = companyData ?? {};
  useRecordingSpotQuery({
    spot_name: '产业-图谱',
  });

  // useEffect(() => {
  //   if (typeof count === 'number') {
  //     setLinkCompanyCount(count);
  //   }
  // }, [count]);

  // —————— 产业分布
  const [isMapFetching, setIsMapFetching] = useState(false);
  const [mapListQueryState, setMapListQueryState] = useState({
    pageSize: 20,
    pageNumber: 1,
    sortedInfo: {},
  });
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState({
    visible: false,
    regionCode: '',
    regionName: '',
    type: '',
    filteredInfo: {},
  });

  // query 查询对应产业或者环节的数据
  // ...

  const contentRef = useRef(null);
  const [graphId, setGraphId] = useState(graphTabs[0]?.key);
  const onGraphTabsClick = (key) => {
    if (contentRef.current) {
      const el = contentRef.current;
      const target = el.querySelector(`[data-id=${key}]`);
      const topOffsetTop = el.querySelector(`[data-id=${graphTabs[0]?.key}]`)?.offsetTop;
      mainRef.current?.scrollTo({
        top: target.offsetTop - topOffsetTop,
        behavior: 'smooth',
      });
    }
    setGraphId(key);
  };
  const graphTabsComponents = useMemo(() => graphTabs.map((
    {
      key,
      title,
    },
  ) => (
    <GraphTab
      key={key}
      name={title}
      active={key === graphId}
      onClick={() => onGraphTabsClick(key)}
      className={styles.graphTab}
    />
  )), [onGraphTabsClick, graphId]);

  const [time, setTime] = useState('');

  useEffect(() => {
    // TODO 优化
    const scroll = () => {
      if (contentRef.current) {
        const el = contentRef.current;
        const target1 = el.querySelector('[data-id=industrialChain]')?.offsetTop;
        const target2 = el.querySelector('[data-id=industrialOverview]')?.offsetTop - target1;
        const target3 = el.querySelector('[data-id=industrialDistribution]')?.offsetTop - target1;
        const target4 = el.querySelector('[data-id=equityFinancing]')?.offsetTop - target1;
        const target5 = el.querySelector('[data-id=investmentInstitutions]')?.offsetTop - target1;
        const target6 = el.querySelector('[data-id=investmentMap]')?.offsetTop - target1;
        if (mainRef?.current?.scrollTop < target2) {
          setGraphId('industrialChain');
        } else if (mainRef?.current?.scrollTop > target2 && mainRef?.current?.scrollTop < target3) {
          setGraphId('industrialOverview');
        } else if (mainRef?.current?.scrollTop > target3 && mainRef?.current?.scrollTop < target4) {
          setGraphId('industrialDistribution');
        } else if (mainRef?.current?.scrollTop > target4 && mainRef?.current?.scrollTop < target5) {
          setGraphId('equityFinancing');
        } else if (mainRef?.current?.scrollTop > target5 && mainRef?.current?.scrollTop < target6) {
          setGraphId('investmentInstitutions');
        } else if (mainRef?.current?.scrollTop > target6) {
          setGraphId('investmentMap');
        }
      }
    };
    mainRef?.current?.addEventListener('scroll', debounce(scroll, 200));
    return () => {
      mainRef?.current?.removeEventListener('scroll', scroll);
      // mainRef.current = null;
    };
  }, []);

  return (
    <>
      <div className={styles.graphTabs}>
        {graphTabsComponents}
      </div>
      <section className={styles.graphScheme} ref={contentRef}>
        {/* 产业链图，树图结合下拉表格的组合 */}
        {
          industryGraphGroup?.includes('industrialChain') && (
            <div
              className={classnames(
                styles.subScheme,
                {
                  'grey-out': isChainFetching,
                },
              )}
              id={styles.tree}
              data-id="industrialChain"
            >
              {
                isChainFetching && <Spin className={styles.spin} delay={200} />
              }
              <div className={styles.header}>
                <Title title="产业链图" />
                <div
                  className={styles.buttons}
                >
                  <ZoomButton
                    zoom={zoom}
                    minZoom={0.1}
                    maxZoom={2}
                    className={styles.button}
                    onChange={z => {
                      const _z = Number(z?.toFixed(2));
                      setZoom(_z);
                      treeRef.current.setZoom(_z);
                    }}
                  />
                  <Button
                    className={styles.button}
                    type="primary"
                    text="展开"
                    onClick={() => {
                      const { setNodesCollapsedState } = treeRef.current;
                      setNodesCollapsedState(false);
                      setIsTreeDropdownOpen(false);
                    }}
                  />
                  <Button
                    className={styles.button}
                    type="primary"
                    text="收起"
                    onClick={() => {
                      const { setNodesCollapsedState } = treeRef.current;
                      setNodesCollapsedState(true);
                    }}
                  />
                  <Button
                    className={styles.button}
                    type="primary"
                    text="下载"
                    onClick={() => {
                      treeRef.current.download();
                    }}
                  />
                </div>
              </div>
              <Tree
                industryName={industryName}
                data={chainData}
                minZoom={0.1}
                maxZoom={2}
                onClick={(item) => {
                  const { id, disabled } = item;
                  if (disabled) return;
                  // if (selectedId === id) {
                  //   setSelectedId(industryCode);
                  // } else {
                  setSelectedId(id);
                  // }
                  setTreeListQueryState(prev => ({ ...prev, pageNumber: 1 }));
                  setIsTreeDropdownOpen(true);
                  const timer = setTimeout(() => {
                    treeRef.current.setNodeCollapsedState(item);
                    clearTimeout(timer);
                  }, 10);
                }}
                config={{
                  padding: isTreeDropdownOpen ? TREE_PADDING : 0,
                }}
                getId={getId}
                getName={getName}
                getValue={getValue}
                getDisabled={getDisabled}
                selectedId={selectedId}
                onInit={() => {
                  const _zoom = treeRef.current.getZoom();
                  if (typeof _zoom === 'number') {
                    setZoom(_zoom);
                  }
                }}
                onViewportChange={() => {
                  const _zoom = treeRef.current.getZoom();
                  if (typeof _zoom === 'number') {
                    const _z = Number(_zoom?.toFixed(2));
                    setZoomDebounced(_z);
                  }
                }}
                disableZoom
                ref={treeRef}
              />
              <div
                className={classnames(
                  styles.list,
                  { [styles.hidden]: !isTreeDropdownOpen },
                )}
              >
                <div className={styles.overview}>
                  <Subtitle
                    className={styles.subtitle}
                    description="产业链包含"
                    value={linkCount}
                    unit="个细分环节"
                  />
                  <Subtitle
                    className={styles.subtitle}
                    description="，企业"
                    value={industryCompanyCount}
                    unit="家"
                  />
                  {
                    // TODO: 稍晚展示，以优化用户体验
                    linkCompanyCount !== industryCompanyCount && !isLinkFetching && (
                      <Subtitle
                        className={classnames(styles.subtitle, styles.lastSubtitle)}
                        description="筛选结果"
                        value={linkCompanyCount}
                        unit="家"
                      />
                    )
                  }
                </div>
                <Underline />
                <SectorCompanyTable
                  className={styles.table}
                  industryCode={industryCode}
                  linkCode={selectedId !== industryCode ? selectedId : undefined}
                  pageSize={treeListQueryState.pageSize}
                  pageNumber={treeListQueryState.pageNumber}
                  onChange={({ current, pageSize }, filter) => {
                    setTreeListQueryState({
                      ...treeListQueryState,
                      pageSize,
                      pageNumber: current,
                      filteredInfo: filter,
                    });
                  }}
                  filteredInfo={treeListQueryState.filteredInfo}
                  onCompanyClick={({ id: _id }) => {
                    if (!_id) return;
                    openNewPage('companyDetail', { id: _id });
                  }}
                  setLinkCompanyCount={setLinkCompanyCount}
                />
                <Button
                  className={classnames(
                    styles.toggleButton,
                    {
                      [styles.slim]: !isTreeDropdownOpen,
                    },
                  )}
                  text={isTreeDropdownOpen ? '收起' : '展开'}
                  onClick={() => setIsTreeDropdownOpen(!isTreeDropdownOpen)}
                />
              </div>
            </div>
          )
        }
        {/* 产业总览 */}
        {
          industryGraphGroup?.includes('industrialOverview') && (
            <div className={styles.overviewScheme} data-id="industrialOverview">
              <div className={styles.header}>
                <Title title="产业总览" subtitle={`(${time})`} />
              </div>
              <div className={styles.content}>
                <Overview industryCode={industryCode} industryName={industryName} getTime={setTime} />
              </div>
            </div>
          )
        }
        {/* ———————— 产业分布，热力图结合右侧表格的组合 ———————— */}
        {
          industryGraphGroup?.includes('industrialDistribution') && (
            <div className={classnames(styles.subScheme, styles.mapScheme)} data-id="industrialDistribution">
              <div className={styles.header}>
                <Title title="产业分布" />
              </div>
              <div className={styles.content}>
                <div
                  className={classnames(
                    styles.map,
                    { 'grey-out': isMapFetching },
                  )}
                >
                  <DrillableHeatmap
                    industryCode={industryCode}
                    regionCode={regionCode}
                    onRegionClick={(region) => {
                      const { id, properties: { adcode } } = region;
                      const _code = String(adcode ?? id);
                      if (['710000', '810000', '820000'].includes(_code)) {
                        return;
                      }
                      if (!isDistrict(_code)) {
                        setRegionPath(prev => ([...prev, _code]));
                      }
                    }}
                    onRequestMapStart={() => {
                      setIsMapFetching(true);
                    }}
                    onRequestMapComplete={() => {
                      setIsMapFetching(false);
                    }}
                    onRequestMapError={() => {
                      setIsMapFetching(false);
                    }}
                  />
                  {
                    regionPath.length > 1 && (
                      <Button
                        className={styles.returnButton}
                        onClick={() => {
                          setRegionPath(prev => prev.slice(0, prev.length - 1));
                        }}
                        text="返回"
                      />
                    )
                  }
                  {
                    isMapFetching && <Spin className={styles.spin} delay={200} size="large" />
                  }
                </div>
                <div className={styles.table}>
                  <Underline />
                  <DistributionRanking
                    industryCode={industryCode}
                    regionCode={regionCode}
                    pageSize={mapListQueryState.pageSize}
                    pageNumber={mapListQueryState.pageNumber}
                    sortedInfo={mapListQueryState.sortedInfo}
                    onChange={({ current }, filter, sorter) => {
                      setMapListQueryState(prev => ({
                        ...prev,
                        pageNumber: current,
                        sortedInfo: sorter,
                      }));
                    }}
                    onClick={({ record, column }) => {
                      const { area, area_key: _regionCode } = record;
                      const { dataIndex } = column;
                      setMapCompanyListQueryState(prev => ({
                        ...prev,
                        regionCode: _regionCode,
                        regionName: area,
                        industryName,
                        filteredInfo: {},
                        type: [dataIndex],
                        visible: true,
                      }));
                    }}
                  />
                </div>
                {
                  mapCompanyListQueryState.visible && (
                    <StandardModal
                      header={
                        <>
                          <span style={{ color: '#0f2849', fontWeight: 'bold' }}>
                            {mapCompanyListQueryState.regionName}
                          </span>
                          <Subtitle
                            className={styles.subtitle}
                            description={`${industryName}企业列表`}
                          />
                        </>
                      }
                      onClose={() => {
                        setMapCompanyListQueryState(prev => ({
                          ...prev,
                          pageNumber: 1,
                          visible: false,
                        }));
                      }}
                    >
                      <SectorCompanyTable
                        className={styles.modalTable}
                        industryCode={industryCode}
                        regionCode={mapCompanyListQueryState.regionCode}
                        pageSize={mapCompanyListQueryState.pageSize}
                        pageNumber={mapCompanyListQueryState.pageNumber}
                        companyTypes={mapCompanyListQueryState.type}
                        onChange={({ current, pageSize }, filter) => {
                          setMapCompanyListQueryState({
                            ...mapCompanyListQueryState,
                            pageSize,
                            pageNumber: current,
                            filteredInfo: filter,
                          });
                        }}
                        filteredInfo={mapCompanyListQueryState.filteredInfo}
                        onCompanyClick={({ id: _id }) => {
                          if (!_id) return;
                          openNewPage('companyDetail', { id: _id });
                        }}
                      />
                    </StandardModal>
                  )
                }
              </div>
            </div>
          )
        }
        {/* 股权融资 */}
        {
          industryGraphGroup?.includes('equityFinancing') && (
            <div className={styles.equityCapitalScheme} data-id="equityFinancing">
              <div className={styles.header}>
                <Title title="股权融资" />
              </div>
              <div className={styles.content}>
                <EquityCapital industryCode={industryCode} />
              </div>
            </div>
          )
        }
        {/* 投资机构 */}
        {
          industryGraphGroup?.includes('investmentInstitutions') && (
            <div className={styles.investmentOrganScheme} data-id="investmentInstitutions">
              <InvestmentOrgan industryCode={industryCode} industryName={industryName} />
            </div>
          )
        }
        {/* 招商图谱 */}
        {
          industryGraphGroup?.includes('investmentMap') && (
            <div className={styles.investmentMapScheme} data-id="investmentMap">
              {
                industryName && <InvestmentMap industryCode={industryCode} industryName={industryName} />
              }
            </div>
          )
        }
      </section>
    </>
  );
}

export default GraphScheme;
