import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import { Popover, Radio, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Container from '../../../Container';
import CustomListFilter from '../../../../../CustomListFilter';
import { FilterIcon } from '../../../../../CustomTableIcon';
import { useGetQualityCertificationTableQuery } from '../../../../../../service/companyDetail';
import style from './index.module.less';

const levelList = [
  { text: '国家级', value: '国家级' },
  { text: '省级', value: '省级' },
];

const AptitudesStrike = ({ companyId }) => {
  const [pitchLevel, setPitchLevel] = useState([]);
  const [penetrationGrade, setPenetrationGrade] = useState('1.3');
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: tableFetching } = useGetQualityCertificationTableQuery(
    {
      page_size: page.pageSize,
      page_number: page.pageNumber,
      tag_level_list: pitchLevel,
      penetration_grade: penetrationGrade,
      company_id: companyId,
    },
    { skip: !companyId },
  );
  const columns = useMemo(() => tableData?.columns.map(item => {
    if (item.dataIndex === 'index') {
      return {
        width: 70,
        ...item,
      };
    }
    if (item.dataIndex === 'level') {
      return {
        ...item,
        width: 100,
        ellipsis: true,
        filterIcon: <FilterIcon />,
        filteredValue: pitchLevel?.length > 0 ? pitchLevel : ['all'],
        filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
        filters: levelList,
        filterResetToDefaultFilteredValue: true,
      };
    }
    if (item.dataIndex === 'penetration_grade') {
      return {
        ...item,
        width: 140,
        ellipsis: true,
        filterIcon: <FilterIcon />,
        filteredValue: penetrationGrade,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, filters, clearFilters }) => (
          <div className={style.list}>
            <Radio.Group
              value={selectedKeys}
              options={filters}
              style={{ display: 'block' }}
              onChange={e => {
                setSelectedKeys(e.target.value);
                confirm();
              }}
            >
              <Space direction="vertical">
                {
                  filters.map(it => <Radio key={it.value} value={it.value}>{it.label}</Radio>)
                }
              </Space>
            </Radio.Group>
          </div>
        ),
        filters: [{ label: '不限', value: '1.3', text: '不限' }, { label: '一级', value: '1', text: '一级' }, { label: '二级', value: '2', text: '二级' }, { label: '三级', value: '3', text: '三级' }],
        filterResetToDefaultFilteredValue: true,
      };
    }
    if (item.dataIndex === 'year') {
      return {
        width: 100,
        ...item,
      };
    }
    if (item.dataIndex === 'company_name') {
      return {
        width: 300,
        ...item,
      };
    }
    return item;
  }), [tableData]);
  const tableEl = useMemo(() => {
    if (!tableData || tableData?.dataSource) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={columns}
          title={() => (
            <>
              <span className={style.tableTitle}>{tableData?.title}</span>
              <Popover content='穿透该企业1、2、3级子公司资质情况' placement="right" overlayClassName={style.pop}>
                <QuestionCircleOutlined />
              </Popover>
              <span className={style.count}>{tableData?.count}</span>
            </>
          )}
          strip
          locale={{ emptyText: ' ' }}
          loading={tableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }, filter) => {
            setPitchLevel(filter.level);
            setPenetrationGrade(filter.penetration_grade);
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [tableData]);
  return (
    <Container>
      {
        tableEl
      }
    </Container>
  );
};

export default AptitudesStrike;
