import React, { useState } from 'react';
import { Table } from '@sqke/design';
import { useGetDetailsSoftwarePublicationsQuery } from '../../../../../service/companyDetail';
import Container from '../../Container';
import TableNoData from '../../TableNoData';

function SoftwareLiterature(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: isTableFetching } = useGetDetailsSoftwarePublicationsQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  return (
    <Container id="申请软著" num={tableData?.count || 0}>
      <Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={tableData?.columns}
        strip
        loading={isTableFetching}
        pagination={{
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        locale={{ emptyText: () => <TableNoData /> }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />
    </Container>
  );
}

export default SoftwareLiterature;
