import React from 'react';
import style from './index.module.less';
// import close from '../../../assets/images/specializedAndNew/close.png';
// import close from '../../../assets/images/specializedAndNew/close.png';
// import close from '../../../assets/images/specializedAndNew/close.png';

const PermissionDenied = ({ close }) => (
  <div className={style.box}>
    <div className={style.icon} onClick={() => { close(false); }} />
    <div className={style.text} />
    <div className={style.phone}>如需开通请联系工作人员：17200309680</div>
  </div>
);

export default PermissionDenied;
