import React, { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import TrabeculaCharts from '../../../../../components/TrabeculaCharts';
import { useGetRegisteredCapitalIncreaseQuery } from '../../../../../service/tool';
// import Treemap from '../../../../../components/Charts/TreeMap';

import style from './index.module.less';

// const data = [
//   { age: '5年以上', proportion: '15%' },
//   { age: '4-5年', proportion: '20%' },
//   { age: '3-4年', proportion: '50%' },
//   { age: '2-3年', proportion: '17%' },
//   { age: '1-2年', proportion: '15%' },
// ];

const terrData = [
  {
    name: '北京市通州区人民政府',
    depth: 0,
    value: 2020,
    unit: '条',
    children: [],
  },
  {
    name: '北京市东城区人民政府',
    depth: 0,
    value: 1729,
    unit: '条',
    children: [],
  },
  {
    name: '北京市怀柔区人民政府',
    depth: 0,
    value: 1393,
    unit: '条',
    children: [],
  },
  {
    name: '北京市平谷区人民政府',
    depth: 0,
    value: 1351,
    unit: '条',
    children: [],
  },
  {
    name: '北京市经济和信息化局',
    depth: 0,
    value: 1311,
    unit: '条',
    children: [],
  },
  {
    name: '北京市海淀区人民政府',
    depth: 0,
    value: 1213,
    unit: '条',
    children: [],
  },
  {
    name: '北京市石景山区人民政府',
    depth: 0,
    value: 1199,
    unit: '条',
    children: [],
  },
  {
    name: '北京市科学技术委员会',
    depth: 0,
    value: 1156,
    unit: '条',
    children: [],
  },
  {
    name: '北京市大兴区人民政府',
    depth: 0,
    value: 1149,
    unit: '条',
    children: [],
  },
  {
    name: '北京市延庆区人民政府',
    depth: 0,
    value: 1029,
    unit: '条',
    children: [],
  },
  {
    name: '北京市投资促进服务中心',
    depth: 0,
    value: 1001,
    unit: '条',
    children: [],
  },
  {
    name: '北京市顺义区人民政府',
    depth: 0,
    value: 993,
    unit: '条',
    children: [],
  },
  {
    name: '北京市人力资源和社会保障局',
    depth: 0,
    value: 983,
    unit: '条',
    children: [],
  },
  {
    name: '北京市发展和改革委员会',
    depth: 0,
    value: 916,
    unit: '条',
    children: [],
  },
  {
    name: '北京市商务局',
    depth: 0,
    value: 830,
    unit: '条',
    children: [],
  },
  {
    name: '北京市房山区人民政府',
    depth: 0,
    value: 640,
    unit: '条',
    children: [],
  },
  {
    name: '北京市朝阳区人民政府',
    depth: 0,
    value: 635,
    unit: '条',
    children: [],
  },
  {
    name: '北京市财政局',
    depth: 0,
    value: 575,
    unit: '条',
    children: [],
  },
  {
    name: '北京市人民政府',
    depth: 0,
    value: 451,
    unit: '条',
    children: [],
  },
  {
    name: '北京市丰台区人民政府',
    depth: 0,
    value: 442,
    unit: '条',
    children: [],
  },
  {
    name: '北京市昌平区人民政府',
    depth: 0,
    value: 227,
    unit: '条',
    children: [],
  },
  {
    name: '北京市门头沟区人民政府',
    depth: 0,
    value: 190,
    unit: '条',
    children: [],
  },
  {
    name: '北京市西城区人民政府',
    depth: 0,
    value: 180,
    unit: '条',
    children: [],
  },
  {
    name: '北京经济技术开发区',
    depth: 0,
    value: 103,
    unit: '条',
    children: [],
  },
  {
    name: '中关村科技园区管理委员会',
    depth: 0,
    value: 73,
    unit: '条',
    children: [],
  },
  {
    name: '北京经济技术开发区管理委员会',
    depth: 0,
    value: 31,
    unit: '条',
    children: [],
  },
  {
    name: '北京市科学技术委员会、中关村科技园区管理委员会',
    depth: 0,
    value: 28,
    unit: '条',
    children: [],
  },
  {
    name: '中关村国家自主创新示范区',
    depth: 0,
    value: 20,
    unit: '条',
    children: [],
  },
  {
    name: '北京市广播电视局',
    depth: 0,
    value: 20,
    unit: '条',
    children: [],
  },
  {
    name: '国务院/中央人民政府',
    depth: 0,
    value: 3,
    unit: '条',
    children: [],
  },
];

const Portrayal = ({ id, name, data, isFetching }) => {
  const { title, median, average, series, self } = data || {};
  const [top, setTop] = useState(0);
  const history = useHistory();
  const params = new URLSearchParams();
  params.set('id', id);
  params.set('name', name);
  const goToCompanyInfo = () => {
    history.push({
      pathname: '/tool/companyTest/companyInfo',
      search: params.toString(),
    });
  };
  const { data: registeredCapitalIncreaseData, isFetching: registeredCapitalIncreaseFetching } = useGetRegisteredCapitalIncreaseQuery();

  return (
    <div className={style.portrayal}>
      <div className={style.header}>
        <div className={style.portrayalLogo} />
        <div className={style.title}>
          <div>高企画像</div>
        &nbsp;
          <div>
            <QuestionCircleOutlined
              style={{ fontSize: '14px', color: '#fff' }}
            />
          &nbsp;&nbsp;
            <span className={style.hint}>
              统计近两年首次申请成为高新技术企业时的各项指标生成画像
            </span>
          </div>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.companyAge}>
          <div>{title || '-'}</div>
          <div>
            <div>
              <span>成立年份均值</span>
              <span>{average || '-'}</span>
            </div>
            <div>
              <span>成立年份中位数</span>
              <span>{median || '-'}</span>
            </div>
          </div>
          <Spin spinning={isFetching}>
            <div className={style.companyAgeCharts}>
              <div className={style.trabeculaCharts}>
                <TrabeculaCharts
                  data={series?.[0]?.data}
                  height={32}
                  percent={0.214}
                  setTop={setTop}
                  locationIndex={self?.index}
                />
              </div>
              <div className={style.myLocation} style={{ paddingTop: `${top}px` }}>
                <div className={style.sign}>
                  <span className={style.line} />
                  <span className={style.lineText}>我的位置</span>
                  <span className={style.line} />
                  <span className={style.arrows} />
                </div>
                <div className={style.describe}>
                  成立
                  { self?.date || '-'}
                  ,
                  超过
                  {self?.surpass || '-'}
                  高新技术企业
                </div>
              </div>
            </div>
          </Spin>

        </div>
        <div className={style.industrialDistribution}>
          {/* <div>产业分布</div>
          <div className={style.distribution}>
            <div className={style.treeMap}>
              <Treemap data={terrData} isFetching={false} proportion="100%" />
            </div>
            <div className={style.myLocation} style={{ paddingTop: '84px' }}>
              <div className={style.sign}>
                <span className={style.line} />
                <span className={style.lineText}>我的位置</span>
                <span className={style.line} />
                <span className={style.arrows} />
              </div>
              <div className={style.describe}>
                成立3年4个月，超过20%高新技术企业
              </div>
            </div>
          </div> */}
          <Spin spinning={registeredCapitalIncreaseFetching}>
            <div className={style.strip}>
              <div>{registeredCapitalIncreaseData?.title}</div>
              <div className={style.stripChart}>
                {registeredCapitalIncreaseData?.series[0].data.map((item, index) => (
                  <div key={index}>
                    <span>{item.name}</span>
                    <div>
                      <span>
                        {item.value}
                        家
                      </span>
                      <span>
                        {item.proportion}
                        %
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Spin>
        </div>
        <div className={style.btn}>
          <button type="button" onClick={goToCompanyInfo}>
            完善信息，对比特征生成报告
          </button>
        </div>
      </div>
    </div>
  );
};

export default Portrayal;
