/**
 新版分析引擎报告相关接口
*/

import { apiSlice } from '../slices/api';

const REPORT = '/report';
const ONLYOFFICE = '/onlyOffice';

export const reportPageApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getReportMsg: builder.query({
      query(arg) {
        return {
          url: `${REPORT}/find_report_info`,
          params: arg,
        };
      },
    }),
    getNewAnalysisMonitorReport: builder.query({
      query(arg) {
        return {
          url: `${ONLYOFFICE}/new_analysis_monitor_report`,
          method: 'post',
          body: arg,
        };
      },
    }),
    downloadReport: builder.query({
      query(arg) {
        return {
          url: `${ONLYOFFICE}/download`,
          params: arg,
        };
      },
    }),
  }),
});

export const {
  useGetReportMsgQuery,
  useLazyGetNewAnalysisMonitorReportQuery,
  useLazyDownloadReportQuery,
} = reportPageApi;
