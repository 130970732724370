/*
 *Description: 柱线图xAxis,yAXIS校验函数
 *Date: 2021/10/27
*/
import { BarAndLineChartProps } from '../basicsType';

/**
 * 去除无效数据
 */
export function handleData(xAxis: BarAndLineChartProps['data']['xAxis'] = [], yAxis: BarAndLineChartProps['data']['yAxis'] = []) {
  const handledXAxis: string[] = [];
  const handledYAxis: BarAndLineChartProps['data']['yAxis'] = [];
  const yValues: (string | number)[][] = [];

  xAxis.forEach((item, index) => {
    if (item && yAxis?.every(yItem => typeof yItem.value[index] !== 'undefined')) {
      handledXAxis.push(item);
      yAxis.forEach((yItem, yIndex) => {
        if (yValues[yIndex]) {
          yValues[yIndex].push(yItem.value[index]);
        } else {
          yValues[yIndex] = [yItem.value[index]];
        }
      });
    }
  });
  yValues.forEach((_item, index) => {
    if (yValues[index].length) { // 有数据才加入
      handledYAxis[index] = { ...yAxis[index] };
    }
    handledYAxis[index].value = yValues[index] || [];
  });
  return {
    x: handledXAxis,
    y: handledYAxis,
  };
}
