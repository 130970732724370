import { cloneDeep } from 'lodash';

const DEFAULT_NODE = {
  fontSize: 16,
  color: '#333',
  borderWidth: 1,
  borderColor: '#1961F5',
  borderRadius: 4,
  backgroundColor: '#fff',
};
const DISABLED_STYLE = {
  fontSize: 16,
  borderWidth: 1,
  borderRadius: 4,
  backgroundColor: '#FFFFFF',
  borderColor: '#1961F5',
  color: '#333333',
};

export const PREDEFINED_STYLE = [
  {
    padding: [16, 6],
    height: 32,
    fontSize: 32,
  },
  {
    padding: [6, 4],
    height: 24,
    fontSize: 24,
  },
  {
    padding: [4, 3],
    height: 24,
    fontSize: 16,
  },
];

const measureTextWidth = (ctx, size, text) => {
  ctx.save();
  ctx.font = `${size}px "Pingfang SC", "Microsoft Yahei", sans-serif`;
  const textWidth = ctx.measureText(text).width;
  ctx.restore();
  return textWidth;
};

export const clamp = (width, minWidth, maxWidth) => {
  let _w = width;
  if (typeof maxWidth === 'number' && _w > maxWidth) {
    _w = maxWidth;
  }
  if (typeof minWidth === 'number' && _w < minWidth) {
    _w = minWidth;
  }
  return _w;
};

const measureWidth = (ctx, name, {
  fontSize,
  padding,
}) => {
  const hPadding = padding[1] * 2;
  const textWidth = measureTextWidth(ctx, fontSize, name);
  const w = hPadding + textWidth;
  const _w = clamp(w, 80, 200);
  return _w;
};

// add depth, width, height, outerLineWidth
//
export const dataTransform = (data, ctx, { nodeStyles, getName, getDisabled, getValue, getId, maxShowDepth = 2, disabledStyle } = {}) => {
  const setNodeStyle = (item, depth) => {
    if (!ctx) return null;
    // 每个矩形的形状
    const rectConfig = {
      ...PREDEFINED_STYLE[clamp(depth, 0, PREDEFINED_STYLE.length - 1)],
      ...((nodeStyles && nodeStyles.length > 0 && item.isBright) ? (
        nodeStyles[clamp(depth, 0, nodeStyles.length - 1)]
      ) : DEFAULT_NODE),
    };
    // 给予预定义的样式
    // TODO: 调整此部分的最大和最小值
    item.width = measureWidth(ctx, item.name, {
      fontSize: rectConfig.fontSize,
      padding: rectConfig.padding,
    });
    if (item.level === 1 && item.is_good === '1') {
      rectConfig.backgroundColor = '#EE964B';
    } else if (item.level > 1 && item.is_good === '1') {
      rectConfig.borderColor = '#EE964B';
    }
    item.height = rectConfig.height;
    return {
      ...rectConfig,
      ...item,
      collapsed: depth >= maxShowDepth,
    };
  };
  // 深拷贝data，并将其数据转换为 G6 支持的格式
  let _data = cloneDeep(data);
  _data.id = getId(_data);
  _data.name = getName(_data);
  _data.value = getValue(_data);
  _data.depth = 0;
  // _data.disabled = getDisabled(_data);
  _data = setNodeStyle(_data, 0);
  const arr = [_data];
  while (arr.length > 0) {
    const p = arr.pop();
    const { depth } = p;
    if (Array.isArray(p.children) && p.children.length > 0) {
      const children = p.children.map(item => {
        item.id = getId(item);
        item.name = getName(item);
        item.value = getValue(item);
        item.depth = depth + 1;
        // item.disabled = getDisabled(item);
        return setNodeStyle(item, depth + 1, ctx);
      });
      arr.push(...children);
      p.children = children;
    }
  }

  return _data;
};

export const findNode = (root, predicate) => {
  const arr = [root];
  while (arr.length > 0) {
    const p = arr.pop();
    if (predicate(p)) return p;
    arr.push(...p.children);
  }
  return undefined;
};

export const findNodes = (root, predicate) => {
  const result = [];
  const arr = [root];
  while (arr.length > 0) {
    const p = arr.pop();
    if (predicate(p)) result.push(p);
    arr.push(...p.children);
  }
  return result;
};
