import React from 'react';
import { useLocation } from 'react-router';

function SchemeMini(props) {
  const location = useLocation();
  const urlParam = location.search.replace('?', '').split('&');
  const result = {};
  urlParam.forEach(item => {
    const data = item.split('=');
    result[data[0]] = data[1] || '';
  });
  localStorage.setItem('token', `Bearer ${result.token}`);
  const msg = window.btoa(window.encodeURIComponent(JSON.stringify({
    curTemplateId: result.curTemplateId,
    id: result.id,
    name: window.decodeURIComponent(result.name) || null,
    area: window.decodeURIComponent(result.area) || null,
    areaKey: result.areaKey ? [result.areaKey] : null,
    industry: window.decodeURIComponent(result.industry) || null,
    industryKey: result.industryKey ? [result.industryKey] : null,
    forMini: true,
    miniSharing: result.miniSharing || false,
  })));

  if (result.android) {
    const { android, url } = result;
    props.history.replace(`/scheme?msg=${msg}&android=${android}&url=${url}`);
  } else {
    props.history.replace(`/scheme?msg=${msg}`);
  }

  return (
    <div />
  );
}

export default SchemeMini;
