import * as CONSTS from '../../consts';

export const modeStyle = {
  light: {
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',

    xAxisLabelColor: '#777A7A',
    xAxisLineColor: 'rgba(0, 0, 0, 0.45)',
    yAxisLabel: {
      color: '#777A7A',
    },
    yNameTextStyle: {
      color: '#919191',
    },
    ySplitLine: {
      color: '#eee',
    },
    series: {
      label: {
        color: '#262c35',
      },
    },
    itemStyle: {
      line: {
        color: '#A4C3B2',
        borderWidth: 0.5,
        borderColor: '#fff',
        colorList: ['#EAD1C1', '#CBC7DB', '#EE964B', '#D5671D', '#CB997E'],
      },
      bar: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0, color: '#4E7293',
            },
            {
              offset: 1, color: '#4E7293',
            },
          ],
        },
        colorList: ['#0D3B66', '#3E5C76', '#A9BDD5', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#CCCCBA', '#FFF5E1', '#EAD1C1', '#CBC7DB'],
        colorList1: ['#0D3B66', '#3E5C76', '#A9BDD5', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#CCCCBA', '#FFF5E1', '#EAD1C1', '#CBC7DB'],
      },
    },
    legendTextColor: '#8C8C8C',
  },
  dark: {
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
    xAxisLabelColor: '#B4B4B4',
    xAxisLineColor: '#000000',

    yAxisLabel: {
      color: '#B4B4B4',
    },
    yNameTextStyle: {
      color: '#B4B4B4',
    },
    ySplitLine: {
      color: '#555555',
    },
    series: {
      label: {
        color: '#B4B4B4',
      },
    },
    itemStyle: {
      line: {
        color: '#FFC52A',
        borderWidth: 1,
        borderColor: '#fff',
        colorList: ['#FFC52A', '#D35C35', '#390D97', '#E65226', '#FEC52A'],
      },
      bar: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 0.95,
          colorStops: [
            {
              offset: 0, color: 'rgba(29, 22, 244, 1)',
            },
            {
              offset: 1, color: 'rgba(3, 0, 30, 1)',
            },
          ],
        },
        colorList: ['rgba(29, 22, 244, 1)', 'rgba(0, 242, 254, 1)', '#5170CA', '#390D97', '#E65226', '#FEC52A'],
        colorList1: ['rgba(3, 0, 30, 1)', 'rgba(3, 0, 30, 1)', 'rgba(3, 0, 30, 1)', 'rgba(3, 0, 30, 1)', 'rgba(3, 0, 30, 1)', 'rgba(3, 0, 30, 1)'],
      },
    },
    legendTextColor: '#8C8C8C',
  },
};
export const chartFontSize = {
  small: {
    xFontSize: 12,
    yFontSize: 12,
    labelFontSize: 8,
    legnedFontSize: 10,
  },
  middle: {
    xFontSize: 14,
    yFontSize: 14,
    labelFontSize: 12,
    legnedFontSize: 12,
  },
  large: {
    xFontSize: 16,
    yFontSize: 16,
    labelFontSize: 14,
    legnedFontSize: 14,
  },
};
