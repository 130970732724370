import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
// import OrientTree from '../OrientTree';
import OrientTree from '../../../../components/OrientTree';
import ZoomButton from '../ZoomButton';
import Button from '../Button';
import styles from './index.module.less';
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';
import Underline from '../../../../components/Underline';
import InvestmentOrganTable from '../InvestmentOrganTable';
import { StandardModal } from '../Modal';
import OrganCompanyTable from '../OrganCompanyTable';
import {
  useGetInvestmentInstitutionsChartQuery,
  useGetInvestmentInstitutionsSumNumberQuery,
} from '../../../../service/industry-channel';
import { openNewPage } from '../../../../utils/urlMethods';

function InvestmentOrgan({ industryCode, industryName = '' }) {
  const [select, setSelect] = useState('');
  const [count, setCount] = useState(0);
  const [zoom, setZoom] = useState(0.8);
  const [isTreeDropdownOpen, setIsTreeDropdownOpen] = useState(true);
  const [screenCount, setScreenCount] = useState(0);
  const [investorName, setInvestorName] = useState('');
  const [organTableState, setOrganTableState] = useState({
    pageSize: 20,
    pageNumber: 1,
  });
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState({
    visible: false,
    filteredInfo: {},
  });
  const { data: orientData, isFetching } = useGetInvestmentInstitutionsChartQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: organCount, isFetching: isNumFetching } = useGetInvestmentInstitutionsSumNumberQuery({
    industry_code: industryCode,
    link_code: '',
  }, {
    skip: !industryCode,
  });

  const onEvents = params => {
    if (params.data?.level !== 3 && params.data?.code) {
      setSelect(params.data?.code);
      setInvestorName('');
      setIsTreeDropdownOpen(true);
    }
    if (params.data?.level === 3 && params.data?.name) {
      setSelect('');
      setInvestorName(params.data?.name);
      setIsTreeDropdownOpen(true);
    }
  };

  useEffect(() => {
    setSelect(industryCode);
  }, [industryCode]);
  return (
    <div className={styles.all}>
      <div className={styles.header}>
        <Title title="投资机构" />
        <div
          className={styles.buttons}
        >
          <ZoomButton
            zoom={zoom}
            minZoom={0.1}
            maxZoom={2}
            className={styles.button}
            onChange={z => {
              const _z = Number(z?.toFixed(2));
              setZoom(_z);
            }}
          />
        </div>
      </div>
      <div className={classnames(styles.investmentOrgan, { 'grey-out': isFetching })}>
        {
          orientData && <OrientTree data={orientData} onEvents={onEvents} select={select} zoom={zoom} />
        }
        <div
          className={classnames(
            styles.list,
            { [styles.hidden]: !isTreeDropdownOpen },
          )}
        >
          <div className={styles.overview}>
            <Subtitle
              className={styles.subtitle}
              description="共计"
              value={organCount}
              unit="家投资机构"
            />
            {
              industryCode !== select && !isNumFetching && (
                <Subtitle
                  className={styles.subtitle}
                  description="筛选结果"
                  value={screenCount}
                  unit="家"
                />
              )
            }
          </div>
          <Underline />
          <InvestmentOrganTable
            className={styles.table}
            industryCode={industryCode}
            investorName={investorName}
            linkCode={select}
            getCount={setScreenCount}
            pageSize={organTableState.pageSize}
            pageNumber={organTableState.pageNumber}
            onChange={({ current, pageSize }) => {
              setOrganTableState({
                ...organTableState,
                pageSize,
                pageNumber: current,
              });
            }}
            onClick={({ record, column }) => {
              const { investor, specialization, IPO: ipo, company_num: companyNum } = record;
              const { dataIndex } = column;
              setMapCompanyListQueryState(prev => ({
                ...prev,
                companyType: dataIndex,
                investorName: investor,
                specialization,
                ipo,
                companyNum,
                visible: true,
              }));
            }}
            onCompanyClick={({ id: _id }) => {
              if (!_id) return;
              openNewPage('companyDetail', { id: _id });
            }}
          />
          <Button
            className={classnames(
              styles.toggleButton,
              {
                [styles.slim]: !isTreeDropdownOpen,
              },
            )}
            text={isTreeDropdownOpen ? '收起' : '展开'}
            onClick={() => setIsTreeDropdownOpen(!isTreeDropdownOpen)}
          />
        </div>
      </div>
      {
        mapCompanyListQueryState.visible && (
          <StandardModal
            className={styles.modal}
            header={
              <>
                {
                  mapCompanyListQueryState.companyType === 'specialization' && (
                    <div className={styles.modalTitle}>
                      <span>
                        本环节
                        <span style={{ color: '#0f2849', fontWeight: 'bold' }}>
                          {mapCompanyListQueryState.investorName}
                        </span>
                        所投专精特新项目列表&emsp;共
                        {mapCompanyListQueryState.specialization}
                        个
                      </span>
                      {/* {
                        count !== mapCompanyListQueryState.specialization && (
                          <span>
                            筛选结果
                            {count}
                            个
                          </span>
                        )
                      } */}
                    </div>
                  )
                }
                {
                  mapCompanyListQueryState.companyType === 'IPO' && (
                    <div className={styles.modalTitle}>
                      <span>
                        本环节
                        <span style={{ color: '#0f2849', fontWeight: 'bold' }}>
                          {mapCompanyListQueryState.investorName}
                        </span>
                        所投IPO项目列表&emsp;共
                        {mapCompanyListQueryState.ipo}
                        个
                      </span>
                      {/* {
                        count !== mapCompanyListQueryState.ipo && (
                          <span>
                            筛选结果
                            {count}
                            个
                          </span>
                        )
                      } */}
                    </div>
                  )
                }
                {
                  mapCompanyListQueryState.companyType === 'company_num' && (
                    <div className={styles.modalTitle}>
                      <span>
                        本环节
                        <span style={{ color: '#0f2849', fontWeight: 'bold' }}>
                          {mapCompanyListQueryState.investorName}
                        </span>
                        所投项目列表&emsp;共
                        {mapCompanyListQueryState.companyNum}
                        个
                      </span>
                      {/* {
                        count !== mapCompanyListQueryState.companyNum && (
                          <span>
                            筛选结果
                            {count}
                            个
                          </span>
                        )
                      } */}
                    </div>
                  )
                }
              </>
            }
            onClose={() => {
              setMapCompanyListQueryState(prev => ({
                ...prev,
                pageNumber: 1,
                filteredInfo: { round: ['all'] },
                visible: false,
              }));
            }}
          >
            <OrganCompanyTable
              className={styles.modalTable}
              industryCode={industryCode}
              getCount={setCount}
              investorName={mapCompanyListQueryState.investorName}
              companyType={mapCompanyListQueryState.companyType}
              linkCode={select}
              filteredInfo={mapCompanyListQueryState.filteredInfo}
              pageSize={mapCompanyListQueryState.pageSize}
              pageNumber={mapCompanyListQueryState.pageNumber}
              onChange={({ current, pageSize }, filters) => {
                setMapCompanyListQueryState(prev => ({
                  ...prev,
                  pageNumber: current,
                  pageSize,
                  filteredInfo: filters,
                }));
              }}
              onCompanyClick={({ company_id: _id }) => {
                if (!_id) return;
                openNewPage('companyDetail', { id: _id });
              }}
            />
          </StandardModal>
        )
      }
    </div>
  );
}

export default InvestmentOrgan;
