import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import style from './index.module.less';
import RadarChart from '../../../../components/IndustryCompanyDetail/CompanyContent/RadarChart';
import { useLazyGetRadarRiskAssessmentQuery, useLazyGetAssessingTrendsQuery, useLazyGetRiskAssessmentQuery, useLazyGetValuationQuery, useLazyGetSearchValuationRecordCompanyQuery, useLazyGetAddValuationRecordQuery } from '../../../../service/tool';
import Underline from '../../../../components/Underline';
import Table from '../../../../components/Table';

const riskColumns = [
  {
    title: '维度',
    dataIndex: 'dimension',
    key: 'dimension',
    ellipsis: true,
  },
  {
    title: '风险等级',
    dataIndex: 'level',
    key: 'level',
    ellipsis: true,
  },
  {
    title: '建议',
    dataIndex: 'advice',
    key: 'advice',
    ellipsis: true,
  },
];

const recordColumns = [
  {
    // title: '维度',
    dataIndex: 'user_name',
    key: 'user_name',
    ellipsis: true,
  },
  {
    // title: '风险等级',
    dataIndex: 'valuation_time',
    key: 'valuation_time',
    ellipsis: true,
  },
  {
    // title: '建议',
    dataIndex: 'result',
    key: 'result',
    ellipsis: true,
  },
  {
    // title: '建议',
    dataIndex: 'industry',
    key: 'industry',
    ellipsis: true,
  },
];

const data2 = [
  { dimension: '经营风险', level: '低', advice: '持续合法正规经营' },
  { dimension: '注资变更', level: '中', advice: '企业历史有资本减持，谨慎变更资本' },
  { dimension: '行政处罚', level: '低', advice: '持续合法正规经营' },
  { dimension: 'ffff', level: '低', advice: '持续合法正规经营' },
  { dimension: '1111111', level: '中', advice: '企业历史抽查结果不合格，请合法经营' },
  { dimension: '2222222', level: '低', advice: '持续合法正规经营' },
  { dimension: '33333333', level: '中', advice: '企业历史有资本减持，谨慎变更资本' },
  { dimension: '行政444444处罚', level: '低', advice: '持续合法正规经营' },
  { dimension: '666666', level: '低', advice: '持续合法正规经营' },
  { dimension: '7777777', level: '中', advice: '企业历史抽查结果不合格，请合法经营' },
  { dimension: '经营风险', level: '低', advice: '持续合法正规经营' },
  { dimension: '注资变更', level: '中', advice: '企业历史有资本减持，谨慎变更资本' },
  { dimension: '7777777', level: '低', advice: '持续合法正规经营' },
  { dimension: '严重违法', level: '低', advice: '持续合法正规经营' },
  { dimension: '444444555566666', level: '中', advice: '企业历史抽查结果不合格，请合法经营' },
  { dimension: '经营风险', level: '低', advice: '持续合法正规经营' },
  { dimension: '4444444', level: '中', advice: '企业历史有资本减持，谨慎变更资本' },
  { dimension: '行政处罚', level: '低', advice: '持续合法正规经营' },
  { dimension: '9796543', level: '低', advice: '持续合法正规经营' },
  { dimension: '抽查不合格', level: '中', advice: '企业历史抽查结果不合格，请合法经营' },
  { dimension: '经营风险', level: '低', advice: '持续合法正规经营' },
  { dimension: '注资变更', level: '中', advice: '企业历史有资本减持，谨慎变更资本' },
  { dimension: '33333333', level: '低', advice: '持续合法正规经营' },
  { dimension: '严重违法', level: '低', advice: '持续合法正规经营' },
  { dimension: '抽查不合格', level: '中', advice: '企业历史抽查结果不合格，请合法经营' },
  { dimension: '7777777', level: '低', advice: '持续合法正规经营' },
  { dimension: '注资变更', level: '中', advice: '企业历史有资本减持，谨慎变更资本' },
  { dimension: '行政处罚', level: '低', advice: '持续合法正规经营' },
  { dimension: '严重违法', level: '低', advice: '持续合法正规经营' },
  { dimension: '抽查不合格', level: '中', advice: '企业历史抽查结果不合格，请合法经营' },
];

const Index = (props) => {
  const [riskDataSource, setRiskDataSource] = useState([]);
  const [recordDataSource, setRecordDataSource] = useState([]);
  const [radarData, setRadarData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [getRadarRiskAssessmentQuery] = useLazyGetRadarRiskAssessmentQuery();
  const [getAssessingTrendsQuery] = useLazyGetAssessingTrendsQuery();
  const [getRiskAssessmentQuery] = useLazyGetRiskAssessmentQuery();
  const [getValuationQuery] = useLazyGetValuationQuery();
  const [getSearchValuationRecordCompanyQuery] = useLazyGetSearchValuationRecordCompanyQuery();
  const [getAddValuationRecordQuery] = useLazyGetAddValuationRecordQuery();
  const params = new URLSearchParams(window.location.href.split('?')[1]);

  const getRadarRiskAssessment = (id) => {
    getRadarRiskAssessmentQuery({ company_id: id }).then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        setRadarData(data);
      } else {
        message.error('获取雷达图失败');
      }
    }).catch(err => console.log(err));
  };
  const getAddValuationRecord = (req) => {
    getAddValuationRecordQuery(req).then(res => {
      const { status } = res;
      if (status !== 'fulfilled') {
        message.error('添加失败');
      }
    }).catch(err => console.log(err));
  };
  // const getAssessingTrends = (id) => {
  //   getAssessingTrendsQuery({ company_id: id }).then(res => {
  //     console.log(res);
  //   });
  // };
  const getRiskAssessment = (id) => {
    getRiskAssessmentQuery({ company_id: id }).then(res => {
      const { status, data } = res;
      if (status === 'fulfilled') {
        setRiskDataSource(data);
      } else {
        message.error('获取风险列表失败');
      }
    }).catch(err => console.log(err));
  };
  const getValuation = (companyAppraisementReq, id) => {
    getValuationQuery(companyAppraisementReq).then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        setCompanyInfo(data);
        getAddValuationRecord({
          company_name: data.company_name,
          result: data.market_value.split(' ')[1],
          company_id: id,
          industry: data.industry,
        });
      } else {
        message.error('估值失败');
      }
    }).catch(err => console.log(err));
  };
  const getSearchValuationRecordCompany = (id) => {
    getSearchValuationRecordCompanyQuery({ company_id: id }).then(res => {
      const { data: { dataSource }, status } = res;
      if (status === 'fulfilled') {
        setRecordDataSource(dataSource);
      } else {
        message.error('获取企业查询记录失败');
      }
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    const id = params.get('id');
    const name = params.get('name');
    const result = params.get('result');
    const time = params.get('time');
    const industry = params.get('industry');
    const min = params.get('min');
    const max = params.get('max');
    const EV = params.get('EV');

    const companyAppraisementReq = JSON.parse(localStorage.getItem('companyAppraisementReq'));
    if (id) {
      getRadarRiskAssessment(id);
      // getAssessingTrends(id);
      getRiskAssessment(id);
      getSearchValuationRecordCompany(id);
    }
    if (name) {
      setCompanyInfo({
        valuation_time: time,
        market_value: result,
        company_name: name,
        EV,
        EV_top: max,
        EV_bottom: min,
        industry,
      });
    } else if (companyAppraisementReq && Object.keys(companyAppraisementReq)?.length > 0) {
      getValuation(companyAppraisementReq, id);
    }
  }, []);
  const refreshAppraisement = () => {
    const companyAppraisementReq = JSON.parse(localStorage.getItem('companyAppraisementReq'));
    const id = params.get('id');
    if (Object.keys(companyAppraisementReq)?.length > 0) {
      getValuation(companyAppraisementReq, id);
    }
  };
  return (
    <div className={style.box}>
      <div className={style.appraisement}>
        <div className={style.header}>
          <p>企业估值</p>
          <p>免费快速测算企业价值</p>
        </div>
        <div className={style.companyInfo}>
          <div>
            <div>
              <p />
            </div>
            <div>
              <p>{companyInfo?.company_name}</p>
              <ul>
                <li>
                  <span>所属行业</span>
                  <span>{companyInfo?.industry}</span>
                </li>
                <li>
                  <span>估值时间</span>
                  <span>{companyInfo?.valuation_time}</span>
                </li>
                <li>
                  {
                    companyInfo?.market_value && (
                    <>
                      <span>公司市值</span>
                      <span>{companyInfo?.market_value}</span>
                    </>
                    )
                  }
                </li>
              </ul>
            </div>
          </div>
          <div>
            <ul>
              <li>贵公司在估值基准日的股权价值</li>
              <li>
                <span onClick={refreshAppraisement} />
                <span onClick={refreshAppraisement}>重新评估</span>
              </li>
            </ul>
            <div>
              <div>
                <span>估值</span>
                <p>
                  <span>{companyInfo?.EV}</span>
                  {/* <span>亿</span> */}
                </p>
              </div>
              <div>
                <span>最小</span>
                <p>
                  <span>{companyInfo?.EV_top}</span>
                  {/* <span>亿</span> */}
                </p>
              </div>
              <div>
                <span>最大</span>
                <p>
                  <span>{companyInfo?.EV_bottom}</span>
                  {/* <span>亿</span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.risk}>
        <p>风险评估</p>
        <div className={style.riskInfo}>
          <div>
            {/* data={{ abscissa_list: ['区域拓展', '创新能力', '融资能力', '成长潜力', '信用风险'], detail_list: [{ ordinate: [60, 63.97, 77.95, 83.92, 100] }] }} */}
            {radarData && <RadarChart data={radarData} />}
          </div>
          <div>
            <Underline />
            <Table
              rowKey={(row) => row.dimension}
              columns={riskColumns}
              loading={!riskDataSource}
              dataSource={riskDataSource}
              pagination={{
                total: riskDataSource?.length,
                pageSize: 10,
                showQuickJumper: true,
              }}
            />
          </div>
        </div>
      </div>
      <div className={style.record}>
        <p>
          <span>评估记录</span>
          <span onClick={() => { props.history.push(`/tool/companyAppraisement/appraisement/appraisementRecord?id=${params.get('id')}`); }}>查看我的评估记录</span>
        </p>
        <Table
          rowKey={(row) => row.id}
          columns={recordColumns}
          dataSource={recordDataSource}
          pagination={{
            total: recordDataSource.length,
            pageSize: 10,
            showQuickJumper: true,
          }}
        />
        <div />
      </div>
    </div>
  );
};

export default Index;
