import {
  INSTITUTIONDETAIL,
  PATENTDETAIL,
} from '../utils/url';
import { get, post } from '../utils/request';

// 单个机构数据
const getOneInstitutionData = (param) => get(`${INSTITUTIONDETAIL}/institution_info`, {
  ...param,
});

// 机构专利数据
const getInstitutionTableList = (param) => post(`${PATENTDETAIL}/patent_table_list`, {
  ...param,
});

export {
  getOneInstitutionData,
  getInstitutionTableList,
};
