import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Popconfirm } from 'antd';
import style from './index.module.less';
import Underline from '../../../../../components/Underline';
import { useLazyGetUserCompanyContrastCollectQuery, useLazyDeleteUserCompanyContrastCollectQuery } from '../../../../../service/tool';
import Table from '../../../../../components/Table';

const Index = () => {
  const history = useHistory();
  const [dataSource, setDataSource] = useState(null);
  const [count, setCount] = useState(null);
  const [pageSize, setPageSize] = useState(24);
  const [page, setPage] = useState(1);
  const [getUserCompanyContrastCollectQuery] = useLazyGetUserCompanyContrastCollectQuery();
  const [deleteUserCompanyContrastCollectQuery] = useLazyDeleteUserCompanyContrastCollectQuery();

  const getUserCompanyContrastCollect = () => {
    getUserCompanyContrastCollectQuery({ page_size: pageSize, page_number: page }).then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        setDataSource(data.dataSource);
        setCount(data.count);
      } else {
        message.error('获取收藏列表失败');
      }
    }).catch(err => {
      console.log(err);
    });
  };
  const deleteUserCompanyContrastCollect = (id) => {
    deleteUserCompanyContrastCollectQuery({ record_id: id }).then(res => {
      const { status } = res;
      if (status === 'fulfilled') {
        message.info('删除列表成功');
        getUserCompanyContrastCollect();
      }
    }).catch(err => console.log(err));
  };
  const confirm = (id) => {
    deleteUserCompanyContrastCollect(id);
  };
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      ellipsis: true,
    },
    {
      title: '对标对象',
      dataIndex: 'company_name_str',
      key: 'company_name_str',
      ellipsis: true,
      width: 960,
    },
    {
      title: '操作',
      dataIndex: 'actibve',
      key: 'actibve',
      render: (text, row) => (
        <div className={style.active}>
          <button
            type="button"
            onClick={() => {
              localStorage.setItem('companyList', row.company_name_str);
              localStorage.setItem('companyIdList', row.company_id_str);
              localStorage.setItem('recordId', row.id);
              history.push('/tool/firmComparison');
            }}
          >
            查看
          </button>
          <Popconfirm
            title="是否删除此条收藏"
            onConfirm={() => confirm(row.id)}
            okText="是"
            cancelText="否"
          >
            <button type="button">删除</button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getUserCompanyContrastCollect();
  }, [pageSize, page]);

  const pagination = {
    total: count,
    current: page,
    pageSize,
    showQuickJumper: true,
    onChange: (page2, pageSize2) => {
      setPageSize(pageSize2);
      setPage(page2);
    },
  };
  return (
    <div className={style.box}>
      <header>
        <div>
          <p>企业对比对标记录</p>
          <p>
            共搜索到
            {count}
            条数据
          </p>
        </div>
        <button type="button" onClick={() => { history.push('/tool/firmComparison'); }}>
          <p />
          <p>返回</p>
        </button>
      </header>
      <Underline />
      <Table
        rowKey={(row) => row.id}
        columns={columns}
        // loading
        dataSource={dataSource}
        pagination={pagination}
      />
    </div>
  );
};

export default Index;
