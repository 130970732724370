import { useEffect, useState } from 'react';
import {
  useLazyGetParkFirstSpecialSubjectChartQuery,
  useLazyGetParkSecondSpecialSubjectChartQuery,
  useLazyGetParkFirstSpecialSubjectTableQuery,
  useLazyGetParkSecondSpecialSubjectTableQuery,
  useLazyGetParkFirstSpecialSubjectCountQuery,
  useLazyGetParkSecondSpecialSubjectCountQuery,
} from '../../../../../../../service/parkTool';

const useChartData = ({ code, key, type, parkTitle }) => {
  const [firstSpecialSubjectChart] = useLazyGetParkFirstSpecialSubjectChartQuery();
  const [secondSpecialSubjectChart] = useLazyGetParkSecondSpecialSubjectChartQuery();
  const [resData, setResData] = useState({});
  const [chartIsFetching, setChartIsFetching] = useState(true);
  const getChartData = () => {
    if (type === '一类专题') {
      return firstSpecialSubjectChart({
        area_key: parkTitle === '园区总览' ? code : undefined,
        park_id_list: parkTitle === '园区总览' ? undefined : [code],
        label: key,
      });
    }
    return secondSpecialSubjectChart({
      area_key: parkTitle === '园区总览' ? code : undefined,
      park_id_list: parkTitle === '园区总览' ? undefined : [code],
      label: key,
    });
  };
  useEffect(() => {
    setChartIsFetching(true);
    if (code && key) {
      getChartData(code, key).then(res => {
        setResData(res);
        setChartIsFetching(false);
      });
    }
  }, [code, key, type]);

  return { ...resData, chartIsFetching };
};

const useGetTable = (tableReq, type) => {
  const {
    code,
    label: key,
    level,
    special_tag: specialTag,
    industry_code_list: industryCodeList,
    sort_key: sortKey,
    sort_value: sortValue,
    page_size: pageSize,
    page_number: pageNumber,
    parkTitle,
  } = tableReq;
  const [firstSpecialSubjectTable] = useLazyGetParkFirstSpecialSubjectTableQuery();
  const [secondSpecialSubjectTable] = useLazyGetParkSecondSpecialSubjectTableQuery();
  const [tableData, setTableData] = useState({});
  const [tableIsFetching, setTableIsFetching] = useState(true);
  const getTableData = () => {
    if (type === '一类专题') {
      return firstSpecialSubjectTable({
        label: key,
        level,
        special_tag: specialTag,
        industry_code_list: industryCodeList,
        sort_key: sortKey,
        sort_value: sortValue,
        page_size: pageSize,
        page_number: pageNumber,
        area_key: parkTitle === '园区总览' ? code : undefined,
        park_id_list: parkTitle === '园区总览' ? undefined : [code],
      });
    }
    return secondSpecialSubjectTable(
      {
        label: key,
        level,
        special_tag: specialTag,
        industry_code_list: industryCodeList,
        sort_key: sortKey,
        sort_value: sortValue,
        page_size: pageSize,
        page_number: pageNumber,
        area_key: parkTitle === '园区总览' ? code : undefined,
        park_id_list: parkTitle === '园区总览' ? undefined : [code],
      },
    );
  };
  useEffect(() => {
    setTableIsFetching(true);
    const time = setTimeout(() => {
      if (code && key) {
        getTableData().then(res => {
          setTableData(res);
          setTableIsFetching(false);
        });
      }
    }, 10);
    return () => {
      clearInterval(time);
    };
  }, [code, key, type, level, specialTag, industryCodeList, sortKey, sortValue, pageSize, pageNumber]);

  return { ...tableData, tableIsFetching };
};

const useGetCountTitle = (tableCountReq, type) => {
  const [firstSpecialSubjectCount] = useLazyGetParkFirstSpecialSubjectCountQuery();
  const [secondSpecialSubjectCount] = useLazyGetParkSecondSpecialSubjectCountQuery();
  const { code, key, parkTitle } = tableCountReq;
  const [data, setData] = useState('');
  const getCount = () => {
    if (type === '一类专题') {
      return firstSpecialSubjectCount({
        area_key: parkTitle === '园区总览' ? code : undefined,
        park_id_list: parkTitle === '园区总览' ? undefined : [code],
        label: key,
      });
    }
    return secondSpecialSubjectCount({
      area_key: parkTitle === '园区总览' ? code : undefined,
      park_id_list: parkTitle === '园区总览' ? undefined : [code],
      label: key,
    });
  };
  useEffect(() => {
    if (code && key) {
      getCount().then(res => {
        setData(res.data);
      });
    }
  }, [type, code, key]);
  return {
    data,
  };
};
export {
  useChartData,
  useGetTable,
  useGetCountTitle,
};
