import React from 'react';
import classNames from 'classnames';
import style from './index.module.less';

/**
 *
 * @param {Array} list tag列表
 * @param {Function} setActive 改变选中的标签
 * @param {String} active 选中的tag
 * @returns {JSX.Element} tag组件
 */
const CompanyLevleTag = ({ list, setActive, active }) => (
  <div className={style.tagBox}>
    {
        list.map(item => (
          <div
            key={item.title}
            className={classNames(style.tag, { [style.active]: active === item.value })}
            onClick={() => { setActive(item.value); }}
          >
            {item.title}
          </div>
        ))
      }
  </div>
);

export default CompanyLevleTag;
