/*
 *Description: ScatterMapChart 应该是要和地图热力图一起用了
 *Date: Sun Nov 07 2021 15:11:37 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { point, featureCollection, Properties } from '@turf/helpers';
import pointsWithinPolygon from '@turf/points-within-polygon';
// @ts-ignore
import * as echarts from 'echarts';
// @ts-ignore
import { linearMap } from 'echarts/lib/util/number';
import { color } from 'zrender';
import { ScatterMapChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, getWordsWidth, toolTipFormatter } from '../../utils/tool';
import { get } from '../../utils/request';
// import { HKMap } from '../HeapMapMapChart/hongKong';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-scatter-map-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;
const ScatterMapChart = forwardRef((props: ScatterMapChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize, visualColors, labelFormat } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  const [registeredMap, setRegisterMap] = useState(false);

  // 九段线 echart自动加入。。。
  const mapName = data?.mapCode === '000000' ? 'china' : data?.mapCode;

  // const [outerPoint, setOuterPoint] = useState([]);
  useEffect(() => {
    setHanldeData(checkData(data || {}));
    if (!data?.mapCode) {
      return;
    }
    get(`${data.host}/${data.mapCode}.json`, {}).then(code => {
      echarts.registerMap(`${mapName}`, code);
      // echarts.registerMap(`${data.mapCode}`, data.region_border);
      setRegisterMap(true);
    }).finally(() => {
      setChartLoading(false);
    });
  }, [data]);

  useEffect(() => {
    if (!registeredMap) {
      return;
    }
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    function getUsedPoinsts() {
      const handlePoint = data?.scatterData.map(
        (item: any) => point([item.y, item.x], { ...item }),
      );

      const handlePoints = featureCollection(handlePoint as any);

      const curPonits = pointsWithinPolygon((handlePoints as any), ((data as any).region_border));
      return curPonits.features.map(item => item.properties);
    }

    let usedPoints: Properties[] = [];
    if (data?.scatterData?.length) {
      usedPoints = getUsedPoinsts();
    }

    let minScatter = Infinity;
    let maxScatter = -Infinity;
    let minHeap = Infinity;
    let maxHeap = -Infinity;

    const scatterColors = [
      '#4E7293',
      '#8ADBCB',
      '#9070aa',
    ];
    let countColor = 0;
    const itemStyleMap: Map<string, string> = new Map();
    const scatterData = (usedPoints || []).map(item => {
      minScatter = Math.min(minScatter, item?.value);
      maxScatter = Math.max(maxScatter, item?.value);
      if (!itemStyleMap.get(item?.name)) {
        itemStyleMap.set(item?.name, scatterColors[countColor % scatterColors.length]);
        countColor += 1;
      }
      return ({
        name: item?.name,
        value: [
          item?.y?.toFixed(6),
          item?.x?.toFixed(6),
          item?.value,
          // item.name,
        ],
        unit: item?.unit,
        itemStyle: {
          color: itemStyleMap.get(item?.name),
          opacity: 0.7,
          borderWidth: 0,
          borderColor: '#fff',
        },
      });
    });
    const series: any[] = [{
      // blendMode: 'source-atop',
      name: '',
      type: 'scatter',
      coordinateSystem: 'geo',
      itemStyle: {
        color: '#9070aa',
      },
      label: {
        show: false,
        formatter(params: any) {
          return params.data.value;
        },
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
        position: 'inside',
      },
      clip: true,
      data: scatterData,
      symbolSize(params: any) {
        if (!params) {
          return 0;
        }
        // console.log(params);
        return 12 * (params[2] / maxScatter) + 4;
      },
      showLegendSymbol: true,
      tooltip: {
        show: true,
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          if (!params.data) {
            return null;
          }
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([{
            ...params.data,
            value: params.data.value[2],
          }], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
    },
    {
      name: '',
      type: 'map',
      geoIndex: 0,
      data: data.heapData.map(item => {
        minHeap = Math.min(minHeap, item.value);
        maxHeap = Math.max(maxHeap, item.value);
        // itemStyle调整样式
        return item;
      }),
      label: {
        fontSize: 12,
        color: 'red',
        show: false,
      },
      selectedMode: false,
      showLegendSymbol: false,
      tooltip: {
        show: true,
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // console.log(params);
          // 模板自动生成可能需要自己手动改改
          if (!params.data) {
            return null;
          }
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
    },
    ];
    if (!data?.scatterData?.length) {
      series.shift();
    }
    if (!data.heapData?.length) {
      series.pop();
    }
    let graphics = {
      width: 0,
      height: 0,
      elements: [],
    };
    if (data?.scatterData?.length) {
      graphics = genLegend(itemStyleMap) as any;
    }

    const left = graphics.width;
    if (left === 0) {
      // left = 20; // 留点空间
    }
    const visualMap = [
      {
        show: !!data.heapData?.length,
        // 直接隐藏掉后续利用其数据自己画
        top: 1000000000000,
        left: 10000000000,
        type: props.visualType || 'piecewise',
        min: minHeap,
        max: maxHeap,
        itemWidth: 22,
        itemHeight: 6,
        itemGap: 2,
        textGap: 2,
        padding: 0,
        // orient: 'horizontal',
        align: 'left',
        text: ['  高', '  低'],
        showLabel: true,
        textStyle: {
          color: '#3E5C76',
          fontSize: 8,
        },
        formatter(from: number, to: number) {
          return `${from.toFixed(0)} - ${to.toFixed(0)}`;
        },
        realtime: false,
        // calculable: true,
        splitNumber: visualColors?.length || 5,
        inRange: {
          color: visualColors || ['#C54646', '#FFDA90', '#C2D8B5'].reverse(),
        },
        seriesIndex: series.findIndex(item => item.type === 'map'),
      },
    ];

    const geo = {
      layoutCenter: ['50%', '50%'],
      layoutSize: '80%',
      tooltip: {
        // show: true,
      },
      map: mapName,
      roam: false,
      itemStyle: {
        areaColor: '#FAFAFA',
        borderType: 'dashed',
        borderWidth: 1,
        borderColor: '#4E7293',
      },
      label: {
        show: false,
        fontSize: 12,
        formatter(params: any) {
          return params?.name;
        },
        rich: {
          small: {
            fontSize: 10,
          },
        },
      },
      select: {
        label: {
        },
      },
      emphasis: {
        label: {
        },
      },
    };
    if (label) {
      geo.label.show = true;
      if (typeof labelFormat === 'function') {
        geo.label.formatter = (params: any) => labelFormat(params, data.heapData);
      }
    }
    const options = {
      geo,
      graphic: {
        elements: graphics.elements,
        _scaterLegnedWidth: left,
      },
      legend: {
        show: false,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
        formatter() {
          return '';
        },
      },
      visualMap,
      tooltip: {
        show: true,
      },
      series,
    };

    setOption(options);
    if (!chartRef.current) {
      return;
    }
    function generateVisual() {
      const L = visualMap[0].splitNumber;
      const rangeColors = [...visualMap[0].inRange.color];
      const colorL = rangeColors.length;
      const parsedColors = new Array(colorL);
      rangeColors.forEach((item, index) => {
        parsedColors[index] = color.parse(item);
      });
      const itemWidth = 22;
      const itemHeight = 12;
      const gapBetweenItemAndText = 2;
      const fSize = 10;

      const groups = [] as any;
      for (let i = 0; i < L; i += 1) {
        const normalizeIndex = linearMap(i, [0, L - 1], [0, 1]);
        const colorMap = color.fastLerp(normalizeIndex, parsedColors);
        const colorStr = `rgba(${colorMap.join(',')})`;

        let text = '1';
        let textLeft = 0;

        if (i === L - 1) {
          text = maxHeap.toString();
          textLeft = itemWidth - getWordsWidth(text, { font: `${fSize}px sans-serif` });
          if (textLeft < 0) {
            textLeft = 0;
          }
        } else if (i === 0) {
          text = minHeap.toString();
        }
        const curLeft = i * itemWidth;
        const curBottom = 0;
        const group = {
          type: 'group',
          left: curLeft,
          bottom: curBottom,
          children: [
            {
              type: 'rect',
              shape: {
                x: 0,
                y: 0,
                width: itemWidth,
                height: itemHeight,
              },
              style: {
                fill: colorStr,
              },
            },
            {
              type: 'text',
              // bounding: 'row',
              textConfig: {
                inside: true,
              },
              x: textLeft,
              y: itemHeight + gapBetweenItemAndText,
              style: {
                text,
                fill: i === 0 || (i === L - 1) ? options.visualMap[0].textStyle.color : 'transparent',
                font: `${fSize}px sans-serif`,
              },
            },
          ],
        };

        groups.push(group);
      }
      const visualHeight = itemHeight + (gapBetweenItemAndText) + fSize;

      return {
        groups,
        height: visualHeight,
      };
    }
    const instance = chartRef.current.getEchartsInstance();
    const containerWidth = instance.getWidth();
    const containerHeight = instance.getHeight();
    const percentLeft = (left / containerWidth) * 100;
    options.geo.layoutSize = `${((containerWidth - left) / containerWidth) * 100}%`;
    // 若是50% left正好是左侧图形的一半 这样是为了保证地图不会覆盖到左边部分图形
    options.geo.layoutCenter = [`${50 + (percentLeft / 2)}%`, `${50}%`];
    // console.log(options.geo);
    // VISUAL MAP自定义
    const visualDiy = generateVisual();
    const visualBottom = 1;
    (options.graphic.elements as any).push({
      type: 'group',
      right: 10,
      bottom: visualBottom,
      children: visualDiy.groups,
    });
    const distanceBetweenMapAndVisual = 0;
    const visualHeight = visualDiy.height + distanceBetweenMapAndVisual + visualBottom;
    const per = (visualHeight / containerHeight) * 100;
    options.geo.layoutCenter[1] = `${50 - per / 2}%`;
    const originLayOutSize = (parseFloat(options.geo.layoutSize) / 100) * containerWidth;
    const curRealHeight = containerHeight - visualHeight;
    // console.log(originLayOutSize, curRealHeight);
    (options.geo.layoutSize as string | number) = Math.min(originLayOutSize, curRealHeight);
  }, [hanldeData, fontSize, label, legend, mode, title, registeredMap]);

  function genLegend(map: Map<string, string>) {
    const arrFromMap = (Array.from(map.entries())).reverse();
    const texts = arrFromMap.map(item => item[0]);
    const legendColor = arrFromMap.map(item => item[1]);

    const legendRadius = 6;
    const textMarginLeft = 12;

    const legendGroupBotomStart = 8;
    const legendGroupLeftStart = 12;

    const gapBetweenLegend = 8;
    const textFontSize = 12;
    let width = 0;
    const elements = [];
    for (let i = 0, L = texts.length; i < L; i += 1) {
      const curBottom = legendGroupBotomStart + i * (gapBetweenLegend + textFontSize);
      const group = {
        type: 'group',
        left: legendGroupLeftStart,
        bottom: curBottom,
        children: [
          {
            type: 'circle',
            x: 0,
            y: 0,
            shape: {
              cx: 6,
              cy: 6,
              r: legendRadius,
            },
            style: {
              fill: legendColor[i],
            },
          },
          {
            type: 'text',
            x: legendRadius * 2 + textMarginLeft,
            y: 0,
            style: {
              text: texts[i],
              fill: '#333333',
              font: '12px PingFang-SC-Regular, PingFang-SC',
            },
          },
        ],
      };
      const curWidth = legendGroupLeftStart + (
        2 * legendRadius + textMarginLeft + texts[i].length * textFontSize
      );
      width = Math.max(curWidth, width);
      elements.push(group);
    }
    return {
      elements,
      width,
      height: legendGroupBotomStart + elements.length * textFontSize + (
        elements.length - 1
      ) * gapBetweenLegend,
    };
  }

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        ref={(e: any) => {
          chartRef.current = e;
        }}
      />
    </div>);
});

export default ScatterMapChart;

ScatterMapChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: false,
  fontSize: 'middle',
};
