import React from 'react';
import clsx from 'clsx';
import { Input as AntInput } from 'antd';
import styles from './index.module.less';
import { IconFont } from '../../utils/mapIcon';

/**
 * 输入框
 * @param {'large' | 'middle' | 'mini'} size
 * @param {string} value
 * @param {string} placeholder
 * @param {string} className
 * @param onChange
 * @param onPressEnter
 * @param onBlur
 * @param onFocus
 * @param onClick
 * @param {JSX.Element} trailingIcon
 * @returns {JSX.Element}
 * @constructor
 */
function Input({
  value,
  placeholder,
  className,
  onChange,
  onPressEnter,
  onSearch,
}) {
  return (
    <div className={clsx(styles.input, className)}>
      <AntInput
        value={value}
        allowClear
        onChange={onChange}
        placeholder={placeholder}
        onPressEnter={() => onPressEnter && onPressEnter(value)}
      />
      <span
        className={styles.trailing}
        onClick={() => onSearch && onSearch(value)}
      >
        <span className={styles.verticalLine}></span>
        <IconFont
          type="iconfenxiang"
          className={styles.icon}
        />
      </span>
    </div>
  );
}

export default Input;
