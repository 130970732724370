// /*
// * Author: zhangmeng
// * Description: '水平柱图'
// * Date: 2022/04/12
// */

// import React, { useState, useEffect } from 'react';
// import ReactEcharts from 'echarts-for-react';

// const multicolor = ['#1961F5', '#4E88FF', '#59D700', '#FDAD61', '#FD6205'];

// function HorizontalBarChart(props) {
//   const { data } = props;
//   const [options, setOptions] = useState({});

//   useEffect(() => {
//     const { yUnit, yName, xAxis, yAxis } = data;
//     const series = yAxis.map((item, index) => (
//       {
//         // name: item.name,
//         type: 'bar',
//         stack: 'total',
//         barMaxWidth: 18,
//         itemStyle: {
//           color: `${multicolor[index]}`,
//         },
//         emphasis: {
//           focus: 'series',
//         },
//         data: item.data,
//       }
//     )) ?? [];
//     const legend = yAxis.map((item, index) => (
//       {
//         name: item.name,
//         itemStyle: {
//           color: `${multicolor[index]}`,
//         },
//       }
//     ));
//     console.log(legend);
//     console.log(xAxis);
//     console.log(yAxis);
//     const option = {
//       tooltip: {
//         trigger: 'axis',
//         axisPointer: {
//           // Use axis to trigger tooltip
//           type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
//         },
//         backgroundColor: '#fff',
//         borderWidth: 0,
//         borderColor: '#7B8796',
//         textStyle: {
//           color: '#7B8796',
//         },
//       },
//       grid: {
//         top: 30,
//         right: 36,
//         bottom: 86,
//         left: 40,
//         // containLabel: true,
//       },
//       legend: {
//         show: true,
//         bottom: 10,
//         itemWidth: 8,
//         itemHeight: 10,
//         symbol: 'rect',
//         textStyle: {
//           color: '#7B8796',
//           fontSize: 12,
//         },
//         data: legend,
//       },
//       xAxis: {
//         type: 'value',
//         name: `(${yName || ''})`,
//         // inverse: true,
//         // name: 'vnfnv',
//         splitLine: {
//           show: false,
//         },
//         axisTick: {
//           show: false,
//         },
//         axisLabel: {
//           show: false,
//         },
//       },
//       yAxis: {
//         type: 'category',
//         // name: `(${yName || ''})`,
//         boundaryGap: false,
//         inverse: true,
//         axisTick: {
//           show: false,
//         },
//         axisLine: {
//           show: false,
//           lineStyle: {
//             color: '#7B8796',
//           },
//         },
//         // axisLabel: {
//         //   color: '#ACF2FF',
//         //   fontSize: 12,
//         //   interval: 0,
//         // },
//         splitLine: {
//           show: true,
//           lineStyle: {
//             type: 'dashed',
//             color: '#EEEEEE',
//           },
//         },
//         data: xAxis,
//       },
//       series,
//     };
//     setOptions(option);
//   }, [data]);

//   return (
//     <ReactEcharts
//       option={options}
//       notMerge={true}
//       style={{ width: '100%', height: '100%' }}
//     />
//   );
// }

// export default HorizontalBarChart;

/*
* Author: zhangmeng
* Description: '堆叠柱状图'
* Date: 2022/03/02
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const multicolor = ['#1961F5', '#4E88FF', '#59D700', '#FDAD61', '#FD6205'];

/**
 * @param {{
 *   data: any;
 *   disableLegend: boolean;
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function StackBarChart(props) {
  const { data, disableLegend } = props;
  const [options, setOptions] = useState({});
  useEffect(() => {
    const { yName } = data;
    // console.log(data?.yAxis);
    const xAxis = data?.xAxis?.reverse();
    const yAxis = data?.yAxis?.map(item => (
      {
        ...item,
        data: item.data.reverse(),
      }
    ));
    if (!Array.isArray(yAxis)) return;
    const legend = yAxis?.map((item, index) => (
      {
        name: item.name,
        itemStyle: {
          color: `${multicolor[index]}`,
        },
      }
    ));
    const newData = yAxis?.map((item, index) => (
      {
        name: item.name,
        type: 'bar',
        stack: 'total',
        barMaxWidth: 18,
        itemStyle: {
          color: `${multicolor[index]}`,
        },
        emphasis: {
          focus: 'series',
        },
        data: item.data,
      }
    )) ?? [];
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        },
        backgroundColor: '#fff',
        borderWidth: 0,
        borderColor: '#7B8796',
        textStyle: {
          color: '#7B8796',
        },
      },
      grid: {
        top: 30,
        right: 36,
        bottom: 86,
        left: 60,
      },
      legend: {
        show: !disableLegend,
        bottom: 10,
        itemWidth: 8,
        itemHeight: 10,
        symbol: 'rect',
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: legend,
      },
      xAxis: {
        type: 'value',
        name: `(${yName || ''})`,
        // inverse: true,
        // name: 'vnfnv',
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: 'category',
        // name: `(${yName || ''})`,
        boundaryGap: false,
        inverse: true,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: '#7B8796',
          },
        },
        // axisLabel: {
        //   color: '#ACF2FF',
        //   fontSize: 12,
        //   interval: 0,
        // },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: '#EEEEEE',
          },
        },
        data: xAxis,
      },
      series: [
        ...newData,
      ],
    };
    setOptions(option);
  }, [data, disableLegend]);

  return (
    <ReactEcharts
      option={options}
      notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default StackBarChart;
