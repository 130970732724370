// 动产抵押
import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import { useGetMortgageMovablePropertyQuery } from '../../../../../service/companyDetail';
import style from '../index.module.less';

const PledgeTable = ({ companyId }) => {
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const { data: tableData, isFetching: tableFetching } = useGetMortgageMovablePropertyQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const _columns = (columns) => {
    if (!columns) return [];
    return columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      return item;
    });
  };
  const pledgeTable = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (<Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={_columns(tableData?.columns)}
        title={() => (
          <>
            <span>{tableData?.title}</span>
            <span className={style.count}>{tableData?.count}</span>
          </>
        )}
        strip
        loading={tableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        locale={{
          emptyText: ' ',
        }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />);
    }
    return null;
  }, [tableData]);
  return pledgeTable;
};

export default PledgeTable;
