import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { companyDetailsTool } from '../../utils/tool';
import { openNewPage } from '../../utils/urlMethods';
import ToolModal from '../ToolModal';
import { useLazyGetToolPermissionQueryQuery } from '../../service/public';
import style from './index.module.less';

const HeaderToolCatalogue = ({ data, setToolShow, setMenuKey, setLoginShow }) => {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const [modalShow, setModalShow] = useState(false);
  const [tool, setTool] = useState('');
  const [bgUrl, setBgUrl] = useState('');
  const [getToolPermissionQuery] = useLazyGetToolPermissionQueryQuery();
  const gotoToolBox = () => {
    if (!token) {
      setLoginShow(true);
      return;
    }
    history.push('/toolBox');
    setToolShow(false);
  };
  const goToToolPage = (item) => {
    if (companyDetailsTool[item.keyword]) {
      if (companyDetailsTool[item.keyword].url.includes('penetrateGraph')) {
        history.push({ pathname: companyDetailsTool[item.keyword].url });
      } else {
        const params = {
          id: '753041486c2f55514f566933366e486f4b41734272413d3d0a',
          name: '中兴通讯股份有限公司',
        };
        // history.push({
        //   pathname: companyDetailsTool[item.keyword].url,
        //   search: params.toString(),
        // });
        openNewPage(companyDetailsTool[item.keyword].url, params);
      }
      setTimeout(() => {
        setMenuKey('toolBox');
        setToolShow(false);
      }, 0);
      return;
    }
    const params = {
      cityCode: window.btoa('110100'),
      title: item.name,
      cityName: '北京市',
      key: item.keyword,
      isProvinceDirectly: false,
    };
    if (item.name === '寻企地图') {
      openNewPage('tool/searchCompanyMap', params);
      return;
    }
    if (item.name === '数据分析') {
      openNewPage('dataAnalysisTool/dataFilter');
      return;
    }
    openNewPage('cityTool', params);
  };

  const clickTool = (item) => {
    if (!token) {
      setLoginShow(true);
      return;
    }
    setTool(item.name);
    setBgUrl(item.reality_img);
    getToolPermissionQuery({ tool_key: item.keyword }).then(res => {
      if (res.data) {
        goToToolPage(item);
        return;
      }
      setModalShow(true);
    }).catch(err => {
      throw new Error(err);
    });
  };
  return (
    <div className={style.toolBox}>
      <div className={style.toolTitle} onClick={gotoToolBox}>
        <span>
          工具中心
        </span>
        <span>
          <RightOutlined />
        </span>
      </div>
      <ul className={style.toolList}>
        {
          data?.map((item, index) => (
            <li key={index}>
              <div className={style.toolListTitle}>{item.name}</div>
              {
                item.tool_list.map((item2, index2) => (
                  <div key={index2} className={style.toolCell} onClick={() => { clickTool(item2); }}>
                    <div className={style.toolName}>
                      <span>{item2.name}</span>
                      {item2.label.length > 0 && <span className={item2.label[0] === '热' ? style.hot : style.new}>{item2.label[0]}</span>}
                    </div>
                    <div className={style.toolIntro}>
                      <span>{item2.introduce}</span>
                    </div>
                  </div>
                ))
              }
            </li>
          ))
        }
      </ul>
      {
        modalShow && <ToolModal title={tool} setTool={setModalShow} bgUrl={bgUrl} />
      }
    </div>
  );
};

export default HeaderToolCatalogue;
