/*
* Author: zhangmeng
* Description: '标准详情页'
* Date: 2021/05/07
*/

import React, { useMemo } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import TitleAndSubTitleComp from '../../../TitleAndSubTitleComp';
import EvolutionChart from '../../../Model/EvolutionChart';
import TracingSourceChart from '../../../Model/TracingSourceChart';
import style from './index.module.less';

function DetailMiddle(props) {
  const { leftChart, rightChart, status } = props;

  const circleShow = (type) => {
    let comp = null;
    switch (type) {
      case 1:
        comp = <div className={style.circle2} />;
        break;
      case 2:
        comp = <div className={style.circle1} />;
        break;
      case 3:
        comp = <div className={style.ok}><CheckCircleFilled /></div>;
        break;
      default:
        break;
    }
    return comp;
  };

  const left = useMemo(() => (
    Object.keys(leftChart).length > 0 && <TracingSourceChart chartData={leftChart} title="标准溯源" />
  ), [leftChart]);

  const right = useMemo(() => (
    Object.keys(rightChart).length > 0 && <EvolutionChart chartData={rightChart} title="标准演进" />
  ), [rightChart]);

  return (
    <div className={style.all}>
      <TitleAndSubTitleComp title="标准状态" />
      <div className={style.timeLineBox}>
        <div className={style.word}>
          <div className={style.fabu}>发布</div>
          <div className={style.shishi}>实施</div>
          <div className={style.feizhi}>废止</div>
        </div>
        <div className={style.onLine}>
          <div className={style.ok}>
            <CheckCircleFilled />
          </div>
          {
            status.status === 1 ? (
              <div className={style.circle1} />
            ) : (
              <div className={style.ok}>
                <CheckCircleFilled />
              </div>
            )
          }
          {
            circleShow(status.status)
          }
        </div>
        <div
          className={`
          ${style.littleCircle}
          ${status.status === 2 ? style.littleCircle2 : ''}
          ${status.status === 3 ? style.littleCircle3 : ''}
          `}
        />
        <div className={style.timeLine} />
        <div
          className={`
          ${style.line}
           ${status.status === 2 ? style.line2 : ''}
           ${status.status === 3 ? style.line3 : ''}
           `}
        />
        <div className={style.dateBox}>
          <div className={style.fabuDate}>{status.fabu}</div>
          <div className={style.shishiDate}>{status.shishi}</div>
          <div className={style.feizhiDate}>{status.feizhi}</div>
        </div>
      </div>
      <div className={style.chartBox} style={{ display: Object.keys(leftChart).length === 0 && Object.keys(rightChart).length === 0 ? 'none' : '' }}>
        <div className={style.chart}>
          {left}
        </div>
        <div className={style.chart}>
          {right}
        </div>
      </div>
    </div>
  );
}

export default DetailMiddle;
