import { getByPath } from '../mapping';

/**
 * 获取字符串中的宏定义
 * 宏定义以 ${} 作为包裹
 */
const getDefineVariables: (str: string) => string[] = (str) => {
  // const expr = /(?<=\{)(.*?)(?=\})/g;
  // const expr = new RegExp('(?<=\{)(.*?)(?=\})', 'g');
  const expr = /{([^}]*)}/g;
  const output = [];
  let arr: string[] | null;
  debugger;
  while ((arr = expr.exec(str)) !== null) {
    if (Array.isArray(arr)) {
      if (arr[1] === '') {
        break
      }
      output.push(arr[1]);
    }
  }
  return output;
}

/**
 * 以映射字典，替换宏定义，如果无匹配项，认为是普通文本，不替换
 * 不处理空字符串的替换
 */
const replaceDefineVariables = (str: string, map: {[index: string]: (string | number)}) => {
  const defineVars =  getDefineVariables(str);

  return defineVars.reduce((prev, next) => {
    // const val = map[next]?.toString();
    const val = getByPath(map, next)?.toString();
    if (val === undefined || next === '') {
      return prev;
    }
    return prev.replaceAll(`\{${next}}`, val);
  }, str);
}

export class Macro {
  private _dictionary;
  constructor(dictionary: Record<string, any>) {
    this._dictionary = dictionary;
  }

  get dictionary() {
    return this._dictionary;
  }

  set dictionary(value) {
    this._dictionary = value;
  }

  static readMacroDefine(str: string) {
    return getDefineVariables(str);
  }

  replace(str: string) {
    return replaceDefineVariables(str, this.dictionary);
  }
}
