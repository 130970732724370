/**
 * 新版报告
 */
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Button, message } from 'antd';
import classnames from 'classnames';
import wx from 'weixin-js-sdk';
import { SchemeRenderer, ConfigContext } from '@sqke/report-render';
import { cloneDeep } from 'lodash';
import '@sqke/report-components/dist/esm/index.css';
import '@sqke/design/dist/esm/index.css';
import '@sqke/report-render/dist/esm/index.css';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/url';
import { getResource } from '../../api/login';
import { handleWidthRolePermission } from '../../utils/tool';
import WxShare from '../../components/WxShare';
import ReportLock from './ReportLock';
import { useGetReportMsgQuery, useLazyGetNewAnalysisMonitorReportQuery, useLazyDownloadReportQuery } from '../../service/report';
import style from './index.module.less';

// if (process.env.NODE_ENV === 'development') {
//   scheme.macro.URL = getUrlPrefix() + scheme.macro.URL;
// }

const DownloadBtnMap = {
  reportPDFDownloadBtn: { name: '下载为PDF', type: 2 },
  reportWordDownloadBtn: { name: '下载为word', type: 1 },
  reportGeneralDownloadBtn: { name: '生成并下载', type: 2 },
};

const reportRenderConfig = {
  geoJSONUrl: code => `/city_json/${code}.json`,
};

function Scheme() {
  const query = useQuery();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  const android = params.get('android');
  const goToUrl = params.get('url');
  const { forMini, miniSharing } = urlReportMsg; // 判断是否来自小程序, 是否为小程序分享页, 两者情况不同
  const [cost, setCost] = useState(0);
  const [sqcoin, setSqcoin] = useState(0);
  const [fileName, setFileName] = useState('');
  const [downloadBtn, setDownloadBtn] = useState(null);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [active, setActive] = useState('');
  const [openWxModal, setOpenWxModal] = useState(true);

  const isSqCoinNotEnough = cost > sqcoin;

  const { data: schemeData } = useGetReportMsgQuery({
    report_id: urlReportMsg.id,
    industry_code: urlReportMsg.industryKey,
    city_code: urlReportMsg.areaKey,
    type: downloadBtn && downloadBtn.reportWordDownloadBtn ? 3 : 2,
  }, {
    skip: !urlReportMsg || !downloadBtn,
  });
  const payStatus = useMemo(() => {
    setOpenWxModal(!schemeData?.pay_status);
    return schemeData?.pay_status;
  }, [schemeData]);
  const scheme = useMemo(() => {
    const _scheme = cloneDeep(schemeData?.scheme) ?? {};
    if (schemeData && payStatus && !forMini) {
      _scheme.components = _scheme.components.filter(item => item.type === 'cover' || item.type === 'toc' || item.type === 'heading');
    }
    if (miniSharing) {
      _scheme.components = _scheme.components.filter(item => item.type === 'cover' || item.type === 'toc' || item.type === 'heading');
    }
    if (schemeData) {
      _scheme.macro.URL = `${window.location.protocol}//${_scheme?.macro?.URL}`;
    }
    return _scheme;
  }, [schemeData]);

  // 获取权限
  useEffect(() => {
    getResource().then(res => {
      if (res.status === '00000') {
        const permission = handleWidthRolePermission(res.data);
        const rgt = {};
        try {
          Object.keys(permission.rgt).forEach(item => {
            if (Object.keys(DownloadBtnMap).includes(item)) {
              rgt[item] = permission.rgt[item];
            }
          });
          if (!Object.keys(rgt).length) {
            setDownloadBtn({ reportGeneralDownloadBtn: true });
          } else {
            setDownloadBtn(rgt);
          }
        } catch {
          setDownloadBtn({ reportGeneralDownloadBtn: true });
        }
      }
    });
  }, []);
  // 下载相关
  const [getNewAnalysisMonitorReport, { isFetching }] = useLazyGetNewAnalysisMonitorReportQuery();
  const getReportWord = (type) => {
    setActive(type);
    getNewAnalysisMonitorReport({
      report_id: urlReportMsg.id,
      type,
    }).then(res => {
      setActive('');
      if (res.status === 'fulfilled') {
        if (res.data) {
          if (res.data?.need_SQCoin_download) {
            setCost(res.data?.goods_sq_coin);
          } else {
            setCost(0);
          }
          setSqcoin(res.data?.user_sq_coin);
          setFileName(res.data?.file_name);
          setDownloadModalVisible(true);
        }
      } else {
        message.warning(res?.error?.data?.message);
      }
    });
  };

  const pushDetailPage = useCallback((key) => {
    query.set('tab', '个人中心');
    query.set('detail', key);
    history.push({
      pathname: 'accountSetting',
      search: query.toString(),
    });
  }, [query]);

  const [downloadReport, { isFetching: downLoadFetching }] = useLazyDownloadReportQuery();
  const download = (file) => {
    if (cost > sqcoin) {
      message.warning('上奇币不足，请获取！');
      return;
    }
    message.loading('文件下载中...', 0);
    downloadReport({
      fileName: file,
      version: 2,
    }).then(res => {
      if (res.status === 'fulfilled') {
        message.destroy();
        window.open(res.data);
        setDownloadModalVisible(false);
      } else {
        message.destroy();
        message.warning(res?.error?.data?.message);
      }
    });
  };
  return (
    <div className={classnames(style.report, { [style.payStatus]: payStatus })}>
      <div style={forMini ? { position: 'absolute', transform: `scale(${window.innerWidth / 796})`, transformOrigin: '0 0' } : {}}>
        <ConfigContext.Provider value={reportRenderConfig}>
          {
            schemeData && (
              <SchemeRenderer
                scheme={scheme}
              />
            )
          }
        </ConfigContext.Provider>
      </div>
      {
        android && (
        <div
          className={style.out}
          onClick={() => {
            if (goToUrl) {
              wx.miniProgram.reLaunch({ url: goToUrl });
            } else {
              wx.miniProgram.reLaunch({ url: '/pages/home/index' });
            }
          }}
        >
          <span />
        </div>
        )
      }
      {
        !payStatus && !forMini && !miniSharing && (
          <div className={style.btnBox}>
            {
              downloadBtn && Object.keys(downloadBtn).length > 0 && Object.keys(downloadBtn).map(item => (
                <Button
                  key={item}
                  className={style.downloadBtn}
                  loading={active === DownloadBtnMap[item].type ? (isFetching || downLoadFetching) : false}
                  disabled={!!active}
                  onClick={() => { getReportWord(DownloadBtnMap[item].type); }}
                >
                  {DownloadBtnMap[item].name}
                </Button>
              ))
            }
          </div>
        )
      }
      {
        downloadModalVisible && (
          <>
            <div className={style.modal}>
              <div className={style.top}>
                <div className={style.title}>报告已生成</div>
                <div className={classnames(style.cost, { [style.alert]: isSqCoinNotEnough })}>
                  {
                    cost === 0
                      ? (
                        <>
                          是否下载
                        </>
                      ) : (
                        <>
                          是否消耗
                          {cost}
                          上奇币下载（余额
                          {sqcoin}
                          个）
                          {
                            isSqCoinNotEnough && (
                              <span
                                onClick={() => pushDetailPage('achieve')}
                              >
                                去获取
                              </span>
                            )
                          }
                        </>
                      )
                  }
                </div>
              </div>
              <div className={style.buttons}>
                <Button className={style.left} onClick={() => setDownloadModalVisible(false)}>稍后再试</Button>
                <Button
                  className={style.right}
                  onClick={() => {
                    setDownloadModalVisible(false);
                    download(fileName);
                  }}
                >
                  现在下载
                </Button>
              </div>
            </div>
            <div className={style.modalCover} />
          </>
        )
      }
      {
        payStatus && schemeData && !forMini && !miniSharing && <ReportLock urlReportMsg={urlReportMsg} coverScheme={schemeData?.cover_scheme} />
      }
      {/* <div className={classnames(style.wxModalMasking, { [style.wxModalMaskingNone]: openWxModal })}>
        <div className={style.wxModal}>
          <div key={urlReportMsg.id}>
            <QRCode value={`https://chanyeos.com/smart-ke/#/report?id=${urlReportMsg.id}`} size={142} />
          </div>
          <div>打开微信扫一扫发送助力邀请</div>
          <div onClick={() => {
            setOpenWxModal(true);
          }}
          >
            直接支付
          </div>
          <div className={style.close}>
            <span onClick={() => {
              setOpenWxModal(true);
            }}
            >
              <CloseOutlined />
            </span>
          </div>
        </div>
      </div> */}
      <WxShare
        id={urlReportMsg.id}
        url={`${window.location.origin}${window.location.pathname}#/report?id=${urlReportMsg.id}`}
        openWxModal={openWxModal}
        setOpenWxModal={setOpenWxModal}
      />
    </div>
  );
}

export default Scheme;
