import React, { useEffect, useMemo } from 'react';
import {
  useGetParkIndustryOverviewCompanyListQuery,
} from '../../../../../../../../service/parkDetail';
import {
  useGetIndustryTreeQuery,
  useGetCompanySpecialTagsQuery,
} from '../../../../../../../../service/interactive-v2';
import Table from '../../../../../../../../components/Table';
import DataSearchButton from '../../../../../../../../components/DataSearchButton';
import Score from '../../../../../../../../components/Score';
import CustomListFilter from '../../../../../../../../components/CustomListFilter';
import CustomTreeFilter from '../../../../../../../../components/CustomTreeFilter';
import { getSingleOption } from '../../../../../../../../utils/tool';
import { getNameConfig, getSortKv } from '../../../../../../../../utils/table';
import SortTitle from '../../../../../../../../components/SortTitle';
import { FilterIcon } from '../../../../../../../../components/CustomTableIcon';
import styles from './index.module.less';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    width: 330,
    ellipsis: true,
    className: styles.name,
  },
  reg_capital_std: {
    title: '注册资本',
    dataIndex: 'reg_capital_std',
    key: 'reg_capital_std',
    width: 120,
    ellipsis: true,
    sorter: true,
  },
  establish_date: {
    title: '成立时间',
    dataIndex: 'establish_date',
    key: 'establish_date',
    width: 92,
    sorter: true,
    className: styles.number,
  },
  location: {
    title: '所属园区',
    dataIndex: 'location',
    key: 'location',
    width: 92,
    ellipsis: true,
    // filters: [],
    // filterMode: 'tree',
    // filterIcon: <FilterIcon />,
  },
  tag_name: {
    title: '产业',
    dataIndex: 'tag_name',
    key: 'tag_name',
    width: 138,
    ellipsis: true,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
    render: text => (Array.isArray(text) ? text.join('、') : text),
  },
  special_tag: {
    title: '资质类别',
    dataIndex: 'special_tag',
    key: 'special_tag',
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: text => (Array.isArray(text) ? text.join('、') : text),
  },
  accumulation_monthly: {
    title: '全部专利',
    dataIndex: 'accumulation_monthly',
    key: 'accumulation_monthly',
    width: 88,
    align: 'center',
    sortDirections: ['descend'],
    sorter: true,
    className: styles.number,
  },
  total_point: {
    title: '上奇评分',
    dataIndex: 'total_point',
    key: 'total_point',
    width: 80,
    render: text => <Score score={text} />,
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
};

const COLUMN_KEYS = [
  'index', 'name', 'reg_capital_std', 'establish_date',
  'location', 'tag_name', 'special_tag', 'accumulation_monthly', 'total_point',
];

function CompanyTable(props) {
  const {
    keyword,
    parkCode,
    sortedInfo = {},
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    onClick,
    onData,
    setParams,
    industryCode = 'all',
  } = props;

  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );

  const { data: companyTags = [] } = useGetCompanySpecialTagsQuery();
  const {
    key: sortKey,
    value: sortValue,
  } = getSortKv(sortedInfo);

  const { data, isFetching, isSuccess } = useGetParkIndustryOverviewCompanyListQuery({
    key_word: keyword,
    park_id: getSingleOption(filteredInfo.location) ?? parkCode,
    industry_code: getSingleOption(filteredInfo.tag_name) ?? industryCode,
    sort_key: sortKey,
    sort_value: sortValue,
    company_type: filteredInfo.special_tag?.filter(tag => tag !== 'all') ?? [],
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !parkCode,
  });

  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'name') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({ onClick: onCompanyClick, keyword })),
        };
      }
      if (item.dataIndex === 'special_tag') {
        rowConfig.filteredValue = filteredInfo.special_tag?.length > 0 ? filteredInfo.special_tag : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = companyTags;
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (item.dataIndex === 'tag_name') {
        rowConfig.filters = industryTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
        rowConfig.filteredValue = filteredInfo?.tag_name ?? [industryCode];
      }
      if (item.dataIndex === 'accumulation_monthly') {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item })}
          />
        );
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [onCompanyClick, columns, onClick, sortedInfo, filteredInfo, industryTree]);
  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        park_id: getSingleOption(filteredInfo.location) ?? parkCode,
        industry_code: getSingleOption(filteredInfo.tag_name) ?? 'all',
        sort_key: sortKey,
        sort_value: sortValue,
        company_type: (filteredInfo?.special_tag && filteredInfo.special_tag.filter(item => item !== 'all').length !== 0)
          ? filteredInfo.special_tag : undefined,
      });
    }
  }, [keyword, parkCode, sortedInfo, filteredInfo]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      noShowJumper
      {...props}
    />
  );
}

export default CompanyTable;
