// @ts-nocheck

import { EChartsOption } from 'echarts';

const extend = item => Array.isArray(item) ? item : [item];

class AxisParser {
  static isCategory(axis) {
    return axis?.type === 'category';
  }
}

type SeriesFilterCondition = {
  seriesName?: string;
  seriesIndex?: number;
};


class OptionParser<T extends EChartsOption> {
  _option: T;

  constructor(option: EChartsOption = {}) {
    this._option = option;
  }

  isEmpty() {
    return Object.keys(this._option).length === 0;
  }

  // —————————————— option ——————————————

  getOption() {
    return this._option;
  }

  setOption() {
    this._option = this._option;
    return this;
  }

  hasCategory() {
    if (this.isEmpty()) {
      return false;
    }
    return (
      [
        this._option?.xAxis?.type,
        this._option?.yAxis?.type,
        this._option?.radiusAxis?.type,
        this._option?.angleAxis?.type,
      ].includes('category')
    );
  }

  getSeriesCount() {
    return this._option.series?.length ?? 0;
  }

  getSeries(conditions: { name?: string, index?: number } | number) {
    if (this.getSeriesCount() === 0) {
      return undefined;
    }
    if (typeof conditions === 'number') {
      return this._option.series[conditions];
    }
    const { name, index } = conditions;
    if (name !== undefined) {
      this._option.series.find(item => item.name === name);
    }
    if (index !== undefined) {
      return this._option.series[index];
    }
    return undefined;
  }

  static isAxisCategory(axis) {
    return AxisParser.isCategory(axis);
  }

  getAxis(
    series: object & {
      coordinateSystem?: 'cartesian2d' | 'geo' | 'polar' | 'none',
      xAxisIndex?: number;
      yAxisIndex?: number;
      polarIndex?: number;
      geoIndex?: number;
    },
    ) {
    let { coordinateSystem } = series;
    const DEFAULT_CARTESIAN2D = [
      'bar', 'line', 'scatter', 'effectScatter', 'boxplot',
      'candlestick', 'heatmap', 'pictorialBar',
    ];
    const DEFAULT_NONE = [];
    if (!coordinateSystem && DEFAULT_CARTESIAN2D.includes(series.type)) {
      coordinateSystem = 'cartesian2d';
    } else if (!coordinateSystem && DEFAULT_NONE.includes(series.type)) {
      coordinateSystem = 'none';
    }

    /**
     * 笛卡尔坐标系（二维）
     */
    if (coordinateSystem === 'cartesian2d') {
      const {
        xAxisIndex = 0,
        yAxisIndex = 0,
      } = series;
      const xAxis = extend(this._option.xAxis)[xAxisIndex];
      const yAxis = extend(this._option.yAxis)[yAxisIndex];
      // if (this._option.xAxis?.type === 'category') {
      //   return this._option.xAxis.data;
      // }
      return {
        xAxis,
        yAxis,
      };
    }

    // 极坐标系
    if (coordinateSystem === 'polar') {
      const {
        polarIndex = 0,
      } = series;
      const angleAxis = extend(this._option.angleAxis).find(item => item.polarIndex === polarIndex);
      const radiusAxis = extend(this._option.radiusAxis).find(item => item.polarIndex === polarIndex);
      return {
        angleAxis,
        radiusAxis,
      };
    }

    // 地理坐标系
    if (coordinateSystem === 'geo') {
      const { geoIndex = 0 } = series;
      const geo = extend(this._option.geo)[geoIndex];
      return { geo };
    }
    return {};
  }
}

export { OptionParser };
