import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import style from './index.module.less';

const Directory = ({ originalList, basicInfo, clickKip }) => {
  const [unpackState, setUnpackState] = useState(true);
  const startEdit = ({ value }) => {
    clickKip(value);
  };
  const unpack = () => {
    setUnpackState(!unpackState);
  };
  return (
    <div className={style.box}>
      <div className={style.title}>问卷目录</div>
      <div
        id={basicInfo}
        className={style.basicsInfo}
        onClick={() => { clickKip(basicInfo); }}
      >
        基本信息
      </div>
      <div className={style.mainMenu}>
        <div className={style.menuTitle}>
          <div className={style.left}>
            <span className={style.name}>主体资格</span>
            <div className={style.proportion}>
              （
              <span className={style.number}>
                {originalList.filter(item => item.color === '#333333').length}
              </span>
              / 6 ）
            </div>
          </div>
          <span className={style.right} onClick={unpack} style={{ transform: unpackState ? 'rotate(0)' : 'rotate(180deg)' }}><DownOutlined /></span>
        </div>
        <div className={style.steps} style={{ display: unpackState ? '' : 'none' }}>
          {
            originalList.map(item => (
              <div key={item.title} className={style.cell}>
                {item.el}
                <div
                  className={style.cellName}
                  style={{ color: item.color }}
                  onClick={() => { startEdit(item); }}
                >
                  {item.title}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Directory;
