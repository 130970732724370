import React, { useEffect, useState } from 'react';
import { Select, Menu } from 'antd';
import aiIcon from '../../../../../assets/images/dataAnalysisTool/aiIcon.png';
import styles from './index.module.less';

const { SubMenu } = Menu;

const options = [
  {
    value: '全部',
    label: '全部',
  },
];

function modifyTreeArray(treeArray) {
  return treeArray.map(item => {
    if (item.type !== 'group') {
      return { ...item, display: 'none' };
    }
    if (item.child && item.child.length > 0) {
      return {
        ...item,
        child: modifyTreeArray(item.child),
      };
    }

    return item;
  });
}

export default function Catalogue({ data, contentRef }) {
  const [menuData, setMenuData] = useState([]);

  const scrollToContent = (path) => {
    const dom = document.getElementById(path);
    if (contentRef?.current) {
      // 滚动到指定位置
      contentRef.current.scrollTo({
        top: dom?.offsetTop - 20,
        behavior: 'smooth',
      });
    }
  };

  const handleMenuClick = (contentId) => {
    scrollToContent(contentId);
  };

  // 处理一级菜单栏
  const renderMenu = ({ name, id, child, meta_data: metaData }) => (
    <Menu.Item key={id} onClick={() => handleMenuClick(metaData ? id : child[0].id)}>
      <span className={styles.itemTitle}>
        <span className={styles.title}>{name}</span>
      </span>
    </Menu.Item>
  );

  // 处理子级菜单栏
  const renderSubMnenu = ({ name, id, child }) => (
    <SubMenu
      key={id}
      title={
        <span className={styles.subMenuTitle}>
          <span className={styles.secondTitle}>{name}</span>
        </span>
      }
    >
      {
        child && child.map(item => {
          if (item.child && item.child.length > 0) {
            return renderSubMnenu(item);
          }
          return renderMenu(item);
        })
      }
    </SubMenu>
  );

  useEffect(() => {
    if (data) {
      setMenuData(modifyTreeArray(data));
    }
  }, [data]);

  return (
    <div className={styles.all}>
      <Select
        defaultValue='全部'
        style={{
          width: 156,
        }}
        options={options}
      />
      <Menu
        theme='light'
        mode='inline'
      >
        {
          menuData.length > 0 && menuData.map(item => {
            if (item.child && item.child.length > 0) {
              return item.child && item.child.length > 0 && (
                <SubMenu
                  key={item.id}
                  title={
                    <span className={styles.subMenuTitle}>
                      {
                        item.title === '智能撰写'
                          ? (
                            <span className={styles.title}>
                              {item.name}
                              <img src={aiIcon} alt="" />
                            </span>
                          )
                          : <span className={styles.title}>{item.name}</span>
                      }
                    </span>
                  }
                >
                  {
                    item.child.map(i => {
                      if (i.child && i.child.length > 0 && i.child?.[0].display !== 'none') {
                        return renderSubMnenu(i);
                      }
                      return renderMenu(i);
                    })
                  }
                </SubMenu>
              );
            }
            return '';
          })
        }
      </Menu>
    </div>
  );
}
