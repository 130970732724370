import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.less';

// 格式化目录序号
const generateNum = (num, level) => {
  let newNum = '';
  switch (level) {
    case 1:
      newNum = `${(num + 1).toLocaleString('zh-Hans-CN-u-nu-hanidec')}、`;
      break;
    case 2:
      newNum = `（${(num + 1).toLocaleString('zh-Hans-CN-u-nu-hanidec')}）`;
      break;
    case 3:
      newNum = `${num + 1}. `;
      break;
    default: break;
  }
  return newNum;
};

const showDirectory = (dir) => {
  if (dir?.length) {
    const newDir = dir.filter(i => !i.disable).map(item => {
      if (item.child) {
        return {
          ...item,
          child: showDirectory(item.child),
        };
      }
      return item;
    });
    return newDir;
  }
  return null;
};

export default function GenerateDirectory({ newDirectoryData, boxRef }) {
  const [data, setData] = useState([]);
  const scrollToContent = (path) => {
    const dom = document.getElementById(path.toString());
    // 滚动到指定位置
    boxRef.current.scrollTo({
      top: dom?.offsetTop,
      behavior: 'smooth',
    });
  };

  const handleMenuClick = (contentId) => {
    scrollToContent(contentId);
  };

  useEffect(() => {
    const writingArr = localStorage.getItem('intelligentWriting') ? JSON.parse(localStorage.getItem('intelligentWriting')) : [];
    const writingDataArr = writingArr.filter(item => item.text);
    const list = [];
    if (newDirectoryData?.length > 0) {
      newDirectoryData.forEach(item => {
        if (item.meta_data?.type === 'data_analysis') {
          const newChild = [];
          writingDataArr.forEach(writingItem => {
            if (item.child?.filter(i => i.id === writingItem.id)?.length > 0) {
              newChild.push(item.child?.filter(i => i.id === writingItem.id)[0]);
            }
          });
          if (newChild.length > 0) {
            list.push({
              ...item,
              child: newChild,
            });
          }
        } else {
          list.push(item);
        }
      });
    }
    setData(list);
  }, [newDirectoryData]);

  const Directory = (list) => {
    if (list?.length) {
      const newDirectory = list.map((item, index) => (
        <div
          className={classNames(
            { [styles.firstTitle]: item.level === 1 },
            { [styles.secondTitle]: item.level === 2 },
            { [styles.thirdTitle]: item.level === 3 },
            { [styles.isHide]: item.disable || item.type !== 'group' },
          )}
          key={item.id}
        >
          <div className={styles.clickBtn} onClick={() => handleMenuClick(item.meta_data ? item.id : item?.child?.[0]?.id)}>{`${generateNum(index, item.level)}${item.name}`}</div>
          {item.child && Directory(item.child)}
        </div>
      ));
      return newDirectory;
    }
    return '';
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentTitle}>目录</div>
      {newDirectoryData && Directory(showDirectory(data)?.filter(i => i.child?.length > 0))}
    </div>
  );
}
