/**
 * # 提取 ECharts Option 的方法
 * 用法：
 * ```js
 *     const _baseOption = rewireOption(barConfig);
 *     if (!data) {
 *       return _baseOption;
 *     }
 *     const _dataOption = rewireData(barConfig, data);
 *     return merge({}, _baseOption, _dataOption);
 * ```
 */
import { base } from './model/base';
import { axis } from './model/axis';
import { legend } from './model/legend';
import { tooltip } from './model/tooltip';
import { geo } from './model/geo';
import { visualMap } from './model/visualMap';
import { dataset } from './model/dataset';
import { series } from './model/series';
import { IModelFunction } from './interface/base';
import { EChartsOption } from 'echarts';

const middlewares = [
  base,
  axis,
  legend,
  geo,
  tooltip,
  visualMap,
  dataset,
  series,
];

export const getOption: IModelFunction = (raw, data) => {
  return middlewares.reduce((prev, next) => {
    return next(prev, { config: raw, data });
  }, {} as EChartsOption);
};

export const COORDINATE_SYSTEMS = {
  CARTESIAN2D: 'cartesian2d',
  POLAR: 'polar',
  GEO: 'geo',
};

export * from './interface/data';
export * from './interface/config';
export * from './model/utils';
export * from './model/optionParser';
