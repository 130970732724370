/*
 *Author: zhaochenyu
 *Description: 研究机构详情信息
 *Date: 2020/11/26
*/

import React from 'react'; // , { useCallback, useState }
import img from '../../../assets/images/institutionDetail/institution.png';
// import { IconFont } from '../../../utils/mapIcon';
// import { companyTabsColorMap } from '../../../utils/color';
import { tagStyleMap } from '../../../utils/tagColor';
import { openNewPage } from '../../../utils/urlMethods';
import style from './index.module.less';

function InstitutionDetail(props) {
  // const [moreDesc, setMore] = useState(true); // 展示更多简介
  // const [MoreBtn, setMoreBtn] = useState(false); // 更多按钮是否显示
  const { data: { name, field, labels, support_unit: supportUnit, judge,
    setUp, location } } = props; // desc,

  // const descRef = useCallback(node => {
  //   if (node !== null) {
  //     setMoreBtn(node.getBoundingClientRect().height > 48);
  //     setMore(!node.getBoundingClientRect().height > 48);
  //   }
  // }, []);

  const supportUnitClick = (id) => {
    if (id) {
      openNewPage('companyDetail', {
        id,
      });
    }
  };

  return (
    <div className={style.all}>
      <div className={style.leftImg}>
        <img className={style.img} src={img} alt="" />
      </div>
      <div className={style.detailContent}>
        <div className={style.nameLine}>
          {name}
        </div>
        <div className={style.labels}>
          {labels.map((item, index) => (
            item.tabs.map((item1, index1) => (
              <div
                key={`${index}${item1}${index1}`}
                className={`${style.labelItem} ${tagStyleMap[item.type]}`}
              >
                {item1}
              </div>
            ))
          ))}
        </div>
        <div className={style.field}>
          <span className={style.leftLabel}>所属领域</span>
          <span className={style.rightContent}>{field && field instanceof Array ? field.join('，') : field}</span>
        </div>
        <div className={style.departments}>
          <div className={style.left}>
            <span className={style.leftLabel}>依托单位</span>
            {supportUnit.map((item, index) => (
              <span
                className={`${item.company_id ? style.supportUnit : style.rightContent}`}
                onClick={() => supportUnitClick(item.company_id)}
                key={`${index}supportUnit`}
              >
                {item.name}
                {index < supportUnit.length - 1 ? '、' : ''}
              </span>
            ))}
          </div>
          <div className={style.left} style={{ display: `${location ? '' : 'none'}` }}>
            <span className={style.leftLabel}>地址</span>
            <span
              className={style.rightContent}
            >
              {location}
            </span>
          </div>
        </div>
        <div className={style.setUp}>
          <span className={style.leftLabel}>认定时间</span>
          <span className={style.rightContent}>{setUp}</span>
        </div>
        <div className={style.judge}>
          <span className={style.leftLabel}>评定机构</span>
          <span className={style.rightContent}>{judge}</span>
        </div>
      </div>
    </div>
  );
}

export default InstitutionDetail;
