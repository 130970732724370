/*
 *Description: 漏斗图业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import FunnelChart from '../../BasicsCompoment/FunnelChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessFunnelChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessFunnelChart(props: BusinessFunnelChartProps) {
  const {
    mode, label, legend, ip, conditions, fontSize,
    onRequestComplete, title, componmentKey,
    imgRef, dataTransformer,
  } = props;

  const [chartData, setChartData] = useState<any>([]);
  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const _data = (dataTransformer && isFunction(dataTransformer)) ? dataTransformer(res.data) : res.data;
          const data = _data.data || _data.data_list || [];
          const sortedData: any[] = [];
          for (let i = 0, L = data.length; i < L; i += 1) {
            sortedData.push(data[i]);
          }
          setChartData(sortedData.reverse());
          setChartTitle(_data.title);
          setChartDesc({
            before_describe_value_dict: (_data && _data.before_describe_value_dict) || {},
            after_describe_value_dict: (_data && _data.after_describe_value_dict) || {},
          });
        } else {
          setChartData([]);
        }
        if (isFunction(onRequestComplete)) {
          onRequestComplete!(res.data);
        }
      }).catch(() => {
        setChartData([]);
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <FunnelChart
          title={typeof title === 'string' ? title : chartTitle}
          data={chartData}
          {...{
            mode,
            label,
            legend,
            chartDesc,
            ref: imgRef,
            fontSize,
          }}
          sort="ascending"
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

// export default BusinessPieChart;
BusinessFunnelChart.defaultProps = {
  mode: 'dark',
  fontSize: 'middle',
  label: true,
  legend: true,
  title: true,
};

// 企业金字塔
export function EnterPrisePyramidFunnelChart(props: BusinessFunnelChartProps) {
  const componmentKey = {
    function_key: 'company_pyramid_fenxi',
    class_name: '/chart/company',
  };
  // 暂时无用
  const sort = ['头部企业', '高成长型企业', '高技术企业', '全量企业'];
  return (
    <BusinessFunnelChart
      {...props}
      componmentKey={componmentKey}
      sortArr={sort}
    />);
}

// 图31早期组件修改 . 3 企业金字塔
export function CompanyChartcompanyPyramidFenxi(props: BusinessFunnelChartProps) {
  const componmentKey = {
    function_key: 'company_pyramid_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessFunnelChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
    />);
}

// 8 . 145--上市企业板块数量
export function CompanyChartpyramidFigureMarketFenxi(
  props: BusinessFunnelChartProps,
) {
  const componmentKey = {
    function_key: 'pyramid_figure_market_fenxi',
    class_name: '/chart/company',
  };
  const sort = ['主板', '创业板', '科创板', '北交所', '新三板-创新层', '新三板-基础层', '全量上市企业'];

  return (
    <BusinessFunnelChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
      sortArr={sort}
    />);
}

// 39 . 30--产业金字塔
export function CompanyChartindustrialPyramidFenxi(
  props: BusinessFunnelChartProps,
) {
  const componmentKey = {
    function_key: 'industrial_pyramid',
    class_name: '/chart/company',
  };
  return (
    <BusinessFunnelChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
    />);
}

// 国家级专精特新企业上市企业金字塔
export function CompanyChartPyramidFigureMarketSpecialization(
  props: BusinessFunnelChartProps,
) {
  const componmentKey = {
    function_key: 'pyramid_figure_market_specialization',
    class_name: '/chart/company',
  };
  return (
    <BusinessFunnelChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
    />);
}

// 资本流动性金字塔
export function CompanyChartMobilityOfCapitalPyramidSpecialization(
  props: BusinessFunnelChartProps,
) {
  const componmentKey = {
    function_key: 'mobility_of_capital_pyramid_specialization',
    class_name: '/chart/company',
  };
  return (
    <BusinessFunnelChart
      {...props}
      componmentKey={componmentKey}
      legend={false}
    />);
}
