import {
  ITreeConfig,
  ITreeStyle,
  ITreeVisualConfig,
} from '../interface/config';
import { IDataItem } from '../interface/data';
import { getLabel, omitUndefined } from './utils';
import { ISeriesGetter, ItemStyleOption } from '../interface/base';
import { merge } from 'lodash';

interface INode {
  [index: string | symbol]: any;

  children?: INode[];
}

type IPicker = (node: INode, extra: { depth: number; parent: INode | null }) => INode;
type Iterator = (root: INode[], depth: number, parent: null | INode) => INode[];
type IteratorGenerator = (picker: IPicker) => Iterator;
type IItemStyle = ItemStyleOption;

/**
 * 递归
 */
const iteratorGenerator: IteratorGenerator = (picker) => {
  const iterator: Iterator = (forest, depth, parent) => {
    if (!Array.isArray(forest) || forest.length === 0) {
      return [];
    }
    return forest.map(node => {
      const { children } = node;
      let _children: typeof children = [];
      const _node = picker(node, { depth, parent });
      if (Array.isArray(children)) {
        _children = iterator(children, depth + 1, node);
      }
      return { ..._node, children: _children };
    });
  };

  return iterator;
};


type colorHandler = (item: IItemStyle | undefined, parent: IItemStyle | undefined) => IItemStyle | undefined;
const getParentColor: colorHandler = (itemStyle, parentItemStyle) => {
  if (!parentItemStyle) {
    return itemStyle;
  }
  if (!itemStyle) {
    return itemStyle;
  }
  return omitUndefined({
    ...itemStyle,
    color: itemStyle.color === 'parent' ? parentItemStyle.color : itemStyle.color,
    borderColor: itemStyle.borderColor === 'parent' ? parentItemStyle.borderColor : itemStyle.borderColor,
  });
};

/**
 * 获取单个节点的样式
 * @param config
 * @param parentStyle
 * @param commonStyle
 */
const getEachNode = (config: ITreeVisualConfig, {
  parentStyle,
  commonStyle,
}: { parentStyle: ITreeStyle, commonStyle: ITreeStyle, }) => {
  const {
    label,
    itemStyle,
    lineStyle,
  } = config ?? {};

  return ({
   ...config,
    label: getLabel(label),
    itemStyle: getParentColor({ ...(commonStyle?.itemStyle ?? {}), ...itemStyle }, parentStyle?.itemStyle),
    lineStyle: getParentColor({ ...(commonStyle?.lineStyle ?? {}), ...lineStyle }, parentStyle?.lineStyle),
  });
};

/**
 * 获取所有数据节点的样式
 * @param visualConfig
 * @param data
 */
const getNodeStyles = (visualConfig: ITreeVisualConfig, data: IDataItem[]) => {
  const { levels, itemStyle, lineStyle, leaves, } = visualConfig;
  if (!levels) {
    return data;
  }
  const depthMap = new Map();
  const styleMap = new Map();
  const picker: IPicker = (node, { depth, parent }) => {
    let index;
    if (!depthMap.has(depth)) {
      depthMap.set(depth, 0);
      index = 0;
    } else {
      index = depthMap.get(depth) + 1;
      depthMap.set(depth, index);
    }

    const styles = levels[depth];
    let style;
    if (Array.isArray(styles)) {
      style = styles[index % styles.length];
    } else {
      style = styles;
    }
    if (!Array.isArray(node.children) || node.children.length === 0) {
      style = merge(style, leaves);
    }

    const _style = omitUndefined(getEachNode(
      style,
      {
        parentStyle: parent ? styleMap.get(parent) : null,
        commonStyle: {
          itemStyle,
          lineStyle,
        },
      },
    ));

    if (_style) {
      styleMap.set(node, _style);
    }

    return ({
      ...node,
      ..._style,
    });
  };
  const iterator = iteratorGenerator(picker);
  return iterator(data as INode[], 0, null);
};

const getEachSeries = (config: ITreeVisualConfig) => {
  const {
    top,
    right,
    bottom,
    left,
    zoom,
    center,
    layout = 'radial',
    orient = 'LR',
    width,
    height,
    roam,
    emphasis,
    symbol,
    symbolSize,
    symbolOffset,
    symbolRotate,
    edgeShape,
    edgeForkPosition,
    initialTreeDepth = -1, // 展开层级
    label,
    itemStyle,
    lineStyle,
    leaves,
  } = config ?? {};

  return ({
    top,
    right,
    bottom,
    left,
    layout,
    orient,
    zoom,
    roam,
    width,
    height,
    center,
    emphasis,
    symbol,
    symbolSize,
    symbolOffset,
    symbolRotate,
    initialTreeDepth,
    expandAndCollapse: false,
    nodeScaleRatio: 1,
    leaves,
    label: getLabel(label),
    edgeShape,
    edgeForkPosition,
    itemStyle,
    lineStyle,
  });
};

export const getSeriesTree: ISeriesGetter<ITreeVisualConfig> = (series, index, extra) => {
  const { material } = extra;
  const { visualConfig } = material.config as ITreeConfig;
  const { data } = series;

  // TODO: 添加样式匹配

  return omitUndefined({
    type: 'tree',
    data: getNodeStyles(visualConfig, data),
    ...getEachSeries(visualConfig),
  });
};
