/*
* Author: zhangmeng
* Description: '投资列表一行'
* Date: 2022/06/29
*/

import React, { useCallback } from 'react';
import TabsComp from '../TabsComp';
import style from './index.module.less';
import { openNewPage } from '../../../../utils/urlMethods';

function InvestmentListLine(props) {
  const { record, participle } = props;
  const highLightWord = useCallback((value) => {
    if (!value || !participle?.length) return value;
    let word = value;
    participle.forEach(item => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(item)) {
        word = word.split(item).join(`<span style="color: #D70000;">${item}</span>`);
      } else {
        const arr = word.split(item);
        const last = arr.pop();
        word = `${arr.join(`<span style="color: #D70000;">${item}</span>`)}${last}`;
      }
    });
    return word;
  }, [participle]);

  return (
    <div className={style.all} onClick={() => openNewPage('investmentDetail', { id: record.id })}>
      <div className={style.topLine}>
        <div className={style.left}>
          <div
            className={style.title}
            title={record.fullName}
            dangerouslySetInnerHTML={{
              __html: highLightWord(record.fullName),
            }}
          />
          <div
            className={style.otherName}
            style={{ display: `${record.shortName ? '' : 'none'}` }}
            title={record.shortName}
            dangerouslySetInnerHTML={{
              __html: `简称：${highLightWord(record.shortName)}`,
            }}
          />
        </div>
        <div className={style.right}>
          <div className={style.row}>
            <span>曝光日期：</span>
            <span>{record.exposure_date}</span>
          </div>
          <div className={style.row}>
            <span>交易金额：</span>
            <span>{record.dealAmount}</span>
          </div>
          <div className={style.row}>
            <span>区域：</span>
            <span title={record.area}>{record.area}</span>
          </div>
        </div>
      </div>
      <div className={style.bottom}>
        <TabsComp labels={record.tabs} />
      </div>
    </div>
  );
}

export default InvestmentListLine;
