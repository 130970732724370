/**
 * Author: zhangmeng
 * Description: 柱线图
 * Date: 2021/01/20
 * */
import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

const colorBar = ['#4E7293', '#4E7293', '#C5D5EA'];

const colorLine = ['#E9C582', '#276643', '#BCB3DF'];

export default function BarLineChart(props) {
  const { data } = props;
  const { abscissa_list: xAxis, detail_list: detailList, title } = data;
  const [option, setOption] = useState({});

  useEffect(() => {
    const legend = [];
    const series = [];
    let unit1 = '';
    let unit2 = '';
    let barIndex = 0;
    let lineIndex = 0;
    detailList.forEach((item) => {
      legend.push(item.target);
      if (item.y_index === '0') {
        series.push(
          {
            name: item.target,
            type: 'bar',
            barWidth: 12,
            yAxisIndex: 0,
            data: item.ordinate,
            smooth: true,
            itemStyle: {
              color: colorBar[barIndex % colorBar.length],
            },
          },
        );
        unit1 = item.unit;
        barIndex += 1;
      } else {
        series.push(
          {
            name: item.target,
            type: 'line',
            data: item.ordinate,
            yAxisIndex: 1,
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              color: colorLine[lineIndex % colorLine.length],
            },
            itemStyle: {
              color: colorLine[lineIndex % colorLine.length],
            },
          },
        );
        unit2 = item.unit;
        lineIndex += 1;
      }
    });

    const formatterFunc = (p) => {
      const arr = p.map((item) => `<div style="display: flex;margin-top: 11px;">
          <div style="font-size: 14px;color: #262D36;letter-spacing: 1.75px;margin-right: 14px">${item.seriesName}</div>
          <div style="font-size: 18px;color: #4E7293;letter-spacing: 2.25px;">${item.value}</div>
          <div style="font-size: 14px;color: #262D36;letter-spacing: 1.75px;">&nbsp;${item.seriesName === '交易事件' ? unit1 : unit2}</div>
                </div>`);
      const len = arr.length;
      let str = '';
      for (let i = 0; i < len; i += 1) {
        str += arr[i];
      }
      return str;
    };

    const options = {
      grid: {
        top: 40,
        right: 50,
        bottom: 30,
        left: 20,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        axisPointer: {
          type: 'shadow',
        },
        borderColor: '#DCDCDC',
        formatter: p => {
          const arr = p[0].name.split('.');
          const str = `<div>
                         <div style="font-size: 14px;color: #3E5C76;letter-spacing: 1.75px;">
                            ${arr[0] || ''}
                            ${arr[1] || ''}
                            ${arr[2] || ''}
                          </div>
                        ${formatterFunc(p)}
                    </div>`;
          return str;
        },
      },
      legend: {
        data: legend,
        bottom: 10,
        itemGap: 50,
        itemWidth: 10,
        itemHeight: 7,
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: '#777A7A',
            interval: 0,
            rotate: 60,
          },
          data: xAxis,
        },
      ],
      yAxis: [
        {
          name: unit1 || '',
          nameTextStyle: {
            fontSize: 10,
            color: '#919191',
            align: 'right',
          },
          axisTick: { show: false },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: '#777A7A',
          },
          type: 'value',
          splitLine: { // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
            },
            show: true, // 隐藏或显示
          },
        },
        {
          name: unit2 || '',
          nameTextStyle: {
            fontSize: 10,
            color: '#919191',
            align: 'left',
          },
          axisTick: { show: false },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: '#777A7A',
          },
          type: 'value',
          splitLine: { // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
            },
            show: true, // 隐藏或显示
          },
        },
      ],
      dataZoom: title === '行业分布' ? [{
        type: 'slider',
        start: 0,
        end: 50,
        height: 6,
        handleIcon: 'M512 512m-208 0a6.5 6.5 0 1 0 416 0 6.5 6.5 0 1 0-416 0Z M512 192C335.264 192 192 335.264 192 512c0 176.736 143.264 320 320 320s320-143.264 320-320C832 335.264 688.736 192 512 192zM512 800c-159.072 0-288-128.928-288-288 0-159.072 128.928-288 288-288s288 128.928 288 288C800 671.072 671.072 800 512 800z',
        bottom: 40,
        showDetail: false,
        handleColor: '#aab6c6',
        borderColor: 'transparent',
        backgroundColor: '#e2e2e2',
        moveHandleSize: 0,
        handleSize: 10,
        handleStyle: {
          borderColor: '#aab6c6',
          shadowBlur: 4,
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowColor: '#e5e5e5',
        },
      }] : [],
      series,
    };
    setOption(options);
  }, [data]);

  return (
    <ReactEcharts
      option={option}
      style={{ height: '100%', width: '100%' }}
    />
  );
}
