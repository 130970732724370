/*
* Description: '漏斗图'
* Date: 2022/03/02
*/

import React, { useEffect, useState, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Spin } from 'antd';
import style from './index.module.less';
import ChartNoData from '../ChartNoData';
import punlicStyle from '../public/index.module.less';

const color1 = [
  '#FD6205', '#59D700', '#4E88FF', '#1961F5',
  '#1307ba', '#0702a5', '#030061',
];
const color2 = ['#011436', '#011436', '#011436', '#011436', '#011436', '#011436'];
function FunnelChart(props) {
  const { data, isFetching } = props;
  const [options, setOptions] = useState({});
  const { length: dataLength } = data ?? {};
  const paddingLeft = 55 / dataLength;
  useEffect(() => {
    const { length } = data || {};
    const gap = 100 / length;
    const newData = data?.map((item, index) => (
      {
        value: index * gap + gap,
        name: item.name,
        amount: item.value,
        percent: item.percent,
        itemStyle: {
          borderWidth: 0,
          color: color1[index % color1.length],
        },
      }
    ));

    const option = {
      series: [
        {
          z: 10,
          name: 'Funnel',
          type: 'funnel',
          left: 0,
          top: 20,
          bottom: 20,
          width: '100%',
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          sort: 'ascending',
          gap: 2,
          label: {
            show: true,
            position: 'inside',
            color: '#FFFFFF',
            fontWeight: 600,
            fontSize: 12,
            lineHeight: 10,
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 3,
              type: 'solid',
            },
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 3,
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: newData,
        },
      ],
    };
    setOptions(option);
  }, [data]);

  const cheartEl = () => {
    if (data || data.length > 0) {
      return (
        <div className={style.all}>
          <div style={{ width: '50%', height: '100%', zIndex: 10 }}>
            <ReactEcharts
              option={options}
              notMerge
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div className={style.right}>
            {
              data?.map((item, index) => (
                <div
                  className={style.box}
                  key={index}
                >
                  <div className={style.num}>
                    {item.value}
                    家
                  </div>
                  {
                    item.name !== '全量企业' && (
                      <div className={style.percent} title={`占比${item.proportion}%`}>
                        {/* {item.proportion} */}
                        {`占比${item.proportion}%`}
                      </div>
                    )
                  }
                </div>
              ))
            }
          </div>
        </div>
      );
    }
    return <ChartNoData />;
  };

  return (
    <Spin spinning={isFetching} wrapperClassName={punlicStyle.loding}>
      {
       data && cheartEl()
      }
    </Spin>
  );
}

export default FunnelChart;

FunnelChart.defaultProps = {
  data: [],
};
