// 分支机构
import React, { useState, useEffect, useMemo } from 'react';
import { ECharts, echarts, Table } from '@sqke/design';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';
import style from './index.module.less';
import { useGetBranchRegionalDistributionQuery, useGetBranchInstitutionQuery, useGetRegionalDistributionOfBranchesTableQuery } from '../../../../../service/companyDetail';
import { useLazyGetCityJSONQuery } from '../../../../../service/static-resource';
import { openNewPage } from '../../../../../utils/urlMethods';
// import { tableData as companyNumberTableData } from './data';

const MAP_COLOR = [
  '#8CCAFF', '#70BCFF', '#49AAFF', '#3088F5',
  '#2F73FF', '#1961F5', '#0051F7', '#0044D1',
];

function Branch(props) {
  const { companyId, preventPageto } = props;
  const [option, setOption] = useState({});
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const [getCityJSON] = useLazyGetCityJSONQuery();

  const { data, isFetching } = useGetBranchRegionalDistributionQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const { pool, series } = data ?? {};

  useEffect(async () => {
    const { data: geojson, isError: isGetJSONError } = await getCityJSON('000000', true);
    if (isGetJSONError) return;
    echarts.registerMap('china', cloneDeep(geojson));

    const _series = series?.map(item => ({
      name: item.name,
      type: 'map',
      geoIndex: 0,
      map: 'china',
      tooltip: {
        formatter: params => {
          const { data: _data } = params;
          if (!_data) return null;
          return `${_data.name}&emsp;&emsp;&emsp;${_data.value}`;
        },
      },
      data: item.data,
    }));
    const seriesData = series?.[0]?.data;
    const maxValue = seriesData?.length > 0 ? Math.max(...seriesData.map(item => item.value)) : 0;
    const minValue = seriesData?.length > 0 ? Math.min(...seriesData.map(item => item.value)) : 0;
    setOption({
      geo: {
        map: 'china',
        roam: true,
        scaleLimit: {
          min: 0.8,
        },
      },
      tooltip: {},
      visualMap: [{
        type: 'piecewise',
        min: minValue,
        max: maxValue,
        left: 60,
        bottom: 80,
        splitNumber: 8,
        inRange: {
          color: MAP_COLOR,
        },
        seriesIndex: 0,
        itemWidth: 22,
        itemHeight: 6,
        itemGap: 3,
        text: [maxValue, minValue],
        textStyle: {
          color: '#0F2849',
          fontSize: 12,
        },
      }],
      series: _series,
    });
  }, [series]);

  const { data: tableData, isFetching: isTableFetching } = useGetBranchInstitutionQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const { data: companyNumberTableData, isFetching: iscompanyNumberTableFetching } = useGetRegionalDistributionOfBranchesTableQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'company_name' && !preventPageto) {
        return {
          ...item,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('companyDetail', { id: record.id })}
            >
              {text}
            </div>
          ),
        };
      }
      return {
        ...item,
      };
    })
  ), [tableData, preventPageto]);

  const chartEl = () => {
    if (!series) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (series?.length > 0) {
      return <ECharts
        showLoading={isFetching}
        className={style.map}
        option={option}
      />;
    }
    return <></>;
  };
  const tablEl = () => {
    if (!tableData) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (tableData?.dataSource.length > 0) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={tableColumns}
          title={
            () => (
              <>
                <span>{tableData?.title}</span>
                <span className={style.count}>{tableData?.count}</span>
              </>
            )
          }
          strip
          loading={isTableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return <></>;
  };

  const companyNumberColumns = () => companyNumberTableData?.columns.map(item => {
    if (item.dataIndex === 'name') {
      return {
        ...item,
        render: (text, params) => <div className={params.level === 'province' ? style.province : style.city}>{text}</div>,
      };
    }
    return item;
  });

  const investCompanyTable = useMemo(() => {
    if (!companyNumberTableData || companyNumberTableData?.dataSource.length) {
      return <Table
        rowKey={(text, value) => value}
        dataSource={companyNumberTableData?.dataSource}
        columns={companyNumberColumns()}
        locale={{ emptyText: ' ' }}
        loading={iscompanyNumberTableFetching}
        scroll={{ y: 540 }}
        title={() => (
          <>
            <span>分支机构数</span>
            <span className={style.count}>{companyNumberTableData?.company_count}</span>
            家
          </>
        )}
        strip
        pagination={false}
      />;
    }
    return null;
  }, [companyNumberTableData]);

  return (
    <div className={style.all} id="分支机构">
      <div className={style.title}>分支机构</div>
      <div className={style.subTitle}>
        {pool?.['描述']}
      </div>
      <div className={style.content}>
        <div>
          {
            chartEl()
          }
        </div>
        <div>
          {
            investCompanyTable
          }
        </div>
      </div>
      {
        tablEl()
      }
    </div>
  );
}

export default Branch;
