/*
 *Author: zhaochenyu
 *Description: 轮播表格
 *Date: 2021/09/28
*/
import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { CarouselTableProps } from '../basicsType';
import { isNumber, isString } from '../../utils/tool';
import { checkTableData, getCurColWidth } from './checkDataFormat';
import './index.less';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component-carousel-table';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTableHead = classNames(`${prefixCls}-head`);
const styleTableHeadItem = classNames(`${prefixCls}-head-item`);
const styleTableBody = classNames(`${prefixCls}-body`);
const styleUnscrollBody = classNames(`${prefixCls}-body-unscroll`);
const styleBodyTr = classNames(`${prefixCls}-body-tr`);
const styleBodyTd = classNames(`${prefixCls}-td`);
const styleBodyTdLine = classNames(`${prefixCls}-body-td-line`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, useCallback } = React;

function getTableHead(head: any[], bodyWidth: number) {
  let setWidthNum: number = 0;
  let remainWidth: number = bodyWidth;
  head.forEach(item => {
    if (item.width && isNumber(item.width)) {
      setWidthNum += 1;
      remainWidth -= item.width;
    } else if (item.width && isString(item.width)) {
      setWidthNum += 1;
      remainWidth = item.width.indexOf('%') > -1 ? (remainWidth * (1 - (parseInt(item.width) / 100))) : (remainWidth -= parseInt(item.width));
    }
  });
  return head.map(item => ({
    title: item.title,
    dataIndex: item.dataIndex,
    width: getCurColWidth(item.width) || `${(((remainWidth) / (head.length - setWidthNum)) / bodyWidth) * 100}%`,
    align: item.align || 'center',
    render: item.render || null,
  }));
}

function getNewTableBody(body: any[], head: any[]) {
  const newTableBody = [];
  if (body.length) {
    for (let i = 0, len = body.length; i < len; i += 1) {
      const list: any[] = [];
      head.forEach(item => {
        let newName = (
          <div
            className={styleBodyTd}
            title={body[i][item.dataIndex]}
          >
            {body[i][item.dataIndex]}
          </div>);
        if (item.render) {
          newName = item.render(body[i][item.dataIndex], body[i], i);
        }
        list.push({
          name: newName,
          width: item.width,
          align: item.align,
        });
      });
      newTableBody.push(list);
    }
  }
  return newTableBody;
}

function CarouselTable(props: CarouselTableProps) {
  const { mode, data, speed = 3000, showAmount = 10 } = props;
  const [unHandleData, setUnHandleData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>({ columns: [], dataSource: [] });
  const [rowHeight, setHeight] = useState<number>(0);
  const [mouseStatue, setMouse] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const timer: any = useRef(null);
  const timer1: any = useRef(null);
  const curData: any = useRef(null);
  const curHeight: any = useRef(10);
  const curWidth: any = useRef(10);
  const carouselBody: any = useRef(null);

  const handleResize = useCallback(() => {
    if (carouselBody.current) {
      const curHeights = carouselBody.current.getBoundingClientRect().height;
      curWidth.current = carouselBody.current.getBoundingClientRect().width;
      curHeight.current = showAmount ? Math.floor(curHeights / Number(showAmount)) : 0;
      setHeight(curHeight.current);
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const stopScroll = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  const reStartScroll = useCallback(() => {
    const interval = Math.ceil(Number(speed) / curHeight.current);
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    let scroll = carouselBody.current.scrollTop;
    timer.current = setInterval(() => {
      const newScroll = scroll + 1;
      if (newScroll > (curData.current.dataSource.length) * 2 * curHeight.current) {
        scroll = newScroll - (curData.current.dataSource.length * curHeight.current);
      } else {
        scroll = newScroll;
      }
      carouselBody.current.scrollTo(0, scroll);
    }, interval < 100 ? 100 : interval);
  }, []);

  useEffect(() => {
    // 清空原有定时器
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    if (timer1.current) {
      clearTimeout(timer1.current);
      timer1.current = null;
    }
    setUnHandleData(checkTableData(data || {}));
    setTableLoading(false);
  }, [data]);

  useEffect(() => {
    if (!unHandleData) return undefined;
    const newCol = getTableHead(unHandleData.columns, curWidth.current);
    const newTableData = {
      columns: newCol,
      dataSource: getNewTableBody(unHandleData.dataSource, newCol),
    };
    setTableData(newTableData);
    curData.current = newTableData;
    carouselBody.current.scrollTo(0, 0);
    if (newTableData.dataSource.length > showAmount) {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
      if (timer1.current) {
        clearTimeout(timer1.current);
        timer1.current = null;
      }
      timer1.current = setTimeout(() => {
        reStartScroll();
      }, 1000);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
      if (timer1.current) {
        clearTimeout(timer1.current);
        timer1.current = null;
      }
    };
  }, [unHandleData]);

  return (
    <div className={styleMap[mode!] || styleDark}>
      <div className={styleTableHead}>
        {tableData.columns.map((item: any, index: number) => (
          <div
            key={`header${item.dataIndex}`}
            className={styleTableHeadItem}
            style={{ width: item.width, textAlign: item.align, paddingRight: index === 0 ? '20px' : '6px' }}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div
        className={mouseStatue ? styleTableBody : styleUnscrollBody}
        ref={carouselBody}
        onMouseOver={() => {
          setMouse(true);
          stopScroll();
        }}
        onMouseLeave={() => {
          setMouse(false);
          reStartScroll();
        }}
        onFocus={() => {}}
      >
        {tableLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={tableLoading} /></div>)}
        {!tableLoading && tableData.dataSource.length === 0
          && (<div className={styleEmpty}>暂无数据</div>)}
        {(!tableLoading && tableData.dataSource.length > 0
          && tableData.dataSource.length > showAmount
          ? [...tableData.dataSource, ...tableData.dataSource, ...tableData.dataSource]
          : tableData.dataSource)
          .map((item: any, index: number) => (
            <div
              className={styleBodyTr}
              key={`${index}carouselTable`}
            >
              {item.map((item1: any, index1: number) => (
                <div
                  key={`${index1}${index}bodyItem`}
                  className={styleBodyTd}
                  style={{ width: item1.width, height: `${rowHeight}px`, lineHeight: `${rowHeight}px`, textAlign: item1.align, paddingRight: index1 === 0 ? '20px' : '6px' }}
                >
                  {item1.name}
                </div>
              ))}
              <div className={styleBodyTdLine} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default CarouselTable;

CarouselTable.defaultProps = {
  mode: 'dark',
  speed: 3000,
  showAmount: 10,
};
