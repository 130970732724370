// 下拉树形选择器

import React from 'react';
import { TreeSelect, Spin } from 'antd';
import style from './index.module.less';

const loading = (
  <div className={style.loading}>
    <Spin size="small" />
  </div>
);

/**
 *
 * @param {Object} customStyle 自定义样式
 * @param {Array} value 选中的值
 * @param {Object} data 树的数据
 * @param {Function} onChange 选择时触发
 * @param {Array} defaultNode 默认展开的节点
 * @param {Boolean} isFetching 数据是否加载中
 * @param {String} placeholder 无选择时展示
 * @returns
 */
const CustomTreeSelect = ({ style: customStyle, value, data, onChange, defaultNode, isFetching, placeholder }) => (
  <TreeSelect
    showSearch
    showArrow
    multiple
    style={customStyle}
    value={value}
    placeholder={placeholder}
    dropdownStyle={{
      maxHeight: 400,
      overflow: 'auto',
    }}
    maxTagCount="responsive"
    notFoundContent={isFetching ? loading : null}
    treeDefaultExpandedKeys={defaultNode}
    onChange={onChange}
    treeData={data}
  />
);

export default CustomTreeSelect;
