import { get, post, requestFile } from '../utils/request';
import { KNOWLEDGECOMPONENT } from '../utils/url';

// 导出产业链穿透图  /industry_knowledge_engine/v2/knowledge_component/export_industry_link_through_the_figure
const getExportIndustryLinkThroughTheFigure = (param, name) => requestFile('post', `${KNOWLEDGECOMPONENT}/export_industry_link_through_the_figure`, {
  ...param,
}, `${name}.xlsx`, false, {
  'Content-Type': 'application/json',
});

// 导出大型企业区域布局穿透  /industry_knowledge_engine/v2/knowledge_component/export_penetration_regional_layout_large_enterprises
const getExportPenetrationRegionalLayoutLargeEnterprises = (param, name) => requestFile('post', `${KNOWLEDGECOMPONENT}/export_penetration_regional_layout_large_enterprises`, {
  ...param,
}, `${name}.xlsx`, false, {
  'Content-Type': 'application/json',
});

// 导出大型企业产业布局穿透  /industry_knowledge_engine/v2/knowledge_component/export_penetration_industry_layout_large_enterprises
const getExportPenetrationIndustryLayoutLargeEnterprises = (param, name) => requestFile('post', `${KNOWLEDGECOMPONENT}/export_penetration_industry_layout_large_enterprises`, {
  ...param,
}, `${name}.xlsx`, false, {
  'Content-Type': 'application/json',
});

// 模糊匹配公司名称  /industry_knowledge_engine/v2/knowledge_component/interaction/find_company_list_by_like_name
const getFindCompanyListByLikeName = param => post(`${KNOWLEDGECOMPONENT}/interaction/find_company_list_by_like_name`, {
  ...param,
});

// 导出区域资本流向图  /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/export
const getExportRegionalCapitalFlowChart = (param, name) => requestFile('post', `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/export`, {
  ...param,
}, `${name}.xlsx`, false, {
  'Content-Type': 'application/json',
});

export {
  getExportIndustryLinkThroughTheFigure,
  getExportPenetrationIndustryLayoutLargeEnterprises,
  getExportPenetrationRegionalLayoutLargeEnterprises,
  getFindCompanyListByLikeName,
  getExportRegionalCapitalFlowChart,
};
