import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import Tree from './Tree';
import Container from '../../../Container';
import CustomListFilter from '../../../../../CustomListFilter';
import { FilterIcon } from '../../../../../CustomTableIcon';
import { useGetCompanyCredentialTagListQuery } from '../../../../../../service/companyDetail';
import style from './index.module.less';

const levelList = [
  { text: '国家级', value: '国家级' },
  { text: '省级', value: '省级' },
];

function HonorQualification(props) {
  const { companyId, companyName } = props;
  const [pitchLevel, setPitchLevel] = useState([]);
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const { data: tableData, isFetching: tableFetching } = useGetCompanyCredentialTagListQuery(
    {
      page_size: page.pageSize,
      page_number: page.pageNumber,
      tag_level_list: pitchLevel,
      company_id: companyId,
    },
    { skip: !companyId },
  );
  const columns = useMemo(() => tableData?.columns.map(item => {
    if (item.dataIndex === 'index') {
      return {
        width: 70,
        ...item,
      };
    }
    if (item.dataIndex === 'level') {
      return {
        ...item,
        width: 120,
        ellipsis: true,
        filterIcon: <FilterIcon />,
        filteredValue: pitchLevel?.length > 0 ? pitchLevel : ['all'],
        filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
        filters: levelList,
        filterResetToDefaultFilteredValue: true,
      };
    }
    if (item.dataIndex === 'year') {
      return {
        width: 100,
        ...item,
      };
    }
    if (item.dataIndex === 'company_name') {
      return {
        width: 300,
        ...item,
      };
    }
    return item;
  }), [tableData]);
  const tableEl = useMemo(() => {
    if (!tableData || tableData?.dataSource) {
      return (<Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={columns}
        locale={{ emptyText: ' ' }}
        title={
          () => (
            <>
              <span>{tableData?.title}</span>
              <span className={style.count}>{tableData?.count}</span>
            </>
          )
        }
        strip
        loading={tableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }, filter) => {
          setPitchLevel(filter.level);
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />);
    }
    return null;
  }, [tableData]);
  const option = {
    title: { title: '荣誉资质', companyName },
    option: {
      name: '荣誉资质',
      companyId,
    },
  };
  return (
    <Container id="荣誉资质" question="展示企业所获国家级标签、省级标签、资质标签及对应标签的获取年份" look="查看全图" option={option}>
      <div style={{ width: '1360px', height: '400px', position: 'relative' }}>
        <Tree {...props} />
      </div>
      {
        tableEl
      }

    </Container>
  );
}

export default HonorQualification;
