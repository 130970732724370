/*
 *Author: zhaochenyu
 *Description: 一些处理url的方法
 *Date: 2021/08/26
*/
import { projectName } from './url';

function getCurUrlData(url, key) {
  const urlSearch = url.split('?').length > 0 ? url.split('?')[1] : '';
  if (!urlSearch) return '';
  const param = new URLSearchParams(urlSearch);
  return param.get(key);
}

function openNewPage(path, query) {
  const a = [];
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const openCompanyDetailWithToken = menu.find(item => item === 'openCompanyDetailWithToken');
  if (path === 'companyDetail' && openCompanyDetailWithToken) {
    path = 'homePageLogin';
    query.maxTime = localStorage.getItem('maxTime');
    query.token = localStorage.getItem('token');
    query.rft = localStorage.getItem('rft');
    query.ocdwt = '1';
  }
  let url = `${window.location.origin}/${process.env.NODE_ENV === 'development' ? '' : `${projectName}/`}#/${path}`;
  for (const key in query) {
    a.push(`${key}=${query[key]}`);
  }
  if (a.length > 0) {
    url += `?${a.join('&')}`;
  }
  window.open(url);
}

function changeSelfPage(path, query) {
  const a = [];
  let url = `${window.location.origin}/${process.env.NODE_ENV === 'development' ? '' : `${projectName}/`}#/${path}`;
  for (const key in query) {
    a.push(`${key}=${query[key]}`);
  }
  if (a.length > 0) {
    url += `?${a.join('&')}`;
  }
  window.location.href = url;
}

function changeSelfPageWithSearch(path, search) {
  const a = [];
  const url = `${window.location.origin}/${process.env.NODE_ENV === 'development' ? '' : `${projectName}/`}#${path}${search}`;
  window.location.href = url;
}

function changeSelfPageToOtherProject(path, query, project) {
  const a = [];
  let url = `${window.location.origin}/${process.env.NODE_ENV === 'development' ? '' : `${project}/`}#/${path}`;
  for (const key in query) {
    a.push(`${key}=${query[key]}`);
  }
  if (a.length > 0) {
    url += `?${a.join('&')}`;
  }
  window.location.href = url;
}

export {
  getCurUrlData,
  openNewPage,
  changeSelfPage,
  changeSelfPageToOtherProject,
  changeSelfPageWithSearch,
};
