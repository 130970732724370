import { get, post } from '../utils/request';
import { SPECIALIZATION } from '../utils/url';

// 新闻列表
const getNewsList = () => get(`${SPECIALIZATION}/news_list`, {});

// 政策列表
const getPolicyList = () => get(`${SPECIALIZATION}/policy_list`, {});

// 下钻地图数据
const getMapData = param => get(`${SPECIALIZATION}/specialization_map`, {
  ...param,
});

// 区域表格
const getAreaTableData = param => post(`${SPECIALIZATION}/area_distribution_company_rank`, {
  ...param,
});

// 企业列表
const getCompnayList = param => post(`${SPECIALIZATION}/company_list`, {
  ...param,
});

// 级别筛选条件
const getLevelList = () => get(`${SPECIALIZATION}/specialization_level`, {});

// 报告列表
const getReportList = () => get(`${SPECIALIZATION}/special_report_list`, {});

export {
  getLevelList,
  getReportList,
  getNewsList,
  getPolicyList,
  getMapData,
  getAreaTableData,
  getCompnayList,
};
