/**
 * 静态资源请求
 */
import { apiSlice } from '../slices/api';

export const staticResourceApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCityJSON: builder.query({
      queryFn: regionCode => fetch(`${process.env.REACT_APP_JSON_URL}/${regionCode}.json`, { method: 'get' }).then(
        res => ({ data: res.json(), meta: res.meta }),
      ),
    }),
  }),
});

export const {
  useLazyGetCityJSONQuery,
} = staticResourceApiSlice;
