import React, { useMemo, useEffect } from 'react';
import classnames from 'classnames';
import Table from '../../../../../../components/Table';
import RankingOrder from '../../../../../../components/RankingOrder';
import DataSearchButton from '../../../../CityContent/DataSearchButton';
import SortTitle from '../../../../CityContent/SortTitle';
import styles from './index.module.less';
import { useGetMigrationStatisticsQuery } from '../../../../../../service/cityTool';

const THEAD_TABLE = {
  index: {
    title: '排行',
    dataIndex: 'index',
    key: 'index',
    width: 40,
    render: text => <RankingOrder order={text} />,
  },
  area: {
    title: '迁出地',
    dataIndex: 'area',
    key: 'area',
    ellipsis: true,
  },
  company_count: {
    title: '企业数量',
    dataIndex: 'company_count',
    key: 'company_count',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  head_company_count: {
    title: '链主企业',
    dataIndex: 'head_company_count',
    key: 'head_company_count',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  market_company_count: {
    title: '上市企业',
    dataIndex: 'market_company_count',
    key: 'market_company_count',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  specialization_new_company_count: {
    title: '专精特新',
    dataIndex: 'specialization_new_company_count',
    key: 'specialization_new_company_count',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
  high_tech_company_count: {
    title: '高新技术',
    dataIndex: 'high_tech_company_count',
    key: 'high_tech_company_count',
    sorter: true,
    sortDirections: ['descend'],
    render(text) {
      return (
        <DataSearchButton
          text={text}
          style={{ marginLeft: 8 }}
        />
      );
    },
  },
};

const COLUMN_KEYS = [
  'index', 'area', 'company_count', 'head_company_count',
  'market_company_count', 'specialization_new_company_count', 'high_tech_company_count',
];

function DistributionRanking(props) {
  const {
    tab: moveType,
    regionCode,
    sortedInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onClick,
    className,
    areaList,
    industryCodeList,
    dateRangeKey,
  } = props;

  const {
    data,
    isFetching,
  } = useGetMigrationStatisticsQuery({
    area_key: regionCode,
    select_time_scope: dateRangeKey,
    industry_code_list: industryCodeList,
    select_area_list: areaList,
    move_type: moveType,
    sort_key: sortedInfo?.order ? sortedInfo?.columnKey : null,
    sort_value: sortedInfo?.order === 'descend' ? 'desc' : null,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !regionCode,
  });
  const { columns: tableHead = [], dataSource: tableBody = [], total = 0 } = data ?? {};
  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
        sortOrder: sortedInfo.columnKey === item.dataIndex ? (sortedInfo?.order ?? null) : null,
      });
      if ([
        'company_count',
        'head_company_count',
        'market_company_count',
        'specialization_new_company_count',
        'high_tech_company_count',
      ].includes(item.dataIndex)) {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item })}
          />
        );
      }
      if (item.dataIndex === 'company_count') {
        rowConfig.className = 'keep';
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [tableHead, sortedInfo, onClick]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
      className={classnames(styles.distributionRanking, className)}
    />
  );
}

export default DistributionRanking;
