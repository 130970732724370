import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { initDingH5RemoteDebug } from 'dingtalk-h5-remote-debug';
import zhCN from 'antd/es/locale/zh_CN';
import App from './App';
import 'moment/locale/zh-cn';
import reportWebVitals from './reportWebVitals';
import store from './store';
import '@shangqi/cube-component/dist/cube-component.min.css';
import 'antd/dist/antd.css';
import '@sqke/design/dist/esm/index.css';
import './index.css';
import './index.module.less';
import './utils/polyfill';

initDingH5RemoteDebug();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
