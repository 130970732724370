import React, { memo } from 'react';
import classNames from 'classnames';
import style from './index.module.less';

const Tabs = ({ data, checkedTab, setCheckedTab }) => (
  <div className={style.tabs}>
    {
      data?.map(item => <div key={item.title} className={classNames({ [style.checkedTab]: checkedTab?.title === item.title })} onClick={() => { setCheckedTab(item); }}>{ item.title }</div>)
    }
  </div>
);

export default memo(Tabs);
