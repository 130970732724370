import { ISeriesGetter, SeriesTypes } from '../interface/base';
import { IRadarVisualConfig } from '../interface/config';
import { getDataItem, omitUndefined } from './utils';
import { merge } from 'lodash';

const getEach = (series: any) => {
  const {
    symbol,
    symbolSize,
    symbolRotate,
    symbolOffset,
    label,
    lineStyle,
    itemStyle,
    areaStyle,
    // own
    showArea,
  } = series;

  return omitUndefined({
    symbol,
    symbolSize,
    symbolRotate,
    symbolOffset,
    label,
    lineStyle,
    itemStyle,
    // own
    areaStyle: showArea ? areaStyle : undefined,
  });
}

export const getRadarSeries: ISeriesGetter<IRadarVisualConfig> = (series, index, extra) => {
  const type: SeriesTypes = 'radar';
  const {
    material,
    getMatchedItemStyle,
  } = extra;
  const { config } = material;
  const { visualConfig } = config;
  const { series: seriesStyles, } = visualConfig;
  const seriesStyle = seriesStyles ? seriesStyles[index]: undefined;
  const { name, data }  = series;
  const seriesProperty = {
    seriesName: name,
    seriesIndex: index,
  };
  const seriesItem = {
    name,
    type,
    coordinateSystem: undefined,
  };
  const seriesData = data.map((d, index) => {
    const item = getDataItem(d);
    const _d = {
      ...seriesProperty,
      type,
      ...item,
      index,
    };
    const matcherStyle = getMatchedItemStyle(_d);

    if (Object.keys(matcherStyle).length === 0) {
      return d;
    }
    return ({
      ...item,
      ...getEach(matcherStyle),
    });
  });

  return omitUndefined(
    merge(
      seriesItem,
      getEach(visualConfig),
      { data: seriesData },
      seriesStyle ? getEach(seriesStyle) : {},
    )
  );
}
