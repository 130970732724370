// 下来选择框
import React from 'react';
import { Select, Spin } from 'antd';
import style from './index.module.less';

const loading = (
  <div className={style.loading}>
    <Spin size="small" />
  </div>
);

/**
 * @param {Object} style 搜索框样式
 * @param {Number | String} value 搜索框选中的内容
 * @param {Function} onChange 搜索框输入时触发
 * @param {Array} data 下拉框的数据
 * @param {String} placeholder 无选择时展示
 * @param {Boolean} isFetching 数据是否加载中
 * @returns
 */

const CustomScreen = ({ style: paramsStyle, value, onChange, data, placeholder, isFetching }) => (
  <Select
    mode="multiple"
    showSearch
    showArrow
    maxTagCount="responsive"
    value={value}
    notFoundContent={isFetching ? loading : null}
    placeholder={placeholder}
    style={paramsStyle}
    onChange={onChange}
    options={data}
  />
);

export default CustomScreen;
