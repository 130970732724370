/**
 * 堆叠柱状图
*/
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import ChartNoData from './ChartNoData';
import style from './public/index.module.less';

/**
 * @param {*} param0
 * @returns
 */

const StackBar = ({ data, isFetching }) => {
  const {
    x,
    series,
    color,
    yName,
    yMax,
    download = false,
    leftYName = 0,
    left = 50,
    right = 40,
    bottom = 56,
    rotate = 60,
  } = data || {};
  const option = useMemo(() => {
    const seriesNew = series?.map((item, index) => ({
      name: item.name,
      data: item.data,
      color: color && color[index],
      type: 'bar',
      stack: 'total',
      emphasis: {
        focus: 'series',
      },
    }));
    return {
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: x?.length > 10 && rotate,
          fontSize: x?.length > 12 ? 9 : 12,
        },
        data: x,
      },
      tooltip: {
        trigger: 'axis',
        formatter: params => {
          let relVal = params[0].axisValueLabel;
          for (let i = 0; i < params.length; i += 1) {
            relVal += `<br/>${params[i].marker}${params[i].seriesName}\u3000\u3000${params[i].value.toLocaleString()}${series[i].unit}`;
          }
          return relVal;
        },
      },
      toolbox: download && {
        show: true,
        feature: {
          saveAsImage: {
            type: 'png',
          },
        },
      },
      yAxis: {
        name: yName,
        type: 'value',
        max: yMax,
        nameTextStyle: {
          padding: leftYName,
        },
      },
      grid: {
        top: 28,
        right,
        bottom,
        left,
        // 坐标系不跟随内容改变
        containLabel: false,
      },
      legend: {
        show: true,
        bottom: 0,
        // itemGap: 60,
        itemHeight: 7,
        itemWidth: 12,
      },
      series: seriesNew,
    };
  }, [data]);
  const cheartEl = useMemo(() => {
    if (!series || series.length > 0) {
      return <ECharts
        style={{
          height: '100%',
          width: '100%',
        }}
        option={option}
      />;
    }
    return <ChartNoData />;
  }, [data]);
  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {cheartEl}
    </Spin>
  );
};

export default StackBar;
