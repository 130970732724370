/**
 * 榜单列表
 */
import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import Table from '../../components/Table';
import style from './index.module.less';
import { useLazyGetGatentRankingTableQuery, useLazyGetGrowthPotentialListQuery, useLazyGetSqScoreListQuery } from '../../../../service/special';
import {
  patentColumns,
  potentialColumns,
  sqColumns,
} from './utils';

const Ranking = ({ level, areaCode }) => {
  const [loadings, setLoadings] = useState([false, false, false]);
  const [patentTableData, setPatentTableData] = useState({});
  const [potentialTableData, setPotentialTableData] = useState({});
  const [recommendData, setRecommendData] = useState([]);

  const [getGatentRankingTableQuery] = useLazyGetGatentRankingTableQuery();
  const [getGrowthPotentialListQuery] = useLazyGetGrowthPotentialListQuery();
  const [getSqScoreListQuery] = useLazyGetSqScoreListQuery();

  const update = (num) => {
    const cloneLoadings = loadings.slice();
    cloneLoadings.splice(num, 1, false);
    setLoadings(cloneLoadings);
  };
  // 获取专利排名前100
  const getGatentRankingTable = () => {
    getGatentRankingTableQuery({
      select_level: level,
      area_key: areaCode,
    }).then(res => {
      if (res.status === 'fulfilled') {
        update(0);
        setPatentTableData(res.data);
      }
    }).catch(err => {
      throw new Error(err);
    });
  };

  // 获取潜力排名前100
  const getGrowthPotentialList = () => {
    getGrowthPotentialListQuery({
      select_level: level,
      area_key: areaCode,
    }).then(res => {
      if (res.status === 'fulfilled') {
        update(1);
        setPotentialTableData(res.data);
      }
    }).catch(err => {
      throw new Error(err);
    });
  };

  // 获取推荐企业列表
  const getSqScoreList = () => {
    getSqScoreListQuery({
      select_level: level,
      area_key: areaCode,
    }).then(res => {
      if (res.status === 'fulfilled') {
        update(2);
        setRecommendData(res.data);
      }
    }).catch(err => {
      throw new Error(err);
    });
  };
  useEffect(() => {
    setLoadings([true, true, true]);
    getGatentRankingTable();
    getGrowthPotentialList();
    getSqScoreList();
  }, [level, areaCode]);
  return (
    <ul className={style.box}>
      <li>
        <Spin spinning={loadings[0]}>
          <div>
            <p className={style.title}>{patentTableData?.title}</p>
            <Table
              rowKey="index"
              columns={patentColumns}
              dataSource={patentTableData?.data?.dataSource}
              pagination={false}
              scroll={{ y: 600 }}
            />
          </div>
        </Spin>
      </li>
      <li>
        <Spin spinning={loadings[1]}>
          <div>
            <p className={style.title}>{potentialTableData?.title}</p>
            <Table
              rowKey="index"
              columns={potentialColumns}
              dataSource={potentialTableData?.data?.dataSource}
              pagination={false}
              scroll={{ y: 600 }}
            />
          </div>
        </Spin>
      </li>
      <li>
        <Spin spinning={loadings[2]}>
          <div>
            <p className={style.title}>{recommendData?.title}</p>
            <Table
              rowKey="index"
              columns={sqColumns}
              dataSource={recommendData?.data?.dataSource}
              pagination={false}
              scroll={{ y: 600 }}
            />
          </div>
        </Spin>
      </li>
    </ul>
  );
};

export default Ranking;
