import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

/**
 * 头部横幅
 * @param {string} title
 * @param {string} className
 * @returns {JSX.Element}
 * @constructor
 */
function Banner({ title = '', className }) {
  return (
    <div className={classnames(styles.banner, className)}>
      <div className={styles.title}>{title}</div>
    </div>
  );
}

export default Banner;
