/**
 *Author:zhangmeng
 *Description: 登录页面
 *Date：2020/09/07
 * */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Tabs, message, Alert, Checkbox } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import style from './index.module.less';
// import { synchronizationUserInfo } from '../../utils/sessionToLocal';
import Password from './PasswordLogin';
import CodeLogin from './CodeLogin';
import UseApply from './UseApply';
import BindWx from './BindWx';
import ForgetPassword from './ForgetPassword';
import EnterpriseLogin from './EnterpriseLogin';
import EnterpriseForget from './EnterpriseForget';
// import passwordLogin from '../../assets/images/login/passwordLogin.png';
// import QRcodeLogin from '../../assets/images/login/QRcodeLogin.png';
import logo from '../../assets/images/nav/logo.png';
import QrCodeDesc from './QrCodeDesc';
import {
  useApplySubmit,
  getToken,
  getResource,
  getUserInfo,
  // getWxCode,
  // getWxToken,
  // getWxBindStatus,
  // getWxbindUser,
  changeUserLoginStatus,
  getTicketCode,
  loginApi,
  bindUser,
  queryBindStatus,
  // getShowqrcode,
} from '../../api/login';
import {
  getModifyPassword,
} from '../../api/accountSetting';
import { IconFont } from '../../utils/mapIcon';
import { getBindWxPermission, handleWidthRolePermission } from '../../utils/tool';

const { TabPane } = Tabs;
// const tabs = ['短信登录', '密码登录', '扫码登录'];
const tabs = ['扫码登录', '短信登录'];

const logoHideTab = ['bindWxAccount', 'bindWx', 'apply', 'enterpriseForget'];

function Login(props) {
  const { loginSuccess, setExpiredAccount, setExpiredRenewal, setLoginModalShow, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false); // 申请测试loading
  const [uid, setUid] = useState('');
  const [type, setType] = useState('password');
  const [base64Code, setBase64Code] = useState('');
  const [userTicket, setUserTicket] = useState('');
  const [tabName, setTabName] = useState('扫码登录');
  const [alertShow, setAlertShow] = useState(false);
  const [bindWxAlertShow, setBindWxAlertShow] = useState(false);
  const [codeExpired, setCodeExpired] = useState(false);
  const [radioCheck, setRadioCheck] = useState(false);
  const [useAgreeCheck, setUserAgreeCheck] = useState(true);
  const [chanyeosToB, setChanyeosTob] = useState(false);
  const [accountType, setAccountType] = useState('个人账号登录');
  const bindWxData = useRef({});
  const loginData = useRef({});
  const userName = useRef('');
  const timer = useRef(null);
  const timer2 = useRef(null);
  const closePoll = useRef(false);
  const urlParams = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);

  useEffect(() => {
    closePoll.current = codeExpired;
  }, [codeExpired]);

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = Math.random() * 16 | 0;
      // eslint-disable-next-line no-bitwise,no-mixed-operators
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  useEffect(() => {
    if (type !== 'bindWxAccount' && type !== 'QRcode') {
      closePoll.current = true;
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    }
  }, [type]);

  // 去产业通B端
  const isGotoChanyeOsB = ({ res, freeCheck }) => {
    const token = window.btoa(res.access_token);
    const rft = window.btoa(res.refresh_token);
    const expiresIn = window.btoa(res.expires_in);
    localStorage.clear();
    // window.open(`http://localhost:3000/#/external?t=${token}&rft=${rft}&freeCheck=${freeCheck ? 1 : 0}&expiresIn=${expiresIn}`);
    window.location.replace(`https://pro.chanyeos.com/smart-ke-b/#/external?t=${token}&rft=${rft}&freeCheck=${freeCheck ? 1 : 0}&expiresIn=${expiresIn}`);
  };

  // 申请试用
  const applyOnFinish = (params) => {
    setApplyLoading(true);
    useApplySubmit({
      ...params,
      activity_id: urlParams.get('active') || '',
      activity_channel_id: urlParams.get('channel') || '',
      invitation_code: urlParams.get('invitation_code') || '',
    }).then(res => {
      if (res.status === '00000') {
        setApplyLoading(false);
        setAlertShow(true);
        setTimeout(() => {
          setAlertShow(false);
        }, 4000);
        clearTimeout();
        setType('password');
      } else if (res.status === 'B0000') {
        setApplyLoading(false);
        message.error(res.message);
      } else {
        setApplyLoading(false);
        message.error(res.message);
      }
    });
  };

  // 扫微信后快读绑定账号
  const bindWxOnFinish = (params) => {
    setIsLoading(true);
    getToken({
      uuid: uid,
      grant_type: 'sms',
      ...params,
    }).then(res => {
      if (res.access_token) {
        const maxTime = new Date().getTime() + (res.expires_in * 1000 - 600 * 1000);
        localStorage.setItem('token', `${res.token_type} ${res.access_token}`);
        localStorage.setItem('maxTime', maxTime);
        localStorage.setItem('rft', res.refresh_token);
        // synchronizationUserInfo({ token: `${res.token_type} ${res.access_token}`, maxTime, rft: res.refresh_token });
        getUserInfo().then(info => {
          if (info.status === '00000') {
            if (Object.keys(info.data).length === 0) return;
            getResource().then(res1 => {
              const { phone_no: phone, real_name: realName,
                account_status: accountStatus, user_id: userId } = info.data;
              if (res1.status === '00000') {
                if (accountStatus === 7 || accountStatus === 8) {
                  changeUserLoginStatus();
                }
                if (res1.data && res1.data.length > 0) {
                  const permission = handleWidthRolePermission(res1.data);
                  bindUser({
                    ticket: userTicket,
                  }).then(bindInfo => {
                    if (bindInfo.status === '00000') {
                      setIsLoading(false);
                      localStorage.setItem('pm', JSON.stringify(permission));
                      localStorage.setItem('userName', realName);
                      localStorage.setItem('ph', phone);
                      localStorage.setItem('isFirstLogin', accountStatus === 7);
                      // synchronizationUserInfo({ pm: permission, userName: realName, ph: phone, isFirstLogin: accountStatus === 7 });
                      if (urlParams.get('active') === '3634125ad65a11ecbc673af9d3b1b5f0' || accountStatus === 8) {
                        localStorage.setItem('act', 'zjtx');
                        // synchronizationUserInfo({ act: 'zjtx' });
                      }
                      setBindWxAlertShow(true);
                      setTimeout(() => {
                        setBindWxAlertShow(false);
                        loginSuccess();
                      }, 2000);
                      clearTimeout();
                    } else {
                      setUid(uuidv4());
                      setIsLoading(false);
                      message.error(bindInfo.message);
                    }
                  });
                }
              } else if (res1.status === 'A0207') {
                setExpiredAccount(phone);
                setExpiredRenewal(true);
                setLoginModalShow(false);
                setIsLoading(false);
                setUid(uuidv4());
              } else {
                localStorage.clear();
                localStorage.removeItem('rft');
                localStorage.removeItem('rftChanyeMax');
                setUid(uuidv4());
                setIsLoading(false);
                message.error(res1.message);
              }
            });
          } else {
            localStorage.clear();
            localStorage.removeItem('rft');
            localStorage.removeItem('rftChanyeMax');
            setUid(uuidv4());
            setIsLoading(false);
            message.error(info.message);
          }
        });
      } else {
        setUid(uuidv4());
        setIsLoading(false);
        message.error(res.message);
      }
    });
  };

  // 登录
  // const loginFun = (ticket, freeLogin) => {
  //   let loginOk = false;
  //   loginApi({ ticket }).then(res => {
  //     const { data } = res;
  //     if (data && res.status === 'A0206') {
  //       loginOk = true;
  //       const { status, access_token: accessToken } = data;
  //       if (status === 'A0319') {
  //         message.error(data.message);
  //         setUid(uuidv4());
  //         setType('bindWx');
  //         bindWxData.current = data;
  //       } else if (res.status === '00000' && accessToken) {
  //         const maxTime = new Date().getTime() + (res.data.expires_in * 1000 - 600 * 1000);
  //         localStorage.setItem('token', `${res.data.token_type} ${res.data.access_token}`);
  //         localStorage.setItem('maxTime', maxTime);
  //         localStorage.setItem('rft', res.data.refresh_token);
  //         if (freeLogin) {
  //           const localMaxTime = new Date().getTime() + (14 * 24 * 60 * 60 * 1000);
  //           localStorage.setItem('rft', res.data.refresh_token);
  //           localStorage.setItem('rftChanyeMax', localMaxTime);
  //         }
  //         getUserInfo().then(info => {
  //           if (info.status === '00000') {
  //             if (Object.keys(info.data).length === 0) return;
  //             getResource().then(res1 => {
  //               const { phone_no: phone, real_name: realName,
  //                 account_status: accountStatus } = info.data;
  //               if (res1.status === '00000') {
  //                 if (accountStatus === 8) {
  //                   changeUserLoginStatus();
  //                 }
  //                 if (res1.data && res1.data.length > 0) {
  //                   const permission = handleWidthRolePermission(res1.data);
  //                   setIsLoading(false);
  //                   localStorage.setItem('pm', JSON.stringify(permission));
  //                   localStorage.setItem('userName', realName);
  //                   localStorage.setItem('ph', phone);
  //                   localStorage.setItem('isFirstLogin', accountStatus === 7);
  //                   if (urlParams.get('active') === '3634125ad65a11ecbc673af9d3b1b5f0' || accountStatus === 8) {
  //                     localStorage.setItem('act', 'zjtx');
  //                   }
  //                   loginSuccess();
  //                 }
  //               } else if (res1.status === 'A0207') {
  //                 setExpiredAccount(phone);
  //                 setExpiredRenewal(true);
  //                 setLoginModalShow(false);
  //                 setIsLoading(false);
  //               } else {
  //                 localStorage.clear();
  //                 localStorage.removeItem('rft');
  //                 localStorage.removeItem('rftChanyeMax');
  //                 setIsLoading(false);
  //                 message.error(res1.message);
  //               }
  //             });
  //           } else {
  //             localStorage.clear();
  //             localStorage.removeItem('rft');
  //             localStorage.removeItem('rftChanyeMax');
  //             setIsLoading(false);
  //             message.error(info.message);
  //           }
  //         });
  //       }
  //     } else {
  //       loginOk = false;
  //     }
  //   }).catch(err => {
  //     clearTimeout(timer);
  //     loginOk = true;
  //     console.log(err);
  //   });

  //   return loginOk;
  // };
  const findWXStatus = (refreshFucn, freeLogin, ticket) => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (userName.current) {
      bindUser({ ticket }).then(res => {
        if (res.status === '00000') {
          if (res.data && res.status !== 'B0101') {
            const {
              phone_no: phone,
              real_name: realName,
              account_status: accountStatus,
            } = loginData.current;
            localStorage.setItem('userName', realName);
            localStorage.setItem('ph', phone);
            localStorage.setItem('isFirstLogin', accountStatus === 7);
            if (urlParams.get('active') === '3634125ad65a11ecbc673af9d3b1b5f0' || accountStatus === 8) {
              localStorage.setItem('act', 'zjtx');
            }
            loginSuccess();
          } else if (!closePoll.current) {
            timer.current = setTimeout(() => {
              findWXStatus(refreshFucn, freeLogin, ticket);
            }, 1000);
          } else {
            message.error(res.message);
          }
        } else if (res.status === 'A0326') {
          message.error(res.message);
          refreshFucn();
        } else if (!closePoll.current) {
          timer.current = setTimeout(() => {
            findWXStatus(refreshFucn, freeLogin, ticket);
          }, 1000);
        }
      }).catch(() => {
        if (!closePoll.current) {
          timer.current = setTimeout(() => {
            findWXStatus(refreshFucn, freeLogin, ticket);
          }, 1000);
        }
      });
    } else if (userName.current === '') {
      loginApi({ ticket }).then(res => {
        const { data } = res;
        if (data && res.status !== 'B0101') {
          clearTimeout(timer);
          const { status, access_token: accessToken } = data;
          if (status === 'A0319') {
            message.error(data.message);
            setUid(uuidv4());
            setType('apply');
            bindWxData.current = data;
          } else if (res.status === '00000' && accessToken) {
            if (data.auth) {
              isGotoChanyeOsB({ res: data, freeCheck: radioCheck });
              return;
            }
            const maxTime = new Date().getTime() + (res.data.expires_in * 1000 - 600 * 1000);
            localStorage.setItem('token', `${res.data.token_type} ${res.data.access_token}`);
            localStorage.setItem('maxTime', maxTime);
            localStorage.setItem('rft', res.data.refresh_token);
            // synchronizationUserInfo({ token: `${res.data.token_type} ${res.data.access_token}`, maxTime, rtf: res.data.refresh_token });
            if (radioCheck) {
              const localMaxTime = new Date().getTime() + (14 * 24 * 60 * 60 * 1000);
              localStorage.setItem('rft', res.data.refresh_token);
              localStorage.setItem('rftChanyeMax', localMaxTime);
            }
            getUserInfo().then(info => {
              if (info.status === '00000') {
                if (Object.keys(info.data).length === 0) return;
                getResource().then(res1 => {
                  const { phone_no: phone, real_name: realName,
                    account_status: accountStatus } = info.data;
                  if (res1.status === '00000') {
                    if (accountStatus === 8) {
                      changeUserLoginStatus();
                    }
                    if (res1.data && res1.data.length > 0) {
                      const permission = handleWidthRolePermission(res1.data);
                      setIsLoading(false);
                      localStorage.setItem('pm', JSON.stringify(permission));
                      localStorage.setItem('userName', realName);
                      localStorage.setItem('ph', phone);
                      localStorage.setItem('isFirstLogin', accountStatus === 7);
                      // synchronizationUserInfo({ pm: permission, userName: realName, ph: phone, isFirstLogin: accountStatus === 7 });
                      if (urlParams.get('active') === '3634125ad65a11ecbc673af9d3b1b5f0' || accountStatus === 8) {
                        localStorage.setItem('act', 'zjtx');
                      }
                      loginSuccess();
                    }
                  } else if (res1.status === 'A0207') {
                    setExpiredAccount(phone);
                    setExpiredRenewal(true);
                    setLoginModalShow(false);
                    setIsLoading(false);
                  } else {
                    localStorage.clear();
                    localStorage.removeItem('rft');
                    localStorage.removeItem('rftChanyeMax');
                    setIsLoading(false);
                    message.error(res1.message);
                  }
                });
              } else {
                localStorage.clear();
                localStorage.removeItem('rft');
                localStorage.removeItem('rftChanyeMax');
                setIsLoading(false);
                message.error(info.message);
              }
            });
          }
        } else if (res.status === 'B0101') {
          timer.current = setTimeout(() => {
            findWXStatus(refreshFucn, freeLogin, ticket);
          }, 1000);
        }
      }).catch(err => {
        clearTimeout(timer);
        // loginOk = true;
        throw new Error(err);
      });
      // timer.current = setTimeout(async () => {
      //   const loginOk = await loginFun(ticket, freeLogin);
      //   if (!loginOk) {
      //     findWXStatus(state, refreshFucn, freeLogin, ticket);
      //   }
      // }, 1000);
      // getWxToken({ captcha: state }).then(res => {
      //   if (res.status === '00000' && res.data) {
      //     const maxTime = new Date().getTime() + (res.data.expires_in * 1000 - 600 * 1000);
      //     localStorage.setItem('token', `${res.data.token_type} ${res.data.access_token}`);
      //     localStorage.setItem('maxTime', maxTime);
      //     localStorage.setItem('rft', res.data.refresh_token);
      //     if (freeLogin) {
      //       const localMaxTime = new Date().getTime() + (14 * 24 * 60 * 60 * 1000);
      //       localStorage.setItem('rft', res.data.refresh_token);
      //       localStorage.setItem('rftChanyeMax', localMaxTime);
      //     }
      //     getUserInfo().then(info => {
      //       if (info.status === '00000') {
      //         if (Object.keys(info.data).length === 0) return;
      //         getResource().then(res1 => {
      //           const { phone_no: phone, real_name: realName,
      //             account_status: accountStatus } = info.data;
      //           if (res1.status === '00000') {
      //             if (accountStatus === 8) {
      //               changeUserLoginStatus();
      //             }
      //             if (res1.data && res1.data.length > 0) {
      //               const permission = handleWidthRolePermission(res1.data);
      //               setIsLoading(false);
      //               localStorage.setItem('pm', JSON.stringify(permission));
      //               localStorage.setItem('userName', realName);
      //               localStorage.setItem('ph', phone);
      //               localStorage.setItem('isFirstLogin', accountStatus === 7);
      //               if (urlParams.get('active') === '3634125ad65a11ecbc673af9d3b1b5f0' || accountStatus === 8) {
      //                 localStorage.setItem('act', 'zjtx');
      //               }
      //               loginSuccess();
      //             }
      //           } else if (res1.status === 'A0207') {
      //             setExpiredAccount(phone);
      //             setExpiredRenewal(true);
      //             setLoginModalShow(false);
      //             setIsLoading(false);
      //           } else {
      //             localStorage.clear();
      //             localStorage.removeItem('rft');
      //             localStorage.removeItem('rftChanyeMax');
      //             setIsLoading(false);
      //             message.error(res1.message);
      //           }
      //         });
      //       } else {
      //         localStorage.clear();
      //         localStorage.removeItem('rft');
      //         localStorage.removeItem('rftChanyeMax');
      //         setIsLoading(false);
      //         message.error(info.message);
      //       }
      //     });
      //   } else if (res.status === 'A0319') {
      //     message.error(res.message);
      //     setUid(uuidv4());
      //     setType('bindWx');
      //     bindWxData.current = res.data;
      //   } else if (res.status === 'A0328') {
      //     message.error(res.message);
      //     setTimeout(() => {
      //       if (accountType === '个人账号登录') {
      //         setAccountType('企业账号登录');
      //         setType('enterprisePassword');
      //       }
      //     }, 1000);
      //   } else if (!closePoll.current) {
      //     timer.current = setTimeout(() => {
      //       findWXStatus(state, refreshFucn, freeLogin);
      //     }, 1000);
      //   }
      // }).catch(() => {
      //   if (!closePoll.current) {
      //     timer.current = setTimeout(() => {
      //       findWXStatus(state, refreshFucn, freeLogin);
      //     }, 1000);
      //   }
      // });
    }
  };

  const getStateData = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    // let params = {
    //   code_type: 'login',
    //   service_type: 'wx',
    // };
    // if (userName.current) {
    //   params = {
    //     code_type: 'bind',
    //     phone_no: userName.current,
    //     service_type: 'wx',
    //   };
    // }
    let params = {
      event_key: 'web扫码登陆',
    };
    if (userName.current) {
      params = {
        event_key: '绑定服务号',
      };
    }
    // 获取登录二维码
    getTicketCode(params).then(res => {
      if (res.status === '00000') {
        const { ticket, picbase64 } = res.data;
        // getShowqrcode({ ticket });
        setBase64Code(picbase64);
        setUserTicket(ticket);
        // eslint-disable-next-line no-undef,no-unused-vars
        // const obj = new WxLogin({
        //   self_redirect: true,
        //   id: 'login_container',
        //   appid,
        //   scope: 'snsapi_login',
        //   redirect_uri: uri,
        //   state,
        //   style: 'black',
        //   href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6MTc5cHg7Ym9yZGVyOiAxcHggc29saWQgYmxhY2t9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHg7Zm9udC1zaXplOiAxNHB4O2NvbG9yOiAjOTE5MTkxO2xldHRlci1zcGFjaW5nOiAxcHg7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9IA==',
        // });
        setCodeExpired(false);
        closePoll.current = false;
        timer.current = setTimeout(() => {
          // findWXStatus(userName.current ? state : state.split('=')[1], getStateData, radioCheck);
          findWXStatus(getStateData, radioCheck, ticket);
        }, 1000);
        timer2.current = setTimeout(() => {
          setCodeExpired(true);
          // 二维码失效清除计时器
          clearTimeout(timer.current);
        }, 120 * 1000);
      }
    });
  };
  // 登录
  const onFinish = (params, curType, freeCheck) => {
    setIsLoading(true);
    const obj = {
      uuid: uid,
      grant_type: curType,
      ...params,
    };
    if (chanyeosToB) {
      obj.type = 'B';
    }
    const loginFunction = getToken(obj);
    loginFunction.then(async (res) => {
      if (chanyeosToB) {
        isGotoChanyeOsB({ freeCheck, res });
        return;
      }
      const maxTime = new Date().getTime() + (res.expires_in * 1000 - 600 * 1000);
      // synchronizationUserInfo({ token: `${res.token_type} ${res.access_token}`, maxTime, rft: res.refresh_token });
      if (res.access_token) {
        // 如果启用14天免登录
        if (freeCheck) {
          const localMaxTime = new Date().getTime() + (14 * 24 * 60 * 60 * 1000);
          localStorage.setItem('rft', res.refresh_token);
          localStorage.setItem('rftChanyeMax', localMaxTime);
        }
        // const { data: status } = await queryBindStatus();
        const resdata = await queryBindStatus();
        const { data: status } = resdata;
        getUserInfo().then(info => {
          if (info.status === '00000') {
            if (Object.keys(info.data).length === 0) return;
            getResource().then(res1 => {
              const {
                phone_no: phone,
                account_status: accountStatus,
                real_name: realName } = info.data;
              if (res1.status === '00000') {
                if (accountStatus === 7 || accountStatus === 8) {
                  changeUserLoginStatus();
                }
                const bindWxPermission = getBindWxPermission(res1.data);
                if (res1.data && res1.data.length > 0) {
                  const permission = handleWidthRolePermission(res1.data);
                  setIsLoading(false);
                  localStorage.setItem('pm', JSON.stringify(permission));
                  // synchronizationUserInfo({ pm: permission });
                  // bindWxPermission 后台权限配置改用户有没有
                  /* （status || !bindWxPermission || accountType === '企业账号登录' */
                  if (status) {
                    localStorage.setItem('userName', realName);
                    localStorage.setItem('ph', phone);
                    localStorage.setItem('isFirstLogin', accountStatus === 7);
                    // synchronizationUserInfo({ userName: realName, ph: phone, isFirstLogin: accountStatus === 7 });
                    if (urlParams.get('active') === '3634125ad65a11ecbc673af9d3b1b5f0' || accountStatus === 8) {
                      localStorage.setItem('act', 'zjtx');
                    }
                    loginSuccess();
                  } else {
                    userName.current = phone;
                    loginData.current = info.data;
                    setType('bindWxAccount');
                    getStateData();
                  }
                }
              } else if (res1.status === 'A0207') {
                setExpiredAccount(phone);
                setExpiredRenewal(true);
                setLoginModalShow(false);
                setIsLoading(false);
                setUid(uuidv4());
              } else {
                localStorage.clear();
                localStorage.removeItem('rft');
                localStorage.removeItem('rftChanyeMax');
                setUid(uuidv4());
                setIsLoading(false);
                message.error(res1.message);
              }
            });
          } else {
            localStorage.clear();
            localStorage.removeItem('rft');
            localStorage.removeItem('rftChanyeMax');
            setUid(uuidv4());
            setIsLoading(false);
            message.error(info.message);
          }
        });
      } else if (res.status === 'A0201') {
        setUid(uuidv4());
        setIsLoading(false);
        message.error(res.message);
        setTimeout(() => {
          if (accountType === '个人账号登录') {
            setType('apply');
          }
        }, 1000);
      } else if (res.status === 'A0329') {
        setIsLoading(false);
        message.error(res.message);
        setTimeout(() => {
          if (accountType === '企业账号登录') {
            setAccountType('个人账号登录');
            setType('password');
          }
        }, 1000);
      } else {
        setUid(uuidv4());
        setIsLoading(false);
        message.error(res.message);
      }
    });
  };

  // 修改密码-确认修改
  const changeConfirm = (params) => {
    const { captcha, newPassword, phone } = params;
    getModifyPassword({
      new_password: newPassword,
      confirm_password: newPassword,
      phone_no: phone,
      captcha,
    }).then(res => {
      if (res.status === '00000') {
        message.success('修改密码成功！');
        setAccountType('企业账号登录');
        setType('enterprisePassword');
      } else {
        message.error(res.message);
      }
    });
  };

  const compSwitch = (params) => {
    let comp = null;
    switch (params) {
      case '密码登录':
        comp = <Password
          onFinish={(value, freeCheck) => onFinish(value, 'password', freeCheck)}
          uid={uid}
          isLoading={isLoading}
          captchaImgClick={() => setUid(uuidv4())}
          useApply={() => setType('apply')}
          forgetPassword={() => setType('forget')}
        />;
        break;
      case '短信登录':
        comp = <CodeLogin
          onFinish={(value, freeCheck) => onFinish(value, 'sms', freeCheck)}
          isLoading={isLoading}
          useApply={() => setType('apply')}
          forgetPassword={() => setType('forget')}
          timer={timer.current}
          setChanyeosTob={setChanyeosTob}
        />;
        break;
      case '扫码登录':
        comp = '';
        break;
      default:
        break;
    }
    return comp;
  };

  const renderComp = () => {
    let comp = null;
    switch (type) {
      case 'apply':
        comp = <UseApply
          onFinish={(p) => applyOnFinish(p)}
          cancelClick={() => setType('password')}
          loading={applyLoading}
        />;
        break;
      case 'forget':
        comp = <ForgetPassword />;
        break;
      case 'password':
        comp = compSwitch(tabName);
        break;
      case 'QRcode':
        comp = (
          <div style={{ marginTop: '6px', width: '100%', height: '100%' }}>
            <div className={style.base64Code}>
              <div
                title="微信登录"
                id="login_container"
                sandbox="allow-scripts allow-top-navigation allow-same-origin"
              // style={{ height: '266px', textAlign: 'center' }}
              >
                {
                  base64Code && (<img src={`data:image/jpeg;base64,${base64Code}`} style={{ width: '190px', height: '190px' }} alt="" />)
                }
                <div className={style.expiredCover} style={{ display: codeExpired ? '' : 'none' }}>
                  {useAgreeCheck ? '二维码已失效' : '请仔细阅读《用户协议》'}
                </div>
              </div>
              <div
                className={style.refresh}
                style={{ visibility: codeExpired && useAgreeCheck ? 'visible' : 'hidden' }}
                onClick={() => getStateData()}
              >
                <IconFont type="iconsync" style={{ marginRight: '4px' }} />
                刷新二维码
              </div>
            </div>
            <div className={`${style.loginFree} ${codeExpired && style.positionDiv}`}>
              <Checkbox checked={radioCheck} onChange={(e) => setRadioCheck(e.target.checked)} />
              &nbsp;
              <div className={style.checkBoxWord}>
                14天内免登录
              </div>
            </div>
            <div className={`${style.qrcodeDesc} ${codeExpired && style.positionDiv}`}>
              <QrCodeDesc
                useAgreeCheck={useAgreeCheck}
                setUserAgreeCheck={(value) => {
                  if (value) {
                    setUserAgreeCheck(true);
                    getStateData();
                  } else {
                    setUserAgreeCheck(false);
                    setCodeExpired(true);
                  }
                }}
              />
            </div>
          </div>
        );
        break;
      case 'bindWxAccount':
        comp = (
          <>
            <div className={style.wxTitle}>绑定微信，尽享快捷登录，一键分享报告给熟悉的人</div>
            <div
              title="微信登录"
              id="login_container"
              sandbox="allow-scripts allow-top-navigation allow-same-origin"
              style={{ height: '270px', textAlign: 'center' }}
            >
              {
                base64Code && (<img src={`data:image/jpeg;base64,${base64Code}`} style={{ width: '190px', height: '190px' }} alt="" />)
              }
            </div>
            <div
              className={style.refresh}
              style={{ display: codeExpired ? '' : 'none' }}
              onClick={() => getStateData()}
            >
              <IconFont type="iconsync" style={{ marginRight: '4px' }} />
              刷新二维码
            </div>
            <div className={style.expiredCover} style={{ display: codeExpired ? '' : 'none' }}>二维码已失效</div>
          </>
        );
        break;
      case 'bindWx':
        comp = (
          <BindWx
            onFinish={(p) => bindWxOnFinish(p)}
            isLoading={isLoading}
          />
        );
        break;
      case 'enterpriseForget':
        comp = (
          <EnterpriseForget
            cancelClick={() => setType('enterprisePassword')}
            onFinish={(p) => changeConfirm(p)}
          />
        );
        break;
      case 'enterprisePassword':
        comp = (
          <EnterpriseLogin
            onFinish={(value, freeCheck) => onFinish(value, 'enterprise', freeCheck)}
            isLoading={isLoading}
            forgetPassword={() => setType('enterpriseForget')}
          />
        );
        break;
      default:
        break;
    }
    return comp;
  };

  const QRcodeClicked = () => {
    userName.current = '';
    setType('QRcode');
    getStateData();
    setUserAgreeCheck(true);
  };

  const bindWxAccountClose = () => {
    const {
      phone_no: phone,
      real_name: realName,
      account_status: accountStatus,
    } = loginData.current;
    localStorage.setItem('userName', realName);
    localStorage.setItem('ph', phone);
    localStorage.setItem('isFirstLogin', accountStatus === 7);
    // synchronizationUserInfo({ userName: realName, ph: phone, isFirstLogin: accountStatus === 7 });
    loginSuccess();
  };

  useEffect(() => {
    const time = setTimeout(() => {
      setUid(uuidv4());
      QRcodeClicked();
    }, 100);
    return () => {
      clearTimeout(time);
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
      closePoll.current = true;
    };
  }, [radioCheck]);

  const titleComp = () => {
    let comp = null;
    switch (type) {
      case 'apply':
        comp = (
          <div className={style.title}>
            注册账号
          </div>
        );
        break;
      case 'forget':
        comp = (
          <div className={style.title}>
            忘记密码
          </div>
        );
        break;
      case 'password':
      case 'QRcode':
        comp = (
          <>
            <div className={style.loginTab}>
              <Tabs
                activeKey={tabName}
                onChange={p => {
                  if (p === '扫码登录') {
                    QRcodeClicked();
                  } else {
                    setType('password');
                  }
                  setTabName(p);
                }}
              >
                {
                  tabs.map(item => (
                    <TabPane tab={item} key={item} />
                  ))
                }
              </Tabs>
            </div>
          </>
        );
        break;
      case 'bindWx':
        comp = (
          <>
            <div className={style.title}>
              绑定账户
            </div>
            <div className={style.close} onClick={() => QRcodeClicked()}>
              <CloseOutlined />
            </div>
          </>
        );
        break;
      case 'bindWxAccount':
        comp = (
          <>
            <div className={style.title}>
              绑定微信账户
            </div>
            <div className={style.close} onClick={() => bindWxAccountClose()}>
              <CloseOutlined />
            </div>
          </>
        );
        break;
      case 'enterpriseForget':
        comp = (
          <div className={style.title}>
            忘记密码
          </div>
        );
        break;
      case 'enterprisePassword':
        comp = '';
        break;
      default:
        break;
    }
    return comp;
  };

  return (
    <div className={style.login}>
      <div className={style.loginContent}>
        {
          alertShow ? (
            <Alert
              message="您的试用申请提交成功！"
              description="恭喜您，产业通试用账号已开通，请及时查收短信！"
              type="success"
              showIcon
              closable
            />
          ) : null
        }
        {
          bindWxAlertShow ? (
            <Alert
              message="绑定微信成功"
              description="后续您可以通过扫描微信二维码直接登录了"
              type="success"
              showIcon
              closable
            />
          ) : null
        }
        <div className={style.content}>
          <div className={style.logoContainer}>
            <div className={`${type !== 'password' ? style.wxLoginBox : style.loginBox}`}>
              {
                (type === 'password' || type === 'QRcode' || type === 'enterprisePassword') && <CloseOutlined className={style.closeIcon} onClick={() => onClose()} />
              }
              {
                !logoHideTab.includes(type) && (
                  <>
                    <div className={style.logo}>
                      <img src={logo} alt="" />
                    </div>
                    {/* <div className={style.buttons}>
                      <Radio.Group
                        value={accountType}
                        onChange={e => {
                          if (e.target.value === '个人账号登录') {
                            setType('password');
                            setTabName('短信登录');
                          } else if (e.target.value === '企业账号登录') {
                            setType('enterprisePassword');
                          }
                          setAccountType(e.target.value);
                        }}
                      >
                        <Radio.Button className={style.buttonTab} value="个人账号登录">个人账号登录</Radio.Button>
                        <Radio.Button className={style.buttonTab} value="企业账号登录">企业账号登录</Radio.Button>
                      </Radio.Group>
                    </div> */}
                  </>
                )
              }
              {titleComp(type)}
              <div className={style.body}>
                {renderComp(type)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Login);
