import React from 'react';
import style from './index.module.less';
import Barandline from '../../../../../components/Charts/BarAndLine';
import FunnelChart from '../../../../../components/Charts/FunnelChart';
import Treemap from '../../../../../components/Charts/TreeMap';
import HollowPie from '../../../../../components/Charts/HollowPie';
import DataScheme from './DataScheme';
import { useGetIndustryOverviewQuery } from '../../../../../service/cityDetail';

const IndustryOverview = ({ title, cityCode, total, isProvinceDirectly }) => {
  const { data, isFetching } = useGetIndustryOverviewQuery({
    area_key: cityCode,
  }, { skip: !cityCode });

  return (
    <div className={style.box} id={title}>
      <p className={style.title}>{title}</p>
      <ul>
        <li>
          <p className={style.chartTitle} >
            {data?.[0].title || ''}
            {data?.[0].subtitle && `(${data?.[0].subtitle})`}
          </p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[0],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
              showLabel
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >
            {data?.[1].title || ''}
            {data?.[1].subtitle && `(${data?.[1].subtitle})`}
          </p>
          <div className={style.chart} style={{ marginLeft: '28px' }}>
            <FunnelChart
              data={data ? data[1].series?.[0]?.data : null}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >
            {data?.[2].title || ''}
            {data?.[2].subtitle && `(${data?.[2].subtitle})`}
          </p>
          <div className={style.chart}>
            <Treemap
              data={data?.[2].series?.[0].data}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >
            {data?.[3].title || ''}
            {data?.[3].subtitle && `(${data?.[3].subtitle})`}
          </p>
          <div className={style.chart}>
            <HollowPie
              data={data ? data[3]?.series[0] : null}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >
            {data?.[4].title || ''}
            {data?.[4].subtitle && `(${data?.[4].subtitle})`}
          </p>
          <div className={style.chart}>
            <HollowPie
              data={data?.[4].series[0]}
              roseType="radius"
              radius={['20%', '70%']}
              isFetching={isFetching}
            />
          </div>
        </li>
        {
          cityCode?.slice(4, 6) === '00' && !isProvinceDirectly && (
            <li>
              <p className={style.chartTitle} >
                {data?.[5].title || ''}
                {data?.[5].subtitle && `(${data?.[5].subtitle})`}
              </p>
              <div className={style.chart}>
                <Barandline
                  data={{
                    ...data?.[5],
                    leftYName: [0, 40, 0, 0],
                  }}
                  isFetching={isFetching}
                />
              </div>
            </li>
          )
        }
      </ul>
      <DataScheme cityCode={cityCode} total={total} />
    </div>
  );
};

export default IndustryOverview;
