import React, { forwardRef, useEffect, useRef, useImperativeHandle, useState } from 'react';
import * as echarts from 'echarts/lib/echarts';
import { Spin } from 'antd';
import html2canvas from 'html2canvas';
import { mockData } from './mockData';
import chartBg from '../../../../../../components/IndustryCompanyDetail/CompanyContent/RelationshipPenetration/IndustrySector/IndustrySectorGraph/components/Tree/background.png';
import styles from './index.module.less';

function generateRandomInPointInCircle() {
  const angle = Math.random() * Math.PI; // 0 to π
  const radius = Math.random() * 600; // 1 to radius
  let times = 0;

  const x = radius * Math.cos(angle);
  const y = radius * Math.sin(angle);
  if (y <= 0.5 && y >= -0.5) {
    times += 1;
  }
  if (x * x + y * y > radius * radius || (y <= 1 && y >= -1 && times > 500)) return generateRandomInPointInCircle();

  return { x, y };
}

function generateRandomOutPointInCircle() {
  const angle = (Math.random() + 1) * Math.PI;// π to 2π
  const radius = Math.random() * 600; // 1 to radius
  let times = 0;

  const x = radius * Math.cos(angle);
  const y = radius * Math.sin(angle);
  if (y <= 0.5 && y >= -0.5) {
    times += 1;
  }
  if (x * x + y * y > radius * radius || (y <= 0.5 && y >= -0.5 && times > 500)) return generateRandomOutPointInCircle();

  return { x, y };
}

// 节点分级
const generateNodeLevel = (currentList, setMostList, setMore1List, setMore2List, setMore3List) => {
  let list = [];
  if (currentList.length > 5) {
    list = currentList.slice(0, 5);
    setMostList(list);
    if (currentList.length > 15) {
      list = currentList.slice(5, 15);
      setMore1List(list);
      if (currentList.length > 30) {
        list = currentList.slice(15, 30);
        setMore2List(list);
        if (currentList.length > 50) {
          list = currentList.slice(30, 50);
          setMore3List(list);
        } else {
          list = currentList.slice(30, currentList.length);
          setMore3List(list);
        }
      } else {
        list = currentList.slice(15, currentList.length);
        setMore2List(list);
      }
    } else {
      list = currentList.slice(5, currentList.length);
      setMore1List(list);
    }
  } else {
    setMostList(currentList);
  }
};

function Graph(props, ref) {
  const { selectIndustryName, selectAreaName, data, isFetching } = props;
  const chartRef = useRef(null);
  const [otherMostList, setOtherMostList] = useState([]);
  const [otherMore1List, setOtherMore1List] = useState([]);
  const [otherMore2List, setOtherMore2List] = useState([]);
  const [otherMore3List, setOtherMore3List] = useState([]);
  const [loactionMostList, setLoactionMostList] = useState([]);
  const [loactionMore1List, setLoactionMore1List] = useState([]);
  const [loactionMore2List, setLoactionMore2List] = useState([]);
  const [loactionMore3List, setLoactionMore3List] = useState([]);
  const [myChart, setMyChart] = useState();

  const nodeStyle = (node) => {
    let size = 0;
    let colorOpacity = 1;
    if (otherMostList.findIndex(i => i.id === node.id) > -1 || loactionMostList.findIndex(i => i.id === node.id) > -1) {
      size = 50;
    } else if (otherMore1List.findIndex(i => i.id === node.id) > -1 || loactionMore1List.findIndex(i => i.id === node.id) > -1) {
      size = 20;
      colorOpacity = 0.5;
    } else if (otherMore2List.findIndex(i => i.id === node.id) > -1 || loactionMore2List.findIndex(i => i.id === node.id) > -1) {
      size = 10;
      colorOpacity = 0.4;
    } else if (otherMore3List.findIndex(i => i.id === node.id) > -1 || loactionMore3List.findIndex(i => i.id === node.id) > -1) {
      size = 5;
      colorOpacity = 0.3;
    } else {
      size = 1;
      colorOpacity = 0.2;
    }
    return { size, colorOpacity };
  };

  useEffect(() => {
    if (data?.data?.length > 0) {
      const otherList = [];
      const locationList = [];
      data.data.forEach(item => {
        if (item.location === 'other') {
          otherList.push(item);
        } else {
          locationList.push(item);
        }
      });
      otherList.sort((a, b) => b.amount - a.amount);
      locationList.sort((a, b) => b.amount - a.amount);
      generateNodeLevel(otherList, setOtherMostList, setOtherMore1List, setOtherMore2List, setOtherMore3List);
      generateNodeLevel(locationList, setLoactionMostList, setLoactionMore1List, setLoactionMore2List, setLoactionMore3List);
    }
  }, [data]);

  useEffect(() => {
    const chartDom = document.getElementById('main');
    const chart = echarts.init(chartDom);
    setMyChart(chart);
  }, []);

  useEffect(() => {
    if (otherMostList.length > 0 && myChart) {
      const option = {
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        tooltip: {
          position: (point) => [point[0] + 20, point[1] - 15],
          formatter: params => {
            // console.log(params);
            if (params.dataType === 'node') {
              return `<span style='color: #333333;'>${params.name}</span>`;
            }
            if (params.dataType === 'edge') {
              const sourceName = data?.data?.filter(i => i.id === params.data.source)?.[0]?.name;
              const targetName = data?.data?.filter(i => i.id === params.data.target)?.[0]?.name;
              // console.log(sourceName, sourceName, params.data.source, data?.data?.filter(i => i.id === params.data.source));
              if (sourceName && targetName) {
                return `${sourceName} > ${targetName}`;
              }
              return '';
            }
            return '';
          },
          extraCssText: 'background: none; border: none; box-shadow: none',
        },
        series: [
          {
            type: 'graph',
            layout: 'none',
            // nodeScaleRatio: 0,  // 0 为不随鼠标缩放
            // data: mockData?.nodes?.map((node) => ({
            data: data?.data?.map((node) => ({
              x: node.location === 'other' ? generateRandomOutPointInCircle().x : generateRandomInPointInCircle().x,
              y: node.location === 'other' ? generateRandomOutPointInCircle().y : generateRandomInPointInCircle().y,
              id: node.id,
              name: node.name,
              // name: node.label,
              symbolSize: nodeStyle(node).size,
              itemStyle: {
                label: {
                  show: false,
                },
                // color: node.location === 'other' ? 'rgba(25,97,245)' : 'rgba(245,147,25)',
                color: node.location === 'other' ? `rgba(25,97,245,${nodeStyle(node).colorOpacity})` : `rgba(245,147,25,${nodeStyle(node).colorOpacity})`,
              },
            })),
            edges: data?.links?.map((edge) => ({
              source: edge.source,
              target: edge.target,
              lineStyle: {
                color: edge.relation_type === 'in' ? 'rgb(245,147,25)' : 'rgb(25,97,245)',
                curveness: 0.3,
                width: 0.5,
              },
              symbol: ['none', 'arrow'],
              symbolSize: 5,
            })),
            roam: true,
            // labelLayout: {
            //   hideOverlap: true,
            // },
            lineStyle: {
              opacity: 0.1,
            },
            emphasis: {
              focus: 'adjacency',
              label: {
                show: false,
              },
              lineStyle: {
                opacity: 0.5,
              },
            },
          },
        ],
      };
      myChart.setOption(option, true);
      myChart.on('mouseover', (params) => {
        myChart.setOption({ series: [{ lineStyle: { opacity: 0.5 } }] });
      });
      myChart.on('mouseout', (params) => {
        myChart.setOption({ series: [{ lineStyle: { opacity: 0.1 } }] });
      });
    }
  }, [otherMostList, myChart]);

  useImperativeHandle(ref, () => ({
    download: () => {
      // 获取 base64 图片
      // const chartDom = document.getElementById('main');
      // const myChart = echarts.getInstanceByDom(chartDom);
      const bgImage = new Image();
      bgImage.src = chartBg;
      bgImage.onload = () => {
        myChart.setOption({
          graphic: {
            elements: [{
              type: 'image',
              style: {
                image: bgImage,
                width: '100%',
                height: '100%',
              },
              left: 0,
              right: 0,
            }],
          },
        });
      };
      html2canvas(myChart.getDom()).then(canvas => {
        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = `${selectAreaName ?? '全国'}${selectIndustryName}投资流向分析`;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        document.body.removeChild(a);
      });
    },
  }));

  return (
    <Spin spinning={isFetching} wrapperClassName={styles.spinChart}>
      <div id='main' ref={chartRef} className={styles.chart} style={{ width: '100%', height: '100%' }}></div>
    </Spin>
  );
}

export default forwardRef(Graph);
