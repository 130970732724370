/*
* Author: zhangmeng
* Description: '相关报告组件'
* Date: 2021/07/13
*/

import React from 'react';
import TitleAndSubTitleComp from '../../../TitleAndSubTitleComp';
import detailBackg from '../../../../assets/images/researchReport/detailBackg.png';
// import { companyTabsColorMap } from '../../../../utils/color';
import { tagStyleMap } from '../../../../utils/tagColor';
import style from './index.module.less';

function SimilarReport(props) {
  const { data, detail } = props;

  return (
    <div className={style.all}>
      <TitleAndSubTitleComp title="相关报告" subTitle="" />
      <div className={style.list}>
        {
          data.length > 0 && data.map((item, index) => (
            <div className={style.item} key={index} onClick={() => detail(item.id)}>
              <div className={style.left}>
                <img src={detailBackg} alt="" />
              </div>
              <div className={style.right}>
                <div className={style.title}>
                  <span title={item.title}>{item.title}</span>
                </div>
                <div className={style.tagContainer}>
                  {
                    item.tags && item.tags.map((item0, index0) => (
                      item0.tabs.map((item1, index1) => (
                        <span
                          key={`${index0}${index1}${item1}`}
                          className={`${style.tag} ${tagStyleMap[item0.type]}`}
                        >
                          {item1}
                        </span>
                      ))
                    ))
                  }
                </div>
                <div className={style.orgDate}>
                  <span>{item.publish_org}</span>
                  <span>{item.publish_date}</span>
                </div>
                <div className={style.footer}>
                  <div className={style.dashedLine} />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default SimilarReport;
