/**
* Author: tianye
* Description: 政策详情页,
* Date: 2020/11/27
* */

import React, { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { getPolicyInfo } from '../../api/policyDetail';
import { IconFont, tabMap } from '../../utils/mapIcon';
// import { companyTabsColorMap } from '../../utils/color';
import { tagStyleMap } from '../../utils/tagColor';
import style from './index.module.less';

export default function IndustryPolicyDetail(props) {
  const { detailParams } = props;
  const { href } = window.location;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { name, tabs, publish_date: publishDate,
    source, declare,
    condition, ways, material, end_date_info: endDateInfo, subsidy, link, abstract } = data;
  const params = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);

  useEffect(() => {
    setLoading(true);
    getPolicyInfo({
      policy_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.title}>
            <span className={style.before}>
              产业通
            &emsp;
              <span className={style.point} />
            &emsp;
            </span>
            <span className={style.later}>政策</span>
          </div>
          <div className={style.imgBox}>
            <IconFont type={tabMap.policy} />
          </div>
        </div>
      </div>
      <article className={style.body}>
        <section className={style.header}>
          <div className={style.title}>{name || ''}</div>
        </section>
        <div className={style.tagContainer}>
          {
              tabs && tabs.map((item, index) => (
                item.tabs.map((item1, index1) => (
                  <span
                    key={`${index}${index1}${item1}`}
                    className={`${style.tag} ${tagStyleMap[item.type]}`}
                  >
                    {item1}
                  </span>
                ))
              ))
          }
        </div>
        <div className={style.authorBar}>
          <span className={style.lightText}>
            {publishDate || ''}
          </span>
          <span className={style.lightText}>
            {`来源：${source || ''}`}
          </span>
        </div>
        <div className={style.contentBox}>
          {
            // eslint-disable-next-line no-nested-ternary
            declare ? (
              declare && declare === '非申报类政策' ? (
                <>
                  <div className={style.box} style={{ paddingBottom: abstract ? '' : '30px' }}>
                    <div className={style.title}>
                      【 摘要 】
                      &emsp;
                      { abstract ? '' : '暂无' }
                    </div>
                    <div className={style.content} style={{ display: abstract ? '' : 'none' }}>
                      &emsp;&emsp;
                      {abstract || ''}
                    </div>
                  </div>
                  <div className={style.box}>
                    <span className={style.title}>【 原文链接 】</span>
                    <a href={link} target="blank">点击查看</a>
                  </div>
                </>
              ) : (
                <>
                  <div className={`${condition ? style.box : style.boxNull}`}>
                    <div className={style.title}>【 申报条件 】</div>
                    <div className={style.content}>
                      &emsp;
                      {condition || '暂无'}
                    </div>
                  </div>
                  <div className={`${ways ? style.box : style.boxNull}`}>
                    <div className={style.title}>【 申报方式 】</div>
                    <div className={style.content}>
                      &emsp;
                      {ways || '暂无'}
                    </div>
                  </div>
                  <div className={`${material ? style.box : style.boxNull}`}>
                    <div className={style.title}>【 申报材料 】</div>
                    <div className={style.content}>
                      &emsp;
                      {material || '暂无'}
                    </div>
                  </div>
                  <div className={`${endDateInfo ? style.box : style.boxNull}`}>
                    <div className={style.title}>【 申报时间 】</div>
                    <div className={style.content}>
                      &emsp;
                      {endDateInfo || '暂无'}
                    </div>
                  </div>
                  <div className={`${subsidy ? style.box : style.boxNull}`}>
                    <div className={style.title}>【 申报金额 】</div>
                    <div className={style.content}>
                      &emsp;
                      {subsidy || '暂无'}
                    </div>
                  </div>
                  <div className={style.boxNull}>
                    <div className={style.title}>【 原文链接 】</div>
                    <div className={style.content}>
                      &emsp;
                      <a href={link} target="blank">点击查看</a>
                    </div>
                  </div>
                </>
              )
            ) : (
              <Spin spinning={loading} tip="加载中..." />
            )
          }
        </div>
      </article>
    </div>
  );
}
