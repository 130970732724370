import { ComponentType, CSSProperties } from 'react';
import clsx from 'clsx';

interface CoverV3Props {
  src: string;
  macro?: any;
  style?: CSSProperties;
  children?: any;
  renderer?: ComponentType;
  className?: string;
}

/**
 * 封皮
 * 支持接收 renderer，通过外部传入的函数渲染内部的 children
 * @param props
 * @constructor
 */
function Cover(props: CoverV3Props) {
  const { src, children, style = {}, renderer: Component, className, } = props;

  return (
    <div
      className={clsx("sqke-cover", className)}
      style={style}
    >
      <div
        className="sqke-cover-image"
        style={{
          backgroundImage: `url(${src})`,
        }}
      />
      {
        Array.isArray(children) && children.map((item: any, index) => {
          if (Component && item) {
            return <Component {...item} key={item.id ?? index} />;
          }
          return null;
        })
      }
    </div>
  );
}

export default Cover;
