/*
 *Author: zhaochenyu
 *Description: 我的-已生成报告表格
 *Date: 2022/01/12
*/

import React from 'react';
import { Table, Pagination } from 'antd';
import ReportNameEdit from '../ReportListNameEdit';
import style from '../../../pages/MySelf/index.module.less';

function GenerateTable(props) {
  const { pagination, getGenerateTable, generatedList, handleGnerateOperator } = props;

  // 已生成报告表格表头
  const genrateColumns = [
    {
      title: '报告名称',
      dataIndex: 'report_name',
      width: '30%',
      ellipsis: true,
      render: (text, record) => (
        <ReportNameEdit
          text={text}
          record={record}
          getAllList={() => getGenerateTable(pagination)}
        />
      ),
    },
    {
      title: '生成时间',
      dataIndex: 'create_time',
      width: '20%',
      ellipsis: true,
    },
    {
      title: '报告类型',
      dataIndex: 'type',
      width: '12%',
      ellipsis: true,
    },
    {
      title: '操作',
      dataIndex: 'operator',
      render: (operators, record) => (
        <div
          className={style.operator}
        >
          {
            operators.map(key => handleGnerateOperator(key, record))
          }
        </div>
      ),
    },
  ];
  return (
    <div className={`${style.tableContainer} ${style.generate}`}>
      <Table
        rowKey={record => record.id}
        columns={genrateColumns}
        dataSource={generatedList}
        pagination={false}
      />
      {pagination.total > 0 && (
      <Pagination
        className={style.pagination}
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        pageSizeOptions={[10]}
        showQuickJumper
        hideOnSinglePage
        onChange={(page, pageSize) => {
          getGenerateTable({ current: page, pageSize });
        }}
      />)}
    </div>
  );
}

export default GenerateTable;
