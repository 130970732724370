import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import ChartNoData from '../../../ChartNoData';
import { useGetPatentStatisticsKeyTechDistributionQuery } from '../../../../../../service/companyDetail';

const HotArea = ({ companyId, grade }) => {
  const { data, isFetching } = useGetPatentStatisticsKeyTechDistributionQuery({
    company_id: companyId,
    penetration_grade: grade,
  });
  const option = () => {
    const _data = data?.series?.[0]?.data?.map(item => (
      {
        ...item,
        unit: data?.series?.[0].unit,
      }
    ));
    return {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params) => (
          `${params.marker}${params.name}\u3000\u3000${params.value}${params.data.unit}`
        ),
        backgroundColor: '#fff',
        extraCssText: 'border: 1px solid #DCDCDC; box-shadow: 2px 4px 8px 0px rgba(108,117,125,0.32);',
      },
      legend: {
        show: false,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: { color: '#919191', fontSize: 12 },
      },
      series: [{
        type: 'wordCloud',
        drawOutOfBound: true,
        shape: 'circle',
        left: 'center',
        top: 'center',
        width: '80%',
        height: '80%',
        right: null,
        bottom: null,
        sizeRange: [8, 50],
        rotationRange: [0, 0],
        rotationStep: -90,
        gridSize: 0,
        data: _data || [],
        label: { distance: 0, show: true, color: '#0D3B66', fontSize: 12, position: 'top' },
      }],
    };
  };

  const chart = () => {
    if (!data || data?.series[0].data.length) {
      return (
        <ECharts
          option={option()}
          style={{ width: '662px', height: '300px' }}
        />
      );
    }
    return <ChartNoData />;
  };
  return (
    <Spin spinning={isFetching} >
      {
        chart()
      }
    </Spin>
  );
};

export default HotArea;
