import React, { useEffect } from 'react';
import DynamicTable from '../../component/DynamicTable';
import { useGetCommonChartQuery } from '../../../../../../service/chartTableTool';

const RanKing = (props) => {
  const { url, dataFilterParams, reportData } = props;
  const { data, isFetching } = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData });
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <DynamicTable {...props} data={data} isFetching={isFetching} delCrosswise />;
};

export default RanKing;
