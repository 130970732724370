/**
 * 城市详情
*/
import { apiSlice } from '../slices/api';

const CITY_DETAIL = 'cityDetail';
export const cityCityDetailApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 头部描述 head_description
    getHeadDescription: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/head_description`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 经济运行数据
    getEconomicData: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/economic_data`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 社会发展
    getSocialDevelopment: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/social_development`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业概览
    getIndustryOverview: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_overview`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 产业概览
    getIndustryOverviewCompanyList: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_overview_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 城市对标
    getCityBenchmark: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/city_benchmark`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 区域分布地图
    getRegionalDistribution: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/regional_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分析表格
    getRegionalDistributionTable: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/regional_distribution_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分析子表格
    getRegionalDistributionSubtable: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/regional_distribution_subtable`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分布
    getIndustryDistribution: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分布-表格
    getIndustryDistributionTable: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_distribution_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分布-表格-子表
    getIndustryDistributionSubtable: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_distribution_subtable`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 股权融资
    getEquityFinancing: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/equity_financing`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 投资列表
    getFindInvestmentList: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/find_investment_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新能力
    getInnovationAbility: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/innovation_ability`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专利列表
    getCityDetailFindPatentList: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/find_patent_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 研究机构列表
    getFindInstitutionsList: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/find_institutions_list`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 园区载体
    getParkCarrier: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/park_carrier`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业载体
    getCityDetailFindIndustryCarrierList: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/find_industry_carrier_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业载体
    getCityDetailIndustryCarrierOverlayNumber: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_carrier_overlay_number`,
          params: arg,
        };
      },
    }),

    // 政策清单
    getListOfPolicies: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/list_of_policies`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业政策清单
    getCityDetailFindIndustryPolicyList: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/find_industry_policy_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业载体
    getCityDetailIndustryPolicyOverlayNumber: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_policy_overlay_number`,
          params: arg,
        };
      },
    }),
    // 工具 tool_status状态
    toolStatus: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/tool_status`,
          params: arg,
        };
      },
    }),
    // 工具 tool_status状态
    buttonPermission: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/button_permission`,
          params: arg,
        };
      },
    }),
  }),
});

export const {
  useGetHeadDescriptionQuery,
  useGetEconomicDataQuery,
  useGetSocialDevelopmentQuery,
  useGetIndustryOverviewQuery,
  useGetIndustryOverviewCompanyListQuery,
  useGetCityBenchmarkQuery,
  useGetRegionalDistributionQuery,
  useGetRegionalDistributionTableQuery,
  useGetRegionalDistributionSubtableQuery,
  useGetIndustryDistributionQuery,
  useGetIndustryDistributionTableQuery,
  useGetIndustryDistributionSubtableQuery,
  useGetEquityFinancingQuery,
  useGetFindInvestmentListQuery,
  useGetInnovationAbilityQuery,
  useGetCityDetailFindPatentListQuery,
  useGetFindInstitutionsListQuery,
  useGetParkCarrierQuery,
  useGetCityDetailFindIndustryCarrierListQuery,
  useGetCityDetailIndustryCarrierOverlayNumberQuery,
  useGetListOfPoliciesQuery,
  useGetCityDetailFindIndustryPolicyListQuery,
  useGetCityDetailIndustryPolicyOverlayNumberQuery,
  useToolStatusQuery,
  useButtonPermissionQuery,
} = cityCityDetailApi;
