import React, { useMemo, useEffect } from 'react';
import BothwayBarTable from '../../../component/BothwayBarTable';
import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const HotArea = (props) => {
  const { url, dataFilterParams, reportData, ok } = props;
  const { data, isFetching } = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData || !ok });
  // const { data, isFetching } = useGetCommonChartQuery({
  //   chart_route: url,
  //   ...dataFilterParams,
  // }, { skip: !url });
  const newData = useMemo(() => {
    if (!data) return undefined;
    if (!data.series.length) {
      const initData = {
        ...data,
        series: [
          { name: '融资金额(万元)', unit: '万元', data: [0] },
          { name: '融资事件(笔)', unit: '笔', data: [0] },
        ],
      };
      return initData;
    }
    return data;
  }, [data]);
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <BothwayBarTable {...props} data={newData} isFetching={isFetching} chartType="柱线图" delCrosswise />;
};

export default HotArea;
