import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Modal, Pagination, Form, Button, message } from 'antd';
import classNames from 'classnames';
import createProjectIcon from '../../../../assets/images/dataAnalysisTool/myReport/createProject.png';
import deleteIcon from '../../../../assets/images/dataAnalysisTool/myReport/delete.png';
import selectDeleteIcon from '../../../../assets/images/dataAnalysisTool/myReport/selectDelete.png';
import settingIcon from '../../../../assets/images/dataAnalysisTool/myReport/setting.png';
import selectSettingIcon from '../../../../assets/images/dataAnalysisTool/myReport/selectSetting.png';
import confirmIcon from '../../../../assets/images/dataAnalysisTool/myReport/confirmIcon.png';
import {
  useLazyGetCreateProjectQuery,
  useLazyGetDeleteProjectQuery,
  useLazyGetUpdateProjectQuery,
  useLazyGetQueryProjectListQuery,
} from '../../../../service/dataAnalysisTool';
import styles from './index.module.less';

const { Search } = Input;

export default function MyReport() {
  const history = useHistory();
  const [selectProject, setSelectProject] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [params, setParams] = useState({
    keyword: '',
    page: 1,
    size: 11,
  });
  const [projectList, setProjectList] = useState([]);
  const [count, setCount] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [getCreateProject] = useLazyGetCreateProjectQuery();
  const [getQueryProjectList] = useLazyGetQueryProjectListQuery();
  const [getDeleteProject] = useLazyGetDeleteProjectQuery();
  const [getUpdateProject] = useLazyGetUpdateProjectQuery();

  // 查询项目列表接口
  const fetchQueryProjectList = async () => {
    const { keyword, page, size } = params;
    const res = await getQueryProjectList({
      keyword,
      page_number: page || 1,
      page_size: size || 11,
    });
    if (res.status === 'fulfilled') {
      setProjectList(res.data?.data);
      setCount(res.data?.count);
    }
  };

  // 新建项目接口
  const fetchCreateProject = async (node) => {
    const { title, subheading, description } = node;
    const res = await getCreateProject({
      title,
      subheading,
      description,
    });
    if (res.status === 'fulfilled') {
      message.success('新建项目成功');
      setIsModalVisible(false);
      fetchQueryProjectList();
    }
  };

  // 更新项目内容
  const fetchUpdateProject = async (node) => {
    const { title, subheading, description } = node;
    const res = await getUpdateProject({
      project_id: selectProject.id,
      title,
      subheading,
      description,
    });
    if (res.status === 'fulfilled') {
      message.success('项目设置成功');
      setIsModalVisible(false);
      fetchQueryProjectList();
      setSelectProject();
    }
  };

  // 表单提交
  const handleFormSubmit = e => {
    if (!selectProject) {
      fetchCreateProject(e);
    } else {
      fetchUpdateProject(e);
    }
  };

  // 关闭表单弹窗
  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectProject();
  };

  // 关闭删除确认弹窗
  const handleDeleteClose = () => {
    setIsDeleteModalOpen(false);
    setSelectProject();
  };

  // 删除弹窗确认
  const handleDeleteConfirm = async () => {
    const res = await getDeleteProject({ project_id: selectProject?.id });
    if (res.status === 'fulfilled') {
      message.success('项目成功删除');
      setIsDeleteModalOpen(false);
      fetchQueryProjectList();
      setSelectProject();
    }
  };

  useEffect(() => {
    if (params) {
      fetchQueryProjectList();
    }
  }, [params]);

  return (
    <div className={styles.myReport}>
      <div className={styles.searchBox}>
        <div className={styles.title}>写报告</div>
        <Search
          placeholder='请输入您要查询的报告名称'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(e) => setParams(prev => ({ ...prev, keyword: e, page: 1 }))}
        />
      </div>
      <div className={styles.listBox}>
        <div className={styles.title}>我的报告</div>
        <div className={styles.list}>
          <div className={styles.create} onClick={() => setIsModalVisible(true)}>
            <div className={styles.inner}>
              <img src={createProjectIcon} alt="" />
              新建项目
            </div>
          </div>
          {
            projectList?.length > 0 && projectList.map(item => (
              <div
                className={classNames(styles.project, { [styles.selectProject]: item.id === selectProject?.id })}
                key={item.id}
                onClick={() => {
                  setSelectProject(item);
                  history.push(`/dataAnalysisTool/dataFilter?id=${item.id}`);
                  const projectObj = JSON.stringify(item);
                  localStorage.setItem('selectProject', projectObj);
                }}
              >
                <div className={styles.top}>
                  <div
                    className={styles.operateIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectProject(item);
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <img src={item.id === selectProject?.id ? selectDeleteIcon : deleteIcon} alt="" />
                  </div>
                  <div
                    className={styles.operateIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectProject(item);
                      setIsModalVisible(true);
                    }}
                  >
                    <img src={item.id === selectProject?.id ? selectSettingIcon : settingIcon} alt="" />
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.discribe}>{item.description}</div>
                  <div className={styles.date}>{item.update_time}</div>
                </div>
              </div>
            ))
          }
        </div>
        <div className={styles.pagination}>
          <div className={styles.total}>{`共 ${count} 个项目`}</div>
          <Pagination
            current={params.page}
            pageSize={11}
            total={count}
            onChange={(current) => {
              setParams(prev => ({ ...prev, page: current }));
            }}
          />
        </div>
      </div>
      <Modal
        title={selectProject ? '编辑项目' : '新建项目'}
        footer={null}
        open={isModalVisible}
        onCancel={handleModalClose}
        destroyOnClose={true}
        width={425}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.modalContent}>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={handleFormSubmit}
            initialValues={selectProject || null}
          >
            <Form.Item
              name='title'
              label='项目名称'
              rules={[
                {
                  required: true,
                  message: '请输入项目名称',
                },
                {
                  validator: (rule, value, callback) => {
                    const reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
                    if (!reg.test(value)) {
                      callback(new Error('只能输入汉字字母和数字'));
                    } else {
                      callback();
                    }
                  },
                  trigger: 'blur',
                },
              ]}
            >
              <Input
                placeholder='请输入项目名称'
              />
            </Form.Item>
            {/* <Form.Item
              name='subheading'
              label='副标题'
            >
              <Input
                placeholder='请输入项目副标题'
              />
            </Form.Item> */}
            <Form.Item
              name='description'
              label='项目描述'
            >
              <Input.TextArea
                placeholder='请输入项目描述'
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button htmlType="button" onClick={handleModalClose} style={{ marginRight: 12 }}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title={
          <div className={styles.deleteTitle}>
            <img src={confirmIcon} alt="" />
            是否确认删除项目？
          </div>
        }
        footer={null}
        open={isDeleteModalOpen}
        onCancel={handleDeleteClose}
        width={360}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.deleteContent}>
          <div className={styles.text}>删除项目将导致您筛选或上传的数据无法保存</div>
          <div className={styles.buttonBox}>
            <Button onClick={handleDeleteClose} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={handleDeleteConfirm}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
