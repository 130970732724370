/*
 *Author: zhaochenyu
 *Description: 思维导图组件
 *Date: 2021/01/07
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import MindMap from '../../BasicsCompoment/MindMapChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BussinessMindMapChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessMindMapChart(props: BussinessMindMapChartProps) {
  const { mode, chartGrid, ip, conditions, onRequestComplete, title,
    componmentKey, imgRef, roam } = props;
  const [chartData, setChartData] = useState<any>([]);
  const [chartTitle, setChartTitle] = useState<string>('');
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  useEffect(() => {
    if (ip) {
      setChartLoading(true);
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },
      }).then(res => {
        if (res.status === '00000') {
          setChartData(res.data.data || []);
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        } else {
          setChartData([]);
        }
        setChartLoading(false);
        if (isFunction(onRequestComplete)) {
          onRequestComplete!(res.data);
        }
      }).catch(() => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setChartLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (chartLoading ? (
    <div className={styleSpin}>
      <Spin tip="加载中..." />
    </div>
  ) : (
    <div className={styleMap[mode!] || styleDark}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <MindMap
          data={chartData}
          mode={mode}
          chartGrid={chartGrid}
          ref={imgRef}
          chartDesc={chartDesc}
          roam={roam}
        />
      </div>
    </div>)
  );
}

BusinessMindMapChart.defaultProps = {
  mode: 'light',
  title: true,
  roam: false,
};

// 竞争格局
export function CompetitionPatternMindMapchart(props: BussinessMindMapChartProps) {
  const componmentKey = {
    function_key: 'company_competition_pattern_graph',
    class_name: '/chart/company',
  };
  return (
    <BusinessMindMapChart
      {...props}
      componmentKey={componmentKey}
    />);
}
