import React, { useState, useEffect } from 'react';
import Table from '../../../components/Table';
import { useGetParkRecommendedEnterpriseListQuery } from '../../../../../../../../service/parkTool';
import { FilterIcon } from '../../../../../../../../components/CustomTableIcon';
import yesIcon from '../../../../../../../../assets/images/CityDetails/yes.png';
import styles from './index.module.less';

const tagsDictMap = {
  regional_development: '区域拓展快',
  many_new_patents: '新增专利多',
  many_new_investment: '新获投资多',
  rapid_staff_growth: '员工增长快',
};
function SectorCompanyTable(props) {
  const {
    industryCode,
    linkCode,
    parkCode,
    setCount,
    onCompanyClick,
    page,
    setPage,
    parkTitle,
  } = props;
  const [pageSize, setPageSize] = useState(20);
  const [areaCode, setAreaCode] = useState(parkCode);
  const [tagsDict, setTagsDict] = useState({
    区域拓展快: ['全部'],
    新增专利多: ['全部'],
    新获投资多: ['全部'],
    员工增长快: ['全部'],
  });

  const { data: tableData, isFetching: isCompanyListFetching } = useGetParkRecommendedEnterpriseListQuery({
    area_key: parkTitle === '园区总览' ? areaCode : undefined,
    park_id_list: parkTitle === '园区总览' ? undefined : [areaCode],
    industry_code: industryCode,
    link_code: linkCode,
    tags_dict: (() => {
      const cpTagsDict = {};
      Object.keys(tagsDict).forEach(key => {
        cpTagsDict[key] = tagsDict[key]?.[0] === '全部' ? '' : tagsDict[key]?.[0];
      });
      return cpTagsDict;
    })(),
    page_size: 20,
    page_number: page,
  }, { skip: !industryCode || !areaCode });
  const { total = 0, count = '', columns = [], dataSource = [] } = tableData || {};
  useEffect(() => {
    setCount(count);
  }, [count]);
  useEffect(() => {
    setAreaCode(parkCode);
  }, [parkCode]);
  const levels = [{
    title: '全部',
    value: '全部',
    text: '全部',
  }, {
    title: '是',
    value: '是',
    text: '是',
  }, {
    title: '否',
    value: '否',
    text: '否',
  }];
  const Tablehandle = (item) => (
    {
      index: {
        className: styles.index,
        width: 50,
      },
      company_name: {
        width: 120,
        ellipsis: true,
        className: styles.name,
        onCell: (record, rowIndex) => ({
          onClick: () => { onCompanyClick(record, rowIndex); },
        }),
      },
      regional_development: {
        filterIcon: <FilterIcon />,
        filters: levels,
        ellipsis: true,
        filteredValue: tagsDict.区域拓展快,
        filterMultiple: false,
        render: text => (text ? <p className={styles.yesIcon}><img src={yesIcon} alt="" style={{ width: '100%' }} /></p> : <p className={styles.noIcon}>-</p>),
      },
      many_new_investment: {
        filterIcon: <FilterIcon />,
        filters: levels,
        ellipsis: true,
        filteredValue: tagsDict.新获投资多,
        filterMultiple: false,
        render: text => (text ? <p className={styles.yesIcon}><img src={yesIcon} alt="" style={{ width: '100%' }} /></p> : <p className={styles.noIcon}>-</p>),
      },
      many_new_patents: {
        filterIcon: <FilterIcon />,
        filters: levels,
        ellipsis: true,
        filteredValue: tagsDict.新增专利多,
        filterMultiple: false,
        render: text => (text ? <p className={styles.yesIcon}><img src={yesIcon} alt="" style={{ width: '100%' }} /></p> : <p className={styles.noIcon}>-</p>),
      },
      rapid_staff_growth: {
        filterIcon: <FilterIcon />,
        filters: levels,
        ellipsis: true,
        filteredValue: tagsDict.员工增长快,
        filterMultiple: false,
        render: text => (text ? <p className={styles.yesIcon}><img src={yesIcon} alt="" style={{ width: '100%' }} /></p> : <p className={styles.noIcon}>-</p>),
      },
    }
  );
  const updateTagsDict = (_, filters, sorter) => {
    Object.keys(filters).forEach(key => {
      setTagsDict(dict => ({ ...dict, [tagsDictMap[key]]: filters[key] }));
    });
  };
  return (
    <Table
      {...props}
      Tablehandle={Tablehandle}
      data={{ columns, dataSource }}
      isFetching={isCompanyListFetching}
      onChange={updateTagsDict}
      pagination={{
        current: page,
        pageSize,
        showQuickJumper: false,
        showSizeChanger: false,
        total,
        onChange: (current) => {
          setPage(current);
        },
        onShowSizeChange: (size) => {
          setPageSize(size);
        },
      }}
    />
  );
}
export default SectorCompanyTable;
