import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import filterIcon from '../../../assets/images/dataAnalysisTool/filterIcon.svg';
import reportIcon from '../../../assets/images/dataAnalysisTool/reportIcon.svg';
import downloadIcon from '../../../assets/images/dataAnalysisTool/downloadIcon.svg';
import collapseIcon from '../../../assets/images/dataAnalysisTool/collapseIcon.svg';
import expandIcon from '../../../assets/images/dataAnalysisTool/expandIcon.svg';
import styles from './index.module.less';

const generateReportMenu = [
  {
    key: 'dataFilter',
    icon: <img src={filterIcon} alt='' />,
    label: '数据筛选',
    title: '数据筛选',
    path: '/dataAnalysisTool/dataFilter',
  },
  {
    key: 'myReport',
    icon: <img src={reportIcon} alt='' />,
    label: '我的报告',
    title: '我的报告',
    path: '/dataAnalysisTool/myReport',
  },
  {
    key: 'downloadList',
    icon: <img src={downloadIcon} alt='' />,
    label: '下载列表',
    title: '下载列表',
    path: '/dataAnalysisTool/downloadList',
  },
];

export default function LeftMenu({ selectTool }) {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [collapsed, setCollpased] = useState(false);
  const [selectdKeys, setSelectedKeys] = useState(['dataFilter']);

  useEffect(() => {
    if (selectTool === '生成报告') {
      setItems(generateReportMenu);
    }
  }, [selectTool]);

  useEffect(() => {
    const cancel = history.listen(location => {
      const { pathname } = location;
      // console.log('pathname', pathname);
      if (pathname === '/dataAnalysisTool/reportPreview' || pathname === '/dataAnalysisTool/chartTableTool') {
        setCollpased(true);
      }
      if (generateReportMenu?.filter(item => item.path === pathname)?.length > 0) {
        setSelectedKeys([generateReportMenu?.filter(item => item.path === pathname)[0].key]);
      } else {
        setSelectedKeys([]);
      }
    });
    return () => cancel();
  }, []);

  return (
    <div className={styles.leftMenu}>
      <Menu
        theme='light'
        mode='inline'
        items={items}
        inlineCollapsed={collapsed}
        selectedKeys={selectdKeys}
        onSelect={(item) => {
          history.push({ pathname: `/dataAnalysisTool/${item.key}` });
          setSelectedKeys([item.key]);
        }}
      />
      <div className={styles.collpaseBtn} onClick={() => setCollpased(!collapsed)}>
        <img src={collapsed ? expandIcon : collapseIcon} alt='' />
      </div>
    </div>
  );
}
