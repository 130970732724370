import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import classNames from 'classnames';
import { useGetKeyPersonnelChangeQuery } from '../../../../../../service/companyDetail';
import style from '../index.module.less';

const Executives = ({ companyId }) => {
  const [personnelPage, setPersonnelPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [afterActive, setAfterActive] = useState(null);
  const { data: personnelData, isFetching: isPersonnelFetching } = useGetKeyPersonnelChangeQuery({
    company_id: companyId,
    page_size: personnelPage.pageSize,
    page_number: personnelPage.pageNumber,
  }, {
    skip: !companyId,
  });
  const redName = (data) => {
    const arr = [];
    let arr2 = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      if (data[i] === '[') {
        arr2 = [];
        arr2.push(i);
      } else if (data[i] === ']') {
        arr2.push(i);
        arr.push([...arr2]);
      }
    }
    if (!arr.length) return [];
    if (arr.length > 1) {
      return arr.map(item => data.slice(item[0] + 1, item[1]).split(',').filter(it => it).join());
    }
    return arr.map(item => data.slice(item[0] + 1, item[1]).split(','))[0].filter(it => it);
  };

  const personnelColumns = useMemo(() => (
    personnelData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          width: 70,
          ...item,
        };
      }
      if (item.dataIndex === 'before') {
        return {
          ...item,
          width: 510,
          render: (text, row) => {
            if (!text) return '';
            let str = text.replace(/\[\+/g, '');
            str = str.replace(/\+\]/g, '');
            let newText = text.replace(/\[\+/g, '<span style=\'color:red\'>');
            newText = newText.replace(/\+\]/g, '</span>');
            if (afterActive === row.source_id) {
              return (
                <div className={classNames(style.activeBefore)}>
                  <div dangerouslySetInnerHTML={{ __html: newText }} />
                  <div
                    className={style.packUp}
                    onClick={() => { setAfterActive(); }}
                    style={{ display: str.length > 31 ? 'block' : 'none' }}
                  >
                    收起
                  </div>
                </div>
              );
            }
            return (
              <div className={classNames(style.before)}>
                <div dangerouslySetInnerHTML={{ __html: newText }} />
                <span
                  className={style.more}
                  style={{ display: str.length > 31 ? 'block' : 'none' }}
                  onClick={() => {
                    setAfterActive(row.source_id);
                  }}
                >
                  更多
                </span>
              </div>
            );
          },
        };
      }
      if (item.dataIndex === 'after') {
        return {
          ...item,
          width: 510,
          render: (text, row) => {
            if (!text) return '';
            let str = text.replace(/\[-/g, '');
            str = str.replace(/-\]/g, '');
            let newText = text.replace(/\[-/g, '<span style=\'color:red\'>');
            newText = newText.replace(/-\]/g, '</span>');
            if (afterActive === row.source_id) {
              return (
                <div className={style.activeAfter}>
                  <div dangerouslySetInnerHTML={{ __html: newText }} />
                  <div
                    className={style.packUp}
                    onClick={() => { setAfterActive(); }}
                    style={{ display: str.length > 31 ? 'block' : 'none' }}
                  >
                    收起
                  </div>
                </div>
              );
            }
            return (
              <div className={classNames(style.after)}>
                <div dangerouslySetInnerHTML={{ __html: newText }} />
                <span
                  className={style.more}
                  style={{ display: str.length > 31 ? 'block' : 'none' }}
                  onClick={() => {
                    setAfterActive(row.source_id);
                  }}
                >
                  更多
                </span>
              </div>
            );
          },
        };
      }
      if (item.dataIndex === 'date') {
        return {
          width: 120,
          ellipsis: true,
          ...item,
        };
      }
      return {
        ...item,
        ellipsis: true,
      };
    })
  ), [personnelData, afterActive]);
  const executivesTable = () => {
    if (!personnelData || personnelData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={personnelData?.dataSource}
          columns={personnelColumns}
          locale={{ emptyText: ' ' }}
          title={() => (
            <>
              <span>{personnelData?.title}</span>
              <span className={style.count}>{personnelData?.count}</span>
              <span className={style.describe}>标有*标志的为法定代表人</span>
            </>
          )}
          strip
          loading={isPersonnelFetching}
          pagination={{
            current: personnelPage.pageNumber,
            pageSize: personnelPage.pageSize,
            total: personnelData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setPersonnelPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  };
  return executivesTable();
};

export default Executives;
