/*
*Description: 跨行跨列业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import NormalChart from '../../BasicsCompoment/NormalChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessNormalChartProps, BusinessSpanTableChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessSpanTableChart(props: BusinessNormalChartProps) {
  const {
    mode, ip, conditions,
    onRequestComplete, title, componmentKey,
    imgRef,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data;
          if (resData.data) {
            const callBack = typeof props.adaperDataWithStyle === 'function' ? props.adaperDataWithStyle : (
              _arg: any,
            ) => ({
              containerStyle: {},
              values: [],
            });
            setData(callBack(resData));
          }
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
         onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        console.log(_reason);
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark} style={{ overflowY: 'scroll' }}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <NormalChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            chartDesc,
            ref: imgRef,
            isSpanChart: true,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}
BusinessSpanTableChart.defaultProps = {
  mode: 'light',
};

type commonStyleGeneratorParams = {
  colGap: Array<number>,
  titleStyle: Array<any>,
  colStyle: Array<any>,
  titleNoWrap?: Array<number>,
  minRows?: number,
  preHandleData?: (data: any) => void,
}
function commonStyleGenerator({
  colGap, titleStyle, colStyle,
  titleNoWrap, minRows, preHandleData,
}:commonStyleGeneratorParams) {
  return function adaperDataWithStyle(resData: {
    data: { columns: any[]; dataSource: any[]; }
  }) {
    if (typeof preHandleData === 'function') {
      preHandleData(resData);
    }
    const handledData: any[][] = [];
    const titles = [];
    let beforeEndColumn = 1;

    // eslint-disable-next-line no-return-assign
    const allColumn = colGap.reduce((prev, item) => (prev += item));
    // title 表头 获取 并获取列的顺序
    const orderKey: any[] = [];
    for (let i = 0, L = resData.data.columns.length; i < L; i += 1) {
      const item = resData.data.columns[i];
      const curColStart = beforeEndColumn;
      const curColEnd = curColStart + colGap[i];
      beforeEndColumn = curColEnd;
      titles.push({
        // 四个字换个行
        text: item.title.length > 4 ? item.title.split('').reduce((
          prev: string, str: string, index: number, arr: any,
        ) => {
          let count = 4;
          if (titleNoWrap?.[i]) {
            count = 0;
          }
          if (index && (index + 1) === count && index !== arr.length - 1) {
            prev += (`${str}<br />`);
          } else {
            prev += (str);
          }
          return prev;
        }, '') : item.title,
        style: {
          gridColumnStart: curColStart,
          gridColumnEnd: curColEnd,
          gridRowStart: 1,
          gridRowEnd: 2,
          ...titleStyle[i],
        },
      });
      orderKey.push(item.dataIndex);
    }
    handledData.push(titles);
    const rowStyle = [
      {}, {},
    ];

    const { dataSource } = resData.data;
    const rL = dataSource.length;
    const visited = new Array(rL).fill(0).map(_item => {
      const newArr = new Array(orderKey.length).fill(0);
      return newArr;
    });
    function findSpan(nextRow: number, nextColIndex: number) {
      function findRowSpan() {
        let count = 0;
        let i = nextRow;
        while (i < rL) {
          if (dataSource[i][orderKey[nextColIndex - 1]] === null && !visited[i][nextColIndex - 1]) {
            visited[i][nextColIndex - 1] = 1;
            i += 1;
            count += 1;
          } else {
            break;
          }
        }
        return count;
      }
      function findColSpan() {
        let i = nextColIndex;
        let count = 0;
        while (i < orderKey.length) {
          if (dataSource[nextRow - 1][orderKey[i]] === null && !visited[nextRow - 1][i]) {
            visited[nextRow - 1][i] = 1;
            count += 1;
            i += 1;
          } else {
            break;
          }
        }
        return count;
      }
      return [findRowSpan(), findColSpan()];
    }
    for (let i = 0, L = rL; i < L; i += 1) {
      const item = dataSource[i];
      const temp: any[] = [];
      let curBeforeEndColumn = 1;
      orderKey.forEach((key, col) => {
        const [rowSpan, colSpan] = findSpan(i + 1, col + 1);
        const curColStart = curBeforeEndColumn;
        const curColEnd = curColStart + colGap[col] + colSpan;
        curBeforeEndColumn = curColEnd;
        if (item[key] === null) {
          return;
        }
        temp.push({
          text: item[key],
          style: {
            gridColumnStart: curColStart,
            gridColumnEnd: curColEnd,
            gridRowStart: i + 2,
            gridRowEnd: i + 3 + rowSpan,
            ...colStyle[col],
            ...rowStyle[1 - (i % 2)],
          },
        });
      });
      handledData.push(temp);
    }
    let row = handledData.length;
    if (typeof minRows === 'number') {
      row = row > minRows ? row : minRows;
    }
    return {
      containerStyle: {
        gridTemplateColumns: `repeat(${allColumn}, 1fr)`,
        gridTemplateRows: `auto repeat(${row},1fr)`,
      },
      values: handledData,
    };
  };
}

// 16 . --参与起草相关产业标准明细
export function StandardChartparticipateDraftingStandardsFenxi(
  props: BusinessSpanTableChartProps,
) {
  const componmentKey = {
    function_key: 'participate_drafting_standards_fenxi',
    class_name: '/chart/standard',
  };
  const colGap = [1, 1, 1];
  // 列序
  const titleStyle = [
    {},
    {
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
    {
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
      fontSize: 12,
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
      borderLeft: '1px solid #E9E5E1',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      fontSize: 12,
      color: '#333333',
      letterSpacing: '1px',
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#919191',
      fontSize: 12,
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
      borderLeft: '1px solid #E9E5E1',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      fontSize: 12,
      color: '#333333',
      letterSpacing: '1px',
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
  ];
  // function preHandleData(source: any) {
  //   const { data } = source;
  //   // 双标头 复制一份原来的
  //   const originKeys: string[] = [];
  //   const copyied: { title: any; dataIndex: string; }[] = [];
  //   data.columns.forEach((item: any) => {
  //     originKeys.push(item.dataIndex);
  //     copyied.push({
  //       title: item.title,
  //       dataIndex: `${item.dataIndex}1`,
  //     });
  //   });
  //   data.columns.push(...copyied);
  //   const secondTable = data.dataSource.splice(
  //     Math.floor(data.dataSource.length / 2),
  //   );
  //   data.dataSource.forEach((item: any, index: number) => {
  //     originKeys.forEach((key: string) => {
  //       item[`${key}1`] = secondTable[index][key];
  //     });
  //   });
  // }
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    // preHandleData,
  });

  return (
    <BusinessSpanTableChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 25 . --企业画像-申请专利网络类型分布
export function PatentChartdistributionOfPatentTypesFenxi(
  props: BusinessSpanTableChartProps,
) {
  const componmentKey = {
    function_key: 'distribution_of_patent_types_fenxi',
    class_name: '/chart/patent',
  };
  // 列序
  const colStyle = [
    {
      color: '#919191',
      fontSize: 12,
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
      borderLeft: '1px solid #E9E5E1',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      fontSize: 12,
      color: '#333333',
      letterSpacing: '1px',
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#919191',
      fontWeight: 'bold',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      padding: '0 5px 0 5px',
      color: '#333333',
      letterSpacing: '1px',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
      wordBreak: 'break-all',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
    },
  ];
  const colGap = [1, 1, 1, 1];
  const titleStyle = [
    {},
    {
      // justifyContent: 'left',
    },
    {
    },
    {},
    {
      // justifyContent: 'left',
    },
    {
    },
  ];
  const minRows = 9;
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows,
  });
  return (
    <BusinessSpanTableChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 27 . --企业画像-起草标准网络类型分布
export function StandardChartdistributionOfStandardTypesFenxi(
  props: BusinessSpanTableChartProps,
) {
  const componmentKey = {
    function_key: 'distribution_of_standard_types_fenxi',
    class_name: '/chart/standard',
  };
  // 列序
  const colStyle = [
    {
      color: '#919191',
      fontSize: 12,
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
      borderLeft: '1px solid #E9E5E1',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      fontSize: 12,
      color: '#333333',
      letterSpacing: '1px',
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#919191',
      textAlign: 'center',
      fontWeight: 'bold',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      padding: '0 5px 0 5px',
      color: '#333333',
      letterSpacing: '1px',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
      wordBreak: 'break-all',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
    },
  ];
  const colGap = [1, 1, 1, 1];
  const titleStyle = [
    {},
    {
      // justifyContent: 'left',
    },
    {
    },
    {},
    {
      // justifyContent: 'left',
    },
    {
    },
  ];
  const minRows = 9;
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows,
  });
  return (
    <BusinessSpanTableChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 32 . --融资历程
export function InvestmentChartcompanyInvestmentExperience(
  props: BusinessSpanTableChartProps,
) {
  const componmentKey = {
    function_key: 'company_investment_experience',
    class_name: '/chart/investment',
  };
  // 列序
  const colStyle = [
    {
      color: '#919191',
      fontSize: 12,
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
      borderLeft: '1px solid #E9E5E1',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      fontSize: 12,
      color: '#333333',
      letterSpacing: '1px',
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      color: '#919191',
      fontWeight: 'bold',
      textAlign: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      padding: '0 5px 0 5px',
      color: '#333333',
      letterSpacing: '1px',
      borderBottom: '1px solid #E9E5E1',
      borderRight: '1px solid #E9E5E1',
      wordBreak: 'break-all',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
    },
  ];
  const colGap = [1, 2, 2, 2, 3];
  const titleStyle = [
    {},
    {
      justifyContent: 'left',
    },
    {
    },
    {},
    {
      // justifyContent: 'left',
    },
    {
    },
  ];
  const minRows = 9;
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows,
  });
  return (
    <BusinessSpanTableChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}
