/**
 * echarts-extend
 * 定义、强化 echarts series 的模块
 * 转换
 */
import type { EChartsOption, SeriesOption } from 'echarts';
import { cloneDeep, extend, merge, set } from 'lodash';
import { getFormatter, getLabel } from '../../utils/label';
import { array } from '../../utils/array';
import { applyMatchers } from '../../utils/jsonLogic';
import { getDataItem, IDataItemObject, omitUndefined, OptionParser } from '../echarts-option';
import { getEachNode, INode, IPicker, iteratorGenerator } from '../../utils/iterator';
import jsonLogic from 'json-logic-js';
import { ECUnitOption } from 'echarts/types/src/util/types';

interface SeriesOptionExtend extends SeriesOption {
  [key: string]: any;
}


/**
 * 组装应用于分析引擎配置的 EChartsVisualConfig 和 数据，
 * 转换为拓展后的 ECOptionEx
 * @param raw
 * @param data
 */
// export const assembleECOptionEx = (raw, data) => {
//   return {};
// };
/**
 * 将扩展后的 series 转换为可被 ECharts 接受和使用的 series
 * 此时，series 应该已经包含 data
 * @param series
 * @param index
 * @param option
 */
export const convertSeries = (series: SeriesOptionExtend, index: number, option: any) => {
  const _series = cloneDeep(series);
  /**
   * 【共同部分》formatter】
   * 1. label.formatter
   * 2. emphasis.label.formatter
   * 4. markPoint.label.formatter
   * 5. markLine.data[*].label.formatter
   * 6. markLine.data[*].emphasis.label.formatter
   * 7. tooltip.formatter
   * 8. tooltip.valueFormatter
   * 9. blur.label.formatter
   * 10. select.label.formatter
   */
  const {
    label,
    emphasis,
    markPoint,
    markLine,
    tooltip,
    blur,
    select,
    data,
  } = series;
  if (label) {
    _series.label = getLabel(label);
  }
  if (emphasis?.label) {
    set(_series, 'emphasis.label', getLabel(emphasis.label));
  }
  if (markPoint?.label) {
    set(_series, 'markPoint.label', getLabel(markPoint.label));
  }
  if (markLine?.data) {
    set(_series, 'markLine.data', markLine.data.map((item: any) => getLabel(item.label)));
  }
  if (tooltip) {
    _series.tooltip = { ...tooltip };
    if (tooltip.formatter) {
      set(_series, 'tooltip.formatter', getFormatter(tooltip.formatter));
    }
    if (tooltip.valueFormatter) {
      set(_series, 'tooltip.valueFormatter', getFormatter(tooltip.valueFormatter));
    }
  }
  if (blur?.label) {
    set(_series, 'blur.label', getLabel(blur.label));
  }
  if (select?.label) {
    set(_series, 'select.label', getLabel(select.label));
  }

  /**
   * 【共同部分》matchers、leaves、levels、categories】
   * jsonLogic 逻辑对象
   * 1. 处理逻辑样式匹配;
   * 2. 处理树状结构的深度匹配和叶子匹配;
   * 3. 处理图结构的分类匹配;
   * 4. 处理树的 parent 属性
   */
  const {
    matchers,
    type,
    name,
  } = series;
  // 获取迭代的类型
  const iterateType = (() => {
    if ([
      'bar', 'line', 'pie', 'scatter',
      'effectScatter', 'radar', 'boxplot', 'candlestick',
      'heatmap', 'map', 'parallel', 'lines',
      'funnel', 'gauge', 'pictorialBar', 'themeRiver',
    ].includes(type)) {
      return 'ARRAY';
    }
    if (['tree', 'treemap', 'sunburst, sankey'].includes(type)) {
      return 'FOREST';
    }
    if (['graph', 'sankey'].includes(type)) {
      return 'GRAPH';
    }
    return 'ARRAY';
  })();

  // 获取系列的坐标轴、坐标系类型，结合系列的名称、类型、序号
  const {
    xAxisIndex,
    yAxisIndex,
    polarIndex,
    geoIndex,
    coordinateSystem,
  } = series;
  const seriesProperty = {
    seriesName: name,
    seriesIndex: index,
  };
  const seriesItem = {
    name,
    type,
    coordinateSystem,
    xAxisIndex,
    yAxisIndex,
    polarIndex,
    geoIndex,
  };
  const optionParser = new OptionParser(option);
  const categoryAxis = Object.values(optionParser.getAxis(seriesItem as any)).filter(OptionParser.isAxisCategory);
  // 处理数据
  const handleDataItems = (item: any, index?: number) => {
    if (!matchers) return item;
    const dataItem = getDataItem(item);
    const dataItemInfos = {
      ...seriesProperty,
      type,
      ...(dataItem as IDataItemObject),
      index,
    } as any;
    if (typeof index === 'number') {
      dataItemInfos.category = categoryAxis.length !== 0 ? categoryAxis[0].data[index] as string : undefined;
    }
    return merge({ ...item }, applyMatchers(matchers, dataItemInfos));
  };


  /**
   * 处理数组类型的数据，此时，仅需要应用逻辑匹配规则
   */
  if (iterateType === 'ARRAY' && data && matchers && matchers.length > 0) {
    _series.data = data.map(handleDataItems);
  }

  /**
   * 处理森林时节点数据
   */
  if (iterateType === 'FOREST' && data) {
    const {
      levels,
      leaves,
      itemStyle,
      lineStyle,
      color: originColor,
      colorMappingBy,
      colorMappingByFrom = 0,
      colorContinuous,
    } = series;
    const color = originColor ? extend(originColor) : null;
    const labelColor = label?.color ? extend(label.color) : null;
    // _series.data = data;
    // } else {
    let colorNext = 0;
    const depthMap = new Map();
    const styleMap = new Map();
    const picker: IPicker = (node, { depth, parent, index: order }) => {
      let index;
      if (!colorContinuous) {
        if (!depthMap.has(depth)) {
          depthMap.set(depth, 0);
          index = 0;
        } else {
          index = depthMap.get(depth) + 1;
          depthMap.set(depth, index);
        }
      } else {
        index = order;
      }

      let style = {};
      // 处理叶子节点样式
      if (leaves && (!Array.isArray(node.children) || node.children.length === 0)) {
        style = merge(style, leaves);
      }

      if (levels) {
        const styles = array(levels[depth]);
        if (styles) {
          style = merge(style, cloneDeep(styles[index % styles.length]));
        }
      }

      let nodeColor;
      let nodeLabelColor;
      // levels > color
      if (colorMappingBy === 'node' || colorMappingBy === 'root') {
        _series.colorMappingBy = 'index';
        const _obj: any = {};
        if (color) {
          nodeColor = color[colorNext < color.length ? colorNext : (color.length - 1)];
          _obj.itemStyle = { color: nodeColor };
        }
        if (labelColor) {
          nodeLabelColor = labelColor[(colorNext < labelColor.length) ? colorNext : (labelColor.length - 1)];
          _obj.label = { color: nodeLabelColor };
        }
        style = merge(_obj, style);

        if (colorMappingBy === 'node') {
          if (!colorMappingByFrom || (colorMappingByFrom && depth >= colorMappingByFrom)) {
            colorNext += 1;
          }
        } else if (colorMappingBy === 'root' && depth === 0) {
          colorNext += 1;
        }
      }

      let _style: any = omitUndefined(getEachNode(
        style as any,
        {
          parentStyle: parent ? styleMap.get(parent) : null,
          commonStyle: {
            itemStyle,
            lineStyle,
          },
        },
      ));

      if (_style) {
        _style = Object.keys(_style as Record<string, any>).reduce((prev, next) => {
          if (next === 'children' || next === 'value' || next === 'name') {
            return prev;
          }
          const explicit = node[next];
          // 如果未显式声明
          if (!explicit && _style[next]) {
            prev[next] = _style[next];
            return prev;
          }
          if (typeof explicit !== 'object') {
            prev[next] = explicit;
            return prev;
          }
          prev[next] = merge({}, _style[next], explicit);
          return prev;
        }, {} as Record<string, any>);
        styleMap.set(node, _style);
      }

      const _node = { ...node };

      if (_node?.label?.formatter) {
        delete _node.label.formatter;
      }

      return merge(handleDataItems(_node), _style);
    };
    const iterator = iteratorGenerator(picker);
    _series.data = iterator(data as INode[], 0, null);
  }


  /**
   * 处理关系图时节点数据
   */
  if (iterateType === 'GRAPH' && matchers && matchers.length > 0) {
    const { nodes, links, edges } = _series;
    if (data) {
      _series.data = data.map((item: any) => merge({ ...item }, applyMatchers(matchers, item)));
    } else if (nodes) {
      _series.nodes = nodes.map((item: any) => merge({ ...item }, applyMatchers(matchers, item)));
    }
    if (links) {
      _series.links = links.map((item: any) => merge({ ...item }, applyMatchers(matchers, item)));
    } else if (edges) {
      _series.edges = edges.map((item: any) => merge({ ...item }, applyMatchers(matchers, item)));
    }
  }


  /**
   * line
   * 1. endLabel.formatter
   * 2. emphasis.endLabel.formatter
   */
  const { endLabel } = series;
  if (endLabel) {
    _series.endLabel = getLabel(endLabel);
  }
  if (emphasis?.endLabel) {
    set(_series, 'emphasis.endLabel', getLabel(emphasis.endLabel));
  }

  /**
   * bar
   */

  /**
   * TODO: 雷达图坐标系
   * radar
   * 1. axisName.label name, indicatorOpt
   */

  /**
   * gauge
   * 1. pointer.icon
   * 2. axisLabel.formatter
   * 3. detail.formatter
   */
  const { pointer, axisLabel, detail } = series;
  if (axisLabel) {
    _series.axisLabel = getLabel(axisLabel);
  }
  if (detail) {
    _series.detail = getLabel(detail);
  }

  /**
   * tree、treemap、sankey
   * 1. levels 还原
   * 2. levels[*].label.formatter
   * 3. levels[*].
   * 4. leaves.label.formatter 实现和强化
   */
  const { levels, leaves } = series;
  if (Array.isArray(levels) && levels.length > 0) {
    _series.levels = levels.map(lv => {
      if (Array.isArray(lv)) {
        if (lv.length > 0) {
          return lv[0];
        }
        return {};
      }
      const _lv = { ...lv };
      if (_lv.label?.formatter) {
        _lv.label.formatter = getFormatter(_lv.label.formatter);
      }
      return _lv;
    });
  }
  if (leaves?.label?.formatter) {
    set(_series, 'leaves.label.formatter', getFormatter(leaves.label.formatter));
  }

  /**
   * graph:
   * 1. 强化 category
   * 2. categories[*].label.formatter
   * 3. categories[*].blur.label.formatter
   * 4. categories[*].select.label.formatter
   * 3. edgeLabel.formatter.
   * 4.
   */
  const { edgeLabel, categories } = _series;
  if (edgeLabel?.formatter) {
    set(_series, 'edgeLabel.formatter', getFormatter(edgeLabel.formatter));
  }
  if (Array.isArray(categories)) {
    set(_series, 'categories', categories.map(category => {
      const { label, blur, select } = category;
      const _category = {...category};
      if (label?.formatter) {
        set(_category, 'label.formatter', getFormatter(label.formatter));
      }
      if (blur?.formatter) {
        set(_category, 'blur.category', getFormatter(blur.category));
      }
      if (select?.formatter) {
        set(_category, 'select.formatter', getFormatter(select.category));
      }
      return _category;
    }))
  }

  /**
   * wordCloud
   * 词云 文本颜色
   */
  if (type === 'wordCloud') {
    const {
      textStyle,
    } = series;
    if (textStyle?.color) {
      let color;
      if (Array.isArray(textStyle.color) || typeof textStyle.color === 'string') {
        const arr = array(textStyle.color);
        color = function ({ dataIndex }: any) {
          return arr[dataIndex % arr.length];
        };
      } else if (typeof textStyle.color === 'object') {
        color = jsonLogic.apply(textStyle.color);
      }
      _series.textStyle.color = color;
    }
  }

  return _series;
};

/**
 * 将 拓展后的 ECUnitOptionEx 转换为可用的 ECUnitOption
 * @param option
 */
export const convertECUnitOptionEx = (option: any) => {
  const _option: EChartsOption = { ...option };

  /**
   * 【处理配置项中的 formatter】
   * xAxis.axisLabel.formatter
   * xAxis.axisPointer.label.formatter
   * yAxis.axisLabel.formatter
   * yAxis.axisPointer.label.formatter
   * polar.tooltip.formatter
   * radiusAxis.axisLabel.formatter
   * radiusAxis.axisPointer.formatter
   * angleAxis.axisLabel.formatter
   * angleAxis.axisPointer.formatter
   * radar.axisName.formatter
   * radar.axisLabel.formatter
   * visualMap[*].formatter (continuous-value)
   * visualMap[*].formatter (piecewise-value, value1)
   * tooltip.formatter
   * axisPointer.label.formatter
   * geo.label.formatter
   * geo.emphasis.label.formatter
   * geo.regions[*].label.formatter
   * geo.regions[*].emphasis.label.formatter
   * geo.tooltip.formatter
   * parallel.parallelAxisDefault.axisLabel.formatter
   * parallelAxis.axisLabel.formatter
   * singleAxis.axisLabel.formatter
   * timeline.label.formatter (-value, index)
   * timeline.emphasis.label.formatter (-value, index)
   * timeline.progress.label.formatter (-value, index)
   * calendar.monthLabel.formatter
   * calendar.yearLabel.formatter
   */
  const {
    xAxis,
    yAxis,
    polar,
    radiusAxis,
    angleAxis,
    radar,
    visualMap,
    tooltip,
    axisPointer,
    geo,
    parallel,
    parallelAxis,
    singleAxis,
    timeline,
    calendar,
  } = option;
  const setAxisFormatter = (axisName: string, axis: any) => {
    if (!axis) return;
    if (!Array.isArray(axis)) {
      if (axis.axisLabel?.formatter) {
        set(_option, `${axisName}.axisLabel.formatter`, getFormatter(axis.axisLabel.formatter));
      }
      if (axis.axisPointer?.label?.formatter) {
        set(_option, `${axisName}.axisPointer.label.formatter`, getFormatter(axis.axisPointer.label.formatter));
      }
      return;
    }
    set(_option, `${axisName}`, axis.map(ax => {
      const _ax = { ...ax };
      if (_ax.axisLabel?.formatter) {
        set(_ax, `${axisName}.axisLabel.formatter`, getFormatter(_ax.axisLabel.formatter));
      }
      if (ax.axisPointer?.label?.formatter) {
        set(_ax, `${axisName}.axisPointer.label.formatter`, getFormatter(_ax.axisPointer.label.formatter));
      }
      return _ax;
    }));
  };
  setAxisFormatter('xAxis', xAxis);
  setAxisFormatter('yAxis', yAxis);
  setAxisFormatter('radiusAxis', radiusAxis);
  setAxisFormatter('angleAxis', angleAxis);
  if (polar?.tooltip?.formatter) {
    set(_option, 'tooltip.formatter', getFormatter(polar.tooltip.formatter));
  }
  if (radar) {
    if (radar.axisName?.formatter) {
      set(_option, 'radar.axisName.formatter', getFormatter(radar.axisName.formatter));
    }
    if (radar.axisLabel?.formatter) {
      set(_option, 'radar.axisLabel.formatter', getFormatter(radar.axisLabel.formatter));
    }
  }
  if (visualMap) {
    _option.visualMap = array(visualMap).map(vm => ({ ...vm, formatter: getFormatter(vm.formatter) }));
  }
  if (tooltip?.formatter) {
    set(_option, 'tooltip.formatter', getFormatter(tooltip.formatter));
  }
  if (axisPointer?.label?.formatter) {
    set(_option, 'axisPointer.label.formatter', getFormatter(axisPointer.label.formatter));
  }
  if (geo) {
    if (geo.label?.formatter) {
      set(_option, 'geo.label.formatter', getFormatter(geo.label.formatter));
    }
    if (geo.emphasis?.label.formatter) {
      set(_option, 'geo.emphasis.label.formatter', getFormatter(geo.emphasis.label.formatter));
    }
    if (geo.regions) {
      set(_option, 'geo.regions', array(geo.regions).map(region => {
        if (!region || region.label?.formatter || !region.emphasis?.label?.formatter) {
          return region;
        }
        const _region = ({ ...region });
        if (_region.label?.formatter) {
          set(_region, 'label.formatter', getFormatter(_region.label.formatter));
        }
        if (_region.emphasis?.label.formatter) {
          set(_region, 'emphasis.label.formatter', getFormatter(_region.emphasis.label.formatter));
        }
        return _region;
      }));
    }
    if (geo.tooltip) {
      set(_option, 'geo.tooltip.formatter', getFormatter(geo.tooltip.formatter));
    }
  }
  if (parallel?.parallelAxisDefault?.axisLabel?.formatter) {
    set(_option, 'parallel.parallelAxisDefault.axisLabel.formatter', parallel.parallelAxisDefault.axisLabel.formatter);
  }
  if (parallelAxis?.axisLabel?.formatter) {
    set(_option, 'parallelAxis.axisLabel.formatter', parallelAxis.axisLabel.formatter);
  }
  if (singleAxis?.axisLabel?.formatter) {
    set(_option, 'singleAxis.axisLabel.formatter', singleAxis.axisLabel.formatter);
  }
  if (timeline) {
    if (timeline.label?.formatter) {
      set(_option, 'timeline.label.formatter', timeline.label.formatter);
    }
    if (timeline.emphasis?.label?.formatter) {
      set(_option, 'timeline.emphasis.label.formatter', timeline.emphasis.label.formatter);
    }
    if (timeline.progress?.label?.formatter) {
      set(_option, 'timeline.progress.label.formatter', timeline.progress.label.formatter);
    }
    if (calendar) {
      if (calendar?.monthLabel?.formatter) {
        set(_option, 'calendar.monthLabel.formatter', calendar.monthLabel.formatter);
      }
      if (calendar?.yearLabel?.formatter) {
        set(_option, 'calendar.yearLabel.formatter', calendar.yearLabel.formatter);
      }
    }
  }


  /**
   * 【处理自定义属性】
   * legend.position
   * 如果未配置 legend: top、right、bottom、left、orient，并配置了 position，
   * 则按照 position 进行计算配置图例位置
   */
  const { legend } = option;
  const { position, top, right, bottom, left, orient } = legend ?? {};
  if (!top && !right && !bottom && !left && !orient && position) {
    const grid: Record<string, number | string> = {
      top: 'auto',
      right: 'auto',
      bottom: 'auto',
      left: 'auto',
      orient: 'horizontal',
    };
    switch (position) {
      case 'top':
      default:
        grid.top = 0;
        grid.left = 'center';
        break;
      case 'bottom':
        grid.bottom = 0;
        grid.left = 'center';
        break;
      case 'left':
        grid.left = 0;
        grid.top = 'middle';
        grid.orient = 'vertical';
        break;
      case 'right':
        grid.left = 0;
        grid.top = 'middle';
        grid.orient = 'vertical';
        break;
    }
    _option.legend = {
      ..._option.series,
      ...grid,
    };
  }


  /**
   * 处理 series 中的
   * **.formatter
   * category
   * levels
   * leaves
   * matchers
   * 等等
   */
  const {
    series,
  } = option;
  if (Array.isArray(series)) {
    _option.series = series.map((item, index) => convertSeries(item, index, option));
  }

  return _option as ECUnitOption;
};



