import React, { useRef, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { Button, Cascader, DatePicker } from 'antd';
import moment from 'moment';
import downIcon from '../../../../assets/images/tool/downIcon.png';
import smallDownIcon from '../../../../assets/images/tool/smallDownIcon.png';
import upIcon from '../../../../assets/images/tool/upIcon.png';
import IndustrySelect from '../components/IndustrySelect';
import Graph from './components/Graph';
import Tables from './components/Tables';
import {
  useGetAreaSelectBoxQuery,
  useGetSearchIndustryTreeQuery,
  useGetCompanyLabelQuery,
  useGetRegionalCapitalFlowChartQuery,
} from '../../../../service/knowledgeCompoent';
import { getExportRegionalCapitalFlowChart } from '../../../../api/knowledgeComponents';
import {
  generateRegionTree,
  addAllOption,
} from '../IndustryChainAdvantageEnterprise';
import pageStyles from './index.module.less';
import styles from '../index.module.less';

const { RangePicker } = DatePicker;
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() 返回的月份是从0开始的，所以需要+1
  const day = date.getDate();
  return `${year}年${month}月${day}日`;
};

const generateDate = (dateList) => {
  let str = '';
  dateList.forEach((item, index) => {
    const subStr = formatDate(item);
    if (index === 0) {
      str += `${subStr}至`;
    } else {
      str += `${subStr}，`;
    }
  });
  return str;
};

const outOptions = [
  {
    key: 'in',
    value: '流入数据',
  },
  {
    key: 'out',
    value: '流出数据',
  },
];

export default function RegionCapitalTrend({ box }) {
  const graphRef = useRef(null);
  const dateFormat = 'YYYY-MM-DD';
  const currentDate = moment();
  const lastYear = moment().clone().subtract(1, 'years');
  const [selectIndustry, setSelectIndustry] = useState({});
  const [industryVisible, setIndustryVisible] = useState(false);
  const [currentAreaValue, setCurrentAreaValue] = useState(['110000']);
  const [areaList, setAreaList] = useState([]);
  const [selectArea, setSelectArea] = useState({
    value: '110000',
    title: '北京市',
    label: '北京市',
  });
  const [selectTags, setSelectTags] = useState(['不限']);
  const [selectTime, setSelectTime] = useState([lastYear, currentDate]);
  const [selectDateList, setSelectDateList] = useState([lastYear.format(dateFormat), currentDate.format(dateFormat)]);
  const [searchParams, setSearchParams] = useState({
    area_key: '110000',
    area_name: '北京市',
    industry_code: '',
    industry_name: '',
    special_tag_list: [],
    time_range_list: [lastYear.format(dateFormat), currentDate.format(dateFormat)],
  });
  const [outListShow, setOutListShow] = useState(false);
  const [selectOut, setSelectOut] = useState();
  const [tagList, setTagList] = useState([]);
  const [defaultList, setDefaultList] = useState([]);
  const { data: regionTree } = useGetAreaSelectBoxQuery();
  const { data: industryList } = useGetSearchIndustryTreeQuery();
  const { data: companyLabelList } = useGetCompanyLabelQuery();

  const { data, isFetching } = useGetRegionalCapitalFlowChartQuery({
    area_key: searchParams.area_key,
    industry_code: searchParams.industry_code,
    special_tag_list: searchParams.special_tag_list.findIndex(i => i === '不限') > -1 ? [] : searchParams.special_tag_list,
    time_range_list: searchParams.time_range_list,
  }, {
    skip: !searchParams,
  });

  // 区域选择变化
  const onCascaderChange = (value, selectOptions) => {
    setCurrentAreaValue(value);
    setSelectArea(selectOptions[selectOptions.length - 1]);
  };
  // 点击企业标签
  const handleTagClick = (node) => {
    let currentTags = [...selectTags];
    const index = currentTags.findIndex(i => i === node);
    if (selectTags.length === 0) {
      currentTags = ['不限'];
    } else if (node === '不限') {
      currentTags = ['不限'];
    } else if (currentTags.findIndex(i => i === '不限') > -1) {
      currentTags.splice(currentTags.findIndex(i => i === '不限'), 1);
      currentTags.push(node);
    } else if (index > -1 && currentTags.length === 1) {
      currentTags = ['不限'];
    } else if (index > -1) {
      currentTags.splice(index, 1);
    } else {
      currentTags.push(node);
    }
    setSelectTags(currentTags);
  };

  useEffect(() => {
    if (regionTree) {
      const tree = addAllOption(generateRegionTree(regionTree[0].children));
      // console.log(addAllOption(tree));
      setAreaList(tree);
    }
  }, [regionTree]);

  useEffect(() => {
    if (companyLabelList) {
      setTagList([
        {
          key: '不限',
          title: '不限',
        },
        ...companyLabelList,
      ]);
      const list = [];
      companyLabelList.forEach((item, index) => {
        if (index < 5 || index === companyLabelList.length - 1) {
          list.push(item.value);
        }
      });
      setDefaultList(list);
      setSelectTags(list);
      setSearchParams((prev) => ({
        ...prev,
        special_tag_list: list,
      }));
    }
  }, [companyLabelList]);

  const handleDownloadData = (key) => {
    const filename = `${selectArea?.label ? selectArea.title : '全国'}${selectIndustry?.title ? selectIndustry.title : ''}投资流向分析`;
    setSelectOut(key);
    setOutListShow(false);
    getExportRegionalCapitalFlowChart({
      area_key: selectArea?.value,
      industry_code: selectIndustry?.value || '',
      special_tag_list: selectTags.findIndex(i => i === '不限') > -1 ? [] : selectTags,
      time_range_list: selectDateList,
      flow_direction: key,
    }, filename);
  };

  // 点击重置
  const handleReset = () => {
    const setParams = throttle(() => {
      setSelectIndustry({});
      setSelectArea({
        value: '110000',
        title: '北京市',
        label: '北京市',
      });
      setCurrentAreaValue(['110000']);
      setSelectTags(defaultList);
      setSelectTime(['', '']);
      setSearchParams({
        area_key: '110000',
        area_name: '北京市',
        industry_code: '',
        industry_name: '',
        special_tag_list: defaultList,
        time_range_list: ['2010-01-01', '2010-12-31'],
      });
    }, 200);
    setParams();
  };

  // 点击查询
  const handleSearch = () => {
    const setParams = throttle(() => {
      setSearchParams({
        area_key: selectArea?.value,
        area_name: selectArea?.title,
        industry_code: selectIndustry?.value || '',
        industry_name: selectIndustry?.title || '',
        special_tag_list: selectTags.findIndex(i => i === '不限') > -1 ? [] : selectTags,
        time_range_list: selectDateList,
      });
    }, 200);
    setParams();
  };

  const disabledDate = (_current) => {
    const current = _current.format('YYYY-MM-DD');
    return current && current > moment().endOf('day').format('YYYY-MM-DD');
  };

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.left}>投资流向分析</div>
        <div className={styles.right}>
          <div className={styles.outBtn}>
            <div className={styles.top} onClick={() => setOutListShow(prev => !prev)}>
              <div className={classNames(styles.text, { [styles.upText]: outListShow })}>导出数据</div>
              <img src={outListShow ? upIcon : smallDownIcon} alt="" />
            </div>
            {
              outListShow && (
                <div className={styles.downBox}>
                  {
                    outOptions && outOptions.map(item => (
                      <div
                        className={classNames(styles.option, { [styles.selectOption]: selectOut === item.key })}
                        key={item.key}
                        onClick={() => handleDownloadData(item.key)}
                      >
                        {item.value}
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>
          <Button
            className={styles.primaryBtn}
            onClick={() => {
              const { download } = graphRef.current;
              download();
            }}
          >
            图片下载
          </Button>
        </div>
      </div>
      <div className={styles.filterBox}>
        <div className={styles.leftRow}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>区域选择</div>
              <Cascader
                showSearch
                options={areaList}
                value={currentAreaValue}
                // changeOnSelect={true}
                getPopupContainer={() => box?.current}
                onChange={onCascaderChange}
              />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>产业选择</div>
              <div className={styles.select} onClick={() => { setIndustryVisible(prev => !prev); }}>
                <div className={styles.text}>{selectIndustry.title || '请选择产业'}</div>
                <img className={classNames(styles.downIcon, { [styles.upIcon]: industryVisible })} src={downIcon} alt="" />
                {
                  industryVisible && (
                    <div className={styles.downBox}>
                      <IndustrySelect
                        ifCancel
                        data={industryList?.children}
                        select={selectIndustry}
                        setSelect={setSelectIndustry}
                      />
                    </div>
                  )
                }
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.label}>时间范围</div>
              <RangePicker
                value={selectTime}
                disabledDate={disabledDate}
                onChange={(value, dateString) => {
                  setSelectTime(value);
                  setSelectDateList(dateString);
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.label}>企业标签</div>
              <div className={styles.tagList}>
                {
                  tagList?.length ? tagList.map(item => (
                    <div
                      key={item.key}
                      className={classNames(styles.tagItem, { [styles.selectedItem]: selectTags.includes(item.value) })}
                      onClick={() => handleTagClick(item.key)}
                    >
                      {item.title}
                    </div>
                  )) : ''
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightRow}>
          <div className={styles.btnBox}>
            <Button type="primary" onClick={handleSearch}>查询</Button>
            <Button type="default" onClick={handleReset}>重置</Button>
          </div>
        </div>
      </div>
      <div className={styles.graphBox}>
        <div className={styles.describe}>
          {
            searchParams?.time_range_list?.length > 0 ? generateDate(searchParams.time_range_list) : ''
          }
          {
            searchParams?.area_name ? searchParams.area_name : ''
          }
          {searchParams?.industry_name}
          发生资本流出企业
          <span className={styles.num}>{data?.pool?.流出企业数量?.toLocaleString()}</span>
          家，其中流出规模最大的企业是
          <span className={styles.num}>{data?.pool?.流出最大规模企业?.toLocaleString()}</span>
          ，流出最大规模
          <span className={styles.num}>{data?.pool?.流出最大规模?.toLocaleString()}</span>
          万元。发生资本流入企业
          <span className={styles.num}>{data?.pool?.流入企业数量?.toLocaleString()}</span>
          家，流入最大规模
          <span className={styles.num}>{data?.pool?.流入最大规模?.toLocaleString()}</span>
          万元，来自
          <span className={styles.num}>{data?.pool?.流入最大规模企业?.toLocaleString()}</span>
          。
        </div>
        <div className={styles.releationGraph}>
          {
            useMemo(() => (
              <Graph
                data={data?.series?.[0] || {}}
                // useDataColors
                selectIndustryName={searchParams.industry_name}
                selectAreaName={searchParams.area_name}
                isFetching={isFetching}
                ref={graphRef}
              />
            ), [searchParams, data, isFetching, graphRef])
          }
          <div className={pageStyles.legendBox}>
            <div className={pageStyles.left}>
              <div className={pageStyles.inLegend}>
                资本流入
              </div>
              <div className={pageStyles.outLegend}>
                资本流出
              </div>
            </div>
            <div className={styles.right}>数据来源：上奇产业通</div>
          </div>
        </div>
        <Tables
          searchParams={searchParams}
        />
      </div>
    </div>
  );
}
