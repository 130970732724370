/**
 * 点击时的逻辑：
 * 1. 如果受控，则不做任何响应；
 * 2. 如果不受控，则更新内部的 selectedItem；
 * @param graph
 * @param proxyInstance
 */

export default function registerEvent(graph, proxyInstance) {
  // —————————————— 绑定按钮事件 ————————————

  // 处理收起/展开 (click)
  const handleCollapse = e => {
    const { target } = e;
    const id = target.get('modelId');
    const item = graph.findById(id);
    if (!item) return;
    const nodeModel = item.getModel();
    nodeModel.collapsed = !nodeModel.collapsed;
    // graph.fitView();
    graph.layout();
    graph.setItemState(item, 'collapsed', nodeModel.collapsed);
  };

  graph.on('collapse-text:click', (e) => {
    handleCollapse(e);
  });
  graph.on('collapse-text:touchstart', (e) => {
    handleCollapse(e);
  });
  graph.on('collapse-back:click', (e) => {
    handleCollapse(e);
  });
  graph.on('collapse-back:touchstart', (e) => {
    handleCollapse(e);
  });
  graph.on('collapse-minus:click', (e) => {
    handleCollapse(e);
  });
  graph.on('collapse-minus:touchstart', (e) => {
    handleCollapse(e);
  });

  // 处理 hover
  let hoveredButtonItem = null;
  const handleButtonHover = (e, isHover) => {
    const { target } = e;
    const id = target.get('modelId');
    if (hoveredButtonItem && hoveredButtonItem._cfg && hoveredButtonItem._cfg.id !== id) {
      graph.setItemState(hoveredButtonItem, 'button:hover', false);
    }
    const item = graph.findById(id);
    if (!item) return;
    graph.setItemState(item, 'button:hover', !!isHover);
    if (isHover) {
      hoveredButtonItem = item;
    } else {
      hoveredButtonItem = null;
    }
  };

  // 按钮样式
  graph.on('collapse-back:mouseenter', (e) => {
    handleButtonHover(e, true);
  });
  graph.on('collapse-text:mouseenter', (e) => {
    handleButtonHover(e, true);
  });
  graph.on('collapse-minus:mouseenter', (e) => {
    handleButtonHover(e, true);
  });
  graph.on('collapse-back:mouseout', (e) => {
    handleButtonHover(e, false);
  });
  graph.on('collapse-text:mouseout', (e) => {
    handleButtonHover(e, false);
  });
  graph.on('collapse-minus:mouseout', (e) => {
    handleButtonHover(e, true);
  });

  // 处理 active
  let buttonActiveItem = null;
  const handleButtonActive = (e, isActive) => {
    const { target } = e;
    const id = target.get('modelId');
    if (buttonActiveItem && buttonActiveItem._cfg && buttonActiveItem._cfg.id !== id) {
      graph.setItemState(buttonActiveItem, 'button:active', false);
    }
    const item = graph.findById(id);
    if (!item) return;
    graph.setItemState(item, 'button:active', !!isActive);
    if (isActive) {
      buttonActiveItem = item;
    } else {
      buttonActiveItem = null;
    }
  };

  graph.on('collapse-text:mousedown', (e) => {
    handleButtonActive(e, true);
  });
  graph.on('collapse-back:mousedown', (e) => {
    handleButtonActive(e, true);
  });
  graph.on('collapse-minus:mousedown', (e) => {
    handleButtonActive(e, true);
  });
  graph.on('collapse-text:mouseup', (e) => {
    handleButtonActive(e, false);
  });
  graph.on('collapse-back:mouseup', (e) => {
    handleButtonActive(e, false);
  });
  graph.on('collapse-minus:mouseup', (e) => {
    handleButtonActive(e, false);
  });
  graph.on('canvas:mouseup', e => {
    handleButtonActive(e, false);
  });

  // —————————————— 绑定节点事件 ————————————
  let selectedItem = null;
  const handleNodeClick = e => {
    const { target } = e;
    const id = target.get('modelId');
    if (selectedItem && selectedItem._cfg && selectedItem._cfg.id !== id) {
      const selectedModel = selectedItem.getModel();
      selectedModel.selected = !selectedModel.selected;
      graph.setItemState(selectedItem, 'selected', selectedModel.selected);
    }
    const item = graph.findById(id);
    if (!item) return null;
    const nodeModel = item.getModel();
    // 如果外部定义代理事件处理器，则对其进行调用
    if (proxyInstance) {
      const onClick = proxyInstance.get('onClick');
      if (onClick) {
        onClick(nodeModel);
      }
    }
    if (nodeModel.disabled) return null;
    if (proxyInstance?.has('selectedId')) return null;
    // 如果当前节点为选中节点，那么取消选中；
    // 如果当前无选中节点，那么选中
    // 如果当前选中节点非点击的节点，那么取消之前的选中，选中当前的节点
    nodeModel.selected = !nodeModel.selected;
    graph.setItemState(item, 'selected', nodeModel.selected);
    if (nodeModel.selected) {
      selectedItem = item;
    } else {
      selectedItem = null;
    }
    return null;
  };

  graph.on('node-name:click', e => {
    handleNodeClick(e);
  });
  graph.on('node-name:touchstart', e => {
    handleNodeClick(e);
  });
  graph.on('node-rect:click', e => {
    handleNodeClick(e);
  });
  graph.on('node-name:touchstart', e => {
    handleNodeClick(e);
  });

  // —————————————— 绑定节点 hover ——————————————

  let hoveredItem = null;
  const handleNodeHover = (e, isHover) => {
    const { target } = e;
    const id = target.get('modelId');
    if (hoveredItem && hoveredItem._cfg && hoveredItem._cfg.id !== id) {
      graph.setItemState(hoveredItem, 'hover', false);
    }
    const item = graph.findById(id);
    if (!item) return null;
    const nodeModel = item.getModel();
    if (nodeModel.disabled) return null;
    graph.setItemState(item, 'hover', !!isHover);
    if (isHover) {
      hoveredItem = item;
    } else {
      hoveredItem = null;
    }
    return null;
  };

  graph.on('node-name:mouseenter', e => {
    handleNodeHover(e, true);
  });
  graph.on('node-rect:mouseenter', e => {
    handleNodeHover(e, true);
  });
  graph.on('node-name:mouseleave', e => {
    handleNodeHover(e, false);
  });
  graph.on('node-rect:mouseleave', e => {
    handleNodeHover(e, false);
  });

  // —————————————— 绑定节点 active  ——————————————

  let activeItem = null;
  const handleNodeActive = (e, isActive) => {
    const { target } = e;
    const id = target.get('modelId');
    if (activeItem && activeItem._cfg && activeItem._cfg.id !== id) {
      graph.setItemState(activeItem, 'active', false);
    }
    const item = graph.findById(id);
    if (!item) return null;
    const nodeModel = item.getModel();
    if (nodeModel.disabled) return null;
    // 如果当前节点为选中节点，那么取消选中；
    // 如果当前无选中节点，那么选中
    // 如果当前选中节点非点击的节点，那么取消之前的选中，选中当前的节点
    graph.setItemState(item, 'active', !!isActive);
    if (isActive) {
      activeItem = item;
    } else {
      activeItem = null;
    }
    return null;
  };

  graph.on('node-name:mousedown', e => {
    handleNodeActive(e, true);
  });
  graph.on('node-rect:mousedown', e => {
    handleNodeActive(e, true);
  });
  graph.on('node-name:mouseup', e => {
    handleNodeActive(e, false);
  });
  graph.on('node-rect:mouseup', e => {
    handleNodeActive(e, false);
  });
  graph.on('canvas:mouseup', e => {
    handleNodeActive(e, false);
  });

  // 调用外部视图变化处理函数
  graph.on('viewportchange', e => {
    if (proxyInstance) {
      const onViewportChange = proxyInstance.get('onViewportChange');
      if (onViewportChange) {
        onViewportChange(e);
      }
    }
  });
}
