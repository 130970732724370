const paramListMap = {
  proprietaryIntellectualPropertyRightsParams: ['software_copyright', 'invent_patent', 'practical_patent', 'design_patent', 'company_id'],
  establishmentPeriodParams: ['company_id', 'time'],
  patentsGrossParam: ['invent_patent', 'practical_patent', 'design_patent', 'company_id'],
};

const generateParam = ({ paramName, companyInfo }) => {
  if (!companyInfo) return null;
  const paramObj = {};
  paramListMap[paramName].forEach(item => {
    if (Object.keys(companyInfo).includes(item)) {
      paramObj[item] = companyInfo[item];
    }
  });
  return paramObj;
};

// const terrData = [
//   {
//     name: '北京市通州区人民政府',
//     depth: 0,
//     value: 2020,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市东城区人民政府',
//     depth: 0,
//     value: 1729,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市怀柔区人民政府',
//     depth: 0,
//     value: 1393,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市平谷区人民政府',
//     depth: 0,
//     value: 1351,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市经济和信息化局',
//     depth: 0,
//     value: 1311,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市海淀区人民政府',
//     depth: 0,
//     value: 1213,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市石景山区人民政府',
//     depth: 0,
//     value: 1199,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市科学技术委员会',
//     depth: 0,
//     value: 1156,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市大兴区人民政府',
//     depth: 0,
//     value: 1149,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市延庆区人民政府',
//     depth: 0,
//     value: 1029,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市投资促进服务中心',
//     depth: 0,
//     value: 1001,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市顺义区人民政府',
//     depth: 0,
//     value: 993,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市人力资源和社会保障局',
//     depth: 0,
//     value: 983,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市发展和改革委员会',
//     depth: 0,
//     value: 916,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市商务局',
//     depth: 0,
//     value: 830,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市房山区人民政府',
//     depth: 0,
//     value: 640,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市朝阳区人民政府',
//     depth: 0,
//     value: 635,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市财政局',
//     depth: 0,
//     value: 575,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市人民政府',
//     depth: 0,
//     value: 451,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市丰台区人民政府',
//     depth: 0,
//     value: 442,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市昌平区人民政府',
//     depth: 0,
//     value: 227,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市门头沟区人民政府',
//     depth: 0,
//     value: 190,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市西城区人民政府',
//     depth: 0,
//     value: 180,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京经济技术开发区',
//     depth: 0,
//     value: 103,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '中关村科技园区管理委员会',
//     depth: 0,
//     value: 73,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京经济技术开发区管理委员会',
//     depth: 0,
//     value: 31,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市科学技术委员会、中关村科技园区管理委员会',
//     depth: 0,
//     value: 28,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '中关村国家自主创新示范区',
//     depth: 0,
//     value: 20,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '北京市广播电视局',
//     depth: 0,
//     value: 20,
//     unit: '条',
//     children: [],
//   },
//   {
//     name: '国务院/中央人民政府',
//     depth: 0,
//     value: 3,
//     unit: '条',
//     children: [],
//   },
// ];

export {
  generateParam,
  // terrData,
};
