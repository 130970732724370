/*
 *Author: zhaochenyu
 *Description: 公司详情内容部分
 *Date: 2021/05/11
*/

import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import { UpOutlined } from '@ant-design/icons';

// 经济运行
import EconomicData from './EconomicOperation/EconomicData';
import Develop from './EconomicOperation/Develop';
// 产业分析
import IndustryOverview from './IndustryAnalysis/IndustryOverview';
import CityBenchmarking from './IndustryAnalysis/CityBenchmarking';
import Overview from './IndustryAnalysis/IndustryMapScheme';
import IndustrialDistribution from './IndustrialDistribution';
import EquityFinancing from './EquityFinancing';
import InnovationAbility from './InnovationAbility';
// 园区载体
import ParkCarrier from './ParkCarrier';
// 政策清单
import PolicyList from './PolicyList';
// 报告
import ReportScheme from './ReportScheme';
import style from './index.module.less';
import { useGetCityChannelStatisticQuery } from '../../../service/city-channel';

const orderTab = [
  '经济运行',
  '产业分析',
  '园区载体',
  '政策清单',
  '研究报告',
];

const CityContent = forwardRef(((props, ref) => {
  const { fixHeight, tabs, box, isProvinceDirectly, code, cityName } = props;
  const [boxScroll, setBoxScroll] = useState(false);
  const [selectAchor, setAchor] = useState('经济运行');
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [tabBottom, setTabBottom] = useState(false);
  const positionRef = useRef([]); // 位置数组
  const timer = useRef(null);
  const timer1 = useRef(null);
  const timer2 = useRef(null);
  const topRef = useRef(null);
  const mainRef = useRef();
  const achorPush = useCallback((node) => {
    const achorName = document.querySelector(`#${node}`);
    if (achorName) {
      box?.current.scrollTo({
        top: achorName.offsetTop - 132,
        behavior: 'smooth',
      });
    }
  }, []);
  useImperativeHandle(ref, () => ({
    contentAnchorPush(node) {
      achorPush(node);
    },
  }));

  useEffect(() => {
    topRef.current = fixHeight;
  }, [fixHeight]);

  const changeAchor = useCallback(() => {
    const scroll = box?.current.scrollTop + 132;
    const len = positionRef.current.length;
    for (let i = 0; i < len; i += 1) {
      if (i === (len - 1) && (positionRef.current[i] <= scroll)) {
        setAchor(orderTab[i]);
        break;
      } else if ((positionRef.current[i] <= scroll)
          && (positionRef.current[i + 1] > scroll)) {
        setAchor(orderTab[i]);
        break;
      } else if ((scroll < positionRef.current[0])) {
        setAchor(orderTab[0]);
        break;
      }
    }
  }, []);

  const scrollLisener = useCallback(() => {
    if (!timer.current) {
      timer.current = setTimeout(() => {
        setBoxScroll(box.current.scrollTop > document.body.clientHeight * 2);
        changeAchor();
        timer.current = null;
      }, 50);
    }
  }, []);

  const scrollToTop = () => {
    box.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getCurPostion = () => {
    const arr = [];
    orderTab.forEach(item => {
      arr.push(document.getElementById(item)?.offsetTop);
    });
    positionRef.current = arr;
    // 高度变化后重新计算当前位置所在区间
    changeAchor();
  };

  const heightListener = useCallback(
    () => {
      if (timer1.current) {
        clearTimeout(timer1.current);
        timer1.current = null;
      }
      timer1.current = setTimeout(() => {
        getCurPostion();
      }, 50);
    },
    [],
  );

  useEffect(() => {
    changeAchor();
    // 监听页面高度变化重新计算锚点高度
    const resizeObserver = new ResizeObserver(heightListener);
    resizeObserver.observe(mainRef.current);
    // 监听页面滚动事件，改变锚点选中状态
    box?.current.addEventListener('scroll', scrollLisener);
    return () => {
      box?.current.removeEventListener('scroll', scrollLisener);
      // resizeObserver.unobserve(mainRef?.current);
      clearTimeout(timer.current);
      clearTimeout(timer1.current);
      clearTimeout(timer2.current);
    };
  }, []);
  const enterTab = useCallback(
    () => {
      if (!timer2.current) {
        timer2.current = setTimeout(() => {
          setTabBottom(true);
          timer2.current = null;
        }, 500);
      }
    },
    [],
  );

  const { data: dataTabs = [] } = useGetCityChannelStatisticQuery({
    area_key: code,
  }, {
    skip: !code,
  });
  return (
    <div className={style.all} ref={mainRef} id="cityAchorTab">
      <div
        className={style.achorTab}
        style={{ position: 'sticky', top: '70px', background: '#fff', zIndex: 100, marginTop: '20px' }}
      >
        <div
          style={{ display: 'flex' }}
          onFocus={() => {}}
          onMouseOver={enterTab}
          onMouseLeave={() => {
            clearTimeout(timer2.current);
            timer2.current = null;
            setTabBottom(false);
          }}
        >
          {orderTab.map((item, index) => (
            <div
              key={`${item}${index}`}
              className={style.achorBorder}
              onClick={() => {
                achorPush(item);
                setTabBottom(false);
              }}
              onFocus={() => {
              }}
              onMouseOver={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(-1)}
            >
              <div className={selectAchor === item ? style.selectAchor : style.achorItem}>
                {item}
              </div>
            </div>
          ))}
          <div
            className={style.bottomTab}
            style={{ display: tabBottom ? '' : 'none' }}
          >
            {orderTab.map((item, index) => (
              <div
                key={`${index}tabItem`}
                className={style.tabItem}
                onFocus={() => {}}
                onMouseOver={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(-1)}
                style={{ background: hoverIndex === index ? '#F9FAFB' : '#fff' }}
              >
                {tabs[item]?.map((item1, index1) => (
                  <div
                    className={style.tabItemItem}
                    key={`${index1}tabItemItem${item1.name}`}
                    onClick={() => {
                      if (item1.name) {
                        achorPush(item1.name?.split('(')?.[0]);
                        setTabBottom(false);
                      }
                    }}
                  >
                    {item1.name}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.outside} id="经济运行">
        {tabs['经济运行'].find(item => item.name.indexOf('经济数据') !== -1) && <EconomicData title="经济数据" cityCode={code} />}
        {tabs['经济运行'].find(item => item.name.indexOf('社会发展') !== -1) && <Develop title="社会发展" cityCode={code} />}
      </div>
      <div className={style.outside} id="产业分析">
        {tabs['产业分析'].find(item => item.name.indexOf('产业概览') !== -1) && <IndustryOverview isProvinceDirectly={isProvinceDirectly} title="产业概览" cityCode={code} total={dataTabs?.[0]?.value} />}
        {tabs['产业分析'].find(item => item.name.indexOf('城市对标') !== -1) && <CityBenchmarking title="城市对标" cityCode={code} />}
        {tabs['产业分析'].find(item => item.name.indexOf('区域分布') !== -1) && <Overview title="区域分布" cityCode={code} cityName={cityName} />}
        {tabs['产业分析'].find(item => item.name.indexOf('产业分布') !== -1) && <IndustrialDistribution title="产业分布" cityCode={code} cityName={cityName} />}
        {tabs['产业分析'].find(item => item.name.indexOf('股权融资') !== -1) && <EquityFinancing title="股权融资" cityCode={code} total={dataTabs?.[2]?.value} />}
        {tabs['产业分析'].find(item => item.name.indexOf('创新能力') !== -1) && <InnovationAbility isProvinceDirectly={isProvinceDirectly} title="创新能力" cityCode={code} patentTotal={dataTabs?.[1]?.value} unitTotal={dataTabs?.[4]?.value} />}
      </div>
      <div className={style.outside} id="园区载体">
        {tabs['园区载体'].find(item => item.name.indexOf('园区载体') !== -1) && <ParkCarrier title="园区载体" cityCode={code} />}
      </div>
      <div className={style.outside} id="政策清单">
        {tabs['政策清单'].find(item => item.name.indexOf('政策清单') !== -1) && <PolicyList title="政策清单" cityCode={code} />}
      </div>
      <div className={style.outside} id="研究报告">
        {tabs['研究报告'].find(item => item.name.indexOf('研究报告') !== -1) && <ReportScheme title="研究报告" cityCode={code} cityName={cityName} />}
      </div>
      <div className={style.scrollTop} title="返回顶部" style={{ display: boxScroll ? '' : 'none' }} onClick={() => scrollToTop()}>
        <UpOutlined />
      </div>
    </div>
  );
}));

export default CityContent;
