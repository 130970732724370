import React, {
  useEffect,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import G6 from '@antv/g6';
import './register';
import { dataTransform, findNode, findNodes } from './utils';
import registerEvent from './model';
import TREE from './mock';
import styles from './index.module.less';
// 水印图片
import backPng from './background.png';

/**
 * @typedef {Object} TNode
 * @property {string} id
 * @property {number} depth
 * @property {string} name
 * @property {number} value
 * @property {boolean} [disabled]
 * @property {TNode[]} children
 */

/**
 * @typedef {Object} NodeStyle
 * @property {number} [fontSize]
 * @property {string} [color]
 * @property {string} [backgroundColor]
 * @property {string} [borderColor]
 * @property {string} [borderWidth]
 * @property {string} [borderRadius]
 * @property {string} [shadowColor]
 * @property {number} [shadowBlur]
 * @property {number} [shadowOffsetX]
 * @property {number} [shadowOffsetY]
 */

/**
 * @typedef {Object} TrailingStyle
 * @property {number} [borderWidth]
 * @property {string} [borderColor]
 * @property {string} [color]
 */

/**
 * @type {NodeStyle[]}
 */
const NODE_STYLES = [
  {
    fontSize: 16,
    color: '#fff',
    borderWidth: 1,
    borderColor: '#fff',
    borderRadius: 4,
    backgroundColor: '#0646c9',
    shadowOffsetX: 2,
    shadowOffsetY: 2,
    shadowBlur: 12,
    shadowColor: '#8FB2E9',
  },
  {
    fontSize: 16,
    color: '#fff',
    borderWidth: 1,
    borderColor: '#fff',
    borderRadius: 4,
    backgroundColor: '#0053FF',
  },
  {
    fontSize: 16,
    color: '#333',
    borderWidth: 1,
    borderColor: '#1961F5',
    borderRadius: 4,
    backgroundColor: '#fff',
  },
];

/**
 * @type {NodeStyle}
 */
const DISABLED_STYLE = {
  fontSize: 16,
  color: '#969696',
  borderWidth: 1,
  borderColor: '#DCDCDC',
  borderRadius: 4,
  backgroundColor: '#E6E6E6',
};

// 默认的鼠标悬停会加粗,边框颜色改变
const defaultStateStyles = {
  hover: {
    fill: '#ccc',
    color: 'red',
  },
};

const LIMIT_TREE_DEPTH = 2;

class EventProxy {
  constructor(props) {
    this.props = props ?? {};
  }

  setProps(props) {
    if (typeof props !== 'object') return null;
    this.props = { ...this.props, ...props };
    return this;
  }

  set(key, value) {
    this.props[key] = value;
    return this;
  }

  get(key) {
    return this.props[key];
  }

  has(key) {
    return Object.hasOwn(this.props, key);
  }
}

/**
 * # 树图
 * 1. 初始状态，默认展开3级，定位到对应位置
 * 2. 产业链环节无企业则显示灰色
 * 3. 点击产业链环节具有交互
 * 4. 3级开始，才可以收起产业链环节，鼠标经过环节时，显示收起按钮
 * 5. 点击“全展开"展开所有环节，点击“收起”按钮，收起到3级环节
 * @param {{
 *   data: TNode;
 *   onClick?: Function;
 *   onViewportChange?: Function;
 *   nodeStyles?: NodeStyle;
 *   trailingStyle?: TrailingStyle;
 *   selectedId?: string;
 *   onInit?;
 *   getName?;
 *   getValue?;
 *   getId?;
 *   getDisabled?;
 *   config?: {padding?: number[]};
 *   disableZoom?: boolean;
 *   disabled?: boolean 置灰是否可点击
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function Tree(props, ref) {
  const {
    // data = TREE,
    data,
    onInit,
    config,
    nodeStyles = NODE_STYLES,
    trailingStyle,
    disabledStyle = DISABLED_STYLE,
    selectedId,
    getName = node => node.name,
    getValue = node => node.value,
    getId = node => node.id,
    getDisabled = node => node.disabled,
    disableZoom,
    minZoom,
    maxZoom,
    name,
    disabled = true,
  } = props;
  const [graph, setGraph] = useState();
  const proxyInstanceRef = useRef(new EventProxy(props));
  const elRef = useRef();
  const { padding = [0, 0, 0, 0] } = config ?? {};

  // useLayoutEffect(() => {
  //   const canvas = document.getElementById('measure-canvas');
  //   if (!canvas) {
  //     const _canvas = document.createElement('canvas');
  //     _canvas.id = 'measure-canvas';
  //     _canvas.width = 0;
  //     _canvas.height = 0;
  //     _canvas.style.position = 'absolute';
  //     _canvas.style.bottom = '0';
  //     _canvas.style.left = '0';
  //     _canvas.style.visibility = 'none';
  //     document.body.append(_canvas);
  //     // const ctx = _canvas.getContext('2d');
  //     return () => {
  //       _canvas.remove();
  //     };
  //   }
  //   return null;
  // }, []);

  useEffect(() => {
    if (!data) return null;
    const ctx = document.createElement('canvas').getContext('2d');
    const _data = dataTransform(
      data,
      ctx,
      {
        nodeStyles,
        trailingStyle,
        disabledStyle,
        getName,
        getValue,
        getId,
        getDisabled,
      },
    );
    const { scrollWidth, scrollHeight } = elRef.current;
    const width = scrollWidth;
    const height = scrollHeight || 500;
    // const fitView = graph.fitView(0, { onlyOutOfViewPort: true, direction: 'y' });

    // 默认配置
    const defaultConfig = {
      width,
      height,
      minZoom,
      maxZoom,
      modes: {
        default: [
          'drag-canvas',
          !disableZoom ? 'zoom-canvas' : null,
        ].filter(Boolean),
      },
      // fitView: true,
      // fitCenter: true,
      animate: true,
      defaultNode: {
        type: 'rounded-rect',
        style: {},
        labelCfg: {},
      },
      defaultEdge: {
        type: 'cubic-horizontal',
        style: {
          stroke: '#0060FE',
          lineWidth: 2,
        },
      },
      layout: {
        type: 'compactBox',
        direction: 'LR',
        getId: d => d.id,
        getHeight: function getHeight(d) {
          return d.height + 8;
        },
        getWidth: function getWidth(d) {
          return 160 + 20;
        },
        getVGap: function getVGap(d) {
          return 0;
        },
        getHGap: function getHGap() {
          return 20;
        },
      },
    };

    const initGraph = source => {
      // 如果没有数据，则不进行处理
      if (!source) {
        return null;
      }
      const tooltip = new G6.Tooltip({
        // offsetX and offsetY include the padding of the parent container
        offsetX: 20,
        offsetY: 30,
        // 允许出现 tooltip 的 item 类型
        itemTypes: ['node'],
        // 自定义 tooltip 内容
        getContent: (e) => {
          const outDiv = document.createElement('div');
          // outDiv.style.padding = '0px 0px 20px 0px';
          const nodeName = e.item.getModel().name;
          let formatedNodeName = '';
          for (let i = 0; i < nodeName.length; i += 1) {
            formatedNodeName = `${formatedNodeName}${nodeName[i]}`;
            if (i !== 0 && i % 20 === 0) formatedNodeName = `${formatedNodeName}<br/>`;
          }
          outDiv.innerHTML = `${formatedNodeName}`;
          return outDiv;
        },
        shouldBegin: (e) => {
          if (e.target.get('name') !== 'node-name' && e.target.get('name') !== 'node-rect') return false;
          return true;
        },
      });

      const _graph = new G6.TreeGraph({
        container: elRef.current,
        ...defaultConfig,
        // ...config,
        plugins: [tooltip],
        // 各个状态下节点的样式-，例如 hover、selected，3.1 版本新增。
        nodeStateStyles: defaultStateStyles,
      });
      _graph.data(source);
      // graph.render();
      _graph.layout();
      // _graph.fitView();
      // _graph.setMinZoom(0.8);
      // _graph.setMaxZoom(1);
      _graph.fitView(padding, {
        onlyOutOfViewPort: true,
        direction: 'x',
        ratioRule: 'min',
      });
      // _graph.setMinZoom(minZoom);
      // _graph.setMaxZoom(maxZoom);
      // _graph.zoomTo(1);
      // _graph.fitCenter()

      registerEvent(_graph, proxyInstanceRef.current, disabled);
      _graph.get('canvas').set('localRefresh', false);
      return _graph;
    };
    // 设置禁用局部渲染，避免出现拖拽残留

    const _graph = initGraph(_data);
    setGraph(_graph);

    return () => {
      _graph?.destroy();
      setGraph(null);
    };
  }, [data, disableZoom]);

  // fit resize
  useEffect(() => {
    if (!graph) return undefined;
    const resize = () => {
      if (!graph || graph.get('destroyed')) return;
      const container = elRef.current;
      if (!container || !container.clientWidth || !container.clientHeight) return;
      graph.changeSize(container.clientWidth, container.clientHeight);
    };
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [graph]);

  useEffect(() => {
    proxyInstanceRef.current.setProps(props);
  }, [props]);

  useEffect(() => {
    if (!graph) return;
    if (typeof onInit === 'function') {
      onInit(graph);
    }
  }, [graph]);

  // useEffect(() => {
  //   if (graph && typeof zoom === 'number' && !graph.destroyed) {
  //     const _zoom = graph.getZoom();
  //     if (_zoom === zoom) return;
  //     graph.zoomTo(zoom);
  //   }
  // }, [graph, zoom]);

  useEffect(() => {
    if (!graph || typeof selectedId !== 'string') return null;
    const _node = graph.find('node', node => node.get('model').id === selectedId);
    if (!_node) return null;
    const selectedModel = _node.getModel();
    selectedModel.selected = true;
    graph.setItemState(_node, 'selected', selectedModel.selected);

    return () => {
      if (graph.destroyed) return;
      const selectedItem = graph.find('node', item => item.getModel().id === selectedId);
      if (selectedItem) {
        selectedItem.getModel().selected = false;
        graph.setItemState(selectedItem, 'selected', false);
      } else {
        const root = graph.save();
        const finded = findNode(root, item => item.id === selectedId);
        finded.selected = false;
      }
    };
  }, [graph, selectedId]);

  useImperativeHandle(ref, () => ({
    setNodesCollapsedState: collapsed => {
      if (!graph || graph.destroyed) return;
      // const isAnimating = graph.isAnimating();
      // if (isAnimating) graph.stopAnimate();
      const root = graph.save();
      const nodes = findNodes(root, node => node.depth >= LIMIT_TREE_DEPTH);
      const _viewedNodes = graph.findAll('node', node => node.get('model').depth >= LIMIT_TREE_DEPTH);
      _viewedNodes.forEach(item => {
        if (!item) return;
        const nodeModel = item.getModel();
        nodeModel.collapsed = !!collapsed;
        // graph.fitView();
        // graph.layout();
        graph.setItemState(item, 'collapsed', nodeModel.collapsed);
      });
      nodes.forEach(node => {
        const model = node;
        model.collapsed = !!collapsed;
      });
      // graph.read(root);
      graph.layout();
      // console.log(graph.save());
    },
    download: () => {
      if (!graph || graph.destroyed) return;
      // graph.downloadFullImage();
      graph.toFullDataURL((res) => {
        const canvas = document.getElementById('networkCanvas');
        const background = document.getElementById('networkBackground');
        const img = new Image();
        img.onload = function () {
          // 画图
          canvas.width = img.width + 32;
          canvas.height = img.height + 32;
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = '#fff';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(background, 0, 0);
          ctx.drawImage(img, 16, 16);
          // 下载
          const oA = document.createElement('a');
          oA.download = `${name}产业分析`;
          oA.href = canvas.toDataURL('image/png');
          document.body.appendChild(oA);
          oA.click();
          oA.remove();
        };
        img.src = res;
      },
      'image/png',
      {
        imageConfig: {
          padding: 16,
        },
      });
    },
    getZoom: () => {
      if (!graph || graph.destroyed) return undefined;
      return graph.getZoom();
    },
    setZoom: zoom => {
      if (!graph || graph.destroyed) return;
      graph.zoomTo(zoom);
    },
  }), [graph]);

  return (
    <div className={styles.tree}>
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          userSelect: 'none',
        }}
        ref={elRef}
      />
      <canvas id="networkCanvas" style={{ display: 'none' }} />
      <img
        id="networkBackground"
        src={backPng}
        style={{ display: 'none' }}
        alt="背景图"
      />
    </div>
  );
}

export default forwardRef(Tree);
