import { IPiesVisualConfig, IPieVisualConfig, IRadarVisualConfig, IWordCloudVisualConfig } from '../interface/config';
import { getLabel, omitUndefined } from './utils';
import { ISeriesGetter } from '../interface/base';
import { merge } from 'lodash';

/**
 * 获取饼图的其他配置
 * @param visualConfig
 */
export const getEach = (visualConfig: IPiesVisualConfig) => {
  const {
    rose,
    roseType,
    label,
    labelLine,
    emphasis,
    itemStyle,
    center,
    radius,
    clockwise,
    startAngle,
    top,
    bottom,
    left,
    right,
  } = visualConfig;

  return omitUndefined({
    label,
    labelLine,
    emphasis,
    itemStyle,
    center,
    radius,
    clockwise,
    startAngle,
    top,
    bottom,
    left,
    right,
    ...(rose ? { roseType: roseType ?? 'radius' } : {}),
  });
}

export const getPiesSeries: ISeriesGetter<IPieVisualConfig> = (series, index, extra) => {
  const { material, } = extra;
  const { config } = material;
  const { data } = series;
  const { visualConfig } = config;
  const {
    top,
    right,
    bottom,
    left,
    maxPieHeightPercent = 90,
    minPieHeightPercent = 40,
    label,
    itemStyle,
  } = visualConfig ?? {};

  const { color } = itemStyle;
  const { length } = data;
  // 饼图最大外半径，也即绘制区域高度
  const d = maxPieHeightPercent;
  // 每一块额外多出的百分比
  const min = minPieHeightPercent;
  const percent = (d - min) / (length - 1);
  const _series = data.map((item, index) => {
    const centerY = 50 + percent / 2 * index;
    const radius = d - percent * index;

    return omitUndefined({
      z: 2 + index,
      name: series.name,
      type: 'pie',
      data: [item],
      // center: ['50%', `${50 - percent * index}%`],
      // radius: [0, `${(25 + (percent * index) / 2)}%`],
      top,
      right,
      bottom,
      left,
      center: ['50%', `${centerY}%`],
      // radius: d - (percent + 4) * index,
      radius: `${radius}%`,
      itemStyle: omitUndefined({
        color: color ? Array.isArray(color) ? color[index % color.length] : color : undefined,
        ...itemStyle,
      }),
      label: merge(
        {
          formatter({ data }: any) {
            const desc = data.desc ? `{small|\n${data.desc}}` : '';
            const str = `${data.name ?? ''}\n{bold|${data.value ?? ''}}{small|${data.unit ?? ''}}${desc}`;
            return str;
          },
          rich: {
            small: {
              fontSize: 10,
            },
            bold: {
              fontWeight: 'bold',
            },
          },
        },
        getLabel(label)
      ),
      // label: {
      //   position: 'center',
      //   show: true,
      //   fontSize: 12,
      //   color: '#fff',
      //   // formatter(_params: any) {
      //   //   const dataItem =
      //   //   const desc = dataItem.desc ? `{small|\n${dataItem.desc}}` : '';
      //   //   const str = `${dataItem.name}\n{bold|${dataItem.value}}{small|${dataItem.unit}}${desc}`;
      //   //   return str;
      //   // },
      //   rich: {
      //     small: {
      //       fontSize: 10,
      //     },
      //     bold: {
      //       fontWeight: 'bold',
      //     },
      //   },
      // },
      labelLayout({ text, rect, labelRect }: any) {
        if (index === length - 1) {
          return {};
        }
        // const curPieRadius = ((curRadius / 100) * r) / 2;
        // const curPieCenterY = ((conHeight) * curCenterY) / 100;
        // const curPieTop = curPieCenterY - curPieRadius;
        // // console.log(curPieCenterY);
        // const distanceBetweenPies = (r * gapBetweenTwo) / 100;
        const centerY = rect.y + labelRect.height + 5;
        return {
          y: centerY,
          verticalAlign: 'bottom',
          align: 'center',
        };
      },
    });
  });

  return _series;
};
