/**
 * 表格
 */

import { Table as RCTable } from 'antd';
import clsx from 'clsx';
import withClassname from '../../hoc/withClassname';
import { ReactNode, Ref, useMemo } from 'react';
import type { TableProps as RCTableProps, ColumnsType } from 'antd/lib/table/Table';

export interface TableProps<RecordType extends object> extends RCTableProps<RecordType> {
  children?: ReactNode;
  strip?: boolean;
  autoMerge?: boolean;
  ignoreAutoMerge?: string[];
  ref?: Ref<HTMLDivElement> | undefined;
}

function ITable<RecordType extends object>(props: TableProps<RecordType>) {
  const { className, strip, autoMerge, dataSource, columns, ignoreAutoMerge } = props;
  const {
    dataSource: _dataSource,
    columns: _columns,
  } = useMemo(() => {
    // 自动合并单元格，当当前列与上一行文字相同，则自动合并
    if (!autoMerge || !Array.isArray(dataSource) || !Array.isArray(columns)) {
      return { dataSource, columns };
    }
    const _columns = columns.map(column => {
      return ({
        ...column,
        onCell: (_: any, index: number) => {
          let cell = {};
          if (column.onCell) {
            cell = column.onCell(_, index);
          }
          const { dataIndex } = column as any;
          if (Array.isArray(ignoreAutoMerge) && ignoreAutoMerge.includes(dataIndex)) {
            return cell;
          }

          const prev = dataSource[index - 1]?.[dataIndex];
          const cur = _[dataIndex];
          if (prev === cur) {
            return { ...cell, rowSpan: 0 };
          }

          let count = 1;
          const rest = dataSource.slice(index + 1);
          for (const value of rest) {
            if (value[dataIndex] === cur) {
              count += 1;
            } else {
              break;
            }
          }

          return {
            ...cell,
            'cell-merged': 'true',
            rowSpan: count,
          };
        }
      })
    });
    return {
      columns: _columns,
      dataSource,
    };
  }, [columns, dataSource, autoMerge]);

  return (
    <RCTable
      {...props}
      dataSource={_dataSource}
      columns={_columns as  ColumnsType<RecordType>}
      className={clsx({ 'ant-table-strip': strip }, className)}
    />
  );
}

export const Table = withClassname(ITable, 'ant-table-extend');
