import React, { useState } from 'react';
import { Select } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useFindReportPublisherQuery } from '../../../../service/dataSearch';
import style from './index.module.less';

const IssuingAuthority = ({ title, value, setValue, setIsConfirm }) => {
  const [unfold, setUnfold] = useState(false);
  const { data } = useFindReportPublisherQuery();
  const selectProps = {
    mode: 'multiple',
    style: {
      height: '100%',
      width: '100%',
    },
    value,
    options: data,
    fieldNames: {
      label: 'title',
      value: 'value',
    },
    onChange: (newValue) => {
      setIsConfirm(true);
      setValue(newValue);
    },
    onClick: (e) => {
      e.stopPropagation();
      setUnfold(params => !params);
    },
    onBlur: () => {
      setUnfold(false);
    },
    showArrow: true,
    suffixIcon: <div className={style.icon} onClick={(e) => { e.stopPropagation(); }}>{unfold ? <UpOutlined /> : <DownOutlined />}</div>,
    placeholder: '可搜索选择机构名称',
    maxTagCount: 'responsive',
  };
  return (
    <div className={style.issuingAuthority}>
      <div className={style.point} />
      <span>
        {title}
      </span>
      <div className={style.select}>
        <Select
          {...selectProps}
        />
      </div>
    </div>
  );
};

export default IssuingAuthority;
