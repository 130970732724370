import { cloneDeep } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import * as echarts from 'echarts/core';
import RouteMap from '../RouteMap';
import { useGetCenterInfoQuery } from '../../../../../../service/interactive-v2';
import {
  useGetMigrationMonitoringMapQuery,
} from '../../../../../../service/cityTool';
import { useLazyGetCityJSONQuery } from '../../../../../../service/static-resource';

// const TAB_TYPE = {
//   emigration: {
//     from: '迁至',
//     text: '迁出',
//   },
//   immigration: {
//     from: '来自',
//     text: '迁入',
//   },
// };

/**
 * 产业迁徙地图
 * @param {{
 *   cityCode: string;
 *   dateRangeKey?: string;
 *   industryCode?: string;
 * }} props
 * @return {null}
 * @constructor
 */
function IndustryMigrationMap(props) {
  const {
    tab: moveType,
    areaList,
    pageType,
    initialSize,
    industryCodeList,
    cityCode,
    cityName,
    dateRangeKey,
    setMapData,
  } = props;

  // 初始化时，获取地图
  const [getCityJSON] = useLazyGetCityJSONQuery();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  useEffect(async () => {
    if (!echarts.getMap('china')) {
      const { data: json } = await getCityJSON('000000', true);
      echarts.registerMap('china', cloneDeep(json));
      setIsMapLoaded(true);
    } else {
      setIsMapLoaded(true);
    }
  }, []);

  const { currentData: center } = useGetCenterInfoQuery({
    area_code: cityCode,
  }, { skip: !cityCode });
  const anchor = useMemo(() => {
    const centerInfo = {
      name: cityName,
      code: cityCode,
      center: [center?.lng, center?.lat],
    };
    if (moveType === 'emigration') {
      return {
        moveOut: centerInfo,
      };
    }
    if (moveType === 'immigration') {
      return {
        moveIn: centerInfo,
      };
    }
    return {};
  }, [center, moveType]);
  // 获取迁入迁出数据
  const { currentData: data } = useGetMigrationMonitoringMapQuery({
    area_key: cityCode,
    select_area_list: areaList,
    select_time_scope: dateRangeKey,
    industry_code_list: industryCodeList,
    move_type: moveType,
  }, { skip: !cityCode || !dateRangeKey || dateRangeKey.length === 0 });

  useEffect(() => {
    setMapData(data);
  }, [data]);

  const moveData = useMemo(() => {
    if (moveType === 'emigration') {
      return {
        moveOut: data?.series?.[0]?.data,
      };
    }
    if (moveType === 'immigration') {
      return {
        moveIn: data?.series?.[0]?.data,
      };
    }
    return {};
  }, [data, moveType]);

  return (
    isMapLoaded && (
      <>
        {/* <div style={{ color: '#0F2849', lineHeight: '20px', marginTop: 15 }}>
          {
            areaList.length === 0 || areaList[0] === '000000'
              ? `${data?.pool?.['年份'] ?? ''}，共计${TAB_TYPE[moveType].text}企业
            ${data?.pool?.['迁入或迁出企业数量'] ?? ''}
            家，主要${TAB_TYPE[moveType].from}${data?.pool?.['城市'] ?? ''}城市`
              : `${data?.pool?.['年份'] ?? ''}，${TAB_TYPE[moveType].from}${data?.pool?.['城市'] ?? ''}城市企业
            ${data?.pool?.['迁入或迁出企业数量'] ?? ''}
            家`
          }
        </div> */}
        <RouteMap
          pageType={pageType}
          map="china"
          anchor={anchor}
          data={moveData}
          initialSize={initialSize}
        />
      </>
    )
  );
}

export default IndustryMigrationMap;
