import React, { useMemo, useEffect } from 'react';
import classnames from 'classnames';
import Table from '../../../../../../components/Table';
import styles from './index.module.less';
import { FilterIcon } from '../../../../../../components/CustomTableIcon';
import { useGetEmigrationWarningQuery } from '../../../../../../service/cityTool';

const TYPELIST = [
  {
    text: '是',
    value: '是',
  },
  {
    text: '否',
    value: '否',
  },
];

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 40,
  },
  company_name: {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    className: styles.name,
    ellipsis: true,
  },
  peripheral_mechanism: {
    title: '外设机构',
    dataIndex: 'peripheral_mechanism',
    key: 'peripheral_mechanism',
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  remote_investment: {
    title: '异地投资',
    dataIndex: 'remote_investment',
    key: 'remote_investment',
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  recruitment_different_places: {
    title: '异地招聘',
    dataIndex: 'recruitment_different_places',
    key: 'recruitment_different_places',
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
};

const COLUMN_KEYS = [
  'index', 'company_name', 'peripheral_mechanism',
  'remote_investment', 'recruitment_different_places',
];

function EmigrationWarningTable(props) {
  const {
    regionCode,
    filteredInfo,
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    className,
  } = props;
  const {
    data,
    isFetching,
  } = useGetEmigrationWarningQuery({
    area_key: regionCode,
    type_dict: {
      外设机构: filteredInfo?.peripheral_mechanism?.length > 0 ? filteredInfo?.peripheral_mechanism?.[0] : null,
      异地投资: filteredInfo?.remote_investment?.length > 0 ? filteredInfo?.remote_investment?.[0] : null,
      异地招聘: filteredInfo?.recruitment_different_places?.length > 0 ? filteredInfo?.recruitment_different_places?.[0] : null,
    },
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !regionCode,
  });
  const { columns: tableHead = [], dataSource: tableBody, total = 0 } = data ?? {};
  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'company_name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      if (item.dataIndex === 'peripheral_mechanism') {
        rowConfig.filteredValue = filteredInfo?.peripheral_mechanism ?? [];
        rowConfig.filters = TYPELIST;
        rowConfig.filterMultiple = false;
      }
      if (item.dataIndex === 'remote_investment') {
        rowConfig.filteredValue = filteredInfo?.remote_investment ?? [];
        rowConfig.filters = TYPELIST;
        rowConfig.filterMultiple = false;
      }
      if (item.dataIndex === 'recruitment_different_places') {
        rowConfig.filteredValue = filteredInfo?.recruitment_different_places ?? [];
        rowConfig.filters = TYPELIST;
        rowConfig.filterMultiple = false;
      }
      return rowConfig;
    });
  }, [tableHead, onCompanyClick, filteredInfo]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
      className={classnames(styles.distributionRanking, className)}
    />
  );
}

export default EmigrationWarningTable;
