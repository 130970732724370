/*
* Author: zhangmeng
* Description: '数据搜索'
* Date: 2022/06/29
*/

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs from './components/Tabs';
import AllPage from './components/AllPage';
import style from './index.module.less';
import CompanyPage from './components/CompanyPage';
import IndustryChart from './components/IndustryChart';
import PatentPage from './components/PatentPage';
import InvestmentPage from './components/InvestmentPage';
import PolicyPage from './components/PolicyPage';
import PublicSentimentPage from './components/PublicSentimentPage';
import ResearchReportPage from './components/ResearchReportPage';
import { useQuery } from '../../hooks/url';
import { useGetAllFilterQuery, usePostCityTreeQuery, usePostIndustryTreeQuery } from '../../service/dataSearch';
import { participleByKeyword } from '../../api/homepage';
import { useRecordingSpotQuery } from '../../service/public';

function DataSearch() {
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const hiddenSearchTopLeftTab = menu.find(item => item === 'hiddenIndustryTopLeftTab');
  const [tabSelected, setTabSelected] = useState('全部');
  const [participle, setParticiple] = useState([]); // 分词
  const [isFirst, setIsFirst] = useState(true); // 首次进入
  const timer = useRef(null);
  const history = useHistory();
  const query = useQuery();
  const page = query.get('page');
  const search = query.get('search');

  const { data: allData } = useGetAllFilterQuery();
  const { data: areaTree } = usePostCityTreeQuery();
  const { data: industryTree } = usePostIndustryTreeQuery();

  useRecordingSpotQuery(
    {
      spot_name: `搜索-${tabSelected}`,
    },
    {
      refetchOnMountOrArgChange: false,
      skip: !tabSelected,
    },
  );
  useEffect(() => {
    if (timer.current !== null) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (search.trim() !== '') {
      timer.current = setTimeout(() => {
        participleByKeyword({
          keyword: search,
        }).then((res) => {
          if (res.status === '00000') {
            if (Array.isArray(res.data) && res.data?.length > 0) {
              setParticiple(res.data.filter(str => !!str.trim()));
            }
          }
        });
      }, 500);
    } else {
      setParticiple([]);
    }
  }, [search]);

  useEffect(() => {
    switch (tabSelected) {
      case '全部':
      case 'all':
        // eslint-disable-next-line no-nested-ternary
        query.set('page', `${isFirst ? (!page ? 'all' : page) : 'all'}`);
        history.push({ search: query.toString() });
        break;
      case '企业':
      case 'company':
        query.set('page', 'company');
        history.push({ search: query.toString() });
        break;
      case '产业':
      case 'industryChart':
        query.set('page', 'industryChart');
        history.push({ search: query.toString() });
        break;
      case '专利':
      case 'patent':
        query.set('page', 'patent');
        history.push({ search: query.toString() });
        break;
      case '投资':
      case 'investment':
        query.set('page', 'investment');
        history.push({ search: query.toString() });
        break;
      case '政策':
      case 'policy':
        query.set('page', 'policy');
        history.push({ search: query.toString() });
        break;
      case '舆情':
      case 'publicSentiment':
        query.set('page', 'publicSentiment');
        history.push({ search: query.toString() });
        break;
      case '研报':
      case 'researchReport':
        query.set('page', 'researchReport');
        history.push({ search: query.toString() });
        break;
      default:
        break;
    }
    setIsFirst(false);
  }, [tabSelected]);
  return (
    <div className={style.all}>
      {
        !hiddenSearchTopLeftTab && (
          <div className={style.tabBox}>
            {
              useMemo(() => (
                <Tabs setTabSelected={setTabSelected} participle={participle} />
              ), [participle])
            }
          </div>
        )
      }
      <div className={style.main}>
        {
          useMemo(() => (
            <AllPage
              moreClick={setTabSelected}
              participle={participle}
              hideTitle={hiddenSearchTopLeftTab}
              styles={{ display: page === 'all' ? 'block' : 'none' }}
            />
          ), [participle, page])
        }
        {
          useMemo(() => (
            allData && areaTree && industryTree && (<CompanyPage
              filter={{
                ...allData,
                areaTree,
                industryTree: [industryTree]?.[0]?.children,
              }}
              participle={participle}
              hideTitle={hiddenSearchTopLeftTab}
              styles={{ display: page === 'company' ? 'block' : 'none' }}
            />)
          ), [allData, areaTree, industryTree, participle, page])
        }
        {
          useMemo(() => (
            allData && industryTree && (<IndustryChart
              filter={{
                ...allData,
                industryTree: [industryTree]?.[0]?.children,
              }}
              participle={participle}
              hideTitle={hiddenSearchTopLeftTab}
              styles={{ display: page === 'industryChart' ? 'block' : 'none' }}
            />)
          ), [allData, page, participle, industryTree])
        }
        {
          useMemo(() => (
            allData && areaTree && industryTree && <PatentPage
              filter={{
                ...allData,
                areaTree,
                industryTree: [industryTree]?.[0]?.children,
              }}
              participle={participle}
              hideTitle={hiddenSearchTopLeftTab}
              styles={{ display: page === 'patent' ? 'block' : 'none' }}
            />
          ), [allData, areaTree, industryTree, participle, page])
        }
        {
          useMemo(() => (
            allData && areaTree && industryTree && <InvestmentPage
              filter={{
                ...allData,
                areaTree,
                industryTree: [industryTree]?.[0]?.children,
              }}
              participle={participle}
              hideTitle={hiddenSearchTopLeftTab}
              styles={{ display: page === 'investment' ? 'block' : 'none' }}
            />
          ), [allData, areaTree, industryTree, participle, page])
        }
        {
          useMemo(() => (
            allData && areaTree && industryTree && <PolicyPage
              filter={{
                ...allData,
                areaTree,
                industryTree: [industryTree]?.[0]?.children,
              }}
              participle={participle}
              hideTitle={hiddenSearchTopLeftTab}
              styles={{ display: page === 'policy' ? 'block' : 'none' }}
            />
          ), [allData, areaTree, industryTree, participle, page])
        }
        {
          useMemo(() => (
            allData && areaTree && industryTree && <PublicSentimentPage
              filter={{
                ...allData,
                areaTree,
                industryTree: [industryTree]?.[0]?.children,
              }}
              participle={participle}
              hideTitle={hiddenSearchTopLeftTab}
              styles={{ display: page === 'publicSentiment' ? 'block' : 'none' }}
            />
          ), [allData, areaTree, industryTree, participle, page])
        }
        {
          useMemo(() => (
            allData && <ResearchReportPage
              filter={allData}
              participle={participle}
              hideTitle={hiddenSearchTopLeftTab}
              styles={{ display: page === 'researchReport' ? 'block' : 'none' }}
            />
          ), [allData, page, participle])
        }
      </div>
    </div>
  );
}

export default DataSearch;
