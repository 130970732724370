/*
* Author: zhangmeng
* Description: '资质认定-树图'
* Date: 2021/05/14
*/

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

const level1 = ['#FFDC6B', '#E2FF6B', '#48C2B6', '#C09BFC', '#FFB6A4', '#91A4FF'];
function CertificationChart(props) {
  const { data, orient, initialTreeDepth } = props;
  const [option, setOption] = useState({});

  useEffect(() => {
    /* eslint-disable no-param-reassign */
    function itemColor(params, borderColor) {
      params.forEach((item, index) => {
        if (item.level === 0) {
          item.itemStyle = {
            color: '#F77800',
            borderColor: '#F77800',
            shadowBlur: 10,
            shadowColor: '#e5e3e3',
          };
          item.label = {
            position: orient && orient === 'TB' ? 'bottom' : 'right',
          };
        } else if (item.level === 1) {
          item.itemStyle = {
            color: level1[index],
            borderColor: level1[index],
          };
          item.label = {
            position: orient === 'TB' ? 'bottom' : 'right',
          };
        } else if (item.level === 2) {
          item.itemStyle = {
            color: borderColor,
            borderColor,
          };
          item.label = orient === 'TB' ? {
            position: 'bottom',
            rotate: params.length > 1 ? -90 : 0,
            verticalAlign: 'middle',
            align: params.length > 1 ? 'left' : 'center',
          } : {
            position: 'right',
          };
        } else {
          item.itemStyle = {
            color: 'transparent',
            borderColor,
          };
          item.label = orient === 'TB' ? {
            position: 'bottom',
            rotate: params.length > 1 ? -90 : 0,
            verticalAlign: 'middle',
            align: params.length > 1 ? 'left' : 'center',
          } : {
            position: 'right',
          };
        }
        if (item.children && item.children.length > 0) {
          itemColor(item.children, item.itemStyle.borderColor);
        } else {
          item.symbol = 'emptyCircle';
          item.itemStyle = {
            color: borderColor,
            borderColor,
          };
        }
      });
    }
    itemColor(data, 0);

    const options = {
      // tooltip: {
      //   trigger: 'item',
      //   triggerOn: 'mousemove',
      // },
      series: [
        {
          type: 'tree',
          data,
          top: '5%',
          left: '7%',
          bottom: '5%',
          right: '20%',
          symbol: 'circle',
          orient,
          roam: true,
          initialTreeDepth,
          symbolSize: (v, p) => {
            const { data: { level } } = p;
            if (level === 0) {
              return 25;
            }
            if (level === 1) {
              return 20;
            }
            return 12;
          },
          label: {
            // position: 'right',
            // width: 250,
            overflow: 'truncate',
            ellipsis: '...',
            formatter: p => {
              const { data: d, name } = p;
              if (d.level === 0) {
                return `{size0|${name}}`;
              }
              if (d.level === 1) {
                return `{size1|${name}}`;
              }
              return `{size2|${name}}`;
            },
            rich: {
              size0: {
                fontSize: 16,
              },
              size1: {
                fontSize: 14,
              },
              size2: {
                fontSize: 12,
              },
            },
          },
          itemStyle: {
            borderWidth: 1,
          },
          lineStyle: {
            color: '#ccc',
            width: 1,
          },
          emphasis: {
            focus: 'descendant',
            label: {
              overflow: 'break',
              formatter: p => {
                const { data: d, name } = p;
                if (d.level === 0) {
                  return `{size0|${name}}`;
                }
                if (d.level === 1) {
                  return `{size1|${name}}`;
                }
                return `{size2|${name}}`;
              },
              rich: {
                size0: {
                  fontSize: 16,
                },
                size1: {
                  fontSize: 14,
                },
                size2: {
                  fontSize: 12,
                },
              },
            },
          },
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
        },
      ],
    };
    setOption(options);
  }, [data]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default CertificationChart;
