import type { ReactNode } from 'react';
import type { SymbolType } from './index';


function Symbol(props: {
  type?: SymbolType
  color?: string;
  size?: number | number[];
  borderColor?: string;
  borderWidth?: number;
  borderRadius?: number;
  shadowOffsetX?: number;
  shadowOffsetY?: number;
  shadowBlur?: number;
  shadowColor?: string;
  padding?: number;
}) {
  const {
    type = 'circle',
    color = 'currentColor',
    size = 12,
    borderColor,
    borderWidth,
    borderRadius,
    shadowOffsetX,
    shadowOffsetY,
    shadowColor,
    shadowBlur,
    padding = 4,
  } = props;
  const [width, height] = Array.isArray(size) ? size : [size, size];
  const _size = Math.max(width, height);
  const _width = width + padding * 2;
  const _height = height + padding * 2;
  const render = (children: ReactNode) => (
    <svg
      width={_width}
      height={_height}
      style={{
        filter: `drop-shadow(
        ${shadowOffsetX}px ${shadowOffsetY}px ${shadowBlur}px ${shadowColor})`,
      }}
      className="sqke-timeline-symbol-item"
    >
      {/*<filter id="shadow-filter">*/}
      {/*  <feOffset*/}
      {/*    dx='0'*/}
      {/*    dy='0'*/}
      {/*  />*/}
      {/*  <feGaussianBlur*/}
      {/*    stdDeviation='0'*/}
      {/*    result='offset-blur'*/}
      {/*  />*/}
      {/*</filter>*/}
      {children}
    </svg>
  );

  if (type === 'circle') {
    return (
      render(
        <circle
          stroke={borderColor}
          fill={color}
          r={_size / 2}
          cx={_width / 2}
          cy={_height / 2}
          strokeWidth={borderWidth}
          // filter="url(#shadow-filter)"
        />,
      )
    );
  }

  if (type === 'rect') {
    return (
      render(
        <rect
          stroke={borderColor}
          fill={color}
          width={width}
          strokeWidth={borderWidth}
          height={height}
          x={padding}
          y={padding}
          rx={borderRadius}
          ry={borderRadius}
          // filter="url(#shadow-filter)"
        />,
      )
    );
  }

  if (type.startsWith('path://')) {
    return (
      render(
        <path
          stroke={borderColor}
          strokeWidth={borderWidth}
          fill={color}
          width={width}
          height={height}
          d={type.substring('path://'.length)}
          transform={`translate(${padding}px, ${padding}px)`}
        />,
      )
    );
  }

  if (type.startsWith('image://')) {
    return (
      <img
        src={type.substring('image://'.length)}
        alt="image-symbol" style={{ width, height, padding }}
      />
    );
  }

  return null;
}

export {
  Symbol,
};
