import { createContext, Dispatch, } from 'react';
import { initialState } from '../reducer';


/**
 * 主题渲染器 Context
 * [state, dispatch] = useContext(SchemeRenderContext);
 */
export const SchemeRenderContext = createContext<[typeof initialState, Dispatch<{ type: string; payload: any }>]>(
  [initialState, () => null],
);
export const { Provider, Consumer } = SchemeRenderContext;
