// 靶向招标
import React, { useState, useEffect } from 'react';
import { Tree, Select } from 'antd';
import style from './index.module.less';
import ChartList from '../components/ChartList';
import IndustrialChainGraph from './IndustrialChainGraph';
import { useTargetedInvestmentPromotionQuery, useTargetedInvestmentPromotionSubgraphQuery } from '../../../../../service/cityTool';
import { useGetSearchIndustryByProportionQuery } from '../../../../../service/public';
import {
  useGetAreaPlugMarqueeV2Query,
} from '../../../../../service/interactive-v2';

const TargetMerchants = (props) => {
  const { cityCode, cityName } = props;
  const [link, setLink] = useState('');
  const [level, setLevel] = useState(1);
  const [industry, setIndustry] = useState({});
  const [area, setArea] = useState({ code: cityCode, name: cityName });
  const { code, name } = area;
  const { industryName, industryCode } = industry;
  // 图形数据
  const { data, isFetching } = useTargetedInvestmentPromotionQuery({
    // area_key: code,
    industry_code: industryCode,
  }, { skip: !industryCode });
  // 环形子图
  const { data: childrenData } = useTargetedInvestmentPromotionSubgraphQuery({
    // area_key: code,
    industry_code: industryCode,
    level,
  }, { skip: !industryCode });

  // 产业列表
  const { data: industryData } = useGetSearchIndustryByProportionQuery({
    region_code: code,
  }, { skip: !code });

  // 获取地区
  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query();
  // 点击母环环节
  const onClick = e => {
    setLevel(e.data.level);
    setLink(e.data.name);
    setLink(`${e.data.name} \n ${e.data.proportion}%`);
  };
  useEffect(() => {
    if (data) {
      const text = data[1].series[0].data[0];
      setLink(`${text.name} \n ${text.proportion}%`);
    }
  }, [industryData, data]);

  useEffect(() => {
    if (industryData) {
      if (!(industryData.some(item => item.key === industryCode))) {
        setIndustry({
          industryName: industryData[0]?.title,
          industryCode: industryData[0]?.key,
        });
      }
    }
  }, [industryData]);
  const onSelectRegion = (value, info) => {
    setIndustry({
      industryName: info.node.title,
      industryCode: info.node.key,
    });
  };
  const upadteArea = (value, info) => {
    setArea({
      code: info.node.code,
      name: info.node.title,
    });
  };
  return (
    <div className={style.box}>
      <div className={style.screen}>
        <div className={style.city}>
          <p>区域：</p>
          <Select
            value={name}
            style={{
              width: 220,
              marginRight: 12,
            }}
            dropdownRender={() => (
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                <Tree
                  defaultExpandedKeys={['000000']}
                  checkedKeys={[code]}
                  onSelect={upadteArea}
                  treeData={regionTree.map(item => ({ ...item, disabled: true }))}
                />
              </div>
            )}
          />
        </div>
        <div className={style.industry}>
          <p>产业：</p>
          <Select
            value={industryName}
            style={{
              width: 220,
              marginRight: 12,
            }}
            dropdownRender={() => (
              <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                <Tree
                  onSelect={onSelectRegion}
                  selectedKeys={[industryCode]}
                  treeData={industryData || []}
                />
              </div>
            )}
          />
        </div>
      </div>

      <ChartList {...props} data={data} link={link} childrenData={childrenData} isFetching={isFetching} onClick={onClick} />
      <IndustrialChainGraph {...props} cityCode={code} industryCode={industryCode} industryName={industryName} name="产业链图" />
    </div>
  );
};

export default TargetMerchants;
