// 标准合作
import React, { useState, useMemo } from 'react';
import { ECharts, Table } from '@sqke/design';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';
import Container from '../../Container';
import style from './index.module.less';
import { useGetStandardCooperationQuery, useGetDistributionStandardTypesQuery, useGetLeadingStandardsQuery } from '../../../../../service/companyDetail';
import { openNewPage } from '../../../../../utils/urlMethods';

function StandardCooperation(props) {
  const { companyId, preventPageto, companyName } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [leadingPage, setLeadingPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data, isFetching } = useGetStandardCooperationQuery({
    company_id: companyId,
    node_limit_list: [null, null, 5],
  }, {
    skip: !companyId,
  });
  const option = useMemo(() => {
    const series = data?.series?.map(item => ({
      name: item.name,
      type: 'tree',
      layout: 'radial',
      roam: true,
      zoom: 1.5,
      center: ['18%', '18%'],
      scaleLimit: {
        min: 0.8,
        max: 4,
      },
      initialTreeDepth: 3,
      levels: [
        {
          label: {
            show: false,
            fontSize: 14,
            padding: [4, 8, 4, 8],
            backgroundColor: 'rgba(233,240,244,0.5)',
          },
        },
        {
          lineStyle: {
            color: '#1961F5',
          },
        },
      ],
      lineStyle: {
        curveness: 0.5,
        width: 0.5,
      },
      symbolSize: 8,
      matchers: [
        {
          rules: { '==': [{ var: ['type'] }, 'company'] },
          result: {
            label: {
              color: '#000',
            },
            itemStyle: {
              color: '#fff',
              borderColor: '#000',
            },
          },
        },
        {
          rules: { '==': [{ var: ['type'] }, null] },
          result: {
            label: {
              color: '#1961F5',
            },
            itemStyle: {
              color: '#fff',
              borderColor: '#1961F5',
            },
          },
        },
      ],
      label: {
        show: true,
        position: '',
        distance: 2,
        fontSize: 16,
      },
      data: item.data,
    }));

    return {
      tooltip: {},
      series,
    };
  }, [data]);
  const amplifyOption = useMemo(() => {
    if (!option.series) return {};
    const _option = cloneDeep(option);
    _option.series[0].levels[0].label.fontSize = 18;
    _option.series[0].label.fontSize = 14;
    return {
      option: _option,
      title: {
        title: '标准合作',
        question: '展示该企业在各业务领域与其他企业合作起草标准情况',
        companyName,
      },
    };
  }, [option]);

  const { data: tableData, isFetching: tableFetching } = useGetDistributionStandardTypesQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const dataSource = useMemo(() => (
    tableData?.dataSource.map((item, index) => ({
      key: index,
      ...item,
    }))
  ), [tableData]);

  const { data: leadingData, isFetching: leadingFetching } = useGetLeadingStandardsQuery({
    company_id: companyId,
    page_size: leadingPage.pageSize,
    page_number: leadingPage.pageNumber,
  }, {
    skip: !companyId,
  });
  const leadingColumns = useMemo(() => (
    leadingData?.columns.map(item => {
      if (item.dataIndex === 'ch_name' && !preventPageto) {
        return {
          ...item,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => {
                if (record.operation) {
                  openNewPage('standardDetail', {
                    id: record.operation,
                  });
                }
              }}
            >
              {text}
            </div>
          ),
        };
      }
      return {
        ...item,
      };
    })
  ), [leadingData, preventPageto]);

  const chartEl = useMemo(() => {
    if (!data) return <div style={{ minHeight: '600px' }}><Spin /></div>;
    if (data.series?.length > 0) {
      return (
        <>
          <div className={style.subTitle}>
            {data?.pool?.company_name}
            起草标准涵盖
            {data?.pool?.industry_box}
            等产业，起草标准合作类型包括
            {data?.pool?.type_box}
            ，与
            {data?.pool?.com_box}
            均有合作。其中，
            {data?.pool?.most_type}
            与外部合作次数最多，
            {data?.pool?.most_type}
            的产业分布最广泛。
          </div>
          <ECharts
            showLoading={isFetching}
            className={style.chart}
            option={option}
          />
        </>
      );
    }
    return <></>;
  }, [data]);

  const standardCooperationTable = useMemo(() => {
    if (!dataSource || dataSource?.length) {
      return (
        <Table
          dataSource={dataSource}
          columns={tableData?.columns}
          title={() => (
            <>
              <span>{tableData?.title}</span>
              <span className={style.count}>{tableData?.count}</span>
            </>
          )}
          strip
          loading={tableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return <></>;
  }, [dataSource]);

  const dominantStandardTable = useMemo(() => {
    if (!leadingData?.dataSource || leadingData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={leadingData?.dataSource}
          columns={leadingColumns}
          title={
            () => (
              <>
                <span>{leadingData?.title}</span>
                <span className={style.count}>{leadingData?.count}</span>
              </>
            )
          }
          strip
          loading={leadingFetching}
          pagination={{
            current: leadingPage.pageNumber,
            pageSize: leadingPage.pageSize,
            total: leadingData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          locale={{ emptyText: () => <div className={style.tableNoData}>该企业无此项数据</div> }}
          onChange={({ current, pageSize }) => {
            setLeadingPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return <></>;
  }, [leadingData]);

  return (
    <Container id="标准合作" look="查看全图" question="展示该企业在各业务领域与其他企业合作起草标准情况" option={amplifyOption}>
      {
        chartEl
      }
      {
        standardCooperationTable
      }
      {
        dominantStandardTable
      }

    </Container>
  );
}

export default StandardCooperation;
