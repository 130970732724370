/*
 *Author: zhaochenyu
 *Description: 企业年报详情
 *Date: 2021/08/24
*/

import React, { useState, useEffect, useMemo } from 'react';
import { Descriptions } from 'antd';
import { IconFont, tabMap } from '../../utils/mapIcon';
import {
  getBasicInfo,
  getAssetsInfo,
  getSocialInfo,
  getShareInfo,
} from '../../api/companyReport';
import CommonTitle from './CommonTitle';
import ReportTable from './ReportTable';
import style from './index.module.less';

function CompanyAnnualReport() {
  const [shareTableData, setShareTableData] = useState({});
  const [basicInfo, setBasicInfo] = useState({});
  const [assetsInfo, setAssetsInfo] = useState({});
  const [socialInfo, setSocialInfo] = useState({});
  const { href } = window.location;
  const params = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);

  const getTableData = (param) => {
    getShareInfo({
      report_id: params.get('id'),
      ...param,
    }).then(res => {
      if (res.status === '00000') {
        setShareTableData(res.data);
      }
    });
  };

  useEffect(() => {
    if (params.get('id')) {
      getBasicInfo({
        report_id: params.get('id'),
      }).then(res => {
        if (res.status === '00000') {
          setBasicInfo(res.data);
        }
      });
      getAssetsInfo({
        report_id: params.get('id'),
      }).then(res => {
        if (res.status === '00000') {
          setAssetsInfo(res.data);
        }
      });
      getSocialInfo({
        report_id: params.get('id'),
      }).then(res => {
        if (res.status === '00000') {
          setSocialInfo(res.data);
        }
      });
      getTableData({
        page_number: 1,
        page_size: 5,
      });
    }
  }, []);

  const basicInfoMemo = useMemo(() => {
    const { credit_code: creditCode, registration_code: registrationCode,
      status, tel_no: telNo, email, postcode, employee_num: employeeNum,
      postal_address: postalAddress } = basicInfo;
    return (
      <Descriptions title="企业基本信息" bordered>
        <Descriptions.item label="统一社会信用代码">
          {creditCode || ''}
        </Descriptions.item>
        <Descriptions.item label="注册号">
          {registrationCode || ''}
        </Descriptions.item>
        <Descriptions.item label="企业经营状态">
          {status || ''}
        </Descriptions.item>
        <Descriptions.item label="企业联系电话">
          {telNo || ''}
        </Descriptions.item>
        <Descriptions.item label="电子邮箱">
          {email || ''}
        </Descriptions.item>
        <Descriptions.item label="邮政编码">
          {postcode || ''}
        </Descriptions.item>
        <Descriptions.item label="从业人数">
          {employeeNum || ''}
        </Descriptions.item>
        <Descriptions.item label="企业通讯地址" span={2}>
          {postalAddress || ''}
        </Descriptions.item>
      </Descriptions>
    );
  }, [basicInfo]);

  const assetsInfoMemo = useMemo(() => {
    const { total_assets: totalAssets, total_equity: totalEquity, total_sales: totalSales,
      total_profit: totalProfit, net_profit: netProfit, revenue, total_tax: totalTax,
      total_liability: totalLiability } = assetsInfo;
    return (
      <Descriptions title="" bordered>
        <Descriptions.item label="资产总额">
          {totalAssets || ''}
        </Descriptions.item>
        <Descriptions.item label="所有者权益合计">
          {totalEquity || ''}
        </Descriptions.item>
        <Descriptions.item label="销售总额(营业总收入)">
          {totalSales || ''}
        </Descriptions.item>
        <Descriptions.item label="利润总额">
          {totalProfit || ''}
        </Descriptions.item>
        <Descriptions.item label="净利润">
          {netProfit || ''}
        </Descriptions.item>
        <Descriptions.item label="主营业务收入">
          {revenue || ''}
        </Descriptions.item>
        <Descriptions.item label="纳税总额">
          {totalTax || ''}
        </Descriptions.item>
        <Descriptions.item label="负债总额" span={2}>
          {totalLiability || ''}
        </Descriptions.item>
      </Descriptions>
    );
  }, [assetsInfo]);

  const socialInfoMemo = useMemo(() => {
    const { endowment_insurance: endowmentInsurance, medical_insurance: medicalInsurance,
      maternity_insurance: maternityInsurance, unemployment_insurance: unemploymentInsurance,
      employment_injury_insurance: employmentInjuryInsurance,
      endowment_insurance_base: endowmentBase,
      unemployment_insurance_base: unemploymentBase,
      medical_insurance_base: medicalBase,
      employment_injury_insurance_base: employmentBase,
      maternity_insurance_base: maternityBase,

      endowment_insurance_pay_amount: endowmentPayAmount,
      unemployment_insurance_pay_amount: unemploymentPayAmount,
      medical_insurance_pay_amount: medicalPayAmount,
      employment_injury_insurance_pay_amount: employmentPayAmount,
      maternity_insurance_pay_amount: maternityPayAmount,

      endowment_insurance_owe_amount: endowmentOweAmount,
      unemployment_insurance_owe_amount: unemploymentOweAmount,
      medical_insurance_owe_amount: medicalOweAmount,
      employment_injury_insurance_owe_amount: employmentOweAmount,
      maternity_insurance_owe_amount: maternityOweAmount,
    } = socialInfo;
    return (
      <>
        <div className={style.socialTop}>
          <Descriptions title="" bordered column={4}>
            <Descriptions.item label="城镇职工基本养老保险" span={2}>
              {endowmentInsurance || ''}
            </Descriptions.item>
            <Descriptions.item label="职工基本医疗保险" span={2}>
              {medicalInsurance || ''}
            </Descriptions.item>
            <Descriptions.item label="生育保险" span={2}>
              {maternityInsurance || ''}
            </Descriptions.item>
            <Descriptions.item label="失业保险" span={2}>
              {unemploymentInsurance || ''}
            </Descriptions.item>
            <Descriptions.item label="工伤保险" span={4}>
              {employmentInjuryInsurance || ''}
            </Descriptions.item>
          </Descriptions>
        </div>

        <div className={style.descriptionTitle}>单位缴纳基数</div>
        <Descriptions title="" bordered>
          <Descriptions.item label="单位参加城镇职工基本养老保险缴费基数" span={3}>
            {endowmentBase || ''}
          </Descriptions.item>
          <Descriptions.item label="单位参加失业保险缴费基数" span={3}>
            {unemploymentBase || ''}
          </Descriptions.item>
          <Descriptions.item label="单位参加职工基本医疗保险缴费基数" span={3}>
            {medicalBase || ''}
          </Descriptions.item>
          <Descriptions.item label="单位参加工伤保险缴费基数" span={3}>
            {employmentBase || ''}
          </Descriptions.item>
          <Descriptions.item label="单位参加生育保险缴费基数" span={3}>
            {maternityBase || ''}
          </Descriptions.item>
        </Descriptions>

        <div className={style.descriptionTitle}>本期实际缴费金额</div>
        <Descriptions title="" bordered>
          <Descriptions.item label="参加城镇职工基本养老保险本期实际缴费金额" span={3}>
            {endowmentPayAmount || ''}
          </Descriptions.item>
          <Descriptions.item label="参加失业保险本期实际缴费金额" span={3}>
            {unemploymentPayAmount || ''}
          </Descriptions.item>
          <Descriptions.item label="参加职工基本医疗保险本期实际缴费金额" span={3}>
            {medicalPayAmount || ''}
          </Descriptions.item>
          <Descriptions.item label="参加工伤保险本期实际缴费金额" span={3}>
            {employmentPayAmount || ''}
          </Descriptions.item>
          <Descriptions.item label="参加生育保险本期实际缴费金额" span={3}>
            {maternityPayAmount || ''}
          </Descriptions.item>
        </Descriptions>

        <div className={style.descriptionTitle}>单位累计欠缴金额</div>
        <Descriptions title="" bordered>
          <Descriptions.item label="单位参加城镇职工基本养老保险累计欠缴金额" span={3}>
            {endowmentOweAmount || ''}
          </Descriptions.item>
          <Descriptions.item label="单位参加失业保险累计欠缴金额" span={3}>
            {unemploymentOweAmount || ''}
          </Descriptions.item>
          <Descriptions.item label="单位参加职工基本医疗保险累计欠缴金额" span={3}>
            {medicalOweAmount || ''}
          </Descriptions.item>
          <Descriptions.item label="单位参加工伤保险累计欠缴金额" span={3}>
            {employmentOweAmount || ''}
          </Descriptions.item>
          <Descriptions.item label="单位参加生育保险累计欠缴金额" span={3}>
            {maternityOweAmount || ''}
          </Descriptions.item>
        </Descriptions>
      </>
    );
  }, [socialInfo]);
  return (
    <div className={style.all}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.title}>
            <span className={style.before}>
              产业通
            &emsp;
              <span className={style.point} />
            &emsp;
            </span>
            <span className={style.later}>企业</span>
          </div>
          <div className={style.imgBox}>
            <IconFont type={tabMap.industry} />
          </div>
        </div>
        <div className={style.msg}>
          公司详情 /&nbsp;
          <span style={{ fontWeight: 'bold' }}>{window.decodeURIComponent(window.atob(params.get('name')))}</span>
        </div>
      </div>
      <div className={style.basicInfo}>
        {Object.keys(basicInfo).length > 0 && basicInfoMemo}
      </div>
      {Object.keys(shareTableData).length > 0 && (
        <>
          <CommonTitle title="股东及出资信息" />
          <div className={style.shareInfo}>
            <ReportTable
              data={shareTableData}
              getList={getTableData}
            />
          </div>
        </>
      )}
      {Object.keys(assetsInfo).length > 0 && (
        <>
          <CommonTitle title="企业资产信息" />
          <div className={style.industryAssetsInfo}>
            {assetsInfoMemo}
          </div>
        </>
      )}
      {Object.keys(socialInfo).length > 0 && (
        <>
          <CommonTitle title="企业社保信息" />
          <div className={style.industrySocialInfo}>
            {socialInfoMemo}
          </div>
        </>
      )}
    </div>
  );
}

export default CompanyAnnualReport;
