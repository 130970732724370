// 饼图
import React, { useMemo, useRef } from 'react';
import { Spin } from 'antd';
import { VerticalAlignBottomOutlined, FileImageOutlined } from '@ant-design/icons';
import { ECharts } from '@sqke/design';
// import ChartNoData from '../ChartNoData';
import style from '../public/index.module.less';

const defaultColor = ['#0048DD', '#FF9031', '#FEA95B', '#FF5200', '#0063FD', '#00DA00', '#00E7B5', '#A6FFC2', '#FFC559', '#FD6205'];

/**
 *
 * @param {*} param0
 * @data Obj 数据坐标
 * @roseType Str 饼图类型 area/均匀玫瑰图  radius/按比例分配玫瑰图
 * @radius Arr 内外环比例
 * @position 文本在扇图的位置
 * @defaultPitch 默认选中第一个扇区
 * @labelShow labelk可见
 * @titleShow 中心标题是否可见
 * @title title 标题内容
 * @param { Function } labelName 接受到的参数是本区域的数据返回值是展示的数据
 * @showCount tooltip
 * @returns
 */
const Hollowpie = ({ data, titleShow = false, icon = 'circle', labelName = (params) => `${params.name}\n${params.data.proportion}%`, bottom = 40, color = defaultColor, showCount = true, title, labelShow = true, roseType, defaultPitch, legend = true, position = 'outside', center = ['50%', '50%'], fonstColor = '#919191', radius = ['60%', '80%'], isFetching, onClick, downloadImg, dataDownload, preview, setReportData, id }) => {
  const canvasRef = useRef();
  const onChartFinished = () => {
    if (!setReportData) return;
    const img = new Image();
    // pieMyChart1 要导出的图表
    img.src = canvasRef.current.getEchartsInstance().getDataURL({
      type: 'png',
      pixelRatio: 1, // 放大2倍
      backgroundColor: '#fff',
    });
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      // 添加文字
      const text = '数据来源：上奇产业通';
      // 设置字体和大小
      ctx.font = '600 14px PingFangSC, PingFang SC';
      // 设置文本颜色
      ctx.fillStyle = '333333';
      //  设置文本
      ctx.fillText(text, canvas.width - 160, canvas.height - 4);
      const dataURL = canvas.toDataURL();
      setReportData(params => ({ ...params, [id]: { ...params[id], el: dataURL } }));
    };
  };
  const hollowPieOption = (hollowPieData) => ({
    tooltip: {
      formatter: params => (showCount ? `${params.marker}${params.name}<br/>${params.value.toLocaleString()}
      ${hollowPieData.unit}\u3000\u3000${params.data.proportion}%` : `${params.marker}${params.name}\u3000${params.data.proportion}%`),
      axisPointer: {
        z: 1000,
      },
    },
    legend: legend && {
      type: 'plain',
      orient: 'horizontal',
      icon,
      itemHeight: 8,
      itemWidth: 8,
      align: 'auto',
      bottom: 15,
      textStyle: {
        color: '#000',
        fontSize: 12,
      },
      data: hollowPieData?.data?.map(item => item.name),
    },
    title: titleShow && {
      show: true,
      text: title,
      textStyle: {
        color: '#919191',
        fontSize: 12,
      },
      x: 'center',
      y: '40%',
    },
    series: [
      {
        name: hollowPieData.name,
        type: 'pie',
        roseType,
        center,
        // 无论占比是多少都不隐藏
        minShowLabelAngle: 0.1,
        radius,
        // 饼图伸出的线 1长度和2阶长度
        labelLine: {
          length: 10,
          length2: 10,
        },
        color,
        bottom,
        left: 0,
        label: {
          show: labelShow,
          position,
          fontSize: 12,
          color: fonstColor,
          formatter: labelName,
          overflow: 'none',
          textBorderColor: 'inherit',
          textBorderWidth: position === 'inside' ? 2 : 0,
        },
        legendHoverLink: true,
        selectedMode: 'single',
        data: hollowPieData.data || [],
        itemStyle: {
          borderColor: '#fff', // 设置分割线的颜色
          borderWidth: 1, // 设置分割线的宽度
        },
      },
    ],
  });
  const onEvents = useMemo(() => ({
    click: (e) => onClick && onClick(e),
    finished: onChartFinished,
  }), [data]);
  const chartEl = useMemo(() =>
    // if (!data || data?.data?.length > 0) {
    // eslint-disable-next-line implicit-arrow-linebreak
    (
      <div className={style.chart}>
        <ECharts
          style={{
            height: '100%',
            width: '100%',
          }}
          ref={canvasRef}
          onEvents={onEvents}
          option={hollowPieOption(data || {})}
          onChartReady={(e) => {
            if (defaultPitch) {
              e.dispatchAction({
                type: 'select',
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          }}
        />
      </div>
    ),
  // );
  // }
  // return <ChartNoData />;
  [JSON.stringify(data), labelShow]);

  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {
        !preview && (
          <div className={style.tool}>
            <div onClick={dataDownload}>
              <span>
                <VerticalAlignBottomOutlined />
              </span>
              <span>下载数据</span>
            </div>
            <div onClick={() => {
              downloadImg(canvasRef, title);
            }}
            >
              <span>
                <FileImageOutlined />
              </span>
              <span>保存图片</span>
            </div>
          </div>
        )
      }
      {
        chartEl
      }
    </Spin>
  );
};

export default Hollowpie;
