(function (window) {
  if (typeof window !== 'undefined' && !window.setImmediate) {
    window.setImmediate = (function setupSetImmediate() {
      return (
        window.msSetImmediate ||
        window.webkitSetImmediate ||
        window.mozSetImmediate ||
        window.oSetImmediate ||
        (function setupSetZeroTimeout() {
          if (!window.postMessage || !window.addEventListener) {
            return null;
          }

          var callbacks = [undefined];
          var message = 'zero-timeout-message';

          // Like setTimeout, but only takes a function argument.  There's
          // no time argument (always zero) and no arguments (you have to
          // use a closure).
          var setZeroTimeout = function setZeroTimeout(callback) {
            var id = callbacks.length;
            callbacks.push(callback);
            window.postMessage(message + id.toString(36), '*');

            return id;
          };

          window.addEventListener(
            'message',
            function setZeroTimeoutMessage(evt) {
              // Skipping checking event source, retarded IE confused this window
              // object with another in the presence of iframe
              if (
                typeof evt.data !== 'string' ||
                evt.data.substr(0, message.length) !== message /* ||
            evt.source !== window */
              ) {
                return;
              }

              evt.stopImmediatePropagation();

              var id = parseInt(evt.data.substr(message.length), 36);
              if (!callbacks[id]) {
                return;
              }

              callbacks[id]();
              callbacks[id] = undefined;
            },
            true
          );

          /* specify clearImmediate() here since we need the scope */
          window.clearImmediate = function clearZeroTimeout(id) {
            if (!callbacks[id]) {
              return;
            }

            callbacks[id] = undefined;
          };

          return setZeroTimeout;
        })() ||
        // fallback
        function setImmediateFallback(fn) {
          window.setTimeout(fn, 0);
        }
      );
    })();
  }
  if (typeof window !== 'undefined' && !window.clearImmediate) {
    window.clearImmediate = (function setupClearImmediate() {
      return (
        window.msClearImmediate ||
        window.webkitClearImmediate ||
        window.mozClearImmediate ||
        window.oClearImmediate ||
        // "clearZeroTimeout" is implement on the previous block ||
        // fallback
        function clearImmediateFallback(timer) {
          window.clearTimeout(timer);
        }
      );
    })();
  }
  if (typeof window !== 'undefined' && !window.CustomEvent) {
    class CustomEvent extends Event {
      #detail;

      constructor(type, options) {
        super(type, options);
        this.#detail = options?.detail ?? null;
      }

      get detail() {
        return this.#detail;
      }
    }
    window.CustomEvent = CustomEvent;
  }
})(global);
