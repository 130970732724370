import React, { useEffect } from 'react';
import RanKing from './Ranking';
import ArealDistribution from './EnterpriseDominant/ArealDistribution';
import RegisteredCapital from './EnterpriseDominant/RegisteredCapital';
import EstablishedTime from './EnterpriseDominant/EstablishedTime';
import IndustrialDistribution from './EnterpriseDominant/IndustrialDistribution';
import ListedSector from './StockFunding/ListedSector/index';
import FinancingScale from './StockFunding/FinancingScale';
import FinancingRound from './StockFunding/FinancingRound';
import HotTrack from './StockFunding/HotTrack';
import HotArea from './StockFunding/HotArea';
import ValueStatistics from './InnovationVitality/ValueStatistics';
import PatentDistribution from './InnovationVitality/PatentDistribution';
import PatentIndustryDistribution from './InnovationVitality/PatentIndustryDistribution';
import StandardTypeDistribution from './InnovationVitality/StandardTypeDistribution';
import NationalStandardDistribution from './InnovationVitality/NationalStandardDistribution';
import NationalStandardTypeDistribution from './InnovationVitality/NationalStandardTypeDistribution';
import IntelligentWriting from './IntelligentWriting';
import style from './index.module.less';

const ComponentList = {
  490: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <ArealDistribution key={key} url={url} title={title} id={490} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} tableTitle='区域' />,
  491: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <RegisteredCapital key={key} url={url} title={title} id={491} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['490'].ok} tableTitle='注册资本' />,
  492: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <EstablishedTime key={key} url={url} title={title} id={492} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['491'].ok} tableTitle='成立时间' />,
  493: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <IndustrialDistribution key={key} url={url} title={title} id={493} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['492'].ok} tableTitle='产业' />,
  494: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <ListedSector key={key} url={url} title={title} id={494} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['493'].ok} tableTitle='企业' />,
  495: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <FinancingScale key={key} url={url} title={title} id={495} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['494'].ok} tableTitle='时间' />,
  496: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <FinancingRound key={key} url={url} title={title} id={496} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['495'].ok} tableTitle='轮次' />,
  497: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <HotTrack key={key} url={url} title={title} id={497} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['496'].ok} tableTitle='产业' />,
  498: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <HotArea key={key} url={url} title={title} id={498} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['497'].ok} tableTitle='区域' />,
  499: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <ValueStatistics key={key} url={url} title={title} id={499} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['498'].ok} tableTitle='时间' />,
  500: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <PatentDistribution key={key} url={url} title={title} id={500} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['499'].ok} tableTitle='区域' />,
  501: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <PatentIndustryDistribution key={key} url={url} title={title} id={501} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['500'].ok} tableTitle='产业' />,
  502: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <StandardTypeDistribution key={key} url={url} title={title} id={502} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['501'].ok} tableTitle='标准类别' />,
  503: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <NationalStandardDistribution key={key} url={url} title={title} id={503} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['502'].ok} tableTitle='区域' />,
  504: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <NationalStandardTypeDistribution key={key} url={url} title={title} id={504} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['503'].ok} tableTitle='产业' />,
  505: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <RanKing key={key} url={url} title={title} id={505} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['490'].ok} />,
  506: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <RanKing key={key} url={url} title={title} id={506} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['490'].ok} />,
  507: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <RanKing key={key} url={url} title={title} id={507} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['490'].ok} />,
  508: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <RanKing key={key} url={url} title={title} id={508} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['490'].ok} />,
  509: ({ key, url, title, setDescribeObj, dataFilterParams, setReportData, describeObj }) => <RanKing key={key} url={url} title={title} id={509} setDescribeObj={setDescribeObj} dataFilterParams={dataFilterParams} setReportData={setReportData} ok={describeObj['490'].ok} />,
};

const initDescribeObjFun = () => {
  const initDescribeObj = {};
  Object.keys(ComponentList).forEach(item => {
    initDescribeObj[item] = { ok: false };
  });
  return initDescribeObj;
};

const Content = ({ data, setReportData, dataFilterParams, setIntelligentWriting, describeObj, setDescribeObj, refreshAble, setRefreshAble }) => {
  useEffect(() => {
    setDescribeObj(initDescribeObjFun());
  }, []);
  return (
    <div className={style.box}>
      {
        data?.map(item => {
          if (item?.meta_data?.type === 'data_analysis') {
            return (
              <div className={style.content} key={item.name}>
                <IntelligentWriting
                  data={data}
                  describeObj={describeObj}
                  setIntelligentWriting={setIntelligentWriting}
                  refreshAble={refreshAble}
                  setRefreshAble={setRefreshAble}
                />
              </div>
            );
          }
          return (
            <div className={style.content} key={item.name}>
              <div className={style.title}>
                {item.name}
              </div>
              {
                item?.meta_data?.type !== 'data_analysis' && item.child.map(item2 => {
                  try {
                    return ComponentList[`${item2.child[0]?.id}`]({ key: item.id + item.catalogue_id + item2.id, url: item2.child[0]?.component_url, title: item2.name, setDescribeObj, dataFilterParams, setReportData, describeObj });
                  } catch {
                    return null;
                  }
                })
              }
            </div>
          );
        })
      }
    </div>
  );
};

export default Content;
