import React, { useEffect } from 'react';
import FunnelTable from '../../../component/FunnelTable';
// import BarTable from '../../component/BarTable';

import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const EstablishedTime = (props) => {
  const { url, dataFilterParams, reportData, ok } = props;
  const { data, isFetching } = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData || !ok });
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <FunnelTable {...props} data={data} isFetching={isFetching} chartType="金字塔" />;
};

export default EstablishedTime;
