/*
* Author: zhangmeng
* Description: '微信绑定'
* Date: 2021/01/19
*/

import React, { useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import { getWxBindStatus, getWxCode, getTicketCode, bindUser } from '../../../api/login';
import { IconFont } from '../../../utils/mapIcon';
import style from './index.module.less';

function BindWx(props) {
  const { setModalVisible, getInfo, queryBindStatusFun } = props;
  const [codeExpired, setCodeExpired] = useState(false);
  const [ticketCode, setTicketCode] = useState('');
  const phone = localStorage.getItem('ph');
  const timer = useRef(null);
  const timer2 = useRef(null);
  const closePoll = useRef(false);

  useEffect(() => {
    closePoll.current = codeExpired;
  }, [codeExpired]);

  useEffect(() => () => {
    clearTimeout(timer.current);
    clearTimeout(timer2.current);
    closePoll.current = true;
  }, []);

  const findWXStatus = (refreshFucn, ticket) => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    bindUser({ ticket }).then(res => {
      if (res.status === '00000') {
        if (res.data) {
          getInfo();
          queryBindStatusFun();
          setModalVisible(false);
          message.success('微信绑定成功！');
        } else if (!closePoll.current) {
          timer.current = setTimeout(() => {
            findWXStatus(refreshFucn, ticket);
          }, 1000);
        } else {
          message.error(res.message);
        }
      } else if (res.status === 'A0326') {
        message.error(res.message);
        refreshFucn();
      } else if (res.status === 'B0101') {
        timer.current = setTimeout(() => {
          findWXStatus(refreshFucn, ticket);
        }, 1000);
      } else {
        message.warning(res.message);
        setModalVisible();
      }
    }).catch(() => {
      if (!closePoll.current) {
        timer.current = setTimeout(() => {
          findWXStatus(refreshFucn, ticket);
        }, 1000);
      }
    });
  };

  const getStateData = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    getTicketCode({
      event_key: '绑定服务号',
    }).then(res => {
      if (res.status === '00000') {
        const { ticket, picbase64 } = res.data;
        setTicketCode(picbase64);
        // const { appid, redirect_uri: uri, state } = res.data;
        // // eslint-disable-next-line no-undef,no-unused-vars
        // const obj = new WxLogin({
        //   self_redirect: true,
        //   id: 'login_container',
        //   appid,
        //   scope: 'snsapi_login',
        //   redirect_uri: uri,
        //   state,
        //   style: 'black',
        //   href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6MTc5cHg7Ym9yZGVyOiAxcHggc29saWQgYmxhY2t9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMjAwcHg7Zm9udC1zaXplOiAxNHB4O2NvbG9yOiAjOTE5MTkxO2xldHRlci1zcGFjaW5nOiAxcHg7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9IA==',
        // });
        setCodeExpired(false);
        closePoll.current = false;
        timer.current = setTimeout(() => {
          findWXStatus(getStateData, ticket);
        }, 1000);
        timer2.current = setTimeout(() => {
          setCodeExpired(true);
        }, 120 * 1000);
      }
    });
  };

  useEffect(() => {
    getStateData();
  }, []);

  return (
    <div className={style.all}>
      <div className={style.wxTitle}>绑定微信，尽享快捷登录，一键分享报告给熟悉的人</div>
      <div
        title="绑定微信"
        id="login_container"
        sandbox="allow-scripts allow-top-navigation allow-same-origin"
        style={{ height: '270px', textAlign: 'center' }}
      >
        {
          ticketCode && (<img src={`data:image/jpeg;base64,${ticketCode}`} style={{ width: '190px', height: '190px', marginTop: '10px' }} alt="" />)
        }
      </div>
      <div
        className={style.refresh}
        style={{ display: codeExpired ? '' : 'none' }}
        onClick={() => getStateData()}
      >
        <IconFont type="iconsync" style={{ marginRight: '4px' }} />
        刷新二维码
      </div>
      <div className={style.expiredCover} style={{ display: codeExpired ? '' : 'none' }}>二维码已失效</div>
    </div>
  );
}

export default BindWx;
