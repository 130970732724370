/*
* Author: zhangmeng
* Description: '报告'
* Date: 2022/06/30
*/

import React, { useState, useCallback, useRef } from 'react';
import { Input, Select, message } from 'antd';
import searchPng from '../../assets/images/reportPage/search.png';
import style from './index.module.less';
import ReportModal from '../../components/ReportModal';
import { openNewPage } from '../../utils/urlMethods';
import { judgeArea } from '../../utils/tool';
import ReportList from './ReportList';
import { getCompnayId, resetTemplateReport, getRegionName } from '../../api/reportGeneration';
// import { usePostCityTreeQuery, usePostIndustryTreeQuery } from '../../service/dataSearch';
import { useGetReportQuery, useGetReportCategoryQuery } from '../../service/reportPage';

const { Option } = Select;
// const { SHOW_PARENT } = TreeSelect;
const tabs = [
  {
    title: '热门报告',
    key: 'hot',
  },
  {
    title: '最新报告',
    key: 'time',
  },
  {
    title: '全部报告',
    key: 'rank',
  },
];

function Report() {
  const [tab, setTab] = useState('hot');
  const [search, setSearch] = useState('');
  const [searchConfirm, setSearchConfirm] = useState('');
  const [scenes, setScenes] = useState('all');
  // const [area, setArea] = useState([]);
  // const [industry, setIndustry] = useState([]);
  const [choseModel, setChoseModel] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [templateType, setTemplateType] = useState('');
  const [choseLoading, setChoseLoading] = useState(false);
  const choseTemplateMsg = useRef(null);

  const { data: scenesData } = useGetReportCategoryQuery();
  // const { data: areaTree } = usePostCityTreeQuery();
  // const { data: industryTree } = usePostIndustryTreeQuery();
  const { data: reportList } = useGetReportQuery({
    // area_key: area,
    // industry_code: industry,
    category_name: scenes,
    keyword: searchConfirm,
    sort_key: tab,
  });
  const searchReport = () => {
    setSearchConfirm(search);
  };

  // 打开报告预览页
  const openNewReportPage = useCallback((curTemplateId, data, curType, conditions) => {
    let obj = {
      curTemplateId,
      title: choseTemplateMsg.current.template_name,
      id: data,
    };
    curType.forEach(item => {
      if (item === 'area') {
        obj = {
          ...obj,
          area: conditions.cityName,
          areaKey: conditions.cityValue,
        };
      }
      if (item === 'industry') {
        obj = {
          ...obj,
          industry: conditions.industryName,
          industryKey: conditions.industryValue,
        };
      }
      if (item === 'company') {
        obj = {
          ...obj,
          name: conditions.companyName,
        };
      }
    });
    const msg = window.btoa(window.encodeURIComponent(JSON.stringify(obj)));
    // template_source区分精品(3),新模板(2),旧模板(1)
    const templateSource = choseTemplateMsg.current.template_source;
    if (templateSource === 1) {
      openNewPage('reportPreview', {
        msg,
      });
    } else if (templateSource === 2) {
      openNewPage('scheme', {
        msg,
      });
    }
  }, []);

  // 各种模板-复用模板逻辑
  const analysisReportConditionChange = async (curTemplateId, curType, conditions) => {
    setChoseLoading(true);
    let companymsg = '';
    if (curType.includes('company')) {
      await getCompnayId({
        company_name: conditions.companyName,
      }).then(res => {
        if (res.status === '00000') {
          companymsg = res.data;
        }
        if (res.status === 'c0401') {
          companymsg = 'error';
          message.error(res.message);
          setChoseLoading(false);
        }
      });
    }
    if (curType.includes('area')) {
      getRegionName({
        code: conditions.cityValue?.[0],
        duplicate: true,
        separator: '',
      }).then(res => {
        if (res.status === '00000') {
          conditions.cityName = res.data;
        }
      });
    }
    if (companymsg === 'error') return;
    let params = {
      template_id: curTemplateId,
      template_source: choseTemplateMsg.current.template_source,
    };
    curType.forEach(item => {
      if (item === 'area') {
        if (curTemplateId === '16,23') {
          params = {
            ...params,
            template_id: !conditions.is_province_directly ? judgeArea(conditions.cityValue, params.template_id) : '16',
            area_key_list: conditions.cityValue,
          };
        } else {
          params = {
            ...params,
            template_id: judgeArea(conditions.cityValue, curTemplateId),
            area_key_list: conditions.cityValue,
          };
        }
      }
      if (item === 'industry') {
        params = {
          ...params,
          industry_code_list: conditions.industryValue,
        };
      }
      if (item === 'company') {
        params = {
          ...params,
          company_info_dict: companymsg || {},
        };
      }
      if (item === 'diy_tag') {
        params = {
          ...params,
          diy_tag_list: conditions.diyTagCode || [],
        };
      }
    });
    resetTemplateReport(params).then(res => {
      if (res.status === '00000') {
        setChoseLoading(false);
        openNewReportPage(params.template_id, res.data, curType, conditions);
        setChoseModel(false);
      } else {
        setChoseLoading(false);
        message.error(res.message);
      }
    }).catch(() => {
      setChoseLoading(false);
    });
  };

  const pushReportDetail = useCallback((reportMsg) => {
    const { template_source: source } = reportMsg;
    if (source === 3) {
      const { id, Buy_state: isApply, template_price: price, cover_url: coverImg,
        template_name: title, sub_template_name: subTitle } = reportMsg;
      openNewPage('boutiqueDetail', {
        msg: window.btoa(window.encodeURIComponent(JSON.stringify({
          title,
          subTitle,
          isApply,
          price,
          coverImg,
          id,
        }))),
      });
    } else {
      choseTemplateMsg.current = reportMsg;
      setTemplateId(reportMsg.id);
      setTemplateType(reportMsg.outer_macro);
      setChoseModel(true);
    }
  }, []);
  // const selectCategory = (item) => {
  //   if (item === 'all') {
  //     setScenes(['all']);
  //     return;
  //   }
  //   if (scenes.includes(item)) {
  //     setScenes(params => {
  //       const newData = params.filter(it => it !== item && it !== 'all');
  //       if (newData.length < 1) {
  //         return ['all'];
  //       }
  //       return newData;
  //     });
  //     return;
  //   }
  //   setScenes(params => ([...params, item].filter(it => it !== 'all')));
  // };
  return (
    <div className={style.all}>
      <div className={style.headerTab}>
        <div className={style.left}>
          {
            tabs.map(item => (
              <div
                className={`${style.tab} ${tab === item.key ? style.selected : ''}`}
                key={item.key}
                onClick={() => {
                  setScenes(['all']);
                  // setArea([]);
                  // setIndustry([]);
                  setTab(item.key);
                }}
              >
                {item.title}
              </div>
            ))
          }
        </div>
        <div className={style.right}>
          <div>
            <Select defaultValue="all" value={scenes} style={{ width: 200 }} onChange={v => setScenes(v)}>
              {
                scenesData && scenesData.map((item, index) => (
                  <Option value={item.value} key={index}>{item.title}</Option>
                ))
              }
            </Select>
          </div>
          <div className={style.inputBox}>
            <Input
              placeholder="搜索报告名称"
              style={{
                width: 200,
              }}
              suffix={
                <span onClick={searchReport}>
                  <img src={searchPng} alt="" />
                </span>
              }
              onPressEnter={searchReport}
              onChange={e => setSearch(e.target.value)}
            />
          </div>

        </div>
      </div>
      {/* <div className={style.selector}> */}
      {/* <div className={style.row}>
          <span className={style.title}>区域</span>
          <span className={style.treeBox}>
            {areaTree && (
              <TreeSelect
                value={area}
                treeData={(areaTree || [])}
                showCheckedStrategy={SHOW_PARENT}
                placeholder="区域选择"
                notFoundContent
                // treeCheckable
                treeDefaultExpandedKeys={[areaTree.length > 0 && areaTree[0].value]}
                onChange={p => setArea(p)}
                maxTagCount={3}
                // suffixIcon={<DownOutlined />}
                showArrow
                style={{ width: '300px' }}
                popupClassName={style.dropTreeSelect}
              />
            )}
          </span>
        </div> */}
      {/* <div className={style.row}>
          <span className={style.title}>产业</span>
          <span className={style.treeBox}>
            {industryTree && (
              <TreeSelect
                value={industry}
                treeData={([industryTree] || [])}
                showCheckedStrategy={SHOW_PARENT}
                placeholder="产业选择"
                // treeCheckable
                treeDefaultExpandedKeys={[[industryTree].length > 0 && [industryTree][0].value]}
                onChange={p => setIndustry(p)}
                maxTagCount={3}
                // suffixIcon={<DownOutlined />}
                showArrow
                style={{ width: '300px' }}
                popupClassName={style.dropTreeSelect}
              />
            )}
          </span>
        </div> */}
      {/* <div className={style.row}>
          <span className={style.title}>类别:</span>
          <div className={style.selectList}>
            {
              scenesData?.map(item => (
                <span key={item.title} className={classNames({ [style.pitch]: scenes.includes(item.value) })} onClick={() => { selectCategory(item.value); }}>{item.title}</span>
              ))
            }
          </div>
        </div> */}
      {/* </div> */}
      <div className={style.banner} />
      <div className={style.reportBox}>
        {
          reportList && <ReportList reportList={reportList?.搜索结果} pushReportDetail={pushReportDetail} />
        }
      </div>
      {
        reportList?.模板报告.length > 0 && (
          <div className={style.templateReport}>
            <div className={style.interval}>
              <p />
              <p>试试3秒生成您想要的报告吧</p>
              <p />
            </div>
            <div className={style.reportBox}>
              <ReportList reportList={reportList.模板报告} pushReportDetail={pushReportDetail} />
            </div>
          </div>
        )
      }
      {choseModel && (
        <ReportModal
          templateId={templateId}
          templateType={templateType}
          visible={choseModel}
          setVisible={setChoseModel}
          startAnalysis={analysisReportConditionChange}
          loading={choseLoading}
        />
      )}
    </div>
  );
}

export default Report;
