import React from 'react';
import RadarChart from './components/RadChart';
import style from './index.module.less';

const list = ['', '', '', '', ''];

const Index = ({
  radar,
  rank,
}) => (
  <div className={style.box}>
    <div className={style.resultTitle}>
      综合评估结果
    </div>
    <div className={style.result}>
      <ul>
        {
            list.map((item, index) => (
              <li key={index}>
                <p>{rank[index]?.company_name}</p>
                <p>{rank[index]?.company_name && radar.abscissa_list[index]}</p>
                <p>
                  {rank[index]?.company_name && '总得分排名'}
                </p>
                {
                  rank[index]?.company_name && <p>{rank[index]?.desc[rank[index]?.desc.length - 1]}</p>
                }
              </li>
            ))
          }
      </ul>
      <div style={{ width: '532px', height: '396px' }}>
        {
            radar && <RadarChart data={radar} company={rank} />
          }
      </div>
    </div>
  </div>
);

export default Index;
