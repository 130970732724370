// 规模拓展
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import style from './index.module.less';
import { useGetScaleExpansionQuery } from '../../../../../service/companyDetail';

function ScaleExpansion(props) {
  const { companyId } = props;

  const { data, isFetching } = useGetScaleExpansionQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const { social_security_count: socialSecurity, recruit_count: recruit } = data ?? {};
  const socialSecurityOption = useMemo(() => {
    const series = socialSecurity?.series?.map(item => (
      {
        ...item,
        type: 'line',
        areaStyle: {},
        data: item.data.map(val => ({
          value: val,
          unit: item.unit,
        })),
      }
    ));
    return {
      xAxis: {
        // name: socialSecurity?.xName,
        // nameTextStyle: {
        //   padding: [0, 0, 0, -10],
        // },
        type: 'category',
        data: socialSecurity?.x,
      },
      tooltip: {
        formatter: (params) => (
          `${params.seriesName}<br />${params.marker}${params.name}\u3000\u3000${params.value}${params.data.unit}`
        ),
      },
      yAxis: {
        name: `${socialSecurity?.yName}(${socialSecurity?.series?.[0]?.unit})`,
        nameTextStyle: {
          padding: [0, 30, 0, 0],
        },
      },
      series,
    };
  }, [socialSecurity]);

  const recruitOption = useMemo(() => {
    const series = recruit?.series?.map(item => (
      {
        ...item,
        type: 'line',
        areaStyle: {},
        data: item.data.map(val => ({
          value: val,
          unit: item.unit,
        })),
      }
    ));

    return {
      xAxis: {
        // name: recruit?.xName,
        // nameTextStyle: {
        //   padding: [0, 0, 0, -10],
        // },
        type: 'category',
        axisLabel: {
          rotate: 45,
          interval: 0,
        },
        data: recruit?.x,
      },
      tooltip: {
        formatter: (params) => (
          `${params.seriesName}<br />${params.marker}${params.name}\u3000\u3000${params.value}${params.data.unit}`
        ),
      },
      yAxis: {
        name: `${recruit?.yName}(${recruit?.series?.[0]?.unit})`,
        nameTextStyle: {
          padding: [0, 0, 0, 40],
        },
        minInterval: 1,
      },
      series,
    };
  }, [recruit]);
  const linChartEl1 = useMemo(() => {
    if (!socialSecurity) return <div style={{ minHeight: '300px' }}><Spin /></div>;
    if (socialSecurity.series?.length > 0) {
      return (
        <>
          <div className={style.subTitle}>
            {socialSecurity?.title}
          </div>
          <ECharts
            showLoading={isFetching}
            className={style.chart}
            option={socialSecurityOption}
          />
        </>
      );
    }
    return null;
  }, [socialSecurity]);

  const linChartEl2 = useMemo(() => {
    if (!recruit) return <div style={{ minHeight: '300px' }}><Spin /></div>;
    if (recruit?.series?.[0]?.data?.filter(item => item !== '-').length > 0) {
      return (
        <>
          <div className={style.subTitle}>
            {recruit?.title}
          </div>
          <ECharts
            showLoading={isFetching}
            className={style.chart}
            option={recruitOption}
          />
        </>
      );
    }
    return null;
  }, [socialSecurity]);

  return (
    <div className={style.all} id="规模拓展">
      <div className={style.title}>规模拓展</div>
      {
        linChartEl1
      }
      {
        linChartEl2
      }
    </div>
  );
}

export default ScaleExpansion;
