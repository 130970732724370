// 行业竞争力
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import style from './index.module.less';
import { useDetailsRadarChartQuery, useDescriptionQuery } from '../../../../../../service/tool';

function IndustryCompetitiveness(props) {
  const { companyId } = props;

  const { data: radarData, isFetching: radarIsFetching } = useDetailsRadarChartQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const { data: listData, isFetching: tableIsFetching } = useDescriptionQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const option = useMemo(() => {
    const series = radarData?.series?.map(item => (
      {
        name: item.name,
        type: 'radar',
        data: item.data,
        label: {
          show: false,
        },
        areaStyle: {
          color: 'rgba(242, 153, 70, 0.21)',
        },
        lineStyle: {
          width: 2,
          color: '#F49944',
        },
      }
    ));
    const indicator = radarData?.indicator?.map((item, index) => (
      {
        name: item,
        max: 100,
        columns: [series?.[0]?.data?.[0]?.value?.[index]],
        axisLabel: {
          show: index === 0,
        },
      }
    ));

    return {
      radar: {
        radius: '60%',
        nameGap: 10,
        axisName: {
          formatter: (name, indicatorOpt) => `{title|${name || ''}}\n{value|${indicatorOpt?.columns?.[0] || '-'}%}`,
          rich: {
            title: {
              padding: [0, 0, 8, 0],
              fontSize: 14,
              align: 'center',
            },
            value: {
              width: 54,
              height: 22,
              fontSize: 14,
              color: '#1961F5',
              align: 'center',
              verticalAlign: 'middle',
              padding: [2, 0, 0, 0],
              backgroundColor: 'rgba(217,232,255, 0.5)',
              borderWidth: 1,
              borderRadius: 4,
            },
          },
        },
        indicator,
      },
      tooltip: {
        show: false,
      },
      series,
    };
  }, [radarData]);

  // const tableData = useMemo(() => {
  //   const { pool } = listData ?? {};
  //   const keys = Object.keys(pool ?? {});
  //   const values = Object.values(pool ?? {});
  //   const dataSource = keys.map((item, index) => ({
  //     key: index,
  //     name: item,
  //     value: values[index],
  //   }));
  //   return {
  //     dataSource,
  //     columns: [
  //       {
  //         title: 'name',
  //         dataIndex: 'name',
  //         width: 150,
  //         align: 'center',
  //       },
  //       {
  //         title: 'value',
  //         dataIndex: 'value',
  //       },
  //     ],
  //   };
  // }, [listData]);

  const chartEl = useMemo(() => {
    if (radarIsFetching) return <div className={style.loading}><Spin /></div>;
    if (radarData?.series?.length > 0) {
      return (
        <ECharts
          // showLoading={isFetching}
          className={style.chart}
          option={option}
        />
      );
    }
    return <div className={style.noData}>该企业无此数据图谱</div>;
  }, [radarData]);
  return (
    <div className={style.all} id="行业竞争力">
      <div className={style.title}>行业竞争力</div>
      <div className={style.content}>
        <div className={style.chartBox}>
          {
            chartEl
          }
        </div>
        <div className={style.list}>
          {
            tableIsFetching ? <div className={style.loading}><Spin /></div> : (
              <div>
                {listData && Object.keys(listData.pool).map(item => (
                  <div key={item} className={style.cell}>
                    <span>{item}</span>
                    <span title={listData.pool[item]}>{listData.pool[item]}</span>
                  </div>
                ))}
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default IndustryCompetitiveness;
