// 技术驱动
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import style from './index.module.less';
import { useGetTechnologyDrivenQuery } from '../../../../../service/companyDetail';

function TechnologyDriven(props) {
  const { companyId } = props;

  const { data, isFetching } = useGetTechnologyDrivenQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const { invent_patent_time_series: inventPatent, per_average_patent: perAveragePatent } = data ?? {};

  const inventPatentOption = useMemo(() => {
    const series = inventPatent?.series?.map(item => (
      {
        name: item.name,
        type: 'line',
        data: item.data,
      }
    ));

    return {
      xAxis: {
        name: inventPatent?.xName,
        nameTextStyle: {
          padding: [0, 0, 0, -10],
        },
        type: 'category',
        data: inventPatent?.x,
      },
      tooltip: {},
      yAxis: {
        name: `${inventPatent?.yName}(${inventPatent?.series?.[0]?.unit})`,
        nameTextStyle: {
          padding: [0, 0, 0, 40],
        },
      },
      series,
    };
  }, [inventPatent]);

  const perAveragePatentOption = useMemo(() => {
    const series = perAveragePatent?.series?.map(item => (
      {
        name: item.name,
        type: item.type,
        data: item.data,
        areaStyle: item.type === 'line' ? {} : null,
        yAxisIndex: (item.name === '专利数量' || item.name === '人均专利数量') ? 1 : 0,
      }
    ));

    return {
      xAxis: {
        // name: perAveragePatent?.xName,
        // nameTextStyle: {
        //   padding: [0, 0, 0, -10],
        // },
        type: 'category',
        data: perAveragePatent?.x,
      },
      tooltip: {},
      legend: {
        bottom: 0,
        itemGap: 30,
      },
      yAxis: [
        {
          name: perAveragePatent?.series?.[0]?.unit,
          nameTextStyle: {
            padding: [0, 30, 0, 0],
          },
        },
        {
          name: perAveragePatent?.series?.[0]?.unit,
          position: 'right',
          nameTextStyle: {
            padding: [0, 0, 0, 30],
          },
        },
      ],
      series,
    };
  }, [perAveragePatent]);
  const linChartEl = useMemo(() => {
    if (!inventPatent) return <div style={{ minHeight: '300px' }}><Spin /></div>;
    if (inventPatent.series?.length > 0) {
      return (<ECharts
        showLoading={isFetching}
        className={style.chart}
        option={inventPatentOption}
      />);
    }
    return <div className={style.noData}>该企业无此数据图谱</div>;
  }, [inventPatent]);

  return (
    <div className={style.all} id="技术驱动">
      <div className={style.title}>技术驱动</div>
      {/* <div className={style.subTitle}>
        {inventPatent?.title}
      </div> */}
      {
        linChartEl
      }
      {/* <div className={style.subTitle}>
        {perAveragePatent?.title}
      </div>
      {
        perAveragePatent?.series?.length > 0 ? (
          <ECharts
            showLoading={isFetching}
            className={style.chart}
            option={perAveragePatentOption}
          />
        ) : (
          <div className={style.noData}>该企业无此数据图谱</div>
        )
      } */}
    </div>
  );
}

export default TechnologyDriven;
