import React from 'react';
import { Table, Pagination, message } from 'antd';
import style from '../../../pages/MySelf/index.module.less';

function ExportTable(props) {
  const { pagination, getGenerateTable, exportList } = props;

  const exportColumns = [
    {
      title: '文件名',
      dataIndex: 'file_name',
      width: '30%',
      ellipsis: true,
    },
    {
      title: '导出条数',
      dataIndex: 'data_number',
      width: '12%',
      ellipsis: true,
    },
    {
      title: '申请时间',
      dataIndex: 'create_time',
      width: '20%',
      ellipsis: true,
    },
    {
      title: '操作',
      dataIndex: 'operator',
      render: (_, record) => (
        <div
          className={style.dl}
          onClick={() => {
            if (record.file_path) {
              window.open(record.file_path);
              message.destroy();
              message.success('下载成功！', 1);
            }
          }}
        >
          <span className={style.downloadWord}>下载</span>
        </div>
      ),
    },
  ];
  return (
    <div className={`${style.tableContainer} ${style.generate}`}>
      <Table
        rowKey={record => record.id}
        columns={exportColumns}
        dataSource={exportList}
        pagination={false}
      />
      {pagination.total > 0 && (
      <Pagination
        className={style.pagination}
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        pageSizeOptions={[10]}
        showQuickJumper
        hideOnSinglePage
        onChange={(page, pageSize) => {
          getGenerateTable({ current: page, pageSize });
        }}
      />)}
    </div>
  );
}

export default ExportTable;
