import React from 'react';
import { Table as AntTable } from 'antd';
import classnames from 'classnames';
import TableNoData from '../IndustryCompanyDetail/CompanyContent/TableNoData';
import styles from './index.module.less';

function Table({
  columns,
  dataSource,
  current = 1,
  pageSize = 20,
  pagination,
  total,
  loading,
  onRow,
  rowKey = 'index',
  onChange,
  className,
  noShowJumper,
  hideOnSinglePage = false,
}) {
  let showJumper = true;
  if (noShowJumper) {
    showJumper = false;
  }
  return (
    <AntTable
      className={classnames(styles.table, className)}
      bordered={false}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      onRow={onRow}
      onChange={onChange}
      rowKey={rowKey}
      locale={{ emptyText: () => {
        if (!dataSource) {
          return '';
        }
        return <TableNoData />;
      } }}
      pagination={(
        pagination ?? {
          current,
          pageSize,
          total,
          hideOnSinglePage,
          showSizeChanger: false,
          showQuickJumper: showJumper,
          responsive: true,
          showLessItems: true,
        }
      )}
    />
  );
}

export default Table;
