const CHINA_INSET_JSON = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: '大陆+港澳',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              [120.991276, 40.744181],
              [121.028848, 40.746448],
              [121.038087, 40.711081],
              [120.983269, 40.712895],
              [120.939537, 40.686586],
              [120.858233, 40.684771],
              [120.8299, 40.671158],
              [120.837291, 40.644378],
              [120.822509, 40.593966],
              [120.72827, 40.539423],
              [120.674683, 40.471183],
              [120.616169, 40.457071],
              [120.615553, 40.413352],
              [120.596459, 40.398772],
              [120.599539, 40.355471],
              [120.537329, 40.325372],
              [120.52193, 40.304842],
              [120.523778, 40.256914],
              [120.465264, 40.178787],
              [120.371641, 40.174673],
              [120.273091, 40.127111],
              [120.161606, 40.095994],
              [120.134504, 40.074479],
              [120.092005, 40.077684],
              [119.955882, 40.046544],
              [119.913998, 39.988349],
              [119.854252, 39.988349],
              [119.872114, 39.96038],
              [119.842549, 39.955794],
              [119.81668, 39.978263],
              [119.791426, 39.952124],
              [119.726137, 39.941115],
              [119.683637, 39.921846],
              [119.642369, 39.925058],
              [119.617115, 39.901652],
              [119.578927, 39.907619],
              [119.540739, 39.88834],
              [119.520413, 39.83828],
              [119.536427, 39.808871],
              [119.466826, 39.810709],
              [119.396609, 39.761053],
              [119.357805, 39.721946],
              [119.269726, 39.498385],
              [119.316537, 39.436953],
              [119.317153, 39.410608],
              [119.272805, 39.36344],
              [119.185342, 39.342157],
              [119.121284, 39.281513],
              [119.096031, 39.242136],
              [119.038132, 39.211545],
              [119.023966, 39.186971],
              [118.926031, 39.123407],
              [118.896466, 39.139651],
              [118.951901, 39.17584],
              [118.87614, 39.14986],
              [118.857662, 39.162851],
              [118.814546, 39.138723],
              [118.76096, 39.133618],
              [118.637156, 39.157284],
              [118.578642, 39.130834],
              [118.588497, 39.107623],
              [118.533062, 39.090907],
              [118.570634, 38.999363],
              [118.604511, 38.971458],
              [118.539837, 38.910028],
              [118.491178, 38.909097],
              [118.377845, 38.971923],
              [118.366143, 39.016101],
              [118.319331, 39.009592],
              [118.225092, 39.034694],
              [118.156723, 39.145684],
              [118.120999, 39.186043],
              [118.037231, 39.220353],
              [117.977485, 39.205982],
              [117.96455, 39.172593],
              [117.871543, 39.122479],
              [117.837667, 39.056999],
              [117.855529, 38.957502],
              [117.898029, 38.948661],
              [117.847522, 38.855535],
              [117.778536, 38.869046],
              [117.752051, 38.847614],
              [117.64611, 38.828972],
              [117.646725, 38.788875],
              [117.740964, 38.753888],
              [117.729261, 38.680127],
              [117.65658, 38.660507],
              [117.639334, 38.62686],
              [117.638102, 38.545013],
              [117.68553, 38.539397],
              [117.644878, 38.527696],
              [117.678754, 38.477126],
              [117.72495, 38.457451],
              [117.730493, 38.425115],
              [117.781, 38.374004],
              [117.84629, 38.367906],
              [117.937449, 38.387606],
              [117.948536, 38.346323],
              [117.895565, 38.30173],
              [117.896797, 38.279659],
              [118.018753, 38.202589],
              [118.045238, 38.213873],
              [118.112376, 38.210112],
              [118.177665, 38.186601],
              [118.217085, 38.147087],
              [118.331034, 38.124968],
              [118.404331, 38.120732],
              [118.431432, 38.106138],
              [118.44991, 38.124497],
              [118.504729, 38.114141],
              [118.552156, 38.055744],
              [118.597736, 38.078826],
              [118.607591, 38.129204],
              [118.726467, 38.154144],
              [118.853967, 38.155085],
              [118.908169, 38.139558],
              [118.974075, 38.094367],
              [119.004872, 37.992114],
              [119.110813, 37.921349],
              [119.12806, 37.814601],
              [119.217371, 37.810347],
              [119.225378, 37.753128],
              [119.275269, 37.739407],
              [119.259871, 37.702492],
              [119.137915, 37.705332],
              [119.080016, 37.696337],
              [118.99748, 37.632396],
              [118.939582, 37.527129],
              [118.940814, 37.505773],
              [119.001176, 37.318527],
              [119.045524, 37.299015],
              [119.054147, 37.254738],
              [119.12806, 37.254738],
              [119.136683, 37.231399],
              [119.190885, 37.2595],
              [119.2069, 37.2233],
              [119.282044, 37.212341],
              [119.298675, 37.197567],
              [119.299291, 37.142738],
              [119.329472, 37.115548],
              [119.365812, 37.126043],
              [119.489616, 37.13463],
              [119.576463, 37.127475],
              [119.678709, 37.157999],
              [119.698419, 37.126998],
              [119.744615, 37.135107],
              [119.83023, 37.225682],
              [119.865339, 37.23378],
              [119.89244, 37.263786],
              [119.883201, 37.310913],
              [119.837006, 37.346596],
              [119.843781, 37.376557],
              [119.926933, 37.386541],
              [119.949723, 37.419812],
              [120.054432, 37.447368],
              [120.144359, 37.482036],
              [120.246605, 37.556543],
              [120.208417, 37.58879],
              [120.215192, 37.621023],
              [120.272475, 37.636661],
              [120.269395, 37.658453],
              [120.217656, 37.67503],
              [120.227511, 37.693497],
              [120.367945, 37.697758],
              [120.466496, 37.757858],
              [120.517619, 37.750289],
              [120.579828, 37.760696],
              [120.634031, 37.796165],
              [120.656821, 37.792855],
              [120.733197, 37.833506],
              [120.83298, 37.821691],
              [120.865008, 37.833034],
              [120.938305, 37.821219],
              [120.942001, 37.787654],
              [120.996819, 37.758331],
              [121.037471, 37.718585],
              [121.144029, 37.721898],
              [121.160043, 37.699178],
              [121.142797, 37.661295],
              [121.153884, 37.613914],
              [121.217326, 37.582626],
              [121.304789, 37.5831],
              [121.358376, 37.597798],
              [121.362071, 37.634292],
              [121.4366, 37.600642],
              [121.389789, 37.59685],
              [121.400876, 37.557966],
              [121.460006, 37.518587],
              [121.4791, 37.474914],
              [121.565331, 37.440242],
              [121.635548, 37.49438],
              [121.66573, 37.47349],
              [121.772903, 37.466366],
              [121.923808, 37.473015],
              [121.997105, 37.493906],
              [122.015583, 37.529976],
              [122.08888, 37.554171],
              [122.15109, 37.557017],
              [122.1708, 37.542312],
              [122.136307, 37.512417],
              [122.166488, 37.439292],
              [122.234857, 37.469216],
              [122.274278, 37.458293],
              [122.284133, 37.426464],
              [122.33156, 37.414585],
              [122.41656, 37.414585],
              [122.487393, 37.434541],
              [122.4954, 37.413634],
              [122.553914, 37.406981],
              [122.670942, 37.429315],
              [122.714058, 37.392246],
              [122.6925, 37.373704],
              [122.641993, 37.386065],
              [122.607501, 37.364193],
              [122.61058, 37.330898],
              [122.573624, 37.296159],
              [122.567465, 37.2595],
              [122.592718, 37.261405],
              [122.629059, 37.194708],
              [122.573624, 37.176117],
              [122.581015, 37.147508],
              [122.498479, 37.147031],
              [122.484313, 37.128906],
              [122.467067, 37.03726],
              [122.498479, 37.034394],
              [122.575472, 37.054452],
              [122.583479, 37.03726],
              [122.544675, 37.004775],
              [122.55761, 36.968931],
              [122.532356, 36.901497],
              [122.48924, 36.886663],
              [122.483081, 36.913936],
              [122.434422, 36.914414],
              [122.457212, 36.868954],
              [122.383915, 36.865604],
              [122.344495, 36.828257],
              [122.220691, 36.848848],
              [122.174495, 36.842623],
              [122.175727, 36.89432],
              [122.119677, 36.891927],
              [122.141235, 36.93833],
              [122.106742, 36.941677],
              [122.093191, 36.913936],
              [122.051923, 36.904846],
              [122.042684, 36.871826],
              [122.008808, 36.962238],
              [121.767975, 36.874698],
              [121.762432, 36.846454],
              [121.726092, 36.826341],
              [121.670657, 36.81772],
              [121.627541, 36.795683],
              [121.652795, 36.730494],
              [121.556092, 36.764054],
              [121.575186, 36.740084],
              [121.532071, 36.736248],
              [121.485259, 36.7861],
              [121.548701, 36.80766],
              [121.539462, 36.823468],
              [121.480332, 36.784662],
              [121.454462, 36.75255],
              [121.3941, 36.738166],
              [121.409499, 36.721862],
              [121.381781, 36.697399],
              [121.35776, 36.713229],
              [121.280767, 36.697399],
              [121.249355, 36.669569],
              [121.161275, 36.65133],
              [121.028848, 36.573046],
              [120.955551, 36.575929],
              [120.926602, 36.611958],
              [120.882255, 36.626845],
              [120.847146, 36.618682],
              [120.912436, 36.568241],
              [120.983269, 36.546133],
              [120.95432, 36.507668],
              [120.96787, 36.477845],
              [120.947544, 36.449455],
              [120.90874, 36.450417],
              [120.919827, 36.419128],
              [120.890878, 36.373375],
              [120.857617, 36.379156],
              [120.858849, 36.424424],
              [120.828668, 36.466779],
              [120.759683, 36.462448],
              [120.694393, 36.390234],
              [120.7449, 36.330969],
              [120.66298, 36.331933],
              [120.657437, 36.275999],
              [120.687002, 36.277929],
              [120.680843, 36.241745],
              [120.712255, 36.126809],
              [120.618633, 36.120526],
              [120.599539, 36.101674],
              [120.478199, 36.091522],
              [120.430771, 36.057186],
              [120.343308, 36.04219],
              [120.290337, 36.061539],
              [120.35809, 36.174639],
              [120.362402, 36.19637],
              [120.319902, 36.232093],
              [120.297112, 36.225335],
              [120.311895, 36.185747],
              [120.228743, 36.187678],
              [120.22012, 36.208923],
              [120.181316, 36.204095],
              [120.140664, 36.17319],
              [120.142512, 36.143722],
              [120.108635, 36.127292],
              [120.116642, 36.102641],
              [120.152367, 36.095389],
              [120.181316, 36.066376],
              [120.231823, 36.063958],
              [120.234902, 36.030578],
              [120.198562, 35.995731],
              [120.257076, 36.024771],
              [120.249069, 35.991859],
              [120.292801, 36.017512],
              [120.316206, 36.002024],
              [120.30512, 35.972008],
              [120.262004, 35.965712],
              [120.209033, 35.917755],
              [120.202258, 35.892069],
              [120.167149, 35.891585],
              [120.207801, 35.947308],
              [120.11849, 35.888676],
              [120.071063, 35.881405],
              [120.032258, 35.809624],
              [120.049505, 35.782447],
              [120.011317, 35.713006],
              [119.960194, 35.759145],
              [119.926317, 35.759631],
              [119.949723, 35.708634],
              [119.923853, 35.696487],
              [119.925085, 35.637184],
              [119.877658, 35.61092],
              [119.801897, 35.627944],
              [119.800665, 35.581729],
              [119.762477, 35.578323],
              [119.75139, 35.61773],
              [119.718129, 35.615785],
              [119.665775, 35.57005],
              [119.618963, 35.459496],
              [119.579543, 35.406357],
              [119.590014, 35.372701],
              [119.543819, 35.347815],
              [119.538275, 35.296556],
              [119.502551, 35.321945],
              [119.476681, 35.308275],
              [119.411392, 35.231581],
              [119.397841, 35.13769],
              [119.432334, 35.111264],
              [119.360269, 35.075526],
              [119.306066, 35.076506],
              [119.238313, 35.04859],
              [119.211211, 34.981456],
              [119.214907, 34.92555],
              [119.202588, 34.890222],
              [119.217371, 34.827869],
              [119.238313, 34.799378],
              [119.312841, 34.774808],
              [119.378747, 34.764487],
              [119.459435, 34.770876],
              [119.501935, 34.756622],
              [119.381827, 34.752197],
              [119.456971, 34.748265],
              [119.50871, 34.729089],
              [119.465594, 34.673012],
              [119.527804, 34.637083],
              [119.560449, 34.631667],
              [119.582623, 34.598676],
              [119.645449, 34.566656],
              [119.781571, 34.515892],
              [119.811752, 34.48532],
              [119.962657, 34.458684],
              [120.103707, 34.391563],
              [120.311895, 34.307091],
              [120.314359, 34.255675],
              [120.347619, 34.179482],
              [120.367329, 34.091328],
              [120.48559, 33.859116],
              [120.500372, 33.817867],
              [120.534249, 33.782566],
              [120.583524, 33.668608],
              [120.651277, 33.575937],
              [120.717183, 33.436744],
              [120.741205, 33.337826],
              [120.769538, 33.306828],
              [120.821893, 33.298327],
              [120.819429, 33.238295],
              [120.843451, 33.209765],
              [120.874247, 33.093548],
              [120.871784, 33.04742],
              [120.932762, 33.005783],
              [120.957399, 32.893315],
              [120.981421, 32.859648],
              [120.972182, 32.761084],
              [120.953088, 32.714279],
              [120.916131, 32.701189],
              [120.963559, 32.682558],
              [120.979573, 32.636215],
              [120.961711, 32.612026],
              [121.020225, 32.605474],
              [121.153268, 32.529333],
              [121.269681, 32.483415],
              [121.352216, 32.47433],
              [121.425513, 32.430909],
              [121.450151, 32.282314],
              [121.493882, 32.263596],
              [121.499426, 32.210962],
              [121.458774, 32.177544],
              [121.542542, 32.152219],
              [121.525295, 32.136514],
              [121.759352, 32.059471],
              [121.772287, 32.033098],
              [121.856055, 31.95546],
              [121.889315, 31.866577],
              [121.970004, 31.719096],
              [121.974931, 31.617249],
              [121.995873, 31.493354],
              [121.981706, 31.464268],
              [121.890547, 31.428537],
              [121.819098, 31.438237],
              [121.682976, 31.491313],
              [121.625693, 31.501517],
              [121.547469, 31.531101],
              [121.434136, 31.59024],
              [121.395332, 31.585653],
              [121.371926, 31.553028],
              [121.343593, 31.512229],
              [121.404571, 31.479067],
              [121.520984, 31.394835],
              [121.593665, 31.376446],
              [121.712541, 31.309504],
              [121.743954, 31.283429],
              [121.809859, 31.196974],
              [121.946598, 31.065861],
              [121.990945, 30.968434],
              [121.994025, 30.863204],
              [121.954605, 30.825703],
              [121.970004, 30.789217],
              [121.988482, 30.711057],
              [122.011271, 30.669381],
              [122.075329, 30.647764],
              [122.134459, 30.59576],
              [122.087032, 30.60194],
              [121.997105, 30.658573],
              [121.968156, 30.68842],
              [121.943518, 30.77688],
              [121.904714, 30.814399],
              [121.681128, 30.81851],
              [121.601056, 30.805149],
              [121.517288, 30.775338],
              [121.426129, 30.730089],
              [121.362071, 30.679673],
              [121.274608, 30.677615],
              [121.231492, 30.64416],
              [121.188992, 30.632835],
              [121.150188, 30.60091],
              [121.058413, 30.563823],
              [121.092906, 30.515897],
              [121.225333, 30.404496],
              [121.328195, 30.397271],
              [121.497578, 30.258864],
              [121.561636, 30.184415],
              [121.635548, 30.070047],
              [121.652795, 30.071083],
              [121.721164, 29.992865],
              [121.78399, 29.993383],
              [121.835113, 29.958139],
              [121.919497, 29.920808],
              [121.968156, 29.956584],
              [122.00388, 29.919771],
              [122.00696, 29.891764],
              [122.140003, 29.901619],
              [122.143082, 29.877757],
              [122.10243, 29.859597],
              [122.043916, 29.822749],
              [122.003264, 29.762516],
              [121.937359, 29.748491],
              [121.833265, 29.653382],
              [121.872685, 29.632581],
              [121.909641, 29.650262],
              [121.966308, 29.635702],
              [122.000185, 29.582642],
              [121.995257, 29.545171],
              [121.968772, 29.515497],
              [121.993409, 29.451954],
              [121.975547, 29.411308],
              [121.937975, 29.384201],
              [121.933047, 29.350307],
              [121.958301, 29.334139],
              [121.94475, 29.284052],
              [122.000185, 29.278833],
              [121.966924, 29.249603],
              [121.971851, 29.193207],
              [121.937359, 29.186939],
              [121.986634, 29.15507],
              [121.988482, 29.111169],
              [121.966308, 29.053128],
              [121.884388, 29.105419],
              [121.85975, 29.086597],
              [121.780294, 29.109601],
              [121.767975, 29.166565],
              [121.715621, 29.12476],
              [121.608447, 29.169177],
              [121.616454, 29.143574],
              [121.660186, 29.118487],
              [121.658954, 29.058358],
              [121.712541, 29.029065],
              [121.711309, 28.986157],
              [121.743338, 28.954226],
              [121.772287, 28.898192],
              [121.774751, 28.864138],
              [121.73841, 28.856802],
              [121.668193, 28.873046],
              [121.692215, 28.85523],
              [121.704534, 28.80491],
              [121.689135, 28.719415],
              [121.646019, 28.682678],
              [121.540694, 28.655379],
              [121.596128, 28.575016],
              [121.634317, 28.56293],
              [121.646019, 28.511418],
              [121.692831, 28.407266],
              [121.658954, 28.392529],
              [121.636164, 28.347253],
              [121.670657, 28.335667],
              [121.627541, 28.251899],
              [121.580114, 28.240303],
              [121.5641, 28.288259],
              [121.488955, 28.30143],
              [121.45631, 28.250318],
              [121.402107, 28.197071],
              [121.390405, 28.156987],
              [121.35468, 28.125331],
              [121.328195, 28.134302],
              [121.299862, 28.067271],
              [121.261057, 28.034533],
              [121.176058, 28.022385],
              [121.140949, 28.031364],
              [121.121239, 28.125331],
              [121.108304, 28.13905],
              [121.059029, 28.096305],
              [121.015298, 27.981708],
              [120.991892, 27.95],
              [121.05595, 27.900306],
              [121.099681, 27.895018],
              [121.162507, 27.90718],
              [121.162507, 27.879153],
              [121.193304, 27.872277],
              [121.192072, 27.822548],
              [121.152652, 27.810376],
              [121.153268, 27.809847],
              [121.149572, 27.801908],
              [121.149572, 27.801379],
              [121.149572, 27.80085],
              [121.13479, 27.787088],
              [121.134174, 27.787088],
              [121.152036, 27.815139],
              [121.107688, 27.813551],
              [121.070116, 27.834188],
              [121.027616, 27.832601],
              [120.97403, 27.887086],
              [120.942001, 27.896605],
              [120.855153, 27.822548],
              [120.815733, 27.77703],
              [120.797871, 27.779677],
              [120.760915, 27.717723],
              [120.709176, 27.68276],
              [120.685154, 27.622871],
              [120.634647, 27.577271],
              [120.662364, 27.519448],
              [120.703016, 27.478581],
              [120.676531, 27.431328],
              [120.673451, 27.369708],
              [120.580444, 27.321345],
              [120.552727, 27.257537],
              [120.574285, 27.243175],
              [120.544104, 27.154303],
              [120.461568, 27.14259],
              [120.393199, 27.081343],
              [120.287257, 27.094128],
              [120.29588, 27.035519],
              [120.274323, 27.028058],
              [120.231823, 26.907006],
              [120.1807, 26.920344],
              [120.117258, 26.916609],
              [120.100012, 26.871252],
              [120.037802, 26.860043],
              [120.047041, 26.824809],
              [120.082765, 26.822139],
              [120.105555, 26.79437],
              [120.1382, 26.79704],
              [120.106787, 26.752704],
              [120.165917, 26.73133],
              [120.110483, 26.692848],
              [120.1382, 26.637775],
              [120.083997, 26.614776],
              [120.066135, 26.628148],
              [120.007621, 26.595516],
              [119.967585, 26.597657],
              [119.947875, 26.560735],
              [119.896136, 26.516306],
              [119.827767, 26.524872],
              [119.851788, 26.595516],
              [119.901679, 26.624404],
              [119.949107, 26.624404],
              [119.99407, 26.720642],
              [120.05936, 26.76606],
              [120.052584, 26.786892],
              [119.942947, 26.784756],
              [119.938636, 26.744689],
              [119.899216, 26.693383],
              [119.905991, 26.659701],
              [119.86965, 26.642588],
              [119.864107, 26.671464],
              [119.83023, 26.69071],
              [119.711354, 26.686433],
              [119.664543, 26.725986],
              [119.63313, 26.698728],
              [119.619579, 26.649006],
              [119.577695, 26.622264],
              [119.620811, 26.592306],
              [119.668854, 26.61745],
              [119.727984, 26.614241],
              [119.788346, 26.58321],
              [119.83639, 26.454185],
              [119.837006, 26.431684],
              [119.893672, 26.356114],
              [119.962657, 26.373269],
              [119.8986, 26.308388],
              [119.845013, 26.322869],
              [119.806825, 26.307852],
              [119.797586, 26.268155],
              [119.7711, 26.285323],
              [119.668854, 26.256887],
              [119.664543, 26.202142],
              [119.604181, 26.168853],
              [119.617115, 26.121587],
              [119.654072, 26.090962],
              [119.668854, 26.025924],
              [119.723673, 26.011406],
              [119.69534, 25.904351],
              [119.628202, 25.872054],
              [119.626354, 25.723374],
              [119.602949, 25.685096],
              [119.543819, 25.684557],
              [119.472986, 25.662448],
              [119.478529, 25.631703],
              [119.541355, 25.62469],
              [119.534579, 25.585301],
              [119.586934, 25.592317],
              [119.616499, 25.556696],
              [119.611572, 25.519985],
              [119.634362, 25.475161],
              [119.675014, 25.475161],
              [119.718745, 25.511346],
              [119.716898, 25.551298],
              [119.683637, 25.592856],
              [119.700267, 25.616598],
              [119.784651, 25.667301],
              [119.790194, 25.614439],
              [119.843165, 25.597713],
              [119.831462, 25.579905],
              [119.883817, 25.54644],
              [119.81668, 25.532403],
              [119.826535, 25.465438],
              [119.764325, 25.433562],
              [119.773564, 25.395732],
              [119.717513, 25.420052],
              [119.675014, 25.468139],
              [119.622659, 25.434102],
              [119.670086, 25.435723],
              [119.649144, 25.34275],
              [119.597405, 25.334639],
              [119.577695, 25.445989],
              [119.555521, 25.429239],
              [119.578927, 25.400597],
              [119.549362, 25.367082],
              [119.48592, 25.364919],
              [119.507478, 25.396273],
              [119.490232, 25.447069],
              [119.463131, 25.44869],
              [119.438493, 25.412487],
              [119.45266, 25.493525],
              [119.400921, 25.493525],
              [119.359037, 25.521605],
              [119.343638, 25.47246],
              [119.353493, 25.411946],
              [119.288204, 25.410865],
              [119.26295, 25.428158],
              [119.275269, 25.476781],
              [119.256175, 25.488664],
              [119.213675, 25.463277],
              [119.196429, 25.425456],
              [119.151465, 25.426537],
              [119.14469, 25.388165],
              [119.218603, 25.368163],
              [119.248784, 25.33518],
              [119.299291, 25.32869],
              [119.337479, 25.284336],
              [119.385522, 25.275138],
              [119.380595, 25.250247],
              [119.32824, 25.231305],
              [119.294979, 25.237259],
              [119.314689, 25.190164],
              [119.26911, 25.15984],
              [119.231537, 25.189081],
              [119.190269, 25.176086],
              [119.131755, 25.223187],
              [119.108349, 25.193954],
              [119.165632, 25.145217],
              [119.147154, 25.055816],
              [119.119436, 25.012447],
              [119.107118, 25.075327],
              [119.134219, 25.106214],
              [119.075705, 25.099712],
              [119.035669, 25.125717],
              [119.032589, 25.174462],
              [119.054147, 25.168505],
              [119.074473, 25.211278],
              [118.991321, 25.201533],
              [118.978386, 25.222645],
              [118.996864, 25.266481],
              [118.956212, 25.272974],
              [118.911249, 25.241589],
              [118.981466, 25.19612],
              [118.985778, 25.166339],
              [118.951285, 25.14955],
              [118.974691, 25.115424],
              [118.889075, 25.083998],
              [118.966067, 25.021664],
              [118.998712, 25.055816],
              [119.023966, 25.043349],
              [118.989473, 24.973944],
              [119.032589, 24.962011],
              [119.032589, 24.961468],
              [118.982698, 24.934343],
              [118.945741, 24.953874],
              [118.918024, 24.924034],
              [118.951285, 24.897986],
              [118.987009, 24.898529],
              [118.96114, 24.871933],
              [118.86259, 24.886589],
              [118.834256, 24.854018],
              [118.805923, 24.869762],
              [118.732626, 24.838816],
              [118.687663, 24.856733],
              [118.647627, 24.843703],
              [118.650707, 24.808949],
              [118.786213, 24.776358],
              [118.778822, 24.743758],
              [118.703677, 24.665485],
              [118.670417, 24.679621],
              [118.661178, 24.622522],
              [118.687047, 24.6334],
              [118.675344, 24.57628],
              [118.614366, 24.521856],
              [118.558316, 24.512602],
              [118.557084, 24.573016],
              [118.512736, 24.607836],
              [118.444367, 24.614907],
              [118.363679, 24.568118],
              [118.375382, 24.536553],
              [118.242955, 24.512602],
              [118.150564, 24.583354],
              [118.084042, 24.528933],
              [118.048934, 24.418385],
              [118.088354, 24.409123],
              [118.081579, 24.356262],
              [118.112376, 24.356807],
              [118.158571, 24.270111],
              [118.115455, 24.229196],
              [118.074803, 24.225376],
              [118.001507, 24.176805],
              [118.000275, 24.15224],
              [117.92821, 24.08944],
              [117.910347, 24.011853],
              [117.864768, 24.004748],
              [117.807486, 23.947344],
              [117.792703, 23.906326],
              [117.762522, 23.88718],
              [117.671979, 23.877879],
              [117.651653, 23.815493],
              [117.660276, 23.789216],
              [117.612849, 23.71364],
              [117.54448, 23.715832],
              [117.500132, 23.703232],
              [117.493357, 23.642406],
              [117.454552, 23.628154],
              [117.463791, 23.58539],
              [117.387415, 23.555228],
              [117.302415, 23.550291],
              [117.291328, 23.571132],
              [117.192778, 23.561809],
              [117.085605, 23.537127],
              [117.054192, 23.542064],
              [117.01046, 23.502564],
              [116.963649, 23.506953],
              [116.921765, 23.53219],
              [116.888504, 23.501466],
              [116.874953, 23.447683],
              [116.874338, 23.447683],
              [116.871258, 23.416391],
              [116.871874, 23.415842],
              [116.782563, 23.313679],
              [116.799809, 23.244427],
              [116.821367, 23.240579],
              [116.806584, 23.200989],
              [116.74499, 23.215286],
              [116.701259, 23.198239],
              [116.665534, 23.158087],
              [116.566368, 23.13443],
              [116.550969, 23.109668],
              [116.576839, 23.014429],
              [116.542346, 22.995704],
              [116.50539, 22.930696],
              [116.449955, 22.936758],
              [116.382818, 22.919124],
              [116.317528, 22.952736],
              [116.226985, 22.914715],
              [116.175246, 22.859042],
              [116.106877, 22.817685],
              [116.073616, 22.8425],
              [115.99724, 22.82706],
              [115.965211, 22.800587],
              [115.883291, 22.785142],
              [115.829089, 22.734934],
              [115.796444, 22.739349],
              [115.788437, 22.809964],
              [115.755792, 22.837538],
              [115.69235, 22.845809],
              [115.654162, 22.865657],
              [115.598727, 22.8425],
              [115.542677, 22.76142],
              [115.606119, 22.754799],
              [115.564851, 22.689124],
              [115.57409, 22.650477],
              [115.471844, 22.697956],
              [115.381301, 22.684156],
              [115.341881, 22.725001],
              [115.338185, 22.776867],
              [115.230396, 22.776867],
              [115.236555, 22.825406],
              [115.190359, 22.818788],
              [115.188512, 22.772454],
              [115.154635, 22.80169],
              [115.076411, 22.788452],
              [115.04007, 22.712307],
              [114.945216, 22.645507],
              [114.927969, 22.62176],
              [114.927353, 22.555469],
              [114.886701, 22.538339],
              [114.87623, 22.589724],
              [114.747499, 22.581437],
              [114.728405, 22.651029],
              [114.749963, 22.764179],
              [114.709927, 22.7879],
              [114.689601, 22.767489],
              [114.601521, 22.730519],
              [114.608913, 22.700716],
              [114.512826, 22.655446],
              [114.579964, 22.66152],
              [114.603369, 22.63888],
              [114.559022, 22.583094],
              [114.575036, 22.555469],
              [114.616304, 22.54276],
              [114.628007, 22.502966],
              [114.611377, 22.481959],
              [114.549167, 22.465925],
              [114.535616, 22.444913],
              [114.485109, 22.446572],
              [114.467863, 22.533365],
              [114.41058, 22.599667],
              [114.321885, 22.587514],
              [114.232574, 22.539997],
              [114.185762, 22.551601],
              [114.185762, 22.551601],
              [114.117393, 22.526733],
              [114.097067, 22.53447],
              [114.053951, 22.499649],
              [114.031778, 22.504071],
              [113.959097, 22.505177],
              [113.940003, 22.471454],
              [113.893807, 22.442701],
              [113.869786, 22.45929],
              [113.856851, 22.539444],
              [113.803264, 22.593038],
              [113.733663, 22.73659],
              [113.678228, 22.726104],
              [113.717033, 22.644955],
              [113.740438, 22.53447],
              [113.691779, 22.515126],
              [113.668373, 22.4803],
              [113.631417, 22.475877],
              [113.573519, 22.411729],
              [113.608627, 22.408963],
              [113.624642, 22.442701],
              [113.669605, 22.416154],
              [113.616635, 22.318771],
              [113.590765, 22.299397],
              [113.596309, 22.232398],
              [113.558736, 22.212458],
              [113.534715, 22.174232],
              [113.554425, 22.107173],
              [113.558736, 22.069473],
              [113.530403, 22.073908],
              [113.45957, 22.043964],
              [113.442324, 22.009575],
              [113.330223, 21.961861],
              [113.319752, 21.90969],
              [113.266781, 21.871937],
              [113.224281, 21.898032],
              [113.1516, 21.979617],
              [113.142977, 22.011794],
              [113.091854, 22.065591],
              [113.086927, 22.126019],
              [113.032724, 22.072799],
              [113.054282, 22.004028],
              [113.037652, 21.935223],
              [112.989608, 21.869716],
              [112.944645, 21.84195],
              [112.884899, 21.851947],
              [112.841167, 21.920237],
              [112.792508, 21.921347],
              [112.68595, 21.81029],
              [112.651458, 21.761954],
              [112.599103, 21.750284],
              [112.523342, 21.760842],
              [112.477763, 21.795847],
              [112.439574, 21.803624],
              [112.415553, 21.734723],
              [112.353343, 21.70693],
              [112.24001, 21.701371],
              [112.233235, 21.72972],
              [112.196894, 21.73639],
              [112.192583, 21.78918],
              [112.136532, 21.793624],
              [112.036134, 21.761398],
              [111.956062, 21.710822],
              [111.951135, 21.671904],
              [112.026895, 21.633533],
              [112.008417, 21.614622],
              [111.92157, 21.599602],
              [111.863055, 21.55676],
              [111.807621, 21.557316],
              [111.829179, 21.584581],
              [111.785447, 21.612953],
              [111.693672, 21.590144],
              [111.677658, 21.52949],
              [111.644397, 21.512791],
              [111.608056, 21.530046],
              [111.546462, 21.502771],
              [111.538455, 21.519471],
              [111.475629, 21.499988],
              [111.444217, 21.513905],
              [111.382623, 21.495534],
              [111.28592, 21.419245],
              [111.257587, 21.413675],
              [111.28284, 21.485513],
              [111.177515, 21.459343],
              [111.061102, 21.44932],
              [110.929291, 21.375792],
              [110.888639, 21.367434],
              [110.796248, 21.374678],
              [110.768531, 21.365205],
              [110.713097, 21.312818],
              [110.65951, 21.23978],
              [110.626249, 21.215797],
              [110.529547, 21.204642],
              [110.501213, 21.217471],
              [110.451322, 21.186232],
              [110.422373, 21.190695],
              [110.388497, 21.125968],
              [110.296722, 21.093594],
              [110.234512, 21.014304],
              [110.211106, 21.053954],
              [110.204947, 21.003691],
              [110.180925, 20.981905],
              [110.184005, 20.891935],
              [110.209874, 20.860069],
              [110.269004, 20.83994],
              [110.350924, 20.841617],
              [110.393424, 20.816452],
              [110.407591, 20.731979],
              [110.392192, 20.682727],
              [110.466105, 20.680488],
              [110.499982, 20.572413],
              [110.550489, 20.47267],
              [110.54125, 20.420531],
              [110.491358, 20.373984],
              [110.452554, 20.311151],
              [110.376178, 20.288704],
              [110.349076, 20.258958],
              [110.216649, 20.250538],
              [110.168606, 20.219661],
              [110.118099, 20.219661],
              [110.082375, 20.258958],
              [109.993679, 20.254467],
              [109.929006, 20.2118],
              [109.909296, 20.237065],
              [109.916071, 20.316762],
              [109.861252, 20.376789],
              [109.895745, 20.42726],
              [109.888354, 20.475473],
              [109.839695, 20.489485],
              [109.811977, 20.541601],
              [109.793499, 20.61554],
              [109.74484, 20.62114],
              [109.730057, 20.719667],
              [109.711579, 20.774501],
              [109.664768, 20.862305],
              [109.655529, 20.929382],
              [109.674007, 21.068471],
              [109.674623, 21.136572],
              [109.763934, 21.226395],
              [109.757775, 21.346816],
              [109.770709, 21.359633],
              [109.868644, 21.365763],
              [109.904368, 21.430384],
              [109.894513, 21.44208],
              [109.785492, 21.457116],
              [109.704188, 21.462684],
              [109.654913, 21.493307],
              [109.612413, 21.55676],
              [109.581, 21.547856],
              [109.576689, 21.493307],
              [109.540964, 21.466025],
              [109.448573, 21.442637],
              [109.245929, 21.425929],
              [109.186183, 21.390835],
              [109.138756, 21.389163],
              [109.082705, 21.424815],
              [109.046365, 21.424258],
              [109.058068, 21.481059],
              [109.124589, 21.501101],
              [109.150459, 21.523924],
              [109.142451, 21.56455],
              [109.091944, 21.58013],
              [108.937959, 21.589588],
              [108.881293, 21.627415],
              [108.835098, 21.609615],
              [108.791982, 21.624634],
              [108.747634, 21.596264],
              [108.710062, 21.646881],
              [108.658939, 21.6441],
              [108.626294, 21.680245],
              [108.591802, 21.677465],
              [108.479085, 21.546743],
              [108.397781, 21.532829],
              [108.338651, 21.541177],
              [108.230245, 21.49108],
              [108.210535, 21.505555],
              [108.249955, 21.561211],
              [108.235173, 21.60294],
              [108.205608, 21.597933],
              [108.156332, 21.550638],
              [108.193905, 21.520027],
              [108.108289, 21.508338],
              [108.030681, 21.546186],
              [107.95492, 21.538395],
              [107.926587, 21.586806],
              [107.89579, 21.594039],
              [107.860066, 21.651886],
              [107.807711, 21.655779],
              [107.71532, 21.617403],
              [107.605067, 21.59682],
              [107.584741, 21.614622],
              [107.546553, 21.58625],
              [107.487422, 21.59682],
              [107.500973, 21.613509],
              [107.46956, 21.659671],
              [107.431372, 21.641876],
              [107.388256, 21.594039],
              [107.36485, 21.598489],
              [107.356843, 21.668012],
              [107.310648, 21.733611],
              [107.247206, 21.703039],
              [107.199163, 21.718604],
              [107.088294, 21.805291],
              [107.011917, 21.826399],
              [107.018693, 21.859166],
              [107.058729, 21.886929],
              [107.05996, 21.915241],
              [106.999598, 21.947433],
              [106.958946, 21.921902],
              [106.926302, 21.96741],
              [106.859164, 21.98572],
              [106.802498, 21.981836],
              [106.785867, 22.007911],
              [106.73844, 22.007911],
              [106.698404, 21.959641],
              [106.681158, 21.995152],
              [106.706411, 22.021779],
              [106.71565, 22.089987],
              [106.681158, 22.167583],
              [106.7021, 22.206918],
              [106.692861, 22.276698],
              [106.670071, 22.283342],
              [106.663296, 22.330948],
              [106.562282, 22.34589],
              [106.588767, 22.37411],
              [106.562282, 22.462608],
              [106.588151, 22.473113],
              [106.585071, 22.516784],
              [106.61402, 22.601876],
              [106.651593, 22.573702],
              [106.710723, 22.57536],
              [106.734745, 22.598562],
              [106.725505, 22.634463],
              [106.756302, 22.689676],
              [106.783404, 22.705684],
              [106.768621, 22.739349],
              [106.820976, 22.768592],
              [106.841302, 22.800036],
              [106.772317, 22.823751],
              [106.674998, 22.891566],
              [106.657136, 22.863452],
              [106.631883, 22.876683],
              [106.606013, 22.925737],
              [106.562282, 22.923533],
              [106.525941, 22.946676],
              [106.504383, 22.910306],
              [106.41384, 22.877234],
              [106.37442, 22.879991],
              [106.366413, 22.857939],
              [106.286957, 22.86676],
              [106.270326, 22.90755],
              [106.206885, 22.978629],
              [106.106486, 22.980832],
              [106.08616, 22.996255],
              [106.019639, 22.990747],
              [105.994385, 22.93786],
              [105.893987, 22.936758],
              [105.879205, 22.91692],
              [105.839169, 22.987442],
              [105.74185, 23.03095],
              [105.724604, 23.062332],
              [105.642068, 23.078296],
              [105.574931, 23.066186],
              [105.558916, 23.177889],
              [105.542902, 23.18449],
              [105.50225, 23.202639],
              [105.445584, 23.292797],
              [105.416018, 23.283454],
              [105.372903, 23.317525],
              [105.325475, 23.390034],
              [105.260186, 23.318075],
              [105.238012, 23.264217],
              [105.181962, 23.279057],
              [105.122215, 23.247726],
              [105.093266, 23.260919],
              [104.958991, 23.18889],
              [104.949136, 23.152035],
              [104.886311, 23.169088],
              [104.874608, 23.123425],
              [104.804391, 23.110218],
              [104.821021, 23.032051],
              [104.860441, 22.970917],
              [104.846275, 22.926288],
              [104.772362, 22.89322],
              [104.732942, 22.851874],
              [104.737869, 22.825957],
              [104.674428, 22.817133],
              [104.579573, 22.84636],
              [104.534609, 22.819891],
              [104.498885, 22.774661],
              [104.422508, 22.734934],
              [104.375697, 22.690228],
              [104.323342, 22.728864],
              [104.272219, 22.738245],
              [104.256821, 22.773557],
              [104.274067, 22.828163],
              [104.117618, 22.808861],
              [104.045553, 22.728312],
              [104.04309, 22.676979],
              [104.008597, 22.573702],
              [104.007365, 22.516784],
              [103.964249, 22.502966],
              [103.825047, 22.615685],
              [103.769613, 22.687468],
              [103.642113, 22.795071],
              [103.566969, 22.698508],
              [103.580519, 22.667041],
              [103.53494, 22.594143],
              [103.50907, 22.600772],
              [103.436389, 22.697404],
              [103.441317, 22.753144],
              [103.375411, 22.795071],
              [103.323057, 22.807758],
              [103.321209, 22.777971],
              [103.287948, 22.730519],
              [103.283021, 22.678635],
              [103.216499, 22.643298],
              [103.195557, 22.648268],
              [103.161065, 22.593038],
              [103.183854, 22.557679],
              [103.081608, 22.506835],
              [103.079761, 22.448784],
              [103.031717, 22.441042],
              [102.984906, 22.478642],
              [102.930703, 22.482512],
              [102.891899, 22.535023],
              [102.880196, 22.586961],
              [102.830921, 22.62176],
              [102.798276, 22.620656],
              [102.688639, 22.700164],
              [102.657226, 22.68802],
              [102.603024, 22.731623],
              [102.570379, 22.700164],
              [102.551901, 22.74266],
              [102.510633, 22.774661],
              [102.458278, 22.76142],
              [102.43672, 22.699612],
              [102.384365, 22.679739],
              [102.420706, 22.636119],
              [102.356648, 22.563757],
              [102.322771, 22.554364],
              [102.25625, 22.457631],
              [102.270416, 22.419472],
              [102.179257, 22.430534],
              [102.145997, 22.3979],
              [102.131214, 22.431087],
              [102.046214, 22.457631],
              [101.978461, 22.427769],
              [101.907628, 22.43717],
              [101.901469, 22.384622],
              [101.862665, 22.389048],
              [101.823244, 22.426662],
              [101.824476, 22.457078],
              [101.76473, 22.506282],
              [101.715455, 22.478089],
              [101.672339, 22.474772],
              [101.648318, 22.400665],
              [101.648934, 22.363044],
              [101.625528, 22.282789],
              [101.56455, 22.2695],
              [101.547304, 22.237936],
              [101.596579, 22.160933],
              [101.602738, 22.132116],
              [101.573789, 22.114933],
              [101.626144, 22.006247],
              [101.606434, 21.967965],
              [101.666796, 21.934668],
              [101.701288, 21.938553],
              [101.700057, 21.896922],
              [101.735165, 21.875824],
              [101.740093, 21.845283],
              [101.777049, 21.826954],
              [101.747484, 21.730276],
              [101.76781, 21.71638],
              [101.780129, 21.640763],
              [101.828788, 21.616847],
              [101.798607, 21.573453],
              [101.754875, 21.585137],
              [101.772737, 21.513348],
              [101.741324, 21.482729],
              [101.749948, 21.409218],
              [101.730238, 21.336785],
              [101.745636, 21.29721],
              [101.791832, 21.28606],
              [101.833715, 21.252606],
              [101.835563, 21.207988],
              [101.794911, 21.208546],
              [101.76473, 21.147733],
              [101.703136, 21.146059],
              [101.672339, 21.195158],
              [101.605818, 21.172285],
              [101.588572, 21.191811],
              [101.604586, 21.229741],
              [101.532521, 21.252606],
              [101.445674, 21.22751],
              [101.387775, 21.225837],
              [101.290457, 21.178422],
              [101.222088, 21.234203],
              [101.244877, 21.302227],
              [101.183899, 21.334556],
              [101.142631, 21.409218],
              [101.194986, 21.425372],
              [101.193138, 21.474378],
              [101.209153, 21.557316],
              [101.145095, 21.561211],
              [101.167885, 21.589588],
              [101.151871, 21.670236],
              [101.117378, 21.689141],
              [101.123537, 21.771956],
              [101.089045, 21.773623],
              [101.015132, 21.70693],
              [100.940603, 21.696924],
              [100.870386, 21.672461],
              [100.804481, 21.610171],
              [100.789082, 21.570671],
              [100.753358, 21.55509],
              [100.730568, 21.518914],
              [100.66959, 21.487183],
              [100.558105, 21.450434],
              [100.526692, 21.471037],
              [100.48296, 21.459343],
              [100.437381, 21.532829],
              [100.352997, 21.528376],
              [100.316657, 21.488853],
              [100.234737, 21.467139],
              [100.206404, 21.509451],
              [100.169447, 21.483843],
              [100.126948, 21.508338],
              [100.107853, 21.585137],
              [100.154049, 21.647993],
              [100.174375, 21.65411],
              [100.13865, 21.696924],
              [100.095535, 21.704151],
              [100.046259, 21.66968],
              [99.991441, 21.703595],
              [99.944014, 21.821955],
              [99.960028, 21.90747],
              [99.981586, 21.918017],
              [99.999448, 21.970184],
              [99.965571, 22.014013],
              [99.972347, 22.052837],
              [99.871333, 22.0667],
              [99.870101, 22.029544],
              [99.762927, 22.067809],
              [99.696406, 22.067809],
              [99.648979, 22.10052],
              [99.592312, 22.112716],
              [99.516552, 22.099412],
              [99.486987, 22.12879],
              [99.400139, 22.099966],
              [99.27264, 22.10052],
              [99.214741, 22.11327],
              [99.169162, 22.146525],
              [99.174705, 22.186975],
              [99.235683, 22.250673],
              [99.233836, 22.296629],
              [99.278799, 22.358064],
              [99.251698, 22.393474],
              [99.282495, 22.401219],
              [99.381661, 22.503519],
              [99.359487, 22.535575],
              [99.385973, 22.57094],
              [99.339777, 22.708995],
              [99.31514, 22.737693],
              [99.385973, 22.761972],
              [99.400139, 22.825406],
              [99.462965, 22.844706],
              [99.43648, 22.913613],
              [99.456806, 22.932901],
              [99.531334, 22.897078],
              [99.563363, 22.925737],
              [99.517168, 23.006719],
              [99.528255, 23.065635],
              [99.440791, 23.079397],
              [99.380429, 23.099762],
              [99.3484, 23.128927],
              [99.255393, 23.077746],
              [99.187024, 23.100312],
              [99.106336, 23.086552],
              [99.048438, 23.11462],
              [99.057677, 23.164688],
              [99.002242, 23.160287],
              [98.907388, 23.18559],
              [98.889525, 23.209238],
              [98.930177, 23.266416],
              [98.936953, 23.309833],
              [98.872895, 23.329613],
              [98.920938, 23.360925],
              [98.912315, 23.426274],
              [98.872279, 23.484456],
              [98.826084, 23.470187],
              [98.808221, 23.547549],
              [98.877823, 23.59197],
              [98.882134, 23.619931],
              [98.847026, 23.631991],
              [98.824236, 23.727335],
              [98.786048, 23.781551],
              [98.696121, 23.784288],
              [98.669019, 23.800713],
              [98.701664, 23.834103],
              [98.68565, 23.90195],
              [98.701048, 23.946251],
              [98.673331, 23.960468],
              [98.727533, 23.970309],
              [98.773729, 24.022237],
              [98.807606, 24.02497],
              [98.899996, 24.109102],
              [98.875975, 24.150056],
              [98.819308, 24.133676],
              [98.716446, 24.12767],
              [98.681954, 24.099818],
              [98.648077, 24.106372],
              [98.611737, 24.08507],
              [98.550759, 24.125485],
              [98.461448, 24.126031],
              [98.362281, 24.109102],
              [98.360434, 24.097087],
              [98.225543, 24.113471],
              [98.19721, 24.098179],
              [98.110978, 24.092171],
              [98.05616, 24.07633],
              [97.984711, 24.031528],
              [97.902175, 24.01404],
              [97.894168, 23.973589],
              [97.839349, 23.971402],
              [97.769748, 23.933126],
              [97.711234, 23.861465],
              [97.688444, 23.878973],
              [97.647176, 23.841217],
              [97.641016, 23.872408],
              [97.5283, 23.926563],
              [97.578191, 23.98671],
              [97.628082, 24.002015],
              [97.634241, 24.046828],
              [97.730328, 24.111833],
              [97.753733, 24.162066],
              [97.72848, 24.183355],
              [97.729712, 24.227013],
              [97.767284, 24.258656],
              [97.721089, 24.295744],
              [97.665038, 24.296289],
              [97.658879, 24.327915],
              [97.716161, 24.358987],
              [97.679821, 24.401495],
              [97.669966, 24.452703],
              [97.531995, 24.43146],
              [97.554785, 24.490824],
              [97.570799, 24.602396],
              [97.568952, 24.719847],
              [97.547394, 24.739411],
              [97.570183, 24.766579],
              [97.652103, 24.791025],
              [97.701379, 24.842617],
              [97.764204, 24.824155],
              [97.797465, 24.845874],
              [97.785762, 24.875733],
              [97.730328, 24.907755],
              [97.716777, 24.978283],
              [97.74203, 25.078037],
              [97.777139, 25.121383],
              [97.839349, 25.27081],
              [97.876305, 25.254036],
              [97.914494, 25.211278],
              [97.950218, 25.221021],
              [98.0075, 25.280549],
              [98.014892, 25.305433],
              [98.06971, 25.311924],
              [98.099891, 25.354106],
              [98.101123, 25.388705],
              [98.137464, 25.381678],
              [98.15779, 25.457334],
              [98.131304, 25.510266],
              [98.163949, 25.524305],
              [98.189818, 25.569111],
              [98.170724, 25.620374],
              [98.247717, 25.607965],
              [98.314854, 25.543201],
              [98.326557, 25.566412],
              [98.402317, 25.593936],
              [98.409709, 25.664066],
              [98.457752, 25.68294],
              [98.476846, 25.777265],
              [98.525505, 25.837056],
              [98.553839, 25.845672],
              [98.640686, 25.798815],
              [98.677642, 25.816052],
              [98.705976, 25.855364],
              [98.686881, 25.925877],
              [98.637606, 25.971608],
              [98.614201, 25.968919],
              [98.601882, 26.056029],
              [98.575396, 26.118364],
              [98.634527, 26.145759],
              [98.667171, 26.116215],
              [98.662244, 26.0872],
              [98.720142, 26.126959],
              [98.710287, 26.153816],
              [98.735541, 26.183351],
              [98.713367, 26.231128],
              [98.672715, 26.240251],
              [98.683802, 26.307316],
              [98.733693, 26.350753],
              [98.757098, 26.491676],
              [98.753403, 26.559129],
              [98.781736, 26.62066],
              [98.746012, 26.697125],
              [98.758946, 26.808789],
              [98.730613, 26.851503],
              [98.757098, 26.87819],
              [98.732461, 27.002472],
              [98.762642, 27.018463],
              [98.765722, 27.050973],
              [98.712135, 27.077081],
              [98.713983, 27.139928],
              [98.696121, 27.211253],
              [98.723222, 27.220831],
              [98.715215, 27.257537],
              [98.741084, 27.330382],
              [98.706591, 27.362269],
              [98.686881, 27.424955],
              [98.704744, 27.462125],
              [98.685034, 27.48442],
              [98.706591, 27.553403],
              [98.674563, 27.582044],
              [98.646229, 27.564011],
              [98.583404, 27.571437],
              [98.554454, 27.646195],
              [98.536592, 27.621811],
              [98.474998, 27.634534],
              [98.444201, 27.665274],
              [98.429419, 27.612268],
              [98.429419, 27.548628],
              [98.376448, 27.508304],
              [98.317318, 27.519448],
              [98.310542, 27.583635],
              [98.278514, 27.659974],
              [98.234166, 27.690707],
              [98.223695, 27.725139],
              [98.245869, 27.735201],
              [98.21692, 27.775442],
              [98.222463, 27.812493],
              [98.169492, 27.851118],
              [98.205217, 27.88973],
              [98.187355, 27.939429],
              [98.143007, 27.948943],
              [98.133152, 27.99069],
              [98.160253, 28.101056],
              [98.139311, 28.142216],
              [98.097427, 28.166482],
              [98.090036, 28.195489],
              [98.03337, 28.187052],
              [98.008116, 28.213944],
              [98.020435, 28.25348],
              [97.907718, 28.363575],
              [97.871378, 28.361469],
              [97.842429, 28.326714],
              [97.801161, 28.326714],
              [97.738335, 28.396213],
              [97.737103, 28.465667],
              [97.68598, 28.51983],
              [97.634857, 28.531921],
              [97.60406, 28.515098],
              [97.569567, 28.541382],
              [97.506126, 28.471453],
              [97.521524, 28.444625],
              [97.485184, 28.386212],
              [97.489495, 28.345146],
              [97.518445, 28.327767],
              [97.469169, 28.303537],
              [97.460546, 28.268236],
              [97.42359, 28.297742],
              [97.398336, 28.238722],
              [97.350909, 28.23714],
              [97.36138, 28.201817],
              [97.352757, 28.149602],
              [97.326887, 28.132719],
              [97.340438, 28.10475],
              [97.305945, 28.071495],
              [97.321344, 28.054071],
              [97.378626, 28.06252],
              [97.378626, 28.031364],
              [97.413119, 28.013406],
              [97.390945, 27.993331],
              [97.373083, 27.930971],
              [97.386634, 27.882855],
              [97.324424, 27.880739],
              [97.303482, 27.913525],
              [97.253591, 27.891845],
              [97.167975, 27.811964],
              [97.103301, 27.780736],
              [97.097758, 27.741026],
              [97.062649, 27.742615],
              [97.049099, 27.814081],
              [97.008447, 27.80773],
              [96.972722, 27.861169],
              [96.908049, 27.884442],
              [96.849534, 27.874393],
              [96.810114, 27.890259],
              [96.784245, 27.9315],
              [96.711564, 27.957399],
              [96.690622, 27.948943],
              [96.635188, 27.994916],
              [96.623485, 28.024498],
              [96.572978, 28.058296],
              [96.510768, 28.07783],
              [96.499681, 28.067271],
              [96.46334, 28.143271],
              [96.426384, 28.161735],
              [96.398667, 28.118471],
              [96.297037, 28.141161],
              [96.275479, 28.228179],
              [96.198487, 28.213944],
              [96.098088, 28.212363],
              [96.074683, 28.19338],
              [95.989067, 28.198126],
              [95.936096, 28.24083],
              [95.907763, 28.241357],
              [95.874502, 28.297742],
              [95.832003, 28.295108],
              [95.787655, 28.270344],
              [95.740228, 28.275087],
              [95.674322, 28.254007],
              [95.528345, 28.182833],
              [95.437802, 28.161735],
              [95.371896, 28.110028],
              [95.352802, 28.04087],
              [95.32878, 28.017631],
              [95.28628, 27.939957],
              [95.067006, 27.840537],
              [95.015267, 27.828897],
              [94.947514, 27.792381],
              [94.88592, 27.743145],
              [94.78121, 27.699183],
              [94.722696, 27.683819],
              [94.660486, 27.650435],
              [94.524979, 27.596362],
              [94.478168, 27.602195],
              [94.443675, 27.585226],
              [94.277372, 27.580983],
              [94.21085, 27.527937],
              [94.147409, 27.458408],
              [93.970634, 27.305396],
              [93.849294, 27.168676],
              [93.841903, 27.045645],
              [93.817265, 27.025393],
              [93.747048, 27.015798],
              [93.625092, 26.955549],
              [93.56781, 26.937948],
              [93.232739, 26.907006],
              [93.111399, 26.880325],
              [93.050421, 26.884061],
              [92.909371, 26.914475],
              [92.802813, 26.895267],
              [92.682089, 26.948082],
              [92.57122, 26.946482],
              [92.404916, 26.902737],
              [92.28604, 26.892599],
              [92.109265, 26.854705],
              [92.124664, 26.959815],
              [92.076005, 27.041381],
              [92.043976, 27.052572],
              [92.02673, 27.108509],
              [92.032273, 27.168144],
              [92.061222, 27.190499],
              [92.091403, 27.264451],
              [92.125896, 27.27296],
              [92.064918, 27.391492],
              [92.010715, 27.474866],
              [91.946657, 27.464248],
              [91.839484, 27.489728],
              [91.753868, 27.462656],
              [91.71876, 27.467434],
              [91.663325, 27.507242],
              [91.626985, 27.508834],
              [91.585101, 27.54014],
              [91.564775, 27.581514],
              [91.582637, 27.599013],
              [91.570934, 27.650965],
              [91.622673, 27.692296],
              [91.642383, 27.766442],
              [91.610355, 27.819373],
              [91.544449, 27.820431],
              [91.561079, 27.85535],
              [91.618978, 27.856408],
              [91.611586, 27.891316],
              [91.486551, 27.937314],
              [91.490246, 27.971668],
              [91.464993, 28.002841],
              [91.309776, 28.057768],
              [91.251878, 27.970611],
              [91.216153, 27.989105],
              [91.162567, 27.968497],
              [91.155175, 27.896076],
              [91.113292, 27.846357],
              [91.025828, 27.857466],
              [90.96485, 27.900306],
              [90.96177, 27.9537],
              [90.896481, 27.9463],
              [90.853365, 27.969026],
              [90.806554, 28.01499],
              [90.802242, 28.040342],
              [90.741264, 28.053015],
              [90.701844, 28.076246],
              [90.591591, 28.021329],
              [90.569417, 28.044039],
              [90.513983, 28.061992],
              [90.47949, 28.044567],
              [90.43699, 28.063048],
              [90.384019, 28.060936],
              [90.367389, 28.088916],
              [90.296556, 28.15435],
              [90.231882, 28.144854],
              [90.166593, 28.187579],
              [90.124709, 28.190743],
              [90.103151, 28.141688],
              [90.07297, 28.155405],
              [90.03355, 28.13694],
              [89.976268, 28.189161],
              [89.906051, 28.180723],
              [89.869094, 28.221853],
              [89.789638, 28.24083],
              [89.779167, 28.197071],
              [89.720037, 28.170175],
              [89.605472, 28.161735],
              [89.541414, 28.088388],
              [89.511233, 28.086276],
              [89.461958, 28.031892],
              [89.44348, 27.968497],
              [89.375727, 27.875979],
              [89.295655, 27.848473],
              [89.238988, 27.796616],
              [89.184786, 27.673752],
              [89.131815, 27.633474],
              [89.129351, 27.609617],
              [89.163228, 27.574619],
              [89.104713, 27.534835],
              [89.095474, 27.471681],
              [89.132431, 27.441417],
              [89.182938, 27.373959],
              [89.152757, 27.318687],
              [89.077612, 27.287319],
              [89.057286, 27.234663],
              [88.975982, 27.217106],
              [88.942105, 27.261792],
              [88.911924, 27.274024],
              [88.920548, 27.325598],
              [88.901453, 27.327192],
              [88.867577, 27.381929],
              [88.838012, 27.37821],
              [88.809063, 27.405834],
              [88.783193, 27.467434],
              [88.797976, 27.52157],
              [88.780729, 27.535896],
              [88.813374, 27.606966],
              [88.816454, 27.641424],
              [88.852178, 27.671103],
              [88.850331, 27.710838],
              [88.870657, 27.743145],
              [88.863265, 27.811964],
              [88.888519, 27.846886],
              [88.864497, 27.921455],
              [88.846635, 27.921455],
              [88.842939, 28.006539],
              [88.812142, 28.01816],
              [88.764099, 28.068327],
              [88.67602, 28.068327],
              [88.645223, 28.111083],
              [88.620585, 28.091555],
              [88.565151, 28.083109],
              [88.554064, 28.027667],
              [88.478919, 28.034005],
              [88.469064, 28.009708],
              [88.401311, 27.976952],
              [88.357579, 27.986463],
              [88.254101, 27.939429],
              [88.242398, 27.96744],
              [88.203594, 27.943129],
              [88.156783, 27.957928],
              [88.120442, 27.915111],
              [88.137689, 27.878624],
              [88.111819, 27.864872],
              [88.037291, 27.901892],
              [87.982472, 27.884442],
              [87.930733, 27.909295],
              [87.826639, 27.927799],
              [87.782292, 27.890788],
              [87.77798, 27.860111],
              [87.727473, 27.802967],
              [87.668343, 27.809847],
              [87.670191, 27.832072],
              [87.598126, 27.81461],
              [87.590119, 27.848473],
              [87.558706, 27.862756],
              [87.538996, 27.841596],
              [87.45954, 27.82096],
              [87.418272, 27.825722],
              [87.420735, 27.859053],
              [87.364069, 27.824135],
              [87.280917, 27.845299],
              [87.227946, 27.813022],
              [87.173744, 27.818314],
              [87.118309, 27.840537],
              [87.080737, 27.910881],
              [87.035157, 27.9463],
              [86.935375, 27.955285],
              [86.926752, 27.985935],
              [86.885484, 27.995973],
              [86.864542, 28.022385],
              [86.827586, 28.012349],
              [86.756753, 28.032948],
              [86.768456, 28.069383],
              [86.700086, 28.101583],
              [86.647732, 28.069383],
              [86.611391, 28.069911],
              [86.568891, 28.103167],
              [86.55842, 28.047735],
              [86.537478, 28.044567],
              [86.513457, 27.996501],
              [86.514689, 27.954757],
              [86.475884, 27.944714],
              [86.450015, 27.908766],
              [86.414906, 27.904536],
              [86.393349, 27.926742],
              [86.308965, 27.950529],
              [86.27324, 27.976952],
              [86.231972, 27.97431],
              [86.206103, 28.084165],
              [86.223965, 28.092611],
              [86.19132, 28.16701],
              [86.128495, 28.086804],
              [86.086611, 28.089971],
              [86.082915, 28.01816],
              [86.125415, 27.923041],
              [86.053966, 27.900306],
              [86.001611, 27.90718],
              [85.949256, 27.937314],
              [85.980053, 27.984349],
              [85.901213, 28.053543],
              [85.903061, 28.094722],
              [85.871648, 28.125331],
              [85.854402, 28.172284],
              [85.791576, 28.195489],
              [85.753388, 28.227652],
              [85.720743, 28.371999],
              [85.682555, 28.375684],
              [85.650526, 28.283517],
              [85.601251, 28.254007],
              [85.602483, 28.295635],
              [85.526106, 28.324607],
              [85.460816, 28.333034],
              [85.415853, 28.321447],
              [85.380128, 28.27456],
              [85.350563, 28.297216],
              [85.272339, 28.282463],
              [85.209513, 28.338827],
              [85.179948, 28.32408],
              [85.113427, 28.34462],
              [85.129441, 28.37779],
              [85.108499, 28.461459],
              [85.160238, 28.492489],
              [85.189803, 28.545062],
              [85.195963, 28.623871],
              [85.126361, 28.675854],
              [85.05676, 28.674279],
              [84.981616, 28.586576],
              [84.896616, 28.587101],
              [84.857196, 28.56766],
              [84.773428, 28.610215],
              [84.698284, 28.633325],
              [84.699515, 28.671654],
              [84.669334, 28.680578],
              [84.650856, 28.714692],
              [84.557233, 28.746172],
              [84.483321, 28.735155],
              [84.445133, 28.764007],
              [84.434046, 28.824307],
              [84.408176, 28.854182],
              [84.330568, 28.859422],
              [84.268358, 28.895573],
              [84.234481, 28.889287],
              [84.228322, 28.950038],
              [84.248648, 29.030635],
              [84.194445, 29.044759],
              [84.20738, 29.118487],
              [84.176583, 29.133645],
              [84.17104, 29.194774],
              [84.203068, 29.240206],
              [84.130388, 29.240206],
              [84.116837, 29.286661],
              [84.052163, 29.297098],
              [84.002272, 29.291358],
              [84.004736, 29.312751],
              [83.917273, 29.324749],
              [83.851367, 29.295011],
              [83.825498, 29.29762],
              [83.805788, 29.256389],
              [83.727563, 29.244383],
              [83.667201, 29.199997],
              [83.656114, 29.167088],
              [83.596368, 29.173879],
              [83.57789, 29.203653],
              [83.548941, 29.201042],
              [83.492274, 29.279877],
              [83.463941, 29.28614],
              [83.450391, 29.332574],
              [83.423289, 29.360737],
              [83.415898, 29.420168],
              [83.383253, 29.421731],
              [83.325355, 29.503],
              [83.27608, 29.506124],
              [83.266841, 29.571194],
              [83.217565, 29.600332],
              [83.164595, 29.595649],
              [83.12887, 29.62374],
              [83.088834, 29.605014],
              [83.016153, 29.66742],
              [82.952096, 29.665861],
              [82.9484, 29.704846],
              [82.876951, 29.686655],
              [82.830756, 29.687694],
              [82.820285, 29.715759],
              [82.774089, 29.726671],
              [82.757459, 29.761997],
              [82.691553, 29.766152],
              [82.737749, 29.806136],
              [82.703872, 29.847662],
              [82.6238, 29.834687],
              [82.64351, 29.868937],
              [82.609017, 29.886576],
              [82.560974, 29.955547],
              [82.498148, 29.947771],
              [82.412533, 30.012037],
              [82.368185, 30.014109],
              [82.333693, 30.04519],
              [82.311519, 30.035867],
              [82.246845, 30.071601],
              [82.17786, 30.067976],
              [82.183403, 30.121814],
              [82.207425, 30.143548],
              [82.188947, 30.18545],
              [82.114418, 30.226816],
              [82.132896, 30.304333],
              [82.104563, 30.346682],
              [82.060215, 30.332224],
              [82.022027, 30.339453],
              [81.99123, 30.322927],
              [81.954274, 30.355976],
              [81.939491, 30.344617],
              [81.872354, 30.373012],
              [81.759021, 30.385401],
              [81.63029, 30.446802],
              [81.613044, 30.412752],
              [81.566232, 30.428747],
              [81.555761, 30.369399],
              [81.494783, 30.381271],
              [81.454131, 30.412236],
              [81.406704, 30.40398],
              [81.432573, 30.379207],
              [81.406088, 30.369399],
              [81.399929, 30.319312],
              [81.427646, 30.305366],
              [81.397465, 30.240774],
              [81.393769, 30.199413],
              [81.335871, 30.150791],
              [81.269349, 30.153378],
              [81.293371, 30.094899],
              [81.2829, 30.061244],
              [81.225618, 30.005301],
              [81.131995, 30.016181],
              [81.09627, 30.052958],
              [81.110437, 30.08558],
              [81.085799, 30.100593],
              [81.082104, 30.151309],
              [81.038372, 30.205101],
              [81.034677, 30.246977],
              [80.996488, 30.267649],
              [80.933662, 30.266616],
              [80.910873, 30.302783],
              [80.81725, 30.321378],
              [80.719316, 30.414816],
              [80.697758, 30.4143],
              [80.633084, 30.458665],
              [80.585041, 30.463823],
              [80.549316, 30.448866],
              [80.504969, 30.483419],
              [80.446454, 30.495277],
              [80.43044, 30.515897],
              [80.357759, 30.520536],
              [80.322035, 30.564338],
              [80.261673, 30.566399],
              [80.214245, 30.585974],
              [80.124934, 30.558671],
              [80.084898, 30.592154],
              [80.04363, 30.603485],
              [80.014065, 30.662176],
              [79.970333, 30.685848],
              [79.961094, 30.771225],
              [79.900732, 30.798982],
              [79.913051, 30.832896],
              [79.890877, 30.854986],
              [79.835443, 30.850876],
              [79.75845, 30.93662],
              [79.668523, 30.980233],
              [79.660516, 30.956633],
              [79.59769, 30.925843],
              [79.550879, 30.958173],
              [79.505915, 31.027415],
              [79.427075, 31.018186],
              [79.421531, 31.067399],
              [79.398126, 31.068424],
              [79.35809, 31.031517],
              [79.316206, 31.017673],
              [79.33222, 30.96946],
              [79.227511, 30.94945],
              [79.201025, 31.006391],
              [79.181931, 31.015622],
              [79.0957, 30.993057],
              [79.059359, 31.02844],
              [79.010084, 31.044333],
              [78.975591, 31.122221],
              [78.997765, 31.159093],
              [78.930628, 31.220514],
              [78.865338, 31.313082],
              [78.841933, 31.288542],
              [78.779723, 31.317171],
              [78.755085, 31.3555],
              [78.760013, 31.392792],
              [78.792041, 31.436195],
              [78.755701, 31.478557],
              [78.729832, 31.478047],
              [78.740303, 31.532631],
              [78.779723, 31.545379],
              [78.833925, 31.584634],
              [78.845628, 31.610115],
              [78.806824, 31.641193],
              [78.798817, 31.675316],
              [78.763092, 31.668696],
              [78.706426, 31.778626],
              [78.654071, 31.821849],
              [78.665158, 31.851839],
              [78.739687, 31.885376],
              [78.768636, 31.926519],
              [78.762476, 31.946829],
              [78.704578, 31.988959],
              [78.599868, 32.024982],
              [78.609107, 32.052371],
              [78.519796, 32.123847],
              [78.509941, 32.147153],
              [78.469905, 32.127901],
              [78.449579, 32.146647],
              [78.430485, 32.211975],
              [78.475449, 32.236777],
              [78.511173, 32.308108],
              [78.480992, 32.329345],
              [78.458818, 32.379889],
              [78.472985, 32.435454],
              [78.395377, 32.530342],
              [78.424942, 32.565647],
              [78.500086, 32.580773],
              [78.518564, 32.605978],
              [78.577695, 32.61505],
              [78.588782, 32.637726],
              [78.628202, 32.630168],
              [78.6861, 32.68004],
              [78.741534, 32.703706],
              [78.74215, 32.655359],
              [78.781571, 32.607994],
              [78.760629, 32.56363],
              [78.782186, 32.480387],
              [78.81052, 32.436464],
              [78.87273, 32.40515],
              [78.970664, 32.331873],
              [79.005772, 32.375341],
              [79.067982, 32.380899],
              [79.103091, 32.369782],
              [79.135736, 32.472311],
              [79.190554, 32.511675],
              [79.252148, 32.51672],
              [79.272474, 32.561108],
              [79.308199, 32.596905],
              [79.27309, 32.678025],
              [79.301423, 32.728877],
              [79.275554, 32.778693],
              [79.224431, 32.784729],
              [79.233054, 32.909893],
              [79.255844, 32.942537],
              [79.204105, 32.965632],
              [79.162837, 33.011804],
              [79.139431, 33.117606],
              [79.162221, 33.166202],
              [79.133888, 33.196248],
              [79.072294, 33.228286],
              [79.083997, 33.245301],
              [79.041497, 33.268316],
              [79.022403, 33.323328],
              [78.9682, 33.334327],
              [78.949722, 33.376307],
              [78.896751, 33.412774],
              [78.84686, 33.421264],
              [78.816679, 33.480671],
              [78.74215, 33.553501],
              [78.755085, 33.623281],
              [78.713201, 33.622783],
              [78.684868, 33.654664],
              [78.692259, 33.676575],
              [78.779723, 33.732323],
              [78.758165, 33.791019],
              [78.766172, 33.823335],
              [78.762476, 33.909284],
              [78.732911, 33.919711],
              [78.744614, 33.980759],
              [78.702114, 34.01945],
              [78.656535, 34.030359],
              [78.661462, 34.086868],
              [78.750158, 34.092815],
              [78.793273, 34.132445],
              [78.828998, 34.125511],
              [78.878273, 34.16265],
              [78.9257, 34.155719],
              [78.958345, 34.230944],
              [78.981751, 34.318458],
              [79.028562, 34.313516],
              [79.039649, 34.33427],
              [78.973128, 34.362921],
              [78.958961, 34.386132],
              [78.899831, 34.355018],
              [78.878273, 34.391563],
              [78.809288, 34.433027],
              [78.742766, 34.454737],
              [78.757549, 34.483348],
              [78.708274, 34.522301],
              [78.634977, 34.538074],
              [78.58139, 34.505539],
              [78.562912, 34.512934],
              [78.542586, 34.574539],
              [78.492695, 34.57848],
              [78.436029, 34.543496],
              [78.427405, 34.594243],
              [78.346101, 34.604093],
              [78.280812, 34.623298],
              [78.265413, 34.651358],
              [78.267261, 34.705482],
              [78.213059, 34.717778],
              [78.230921, 34.776282],
              [78.237696, 34.882369],
              [78.206283, 34.891694],
              [78.182262, 34.936832],
              [78.201972, 34.974592],
              [78.160704, 34.99126],
              [78.123131, 35.036833],
              [78.150849, 35.06965],
              [78.124979, 35.108327],
              [78.078784, 35.100006],
              [78.062769, 35.11469],
              [78.060306, 35.180248],
              [78.01719, 35.22816],
              [78.020885, 35.315111],
              [78.013494, 35.36587],
              [78.107117, 35.437075],
              [78.136066, 35.49263],
              [78.048603, 35.491168],
              [78.035668, 35.467781],
              [78.009799, 35.491655],
              [77.951284, 35.478501],
              [77.917408, 35.491168],
              [77.914944, 35.464857],
              [77.816394, 35.518445],
              [77.797299, 35.491168],
              [77.757879, 35.497501],
              [77.735706, 35.461446],
              [77.690742, 35.448287],
              [77.657481, 35.477526],
              [77.639619, 35.45511],
              [77.578025, 35.475577],
              [77.518895, 35.481912],
              [77.451758, 35.460471],
              [77.396939, 35.467781],
              [77.307628, 35.540356],
              [77.195527, 35.519419],
              [77.093281, 35.569563],
              [77.072339, 35.590974],
              [76.99781, 35.61092],
              [76.967013, 35.591947],
              [76.917738, 35.608488],
              [76.83705, 35.66198],
              [76.76129, 35.65566],
              [76.751435, 35.68045],
              [76.698464, 35.722236],
              [76.69292, 35.747492],
              [76.593754, 35.772253],
              [76.566037, 35.819328],
              [76.587595, 35.840188],
              [76.59745, 35.895947],
              [76.54879, 35.919209],
              [76.51553, 35.88092],
              [76.464407, 35.881405],
              [76.431762, 35.851828],
              [76.369552, 35.863466],
              [76.365857, 35.82418],
              [76.298719, 35.841643],
              [76.228502, 35.837277],
              [76.221727, 35.82321],
              [76.147198, 35.833397],
              [76.16506, 35.909033],
              [76.117017, 35.974913],
              [76.104082, 36.018964],
              [76.014771, 36.017996],
              [75.961184, 36.051381],
              [75.942706, 36.137923],
              [75.96796, 36.158699],
              [76.016619, 36.165461],
              [76.011691, 36.229197],
              [76.060967, 36.225335],
              [76.055423, 36.252843],
              [75.998757, 36.312168],
              [75.989518, 36.340127],
              [76.035097, 36.409017],
              [75.991981, 36.505264],
              [75.924228, 36.566319],
              [75.945786, 36.588421],
              [75.871873, 36.66621],
              [75.724048, 36.750632],
              [75.634121, 36.771723],
              [75.588541, 36.762616],
              [75.537418, 36.773161],
              [75.536802, 36.730015],
              [75.504773, 36.743441],
              [75.458578, 36.720903],
              [75.425933, 36.778912],
              [75.430245, 36.873262],
              [75.396368, 36.904368],
              [75.413614, 36.954588],
              [75.288579, 36.974667],
              [75.244847, 36.963194],
              [75.16847, 36.991873],
              [75.148144, 37.026274],
              [75.130898, 37.010987],
              [75.063145, 37.006209],
              [75.032348, 37.01672],
              [75.005862, 36.99474],
              [74.927638, 36.978013],
              [74.938725, 36.943112],
              [74.893762, 36.939764],
              [74.84387, 37.013375],
              [74.84695, 37.056839],
              [74.806914, 37.054452],
              [74.792747, 37.027707],
              [74.739161, 37.028185],
              [74.70898, 37.084529],
              [74.632603, 37.066866],
              [74.617205, 37.043468],
              [74.56793, 37.032961],
              [74.530357, 37.082142],
              [74.498944, 37.072595],
              [74.496481, 37.116025],
              [74.465068, 37.147031],
              [74.487858, 37.161814],
              [74.477387, 37.199474],
              [74.511263, 37.239973],
              [74.54514, 37.249499],
              [74.578401, 37.231399],
              [74.598727, 37.258548],
              [74.642458, 37.261405],
              [74.665864, 37.235686],
              [74.727458, 37.282831],
              [74.753943, 37.280927],
              [74.800139, 37.24807],
              [74.816153, 37.216629],
              [74.911008, 37.23378],
              [74.927022, 37.277594],
              [75.018181, 37.293779],
              [75.078543, 37.318051],
              [75.125971, 37.322334],
              [75.140137, 37.355633],
              [75.125971, 37.387967],
              [75.153072, 37.414109],
              [75.129666, 37.459243],
              [75.069304, 37.513367],
              [75.035428, 37.501026],
              [74.940573, 37.558914],
              [74.891914, 37.668399],
              [74.920863, 37.684501],
              [74.923327, 37.717166],
              [75.006478, 37.771102],
              [74.989848, 37.798056],
              [74.917167, 37.844847],
              [74.936877, 37.876024],
              [74.919015, 37.908132],
              [74.911008, 37.967118],
              [74.92579, 38.017102],
              [74.879595, 38.021344],
              [74.821697, 38.102842],
              [74.80445, 38.166847],
              [74.816769, 38.215283],
              [74.793363, 38.270734],
              [74.806914, 38.285764],
              [74.789668, 38.324734],
              [74.868508, 38.404018],
              [74.862965, 38.484152],
              [74.821697, 38.491177],
              [74.792747, 38.536121],
              [74.717603, 38.542205],
              [74.639995, 38.599744],
              [74.613509, 38.593197],
              [74.546988, 38.607693],
              [74.506336, 38.63761],
              [74.455829, 38.632936],
              [74.377604, 38.6549],
              [74.229779, 38.656302],
              [74.147859, 38.676858],
              [74.11275, 38.611433],
              [74.068403, 38.585714],
              [74.090577, 38.542205],
              [74.034526, 38.541737],
              [74.011736, 38.524888],
              [73.926121, 38.536121],
              [73.89902, 38.579166],
              [73.852208, 38.584311],
              [73.799237, 38.610966],
              [73.809092, 38.634339],
              [73.757353, 38.719818],
              [73.769056, 38.775815],
              [73.729636, 38.836895],
              [73.699455, 38.857865],
              [73.70931, 38.893266],
              [73.742571, 38.933769],
              [73.767824, 38.941215],
              [73.826339, 38.917012],
              [73.846665, 38.962154],
              [73.820179, 39.041665],
              [73.743187, 39.029581],
              [73.720397, 39.071865],
              [73.719781, 39.108088],
              [73.688368, 39.154964],
              [73.657571, 39.166098],
              [73.639709, 39.220353],
              [73.580579, 39.237502],
              [73.542391, 39.269471],
              [73.554094, 39.350023],
              [73.502355, 39.383791],
              [73.592898, 39.412457],
              [73.61076, 39.466059],
              [73.6471, 39.474373],
              [73.745651, 39.461902],
              [73.868223, 39.482686],
              [73.914418, 39.563915],
              [73.916266, 39.586513],
              [73.953838, 39.600345],
              [73.927969, 39.703536],
              [73.905795, 39.742193],
              [73.841737, 39.756453],
              [73.845433, 39.831389],
              [73.907027, 39.873647],
              [73.910722, 39.934693],
              [73.980324, 40.004851],
              [73.943367, 40.015849],
              [74.008041, 40.051125],
              [74.023439, 40.085008],
              [74.089345, 40.079973],
              [74.126301, 40.10469],
              [74.26304, 40.125281],
              [74.285829, 40.095536],
              [74.311699, 40.108351],
              [74.356662, 40.089128],
              [74.442894, 40.137175],
              [74.476771, 40.17513],
              [74.546988, 40.219004],
              [74.577169, 40.260567],
              [74.618437, 40.279741],
              [74.673255, 40.278828],
              [74.697893, 40.309861],
              [74.697893, 40.344527],
              [74.811842, 40.347263],
              [74.862965, 40.326284],
              [74.908544, 40.339055],
              [74.795211, 40.443412],
              [74.814921, 40.461169],
              [74.819233, 40.505767],
              [74.844486, 40.521233],
              [74.891914, 40.507587],
              [74.973218, 40.460258],
              [75.021877, 40.467086],
              [75.102565, 40.439769],
              [75.13521, 40.463445],
              [75.242383, 40.448876],
              [75.268869, 40.483471],
              [75.292274, 40.483926],
              [75.355716, 40.538059],
              [75.432093, 40.563518],
              [75.467817, 40.599872],
              [75.550353, 40.648917],
              [75.599628, 40.659812],
              [75.636584, 40.624399],
              [75.631041, 40.548971],
              [75.646439, 40.516684],
              [75.733287, 40.474369],
              [75.686475, 40.417907],
              [75.669845, 40.363678],
              [75.688323, 40.344071],
              [75.709265, 40.28111],
              [75.785642, 40.301191],
              [75.831221, 40.327196],
              [75.921764, 40.291151],
              [75.932235, 40.339511],
              [75.986438, 40.381911],
              [76.048648, 40.356839],
              [76.048648, 40.388747],
              [76.144118, 40.393304],
              [76.176147, 40.381455],
              [76.213719, 40.394671],
              [76.279625, 40.439314],
              [76.283321, 40.415174],
              [76.327668, 40.391482],
              [76.333212, 40.343615],
              [76.381871, 40.391026],
              [76.390494, 40.377809],
              [76.442233, 40.391482],
              [76.470566, 40.422462],
              [76.508754, 40.42975],
              [76.539551, 40.4639],
              [76.556798, 40.542606],
              [76.611, 40.601689],
              [76.657196, 40.620312],
              [76.654732, 40.652549],
              [76.676906, 40.696113],
              [76.646725, 40.760045],
              [76.693536, 40.77953],
              [76.731724, 40.818935],
              [76.741579, 40.912147],
              [76.761905, 40.954185],
              [76.85368, 40.976323],
              [76.885709, 41.027348],
              [76.940528, 41.028703],
              [77.002122, 41.073373],
              [77.023064, 41.059388],
              [77.091433, 41.062546],
              [77.108063, 41.038181],
              [77.169041, 41.009291],
              [77.236795, 41.0278],
              [77.296541, 41.004776],
              [77.363062, 41.040888],
              [77.473931, 41.022835],
              [77.476395, 40.999357],
              [77.540453, 41.006582],
              [77.591576, 40.992132],
              [77.654402, 41.016063],
              [77.684583, 41.007936],
              [77.737553, 41.032313],
              [77.780669, 41.022835],
              [77.829328, 41.059388],
              [77.807155, 41.091864],
              [77.814546, 41.134238],
              [77.905089, 41.185141],
              [78.003639, 41.181988],
              [78.094798, 41.224304],
              [78.129291, 41.228354],
              [78.136682, 41.279184],
              [78.165015, 41.340756],
              [78.162551, 41.382521],
              [78.240776, 41.399578],
              [78.324544, 41.384316],
              [78.383674, 41.394192],
              [78.391681, 41.408105],
              [78.458818, 41.41349],
              [78.534579, 41.445789],
              [78.580774, 41.481659],
              [78.650375, 41.467314],
              [78.707042, 41.522437],
              [78.696571, 41.541696],
              [78.739071, 41.555578],
              [78.825302, 41.560503],
              [78.891824, 41.597651],
              [78.959577, 41.652663],
              [79.021787, 41.657134],
              [79.043345, 41.681269],
              [79.10925, 41.697354],
              [79.138199, 41.722814],
              [79.21704, 41.725493],
              [79.326061, 41.809391],
              [79.361169, 41.796457],
              [79.415372, 41.837036],
              [79.462799, 41.848625],
              [79.550879, 41.833915],
              [79.604465, 41.849071],
              [79.640806, 41.884717],
              [79.724574, 41.896743],
              [79.776313, 41.892734],
              [79.822508, 41.963512],
              [79.854537, 41.984419],
              [79.852689, 42.015544],
              [79.918594, 42.041322],
              [80.089826, 42.047543],
              [80.14218, 42.034656],
              [80.193303, 42.081301],
              [80.16805, 42.096842],
              [80.139717, 42.150983],
              [80.163738, 42.152314],
              [80.168666, 42.200202],
              [80.233339, 42.210396],
              [80.28631, 42.232993],
              [80.29247, 42.259569],
              [80.270296, 42.28746],
              [80.283847, 42.320649],
              [80.229028, 42.358241],
              [80.240115, 42.388741],
              [80.206238, 42.428943],
              [80.225948, 42.485888],
              [80.265368, 42.502211],
              [80.221637, 42.533523],
              [80.164354, 42.627363],
              [80.179753, 42.670491],
              [80.228412, 42.692923],
              [80.225948, 42.713149],
              [80.280151, 42.838316],
              [80.328194, 42.830419],
              [80.409498, 42.835684],
              [80.450766, 42.862004],
              [80.572722, 42.895765],
              [80.598591, 42.915924],
              [80.487106, 42.94878],
              [80.397795, 42.996936],
              [80.378701, 43.031497],
              [80.416889, 43.056859],
              [80.482795, 43.069536],
              [80.556092, 43.104494],
              [80.593048, 43.133319],
              [80.665729, 43.147726],
              [80.73225, 43.131135],
              [80.752576, 43.148163],
              [80.79446, 43.137248],
              [80.804315, 43.178276],
              [80.788917, 43.242381],
              [80.769207, 43.265477],
              [80.777214, 43.30816],
              [80.69283, 43.32035],
              [80.686055, 43.333842],
              [80.735946, 43.389523],
              [80.761199, 43.446456],
              [80.75504, 43.49422],
              [80.522215, 43.816724],
              [80.511128, 43.906887],
              [80.475404, 43.940071],
              [80.453846, 43.989596],
              [80.449534, 44.077778],
              [80.3941, 44.127189],
              [80.407034, 44.149946],
              [80.400875, 44.198868],
              [80.413194, 44.264461],
              [80.399027, 44.306009],
              [80.379933, 44.413383],
              [80.350368, 44.484713],
              [80.411962, 44.605392],
              [80.400259, 44.628817],
              [80.313412, 44.704987],
              [80.238883, 44.722845],
              [80.200695, 44.75642],
              [80.178521, 44.796769],
              [80.18776, 44.825633],
              [80.169898, 44.844727],
              [80.115695, 44.815023],
              [80.087978, 44.817145],
              [79.999283, 44.793797],
              [79.991891, 44.830301],
              [79.953703, 44.849393],
              [79.969102, 44.877383],
              [79.887798, 44.909173],
              [79.944464, 44.937981],
              [80.056565, 45.011207],
              [80.060876, 45.02601],
              [80.144644, 45.058985],
              [80.195767, 45.030661],
              [80.24381, 45.031507],
              [80.291854, 45.065747],
              [80.328194, 45.069973],
              [80.358375, 45.040809],
              [80.404571, 45.049264],
              [80.443991, 45.077579],
              [80.445839, 45.097856],
              [80.493882, 45.126991],
              [80.519135, 45.108836],
              [80.599207, 45.10588],
              [80.686055, 45.129102],
              [80.731634, 45.156111],
              [80.816634, 45.152736],
              [80.862214, 45.126991],
              [80.897938, 45.127413],
              [80.93551, 45.16033],
              [81.024821, 45.162862],
              [81.080872, 45.183108],
              [81.111669, 45.218522],
              [81.170183, 45.210935],
              [81.175111, 45.227794],
              [81.236705, 45.247175],
              [81.284748, 45.23917],
              [81.327864, 45.260653],
              [81.382066, 45.257705],
              [81.412863, 45.280865],
              [81.44982, 45.265707],
              [81.52866, 45.285917],
              [81.575471, 45.307803],
              [81.582863, 45.33641],
              [81.634601, 45.357856],
              [81.730688, 45.363321],
              [81.78797, 45.383497],
              [81.844637, 45.306119],
              [81.879745, 45.284233],
              [81.921013, 45.233272],
              [81.993078, 45.238328],
              [82.052824, 45.25602],
              [82.09594, 45.249702],
              [82.109491, 45.211357],
              [82.206809, 45.236642],
              [82.294272, 45.247596],
              [82.344779, 45.218944],
              [82.487061, 45.181],
              [82.562822, 45.204612],
              [82.58746, 45.224001],
              [82.60101, 45.346083],
              [82.546808, 45.425925],
              [82.448257, 45.461187],
              [82.281954, 45.538772],
              [82.266555, 45.620015],
              [82.288729, 45.655575],
              [82.289961, 45.7166],
              [82.340468, 45.772552],
              [82.349707, 45.823027],
              [82.336156, 45.882204],
              [82.342932, 45.935076],
              [82.401446, 45.972099],
              [82.461808, 45.979999],
              [82.518474, 46.153938],
              [82.609017, 46.295093],
              [82.726662, 46.494818],
              [82.774089, 46.600163],
              [82.788872, 46.677805],
              [82.829524, 46.772551],
              [82.878183, 46.797133],
              [82.876335, 46.82375],
              [82.923762, 46.932133],
              [82.937929, 47.014193],
              [82.993364, 47.06557],
              [83.031552, 47.168176],
              [83.02724, 47.215341],
              [83.108544, 47.222249],
              [83.15474, 47.236063],
              [83.207094, 47.213715],
              [83.221877, 47.186884],
              [83.257602, 47.173057],
              [83.306261, 47.179564],
              [83.33213, 47.167769],
              [83.370318, 47.178751],
              [83.418978, 47.118934],
              [83.463325, 47.131961],
              [83.576042, 47.059049],
              [83.700462, 47.03214],
              [83.69923, 47.015825],
              [83.766367, 47.026838],
              [83.88586, 46.981956],
              [83.932671, 46.970117],
              [83.951765, 46.987262],
              [84.002888, 46.990527],
              [84.086656, 46.965217],
              [84.150098, 46.977465],
              [84.195061, 47.003586],
              [84.2893, 46.994608],
              [84.336727, 47.005218],
              [84.37122, 46.993384],
              [84.425422, 47.00889],
              [84.506726, 46.972975],
              [84.563393, 46.991751],
              [84.668718, 46.995016],
              [84.748175, 47.009706],
              [84.781435, 46.979915],
              [84.849189, 46.95705],
              [84.867051, 46.927638],
              [84.934188, 46.863857],
              [84.979768, 46.883081],
              [84.987159, 46.918239],
              [85.082014, 46.939895],
              [85.102956, 46.968892],
              [85.175637, 46.998281],
              [85.213825, 47.041111],
              [85.276651, 47.068831],
              [85.325926, 47.044781],
              [85.441106, 47.063533],
              [85.545816, 47.057826],
              [85.547048, 47.096536],
              [85.582772, 47.14295],
              [85.682555, 47.222655],
              [85.701033, 47.288444],
              [85.675163, 47.318063],
              [85.701649, 47.384138],
              [85.685018, 47.429087],
              [85.614801, 47.497853],
              [85.617881, 47.550781],
              [85.547048, 48.00833],
              [85.529186, 48.02714],
              [85.551975, 48.081531],
              [85.55136, 48.127879],
              [85.587084, 48.191738],
              [85.622193, 48.202905],
              [85.633895, 48.232805],
              [85.678243, 48.266272],
              [85.695489, 48.335129],
              [85.758315, 48.402702],
              [85.791576, 48.418986],
              [85.916612, 48.438043],
              [86.053966, 48.441218],
              [86.225813, 48.432485],
              [86.270161, 48.452331],
              [86.305269, 48.491999],
              [86.38103, 48.493585],
              [86.416138, 48.481688],
              [86.579978, 48.538768],
              [86.594761, 48.576785],
              [86.640956, 48.629012],
              [86.693311, 48.643641],
              [86.70255, 48.666171],
              [86.780774, 48.73133],
              [86.754289, 48.78458],
              [86.770303, 48.810199],
              [86.818963, 48.831078],
              [86.822042, 48.849193],
              [86.757985, 48.894844],
              [86.730267, 48.959707],
              [86.732731, 48.995444],
              [86.769688, 49.026448],
              [86.836209, 49.051159],
              [86.848528, 49.103285],
              [86.88918, 49.132656],
              [86.95139, 49.13109],
              [87.000049, 49.142442],
              [87.088128, 49.13383],
              [87.112766, 49.155748],
              [87.211932, 49.140485],
              [87.239033, 49.114644],
              [87.304939, 49.112294],
              [87.388707, 49.098193],
              [87.43675, 49.075073],
              [87.511894, 49.101718],
              [87.49896, 49.141268],
              [87.563017, 49.142442],
              [87.685589, 49.160053],
              [87.700372, 49.175701],
              [87.821096, 49.173745],
              [87.82048, 49.148313],
              [87.845733, 49.145965],
              [87.867291, 49.108769],
              [87.844502, 49.089965],
              [87.85682, 49.068802],
              [87.833415, 49.050374],
              [87.883306, 49.023702],
              [87.883922, 48.993874],
              [87.911639, 48.980132],
              [87.871603, 48.963636],
              [87.87653, 48.949099],
              [87.814321, 48.94517],
              [87.763198, 48.926697],
              [87.742256, 48.881074],
              [87.78106, 48.872417],
              [87.829103, 48.825564],
              [87.799538, 48.818079],
              [87.832183, 48.799559],
              [87.872219, 48.799559],
              [87.93874, 48.757765],
              [87.96153, 48.77354],
              [88.029283, 48.75027],
              [88.064392, 48.712778],
              [88.090877, 48.719884],
              [88.089645, 48.695009],
              [88.02682, 48.653129],
              [88.027436, 48.62743],
              [87.96153, 48.599344],
              [87.973233, 48.575993],
              [88.041602, 48.548275],
              [88.10874, 48.545898],
              [88.196819, 48.493982],
              [88.229464, 48.498343],
              [88.318159, 48.478516],
              [88.363123, 48.460267],
              [88.360659, 48.433279],
              [88.443811, 48.391579],
              [88.503557, 48.413029],
              [88.535586, 48.368927],
              [88.573158, 48.369722],
              [88.605803, 48.337914],
              [88.575006, 48.277423],
              [88.601491, 48.221644],
              [88.663085, 48.172189],
              [88.704969, 48.181366],
              [88.721599, 48.160616],
              [88.79736, 48.133869],
              [88.824461, 48.106708],
              [88.939026, 48.115497],
              [88.953808, 48.090724],
              [89.027105, 48.050743],
              [89.044967, 48.009931],
              [89.078228, 47.98711],
              [89.166923, 47.996319],
              [89.231597, 47.980301],
              [89.282104, 47.993917],
              [89.308589, 48.021938],
              [89.372031, 48.032342],
              [89.38127, 48.046344],
              [89.498299, 48.02834],
              [89.569132, 48.037943],
              [89.599313, 48.015534],
              [89.595617, 47.973492],
              [89.645508, 47.94785],
              [89.651052, 47.913774],
              [89.735435, 47.897329],
              [89.761921, 47.835916],
              [89.845688, 47.833506],
              [89.957789, 47.842743],
              [89.960253, 47.885694],
              [90.086521, 47.865628],
              [90.070506, 47.820651],
              [90.07605, 47.777646],
              [90.13518, 47.723337],
              [90.192462, 47.722532],
              [90.216484, 47.705221],
              [90.331665, 47.68146],
              [90.384635, 47.644387],
              [90.346447, 47.637534],
              [90.376012, 47.60285],
              [90.398186, 47.547551],
              [90.468403, 47.497853],
              [90.474562, 47.462267],
              [90.459164, 47.4388],
              [90.468403, 47.404795],
              [90.507823, 47.399935],
              [90.526301, 47.378871],
              [90.488113, 47.317252],
              [90.521374, 47.284791],
              [90.56141, 47.207212],
              [90.579888, 47.198269],
              [90.653801, 47.111604],
              [90.691989, 47.080648],
              [90.767134, 46.992568],
              [90.830575, 46.995833],
              [90.901408, 46.960725],
              [90.92235, 46.938669],
              [90.929742, 46.893304],
              [90.958075, 46.8794],
              [90.942676, 46.825797],
              [91.019053, 46.766403],
              [91.054161, 46.71761],
              [91.036299, 46.670416],
              [91.017821, 46.582483],
              [91.068328, 46.579193],
              [91.079415, 46.558626],
              [91.038147, 46.500997],
              [91.025828, 46.443719],
              [90.996263, 46.419388],
              [90.983328, 46.374823],
              [90.900177, 46.31204],
              [90.955611, 46.233874],
              [90.94822, 46.218973],
              [90.98456, 46.16057],
              [91.021517, 46.121185],
              [91.014741, 46.066415],
              [91.028292, 46.023224],
              [90.850285, 45.888035],
              [90.799778, 45.835117],
              [90.714779, 45.728714],
              [90.676591, 45.582339],
              [90.671047, 45.48762],
              [90.772677, 45.432223],
              [90.773909, 45.405765],
              [90.813329, 45.328418],
              [90.804706, 45.294756],
              [90.831807, 45.300228],
              [90.877387, 45.280865],
              [90.897713, 45.249702],
              [90.866916, 45.209249],
              [90.881698, 45.191964],
              [90.96177, 45.20124],
              [91.007966, 45.218522],
              [91.050466, 45.208828],
              [91.129922, 45.215993],
              [91.17119, 45.199553],
              [91.195827, 45.159065],
              [91.242023, 45.137544],
              [91.33503, 45.129524],
              [91.37753, 45.110947],
              [91.429268, 45.156533],
              [91.448978, 45.156533],
              [91.500101, 45.103768],
              [91.561695, 45.075466],
              [91.694738, 45.065325],
              [91.803144, 45.082649],
              [91.906005, 45.078846],
              [92.044592, 45.084339],
              [92.100026, 45.081381],
              [92.240461, 45.015859],
              [92.315605, 45.02897],
              [92.348866, 45.014167],
              [92.414155, 45.018397],
              [92.501003, 45.001054],
              [92.547814, 45.018397],
              [92.683937, 45.025587],
              [92.779407, 45.050532],
              [92.847777, 45.038695],
              [92.884117, 45.046727],
              [92.932776, 45.017551],
              [93.002377, 45.009938],
              [93.062124, 45.018397],
              [93.100312, 45.007399],
              [93.174225, 45.015436],
              [93.314043, 44.980319],
              [93.314659, 44.99513],
              [93.376869, 44.985398],
              [93.434767, 44.955343],
              [93.509296, 44.968044],
              [93.613389, 44.926544],
              [93.716251, 44.89434],
              [93.723642, 44.86551],
              [94.066105, 44.732197],
              [94.152336, 44.684997],
              [94.215162, 44.667978],
              [94.279836, 44.603688],
              [94.329727, 44.582811],
              [94.359292, 44.51544],
              [94.390705, 44.521839],
              [94.470777, 44.509466],
              [94.557008, 44.462512],
              [94.606283, 44.448418],
              [94.673421, 44.396712],
              [94.722696, 44.340681],
              [94.768275, 44.340681],
              [94.826174, 44.320137],
              [94.945666, 44.292734],
              [94.998637, 44.25332],
              [95.1286, 44.269603],
              [95.398381, 44.294447],
              [95.43041, 44.281598],
              [95.4107, 44.245177],
              [95.376208, 44.227601],
              [95.355882, 44.165829],
              [95.35157, 44.090242],
              [95.326932, 44.028756],
              [95.377439, 44.026175],
              [95.426099, 44.009395],
              [95.527113, 44.007243],
              [95.623199, 43.855567],
              [95.645373, 43.787791],
              [95.705735, 43.671054],
              [95.735916, 43.597437],
              [95.857872, 43.417779],
              [95.880046, 43.280289],
              [95.921314, 43.22974],
              [96.363558, 42.900586],
              [96.386348, 42.727655],
              [96.742361, 42.757096],
              [96.968411, 42.756218],
              [97.172903, 42.795305],
              [97.831958, 42.706115],
              [98.195362, 42.653331],
              [98.546447, 42.638368],
              [98.936953, 42.60799],
              [99.167314, 42.593456],
              [99.507313, 42.567903],
              [99.969267, 42.648051],
              [100.272309, 42.636167],
              [100.32528, 42.689845],
              [100.826655, 42.67533],
              [100.862995, 42.671371],
              [101.23995, 42.59698],
              [101.581796, 42.525145],
              [101.803534, 42.503534],
              [101.870056, 42.439983],
              [102.070236, 42.232107],
              [102.093642, 42.223689],
              [102.449039, 42.143885],
              [102.540814, 42.162072],
              [102.712045, 42.152757],
              [103.021862, 42.02799],
              [103.20726, 41.962622],
              [103.418527, 41.882489],
              [103.868779, 41.802701],
              [104.080046, 41.804931],
              [104.418813, 41.860658],
              [104.530298, 41.874916],
              [104.524138, 41.662051],
              [104.68921, 41.64551],
              [104.923267, 41.654005],
              [105.009498, 41.583331],
              [105.230621, 41.750942],
              [105.291599, 41.750049],
              [105.385221, 41.797349],
              [105.589713, 41.88828],
              [105.74185, 41.949274],
              [106.01348, 42.03199],
              [106.344855, 42.149652],
              [106.612789, 42.241853],
              [106.785867, 42.291444],
              [107.051337, 42.319322],
              [107.271844, 42.363989],
              [107.303872, 42.4126],
              [107.46648, 42.458967],
              [107.501589, 42.456759],
              [107.57427, 42.413042],
              [107.736262, 42.414809],
              [107.939522, 42.403764],
              [108.022058, 42.433359],
              [108.089195, 42.43645],
              [108.238252, 42.460291],
              [108.298614, 42.438216],
              [108.532671, 42.443073],
              [108.690968, 42.415251],
              [108.798757, 42.415251],
              [108.845569, 42.395811],
              [108.983539, 42.448813],
              [109.026039, 42.458525],
              [109.291509, 42.435567],
              [109.486761, 42.458525],
              [109.544044, 42.472208],
              [109.683862, 42.559089],
              [109.906216, 42.635727],
              [110.108244, 42.642769],
              [110.139657, 42.67489],
              [110.34846, 42.742158],
              [110.437156, 42.781254],
              [110.469801, 42.839194],
              [110.631177, 42.936078],
              [110.689691, 43.021437],
              [110.687227, 43.036308],
              [110.736502, 43.089639],
              [110.769763, 43.099251],
              [110.82027, 43.149036],
              [111.02045, 43.329926],
              [111.069725, 43.357774],
              [111.183674, 43.396045],
              [111.354289, 43.436029],
              [111.400485, 43.472948],
              [111.456535, 43.49422],
              [111.564325, 43.490314],
              [111.606209, 43.51375],
              [111.79407, 43.67192],
              [111.891388, 43.674084],
              [111.951135, 43.693122],
              [111.970845, 43.748472],
              [111.959758, 43.8232],
              [111.870447, 43.940071],
              [111.773128, 44.010686],
              [111.702295, 44.033919],
              [111.662875, 44.061012],
              [111.559397, 44.171408],
              [111.541535, 44.206588],
              [111.534144, 44.26189],
              [111.507042, 44.294019],
              [111.428818, 44.319281],
              [111.415883, 44.357368],
              [111.427586, 44.394147],
              [111.478709, 44.488982],
              [111.514434, 44.507333],
              [111.530448, 44.549988],
              [111.569868, 44.576418],
              [111.560629, 44.647124],
              [111.624687, 44.778509],
              [111.69244, 44.859997],
              [111.764505, 44.969314],
              [111.889541, 45.045459],
              [112.002874, 45.090675],
              [112.071243, 45.096166],
              [112.113743, 45.072931],
              [112.396459, 45.06448],
              [112.438959, 45.071663],
              [112.540589, 45.001054],
              [112.599719, 44.93078],
              [112.712436, 44.879503],
              [112.850406, 44.840484],
              [112.937869, 44.84006],
              [113.037652, 44.822663],
              [113.11526, 44.799741],
              [113.503918, 44.77766],
              [113.540874, 44.759394],
              [113.631417, 44.745372],
              [113.712105, 44.788277],
              [113.798953, 44.849393],
              [113.861778, 44.86339],
              [113.907358, 44.915105],
              [114.065038, 44.931204],
              [114.116777, 44.957037],
              [114.19069, 45.036581],
              [114.313262, 45.107147],
              [114.347139, 45.119392],
              [114.409348, 45.179734],
              [114.459855, 45.213465],
              [114.519602, 45.283812],
              [114.539928, 45.325894],
              [114.551014, 45.387699],
              [114.745035, 45.438521],
              [114.920578, 45.386018],
              [114.974165, 45.377193],
              [115.136773, 45.394422],
              [115.36467, 45.392321],
              [115.586408, 45.4402],
              [115.699741, 45.459509],
              [115.864197, 45.572707],
              [115.936878, 45.632987],
              [116.026805, 45.661011],
              [116.035428, 45.68526],
              [116.1155, 45.679826],
              [116.17463, 45.688604],
              [116.217746, 45.722449],
              [116.22329, 45.747506],
              [116.260862, 45.77589],
              [116.286731, 45.775056],
              [116.288579, 45.838869],
              [116.243, 45.875956],
              [116.271949, 45.966692],
              [116.414231, 46.13404],
              [116.439484, 46.137771],
              [116.536187, 46.232632],
              [116.573143, 46.259114],
              [116.585462, 46.292199],
              [116.673541, 46.325264],
              [116.745606, 46.327743],
              [116.81336, 46.355831],
              [116.826294, 46.380602],
              [117.097308, 46.35707],
              [117.247597, 46.366979],
              [117.372017, 46.360373],
              [117.375712, 46.416501],
              [117.392343, 46.463093],
              [117.447777, 46.528172],
              [117.42006, 46.582071],
              [117.49582, 46.600574],
              [117.596218, 46.603452],
              [117.622704, 46.596052],
              [117.641182, 46.558214],
              [117.704008, 46.516645],
              [117.769913, 46.537639],
              [117.813645, 46.530642],
              [117.870927, 46.549985],
              [117.868464, 46.575491],
              [117.914659, 46.607973],
              [117.982412, 46.61496],
              [117.992883, 46.631398],
              [118.04647, 46.631398],
              [118.124078, 46.678216],
              [118.192448, 46.682731],
              [118.238643, 46.709406],
              [118.316252, 46.739347],
              [118.41049, 46.728275],
              [118.446831, 46.704482],
              [118.586033, 46.692992],
              [118.639004, 46.721302],
              [118.677192, 46.697917],
              [118.788061, 46.687246],
              [118.788061, 46.71761],
              [118.845343, 46.771731],
              [118.914329, 46.775009],
              [118.912481, 46.733196],
              [118.951285, 46.722123],
              [119.011647, 46.745498],
              [119.041212, 46.708995],
              [119.123132, 46.642901],
              [119.152081, 46.658097],
              [119.26295, 46.649062],
              [119.325776, 46.608795],
              [119.357805, 46.619481],
              [119.374435, 46.60304],
              [119.431718, 46.638793],
              [119.491464, 46.629343],
              [119.557985, 46.633863],
              [119.598637, 46.618248],
              [119.656535, 46.625645],
              [119.677477, 46.584539],
              [119.739687, 46.615371],
              [119.783419, 46.626056],
              [119.8136, 46.668363],
              [119.911534, 46.669595],
              [119.93494, 46.712688],
              [119.917078, 46.758206],
              [119.936172, 46.790169],
              [119.920157, 46.853219],
              [119.926933, 46.903933],
              [119.859795, 46.917013],
              [119.845013, 46.965217],
              [119.795122, 47.01297],
              [119.806825, 47.054973],
              [119.763093, 47.131147],
              [119.716282, 47.195829],
              [119.650376, 47.23647],
              [119.56784, 47.24825],
              [119.559833, 47.303053],
              [119.450812, 47.352935],
              [119.438493, 47.378061],
              [119.386138, 47.397505],
              [119.353493, 47.43192],
              [119.32208, 47.427063],
              [119.365812, 47.477232],
              [119.205052, 47.520081],
              [119.152081, 47.540685],
              [119.134219, 47.664539],
              [118.773278, 47.771213],
              [118.568171, 47.992315],
              [118.441903, 47.995919],
              [118.424041, 48.014734],
              [118.37415, 48.016335],
              [118.299621, 48.005127],
              [118.231252, 48.043943],
              [118.178281, 48.050743],
              [118.141325, 48.034742],
              [118.047702, 48.021538],
              [118.051398, 48.013533],
              [117.96147, 48.011132],
              [117.886942, 48.025139],
              [117.813645, 48.016335],
              [117.529081, 47.782873],
              [117.493357, 47.758343],
              [117.384335, 47.641162],
              [117.094844, 47.823865],
              [116.879265, 47.893718],
              [116.791186, 47.89773],
              [116.669846, 47.890509],
              [116.453035, 47.837522],
              [116.26579, 47.876866],
              [116.111189, 47.811812],
              [115.968291, 47.689919],
              [115.939342, 47.683071],
              [115.580249, 47.921793],
              [115.529126, 48.155029],
              [115.822929, 48.2595],
              [115.799523, 48.514993],
              [115.83032, 48.560156],
              [116.077928, 48.822412],
              [116.048363, 48.873598],
              [116.428397, 49.430465],
              [116.717889, 49.847388],
              [116.736367, 49.847388],
              [117.068974, 49.695524],
              [117.278394, 49.636272],
              [117.485349, 49.633172],
              [117.638102, 49.575009],
              [117.809333, 49.521049],
              [117.849369, 49.551336],
              [117.867848, 49.592853],
              [117.950999, 49.595956],
              [117.980565, 49.621158],
              [118.057557, 49.611854],
              [118.084658, 49.618057],
              [118.122231, 49.669586],
              [118.156723, 49.660292],
              [118.205998, 49.684686],
              [118.237411, 49.738852],
              [118.300853, 49.75161],
              [118.313172, 49.773641],
              [118.388316, 49.786004],
              [118.395092, 49.819601],
              [118.441903, 49.835812],
              [118.473316, 49.825005],
              [118.485635, 49.86706],
              [118.565707, 49.903299],
              [118.574946, 49.931423],
              [118.646395, 49.9372],
              [118.652554, 49.951061],
              [118.741866, 49.946441],
              [118.814546, 49.969537],
              [118.828713, 49.962994],
              [118.919256, 49.980696],
              [118.929111, 49.989545],
              [119.050451, 49.980696],
              [119.092335, 49.986082],
              [119.12498, 50.019156],
              [119.163168, 50.027613],
              [119.187806, 50.054128],
              [119.190269, 50.087538],
              [119.242008, 50.087538],
              [119.290052, 50.121693],
              [119.309762, 50.16119],
              [119.339327, 50.168089],
              [119.360269, 50.196441],
              [119.319001, 50.220948],
              [119.35103, 50.303949],
              [119.381827, 50.3242],
              [119.358421, 50.358949],
              [119.322696, 50.352459],
              [119.277117, 50.366201],
              [119.259871, 50.345205],
              [119.214291, 50.363911],
              [119.188422, 50.347496],
              [119.158241, 50.367727],
              [119.174255, 50.38032],
              [119.125596, 50.389095],
              [119.165016, 50.422653],
              [119.217371, 50.414647],
              [119.22353, 50.441709],
              [119.250631, 50.448568],
              [119.264182, 50.536877],
              [119.295595, 50.57375],
              [119.28266, 50.604899],
              [119.361501, 50.632611],
              [119.394145, 50.667135],
              [119.383674, 50.682301],
              [119.450196, 50.695569],
              [119.506862, 50.764118],
              [119.495775, 50.77547],
              [119.514253, 50.816315],
              [119.496391, 50.831056],
              [119.491464, 50.879026],
              [119.553674, 50.920912],
              [119.59063, 50.976324],
              [119.633746, 51.010218],
              [119.683021, 51.018876],
              [119.726137, 51.050105],
              [119.752006, 51.115882],
              [119.771716, 51.12452],
              [119.788346, 51.16656],
              [119.760629, 51.21231],
              [119.786499, 51.225426],
              [119.820375, 51.214184],
              [119.797586, 51.243409],
              [119.826535, 51.259887],
              [119.811136, 51.278604],
              [119.886281, 51.30891],
              [119.883817, 51.336954],
              [119.921389, 51.344803],
              [119.944795, 51.366848],
              [119.910918, 51.391123],
              [119.97128, 51.400455],
              [119.981135, 51.443736],
              [120.002693, 51.459396],
              [119.985447, 51.505227],
              [120.051968, 51.553245],
              [120.035338, 51.586343],
              [120.061208, 51.635761],
              [120.098164, 51.647643],
              [120.087077, 51.678076],
              [120.172693, 51.679931],
              [120.226279, 51.717757],
              [120.292801, 51.750365],
              [120.317438, 51.785911],
              [120.363634, 51.789982],
              [120.40675, 51.816621],
              [120.398742, 51.832153],
              [120.480046, 51.855072],
              [120.481278, 51.885735],
              [120.549032, 51.882411],
              [120.548416, 51.907888],
              [120.656821, 51.92634],
              [120.66298, 51.958061],
              [120.704864, 51.983494],
              [120.719031, 52.014438],
              [120.68577, 52.036896],
              [120.717183, 52.072951],
              [120.753523, 52.085454],
              [120.760299, 52.136897],
              [120.786784, 52.157824],
              [120.7449, 52.206984],
              [120.755371, 52.258287],
              [120.715951, 52.261217],
              [120.695625, 52.290139],
              [120.627256, 52.324161],
              [120.62356, 52.361081],
              [120.688234, 52.427531],
              [120.68269, 52.464729],
              [120.706712, 52.492789],
              [120.689466, 52.516098],
              [120.727654, 52.529568],
              [120.723342, 52.549583],
              [120.686386, 52.548128],
              [120.624176, 52.571771],
              [120.605082, 52.589222],
              [120.557039, 52.597582],
              [120.467728, 52.644076],
              [120.40367, 52.617929],
              [120.287873, 52.623378],
              [120.196714, 52.579043],
              [120.082765, 52.585224],
              [120.049505, 52.598672],
              [120.035338, 52.646255],
              [120.071063, 52.706113],
              [120.03657, 52.760096],
              [120.038418, 52.780006],
              [120.101244, 52.788691],
              [120.1382, 52.811481],
              [120.180084, 52.807141],
              [120.222584, 52.842934],
              [120.297112, 52.87003],
              [120.306351, 52.89783],
              [120.350699, 52.906131],
              [120.363018, 52.941481],
              [120.411061, 52.958064],
              [120.455409, 53.011376],
              [120.529321, 53.045921],
              [120.549647, 53.076125],
              [120.643886, 53.106667],
              [120.659901, 53.137187],
              [120.691929, 53.144724],
              [120.690698, 53.172707],
              [120.736277, 53.204615],
              [120.840371, 53.24724],
              [120.820661, 53.269073],
              [120.867472, 53.278734],
              [120.882871, 53.294472],
              [120.933994, 53.287319],
              [120.952472, 53.298048],
              [121.04671, 53.28875],
              [121.096602, 53.307344],
              [121.129246, 53.277303],
              [121.155732, 53.285531],
              [121.234572, 53.28088],
              [121.285695, 53.291253],
              [121.347289, 53.327003],
              [121.416274, 53.319498],
              [121.499426, 53.337008],
              [121.596128, 53.352368],
              [121.697758, 53.392705],
              [121.754425, 53.389494],
              [121.816019, 53.413394],
              [121.875765, 53.426587],
              [122.111054, 53.426944],
              [122.161561, 53.468635],
              [122.227466, 53.461868],
              [122.266886, 53.47006],
              [122.350038, 53.50566],
              [122.37406, 53.47469],
              [122.435038, 53.444766],
              [122.496016, 53.458662],
              [122.5379, 53.453318],
              [122.608117, 53.46543],
              [122.673406, 53.459018],
              [122.763949, 53.463649],
              [122.826775, 53.457237],
              [122.894528, 53.462936],
              [122.943804, 53.483947],
              [123.052209, 53.506727],
              [123.137209, 53.498186],
              [123.179092, 53.50993],
              [123.231447, 53.549407],
              [123.274563, 53.563269],
              [123.309672, 53.560781],
              [123.394055, 53.53803],
              [123.454417, 53.536608],
              [123.47228, 53.509218],
              [123.510468, 53.509218],
              [123.490758, 53.542652],
              [123.517243, 53.558293],
              [123.557895, 53.531985],
              [123.53141, 53.507083],
              [123.569598, 53.505304],
              [123.58746, 53.546919],
              [123.620721, 53.550118],
              [123.668764, 53.533763],
              [123.698329, 53.498542],
              [123.746373, 53.500322],
              [123.797495, 53.49],
              [123.865249, 53.489644],
              [123.985973, 53.43443],
              [124.01369, 53.403408],
              [124.058038, 53.404121],
              [124.125791, 53.348082],
              [124.19416, 53.373433],
              [124.239124, 53.379501],
              [124.327819, 53.332006],
              [124.375863, 53.259053],
              [124.412203, 53.248672],
              [124.435609, 53.223962],
              [124.496587, 53.20784],
              [124.563108, 53.201389],
              [124.614847, 53.209632],
              [124.683832, 53.206406],
              [124.720789, 53.19207],
              [124.712165, 53.162306],
              [124.734339, 53.146519],
              [124.832889, 53.145083],
              [124.87231, 53.099123],
              [124.909266, 53.118159],
              [124.887708, 53.164458],
              [124.970244, 53.194221],
              [125.084193, 53.204973],
              [125.195062, 53.198522],
              [125.252344, 53.180597],
              [125.315786, 53.145083],
              [125.343503, 53.144724],
              [125.503647, 53.095171],
              [125.504263, 53.061385],
              [125.530749, 53.050956],
              [125.588647, 53.080797],
              [125.613901, 53.083313],
              [125.643466, 53.039445],
              [125.684118, 53.008136],
              [125.742632, 52.993733],
              [125.737704, 52.945087],
              [125.665023, 52.913348],
              [125.678574, 52.860999],
              [125.772197, 52.89783],
              [125.836255, 52.898913],
              [125.855349, 52.866418],
              [125.923718, 52.815821],
              [125.937269, 52.78652],
              [125.985312, 52.758648],
              [126.02042, 52.795566],
              [126.058609, 52.798098],
              [126.116507, 52.768061],
              [126.115275, 52.757924],
              [126.045058, 52.738366],
              [126.072775, 52.691247],
              [126.061688, 52.673473],
              [125.995783, 52.675287],
              [125.968682, 52.630279],
              [126.030891, 52.576135],
              [126.066616, 52.60376],
              [126.147304, 52.572862],
              [126.213209, 52.5252],
              [126.192883, 52.492061],
              [126.205202, 52.466187],
              [126.266796, 52.475664],
              [126.326542, 52.424248],
              [126.353644, 52.389207],
              [126.348716, 52.357792],
              [126.322231, 52.343904],
              [126.327774, 52.310628],
              [126.4331, 52.298555],
              [126.357955, 52.264147],
              [126.300673, 52.220915],
              [126.34502, 52.192315],
              [126.403535, 52.184979],
              [126.499005, 52.160394],
              [126.556288, 52.136162],
              [126.563679, 52.119266],
              [126.514404, 52.037264],
              [126.450962, 52.027693],
              [126.468208, 51.982389],
              [126.462665, 51.948473],
              [126.510092, 51.922281],
              [126.555056, 51.874285],
              [126.580925, 51.824758],
              [126.622809, 51.777397],
              [126.658534, 51.762587],
              [126.6727, 51.73184],
              [126.71212, 51.730358],
              [126.734294, 51.711454],
              [126.723823, 51.678818],
              [126.741069, 51.642073],
              [126.67886, 51.602326],
              [126.69549, 51.578536],
              [126.837156, 51.536128],
              [126.843931, 51.521983],
              [126.812518, 51.494053],
              [126.784185, 51.44821],
              [126.835308, 51.413892],
              [126.908605, 51.407174],
              [126.930163, 51.359376],
              [126.904293, 51.340318],
              [126.837156, 51.345177],
              [126.813134, 51.311902],
              [126.841468, 51.258763],
              [126.884583, 51.244532],
              [126.92154, 51.259512],
              [126.877808, 51.300681],
              [126.887047, 51.321999],
              [126.978822, 51.329477],
              [126.976358, 51.291702],
              [126.926467, 51.246405],
              [126.899982, 51.200689],
              [126.917844, 51.139163],
              [126.922772, 51.061764],
              [126.985597, 51.029037],
              [127.052119, 50.963137],
              [127.113713, 50.937505],
              [127.143894, 50.91035],
              [127.236285, 50.781524],
              [127.295415, 50.755035],
              [127.305886, 50.733832],
              [127.28864, 50.699358],
              [127.294799, 50.663721],
              [127.370559, 50.581349],
              [127.36132, 50.547524],
              [127.323132, 50.525847],
              [127.293567, 50.46571],
              [127.3644, 50.43828],
              [127.369944, 50.40397],
              [127.332371, 50.340623],
              [127.371791, 50.296688],
              [127.44632, 50.27069],
              [127.603385, 50.23932],
              [127.60708, 50.178819],
              [127.58737, 50.137802],
              [127.501755, 50.056817],
              [127.495595, 49.994545],
              [127.543638, 49.944131],
              [127.529472, 49.864361],
              [127.531936, 49.825777],
              [127.563964, 49.793343],
              [127.660051, 49.77905],
              [127.674833, 49.764366],
              [127.677913, 49.697846],
              [127.705015, 49.664939],
              [127.775848, 49.635885],
              [127.815268, 49.594017],
              [127.897804, 49.578889],
              [127.949542, 49.596344],
              [128.001281, 49.592465],
              [128.070882, 49.55677],
              [128.185447, 49.539301],
              [128.243345, 49.56298],
              [128.287077, 49.566473],
              [128.343128, 49.545125],
              [128.389939, 49.590138],
              [128.500192, 49.594017],
              [128.537764, 49.604487],
              [128.619684, 49.593629],
              [128.715155, 49.56492],
              [128.744104, 49.594792],
              [128.802618, 49.581992],
              [128.813089, 49.558323],
              [128.754575, 49.506676],
              [128.76135, 49.482192],
              [128.792147, 49.473251],
              [128.871604, 49.492298],
              [128.948596, 49.461974],
              [129.013886, 49.457307],
              [129.055769, 49.382188],
              [129.084719, 49.359981],
              [129.143849, 49.357253],
              [129.215298, 49.398935],
              [129.266421, 49.396209],
              [129.320623, 49.358422],
              [129.379138, 49.366995],
              [129.374826, 49.414118],
              [129.390224, 49.432799],
              [129.448739, 49.441359],
              [129.51834, 49.423849],
              [129.546057, 49.395041],
              [129.562687, 49.299541],
              [129.604571, 49.278858],
              [129.674788, 49.296029],
              [129.714209, 49.296029],
              [129.761636, 49.257384],
              [129.753629, 49.208547],
              [129.792433, 49.181568],
              [129.847867, 49.181177],
              [129.864498, 49.158488],
              [129.866962, 49.114252],
              [129.913157, 49.108377],
              [129.934715, 49.078992],
              [129.9187, 49.060569],
              [129.937179, 49.04057],
              [130.020946, 49.020955],
              [130.059135, 48.978954],
              [130.113337, 48.956564],
              [130.211272, 48.901137],
              [130.245148, 48.866514],
              [130.279641, 48.867301],
              [130.412068, 48.90507],
              [130.471198, 48.905464],
              [130.501995, 48.86612],
              [130.559277, 48.861003],
              [130.609168, 48.881074],
              [130.680617, 48.881074],
              [130.689856, 48.849586],
              [130.622103, 48.783792],
              [130.576524, 48.68869],
              [130.538951, 48.635734],
              [130.538335, 48.612004],
              [130.605473, 48.5942],
              [130.615944, 48.575597],
              [130.620871, 48.495964],
              [130.647357, 48.484861],
              [130.711414, 48.511425],
              [130.767465, 48.507858],
              [130.776704, 48.480102],
              [130.740363, 48.425339],
              [130.766849, 48.375286],
              [130.81982, 48.341493],
              [130.845073, 48.296533],
              [130.769313, 48.23121],
              [130.765617, 48.189344],
              [130.673842, 48.128278],
              [130.668915, 48.099116],
              [130.699711, 48.044344],
              [130.737284, 48.033942],
              [130.770544, 47.998321],
              [130.891269, 47.927006],
              [130.961486, 47.827882],
              [130.966413, 47.732996],
              [131.029855, 47.694752],
              [131.115471, 47.689919],
              [131.183224, 47.702805],
              [131.236811, 47.732996],
              [131.273767, 47.739032],
              [131.359998, 47.730984],
              [131.456085, 47.747079],
              [131.543548, 47.735813],
              [131.59036, 47.660912],
              [131.641483, 47.663733],
              [131.695685, 47.709248],
              [131.741881, 47.706429],
              [131.825649, 47.677432],
              [131.900793, 47.685488],
              [131.970394, 47.671388],
              [132.000575, 47.712066],
              [132.086191, 47.703208],
              [132.272205, 47.718507],
              [132.325175, 47.762365],
              [132.371987, 47.76518],
              [132.469305, 47.726154],
              [132.524124, 47.716091],
              [132.570319, 47.720922],
              [132.6005, 47.740642],
              [132.599268, 47.792521],
              [132.621442, 47.828686],
              [132.687348, 47.885293],
              [132.661478, 47.944643],
              [132.723072, 47.963076],
              [132.769268, 47.938631],
              [132.819159, 47.937028],
              [132.883216, 48.002325],
              [132.992238, 48.035142],
              [133.016259, 48.054343],
              [133.041513, 48.102313],
              [133.103107, 48.129476],
              [133.15423, 48.137063],
              [133.239845, 48.126681],
              [133.302055, 48.103112],
              [133.407997, 48.124684],
              [133.435714, 48.114698],
              [133.536728, 48.117494],
              [133.560134, 48.137063],
              [133.573068, 48.182164],
              [133.59709, 48.194928],
              [133.693177, 48.186951],
              [133.740604, 48.25472],
              [133.791111, 48.260695],
              [133.824372, 48.277423],
              [133.876111, 48.282599],
              [133.940784, 48.302105],
              [133.995603, 48.303697],
              [134.0689, 48.338311],
              [134.131109, 48.335527],
              [134.20379, 48.38244],
              [134.297413, 48.384427],
              [134.350384, 48.378466],
              [134.501905, 48.418986],
              [134.578281, 48.405483],
              [134.696542, 48.407072],
              [134.764295, 48.370119],
              [134.820961, 48.376081],
              [134.886867, 48.437646],
              [134.927519, 48.451537],
              [135.035924, 48.440822],
              [135.068569, 48.459474],
              [135.09567, 48.437646],
              [135.082736, 48.396346],
              [135.009439, 48.365747],
              [134.864077, 48.332345],
              [134.77107, 48.288969],
              [134.679295, 48.256314],
              [134.67252, 48.170593],
              [134.632484, 48.099516],
              [134.551796, 48.032742],
              [134.55426, 47.981903],
              [134.599839, 47.94785],
              [134.607846, 47.909362],
              [134.660201, 47.900538],
              [134.678679, 47.819446],
              [134.772918, 47.763572],
              [134.779694, 47.716091],
              [134.684223, 47.631889],
              [134.685455, 47.603253],
              [134.627556, 47.546339],
              [134.576434, 47.519273],
              [134.568426, 47.478445],
              [134.522847, 47.46793],
              [134.493898, 47.446894],
              [134.339297, 47.43961],
              [134.307268, 47.428682],
              [134.266616, 47.39224],
              [134.263536, 47.371173],
              [134.177305, 47.32658],
              [134.156979, 47.248656],
              [134.210566, 47.210463],
              [134.230276, 47.182411],
              [134.222268, 47.105496],
              [134.142812, 47.093277],
              [134.118175, 47.061902],
              [134.103392, 47.007258],
              [134.063972, 46.979915],
              [134.076291, 46.938669],
              [134.042414, 46.886761],
              [134.025168, 46.810648],
              [134.052885, 46.779926],
              [134.033175, 46.759026],
              [134.030711, 46.708995],
              [134.011001, 46.637971],
              [133.919842, 46.596052],
              [133.890893, 46.525291],
              [133.849625, 46.475044],
              [133.852089, 46.449903],
              [133.902596, 46.446192],
              [133.950023, 46.394634],
              [133.91861, 46.371108],
              [133.876726, 46.362438],
              [133.869335, 46.338071],
              [133.922922, 46.330635],
              [133.904444, 46.25084],
              [133.83977, 46.202825],
              [133.814517, 46.230977],
              [133.789263, 46.187915],
              [133.706111, 46.163056],
              [133.690713, 46.133625],
              [133.745531, 46.075547],
              [133.740604, 46.048561],
              [133.681474, 45.986651],
              [133.676546, 45.942982],
              [133.616184, 45.943398],
              [133.618032, 45.903442],
              [133.576148, 45.870957],
              [133.551511, 45.895114],
              [133.51209, 45.886785],
              [133.494228, 45.842204],
              [133.470822, 45.838035],
              [133.469591, 45.799255],
              [133.505315, 45.785905],
              [133.469591, 45.777977],
              [133.484373, 45.738737],
              [133.454192, 45.732055],
              [133.445569, 45.70532],
              [133.491764, 45.672301],
              [133.448649, 45.647628],
              [133.471438, 45.631313],
              [133.412924, 45.617923],
              [133.420315, 45.574801],
              [133.371656, 45.576895],
              [133.232454, 45.51404],
              [133.21028, 45.516975],
              [133.178867, 45.465384],
              [133.141295, 45.427605],
              [133.144991, 45.367525],
              [133.119121, 45.352811],
              [133.119737, 45.309907],
              [133.094484, 45.260232],
              [133.124665, 45.222737],
              [133.138215, 45.178469],
              [133.103107, 45.107147],
              [133.070462, 45.097011],
              [133.045824, 45.06617],
              [132.954049, 45.023049],
              [132.867202, 45.061944],
              [132.76434, 45.081381],
              [132.394161, 45.163706],
              [132.025829, 45.250545],
              [131.93159, 45.288442],
              [131.917423, 45.339354],
              [131.887858, 45.342298],
              [131.82996, 45.31159],
              [131.788692, 45.245911],
              [131.79362, 45.211778],
              [131.721555, 45.234536],
              [131.681519, 45.215151],
              [131.650722, 45.159065],
              [131.695685, 45.132056],
              [131.632244, 45.074621],
              [131.56757, 45.045882],
              [131.529382, 45.012053],
              [131.484418, 44.995553],
              [131.497353, 44.970161],
              [131.464708, 44.963388],
              [131.409889, 44.985821],
              [131.355071, 44.990053],
              [131.313803, 44.965928],
              [131.274999, 44.919766],
              [131.263296, 44.929933],
              [131.207861, 44.913834],
              [131.20355, 44.932898],
              [131.16105, 44.948145],
              [131.090217, 44.924426],
              [131.10192, 44.897731],
              [131.07913, 44.881623],
              [130.967029, 44.854059],
              [130.973189, 44.819692],
              [131.016304, 44.814174],
              [131.016304, 44.789551],
              [131.064348, 44.787003],
              [131.111775, 44.71009],
              [131.310723, 44.046392],
              [131.263912, 44.030047],
              [131.245434, 43.955579],
              [131.267608, 43.938778],
              [131.254057, 43.893522],
              [131.211557, 43.826221],
              [131.217716, 43.764462],
              [131.231883, 43.741124],
              [131.216485, 43.713019],
              [131.233731, 43.636422],
              [131.216485, 43.613034],
              [131.244818, 43.604369],
              [131.209093, 43.584869],
              [131.20047, 43.531971],
              [131.276847, 43.495523],
              [131.304564, 43.502033],
              [131.32181, 43.495089],
              [131.295941, 43.441677],
              [131.314419, 43.392567],
              [131.275615, 43.369084],
              [131.269455, 43.29771],
              [131.255289, 43.265041],
              [131.206014, 43.23715],
              [131.218332, 43.146853],
              [131.173985, 43.111483],
              [131.171521, 43.069536],
              [131.120398, 43.068225],
              [131.102536, 43.021],
              [131.115471, 42.975489],
              [131.151195, 42.968485],
              [131.114855, 42.915048],
              [131.034167, 42.929069],
              [131.017536, 42.912857],
              [131.045869, 42.866828],
              [130.981812, 42.857179],
              [130.949167, 42.876913],
              [130.890653, 42.852793],
              [130.844457, 42.881298],
              [130.801957, 42.879544],
              [130.784095, 42.842265],
              [130.666451, 42.847968],
              [130.588226, 42.813303],
              [130.564205, 42.815937],
              [130.53156, 42.786963],
              [130.46627, 42.772471],
              [130.40714, 42.731611],
              [130.464423, 42.688525],
              [130.542647, 42.699519],
              [130.592538, 42.671371],
              [130.633806, 42.603586],
              [130.622719, 42.573191],
              [130.570364, 42.557327],
              [130.565437, 42.506622],
              [130.599313, 42.486329],
              [130.600545, 42.450579],
              [130.645509, 42.426293],
              [130.581451, 42.435567],
              [130.585763, 42.485446],
              [130.558661, 42.496035],
              [130.520473, 42.593456],
              [130.522937, 42.62252],
              [130.482285, 42.626483],
              [130.459495, 42.58817],
              [130.476125, 42.569666],
              [130.435474, 42.55336],
              [130.423771, 42.574513],
              [130.44656, 42.607109],
              [130.388046, 42.603145],
              [130.373264, 42.630885],
              [130.333228, 42.649811],
              [130.290112, 42.703037],
              [130.257467, 42.710951],
              [130.242069, 42.738643],
              [130.265474, 42.904092],
              [130.17062, 42.912419],
              [130.136127, 42.903654],
              [130.10225, 42.922935],
              [130.144134, 42.976365],
              [130.10841, 42.989934],
              [130.027106, 42.967609],
              [129.961816, 42.979429],
              [129.954425, 43.010938],
              [129.897143, 43.001749],
              [129.856491, 42.951845],
              [129.835549, 42.866828],
              [129.816454, 42.851039],
              [129.810911, 42.795305],
              [129.78381, 42.762807],
              [129.767179, 42.707874],
              [129.796744, 42.681928],
              [129.757324, 42.642329],
              [129.786889, 42.615476],
              [129.741926, 42.580681],
              [129.749933, 42.546748],
              [129.738846, 42.500447],
              [129.748701, 42.470884],
              [129.704354, 42.427176],
              [129.643991, 42.431151],
              [129.624281, 42.458967],
              [129.60026, 42.411275],
              [129.546057, 42.361336],
              [129.49863, 42.412158],
              [129.452434, 42.440866],
              [129.400695, 42.448813],
              [129.368051, 42.42541],
              [129.371746, 42.453228],
              [129.344029, 42.451462],
              [129.30892, 42.403764],
              [129.326167, 42.390067],
              [129.240551, 42.376366],
              [129.231312, 42.356031],
              [129.260261, 42.335689],
              [129.208522, 42.293214],
              [129.225153, 42.269753],
              [129.183269, 42.262225],
              [129.215914, 42.20818],
              [129.138305, 42.169167],
              [129.120443, 42.142111],
              [129.040987, 42.130575],
              [129.039139, 42.107496],
              [128.954755, 42.083966],
              [128.955371, 42.030657],
              [128.930734, 42.014211],
              [128.737945, 42.050209],
              [128.70222, 42.020434],
              [128.60675, 42.030212],
              [128.569177, 41.996426],
              [128.496497, 42.001762],
              [128.466316, 42.020878],
              [128.290773, 42.026657],
              [128.090593, 42.022656],
              [128.033926, 42.000428],
              [128.106607, 41.950164],
              [128.115846, 41.896743],
              [128.103527, 41.842831],
              [128.112766, 41.79378],
              [128.147875, 41.77995],
              [128.146643, 41.752728],
              [128.171897, 41.713882],
              [128.269831, 41.668309],
              [128.317258, 41.593177],
              [128.301244, 41.539905],
              [128.242114, 41.501827],
              [128.243345, 41.477176],
              [128.203309, 41.411246],
              [128.168817, 41.403617],
              [128.113998, 41.364561],
              [128.110919, 41.393294],
              [128.040085, 41.393294],
              [128.019759, 41.414387],
              [127.932296, 41.446686],
              [127.909506, 41.429641],
              [127.871934, 41.439959],
              [127.87563, 41.407656],
              [127.744435, 41.427398],
              [127.687768, 41.424258],
              [127.653892, 41.406758],
              [127.618783, 41.432782],
              [127.563964, 41.43323],
              [127.549182, 41.474487],
              [127.498675, 41.478521],
              [127.46603, 41.464175],
              [127.40998, 41.463278],
              [127.405668, 41.478521],
              [127.360704, 41.465969],
              [127.294183, 41.48659],
              [127.261538, 41.480763],
              [127.283096, 41.513925],
              [127.246756, 41.501379],
              [127.241212, 41.521093],
              [127.164836, 41.542592],
              [127.115561, 41.540353],
              [127.127879, 41.568561],
              [127.179618, 41.599888],
              [127.13835, 41.600336],
              [127.095235, 41.624045],
              [127.103242, 41.647746],
              [127.039184, 41.671884],
              [127.055814, 41.705842],
              [127.051503, 41.744693],
              [127.008387, 41.747371],
              [126.978822, 41.777273],
              [126.943714, 41.772365],
              [126.952953, 41.804485],
              [126.931395, 41.812959],
              [126.861178, 41.767902],
              [126.841468, 41.727726],
              [126.808207, 41.748264],
              [126.798968, 41.697354],
              [126.726903, 41.751389],
              [126.694874, 41.750942],
              [126.690562, 41.728173],
              [126.725055, 41.711202],
              [126.65607, 41.66071],
              [126.623425, 41.677694],
              [126.605563, 41.639697],
              [126.569838, 41.621809],
              [126.582773, 41.563189],
              [126.54828, 41.530948],
              [126.533498, 41.480314],
              [126.497158, 41.406758],
              [126.539041, 41.366806],
              [126.519331, 41.348393],
              [126.486687, 41.373541],
              [126.435564, 41.351088],
              [126.366578, 41.282332],
              [126.35426, 41.244552],
              [126.322847, 41.231054],
              [126.295129, 41.17163],
              [126.233535, 41.143701],
              [126.157775, 41.091413],
              [126.124514, 41.092315],
              [126.133753, 41.0639],
              [126.099877, 41.036375],
              [126.1085, 41.012],
              [126.066616, 40.998454],
              [126.08263, 40.976775],
              [126.051833, 40.961866],
              [126.031507, 40.927067],
              [126.008102, 40.936559],
              [125.959442, 40.881845],
              [125.921254, 40.88275],
              [125.916943, 40.912147],
              [125.875059, 40.90853],
              [125.817161, 40.866915],
              [125.785132, 40.895867],
              [125.707523, 40.866915],
              [125.648393, 40.82618],
              [125.641002, 40.798103],
              [125.685349, 40.769109],
              [125.61698, 40.763671],
              [125.585567, 40.788591],
              [125.551075, 40.761858],
              [125.544915, 40.72922],
              [125.49564, 40.728767],
              [125.459916, 40.706999],
              [125.453756, 40.676603],
              [125.418648, 40.673427],
              [125.422343, 40.635297],
              [125.375532, 40.65845],
              [125.329337, 40.643924],
              [125.279445, 40.655273],
              [125.262815, 40.620312],
              [125.190134, 40.615316],
              [125.113758, 40.569427],
              [125.018287, 40.53624],
              [125.004737, 40.496213],
              [125.044157, 40.466631],
              [124.985642, 40.475279],
              [124.922201, 40.458892],
              [124.913578, 40.481651],
              [124.834121, 40.422918],
              [124.739267, 40.371883],
              [124.718325, 40.319441],
              [124.62039, 40.290695],
              [124.523072, 40.228141],
              [124.490427, 40.183816],
              [124.457166, 40.177416],
              [124.426985, 40.144036],
              [124.350609, 40.084093],
              [124.336442, 40.049751],
              [124.372167, 40.021348],
              [124.350609, 39.989266],
              [124.289015, 39.963131],
              [124.287167, 39.93194],
              [124.239124, 39.927352],
              [124.214486, 39.864922],
              [124.173218, 39.841496],
              [124.163979, 39.778529],
              [124.144885, 39.745413],
              [124.099306, 39.777609],
              [124.103001, 39.823577],
              [124.002603, 39.800137],
              [123.944089, 39.819441],
              [123.828908, 39.831389],
              [123.687858, 39.808411],
              [123.645358, 39.824037],
              [123.642279, 39.79646],
              [123.612714, 39.77485],
              [123.546808, 39.756453],
              [123.536337, 39.788644],
              [123.476591, 39.745873],
              [123.392823, 39.723787],
              [123.383584, 39.766572],
              [123.274563, 39.753693],
              [123.253005, 39.689724],
              [123.166774, 39.674068],
              [123.148911, 39.649656],
              [123.110107, 39.673608],
              [123.010941, 39.655184],
              [123.021412, 39.643206],
              [122.941956, 39.604494],
              [122.852029, 39.601728],
              [122.808913, 39.559764],
              [122.686341, 39.515004],
              [122.661703, 39.522851],
              [122.640761, 39.492845],
              [122.581631, 39.464211],
              [122.532972, 39.420315],
              [122.489856, 39.403673],
              [122.412864, 39.411995],
              [122.366668, 39.390727],
              [122.363589, 39.365753],
              [122.274893, 39.322257],
              [122.242865, 39.267618],
              [122.117213, 39.213863],
              [122.123988, 39.172593],
              [122.167104, 39.158676],
              [122.088264, 39.112266],
              [122.048228, 39.101123],
              [122.061778, 39.060251],
              [122.013735, 39.073258],
              [121.963228, 39.030046],
              [121.913953, 39.059786],
              [121.929352, 39.024933],
              [121.864062, 39.037018],
              [121.855439, 39.025863],
              [121.905946, 38.997503],
              [121.920728, 38.969598],
              [121.863446, 38.942611],
              [121.804932, 38.970993],
              [121.790149, 39.022609],
              [121.756889, 39.025863],
              [121.73841, 38.998898],
              [121.671273, 39.010057],
              [121.655874, 38.9468],
              [121.719316, 38.92027],
              [121.708845, 38.872772],
              [121.617686, 38.862524],
              [121.565331, 38.875101],
              [121.509897, 38.817784],
              [121.428593, 38.811257],
              [121.359608, 38.822446],
              [121.305405, 38.789341],
              [121.259825, 38.786543],
              [121.198848, 38.721686],
              [121.13479, 38.72402],
              [121.112, 38.776282],
              [121.12863, 38.799134],
              [121.110768, 38.862058],
              [121.124935, 38.88954],
              [121.094138, 38.894197],
              [121.08921, 38.922132],
              [121.128014, 38.958897],
              [121.180369, 38.959828],
              [121.204391, 38.941215],
              [121.275224, 38.971923],
              [121.341129, 38.980761],
              [121.318956, 39.017031],
              [121.370695, 39.060251],
              [121.431057, 39.027257],
              [121.508049, 39.034229],
              [121.581962, 39.075581],
              [121.599208, 39.098802],
              [121.562252, 39.12712],
              [121.590585, 39.154964],
              [121.642324, 39.119694],
              [121.605983, 39.08069],
              [121.631853, 39.077903],
              [121.68236, 39.117837],
              [121.639244, 39.166098],
              [121.604136, 39.166098],
              [121.591201, 39.228696],
              [121.631237, 39.226379],
              [121.589353, 39.262985],
              [121.626309, 39.286144],
              [121.668193, 39.276419],
              [121.667577, 39.310684],
              [121.70207, 39.326423],
              [121.723628, 39.367603],
              [121.621382, 39.32596],
              [121.559172, 39.320868],
              [121.51544, 39.286607],
              [121.464933, 39.301425],
              [121.432904, 39.357426],
              [121.307869, 39.39119],
              [121.261673, 39.374078],
              [121.246891, 39.421702],
              [121.304173, 39.481762],
              [121.286927, 39.507157],
              [121.268449, 39.482686],
              [121.224717, 39.51962],
              [121.226565, 39.555151],
              [121.297398, 39.605877],
              [121.384245, 39.608643],
              [121.450151, 39.625235],
              [121.451999, 39.658409],
              [121.482796, 39.65933],
              [121.501274, 39.706758],
              [121.45939, 39.747713],
              [121.487107, 39.760133],
              [121.472325, 39.802436],
              [121.530223, 39.851603],
              [121.626925, 39.882831],
              [121.699606, 39.937445],
              [121.76428, 39.933316],
              [121.82341, 40.036467],
              [121.884388, 40.053415],
              [121.960764, 40.134887],
              [121.980474, 40.123908],
              [122.01004, 40.149067],
              [121.950293, 40.203926],
              [121.940438, 40.2423],
              [122.02667, 40.245041],
              [122.039605, 40.26011],
              [122.040221, 40.322178],
              [122.079641, 40.333126],
              [122.110438, 40.315792],
              [122.138155, 40.339055],
              [122.111054, 40.349087],
              [122.135691, 40.375074],
              [122.152322, 40.357751],
              [122.198517, 40.382367],
              [122.186814, 40.422918],
              [122.229314, 40.423829],
              [122.245944, 40.519868],
              [122.150474, 40.588513],
              [122.133843, 40.614408],
              [122.148626, 40.671612],
              [122.06609, 40.648464],
              [122.025438, 40.674334],
              [121.951525, 40.680687],
              [121.936127, 40.711535],
              [121.934279, 40.798103],
              [121.883772, 40.80218],
              [121.847432, 40.824369],
              [121.844968, 40.853792],
              [121.816019, 40.894962],
              [121.778446, 40.886821],
              [121.732251, 40.847003],
              [121.682976, 40.829802],
              [121.626309, 40.844287],
              [121.576418, 40.83795],
              [121.526527, 40.851529],
              [121.499426, 40.880035],
              [121.355296, 40.892249],
              [121.251202, 40.880488],
              [121.23642, 40.851077],
              [121.177906, 40.873702],
              [121.126167, 40.869177],
              [121.076892, 40.815313],
              [121.086747, 40.798103],
              [121.010986, 40.784514],
              [121.00729, 40.807614],
              [120.980189, 40.76639],
              [120.991276, 40.744181],
            ],
          ],
          [
            [
              [120.16845703125, 38.013476231041935],
              [119.81689453125, 37.80544394934271],
              [119.4873046875, 37.49229399862877],
              [119.37744140625, 37.77071473849609],
              [119.28955078124999, 38.11727165830543],
              [118.87207031250001, 38.39333888832238],
              [118.27880859375001, 38.44498466889473],
              [118.0810546875, 38.61687046392973],
              [118.0810546875, 38.8225909761771],
              [118.5205078125, 38.805470223177466],
              [119.091796875, 38.90813299596705],
              [119.59716796875, 39.317300373271024],
              [119.86083984375, 39.487084981687495],
              [120.41015624999999, 39.9434364619742],
              [120.87158203125, 40.111688665595956],
              [121.2451171875, 40.48038142908172],
              [121.59667968749999, 40.59727063442024],
              [121.83837890625, 40.49709237269567],
              [121.59667968749999, 40.22921818870117],
              [121.26708984374999, 39.8928799002948],
              [121.025390625, 39.639537564366684],
              [120.91552734375, 39.21523130910491],
              [120.91552734375, 38.85682013474361],
              [121.13525390625, 38.496593518947584],
              [121.6845703125, 38.58252615935333],
              [122.16796875, 38.8225909761771],
              [122.84912109375, 39.027718840211605],
              [123.64013671874999, 39.317300373271024],
              [124.0576171875, 39.38526381099774],
              [124.541015625, 39.57182223734374],
              [125.24414062499999, 39.926588421909436],
              [125.68359374999999, 40.212440718286466],
              [126.14501953125, 40.49709237269567],
              [126.54052734374999, 40.74725696280421],
              [126.84814453125, 40.896905775860006],
              [127.39746093749999, 40.96330795307353],
              [127.9248046875, 40.97989806962013],
              [128.43017578125, 40.94671366508002],
              [129.08935546875, 40.9964840143779],
              [129.35302734375, 41.21172151054787],
              [129.990234375, 41.590796851056005],
              [130.27587890625, 42.032974332441405],
              [130.5615234375, 42.24478535602799],
              [131.28662109375, 42.56926437219384],
              [131.50634765625, 42.89206418807337],
              [131.8798828125, 43.35713822211053],
              [131.8798828125, 43.75522505306928],
              [131.72607421875, 44.213709909702054],
              [131.77001953125, 44.5278427984555],
              [132.0556640625, 44.68427737181225],
              [132.5830078125, 44.824708282300236],
              [133.06640625, 44.933696389694674],
              [133.41796874999997, 44.88701247981298],
              [133.90136718749997, 45.506346901083425],
              [134.23095703125, 45.9511496866914],
              [134.62646484375, 46.81509864599243],
              [134.86816406249997, 47.27922900257082],
              [135.24169921875, 47.57652571374621],
              [135.52734375, 48.151428143221224],
              [135.439453125, 48.705462895790546],
              [134.71435546875, 48.93693495409401],
              [133.70361328125, 48.748945343432936],
              [133.06640625, 48.42920055556841],
              [132.47314453125, 48.28319289548349],
              [131.77001953125, 48.21003212234042],
              [131.28662109375, 48.29781249243716],
              [131.02294921875, 48.574789910928864],
              [130.9130859375, 48.850258199721495],
              [130.71533203125, 49.095452162534826],
              [129.96826171875, 49.468124067331644],
              [129.17724609374997, 49.724479188712984],
              [128.47412109375, 49.809631563563094],
              [128.12255859375, 49.993615462541136],
              [127.79296875, 50.14874640066278],
              [127.88085937499999, 50.499452103967734],
              [127.72705078124999, 50.999928855859636],
              [127.265625, 51.481382896100975],
              [126.89208984375, 52.01193653675363],
              [126.65039062499999, 52.348763181988105],
              [126.45263671875, 52.61639023304539],
              [126.2548828125, 53.001562274591464],
              [125.96923828125, 53.225768435790194],
              [125.20019531249999, 53.409531853086435],
              [124.16748046874999, 53.605544099238],
              [123.48632812499999, 53.70971358510174],
              [122.54150390625, 53.74871079689897],
              [120.78369140624999, 53.657661020298],
              [120.03662109374999, 53.38332836757156],
              [119.4873046875, 52.96187505907603],
              [119.77294921874999, 52.46939684276205],
              [120.41015624999999, 52.29504228453735],
              [120.21240234375001, 52.03897658307622],
              [119.7509765625, 51.6180165487737],
              [119.33349609375, 50.98609893339354],
              [118.80615234374999, 50.45750402042058],
              [118.54248046874999, 50.30337575356313],
              [117.83935546874999, 50.12057809796008],
              [117.1142578125, 50.035973672195496],
              [116.12548828124999, 49.937079756975294],
              [116.01562499999999, 49.66762782262194],
              [115.6201171875, 49.009050809382046],
              [115.11474609375001, 48.356249029540734],
              [115.1806640625, 47.79839667295524],
              [115.48828125000001, 47.57652571374621],
              [115.90576171874999, 47.3834738721015],
              [116.74072265625, 47.5913464767971],
              [117.1142578125, 47.54687159892238],
              [117.46582031249999, 47.487513008956554],
              [118.01513671875, 47.65058757118734],
              [118.6083984375, 47.54687159892238],
              [118.98193359375, 47.17477833929903],
              [119.24560546875001, 46.9052455464292],
              [118.36669921875, 46.92025531537451],
              [117.2900390625, 46.84516443029276],
              [116.78466796875, 46.558860303117164],
              [115.4443359375, 45.99696161820381],
              [114.98291015625, 45.78284835197676],
              [114.49951171875, 45.521743896993634],
              [113.5986328125, 45.42929873257377],
              [113.48876953125, 45.22848059584359],
              [112.5, 45.259422036351694],
              [111.884765625, 45.321254361171476],
              [111.11572265625, 44.96479793033101],
              [111.005859375, 44.62175409623324],
              [110.8740234375, 44.32384807250689],
              [110.80810546875, 43.929549935614595],
              [110.830078125, 43.61221676817573],
              [110.478515625, 43.24520272203356],
              [109.2041015625, 42.73087427928485],
              [108.39111328125, 42.74701217318067],
              [106.72119140625, 42.633958722673135],
              [105.49072265625, 42.261049162113856],
              [104.61181640625, 42.27730877423709],
              [103.35937499999999, 42.4234565179383],
              [102.41455078125, 42.52069952914966],
              [101.57958984375, 42.924251753870685],
              [100.74462890625, 43.18114705939968],
              [99.97558593749999, 42.89206418807337],
              [98.98681640625, 43.11702412135048],
              [98.15185546874999, 43.27720532212024],
              [97.58056640625, 43.02071359427862],
              [97.0751953125, 43.30919109985686],
              [96.48193359375, 43.83452678223682],
              [95.49316406249999, 44.69989765840318],
              [94.1748046875, 45.01141864227728],
              [92.92236328125, 45.413876460821086],
              [91.38427734374999, 45.521743896993634],
              [91.29638671875, 45.84410779560204],
              [91.23046875, 46.543749602738565],
              [91.25244140624999, 47.05515408550348],
              [91.03271484375, 47.517200697839414],
              [90.85693359375, 47.916342040161155],
              [90.3955078125, 48.268569112964336],
              [89.6484375, 48.76343113791796],
              [88.83544921874999, 49.05227025601607],
              [88.26416015625, 49.296471602658066],
              [87.4072265625, 49.48240137826932],
              [86.572265625, 49.61070993807422],
              [86.572265625, 49.61070993807422],
              [85.69335937499999, 48.86471476180277],
              [84.814453125, 48.31242790407178],
              [84.0234375, 48.019324184801185],
              [82.6171875, 47.517200697839414],
              [81.9140625, 46.98025235521883],
              [80.68359375, 46.164614496897094],
              [79.7607421875, 45.61403741135093],
              [79.40917968749999, 44.99588261816546],
              [79.541015625, 44.62175409623324],
              [79.541015625, 43.929549935614595],
              [79.62890625, 43.32517767999296],
              [79.8486328125, 42.779275360241904],
              [79.62890625, 42.35854391749705],
              [78.75, 42.00032514831621],
              [78.1787109375, 41.60722821271717],
              [77.2998046875, 41.27780646738183],
              [76.728515625, 41.31082388091818],
              [75.673828125, 41.07935114946899],
              [74.7509765625, 40.613952441166596],
              [73.564453125, 39.9434364619742],
              [73.0810546875, 39.198205348894795],
              [73.388671875, 38.44498466889473],
              [73.65234375, 37.82280243352756],
              [74.3115234375, 37.82280243352756],
              [74.6630859375, 37.54457732085582],
              [74.2236328125, 37.23032838760387],
              [74.53125, 36.73888412439431],
              [75.05859375, 36.63316209558658],
              [75.5419921875, 36.24427318493909],
              [75.89355468749999, 35.746512259918504],
              [76.2451171875, 35.31736632923788],
              [76.728515625, 35.35321610123823],
              [77.3876953125, 34.92197103616377],
              [77.9150390625, 34.70549341022544],
              [78.3544921875, 34.19817309627726],
              [78.57421875, 33.17434155100208],
              [78.92578124999999, 33.02708758002874],
              [78.22265625, 32.731840896865684],
              [78.0908203125, 32.0639555946604],
              [78.31054687499999, 31.466153715024294],
              [78.7060546875, 30.713503990354965],
              [79.7607421875, 30.183121842195515],
              [80.5078125, 30.031055426540206],
              [81.5185546875, 29.57345707301757],
              [82.529296875, 29.22889003019423],
              [84.0673828125, 28.65203063036226],
              [84.8583984375, 28.265682390146477],
              [85.5615234375, 27.68352808378776],
              [86.8798828125, 27.059125784374068],
              [87.9345703125, 26.86328062676624],
              [89.033203125, 26.902476886279832],
              [90.263671875, 26.980828590472107],
              [91.4501953125, 26.745610382199022],
              [92.28515625, 26.470573022375085],
              [93.55957031249999, 26.470573022375085],
              [95.00976562499999, 26.82407078047018],
              [96.7236328125, 27.254629577800063],
              [97.6904296875, 27.371767300523047],
              [98.173828125, 26.667095801104814],
              [97.6904296875, 26.03704188651584],
              [97.2509765625, 25.045792240303445],
              [97.3388671875, 23.96617587126503],
              [97.6904296875, 23.40276490540795],
              [98.4814453125, 22.471954507739227],
              [98.96484375, 21.657428197370653],
              [99.404296875, 21.657428197370653],
              [99.90966796875, 21.207458730482642],
              [100.83251953125, 20.96143961409684],
              [101.49169921875, 20.838277806058933],
              [102.216796875, 20.92039691397189],
              [102.37060546875, 21.80030805097259],
              [102.74414062499999, 22.187404991398775],
              [103.84277343749999, 22.207749178410847],
              [104.65576171875, 22.28909641872304],
              [105.62255859375, 22.248428704383624],
              [106.41357421875, 22.024545601240337],
              [106.80908203125, 21.555284406923192],
              [107.60009765625, 21.268899719967695],
              [108.17138671875, 21.14599216495789],
              [108.61083984375, 21.17672864097083],
              [108.96240234375, 21.22794190505815],
              [109.2919921875, 21.207458730482642],
              [109.53369140625, 21.186972714123776],
              [109.5556640625, 20.848545148787238],
              [109.62158203125, 20.58136735381002],
              [109.72869873046874, 20.493918871618803],
              [109.7808837890625, 20.38325080173755],
              [109.80010986328124, 20.321447992849983],
              [109.8248291015625, 20.233851749955672],
              [109.8907470703125, 20.166833052277696],
              [109.97863769531249, 20.166833052277696],
              [110.10498046875, 20.17714549791179],
              [110.19012451171875, 20.182301464987432],
              [110.27526855468749, 20.192612887475377],
              [110.357666015625, 20.218388457307814],
              [110.4400634765625, 20.233851749955672],
              [110.511474609375, 20.251890313952938],
              [110.511474609375, 20.331750177822833],
              [110.54168701171875, 20.367802418359798],
              [110.57464599609375, 20.41156881800431],
              [110.5938720703125, 20.470762381715577],
              [110.62408447265625, 20.576224710301645],
              [110.58837890625, 20.64306554672647],
              [110.6048583984375, 20.817741019786485],
              [110.66253662109375, 20.86651131245835],
              [110.67626953125, 20.930658643632885],
              [110.6378173828125, 20.971698530475734],
              [110.57189941406249, 20.97426314957793],
              [110.5828857421875, 21.017854937856118],
              [110.61035156249999, 21.06399706324597],
              [110.63232421875, 21.099875492701216],
              [110.6597900390625, 21.130621534363144],
              [110.7147216796875, 21.18441176237681],
              [110.7476806640625, 21.27145920527429],
              [110.82733154296875, 21.3124049136927],
              [110.90972900390625, 21.335431860495554],
              [111.0443115234375, 21.391704731036587],
              [111.1761474609375, 21.381474908405597],
              [111.3299560546875, 21.350781150679737],
              [111.55517578125, 21.417276156993662],
              [111.8902587890625, 21.468405577312012],
              [112.0440673828125, 21.53995662308542],
              [112.3516845703125, 21.57571893245848],
              [112.4835205078125, 21.53995662308542],
              [112.6702880859375, 21.514406720030294],
              [113.060302734375, 21.596150576461426],
              [113.895263671875, 21.779905342529645],
              [114.774169921875, 21.963424936844223],
              [115.56518554687499, 22.238259929564308],
              [115.916748046875, 22.421184710331858],
              [116.35620117187499, 22.6038688428957],
              [116.971435546875, 22.978623970384913],
              [117.674560546875, 23.34225583513053],
              [118.27880859375001, 23.936054914599815],
              [118.88305664062499, 24.467150664739002],
              [119.66308593749999, 25.005972656239187],
              [120.673828125, 25.681137335685307],
              [121.37695312499999, 26.667095801104814],
              [122.16796875, 28.14950321154457],
              [122.51953124999999, 29.075375179558346],
              [122.6953125, 30.29701788337205],
              [122.6953125, 30.977609093348686],
              [122.4755859375, 31.840232667909365],
              [122.1240234375, 32.861132322810946],
              [121.53076171875, 33.779147331286474],
              [120.82763671875, 34.470335121217474],
              [120.03662109374999, 34.97600151317588],
              [120.25634765624999, 35.567980458012094],
              [121.33300781249999, 36.10237644873644],
              [121.81640624999999, 36.36822190085111],
              [122.431640625, 36.63316209558658],
              [122.958984375, 36.84446074079564],
              [123.134765625, 37.24782120155428],
              [123.11279296875001, 37.77071473849609],
              [122.03613281249999, 37.97884504049713],
              [121.28906250000001, 38.22091976683121],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        name: '海南省',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [110.106396, 20.026963],
              [110.144585, 20.074176],
              [110.243135, 20.077548],
              [110.291178, 20.056754],
              [110.318279, 20.109015],
              [110.387265, 20.113509],
              [110.526467, 20.0753],
              [110.562191, 20.109577],
              [110.655814, 20.134296],
              [110.689075, 20.164067],
              [110.717408, 20.148901],
              [110.744509, 20.074176],
              [110.778386, 20.068556],
              [110.822118, 20.025276],
              [110.871393, 20.011784],
              [110.940994, 20.028649],
              [110.969327, 20.010097],
              [111.013675, 19.850349],
              [111.043856, 19.763658],
              [111.071573, 19.629025],
              [111.008747, 19.604226],
              [110.920668, 19.552926],
              [110.844292, 19.450278],
              [110.78085, 19.395543],
              [110.729727, 19.378611],
              [110.706321, 19.3199],
              [110.676756, 19.286583],
              [110.619474, 19.152118],
              [110.585597, 18.880594],
              [110.578206, 18.78489],
              [110.499366, 18.751466],
              [110.495054, 18.65002],
              [110.367555, 18.631878],
              [110.329366, 18.64265],
              [110.246215, 18.609764],
              [110.121795, 18.512772],
              [110.090382, 18.399262],
              [110.022629, 18.360083],
              [109.919767, 18.375415],
              [109.785492, 18.339639],
              [109.749767, 18.193617],
              [109.726362, 18.177701],
              [109.661688, 18.175427],
              [109.584696, 18.143589],
              [109.527413, 18.169174],
              [109.467051, 18.173722],
              [109.441182, 18.199301],
              [109.355566, 18.215216],
              [109.287813, 18.264655],
              [109.17448, 18.260109],
              [109.138756, 18.268064],
              [109.108575, 18.323736],
              [108.944735, 18.31408],
              [108.888068, 18.412319],
              [108.776583, 18.441837],
              [108.68912, 18.447513],
              [108.644772, 18.486672],
              [108.641077, 18.565529],
              [108.663866, 18.673261],
              [108.65278, 18.740701],
              [108.593033, 18.809246],
              [108.595497, 18.872102],
              [108.637997, 18.924747],
              [108.630606, 19.002835],
              [108.598577, 19.056004],
              [108.591186, 19.141378],
              [108.609048, 19.276417],
              [108.663866, 19.374095],
              [108.765496, 19.401187],
              [108.806148, 19.450842],
              [108.855424, 19.469459],
              [108.92872, 19.524169],
              [108.993394, 19.586752],
              [109.048829, 19.620007],
              [109.093792, 19.689877],
              [109.147379, 19.705087],
              [109.169553, 19.736628],
              [109.159082, 19.790684],
              [109.264407, 19.904929],
              [109.300748, 19.917868],
              [109.349407, 19.898741],
              [109.411001, 19.895365],
              [109.498464, 19.873422],
              [109.526797, 19.943743],
              [109.585312, 19.98817],
              [109.712195, 20.017406],
              [109.76147, 19.981422],
              [109.820601, 19.994355],
              [109.855093, 19.984234],
              [109.965346, 19.993792],
              [109.997375, 19.979735],
              [110.106396, 20.026963],
            ],
          ],
          [
            [
              [108.9239501953125, 19.64776095569737],
              [108.8580322265625, 19.596019240312494],
              [108.7481689453125, 19.528730138897632],
              [108.6712646484375, 19.482128945320483],
              [108.577880859375, 19.4303341116379],
              [108.4954833984375, 19.33706180106996],
              [108.4515380859375, 19.155546551403607],
              [108.4954833984375, 18.984220415249744],
              [108.48999023437499, 18.890695349102117],
              [108.52844238281249, 18.729501999072138],
              [108.555908203125, 18.594188856740413],
              [108.5723876953125, 18.510865709091377],
              [108.5943603515625, 18.411866765202845],
              [108.69873046875, 18.34931174429646],
              [108.797607421875, 18.312810846425442],
              [108.9129638671875, 18.255436745247998],
              [109.0008544921875, 18.255436745247998],
              [109.127197265625, 18.187606552494625],
              [109.2425537109375, 18.171949679910608],
              [109.36340332031249, 18.130191156120055],
              [109.4622802734375, 18.088422664636212],
              [109.62158203125, 18.093644270502615],
              [109.764404296875, 18.119749966946426],
              [109.8687744140625, 18.18238775108558],
              [110.0555419921875, 18.260653356758375],
              [110.203857421875, 18.40665471391907],
              [110.3961181640625, 18.531700307384043],
              [110.55541992187499, 18.58377568837094],
              [110.621337890625, 18.687878686034182],
              [110.687255859375, 18.869904894964883],
              [110.7366943359375, 19.098457525292385],
              [110.75866699218749, 19.186677697957833],
              [110.7696533203125, 19.2489223284628],
              [110.8245849609375, 19.30077482585895],
              [110.8575439453125, 19.362976133341846],
              [110.90698242187499, 19.458823317103146],
              [110.95367431640624, 19.505431220375744],
              [111.04705810546874, 19.557202031700292],
              [111.1212158203125, 19.601194161263145],
              [111.1212158203125, 19.681384130000946],
              [111.1102294921875, 19.769288277210887],
              [111.0992431640625, 19.831309106118404],
              [111.08551025390624, 19.89072302399691],
              [111.060791015625, 19.95527809397557],
              [111.04156494140625, 19.993998469485504],
              [111.005859375, 20.05593126519445],
              [110.95916748046875, 20.084308968998194],
              [110.88226318359375, 20.073990398154418],
              [110.8245849609375, 20.125576455270572],
              [110.79711914062499, 20.166833052277696],
              [110.75866699218749, 20.190035095822594],
              [110.70648193359375, 20.202923627504823],
              [110.6378173828125, 20.184879384574106],
              [110.55541992187499, 20.151363105661474],
              [110.4949951171875, 20.143627557443363],
              [110.45928955078125, 20.151363105661474],
              [110.401611328125, 20.16425483433661],
              [110.27526855468749, 20.15394153657739],
              [110.21759033203125, 20.120418614613268],
              [110.12420654296875, 20.115260603847357],
              [110.0555419921875, 20.092047451277185],
              [109.9896240234375, 20.066251024326302],
              [109.87152099609375, 20.05593126519445],
              [109.764404296875, 20.06109122960637],
              [109.57763671875, 20.053351219365318],
              [109.51995849609375, 20.00690314683048],
              [109.48974609375, 19.960441360123426],
              [109.44854736328124, 19.950114658880615],
              [109.37164306640625, 19.970767385432207],
              [109.2919921875, 19.97851146065698],
              [109.2041015625, 19.947532877989353],
              [109.1766357421875, 19.90105360620521],
              [109.13543701171875, 19.86747675028447],
              [109.10522460937499, 19.828725387681168],
              [109.05853271484374, 19.774457602469948],
              [109.0283203125, 19.74343913716519],
              [108.9898681640625, 19.715000248204287],
              [108.95690917968749, 19.68914232238881],
              [108.9239501953125, 19.64776095569737],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        name: '台湾省',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [120.443706, 22.441042],
              [120.297112, 22.531154],
              [120.274323, 22.560442],
              [120.20041, 22.721137],
              [120.149287, 22.896527],
              [120.131425, 23.002313],
              [120.029795, 23.048569],
              [120.018708, 23.073342],
              [120.081534, 23.291698],
              [120.108019, 23.34115],
              [120.12157, 23.504758],
              [120.095084, 23.587583],
              [120.102476, 23.70104],
              [120.175156, 23.807282],
              [120.245989, 23.84067],
              [120.278018, 23.927657],
              [120.391967, 24.118386],
              [120.451713, 24.182809],
              [120.470807, 24.242835],
              [120.520698, 24.312103],
              [120.589068, 24.432549],
              [120.642654, 24.490279],
              [120.68885, 24.600764],
              [120.762147, 24.658416],
              [120.82374, 24.68832],
              [120.89211, 24.767665],
              [120.914899, 24.864876],
              [120.961095, 24.940311],
              [121.009754, 24.993468],
              [121.024537, 25.040639],
              [121.132942, 25.078579],
              [121.209318, 25.127342],
              [121.319572, 25.140884],
              [121.371926, 25.15984],
              [121.413194, 25.238883],
              [121.444607, 25.27081],
              [121.53515, 25.307597],
              [121.62323, 25.294614],
              [121.655259, 25.24213],
              [121.700222, 25.226975],
              [121.707613, 25.191247],
              [121.745186, 25.162007],
              [121.841888, 25.135468],
              [121.917033, 25.137634],
              [121.947214, 25.031965],
              [121.98109, 25.030881],
              [122.012503, 25.001602],
              [121.933047, 24.938684],
              [121.844968, 24.836101],
              [121.841272, 24.733977],
              [121.892395, 24.618171],
              [121.88562, 24.529477],
              [121.867758, 24.478844],
              [121.82649, 24.423833],
              [121.809243, 24.338818],
              [121.689135, 24.174622],
              [121.678048, 24.133676],
              [121.643556, 24.097633],
              [121.65957, 24.006934],
              [121.621382, 23.920547],
              [121.587505, 23.761291],
              [121.522832, 23.538772],
              [121.5216, 23.483358],
              [121.497578, 23.419685],
              [121.479716, 23.32247],
              [121.440296, 23.271912],
              [121.415042, 23.196039],
              [121.430441, 23.137181],
              [121.409499, 23.102513],
              [121.370695, 23.084351],
              [121.35468, 23.010024],
              [121.324499, 22.945574],
              [121.237652, 22.836435],
              [121.21055, 22.770799],
              [121.170514, 22.723345],
              [121.03316, 22.650477],
              [121.014682, 22.584199],
              [120.981421, 22.528391],
              [120.914899, 22.302718],
              [120.903197, 22.126573],
              [120.907508, 22.033426],
              [120.86624, 21.98461],
              [120.873016, 21.897477],
              [120.842835, 21.885263],
              [120.781857, 21.924122],
              [120.701784, 21.926898],
              [120.651277, 22.033426],
              [120.661748, 22.067255],
              [120.659285, 22.154284],
              [120.640806, 22.241259],
              [120.569973, 22.361938],
              [120.5053, 22.416154],
              [120.443706, 22.441042],
            ],
          ],
          [
            [
              [120.08056640625, 22.350075806124867],
              [120.16845703125, 22.167057857886153],
              [120.234375, 22.06527806776582],
              [120.30029296875, 21.993988560906033],
              [120.4541015625, 21.912470952680266],
              [120.62988281249999, 21.820707853875017],
              [120.80566406250001, 21.76970289940967],
              [120.9814453125, 21.85130210558968],
              [121.1572265625, 22.04491330024569],
              [121.201171875, 22.21792016631101],
              [121.278076171875, 22.370396344320053],
              [121.31103515625, 22.553147478403194],
              [121.387939453125, 22.74578914242589],
              [121.48681640624999, 22.917922936146045],
              [121.56372070312499, 23.19086257687362],
              [121.61865234375, 23.32208001137843],
              [121.6845703125, 23.61432859499168],
              [121.761474609375, 23.845649887659352],
              [121.827392578125, 24.016361823963027],
              [121.904296875, 24.216909537721747],
              [121.97021484374999, 24.407137917727667],
              [122.04711914062499, 24.597080137096412],
              [122.05810546875, 24.826624956562167],
              [122.18994140624999, 24.996015742094006],
              [122.15698242187499, 25.23475847023373],
              [122.03613281249999, 25.37380917154398],
              [121.88232421875, 25.46311452925943],
              [121.695556640625, 25.54244147012483],
              [121.46484375, 25.48295117535531],
              [121.46484375, 25.48295117535531],
              [121.04736328125, 25.31423555219758],
              [120.88256835937499, 25.145284610685064],
              [120.66284179687499, 24.936257323061316],
              [120.4541015625, 24.627044746156027],
              [120.33325195312499, 24.41714202537204],
              [120.17944335937499, 24.196868919249656],
              [120.08056640625, 24.016361823963027],
              [119.83886718750001, 23.68477416688374],
              [119.7509765625, 23.433009077420344],
              [119.718017578125, 22.98873816096074],
              [119.77294921874999, 22.826820400544044],
              [119.937744140625, 22.553147478403194],
            ],
          ],
        ],
      },
    },
  ],
};

export default CHINA_INSET_JSON;
