/*
 *Description: data校验函数
 *Date: Thu Dec 02 2021 11:37:52 GMT+0800 (中国标准时间)
*/

export function checkData(data: any) {
  const links: any[] = [];
  const nodes: any[] = [];
  let maxNodeVal = -1;
  let minNodeVal = Infinity;
  if (!data?.links || !data.nodes) {
    return {
      links,
      nodes,
      maxNodeVal,
      minNodeVal,
    };
  }
  data.links.forEach((item: any) => {
    if (item.source && item.target) {
      links.push(item);
    }
  });
  data.nodes.forEach((item: any) => {
    if (item.name) {
      maxNodeVal = Math.max(item.value, maxNodeVal);
      minNodeVal = Math.min(item.value, minNodeVal);
      nodes.push(item);
    }
  });
  return {
    links,
    nodes,
    minNodeVal,
    maxNodeVal,
  };
}
