/*
* Author: zhangmeng
* Description: '舆情列表一行'
* Date: 2022/06/29
*/

import React, { useCallback } from 'react';
import TabsComp from '../TabsComp';
import style from './index.module.less';

function PublicSentimentListLine(props) {
  const { record, participle } = props;

  const highLightWord = useCallback((value, t) => {
    if (!value || !participle?.length) return value;
    let word = `${t === 'abstract' ? '[简介]' : ''}${value}`;
    participle.forEach(item => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(item)) {
        word = word.split(item).join(`<span style="color: #D70000;">${item}</span>`);
        word = word.split('[简介]').join(`<span style="color: #1961F5;">${'[简介]'}</span>`);
      } else {
        const arr = word.split(item);
        const last = arr.pop();
        word = `${arr.join(`<span style="color: #D70000;">${item}</span>`)}${last}`;
        word = word.split('[简介]').join(`<span style="color: #1961F5;">${'[简介]'}</span>`);
      }
    });
    return word;
  }, [participle]);

  return (
    <div className={style.all} onClick={() => window.open(record.url)}>
      <div className={style.topLine}>
        <div className={style.left}>
          <div
            className={style.title}
            title={record.title}
            dangerouslySetInnerHTML={{
              __html: highLightWord(record.title),
            }}
          />
        </div>
        <div className={style.right}>
          <div className={style.row}>
            <span>{record.time}</span>
          </div>
        </div>
      </div>
      <div className={style.bottom}>
        <TabsComp labels={record.tabs} />
      </div>
      <div className={style.descBox}>
        <span
          className={style.abstract}
          title={record.abstract}
          dangerouslySetInnerHTML={{
            __html: highLightWord(record.abstract, 'abstract'),
          }}
        />
      </div>
    </div>
  );
}

export default PublicSentimentListLine;
