import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import Container from '../../Container';
import { openNewPage } from '../../../../../utils/urlMethods';
import { useGetCompanyYearReportQuery } from '../../../../../service/companyDetail';
import style from './index.module.less';
import TableNoData from '../../TableNoData';

function CompanyReport(props) {
  const { companyId, preventPageto } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: isTableFetching } = useGetCompanyYearReportQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      // if (item.dataIndex === 'index') {
      //   return {
      //     ...item,
      //     width: 70,
      //   };
      // }
      if (item.dataIndex === 'report' && !preventPageto) {
        return {
          ...item,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => record?._id && openNewPage('companyReport', { id: record?._id, name: window.btoa(window.encodeURIComponent(record?.report)) })}
            >
              {text}
            </div>
          ),
        };
      }
      return {
        ...item,
      };
    })
  ), [tableData, preventPageto]);

  return (
    <Container id="企业年报" num={tableData?.count || 0}>
      <Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={tableColumns}
        strip
        loading={isTableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        locale={{ emptyText: ' ' }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />
    </Container>
  );
}

export default CompanyReport;
