import { AdditionalOperation, RulesLogic } from 'json-logic-js';

export type Condition = {
  logic: '' | 'and' | 'or';
  operator: '==' | '===' | '!=' | '<=' | '>=' | '<' | '>';
  value: any;
};

export type Variable = { 'var': any } | string | number;

type JSONLogicBuilder = (conditions: Condition[], variable?: Variable) => RulesLogic<any>;

/**
 * 将易于 UI 表示的逻辑联组转化为 jsonLogic 规则
 * @param conditions
 * @param variable
 */
export const buildJSONLogic: JSONLogicBuilder = (conditions, variable = { 'var': '' } as RulesLogic<any>) => {
  const _variable = typeof variable === 'object' ? variable : { 'var': variable };
  return conditions.reduce<RulesLogic<any>>((prev, next: Condition) => {
    const { logic, operator, value } = next;
    if (!logic) {
      return { [operator]: [_variable, value], };
    }
    return {
      [logic]: [
        { [operator]: [_variable, value], },
        prev,
      ]
    };
  }, _variable);
};

export type {
  RulesLogic,
  AdditionalOperation,
};
