/*
* Author: zhangmeng
* Description: '笔记组件'
* Date: 2021/08/02
*/

import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import history from '../../../../../../../../assets/images/companyDetail/history.png';
import { IconFont } from '../../../../../../../../utils/mapIcon';
import Confirm from '../../../../../../../../components/Confirm';
import { updateNote, noteDelete } from '../../../../../../../../api/companyDetail';
import style from './index.module.less';

const { TextArea } = Input;

function NotesDetail(props) {
  const { notesData, notesDetailEvent, companyName, closePop, updateNoteList } = props;
  const [textArea, setTextArea] = useState(''); // 文本域内容
  const [historyData, setHistoryData] = useState([]); // 历史记录data
  const [editStatus, setEditStatus] = useState(false); // 编辑点击状态
  const [indexClick, setIndexClick] = useState(null); // 点击的index
  const [editArea, setEditArea] = useState(''); // 点击-编辑文本域
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [noteId, setNoteId] = useState(''); // 编辑、删除的笔记id

  useEffect(() => {
    const { data_list: dataList } = notesData;
    setHistoryData(dataList);
  }, [notesData]);

  const textAreaChange = (p) => {
    setTextArea(p.target.value);
  };

  const editAreaChange = (p) => {
    setEditArea(p.target.value);
  };

  const editClick = (info, i) => {
    setEditStatus(true);
    setIndexClick(i);
    setEditArea(info);
  };

  // 编辑确认
  const updateNoteConfirm = (id) => {
    updateNote({
      note_id: id,
      content: editArea,
    }).then(res => {
      if (res.status === '00000') {
        updateNoteList();
        setEditStatus(false);
        message.success('修改成功!');
      } else {
        message.error('修改失败！');
      }
    });
  };

  const hideConfirm = () => {
    noteDelete({
      note_id: noteId,
    }).then(res => {
      if (res.status === '00000') {
        setConfirmVisible(false);
        updateNoteList();
        message.success('删除成功！');
      } else {
        message.error('删除失败！');
      }
    });
  };

  return (
    <div className={style.all}>
      <header>
        <span>记录一下</span>
        <span className={style.add} onClick={() => closePop()}>
          <CloseOutlined />
        </span>
      </header>
      <div className={style.ctn}>
        <div className={style.companyName}>{companyName}</div>
        <div className={style.editArea}>
          <TextArea
            rows={4}
            allowClear
            placeholder="请输入您需要记录的内容"
            value={textArea}
            onChange={(p) => textAreaChange(p)}
          />
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.confirm} onClick={() => { notesDetailEvent('confirm', textArea); setEditStatus(false); setTextArea(''); }}>确认</div>
        <div className={style.cancel} onClick={() => { notesDetailEvent('cancel'); setTextArea(''); }}>取消</div>
      </div>
      <div className={style.history} style={{ display: historyData.length > 0 ? '' : 'none' }}>
        <div className={style.header}>历史记录</div>
        <div className={style.noteList}>
          {
            historyData.length > 0 && (
              historyData.map((item, index) => (
                <div className={style.historyBox} key={index}>
                  <div className={style.left} style={{ visibility: editStatus ? 'hidden' : 'visible' }}>
                    <img src={history} alt="" />
                  </div>
                  <div className={style.right}>
                    <div className={style.info}>
                      {
                        indexClick === index && editStatus ? (
                          <div className={style.editArea}>
                            <TextArea
                              rows={4}
                              allowClear
                              value={editArea}
                              onChange={(p) => editAreaChange(p)}
                            />
                          </div>
                        ) : (
                          <span title={item.content}>{item.content}</span>
                        )
                      }
                    </div>
                    <div className={style.historyFooter}>
                      <div className={style.date} style={{ visibility: editStatus ? 'hidden' : 'visible' }}>{item.update_date}</div>
                      <div className={style.btn}>
                        <span className={style.editChild} style={{ visibility: indexClick === index && editStatus ? 'visible' : 'hidden' }}>
                          <CloseOutlined onClick={() => setEditStatus(false)} />
                          <CheckOutlined onClick={() => updateNoteConfirm(item.id)} />
                        </span>
                        <IconFont type="iconedit-square" onClick={() => editClick(item.content, index)} />
                        <IconFont type="icondelete" onClick={() => { setConfirmVisible(true); setNoteId(item.id); }} />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )
          }
          <Confirm
            icon={<IconFont type="iconfrown-fill" />}
            text="确定要删除该条笔记吗？"
            visible={confirmVisible}
            onOK={() => {
              hideConfirm();
            }}
            onCancel={() => { setConfirmVisible(false); setNoteId(''); }}
          />
        </div>
      </div>
    </div>
  );
}

export default NotesDetail;
