import React, { useState, useEffect } from 'react';
import { Modal, message, Button, Upload, Tooltip } from 'antd';
import { ToTopOutlined, CloseOutlined } from '@ant-design/icons';
import { useClickSpace } from '../../../../../../hooks/browser';
import createProjectIcon from '../../../../../../assets/images/dataAnalysisTool/myReport/createProject.png';
import clearIcon from '../../../../../../assets/images/dataAnalysisTool/dataFilter/clearIcon.png';
import confirmIcon from '../../../../../../assets/images/dataAnalysisTool/myReport/confirmIcon.png';
import Table from '../../../../components/DataFilterTable';
import {
  useLazyGetUploadDataQuery,
  useLazyGetUploadDataQueryQuery,
  useLazyGetUploadDataClearQuery,
} from '../../../../../../service/dataAnalysisTool';
import dataDownload from '../DownloadExcel';
import styles from './index.module.less';
import ErrorTable from './ErrorTable';

export default function UploadData({ handleIfCreate, id, setTotal: setExportTotal }) {
  const [fileFlow, setFileFlow] = useState(null);
  const [showErrorTable, setShowErrorTable] = useState(false);
  const [errorColums, setErrorColums] = useState([]);
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [abnormalCount, setAbnormalCount] = useState(0);
  const [tableData, setTableData] = useState();
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState({
    current: 1,
    pageSize: 30,
  });

  const [getUploadDataQuery] = useLazyGetUploadDataQueryQuery();
  const [getUploadDataClear] = useLazyGetUploadDataClearQuery();

  useClickSpace(() => (setShowErrorTable(false)), []);
  // 查询上传数据接口
  const fetchUploadDataQuery = async () => {
    const res = await getUploadDataQuery({
      project_id: id,
      page_size: page?.pageSize,
      page_number: page?.current,
    });
    if (res.status === 'fulfilled') {
      setTableData(res.data);
      setTotal(res.data.count);
      setExportTotal(res.data.count);
    }
  };

  // 上传数据清空接口
  const fetchUploadDataClear = async () => {
    const res = await getUploadDataClear({ project_id: id });
    if (res.status === 'fulfilled') {
      message.success('数据已清空');
      fetchUploadDataQuery();
    }
  };

  // 关闭清空数据确认弹窗
  const handleClearClose = () => {
    setIsClearModalOpen(false);
  };

  // 清空数据弹窗确认
  const handleClearConfirm = () => {
    setIsClearModalOpen(false);
    // 调清空数据接口，后重新调获取数据接口
    fetchUploadDataClear();
  };

  // 上传文件
  const handleFileChange = (fileData) => {
    if (fileData) {
      message.loading('数据上传中...', 0);
      const formdata = new FormData();
      formdata.append('file', fileData);
      formdata.append('project_id', id);
      fetch('/industry_knowledge_engine/v2/dataAnalysis/dataSelect/upload_data', {
        method: 'POST',
        body: formdata,
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
        .then((res) => {
          res.json().then((response) => {
            if (response.status === '00000') {
              setAbnormalCount(response.data?.abnormal_count);
              message.destroy();
              message.success(`数据上传成功，共导入${response.data?.data_count}条数据`);
              fetchUploadDataQuery();
            } else {
              message.destroy();
              message.error(response.message);
            }
          });
        }).catch((err) => {
          message.destroy();
          message.error(err);
        });
    }
  };

  useEffect(() => {
    if (fileFlow) {
      handleFileChange(fileFlow);
    }
  }, [fileFlow]);

  useEffect(() => {
    if (id) {
      fetchUploadDataQuery();
    }
  }, [id, page]);

  useEffect(() => {
    if (tableData) {
      let head = [];
      const list = [];
      if (tableData?.columns?.length > 0) {
        head = tableData?.columns.map(item => {
          let rowConfig = { ...item };
          if (item.dataIndex === 'index') {
            rowConfig = {
              ...item,
              width: 80,
              align: 'center',
            };
          } else {
            rowConfig = {
              ...item,
              ellipsis: true,
            };
          }
          list.push({
            key: item.dataIndex,
            value: item.title,
            isSelected: true,
          });
          return rowConfig;
        });
      }
      // setExportType(list);
      setColumns(head);
    }
  }, [tableData]);

  const openErrorTable = (e) => {
    e.stopPropagation();
    setShowErrorTable(!showErrorTable);
  };

  return (
    <div className={styles.uploadData}>
      {
        tableData?.dataSource?.length > 0 ? (
          <div className={styles.uploadResult}>
            <div className={styles.top}>
              <div className={styles.overview}>
                共找到
                <span className={styles.num}>{total}</span>
                条数据
                {
                  abnormalCount > 0 && (
                    <span
                      className={styles.error}
                      onClick={openErrorTable}
                    >
                      {`${abnormalCount}条异常`}
                    </span>
                  )
                }
              </div>
              <div className={styles.clearBtn} onClick={() => setIsClearModalOpen(true)}>
                <img src={clearIcon} alt="" />
                清空数据
              </div>
            </div>
            <div className={styles.sperateLine} />
            <Table
              columns={columns}
              dataSource={tableData?.dataSource}
              scroll={{
                y: 758,
              }}
              onChange={(pageObj) => {
                setPage({
                  current: pageObj.current,
                  pageSize: pageObj.pageSize,
                });
              }}
              pagination={{
                current: page?.current,
                pageSize: page?.pageSize,
                total,
                showSizeChanger: true,
                pageSizeOptions: [30, 100, 150, 200],
                showQuickJumper: true,
              }}
            />
          </div>
        ) : (
          <div className={styles.uploadBox}>
            <div className={styles.content}>
              <img src={createProjectIcon} alt="" />
              <Upload
                accept=".xls,.xlsx"
                maxCount={1}
                showUploadList={false}
                beforeUpload={(e) => {
                  if (id) {
                    setFileFlow(e);
                  } else {
                    handleIfCreate(id, { callbackFn: () => setFileFlow(e) });
                  }
                }}
              >
                <div className={styles.uploadBtn}>
                  <ToTopOutlined />
                  上传数据
                </div>
              </Upload>
              <div className={styles.discribe}>上传数据格式要求excel，数据上传限制10000条(大陆注册企业)</div>
            </div>
          </div>
        )
      }
      <Modal
        title={
          <div className={styles.clearTitle}>
            <img src={confirmIcon} alt="" />
            是否确认清空当前数据？
          </div>
        }
        footer={null}
        open={isClearModalOpen}
        onCancel={handleClearClose}
        width={360}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.clearContent}>
          <div className={styles.text}>清空当前数据不可恢复，再次分析需要您重新手动上传</div>
          <div className={styles.buttonBox}>
            <Button onClick={handleClearClose} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={handleClearConfirm}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
      {
        showErrorTable && (
          <div
            className={styles.errorData}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={styles.top}>
              <div className={styles.describe}>
                <span className={styles.errorTitle}>异常名单</span>
                <Tooltip title={
                  <>
                    共
                    <span className={styles.errorNumber}>
                      {abnormalCount || '-' }
                    </span>
                    家，请检查是否为上传超过10000条、数据重复和不准确、非大陆注册企业等原因。
                  </>
                }
                >
                  <div className={styles.errorHint}>
                    共
                    <span className={styles.errorNumber}>
                      {abnormalCount || '-' }
                    </span>
                    家，请检查是否为上传超过10000条、数据重复和不准确、非大陆注册企业等原因。
                  </div>
                </Tooltip>
              </div>
              <div
                className={styles.button}
                onClick={() => {
                  dataDownload({
                    columns: errorColums,
                    project_id: id,
                  }, '异常名单', '/dataSelect/find_abnormal_list_export');
                }}
              >
                导出数据
              </div>
              <div className={styles.errorClose} onClick={openErrorTable}>
                <CloseOutlined />
              </div>
            </div>
            <div className={styles.errorTable}>
              <ErrorTable id={id} setErrorColums={setErrorColums} />
            </div>
          </div>
        )
      }

    </div>
  );
}
