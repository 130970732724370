/**
 * 水平条形图数据处理函数相关
 */
import { HorizontalBarChartProps } from '../basicsType';

export function handleData(xAxis: HorizontalBarChartProps['xAxis'] = [], yAxis: HorizontalBarChartProps['yAxis']) {
  const handledData: Array<{ name: string; value: string | number; }> = [];
  xAxis.forEach((item, index) => {
    handledData.push({
      name: item,
      value: yAxis?.value[index],
    });
  });
  handledData.sort((a, b) => Number(a.value) - Number(b.value));
  return handledData;
}
