/*
 *Author: zhaochenyu
 *Description: 专精特新下钻地图
 *Date: 2022/05/11
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/lib/echarts';
import { get } from '../../../../utils/request';
import { tooltipFormatter } from '../../../../utils/charts';

const MAP_COLOR = [
  '#8CCAFF', '#70BCFF', '#49AAFF', '#3088F5',
  '#2F73FF', '#1961F5', '#0051F7', '#0044D1',
];

function SpecialNewMap(props) {
  const { data, color, title, mapCode, unit, source, getAreaCompnayCount, getAreaMapCount,
    setDrillCityCode, drillCityCodeList, setDrillCityNameList } = props;
  const [option, setOption] = useState({});
  const chartEvent = {
    click: (params) => {
      if (params.data && params.data.code) {
        drillCityCodeList.current.push(mapCode);
        setDrillCityNameList(item => ({ ...item, [params.data.code]: params.name }));
        setDrillCityCode(params.data.code);
        getAreaMapCount({
          area_key: params.data.code,
        });
        getAreaCompnayCount({
          area_key: params.data.code,
          sort_column: 'count',
          industry_name_list: [],
          select_level: '',
        });
      }
    },
  };

  useEffect(() => {
    const generateOption = async () => {
      let mapName = 'china';
      if (mapCode) {
        mapName = mapCode === '000000' ? 'china' : mapCode;
        if (!echarts.getMap(mapName)) {
          const geoJson = await get(`${process.env.REACT_APP_JSON_URL}/${mapCode}.json`);
          echarts.registerMap(mapName, geoJson);
        }
      } else if (!echarts.getMap('china')) {
        const geoJson = await get(`${process.env.REACT_APP_JSON_URL}/000000.json`);
        echarts.registerMap('china', geoJson);
      }
      const maxValue = data.length > 0 ? Math.max(...data.map(item => item.value)) : 0;
      const minValue = data.length > 0 ? Math.min(...data.map(item => item.value)) : 0;
      const options = {
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        backgroundColor: '#fff',
        visualMap: [{
          type: 'piecewise',
          min: minValue,
          max: maxValue,
          left: 14,
          bottom: 10,
          splitNumber: 8,
          inRange: {
            color: MAP_COLOR,
          },
          seriesIndex: 0,
          itemWidth: 22,
          itemHeight: 6,
          itemGap: 3,
          text: ['高', '低'],
          textStyle: {
            color: '#3E5C76',
            fontSize: 12,
          },
          // showLabel: false,
        }],
        geo: [{
          map: mapName,
          zoom: 1.2,
          itemStyle: {
            normal: {
              borderColor: '#fff',
            },
          },
          emphasis: {
            itemStyle: {
              areaColor: '#002187',
              borderColor: '#fff',
              borderWidth: 2,
              shadowColor: '#0060C9',
              shadowOffsetX: 2,
              shadowOffsetY: 6,
              shadowBlur: 8,
            },
            label: {
              color: '#fff',
            },
          },
          label: {
            show: true,
            // color: '#fff',
          },
        }],
        series: [
          {
            // name: '',
            type: 'map',
            selectedMode: false,
            geoIndex: 0,
            map: mapName,
            tooltip: {
              // formatter: '{b}&emsp;&emsp;&emsp;&emsp;{c}',
              formatter: (params) => {
                let str;
                if (source) {
                  str = '<div style="padding: 9px 9px 4px 9px">';
                  if (title) {
                    str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: #3E5C76;letter-spacing: 1.75px;">${title}</div>`;
                  }
                  str += `<div style="margin-bottom: 10px; font-size: 14px; color: #3E5C76;letter-spacing: 1.75px;">${params.name}</div>`;
                  str += `<div style="display: flex; justify-content: space-between; color: #262D36;">
                    <span>
                      <span style="color: #4E7293;font-weight: bold;font-size: 18px;margin-right: 4px;">${(params.data && params.data.value) || 0}</span>
                      ${unit}
                    </span>
                  </div>`;
                  str += '</div>';
                } else {
                  str = tooltipFormatter(params, { title, valueWrap: true });
                }
                return str;
              },
              backgroundColor: source ? '#fff' : 'rgba(65, 89, 76, 0.8)',
              extraCssText: source ? 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);'
                : 'border: 1px solid #a4c3b2; border-radius: 0',
            },
            data,
          },
          // scatterSeries,
        ],
      };
      setOption(options);
    };
    generateOption();
  }, [data, color, mapCode]);

  return <ReactEcharts
    option={option}
    onEvents={chartEvent}
    style={{ width: '100%', height: '100%' }}
  />;
}

export default SpecialNewMap;

SpecialNewMap.defaultProps = {
  color: MAP_COLOR,
};
