/*
* Author: zhangmeng
* Description: '目录组件'
* Date: 2021/07/13
*/

import React from 'react';
import { Input } from 'antd';
import TitleAndSubTitleComp from '../../../TitleAndSubTitleComp';
import style from './index.module.less';

const { TextArea } = Input;
function Catalogue(props) {
  // eslint-disable-next-line no-unused-vars
  const { data } = props;

  return (
    <div className={style.all}>
      <TitleAndSubTitleComp title="内容目录" subTitle="" />
      <div className={style.catalogue}>
        <p className={style.title}>目录</p>
        <TextArea bordered={false} disabled value={data || '暂无数据'} />
      </div>
    </div>
  );
}

export default Catalogue;
