/*
 *Description: SingleScatter
 *Date: Tue Jan 11 2022 10:40:01 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { SingleScatterChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, getWordsWidth, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-single-scatter-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const SingleScatterChart = forwardRef((props: SingleScatterChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);
  const curStyle = modeStyle[mode!] || modeStyle.dark;

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);
  useEffect(() => {
    if (!data?.length) {
      return;
    }
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    const singleAxis: any [] = [];
    const series: any[] = [];
    data.forEach((item: any, idx: number) => {
      const xData = [];
      const realData: any[] = [];
      let max = -Infinity;
      item.data.forEach((scatterData: any, index: number, arr: any) => {
        max = Math.max(max, scatterData.value);
        xData.push(scatterData.xIndex);
        realData.push({
          name: scatterData.name,
          value: scatterData.value,
          itemStyle: {
            color: scatterData.name === item.selfName || (
              index === arr.length - 1
            ) ? curStyle.highlightColor : (
                curStyle.baseColor
              ),
          },
          label: {
            show: scatterData.name === item.selfName || index === arr.length - 1,
            formatter() {
              return scatterData.name;
            },
            position: 'top',
            distance: 8,
            color: '#0D3B66',
            fontSize: 10,
          },
        });
      });
      singleAxis.push({
        type: 'value',
        boundaryGap: false,
        top: `${(idx * 23) + 25}%`,
        height: 10,
        right: getWordsWidth(item.xName, {
          font: `${8}px sans-serif`,
        }) + 15 + 30,
        name: item.xName,
        nameTextStyle: {
          fontSize: 8,
          color: '#777A7A',
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#EEEEEE',
          },
        },
        axisLabel: {
          fontSize: 8,
          color: '#777A7A',
        },
      });
      series.push({
        singleAxisIndex: idx,
        coordinateSystem: 'singleAxis',
        type: 'effectScatter',
        data: realData,
        symbolOffset: [0, '50%'],
        labelLayout: {
          moveOverlap: 'shiftY',
        },
        symbolSize(value: any) {
          const size = (Math.abs(value) / Math.abs(max)) * 16 + 4;
          return size > 20 ? 20 : size;
        },
      });
    });

    const options = {
      singleAxis,
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };
    setOption(options);
  }, [hanldeData, fontSize, label, legend, data, title]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default SingleScatterChart;

SingleScatterChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
