/**
 * store
 */

import { configureStore } from '@reduxjs/toolkit';
import { api } from '@sqke/report-render';
import { apiSlice } from './slices/api';
import appReducer from './slices/app';
import dataAnalysisSelector from './pages/DataAnalysisTool/store';

const initialState = {};
const store = configureStore({
  reducer: {
    app: appReducer,
    dataAnalysis: dataAnalysisSelector,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().slice(0, 2).concat(apiSlice.middleware, api.middleware)
  ),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: initialState,
});

export default store;
