/*
*Description: 样式文件
*/
import * as CONSTS from '../../consts';

const chartFontSize = {
  small: {
    legnedFontSize: 10,
    labelFontSize: 10,
  },
  middle: {
    legnedFontSize: 12,
    labelFontSize: 12,
  },
  large: {
    legnedFontSize: 14,
    labelFontSize: 14,
  },
};

const modeStyle = {
  light: {
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    color: ['rgb(13, 59, 102)', 'rgb(78, 114, 147)', 'rgb(197, 213, 234)', 'rgb(164, 195, 178)',
      'rgb(204, 227, 222)', 'rgb(234, 209, 193)', 'rgb(203, 199, 219)',
      'rgb(233, 197, 130)', 'rgb(238, 150, 75)', 'rgb(213, 103, 29)',
      'rgb(197, 70, 70)', 'rgb(203, 153, 126)', 'rgb(224, 199, 199)', 'rgb(204, 204, 186)',
      'rgb(216, 225, 233)', 'rgb(188, 179, 223)', 'rgb(255, 196, 155)', 'rgb(210, 210, 120)',
      'rgb(180, 180, 113)', 'rgb(48, 161, 112)', 'rgba(91, 120, 104, 1)', 'rgba(87, 189, 167, 1)', 'rgba(181, 242, 229, 1)',
      'rgba(147, 212, 237, 1)', 'rgba(131, 158, 218, 1)',
    ],
    labelColor: '#0D3B66',
    labelBgdColor: '#E2EEFF',
    labelBorderColor: '#C5D5EA',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',
  },
  dark: {
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    color: ['#00F2FE', '#3E83F7', '#1D16F4', '#5170CA', '#390D97', '#E65226', '#FEC52A'],
    labelColor: '#fff',
    labelBgdColor: 'rgba(27, 2, 253, 0.3)',
    labelBorderColor: 'transparent',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
  },
};

export {
  chartFontSize,
  modeStyle,
};
