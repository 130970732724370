import { useEffect, useState } from 'react';
import {
  useLazyGetParkPotentialSpecializedAndVeryNewQuery,
  useLazyGetParkPotentialListedEnterpriseQuery,
  useLazyGetParkPotentialHighTechnologyQuery,
  useLazyGetParkPotentialRiskQuery,
} from '../../../../../../../service/parkTool';

const useGetData = (reqData, type) => {
  const [data, setData] = useState({});
  const [chartIsFetching, setChartIsFetching] = useState(true);
  const {
    sort_key: sortKey,
    industry_code_list: industryCodeList,
    sort_value: sortValue,
    page_number: page,
  } = reqData;
  const [potentialSpecializedAndVeryNew] = useLazyGetParkPotentialSpecializedAndVeryNewQuery();
  const [potentialListedEnterprise] = useLazyGetParkPotentialListedEnterpriseQuery();
  const [potentialHighTechnology] = useLazyGetParkPotentialHighTechnologyQuery();
  const [potentialRisk] = useLazyGetParkPotentialRiskQuery();
  const getTableList = () => {
    switch (type) {
      case '专精特新':
        return potentialSpecializedAndVeryNew(reqData);
      case '上市公司':
        return potentialListedEnterprise(reqData);
      case '高新技术':
        return potentialHighTechnology(reqData);
      case '潜在风险':
        return potentialRisk(reqData);
      default: return potentialSpecializedAndVeryNew(reqData);
    }
  };
  useEffect(() => {
    setChartIsFetching(true);
    const time = setTimeout(() => {
      if (type) {
        getTableList().then(res => {
          setChartIsFetching(false);
          setData(res.data);
        }).catch(err => {
          setData({});
        });
      }
    }, 5);
    return () => { clearInterval(time); };
  }, [sortKey, industryCodeList, sortValue, page, type]);
  return {
    data,
    chartIsFetching,
  };
};

export { useGetData };
