import React, { useState, useRef, useEffect } from 'react';
import { Button, Form, Input, Select, DatePicker, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import style from './index.module.less';
import { useLazyAssociationSearchQuery, useLazyGetIndustrySelfQuery, useLazyGetIncomeOperateRevenueQuery } from '../../../service/tool';
import Underline from '../../../components/Underline';

const Index = (props) => {
  const [revenueVerify, setRevenueVerify] = useState(false);
  const [lastYear, setLastYear] = useState(null);
  const [twoYear, setTwoYear] = useState(null);
  const [twoYearData, setTwoYearData] = useState(null);
  const [triennium, setTriennium] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [companyListShow, setCompanyListShow] = useState(false);
  const [industryNameList, setIndustryNameList] = useState([]);
  const [yearVerify, setYearVerify] = useState(false);

  const formRef = useRef(null);
  const params = new URLSearchParams(window.location.href.split('?')[1]);

  const [associationSearchQuery] = useLazyAssociationSearchQuery();
  const [getIndustrySelfQuery] = useLazyGetIndustrySelfQuery();
  const [getIncomeOperateRevenueQuery] = useLazyGetIncomeOperateRevenueQuery();

  const getIndustrySelf = (id) => {
    getIndustrySelfQuery({ company_id: id }).then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        setIndustryNameList(data.map(item => ({ value: item, label: item })));
      } else {
        message.error('获取产业失败');
      }
    }).catch(err => console.log(err));
  };

  const associationSearch = () => {
    if (!companyName) return;
    associationSearchQuery({ keyword: companyName }).then(res => {
      const { status, data } = res;
      if (status === 'fulfilled') {
        setCompanyList(data?.map(item => {
          const oRegExp = new RegExp(companyName, 'ig');
          const name = item.name.match(oRegExp);
          return {
            showName: item.name.replace(oRegExp, `<span>${name}</span>`),
            name: item.name,
            id: item.company_id,
          };
        }));
      } else {
        message.error('搜索企业失败');
      }
      setCompanyListShow(true);
    }).catch(err => console.log(err));
  };
  const getIncomeOperateRevenue = (id, year) => {
    if (!id) return;
    getIncomeOperateRevenueQuery({ company_id: id, year_str: year }).then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        setTwoYearData(data);
      } else {
        message.error('自动获取前两年金额失败');
      }
    }).catch(err => console.log(err));
  };

  const onFinish = (values) => {
    if (!lastYear) {
      setYearVerify(true);
      return;
    }
    const companyInfo = {
      company_name: values.name,
      industry: values.industry,
      operate_revenue_dict: {
        [lastYear]: values.lastYear - 0,
        [twoYear]: values.twoYear - 0,
        [triennium]: values.triennium - 0,
      },
      net_profit: values.profit - 0,
      gross_profit_margin: values.rateOfMargin - 0,
      total_expenditure: values.pay - 0,
      total_tax_paid: values.ratepaying - 0,
      net_worth: values.property - 0,
      company_id: companyId,
    };
    localStorage.setItem('companyAppraisementReq', JSON.stringify(companyInfo));
    // <props className="hist"></props>
    props.history.push(`/tool/companyAppraisement/appraisement?id=${companyId}`);
  };
  const onFinishFailed = (errorInfo) => {
    // props.history.push(`/tool/companyAppraisement/appraisement?id=${companyId}`);
    if (!lastYear) {
      setYearVerify(true);
    }
  };
  const selectCompany = (item) => {
    formRef?.current.setFieldsValue({
      name: item.name,
      lastYear: twoYearData && twoYearData[lastYear],
      twoYear: twoYearData && twoYearData[twoYear],
      triennium: twoYearData && twoYearData[triennium],
    });
    getIndustrySelf(item.id);
    setCompanyName(item.name);
    setCompanyId(item.id);
  };

  useEffect(() => {
    setCompanyName(params.get('name'));
    formRef?.current.setFieldsValue({
      name: params.get('name'),
    });
    setCompanyId(params.get('id'));
    if (params.get('id')) {
      getIndustrySelf(params.get('id'));
    }
  }, []);

  useEffect(() => {
    if (lastYear && companyId) {
      getIncomeOperateRevenue(companyId, lastYear);
    }
  }, [lastYear, companyId]);

  return (
    <div className={style.all}>
      <ul className={style.title}>
        <li>企业估值</li>
        <li>免费快速测算企业价值</li>
      </ul>
      <Underline />
      <div className={style.companyInfo}>
        <Form
          ref={formRef}
          className={style.form}
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="公司名称"
            name="name"
            rules={[
              {
                required: true,
                message: '必填项，请输入后再次进行提交',
              },
            ]}
          >
            <Input
              // value="dbisuybcisyg"
              onChange={e => setCompanyName(e.target.value)}
              // ref={nameInput}
              suffix={<SearchOutlined onClick={associationSearch} />}
            />
          </Form.Item>
          <Form.Item
            label="公司行业"
            name="industry"
            rules={[
              {
                required: true,
                message: '必填项，请输入后再次进行提交',
              },
            ]}
          >
            <Select
              placeholder="请选择行业"
              options={industryNameList}
            />
          </Form.Item>
          <Form.Item
            className={style.revenue}
            label="公司营业收入"
            name="lll"
            rules={[
              {
                required: false,
                message: '必填项，请输入后再次进行提交',
              },
            ]}
          >
            <Form.Item
              label={<DatePicker
                picker="year"
                allowClear={false}
                style={{
                  width: 220,
                }}
                onChange={(e, year) => {
                  setYearVerify(false);
                  setLastYear(year);
                  setTwoYear((year - 1).toString());
                  setTriennium((year - 2).toString());
                }}
                // value={2022}
              />}
              name="lastYear"
              rules={[
                {
                  required: true,
                  message: '必填项，请输入后再次进行提交',
                },
                { pattern: '^[0-9]*$', message: '请正确输入' },
              ]}
            >
              <Input suffix="万元" />
            </Form.Item>
            <Form.Item
              label={
                <DatePicker
                  picker="year"
                  style={{
                    width: 220,
                  }}
                  disabled
                  value={twoYear ? moment(twoYear) : ''}
                />
              }
              name="twoYear"
              rules={[
                {
                  required: true,
                  message: '必填项，请输入后再次进行提交',
                },
                { pattern: '^[0-9]*$', message: '请正确输入' },
              ]}
            >
              <Input suffix="万元" />
            </Form.Item>
            <Form.Item
              label={<DatePicker
                picker="year"
                style={{
                  width: 220,
                }}
                disabled
                value={triennium ? moment(triennium) : ''}
              />}
              name="triennium"
              rules={[
                {
                  required: true,
                  message: '必填项，请输入后再次进行提交',
                },
                { pattern: '^[0-9]*$', message: '请正确输入' },
              ]}
            >
              <Input suffix="万元" />
            </Form.Item>
            {
              yearVerify && <span style={{ fontSize: '14px', color: 'red', marginTop: '-20px' }}>必填项，请输入后再次进行提交</span>
            }
          </Form.Item>
          <Form.Item
            label="近一年净资产"
            name="property"
            rules={[
              { pattern: '^[0-9]*$', message: '请正确输入' },
            ]}
          >
            <Input suffix="万元" />
          </Form.Item>
          <Form.Item
            label="近一年净利润"
            name="profit"
            rules={[
              {
                required: true,
                message: '必填项，请输入后再次进行提交',
              },
              { pattern: '^[0-9]*$', message: '请正确输入' },
            ]}
          >
            <Input suffix="万元" />
          </Form.Item>
          <Form.Item
            label="近一年毛利率"
            name="rateOfMargin"
            rules={[
              {
                required: true,
                message: '必填项，请输入后再次进行提交',
              },
              { pattern: '^[0-9][0-9]*([.][0-9]{1,2})?$', message: '请正确输入' },
            ]}
          >
            <Input suffix="%" />
          </Form.Item>
          <Form.Item
            label="近一年总支出"
            name="pay"
            rules={[
              { pattern: '^[0-9]*$', message: '请正确输入' },
            ]}
          >
            <Input suffix="万元" />
          </Form.Item>
          <Form.Item
            label="近一年纳税总额"
            name="ratepaying"
            rules={[
              { pattern: '^[0-9]*$', message: '请正确输入' },
            ]}
          >
            <Input suffix="万元" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 20,
            }}
          >
            <Button type="primary" htmlType="submit" style={{ width: '556px' }}>
              立即提交
            </Button>
          </Form.Item>
        </Form>
      </div>
      {
        companyListShow && (
          <div className={style.companyList}>
            <div>
              <p>搜索结果</p>
              <div onClick={() => { setCompanyListShow(false); }} />
            </div>
            <ul>
              {
                companyList?.length > 0 && (
                  companyList.map(item => <div key={item.id} dangerouslySetInnerHTML={{ __html: item.showName }} onClick={() => { selectCompany(item); }} />)
                )
              }
            </ul>
          </div>
        )
      }
    </div>
  );
};

export default Index;
