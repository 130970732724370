import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Spin } from 'antd';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { useGetCompanyIndustryLinkDistributionQuery } from '../../../../../../service/companyDetail';
import Tree from './components/Tree';
import Button from './components/Button';
import ZoomButton from './components/ZoomButton';
import styles from './index.module.less';

const getName = node => node.name;
const getOptionsName = (node) => node.title;
const getValue = node => node.score;
const getId = node => node.value;
const getDisabled = node => !node.isBright;

const TREE_PADDING = [10, 220, 10, 30];

/**
 * 图谱 scheme
 * 所谓全展开，就是根节点
 * @param {string} industryCode
 * @returns {JSX.Element}
 * @constructor
 */
const IndustrialChainGraph = (props) => {
  const {
    industryCode,
    industryName,
    companyId,
    viewAll,
    optionsData,
  } = props;
  // 当前产业/环节名称，产业同种需要根据产业名称进行查询
  const [selectedId, setSelectedId] = useState(industryCode);
  // 树图 ref，负责调用内置方法
  const treeRef = useRef();
  useEffect(() => {
    setSelectedId(industryCode);
  }, [industryCode]);
  useEffect(() => {
    if (viewAll && treeRef.current.setNodesCollapsedState) {
      treeRef.current.setNodesCollapsedState(false);
    }
  }, [treeRef.current, viewAll]);
  // 树图缩放等级
  const [zoom, setZoom] = useState(1);
  const setZoomDebounced = useCallback(debounce(setZoom, 50), []);
  const {
    data: chainData,
    isFetching: isChainFetching,
  } = useGetCompanyIndustryLinkDistributionQuery({
    industry_code: industryCode,
    company_id: companyId,
  }, {
    skip: !industryCode,
  });
  const { series } = chainData ?? {};
  return (
    <section className={styles.graphScheme}>
      {/* 产业链图，树图结合下拉表格的组合 */}
      <div
        className={classnames(
          styles.subScheme,
          {
            'grey-out': isChainFetching,
            [styles.viewAll]: viewAll,
          },
        )}
        id={styles.tree}
      >
        {
          isChainFetching && <Spin className={styles.spin} delay={200} />
        }
        <div
          className={styles.buttons}
        >
          <ZoomButton
            zoom={zoom}
            minZoom={0.1}
            maxZoom={2}
            className={styles.button}
            onChange={z => {
              const _z = Number(z?.toFixed(2));
              setZoom(_z);
              treeRef.current.setZoom(_z);
            }}
          />
          <Button
            className={styles.button}
            type="primary"
            text="展开"
            onClick={() => {
              const { setNodesCollapsedState } = treeRef.current;
              setNodesCollapsedState(false);
            }}
          />
          <Button
            className={styles.button}
            type="primary"
            text="收起"
            onClick={() => {
              const { setNodesCollapsedState } = treeRef.current;
              setNodesCollapsedState(true);
            }}
          />
          <Button
            className={styles.button}
            type="primary"
            text="下载图片"
            onClick={() => {
              treeRef.current.download();
            }}
          />
        </div>
        <Tree
          name={industryName}
          data={series?.[0]?.data?.[0] || optionsData}
          minZoom={0.1}
          maxZoom={2}
          onClick={(item) => {
            const { id, disabled } = item;
            if (disabled) return;
            if (selectedId === id) {
              setSelectedId(industryCode);
            } else {
              setSelectedId(id);
            }
          }}
          config={{
            // padding: isTreeDropdownOpen ? TREE_PADDING : 0,
            // padding: 0,
            padding: TREE_PADDING,
          }}
          getId={getId}
          getName={series?.[0]?.data?.[0] ? getName : getOptionsName}
          getValue={getValue}
          getDisabled={getDisabled}
          selectedId={selectedId}
          onInit={() => {
            const _zoom = treeRef.current.getZoom();
            if (typeof _zoom === 'number') {
              setZoom(_zoom);
            }
          }}
          onViewportChange={() => {
            const _zoom = treeRef.current.getZoom();
            if (typeof _zoom === 'number') {
              const _z = Number(_zoom?.toFixed(2));
              setZoomDebounced(_z);
            }
          }}
          disableZoom
          ref={treeRef}
        />
      </div>
      <ul className={styles.legend}>
        <li>
          <p />
          <p>业务领域覆盖</p>
        </li>
        <li>
          <p />
          <p>暂无业务布局</p>
        </li>
        {/* <li>
          <p />
          <p>缺失环节</p>
        </li> */}
      </ul>
    </section>
  );
};

export default IndustrialChainGraph;
