import React, { useEffect, useState } from 'react';
import { throttle } from 'lodash';
import Table from '../../../../components/DataFilterTable';
import {
  useLazyGetFilterDataQuery,
} from '../../../../../../service/dataAnalysisTool';
import { isArrayMultiDimensional } from '../../../../utils/public';
import styles from './index.module.less';

const total = 300;

const toServerData = (list) => {
  const arr = [];
  if (list?.length > 0) {
    list.forEach(item => {
      arr.push(item[item.length - 1]);
    });
  }
  return arr;
};

export default function FilterResult(props) {
  const { filterParams, searchPage, setSearchPage, setTotal } = props;
  const [tableData, setTableData] = useState();
  const [getFilterData, { isFetching }] = useLazyGetFilterDataQuery();
  const [columns, setColumns] = useState();
  // 获取筛选数据接口
  const fetchFilterData = async () => {
    const res = await getFilterData({
      ...filterParams,
      industry_code_list: toServerData(filterParams.industry_code_list),
      region_code_list: isArrayMultiDimensional(filterParams?.region_code_list) ? toServerData(filterParams.region_code_list) : filterParams.region_code_list,
      park_id_list: filterParams.park_id_list.map(item => item.value),
      page_size: searchPage?.pageSize,
      page_number: searchPage?.current,
    });
    if (res.status === 'fulfilled') {
      setTableData(res.data);
      setTotal(res.data?.count);
    }
  };

  useEffect(() => {
    if (filterParams && searchPage) {
      const fetchData = throttle(() => {
        fetchFilterData();
      }, 200);
      fetchData();
    }
  }, [filterParams, searchPage]);
  useEffect(() => {
    if (tableData) {
      let head = [];
      const list = [];
      if (tableData?.columns?.length > 0) {
        head = tableData?.columns.map(item => {
          let rowConfig = { ...item };
          if (item.dataIndex === 'index') {
            rowConfig = {
              ...item,
              width: 80,
              align: 'center',
            };
          } else if (item.dataIndex === 'name') {
            rowConfig = {
              ...item,
              width: 320,
              ellipsis: true,
            };
          } else if (item.dataIndex === 'credit_code') {
            rowConfig = {
              ...item,
              width: 200,
              ellipsis: true,
            };
          } else if (item.dataIndex === 'industry_name') {
            rowConfig = {
              ...item,
              ellipsis: true,
              render: (text) => <span title={text} >{text}</span>,
            };
          } else if (item.dataIndex === 'park') {
            rowConfig = {
              ...item,
              ellipsis: true,
              render: (text) => <span title={text} >{text}</span>,
            };
          } else {
            rowConfig = {
              ...item,
              ellipsis: true,
            };
          }
          list.push({
            key: item.dataIndex,
            value: item.title,
            isSelected: true,
          });
          return rowConfig;
        });
      }
      // setExportType(list);
      setColumns(head);
    }
  }, [tableData]);

  return (
    <div className={styles.resultBox}>
      <div className={styles.overview}>
        共找到
        <span>{tableData?.count}</span>
        条数据
      </div>
      <div className={styles.sperateLine} />
      <Table
        columns={columns}
        loading={isFetching}
        dataSource={tableData?.dataSource}
        scroll={{
          y: 510,
          x: 2600,
        }}
        onChange={(pageObj) => {
          setSearchPage({
            current: pageObj.current,
            pageSize: pageObj.pageSize,
          });
        }}
        pagination={{
          current: searchPage?.current,
          pageSize: searchPage?.pageSize,
          showSizeChanger: true,
          total: tableData?.count > total ? total : tableData?.count,
          pageSizeOptions: [15, 30, 50, 100],
          showQuickJumper: true,
        }}
        total={100}
      />
    </div>
  );
}
