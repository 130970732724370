/*
 *Author: zhaochenyu
 *Description: 柱状图
 *Date: 2021/09/18
*/

import React from 'react';
// import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { BarChartProps, BarAndLineData } from '../basicsType';
import { addWaterMark, getDefaultCanvas, handleToolTipLongTitle, getRoundingNumber } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { handleWithDarkColor, checkBarChartData } from './checkDataFormat';
import { barHightLightColor } from '../../consts';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-bar-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const BarChart = forwardRef((props: BarChartProps, ref: any) => {
  const { mode, chartGrid = {}, fontSize, label, legend, data, title = '',
    chartType = '', chartDesc, yMax, noYaxis } = props;
  const [hanldeData, setHanldeData] = useState<BarAndLineData>({ xAxis: [], yAxis: [] });
  const [option, setOption] = useState({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkBarChartData(data || {}));
    setChartLoading(false);
  }, [data]);

  const handleWithHighlightData = (dataList: any, hightLightIndex: number[], curColor: any,
    parentIndex: number, highlightColor: any, showLabel: any) => {
    const newData = dataList.map((item1: any, index1: number) => ({
      value: item1,
      itemStyle: {
        color: hightLightIndex.includes(index1)
          ? highlightColor[parentIndex % highlightColor.length]
          : curColor[parentIndex % curColor.length],
      },
      label: {
        show: hightLightIndex.includes(index1) ? false : showLabel,
      },
    }));
    return newData;
  };

  const handleWithLabelName = (yDataItem: any) => {
    let res;
    if (yDataItem.value_name) {
      res = yDataItem.value.map((item1: any, index1: number) => ({
        value: item1,
        labelName: yDataItem.value_name[index1],
      }));
    } else {
      res = yDataItem.value;
    }
    return res;
  };

  useEffect(() => {
    const { xAxis = [], yAxis = [], xName = '', xUnit = '', yName = '',
      yUnit = '', highlight = [], total = [] } = hanldeData!;

    if (xAxis.length === 0) return;

    const curStyle = modeStyle[mode!] || modeStyle.light;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    let chartColor: any[];
    let barStack: boolean = false;
    const stackObj: any = {};
    yAxis.forEach(item => {
      if (item.stack) {
        // eslint-disable-next-line no-unused-expressions
        stackObj[item.stack] ? stackObj[item.stack] += 1 : stackObj[item.stack] = 1;
      }
    });
    // 处理堆叠
    if (Object.keys(stackObj).length > 0
    && (Object.values(stackObj) as number[]).some(item => item > 1)) {
      barStack = true;
      switch (mode) {
        case 'light':
          chartColor = label ? curStyle.color.slice(2) : curStyle.color;
          break;
        case 'dark':
          chartColor = curStyle.color;
          break;
        default: break;
      }
    } else {
      barStack = false;
      switch (mode) {
        case 'light':
          chartColor = curStyle.color;
          break;
        case 'dark':
          chartColor = handleWithDarkColor(curStyle.color);
          break;
        default: break;
      }
    }
    let series: any[] = yAxis.map((item, index) => ({
      name: item.name,
      type: 'bar',
      stack: item.stack ? item.stack : item.name,
      data: handleWithLabelName(item),
      barMinHeight: 0,
      label: {
        show: label,
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
        position: barStack ? 'inside' : 'top',
        formatter: (param: any) => {
          let res = '';
          if (param?.data?.labelName) {
            res = param.data.labelName;
          } else if (param.value) {
            res = param.value;
          }
          return res;
        },
      },
      color: chartColor[index % chartColor.length],
      barMaxWidth: 20,
    }));

    // 高亮柱状图时特殊处理高亮的柱子
    const highlightIndex:number[] = [];
    if (highlight.length > 0) {
      xAxis.forEach((item, index) => {
        highlight.forEach((item1) => {
          if (item === item1) {
            highlightIndex.push(index);
          }
        });
      });
    }
    if (chartType === 'highlight' && highlight.length > 0) {
      const highlightColor = barHightLightColor;
      series = yAxis.map((item, index) => ({
        name: item.name,
        type: 'bar',
        stack: item.stack ? item.stack : item.name,
        data: handleWithHighlightData(item.value, highlightIndex,
          chartColor, index, highlightColor, label),
        barMinHeight: 0,
        label: {
          show: label,
          color: curStyle.labelColor,
          fontSize: curFont.labelFontSize,
          position: barStack ? 'insideTop' : 'top',
          formatter: (param: any) => {
            if (param.value) {
              return param.value;
            }
            return '';
          },
        },
        color: chartColor[index % chartColor.length],
        markPoint: {
          data: highlightIndex.map(hIndex => ({
            name: `${hIndex}highlight`,
            value: item.value[hIndex] || '',
            xAxis: hIndex,
            yAxis: item.value[hIndex] || '',
            itemStyle: {
              color: highlightColor[index % highlightColor.length],
            },
            label: {
              color: '#fff',
              fontSize: curFont.labelFontSize,
              textBorderColor: highlightColor[index % highlightColor.length],
              textBorderWidth: 1,
            },
          })),
        },
        barMaxWidth: 20,
      }));
      // 堆叠高亮图时不显示气泡
      if (barStack) {
        series.forEach((item: any) => {
          // eslint-disable-next-line no-param-reassign
          delete item.markPoint;
        });
      }
    }

    if (barStack && total.length > 0) {
      const stackTotalSeries = {
        type: 'bar',
        stack: yAxis[0].stack,
        data: total.map((item: string | number, index) => ({
          value: 0,
          showLabel: item,
          label: {
            show: true,
            position: 'top',
            padding: [0, 4, 0, 4],
            height: 18,
            lineHeight: 18,
            color: '#fff',
            backgroundColor: highlightIndex.includes(index) ? '#EA8686' : '#3E6385',
            formatter: (param: any) => param.data.showLabel,
          },
        })),
      };
      series.push(stackTotalSeries);
    }

    const options = {
      grid: {
        top: 30,
        left: 20,
        right: 20,
        bottom: 25,
        ...chartGrid,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => {
          let str = '<div style="padding: 9px 9px 4px 9px;">';
          if (title) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: ${curStyle.tooltipFont[0]};letter-spacing: 1.75px;">${handleToolTipLongTitle(title)}</div>`;
          }
          if (params.length > 0) {
            str += `<div style="margin-bottom: 10px; font-size: 14px; color: ${curStyle.tooltipFont[1]};letter-spacing: 1.75px;">${params[0].name}</div>`;
          }
          const newParams = params;
          if (barStack && total.length > 0) {
            newParams.pop();
          }
          newParams.forEach((item: any) => {
            const { seriesName, value } = item;
            str += `<div style="display: flex; justify-content: space-between; color: ${curStyle.tooltipFont[2]};">
              <span style="margin-right: 60px">${seriesName}</span>
              <span>
                <span style="color: ${curStyle.tooltipFont[3]};font-weight: 600;font-size: 18px;margin-right: 4px;">${value}</span>
                ${yAxis[0].unit}
              </span>
            </div>`;
          });
          str += '</div>';
          if (noYaxis) {
            str = '';
          }
          return str;
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 6,
        left: 'center',
        data: yAxis.map(item => item.name),
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      xAxis: {
        type: 'category',
        name: `${xName + xUnit}`,
        nameGap: 6,
        nameTextStyle: {
          color: curStyle.xColor,
          fontSize: curFont.xFontSize,
        },
        data: xAxis,
        axisLabel: {
          color: curStyle.xColor,
          interval: 0,
          fontSize: xAxis.length >= 25 ? 8 : curFont.xFontSize,
          rotate: (xAxis.some(item => item.length > 5) || xAxis.length > 10) ? 45 : 0,
        },
        axisLine: {
          lineStyle: {
            color: curStyle.xAxisLineColor,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        max: yMax,
        name: (yName && yUnit) ? `${yName} \n (${yUnit})` : `${yName}${yUnit}`,
        nameTextStyle: {
          color: curStyle.yColor,
          fontSize: curFont.yFontSize,
        },
        axisLabel: {
          color: curStyle.yColor,
          fontSize: curFont.yFontSize,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: curStyle.yAxisLineColor,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dotted',
            color: curStyle.splitLineColor,
          },
        },
      },
      series,
    };
    if (!yMax) {
      delete options.yAxis.max;
    }
    if (noYaxis) {
      options.yAxis.nameTextStyle.color = 'rgba(0, 0, 0, 0)';
      options.yAxis.axisLabel.color = 'rgba(0, 0, 0, 0)';
    }
    if (highlight.length === 1) {
      if (xAxis.findIndex(item => item === highlight[0]) === 0) {
        (options.yAxis.max as any) = function (val: any) {
          const newValue: number = getRoundingNumber(val.max * 1.3);
          return newValue;
        };
      }
    }
    setOption(options);
  }, [hanldeData, fontSize, label, legend, mode, title, chartType]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 2) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (hanldeData.xAxis.length === 0 || hanldeData.yAxis.length === 0) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return chartDesc;
    },
    getChartSize() {
      if (hanldeData.xAxis.length === 0 || hanldeData.yAxis.length === 0) {
        return {
          width: 0,
          height: 0,
        };
      }
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {/* {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..."
      spinning={chartLoading} /></div>)} */}
      {!chartLoading && (hanldeData.xAxis.length === 0 || hanldeData.yAxis.length === 0)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default BarChart;

BarChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
