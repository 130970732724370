import {
  ITreeMapConfig,
  ITreeMapVisualConfig, ITreeStyle,
} from '../interface/config';
import { IDataItem, IDataItemObject } from '../interface/data';
import { getLabel, omitUndefined } from './utils';
import { ISeriesGetter, ItemStyleOption } from '../interface/base';
import { extend, merge } from 'lodash';

interface INode {
  [index: string | symbol]: any;

  children?: INode[];
}

type IPicker = (node: INode, extra: { depth: number; parent: INode | null }) => INode;
type Iterator = (root: INode[], depth: number, parent: null | INode) => INode[];
type IteratorGenerator = (picker: IPicker) => Iterator;
type IItemStyle = ItemStyleOption;

/**
 * 递归
 */
const iteratorGenerator: IteratorGenerator = (picker) => {
  const iterator: Iterator = (forest, depth, parent) => {
    if (!Array.isArray(forest) || forest.length === 0) {
      return [];
    }
    return forest.map(node => {
      const { children } = node;
      let _children: typeof children = [];
      const _node = picker(node, { depth, parent });
      if (Array.isArray(children)) {
        _children = iterator(children, depth + 1, node);
      }
      return { ..._node, children: _children };
    });
  };

  return iterator;
};


type colorHandler = (item: IItemStyle | undefined, parent: IItemStyle | undefined) => IItemStyle | undefined;
const getParentColor: colorHandler = (itemStyle, parentItemStyle) => {
  if (!parentItemStyle) {
    return itemStyle;
  }
  if (!itemStyle) {
    return itemStyle;
  }
  return omitUndefined({
    ...itemStyle,
    color: itemStyle.color === 'parent' ? parentItemStyle.color : itemStyle.color,
    borderColor: itemStyle.borderColor === 'parent' ? parentItemStyle.borderColor : itemStyle.borderColor,
  });
};

/**
 * 获取单个节点的样式
 * @param config
 * @param parentStyle
 * @param commonStyle
 */
const getEachNode = (config: ITreeMapVisualConfig, {
  parentStyle,
  commonStyle,
}: { parentStyle: ITreeStyle, commonStyle: ITreeStyle, }) => {
  const {
    symbol,
    symbolSize,
    symbolOffset,
    symbolRotate,
    label,
    emphasis,
    itemStyle,
    lineStyle,
    upperLabel,
  } = config ?? {};

  return ({
    symbol,
    symbolSize,
    symbolOffset,
    symbolRotate,
    emphasis,
    upperLabel,
    label: getLabel(label),
    itemStyle: getParentColor({ ...(commonStyle?.itemStyle ?? {}), ...itemStyle }, parentStyle?.itemStyle),
    lineStyle: getParentColor({ ...(commonStyle?.lineStyle ?? {}), ...lineStyle }, parentStyle?.lineStyle),
  });
};

const getEachSeries = (config: ITreeMapVisualConfig) => {
  const {
    top,
    right,
    bottom,
    left,
    zoom,
    center,
    layout = 'radial',
    orient = 'LR',
    width,
    height,
    levels,
    roam,
    visualDimension,
    visualMin,
    visualMax,
    colorAlpha,
    colorMappingBy,
    visibleMin,
    childrenVisibleMin,
    upperLabel,
    emphasis,
    symbol,
    symbolSize,
    symbolOffset,
    symbolRotate,
    edgeShape,
    edgeForkPosition,
    initialTreeDepth = -1, // 展开层级
    label,
    itemStyle,
    lineStyle,
    leaves,
  } = config ?? {};

  return omitUndefined(merge(
    {},
    {
      top,
      right,
      bottom,
      left,
      layout,
      orient,
      zoom,
      roam: false,
      nodeClick: false,
      breadcrumb: { show: false },
      levels,
      visualDimension,
      visualMin,
      visualMax,
      colorAlpha,
      colorMappingBy,
      visibleMin,
      childrenVisibleMin,
      upperLabel,
      width,
      height,
      center,
      emphasis,
      symbol,
      symbolSize,
      symbolOffset,
      symbolRotate,
      initialTreeDepth,
      expandAndCollapse: false,
      nodeScaleRatio: 1,
      leaves,
      label,
      edgeShape,
      edgeForkPosition,
      itemStyle,
      lineStyle,
    }
  ));
};

export const getSeriesTreeMap: ISeriesGetter<ITreeMapVisualConfig> = (series, index, extra) => {
  const { material } = extra;
  const { visualConfig } = material.config as ITreeMapConfig;
  const { data = [] } = series;

  return omitUndefined({
    type: 'treemap',
    data,
    ...getEachSeries(visualConfig),
  });
};
