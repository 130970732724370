/**
 * 头部列表
 */
import { apiSlice } from '../slices/api';
// import * as messages from '../proto/dot_map_pb';
const HOMR_PAGE = '/homePage';

export const headerApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 专题列表
    getNavigationBarSpecialTopic: builder.query({
      query(arg) {
        return {
          url: `${HOMR_PAGE}/navigation_bar_special_topic`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 查询用户是否有B端产业通的权限
    findUserChanyeOsB: builder.query({
      query(arg) {
        return {
          url: '/c2b/auth_b_user',
          method: 'POST',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetNavigationBarSpecialTopicQuery,
  useLazyFindUserChanyeOsBQuery,
} = headerApi;
