import React, { useEffect, useState, useMemo, useRef } from 'react';
import { cloneDeep, difference } from 'lodash';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Form, Input, Radio, Checkbox, Select, TreeSelect, message as antdMessage } from 'antd';
import { allForm } from '../data';
import { useLazyPostIndustryTreeQuery } from '../../../../../../service/dataSearch';
import { useLazyGetFindCompanyListByLikeNameQuery, useLazySaveBasicTestQuery } from '../../../../../../service/tool';
import style from './index.module.less';

const { Option } = Select;

let timeout;

const Content = ({ handleFormChange, fromRef, fulfilleTheAll }) => {
  const history = useHistory();
  const { href } = window.location;
  const check = useRef(null);
  const routeParams = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);
  const [findCompanyListByLikeName] = useLazyGetFindCompanyListByLikeNameQuery();
  const [getIndustryTree] = useLazyPostIndustryTreeQuery();
  const [saveBasicTest] = useLazySaveBasicTestQuery();
  const [companyId, setCompanyId] = useState(routeParams.get('companyId'));
  const [checkboxValue, setCheckboxValue] = useState({});
  const formData = JSON.parse(localStorage.getItem('formData'));

  useEffect(() => {
    if (fromRef.current) {
      if (formData) {
        fromRef.current.setFieldsValue(formData);
        fulfilleTheAll(formData);
      } else {
        fromRef.current.setFieldsValue({
          basicInfo: {
            companyName: routeParams.get('name'),
          },
        });
      }
    }
  }, [fromRef.current, JSON.stringify(formData)]);
  const [selectList, setSelectList] = useState({});
  // 提交请求
  const saveBasicTestQuery = (params, noGo) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      saveBasicTest({ company_id: companyId, appraisal_content: params }).then(res => {
        if (res.isSuccess) {
          if (!noGo) {
            history.push(`/companyIntelligentEvaluation/evaluationResult?id=${res.data.test_record_id}&companyId=${companyId}`);
          } else {
            antdMessage.info('保存成功');
          }
        } else {
          antdMessage.error('评测失败请稍后重试');
        }
      });
    }, 200);
  };

  // 保存表单
  const preserve = () => {
    fromRef.current.validateFields().then(() => {
      const data = fromRef.current.getFieldsValue();
      saveBasicTestQuery(data, true);
    }).catch(() => {
      antdMessage.warning('请正确填写表单');
    });
  };
  const checkboxMutualExclusion = (value, keyList) => {
    const key = value.indexOf('无') !== -1 ? value.indexOf('无') : value.indexOf('其他');
    setCheckboxValue(params => (
      {
        ...params,
        [keyList[1]]: value,
      }
    ));
    if (key === -1) return;
    if (checkboxValue[keyList[1]]) {
      const thisTime = difference(value, checkboxValue[keyList[1]]);
      if (thisTime.includes('无') || thisTime.includes('其他')) {
        setCheckboxValue(params => ({
          ...params,
          [keyList[1]]: thisTime,
        }));
        const data = cloneDeep(fromRef.current.getFieldsValue());
        let newData = data;
        keyList.forEach((item, index) => {
          if (index === keyList.length - 1) {
            newData[item] = thisTime;
          }
          newData = newData[item];
        });
        fromRef.current.setFieldsValue(data);
        return;
      }
      if (value.includes('无') || value.includes('其他')) {
        setCheckboxValue(params => ({
          ...params,
          [keyList[1]]: value.filter(item => item !== '无' && item !== '其他'),
        }));
        const data = cloneDeep(fromRef.current.getFieldsValue());
        let newData = data;
        keyList.forEach((item, index) => {
          if (index === keyList.length - 1) {
            newData[item] = value.filter(it => it !== '无' && it !== '其他');
          }
          newData = newData[item];
        });
        fromRef.current.setFieldsValue(data);
      }
    }
  };
  const findCompanyList = (value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      findCompanyListByLikeName({ like_name: value }).then(res => {
        const { data } = res;
        setSelectList((params) => ({
          ...params,
          companyNameList: data,
        }));
      });
    }, 200);
  };
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getIndustryTree();
      setSelectList((params) => ({
        ...params,
        industryTree: data.children.map(item => ({ ...item, disabled: false })) || [],
      }));
    };
    fetchData();

    return () => {
      localStorage.removeItem('formData');
    };
  }, []);

  const allFormEl = {
    borderInput: ({ value, label, name, required, message, pattern, placeholder }) => (
      <div key={name} className={style.customFormItem} >
        <Form.Item
          label={label}
          name={[...value, name]}
          rules={[
            {
              required,
              message,
            },
            {
              pattern: pattern && pattern,
              message,
            },
          ]}
        >
          {/* <div key={label} className={style.borderInput}>
            <div className={style.input}> */}
          <Input style={{ width: 300 }} placeholder={placeholder} />
          {/* </div>
          </div> */}
        </Form.Item>
      </div>
    ),
    multipleChoice: ({ value, label, name, required, noBold, message, list }) => (
      <div key={name} className={classNames(style.multipleChoice, { [style.noBold]: noBold })} >
        <Form.Item
          label={label}
          name={[...value, name]}
          colon={false}
          labelAlign="left"
          rules={[
            {
              required,
              message,
            },
          ]}
        >
          {/* <div className={style.radio}> */}
          <Radio.Group>
            {
              list.map(item => <Radio key={item.value} value={item.value}>{item.title}</Radio>)
            }
          </Radio.Group>
          {/* </div> */}
        </Form.Item>
      </div >
    ),
    noBorderInput: ({ label, value, name, width, unit, required, pattern, message }) => (
      <div key={name} className={classNames(style.noBorderInput, style.borderBottom)}>
        <Form.Item
          label={label}
          name={[...value, name]}
          colon={false}
          rules={[
            {
              required,
              message,
            },
            {
              pattern: pattern && pattern,
              message,
            },
          ]}
        >
          {/* <div className={style.inputBox}>
            <div className={style.input}> */}
          <Input
            // bordered={false}
            suffix={unit}
            style={{
              height: 32,
              width: width || 126,
            }}
          />
          {/* </div> */}
          {/* <span>{unit}</span> */}
          {/* </div> */}
        </Form.Item>
      </div>
    ),
    multiSelect: ({ label, value, list, name, column, required, message }) => (
      <div key={name} className={column ? style.multiSelect : style.crosswise}>
        <Form.Item
          label={label}
          name={[...value, name]}
          colon={false}
          labelAlign="left"
          rules={[
            {
              required,
              message,
            },
          ]}
        >
          <Checkbox.Group ref={check} options={list} onChange={(e) => { checkboxMutualExclusion(e, [...value, name]); }} />
        </Form.Item>
      </div>
    ),
    treeSelect: ({ label, value, name, required, pattern, message, list, placeholder }) => (
      <div key={name} className={style.treeSelect}>
        <Form.Item
          label={label}
          name={[...value, name]}
          colon={false}
          rules={[
            {
              required,
              message,
            },
            {
              pattern: pattern && pattern,
              message,
            },
          ]}
        >
          <TreeSelect
            style={{
              width: 200,
            }}
            placeholder={placeholder}
            treeData={selectList[list]}
          />
        </Form.Item>
      </div>
    ),
    select: ({ label, value, name, required, pattern, message, list, placeholder }) => (
      <div key={name} className={style.select}>
        <Form.Item
          label={label}
          name={[...value, name]}
          colon={false}
          rules={[
            {
              required,
              message,
            },
            {
              pattern: pattern && pattern,
              message,
            },
          ]}
        >
          <Select
            style={{
              width: 200,
            }}
            placeholder={placeholder}
            options={list}
          />
        </Form.Item>
      </div>
    ),
    associativeSearch: ({ label, value, name, required, pattern, message, list }) => (
      <div key={name} className={classNames(style.associativeSearch)}>
        <Form.Item
          label={label}
          name={[...value, name]}
          colon={false}
          rules={[
            {
              required,
              message,
            },
            {
              pattern: pattern && pattern,
              message,
            },
          ]}
        >
          <Select
            style={{
              width: 300,
            }}
            showSearch
            showArrow={false}
            filterOption={false}
            defaultActiveFirstOption={false}
            onSearch={findCompanyList}
            notFoundContent={null}
            onChange={(e) => {
              setCompanyId(e);
            }}
          >
            {
              selectList[list]?.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
            }
          </Select>
        </Form.Item>
      </div>
    ),
  };
  const basicInfoInput = ({ list, value }) => list.map(it => allFormEl[it.type]({ ...it, value }));
  return (
    <div className={style.box}>
      <Form
        ref={fromRef}
        onValuesChange={handleFormChange}
        name="all"
        initialValues={{}}
        onFinish={saveBasicTestQuery}
        onFinishFailed={(params => {
          // console.log(params);
          antdMessage.warning('请正确填写表单');
        })}
      >
        {
          allForm.map(item => (
            <div key={item.value} id={item.value} className={style.info}>
              <div className={style.title}>{item.title}</div>
              <div className={style.form}>
                {
                  item.level === 2 ? item.list.map(it => (
                    <div key={it.title}>
                      {
                        it.disabled ? basicInfoInput({ ...it, value: [item.value] }) : (
                          <div style={{ marginLeft: '8px' }}>
                            <div className={style.subhead}>{it.title}</div>
                            {basicInfoInput({ ...it, value: [item.value, it.value] })}
                          </div>
                        )
                      }
                    </div>
                  )) : basicInfoInput({ ...item, value: [item.value] })
                }
              </div>
              {
                item.border ? <div className={style.line} /> : <div className={style.interval}><div /></div>

              }
            </div>
          ))
        }
        <Form.Item className={style.submit}>
          <Button type="primary" className={style.add} onClick={preserve}>保存</Button>
          <Button type="primary" htmlType="submit">查看测评报告</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Content;
