/*
* Author: zhangmeng
* Description: '标签组件'
* Date: 2022/06/29
*/

import React, { useCallback, useState } from 'react';
import { tagStyleMap } from '../../../../utils/tagColor';
import style from './index.module.less';

function TabsComp(props) {
  const { labels } = props;
  const [arr, setArr] = useState(null);

  const labelsBox = useCallback(node => {
    if (node && node.scrollHeight) {
      setArr(node.scrollHeight);
    }
  }, [labels]);

  return (
    <div className={style.box}>
      <div
        className={style.tabs}
        ref={labelsBox}
        style={{
          overflow: arr > 28 ? 'hidden' : 'hidden',
          height: arr > 28 ? ' 28px' : '28px',
        }}
      >
        {
          labels && labels.length > 0 && labels.map((item, index) => (
            item.tabs.map((item1, index1) => (
              <div
                key={`${item1}${index1}${index}}`}
                className={`${style.tab} ${tagStyleMap[item.type]}`}
              >
                {item1}
              </div>
            ))
          ))
        }
      </div>
      <div className={style.omit} style={{ display: arr > 28 ? '' : 'none' }}>...</div>
    </div>
  );
}

export default TabsComp;
