import React, { useState } from 'react';
import { Spin } from 'antd';
import { debounce } from 'lodash';
import classnames from 'classnames';
// import Button from '../Button';
import Subtitle from './components/Subtitle';
import Underline from './components/Underline';
import DrillableHeatmap from './components/DrillableHeatmap';
import DistributionRanking from './components/DistributionRanking';
import SectorCompanyTable from './components/SectorCompanyTable';
import { StandardModal } from './components/Modal';
import { openNewPage } from '../../../../utils/urlMethods';
import styles from './index2.module.less';
import {
  useGetChannelIndustryInfoQuery,
  useGetChannelIndustryLinkQuery,
  useGetChannelLinkCompanyListQuery,
} from '../../../../service/industry-channel';

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

function GraphScheme({
  industryCode = 'all',
  cityCode,
  cityName,
}) {
  // // 产业信息
  const { data: industryInfo } = useGetChannelIndustryInfoQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });
  const { name: industryName } = industryInfo ?? {};
  // —————— 产业分布
  const [isMapFetching, setIsMapFetching] = useState(false);
  const [mapListQueryState, setMapListQueryState] = useState({
    pageSize: 20,
    pageNumber: 1,
    sortedInfo: {},
  });
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState({
    visible: false,
    regionCode: '',
    regionName: '',
    type: '',
    filteredInfo: {},
  });
  // head_company_count
  // console.log('industryCode', industryCode);
  // console.log('regionCode', regionCode);
  // const industryCode = 'INB1323';
  // const regionCode = '370200';
  return (
    <>
      <section className={styles.graphScheme}>
        {/* ———————— 产业分布，热力图结合右侧表格的组合 ———————— */}
        <div className={classnames(styles.subScheme, styles.mapScheme)} data-id="distribution">
          <div className={styles.content}>
            <div
              className={classnames(
                styles.map,
                { 'grey-out': isMapFetching },
              )}
            >
              <DrillableHeatmap
                // industryCode={industryCode}
                regionCode={cityCode}
                onRequestMapStart={() => {
                  setIsMapFetching(true);
                }}
                onRequestMapComplete={() => {
                  setIsMapFetching(false);
                }}
                onRequestMapError={() => {
                  setIsMapFetching(false);
                }}
              />
              {/* {
                regionPath.length > 1 && (
                  <Button
                    className={styles.returnButton}
                    onClick={() => {
                      setRegionPath(prev => prev.slice(0, prev.length - 1));
                    }}
                    text="返回"
                  />
                )
              } */}
              {
                isMapFetching && <Spin className={styles.spin} delay={200} size="large" />
              }
            </div>
            <div className={styles.table}>
              <Underline />
              <DistributionRanking
                industryCode={industryCode}
                regionCode={cityCode}
                pageSize={mapListQueryState.pageSize}
                pageNumber={mapListQueryState.pageNumber}
                sortedInfo={mapListQueryState.sortedInfo}
                onChange={({ current }, filter, sorter) => {
                  setMapListQueryState(prev => ({
                    ...prev,
                    pageNumber: current,
                    sortedInfo: sorter,
                  }));
                }}
                onClick={({ record, column }) => {
                  // TODO: 添加一个 event，获取当前点击的位置、计算浮窗出现的位置（有时间再做）
                  const { area, area_key: _regionCode } = record;
                  const { dataIndex } = column;
                  setMapCompanyListQueryState(prev => ({
                    ...prev,
                    regionCode: _regionCode,
                    regionName: area,
                    // industryName,
                    filteredInfo: {},
                    type: [dataIndex],
                    visible: true,
                  }));
                }}
              />
            </div>
            {
              mapCompanyListQueryState.visible && (
                <StandardModal
                  header={
                    <>
                      <span style={{ color: '#0f2849', fontWeight: 'bold' }}>
                        {mapCompanyListQueryState.regionName}
                      </span>
                      {/* <Subtitle
                        className={styles.subtitle}
                        description={`${industryName}企业列表`}
                      /> */}
                    </>
                  }
                  onClose={() => {
                    setMapCompanyListQueryState(prev => ({
                      ...prev,
                      pageNumber: 1,
                      visible: false,
                    }));
                  }}
                >
                  <SectorCompanyTable
                    className={styles.modalTable}
                    industryCode={industryCode}
                    regionCode={mapCompanyListQueryState.regionCode}
                    pageSize={mapCompanyListQueryState.pageSize}
                    pageNumber={mapCompanyListQueryState.pageNumber}
                    companyTypes={mapCompanyListQueryState.type}
                    onChange={({ current, pageSize }, filter) => {
                      setMapCompanyListQueryState({
                        ...mapCompanyListQueryState,
                        pageSize,
                        pageNumber: current,
                        filteredInfo: filter,
                      });
                    }}
                    filteredInfo={mapCompanyListQueryState.filteredInfo}
                    onCompanyClick={({ id: _id }) => {
                      if (!_id) return;
                      openNewPage('companyDetail', { id: _id });
                    }}
                  />
                </StandardModal>
              )
            }
          </div>
        </div>
      </section>
    </>
  );
}

export default GraphScheme;
