import { openNewPage } from '../../../../utils/urlMethods';

const pushToCompany = (id) => {
  openNewPage('companyDetail', { id });
};

export const patentColumns = [
  {
    title: '排行',
    dataIndex: 'index',
    key: 'index',
    width: 60,
  },
  {
    title: '企业名称',
    dataIndex: 'name',
    ellipsis: true,
    key: 'name',
    render: (text, record) => (
      <div
        title={text}
        style={{ cursor: 'pointer' }}
        onClick={() => pushToCompany(record._id)}
      >
        {text}
      </div>
    ),
  },
  {
    title: '专利数量',
    dataIndex: 'approval_invent_patent_count',
    key: 'approval_invent_patent_count',
    ellipsis: true,
    width: 100,
  },
];

export const potentialColumns = [
  {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 60,
    render: (text, record) => (
      <div
        title={text}
        style={{ cursor: 'pointer' }}
        onClick={() => pushToCompany(record._id)}
      >
        {text}
      </div>
    ),
  },
  {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (text, record) => (
      <div
        title={text}
        style={{ cursor: 'pointer' }}
        onClick={() => pushToCompany(record._id)}
      >
        {text}
      </div>
    ),
  },
  {
    title: '可达级别',
    dataIndex: 'level',
    key: 'level',
    ellipsis: true,
    width: 100,
  },
];
export const sqColumns = [
  {
    title: '排名',
    dataIndex: 'index',
    key: 'index',
    width: 60,
    render: (text, record) => (
      <div
        title={text}
        style={{ cursor: 'pointer' }}
        onClick={() => pushToCompany(record._id)}
      >
        {text}
      </div>
    ),
  },
  {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (text, record) => (
      <div
        title={text}
        style={{ cursor: 'pointer' }}
        onClick={() => pushToCompany(record._id)}
      >
        {text}
      </div>
    ),
  },
  {
    title: '上奇评分',
    dataIndex: 'level',
    key: 'level',
    ellipsis: true,
    width: 100,
  },
];
