import React, { useMemo } from 'react';
import classNames from 'classnames';
import { message } from 'antd';
// import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import style from './index.module.less';
import { useGetCompanyToolListQuery } from '../../../../service/companyDetail';
import { useLazyRecordingSpotQuery } from '../../../../service/public';

function ToolButton({ id, name }) {
  const history = useHistory();
  const [recordingSpotQuery] = useLazyRecordingSpotQuery();
  const params = new URLSearchParams();
  params.set('id', id);
  params.set('name', name);
  const TOOL = [
    {
      name: '同行对标',
      key: 'peer_comparison',
      desc: '企业对比对标',
      icon: 'contrastBenchmarking',
      onClick: () => {
        history.push({
          pathname: '/tool/firmComparison',
          search: params.toString(),
        });
        recordingSpotQuery({
          spot_name: '企业详情-工具-同行对标',
        });
      },
    },
    {
      name: '股东溯源',
      key: 'shareholder_traceability',
      desc: '央企血缘关系图',
      icon: 'shareholderTraceability',
    },
    {
      name: '供应关系',
      key: 'supply_relationship',
      icon: 'supplyRelations',
    },
    {
      name: '政策速配',
      key: 'policy_match',
      icon: 'policySpeedMatching',
    },
    {
      name: '高企自测',
      key: 'HighEvaluation',
      icon: 'HighEvaluation',
      onClick: () => {
        history.push({
          pathname: '/tool/companyTest',
          search: params.toString(),
        });
        recordingSpotQuery({
          spot_name: '企业详情-工具-高企自测',
        });
      },
    },
    {
      name: '锚点拓客',
      key: 'anchor_point_extension',
      icon: 'anchorPointExtension',
      onClick: () => {
        history.push({
          pathname: '/tool/anchorExpande',
          search: params.toString(),
        });
        recordingSpotQuery({
          spot_name: '企业详情-工具-锚点拓客',
        });
      },
    },
  ];

  const { data: _toolList } = useGetCompanyToolListQuery();
  const toolList = useMemo(() => {
    if (_toolList) {
      const tool = TOOL.map(item => ({
        ...item,
        value: _toolList.filter(list => list.key === item.key)?.[0]?.value ?? 0,
      }));
      return tool;
    }
    return [];
  }, [_toolList]);

  return (
    <div className={style.all}>
      <div className={style.content}>
        {/* <LeftCircleOutlined style={{ fontSize: '20px', color: '#1961F5' }} /> */}
        <div className={style.toolBox}>
          <div className={style.toolList}>
            {
              toolList.map(item => (
                <div
                  key={item.key}
                  style={{ display: !item.value && 'none' }}
                  className={classNames(style.btn, { [style.disableBtn]: item.value === '2' })}
                  onClick={() => {
                    if (item.value === '2') return;
                    if (!item.onClick) {
                      message.info('暂未上线，敬请期待');
                      return;
                    }
                    item.onClick();
                  }}
                >
                  <div className={style.left}>
                    <div className={classNames(style.icon, style[item.icon], { [style.clickable]: item.value === '1' })} />
                  </div>
                  <div className={style.right}>
                    <div className={style.top}>{item.name}</div>
                    {
                      item.desc && <div className={style.bottom}>{item.desc}</div>
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        {/* <RightCircleOutlined style={{ fontSize: '20px', color: '#1961F5' }} /> */}
      </div>
    </div>
  );
}

export default ToolButton;
