/*
 *Description: 矩形树图业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { RectangleChartProps } from '../../BasicsCompoment/basicsType';
import RectangleChart from '../../BasicsCompoment/RectangleChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessRectangleChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessRectangleChart(props: BusinessRectangleChartProps) {
  const {
    mode, label, ip, conditions,
    onRequestComplete, title, componmentKey,
    imgRef, upperLabel,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  const [data, setData] = useState<RectangleChartProps['data']>([]);
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data;
          setData(Array.isArray(resData.data) ? resData.data : []);
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
          onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark} style={{ overflowY: 'hidden' }}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <RectangleChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            label,
            ref: imgRef,
            roam: false,
            breadcrumb: false,
            nodeClick: false,
            chartDesc,
            upperLabel,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}

BusinessRectangleChart.defaultProps = {
  mode: 'light',
  fontSize: 'middle',
  label: true,
  title: true,
};

// 头部企业数量
export function HeadEnterprisesCount(props: BusinessRectangleChartProps) {
  const componmentKey = {
    function_key: 'rectangular_head_tree_graph_fenxi',
    class_name: '/chart/company',
  };

  return (
    <BusinessRectangleChart
      {
        ...props
      }
      componmentKey={componmentKey}
    />);
}
// 高技术企业数量
export function HighTechEnterprise(props: BusinessRectangleChartProps) {
  const componmentKey = {
    function_key: 'rectangular_tree_graph_new_technology_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 企业产业分布 rectangular_tree_graph_com_industry_fenxi
export function EnterpriseAndindustryDistribute(props: BusinessRectangleChartProps) {
  const componmentKey = {
    function_key: 'rectangular_tree_graph_com_industry_fenxi',
    class_name: '/chart/company',
  };
  return (
    <BusinessRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}
// 授权发明专利产业分布
export function AuthorizedAndInventionDistribute(props: BusinessRectangleChartProps) {
  const componmentKey = {
    function_key: 'rectangular_tree_graph_patent_industry_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

//  . 137--新增授权发明专利产业分布
export function PatentChartnewRectangularTreeGraphPatentIndustryFenxi(
  props: BusinessRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'new_rectangular_tree_graph_patent_industry_fenxi',
    class_name: '/chart/patent',
  };
  return (
    <BusinessRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}

// 15 . --相关产业标准类型分布
export function StandardChartcompanyStandardsCountFenxi(
  props: BusinessRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'company_standards_count_fenxi',
    class_name: '/chart/standard',
  };
  return (
    <BusinessRectangleChart
      {...props}
      componmentKey={componmentKey}
      upperLabel
    />);
}

// 国家级专精特新企业授权发明专利产业分布
export function PatentChartDistributionOfPatentIndustryTreeSpecialization(
  props: BusinessRectangleChartProps,
) {
  const componmentKey = {
    function_key: 'distribution_of_patent_industry_tree_specialization',
    class_name: '/chart/patent',
  };
  return (
    <BusinessRectangleChart
      {...props}
      componmentKey={componmentKey}
    />);
}
