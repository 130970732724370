/*
 *Author: zhaochenyu
 *Description: 用户关注树-可编辑
 *Date: 2021/07/29
*/

import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { Modal, Input } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { IconFont } from '../../../utils/mapIcon';
import warningIcon from '../../../assets/images/myself/waringicon.png';
import style from './index.module.less';

function FollowTreeNode(props) {
  const { parentKey, nodeData, selectNode, setSelectNode,
    index, data, changeData, handleWithTreeNode, setSelectTrunkNode } = props;
  const [showMore, setShowMore] = useState(false);
  const [editCard, setEditCard] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const timer = useRef(null);
  const timer1 = useRef(null);
  const targetRef = useRef(null);
  const inputRef = useRef(null);

  // 鼠标移入显示更多操作
  const getMoreHandle = useCallback(
    () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
      timer.current = setTimeout(() => {
        setShowMore(true);
      }, 500);
    },
    [],
  );

  // 鼠标移出隐藏
  const hideMoreHandle = useCallback(
    () => {
      clearTimeout(timer.current);
      setShowMore(false);
      setEditCard(false);
    },
    [],
  );

  // 获取当前最新数组数据
  const getNewMenuData = (inputData) => {
    if (repeat) return;
    const newData = cloneDeep(data);
    // 通过key判断是新增节点还是修改节点名称
    if (nodeData.key && inputData) {
      // 修改节点且名称不为空
      handleWithTreeNode('edit', {
        attention_group_id: nodeData.key,
        group_name: inputData,
      });
    } else if (nodeData.key && !inputData) {
      // 修改节点名称为空
      newData.forEach(item => {
        if (item.key === parentKey) {
          for (let i = 0; i < item.children.length; i += 1) {
            if (item.children[i].key === nodeData.key) {
              // eslint-disable-next-line no-param-reassign
              delete item.children[i].isInEdit;
              break;
            }
          }
        }
      });
      changeData(newData);
    } else if (!nodeData.key && inputData) {
      // 新增节点且内容不为空
      handleWithTreeNode('add', {
        attention_data_type_name: parentKey,
        group_name: inputData,
      });
    } else if (!nodeData.key && !inputData) {
      // 新增节点且名称为空
      for (let i = 0; i < newData.length; i += 1) {
        if (newData[i].key === parentKey) {
          newData[i].children.pop();
          break;
        }
      }
      changeData(newData);
    }
  };

  // 重命名
  const rename = (e) => {
    e.stopPropagation();
    const newData = cloneDeep(data);
    newData.forEach(item => {
      if (item.key === parentKey) {
        for (let i = 0; i < item.children.length; i += 1) {
          if (item.children[i].key === nodeData.key) {
            // eslint-disable-next-line no-param-reassign
            item.children[i].isInEdit = true;
            break;
          }
        }
      }
    });
    changeData(newData);
    setEditCard(false);
  };

  // 重命名后回车
  const editOver = (e) => {
    getNewMenuData(e.target.value.trim());
  };

  // 删除分组
  const deleteGroup = () => {
    handleWithTreeNode('delete', {
      attention_group_id: nodeData.key,
    }, selectNode);
  };

  // 修改模式焦点集中input
  useEffect(() => {
    if (nodeData.isInEdit) {
      inputRef.current.focus();
    }
  }, [nodeData]);

  // 点击外部区域等同于修改完成
  const handleWidthInput = (e) => {
    let { parentNode } = e.target;
    while (parentNode !== null) {
      if (parentNode === targetRef.current) {
        break;
      }
      parentNode = parentNode.parentNode;
    }
    if (parentNode) {
      return;
    }
    getNewMenuData(inputRef.current.state.value.trim());
  };

  const onBlurInput = (e) => {
    if (inputRef.current) {
      if (timer1.current) {
        clearTimeout(timer1.current);
        timer1.current = null;
      }
      timer1.current = setTimeout(() => {
        handleWidthInput(e);
      }, 50);
    }
  };

  const inspectRepeat = (e) => {
    // 判断当前修改后的分组名称是否存在
    if (e.target.value.trim()) {
      let exist = '';
      data.forEach(item => {
        if (item.key === parentKey) {
          exist = item.children.find(item1 => item1.title === e.target.value.trim());
        }
      });
      setRepeat(!!exist);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onBlurInput);
    return () => {
      document.removeEventListener('click', onBlurInput);
    };
  }, [data, repeat]);

  useEffect(() => () => {
    clearTimeout(timer.current);
    clearTimeout(timer1.current);
  }, []);

  return (
    <div
      className={selectNode === nodeData.key ? style.selectTreeNode : style.treeNode}
      ref={targetRef}
      onFocus={() => {}}
      onMouseOver={() => getMoreHandle()}
      onMouseLeave={() => hideMoreHandle()}
      key={`${nodeData.key}${index}`}
    >
      {nodeData.isInEdit ? (
        <div className={`${style.renameInput} ${repeat && style.repeatName}`}>
          <Input
            ref={inputRef}
            onPressEnter={editOver}
            placeholder="输入分组名称"
            defaultValue={nodeData.title || ''}
            onChange={inspectRepeat}
          />
          <div className={style.errorCard} style={{ display: repeat ? '' : 'none' }}>
            <img alt="" src={warningIcon} className={style.icon} />
            <div className={style.word}>
              此命名与现有分组命名有重复，请调整修改。
            </div>
          </div>
        </div>
      ) : (
        <div
          title={nodeData.title}
          className={style.nodeWord}
          onClick={() => {
            setSelectTrunkNode(parentKey);
            setSelectNode(nodeData.key);
          }}
        >
          {nodeData.title}
        </div>
      )}
      {/* <IconFont
        className={style.moreEdit}
        type="iconmenu2"
        onClick={() => setEditCard(!editCard)}
        style={{ visibility: showMore && !nodeData.isInEdit && nodeData.isEdit ? 'visible' : 'hidden' }}
      /> */}
      <div
        className={style.moreEdit}
        onClick={(e) => {
          e.stopPropagation();
          setEditCard(!editCard);
        }}
        style={{ visibility: showMore && !nodeData.isInEdit && nodeData.isEdit ? 'visible' : 'hidden' }}
      />
      <div
        className={style.editCard}
        style={{ display: editCard ? '' : 'none' }}
      >
        <div className={style.editBtn} onClick={(e) => rename(e)}>
          重命名
        </div>
        <div className={style.editBtn} onClick={() => setModalShow(true)}>
          删除该分组
        </div>
      </div>
      <Modal
        visible={modalShow}
        footer={null}
        maskClosable={false}
        wrapClassName={style.delModal}
        onCancel={() => setModalShow(false)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconFont type="iconfrown-fill" style={{ fontSize: '35px', color: '#E0C7C7' }} />
          <div className={style.word}>
            确定要删除该分组吗？
            <br />
            此次操作不可撤销，请慎重考虑
          </div>
        </div>
        <div className={style.btns}>
          <div className={style.btn} onClick={() => deleteGroup()}>确认</div>
          <div className={style.btn} onClick={() => setModalShow(false)}>取消</div>
        </div>
      </Modal>
    </div>
  );
}

function FollowEditTree(props) {
  const { data, setData, handleWithTreeNode,
    selectTreeNode, setSelectTreeNode, setSelectTrunkNode } = props;
  const [openNode, setOpenNode] = useState([]);

  // 点击树枝节点
  const handleWithClickTrunk = useCallback((cur, all) => {
    let res;
    if (all.includes(cur)) {
      res = all.filter(item => item !== cur);
    } else {
      res = [...all, cur];
    }
    setOpenNode(res);
  }, []);

  useEffect(() => {
    // 初始默认选中
    if (data.length) {
      setOpenNode([data[0].key]);
      setSelectTrunkNode(data[0].key);
      setSelectTreeNode(data[0].children[0].key);
    }
  }, []);

  // 新增节点自带名称  新分组x
  const addNewNode = (addKey) => {
    const newData = cloneDeep(data);
    for (let i = 0; i < newData.length; i += 1) {
      if (newData[i].key === addKey) {
        let title = '新分组';
        let j = 0;
        let exist = newData[i].children.find(item => item.title === title);
        while (exist) {
          j += 1;
          title = `新分组${j}`;
          // eslint-disable-next-line no-loop-func
          exist = newData[i].children.find(item => item.title === title);
        }
        newData[i].children.push({
          title,
          isInEdit: true,
          isEdit: true,
        });
        break;
      }
    }
    setData(newData);
  };
  return (
    <div className={style.all}>
      {data.map((item, index) => (
        <Fragment key={`${index}${item.key}`}>
          <div
            className={style.treeTrunk}
          >
            <div
              onClick={() => handleWithClickTrunk(item.key, openNode)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <IconFont
                type={openNode.includes(item.key) ? 'icondown-circle-fill' : 'iconright-circle'}
                style={{ marginRight: '8px' }}
              />
              <div title={item.title} className={style.trunkWord}>{item.title}</div>
            </div>
            <IconFont
              type="iconplus-circle"
              className={style.addNodes}
              onClick={() => addNewNode(item.key)}
              style={{ visibility: openNode.includes(item.key) ? 'visible' : 'hidden' }}
            />
          </div>
          <div
            className={openNode.includes(item.key) ? style.treeNodeBody : style.hiddenBody}
          >
            {item.children.map((item1, index1) => (
              <FollowTreeNode
                parentKey={item.key}
                nodeData={item1}
                index={index1}
                key={`${item1.key}${index1}`}
                selectNode={selectTreeNode}
                setSelectNode={setSelectTreeNode}
                setSelectTrunkNode={setSelectTrunkNode}
                data={data}
                changeData={setData}
                handleWithTreeNode={handleWithTreeNode}
              />
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
}

export default FollowEditTree;
