/*
* Author: zhangmeng
* Description: '企业详情企业相关图谱组件'
* Date: 2021/04/06
*/
// getQualityCertification
import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { TreeChart, RadialTreeChart } from '@shangqi/cube-component';
import { getCaptialStruture, getParenterShip, getSupplyRelation,
  getGuardianship, getQualityCertification, getCreativePlatform,
  getCoreTechnology, getDevelopmentHistory, getCMAFData } from '../../../api/companyDetail';
import MindMap from '../../IndustryCompanyDetail/CompanyContent/MindMap';
import TreeRadialChart2 from '../../IndustryCompanyDetail/CompanyContent/TreeRadialChart2';
import D3ThroughChart from '../../Model/D3ThroughChart';
import GraphForceOriented1 from '../../IndustryCompanyDetail/CompanyContent/GraphForceOriented1';
import CertificationChart from '../../IndustryCompanyDetail/CompanyContent/CertificationChart';
// import TreeRadialChart from '../IndustryCompanyDetail/CompanyContent/TreeRadialChart';
import TimeLine from '../../IndustryCompanyDetail/CompanyContent/TimeLine';
import { gridOption, nodeStyle, extraStyleHanlder, symbolSize } from '../../IndustryCompanyDetail/CompanyContent/RelationshipMap/CMAF';
import style from './index.module.less';

function BtnChartComp(props) {
  const { type, companyId, setChartDesc, companyName } = props;
  const [data, setData] = useState({});
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(companyName);
    switch (type) {
      case '供应关系':
        setLoading(true);
        getSupplyRelation({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data.data || {});
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '伙伴关系':
        setLoading(true);
        getParenterShip({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data.data ? [res.data.data] : []);
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '股权结构':
        setLoading(true);
        getCaptialStruture({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data || {});
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '法人关系':
        setLoading(true);
        getGuardianship({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data.data);
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '资质认定':
        setLoading(true);
        getQualityCertification({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data.data);
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '资质穿透':
        setLoading(true);
        getCMAFData({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data.data);
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '创新平台':
        setLoading(true);
        getCreativePlatform({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data.data);
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '核心技术':
        setLoading(true);
        getCoreTechnology({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data.data);
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      case '发展历程':
        setLoading(true);
        getDevelopmentHistory({
          company_id: companyId,
          company_name: companyName,
        }).then(res => {
          if (res.status === '00000') {
            setData(res.data);
            if (setChartDesc) {
              setChartDesc(res.data.desc || '');
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
        break;
      default:
        break;
    }
  }, []);

  const compSelect = (chartType) => {
    let comp = null;
    switch (chartType) {
      case '供应关系':
        comp = Object.keys(data).length > 0 ? <MindMap chartData={data} /> : <div>暂无数据</div>;
        break;
      case '伙伴关系':
        comp = Object.keys(data).length > 0 ? <TreeRadialChart2 data={data} /> : <div>暂无数据</div>;
        break;
      case '股权结构':
        comp = Object.keys(data).length > 0
          ? <D3ThroughChart data={data} rootName={data.downward.name} />
          : <div>暂无数据</div>;
        break;
      case '法人关系':
        comp = Object.keys(data).length > 0 ? <GraphForceOriented1 data={data} /> : <div>暂无数据</div>;
        break;
      case '资质认定':
        comp = Object.keys(data).length > 0
          ? <TreeChart
              data={data}
              mode="light"
              fontSize="large"
              nodeStyle={nodeStyle}
              extraStyleHanlder={extraStyleHanlder}
              gridOption={gridOption}
              symbolSize={symbolSize}
          /> : <div>暂无数据</div>;
        break;
      case '资质穿透':
        comp = Object.keys(data).length > 0 ? <CertificationChart data={data} orient="LR" /> : <div>暂无数据</div>;
        break;
      case '创新平台':
        comp = Object.keys(data).length > 0 ? <CertificationChart data={data} orient="TB" /> : <div>暂无数据</div>;
        break;
      case '核心技术':
        comp = Object.keys(data).length > 0 ? <RadialTreeChart mode="light" fontSize="large" legend={false} roam data={data} /> : <div>暂无数据</div>;
        break;
      case '发展历程':
        comp = Object.keys(data).length > 0 ? <TimeLine data={data} /> : <div>暂无数据</div>;
        break;
      default:
        break;
    }
    return comp;
  };

  return (
    <div className={style.all}>
      <div className={style.pageHeader}>
        <span className={style.first}>{name || '公司详情'}</span>
        <span className={style.slash}>&nbsp;/&nbsp;</span>
        <span className={style.second}>{`${type}图谱`}</span>
      </div>
      <div className={style.chart}>
        {loading ? <Spin tip="搜索中，请稍候....." /> : compSelect(type)}
      </div>
    </div>
  );
}

export default BtnChartComp;
