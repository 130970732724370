import React, { ComponentType, useContext, useEffect, useMemo, useReducer } from 'react';
import ComponentRenderer from '../ComponentRenderer';
import { Provider, SchemeRenderContext } from '../../../context/scheme';
import {
  initialState, reducer, selectById, setMacro, upsertAllComponents, upsertAllMetadata,
} from '../../../reducer';
import { generateMetadata } from '../../../utils';

export interface createSchemeRendererOptions {
  renderer: ComponentType<any>;
}

export interface SchemeRendererProps {
  scheme?: any;
}

/**
 * 用法： createSchemeRenderer({ renderer: ComponentRenderer })
 * @param options
 */
function createSchemeRenderer(options: createSchemeRendererOptions) {
  const { renderer: Component } = options;
  /**
   * 组件渲染器封装（自动提取）
   * @param props
   * @constructor
   */
  function Renderer (props: { id: string; [key: string]: any }) {
    const { id } = props;
    const [state] = useContext(SchemeRenderContext);
    const { component, metadata } = selectById(state, id);
    const { macro } = state;
    if (!component) {
      return null;
    }

    const _props = {
      properties: component.properties,
      style: { ...(component.style ?? {}), ...(component.properties.style ?? {})},
      metadata,
      type: component.type,
      macro: macro,
    };

    return <Component {..._props} />
  }

  return function (props: SchemeRendererProps) {
    const { scheme } = props;
    const reducerArray = useReducer(reducer, initialState);
    const [state, dispatch] = reducerArray;

    useEffect(() => {
      dispatch(upsertAllComponents(scheme.components));
      const metas = generateMetadata(scheme.components);
      dispatch(upsertAllMetadata(metas));
      dispatch(setMacro(scheme.macro));
    }, [scheme]);

    const { components: { ids } } = state;

    return (
      <Provider value={reducerArray} >
        <div className="sqke-report">
          {
            useMemo(() => {
              return ids.map(id => (
                <Renderer
                  id={id as string}
                  key={id}
                />
              ));
            }, [ids])
          }
        </div>
      </Provider>
    );
  }
}

/**
 * 主题渲染器
 * @param props
 * @constructor
 */
const SchemeRenderer = createSchemeRenderer({ renderer: ComponentRenderer });

export default SchemeRenderer;

export {
  createSchemeRenderer,
};
