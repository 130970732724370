import { Tree } from 'antd';
import RankingOrder from '../../../../RankingOrder';
import { IconFont } from '../../../../../utils/mapIcon';
import { openNewPage } from '../../../../../utils/urlMethods';
import style from './index.module.less';

// const onCheck = (checkedKeys, info) => {
//   console.log('onCheck', checkedKeys, info);
// };

const popupColumns = ({
  data,
  list,
  industryList,
  aptitudes,
  setAptitudes,
  areaFilter,
  area,
  setSecondAreaKey,
  setCurDetail,
  setPage,
  curDetail,
  selectKeys,
  setSelectKeys,
}) => {
  if (!data) return [];

  const onSelect = (selectedKeys, info) => {
    setSecondAreaKey(...selectedKeys);
    setCurDetail(info.node.title);
    setPage(1);
  };
  const selectTreeKey = (selected) => {
    setSelectKeys(selected);
    setPage(1);
  };
  return data.map(item => {
    if (item.dataIndex === 'index') {
      return {
        ...item,
        key: item.dataIndex,
        width: 70,
      };
    }
    if (item.dataIndex === 'name') {
      return {
        ...item,
        key: item.dataIndex,
        render: (text, record) => (
          <div
            title={text}
            style={{ color: '#262D36', cursor: 'pointer' }}
            className={style.companyName}
            onClick={() => openNewPage('companyDetail', { id: record.id })}
          >
            {text}
          </div>
        ),
      };
    }
    if (item.dataIndex === 'special_tag') {
      return {
        ...item,
        key: item.dataIndex,
        ellipsis: true,
        filterDropdown: () => (
          <ul className={style.list}>
            {
              list?.map(it => <div key={it.value} className={it.value === aptitudes ? style.checked : ''} onClick={() => { setAptitudes(it.value); setPage(1); }}>{it.value}</div>)
            }
          </ul>
        ),
      };
    }
    if (item.dataIndex === 'in_area') {
      return {
        ...item,
        key: item.dataIndex,
        ellipsis: true,
        filterDropdown: () => (
          <div style={{ maxHeight: '300px', overflow: 'auto' }}>
            <Tree
              // checkable
              defaultExpandedKeys={[area]}
              onSelect={onSelect}
              // onCheck={onCheck}
              treeData={areaFilter}
            />
          </div>
        ),
      };
    }
    if (item.dataIndex === 'industry') {
      return {
        ...item,
        key: item.dataIndex,
        ellipsis: true,
        filterDropdown: () => (
          <div style={{ maxHeight: '300px', overflow: 'auto' }}>
            <Tree
              onCheck={selectTreeKey}
              checkedKeys={selectKeys}
              treeData={industryList}
              checkable
              defaultExpandedKeys={industryList.length > 0 ? [industryList[0].key] : []}
            />
          </div>
        ),
      };
    }
    return {
      ...item,
      ellipsis: true,
      key: item.dataIndex,
    };
  });
};
const THEAD_TABLE = {
  index: {
    title: '排行',
    dataIndex: 'index',
    key: 'index',
    width: 70,
    render: text => <RankingOrder order={text} />,
  },
  area: {
    title: '区域',
    dataIndex: 'area',
    key: 'area',
    width: 80,
    ellipsis: true,
  },
  head_quarters: {
    title: '总部',
    dataIndex: 'head_quarters',
    key: 'head_quarters',
    ellipsis: true,
    sorter: true,
    width: 80,
    sortDirections: ['descend'],
    render: (text, record) => (
      <div className={style.operation}>
        <span>{text}</span>
        <IconFont
          type="iconshujuchaxun-icon"
          className={style.iconNum}
          // onClick={() => {}}
        />
      </div>
    ),

  },
  first_office_count: {
    title: '一级子公司',
    dataIndex: 'first_office_count',
    key: 'first_office_count',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],

  },
  second_office_count: {
    title: '二级子公司',
    dataIndex: 'second_office_count',
    key: 'second_office_count',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],
  },
  third_office_count: {
    title: '三级及以下',
    dataIndex: 'third_office_count',
    key: 'third_office_count',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],

  },
  total_office_count: {
    title: '全部',
    dataIndex: 'total_office_count',
    key: 'total_office_count',
    ellipsis: true,
    sorter: true,
    sortDirections: ['descend'],

  },
};

export {
  popupColumns,
  THEAD_TABLE,
};
