/*
 *Author: zhaochenyu
 *Description: 专精特新地图表格
 *Date: 2022/05/11
*/

import React, { useState, useEffect, useMemo } from 'react';
import { Table, Tree, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { IconFont } from '../../../../utils/mapIcon';
import {
  useGetChannelInvestmentRoundListQuery,
} from '../../../../service/industry-channel';
import { getSelectArea } from '../../../../utils/tool';
import SpecialALertTable from '../SpecialALertTable';
import RankingOrder from '../../../RankingOrder';
import ExportModal from '../../../ExportModal';
import style from './index.module.less';

function MultiNodeTree(props) {
  const { data, getAreaCompnayCount, setIndustry } = props;
  const [selectKeys, setSelectKeys] = useState([]);

  const selectTreeKey = (selected) => {
    setIndustry(selected);
    setSelectKeys(selected);
    getAreaCompnayCount({
      industry_name_list: selected,
    });
  };

  useEffect(() => {
    setIndustry([]);
  }, [data]);
  return (
    <Tree
      onCheck={selectTreeKey}
      checkedKeys={selectKeys}
      treeData={data}
      checkable
      defaultExpandedKeys={data.length > 0 ? [data[0].key] : []}
    />
  );
}

function SpecialTable(props) {
  const { data, getAreaCompnayCount, areaCode, allAreaData, levelData,
    labelList, industryList, level, setLevel } = props;
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const { tableHead, tableBody } = data;
  const [cardShow, setCardShow] = useState(false);
  const [curDetail, setCurDetail] = useState(''); // 区域名称
  const [areaFilter, setAreaFilter] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [screen, setScreen] = useState('');
  // const [level, setLevel] = useState('');
  const [sortInfo, setSortInfo] = useState({
    order: 'descend',
    field: 'count',
  });
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [params, setParams] = useState(null);

  useEffect(() => {
    // 重置状态
    setCardShow(false);
    setSortInfo({
      order: 'descend',
      field: 'count',
    });
    setIndustry([]);
    setLevel('');
  }, [areaCode]);

  const tableChange = (_, filters, sorter) => {
    setSortInfo({
      ...sorter,
    });
    getAreaCompnayCount({
      sort_column: sorter.field || '',
    });
  };

  const { data: roundList } = useGetChannelInvestmentRoundListQuery();
  const getDetailData = (titleItem, rowData) => {
    // 查看其他列详情数据时，重置回默认条件
    setAreaFilter(getSelectArea(allAreaData, rowData.code || '') || []);
    setCurDetail(rowData);
    setCardShow(true);
    // getCompnayList();
  };
  const handleWithHead = (head) => (
    head.map(item => {
      let res;
      if (item.dataIndex === 'index') {
        res = {
          ...item,
          width: '10%',
          ellipsis: true,
          render: text => <RankingOrder order={text} />,
        };
      } else if (item.dataIndex === 'count') {
        res = {
          ...item,
          // ellipsis: true,
          width: '18%',
          sorter: true,
          sortDirections: ['descend'],
          sortOrder: sortInfo.field === item.dataIndex && sortInfo.order,
          render: (text, record) => (
            <div className={style.operation}>
              <span>{text}</span>
              <IconFont
                type="iconshujuchaxun-icon"
                className={style.iconNum}
                onClick={() => getDetailData(item, record)}
              />
            </div>
          ),
        };
      } else if (item.dataIndex === 'industry') {
        res = {
          ...item,
          // width: '12%',
          ellipsis: true,
          filteredValue: industry,
          filterDropdown: (
            <div className={style.linkNodeDropdown}>
              <MultiNodeTree
                data={industryList}
                getAreaCompnayCount={getAreaCompnayCount}
                setIndustry={setIndustry}
              />
            </div>
          ),
        };
      } else if (item.dataIndex === 'level') {
        res = {
          ...item,
          width: '20%',
          ellipsis: true,
          filteredValue: level,
          filterDropdown: () => (
            <div className={style.areaDropdown}>
              { levelData.length > 0 ? levelData.map((item2, index) => (
                <div
                  className={level === item2.key ? style.selectArea : style.areaItem}
                  key={`${index}relying_unit`}
                  onClick={() => {
                    setLevel(item2.key);
                    getAreaCompnayCount({
                      select_level: item2.key,
                    });
                  }}
                  title={item2.title}
                >
                  {item2.title}
                </div>
              )) : <div>暂无数据</div>}
            </div>
          ),
        };
      } else {
        res = {
          ...item,
          width: '16%',
          ellipsis: true,
        };
      }
      return res;
    })
  );

  const table = useMemo(() => (
    <SpecialALertTable
      areaFilter={areaFilter}
      detailMsg={curDetail}
      labelList={labelList}
      levelData={levelData}
      roundList={roundList}
      industryList={industryList}
      setParams={setParams}
      setScreen={setScreen}
    />
  ), [areaFilter, curDetail, labelList, levelData, industryList]);

  return (
    <div className={style.all}>
      <div className={style.line} />
      <Table
        rowKey="index"
        columns={handleWithHead(tableHead)}
        onChange={tableChange}
        dataSource={tableBody}
        pagination={false}
        className={style.table}
        scroll={{ y: 600 }}
      />
      {cardShow && (
      <div className={style.modalCard}>
        <div className={style.cardTitle}>
          <span style={{ fontWeight: 'blod', color: '#262D36', marginRight: '6px' }}>{curDetail.area}</span>
          {/* 企业列表 */}
          专精特新企业共计
          {curDetail?.count}
          家,搜索结果
          {screen}
          家。
        </div>
        {
          Array.isArray(enterpriseAccountExport)
          && enterpriseAccountExport?.length > 0
          && enterpriseAccountExport?.includes('exportSpecial')
          && (
            <Button className={style.exportButton} onClick={() => setExportModalVisible(true)}>数据导出</Button>
          )
        }
        <div className={style.close} onClick={() => setCardShow(false)}>
          <CloseOutlined />
        </div>
        <div className={style.tableBody}>
          {table}
        </div>
      </div>)}
      {
        exportModalVisible && (
          <div className={style.mask}>
            <ExportModal
              dataKey="newSpecial_company"
              params={params}
              onClose={() => {
                setExportModalVisible(false);
              }}
            />
          </div>
        )
      }
    </div>
  );
}

export default SpecialTable;
