/*
* Author: zhangmeng
* Description: '标准详情页'
* Date: 2021/05/07
*/

import React, { useState, useEffect, useMemo } from 'react';
import { IconFont, tabMap } from '../../../utils/mapIcon';
import DetailTop from './DetailTop';
import DetailMiddle from './DetailMiddle';
import DetailBottom from './DetailBottom';
import { getStandardDetail, getStandardCharts, getStandardRelation } from '../../../api/standardDetail';
import style from './index.module.less';

function StandardDetail(props) {
  const { detailParams } = props;
  const [topData, setTopData] = useState({});
  const [leftChart, setLeftChart] = useState({});
  const [rightChart, setRightChart] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [tableData, setTableData] = useState({});
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);

  const getTable = (p) => {
    getStandardRelation({
      standard_id: detailParams || params.get('id'),
      page_number: pageNum,
      page_size: 10,
      ...p,
    }).then(res => {
      if (res.status === '00000') {
        const { tableHead } = res.data;
        const newData = tableHead.map(item => {
          let obj = {};
          if (item.dataIndex === 'index') {
            obj = {
              width: '7%',
              ellipsis: true,
              ...item,
            };
          } else if (item.dataIndex === 'standard_name') {
            obj = {
              width: '25%',
              ellipsis: true,
              ...item,
            };
          } else if (item.dataIndex === 'draft_unit') {
            obj = {
              width: '30%',
              ellipsis: true,
              ...item,
            };
          } else if (item.dataIndex === 'industry') {
            obj = {
              width: '10%',
              ellipsis: true,
              ...item,
            };
          } else if (item.dataIndex === 'code') {
            obj = {
              width: '16%',
              ellipsis: true,
              ...item,
            };
          } else if (item.dataIndex === 'act_date') {
            obj = {
              width: '12%',
              ellipsis: true,
              ...item,
            };
          }
          return obj;
        });
        setTableData({
          ...res.data,
          tableHead: newData,
        });
      }
    });
  };

  useEffect(() => {
    getStandardDetail({
      standard_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setTopData(res.data);
      }
    });
    getStandardCharts({
      standard_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        const { source, evolution } = res.data;
        setLeftChart(source || {});
        setRightChart(evolution || {});
      }
    });
    getTable();
  }, []);

  return (
    <div className={style.all}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.title}>
            <span className={style.before}>
              产业通
            &emsp;
              <span className={style.point} />
            &emsp;
            </span>
            <span className={style.later}>标准</span>
          </div>
          <div className={style.imgBox}>
            <IconFont type={tabMap.standard} />
          </div>
        </div>
      </div>
      <DetailTop data={topData} />
      <DetailMiddle
        leftChart={leftChart}
        rightChart={rightChart}
        status={topData.standard_status || {}}
      />
      <DetailBottom
        data={tableData}
        pageNum={pageNum}
        getList={(p) => {
          setPageNum(p.page_number);
          getTable(p);
        }}
      />
    </div>
  );
}

export default StandardDetail;
