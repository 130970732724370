/*
 *Author: zhaochenyu
 *Description: 标准演进图
 *Date: 2021/05/06
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { standardColorMap } from '../../../utils/color';
import style from './index.module.less';

function EvolutionChart(props) {
  const { chartData, title } = props;
  const [option, setOption] = useState({});
  const [legendList, setLegendList] = useState([]);

  useEffect(() => {
    const { abscissa_list: xData, detail_list: yData } = chartData;
    const fuzhuList = [];
    const seriesData = [];
    const leList = yData.reduce((prev, cur) => (
      prev.includes(cur.type) ? prev : [...prev, cur.type]), []);
    setLegendList(leList);
    yData.forEach(item => {
      fuzhuList.push(xData.findIndex(item1 => item1 === item.start));
      seriesData.push({
        // name: item.target,
        value: xData.findIndex(item1 => item1 === item.end)
        - xData.findIndex(item1 => item1 === item.start),
        itemStyle: {
          color: standardColorMap[item.type],
        },
        label: {
          show: true,
          position: 'inside',
          color: '#262D36',
          formatter: () => (item.target.length > 7 ? `${item.target.slice(0, 7)}...` : item.target),
        },
        emphasis: {
          label: {
            show: true,
            position: 'inside',
            color: '#262D36',
            formatter: () => {
              let res = '';
              if (item.target.length > 14) {
                const copy = item.target.split('');
                while (copy.length > 14) {
                  res += `${copy.splice(0, 14).join('')}\n`;
                }
                res += copy.join('');
              } else {
                res = item.target;
              }
              return res;
            },
          },
        },
      });
    });
    const options = {
      grid: {
        bottom: 50,
      },
      yAxis: {
        type: 'category',
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#ebebeb',
          },
        },
        data: yData.map(item => item.target),
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: (xData.length) - 1,
        axisLabel: {
          color: '#919191',
          fontSize: 10,
          formatter: (param) => xData[param],
        },
        splitLine: {
          lineStyle: {
            color: '#ebebeb',
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          // show: false,
        },
      },
      series: [
        {
          name: '',
          type: 'bar',
          stack: '11',
          barWidth: 20,
          itemStyle: {
            barBorderColor: 'rgba(0,0,0,0)',
            color: 'transparent',
          },
          emphasis: {
            itemStyle: {
              barBorderColor: 'rgba(0,0,0,0)',
              color: 'transparent',
            },
          },
          data: fuzhuList,
        },
        {
          name: 'text',
          type: 'bar',
          stack: '11',
          data: seriesData,
        },
      ],
    };
    setOption(options);
  }, [chartData]);

  return (
    <div className={style.all}>
      <div className={style.title}>{title}</div>
      <ReactEcharts
        option={option}
        style={{ width: '100%', height: '100%' }}
      />
      <div className={style.legend}>
        {legendList.map((item, index) => (
          <div className={style.legendItem} key={`${index}legend`}>
            <span className={style.icon} style={{ background: standardColorMap[item] }} />
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default EvolutionChart;
