import React, { useState, useEffect } from 'react';
import style from './index.module.less';
import { FilterIcon } from '../../../../../../components/CustomTableIcon';
import CustomTreeFilter from '../../../../../../components/CustomTreeFilter';
import Score from '../../../../../../components/Score';
import { getNameConfig } from '../../../../../../utils/table';
import Subtitle from '../../../../../../components/Subtitle';
import Tabs from '../../components/Tabs';
import Table from '../../components/Table';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetIndustryTreeQuery,
} from '../../../../../../service/interactive-v2';
import { useListOfNurturingEnterprisesQuery, usePotentialNumberQuery } from '../../../../../../service/cityTool';
import { useGetData } from './useGetData';

const sortMap = {
  descend: 'desc',
  ascend: 'asc',
};
const titleMap = {
  专精特新: '专精特新潜力企业共计',
  上市公司: '上市潜力企业共计',
  高新技术: '高新技术潜力企业共计',
  潜在风险: '潜在风险企业共计',
};

const PotentialCompany = (props) => {
  const { title, area: { code }, isProvinceDirectly, onCompanyClick } = props;
  const [checkedTab, setCheckedTab] = useState({});
  const [location, setLocation] = useState([code]);
  const [industryCodeList, setIndustryCodeList] = useState(['all']);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortKey, setSortKey] = useState('');
  const [sortValue, setSortValue] = useState('descend');

  // 获取表格数据
  const { data, chartIsFetching } = useGetData({
    area_key: location[0],
    sort_key: sortKey,
    industry_code_list: industryCodeList,
    sort_value: sortValue && sortMap?.[sortValue],
    page_number: page,
  }, checkedTab.title);

  // 获取tabs
  const { data: tabsData } = useListOfNurturingEnterprisesQuery();
  // 获取总数
  const { data: count } = usePotentialNumberQuery(
    {
      area_key: code,
      label: checkedTab.title,
    }, { skip: !checkedTab.title && code },
  );

  // tabs 默认第一个
  useEffect(() => {
    if (tabsData) {
      setCheckedTab(tabsData[0]);
    }
  }, [tabsData]);

  // 筛选时重置页码
  useEffect(() => {
    setPage(1);
  }, [location, industryCodeList, code]);
  // 切换城市和table时重置请求条件
  useEffect(() => {
    setPage(1);
    setLocation([code]);
    setIndustryCodeList(['all']);
    setSortKey('');
    setSortValue('descend');
  }, [checkedTab.title, code]);

  const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query({
    area_code: code,
  });
  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data: TreeData }) => {
        if (!TreeData) return [];
        return {
          industryTree: [TreeData],
        };
      },
    },
  );
  const updateFiltrate = (columnKey, FiltrateData) => {
    const filtrateMap = {
      industry: () => { setIndustryCodeList(FiltrateData || ['all']); },
      location: () => { setLocation(FiltrateData || [code]); },
    };
    if (filtrateMap[columnKey]) {
      filtrateMap[columnKey]();
    }
  };
  const Tablehandle = (item) => ({
    index: {
      width: 50,
      className: style.index,
    },
    patent: {
      render: (text) => text || '-',
    },
    standard: {
      render: (text) => text || '-',
    },
    company_name: {
      width: 230,
      ellipsis: true,
      className: style.name,
      ...getNameConfig(({ onClick: onCompanyClick, keyword: 'city' })),
    },
    reg_capital_std: {
      width: 120,
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      sorter: true,
    },
    establish_date: {
      width: 100,
      ellipsis: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      className: style.number,
    },
    industry: {
      ellipsis: true,
      filterMode: 'tree',
      filterIcon: <FilterIcon />,
      filters: industryTree,
      filterDropdown: _props => <CustomTreeFilter {..._props} />,
      filteredValue: industryCodeList,
    },
    location: {
      ellipsis: true,
      filterMode: 'tree',
      filterIcon: <FilterIcon />,
      filters: regionTree,
      filterDropdown: _props => <CustomTreeFilter {..._props} />,
      filteredValue: location,
    },
    total_point: {
      width: 100,
      render: text => <Score score={text} />,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
  });
  return (
    <div className={style.box} id={title}>
      {/* <p className={style.title}>{ title }</p> */}
      <div className={style.tab}>
        <Tabs
          checkedTab={checkedTab}
          setCheckedTab={setCheckedTab}
          data={tabsData}
        />
      </div>
      <div className={style.tableTitle}>
        <Subtitle
          description={titleMap[checkedTab.title]}
          value={count ?? '-'}
          unit="家"
          className={style.subtitle}
        />
        <Subtitle
          description="筛选结果"
          value={data?.count ?? '-'}
          unit="家"
          className={style.subtitle}
        />
      </div>
      <Table
        {...props}
        sortKey={sortKey}
        sortValue={sortValue}
        Tablehandle={Tablehandle}
        data={data}
        isFetching={chartIsFetching}
        onChange={(_, filters, sorter) => {
          Object.keys(filters).forEach(item => {
            updateFiltrate(item, filters[item]);
          });
          setSortKey(sorter.field);
          setSortValue(sorter.order);
        }}
        pagination={{
          current: page,
          pageSize,
          showQuickJumper: true,
          showSizeChanger: false,
          total: data?.total,
          onChange: (current) => {
            setPage(current);
          },
          onShowSizeChange: (size) => {
            setPageSize(size);
          },
        }}
      />
    </div>
  );
};

export default PotentialCompany;
