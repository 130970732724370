import React, { useEffect, useState } from 'react';
import { cloneDeep, difference } from 'lodash';
import { Select, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CustomTreeSelect from '../../../../../../../components/CustomTreeSelect';
import CustomScreen from '../../../../../../../components/CustomScreen';
import Table from '../Table';
import { useGetAreaPlugMarqueeV2Query } from '../../../../../../../service/interactive-v2';
import { usePostIndustryTreeQuery } from '../../../../../../../service/dataSearch';
import { useGetParkChannelInvestmentRoundListQuery } from '../../../../../../../service/parkDetail';
import { useLazyConditionTestingQuery, useLazySaveReportQuery, useQualificationSelectQuery } from '../../../../../../../service/tool';
import { conditionTestingExport } from '../../../../../../../api/dataDownload';
import { resetTemplateReport } from '../../../../../../../api/reportGeneration';
import { openNewPage } from '../../../../../../../utils/urlMethods';
import style from './index.module.less';

const screenStyle = {
  width: 200,
  height: 32,
};

const sortMap = { ascend: 'asc', descend: 'desc' };

const ConditionEvaluation = () => {
  const [value, setValue] = useState('');
  const [sort, setSort] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableIsFetching, setTableIsFetching] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [area, setArea] = useState({ title: [], code: [] });
  const [industry, setIndustry] = useState({ title: [], code: [] });
  const [aptitudes, setAptitudes] = useState({ title: [], code: [] });
  const [financing, setFinancing] = useState({ title: [], code: [] });
  const [conditionTesting] = useLazyConditionTestingQuery();
  const [saveReportQuery] = useLazySaveReportQuery();
  const { data: areaData, isFetching: areaIsFetching } = useGetAreaPlugMarqueeV2Query({ enable: { country: '0', province: '0', city: '1', district: '0' } });
  const { data: industryData, isFetching: industryIsFetching } = usePostIndustryTreeQuery();
  const { data: aptitudesData, isFetching: aptitudesIsFetching } = useQualificationSelectQuery();
  const { data: financingData, isFetching: financingIsFetching } = useGetParkChannelInvestmentRoundListQuery();

  const downloadTable = () => {
    conditionTestingExport({
      keyword: value,
      industry_code_list: industry.code,
      region_code_list: area.code.map(item => item.split(',')[1]),
      special_tag_list: aptitudes.code,
      financing_round_list: financing.code,
      page_size: pageSize,
      page_number: pageNumber,
    }, '测评名单');
  };

  // 所有筛选条件
  const allConditionValue = () => [industry.code, area.code, aptitudes.code, financing.code].filter(Boolean).flat();

  const saveReport = async () => {
    const { data } = await saveReportQuery({
      keyword: value,
      industry_code_list: industry.code,
      region_code_list: area.code.map(item => item.split(',')[1]),
      special_tag_list: aptitudes.code,
      financing_round_list: financing.code,
    });
    const res = await resetTemplateReport({
      list_analysis: { query_record_id: data.record_id },
      template_id: '12116179',
      template_source: 2,
    });
    const msg = window.btoa(window.encodeURIComponent(JSON.stringify({
      curTemplateId: 12116179,
      title: '画像报告',
      id: res.data,
      // name: '百度在线网络技术（北京）有限公司',
    })));
    openNewPage('scheme', {
      msg,
    });
  };
  // 获取评测列表
  const getConditionTestingList = () => {
    conditionTesting({
      keyword: value,
      industry_code_list: industry.code,
      region_code_list: area.code.map(item => item.split(',')[1]),
      special_tag_list: aptitudes.code,
      financing_round_list: financing.code,
      sort_value: sort,
      page_size: pageSize,
      page_number: pageNumber,
    }).then(res => {
      setTableData(res.data);
      setTableIsFetching(false);
    });
  };

  useEffect(() => {
    setPageNumber(1);
  }, [sort]);

  useEffect(() => {
    setTableIsFetching(true);
    const timeout = setTimeout(() => {
      getConditionTestingList();
    }, 200);

    return () => { clearTimeout(timeout); };
  }, [pageSize, pageNumber, sort, value, JSON.stringify(allConditionValue())]);

  // table事件
  const tableEventHandle = (page, p, sortEvnt) => {
    setPageNumber(page.current);
    setPageSize(page.pageSize);
    setSort(sortMap[sortEvnt.order]);
  };

  // 筛选条件时
  const updateHandleSearch = (setData, code, title) => {
    const newTitle = title.map(item => {
      if (typeof item === 'object') {
        return item.title;
      }
      return item;
    });
    setData({ code, title: newTitle });
    setPageNumber(1);
  };

  // 改变value值用于搜索
  const updateEare = (data) => data.map(item => {
    if (item.children.length) {
      item.children = updateEare(item.children);
    }
    return {
      ...item,
      value: `${item.title},${item.value}`,
      key: `${item.title},${item.value}`,
    };
  });

  // 所有筛选条件可选值
  const allCondition = () => {
    const list = (data) => data.code.map((item, index) => ({ value: item, label: data.title[index] }));
    const areaList = list(area);
    const industryList = list(industry);
    const aptitudesList = list(aptitudes);
    const financingList = list(financing);
    return [areaList, industryList, aptitudesList, financingList].filter(Boolean).flat();
  };
  // 删除条件
  const deleteCondition = (params) => {
    setPageNumber(1);
    const all = allConditionValue();
    const delValue = difference(all, params)[0];
    const allConditionList = [
      { value: area, setValue: setArea },
      { value: industry, setValue: setIndustry },
      { value: aptitudes, setValue: setAptitudes },
      { value: financing, setValue: setFinancing },
    ];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of allConditionList) {
      if (item.value.code.includes(delValue)) {
        const delIndex = item.value.code.indexOf(delValue);
        const newTitle = item.value.title.filter((it, index) => index !== delIndex);
        const newCode = item.value.code.filter((it, index) => index !== delIndex);
        item.setValue({ title: newTitle, code: newCode });
        return;
      }
    }
  };

  return (
    <>
      <div className={style.top}>
        <div className={style.screen}>
          <div className={style.search}>
            <span>查询</span>
            <div>
              <Input
                value={value}
                style={{ width: 412, height: 32 }}
                placeholder="请输入您要查询的内容"
                suffix={<SearchOutlined style={{ color: '#999' }} />}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.bedingterFilter}>
            <span>筛选</span>
            <div className={style.selectBox}>
              <div className={style.selectTitle}>产业</div>
              <CustomTreeSelect
                style={screenStyle}
                value={industry.code}
                placeholder="产业"
                data={industryData && [industryData]}
                defaultNode={[industryData?.value]}
                onChange={(code, title) => { updateHandleSearch(setIndustry, code, title); }}
                isFetching={industryIsFetching}
              />
            </div>
            <div className={style.selectBox}>
              <div className={style.selectTitle}>区域</div>
              <CustomTreeSelect
                style={screenStyle}
                value={area.code}
                placeholder="区域"
                data={areaData && updateEare(cloneDeep(areaData))}
                defaultNode={['全国,000000']}
                onChange={(code, title) => { updateHandleSearch(setArea, code, title); }}
                isFetching={areaIsFetching}
              />
            </div>
            <div className={style.selectBox}>
              <div className={style.selectTitle}>资质</div>
              <CustomScreen
                style={screenStyle}
                value={aptitudes.code}
                placeholder="资质"
                data={aptitudesData?.map(item => ({ ...item, value: item.key }))}
                onChange={(code, title) => { updateHandleSearch(setAptitudes, code, title); }}
                isFetching={aptitudesIsFetching}
              />
            </div>
            <div className={style.selectBox}>
              <div className={style.selectTitle}>融资</div>
              <CustomScreen
                style={screenStyle}
                value={financing.code}
                placeholder="融资"
                data={financingData}
                onChange={(code, title) => { updateHandleSearch(setFinancing, code, title); }}
                isFetching={financingIsFetching}
              />
            </div>
          </div>
        </div>
        <div className={style.lint} />
        {
          allConditionValue().length > 0 && (
            <div className={style.allCondition}>
              <span>已筛选条件：</span>
              <div>
                <Select
                  mode="multiple"
                  bordered={false}
                  open={false}
                  style={{
                    width: '100%',
                  }}
                  value={allConditionValue()}
                  onChange={deleteCondition}
                  options={allCondition()}
                />
              </div>
            </div>
          )
        }
      </div>

      <div className={style.tableBox}>
        <div className={style.tableHeader}>
          <div className={style.left}>
            潜力小巨人企业
            <span>
              {tableData?.count}
            </span>
            家
          </div>
          <div className={style.right}>
            {/* <button type="button" onClick={() => { setOpen(true); }} style={{ display: active === 'uploading' ? 'block' : 'none' }}>
              文件上传
            </button> */}
            <button type="button" onClick={downloadTable}>
              名单导出
            </button>
            <button type="button" onClick={saveReport}>
              名单分析
            </button>
          </div>
        </div>
        <Table
          y={450}
          pageNumber={pageNumber}
          pageSize={pageSize}
          data={tableData}
          total='auth_count'
          isFetching={tableIsFetching}
          onChange={tableEventHandle}
        />
      </div>
    </>
  );
};

export default ConditionEvaluation;
