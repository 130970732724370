import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import style from './index.module.less';

const ToolModal = ({ title, setTool, bgUrl }) => (
  <div className={style.modal}>
    <div className={style.content}>
      <span className={style.close} onClick={() => { setTool(''); }}>
        <CloseOutlined />
      </span>
      <div className={style.title}>{title}</div>
      <div className={style.dome} style={{ background: `url(${bgUrl})` }}>
        <div className={style.bottom}>
          <div>
            联系客服 13898766789
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ToolModal;
