/*
 *Author: zhaochenyu
 *Description: vip介绍页
 *Date: 2022/04/11
*/

import React, { useState, useEffect } from 'react';
import { getVipDetailInfo } from '../../../api/apply';
import style from './index.module.less';

const styleMap = {
  normal: style.msg,
  vip: style.recommend,
  company: style.company,
};

function Vipinfo(props) {
  const { setUpgradeShow } = props;
  const [vipInfo, setVipInfo] = useState({});
  const permission = JSON.parse(localStorage.getItem('pm'));

  useEffect(() => {
    getVipDetailInfo().then(res => {
      if (res.status === '00000') {
        setVipInfo(res.data);
      }
    });
  }, []);
  return (
    <div className={style.all}>
      <header className={style.header}>
        <div className={style.word}>开通VIP</div>
        <div className={style.word1}>享受专属权益</div>
      </header>
      <div className={style.content}>
        <div className={style.menu}>
          <div className={style.menuTitle}>权益</div>
          <div className={style.menuContent}>
            {(vipInfo.powerItem || []).map((item, index) => (
              <div
                className={style.menuItem}
                key={`${index}menuItem`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className={style.usersMsg}>
          {(vipInfo.accountMsg || []).map((item, index) => (
            <div
              className={styleMap[item.type]}
              key={`${index}msg`}
            >
              {item.type === 'vip' ? (
                <>
                  <div className={style.title}>
                    <div className={style.realTitle}>
                      <div>{item.name}</div>
                      <div className={style.amount}>
                        {item.price}
                        <span>元/年</span>
                      </div>
                      <div className={style.discountWord}>推荐</div>
                    </div>
                  </div>
                  <div className={style.pirce}>
                    <div className={style.oldPirce}>
                      <span style={{ position: 'relative' }}>
                        <div className={style.line}></div>
                        原价
                        <span style={{ fontSize: '16px' }}>{item.originalPrice}</span>
                        元/年
                      </span>
                    </div>
                    <div className={style.word2}>限时优惠</div>
                  </div>
                  <div className={style.discountDate}>
                    {item.date}
                  </div>
                </>
              ) : (<div className={style.title}>{item.name}</div>)}
              {(vipInfo.powerItem || []).map((item1, index1) => (
                <div className={`${style.msgItem} ${(index1 % 2 === 0) && style.doubleRow}`} key={`${index1}msgItem`}>
                  {item1 === '' ? item.desc : item[item1] }
                </div>
              ))}
              {item.type === 'vip' && permission?.vip?.up === 'ol' && setUpgradeShow && (
                <div className={style.openBtn} onClick={() => setUpgradeShow(true)}>立即开通</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Vipinfo;
