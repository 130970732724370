import { get, post } from '../utils/request';
import { TRADE, USERMANAGE, SQCOIN } from '../utils/url';

// vip权益页信息
const getVipDetailInfo = () => get(`${USERMANAGE}/vip_rights_table`, {
});

// 获取vip价格
const getVipPrice = () => get(`${USERMANAGE}/find_vip_price`, {
});

// 获取系统模板二维码
const getQrCode = () => get(`${TRADE}/system_template_QR_code`, {});

// 查询系统模板订单支付状态
const getOrderStatus = params => post(`${TRADE}/find_trade_system_template_status`, {
  ...params,
});

// 获取精品报告二维码
const getBoutiqueReportQrCode = param => get(`${TRADE}/good_report_QR_code`, {
  ...param,
});

// 查询精品报告订单状态
const getBoutiqueReportStatus = params => get(`${TRADE}/find_trade_status_on_good_report`, {
  ...params,
});

// 获取支付列表
const getOrderList = params => post(`${TRADE}/find_trade_list`, {
  ...params,
});

// 用户升级调查问卷
const getQuestions = () => get(`${USERMANAGE}/questionnaire`, {
});

// 升级vip支付二维码
const getVipQrCode = param => get(`${TRADE}/trial_upgrade_VIP_QR_code`, {
  ...param,
});

// 查询vip升级接口
const getPayVipStatus = params => post(`${TRADE}/trial_upgrade_VIP_order_status`, {
  ...params,
});

// 重置用户权限次数
const resetOldCount = () => get(`${TRADE}/frequency_reset`, {});

// 系统模板二维码
const getSystemReportQrcode = params => get(`${TRADE}/system_template_QR_code`, {
  ...params,
});

// 监听系统模板二维码状态
const getSystemReportQrcodeStatus = params => post(`${TRADE}/find_trade_system_template_status`, {
  ...params,
});

// 上奇币购买模板报告接口
const paySQcoinSystemTemplate = params => post(`${SQCOIN}/SQCoin_pay_system_template`, {
  ...params,
});

// 上奇币购买精品报告接口
const paySQcoinPaidReport = params => get(`${SQCOIN}/SQCoin_pay_paid_report`, {
  ...params,
});

export {
  getSystemReportQrcode,
  getSystemReportQrcodeStatus,
  resetOldCount,
  getVipDetailInfo,
  getVipPrice,
  getVipQrCode,
  getPayVipStatus,
  getQuestions,
  getQrCode,
  getOrderStatus,
  getOrderList,
  getBoutiqueReportQrCode,
  getBoutiqueReportStatus,
  paySQcoinSystemTemplate,
  paySQcoinPaidReport,
};
