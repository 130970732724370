import React, { useLayoutEffect } from 'react';
import { Spin } from 'antd';
import { useQuery } from '../../hooks/url';
import { handleWidthRolePermission } from '../../utils/tool';
import { changeSelfPage } from '../../utils/urlMethods';
import {
  getResource,
  getUserInfo,
} from '../../api/login';
import store from '../../store';
import { setUser } from '../../actions/app';

function HomePageLogin() {
  const query = useQuery();
  let redirect = query.get('redirect_to');
  const openCompanyDetailWithToken = query.get('ocdwt');
  // const encryptionToken = query.get('token');
  // const tokenData = JSON.parse(decodeURIComponent(atob(encryptionToken)));
  // const maxTime = new Date().getTime() + (tokenData.expires_in * 1000 - 600 * 1000);
  // localStorage.clear();
  // localStorage.setItem('maxTime', maxTime);
  // localStorage.setItem('token', `${tokenData.token_type} ${tokenData.access_token}`);
  // localStorage.setItem('rft', tokenData.refresh_token);
  useLayoutEffect(() => {
    if (openCompanyDetailWithToken === '1') {
      redirect = 'companyDetail';
      localStorage.clear();
      localStorage.setItem('maxTime', query.get('maxTime'));
      localStorage.setItem('token', query.get('token'));
      localStorage.setItem('rft', query.get('rft'));
    } else {
      const encryptionToken = query.get('token');
      const tokenData = JSON.parse(decodeURIComponent(atob(encryptionToken)));
      const maxTime = new Date().getTime() + (tokenData.expires_in * 1000 - 600 * 1000);
      localStorage.clear();
      localStorage.setItem('maxTime', maxTime);
      localStorage.setItem('token', `${tokenData.token_type} ${tokenData.access_token}`);
      localStorage.setItem('rft', tokenData.refresh_token);
    }
    getUserInfo().then(info => {
      if (info.status === '00000') {
        if (Object.keys(info.data).length === 0) return;
        getResource().then(res => {
          const { real_name: _realName, phone_no: phone } = info.data;
          const realName = String(_realName);
          if (res.status === '00000') {
            if (res.data && res.data.length > 0) {
              const permission = handleWidthRolePermission(res.data);
              localStorage.setItem('pm', JSON.stringify(permission));
              localStorage.setItem('userName', realName);
              localStorage.setItem('ph', phone);
              store.dispatch(setUser(realName));
              if (redirect) {
                const search = {};
                query.forEach((val, key) => {
                  if (key !== 'redirect_to' && key !== 'token') {
                    search[key] = val;
                  }
                });
                changeSelfPage(redirect, search);
              } else {
                changeSelfPage('homePage');
              }
            }
          }
        });
      }
    });
  }, []);
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin spinning />
    </div>
  );
}

export default HomePageLogin;
