/*
 *Description: 下钻地图
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/lib/echarts';
import { get } from '../../../../../utils/request';
import {
  useGetAreaSubsidiaryCompanyMapQuery,
} from '../../../../../service/special';

const MAP_COLOR = [
  '#8CCAFF', '#70BCFF', '#49AAFF', '#3088F5',
  '#2F73FF', '#1961F5', '#0051F7', '#0044D1',
];

function SpecialNewMap(props) {
  const { name, area, setArea, setMapLoading, setSelectedCity } = props;
  const [option, setOption] = useState({});

  // 获取企业地图
  const { data, isFetching: mapLoading } = useGetAreaSubsidiaryCompanyMapQuery({
    area_key: area,
    label: name,
  });
  const chartEvent = {
    click: (params) => {
      if (params.data && params.data.code) {
        setArea(params.data.code);
        setSelectedCity((item) => ({ ...item, [params.data.code]: { parent: params.data.parent_code } }));
      }
    },
  };

  useEffect(() => {
    setMapLoading(mapLoading);
  }, [mapLoading]);

  useEffect(() => {
    const generateOption = async () => {
      let mapName = 'china';
      if (area) {
        mapName = area === '000000' ? 'china' : area;
        if (!echarts.getMap(mapName)) {
          const geoJson = await get(`${process.env.REACT_APP_JSON_URL}/${area}.json`);
          echarts.registerMap(mapName, geoJson);
        }
      } else if (!echarts.getMap('china')) {
        const geoJson = await get(`${process.env.REACT_APP_JSON_URL}/000000.json`);
        echarts.registerMap('china', geoJson);
      }
      const maxValue = data?.length > 0 ? Math.max(...data?.map(item => item.value)) : 0;
      const minValue = data?.length > 0 ? Math.min(...data?.map(item => item.value)) : 0;
      const options = {
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        backgroundColor: '#fff',
        visualMap: [{
          type: 'piecewise',
          min: minValue,
          max: maxValue,
          left: 14,
          bottom: 10,
          splitNumber: 8,
          inRange: {
            color: MAP_COLOR,
          },
          seriesIndex: 0,
          itemWidth: 22,
          itemHeight: 6,
          itemGap: 3,
          text: ['高', '低'],
          textStyle: {
            color: '#3E5C76',
            fontSize: 12,
          },
          // showLabel: false,
        }],
        geo: [{
          map: mapName,
          zoom: 1.2,
          itemStyle: {
            normal: {
              borderColor: '#fff',
            },
          },
          emphasis: {
            itemStyle: {
              areaColor: '#002187',
              borderColor: '#fff',
              borderWidth: 2,
              shadowColor: '#0060C9',
              shadowOffsetX: 2,
              shadowOffsetY: 6,
              shadowBlur: 8,
            },
            label: {
              color: '#fff',
            },
          },
          label: {
            show: true,
          },
        }],
        series: [
          {
            // name: '',
            type: 'map',
            selectedMode: false,
            geoIndex: 0,
            map: mapName,
            tooltip: {
              formatter: (params) => {
                const str = `<div style=" width: 100%">
                  <p style="hieght:32px; width: 100%; padding: 0 0 0 10px; border-bottom: 1px solid #E9E5E1; line-height:32px; font-weight: 500; color: #2B2B2B; ">${params.name}</p>
                  <p style="height: 16px; margin: 0 10px; line-height: 16px; color:#4D4D4D; font-size: 12px ">
                    <span>共计：</span>
                    <span>${params?.data?.value || 0}家</span>
                  </p>
                  <p style="height: 16px; margin: 0 10px; line-height: 16px; color:#4D4D4D; font-size: 12px ">
                    <span>总部：</span>
                    <span>${params?.data?.head_quarters || 0}家</span>
                  </p>
                  <p style="height: 16px; margin: 0 10px;line-height: 16px; color:#4D4D4D; font-size: 12px ">
                    <span>一级： </span>
                    <span>${params?.data?.first_office_count || 0}家</span>
                  </p>
                  <p style="height: 16px; margin: 0 10px; line-height: 16px; color:#4D4D4D; font-size: 12px ">
                    <span>二级： </span>
                    <span>${params?.data?.second_office_count || 0}家</span>
                  </p>
                  <p style="height: 16px; margin: 0 10px 10px 10px; line-height: 16px; color:#4D4D4D; font-size: 12px ">
                    <span>三级及以下： </span>
                    <span>${params?.data?.third_office_count || 0}家</span>
                  </p>
                </div>`;
                return str;
              },
              backgroundColor: '#fff',
              extraCssText: 'border: 1px solid #DCDCDC; border-radius: 4;box-shadow: 2px 5px 8px 0 rgba(108,117,125,0.32);',
            },
            data,
          },
          // scatterSeries,
        ],
      };
      setOption(options);
    };
    generateOption();
  }, [data, area]);
  return <ReactEcharts
    option={option}
    onEvents={chartEvent}
    style={{
      height: 600,
      width: '100%',
    }}
  />;
}

export default SpecialNewMap;

SpecialNewMap.defaultProps = {
  color: MAP_COLOR,
};
