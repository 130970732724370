/*
* Author: zhangmeng
* Description: '研报列表一行'
* Date: 2022/06/29
*/

import React, { useCallback } from 'react';
import TabsComp from '../TabsComp';
import { IconFont } from '../../../../utils/mapIcon';
import { useLazyResearchReportCountQuery } from '../../../../service/dataSearch';
import style from './index.module.less';
// import { openNewPage } from '../../../../utils/urlMethods';

function ResearchReportListLine(props) {
  const { record, participle } = props;
  const [researchReportCountQuery] = useLazyResearchReportCountQuery();

  const handelClick = () => {
    if (!record?.link) return;
    window.open(record?.link);
    researchReportCountQuery({ report_id: record.id }).catch(err => {
      throw new Error(err);
    });
  };

  const highLightWord = useCallback((value) => {
    if (!value || !participle?.length) return value;
    let word = value;
    participle.forEach(item => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(item)) {
        word = word.split(item).join(`<span style="color: #D70000;">${item}</span>`);
      } else {
        const arr = word.split(item);
        const last = arr.pop();
        word = `${arr.join(`<span style="color: #D70000;">${item}</span>`)}${last}`;
      }
    });
    return word;
  }, [participle]);

  return (
    <div className={style.all} onClick={handelClick}>
      <div className={style.topLine}>
        <div className={style.left}>
          <div
            className={style.title}
            title={record.report_name}
            style={{
              maxWidth: `calc(100% - ${record.report_score > 0 ? '150px' : '0px'})`,
            }}
            dangerouslySetInnerHTML={{
              __html: highLightWord(record.report_name),
            }}
          />
          {
            record.report_score > 0 && (
              <div className={style.scoreBox}>
                <span className={style.reportScore}>研报价值分</span>
                <div className={style.score}>
                  {record.report_score}
                  &nbsp;
                  <span className={style.unit}>分</span>
                  <div className={style.bar} />
                </div>
              </div>
            )
          }
        </div>
        <div className={style.right}>
          <div className={style.row}>
            <span>发布机构：</span>
            <span>{record.institution}</span>
          </div>
          <div className={style.row}>
            <span>发布日期：</span>
            <span>{record.release_time}</span>
          </div>
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.bLeft}>
          <TabsComp labels={record.tabs} />
        </div>
        <div className={style.bRight}>
          <div className={style.author}>
            作者：
            <span title={record.author}>{record.author}</span>
          </div>
          <div className={style.info}>
            <span className={style.page}>
              <IconFont type="icon16" />
              {record.pages}
              页
            </span>
            <span className={style.view}>
              <IconFont type="iconico_yueduliang" />
              {record.read_count}
              次
            </span>
            <span className={style.download}>
              <IconFont type="iconxiazai" />
              {record.download_count}
              次
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResearchReportListLine;
