/*
* Author: zhangmeng
* Description: '标题和列表'
* Date: 2022/06/29
*/

import React from 'react';
import { useHistory } from 'react-router-dom';
import rectIcon from '../../../../assets/images/dataSearch/rectIcon.png';
import style from './index.module.less';

function Title(props) {
  const { title, moreClick } = props;
  const history = useHistory();

  const more = p => {
    moreClick(p);
  };

  return (
    <div className={style.all}>
      <div className={style.titleBox}>
        <span>
          <img src={rectIcon} alt="" />
          <span>{title}</span>
        </span>
        <span onClick={() => more(title)}>查看更多</span>
      </div>
    </div>
  );
}

export default Title;
