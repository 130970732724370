/*
 *Author: zhaochenyu
 *Description: 报告弹窗
 *Date: 2022/06/29
*/

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { DownCircleOutlined, ExclamationCircleFilled, CloseOutlined } from '@ant-design/icons';
import { Modal, Input, Button, TreeSelect, Spin } from 'antd';
import {
  getVagueCompnayName,
  getTemplateAreaList,
  getTemplateIndustryList,
  getTemplateDiyTagList,
} from '../../api/reportGeneration';
import style from './index.module.less';

// function handleWIthTreeData(array) {
//   function setValue(arr) {
//     const newArrr = arr.map((item) => {
//       // eslint-disable-next-line no-param-reassign
//       item = {
//         ...item,
//         value: item.key,
//       };
//       if (item.children) {
//         item.children = setValue(item.children);
//       }
//       return item;
//     });
//     return newArrr;
//   }
//   const newArr = setValue(array);
//   return newArr;
// }

// 数据未加载出来时
const notFoundContentEl = (data) => {
  if (!data.length) return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin /></div>;
  return undefined;
};
const titleEl = (
  <div className={style.title}>
    <span>请选择参数</span>
    <span>每月16日更新上月数据</span>
  </div>
);

// 区域+产业筛选框
function CityAndIndustryModal(props) {
  const { visible, cityData, industryData, startAnalysis, setVisible, loading, industryCode, industryName, cityCode, cityName, id } = props;
  const [areaSelect, setAreaSelect] = useState([]);
  const [areaLabel, setareaLabel] = useState('');
  const [industrySelect, setIndustrySelect] = useState([]);
  const [industryLabel, setIndustryLabel] = useState('');
  const [paramsTip, setParamsTip] = useState(false);

  const industryChange = (value, label) => {
    setIndustrySelect(value);
    setIndustryLabel(label);
  };

  const areaChange = (value, label) => {
    setAreaSelect(value);
    setareaLabel(label);
  };

  const analysis = () => {
    if (areaSelect.length === 0 || industrySelect.length === 0) {
      setParamsTip(true);
      return;
    }
    startAnalysis({
      cityValue: [areaSelect],
      cityName: areaLabel[0],
      industryValue: [industrySelect],
      industryName: industryLabel[0],
    });
  };

  useEffect(() => {
    if (industryCode) {
      setIndustrySelect(industryCode);
      setIndustryLabel([industryName]);
    }
    if (cityCode) {
      setAreaSelect(cityCode);
      setareaLabel([cityName]);
    }
  }, []);

  return (
    <Modal
      title={titleEl}
      open={visible}
      maskClosable={false}
      wrapClassName={style.paramsModal}
      footer={null}
      onCancel={() => setVisible(false)}
      style={{ top: 250 }}
      maskStyle={{ background: 'rgba(62, 92, 118, 0.2)' }}
    >
      <div className={style.content} style={{ paddingTop: '25px' }}>
        {/* <div className={style.subtitle}>分析组件为默认参数，可根据需要进行统一修改</div> */}
        {
          paramsTip && (
            <div className={style.paramsTip}>
              <ExclamationCircleFilled />
              <span className={style.tipWord}>您还没选择必选参数</span>
              <CloseOutlined onClick={() => setParamsTip(false)} />
            </div>
          )
        }
        {
          !industryCode && (
            <div className={style.line}>
              <div className={style.must}>*</div>
              <div className={style.label}>产业</div>
              <div className={style.inputBox}>
                <TreeSelect
                  placeholder="请选择想要生成报告的产业"
                  style={{ width: '100%' }}
                  value={industrySelect}
                  // suffixIcon={<DownCircleOutlined />}
                  dropdownClassName={style.conditionSelect}
                  treeDefaultExpandedKeys={[industryData?.length > 0 && industryData?.[0]?.key]}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={industryData}
                  notFoundContent={notFoundContentEl(industryData)}
                  listHeight={320}
                  onChange={industryChange}
                />
              </div>
            </div>
          )
        }
        {
          (!cityCode || id !== '493') && (
            <div className={style.line}>
              <div className={style.must}>*</div>
              <div className={style.label}>区域</div>
              <div className={style.inputBox}>
                {/* {cityData?.length > 0 && ( */}
                <TreeSelect
                  style={{ width: '100%' }}
                  value={areaSelect}
                  // suffixIcon={<DownCircleOutlined />}
                  treeDefaultExpandedKeys={[cityData?.length > 0 && cityData?.[0].key]}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  listHeight={320}
                  treeData={cityData}
                  notFoundContent={notFoundContentEl(cityData)}
                  dropdownClassName={style.conditionSelect}
                  placeholder="请选择想要生成报告的地区"
                  onChange={areaChange}
                />
                {/* )} */}
                {/* {cityData?.length === 0 && (
                  <TreeSelect
                    style={{ width: '100%' }}
                    // suffixIcon={<DownCircleOutlined />}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    listHeight={320}
                    dropdownClassName={style.conditionSelect}
                    placeholder="请选择想要生成报告的地区"
                  />)} */}
              </div>
            </div>
          )
        }
      </div>
      <div className={style.buttons}>
        <Button
          className={style.cancel}
          onClick={() => setVisible(false)}
        >
          取消
        </Button>
        <Button
          className={(areaSelect.length === 0 || industrySelect.length === 0)
            ? style.noClick : style.ok}
          onClick={() => {
            if (areaSelect.length > 0 && industrySelect.length > 0) {
              analysis();
            }
          }}
          loading={loading}
        >
          确定
        </Button>
      </div>
    </Modal>
  );
}

// 区域筛选框
function CityMOdal(props) {
  const { visible, cityData, startAnalysis, setVisible, loading } = props;
  const [areaSelect, setAreaSelect] = useState([]);
  const [areaLabel, setareaLabel] = useState('');
  const [paramsTip, setParamsTip] = useState(false);
  const [isProvinceDirectly, setIsProvinceDirectly] = useState(false);

  const areaChange = (value, label, extra) => {
    setAreaSelect(value);
    setareaLabel(label);
    setIsProvinceDirectly(extra?.triggerNode?.props?.is_province_directly);
  };

  const analysis = () => {
    if (areaSelect.length === 0) {
      setParamsTip(true);
      return;
    }
    startAnalysis({
      cityValue: [areaSelect],
      cityName: areaLabel[0],
      is_province_directly: isProvinceDirectly,
    });
  };

  return (
    <Modal
      title={titleEl}
      open={visible}
      maskClosable={false}
      wrapClassName={style.paramsModal}
      footer={null}
      onCancel={() => setVisible(false)}
      style={{ top: 250 }}
      maskStyle={{ background: 'rgba(62, 92, 118, 0.2)' }}
    >
      <div className={style.content} style={{ paddingTop: '25px' }}>
        {/* <div className={style.subtitle}>分析组件为默认参数，可根据需要进行统一修改</div> */}
        {
          paramsTip && (
            <div className={style.paramsTip}>
              <ExclamationCircleFilled />
              <span className={style.tipWord}>您还没选择必选参数</span>
              <CloseOutlined onClick={() => setParamsTip(false)} />
            </div>
          )
        }
        <div className={style.line}>
          <div className={style.must}>*</div>
          <div className={style.label}>区域</div>
          <div className={style.inputBox}>
            <TreeSelect
              style={{ width: '100%' }}
              value={areaSelect}
              // suffixIcon={<DownCircleOutlined />}
              treeDefaultExpandedKeys={[cityData?.length > 0 && cityData?.[0]?.key]}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              listHeight={320}
              treeData={cityData}
              // treeData={[]}
              notFoundContent={notFoundContentEl(cityData)}
              dropdownClassName={style.conditionSelect}
              placeholder="请选择想要生成报告的地区"
              onChange={areaChange}
            />
          </div>
        </div>
      </div>
      <div className={style.buttons}>
        <Button
          className={style.cancel}
          onClick={() => setVisible(false)}
        >
          取消
        </Button>
        <Button
          className={areaSelect.length === 0 ? style.noClick : style.ok}
          onClick={() => {
            if (areaSelect.length > 0) {
              analysis();
            }
          }}
          loading={loading}
        >
          确定
        </Button>
      </div>
    </Modal>
  );
}

// 企业名称筛选框
function CompnayModal(props) {
  const { visible, startAnalysis, setVisible, loading } = props;
  const [compnayName, setCompnayName] = useState('');
  const [vagueList, setVagueList] = useState([]);
  const [vagueShow, setVagueShow] = useState(false);
  const [vagueLoading, setVagueLoading] = useState(false);
  const [paramsTip, setParamsTip] = useState(false);
  const timer = useRef(null);
  const timer1 = useRef(null);
  const cardRef = useRef(null);
  const nameRef = useRef(null);

  const getCompanyLikeName = useCallback(
    (name) => {
      setVagueLoading(true);
      getVagueCompnayName({
        like_name: name,
      }).then(res => {
        if (res.status === '00000') {
          if (nameRef.current === name) {
            setVagueList(res.data);
          }
        }
      }).finally(() => {
        setVagueLoading(false);
      });
    },
    [],
  );

  const clickFocus = (e) => {
    let { parentNode } = e.target;
    while (parentNode !== null) {
      if (parentNode === cardRef.current) {
        break;
      }
      parentNode = parentNode.parentNode;
    }
    if (!parentNode) {
      setVagueShow(false);
    }
  };

  const clickCard = (e) => {
    if (timer1.current) {
      clearTimeout(timer1.current);
      timer1.current = null;
    }
    timer1.current = setTimeout(() => {
      clickFocus(e);
    }, 50);
  };

  useEffect(() => {
    document.addEventListener('click', clickCard);
    return () => {
      document.removeEventListener('click', clickCard);
    };
  }, []);

  useEffect(() => {
    if (compnayName) {
      // 请求公司对应的模糊匹配单词
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }

      timer.current = setTimeout(() => {
        nameRef.current = compnayName;
        getCompanyLikeName(compnayName);
      }, 300);
    }
  }, [compnayName]);

  const analysis = () => {
    if (compnayName.length === 0) {
      setParamsTip(true);
      return;
    }
    startAnalysis({
      companyName: compnayName,
    });
  };

  const inputChange = (e) => {
    setVagueShow(e.target.value.length > 0);
    setCompnayName(e.target.value);
  };

  return (
    <Modal
      title={titleEl}
      open={visible}
      maskClosable={false}
      wrapClassName={style.paramsModal}
      footer={null}
      onCancel={() => setVisible(false)}
      style={{ top: 250 }}
      maskStyle={{ background: 'rgba(62, 92, 118, 0.2)' }}
    >
      <div className={style.content} style={{ paddingTop: '25px' }}>
        {/* <div className={style.subtitle}>分析组件为默认参数，可根据需要进行统一修改</div> */}
        {
          paramsTip && (
            <div className={style.paramsTip}>
              <ExclamationCircleFilled />
              <span className={style.tipWord}>您还没输入必填参数</span>
              <CloseOutlined onClick={() => setParamsTip(false)} />
            </div>
          )
        }
        <div className={style.line}>
          <div className={style.must}>*</div>
          <div className={style.label}>企业名称</div>
          <div className={style.inputBox}>
            <Input value={compnayName} onChange={inputChange} />
            {vagueShow && (
              <div className={style.vagueList} ref={cardRef}>
                <Spin spinning={vagueLoading} tip="搜索中，请稍候....">
                  {vagueList.map((item, index) => (
                    <div
                      className={style.vagueListItem}
                      key={`${index}vague`}
                      onClick={() => {
                        setCompnayName(item);
                        setVagueShow(false);
                      }}
                      title={item}
                    >
                      {item}
                    </div>
                  ))}
                </Spin>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style.buttons}>
        <Button
          className={style.cancel}
          onClick={() => setVisible(false)}
        >
          取消
        </Button>
        <Button
          className={compnayName.length === 0 ? style.noClick : style.ok}
          onClick={() => {
            if (compnayName.length > 0) {
              analysis();
            }
          }}
          loading={loading}
        >
          确定
        </Button>
      </div>
    </Modal>
  );
}

// 产业筛选框
function IndustryModal(props) {
  const { visible, industryData, startAnalysis, setVisible, loading } = props;
  const [industrySelect, setIndustrySelect] = useState([]);
  const [industryLabel, setIndustryLabel] = useState('');
  const [paramsTip, setParamsTip] = useState(false);

  const industryChange = (value, label) => {
    setIndustrySelect(value);
    setIndustryLabel(label);
  };

  const analysis = () => {
    if (industrySelect.length === 0) {
      setParamsTip(true);
      return;
    }
    startAnalysis({
      industryValue: [industrySelect],
      industryName: Array.isArray(industryLabel) ? industryLabel[0] : industryLabel,
    });
  };

  return (
    <Modal
      title={titleEl}
      open={visible}
      maskClosable={false}
      wrapClassName={style.paramsModal}
      footer={null}
      onCancel={() => setVisible(false)}
      style={{ top: 250 }}
      maskStyle={{ background: 'rgba(62, 92, 118, 0.2)' }}
    >
      <div className={style.content} style={{ paddingTop: '25px' }}>
        {/* <div className={style.subtitle}>分析组件为默认参数，可根据需要进行统一修改</div> */}
        {
          paramsTip && (
            <div className={style.paramsTip}>
              <ExclamationCircleFilled />
              <span className={style.tipWord}>您还没选择必选参数</span>
              <CloseOutlined onClick={() => setParamsTip(false)} />
            </div>
          )
        }
        <div className={style.line}>
          <div className={style.must}>*</div>
          <div className={style.label}>产业</div>
          <div className={style.inputBox}>
            <TreeSelect
              placeholder="请选择想要生成报告的产业"
              style={{ width: '100%' }}
              value={industrySelect}
              // suffixIcon={<DownCircleOutlined />}
              dropdownClassName={style.conditionSelect}
              // treeDefaultExpandedKeys={[industryData.length > 0 && industryData[0].key]}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={industryData}
              listHeight={320}
              onChange={industryChange}
            />
          </div>
        </div>
      </div>
      <div className={style.buttons}>
        <Button
          className={style.cancel}
          onClick={() => setVisible(false)}
        >
          取消
        </Button>
        <Button
          className={industrySelect.length === 0 ? style.noClick : style.ok}
          onClick={() => {
            if (industrySelect.length > 0) {
              analysis();
            }
          }}
          loading={loading}
        >
          确定
        </Button>
      </div>
    </Modal>
  );
}

// 特色标签筛选框
function DiyTagModal(props) {
  const { visible, cityData, diyTagData, startAnalysis, setVisible, loading } = props;
  const [areaSelect, setAreaSelect] = useState([]);
  const [areaLabel, setareaLabel] = useState('');
  const [diyTagSelect, setDiyTagSelect] = useState([]);
  const [diyTagLabel, setDiyTagLabel] = useState('');
  const [paramsTip, setParamsTip] = useState(false);

  const areaChange = (value, label) => {
    setAreaSelect(value);
    setareaLabel(label);
  };

  const diyTagChange = (value, label) => {
    setDiyTagSelect(value);
    setDiyTagLabel(label);
  };

  const analysis = () => {
    if (areaSelect.length === 0 || diyTagSelect.length === 0) {
      setParamsTip(true);
      return;
    }
    startAnalysis({
      cityValue: [areaSelect],
      cityName: areaLabel[0],
      diyTagCode: diyTagSelect,
      diyTagName: diyTagLabel[0],
    });
  };

  return (
    <Modal
      title={titleEl}
      open={visible}
      maskClosable={false}
      wrapClassName={style.paramsModal}
      footer={null}
      onCancel={() => setVisible(false)}
      style={{ top: 250 }}
      maskStyle={{ background: 'rgba(62, 92, 118, 0.2)' }}
    >
      <div className={style.content} style={{ paddingTop: '25px' }}>
        {/* <div className={style.subtitle}>分析组件为默认参数，可根据需要进行统一修改</div> */}
        {
          paramsTip && (
            <div className={style.paramsTip}>
              <ExclamationCircleFilled />
              <span className={style.tipWord}>您还没选择必选参数</span>
              <CloseOutlined onClick={() => setParamsTip(false)} />
            </div>
          )
        }
        <div className={style.line}>
          <div className={style.must}>*</div>
          <div className={style.label}>区域</div>
          <div className={style.inputBox}>
            <TreeSelect
              style={{ width: '100%' }}
              value={areaSelect}
              treeDefaultExpandedKeys={[cityData?.length > 0 && cityData?.[0]?.key]}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              listHeight={320}
              treeData={cityData}
              notFoundContent={notFoundContentEl(cityData)}
              dropdownClassName={style.conditionSelect}
              placeholder="请选择想要生成报告的地区"
              onChange={areaChange}
            />
          </div>
        </div>
        <div className={style.line}>
          <div className={style.must}>*</div>
          <div className={style.label}>特色标签</div>
          <div className={style.inputBox}>
            <TreeSelect
              multiple
              style={{ width: '100%' }}
              value={diyTagSelect}
              treeDefaultExpandedKeys={[diyTagData?.length > 0 && diyTagData?.[0].key]}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              listHeight={320}
              treeData={diyTagData}
              notFoundContent={notFoundContentEl(diyTagData)}
              dropdownClassName={style.conditionSelect}
              placeholder="请选择想要生成报告的地区"
              onChange={diyTagChange}
            />
          </div>
        </div>
      </div>
      <div className={style.buttons}>
        <Button
          className={style.cancel}
          onClick={() => setVisible(false)}
        >
          取消
        </Button>
        <Button
          className={areaSelect.length === 0 ? style.noClick : style.ok}
          onClick={() => {
            if (areaSelect.length > 0) {
              analysis();
            }
          }}
          loading={loading}
        >
          确定
        </Button>
      </div>
    </Modal>
  );
}

function ReportModal(props) {
  const {
    visible,
    setVisible,
    loading,
    startAnalysis,
    templateId,
    templateType,
    industryCode = '',
    industryName = '',
    cityCode,
    cityName = '',
  } = props;
  const [industryData, setIndustryData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [diyTagData, setDiyTagData] = useState([]);

  useEffect(() => {
    setAreaData([]);
    setIndustryData([]);
    setDiyTagData([]);
    if (!Array.isArray(templateType) || templateType.length === 0) return;
    templateType.forEach(item => {
      if (item === 'area') {
        getTemplateAreaList({
          report_template_id: templateId,
          area_code: cityCode,
        }).then(res => {
          if (res.status === '00000') {
            setAreaData(res.data);
          }
        });
      }
      if (item === 'industry' && !industryCode) {
        getTemplateIndustryList({
          report_template_id: templateId,
        }).then(res => {
          if (res.status === '00000') {
            setIndustryData([res.data]);
          }
        });
      }
      if (item === 'diy_tag') {
        getTemplateDiyTagList().then(res => {
          if (res.status === '00000') {
            setDiyTagData(res.data);
          }
        });
      }
    });
  }, [templateId, templateType]);
  const getModal = (curId, curType) => {
    let res = null;
    if (!Array.isArray(curType) || curType.length === 0) return null;
    if (curType.includes('area') && curType.includes('industry') && !curType.includes('diy_tag')) {
      res = <CityAndIndustryModal
        visible={visible}
        setVisible={setVisible}
        cityData={areaData}
        startAnalysis={(conditions) => startAnalysis(curId, curType, conditions)}
        industryData={industryData}
        loading={loading}
        industryCode={industryCode}
        industryName={industryName}
        cityCode={cityCode}
        cityName={cityName}
        id={curId}
      />;
    }
    if (!curType.includes('area') && curType.includes('industry') && !curType.includes('diy_tag')) {
      res = <IndustryModal
        visible={visible}
        setVisible={setVisible}
        loading={loading}
        industryData={industryData}
        startAnalysis={(conditions) => startAnalysis(curId, curType, conditions)}
      />;
    }
    if (curType.includes('area') && !curType.includes('industry') && !curType.includes('diy_tag')) {
      res = <CityMOdal
        visible={visible}
        setVisible={setVisible}
        cityData={areaData}
        startAnalysis={(conditions) => startAnalysis(curId, curType, conditions)}
        loading={loading}
      />;
    }
    if (curType.includes('company')) {
      res = <CompnayModal
        visible={visible}
        setVisible={setVisible}
        loading={loading}
        startAnalysis={(conditions) => startAnalysis(curId, curType, conditions)}
      />;
    }
    if (curType.includes('area') && !curType.includes('industry') && curType.includes('diy_tag')) {
      res = <DiyTagModal
        visible={visible}
        setVisible={setVisible}
        cityData={areaData}
        diyTagData={diyTagData}
        loading={loading}
        startAnalysis={(conditions) => startAnalysis(curId, curType, conditions)}
      />;
    }
    return res;
  };
  return (getModal(templateId, templateType));
}

export default ReportModal;
