/**
 * 横向柱状图
*/
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { Spin } from 'antd';
import html2canvas from 'html2canvas';
import { VerticalAlignBottomOutlined, FileImageOutlined } from '@ant-design/icons';
import { ECharts } from '@sqke/design';
// import ChartNoData from '../ChartNoData';
import style from '../public/index.module.less';

const CrosswiseDar = ({ data, isFetching, right = 30, left = 30, isShowOver, barColor = '#1961F5', downloadImg, dataDownload, preview, setReportData, id, title, position = 'right', yAxisIsLabelShow = true, YnamePadding, download = true, chartDiv, legendIsShow, showLabel }) => {
  const canvasRef = useRef();
  const [barCrosswiseWidth, setBarCrosswiseWidth] = useState('100%');
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setBarCrosswiseWidth((`${data?.x?.length * 12 + (data?.x?.length / 2) * 20 + 370}px`));
  }, [data]);
  useEffect(() => {
    const time = setTimeout(() => {
      setRefresh(!refresh);
    }, 1000);
    return () => { clearTimeout(time); };
  }, []);
  const onChartFinished = () => {
    if (!setReportData) return;
    if (chartDiv) {
      html2canvas(chartDiv.current, { scale: 3 }).then(canvas => {
        const base64Image = canvas.toDataURL(); // 生成Base64图片
        // 在这里可以将base64Image用于其他用途，例如显示在img标签中或通过API发送到服务器
        setReportData(params => ({ ...params, [id]: { ...params[id], el: base64Image } }));
      });
      return;
    }
    const img = new Image();
    // pieMyChart1 要导出的图表
    img.src = canvasRef.current.getEchartsInstance().getDataURL({
      type: 'png',
      pixelRatio: 1, // 放大2倍
      backgroundColor: '#fff',
    });
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      // 添加文字
      const text = '数据来源：上奇产业通';
      // 设置字体和大小
      ctx.font = '600 14px PingFangSC, PingFang SC';
      // 设置文本颜色
      ctx.fillStyle = '333333';
      //  设置文本
      ctx.fillText(text, canvas.width - 160, canvas.height - 5);
      const dataURL = canvas.toDataURL();
      setReportData(params => ({ ...params, [id]: { ...params[id], el: dataURL } }));
    };
  };
  const {
    x,
    series,
    yName,
  } = data || {};
  const option = useMemo(() => ({
    yAxis: {
      name: yName,
      axisLine: {
        show: false, // 不显示坐标轴线
      },
      nameTextStyle: {
        padding: YnamePadding,
        // align: 'right',
      },
      axisLabel: {
        show: yAxisIsLabelShow,
        // align: 'left',
        // 坐标轴刻度重叠时不隐藏
        interval: 0,
        fontSize: x?.length >= 18 ? 12 : 14,
        color: '#333',
      },
      type: 'category',
      data: x.length ? cloneDeep(x)?.reverse() : [100, 123, 344, 98],
    },
    tooltip: {
      formatter: params => {
        const relVal = `${params.name}<br/>${params.marker}${params.seriesName}\u3000\u3000${Math.abs(params.value).toLocaleString()}${series[0].unit}`;
        return relVal;
      },
    },
    grid: {
      // top,
      right,
      bottom: 10,
      left,
    },
    xAxis: {
      show: false,
    },
    legend: {
      show: legendIsShow,
      // show: true,
      bottom: 0,
      itemGap: 60,
      itemHeight: 8,
      itemWidth: 12,
    },
    series: [
      {
        name: series?.[0]?.name,
        type: 'bar',
        barCategoryGap: '50%',
        coordinateSystem: 'cartesian2d',
        data: cloneDeep(series?.[0]?.data)?.reverse(),
        itemStyle: {
          color: barColor,
        },
        // 隐藏坐标轴
        label: {
          show: showLabel,
          formatter: (params) => Math.abs(params.value),
          position,
          valueAnimation: showLabel,
          color: '#000',
          fontSize: 12,
        },
      },
    ],
  }), [JSON.stringify(series), showLabel]);

  const chartEl = useMemo(() => (
    <div className={style.chart}>
      <ECharts
        style={{
          height: (isShowOver && (series?.[0]?.data || []).length > 10) ? `${barCrosswiseWidth}` : '100%',
          width: '100%',
        }}
        ref={canvasRef}
        option={option}
        onEvents={{
          finished: onChartFinished,
        }}
      />
    </div>
  ), [JSON.stringify(data), showLabel, refresh]);
  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {
        !preview && download && (
          <div className={style.tool}>
            <div onClick={dataDownload}>
              <span>
                <VerticalAlignBottomOutlined />
              </span>
              <span>下载数据</span>
            </div>
            <div onClick={() => {
              if (chartDiv) {
                downloadImg(chartDiv.current, title);
                return;
              }
              downloadImg(canvasRef, title, 200, 20);
            }}
            >
              <span>
                <FileImageOutlined />
              </span>
              <span>保存图片</span>
            </div>
          </div>
        )
      }
      {
        chartEl
      }
    </Spin>
  );
};

export default CrosswiseDar;
