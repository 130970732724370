import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
import styles from './index.module.less';
import DataSearchButton from '../DataSearchButton';
import { useGetChannelPatentListQuery } from '../../../../service/industry-channel';
import { getNameConfig, getSortKv } from '../../../../utils/table';
import SortTitle from '../SortTitle';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  name: {
    title: '单位名称',
    dataIndex: 'name',
    key: 'name',
    // width: 330,
    ellipsis: true,
  },
  industry_patent: {
    title: '本产业专利',
    dataIndex: 'industry_patent',
    key: 'industry_patent',
    width: 100,
    ellipsis: true,
    className: styles.number,
  },
  approval_patent_count: {
    title: '全部专利',
    dataIndex: 'approval_patent_count',
    key: 'approval_patent_count',
    width: 100,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  possess_patent_sum: {
    title: '发明(本产业/全部)',
    dataIndex: 'possess_patent_sum',
    key: 'possess_patent_sum',
    width: 146,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  utility_patent_num: {
    title: '实用(本产业/全部)',
    dataIndex: 'utility_patent_num',
    key: 'utility_patent_num',
    width: 146,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  design_patent_num: {
    title: '外观(本产业/全部)',
    dataIndex: 'design_patent_num',
    key: 'design_patent_num',
    width: 146,
    sortDirections: ['descend'],
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  national_standards_count: {
    title: '起草国家标准',
    dataIndex: 'national_standards_count',
    key: 'national_standards_count',
    width: 120,
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
  software_copyright_num: {
    title: '软著',
    dataIndex: 'software_copyright_num',
    key: 'software_copyright_num',
    width: 96,
    sorter: true,
    ellipsis: true,
    className: styles.number,
  },
};

const COLUMN_KEYS = [
  'index', 'name', 'industry_patent', 'approval_patent_count', 'possess_patent_sum',
  'utility_patent_num', 'design_patent_num', 'national_standards_count', 'software_copyright_num',
];

function PatentTable(props) {
  const {
    keyword,
    industryCode,
    sortedInfo = {},
    // filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    onClick,
    onData,
    setParams,
  } = props;

  const {
    key: sortedKey,
    value: sortedValue,
  } = getSortKv(sortedInfo);

  const { data, isFetching } = useGetChannelPatentListQuery({
    key_word: keyword,
    industry_code: industryCode,
    sort_key: sortedKey,
    sort_value: sortedValue,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });
  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'name') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({
            onClick: onCompanyClick,
            keyword,
            idField: '_id',
            className: 'clickable-name',
          })),
        };
      }
      if ([
        'industry_patent', 'approval_patent_count', 'possess_patent_sum', 'utility_patent_num', 'design_patent_num',
      ].includes(item.dataIndex)) {
        rowConfig.render = (text, record) => (
          <DataSearchButton
            text={text}
            style={{ marginLeft: 8 }}
            onClick={() => onClick && onClick({ record, column: item })}
          />
        );
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [onCompanyClick, onClick, columns]);

  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        industry_code: industryCode,
        sort_key: sortedKey,
        sort_value: sortedValue,
      });
    }
  }, [keyword, industryCode, sortedInfo]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      {...props}
    />
  );
}

export default PatentTable;
