// 舆情预警
import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import Container from '../../Container';
import style from './index.module.less';
import { useGetPublicOpinionWarningQuery } from '../../../../../service/companyDetail';

function PublicOpinion(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: tableFetching } = useGetPublicOpinionWarningQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'time') {
        return {
          ...item,
          width: 120,
        };
      }
      if (item.dataIndex === 'title') {
        return {
          ...item,
          width: 550,
          render: (text, row) => (
            <div
              className={style.operation}
              onClick={() => row.link && window.open(row.link)}
            >
              {text}
            </div>
          ),
        };
      }
      return {
        ...item,
      };
    })
  ), [tableData]);

  return (
    <Container id="舆情预警" num={tableData?.count || 0}>
      <Table
        rowKey="index"
        dataSource={tableData?.dataSource}
        columns={tableColumns}
        strip
        loading={tableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        locale={{ emptyText: () => {
          if (!(tableData?.dataSource)) {
            return '';
          }
          return <div className={style.tableNoData}>该企业无此项数据</div>;
        } }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />
    </Container>
  );
}

export default PublicOpinion;
