import React, { useState, useEffect } from 'react';
import Table from '../../../../../components/DataFilterTable';
import { useGetErrorTableQuery } from '../../../../../../../service/dataAnalysisTool';
import styles from './index.module.less';

const ErrorTable = ({ id, setErrorColums }) => {
  const [page, setPage] = useState(1);
  const { data, isFetching } = useGetErrorTableQuery({
    project_id: id,
    page_number: page,

  }, { skip: !id });
  const { columns, dataSource, total } = data || {};
  useEffect(() => {
    setErrorColums(columns);
  }, [columns]);
  return (
    <Table
      className={styles.table}
      columns={columns}
      loading={isFetching}
      dataSource={dataSource}
      onChange={(pageObj) => {
        setPage(pageObj.current);
      }}
      pagination={{
        current: page,
        pageSize: 10,
        total,
        size: 'small',
        hideOnSinglePage: true,
        showSizeChanger: false,
        // pageSizeOptions: [],
        // showSizeChanger: true,
        // pageSizeOptions: [30, 100, 150, 200],
        // showQuickJumper: true,
      }}
    />
  );
};

export default ErrorTable;
