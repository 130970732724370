/*
 *Description: data校验函数
 *Date: Tue Nov 09 2021 10:56:41 GMT+0800 (中国标准时间)
*/

export function checkData(_data: any) {
  const result: any[] = [];

  return result;
}
