// 矩形树图
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import ChartNoData from './ChartNoData';
import style from './public/index.module.less';

/**
 *
 * @param {Array} data 森林结构的数据
 * @param {Boolean} isFetching 是否展示 loading
 * @param {String} proportion 站元素的比例
 * @returns
 */
const TreeMap = ({ data, isFetching, proportion = '90%' }) => {
  const option = {
    tooltip: {
      formatter: params => `${params.marker}${params.name}\u3000\u3000${params.value.toLocaleString()}${params.data.unit}`,
    },
    series: [
      {
        type: 'treemap',
        width: proportion,
        height: proportion,
        visibleMin: 100,
        childrenVisibleMin: 50,
        data: data?.map(item => ({ ...item })),
        itemStyle: {
          gapWidth: 0.5,
          borderWidth: 0,
        },
        levels: [
          {
            label: {
              show: true,
              fontSize: 10,
              textBorderWidth: 0,
              formatter: '{b}\n{c}',
              align: 'left',
              verticalAlign: 'middle',
              position: 'insideLeft',
              overflow: 'truncate',
            },
            upperLabel: {
              show: false,
              fontSize: 10,
              textBorderWidth: 0,
              formatter: '{b}',
              overflow: 'truncate',
            },
          },
        ],
      },
    ],
  };
  const cheartEl = useMemo(() => {
    if (!data || data.length > 0) {
      return <ECharts
        style={{
          width: '100%',
          height: '100%',
        }}
        option={option}
      />;
    }
    return <ChartNoData />;
  }, [data]);
  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {
       cheartEl
      }
    </Spin>
  );
};

export default TreeMap;
