/**
 * 1. 'a.b.c.0' : obj.a.b.c[0]
 * 2. ['a', 'b', 'c', '0'] : obj.a.b.c[0]
 * 3. a.b.c[*].d : obj.a.b.c.map(item => item.d)
 * 4. '*' : obj.map
 * 5. '' : obj['']
 */
export const getPath = (path: string | string[]) => {
  // 格式化 path [0] => .0.，生成 .1.2.3 路径数组
  const expr = /\[(.*?)\]/g;
  return (!Array.isArray(path) ? path.replaceAll(expr, (matchedStr, subMatchedStr0, offset, str) => {
    return `.${subMatchedStr0}`;
  }).split('.') : path);
};

export const getByPath = (obj: Record<string, any>, path: string | string[], defaultValue?: any) => {
  const paths = getPath(path);
  let p: string | undefined = paths.shift();
  let output = obj;
  // p === undefined return; p === '' return
  while (typeof output === 'object' && p !== undefined) {
    if (p === '*' && Array.isArray(output)) {
      output = output.map(item => getByPath(item, paths.slice(), defaultValue))
      break;
    }
    output = output[p];
    p = paths.shift();
  }
  if (output === undefined && defaultValue !== undefined) {
    return defaultValue;
  }
  return output;
}
