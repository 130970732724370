import { defaultsDeep, cloneDeep } from 'lodash';
import ReactEcharts from 'echarts-for-react';

const COLORS = [
  '#1961F5',
  '#59D700',
  '#FD6205',
];

const getBaseBarStyle = (index, colors = COLORS) => ({
  type: 'bar',
  barWidth: 12,
  itemStyle: {
    color: colors[index % colors.length],
  },
  z: 2,
});

function StackBarChart({
  labelRotate = 0,
  xAxis,
  yAxis,
  series = [],
  unit,
  colors,
  yName,
  bottom = 60,
  legendGap = 20,
}) {
  const _series = cloneDeep(series);
  const newSeries = _series.map(item => (
    {
      stack: item.stack,
      name: item.name,
      data: item.value,
      // data: item.value?.map(val => (
      //   {
      //     value: val,
      //     label: {
      //       show: val,
      //       color: '#000',
      //       position: 'inside',
      //     },
      //   }
      // )),
    }
  ));

  return (
    <ReactEcharts
      notMerge
      style={{
        width: '100%',
        height: '100%',
      }}
      option={{
        xAxis: {
          type: xAxis ? 'category' : 'value',
          data: xAxis,
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: !yAxis,
            color: '#7B8796',
            rotate: labelRotate,
          },
          axisLine: {
            show: !yAxis,
            lineStyle: {
              width: 1,
              color: 'rgba(0, 0, 0, 0.15)',
            },
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          name: yName,
          nameLocation: 'end',
          nameTextStyle: {
            align: 'right',
            padding: [0, 8, 0, 0],
          },
          type: yAxis ? 'category' : 'value',
          data: yAxis,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: !yAxis,
            lineStyle: {
              width: 1,
              type: 'dashed',
              color: '#EBEBEB',
            },
          },
          axisLabel: {
            color: '#7B8796',
          },
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          confine: true,
          textStyle: {
            color: 'inherit',
            fontSize: '12',
          },
          valueFormatter: value => `${value}${unit ? ` ${unit}` : ''}`,
        },
        grid: {
          top: 40,
          left: 0,
          right: 0,
          bottom,
          containLabel: true,
        },
        legend: {
          show: true,
          bottom: 0,
          itemWidth: 8,
          itemHeight: 10,
          itemGap: legendGap,
          symbol: 'rect',
          textStyle: {
            color: '#7B8796',
            fontSize: 12,
          },
        },
        series: [
          ...(
            Array.isArray(newSeries)
              ? newSeries.map((item, index) => defaultsDeep(item, getBaseBarStyle(index, colors)))
              : []
          ),
        ].filter(Boolean),
      }}
    />
  );
}

export default StackBarChart;
