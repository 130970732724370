/*
* Author: zhangmeng
* Description: '更改密码'
* Date: 2021/01/19
*/

import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { getModifyNote } from '../../../../api/accountSetting';
import style from './index.module.less';

const REG = new RegExp("^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$");

function ChangePassword(props) {
  const { onFinish, cancelClick, encryptPhone, phone } = props;
  const [btnValue, setBtnValue] = useState('发送验证码');
  const captchaRef = useRef({ timer: null });

  useEffect(() => clearInterval(captchaRef.current.timer), []);

  const getCaptcha = () => {
    if (btnValue === '发送验证码') {
      const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
      if (!phone) {
        message.error('手机号不能为空');
      } else if (!checkPhone.test(phone)) {
        message.error('手机号码格式错误');
      } else {
        getModifyNote({
          phone_no: phone,
        }).then(res => {
          if (res.status === '00000') {
            let second = 60;
            setBtnValue(`已发送(${second}s)`);
            if (captchaRef.current && captchaRef.current.timer) {
              clearInterval(captchaRef.current.timer);
            }
            captchaRef.current.timer = setInterval(() => {
              if (second > 0) {
                second -= 1;
                setBtnValue(`已发送(${second}s)`);
              } else {
                if (captchaRef.current) {
                  clearInterval(captchaRef.current.timer);
                }
                setBtnValue('发送验证码');
              }
            }, 1000);
          } else {
            message.error(res.message);
          }
        });
      }
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 18 },
    },
  };

  const onValuesChange = (value) => {
    if (value.captcha && value.captcha === '1111') {
      // setCodeIsRight(false);
    }
  };

  return (
    <div className={style.all}>
      <Form
        name="basic"
        labelAlign="left"
        onFinish={(p) => onFinish(p)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...formItemLayout}
        onValuesChange={(value) => onValuesChange(value)}
      >
        <Form.Item
          label="新密码"
          name="newPassword"
          rules={[
            {
              pattern: REG,
              message: '为保证安全，请输入8-20位大小写字母+数字+符号的组合',
            },
            {
              required: true,
              message: '请输入8-20位新密码',
            },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder="请输入8-20位新密码"
          />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="confirm"
          rules={[
            {
              required: true,
              message: '请输入8-20位新密码',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('两次密码不一致！');
              },
            }),
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder="请输入8-20位新密码"
          />
        </Form.Item>
        <Form.Item label="" className="captchaBox">
          <div className="phoneBox">
            <div className="phone">{encryptPhone}</div>
            <div className="getCaptcha" style={{ position: 'relative', top: '31px', zIndex: 4 }}>
              <Button
                ref={captchaRef}
                type="text"
                onClick={() => getCaptcha()}
                className={btnValue !== '发送验证码' ? 'btnClicked' : ''}
                disabled={btnValue !== '发送验证码'}
              >
                {btnValue}
              </Button>
            </div>
          </div>
          <Form.Item
            name="captcha"
            className="captcha"
            rules={[
              { required: true, message: '请输入验证码' },
            ]}
          >
            <Input placeholder="请输入验证码" style={{ width: '260px' }} />
          </Form.Item>
        </Form.Item>
        <Form.Item className="submit">
          <Button className="cancel-btn" onClick={() => cancelClick()}>
            取消
          </Button>
          <Button type="primary" htmlType="submit" className="submit-btn">
            确认修改
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ChangePassword;
