import React, { useState, useEffect, useMemo } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import DetailTop from './DetailTop';
import { openNewPage } from '../../../../../utils/urlMethods';
import IndustryCompetitiveness from './IndustryCompetitiveness';
import { getTitleInfo } from '../../../../../api/companyDetail';
import style from './index.module.less';

const tooltip = {
  title: '上奇企业评级指标说明分为4类',
  detail: [
    {
      level: 'S',
      desc: '排名在前10%',
    },
    {
      level: 'AAA',
      desc: '排名在30%—10%',
    },
    {
      level: 'AA',
      desc: '排名在50%—30%',
    },
    {
      level: 'A',
      desc: '排名在后50%',
    },
  ],
};

const levelColor = {
  S: {
    color: '#0D3B66',
  },
  AAA: {
    color: '#4E7293',
  },
  AA: {
    color: '#A9BDD5',
  },
  A: {
    color: '#A4C3B2',
  },
};

const Details = (props) => {
  const { href } = window.location;
  const params = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);
  const id = params.get('id');
  const history = useHistory();
  const [data, setData] = useState({});
  useEffect(() => {
    getTitleInfo({ company_id: id }).then(res => {
      setData(res.data);
    });
  }, []);
  const { companyName, rate, score } = data;

  const gotoEvaluationPage = () => {
    if (companyName) {
      history.push(`/companyIntelligentEvaluation/basicInformation?companyId=${id}&name=${companyName}`);
    }
  };
  return (
    <div className={style.box}>
      <div className={style.companyNameRow}>
        <div className={style.companyName}>{companyName}</div>
        <div className={style.levelScore}>
          <div className={style.levelBox}>
            <div className={style.levelDiv}>
              <div className={style.word}>上奇评级</div>
              <div className={style.level}>{rate}</div>
            </div>
          </div>
          <div className={style.scoreBox}>
            <div className={style.scoreDiv}>
              <div className={style.word}>得分</div>
              <div className={style.score}>{score}</div>
            </div>
          </div>
          <div className={style.questionBox}>
            <span className={style.question}>
              <QuestionCircleOutlined />
              <div className={style.tipBox}>
                <div className={style.title}>
                  上奇企业评级指标说明分为4类:
                  :
                </div>
                {
                  Object.keys(tooltip).length > 0 && tooltip.detail.map((item, index) => (
                    <div className={style.tipDetail} key={`${index}tooltip`}>
                      <div
                        className={style.level}
                        style={levelColor[item.level]}
                      >
                        {item.level}
                      </div>
                      <div className={style.levelDesc}>{item.desc}</div>
                    </div>
                  ))
                }
              </div>
            </span>
          </div>
        </div>
        {/* {
        !menu.find(item => item === 'hiddenDetailTag') && (
          <div
            className={style.button}
            onClick={() => detailTopRef.current?.openReport()}
          >
            <div className={style.report} />
            基础测评
          </div>
        )
      } */}

        <div className={style.skip}>
          <div
            className={style.button}
            onClick={() => {
              openNewPage('companyDetail', { id });
            }}
          >
            查看详情
          </div>
          <div
            className={style.button}
            onClick={gotoEvaluationPage}
          >
            基础测评
          </div>
        </div>

      </div>
      <DetailTop data={data} id={id} />
      <IndustryCompetitiveness companyId={id} />
    </div>
  );
};

export default Details;
