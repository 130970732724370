/*
* Author: zhangmeng
* Description: '气泡柱状图'
* Date: 2022/03/02
*/

import React, { useState, useEffect } from 'react';
// import ReactEcharts from '../../../../modules/echarts-for-react';
import ReactEcharts from 'echarts-for-react';

/**
 * @param {{
 *   data: any;
 *   disableLegend: boolean;
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function BarChart(props) {
  const { data, disableLegend } = props;
  const [options, setOptions] = useState({});
  useEffect(() => {
    if (Object.keys(data.data).length < 1) return;
    const { data: { xAxis, yAxis, xName, yName, yUnit }, curLocation } = data;
    const bubbleHeight = (index) => yAxis[0].value[index];
    if (!Array.isArray(yAxis)) return;
    const legend = yAxis?.map((item, index) => (
      {
        name: item.name,
        itemStyle: {
          color: '#1961F5',
        },
      }
    ));
    const newData = yAxis[0]?.value?.map((item, index) => (
      {
        value: item,
        itemStyle: {
          color: xAxis[index] === curLocation ? '#FD6205' : '#1961F5',
          // color: {
          //   type: 'linear',
          //   x: 0,
          //   x2: 0,
          //   y: 1,
          //   y2: 0,
          //   colorStops: [
          //     {
          //       offset: 0,
          //       color: xAxis[index] === curLocation ? '#011A3C' : '#01193D',
          //     },
          //     {
          //       offset: 1,
          //       color: xAxis[index] === curLocation ? '#00CA72' : '#1465F3',
          //     }],
          // },
        },
      }
    )) ?? [];
    const option = {
      tooltip: {
        // show: false,
        // trigger: 'axis',
        backgroundColor: '#fff',
        borderWidth: 0,
        borderColor: '#7B8796',
        textStyle: {
          color: '#7B8796',
        },
      },
      grid: {
        // top: 30,
        // right: 30,
        // bottom: 86,
        // left: 60,
        top: 30,
        right: 40,
        bottom: 86,
        left: 64,
      },
      legend: {
        show: disableLegend,
        bottom: 5,
        itemWidth: 8,
        itemHeight: 10,
        symbol: 'rect',
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: legend,
      },
      xAxis: [
        {
          name: xName || '',
          type: 'category',
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#000000',
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 55,
            fontSize: 8,
            color: '#7B8796',
            formatter: v => {
              let label = '';
              if (v === '客户区') {
                label = `{curArea|${v}}`;
              } else {
                label = `{otherArea|${v}}`;
              }
              return label;
            },
            rich: {
              curArea: {
                color: '#00F2FE',
                fontSize: 8,
              },
              otherArea: {
                color: '#7B8796',
                fontSize: 8,
              },
            },
          },
          data: xAxis,
        },
      ],
      yAxis: [
        {
          name: `(${yUnit})` || '',
          // nameTextStyle: {
          //   color: '#7B8796',
          //   fontSize: 12,
          // },
          type: 'value',
          // max: (value) => value.max + 10,
          // axisLine: {
          //   show: true,
          //   lineStyle: {
          //     color: '#02276B',
          //   },
          // },
          axisLabel: {
            color: '#7B8796',
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              color: '#EBEBEB',
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          name: yAxis[0].name,
          type: 'bar',
          barMaxWidth: 18,
          data: newData,
          markPoint: {
            data: [
              { value: bubbleHeight(xAxis.indexOf(curLocation)), xAxis: xAxis.indexOf(curLocation), yAxis: bubbleHeight(xAxis.indexOf(curLocation)) },
            ],
            itemStyle: {
              color: '#FD6205',
            },
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              fontWeight: 600,
              fontSize: 12,
              lineHeight: 10,
              textBorderColor: '#FD6205',
              textBorderWidth: 3,
            },
          },
        },
      ],
    };
    setOptions(option);
  }, [data, disableLegend]);

  return (
    <ReactEcharts
      option={options}
      notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default BarChart;
