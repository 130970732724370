import React, { useRef, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { Table } from 'antd';
import style from './index.module.less';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const ComponentsTable = (props) => {
  const tableEl = useRef();
  const { dataSource, columns, isFetching } = props;
  const [columnsData, setColumnsData] = useState([]);
  const [dataSourceData, setDataSourceData] = useState([]);
  const initData = () => {
    setColumnsData((columns || []).map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 100,
          key: uniqueId(),
        };
      }
      if (item.dataIndex === 'name') {
        return {
          ...item,
          width: 300,
          key: uniqueId(),
        };
      }
      return { ...item, key: uniqueId() };
    }));
    setDataSourceData((dataSource || []).map(item => ({ ...item, key: uniqueId() })));
  };
  useEffect(() => {
    initData();
  }, [dataSource, columns]);

  return (
    <div className={style.table}>
      {
        Array.isArray(columnsData) && columnsData.length && (
          <div ref={tableEl}>
            <Table
              columns={columnsData}
              dataSource={dataSourceData}
              pagination={false}
              bordered
              loading={isFetching}
              locale={{
                emptyText: async () => {
                  await sleep(0);
                  return isFetching ? undefined : (
                    <div className={style.noData}>
                      <div className={style.icon} />
                      <div className={style.text}>暂无数据</div>
                    </div>
                  );
                },
              }}
            />
          </div>
        )
      }
    </div>
  );
};
export default ComponentsTable;
