/*
 *Author: zhaochenyu
 *Description: 首页
 *Date: 2022/01/18
*/

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import * as dd from 'dingtalk-jsapi'; // 此方式为整体加载，也可按需进行加载
import { withRouter, useHistory } from 'react-router-dom';
import { Input, message, Carousel, Spin } from 'antd';
import classNames from 'classnames';
// import QRCode from 'qrcode.react';
import cloneDeep from 'lodash/cloneDeep';
import style from './index.module.less';
import {
  getToken,
  getResource,
  getUserInfo,
  changeUserLoginStatus,
  authorization,
  // getShowqrcode,
} from '../../api/login';
import {
  getIndustryGroupList,
  getGroupIndustry,
  getGroupIndustryLogin,
  getEveryCount,
  // recommendList,
  getReportList,
  queryMyChannel,
  getAttetion,
  getAttetionLogin,
  getIsBuyIndustry,
  getHistoryUseSearch,
  getHotUsedSearch,
} from '../../api/homepage';
import {
  getCompnayId,
  resetTemplateReport,
  getRegionName,
} from '../../api/reportGeneration';
import OverflowIndustryList from '../../components/HomePage/OverflowIndustryList';
import { IconFont } from '../../utils/mapIcon';
import { openNewPage } from '../../utils/urlMethods';
import { judgeArea, getBindWxPermission, handleWidthRolePermission } from '../../utils/tool';
import { reportCoverMap } from '../../utils/reportCoverMap';
import ReportCover from '../../components/ReportCover';
import HomeBottom from '../../components/HomePage/HomeBottom';
// import RecommendNews from '../../components/HomePage/RecommedNews';
import FocusList from '../../components/HomePage/FocusList';
import ReportModal from '../../components/ReportModal';
import ChannelModal from '../../components/MySelf/ChannelModal';
import { useGetNavigationBarSpecialTopicQuery } from '../../service/homepage';
import { useRecordingSpotQuery } from '../../service/public';
import { setUser } from '../../actions/app';
import zgcIcon from '../../assets/images/homepage/specialUser.svg';
import project from '../../assets/images/homepage/145project.png';
import store from '../../store';

const serviceId = 'ding48d0e21efc54b5f635c2f4657eb6378f';
const serviceType = 'dingding_interior';

// import backImg from '../../assets/images/homepage/carouselBack.jpg';
const industryImgMap = {
  未来产业: style.industryWeilaichanye,
  信息技术: style.industryXinxijishu,
  装备制造: style.industryZhuangbeizhizao,
  医疗健康: style.industryYiliaojiankang,
  新能源: style.industryXinnengyuan,
  新材料: style.industryXincailiao,
  服务业: style.industryfuWu,
  其他: style.industryQita,
};

const selectIndustryImgMap = {
  未来产业: style.industryWeilaichanyeSelect,
  信息技术: style.industryXinxijishuSelect,
  装备制造: style.industryZhuangbeizhizaoSelect,
  医疗健康: style.industryYiliaojiankangSelect,
  新能源: style.industryXinnengyuanSelect,
  新材料: style.industryXincailiaoSelect,
  服务业: style.industryfuWuSelect,
  其他: style.industryQitaSelect,
};

function HomePage(props) {
  const {
    setLoginShow,
    setChannelModalVisible,
    channelModalVisible,
    payType,
    setPayType,
    defaultBuyIndustryKey,
    setDefaultBuyIndustryKey,
  } = props;
  const history = useHistory();
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const EAPersonalCenter = menu.find(item => item === 'EAPersonalCenter');
  const zgc = menu.find(item => item === 'zgc');
  const [industryGroup, setIndustryGroup] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [everyCountData, setEveryCount] = useState([]);
  // const [recommendData, setRecommendData] = useState([]);
  const [focusList, setFocusList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [selectIndustryClass, setSelectIndustryClass] = useState('');
  const [choseModel, setChoseModel] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [templateType, setTemplateType] = useState('');
  const [buyIndustryList, setBuyIndustryList] = useState([]);
  const [buyCityList, setBuyCityList] = useState([]);
  const [choseLoading, setChoseLoading] = useState(false);
  const choseTemplateMsg = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [historyUseSearch, setHistoryUseSearch] = useState([]);
  const [hotUseSearch, setHotUseSearch] = useState([]);
  const [loading, setLoading] = useState([true, true, true, true, true, true]);
  const loadingRef = useRef([true, true, true, true, true, true]);
  const searchInputRef = useRef(null);
  const urlParamsLogin = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const { data: specialList, refetch: getSpecialList } = useGetNavigationBarSpecialTopicQuery();

  const modalClick = e => {
    if (!searchInputRef.current) return;
    if (!searchInputRef.current.contains(e.target) && searchInputRef.current
      !== e.target && e.target.className !== 'searchInput') {
      setFocus(false);
    }
  };

  const getMyFocusList = () => {
    let attentionFun = getAttetion;
    if (localStorage.getItem('token')) {
      attentionFun = getAttetionLogin;
    }
    loadingRef.current[3] = true;
    setLoading(cloneDeep(loadingRef.current));
    attentionFun().then(res => {
      if (res.status === '00000') {
        setFocusList(res.data);
        loadingRef.current[3] = false;
        setLoading(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[3] = false;
        setLoading(cloneDeep(loadingRef.current));
      }
    });
  };
  const login = (req) => {
    getToken(req).then(async (res) => {
      if (res.access_token) {
        getUserInfo().then(info => {
          if (info.status === '00000') {
            if (Object.keys(info.data).length === 0) return;
            getResource().then(res1 => {
              const { phone_no: phone,
                account_status: accountStatus, real_name: realName } = info.data;
              if (res1.status === '00000') {
                if (accountStatus === 7 || accountStatus === 8) {
                  changeUserLoginStatus();
                }
                const bindWxPermission = getBindWxPermission(res1.data);
                if (res1.data && res1.data.length > 0) {
                  const permission = handleWidthRolePermission(res1.data);
                  localStorage.setItem('pm', JSON.stringify(permission));
                  localStorage.setItem('userName', realName);
                  localStorage.setItem('ph', phone);
                  localStorage.setItem('isFirstLogin', accountStatus === 7);
                  store.dispatch(setUser(realName));
                  if (urlParamsLogin.get('active') === '3634125ad65a11ecbc673af9d3b1b5f0' || accountStatus === 8) {
                    localStorage.setItem('act', 'zjtx');
                  }
                }
              } else if (res1.status === 'A0207') {
                // setExpiredAccount(phone);
                // setExpiredRenewal(true);
                // setLoginModalShow(false);
                // setIsLoading(false);
                // setUid(uuidv4());
              } else {
                localStorage.clear();
                localStorage.removeItem('rft');
                localStorage.removeItem('rftChanyeMax');
                // setUid(uuidv4());
                // setIsLoading(false);
                message.error(res1.message);
              }
            });
          } else {
            localStorage.clear();
            localStorage.removeItem('rft');
            localStorage.removeItem('rftChanyeMax');
            // setUid(uuidv4());
            // setIsLoading(false);
            message.error(info.message);
          }
        });
      } else if (res.status === 'A0201') {
        // setUid(uuidv4());
        // setIsLoading(false);
        message.error(res.message);
        // setTimeout(() => {
        //   if (accountType === '个人账号登录') {
        //     setType('apply');
        //   }
        // }, 1000);
      } else if (res.status === 'A0329') {
        // setIsLoading(false);
        message.error(res.message);
        // setTimeout(() => {
        //   if (accountType === '企业账号登录') {
        //     setAccountType('个人账号登录');
        //     setType('password');
        //   }
        // }, 1000);
      } else {
        // setUid(uuidv4());
        // setIsLoading(false);
        // message.error(res.message);
      }
    });
  };
  // 获取钉钉用户code
  const getAuthorizationCode = async (userId) => {
    const pageOrigin = window.location.origin;
    const corpId = urlParamsLogin.get('corpId') || serviceId;
    // const corpId = urlParamsLogin.get('corpId');
    const res = await authorization({
      response_type: 'code',
      service_id: corpId,
      callback_uri: `${pageOrigin}/industry_knowledge_engine/v2/oauth/dd_single_sign`,
      code: userId,
      service_type: serviceType,
    });
    const { data } = res;
    login({
      grant_type: 'authorization_code',
      service_id: corpId,
      code: data,
      service_type: serviceType,
    });
  };
  // 用户免登录
  useEffect(() => {
    const code = urlParamsLogin.get('code');
    const corpId = urlParamsLogin.get('corpId') || serviceId;
    // 获取是不是dd环境
    const isDingTalkBrowser = /DingTalk/.test(navigator.userAgent);
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isDingTalkBrowser) {
      dd.getAuthCode({
        corpId,
        success: (res) => {
          const { code: userId } = res;
          if (isMobileDevice) {
            getAuthorizationCode(userId);
          } else {
            openNewPage('homePage', { code: userId, corpId });
          }
          // getAuthorizationCode(userId);
        },
        fail: () => {
          message.info('自动登录失败，请手动登录');
        },
        complete: () => {
          // history.push('/homePage');
        },
      });
    } else if (code) {
      getAuthorizationCode(code);
    } else {
      login({ grant_type: 'ip' });
    }
  }, []);

  // 用户监测 埋点
  useRecordingSpotQuery({ spot_name: '首页' });
  useEffect(() => {
    // if (!localStorage.getItem('token')) {
    //   setLoginShow(true);
    // }
    loadingRef.current = [true, true, true, true, true, true];
    setLoading(cloneDeep(loadingRef.current));
    getEveryCount().then(res => {
      if (res.status === '00000') {
        setEveryCount(res.data);
        loadingRef.current[0] = false;
        setLoading(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[0] = false;
        setLoading(cloneDeep(loadingRef.current));
      }
    });
    getIndustryGroupList().then(res => {
      if (res.status === '00000') {
        setIndustryGroup(res.data);
        setSelectIndustryClass(res.data.length > 0 ? res.data[0].key : '');
        loadingRef.current[1] = false;
        setLoading(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[1] = false;
        setLoading(cloneDeep(loadingRef.current));
      }
    });

    getMyFocusList();
    getReportList().then(res => {
      if (res.status === '00000') {
        setReportList(res.data);
        loadingRef.current[5] = false;
        setLoading(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[5] = false;
        setLoading(cloneDeep(loadingRef.current));
      }
    });
    if (localStorage.getItem('token')) {
      queryMyChannel().then(res => {
        if (res.status === '00000') {
          setBuyIndustryList(res.data.industry);
          setBuyCityList(res.data.city);
        }
      });
    }
    getHotUsedSearch().then(res => {
      if (res.status === '00000') {
        setHotUseSearch(res.data);
      }
    });
    window.addEventListener('click', modalClick);
    return () => {
      window.removeEventListener('click', modalClick);
    };
  }, []);

  useEffect(() => {
    if (!selectIndustryClass) return;
    let fun = getGroupIndustry;
    if (localStorage.getItem('token')) {
      fun = getGroupIndustryLogin;
    }
    loadingRef.current[4] = true;
    setLoading(cloneDeep(loadingRef.current));
    fun({
      group_name: selectIndustryClass,
    }).then(res1 => {
      if (res1.status === '00000') {
        setIndustryList(res1.data);
        loadingRef.current[4] = false;
        setLoading(cloneDeep(loadingRef.current));
      } else {
        loadingRef.current[4] = false;
        setLoading(cloneDeep(loadingRef.current));
      }
    });
  }, [selectIndustryClass]);

  // 打开报告预览页
  const openNewReportPage = useCallback((curTemplateId, data, curType, conditions) => {
    let obj = {
      curTemplateId,
      title: choseTemplateMsg.current.template_name,
      id: data,
    };
    curType.forEach(item => {
      if (item === 'area') {
        obj = {
          ...obj,
          area: conditions.cityName,
          areaKey: conditions.cityValue,
        };
      }
      if (item === 'industry') {
        obj = {
          ...obj,
          industry: conditions.industryName,
          industryKey: conditions.industryValue,
        };
      }
      if (item === 'company') {
        obj = {
          ...obj,
          name: conditions.companyName,
        };
      }
    });
    const msg = window.btoa(window.encodeURIComponent(JSON.stringify(obj)));
    // template_source区分精品(3),新模板(2),旧模板(1)
    const templateSource = choseTemplateMsg.current.template_source;
    if (templateSource === 1) {
      openNewPage('reportPreview', {
        msg,
      });
    } else if (templateSource === 2) {
      openNewPage('scheme', {
        msg,
      });
    }
  }, []);

  // 各种模板-复用模板逻辑
  const analysisReportConditionChange = async (curTemplateId, curType, conditions) => {
    setChoseLoading(true);
    let companymsg = '';
    if (curType.includes('company')) {
      await getCompnayId({
        company_name: conditions.companyName,
      }).then(res => {
        if (res.status === '00000') {
          companymsg = res.data;
        }
        if (res.status === 'c0401') {
          companymsg = 'error';
          message.error(res.message);
          setChoseLoading(false);
        }
      });
    }
    if (curType.includes('area')) {
      getRegionName({
        code: conditions.cityValue?.[0],
        duplicate: true,
        separator: '',
      }).then(res => {
        if (res.status === '00000') {
          conditions.cityName = res.data;
        }
      });
    }
    if (companymsg === 'error') return;
    let params = {
      template_id: curTemplateId,
      template_source: choseTemplateMsg.current.template_source,
    };
    curType.forEach(item => {
      if (item === 'area') {
        if (curTemplateId === '16,23') {
          params = {
            ...params,
            template_id: !conditions.is_province_directly ? judgeArea(conditions.cityValue, params.template_id) : '16',
            area_key_list: conditions.cityValue,
          };
        } else {
          params = {
            ...params,
            template_id: judgeArea(conditions.cityValue, curTemplateId),
            area_key_list: conditions.cityValue,
          };
        }
      }
      if (item === 'industry') {
        params = {
          ...params,
          industry_code_list: conditions.industryValue,
        };
      }
      if (item === 'company') {
        params = {
          ...params,
          company_info_dict: companymsg || {},
        };
      }
      if (item === 'diy_tag') {
        params = {
          ...params,
          diy_tag_list: conditions.diyTagCode || [],
        };
      }
    });
    resetTemplateReport(params).then(res => {
      if (res.status === '00000') {
        setChoseLoading(false);
        openNewReportPage(params.template_id, res.data, curType, conditions);
        setChoseModel(false);
      } else {
        setChoseLoading(false);
        message.error(res.message);
      }
    }).catch(() => {
      setChoseLoading(false);
    });
  };

  const pushReportDetail = useCallback((reportMsg) => {
    if (reportMsg.template_source === 3) {
      const { id, Buy_state: isApply, template_price: price, cover_url: coverImg,
        template_name: title, sub_template_name: subTitle } = reportMsg;
      openNewPage('boutiqueDetail', {
        msg: window.btoa(window.encodeURIComponent(JSON.stringify({
          title,
          subTitle,
          isApply,
          price,
          coverImg,
          id,
        }))),
      });
    } else {
      choseTemplateMsg.current = reportMsg;
      setTemplateId(reportMsg.id);
      setTemplateType(reportMsg.outer_macro);
      setChoseModel(true);
    }
  }, []);

  const pushToIndustryChannel = useCallback((industryKey, isOpen) => {
    const params = new URLSearchParams();
    params.set('type', 'graph');
    params.set('industry-code', window.btoa(industryKey));
    if (!isOpen) {
      props.history.push({
        pathname: 'industry',
        search: params.toString(),
      });
      return;
    }
    const queryString = params.toString();
    openNewPage(`industry?${queryString}`);
  }, []);

  const pushToIndustry = (industryKey, isOpen) => {
    getIsBuyIndustry({
      industry_code: industryKey,
    }).then(res => {
      if (res.status === '00000') {
        if (res.data) {
          pushToIndustryChannel(industryKey, isOpen);
        } else {
          setDefaultBuyIndustryKey(industryKey);
          setChannelModalVisible(true);
        }
      }
    });
  };

  const pushToCityChannel = useCallback((city) => {
    const params = new URLSearchParams();
    params.set('type', 'graph');
    params.set('city-code', window.btoa(city?.value));
    params.set('city-title', city?.name);
    params.set('is-province-directly', city?.is_province_directly || false);
    props.history.push({
      pathname: 'cityDetails',
      search: params.toString(),
    });
  }, []);

  // 跳转专题
  const specialPageRoute = (item) => {
    if (!localStorage.getItem('token')) {
      setLoginShow(true);
      return;
    }
    if (item.disabled) {
      setChannelModalVisible(true);
      // console.log(setPayType);
      setDefaultBuyIndustryKey(item.key);
      setPayType('special');
      return;
    }
    const name = window.btoa(encodeURIComponent(item.value));
    const code = window.btoa(item.key);
    history.push({ pathname: '/special', search: `name=${name}&code=${code}` });
  };

  const reportLists = useMemo(() => (
    reportList.map((item, index) => (
      <div className={style.reportItem} key={`${index}reportItem`}>
        <div
          className={style.reportCover}
          onClick={() => {
            if (!localStorage.getItem('token')) {
              setLoginShow(true);
            } else {
              pushReportDetail(item);
            }
          }}
        >
          {
            item.cover_scheme && Object.keys(item.cover_scheme).length > 0 && (
              <ReportCover
                coverScheme={item.cover_scheme}
                scale="scale(0.2202, 0.22076)"
              />
            )
          }
          {
            (!item.cover_scheme || Object.keys(item.cover_scheme).length === 0) && (
              <img
                src={item.template_source === 1 ? reportCoverMap[item.id] : item.cover_url || item.static_cover_url}
                alt={item.title}
                style={{ width: '100%', height: '100%' }}
              />
            )
          }
        </div>
        <div className={style.reportMsg}>
          <div className={style.msgTop}>
            <span style={{ marginRight: '12px' }}>
              <IconFont type="iconeye-fill" style={{ marginRight: '4px' }} />
              <span className={style.count}>{item.read_the_number}</span>
            </span>
            <span>
              <IconFont type="icondownload" style={{ marginRight: '4px' }} />
              <span className={style.count}>{item.download_the_number}</span>
            </span>
          </div>
          <div
            className={style.msgBottom}
            onClick={() => {
              if (!localStorage.getItem('token')) {
                setLoginShow(true);
              } else {
                pushReportDetail(item);
              }
            }}
          >
            <div className={style.titleTag}>
              <div className={style.reporTtitle}>{item.template_name}</div>
              <div className={style.reporTag}>
                {(item.category_list || []).map((item1, index1) => (
                  <div key={`${index1}tags`} className={style.tagsItem}>{item1}</div>
                ))}
              </div>
            </div>
            <div className={style.authorTime}>
              <div className={style.reportLabel} style={{ letterSpacing: '28px' }}>作者</div>
              <div className={style.reportValue1}>{item.author}</div>
              <div className={style.reportLabel}>发表时间</div>
              <div className={style.reportValue}>{item.date}</div>
            </div>
          </div>
        </div>
      </div>
    ))
  ), [reportList, setLoginShow, pushReportDetail]);
  // 搜索框
  const searchOnChange = p => {
    setSearchValue(p);
  };

  // input 聚焦
  const inputOnFocus = () => {
    if (!localStorage.getItem('token')) {
      setLoginShow(true);
      return;
    }
    setFocus(true);
    getHistoryUseSearch().then(res => {
      if (res.status === '00000') {
        setHistoryUseSearch(res.data);
      }
    });
  };

  const iconClick = (p) => {
    if (!localStorage.getItem('token')) {
      setLoginShow(true);
      return;
    }
    setFocus(false);
    openNewPage('dataSearch', {
      search: p,
    });
  };
  return (
    <div className={style.all}>
      {/* <Carousel autoplay> */}
      <Carousel autoplay>
        <div className={style.header}>
          {
          zgc ? (
            <div className={style.zgc}>
              <img src={zgcIcon} alt="SVG" />
            </div>
          ) : (
            <div className={style.project} /* onClick={() => { history.push('/comeFromDD'); }} */ >
              <img src={project} alt="" />
            </div>
          )
        }
          <div className={style.headerCenter}>
            <div className={style.bigTitle}>
              <span style={{ marginRight: '44px' }}>让数据更具价值</span>
              <span>让资源触手可及</span>
            </div>
            <div
              className={style.searchInput}
              ref={searchInputRef}
              onClick={() => {
                if (!localStorage.getItem('token')) {
                  setLoginShow(true);
                }
              }}
            >
              <Input
                placeholder="搜索企业、政策等关键词"
                value={searchValue}
                onClick={e => e.stopPropagation()}
                onFocus={() => inputOnFocus()}
                onChange={e => searchOnChange(e.target.value)}
                onPressEnter={e => iconClick(e.target.value)}
              />
              <div className={style.inputLine} />
              <IconFont type="iconfenxiang" className={style.inputIcon} onClick={() => iconClick(searchValue)} />
              {
              focus && (
                <div className={style.focusBox}>
                  {
                    historyUseSearch.length > 0 && (
                      <div className={style.history}>
                        <div className={style.title}>
                          <span className={style.point} />
                          历史搜索
                        </div>
                        <div className={style.itemBox}>
                          {
                            historyUseSearch.map(item => (
                              <div
                                key={item}
                                onClick={() => {
                                  setSearchValue(item);
                                  iconClick(item);
                                }}
                              >
                                {item}
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    )
                  }
                </div>
              )
            }
            </div>
            <div className={style.hotSearch}>
              <div className={style.label}>热门搜索：</div>
              <div className={style.hotList}>
                {hotUseSearch.length > 0 && hotUseSearch.map(item => (
                  <div
                    key={item}
                    className={style.hotLitsItem}
                    onClick={() => {
                      if (!localStorage.getItem('token')) {
                        setLoginShow(true);
                      } else {
                        setSearchValue(item);
                        iconClick(item);
                      }
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className={style.totalCountList}>
              <Spin spinning={loading[0]}>
                {everyCountData.map((item, index) => (
                  <div className={style.totalItem} key={`${index}total`}>
                    <div className={style.totalItemName}>
                      {item.name}
                    </div>
                    <div className={style.totalItemValue}>
                      {item.count}
                    </div>
                    {/* <div className={style.rightLine} style={{ display: index === everyCountData.length - 1 ? 'none' : '' }} /> */}
                  </div>
                ))}
              </Spin>
            </div>
          </div>
        </div>
        <div
          className={style.banner2}
          onClick={() => {
            window.open('https://mp.weixin.qq.com/s/iwA75e8cqFgZLbhjGXzEbw');
          }}
        />
      </Carousel>
      {/* <div className={style.otherBack}>
        <img src={backImg} alt="" />
      </div> */}
      {/* </Carousel> */}
      <div className={style.body}>
        <div className={style.recommend}>
          <div className={style.industryRecommend}>
            <div className={style.industryTitle}>
              <span className={style.bigWord}>产业图谱</span>
              <span style={{ fontSize: '16px', color: '#7B8796' }}>六大领域，百余条产业链......</span>
            </div>
            <div className={style.industryBody}>
              <div className={style.industryClass}>
                <Spin spinning={loading[1]}>
                  {industryGroup.map((item, index) => (
                    <div
                      className={item.key === selectIndustryClass ? style.selectClassItem : style.industryClassItem}
                      key={`${index}industry`}
                      onClick={() => {
                        if (!localStorage.getItem('token')) {
                          setLoginShow(true);
                        } else {
                          setSelectIndustryClass(item.key);
                        }
                      }}
                    >
                      <div className={item.key === selectIndustryClass ? style.selectBack : style.normalBack}>
                        <div className={`${style.img} ${item.key === selectIndustryClass ? selectIndustryImgMap[item.key] : industryImgMap[item.key]}`} />
                        <div className={style.industryName}>{item.title}</div>
                      </div>
                    </div>
                  ))}
                  <div className={style.industryRightLine} />
                </Spin>
              </div>
              <div className={style.industryClassDetail}>
                <Spin spinning={loading[4]}>
                  {industryList.map((item, index) => (
                    <div
                      className={classNames(style.industryDetailItem, {
                        [style.industryDetailItemDisable]: item.disabled && !EAPersonalCenter,
                        [style.industryDetailItemNormal]: !item.disabled && !EAPersonalCenter,
                      })}
                      key={`${index}industryDetail`}
                      onClick={() => {
                        if (item.disabled && EAPersonalCenter) return;
                        if (!localStorage.getItem('token')) {
                          setLoginShow(true);
                        } else {
                          pushToIndustry(item.key, true);
                        }
                      }}
                    >
                      {
                        !EAPersonalCenter && (
                          !item.disabled && (
                            <div className={style.notOpen}>
                              已开通
                            </div>
                          )
                        )
                      }
                      <div className={style.detailTop} title={item.title}>
                        {item.title}
                        {/* {item.disabled && <div className={style.industryLock} />} */}
                      </div>
                      <div className={style.detailCenter}>
                        <div className={style.detailLabel}>环节</div>
                        <div className={style.detailValue}>{item.link_count}</div>
                        <div className={style.detailUnit}>个</div>
                      </div>
                      <div className={style.detailCenter}>
                        <div className={style.detailLabel}>企业</div>
                        <div className={style.detailValue}>{item.company_count}</div>
                        <div className={style.detailUnit}>家</div>
                      </div>
                    </div>
                  ))}
                </Spin>
              </div>
            </div>
          </div>
          <div className={style.rightRecommend}>
            <div className={style.hotRecommend}>
              <div className={style.hotRecommendTitle}>
                专题
              </div>
              <div className={style.specialList}>
                {/* <Spin spinning={loading[2]}>
                  {useMemo(() => (
                    <RecommendNews data={recommendData} setLoginShow={setLoginShow} />
                  ), [recommendData, setLoginShow])}
                </Spin> */}
                {
                  specialList?.map(item => (
                    <div
                      key={item.value}
                      className={style.special}
                      onClick={() => {
                        specialPageRoute(item);
                      }}
                    >
                      <div>
                        <p>
                          <span />
                        </p>
                        <p>
                          <span>{item.title}</span>
                        </p>
                      </div>
                      <div>
                        <p />
                      </div>
                      {/* {
                        !(specializedJurisdiction?.includes(item.code)) && <div className={style.disabled}>未开通</div>
                      } */}
                    </div>
                  ))
                }
              </div>
            </div>
            <div className={style.myFocus}>
              <div className={style.myFocusTitle}>
                我的关注
                <div
                  className={style.focusMore}
                  onClick={() => {
                    if (!localStorage.getItem('token')) {
                      setLoginShow(true);
                    } else {
                      openNewPage('accountSetting', {
                        tab: '动态监测',
                      });
                    }
                  }}
                >
                  <IconFont type="iconswap-right" style={{ fontSize: '18px', marginRight: '4px' }} />
                  查看更多
                </div>
              </div>
              <div className={style.myFocusSubTitle}>
                关注企业
              </div>
              <div className={style.focusBody}>
                <Spin spinning={loading[3]}>
                  {useMemo(() => (
                    <FocusList data={focusList} setLoginShow={setLoginShow} getMyFocusList={getMyFocusList} />
                  ), [focusList, setLoginShow])}
                </Spin>
              </div>
              {buyIndustryList.length > 0 && (
                <div className={style.myFocusSubTitle}>
                  我的频道
                </div>)}
              <div className={style.bottomWord}>
                {useMemo(() => (
                  <OverflowIndustryList data={buyIndustryList} onClick={(e) => pushToIndustryChannel(e.value)} />
                ), [buyIndustryList, pushToIndustryChannel])}
                {useMemo(() => (
                  <OverflowIndustryList data={buyCityList} onClick={pushToCityChannel} />
                ), [buyCityList, pushToCityChannel])}
              </div>
            </div>
          </div>
        </div>
        <div className={style.report}>
          <div className={style.reportBody}>
            <div className={style.reportTitle}>研究报告</div>
            <div className={style.reportBody1}>
              <Spin spinning={loading[5]}>
                {reportLists}
              </Spin>
            </div>
          </div>
        </div>
        <div className={style.bottom}>
          <HomeBottom setLoginShow={setLoginShow} />
        </div>
      </div>
      {/* <SpecialPayModal /> */}
      {choseModel && (
        <ReportModal
          templateId={templateId}
          templateType={templateType}
          visible={choseModel}
          setVisible={setChoseModel}
          startAnalysis={analysisReportConditionChange}
          loading={choseLoading}
        />
      )}
      {
        useMemo(() => channelModalVisible && <ChannelModal
          defaultSelect={defaultBuyIndustryKey}
          type={payType}
          setPayType={setPayType}
          getSpecialList={getSpecialList}
          onClose={() => setChannelModalVisible(false)}
          paySuccessCb={(curBuyIndustrList) => {
            loadingRef.current[4] = true;
            setLoading(cloneDeep(loadingRef.current));
            getGroupIndustryLogin({
              group_name: selectIndustryClass,
            }).then(res1 => {
              if (res1.status === '00000') {
                setIndustryList(res1.data);
                loadingRef.current[4] = false;
                setLoading(cloneDeep(loadingRef.current));
              } else {
                loadingRef.current[4] = false;
                setLoading(cloneDeep(loadingRef.current));
              }
            });

            const urlParams = new URLSearchParams();
            urlParams.set('tab', '个人中心');
            props.history.push({
              pathname: 'accountSetting',
              search: urlParams.toString(),
            });

            // if (curBuyIndustrList.includes(defaultBuyIndustryKey)) {
            //   pushToIndustryChannel(defaultBuyIndustryKey);
            // }
          }}
        />, [channelModalVisible, setChannelModalVisible, defaultBuyIndustryKey, pushToIndustryChannel,
          selectIndustryClass])
      }
      <div className={style.tool}>
        <div>
          <span />
          <span>小程序</span>
          <div className={style.suspension} />
        </div>
        <div>
          <span />
          <span>公众号</span>
          <div className={style.suspension} />
        </div>
        <div>
          <span />
          <span>客服中心</span>
          <div className={style.suspension} />
        </div>
      </div>
      {/* <QRCode value={`${window.location.origin}${window.location.pathname}#/report?id=199" size={124}`} /> */}

      {/* <QRCode value="https://industrydata.cn/smart-ke/#/report?id=19920" size={124} /> */}
      {/* <QRCode value="https://industrydata.cn/smart-ke/#/reportList?id=99&coverUrl=https://industry-engine.ks3-cn-beijing.ksyun.com/prod/report/05894人工智能产业分析报告(2023)/content/3964596c71317a353164502f64476f4b55676a6651513d3d0a_0.png" size={124} /> */}
    </div>
  );
}

export default withRouter(HomePage);
