import React, { useState, forwardRef, useImperativeHandle } from 'react';
import style from './index.module.less';
import { IconFont } from '../../../../utils/mapIcon';
import Subtitle from '../../../../components/Subtitle';
import Table from '../../../../components/TableV2';
import { useGetCompanyPatentListQuery } from '../../../../service/industry-channel';

const Tablehandle = (item) => ({
  index: {
    width: 50,
    className: style.index,
  },
});

const ModalTable = forwardRef(({ onClick, id }, ref) => {
  const [page, setPage] = useState(1);
  const { data: tableData = {}, isFetching: tableIsFetching } = useGetCompanyPatentListQuery({
    company_id: id,
    page_number: page,
    page_size: 20,
  }, { skip: !id });
  // 指定暴露的对象 返回值就是暴露的对象 ref 就是上面的参数
  useImperativeHandle(ref, () => ({
    setPage,
  }));
  return (
    <div className={style.box}>
      <div className={style.header}>
        <Subtitle
          selectName="全部专利"
          description="共"
          value={tableData?.count || '-'}
          unit="条"
          className={style.subtitle}
        />
        <IconFont className={style.icon} type="iconclose" onClick={onClick} />
      </div>
      <Table
        Tablehandle={Tablehandle}
        data={tableData}
        isFetching={tableIsFetching}
        pagination={{
          current: page,
          pageSize: 20,
          showQuickJumper: true,
          showSizeChanger: false,
          total: tableData?.total,
          onChange: (current) => {
            setPage(current);
          },
          // onShowSizeChange: (size) => {
          //   setPageSize(size);
          // },
        }}
      />
    </div>
  );
});

export default ModalTable;
