import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';
import ReportCover from '../ReportCover';
import { IconFont } from '../../utils/mapIcon';

const withIcon = (Component, type) => props => <Component {...props} type={type} className={classnames(styles.icon, props.className)} />;
const EyeIcon = withIcon(IconFont, 'iconeye-fill');
const DownloadIcon = withIcon(IconFont, 'icondownload');
const Tag = ({ text }) => <span className={styles.tag} title={text}>{text}</span>;
const Info = ({ name, value }) => (
  <div className={styles.info}>
    <span>{name}</span>
    <span>{value}</span>
  </div>
);

/**
 * 封面
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
const Cover = ({ src, title, subtitle, coverId, area, desClick }) => {
  if (src) {
    return (
      <div className={styles.cover} onClick={() => desClick && desClick()}>
        <img src={src} alt={`${title}-${subtitle}`} />
      </div>
    );
  }

  let tmp;

  switch (coverId) {
    case '16,23':
      tmp = (
        <div className={classnames(styles.cover, styles.cover16)} onClick={() => desClick && desClick()}>
          <div className={styles.area}>{area}</div>
          <div className={styles.title}>区域画像报告</div>
        </div>
      );
      break;
    case '17,22':
      tmp = (
        <div className={classnames(styles.cover, styles.cover17)} onClick={() => desClick && desClick()}>
          <div className={styles.area}>
            {area}
            {title}
            <div className={styles.title}>产业分析报告</div>
          </div>
        </div>
      );
      break;
    case '18':
      tmp = (
        <div className={classnames(styles.cover, styles.cover18)} onClick={() => desClick && desClick()}>
          <div className={styles.company}>
            杭州海康威视数字技术股份有限公司
            <div className={styles.title}>企业尽调报告</div>
          </div>
        </div>
      );
      break;
    case '493':
      tmp = (
        <div className={classnames(styles.cover, styles.cover493)} onClick={() => desClick && desClick()}>
          <div className={styles.area}>
            {area}
            {title}
          </div>
          <div className={styles.title}>国家级专精特新分析报告</div>
        </div>
      );
      break;
    case '495,494':
      tmp = (
        <div className={classnames(styles.cover, styles.cover494)} onClick={() => desClick && desClick()}>
          <div className={styles.area}>
            {area}
            {title}
          </div>
          <div className={styles.title}>国高新企业分析报告</div>
        </div>
      );
      break;
    case '497,496':
      tmp = (
        <div className={classnames(styles.cover, styles.cover496)} onClick={() => desClick && desClick()}>
          <div className={styles.area}>
            {area}
            {title}
          </div>
          <div className={styles.title}>知识产权分析报告</div>
        </div>
      );
      break;
    case '498':
      tmp = (
        <div className={classnames(styles.cover, styles.cover498)} onClick={() => desClick && desClick()}>
          <div className={styles.area}>{title}</div>
          <div className={styles.title}>股权投资监测报告</div>
        </div>
      );
      break;
    case '499':
      tmp = (
        <div className={classnames(styles.cover, styles.cover499)} onClick={() => desClick && desClick()}>
          <div className={styles.area}>{title}</div>
          <div className={styles.title}>招商项目监测报告</div>
        </div>
      );
      break;
    default:
      tmp = (
        <div className={classnames(styles.cover)} onClick={() => desClick && desClick()}>
          <div className={styles.meta}>
            <div className={styles.title}>
              {area}
              {title}
            </div>
          </div>
        </div>
      );
      break;
  }

  return tmp;
};

const Extra = ({ readCount, downloadCount }) => (
  <div className={styles.extra}>
    <span>
      <EyeIcon />
      {readCount ?? 0}
    </span>
    <span>
      <DownloadIcon />
      {downloadCount ?? 0}
    </span>
  </div>
);

const Description = ({
  title,
  tag,
  author,
  date,
  readCount,
  downloadCount,
  desClick,
}) => (
  <div className={styles.desc} onClick={() => desClick && desClick()}>
    <div className={styles.title} title={title}>
      {title}
    </div>
    <div className={styles.tags}>
      <Tag text={tag} />
    </div>
    <div className={styles.meta}>
      <Info name="作&emsp;&emsp;者" value={author} />
      <Info name="发表时间" value={date} />
    </div>
  </div>
);

// 精品报告、模板

function ReportCard({
  title,
  tag,
  author,
  date,
  downloadCount,
  readCount,
  coverId,
  coverURL,
  coverScheme,
  coverTitle,
  coverSubtitle,
  coverArea,
  className,
  onClick,
}) {
  return (
    <div className={classnames(styles.reportCard, className)}>
      {
        coverScheme && Object.keys(coverScheme).length > 0 && (
          <div className={styles.cover}>
            <ReportCover
              coverScheme={coverScheme}
              textMap={{ INDUSTRY_NAME: coverTitle, AREA_NAME: coverArea }}
              scale="scale(0.19899, 0.199465)"
            />
          </div>
        )
      }
      {
        (!coverScheme || Object.keys(coverScheme).length === 0) && (
          <Cover
            src={coverURL}
            title={coverTitle}
            subtitle={coverSubtitle}
            coverId={coverId}
            area={coverArea}
            desClick={() => onClick && onClick()}
          />
        )
      }
      <Extra readCount={readCount} downloadCount={downloadCount} />
      <Description
        title={title}
        tag={tag}
        author={author}
        date={date}
        desClick={() => onClick && onClick()}
      />
    </div>
  );
}

export default ReportCard;
