import React, { useState, useMemo } from 'react';
import ConditionEvaluation from './components/ConditionEvaluation';
import UploadAssessment from './components/UploadAssessment';
import style from './index.module.less';

const tag = [{ title: '条件测评', value: 'condition' }, { title: '上传测评', value: 'uploading' }];

const Evaluation = () => {
  const { href } = window.location;
  const routeParams = useMemo(() => new URLSearchParams(href.split('?')[1]), [href]);
  const recordId = routeParams.get('uploadRecordId') || localStorage.getItem('uploadRecordId');
  const [active, setActive] = useState(routeParams.get('type') || 'condition');
  const [uploadRecordId, setUploadRecordId] = useState(recordId);

  const switchTag = (tagValue) => {
    setActive(tagValue);
  };
  return (
    <div className={style.box}>
      <div className={style.top}>
        <div className={style.title}>
          {
            tag.map(item => <div key={item.title} className={active === item.value ? style[active] : ''} onClick={() => { switchTag(item.value); }}>{item.title}</div>)
          }
        </div>

      </div>
      {active === 'condition' ? <ConditionEvaluation /> : <UploadAssessment uploadRecordId={uploadRecordId} setUploadRecordId={setUploadRecordId}/>}
    </div>
  );
};

export default Evaluation;
