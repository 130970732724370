import { IBarVisualConfig, ILineVisualConfig } from '../interface/config';
import { getDataItem, omitUndefined } from './utils';
import { ISeriesGetter, SeriesTypes } from '../interface/base';
import { merge } from 'lodash';
import { OptionParser } from './optionParser';
import { IDataItemObject } from '../interface/data';

export const getEach = (config: any) => {
  const {
    // global
    smooth,
    step,
    stack,
    // symbol
    showSymbol,
    symbol,
    symbolSize,
    symbolOffset,
    itemStyle,
    lineStyle,
    label,
    // area
    showArea,
    areaStyle = {},
  } = config;

  return omitUndefined({
    smooth,
    stack,
    step,
    showSymbol,
    symbol,
    symbolSize,
    symbolOffset,
    itemStyle,
    lineStyle,
    label,
    areaStyle: showArea ? areaStyle : undefined,
  });
};


export const getLineSeries: ISeriesGetter<IBarVisualConfig> = (series, index, extra) => {
  const type: SeriesTypes = (series.type as SeriesTypes) || 'line';
  const {
    material,
    optionParser,
    getMatchedItemStyle,
  } = extra;
  const { config } = material;
  const { visualConfig } = config;
  const { series: seriesStyles, coordinateSystem, } = visualConfig;
  const seriesStyle = seriesStyles ? seriesStyles[index]: undefined;
  const { name, data, xAxisIndex, yAxisIndex, polarIndex, geoIndex }  = series;
  const seriesProperty = {
    seriesName: name,
    seriesIndex: index,
  };
  const seriesItem = {
    name,
    type,
    coordinateSystem,
    xAxisIndex,
    yAxisIndex,
    polarIndex,
    geoIndex,
  };
  const categoryAxis = Object.values(optionParser.getAxis(seriesItem as any)).flat().filter(OptionParser.isAxisCategory);
  const seriesData = data.map((d, index) => {
    const item = getDataItem(d);
    const _d = {
      ...seriesProperty,
      type,
      category: categoryAxis.length !== 0 ? categoryAxis[0].data[index] as string : undefined,
      ...(item as IDataItemObject),
      index,
    };
    const matcherStyle = getMatchedItemStyle(_d);

    if (Object.keys(matcherStyle).length === 0) {
      return d;
    }
    return ({
      ...item,
      ...getEach(matcherStyle),
    });
  });

  return omitUndefined(
    merge(
      seriesItem,
      getEach(visualConfig),
      { data: seriesData },
      seriesStyle ? getEach(seriesStyle) : {},
    )
  );
}
