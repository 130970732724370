import { PATENTDETAIL } from '../utils/url';
import { get } from '../utils/request';

// 专利详情-详情信息
const getPatentInfo = (params) => get(`${PATENTDETAIL}/patent_info`, {
  ...params,
});

// 专利详情-详情信息
const getLikePatent = (params) => get(`${PATENTDETAIL}/find_like_patent`, {
  ...params,
});

export {
  getPatentInfo,
  getLikePatent,
};
