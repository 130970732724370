// 漏斗图
import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { FunnelChartProps } from '../basicsType';
// import { modeStyle, chartFontSize } from './style';
import { addWaterMark, getDefaultCanvas, handleToolTipLongTitle } from '../../utils/tool';

import './index.less';
import '../../index.less';
import { modeStyle, chartFontSize } from './style';

const prefixCls: string = 'cube-component-funnel-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};
const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const FunnelChart = forwardRef((props: FunnelChartProps, ref: any) => {
  const { title = '', data, mode = 'dark', sort, legend, fontSize } = props;
  const [option, setOption] = useState({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    const curStyle = modeStyle[mode];
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    const {
      colorList,
      titleColor, numColor,
      percentColor,
    } = curStyle;

    let max = 0;
    const curList1 = [...curStyle.colorList1];
    const colorList0 = [...curStyle.colorList];
    if (data.length < curStyle.colorList1.length) {
      curList1.splice(0, 1);
      colorList0.splice(0, 1);
    }
    // 左侧漏斗图
    const dataShow = data.map((item, index) => {
      // max = Math.max(+item.amount, max);
      max = Math.max((index + 1) * 25, max);
      return {
        // value: item.amount,
        value: (index + 1) * 25,
        name: item.name,
        percent: item.percent,
        show: {
          ...item,
        },
        itemStyle: {
          borderWidth: 0,
          color: {
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0, color: curList1[index % curList1.length],
              },
              {
                offset: 1, color: colorList0[index % colorList0.length],
              },
            ],
            global: false,
          },
        },
      };
    });
    // @ts-ignore 右侧背景
    const dataMiddle = data.map((item: any, index: number) => {
      const realIndex = data.length - 1 - index;
      return {
        // value: item.amount,
        value: (realIndex + 1) * 25,
        name: data[realIndex].name, // 倒序
        show: {
          ...data[index],
        },
        itemStyle: {
          borderWidth: 0,
          color: {
            type: 'linear',
            x: 1,
            y: 0,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0, color: curStyle.offsetColor0,
              },
              {
                offset: 1, color: curStyle.offsetColor1,
              },
            ],
            global: false,
          },
        },
      };
    });
    // 右侧文字
    const dataRight = data.map((_item, index) => {
      const realIndex = data.length - 1 - index;
      return {
        // value: data[realIndex].amount,
        value: (realIndex + 1) * 25,
        name: data[realIndex].name, // 倒序
        show: {
          ...data[index],
        },
        itemStyle: {
          borderWidth: 0,
          color: {
            type: 'linear',
            x: 1,
            y: 0,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0, color: 'rgba(0,0,0,0)',
              },
              {
                offset: 1, color: 'rgba(0,0,0,0)',
              },
            ],
            global: false,
          },
        },
      };
    });
    const tooltipSwitch = (params: any) => {
      const { name, amount, proportion } = params.data.show;
      let str = '<div style="padding: 9px 9px 4px 9px;">';
      str += `<div style="margin-bottom: 10px; font-size: 14px; font-weight: bold; color: ${curStyle.tooltipFont[0]};letter-spacing: 1.75px;">${handleToolTipLongTitle(name)}</div>`;

      str += `<div style="display: flex; justify-content: space-between; color: ${curStyle.tooltipFont[2]};">
        <span style="margin-right: 60px">${amount || 0}家</span>
        <span>
          <span style="color: ${curStyle.tooltipFont[3]};font-weight: 600;font-size: 18px;margin-right: 4px;">${proportion || ''}</span>
        </span>
      </div>`;

      str += '</div>';
      return str;
    };
    const instance = chartRef.current.getEchartsInstance();
    const containerHeight = instance.getHeight() - 20; // 20 是top bottom
    const perItemHeight = (containerHeight - (dataShow.length - 1) * 2) / dataShow.length; // 2是gap
    const dataShowLeft = curFont.bodyLeft;
    const dataShowRightLeft = dataShowLeft + (curFont.bodyWidth / 2);
    const dataShowMiddleLeft = dataShowRightLeft;
    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (p: any) => tooltipSwitch(p),
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      color: colorList,
      legend: {
        show: legend,
      },
      series: [
        {
          z: 5,
          type: 'funnel',
          left: `${dataShowLeft}%`,
          top: 10,
          bottom: 5,
          width: `${curFont.bodyWidth}%`,
          min: 0,
          max,
          minSize: '0%',
          maxSize: '100%',
          sort,
          gap: 2,
          label: {
            show: true,
            position: 'inside',
            // height: '100%',
            formatter: (p: any) => {
              const { name } = p.data.show;
              return `{name|${name}}`;
            },
            rich: {
              name: {
                padding: [perItemHeight / 1.8, 0, 0, 0],
                textBorderWidth: 0,
                verticalAlign: 'bottom',
                fontSize: curFont.bodyFontSize,
                color: titleColor,
                fontWeight: 'bold',
              },
            },
          },
          data: dataShow,
        },
        {
          show: true,
          z: 1,
          type: 'funnel',
          left: `${dataShowMiddleLeft}%`,
          top: 10,
          bottom: 5,
          width: `${curFont.bodyWidth}%`,
          sort: 'desending',
          min: 0,
          max,
          minSize: '100%',
          maxSize: '100%',
          gap: 2,
          label: {
            show: false,
            position: 'outside',
          },
          labelLine: {
            length: 0,
          },
          data: dataMiddle,
          silent: true,
          legendHoverLink: false,
        },
        {
          z: 4,
          type: 'funnel',
          left: `${dataShowRightLeft}%`,
          top: 10,
          bottom: 5,
          width: `${curFont.bodyWidth}%`,
          min: 0,
          max,
          minSize: '0%',
          maxSize: '100%',
          sort: 'desending',
          gap: 2,
          label: {
            show: true,
            position: 'insideLeft',
            padding: [0, 0, 0, curFont.descLeft],
            textBorderWidth: 0,
            verticalAlign: 'middle',
            formatter: (p: any) => {
              const { amount, proportion } = p.data.show;
              const str = `{num|${amount || 0}家}${(proportion || '') && '\n'}{percent|${proportion || ''}}`;
              return str;
            },
            rich: {
              num: {
                fontSize: curFont.numFontSize,
                color: numColor,
                fontWeight: 400,
              },
              percent: {
                padding: [curFont.descTop, 0, 0, 1],
                fontSize: curFont.descFontSize,
                color: percentColor,
                fontWeight: 'normal',
                // fontStyle: 'italic',
              },
            },
          },
          labelLine: {
            length: 0,
          },
          emphasis: {
            label: {
              show: true,
            },
          },
          data: dataRight,
          tooltip: {
            show: false,
          },
        },
      ],
    };
    setOption(options);
  }, [data, legend, mode, sort, title, fontSize]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!data || !data.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode as 'light'] || styleDark} ref={bodyRef}>
      {
        chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)
      }
      {
        !chartLoading && (!data || !data.length) && (<div className={styleEmpty}>暂无数据</div>)
      }
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>
  );
});
FunnelChart.defaultProps = {
  data: [],
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
export default FunnelChart;
