/*
* Author: zhangmeng
* Description: '企业评价-雷达图'
* Date: 2020/11/30
*/

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

const bgColor = ['rgba(101, 144, 242, 0.4)', 'rgba(125, 213, 169, 0.4)', 'rgba(96, 112, 143, 0.4)', 'rgba(238, 190, 69, 0.6)', 'rgba(107, 99, 240, 0.4)'];

function RadarChart(props) {
  const { data } = props;
  const { abscissa_list: abscissaList, detail_list: detailList } = data;
  const [option, setOption] = useState({});
  useEffect(() => {
    const options = {
      color: ['#6590f2', '#7dd5a9', '#60708f', '#eebe45', '#6b63f0'],
      // title: {
      //   text: 'Customized Radar Chart',
      // },
      legend: {
        show: true,
        bottom: 20,
        // left: 20,
        itemWidth: 10,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: detailList?.map(item => item.name),
      },
      tooltip: {
        show: true,
      },
      radar: [
        {
          center: ['50%', '50%'],
          radius: 120,
          startAngle: 90,
          splitNumber: 4,
          shape: 'circle',
          axisName: {
            formatter: '{value}',
            color: '#428BD4',
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(210, 234, 255, 0.4)', 'rgba(210, 234, 255, 0.3)', 'rgba(210, 234, 255, 0.2)', 'rgba(210, 234, 255, 0.1)'],
              shadowColor: 'rgba(0, 0, 0, 0.2)',
              shadowBlur: 10,
            },
          },
          axisLine: {
            lineStyle: {
              color: '#D2EAFF',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#D2EAFF',
            },
          },
          indicator: abscissaList?.map((item, index) => (
            {
              name: item,
              // max: 1200,
              min: 0,
              max: 100,
              color: '#919191',
              axisLabel: { show: index === 0, color: '#919191' },
              axisTick: { show: index === 0 },
              axisLine: { show: index === 0, lineStyle: { color: '#BFBFBF' } },
            }
          )),
        },
      ],
      series: [
        {
          type: 'radar',
          emphasis: {
            lineStyle: {
              width: 4,
            },
          },
          data: detailList?.map((item, index) => (
            {
              value: item.value,
              name: detailList[index]?.name,
              areaStyle: {
                color: bgColor[index],
              },
            }
          )),
        },
      ],
    };
    setOption(options);
  }, [data]);

  return (
    <ReactEcharts
      option={option}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default RadarChart;
