import React, { useMemo, useEffect, memo } from 'react';
import Table from '../../../../../components/Table';
import styles from './index.module.less';
import {
  useGetIndustryCarrierTypeListQuery,
  useGetIndustryCarrierLevelListQuery,
} from '../../../../../service/city-channel';
import {
  useGetCityDetailFindIndustryCarrierListQuery,
} from '../../../../../service/cityDetail';
import {
  useGetIndustryTreeQuery,
} from '../../../../../service/interactive-v2';
import CustomTreeFilter from '../../../../../components/CustomTreeFilter';
import CustomListFilter from '../../../../../components/CustomListFilter';
import { FilterIcon } from '../../../../../components/CustomTableIcon';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  carrier: {
    title: '名称',
    dataIndex: 'carrier',
    key: 'carrier',
    width: 400,
    ellipsis: true,
  },
  label_detail_type: {
    title: '类别',
    dataIndex: 'label_detail_type',
    key: 'label_detail_type',
    width: 150,
    ellipsis: true,
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  label_level: {
    title: '级别',
    dataIndex: 'label_level',
    key: 'label_level',
    width: 120,
    ellipsis: true,
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  support_unit_name: {
    title: '依托单位',
    dataIndex: 'support_unit_name',
    key: 'support_unit_name',
    width: 450,
    ellipsis: true,
    className: styles.name,
  },
  industry: {
    title: '产业',
    dataIndex: 'industry',
    key: 'industry',
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
    filterMode: 'tree',
    ellipsis: true,
  },
};

const COLUMN_KEYS = ['index', 'carrier', 'label_detail_type', 'label_level', 'support_unit_name', 'industry'];

const InnovativeTable = memo((props) => {
  const {
    keyword,
    regionCode,
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    filteredInfo = {},
    getCount,
    setParams,
  } = props;
  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetCityDetailFindIndustryCarrierListQuery({
    key_word: keyword,
    industry_code_list: filteredInfo.industry?.filter(tag => tag !== 'all') ?? [],
    area_key: regionCode,
    type_list: filteredInfo.label_detail_type?.filter(tag => tag !== 'all') ?? [],
    level_list: filteredInfo.label_level?.filter(tag => tag !== 'all') ?? [],
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !regionCode,
  });
  const { tableHead = [], tableBody = [], total = 0, count = 0 } = companyData ?? {};
  const { data: companyTags = [] } = useGetIndustryCarrierTypeListQuery();
  const { data: levelList = [] } = useGetIndustryCarrierLevelListQuery();
  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );

  const createLink = (row) => (row?.support_unit?.map((item, index) => (
    <div key={index} className={styles.tabLink} style={{ color: !item.company_id && 'rgba(0, 0, 0, 0.85)' }} onClick={() => { onCompanyClick({ _id: item.company_id }); }}>
      {item.name}
    </div >
  ))
  );
  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      // 处理企业名称
      if (item.dataIndex === 'support_unit_name') {
        rowConfig.render = (text, row) => createLink(row);
      }
      if (item.dataIndex === 'label_detail_type') {
        rowConfig.filteredValue = filteredInfo.label_detail_type?.length > 0 ? filteredInfo.label_detail_type : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = companyTags.map(item2 => ({ ...item2, text: item2.value }));
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (item.dataIndex === 'industry') {
        rowConfig.filters = industryTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} multiple={true} />;
        rowConfig.filteredValue = filteredInfo?.industry ?? ['all'];
      }
      if (item.dataIndex === 'label_level') {
        rowConfig.filteredValue = filteredInfo.label_level?.length > 0 ? filteredInfo.label_level : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = levelList.map(item2 => ({ ...item2, text: item2.value }));
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      return rowConfig;
    });
  }, [onCompanyClick, tableHead, companyTags, levelList, filteredInfo, regionCode, industryTree]);

  useEffect(() => {
    getCount(count);
  }, [getCount, count]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        industry_code_list: filteredInfo.industry?.filter(tag => tag !== 'all') ?? [],
        area_key: regionCode,
        type_list: filteredInfo.label_detail_type?.filter(tag => tag !== 'all') ?? [],
        level_list: filteredInfo.label_level?.filter(tag => tag !== 'all') ?? [],
      });
    }
  }, [keyword, filteredInfo, regionCode]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
    />
  );
});

export default InnovativeTable;
