import React, { useState, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { Table } from '@sqke/design';
import { useHistory } from 'react-router-dom';
import { RedoOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { DetailedEvaluation } from '../tableData';
import {
  usePutAssessCompanyIndexQuery,
  useGetEstablishmentPeriodQuery,
  useGetUndertakeResearchInstitutesQuery,
  useGetBidsNumberQuery,
  useGetProprietaryIntellectualPropertyRightsQuery,
  useGetFinancingOrNotQuery,
  useGetTotalNumberOfPatentsGrantedQuery,
  useGetTotalNumberOfInventPatentsGrantedQuery,
  useGetTotalNumberOfOtherPatentsGrantedQuery,
  useGetThreeYearsDraftingStandardNumberQuery,
  useGetThreeYearsDevelopBranchNumberQuery,
  useGetRegisteredCapitalIncreaseQuery,
  useGetSocialSecurityNumberQuery,
} from '../../../../service/tool';
import { generateParam } from './utils';
import TrabeculaCharts from '../../../../components/TrabeculaCharts';

// import Treemap from '../../../../components/Charts/TreeMap';
import style from './index.module.less';

const infoKeyMap = {
  companyId: 'company_id',
  patent: 'invent_patent',
  practicalPatent: 'practical_patent',
  designPatent: 'design_patent',
  copyright: 'software_copyright',
  YFRS: 'development_proportion',
  cnExpenditure: 'cn_expenditure',
  serviceCharge: 'service_charge',
  grossRevenue: 'gross_revenue',
  intellectualProperty: 'intellectual_property',
};

const TestResult = () => {
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const goParams = new URLSearchParams();
  const [top, setTop] = useState(0);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [trabeculaChartsH, setTrabeculaChartsH] = useState(32);

  // 转换请求对象的key
  useEffect(() => {
    const info = JSON.parse(localStorage.getItem('companyInfoForm'));
    try {
      Object.keys(info).forEach(item => {
        if (!info[item]) {
          delete info[item];
        } else if (infoKeyMap[item]) {
          info[infoKeyMap[item]] = info[item];
          delete info[item];
        }
      });
      if (info.violation === '是') {
        info.violation = true;
      } else {
        info.violation = false;
      }
      setCompanyInfo(info);
    } catch {
      history.push({
        pathname: '/companyDetail',
        search: params.toString(),
      });
    }
  }, []);

  // 后退到上一页
  const retreat = () => {
    goParams.set('name', params.get('name'));
    goParams.set('id', params.get('id'));
    history.push({
      pathname: '/tool/companyTest/companyInfo',
      search: params.toString(),
    });
  };

  // 获取企业评测结果
  const { data: evaluationInfo, isFetching: tableIsFetching } = usePutAssessCompanyIndexQuery(companyInfo, { skip: !companyInfo });
  const { score, name, fail_list: failList, result: resultList } = evaluationInfo || {};

  // 获取企业成立年限
  const { data: establishmentPeriodData, isFetching: establishmentPeriodIsFetching } = useGetEstablishmentPeriodQuery(generateParam({ paramName: 'establishmentPeriodParams', companyInfo }), { skip: !companyInfo });
  // 承担科研情况
  const { data: undertakeResearchInstitutesData, isFetching: undertakeResearchInstitutesIisFetching } = useGetUndertakeResearchInstitutesQuery();
  // 近三年中标数量
  const { data: bidsNumberData, isFetching: bidsNumberDataIisFetching } = useGetBidsNumberQuery({ company_id: companyInfo?.company_id }, { skip: !companyInfo });
  // 自主知识产权
  const { data: proprietaryIntellectualPropertyRightsData, isFetching: proprietaryIntellectualPropertyRightsIisFetching } = useGetProprietaryIntellectualPropertyRightsQuery(generateParam({ paramName: 'proprietaryIntellectualPropertyRightsParams', companyInfo }), { skip: !companyInfo });
  // console.log(res);
  // 获得融资
  const { data: financingOrNotData, isFetching: financingOrNotIsFetching } = useGetFinancingOrNotQuery();
  // 专利授权总量
  const { data: patentsGrossData, isFetching: patentsGrossIsFetching } = useGetTotalNumberOfPatentsGrantedQuery(generateParam({ paramName: 'patentsGrossParam', companyInfo }), { skip: !companyInfo });
  // 近三年专利数总量
  const { data: totalNumberOfInventPatentsGrantedData, isFetching: totalNumberOfInventPatentsGrantedIsFetching } = useGetTotalNumberOfInventPatentsGrantedQuery({ company_id: companyInfo?.company_id }, { skip: !companyInfo });
  // 近三年外观专利
  const { data: totalNumberOfOtherPatentsGrantedData, isFetching: totalNumberOfOtherPatentsGrantedIsFetching } = useGetTotalNumberOfOtherPatentsGrantedQuery({ company_id: companyInfo?.company_id }, { skip: !companyInfo });
  // 近三年起草标准
  const { data: threeYearsDraftingStandardNumberData, isFetching: threeYearsDraftingStandardNumberIsFetching } = useGetThreeYearsDraftingStandardNumberQuery({ company_id: companyInfo?.company_id }, { skip: !companyInfo });
  // 近三年设立子公司数量
  const { data: threeYearsDevelopBranchNumberData, isFetching: threeYearsDevelopBranchNumberIsFetching } = useGetThreeYearsDevelopBranchNumberQuery({ company_id: companyInfo?.company_id }, { skip: !companyInfo });
  // 近三年注册资本 useGetRegisteredCapitalIncreaseQuery
  const { data: registeredCapitalIncreaseData, isFetching: registeredCapitalIncreaseFetching } = useGetRegisteredCapitalIncreaseQuery();
  // 社保人数
  const { data: socialSecurityNumberData, isFetching: socialSecurityNumberFetching } = useGetSocialSecurityNumberQuery({ company_id: companyInfo?.company_id }, { skip: !companyInfo });

  // 企业情况列
  const condition = (text, index) => {
    const keyMap = {
      外观设计专利: 'design_patent',
      授权发明专利: 'invent_patent',
      实用新型专利: 'practical_patent',
      软件著作权: 'software_copyright',
    };
    if (resultList) {
      if (typeof resultList[index].value === 'string') {
        if (text === '产品（服务）不属于规定的范围') {
          return `${resultList[index].value}`;
        }
        return `${text}${resultList[index].value}`;
      }
      if (typeof resultList[index].value === 'number') {
        return `${text}${resultList[index].value}%`;
      }
      if (resultList[index].index === '核心自主知识产权') {
        const obj = resultList[index].value;
        const patentText = [];
        Object.keys(keyMap).forEach((item) => {
          if (obj[keyMap[item]]) {
            patentText.push(`${item}${obj[keyMap[item]]}个`);
          }
        });
        return patentText.join('\n');
      }
      if (resultList[index].index === '研发费用') {
        return `企业销售收入所属区间 ${resultList[index].value.section}（单位：万元）\n 比例${resultList[index].value.proportion}%`;
      }
    }
    return '-';
  };

  const meetTheConditions = (index) => {
    if (!resultList) return '-';
    if (resultList[index].satisfy_condition) {
      return '是';
    }
    return '否';
  };

  const columns = [
    {
      title: '指标名称',
      dataIndex: 'name',
      key: 'name',
      width: 226,
      render: (text, row, index) => <div className={style.standard}>{text}</div>,
    },
    {
      title: '指标要求',
      dataIndex: 'standard',
      key: 'standard',
      width: 586,
      render: (text, row, index) => {
        if (typeof text === 'string') {
          return <div className={style.standard}>{text}</div>;
        }
        return (
          <div className={style.standardList}>
            {
              text.map(item => (
                <div key={Object.keys(item)[0]}>
                  <span>{Object.keys(item)[0]}</span>
                  <span>{item[Object.keys(item)[0]]}</span>
                </div>
              ))
            }
          </div>
        );
      },
    },
    {
      title: '企业情况',
      dataIndex: 'condition',
      key: 'condition',
      width: 428,
      render: (text, row, index) => <div className={style.standard}>{condition(text, index)}</div>,
    },
    {
      title: '是否满足',
      dataIndex: 'satisfy',
      key: 'satisfy',
      width: 107,
      // eslint-disable-next-line no-nested-ternary
      render: (text, row, index) => <div className={style.standard}>{meetTheConditions(index)}</div>
      ,
    },
  ];

  // 返回符合不符合字符串
  const result = () => {
    if (score) {
      if (score === 9) {
        return '符 合';
      }
      return '待达标';
    }
    return '-';
  };

  // 返回评测结果描述文本
  const resultDescribe = () => {
    if (failList) {
      if (failList.length) {
        return (
          <>
            您的
            {' '}
            <span style={{ color: '#E45500' }} >{failList.join('、')}</span>
            {' '}
            不满足高新技术企业申报要求
          </>
        );
      }
      return '您的各项指标均符合高新技术企业标准要求';
    }
    return '-';
  };
  return (
    <div className={style.testDetails}>
      <header>
        <div className={style.portrayalLogo} />
        <div className={style.title}>
          <div>高企画像</div>
          &nbsp;&nbsp;
          <div>
            <span>结果</span>
          </div>
        </div>
      </header>
      <div className={style.content}>
        <div style={{ position: 'relative' }}>
          <div className={style.testResult}>
            <div className={style.result}>
              {result()}
            </div>
            <div className={style.companyName}>
              <span>{name || '-'}</span>
              <span>
                {resultDescribe()}
              </span>
            </div>
          </div>
          <button type="button" className={style.rework} onClick={retreat}>
            <span>
              <RedoOutlined className={style.reworkIcon} />
            </span>
            &nbsp;
            <span>重新评估</span>
          </button>
        </div>
        <div className={style.tableBox}>
          <div className={style.score}>
            <span>高企评分</span>
            <span>
              {score || '-'}
              分
            </span>
          </div>
          <Spin spinning={tableIsFetching} size="large">
            <div className={style.table}>
              <Table
                rowKey="select"
                columns={columns}
                dataSource={DetailedEvaluation}
                // autoMerge
                strip
                pagination={{
                  defaultPageSize: 20,
                  hideOnSinglePage: true,
                }}
              />
            </div>
          </Spin>
        </div>
        <div className={style.chartHeader}>
          <div className={style.portrayalLogo} />
          <div className={style.title}>
            <div>高企画像报告</div>
            &nbsp;
            <div>
              <QuestionCircleOutlined
                style={{ fontSize: '14px', color: '#fff' }}
              />
              &nbsp;&nbsp;
              <span className={style.hint}>
                统计近两年首次申请成为高新技术企业时的各项指标生成画像，对比企业自身所处位置
              </span>
            </div>
          </div>
        </div>
        <div className={style.charts}>
          {/* <div className={style.portrayal}>
            <div className={style.content}> */}
          <Spin spinning={establishmentPeriodIsFetching}>
            <div className={style.companyAge}>
              <div>{establishmentPeriodData?.title || '-'}</div>
              <div>
                <div>
                  <span>成立年份均值</span>
                  <span>{establishmentPeriodData?.average === undefined ? '-' : establishmentPeriodData.average}</span>
                </div>
                <div>
                  <span>成立年份中位数</span>
                  <span>{establishmentPeriodData?.median === undefined ? '-' : establishmentPeriodData.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={establishmentPeriodData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                    setTop={setTop}
                    locationIndex={establishmentPeriodData?.self.index}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${establishmentPeriodData?.self.index * trabeculaChartsH || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `成立${establishmentPeriodData?.self.date || '-'}超过${establishmentPeriodData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>

          {/* <div className={style.industrialDistribution}>
            <div>产业分布</div>
            <div className={style.distribution}>
              <div className={style.treeMap}>
                <Treemap data={terrData} isFetching={false} proportion="100%" />
              </div>
              <div className={style.myLocation} style={{ paddingTop: '84px' }}>
                <div className={style.sign}>
                  <span className={style.line} />
                  <span className={style.lineText}>我的位置</span>
                  <span className={style.line} />
                  <span className={style.arrows} />
                </div>
                <div className={style.describe}>
                  成立3年4个月，超过20%高新技术企业
                </div>
              </div>
            </div>
          </div> */}
          <Spin spinning={undertakeResearchInstitutesIisFetching}>
            <div className={style.strip}>
              <div>{undertakeResearchInstitutesData?.title}</div>
              <div className={style.stripChart}>
                {undertakeResearchInstitutesData?.series[0].data.map((item, index) => (
                  <div key={index}>
                    <span>{item.name}</span>
                    <div>
                      <span>
                        {item.value}
                        家
                      </span>
                      <span>
                        {item.proportion}
                        %
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Spin>
          <Spin spinning={bidsNumberDataIisFetching}>
            <div className={style.companyAge}>
              <div>{bidsNumberData?.title}</div>
              <div>
                <div>
                  <span>近三年中标数量均值</span>
                  <span>{bidsNumberData?.average === undefined ? '-' : bidsNumberData.average}</span>
                </div>
                <div>
                  <span>近三年中标数量中位数</span>
                  <span>{bidsNumberData?.median === undefined ? '-' : bidsNumberData.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={bidsNumberData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${trabeculaChartsH * bidsNumberData?.self.index || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `近三年中标数量${bidsNumberData?.self.date.toString() || '-'}，超过${bidsNumberData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <Spin spinning={proprietaryIntellectualPropertyRightsIisFetching}>
            <div className={style.companyAge}>
              <div>{proprietaryIntellectualPropertyRightsData?.title}</div>
              <div>
                <div>
                  <span>自主知识产权数量均值</span>
                  <span>{proprietaryIntellectualPropertyRightsData?.average === undefined ? '-' : proprietaryIntellectualPropertyRightsData.average}</span>
                </div>
                <div>
                  <span>自主知识产权数量中位数</span>
                  <span>{proprietaryIntellectualPropertyRightsData?.median === undefined ? '-' : proprietaryIntellectualPropertyRightsData?.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={proprietaryIntellectualPropertyRightsData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${trabeculaChartsH * proprietaryIntellectualPropertyRightsData?.self.index || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `自主知识产权数量${proprietaryIntellectualPropertyRightsData?.self.date.toString() || '-'}，超过${proprietaryIntellectualPropertyRightsData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <Spin spinning={financingOrNotIsFetching}>
            <div className={style.strip}>
              <div>{financingOrNotData?.title}</div>
              <div className={style.stripChart}>
                {financingOrNotData?.series[0].data.map((item, index) => (
                  <div key={index}>
                    <span>{item.name}</span>
                    <div>
                      <span>
                        {item.value}
                        家
                      </span>
                      <span>
                        {item.proportion}
                        %
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Spin>
          <Spin spinning={patentsGrossIsFetching}>
            <div className={style.companyAge}>
              <div>{patentsGrossData?.title}</div>
              <div>
                <div>
                  <span>专利授权数量均值</span>
                  <span>{patentsGrossData?.average === undefined ? '-' : patentsGrossData?.average}</span>
                </div>
                <div>
                  <span>专利授权数量中位数</span>
                  <span>{patentsGrossData?.median === undefined ? '-' : patentsGrossData?.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={patentsGrossData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                  // setTop={setTop}
                  // locationIndex={patentsGrossData?.self.index}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${trabeculaChartsH * patentsGrossData?.self.index || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `专利授权数量${patentsGrossData?.self.date.toString() || '-'}，超过${patentsGrossData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <Spin spinning={totalNumberOfInventPatentsGrantedIsFetching}>
            <div className={style.companyAge}>
              <div>{totalNumberOfInventPatentsGrantedData?.title}</div>
              <div>
                <div>
                  <span>近三年授权发明专利数量均值</span>
                  <span>{totalNumberOfInventPatentsGrantedData?.average === undefined ? '-' : totalNumberOfInventPatentsGrantedData.average}</span>
                </div>
                <div>
                  <span>近三年授权发明专利数量中位数</span>
                  <span>{totalNumberOfInventPatentsGrantedData?.median === undefined ? '-' : totalNumberOfInventPatentsGrantedData?.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={totalNumberOfInventPatentsGrantedData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${trabeculaChartsH * totalNumberOfInventPatentsGrantedData?.self.index || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `近三年授权发明专利数量${totalNumberOfInventPatentsGrantedData?.self.date.toString() || '-'}，超过${totalNumberOfInventPatentsGrantedData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <Spin spinning={totalNumberOfOtherPatentsGrantedIsFetching}>
            <div className={style.companyAge}>
              <div>{totalNumberOfOtherPatentsGrantedData?.title}</div>
              <div>
                <div>
                  <span>近三年外观设计专利总量均值</span>
                  <span>{totalNumberOfOtherPatentsGrantedData?.average === undefined ? '-' : totalNumberOfOtherPatentsGrantedData?.average}</span>
                </div>
                <div>
                  <span>近三年外观设计专利总量中位数</span>
                  <span>{totalNumberOfOtherPatentsGrantedData?.median === undefined ? '-' : totalNumberOfOtherPatentsGrantedData.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={totalNumberOfOtherPatentsGrantedData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${trabeculaChartsH * totalNumberOfOtherPatentsGrantedData?.self.index || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `近三年外观设计专利总量${totalNumberOfOtherPatentsGrantedData?.self.date.toString() || '-'}，超过${totalNumberOfOtherPatentsGrantedData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <Spin spinning={threeYearsDraftingStandardNumberIsFetching}>
            <div className={style.companyAge}>
              <div>{threeYearsDraftingStandardNumberData?.title}</div>
              <div>
                <div>
                  <span>近三年新起草标准数量均值</span>
                  <span>{threeYearsDraftingStandardNumberData?.average === undefined ? '-' : threeYearsDraftingStandardNumberData.average}</span>
                </div>
                <div>
                  <span>近三年新起草标准数量中位数</span>
                  <span>{threeYearsDraftingStandardNumberData?.median === undefined ? '-' : threeYearsDraftingStandardNumberData.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={threeYearsDraftingStandardNumberData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${trabeculaChartsH * threeYearsDraftingStandardNumberData?.self.index || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `近三年新起草标准数量数量${threeYearsDraftingStandardNumberData?.self.date.toString() || '-'}，超过${threeYearsDraftingStandardNumberData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <Spin spinning={threeYearsDevelopBranchNumberIsFetching}>
            <div className={style.companyAge}>
              <div>{threeYearsDevelopBranchNumberData?.title}</div>
              <div>
                <div>
                  <span>近三年设立分子公司数量均值</span>
                  <span>{threeYearsDevelopBranchNumberData?.average === undefined ? '-' : threeYearsDevelopBranchNumberData.average}</span>
                </div>
                <div>
                  <span>近三年设立分子公司数量中位数</span>
                  <span>{threeYearsDevelopBranchNumberData?.median === undefined ? '-' : threeYearsDevelopBranchNumberData.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={threeYearsDevelopBranchNumberData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${trabeculaChartsH * threeYearsDevelopBranchNumberData?.self.index || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `近三年设立分子公司数量${threeYearsDevelopBranchNumberData?.self.date.toString() || '-'}，超过${threeYearsDevelopBranchNumberData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <Spin spinning={registeredCapitalIncreaseFetching}>
            <div className={style.strip}>
              <div>{registeredCapitalIncreaseData?.title}</div>
              <div className={style.stripChart}>
                {registeredCapitalIncreaseData?.series[0].data.map((item, index) => (
                  <div key={index}>
                    <span>{item.name}</span>
                    <div>
                      <span>
                        {item.value}
                        家
                      </span>
                      <span>
                        {item.proportion}
                        %
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Spin>
          <Spin spinning={socialSecurityNumberFetching}>
            <div className={style.companyAge}>
              <div>{socialSecurityNumberData?.title}</div>
              <div>
                <div>
                  <span>社保人数均值</span>
                  <span>{socialSecurityNumberData?.average === undefined ? '-' : socialSecurityNumberData.average}</span>
                </div>
                <div>
                  <span>社保人数中位数</span>
                  <span>{socialSecurityNumberData?.median === undefined ? '-' : socialSecurityNumberData.median}</span>
                </div>
              </div>
              <div className={style.companyAgeCharts}>
                <div className={style.trabeculaCharts}>
                  <TrabeculaCharts
                    data={socialSecurityNumberData?.series[0].data}
                    height={trabeculaChartsH}
                    percent={0.214}
                  />
                </div>
                <div className={style.myLocation} style={{ paddingTop: `${trabeculaChartsH * socialSecurityNumberData?.self.index || 0}px` }}>
                  <div className={style.sign}>
                    <span className={style.line} />
                    <span className={style.lineText}>我的位置</span>
                    <span className={style.line} />
                    <span className={style.arrows} />
                  </div>
                  <div className={style.describe}>
                    {
                      `社保人数${socialSecurityNumberData?.self.date.toString() || '-'}，超过${socialSecurityNumberData?.self.surpass || '-'}高新技术企业`
                    }
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default TestResult;
