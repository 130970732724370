import React, { useEffect } from 'react';
import PieTable from '../../../component/PieTable';
import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const RegisteredCapital = (props) => {
  const { url, dataFilterParams, reportData, ok } = props;
  const { data, isFetching } = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData || !ok });
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <PieTable {...props} data={data} isFetching={isFetching} chartType="饼图" radius={['0%', '80%']} />;
};

export default RegisteredCapital;
