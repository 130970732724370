import { createAction, createEntityAdapter, createReducer } from '@reduxjs/toolkit';

export interface Entity { id: string, [key: string]: any }
const componentsAdapter = createEntityAdapter<Entity>({ selectId: component => component.id, });
export interface Metadata { id: string; path: string; position?: number }
const metadataAdapter = createEntityAdapter<Metadata>({ selectId: item => item.id, });


export const initialState = {
  components: componentsAdapter.getInitialState(),
  metadata: metadataAdapter.getInitialState(),
  macro: {} as Record<string, string | number>,
};

const passBy = (payload: any) => ({ payload });
export const removeAll = createAction('removeAll');
export const upsertAllComponents = createAction('upsertAllComponents', passBy);
export const upsertAllMetadata = createAction('upsertAllMetadata', passBy);
export const setMacro = createAction('setMacro', passBy);

const componentsSelectors = componentsAdapter.getSelectors();
const metadataSelectors = metadataAdapter.getSelectors();
export const selectById = (state: typeof initialState, id: string) => ({
  component: componentsSelectors.selectById(state.components, id),
  metadata: metadataSelectors.selectById(state.metadata, id),
});
export const selectAll = (state: typeof initialState) => (
  state.components.ids.map((id) => ({
    component: componentsSelectors.selectById(state.components, id),
    metadata: metadataSelectors.selectById(state.metadata, id),
  })
));


export const reducer = createReducer(initialState, {
  [removeAll.toString()](state) {
    componentsAdapter.removeAll(state.components);
    metadataAdapter.removeAll(state.metadata);
  },
  [upsertAllComponents.toString()](state, action) {
    componentsAdapter.upsertMany(state.components, action.payload);
  },
  [upsertAllMetadata.toString()](state, action) {
    metadataAdapter.upsertMany(state.metadata, action.payload);
  },
  [setMacro.toString()](state, action) {
    state.macro = action.payload;
  }
});

