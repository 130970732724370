import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Popconfirm, message, Input, Tooltip } from 'antd';
import { Table } from '@sqke/design';
import { IconFont } from '../../../../../../utils/mapIcon';
import objClearNull from '../../../../../../utils/objClearNull';
import { useGetCityComparisonTableQuery, useLazyDeleteUserCityContrastCollectQuery } from '../../../../../../service/tool';
import TableNoData from '../../../../../../components/IndustryCompanyDetail/CompanyContent/TableNoData';
import style from './index.module.less';

const EvaluationRecord = (props) => {
  const [deleteRecord] = useLazyDeleteUserCityContrastCollectQuery();
  const history = useHistory();
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [keyword, setKeyword] = useState('');
  const inputRef = useRef();
  // 获取记录列表
  const { isFetching, data, refetch } = useGetCityComparisonTableQuery(objClearNull({ page_size: pageSize, page_number: pageNumber, keyword }));
  const { dataSource, count } = data || {};
  // 删除记录
  const deleteRecordFun = (id) => {
    deleteRecord({ record_id: id }).then(res => {
      const { isError } = res;
      if (!isError) {
        refetch();
        message.info('删除成功');
      }
    });
  };

  const pagination = {
    total: count || 0,
    current: pageNumber,
    pageSize,
    showQuickJumper: true,
    onChange: (page2, pageSize2) => {
      setPageSize(pageSize2);
      setPageNumber(page2);
    },
  };

  // 查看详情
  const viewDetails = (content) => {
    localStorage.setItem('cityComparisonRecord', JSON.stringify(content));
    history.push(`/cityTool${props.location.search}`);
  };

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 80,
    },
    {
      title: '收藏时间',
      dataIndex: 'update_time',
      key: 'update_time',
      width: 220,
    },
    {
      title: '对标对象',
      dataIndex: 'region_name_str',
      key: 'region_name_str',
      ellipsis: true,
      render: (address) => (
        <Tooltip placement="topLeft">
          {address}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      dataIndex: 'active',
      key: 'active',
      width: 200,
      render: (text, row) => (
        <div className={style.active}>
          <button type="button" onClick={() => { viewDetails(row); }}>查看</button>
          {/* <button type="button" onClick={() => { goToDetailsPage({ ...row.content, company_id: row.company_id }); }}>重新测评</button> */}
          <Popconfirm placement="top" title="是否确认删除" onConfirm={() => { deleteRecordFun(row.id); }} okText="是" cancelText="否">
            <button type="button">删除</button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className={style.evaluationRecord}>
      <header>
        <div>
          <div className={style.left}>
            <p>城市对比对标记录</p>
            <p>
              共搜索到
              {count || '-'}
              条数据
            </p>
          </div>
          <div className={style.right}>
            <Input type="text" ref={inputRef} className={style.keyword} placeholder="请输入城市名称关键词" />
            <div>
              <span />
              <span onClick={() => { setKeyword(inputRef.current.input.value); }}><IconFont type="iconfenxiang" /></span>
            </div>
          </div>
        </div>
        <button
          type="button"
          className={style.out}
          onClick={() => {
            history.push(`/cityTool${props.location.search}`);
          }}
        >
          <p />
          <p>返回</p>
        </button>
      </header>
      <div className={style.table}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          loading={isFetching}
          pagination={pagination}
          locale={{ emptyText: () => {
            if (!dataSource) {
              return '';
            }
            return <TableNoData />;
          } }}
        />
      </div>
    </div>
  );
};

export default EvaluationRecord;
