import React, { useState, useEffect } from 'react';
import { uniqBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Input } from 'antd';
import { IconFont } from '../../utils/mapIcon';
import ToolModal from '../../components/ToolModal';
import { openNewPage } from '../../utils/urlMethods';
import { companyDetailsTool } from '../../utils/tool';
import { useGetNavigationToolListQuery, useLazyGetToolPermissionQueryQuery } from '../../service/public';
import style from './index.module.less';

const tagColorMap = {
  监测: '#008CD6',
  招商: '#D66B00',
  培育: '#00BED6',
  预警: '#D63400',
  投资: '#0069D6',
};

const AllTool = () => {
  const history = useHistory();
  const [getToolPermissionQuery] = useLazyGetToolPermissionQueryQuery();
  const [searchValue, setSearchValue] = useState();
  const [tool, setTool] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [toolClassify, setToolClassify] = useState();
  const [toolList, setToolList] = useState();
  const [allToolList, setAllToolList] = useState();
  const [toolCategory, setToolCategory] = useState('全部工具');
  const [keyWord, setKeyWord] = useState('');
  const [bgUrl, setBgUrl] = useState('');
  const { data } = useGetNavigationToolListQuery({ key_word: keyWord });

  useEffect(() => {
    if (data) {
      setToolCategory('全部工具');
      setToolClassify(['全部工具', ...data.map(item => item.name)]);
      setAllToolList([
        { name: '全部工具', tool_list: uniqBy(data.map(item => item.tool_list).flat(), 'name') },
        ...data,
      ]);
      setToolList(uniqBy(data.map(item => item.tool_list).flat(), 'name'));
    }
  }, [data]);

  const searchTool = () => {
    setKeyWord(searchValue);
  };

  const cutTool = (item) => {
    setToolCategory(item);
    setToolList(allToolList.filter(it => it.name === item)[0].tool_list);
  };

  const goToToolPage = (item) => {
    if (companyDetailsTool[item.keyword]) {
      if (companyDetailsTool[item.keyword].url.includes('penetrateGraph')) {
        history.push({ pathname: companyDetailsTool[item.keyword].url });
      } else {
        const params = {
          id: '753041486c2f55514f566933366e486f4b41734272413d3d0a',
          name: '中兴通讯股份有限公司',
        };
        // history.push({
        //   pathname: companyDetailsTool[item.keyword].url,
        //   search: params.toString(),
        // });
        openNewPage(companyDetailsTool[item.keyword].url, params);
      }
      return;
    }
    const params = {
      cityCode: window.btoa('110100'),
      title: item.name,
      cityName: '北京市',
      key: item.keyword,
      isProvinceDirectly: false,
    };
    if (item.name === '寻企地图') {
      openNewPage('tool/searchCompanyMap', params);
      return;
    }
    if (item.name === '数据分析') {
      openNewPage('dataAnalysisTool/dataFilter');
      return;
    }
    openNewPage('cityTool', params);
  };

  const clickTool = (item) => {
    setTool(item.name);
    setBgUrl(item.reality_img);
    getToolPermissionQuery({ tool_key: item.keyword }).then(res => {
      if (res.data) {
        goToToolPage(item);
        return;
      }
      setModalShow(true);
    }).catch(err => {
      throw new Error(err);
    });
  };
  return (
    <div className={style.tool}>
      <header>
        <div className={style.search}>
          <div className={style.searchInput}>
            <Input
              placeholder="请输入场景、工具名称等"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            <div className={style.inputLine} />
            <IconFont
              type="iconfenxiang"
              className={style.inputIcon}
              onClick={searchTool}
            />
          </div>
        </div>
      </header>
      <div className={style.content}>
        <div className={style.tab}>
          {
            toolClassify?.map(item => (
              <div
                key={item}
                className={classNames({ [style.presentTool]: item === toolCategory })}
                onClick={() => { cutTool(item); }}
              >
                {item}
              </div>
            ))
          }
        </div>
        <div className={style.toolBox}>
          {
            toolList?.map((item, index) => (
              <div
                key={index}
                className={style.cell}
                onClick={() => { clickTool(item); }}
              >
                <div className={style.icon} >
                  <img src={item.icon} alt="" />
                </div>
                <div className={style.name}>{item.name}</div>
                <div className={style.describe}>{item.introduce}</div>
                <div className={style.tagBox}>
                  {
                    item.scene_name.map(item2 => (<div key={item2} style={{ background: tagColorMap[item2] }}>{item2}</div>))
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
      {
        modalShow && <ToolModal title={tool} setTool={setModalShow} bgUrl={bgUrl} />
      }
    </div>
  );
};

export default AllTool;
