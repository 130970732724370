import React from 'react';
import HollowPie from '../../../../../Charts/HollowPie';
import { useGetPatentStatisticsTypeDistributionQuery } from '../../../../../../service/companyDetail';

const color = ['#4E88FF', '#59D700', '#1961F5'];
const LegalStatus = ({ companyId, grade }) => {
  const { data, isFetching } = useGetPatentStatisticsTypeDistributionQuery({
    company_id: companyId,
    penetration_grade: grade,
  });
  return (
    <div style={{ width: '662px', height: '344px' }}>
      <HollowPie data={data?.series[0]} radius={['45%', '60%']} color={color} icon="rect" isFetching={isFetching} />
    </div >
  );
};

export default LegalStatus;
