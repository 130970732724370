import React from 'react';
import classnames from 'classnames';
import { IconFont } from '../../../../../../../utils/mapIcon';
import styles from './index.module.less';
import Underline from '../../../../../../../components/Underline';

const CloseIcon = ({ className, onClick }) => (
  <IconFont className={classnames(styles.icon, className)} type="iconclose" onClick={onClick} />
);

function Modal({
  className,
  onClose,
  onClick,
  children,
}) {
  return (
    <div className={classnames(styles.modal, className)} onClick={onClick}>
      <CloseIcon className={styles.closeIcon} onClick={onClose} />
      {children}
    </div>
  );
}

function StandardModal({
  header,
  className,
  onClose,
  children,
}) {
  return (
    <Modal
      className={classnames(styles.standard, className)}
      onClose={onClose}
    >
      {
        header && (
          <div className={styles.header}>
            {header}
          </div>
        )
      }
      {
        header && <Underline className={styles.line} />
      }
      <div className={styles.content}>
        {children}
      </div>
    </Modal>
  );
}

export default Modal;

export {
  StandardModal,
};
