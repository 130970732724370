/*
* Author: zhangmeng
* Description: '投资机构'
* Date: 2021/08/05
*/

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Spin } from 'antd';
import InvestmentTableComp from '../InvestmentTableComp';
import TitleComp from '../../IndustryCompanyDetail/CompanyContent/TitleComp';
import { institutionalInvestorInfo } from '../../../api/InvestmentDetail';
import style from './index.module.less';

function InvestmentOrganization(props) {
  const { id } = props;
  const [data, setData] = useState({});
  const condition = useRef({
    page_size: 5,
    page_number: 1,
  });

  const getList = (p) => {
    institutionalInvestorInfo({
      investment_id: id,
      ...condition.current,
      ...p,
    }).then(res => {
      if (res.status === '00000') {
        const { columns } = res.data;
        const newData = columns.map(item => {
          let obj = {};
          if (item.dataIndex === 'index') {
            obj = {
              ...item,
              width: '5%',
              ellipsis: true,
            };
          } else if (item.dataIndex === 'ent_short') {
            obj = {
              ...item,
              width: '9%',
              ellipsis: true,
            };
          } else if (item.dataIndex === 'ent_name') {
            obj = {
              ...item,
              width: '15%',
              ellipsis: true,
            };
          } else if (item.dataIndex === 'establish_date') {
            obj = {
              ...item,
              width: '8%',
              ellipsis: true,
            };
          } else if (item.dataIndex === 'region') {
            obj = {
              ...item,
              width: '10%',
              ellipsis: true,
              align: 'center',
            };
          } else if (item.dataIndex === 'invest_case_count') {
            obj = {
              ...item,
              width: '8%',
              ellipsis: true,
              align: 'center',
            };
          } else if (item.dataIndex === 'manage_capital_amount') {
            obj = {
              ...item,
              width: '10%',
              ellipsis: true,
              align: 'center',
            };
          } else if (item.dataIndex === 'quit_case_count') {
            obj = {
              ...item,
              width: '8%',
              ellipsis: true,
              align: 'center',
            };
          } else if (item.dataIndex === 'invested_industry') {
            obj = {
              ...item,
              ellipsis: true,
              render: (text) => (<span title={text}>{text && text.join(',')}</span>),
            };
          } else if (item.dataIndex === 'invested_phase') {
            obj = {
              ...item,
              ellipsis: true,
              render: (text) => (<span title={text}>{text && text.join(',')}</span>),
            };
          } else {
            obj = {
              ...item,
              ellipsis: true,
            };
          }
          return obj;
        });
        setData({
          ...res.data,
          columns: newData,
        });
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const content = useMemo(() => (
    Object.keys(data).length > 0
    && <InvestmentTableComp data={data} size={5} getList={(p) => getList(p)} />
  ), [data]);

  return (
    <div className={style.all}>
      <TitleComp title="投资机构" />
      <Spin tip="搜索中，请稍候...." spinning={false}>
        <div className={style.ctn}>
          <div className={style.header}>
            <div className={style.total}>
              <div className={style.word}>总</div>
              <div className={style.num}>
                {data.count || 0}
                <span>家</span>
              </div>
            </div>
            <div className={style.names}>
              {
                Object.keys(data).length > 0 && data.short_list.map((item, index) => (
                  <span key={index}>{item}</span>
                ))
              }
            </div>
          </div>
          {content}
        </div>
      </Spin>
    </div>
  );
}

export default InvestmentOrganization;
