/**
 *
 * @param {import('echarts').} echarts
 */
export function registerSeries(echarts) {
  echarts.extendSeriesModel()
  function randomHslColor(min, max) {
    return (
      'hsl(' +
      (Math.random() * 360).toFixed() +
      ',' +
      (Math.random() * 30 + 70).toFixed() +
      '%,' +
      (Math.random() * (max - min) + min).toFixed() +
      '%)'
    );
  }

  function getRandomDarkColor() {
    return randomHslColor(10, 50);
  }

  function getRandomLightColor() {
    return randomHslColor(50, 90);
  }

  echarts.extendSeriesModel({
    type: 'series.wordCloud',

    visualStyleAccessPath: 'textStyle',
    visualStyleMapper: function (model) {
      return {
        fill: (function (color) {
          switch (color) {
            case 'random-dark':
              return getRandomDarkColor;
            case 'random-light':
              return getRandomLightColor;
            default:
              return color;
          }
        })(model.get('color'))
      };
    },
    visualDrawType: 'fill',

    optionUpdated: function () {
      var option = this.option;
      option.gridSize = Math.max(Math.floor(option.gridSize), 4);
    },

    getInitialData: function (option, ecModel) {
      var dimensions = echarts.helper.createDimensions(option.data, {
        coordDimensions: ['value']
      });
      var list = new echarts.List(dimensions, this);
      list.initData(option.data);
      return list;
    },

    // Most of options are from https://github.com/timdream/wordcloud2.js/blob/gh-pages/API.md
    defaultOption: {
      maskImage: null,

      // Shape can be 'circle', 'cardioid', 'diamond', 'triangle-forward', 'triangle', 'pentagon', 'star'
      shape: 'circle',
      keepAspect: false,

      left: 'center',

      top: 'center',

      width: '70%',

      height: '80%',

      sizeRange: [12, 60],

      rotationRange: [-90, 90],

      rotationStep: 45,

      gridSize: 8,

      drawOutOfBound: false,
      shrinkToFit: false,

      textStyle: {
        fontWeight: 'normal'
      }
    }
  });
}
