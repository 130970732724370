import clsx from 'clsx';
function PageBreak(props: any) {
  const { style, className } = props;
  return (
    <div className={clsx('sqke-page-break', className)} style={style}>
      <div className="sqke-page-break-dash" />
      <span className="sqke-page-break-text"></span>
    </div>
  );
}

export default PageBreak;
