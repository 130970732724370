import {
  DATASEARCH, RESEARCHREPORTDETAIL,
} from '../utils/url';
import { get, post, requestFile } from '../utils/request';

// 研报
const reportSearchList = param => post(`${DATASEARCH}/search_research_report_list`, {
  ...param,
});

// 研报详情
const reportSearchDetailInfo = param => get(`${RESEARCHREPORTDETAIL}/base_info_report`, {
  ...param,
});

// 研报查看
const reportResearchRead = (params) => get(`${RESEARCHREPORTDETAIL}/read_report`, {
  ...params,
});

// 研报下载
const reportResearchDownload = (params, name) => requestFile('get', `${RESEARCHREPORTDETAIL}/download_report`, {
  ...params,
}, `${name}.pdf`);

// 研报详情-目录
const getCatalog = params => get(`${RESEARCHREPORTDETAIL}/report_catalog`, {
  ...params,
});

// 研报详情-主要图表
const getMainChart = params => get(`${RESEARCHREPORTDETAIL}/main_chart`, {
  ...params,
});

// 研报详情-相关推荐
const getReportRecommend = params => get(`${RESEARCHREPORTDETAIL}/report_recommend`, {
  ...params,
});

export {
  reportSearchList,
  reportSearchDetailInfo,
  reportResearchRead,
  reportResearchDownload,
  getCatalog,
  getMainChart,
  getReportRecommend,
};
