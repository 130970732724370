/*
 *Author: zhaochenyu
 *Description: 忘记密码
 *Date: 2021/04/27
*/

import React, { useState, useCallback } from 'react';
import { Input, Form, Button } from 'antd';
import CountDown from '../UseApply/CountDown';
import style from './index.module.less';

function ForgetPassword() {
  const [next, setNext] = useState(false);
  const [phone, setPhone] = useState('');
  const [countDown, setcountDown] = useState(false);

  const getAuthCode = () => {
    // if (countDown) return;
    // getAuthCount({
    //   phone: value,
    // });
  };

  const onFinish = useCallback(
    (value) => {
      setNext(true);
      setPhone(value.user_name);
      getAuthCode(value.user_name);
      setcountDown(true);
    },
    [],
  );

  const changePassword = useCallback(
    (value) => {
      // console.log(value);
    },
    [],
  );
  return (
    <div className={style.all}>
      {next ? (
        <div className={style.changePassword}>
          <Form
            name="basic"
            onFinish={changePassword}
          >
            <Form.Item
              label=""
              name="user_name"
              className="username"
            >
              <Input placeholder="请输入手机号码" value={phone} disabled />
            </Form.Item>
            <Form.Item
              label="新密码"
              name="newPassword"
              rules={[
                {
                  pattern: /^.{6,18}$/,
                  message: '密码长度为6~18位！',
                },
                {
                  required: true,
                  message: '请输入6-18位新密码',
                },
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                placeholder="请输入6-18位新密码"
              />
            </Form.Item>
            <Form.Item
              label="确认密码"
              name="confirm"
              rules={[
                {
                  required: true,
                  message: '请输入6-18位新密码',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('两次密码不一致！');
                  },
                }),
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                placeholder="请输入6-18位新密码"
              />
            </Form.Item>
            <Form.Item
              name="auth_code"
              className="captcha"
              rules={[
                { required: true, message: '请输入验证码' },
              ]}
            >
              <Input placeholder="请输入验证码" />
            </Form.Item>
            <div
              className={`${style.getAuthCode} ${countDown ? style.counting : undefined}`}
              onClick={() => getAuthCode(phone)}
            >
              {countDown ? <CountDown changeSend={setcountDown} /> : '发送验证码'}
            </div>
            <Form.Item className="submit">
              <Button type="primary" htmlType="submit" className="submit-btn">
                确认修改
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div className={style.searchPhone}>
          <Form
            name="basic"
            onFinish={onFinish}
          >
            <Form.Item
              label=""
              name="user_name"
              className="username"
              rules={[
                {
                  pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                  message: '请输入正确的手机号',
                },
                {
                  required: true,
                  message: '请输入手机号码',
                },
              ]}
            >
              <Input placeholder="请输入手机号码" id="phone" />
            </Form.Item>
            <Form.Item className="submit">
              <Button type="primary" htmlType="submit">
                下一步
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}

export default ForgetPassword;
