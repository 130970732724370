import { ComponentType, CSSProperties, memo, ReactElement } from 'react';
import { withDataMacro } from '../../../hoc/withDataMacro';
import { withTitle } from '../../../hoc/withTitle';
import { COMPONENTS_MAP } from '../../../data/mapping';
import { withMacro } from '../../../hoc/withMacro';
import { withDetection } from '../../../hoc/withDetection';
import { withFetch } from '../../../hoc/withFetch';
import { withExampleData } from '../../../hoc/withExampleData';
import { withErrorCatch } from '../../../hoc/withErrorCatch';


interface ComponentRendererProps {
  macro: Record<string, string | number>;
  type: string;
  properties: Record<string, any>;
  metadata: any;
  style?: CSSProperties;
  getExampleData?: (item: any) => any;
  renderOnError?: (item: any) => ReactElement;
  renderOnFetching?: (item: any) => ReactElement;
  // TODO: 切换自动更新和手动更新数据
  useLazyQuery?: boolean;
}

const middlewares: ((item: ComponentType<any>) => ComponentType<any>)[] = [
  memo,
  withErrorCatch,
  withTitle,
  withDataMacro,
  withExampleData,
  withFetch,
  withDetection,
  withMacro,
];
const currentComponentsMap = Object.entries(COMPONENTS_MAP).reduce<typeof COMPONENTS_MAP>(
  (prev, [key, value]) => {
    prev[key] = {
      ...value,
      component: middlewares.reduce((prev, next) => next(prev), value.component),
    };
    return prev;
  }, {});

/**
 * 组件渲染组件
 * 传入 id 从 store 中获取
 * 对应的样式
 * @param props
 * @constructor
 */
function ComponentRenderer(props: ComponentRendererProps) {
  const description = currentComponentsMap[props?.type];
  let Component = description?.component;
  if (!Component || !props) {
    return null;
  }
  const { display } = description;

  let attachCls = '';
  switch (display) {
    case 'page':
    case 'page-break':
      break;
    case 'block':
    default:
      attachCls = 'sqke-component-display-block';
      break;
  }

  const {
    type,
    macro,
    properties,
    metadata,
    style,
    getExampleData,
    renderOnError,
    renderOnFetching,
    useLazyQuery,
  } = props;

  return (
    <Component
      {...properties}
      {...metadata}
      style={style}
      macro={macro}
      __type={type}
      className={attachCls}
      // 给子节点使用
      getExampleData={getExampleData}
      renderer={ComponentRenderer}
      renderOnError={renderOnError}
      renderOnFetching={renderOnFetching}
      useLazyQuery={useLazyQuery}
    />
  );
}

export default ComponentRenderer;
