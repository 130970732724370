import React from 'react';
import IndustryMapScheme from '../IndustryMapScheme/index2';
import Title from '../../../../components/Title';
import styles from './index.module.less';

const IndustrialDistribution = ({ name, cityCode, cityName }) => (
  <div id='distribution' className={styles.box}>
    <div className={styles.title}>
      <Title title={name} />
    </div>
    <IndustryMapScheme
      cityCode={cityCode}
      cityName={cityName}
    />
  </div>
);

export default IndustrialDistribution;
