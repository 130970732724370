/*
* Author: zhangmeng
* Description: '关注弹窗'
* Date: 2021/07/28
*/

import React, { useState, useRef, useEffect } from 'react';
import { Checkbox, Input, message, Popover } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { IconFont } from '../../../../../utils/mapIcon';
import { addAttentionGroup, updateAttentionGroup } from '../../../../../api/companyDetail';
import warningIcon from '../../../../../assets/images/companyDetail/waringicon.png';
import style from './index.module.less';

function FocusDetail(props) {
  const { focusDetailEvent, focusData, updateList } = props;
  const [checkBoxData, setCheckBoxData] = useState([]);
  const [checkbox, setCheckbox] = useState({});
  const [selected, setSelected] = useState([]);
  const [pop, setPop] = useState([]); // 更多弹窗
  const [isRename, setIsRename] = useState([]); // 是否重命名
  const [lastIndex, setLastIndex] = useState(null); // 上次点击的index
  const [changedName, setChangedName] = useState(''); // 分组重命名后的分组名字
  const [changedId, setChangedId] = useState(''); // 分组重命名后的分组id
  const [repeatIndex, setRepeatIndex] = useState(-1); // 需要重命名索引
  const curIndex = useRef(null); // 当前点击的index
  const timer = useRef(null);
  const alGroupName = useRef(null);

  // 修改分组名称
  const updateAttentionGroupName = () => {
    updateAttentionGroup({
      attention_data_type_name: '企业',
      group_name: changedName,
      attention_group_id: changedId,
    }).then(res => {
      if (res.status === '00000') {
        updateList();
        setChangedName('');
        setChangedId('');
        message.destroy();
        message.success('修改成功！');
      }
    });
  };

  useEffect(() => {
    setCheckBoxData(focusData || []);
    alGroupName.current = focusData;
    const len = focusData.length;
    const arr = [];
    for (let i = 0; i < len; i += 1) {
      arr.push(false);
    }
    setIsRename(arr);
    setPop(arr);
    const selectedArr = [];
    focusData.forEach(item => {
      if (item.is_check) {
        selectedArr.push(item.id);
      }
    });
    setSelected(selectedArr);
  }, [focusData]);

  // checkbox  onchange
  const checkBoxChange = (p, i) => {
    const obj = { ...checkbox };
    obj[i] = p.target.checked;
    setCheckbox(obj);
    if (p.target.checked) {
      setSelected([...selected, p.target.value]);
    } else {
      const idx = selected.indexOf(p.target.value);
      const arr = [...selected];
      arr.splice(idx, 1);
      setSelected(arr);
    }
  };

  // 重命名
  const renameClick = (index, name, id) => {
    curIndex.current = index;
    clearTimeout(timer.current);
    setChangedName(name);
    setChangedId(id);
    const arr = [...isRename];
    arr[index] = true;
    setIsRename(arr);
    const popArr = [...pop];
    popArr[index] = false;
    setPop(popArr);
    setLastIndex(index);
    timer.current = setTimeout(() => {
      document.getElementById(`input${index}`).focus();
    }, 100);
  };

  // 输入框onChange
  const inputOnchange = (e) => {
    setChangedName(e.target.value);
    let exist = false;
    alGroupName.current.forEach((item, index) => {
      if (index !== curIndex.current && item.default_group_name === e.target.value.trim()) {
        exist = true;
      }
    });
    setRepeatIndex(exist ? curIndex.current : -1);
  };

  // 输入框pressEnter
  const inputOnPressEnter = (index) => {
    if (curIndex.current === repeatIndex) return;
    document.getElementById(`input${index}`).blur();
    const arr = [...isRename];
    arr[index] = false;
    setIsRename(arr);
    if (changedName.trim() === '') {
      return;
    }
    if ((changedName.trim() === checkBoxData[curIndex.current].default_group_name)) {
      setChangedName('');
      setChangedId('');
      return;
    }
    updateAttentionGroupName();
  };

  // 点击更多
  const moreClick = (index) => {
    curIndex.current = index;
    const arr = [...pop];
    if (index === lastIndex) {
      arr[index] = !pop[index];
    } else {
      arr[index] = true;
      arr[lastIndex] = false;
    }
    setPop(arr);
    setLastIndex(index);
  };

  // input 失去焦点
  const onBlur = () => {
    if (curIndex.current === repeatIndex) return;
    const arr = [...isRename];
    arr[curIndex.current] = false;
    setIsRename(arr);
    if (changedName.trim() === '') {
      return;
    }
    if ((changedName.trim() === checkBoxData[curIndex.current].default_group_name)) {
      setChangedName('');
      setChangedId('');
      return;
    }
    updateAttentionGroupName();
  };

  // 添加分组
  const addClick = () => {
    let title = '默认分组';
    let j = 0;
    let exist = checkBoxData.find(item => item.default_group_name === title);
    while (exist) {
      j += 1;
      title = `默认分组${j}`;
      // eslint-disable-next-line no-loop-func
      exist = checkBoxData.find(item => item.default_group_name === title);
    }
    addAttentionGroup({
      attention_data_type_name: '企业',
      group_name: title,
    }).then(res => {
      if (res.status === '00000') {
        updateList();
        setIsRename([...isRename, false]);
        setPop([...pop, false]);
        message.success('添加成功！');
      }
    });
  };

  return (
    <div className={style.all}>
      <header>
        <span>选择分组</span>
        <span className={style.add} onClick={() => addClick()}>
          <PlusCircleOutlined />
        </span>
      </header>
      <div className={style.content}>
        {
          checkBoxData.length > 0 && checkBoxData.map((item, index) => (
            <div className={`${style.box} ${repeatIndex === index ? style.repeatBox : undefined}`} key={index}>
              <Checkbox
                defaultChecked={item.is_check}
                name={item.default_group_name}
                value={item.id}
                onChange={(p) => checkBoxChange(p, index)}
              >
                {
                  isRename[index] ? (
                    <Input
                      onBlur={() => onBlur(item.id)}
                      id={`input${index}`}
                      className={`${checkbox && checkbox[index] ? 'checked' : ''}`}
                      value={changedName}
                      bordered={!!isRename[index]}
                      disabled={!isRename[index]}
                      onChange={(e) => inputOnchange(e)}
                      onPressEnter={() => inputOnPressEnter(index)}
                    />
                  ) : (
                    <Input
                      onBlur={() => onBlur(item.id)}
                      id={`input${index}`}
                      className={`${checkbox && checkbox[index] ? 'checked' : ''}`}
                      value={item.default_group_name}
                      bordered={!!isRename[index]}
                      disabled={!isRename[index]}
                      onChange={(e) => inputOnchange(e)}
                      onPressEnter={() => inputOnPressEnter(index)}
                    />
                  )
                }
                <div className={style.errorCard} style={{ display: repeatIndex === index ? '' : 'none' }}>
                  <img alt="" src={warningIcon} className={style.icon} />
                  <div className={style.word}>
                    此命名与现有分组命名有重复，请调整修改。
                  </div>
                </div>
              </Checkbox>
              <Popover
                overlayClassName={style.pop}
                visible={pop[index]}
                content={
                  <div
                    className={style.rename}
                    onClick={() => renameClick(index, item.default_group_name, item.id)}
                  >
                    重命名
                  </div>
                }
                trigger="click"
              >
                <IconFont className={item.id === 1 ? 'defaultGroup' : 'otherGroup'} id={String(index)} onClick={() => moreClick(index)} type="iconmenu2" />
              </Popover>
            </div>
          ))
        }
      </div>
      <div className={style.footer}>
        <div className={style.confirm} onClick={() => focusDetailEvent('confirm', selected)}>确认</div>
        <div className={style.cancel} onClick={() => focusDetailEvent('cancel')}>取消</div>
      </div>
    </div>
  );
}

export default FocusDetail;
