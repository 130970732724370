import React, { useEffect, useState, useRef } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import NoPage from '../components/NoPage';
import Header from './header';
import LeftMenu from './leftMenu';
import DataFilter from '../pages/DataFilter';
import MyReport from '../pages/MyReport';
import DownloadList from '../pages/DownloadList';
import ChartTableTool from '../pages/ChartTableTool';
import ReportPreview from '../pages/ReportPreview';
import style from './page.module.less';

function BasicLayout() {
  const [selectTool, setSelectTool] = useState('生成报告');
  return (
    <Layout className={style.basic}>
      <Switch>
        <Route path="/dataAnalysisTool">
          <Header selectTool={selectTool} setSelectTool={setSelectTool} />
          <div className={style.center}>
            <LeftMenu selectTool={selectTool} />
            <div className={style.context} >
              <Switch>
                <Route
                  exact
                  path="/dataAnalysisTool"
                  render={() => <Redirect to="/dataAnalysisTool/dataFilter" push />}
                />
                <Route exact path="/dataAnalysisTool/dataFilter" component={DataFilter} />
                <Route exact path="/dataAnalysisTool/myReport" component={MyReport} />
                <Route exact path="/dataAnalysisTool/downloadList" component={DownloadList} />
                <Route exact path="/dataAnalysisTool/chartTableTool" component={ChartTableTool} />
                <Route exact path="/dataAnalysisTool/reportPreview" render={(props) => <ReportPreview {...props} />} />
                {/* 404页面 */}
                <Route exact component={NoPage} />
              </Switch>
            </div>
          </div>
        </Route>
      </Switch>
    </Layout>
  );
}

export default withRouter(BasicLayout);
