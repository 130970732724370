import React, { useRef, useState } from 'react';
import { CheckOutlined, SyncOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Content from './Content';
import Directory from './Directory';
import { allForm } from './data';
import style from './index.module.less';

const list = allForm.map(item => ({ title: item.title, value: item.value }));
const color = {
  completed: '#333333',
  inHand: '#4473F4',
  notStarted: '#999999',
};

const completed = (
  <div className={style.completed}>
    <CheckOutlined />
    <span className={style.line} />
  </div>
);
const inHand = (
  <div className={style.inHand}>
    <SyncOutlined />
    <span className={style.line} />
  </div>
);
const notStarted = (
  <div className={style.notStarted}>
    <ClockCircleOutlined />
    <span className={style.line} />
  </div>
);

const BasicInformation = () => {
  const content = useRef();
  const fromRef = useRef();
  const [originalList, setOriginalList] = useState(list.map(item => ({ title: item.title, value: item.value, el: notStarted, color: color.notStarted })).slice(1));
  const [lastStep, setLastStep] = useState('');

  // 跳转锚点
  const skipAnchor = (id) => {
    const el = document.getElementById(id);
    content.current.scrollTo({
      top: el.offsetTop - 80,
      behavior: 'smooth',
    });
  };

  // 更新步骤条
  const updataStepBar = ({ value, el, color: startColor }) => {
    setOriginalList(params => params.map(item => {
      if (item.value === value) {
        return { ...item, el, color: startColor };
      }
      return item;
    }));
  };

  // 判断数组和对象是否为空
  const ArreyAndObjIsnull = (data, requiredList) => {
    if (Array.isArray(data)) {
      return data.length;
    }
    return Object.keys(data).every(item => {
      if (typeof data[item] === 'object') {
        ArreyAndObjIsnull(data[item]);
      }
      if (requiredList.includes(item)) {
        return true;
      }
      return data[item];
    });
  };

  // 全部完成
  const fulfilleTheAll = (data) => {
    Object.keys(data).splice(1);
    Object.keys(data).splice(1).forEach(item => {
      updataStepBar({ value: item, el: completed, color: color.completed });
    });
  };

  // 单独处理创新能力
  const innovateAccomplishFill = () => {
    const fieldsValue = Object.values(fromRef.current.getFieldsValue()[lastStep]);
    let requiredList = allForm.filter(item => item.value === lastStep)[0].list.map(item => item.list.filter(it => !it.required).map(it => it.name));
    requiredList = requiredList.flat();
    const ok = fieldsValue.every(item => {
      if (typeof item === 'string') {
        return item;
      }
      if (typeof item === 'object') {
        const ll = ArreyAndObjIsnull(item, requiredList);
        return ll;
      }
      if (typeof item === 'boolean') {
        return true;
      }
      return item;
    });
    if (ok) {
      updataStepBar({ value: lastStep, el: completed, color: color.completed });
      return;
    }
    const isNullFun = (data) => data.some(item => {
      if (typeof item === 'object') {
        if (Array.isArray(data)) {
          return data.some(Boolean);
        }
        return Object.keys(data).some(it => {
          if (typeof it === 'object') {
            ArreyAndObjIsnull(data[item]);
          }
          return data[item];
        });
      }
      return item;
    });
    if (!isNullFun(fieldsValue)) {
      updataStepBar({ value: lastStep, el: notStarted, color: color.notStarted });
    }
  };

  // 是否完成
  const accomplishFill = (value) => {
    if (!lastStep || lastStep === value) {
      return;
    }
    if (lastStep === 'innovate') {
      innovateAccomplishFill();
      return;
    }
    const fieldsValue = fromRef.current.getFieldsValue()[lastStep];
    const requiredList = allForm.filter(item => item.value === lastStep)[0].list.filter(item => item.required).map(item => item.name);
    const ok = requiredList.every(item => {
      if (typeof fieldsValue[item] === 'string') {
        return fieldsValue[item];
      }
      if (typeof fieldsValue[item] === 'object') {
        if (Array.isArray(fieldsValue[item])) {
          return fieldsValue[item].length;
        }
        return Object.values(fieldsValue[item]).every(Boolean);
      }
      if (typeof fieldsValue[item] === 'boolean') {
        return true;
      }
      return fieldsValue[item];
    });
    if (ok) {
      updataStepBar({ value: lastStep, el: completed, color: color.completed });
      return;
    }
    if (!Object.values(fieldsValue).some(Boolean)) {
      updataStepBar({ value: lastStep, el: notStarted, color: color.notStarted });
    }
  };
  // 点击锚点
  const clickKip = (id) => {
    skipAnchor(id);
    updataStepBar({ value: id, el: inHand, color: color.inHand });
    accomplishFill(id);
    setLastStep(id);
  };

  /**
   * 监听form表单
   * @param {*} changedValues 当前触发的
   * @param {*} allValues 所有的
   */
  const handleFormChange = (changedValues, allValues) => {
    const value = Object.keys(changedValues)[0];
    updataStepBar({ value, el: inHand, color: color.inHand });
    accomplishFill(value);
    setLastStep(value);
  };

  return (
    <div className={style.box}>
      <div className={style.directory}>
        <Directory
          originalList={originalList}
          basicInfo="basicInfo"
          clickKip={clickKip}
        />
      </div>
      <div
        ref={content}
        className={style.content}
      >
        <Content
          fromRef={fromRef}
          handleFormChange={handleFormChange}
          fulfilleTheAll={fulfilleTheAll}
        />
      </div>
    </div>
  );
};

export default BasicInformation;
