/*
 *Description: 饼图data校验函数
 *Date: 2021/09/30
*/

import { PieChartProps } from '../basicsType';

export function checkPieChartData(data: PieChartProps['data']) {
  const handledData: any[] = [];
  data = Array.isArray(data) ? data : [];
  data.forEach(item => {
    if (item?.name && item.value && item.unit) {
      handledData.push(item);
    }
  });
  return handledData;
}
