import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

function LineChart(props) {
  const { data, labelRotate = 0, yName } = props;
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!data) return;
    const { xAxis, yAxis } = data;
    if (!Array.isArray(yAxis)) return;
    const legend = yAxis.map((item, index) => (
      {
        name: item.name,
        itemStyle: {
          color: 'rgba(89, 215, 0, 1)',
        },
      }
    ));

    const option = {
      tooltip: {
        formatter: params => {
          const str = `<div>${params.marker}${params.seriesName}：${params.value}件</div>`;
          return str;
        },
      },
      grid: {
        top: 36,
        right: 0,
        bottom: 80,
        left: 55,
      },
      legend: {
        show: false,
        bottom: 0,
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: legend,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)',
            },
          },
          axisLabel: {
            rotate: labelRotate,
            color: '#7B8796',
            fontSize: 12,
          },
          data: xAxis,
        },
      ],
      yAxis: [
        {
          name: yName,
          nameLocation: 'end',
          nameTextStyle: {
            align: 'right',
            padding: [0, 8, 0, 0],
          },
          type: 'value',
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: '#7B8796',
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              color: '#EBEBEB',
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          name: yAxis[0]?.name,
          type: 'line',
          data: yAxis[0]?.value,
          smooth: true,
          symbolSize: 2,
          itemStyle: {
            color: 'rgba(89, 215, 0, 1)',
          },
          lineStyle: {
            width: 2,
            color: 'rgba(89, 215, 0, 1)',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(89, 215, 0, 1)',
              }, {
                offset: 1,
                color: 'rgba(89, 215, 0, 0)',
              }],
            },
          },
        },
      ],
    };
    setOptions(option);
  }, [data]);

  return (
    <ReactEcharts
      option={options}
      notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default LineChart;
