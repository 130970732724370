import React from 'react';
import classNames from 'classnames';
import style from './index.module.less';
import CrosswiseDar from '../../../../../../components/Charts/CrosswiseDar';
import HollowPie from '../../../../../../components/Charts/HollowPie';
import FunnelChart from '../../../../../../components/Charts/FunnelChart';

const ChartList = ({ data, isFetching, link, childrenData, cityCode, isProvinceDirectly, onClick }) => (
  <ul className={style.chartList}>
    <li>
      <p className={style.chartTitle} >
        {data?.[0].title || ''}
        {data?.[0].subtitle && `(${data?.[0].subtitle || ''})`}
      </p>
      <div className={style.chart}>
        <FunnelChart
          data={data?.[0].series[0].data}
          isFetching={isFetching}
        />
      </div>
    </li>
    <li>
      <p className={style.chartTitle} >
        {data?.[1].title || ''}
        {data?.[1].subtitle && `(${data?.[1].subtitle || ''})`}
      </p>
      <div className={classNames(style.chart, style.hollowPieBox)}>
        <div className={style.parentHollowPie}>
          <HollowPie
            data={data?.[1].series[0]}
            isFetching={isFetching}
            radius={['40%', '60%']}
            onClick={onClick}
            showCount={false}
            position="inside"
            fonstColor="#fff"
            center={['25%', '50%']}
            defaultPitch
          />
        </div>
        <div className={style.childrenChart}>
          <HollowPie
            data={childrenData?.series[0]}
            isFetching={isFetching}
            radius={['40%', '60%']}
            center={['50%', '60%']}
            // position="inside"
            // fonstColor="#fff"
            showCount={false}
            labelShow={false}
            legend={false}
            title={link}
            titleShow
          />
        </div>
      </div>
    </li>
    <li>
      <p className={style.chartTitle} >
        {data?.[2].title || ''}
        {data?.[2].subtitle && `(${data?.[2].subtitle || ''})`}
      </p>
      <div className={style.chart}>
        <HollowPie
          data={data?.[2].series[0]}
          roseType="radius"
          // showCount={false}
          radius={['20%', '70%']}
          isFetching={isFetching}
        />
      </div>
    </li>
    {
      data?.[3].series && (
        <li>
          <p className={style.chartTitle} >
            {data?.[3].title || ''}
            {data?.[3].subtitle && `(${data?.[3].subtitle || ''})`}
          </p>
          <div className={style.chart}>
            <CrosswiseDar data={data?.[3]} isFetching={isFetching} />
          </div>
        </li>
      )
    }

  </ul>
);

export default ChartList;
