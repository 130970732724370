import React, { useMemo } from 'react';
import clsx from 'clsx';
import { SorterIcon, SorterAscIcon, SorterDescIcon, SorterAscActiveIcon, SorterDescActiveIcon } from '../../../../../../components/CustomTableIcon';
import styles from './index.module.less';

function SortTitle(props) {
  const {
    sortOrder,
    name,
    sortDirections,
  } = props;

  const sorterIconRender = useMemo(() => {
    const allSortNormalStatus = !sortDirections || (Array.isArray(sortDirections) && sortDirections.length === 2);
    const allSortAscSelectStatus = allSortNormalStatus && sortOrder === 'ascend';
    const allSortDescSelectStatus = allSortNormalStatus && sortOrder === 'descend';
    const ascSortNormalStatus = Array.isArray(sortDirections) && sortDirections.length === 1 && sortDirections[0] === 'ascend';
    const ascSortSelectStatus = ascSortNormalStatus && sortOrder === 'ascend';
    const descSortNormalStatus = Array.isArray(sortDirections) && sortDirections.length === 1 && sortDirections[0] === 'descend';
    const descSortSelectStatus = descSortNormalStatus && sortOrder === 'descend';
    if (descSortNormalStatus || descSortSelectStatus) {
      return <SorterDescIcon className={clsx({ [styles.select]: allSortAscSelectStatus || descSortSelectStatus })} />;
    }
    if (ascSortNormalStatus || ascSortSelectStatus) {
      return <SorterAscIcon className={clsx({ [styles.select]: allSortAscSelectStatus || descSortSelectStatus })} />;
    }
    if (allSortAscSelectStatus) {
      return <SorterAscActiveIcon className={clsx(styles.select)}/>;
    }
    if (allSortDescSelectStatus) {
      return <SorterDescActiveIcon className={clsx(styles.select)}/>;
    }
    if (allSortNormalStatus) {
      return <SorterIcon />;
    }
    return '';
  }, [sortOrder, sortDirections]);

  return (
    <div className={styles.tableTitle}>
      <div className={styles.titleText}>{name}</div>
      <div className={styles.icon}>
        { sorterIconRender }
      </div>
    </div>
  );
}

export default SortTitle;
