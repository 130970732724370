import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { ECharts } from '@sqke/design';
import style from './index.module.less';
import { barOption, barCrosswiseOption, hollowPieOption, pieOption } from './utils';
import FunnelChart from '../FunnelChart';

const Chart = ({ loadings, barData, barCrosswiseData, funnelData, hollowPieData, pieData, level, area }) => {
  const [barWidth, setBarWidth] = useState('100%');
  const [barCrosswiseWidth, setBarCrosswiseWidth] = useState('100%');
  useEffect(() => {
    setBarWidth(`${barData?.x?.length * 14 + (barData?.x?.length / 2) * 12 + 120}px`);
    setBarCrosswiseWidth((`${barCrosswiseData?.x?.length * 12 + (barCrosswiseData?.x?.length / 2) * 12 + 120}px`));
  }, [barCrosswiseData, barData]);
  return (
    <ul className={style.box}>
      <li>
        <Spin spinning={loadings.length ? loadings[0] : loadings}>
          <p className={style.title}>
            <span>
              {area}
              {level || barData?.title_before}
            </span>
            {barData?.title}
          </p>
          <div className={style.bar}>
            {
              (barData?.series?.length > 0 || !barData) ? <ECharts
                style={{
                  width: barWidth,
                  height: '100%',
                }}
                option={barOption(barData)}
              /> : <div className={style.noData}>该企业无此数据图谱</div>
            }
          </div>
        </Spin>
      </li>
      <li>
        <Spin spinning={loadings.length ? loadings[1] : loadings}>
          <p className={style.title}>
            <span>
              {area}
              {level || barCrosswiseData?.title_before}
            </span>
            {barCrosswiseData?.title}
          </p>
          {
            (barCrosswiseData?.series?.length > 0 || !barCrosswiseData) ? (
              <div className={style.bar}>
                <ECharts
                  style={{
                    height: `${barCrosswiseWidth}`,
                    width: '100%',
                  }}
                  option={barCrosswiseOption(barCrosswiseData)}
                />
              </div>
            ) : <div className={style.noData}>该企业无此数据图谱</div>
          }
        </Spin>
      </li>
      <li>
        <Spin spinning={loadings.length ? loadings[2] : loadings}>
          <p className={style.title}>
            <span>
              {area}
              {level || funnelData?.title_before}
            </span>
            {funnelData?.title}
          </p>
          {
            (funnelData?.series?.length > 0 || !funnelData) ? (
              <div className={style.chart}>
                <FunnelChart data={funnelData?.series?.[0]?.data} />
              </div>
            ) : <div className={style.noData}>该企业无此数据图谱</div>
          }
        </Spin>
      </li>
      <li>
        <Spin spinning={loadings.length ? loadings[3] : loadings}>
          <p className={style.title}>
            <span>
              {area}
              {level || hollowPieData?.title_before}
            </span>
            {hollowPieData?.title}
          </p>
          {
            (hollowPieData?.series?.length > 0 || !hollowPieData) ? (
              <div className={style.chart}>
                <ECharts
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                  option={hollowPieOption(hollowPieData)}
                />
              </div>
            ) : <div className={style.noData}>该企业无此数据图谱</div>
          }
        </Spin>
      </li>
      <li>
        <Spin spinning={loadings.length ? loadings[4] : loadings}>
          <p className={style.title}>
            <span>
              {area}
              {level || pieData?.title_before}
            </span>
            {pieData?.title}
          </p>
          {
            (pieData?.series?.length > 0 || !pieData) ? (
              <div className={style.chart}>
                <ECharts
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                  option={pieOption(pieData)}
                />
              </div>
            ) : <div className={style.noData}>该企业无此数据图谱</div>
          }
        </Spin>

      </li>
    </ul>
  );
};

export default Chart;
