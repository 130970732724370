import React, { useState } from 'react';
import FilesContent from './components/FilesContent';
import HistoryContent from './components/HistoryContent';
import style from './index.module.less';

const tag = [
  { title: '文件记录', value: 'filesContent' },
  { title: '历史测评', value: 'historyContent' },
];

const FilesRecording = () => {
  const [active, setActive] = useState('filesContent');
  return (
    <div className={style.filesRecordingBox}>
      <div className={style.top}>
        <div className={style.title}>
          {tag.map((item) => (
            <div
              key={item.title}
              className={active === item.value ? style[active] : ''}
              onClick={() => {
                setActive(item.value);
              }}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div className={style.contentBox}>
        {active === tag?.[0]?.value ? <FilesContent /> : <HistoryContent />}
      </div>
    </div>
  );
};
export default FilesRecording;
