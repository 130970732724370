/*
* Author: zhangmeng
* Description: '行业趋势'
* Date: 2021/08/05
*/

import React, { useMemo } from 'react';
import TitleComp from '../../IndustryCompanyDetail/CompanyContent/TitleComp';
import BarLineChart from '../../IndustryCompanyDetail/CompanyContent/BarLineChart';
import StackBarChart from './StackBarChart';
import style from './index.module.less';

function IndustryTrend(props) {
  const { investmentTrend, releaseTrend } = props;

  return (
    <div className={style.all}>
      <TitleComp title="行业趋势" />
      <div className={style.chartBox}>
        <div className={style.chartCtn}>
          <div className={style.header}>
            <span className={style.title}>{releaseTrend.title}</span>
            <span className={style.section}>{releaseTrend.section}</span>
          </div>
          <div className={style.chart}>
            {
              useMemo(() => Object.keys(releaseTrend).length > 0
                && <StackBarChart data={releaseTrend} />, [releaseTrend])
            }
          </div>
        </div>
        <div className={style.chartCtn}>
          <div className={style.header}>
            <span className={style.title}>{investmentTrend.title}</span>
            <span className={style.section}>{investmentTrend.section}</span>
          </div>
          <div className={style.chart}>
            {
              useMemo(() => Object.keys(investmentTrend).length > 0
                && <BarLineChart data={investmentTrend} />, [investmentTrend])
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndustryTrend;
