// 就业人数
import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import style from './index.module.less';
import { useGetEmploymentNumberQuery, useGetLookingForPositionsQuery } from '../../../../../service/companyDetail';

function TotalEmployment(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [positionPage, setPositionPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: tableFetching } = useGetEmploymentNumberQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const { data: positionData, isFetching: positionFetching } = useGetLookingForPositionsQuery({
    company_id: companyId,
    page_size: positionPage.pageSize,
    page_number: positionPage.pageNumber,
  }, {
    skip: !companyId,
  });
  const _columns = useMemo(() => tableData?.columns?.map(item => {
    if (item.dataIndex === 'index') {
      return {
        ...item,
        width: 70,
      };
    }
    return item;
  }), [tableData]);

  const tableEl1 = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={_columns}
          title={() => tableData?.title}
          strip
          loading={tableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          // locale={{ emptyText: () => <div className={style.tableNoData}>该企业无此项数据</div> }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [tableData]);

  const positionColumns = useMemo(() => positionData?.columns?.map(item => {
    if (item.dataIndex === 'index') {
      return {
        ...item,
        width: 70,
      };
    }
    return item;
  }), [positionData]);

  const tableEl2 = useMemo(() => {
    if (!positionData || positionData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={positionData?.dataSource}
          columns={positionColumns}
          title={
            () => (
              <>
                <span>{positionData?.title}</span>
                <span className={style.count}>{positionData?.count}</span>
              </>
            )
          }
          strip
          loading={positionFetching}
          pagination={{
            hideOnSinglePage: true,
            current: positionPage.pageNumber,
            pageSize: positionPage.pageSize,
            total: positionData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          // locale={{
          //   emptyText: () => {
          //     if (!(tableData?.dataSource)) {
          //       return '';
          //     }
          //     return <div className={style.tableNoData}>该企业无此项数据</div>;
          //   }
          // }}
          onChange={({ current, pageSize }) => {
            setPositionPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [positionData]);

  return (
    <div className={style.all} id="就业人数">
      <div className={style.title}>就业人数</div>
      {
        tableEl1
      }
      {
        tableEl2
      }
    </div>
  );
}

export default TotalEmployment;
