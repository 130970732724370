/*
 *Author: zhaochenyu
 *Description: 分析引擎组件边框
 *Date: 2021/10/26
*/

import React, { useState, useMemo } from 'react';
import { Modal } from 'antd';
import { IconFont } from '../../../utils/mapIcon';
import style from './index.module.less';

function ChartBorder(props) {
  const { title, onRemove, children, chartIndex, onEdit, hidden, modalChildren } = props;
  const [modalShow, setModalShow] = useState(false);
  const myDate = new Date();

  const bottom = useMemo(() => (
    <>
      <span>
        <span style={{ marginRight: '16px' }}>更新时间</span>
        {`${myDate.getFullYear()}年${myDate.getMonth() + 1}月${myDate.getDate()}日`}
      </span>
      <span>
        <span style={{ marginRight: '16px' }}>数据来源</span>
        上奇产业通
      </span>
    </>
  ), []);
  return (hidden ? (
    <div className={style.onlyChart}>
      {children}
    </div>
  ) : (
    <div className={`${style.all} ${chartIndex % 2 === 1 ? style.double : undefined}`}>
      <header className={style.title}>
        <IconFont
          type="iconedit-square"
          className={style.iconLeft}
          onClick={typeof onEdit === 'function' ? onEdit : undefined}
        />
        <div className={style.headerTitle} title={title}>{title}</div>
        <IconFont
          type="iconclose"
          className={style.icon}
          onClick={typeof onRemove === 'function' ? onRemove : undefined}
        />
      </header>
      <article className={style.content}>
        <IconFont
          type="iconfullscreen"
          className={style.enlarge}
          onClick={() => setModalShow(true)}
        />
        <div className={style.chart}>
          {children}
        </div>
        <div className={style.bottom}>
          {bottom}
        </div>
      </article>
      {modalShow && (
      <Modal
        visible={modalShow}
        wrapClassName={style.enlargeChart}
        footer={null}
        onCancel={() => setModalShow(false)}
        maskClosable={false}
        // style={{ top: 250 }}
        closeIcon={<IconFont type="iconfullscreen-exit" />}
        maskStyle={{ background: 'rgba(62, 92, 118, 0.2)' }}
      >
        <div className={style.curTitle}>{title}</div>
        <div className={style.curChart}>{modalChildren}</div>
        <div className={style.curBottom}>{bottom}</div>
      </Modal>)}
    </div>
  ));
}

export default ChartBorder;
