import React from 'react';
import classNames from 'classnames';
import { usePostIndustryTreeQuery } from '../../../../../../../service/dataSearch';
import style from './index.module.less';

const rect = (value) => (
  <div className={classNames(style.rect, { [style.gray]: !value })} >
    <div className={style.core} />
  </div>
);

const getIndustryValue = (tree, key) => {
  if (tree.key === key) {
    return tree.title;
  }
  if (tree.children) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tree.children.length; i++) {
      const result = getIndustryValue(tree.children[i], key);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const Content = ({ allForm, data }) => {
  const { data: industryData } = usePostIndustryTreeQuery();
  // 获取form表单里的值
  const getFormValue = (key) => {
    if (!data) {
      return false;
    }
    let value = data.appraisal_content;
    key.forEach(item => {
      value = value[item];
    });
    if (key[1] === 'industry' && key[0] === 'industryChain') {
      if (industryData) {
        return getIndustryValue(industryData, value);
      }
      return '-';
    }
    return value;
  };

  // 判断单选是否选中
  const radioHighlight = (key) => {
    const value = getFormValue(key);
    if (value) {
      return JSON.parse(value);
    }
    return false;
  };

  // 判断指标是否符合标准 standard
  const inputHighlight = (key, standard) => {
    const value = getFormValue(key);
    if (!value) {
      return false;
    }
    // console.log(key, standard, value);
    if (/^\d+(\.\d+)?$/.test(value) && standard) {
      if ((value - 0) >= standard) {
        return true;
      }
      return false;
    }
    return true;
  };

  // 判断创新能力
  const innovationAbility = (value, children) => {
    const innovationAbilityData = data?.appraisal_content[value];
    if (children === 'researchInstitute') {
      const researchInstituteData = innovationAbilityData[children];
      if (JSON.parse(researchInstituteData.doctor) || JSON.parse(researchInstituteData.expert) || researchInstituteData.academy.length || researchInstituteData.technology.length) {
        return true;
      }
      return false;
    }
    if (children === 'revenue' || children === 'studyPersonnelRatio' || children === 'studyShareRatio' || children === 'studyShare' || children === 'financing') {
      if (innovationAbilityData.revenue.lastYear - 0 >= 10000 && innovationAbilityData.studyShareRatio.lastYear - 0 >= 3) {
        return true;
      }
      if (innovationAbilityData.revenue.lastYear - 0 >= 5000 && innovationAbilityData.studyShareRatio.lastYear - 0 >= 6) {
        return true;
      }
      const money = parseInt(innovationAbilityData.studyShare.lastYear) + parseInt(innovationAbilityData.studyShare.twoYearsAgo);
      const personnelProportion = Math.floor((parseInt(innovationAbilityData.studyPersonnelRatio.lastYear) + parseInt(innovationAbilityData.studyPersonnelRatio.twoYearsAgo)) / 2);
      if (innovationAbilityData.financing.twoYearsAgo - 0 >= 8000 && money >= 3000 && personnelProportion >= 50) {
        return true;
      }
      return false;
    }
    if (children === 'intellectualProperty') {
      if (Object.values(innovationAbilityData[children]).length) {
        return true;
      }
      return false;
    }
    return false;
  };

  const allFormEl = {
    multipleChoice: ({ value, label, name }) => (
      <div key={name} className={classNames(style.multipleChoice, style.formValue)} >
        {rect(radioHighlight([...value, name]))}
        <span className={style.value}>
          {label}
        </span>
      </div >
    ),
    noBorderInput: ({ label, value, name, unit, standard, disabled }) => (
      <div key={name} className={classNames(style.noBorderInput, style.formValue)}>
        {
          !disabled && (
            <>
              {
                label === '上年度资产负债率' ? rect(!inputHighlight([...value, name], standard)) : rect(inputHighlight([...value, name], standard))
              }
            </>
          )
        }
        <span>
          {label}
          {getFormValue([...value, name], standard) || ' - '}
          {unit || ''}
        </span>
      </div>
    ),
    multiSelect: ({ label, value, name, column }) => (
      <div key={name} className={style.multiSelect} >
        <div>
          <span className={style.value}>{label}</span>
        </div>
        <div className={classNames(style.checkbox, { [style.crosswise]: !column })}>
          {
            getFormValue([...value, name]).map(item => (
              <div key={item} className={classNames(style.children, style.formValue)}>
                {
                  item === '无' ? rect(false) : rect(true)
                }
                <span className={style.value}>{item}</span>
              </div>
            ))
          }
        </div>
      </div>
    ),
    treeSelect: ({ label, value, name }) => (
      <div key={name} className={classNames(style.noBorderInput, style.formValue)}>
        {rect(true)}
        <span>
          {label}
          {getFormValue([...value, name])}
        </span>
      </div>
    ),
    select: ({ label, value, name }) => (
      <div key={name} className={classNames(style.noBorderInput, style.formValue)}>
        {
          getFormValue([...value, name]) === '无' ? rect() : rect(true)
        }
        <span>
          {label}
          {getFormValue([...value, name])}
        </span>
      </div>
    ),
  };
  const basicInfoInput = ({ list, value }) => list.map(it => allFormEl[it.type]({ ...it, value }));
  return (
    <div className={style.box}>
      {
        allForm.map(item => (
          <div key={item.value} id={item.value} className={style.info}>
            <div className={style.title}>{item.title}</div>
            <div className={style.form}>
              {
                item.level === 2 ? item.list.map(it => (
                  <div key={it.title}>
                    {
                      it.disabled ? basicInfoInput({ ...it, value: [item.value] }) : (
                        <div className={style.levelTwo}>
                          <div className={classNames(style.subhead, style.formValue)}>
                            {it.rect && rect(innovationAbility(item.value, it.value))}
                            {/* {it.rect && rect(false)} */}
                            {it.title}
                          </div>
                          <div className={style.checkboxList}>
                            {basicInfoInput({ ...it, value: [item.value, it.value] })}
                          </div>
                        </div>
                      )
                    }
                  </div>
                )) : basicInfoInput({ ...item, value: [item.value] })
              }
            </div>
            {
              item.border ? <div className={style.line} /> : <div className={style.interval}><div /></div>
            }
          </div>
        ))
      }
    </div>
  );
};

export default Content;
