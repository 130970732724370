import React, { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
import { useQuery } from '../../../hooks/url';
import { useGetHeadDescriptionQuery, useToolStatusQuery, useButtonPermissionQuery } from '../../../service/cityDetail';
import { openNewPage } from '../../../utils/urlMethods';
import { judgeArea } from '../../../utils/tool';
import style from './index.module.less';
import {
  resetTemplateReport,
} from '../../../api/reportGeneration';
import { useLazyRecordingSpotQuery, useGetNavigationToolListQuery } from '../../../service/public';

const DetailTop = () => {
  const query = useQuery();
  const [cityTool, setCityTool] = useState(null);
  const [toolIcon, setToolIcon] = useState({});
  const [recordingSpotQuery] = useLazyRecordingSpotQuery();
  const { code, title, isProvinceDirectly } = useMemo(() => {
    const cityCode = query.get('city-code');
    if (cityCode) {
      return {
        code: window.atob(query.get('city-code')),
        title: query.get('city-title'),
        isProvinceDirectly: JSON.parse(query.get('is-province-directly')),
      };
    }
    return undefined;
  }, [query]);
  const { data } = useGetHeadDescriptionQuery({ area_key: code }, { skip: !code });
  const { data: allToolList } = useGetNavigationToolListQuery();
  const { pool } = data || {};
  const { data: industrialLivingRoom } = useButtonPermissionQuery();
  const goToCityTool = (city) => {
    if (city.value !== '1') return;
    if (city.title !== '项目配置' || city.title !== '企业预警') {
      recordingSpotQuery({ spot_name: `城市-工具-${city.title}` });
    }
    const params = {
      cityCode: window.btoa(code),
      title: city.title,
      cityName: title,
      key: city.key,
      isProvinceDirectly,
    };
    if (city.key === 'SearchMap') {
      openNewPage('tool/searchCompanyMap', params);
      // history.push({
      //   pathname: 'tool/searchCompanyMap',
      //   search: params.toString(),
      // });
    } else {
      openNewPage('cityTool', params);
    }
  };
  const { data: toolList } = useToolStatusQuery();
  const openReport = () => {
    const templateId = !isProvinceDirectly ? judgeArea(code, '1216173,1216171') : '1216173';
    resetTemplateReport({
      template_id: templateId,
      area_key_list: [code],
    }).then(res => {
      if (res.status === '00000') {
        const msg = window.btoa(window.encodeURIComponent(JSON.stringify({
          curTemplateId: templateId,
          title: '城市画像报告',
          id: res.data,
          area: title,
          areaKey: code,
        })));
        openNewPage('scheme', {
          msg,
        });
      } else {
        message.error(res.message);
      }
    });
  };
  useEffect(() => {
    if (toolList) {
      const cpToolList = cloneDeep(toolList);
      const { menu } = cpToolList;
      const companyMap = menu[menu.length - 1];
      const warning = menu[menu.length - 2];
      cpToolList.menu[menu.length - 1] = warning;
      cpToolList.menu[menu.length - 2] = companyMap;
      setCityTool(cpToolList);
    }
  }, [toolList]);
  useEffect(() => {
    if (allToolList) {
      const allMap = {};
      const allIcon = allToolList?.map(item => item.tool_list).flat();
      allIcon.forEach(item => {
        allMap[item.keyword] = item.icon;
      });
      console.log(allMap);
      setToolIcon(allMap);
    }
  }, [allToolList]);
  return (
    <>
      <div className={style.city}>
        <p>
          {title}
        </p>
        <div className={style.report} onClick={() => openReport()}>
          <div className={style.icon} />
          画像报告
        </div>
        {
          industrialLivingRoom?.menu[0].value === '1' && (
            <div onClick={() => window.open('http://dashboard.chanyeos.com')}>
              <div className={style.icon} />
              产业客厅
            </div>
          )
        }
      </div>
      <div className={style.line} />
      <div className={style.toolBox}>
        <div className={style.describe}>
          {/* 截止
          {pool?.截止时间.split('-')[0]}
          年
          {pool?.截止时间.split('-')[1]}
          月， */}
          {pool?.城市全称}
          共计
          <span>{pool?.企业数量}</span>
          家企业，专精特新
          <span>{pool?.专精特新}</span>
          家企业、上市企业
          <span>{pool?.上市企业}</span>
          家企业、高新技术企业
          <span>{pool?.高新技术}</span>
          家企业。
        </div>
        <div className={style.toolList}>
          {cityTool?.menu?.map(item => (
            <div
              className={
                classNames(style.tool, { [style[item.key]]: item.value === '2' }, { [style[`${item.key}No`]]: item.value === '0' })
              }
              key={item.key}
              onClick={() => goToCityTool(item)}
            >
              <p>
                <img src={toolIcon[item.key]} alt="" />
              </p>
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DetailTop;
