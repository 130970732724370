/**
 * 横向柱状图
*/
import React, { useMemo, useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Spin } from 'antd';
import { ECharts } from '@sqke/design';
import ChartNoData from './ChartNoData';
import style from './public/index.module.less';

const CrosswiseDar = ({ data, isFetching, top = 30, isShowOver }) => {
  const [barCrosswiseWidth, setBarCrosswiseWidth] = useState('100%');
  useEffect(() => {
    setBarCrosswiseWidth((`${data?.x?.length * 12 + (data?.x?.length / 2) * 20 + 370}px`));
  }, [data]);
  const {
    x,
    series,
  } = data || {};
  const option = useMemo(() => ({
    yAxis: {
      axisLine: {
        show: false, // 不显示坐标轴线
      },
      axisLabel: {
        // 坐标轴刻度重叠时不隐藏
        interval: 0,
        fontSize: x?.length >= 18 ? 10 : 12,
      },
      type: 'category',
      data: cloneDeep(x)?.reverse(),
    },
    tooltip: {
      formatter: params => {
        const relVal = `${params.name}<br/>${params.marker}${params.seriesName}\u3000\u3000${params.value.toLocaleString()}${series[0].unit}`;
        return relVal;
      },
    },
    grid: {
      top,
      right: 30,
      bottom: 10,
      left: 10,
    },
    xAxis: {
      show: false,
    },
    series: [
      {
        name: series?.[0]?.name,
        type: 'bar',
        barCategoryGap: '50%',
        coordinateSystem: 'cartesian2d',
        data: cloneDeep(series?.[0]?.data)?.reverse(),
        // 隐藏坐标轴
        label: {
          show: true,
          position: 'right',
          valueAnimation: true,
          fontSize: x?.length >= 18 ? 7 : 12,
        },
      },
    ],
  }), [series]);
  const chartEl = useMemo(() => {
    if (!series || series.length > 0) {
      return <ECharts
        style={{
          height: (isShowOver && (series?.[0]?.data || []).length > 10) ? `${barCrosswiseWidth}` : '100%',
          width: '100%',
        }}
        option={option}
      />;
    }
    return <ChartNoData />;
  }, [data, barCrosswiseWidth]);
  return (
    <Spin spinning={isFetching} wrapperClassName={style.loding}>
      {
        chartEl
      }
    </Spin>
  );
};

export default CrosswiseDar;
