import React from 'react';
import StackBarChart from '../../../../../Charts/StackBar';
import { useGetPatentStatisticsPublicTrendQuery } from '../../../../../../service/companyDetail';

const color = ['#4E88FF', '#59D700', '#1961F5'];
const OpenTrend = ({ companyId, grade }) => {
  const { data, isFetching } = useGetPatentStatisticsPublicTrendQuery({
    company_id: companyId,
    penetration_grade: grade,
  });
  return (
    <div style={{ width: '662px', height: '344px' }}>
      <StackBarChart data={{ ...data }} color={color} isFetching={isFetching} />
    </div >
  );
};

export default OpenTrend;
