/**
 * 针对于 echarts 的加强组件，其加强以下几点：
 * 1. 提供预设主题；
 * 2. 各个系列的颜色、区域颜色支持数组配置；
 */

import type { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { forwardRef, LegacyRef, useEffect, useMemo } from 'react';
import EChartsReact, { EChartsReactProps } from 'echarts-for-react';
import engineLight from './theme/engine-light.json';
import engineLightExtend from './theme/engine-light-extends.json';
import { register } from '@sqke/echarts-wordcloud';
import { convertECUnitOptionEx, EChartsThemeManager } from '@sqke/parser'
import { array } from '../../utils/array';
let isWordCloudRegistered = false;

const THEME: { [key: string]: any } = {
  engineLight: {
    base: engineLight,
    extend: engineLightExtend,
  },
};

const echartsThemeManager = new EChartsThemeManager(echarts);

Object.entries(THEME).forEach(([key, theme]) => {
  // 主题注册
  echartsThemeManager.registerTheme(key, theme);
});


function InnerECharts(props: Omit<EChartsReactProps, 'option'> & { option: EChartsOption }, ref?: LegacyRef<EChartsReact>) {
  const { theme = "engineLight", option } = props;

  if (
    !isWordCloudRegistered
  ) {
    register(echarts);
    isWordCloudRegistered = true;
  }

  const _option = useMemo(() => {
    if (!option || typeof theme === 'object' || !theme) {
      return option;
    }

    return convertECUnitOptionEx(
      echartsThemeManager.useTheme(option, theme)
    );
  }, [option, theme]);

  return (
    <EChartsReact
      {...props}
      option={_option}
      theme={theme}
      ref={ref}
    />
  )
}
export { echarts, echartsThemeManager, };
export const ECharts = forwardRef(InnerECharts);
