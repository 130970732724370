import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Radio, Checkbox, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RollbackOutlined, DownloadOutlined } from '@ant-design/icons';
import { setReportDownLoadUrl } from '../../store';
import { base64toFile } from '../../../../utils/report';
import settingIcon from '../../../../assets/images/dataAnalysisTool/settingIcon.png';
import confirmIcon from '../../../../assets/images/dataAnalysisTool/myReport/confirmIcon.png';
import GenerateDirectory from './components/GenerateDirectory';
import { useGetSideTableContentsQuery } from '../../../../service/chartTableTool';
import { analysisSaveReportByPic } from '../../../../api/office';
import Preview from '../ChartTableTool/Preview';
import { openNewPage } from '../../../../utils/urlMethods';
import styles from './index.module.less';

const showDirectory = (dir) => {
  if (dir?.length) {
    const newDir = dir.filter(i => !i.disable).map(item => {
      if (item.child) {
        return {
          ...item,
          child: showDirectory(item.child),
        };
      }
      return item;
    });
    return newDir;
  }
  return null;
};

const treeFilter = (tree, rootLevel) => tree.map(node => ({ ...node })).filter(node => {
  node.level = rootLevel;
  node.disable = false;
  node.child = node.child && treeFilter(node.child, rootLevel + 1);
  return node.type === 'group';
});

const lastLevelChild = (list) => {
  if (list.length > 0) {
    const newList = list.map(item => ({
      ...item,
      level: 3,
    }));
    return newList;
  }
  return [];
};

const mergeTrees = (treeA, treeB) => {
  const result = [];
  if (treeA?.length && treeB?.length) {
    // 遍历第一个树形数组
    treeA.forEach(nodeA => {
      let merged = false;
      // 遍历第二个树形数组，找到与nodeA.id匹配的节点
      treeB.forEach(nodeB => {
        if (nodeA.id === nodeB.id) {
          // 找到匹配的节点，合并两个节点
          const mergedNode = { ...nodeB, ...nodeA };
          mergedNode.child = nodeB.level === 2 ? lastLevelChild(nodeA.child) : mergeTrees(nodeA.child, nodeB.child);
          result.push(mergedNode);
          merged = true;
        }
      });

      // 如果在第二个树形数组中没有找到匹配的节点，将nodeA添加到结果数组中
      if (!merged) {
        result.push(nodeA);
      }
    });
  }
  // 返回合并后的树形数组
  return result;
};

export default function ReportPreview(props) {
  const history = useHistory();
  const boxRef = useRef();
  const dispatch = useDispatch();
  const { projectDetails = {} } = JSON.parse(localStorage.getItem('dataFilterParams')) || {};
  const reportTitle = JSON.parse(localStorage.getItem('reportTitle')) || '';
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [reportAuthority, setReportAuthority] = useState('私密');
  const [directorySelect, setDirectorySelect] = useState();
  const [directoryFinal, setDirectoryFinal] = useState();
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [targetLocation, setTargetLocation] = useState();
  const [disableList, setDisableList] = useState([]);
  const [reportUrl, setReportUrl] = useState('');
  const [download, setDownload] = useState(false);
  const [reportData, setReportData] = useState(useSelector(store => store.dataAnalysis.reportDataStore));
  const [intelligentData, setIntelligentData] = useState(useSelector(store => store.dataAnalysis.intelligentDataStore));
  const [isDownloadBtnDisable, setIsDownloadBtnDisable] = useState(false); // 下载报告按钮是否禁用
  const [newDirectoryData, setNewDirectoryData] = useState();
  // const reportData = useSelector(store => store.dataAnalysis.reportDataStore);
  // const intelligentDataStore = useSelector(store => store.dataAnalysis.intelligentDataStore);
  const { data: directoryData } = useGetSideTableContentsQuery();
  // 映射报告列表key
  const allReportMap = (repotrList = directoryData) => {
    const lv1 = repotrList.map(item => item.child.map(it => ({ ...it.child[0], disable: it.disable, parent: item.name })));
    const lv1Parent = lv1.map(item => item.map(it => ({ [it.id]: it.parent, disable: it.disable })));
    let lv1ParentMap = {};
    lv1Parent.forEach(item => {
      item.forEach(it => {
        lv1ParentMap = { ...it, ...lv1ParentMap };
        if (it.disable) {
          Object.keys(it).forEach(it2 => {
            if (it2 !== 'disable') {
              setDisableList(params => Array.from(new Set([...params, it2])));
            }
          });
        }
        // setDisableList(params => [...params, ])
      });
    });
    delete lv1ParentMap.disable;
    return lv1ParentMap;
  };

  // 下载报告
  const downloadReport = () => {
    setDownload(false);
    dispatch(setReportDownLoadUrl(reportUrl));
    openNewPage('reportDownLoad', { url: window.btoa(window.encodeURIComponent(reportUrl)), name: window.btoa(window.encodeURIComponent(reportTitle || projectDetails.title)) });
    // 创建一个下载链接
    // const link = document.createElement('a');
    // link.href = reportUrl;
    // link.download = `${projectDetails}.png`; // 设置下载文件名
    // // 将链接添加到DOM中并模拟点击下载
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };
  const generateReportData = () => {
    const picList = [];
    const reportDataList = [];
    newDirectoryData.forEach((item, index) => {
      if (!item.child.length) {
        return;
      }
      reportDataList.push({
        type: 'heading',
        properties: {
          text: item.name,
          level: 1,
          style: {},
        },
      });
      const writingArr = localStorage.getItem('intelligentWriting') ? JSON.parse(localStorage.getItem('intelligentWriting')) : [];
      const writingDataArr = writingArr.filter(node => node.text);
      if (item.name === '智能撰写' && writingDataArr?.length > 0) {
        // eslint-disable-next-line no-plusplus
        item.child.forEach(i => {
          if (i.disable || intelligentData.filter(writeItem => writeItem.id === i.id).length === 0) {
            return;
          }
          const intelligentDataKey = i.id;
          // eslint-disable-next-line no-plusplus
          const title = {
            type: 'heading',
            properties: {
              text: i.name,
              level: 2,
              style: {},
            },
          };
          const content = {
            type: 'data_analysis_text',
            properties: {
              contents: intelligentData.filter(node => node.id === intelligentDataKey)?.[0]?.message,
            },
          };
          reportDataList.push(title);
          reportDataList.push(content);
        });
        return;
      }
      // eslint-disable-next-line no-plusplus
      item.child.forEach(it => {
        if (it.disable) {
          return;
        }
        const reportDataKey = it.child[0].id.toString();
        if (reportData[reportDataKey].el && reportData[reportDataKey].type !== 'table') {
          picList.push(reportData[reportDataKey].el);
        }
        // eslint-disable-next-line no-plusplus
        const title = {
          type: 'heading',
          properties: {
            text: it.name,
            level: 2,
            style: {},
          },
        };
        const content = {
          type: 'data_analysis_text',
          properties: {
            contents: reportData[reportDataKey].contents,
          },
        };
        const table = {
          type: 'table',
          properties: {
            title: {
              show: false,
              content: '',
            },
            dataConfig: {
              dataSource: {
                data: {
                  columns: reportData[reportDataKey]?.el?.columns || reportData[reportDataKey]?.data?.tableData?.el?.columns || [],
                  dataSource: reportData[reportDataKey]?.el?.dataSource || reportData[reportDataKey]?.data?.tableData?.el?.dataSource || [],
                },
              },
            },
            table_scale: reportData[reportDataKey]?.el?.columns?.map((it2, index2) => (index2 === 1 ? 1.2 : 1)) || reportData[reportDataKey]?.data?.tableData?.el?.columns.map((it2, index2) => (index2 === 1 ? 1.2 : 1)),
          },
        };
        // 图片
        const img = {
          type: 'config-echarts',
          properties: {
            pic_url: `${picList.length - 1}.png`,
            title: {
              show: 'True',
              content: reportData[reportDataKey].content,
            },
          },
        };
        reportDataList.push(title);
        if (reportData[reportDataKey].el && reportData[reportDataKey].type !== 'table') {
          reportDataList.push(content);
          reportDataList.push(img);
        }
        if (reportData[reportDataKey].data.numberFlat && reportData[reportDataKey].type !== 'table') {
          reportDataList.push(table);
        }
        if (reportData[reportDataKey].type === 'table') {
          reportDataList.push(table);
        }
      });
    });
    const req = {
      template_id: 2316279,
      authority: reportAuthority === '私密' ? '1' : '2',
      component_list: JSON.stringify(reportDataList),
      pic_list: picList.map(item => base64toFile(item, '')),
      macro: JSON.stringify({
        template_name: reportTitle || projectDetails.title || '',
        sub_template_name: projectDetails.subheading || '',
      }),
    };
    analysisSaveReportByPic(req).then(res => {
      message.destroy();
      setIsDownloadBtnDisable(false);
      if (res.status === '00000') {
        message.success('报告下载完成');
        setReportUrl(res.data);
        setDownload(true);
      } else {
        message.success(res.message);
      }
    });
  };

  // 报告设置弹窗关闭
  const handleSettingClose = () => {
    setIsSettingModalOpen(false);
  };
  // 报告设置弹窗确定
  const handleSettingConfirm = () => {
    setDirectoryFinal(directorySelect);
    setIsSettingModalOpen(false);
    // setDisableList(allReportMap(directorySelect));
    // console.log(directorySelect);
  };
  // 点击下载
  const handleDownload = () => {
    const iSrenderComplete = Object.keys(reportData).every(item => reportData[item].el);
    if (!iSrenderComplete) {
      message.warning('报告生成中请稍后');
      return;
    }
    message.loading('报告下载中...', 0);
    setIsDownloadBtnDisable(true);
    generateReportData();
  };

  // 点击返回
  const handleReturn = () => {
    history.push('/dataAnalysisTool/chartTableTool');
  };

  useEffect(() => {
    const cancel = history.block(location => {
      setIsExitModalOpen(true);
      setTargetLocation(location);
      return false;
    });
    return () => cancel();
  }, [isExitModalOpen]);

  const beforeunloadListener = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeunloadListener);
    };
  }, []);

  // 点击退出弹窗取消
  const handleExitClose = () => {
    setIsExitModalOpen(false);
    history.block(location => true);
    history.push('/dataAnalysisTool/reportPreview');
    setTargetLocation();
  };

  // 点击退出弹窗确定
  const handleExitConfirm = () => {
    setIsExitModalOpen(false);
    history.block(location => true);
    history.push(targetLocation);
  };

  useEffect(() => {
    if (directoryData) {
      setDirectorySelect(treeFilter(directoryData, 1));
      setDirectoryFinal(treeFilter(directoryData, 1));
    }
  }, [directoryData]);

  useEffect(() => {
    setNewDirectoryData(mergeTrees(directoryData, directoryFinal));
    allReportMap(mergeTrees(directoryData, directoryFinal));
    // console.log((directoryData, directoryFinal));
  }, [directoryData, directoryFinal]);

  useEffect(() => {
    if (directoryFinal) {
      localStorage.setItem('directoryFinal', JSON.stringify(directoryFinal));
    }
  }, [directoryFinal]);
  return (
    <div className={styles.wrapper} ref={boxRef}>
      <div className={styles.report}>
        <div className={styles.cover}>
          <div className={styles.title}>{reportTitle || projectDetails?.title}</div>
          <div className={styles.subtitle}>{projectDetails?.subheading}</div>
          <div className={styles.creator}>北京上奇产业研究</div>
          <div className={styles.date}>
            <span>
              <span className={styles.num}>{projectDetails?.update_time?.split('-')?.[0]}</span>
              年
            </span>
            <span>
              <span className={styles.num}>{projectDetails?.update_time?.split('-')?.[1]}</span>
              月
            </span>
          </div>
        </div>
        <div className={styles.directory}>
          <GenerateDirectory newDirectoryData={newDirectoryData} boxRef={boxRef} />
        </div>
        <div>
          <Preview data={showDirectory(newDirectoryData)} disableList={disableList} reportData={reportData} setReportData={setReportData} />
        </div>
      </div>
      <div className={styles.btnBox}>
        <Button onClick={handleReturn}>
          <RollbackOutlined />
          返回
        </Button>
        <Button onClick={() => setIsSettingModalOpen(true)}>
          <img src={settingIcon} alt="" />
          删选组件
        </Button>
        <Button onClick={handleDownload} disabled={isDownloadBtnDisable}>
          <DownloadOutlined />
          下载报告
        </Button>
      </div>
      <Modal
        title={<div className={styles.settingTitle}>删选组件</div>}
        footer={null}
        open={isSettingModalOpen}
        onCancel={handleSettingClose}
        width={680}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.settingContent}>
          <div className={styles.setting}>
            <div className={styles.label}>报告权限设置</div>
            <Radio.Group onChange={(e) => setReportAuthority(e.target.value)} value={reportAuthority}>
              <Radio value="私密">私密</Radio>
              <Radio value="公开">公开</Radio>
            </Radio.Group>
          </div>
          {
            directorySelect && directorySelect.map(item => (
              <div className={styles.setting} key={item.id}>
                <div className={styles.label}>{item.name}</div>
                <div className={styles.checkBox}>
                  {
                    item.child && item.child.map(i => (
                      <Checkbox
                        checked={!i.disable}
                        key={i.id}
                        onChange={(e) => setDirectorySelect(prev => prev.map(node => {
                          if (node.name === item.name) {
                            const newChildren = item.child.map(childrenItem => {
                              if (childrenItem.id === i.id) {
                                return {
                                  ...childrenItem,
                                  disable: !e.target.checked,
                                };
                              }
                              return childrenItem;
                            });
                            return {
                              ...node,
                              child: newChildren,
                            };
                          }
                          return node;
                        }))}
                      >
                        {i.name}
                      </Checkbox>
                    ))
                  }
                </div>
              </div>
            ))
          }
          <div className={styles.buttonBox}>
            <Button onClick={handleSettingClose} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={handleSettingConfirm}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <div className={styles.exitTitle}>
            <img src={confirmIcon} alt="" />
            是否确认退出当前页面？
          </div>
        }
        footer={null}
        open={isExitModalOpen}
        onCancel={handleExitClose}
        width={360}
        wrapClassName={styles.dataToolModal}
      >
        <div className={styles.exitContent}>
          <div className={styles.text}>关闭当前页面将导致您手动修改的图表和数据无法保存</div>
          <div className={styles.buttonBox}>
            <Button onClick={handleExitClose} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={handleExitConfirm}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={null}
        open={download}
        footer={null}
        closable={false}
      >
        <div className={styles.exitContent}>
          <div className={styles.text}>是否下载报告</div>
          <div className={styles.buttonBox}>
            <Button onClick={() => { setDownload(false); }} style={{ marginRight: 12 }}>
              取消
            </Button>
            <Button type="primary" onClick={downloadReport}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
