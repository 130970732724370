import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useGetParkEquityFinancingQuery } from '../../../../../../service/parkDetail';
import Barandline from '../../../../../../components/Charts/BarAndLine';
import HollowPie from '../../../../../../components/Charts/HollowPie';
import StackBar from '../../../../../../components/Charts/StackBar';
import Underline from '../../../../../../components/Underline';
import Subtitle from '../../../../../../components/Subtitle';
import ExportModal from '../../../../../../components/ExportModal';
import InvestmentTable from './InvestmentTable';
import Input from '../../../../../../components/Input';
// import { openNewPage } from '../../../../../../utils/urlMethods';
import { jumpCompantDetail, jumpInstitutionDetail } from '../../../../../../utils/jumpCompantDetail';
import style from './index.module.less';

const DEFAULT_TABLE_STATE = {
  pageSize: 20,
  pageNumber: 1,
  filteredInfo: {},
  sortedInfo: {},
};

function EquityFinancing({ title, parkCode, allNumberData }) {
  const permission = JSON.parse(localStorage.getItem('pm'));
  const enterpriseAccountExport = permission?.enterpriseAccountExport;
  const exportFlag = Array.isArray(enterpriseAccountExport)
    && enterpriseAccountExport?.length > 0
    && enterpriseAccountExport?.includes('exportCityDataInvest');

  const [keyword, setKeyword] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tableState, setTableState] = useState(DEFAULT_TABLE_STATE);
  const [count, setCount] = useState(0);
  const [params, setParams] = useState(null);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const { data: chartData, isFetching } = useGetParkEquityFinancingQuery({
    park_id: parkCode,
  }, {
    skip: !parkCode,
  });
  const generateSeries = (newData) => newData?.map(item => (
    {
      ...item,
      type: item.name === '交易事件' ? 'line' : 'bar',
    }
  ));

  const generateChangeSeries = (newData) => newData?.map(item => (
    {
      ...item,
      type: item.name === '融资事件' ? 'line' : 'bar',
    }
  ));

  useEffect(() => {
    setTableState(DEFAULT_TABLE_STATE);
  }, [parkCode]);

  const onData = useCallback((data = {}) => {
    const { count: _count } = data;
    setCount(_count);
  }, []);
  return (
    <div className={style.all} id={title}>
      <div className={style.title}>{title}</div>
      {/* <div className={style.description}>2017年01月至2022年04月，湖州莫干山高新技术产业开发区发生融资14,374笔，占全国融资事件的19.71%，居全国排名第1位。北京市获得融资388,226,722万元，占全国融资金额的21.98%，居全国排名第1位。</div> */}
      <div className={style.main}>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[0]?.title ?? ''}
            {chartData?.[0].subtitle && `(${chartData?.[0].subtitle})`}
          </div>
          <div className={style.chart}>
            <Barandline
              data={{
                ...chartData?.[0],
                // highlight: chartData?.[0]?.highlight[0],
                series: generateSeries(chartData?.[0]?.series),
                leftYName: [0, 30, 0, 0],
                rightYName: [0, 0, 0, 50],
                left: 40,
                right: 40,
              }}
              isFetching={isFetching}
              scoreShow
              textLength={6}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[1]?.title ?? ''}
            {chartData?.[1].subtitle && `(${chartData?.[1].subtitle})`}
          </div>
          <div className={style.chart}>
            <Barandline
              data={{
                ...chartData?.[1],
                // highlight: chartData?.[1]?.highlight[0],
                series: generateSeries(chartData?.[1]?.series),
                leftYName: [0, 30, 0, 0],
                rightYName: [0, 0, 0, 40],
                left: 40,
                right: 40,
                bottom: 80,
                rotate: 40,
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[2]?.title ?? ''}
            {chartData?.[2].subtitle && `(${chartData?.[2].subtitle})`}
          </div>
          <div className={style.chart}>
            <HollowPie data={chartData?.[2].series[0]} roseType="radius" radius={['20%', '70%']} isFetching={isFetching} />
          </div>
        </div>
      </div>
      <div className={style.main}>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[3]?.title ?? ''}
            {chartData?.[3].subtitle && `(${chartData?.[3].subtitle})`}
          </div>
          <div className={style.chart}>
            <Barandline
              data={{
                ...chartData?.[3],
                series: generateChangeSeries(chartData?.[3]?.series),
                leftYName: [0, 40, 0, 0],
                rightYName: [0, 0, 0, 40],
                left: 40,
                right: 40,
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[4]?.title ?? ''}
            {chartData?.[4].subtitle && `(${chartData?.[4].subtitle})`}
          </div>
          <div className={style.chart}>
            <StackBar
              data={{
                ...chartData?.[4],
                leftYName: [0, 30, 0, 0],
                bottom: 70,
                rotate: 30,
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[5]?.title ?? ''}
            {chartData?.[5].subtitle && `(${chartData?.[5].subtitle})`}
          </div>
          <div className={style.chart}>
            <HollowPie data={chartData?.[5].series[0]} roseType="radius" radius={['20%', '70%']} isFetching={isFetching} />
          </div>
        </div>
      </div>
      <div className={style.table}>
        <div className={style.header}>
          <div className={style.title}>{allNumberData.length > 0 && allNumberData[2].title}</div>
          {
            allNumberData.length > 0 && <Subtitle
              description="共计"
              value={allNumberData[2].value ?? '-'}
              unit={allNumberData[2].unit}
              className={style.subtitle}
            />
          }
          {
            allNumberData.length > 0 && < Subtitle
              description="当前数据结果"
              value={count ?? '-'}
              unit={allNumberData[2].unit}
              className={style.subtitle}
            />
          }
          <Input
            className={clsx(style.search, { [style.right]: !exportFlag })}
            value={keyword}
            placeholder="搜索企业名称、投资方"
            onChange={e => {
              setKeyword(e.target.value);
              if (e.target.value === '') {
                setSearchKeyword('');
                setTableState({
                  ...DEFAULT_TABLE_STATE,
                  sortedInfo: tableState?.sortedInfo,
                });
              }
            }}
            onPressEnter={value => {
              setSearchKeyword(value);
              setTableState({
                ...DEFAULT_TABLE_STATE,
                sortedInfo: tableState?.sortedInfo,
              });
            }}
            onSearch={value => {
              setSearchKeyword(value);
              setTableState(DEFAULT_TABLE_STATE);
            }}
          />
          {
            exportFlag && (
              <div className={style.exportButton} onClick={() => setExportModalVisible(true)}>数据导出</div>
            )
          }
        </div>
        <Underline />
        <InvestmentTable
          keyword={searchKeyword}
          parkCode={parkCode}
          pageNumber={tableState.pageNumber}
          pageSize={tableState.pageSize}
          sortedInfo={tableState.sortedInfo}
          filteredInfo={tableState.filteredInfo}
          onData={onData}
          onCompanyClick={({ _id }) => {
            if (!_id) return;
            jumpCompantDetail(_id);
          }}
          onInvestmentClick={({ invest_id: investId }) => {
            if (!investId) return;
            // openNewPage('investmentDetail', { id: investId });
            jumpInstitutionDetail(investId);
          }}
          onChange={({ current }, filters, sorter) => {
            setTableState(prev => ({
              ...prev,
              pageNumber: current,
              filteredInfo: filters,
              sortedInfo: sorter,
            }));
          }}
          setParams={setParams}
        />
      </div>
      {
        exportModalVisible && (
          <div className={style.mask}>
            <ExportModal
              dataKey="find_investment_list"
              params={params}
              onClose={() => {
                setExportModalVisible(false);
              }}
            />
          </div>
        )
      }
    </div>
  );
}
export default EquityFinancing;
