import React, { useState, useRef } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import industry from './imges/industry.png';
import recruit from './imges/recruit.png';
import marketing from './imges/marketing.png';
import monitor from './imges/monitor.png';
import migration from './imges/migration.png';
import diagnose from './imges/diagnose.png';
import cultivate from './imges/cultivate.png';
import expand from './imges/expand.png';
import target from './imges/target.png';
import analyse from './imges/analyse.png';
import government from './imges/government.png';
import organization from './imges/organization.png';
import company from './imges/company.png';
import {
  useLazyGetProIntroduceCaptchaQuery,
  useLazyBusinessCooperationQuery,
} from '../../service/proIntroduce';
import style from './index.module.less';

const plate = [
  {
    name: '产业研究',
    hint: ['万亿数据一键导出', '百万报告生成下载'],
    icon: industry,
  },
  {
    name: '精准招商',
    hint: ['优质企业动态推荐', '项目论证画像评估'],
    icon: recruit,
  },
  {
    name: '营销拓客',
    hint: ['沿产业链高效拓客', '顺供应链挖掘需求'],
    icon: marketing,
  },
  {
    name: '运行监测',
    hint: ['迁移监测短板诊断', '产业情报风险预警'],
    icon: monitor,
  },
];

const left = [
  {
    name: '迁移监测',
    hint: '监测全国企业迁移动向，推送外迁预警名单',
    icon: migration,
  },
  {
    name: '短板诊断',
    hint: '摸清产业家底，识别产业发展长短板',
    icon: diagnose,
  },
  {
    name: '分级培育',
    hint: '整合龙头企业资源，构建梯度培育企业库',
    icon: cultivate,
  },
];

const right = [
  {
    name: '锚点拓客',
    hint: '寻找目标区域优质供应商，发现潜在客户',
    icon: expand,
  },
  {
    name: '靶向招商',
    hint: '聚焦关键环节补短板、锻长板，推荐招商短名单',
    icon: target,
  },
  {
    name: '数据分析',
    hint: '根据条件筛选分析对象，自定义报告模板',
    icon: analyse,
  },
];

const serve = [
  { name: '政府园区', icon: government },
  { name: '智库机构', icon: organization },
  { name: '龙头企业', icon: company },
];
const ProIntroduce = () => {
  const formRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countDown, setCountDown] = useState();
  const [getProIntroduceCaptchaQuery] = useLazyGetProIntroduceCaptchaQuery();
  const [businessCooperationQuery] = useLazyBusinessCooperationQuery();

  const getAuthCode = async (phone) => {
    const { data, isError } = await getProIntroduceCaptchaQuery({ phone_no: phone, operate_type: 'check_phone' });
    if (!isError) {
      if (data?.msg === '发送成功') {
        message.success(data?.msg);
      } else {
        message.warning('发送失败请刷新重试');
      }
      setCountDown(60);
      const time = setInterval(() => {
        setCountDown(params => {
          if (params < 1) {
            clearInterval(time);
            return 0;
          }
          return params - 1;
        });
      }, 1000);
    } else {
      message.error(data);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const getAuthCodeQuery = () => {
    const { phone } = formRef.current.getFieldsValue();
    if (!phone) {
      message.info('请输入手机号');
      return;
    }
    const regex = /^1[3-9]\d{9}$/;
    if (!regex.test(phone)) {
      message.info('请输入正确的手机号');
      return;
    }
    getAuthCode(phone);
  };
  const onFinish = async (values) => {
    const { error, data } = await businessCooperationQuery(values);
    if (data?.msg === '提交信息成功') {
      setIsModalOpen(false);
      message.success(data.msg);
    } else {
      message.warning(error?.data?.message || '提交失败请刷新重试');
    }
  };
  const onFinishFailed = () => {
    message.warning('请正确填写表单内容');
  };
  return (
    <div className={style.proIntroduce}>
      <div className={style.hander}>
        <div className={style.logo} >
          <p />
        </div>
        <span className={style.name} onClick={() => { setIsModalOpen(true); }}>
          业务咨询
        </span>
      </div>
      <div className={style.banner}>
        <span className={style.title}>上奇产业通 &nbsp; &nbsp;一键通产业</span>
        <div className={style.tag}>
          <span>SaaS账号服务</span>
          <span>数据接口服务</span>
          <span>定制开发服务</span>
        </div>
        <button type="button" onClick={() => { setIsModalOpen(true); }}> 业务咨询 </button>
      </div>
      <div className={style.intro}>
        <span className={style.title}>更专业，更便捷</span>
        <span className={style.describe}>覆盖100余个产业链、30万种产品、6000万家企业等45类数据集，提供产业数据和关系的一站式信息搜索、图谱绘制、内容订阅、报告生成、企业尽调、项目推荐等服务。</span>
        <div className={style.plate}>
          {
          plate.map((item, index) => (
            <div className={style.tag} key={index}>
              <div className={style.icon}>
                <img src={item.icon} alt="" />
              </div>
              <span className={style.name}>{item.name}</span>
              {
                item.hint.map((it, index2) => (
                  <div className={style.hint} key={index2}>
                    <span className={style.circle} />
                    <span className={style.text}>{it}</span>
                  </div>
                ))
              }
            </div>
          ))
        }

        </div>
      </div>
      <div className={style.module}>
        <div className={style.title}>功能应用</div>
        <div className={style.tagBox}>
          <div className={style.left}>
            {
            left.map((item, index) => (
              <div key={index} className={style.tag}>
                <span className={style.name}>{item.name}</span>
                <span className={style.hint}>{item.hint}</span>
                <div className={style.icon}>
                  <img src={item.icon} alt="" />
                </div>
              </div>
            ))
          }
          </div>
          <div className={style.right}>
            {
            right.map((item, index) => (
              <div key={index} className={style.tag}>
                <span className={style.name}>{item.name}</span>
                <span className={style.hint}>{item.hint}</span>
                <div className={style.icon}>
                  <img src={item.icon} alt="" />
                </div>
              </div>
            ))
          }
          </div>
        </div>
      </div>
      <div className={style.serve}>
        <div>
          <div className={style.title}>服务客户</div>
          {
        serve.map((item, index) => (
          <div className={style.tag} key={index}>
            <span className={style.name}>{item.name}</span>
            <div className={style.icon}>
              <img src={item.icon} alt="" />
            </div>
          </div>
        ))
      }
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.left}>
          <span className={style.title}>联系我们</span>
          <span className={style.relation}>电话咨询：172-0030-9680</span>
          <span className={style.relation}>合作邮箱：business@ishang.com</span>
        </div>
        <div className={style.right}>
          <div className={style.public}>
            <span />
            <span>微信公众号</span>
          </div>
          <div className={style.wx}>
            <span />
            <span>微信小程序</span>
          </div>
        </div>
      </div>
      <Modal
        wrapClassName={style.modal}
        destroyOnClose
        title={
          <span className={style.title}>
            业务咨询
          </span>
        }
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className={style.modalContent}>
          <div className={style.describe}>
            感谢您的留言，我们会尽快联系您。客服
            <span>172-0030-9680</span>
          </div>
          <Form
            ref={formRef}
            className={style.form}
            name="basic"
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 20,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="需求"
              name="demand"
              rules={[
                {
                  required: true,
                  message: '请输入您的需求',
                },
              ]}
            >
              <Input placeholder="请输入您的需求" />
            </Form.Item>
            <Form.Item
              label="公司"
              name="company_name"
              rules={[
                {
                  required: true,
                  message: '请输入您的企业名称',
                },
              ]}
            >
              <Input placeholder="请输入您的企业名称" />
            </Form.Item>
            <Form.Item
              label="姓名"
              name="rela_name"
              rules={[
                {
                  required: true,
                  message: '请输入姓名',
                },
              ]}
            >
              <Input placeholder="请输入姓名" />
            </Form.Item>
            <Form.Item
              label="手机"
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: /^1[3-9]\d{9}$/,
                  message: '请输入正确的手机号码',
                },
              ]}
            >
              <Input placeholder="请输入手机号码" />
            </Form.Item>
            <Form.Item
              label="验证码"
              name="captcha"
              rules={[
                {
                  required: true,
                  pattern: /^\d{6}$/,
                  message: '请输入正确的验证码',
                },
              ]}
            >
              <Input
                placeholder="请输入验证码"
                className={style.code}
                suffix={(
                  <div >
                    {
                  countDown ? (
                    <div className={style.countDown}>
                      {countDown}
                      s
                    </div>
                  ) : (
                    <div className={style.getCode} onClick={getAuthCodeQuery}>
                      发送验证码
                    </div>
                  )
                }
                  </div>
            )}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 11,
                span: 4,
              }}
            >
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ProIntroduce;
