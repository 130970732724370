import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Button } from 'antd';
import { useQuery } from '../../hooks/url';
import apply from '../../assets/images/myself/shangqiCoin.png';
import style from './index.module.less';

export default function SQPayAmountCard(props) {
  const { sqcoin, count, paySuccess, successPayLoading = false } = props;
  const history = useHistory();
  const query = useQuery();

  const paySQcoin = () => {
    if (count > sqcoin) {
      message.warning('上奇币不足，请获取！');
      return;
    }
    paySuccess();
  };

  const pushDetailPage = useCallback((key) => {
    query.set('tab', '个人中心');
    query.set('detail', key);
    history.push({
      pathname: 'accountSetting',
      search: query.toString(),
    });
  }, [query]);

  return (
    <div className={style.applyMethod}>
      <div className={style.left}>
        <div>付款方式</div>
        <div style={{ display: 'flex' }}>
          <img alt="" src={apply} style={{ width: '18px', height: '21px', marginRight: '4px' }} />
          上奇币
        </div>
      </div>
      <div className={style.right}>
        <Button loading={!count || successPayLoading} className={style.apllyCount} onClick={() => paySQcoin()}>{`${count}上奇币`}</Button>
        {
          sqcoin >= count && (
            <div className={style.balance}>
              当前余额
              {sqcoin}
              上奇币
            </div>
          )
        }
        {
          count > sqcoin && (
            <div className={style.lastLine}>
              <span className={style.toget} onClick={() => pushDetailPage('achieve')}>转发好友</span>
              赠上奇币，免费开通频道
              {/* 当前余额
              {sqcoin}
              上奇币， */}
            </div>
          )
        }
      </div>
    </div>
  );
}
