import React, { useMemo, useEffect } from 'react';
import Table from '../../../../../../../components/Table';
import {
  useGetParkFindInvestmentListQuery,
  useGetParkChannelInvestmentRoundListQuery,
} from '../../../../../../../service/parkDetail';
import {
  useGetIndustryTreeQuery,
} from '../../../../../../../service/interactive-v2';
import { getNameConfig, getSortKv } from '../../../../../../../utils/table';
import SortTitle from '../../SortTitle';
import { getSingleOption } from '../../../../../../../utils/tool';
import { FilterIcon } from '../../../../../../../components/CustomTableIcon';
import CustomListFilter from '../../../../../../../components/CustomListFilter';
import CustomTreeFilter from '../../../../../../../components/CustomTreeFilter';

const MultiLineEllipsis = text => {
  const _text = Array.isArray(text) ? text.join('、') : text;
  return <div className="overflow-multi-5" title={_text}>{_text}</div>;
};

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: 'table-index',
  },
  ent_name: {
    title: '企业名称',
    dataIndex: 'ent_name',
    key: 'ent_name',
    ellipsis: true,
  },
  location: {
    title: '区域',
    dataIndex: 'location',
    key: 'location',
    width: 140,
    ellipsis: true,
    // filters: [],
    // filterMode: 'tree',
    // filterIcon: <FilterIcon />,
  },
  tag_name: {
    title: '产业',
    dataIndex: 'tag_name',
    key: 'tag_name',
    width: 146,
    ellipsis: true,
    filters: [],
    filterMode: 'tree',
    filterIcon: <FilterIcon />,
    render: text => (Array.isArray(text) ? text.join('、') : text),
  },
  total_money: {
    title: '融资金额',
    dataIndex: 'total_money',
    key: 'total_money',
    width: 100,
    sortDirections: ['ascend', 'descend'],
    sorter: true,
    className: 'table-number',
    render: MultiLineEllipsis,
  },
  announce_date: {
    title: '融资时间',
    dataIndex: 'announce_date',
    key: 'announce_date',
    width: 100,
    sortDirections: ['ascend', 'descend'],
    sorter: true,
    className: 'table-number',
    render: MultiLineEllipsis,
  },
  value: {
    title: '融资轮次',
    dataIndex: 'value',
    key: 'value',
    width: 100,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: MultiLineEllipsis,
  },
  short: {
    title: '投资方',
    dataIndex: 'short',
    key: 'short',
    width: 220,
    render: MultiLineEllipsis,
  },
  financing: {
    title: '累计融资总额',
    dataIndex: 'financing',
    key: 'financing',
    width: 140,
    className: 'table-number',
    render: MultiLineEllipsis,
  },
};

const COLUMN_KEYS = [
  'index', 'ent_name', 'location', 'tag_name', 'total_money', 'announce_date',
  'value', 'short', 'financing',
];

function InvestmentTable(props) {
  const {
    keyword,
    parkCode,
    sortedInfo = {},
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onData,
    onCompanyClick,
    onInvestmentClick,
    setParams,
    industryCode = 'all',
  } = props;

  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );
  const { data: round } = useGetParkChannelInvestmentRoundListQuery();
  // const { data: regionTree = [] } = useGetAreaPlugMarqueeV2Query({
  //   area_code: parkCode,
  // });
  const {
    key: sortKey,
    value: sortValue,
  } = getSortKv(sortedInfo);
  const { data, isFetching } = useGetParkFindInvestmentListQuery({

    key_word: keyword,
    park_id: getSingleOption(filteredInfo.location) ?? parkCode,
    industry_code: getSingleOption(filteredInfo.tag_name) ?? industryCode,
    sort_key: sortKey,
    sort_value: sortValue,
    round: (filteredInfo?.value && filteredInfo.value.filter(item => item !== 'all').length !== 0)
      ? filteredInfo.value : undefined,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !parkCode,
  });

  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'ent_name') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({
            onClick: onCompanyClick,
            keyword,
            idField: '_id',
            className: 'overflow-multi-5 clickable-name',
          })),
        };
      }
      // if (item.dataIndex === 'location') {
      //   rowConfig.filters = regionTree;
      //   rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
      //   rowConfig.filteredValue = filteredInfo?.location ?? [parkCode];
      // }
      if (item.dataIndex === 'tag_name') {
        rowConfig.filters = industryTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
        rowConfig.filteredValue = filteredInfo?.tag_name ?? [industryCode];
      }
      if (item.dataIndex === 'value') {
        rowConfig.filters = round;
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filteredValue = filteredInfo.value?.length > 0 ? filteredInfo.value : ['all'];
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (['total_money', 'announce_date', 'value', 'financing'].includes(item.dataIndex)) {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({
            // onClick: onInvestmentClick,
            idField: 'invest_id',
            // className: 'overflow-multi-5 clickable-name',
          })),
        };
      }
      if (item.dataIndex === 'short') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig({
            keyword,
            // onClick: onInvestmentClick,
            idField: 'invest_id',
            // className: 'overflow-multi-5 clickable-name',
          }),
        };
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [onCompanyClick, round, columns, filteredInfo, keyword, onInvestmentClick, industryTree]);

  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        park_id: getSingleOption(filteredInfo.location) ?? parkCode,
        industry_code: getSingleOption(filteredInfo.tag_name) ?? 'all',
        sort_key: sortKey,
        sort_value: sortValue,
        round: (filteredInfo?.value && filteredInfo.value.filter(item => item !== 'all').length !== 0)
          ? filteredInfo.value : undefined,
      });
    }
  }, [keyword, parkCode, sortedInfo, filteredInfo]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      noShowJumper
      {...props}
    />
  );
}

export default InvestmentTable;
