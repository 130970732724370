/**
 * 城市频道页面相关接口
 */
import { apiSlice } from '../slices/api';
// /industry_knowledge_engine/v2/dataAnalysis/catalogue/side_table_contents
const KNOWLEDGECOMPONENT = '/knowledge_component';

export const knowledgeCompoentApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 区域筛选框 /industry_knowledge_engine/v2/knowledge_component/interaction/area_select_box
    getAreaSelectBox: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/interaction/area_select_box`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业tree /industry_knowledge_engine/v2/knowledge_component/interaction/search_industry_tree
    getSearchIndustryTree: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/interaction/search_industry_tree`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业标签 /industry_knowledge_engine/v2/knowledge_component/get_company_label
    getCompanyLabel: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/get_company_label`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业链穿透图标签(根据产业变化带企业数量) /industry_knowledge_engine/v2/knowledge_component/industry_link_through_the_figure_label
    getIndustryLinkThroughTheFigureLabel: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/industry_link_through_the_figure_label`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业链穿透图 /industry_knowledge_engine/v2/knowledge_component/industry_link_through_the_figure
    getIndustryLinkThroughTheFigure: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/industry_link_through_the_figure`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 大型企业区域布局穿透 /industry_knowledge_engine/v2/knowledge_component/penetration_regional_layout_large_enterprises
    getPenetrationRegionalLayoutLargeEnterprise: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/penetration_regional_layout_large_enterprises`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 大型企业区域布局穿透描述 /industry_knowledge_engine/v2/knowledge_component/penetration_regional_layout_large_enterprises_describe
    getPenetrationRegionalLayoutLargeEnterprisesDescribe: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/penetration_regional_layout_large_enterprises_describe`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 大型企业产业布局穿透 /industry_knowledge_engine/v2/knowledge_component/penetration_industry_layout_large_enterprises
    getPenetrationIndustryLayoutLargeEnterprise: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/penetration_industry_layout_large_enterprises`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 大型企业产业布局穿透描述 /industry_knowledge_engine/v2/knowledge_component/penetration_industry_layout_large_enterprises_describe
    getPenetrationIndustryLayoutLargeEnterprisesDescribe: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/penetration_industry_layout_large_enterprises_describe`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 区域资本流向图 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/chat
    getRegionalCapitalFlowChart: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/chat`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 流入top10企业 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/capital_inflows_into_top10_enterprises
    getCapitalInflowsIntoTop10Enterprises: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/capital_inflows_into_top10_enterprises`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 流出top10企业 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/capital_outflows_from_top10_enterprises
    getCapitalOutflowsIntoTop10Enterprises: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/capital_outflows_from_top10_enterprises`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 流入top10城市 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/capital_inflows_into_top10_cities
    getCapitalInflowsIntoTop10Cities: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/capital_inflows_into_top10_cities`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 流出top0城市 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/capital_outflows_from_top10_cities
    getCapitalOutflowsIntoTop10Cities: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/capital_outflows_from_top10_cities`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业流入企业详情 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/capital_inflows_into_enterprise_detail
    getCaitalInflowsIntoEnterpriseDetail: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/capital_inflows_into_enterprise_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业流出企业详情 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/capital_outflows_from_enterprise_detail
    getCaitalOutflowsFromEnterpriseDetail: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/capital_outflows_from_enterprise_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 流出城市详情 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/capital_outflows_from_city_detail
    getCaitalOutflowsFromCityDetail: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/capital_outflows_from_city_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 流入城市详情 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/capital_inflows_into_city_detail
    getCaitalInflowsIntoCityDetail: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/capital_inflows_into_city_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 城市流入企业详情 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/city_capital_inflows_into_enterprise_detail
    getCityCapitalInflowsIntoEnterpriseDetail: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/city_capital_inflows_into_enterprise_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 城市流出企业详情 /industry_knowledge_engine/v2/knowledge_component/regional_capital_flow_chart/city_capital_outflows_from_enterprise_detail
    getCityCapitalOutflowsFromEnterpriseDetail: builder.query({
      query(arg) {
        return {
          url: `${KNOWLEDGECOMPONENT}/regional_capital_flow_chart/city_capital_outflows_from_enterprise_detail`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetAreaSelectBoxQuery,
  useGetSearchIndustryTreeQuery,
  useGetCompanyLabelQuery,
  useGetIndustryLinkThroughTheFigureLabelQuery,
  useGetIndustryLinkThroughTheFigureQuery,
  useGetPenetrationRegionalLayoutLargeEnterpriseQuery,
  useGetPenetrationRegionalLayoutLargeEnterprisesDescribeQuery,
  useGetPenetrationIndustryLayoutLargeEnterpriseQuery,
  useGetPenetrationIndustryLayoutLargeEnterprisesDescribeQuery,
  useGetRegionalCapitalFlowChartQuery,
  useGetCapitalInflowsIntoTop10EnterprisesQuery,
  useGetCapitalOutflowsIntoTop10EnterprisesQuery,
  useGetCapitalInflowsIntoTop10CitiesQuery,
  useGetCapitalOutflowsIntoTop10CitiesQuery,
  useGetCaitalInflowsIntoCityDetailQuery,
  useGetCaitalInflowsIntoEnterpriseDetailQuery,
  useGetCaitalOutflowsFromCityDetailQuery,
  useGetCaitalOutflowsFromEnterpriseDetailQuery,
  useGetCityCapitalInflowsIntoEnterpriseDetailQuery,
  useGetCityCapitalOutflowsFromEnterpriseDetailQuery,
} = knowledgeCompoentApi;
