/**
 * 园区详情
*/
import { apiSlice } from '../slices/api';

const CITY_DETAIL = 'cityDetail';
const INDUSTRYCHANNEL = '/industryChannel';

const PARKDETAIL = '/parkDetail';
const INTERACTION = '/interaction';

export const parkDetailApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 头部描述 head_description
    getParkHeadDescription: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/head_description`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 经济运行数据
    getParkEconomicData: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/economic_operation`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 数据统计 head_description
    getParkDataStatistical: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/data_statistical`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 社会发展
    getParkSocialDevelopment: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/social_development`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业概览
    getParkIndustryOverview: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/industry_overview`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 产业概览
    getParkIndustryOverviewCompanyList: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/industry_overview_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 园区对标
    getParkParkBenchmark: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/park_benchmark`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 区域分布地图
    getParkRegionalDistribution: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/regional_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分析表格
    getParkRegionalDistributionTable: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/regional_distribution_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分析子表格
    getParkRegionalDistributionSubtable: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/regional_distribution_subtable`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分布
    getParkIndustryDistribution: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/industry_distribution`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分布-表格
    getParkIndustryDistributionTable: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/industry_distribution_table`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业分布-表格-子表
    getParkIndustryDistributionSubtable: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/industry_distribution_subtable`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 股权融资
    getParkEquityFinancing: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/equity_financing`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 投资列表
    getParkFindInvestmentList: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/find_investment_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 创新能力
    getParkInnovationAbility: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/innovation_ability`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专利列表
    getParkCityDetailFindPatentList: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/find_patent_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 研究机构列表
    getParkFindInstitutionsList: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/find_institutions_list`,
          method: 'post',
          body: arg,
        };
      },
    }),

    // 园区载体
    getParkParkCarrier: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/park_carrier`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业载体
    getParkCityDetailFindIndustryCarrierList: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/find_industry_carrier_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业载体
    getParkCityDetailIndustryCarrierOverlayNumber: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_carrier_overlay_number`,
          params: arg,
        };
      },
    }),

    // 政策清单
    getParkListOfPolicies: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/list_of_policies`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业政策清单
    getParkCityDetailFindIndustryPolicyList: builder.query({
      query(arg) {
        return {
          url: `${PARKDETAIL}/find_industry_policy_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业载体
    getParkCityDetailIndustryPolicyOverlayNumber: builder.query({
      query(arg) {
        return {
          url: `${CITY_DETAIL}/industry_policy_overlay_number`,
          params: arg,
        };
      },
    }),
    // 融资轮次
    getParkChannelInvestmentRoundList: builder.query({
      query(arg) {
        return {
          url: `${INTERACTION}/investment_round_select_list`,
          method: 'post',
          body: arg,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getParkDetailIndustryList: builder.query({
      query(arg) {
        return {
          url: `${INTERACTION}/getPark_industry_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getParkAreaPlugMarqueeV2: builder.query({
      query(arg) {
        return {
          url: `${INTERACTION}/area_select_box`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getParkIndustryTree: builder.query({
      query(arg) {
        return {
          url: `${INTERACTION}/search_industry_tree`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getParkCompanySpecialTags: builder.query({
      query(arg) {
        return {
          url: `${INTERACTION}/getPark_company_type_list`,
          method: 'post',
          body: arg,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    // 获取城市中心点
    getParkCenterInfo: builder.query({
      query(arg) {
        return {
          url: `${INDUSTRYCHANNEL}/getPark_center_info`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 地图区域分布企业列表 city_channel_area_map_company_list
    getParkCityChannelAreaMapCompanyList: builder.query({
      query(arg) {
        return {
          url: `${INDUSTRYCHANNEL}/city_channel_area_map_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 企业分布右侧列表 area_distribution_company_rank
    getParkAreaDstributionCompanyRank: builder.query({
      query(arg) {
        return {
          url: `${INDUSTRYCHANNEL}/area_distribution_company_rank`,
          params: arg,
        };
      },
    }),
    // 产业环节筛选
    getCityIndustryLinkSelect: builder.query({
      query(arg) {
        return {
          url: `${INTERACTION}/industry_link_select`,
          params: arg,
        };
      },
    }),
  }),
});

export const {
  useGetParkHeadDescriptionQuery,
  useLazyGetParkDataStatisticalQuery,
  useGetParkEconomicDataQuery,
  useGetParkSocialDevelopmentQuery,
  useGetParkIndustryOverviewQuery,
  useGetParkIndustryOverviewCompanyListQuery,
  useGetParkParkBenchmarkQuery,
  useGetParkRegionalDistributionQuery,
  useGetParkRegionalDistributionTableQuery,
  useGetParkRegionalDistributionSubtableQuery,
  useGetParkIndustryDistributionQuery,
  useGetParkIndustryDistributionTableQuery,
  useGetParkIndustryDistributionSubtableQuery,
  useGetParkEquityFinancingQuery,
  useGetParkFindInvestmentListQuery,
  useGetParkInnovationAbilityQuery,
  useGetParkCityDetailFindPatentListQuery,
  useGetParkFindInstitutionsListQuery,
  useGetParkParkCarrierQuery,
  useGetParkCityDetailFindIndustryCarrierListQuery,
  useGetParkCityDetailIndustryCarrierOverlayNumberQuery,
  useGetParkListOfPoliciesQuery,
  useGetParkCityDetailFindIndustryPolicyListQuery,
  useGetParkCityDetailIndustryPolicyOverlayNumberQuery,
  useGetParkChannelInvestmentRoundListQuery,
  useGetParkAreaPlugMarqueeV2Query,
  useLazyGetParkAreaPlugMarqueeV2Query,
  useGetParkIndustryTreeQuery,
  useGetParkCompanySpecialTagsQuery,
  useGetParkCenterInfoQuery,
  useGetParkCityChannelAreaMapCompanyListQuery,
  useGetParkAreaDstributionCompanyRankQuery,
  useGetParkDetailIndustryListQuery,
  useGetCityIndustryLinkSelectQuery,
} = parkDetailApi;
