// 供应关系网络
import React, { useState, useMemo } from 'react';
import { ECharts, Table } from '@sqke/design';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash';
import Container from '../../Container';
import style from './index.module.less';
import { useGetSupplyRelationshipQuery, useGetSupplyRelationshipDetailsQuery } from '../../../../../service/companyDetail';
import { openNewPage } from '../../../../../utils/urlMethods';

function SupplyRelationship(props) {
  const { companyId, preventPageto, companyName } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data, isFetching } = useGetSupplyRelationshipQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });
  const option = useMemo(() => {
    const series = data?.series?.map(item => ({
      name: item.name,
      type: 'tree',
      layout: 'radial',
      roam: true,
      scaleLimit: {
        min: 0.8,
        max: 4,
      },
      initialTreeDepth: 2,
      levels: [
        {
          label: {
            show: false,
            fontSize: 14,
            padding: [4, 8, 4, 8],
            backgroundColor: 'rgba(233,240,244,0.5)',
          },
        },
        {
          lineStyle: {
            color: '#1961F5',
          },
        },
      ],
      lineStyle: {
        curveness: 0.5,
        width: 0.5,
      },
      symbolSize: 8,
      matchers: [
        {
          rules: { '==': [{ var: ['type'] }, 'company'] },
          result: {
            label: {
              color: '#000',
            },
            itemStyle: {
              color: '#fff',
              borderColor: '#000',
            },
          },
        },
        {
          rules: { '==': [{ var: ['type'] }, '高校'] },
          result: {
            label: {
              color: '#1961F5',
            },
            itemStyle: {
              color: '#fff',
              borderColor: '#1961F5',
            },
          },
        },
      ],
      label: {
        show: true,
        position: '',
        distance: 2,
        fontSize: 16,
      },
      data: item.data,
    }));

    return {
      tooltip: {},
      series,
    };
  }, [data]);
  const amplifyOption = useMemo(() => {
    if (!option.series) return {};
    const _option = cloneDeep(option);
    _option.series[0].levels[0].label.fontSize = 18;
    _option.series[0].label.fontSize = 14;
    return {
      option: _option,
      title: {
        title: '供应关系',
        question: '展示该企业供应商、客户以及相应的产品和服务',
        companyName,
      },
    };
  }, [option]);

  const { data: tableData, isFetching: tableFetching } = useGetSupplyRelationshipDetailsQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'name' && !preventPageto) {
        return {
          ...item,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('companyDetail', { id: record.company_id })}
            >
              {text}
            </div>
          ),
        };
      }
      return {
        ...item,
      };
    })
  ), [tableData, preventPageto]);

  const chartEl = useMemo(() => {
    if (!data) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (data.series?.length > 0) {
      return <ECharts
        showLoading={isFetching}
        className={style.chart}
        option={option}
      />;
    }
    return <></>;
  }, [data]);

  const tableEl = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={tableColumns}
          title={
            () => (
              <>
                <span>{tableData?.title}</span>
                <span className={style.count}>{tableData?.count}</span>
              </>
            )
          }
          strip
          loading={tableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return <></>;
  }, [tableData]);
  return (
    <Container id="供应关系" question="展示该企业供应商、客户以及相应的产品和服务" look="查看全图" title={data?.title} option={amplifyOption}>
      {
        chartEl
      }
      {
        tableEl
      }
    </Container>
  );
}

export default SupplyRelationship;
