/*
 *Author: zhaochenyu
 *Description: 外部链入登录页-首页
 *Date: 2022/04/27
*/

import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as dd from 'dingtalk-jsapi'; // 此方式为整体加载，也可按需进行加载
import { Spin, Modal, message } from 'antd';
import { authorization } from '../../api/login';

function ComeFromDD(props) {
  const history = useHistory();
  // 获取钉钉用户code
  const getAuthorizationCode = async (userId) => {
    const pageOrigin = window.location.origin;
    // const hasHash = window.location.hash.split('/');
    // if (hasHash[0] === '#') {
    //   pageOrigin += '/#';
    // }
    alert(JSON.stringify(
      {
        response_type: 'code',
        service_id: 'ding1def6f57d6b13ed6f2c783f7214b6d69',
        callback_uri: `${pageOrigin}/industry_knowledge_engine/v2/oauth/dd_single_sign`,
        code: userId,
        service_type: 'dingding_interior',
      },
    ));
    const res = await authorization({
      response_type: 'code',
      service_id: 'ding1def6f57d6b13ed6f2c783f7214b6d69',
      callback_uri: `${pageOrigin}/industry_knowledge_engine/v2/oauth/dd_single_sign`,
      code: userId,
      service_type: 'dingding_interior',
    });
    alert(JSON.stringify(res));
  };

  useEffect(() => {
    dd.getAuthCode({
      corpId: 'ding1def6f57d6b13ed6f2c783f7214b6d69',
      success: (res) => {
        const { code: userId } = res;
        alert(userId);
        getAuthorizationCode(userId);
      },
      fail: () => {
        message.info('自动登录失败，请手动登录');
      },
      complete: () => {
        // history.push('/homePage');
      },
    });
  }, []);
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin spinning />
    </div>
  );
}

export default ComeFromDD;
