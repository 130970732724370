import React from 'react';
import style from './index.module.less';

const SubTitleComp = ({ title }) => (
  <div className={style.box}>
    {title}
  </div>
);

export default SubTitleComp;
