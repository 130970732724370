import React from 'react';
import { Table } from '@sqke/design';
import TableNoData from '../../../IndustryCompanyDetail/CompanyContent/TableNoData';

export default (props) => (
  <Table
    {...props}
    locale={{ emptyText: () => {
      if (!props.dataSource) {
        return '';
      }
      return <TableNoData />;
    } }}
    // bordered
    strip
  />
);
