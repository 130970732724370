/*
 *Author: zhaochenyu
 *Description: 专精特新弹出表格
 *Date: 2022/05/11
*/

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Table, Tree, Pagination, Alert, message, Spin } from 'antd';
import UpgradeWindow from '../../../UpgradeWindow';
import { openNewPage } from '../../../../utils/urlMethods';
import { getCurUpgradeType } from '../../../../utils/tool';
import { getCompnayList } from '../../../../api/specialAndNews';
import style from '../SpecialTable/index.module.less';

function TreeNode(props) {
  const { data, hanldleCondition, setCurPagination, getCurCompnayList, curPagination,
    setSelectArea } = props;
  const [selectKeys, setSelectKeys] = useState([]);

  const selectTreeKey = (selected) => {
    if (selected.length > 0) {
      setSelectArea(selected);
      setSelectKeys(selected);
      hanldleCondition({
        page_number: 1,
        area_key: selected[0],
      });
      setCurPagination({
        ...curPagination,
        pageNum: 1,
      });
      getCurCompnayList();
    }
  };

  useEffect(() => {
    setSelectArea(null);
  }, [data]);

  return (
    <Tree
      onSelect={selectTreeKey}
      selectedKeys={selectKeys}
      treeData={data}
      // selectable
      defaultExpandedKeys={data.length > 0 ? [data[0].key] : []}
    />
  );
}

function MultiNodeTree(props) {
  const { data, hanldleCondition, setCurPagination, getCurCompnayList, curPagination,
    setIndustry } = props;
  const [selectKeys, setSelectKeys] = useState([]);

  const selectTreeKey = (selected) => {
    setIndustry(selected);
    setSelectKeys(selected);
    hanldleCondition({
      page_number: 1,
      industry_name_list: selected,
    });
    setCurPagination({
      ...curPagination,
      pageNum: 1,
    });
    getCurCompnayList();
  };

  useEffect(() => {
    setIndustry([]);
  }, [data]);
  return (
    <Tree
      onCheck={selectTreeKey}
      checkedKeys={selectKeys}
      treeData={data}
      checkable
      defaultExpandedKeys={data.length > 0 ? [data[0].key] : []}
    />
  );
}

function SpecialALertTable(props) {
  const { detailMsg, areaFilter, labelList, industryList, roundList, levelData, setParams, setScreen } = props;
  const [curPagination, setCurPagination] = useState({ pageNum: 1, pageSize: 20 });
  const [label, setLabel] = useState('all');
  const [financing, setFinancing] = useState('all');
  const [industry, setIndustry] = useState([]);
  const [level, setLevel] = useState('');
  const [selectArea, setSelectArea] = useState(null);
  const [tableData, setTableData] = useState({});
  const permission = JSON.parse(localStorage.getItem('pm'));
  const [upgradeWindowShow, setUpgradeWindowShow] = useState('');
  const [loading, setLoading] = useState(false);
  const tableCondition = useRef({
    industry_name_list: [],
    area_key: '',
    page_number: 1,
    page_size: 20,
    select_level: '',
    second_special_tag: 'all', // 标签
    round_list: [],
  });
  const hanldleCondition = useCallback((obj) => {
    tableCondition.current = {
      ...tableCondition.current,
      ...obj,
    };
  }, []);
  const getCurCompnayList = useCallback(() => {
    setLoading(true);
    if (setParams) {
      setParams(tableCondition.current);
    }
    getCompnayList(tableCondition.current).then(res => {
      if (res.status === '00000') {
        setTableData({
          ...res.data,
          total: res.data.total,
        });
        setScreen(res.data.total);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (detailMsg.code) {
      // 初始化
      hanldleCondition({
        industry_name_list: [],
        area_key: detailMsg.code,
        select_level: '',
        second_special_tag: 'all',
        round_list: [],
        page_number: 1,
        page_size: 20,
      });
      setLabel('all');
      setFinancing('all');
      setLevel('');
      setIndustry([]);
      setSelectArea('');
      setCurPagination({ pageNum: 1, pageSize: 20 });
      getCurCompnayList();
    }
  }, [detailMsg]);

  const pushToCompany = useCallback(
    (id) => {
      openNewPage('companyDetail', { id });
    },
    [],
  );

  const paginationChange = (pageNumber, pageSize) => {
    if (pageNumber > tableData.auth_number && permission?.hp?.spn?.up === 'ol') {
      setUpgradeWindowShow('up');
      return;
    }
    hanldleCondition({
      page_number: pageNumber,
      page_size: pageSize,
    });
    setCurPagination({
      pageNum: pageNumber,
      pageSize,
    });
    getCurCompnayList();
  };

  const handleWithHead = (head) => (
    head.map(item => {
      let res;
      if (item.dataIndex === 'index') {
        res = {
          ...item,
          width: '8%',
          ellipsis: true,
          // render: (text, record, index) => (
          //   <div className={index > 2 ? style.otherStyle : style.topStyle}>{text}</div>
          // ),
        };
      } else if (item.dataIndex === 'name') {
        res = {
          ...item,
          width: '28%',
          render: (text, record) => (
            <div
              title={text}
              style={{ color: '#262D36', cursor: 'pointer' }}
              className={style.companyName}
              onClick={() => pushToCompany(record.id)}
            >
              {text}
            </div>
          ),
        };
      } else if (item.dataIndex === 'special_tag') {
        res = {
          ...item,
          width: '13%',
          ellipsis: true,
          filteredValue: label === 'all' ? null : label,
          filterDropdown: (
            <div className={style.areaDropdown}>
              { labelList.length > 0 ? labelList.map((item2, index) => (
                <div
                  className={label === item2.value ? style.selectArea : style.areaItem}
                  key={`${index}relying_unit`}
                  onClick={() => {
                    hanldleCondition({
                      page_number: 1,
                      second_special_tag: item2.value,
                    });
                    setLabel(item2.value);
                    setCurPagination({
                      ...curPagination,
                      pageNum: 1,
                    });
                    getCurCompnayList();
                  }}
                  title={item2.name}
                >
                  {item2.name}
                </div>
              )) : <div>暂无数据</div>}
            </div>
          ),
        };
      } else if (item.dataIndex === 'level') {
        res = {
          ...item,
          width: '10%',
          ellipsis: true,
          filteredValue: level,
          filterDropdown: (
            <div className={style.areaDropdown}>
              { levelData.length > 0 ? levelData.map((item2, index) => (
                <div
                  className={level === item2.key ? style.selectArea : style.areaItem}
                  key={`${index}relying_level`}
                  onClick={() => {
                    hanldleCondition({
                      page_number: 1,
                      select_level: item2.key,
                    });
                    setLevel(item2.key);
                    setCurPagination({
                      ...curPagination,
                      pageNum: 1,
                    });
                    getCurCompnayList();
                  }}
                  title={item2.title}
                >
                  {item2.title}
                </div>
              )) : <div>暂无数据</div>}
            </div>
          ),
        };
      } else if (item.dataIndex === 'in_area') {
        res = {
          ...item,
          width: '10%',
          ellipsis: true,
          filteredValue: selectArea,
          filterDropdown: (
            <div className={style.linkNodeDropdown}>
              <TreeNode
                data={areaFilter}
                hanldleCondition={hanldleCondition}
                setCurPagination={setCurPagination}
                getCurCompnayList={getCurCompnayList}
                curPagination={curPagination}
                setSelectArea={setSelectArea}
              />
            </div>
          ),
        };
      } else if (item.dataIndex === 'industry') {
        res = {
          ...item,
          width: '20%',
          ellipsis: true,
          filteredValue: industry,
          filterDropdown: (
            <div className={style.linkNodeDropdown}>
              <MultiNodeTree
                data={industryList}
                hanldleCondition={hanldleCondition}
                setCurPagination={setCurPagination}
                getCurCompnayList={getCurCompnayList}
                curPagination={curPagination}
                setIndustry={setIndustry}
              />
            </div>
          ),
        };
      } else if (item.dataIndex === 'latest_round') {
        res = {
          ...item,
          width: '13%',
          ellipsis: true,
          filteredValue: financing === 'all' ? null : financing,
          filterDropdown: (
            <div className={style.areaDropdown}>
              { roundList?.length > 0 ? roundList?.map((item2, index) => (
                <div
                  className={financing === item2.value ? style.selectArea : style.areaItem}
                  key={item2.title}
                  onClick={() => {
                    hanldleCondition({
                      page_number: 1,
                      round_list: [item2.value],
                    });
                    setFinancing(item2.value);
                    getCurCompnayList();
                  }}
                  title={item2.title}
                >
                  {item2.title}
                </div>
              )) : <div>暂无数据</div>}
            </div>
          ),
        };
      } else {
        res = {
          ...item,
          width: '16%',
          ellipsis: true,
        };
      }
      return res;
    })
  );
  return (
    <div className={style.all}>
      <Spin tip="搜索中，请稍候....." spinning={loading}>
        <Table
          rowKey="index"
          columns={handleWithHead(tableData.tableHead || [])}
          dataSource={tableData.tableBody || []}
          pagination={false}
          className={style.alertTable}
          scroll={{ y: 600 }}
        />
        <div className={style.pagination}>
          <Pagination
            showQuickJumper
            hideOnSinglePage
            total={tableData.total > 10000 ? 10000 : tableData.total || 0}
            // pageSizeOptions={false}
            current={curPagination.pageNum}
            showSizeChanger={false}
            pageSize={curPagination.pageSize}
            onChange={paginationChange}
          />
        </div>
      </Spin>
    </div>
  );
}

export default SpecialALertTable;
