import React, { useState } from 'react';
import Container from '../../Container';
import StockRights from '../StockRights';
import PledgeTable from '../PledgeTable';
import OperationalRisksTable from '../OperationalRisksTable';
import IllegalSituationTable from '../IllegalSituationTable';
import PunishTable from '../PunishTable';
import { useGetCoRelatedRisksQuery } from '../../../../../service/companyDetail';
import style from './index.module.less';

const riskListMap = [
  { value: 'punishment', name: '行政处罚', El: PunishTable },
  { value: 'equity', name: '股权出质', El: StockRights },
  { value: 'illegal', name: '严重违法', El: IllegalSituationTable },
  { value: 'abnormal', name: '经营异常', El: OperationalRisksTable },
  { value: 'mortgage', name: '动产抵押', El: PledgeTable },
];

const RelatedRisks = ({ companyId }) => {
  // 需要展开Table的索引值
  const [listIndex, setLinstIndex] = useState([]);
  const res = useGetCoRelatedRisksQuery({ company_id: companyId });
  const { data } = res;
  // 点击展开收起
  const tableSwitch = (index) => {
    if (listIndex.includes(index)) {
      setLinstIndex(params => params.filter(item => item !== index));
      return;
    }
    setLinstIndex(params => ([...params, index]));
  };
  return (
    <Container id="关联风险" num={data?.relevance_abnormal || '-'} >
      <div className={style.riskList}>
        <div className={style.overview}>
          <div className={style.riskCount}>
            <span></span>
            该公司共有
            <span>{data?.relevance_abnormal}</span>
            条关联风险数据
          </div>
          <div className={style.relevanceNumber}>
            涉及
            <span>{data?.relevance_company}</span>
            家关联企业
          </div>
        </div>
        <div className={style.relevanceList}>
          {
            data?.data.map((item, index) => (
              <div key={item.company_id}>
                <div className={style.companyCell}>
                  <div className={style.name}>
                    该公司的
                    {item.company_type}
                    <span className={style.companyName}>{item.company_name}</span>
                    有
                    <span className={style.companyAbnormalCount}>{item.company_abnormal_count}</span>
                    条风险数据
                  </div>
                  <div className={style.relevanCompanyList}>
                    {
                      riskListMap.map(item2 => {
                        if (item[item2.value]) {
                          return (
                            <div key={item2.value} className={style.relevanCompanyCell}>
                              <div className={style.left}>
                                <span></span>
                                <span>{item[item2.value]}</span>
                              </div>
                              <div className={style.right}>
                                条
                                {item2.name}
                                记录
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })
                    }
                  </div>
                  <div
                    className={style.btn}
                    onClick={() => { tableSwitch(index); }}
                  >
                    {
                      listIndex.includes(index) ? '收起' : '展开'
                    }
                  </div>
                </div>
                {
                  listIndex.includes(index) && (
                    <div>
                      {
                        riskListMap.map((item2, index2) => {
                          if (item[item2.value]) {
                            return (<div className={style.table} key={index2}><item2.El companyId={item.company_id} /></div>);
                          }
                          return null;
                        })
                      }
                    </div>
                  )
                }
              </div>
            ))
          }
        </div>
      </div>
    </Container>
  );
};

export default RelatedRisks;
