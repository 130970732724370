import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

// Tab

/**
 * 侧边导航栏
 * @param {[]{key: string; name: string;}} options
 * @param {string} selectedKey
 * @param {(e: {key: string, name: string}) => void} onClick
 * @param {string} [clasName]
 * @param {string} [vLineClassName]
 * @returns {JSX.Element}
 * @constructor
 */
function SideNavi({ options, selectedKey, onClick, clasName, vLineClassName }) {
  return (
    <nav className={classnames(styles.navigation, clasName)}>
      <div>
        {
          options?.map(({ name, key }, index) => (
            <div
              className={classnames(
                styles.item,
                {
                  [styles.selected]: key === selectedKey,
                },
              )}
              onClick={() => onClick && onClick({ key, name })}
              key={key ?? index}
            >
              <span>{name}</span>
            </div>
          ))
        }
      </div>
      <div className={classnames(styles.verticalLine, vLineClassName)} />
    </nav>
  );
}

export default SideNavi;
