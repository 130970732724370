import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Popconfirm, Input } from 'antd';
import { IconFont } from '../../../../utils/mapIcon';
import style from './index.module.less';
import Underline from '../../../../components/Underline';
import { useLazyGetSearchValuationRecordMyselfQuery, useLazyDelValuationRecordQuery } from '../../../../service/tool';
import Table from '../../../../components/Table';

const Index = () => {
  const history = useHistory();
  const [dataSource, setDataSource] = useState(null);
  const [count, setCount] = useState(null);
  const [pageSize, setPageSize] = useState(24);
  const [page, setPage] = useState(1);
  const [companyName, setCompanyName] = useState(null);
  const [getSearchValuationRecordMyselfQuery] = useLazyGetSearchValuationRecordMyselfQuery();
  const [delValuationRecordQuery] = useLazyDelValuationRecordQuery();
  const params = new URLSearchParams(window.location.href.split('?')[1]);
  const InputRef = useRef(null);

  const getSearchValuationRecordMyself = () => {
    getSearchValuationRecordMyselfQuery({ page_size: pageSize, page_number: page, keyword: companyName }).then(res => {
      const { data, status } = res;
      if (status === 'fulfilled') {
        setDataSource(data.dataSource);
        setCount(data.count);
      } else {
        message.error('获取收藏列表失败');
      }
    }).catch(err => {
      console.log(err);
    });
  };
  const delValuationRecord = (id) => {
    delValuationRecordQuery({ valuation_id: id }).then(res => {
      const { status } = res;
      if (status === 'fulfilled') {
        message.info('删除列表成功');
        getSearchValuationRecordMyself();
      }
    }).catch(err => console.log(err));
  };
  const confirm = (id) => {
    delValuationRecord(id);
  };
  const columns = [
    {
      title: '企业名称',
      dataIndex: 'company_name',
      key: 'company_name',
      ellipsis: true,
    },
    {
      title: '估值时间',
      dataIndex: 'valuation_time',
      key: 'valuation_time',
      ellipsis: true,
    },
    {
      title: '估值结果',
      dataIndex: 'market_value',
      key: 'market_value',
      ellipsis: true,
    },
    {
      title: '操作',
      dataIndex: 'actibve',
      key: 'actibve',
      render: (text, row) => (
        <div className={style.active}>
          <button
            type="button"
            onClick={() => {
              history.push(`/tool/companyAppraisement/appraisement?id=${row.company_id}&name=${row.company_name}&result=${row.market_value}&time=${row.valuation_time}&max=${row.EV_top}&min=${row.EV_bottom}&EV=${row.EV}`);
            }}
          >
            查看
          </button>
          <Popconfirm
            title="是否删除此条收藏"
            onConfirm={() => confirm(row.id)}
            okText="是"
            cancelText="否"
          >
            <button type="button">删除</button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getSearchValuationRecordMyself();
  }, [pageSize, page, companyName]);

  const pagination = {
    total: count,
    current: page,
    pageSize,
    showQuickJumper: true,
    onChange: (page2, pageSize2) => {
      setPageSize(pageSize2);
      setPage(page2);
    },
  };
  return (
    <div className={style.box}>
      <header>
        <div>
          <p>企业估值记录</p>
          <p>
            共搜索到
            {count}
            条数据
          </p>
        </div>
        <div>
          <button type="button" onClick={() => { history.push(`/tool/companyAppraisement/appraisement?id=${params.get('id')}`); }}>
            <p />
            <p>返回</p>
          </button>
          <div>
            <Input
              ref={InputRef}
              suffix={
                <>
                  <div className={style.inputLine} />
                  <IconFont
                    type="iconfenxiang"
                    onClick={() => {
                      setPage(1);
                      setPageSize(24);
                      setCompanyName(InputRef.current.input.value);
                    }}
                  />
                </>
            }
            />
          </div>

        </div>
      </header>
      <Underline />
      <Table
        rowKey={(row) => row.id}
        columns={columns}
        // loading
        dataSource={dataSource}
        pagination={pagination}
      />
    </div>
  );
};

export default Index;
