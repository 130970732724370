import React, { useEffect } from 'react';
import BarTable from '../../../component/BarTable';
import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const IndustrialDistribution = (props) => {
  const { url, dataFilterParams, reportData, ok } = props;
  const res = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData || !ok });
  const { data, isFetching } = res;
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <BarTable {...props} data={data} isFetching={isFetching} chartType="柱线图" delCrosswise />;
};

export default IndustrialDistribution;
