import { get, post } from '../utils/request';
import { HOMEPAGEV2, DATASEARCHV2, PUCHASEINDUSTRY, PUCHASECITY, FINESYSTEMREPORT, NAVAUTHORITY } from '../utils/url';

// 导航栏各频道状态
const getNavigationBarAuthority = param => get(`${NAVAUTHORITY}/navigation_bar_authority`);

// 头部产业分类
const allGroupIndustryList = () => get(`${HOMEPAGEV2}/navigation_bar_industry_list`, {});

// 产业分类
const getIndustryGroupList = () => get(`${HOMEPAGEV2}/find_industry_group_list`, {});

// 分类下得产业-登录
const getGroupIndustryLogin = param => get(`${HOMEPAGEV2}/find_industry_by_group_on_login`, {
  ...param,
});

// 分类下得产业
const getGroupIndustry = param => get(`${HOMEPAGEV2}/find_industry_by_group`, {
  ...param,
});

// 我的关注
const getAttetion = () => post(`${HOMEPAGEV2}/my_attention_company`, {});

// 我的关注-登录
const getAttetionLogin = () => post(`${HOMEPAGEV2}/my_attention_company_login`, {});

// 模块数量
const getEveryCount = () => get(`${HOMEPAGEV2}/home_page_search_every_count`, {});

// 热门推荐
const recommendList = () => get(`${HOMEPAGEV2}/recommend_data`, {});

// 热门推荐详情
const recommendDetail = param => post(`${HOMEPAGEV2}/recommend_data_detail`, {
  ...param,
});

// 报告列表
const getReportList = () => get(`${FINESYSTEMREPORT}/find_report_list_on_homepage`, {});

// 查询产业是否被购买
const getIsBuyIndustry = param => get(`${PUCHASEINDUSTRY}/find_industry_available`, {
  ...param,
});

// 查询城市是否被购买
const getIsBuyCity = param => post(`${PUCHASECITY}/find_city_code_status`, {
  ...param,
});

// 搜索历史
const getHistoryUseSearch = () => get(`${HOMEPAGEV2}/history_use_search`, {});

// 热门搜索
const getHotUsedSearch = () => get(`${HOMEPAGEV2}/hot_used_search`, {});

// 搜索后的分词
const participleByKeyword = param => get(`${DATASEARCHV2}/participle_by_keyword`, {
  ...param,
});

// 已购频道
const queryMyChannel = param => get(`${HOMEPAGEV2}/my_attention_channel`, {
  ...param,
});

// 导航栏城市列表
const allGroupCityList = param => get(`${HOMEPAGEV2}/navigation_bar_city`, {
  ...param,
});

export {
  getNavigationBarAuthority,
  allGroupIndustryList,
  getIsBuyIndustry,
  getIsBuyCity,
  getAttetion,
  getAttetionLogin,
  getIndustryGroupList,
  getGroupIndustry,
  getGroupIndustryLogin,
  getEveryCount,
  recommendList,
  recommendDetail,
  getReportList,
  getHistoryUseSearch,
  getHotUsedSearch,
  participleByKeyword,
  queryMyChannel,
  allGroupCityList,
};
