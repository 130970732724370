/*
 *饼图
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { PieChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, toolTipFormatter } from '../../utils/tool';
import { checkPieChartData } from './checkDataFormat';

import { modeStyle, chartFontSize } from './style';

import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-pie-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};
const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const Piehart = forwardRef((props: PieChartProps, ref: any) => {
  const { title = '', data, type, mode = 'dark', fontSize, radius, center, legend = true } = props;
  const [hanldeData, setHanldeData] = useState({});
  const [option, setOption] = useState({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);
  const [wraperHeight, setWraperHeight] = useState(0);
  useEffect(() => {
    setHanldeData(checkPieChartData(data) as any);
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!];

    const chartColor: any[] = curStyle.color;
    let sum = 0;
    // eslint-disable-next-line no-unused-expressions
    data?.forEach(item => {
      sum += Number(item.value);
    });
    // 根据实际显示的labelcount调整label字号
    let labelCount = 0;
    // 小于1%隐藏label
    // eslint-disable-next-line no-unused-expressions
    data?.forEach(item => {
      // console.log((Number(item.value) * 100).toFixed(2));
      if (item.unit === '%' && Number(
        (Number(item.value)),
      ) < 1) {
        item.labelLine = {
          show: false,
        };
        item.label = {
          show: false,
        };
        item.select = {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        };
      } else if (item.unit !== '%' && Number(item.value) / sum < 0.01) {
        item.labelLine = {
          show: false,
        };
        item.label = {
          show: false,
        };
        item.select = {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        };
      }
      if (item.unit === '%' && Number(
        (Number(item.value)),
      ) >= 1) {
        labelCount += 1;
      } else if (item.unit !== '%' && Number(item.value) / sum >= 0.01) {
        labelCount += 1;
      }
    });
    let labelFontSize = 8;
    if (labelCount <= 6) {
      labelFontSize = 12;
    } else if (labelCount <= 10) {
      labelFontSize = 10;
    }
    const options = {
      tooltip: {
        show: true,
        confine: true,
        formatter: (params: any) => {
          const { name, value, unit } = params.data;
          let showValue = value;
          if (unit === '%') {
            showValue = (value);
          }
          if (!params.data) {
            return null;
          }
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([{
            name,
            unit,
            value: showValue,
          }], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      color: chartColor,
      // backgroundColor: '#fff',
      legend: {
        show: legend,
        bottom: 6,
        padding: 0,
        itemGap: 5,
        icon: 'circle',
        itemWidth: 10,
        selectedMode: false,
        itemHeight: 10,
        symbolKeepAspect: true,
        // formatter(arg: any) {
        //   console.log(arg);
        //   return '';
        // },
        textStyle: {
          fontSize: curFont.legnedFontSize,
          fontWeight: 400,
          color: curStyle.legendTextColor,
        },
      },
      series: {
        type: 'pie',
        roseType: type,
        clockwise: true,
        startAngle: 90,
        center: ['50%', '50%'],
        radius: radius || (type ? ['0%', '65%'] : ['0', '65%']),
        selectedOffset: 10,
        top: 8,
        bottom: 0,
        label: {
          position: 'outside',
          // alignTo: 'edge',
          // edgeDistance: '25%',
          // rotate: true,
          overflow: 'breakAll',
          color: curStyle.labelColor,
          // letterSpacing: 1,
          distanceToLabelLine: 1,
          // padding: [1, 2],
          // backgroundColor: curStyle.labelBgdColor,
          // borderColor: curStyle.labelBorderColor,
          // borderWidth: 1,
          formatter(params: any) {
            const dataIn = params.data;
            if (dataIn.unit === '%') {
              if (Number((dataIn.value)) < 1) {
                return '';
              }
              return `{textStyle|${params.name}\n${(dataIn.value)}%}`;
            }
            return type
              ? (`{textStyle|${params.name}\n${params.percent}%}`)
              : (`{textStyle|${params.name}\n${params.percent}%}`);
          },
          rich: {
            textStyle: {
              align: 'left',
              fontSize: labelFontSize,
              lineHeight: labelFontSize,
            },
          },
        },
        // labelLayout(params: any) {
        //   console.log(params);
        // },
        labelLayout: {
          hideOverlap: true,
          // moveOverlap: 'shiftY',
        },
        labelLine: {
          // show: false,
          // length: 8,
          // length2: 16,
          lineStyle: {
            type: 'dashed',
            color: curStyle.labelLineColor,
          },
        },
        data: (data).filter(item => item.name),
      },
    };
    if (!chartRef.current) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    let legendHeight = 0;
    // todo 不精确
    const legendAllWidth = (data as any).reduce((all: number, item: { name: any; }) => {
      all += (String(item.name).length * curFont.legnedFontSize + 15); // legend 10 gap 5;
      return all;
    }, 0);
    // console.log('legendAllWidth', legendAllWidth);
    // console.log('instance.getWidth() - 16', instance.getWidth() - 16);
    legendHeight = (Math.ceil(legendAllWidth / (instance.getWidth() - 16))) * (
      curFont.legnedFontSize + options.legend.itemGap
    );
    if (legendHeight < 0) {
      legendHeight = 0;
    }
    options.series.bottom = legendHeight + options.legend.bottom + 8;
    setWraperHeight(0 && legendHeight);
    setOption(options);
  }, [hanldeData, mode, title, data, fontSize, type, center, radius]);

  // legend实际高度
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    let bottom;
    try {
      const echartInstance = chartRef.current.getEchartsInstance();
      const legendComView = echartInstance._componentsViews.find((item: any) => item.type.match(/^legend/));
      bottom = legendComView._backgroundEl.shape.height + (option as any).legend.bottom + 8;
      bottom = Number(bottom);
      // eslint-disable-next-line no-self-compare
      if (bottom === bottom) { // !nan
        // 主要是为了图表和图例防止重叠 所以还是取一个手动计算的高度和这个值的比较大的一个
        (option as any).series.bottom = Math.max(bottom, (option as any).series.bottom);
        echartInstance.setOption({ ...option });
      }
    } catch (ex) {
      // console.log(ex, '计算legend高度');
    }
  }, [option]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!data || !data.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: modeStyle[mode].toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {
        chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)
      }
      {
        !chartLoading && (!data || !data.length) && (<div className={styleEmpty}>暂无数据</div>)
      }
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: `calc(100% + ${wraperHeight}px)` }}
        ref={chartRef}
      />
    </div>);
});

export default Piehart;

Piehart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
