import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { useQuery } from '../hooks/url';
import { Context, menus } from '../utils/context';
import { IconFont } from '../utils/mapIcon';

// import { LeaveModal } from '../component/AnalysisModal';
import logo from '../assets/images/nav/logo.png';
import style from './page.module.less';
import { getNavigationBarAuthority, getHistoryUseSearch, allGroupIndustryList, getIsBuyIndustry, getIsBuyCity, queryMyChannel, allGroupCityList } from '../api/homepage';
import {
  useGetAreaPlugMarqueeV2Query,
} from '../service/interactive-v2';
import { logout } from '../api/login';
import ChannelModal from '../components/MySelf/ChannelModal';
// import CityTab from '../components/CityTab';
import CityTabSimple from '../components/CityTabSimple';
import ParkTab from '../components/ParkTab';
import HeaderToolCatalogue from '../components/HeaderToolCatalogue';
import { openNewPage } from '../utils/urlMethods';
import { appSelector } from '../slices/app';
import { setUser } from '../actions/app';
import * as authUtil from '../utils/auth';
import { apiSlice } from '../slices/api';
import { useGetNavigationBarSpecialTopicQuery } from '../service/homepage';
import { useLazyRecordingSpotQuery, useGetHeaderNavigationBarParkQuery, useGetNavigationProvinceListQuery, useGetNavigationToolListQuery } from '../service/public';

const industryGroup = ['信息技术', '装备制造', '医疗健康', '未来产业', '新能源', '新材料', '服务业', '其他'];
const mySelfList = ['个人中心', '下载', '支付订单', '动态监测', '关注', '笔记', '标签'];
const monitorSpecialMap = {
  specializedAndNew: '专精特新',
  centralEnterprises: '央企',
  listedCompany: '上市',
  chinaTop500: '中国500强',
  privateTop500: '民营500强',
};

// 直接跳转不新开页面
const gotoPage = ['industry', 'city', 'toolBox', 'apiShop', 'special', 'park', 'generateReport', 'proIntroduce'];

function Header(props) {
  const { setLoginShow, setModal, setPayType, setDefaultBuyIndustryKey, showOut, setOut } = props;
  const menu = localStorage.getItem('pm') ? Object.keys(JSON.parse(localStorage.getItem('pm'))) : [];
  const EAPersonalCenter = menu.find(item => item === 'EAPersonalCenter');
  const [menuList, setMenuList] = useState([]);
  const [menuKey, setMenuKey] = useState('');
  const { user } = useSelector(appSelector);
  const reduxDispatch = useDispatch();
  const [inputText, setInputText] = useState('');
  const { state, dispatch } = useContext(Context);
  // const [icon, setIcon] = useState(true);
  const [focus, setFocus] = useState(false);
  const [historyUseSearch, setHistoryUseSearch] = useState([]);
  const [dropCardShow, setDropCardShow] = useState(false);
  const [cityCardShow, setCityCardShow] = useState(false);
  const [specialCardShow, setSpecialCardShow] = useState(false);
  const [parkCardShow, setParkCardShow] = useState(false);
  const [toolShow, setToolShow] = useState(false);
  const [dropIndustryList, setDropIndustryList] = useState([]);
  const [dropCityList, setDropCityList] = useState(null);
  // const [purchaseIndustryList, setPurchaseIndustryList] = useState([]);
  // const [purchaseCityList, setPurchaseCityList] = useState([]);
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [defaultBuyKey, setDefaultBuyKey] = useState(false);
  const [channelType, setChannelType] = useState('industry');
  const [selectProvince, setSelectProvince] = useState('110000');
  const [parkSearchValue, setParkSearchValue] = useState(null);
  const [parkTabActive, setParkTabActive] = useState('recommend');
  const [cityList, setCityList] = useState([]);
  // const [keyword, setKeyword] = useState('');
  const menuKeyRef = useRef(null);
  const myNode = useRef(null);
  const soonToPage = useRef(null);
  const headerSearchRef = useRef(null);
  const history = useHistory();
  const query = useQuery();
  const search = query.get('search');
  const [recordingSpotQuer] = useLazyRecordingSpotQuery();

  // const timer = useRef(null);
  const { data: specialList } = useGetNavigationBarSpecialTopicQuery();
  const { data: regionTree = [], isSuccess } = useGetAreaPlugMarqueeV2Query({
    area_code: '000000',
  });
  const { data: toolList } = useGetNavigationToolListQuery();
  const { data: parkList } = useGetHeaderNavigationBarParkQuery({ area_key: parkTabActive });
  const { data: provinceList } = useGetNavigationProvinceListQuery();
  useEffect(() => {
    if (regionTree) {
      const newRegionTree = [];
      (regionTree[0]?.children || []).forEach((item) => {
        newRegionTree.push({
          label: item.title,
          value: item.value,
        });
      });
      setCityList(newRegionTree);
    }
  }, [isSuccess]);
  function urlChange(pathname) {
    const path = pathname || props.location.pathname;
    if (path === '/') return;
    const keys = path.split('/');
    if (keys.length > 1 && keys[1] !== '') {
      setMenuKey(keys[1]);
      const [key] = keys;
      menuKeyRef.current = key;
      dispatch({
        type: 'setState',
        payload: {
          key: keys[1],
          key2: keys[2],
        },
      });
    }
  }
  const clickPoint = e => {
    let { parentNode } = e.target;
    while (parentNode !== null) {
      if (parentNode === myNode.current) {
        break;
      }
      parentNode = parentNode.parentNode;
    }
    if (parentNode === null) {
      setOut(false);
    }

    if (!headerSearchRef.current) return;
    if (!headerSearchRef.current.contains(e.target) && headerSearchRef.current
      !== e.target && e.target.className !== 'headerSearch') {
      setFocus(false);
    }
  };

  const getDropCityList = (e = '') => {
    allGroupCityList({
      keyword: e,
    }).then(res => {
      if (res.status === '00000') {
        setDropCityList(res.data);
      }
    });
  };
  // 获取导航频道状态
  const fetchNavigationBarAuthority = () => {
    getNavigationBarAuthority().then(res => {
      if (res.status === '00000') {
        // console.log(res.data);
        setMenuList(res.data);
      }
    });
  };
  useEffect(() => {
    // 页面刷新或初始化
    urlChange();
    window.addEventListener('click', clickPoint);
    allGroupIndustryList().then(res => {
      if (res.status === '00000') {
        const arr = industryGroup.map(item => res.data.filter(val => item === val.group_name)?.[0] || {
          group_name: item,
          industry_list: [],
        });
        setDropIndustryList(arr);
      }
    });
    getDropCityList();
    fetchNavigationBarAuthority();
    if (!localStorage.getItem('pm') && !localStorage.getItem('rft')) {
      localStorage.clear();
      localStorage.removeItem('rft');
      localStorage.removeItem('rftChanyeMax');
    }

    // 监听路由变化切换
    const listenUnregister = props.history.listen(location => {
      urlChange(location.pathname);
    });

    return () => {
      window.removeEventListener('click', clickPoint);
      listenUnregister();
    };
  }, [props.location.pathname]);

  useEffect(() => {
    if (search) {
      setInputText(search);
    }
  }, [search]);

  useEffect(() => {
    setMenuKey(state.key);
    menuKeyRef.current = state.key;
  }, [state]);

  useMemo(() => {
    if (menuList.length > 0) {
      menus.forEach(item => {
        menuList.forEach(i => {
          if (i.name !== item.name) return;
          item.value = i.value;
        });
      });
    }
  }, [menuList]);

  function handleMenuClick(item) {
    if (item) {
      soonToPage.current = item;
      if (item === 'report') {
        recordingSpotQuer({
          spot_name: '报告',
        });
      }
      if (process.env.REACT_APP_IGNORE_LOGIN === 'true') {
        props.history.push(`/${item}`);
        return;
      }
      if (!localStorage.getItem('token')) {
        setLoginShow(true);
      } else if (item === 'industry' && props.location.pathname === '/homePage' && !dropCardShow) {
        window.scrollTo({
          top: 670,
          behavior: 'smooth',
        });
      } else if (!gotoPage.includes(item)) {
        props.history.push(`/${item}`);
      }
      if (item === 'apiShop') {
        window.open('https://open.chanyeos.com/api-store/search');
      }
      if (item === 'generateReport') {
        openNewPage('dataAnalysisTool');
      }
      if (item === 'proIntroduce' && localStorage.getItem('token')) {
        openNewPage('proIntroduce');
      }

      // const modify = localStorage.getItem('modify');
      // if (menuKey === 'knowledgeEngine' && modify === 'true') {
      //   setLeave(true);
      // } else {
      //   props.history.push(`/${item}`);
      //   // setMenuKey(item);
      // }
    }
  }

  // const pushToClick = () => {
  //   props.history.push(`/${soonToPage.current}`);
  //   // setMenuKey(soonToPage.current);
  // };

  const pushToLogin = () => {
    logout().then(() => {
      setOut(false);
      localStorage.clear();
      localStorage.removeItem('rft');
      localStorage.removeItem('rftChanyeMax');
      localStorage.removeItem('token');
      localStorage.removeItem('userName');
      localStorage.removeItem('ph');
      // localStorage.removeItem('sessionInfo');
      reduxDispatch(setUser(''));
      props.history.push('/homePage');
      authUtil.clearRefreshToken();
      authUtil.clearAccessToken();
      reduxDispatch(apiSlice.util.resetApiState());
      if (menuKey === 'homePage') {
        window.location.reload();
      }
    }).catch(err => {
      throw new Error(err);
    });
  };

  const pushToSetting = (tabItem) => {
    if (tabItem === '个人中心') {
      recordingSpotQuer({
        spot_name: tabItem,
      });
    } else {
      recordingSpotQuer({
        spot_name: `个人中心-${tabItem}`,
      });
    }
    setOut(false);
    props.history.push({
      pathname: '/accountSetting',
      search: `tab=${tabItem}`,
      // query: {
      //   tab: tabItem,
      // },
    });
  };

  const pushFirst = () => {
    if (localStorage.getItem('token')) {
      if (props.location.pathname === '/homePage' && !props.location.search) {
        window.location.reload();
      } else {
        props.history.push('/homePage');
        window.location.reload();
      }
    }
  };

  const searchWord = (wd) => {
    setFocus(false);
    if (props.location.pathname !== '/dataSearch') {
      // query.set('search', wd);
      // history.push({ search: query.toString(), pathname: '/dataSearch' });
      openNewPage('dataSearch', {
        search: wd,
      });
    } else {
      query.set('search', wd);
      history.push({ search: query.toString() });
    }
  };

  // input 聚焦
  const inputOnFocus = () => {
    setFocus(true);
    getHistoryUseSearch().then(res => {
      if (res.status === '00000') {
        setHistoryUseSearch(res.data);
      }
    });
  };

  const pushToIndustryChannel = industryKey => {
    const params = new URLSearchParams();
    params.set('type', 'graph');
    params.set('industry-code', window.btoa(industryKey));
    props.history.push({
      pathname: '/industry',
      search: params.toString(),
    });
  };

  const pushToIndustry = (e, industryKey) => {
    if (!localStorage.getItem('token')) {
      setLoginShow(true);
    } else {
      getIsBuyIndustry({
        industry_code: industryKey,
      }).then(res => {
        if (res.status === '00000') {
          if (res.data) {
            pushToIndustryChannel(industryKey);
            setDropCardShow(false);
          } else {
            setDefaultBuyKey(industryKey);
            setChannelType('industry');
            setChannelModalVisible(true);
          }
        }
      });
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const pushToCityChannel = city => {
    const params = new URLSearchParams();
    params.set('type', 'graph');
    params.set('city-code', window.btoa(city?.key || city?.value));
    params.set('city-title', city?.name);
    params.set('is-province-directly', city?.is_province_directly || false);
    props.history.push({
      pathname: '/cityDetails',
      search: params.toString(),
    });
  };

  const pushToCity = (city) => {
    if (!localStorage.getItem('token')) {
      setLoginShow(true);
    } else {
      getIsBuyCity({
        city_code: city.key || city.value,
      }).then(res => {
        if (res.status === '00000') {
          if (res.data) {
            pushToCityChannel(city);
            setCityCardShow(false);
          } else {
            setDefaultBuyKey(city);
            setChannelType('city');
            setChannelModalVisible(true);
          }
        }
      });
    }
  };

  const pushToPark = (park, title, type) => {
    if (!localStorage.getItem('token')) {
      setLoginShow(true);
      return;
    }
    if (title === '省份') {
      setParkTabActive(park.key);
      return;
    }
    const params = new URLSearchParams();
    params.set('park-title', park.title);
    params.set('park-code', window.btoa(park.code));
    params.set('city-code', window.btoa(selectProvince));
    const cityListData = cityList.find((item) => item.value === selectProvince);
    params.set('city-name', cityListData.label);
    if (type === '详情') {
      recordingSpotQuer({
        spot_name: '园区',
      });
      props.history.push({
        pathname: '/parkDetail',
        search: params.toString(),
      });
    }
  };
  const specialPageRoute = (value) => {
    if (!localStorage.getItem('token')) {
      setLoginShow(true);
      return;
    }
    if (value.disabled) {
      setDefaultBuyIndustryKey(value.key);
      setModal(true);
      setPayType('special');
      if (history.location.pathname !== '/homePage') {
        history.push('/homePage');
        return;
      }
      return;
    }
    const name = window.btoa(encodeURIComponent(value.value));
    const code = window.btoa(value.key);
    recordingSpotQuer({
      spot_name: monitorSpecialMap[value.key],
    });
    history.push({ pathname: '/special', search: `name=${name}&code=${code}` });
  };
  const Division = ({ groupitem }) => (
    <div
      className={style.dropGroup}
    >
      <div className={style.groupTitle}>
        {groupitem.group_name.split('').map((titleItem, titleIndex) => (
          <div
            key={`${titleIndex}groupTitle`}
          >
            {titleItem}
          </div>
        ))}
      </div>
      <div className={style.groupBody}>
        <div className={style.topLine} />
        <div className={style.groupIndustryBody}>
          {groupitem.industry_list.map((industryItem, industryindex) => (
            <div
              className={classNames(style.industryItem, { [style.industryItemDisable]: industryItem.disabled && EAPersonalCenter })}
              key={`${industryindex}industry`}
              onClick={(e) => {
                if (industryItem.disabled && EAPersonalCenter) return;
                pushToIndustry(e, industryItem.value);
              }}
            >
              {industryItem.title}
              {/* {industryItem.disabled && <div className={style.industryLock} />} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  return (
    <div className={`${style.header}`}>
      <div className={style.icon} onClick={() => pushFirst()}>
        <img alt="" src={logo} />
      </div>
      <div className={style.menus}>
        {
          menus.map((item, index) => (
            <div
              id={item.key}
              className={classNames(item.key === menuKey ? style.selectKy : style.menuItem, { [style.proIntroduce]: item.name === '产业通Pro' })}
              style={{
                display: Number(item.value) === 0 ? 'none' : '',
              }}
              onMouseOver={() => {
                if (item.key === 'industry') {
                  setDropCardShow(true);
                }
                if (item.key === 'city') {
                  setCityCardShow(true);
                }
                if (item.key === 'special') {
                  setSpecialCardShow(true);
                }
                if (item.key === 'park') {
                  setParkCardShow(true);
                }
                if (item.key === 'toolBox') {
                  setToolShow(true);
                }
              }}
              onFocus={() => {
                if (item.key === 'industry') {
                  setDropCardShow(true);
                }
                if (item.key === 'city') {
                  setCityCardShow(true);
                }
                if (item.key === 'special') {
                  setSpecialCardShow(true);
                }
                if (item.key === 'park') {
                  setParkCardShow(true);
                }
              }}
              onMouseLeave={() => {
                setDropCardShow(false);
                setCityCardShow(false);
                setSpecialCardShow(false);
                setParkCardShow(false);
                setParkTabActive('recommend');
                setParkSearchValue(null);
                setToolShow(false);
              }}
              key={`${index}${item.key}menu`}
              onClick={() => {
                if (item.key === menuKey) return;
                handleMenuClick(item.key, menuKey);
              }}
            >
              {item.name}
              <div className={classNames({ [style.iconBox]: item.key === 'toolBox' || item.key === 'report' })}>
                {
                  item.key === 'toolBox' && (
                    <div className={style.vipIcon} />
                  )
                }
                {
                  item.key === 'report' && (
                    <div className={style.freeOfChargeReport} />
                  )
                }
              </div>
              {(dropCardShow && item.key === 'industry') && (
                <div className={classNames(style.dropCard, style.industryDrop)} onClick={(e) => e.stopPropagation()}>
                  <div className={style.dorpBody}>
                    <div className={style.division}>
                      {dropIndustryList.slice(0, 3).map((groupitem, groupindex) => (
                        <Division groupitem={groupitem} key={`${groupindex}dropGroup`} />
                      ))}
                    </div>
                    <div className={style.division}>
                      {dropIndustryList.slice(3, dropIndustryList.length).map((groupitem, groupindex) => (
                        <Division groupitem={groupitem} key={`${groupindex}dropGroup`} />
                      ))}
                    </div>
                  </div>
                  {/* {
                    Array.isArray(purchaseIndustryList) && purchaseIndustryList.length > 0 && (
                      <div className={style.dorpFooter}>
                        <div className={style.footerContent}>
                          <div className={style.footerTitle}>
                            已开通
                          </div>
                          <div className={style.purchaseList}>
                            {
                              purchaseIndustryList.map((purchase, purchaseIndex) => (
                                <div key={`${purchaseIndex}industry`} className={style.purchase} onClick={(e) => pushToIndustry(e, purchase.value)}>
                                  {purchase.title}
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    )
                  } */}
                </div>
              )}
              {(cityCardShow && item.key === 'city') && (
                <div className={classNames(style.dropCard, style.cityDrop)} onClick={(e) => e.stopPropagation()}>
                  {
                    // dropCityList && (
                    //   <div className={style.dorpBody}>
                    //     <CityTab
                    //       data={dropCityList}
                    //       itemClick={e => pushToCity(e)}
                    //     >
                    //       <Input
                    //         className={style.search}
                    //         placeholder="请输入城市名称"
                    //         value={keyword}
                    //         onChange={(e) => {
                    //           setKeyword(e.target.value);
                    //         }}
                    //         onPressEnter={e => getDropCityList(e.target.value)}
                    //       />
                    //     </CityTab>
                    //   </div>
                    // )
                    dropCityList && (
                      <div className={style.dorpBody}>
                        <CityTabSimple
                          data={dropCityList}
                          EAPersonalCenter={EAPersonalCenter}
                          itemClick={e => pushToCity(e)}
                        />
                      </div>
                    )
                  }
                  {/* {
                    Array.isArray(purchaseCityList) && purchaseCityList.length > 0 && (
                      <div className={style.dorpFooter}>
                        <div className={style.footerContent}>
                          <div className={style.footerTitle}>
                            已开通
                          </div>
                          <div className={style.purchaseList}>
                            {
                              purchaseCityList.map((purchase, purchaseIndex) => (
                                <div key={`${purchaseIndex}city`} className={style.purchase} onClick={() => pushToCity(purchase)}>
                                  {purchase.title}
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    )
                  } */}
                </div>
              )}
              {(specialCardShow && item.key === 'special') && (
                <div className={classNames(style.dropCard, style.specialDrop)} onClick={(e) => e.stopPropagation()}>
                  <ul>
                    <div className={style.topLine} />
                    {
                      specialList?.map(it => (
                        <div className={classNames(style.specialList, { [style.unpurchased]: it.disabled === true })} key={it.key} onClick={() => { specialPageRoute(it); }}>
                          {it.title}
                        </div>
                      ))
                    }
                  </ul>
                </div>
              )}
              {(parkCardShow && item.key === 'park') && (
                <div className={classNames(style.dropCard, style.parkDrop)} onClick={(e) => e.stopPropagation()}>
                  <div className={classNames(style.dorpBody, style.parkBody)}>
                    <ParkTab
                      data={{
                        省份: provinceList,
                        ...parkList,
                      }}
                      active={parkTabActive}
                      onClick={(val, title) => pushToPark(val, title, '详情')}
                    />
                  </div>
                </div>
              )}
              {(toolShow && item.key === 'toolBox') && (
                <div className={classNames(style.dropCard, style.toolDrop)} onClick={(e) => e.stopPropagation()}>
                  <HeaderToolCatalogue data={toolList} setToolShow={setToolShow} setMenuKey={setMenuKey} setLoginShow={setLoginShow} />
                </div>
              )}
            </div>
          ))
        }
      </div>
      <div className={style.my}>
        {props.location.pathname !== '/homePage' && (
          <div className={style.headerSearch} ref={headerSearchRef}>
            <Input
              placeholder="搜索企业、政策等关键词"
              value={inputText}
              onFocus={() => inputOnFocus()}
              onChange={(e) => {
                setInputText(e.target.value);
              }}
              onPressEnter={e => searchWord(e.target.value)}
            />
            {
              inputText && (
                <div className={style.inputCover} style={{ display: !focus ? 'block' : 'none' }} onClick={() => inputOnFocus()}>
                  <span className={style.coverWord}>
                    <span className={style.word1} title={inputText}>{inputText}</span>
                    <span className={style.close}>
                      &nbsp;
                      <CloseCircleOutlined onClick={() => { setInputText(''); searchWord(''); }} />
                    </span>
                  </span>
                </div>
              )
            }
            <div className={style.inputLine} />
            <IconFont
              type="iconfenxiang"
              className={style.inputIcon}
              onClick={() => searchWord(inputText)}
            />
            {
              focus && (
                <div className={style.focusBox}>
                  {
                    historyUseSearch.length > 0 && (
                      <div className={style.history}>
                        <div className={style.title}>
                          <span className={style.point} />
                          历史搜索
                        </div>
                        <div className={style.itemBox}>
                          {
                            historyUseSearch.map(item => (
                              <div
                                key={item}
                                onClick={() => {
                                  setInputText(item);
                                  searchWord(item);
                                }}
                              >
                                {item}
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>)}
        {false && (
          <div className={menuKey === 'tool' ? style.selectHelp : style.help} onClick={() => handleMenuClick('tool', menuKey)}>
            <IconFont type="iconapp" className={style.navImg} />
            工具
          </div>)}
        {false && (
          <div className={menuKey === 'solution' ? style.selectHelp : style.help} onClick={() => handleMenuClick('solution', menuKey)}>
            <IconFont type="iconfile-copy-line" className={style.navImg} />
            解决方案
          </div>)}
        {false && (
          <div className={menuKey === 'helpCenter' ? style.selectHelp : style.help} onClick={() => handleMenuClick('helpCenter', menuKey)}>
            <IconFont type="iconquestion" className={style.navImg} />
            帮助
          </div>)}
        <div
          className={menuKey === 'accountSetting' ? style.selectOut : style.out}
          onClick={() => {
            if (localStorage.getItem('token')) {
              pushToSetting(mySelfList[0]);
            } else {
              setLoginShow(true);
            }
          }}
          onMouseOver={() => {
            // 如果 localStorage 中发现有 token，那么调用外部的回调，设置登录窗口弹出
            if (localStorage.getItem('token')) {
              setOut(true);
            }
          }}
          onFocus={() => {
            if (localStorage.getItem('token')) {
              setOut(true);
            }
          }}
          onMouseLeave={() => {
            setOut(false);
          }}
          ref={myNode}
        >
          <div className={style.name}>
            {!localStorage.getItem('token') && (
              <div className={style.help}>
                <IconFont type="iconuser" className={style.navImg} />
                登录/注册
              </div>)}
            {
              localStorage.getItem('token') && EAPersonalCenter ? <div className={style.enterpriseIcon} /> : (
                <IconFont type="iconuser" className={style.navImg} style={{ display: user ? '' : 'none' }} />
              )
            }
            {localStorage.getItem('token') && user}
          </div>
          <div className={style.drop} style={{ display: showOut ? '' : 'none' }} onClick={(e) => { e.stopPropagation(); }}>
            <div className={style.menuList}>
              {mySelfList.map((item, index) => (
                <div
                  key={`${index}menuItem`}
                  className={style.menuItem}
                  onClick={() => pushToSetting(item)}
                >
                  {item}
                </div>
              ))}
              <div
                className={`${style.menuItem} ${style.loginOut} `}
                // onFocus={() => {}}
                // onMouseOver={() => setIcon(false)}
                // onMouseLeave={() => setIcon(true)}
                onClick={() => pushToLogin()}
              >
                退出登录
              </div>
            </div>
          </div>
        </div>
        {
          localStorage.getItem('token') && (
            <div className={style.exit} onClick={() => pushToLogin()}>
              退出
            </div>
          )
        }
      </div>
      {
        useMemo(() => channelModalVisible && <ChannelModal
          defaultSelect={defaultBuyKey}
          type={channelType}
          onClose={() => setChannelModalVisible(false)}
          paySuccessCb={(curBuyIndustrList) => {
            allGroupIndustryList().then(res => {
              if (res.status === '00000') {
                setDropIndustryList(res.data);
              }
            });

            const urlParams = new URLSearchParams();
            urlParams.set('tab', '个人中心');
            props.history.push({
              pathname: 'accountSetting',
              search: urlParams.toString(),
            });

            // if (curBuyIndustrList.includes(defaultBuyKey)) {
            //   pushToIndustryChannel(defaultBuyKey);
            // }
          }}
        />, [channelModalVisible, setChannelModalVisible, defaultBuyKey, pushToIndustryChannel])
      }
    </div>
  );
}
export default withRouter(Header);
