import React, { useState, useEffect, useMemo } from 'react';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import styles from './index.module.less';
import { useLazyGetCityJSONQuery } from '../../../../../../../service/static-resource';
import { useGetRegionalDistributionQuery } from '../../../../../../../service/cityDetail';

const MAP_COLOR = [
  '#8CCAFF', '#70BCFF', '#49AAFF', '#3088F5',
  '#2F73FF', '#1961F5', '#0051F7', '#0044D1',
];

const getMapName = code => {
  if (code?.toString() === '000000') return 'china';
  return code?.toString();
};

const getOption = (data, mapName) => {
  if (!data || typeof mapName !== 'string') return {};
  const maxValue = data.length > 0 ? Math.max(...data.map(item => item.value)) : 0;
  const minValue = data.length > 0 ? Math.min(...data.map(item => item.value)) : 0;
  const option = {
    tooltip: {
      trigger: 'item',
      confine: true,
    },
    backgroundColor: '#fff',
    visualMap: [{
      type: 'piecewise',
      min: minValue,
      max: maxValue,
      left: 14,
      bottom: 10,
      splitNumber: 8,
      inRange: {
        color: MAP_COLOR,
      },
      seriesIndex: 0,
      itemWidth: 22,
      itemHeight: 6,
      itemGap: 3,
      text: ['高', '低'],
      textStyle: {
        color: '#3E5C76',
        fontSize: 12,
      },
      // showLabel: false,
    }],
    geo: [{
      map: mapName,
      zoom: 1.2,
      itemStyle: {
        normal: {
          borderColor: '#fff',
        },
      },
      emphasis: {
        itemStyle: {
          areaColor: '#002187',
          borderColor: '#fff',
          borderWidth: 2,
          shadowColor: '#0060C9',
          shadowOffsetX: 2,
          shadowOffsetY: 6,
          shadowBlur: 8,
        },
        label: {
          // color: '#000',
          color: '#fff',
        },
      },
      label: {
        show: true,
        fontSize: 14,
        fontFamily: 'siyuan',
        // color: '#fff',
      },
    }],
    series: [
      {
        // name: '',
        type: 'map',
        selectedMode: false,
        geoIndex: 0,
        map: mapName,
        tooltip: {
          formatter: params => {
            const { data: _data } = params;
            if (!_data) return null;
            return `${_data.name}&emsp;&emsp;&emsp;${_data.value}${_data.unit}`;
          },
        },
        data,
      },
    ],
  };
  return option;
};

/**
 * 地图
 * @param industryCode
 * @param regionCode
 * @param onRequestMapStart
 * @param onRequestMapComplete
 * @param onRequestMapFail
 * @param onRegionClick
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
function DrillableHeatmap({
  industryCode,
  regionCode,
  onRegionClick,
  onRequestMapStart,
  onRequestMapComplete,
  onRequestMapError,
  className,
}) {
  const [option, setOption] = useState({});
  const [getCityJSON, { isFetching: isMapFetching, isError: isMapError }] = useLazyGetCityJSONQuery();
  const { data: initData, isFetching, isError } = useGetRegionalDistributionQuery({
    area_key: regionCode,
  }, {
    skip: !regionCode,
  });
  const { series } = initData || {};
  const data = series?.[0].data || [];

  useEffect(() => {
    if (isMapFetching || isFetching) {
      if (onRequestMapStart) {
        onRequestMapStart({ data: isFetching, map: isMapFetching });
      }
    } else if (!isMapError && !isError) {
      if (onRequestMapComplete) {
        onRequestMapComplete({ data: !isFetching, map: !isMapFetching });
      }
    }
    if (isMapError || isError) {
      if (onRequestMapError) {
        onRequestMapError({ data: isError, map: isMapError });
      }
    }
  }, [isMapFetching, isFetching, isMapError, isError]);

  useEffect(() => {
    if (!regionCode) return;
    const init = async () => {
      const mapName = getMapName(regionCode);
      // 如果
      if (!echarts.getMap(mapName)) {
        const { data: geojson, isError: isGetJSONError } = await getCityJSON(regionCode, true);
        if (isGetJSONError) {
          return;
        }
        echarts.registerMap(mapName, cloneDeep(geojson));
      }
      setOption(getOption(data, mapName));
    };
    init();
  }, [data, regionCode]);

  const onEvts = useMemo(() => ({
    click: ({ seriesType, name }, instance) => {
      if (seriesType === 'map') {
        const mapName = getMapName(regionCode);
        const map = echarts.getMap(mapName);
        if (!map) return;
        const { geoJSON: { features } } = map;
        const region = features?.find(feature => feature.properties.name === name);
        if (onRegionClick) {
          onRegionClick(region);
        }
      }
    },
  }), [regionCode, onRegionClick]);

  return (
    <ReactEcharts
      className={classnames(styles.chart, className)}
      style={{ width: '100%', height: '100%' }}
      option={option}
      notMerge
      onEvents={onEvts}
    />
  );
}

export default DrillableHeatmap;
