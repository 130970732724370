import { ComponentType } from 'react';

import Heading from '../components/ui/Heading';
import Cover from '../components/ui/Cover';
import Text from '../components/ui/Text';
import Image from '../components/ui/Image';
import TableOfContents from '../components/ui/TableOfContents';
import PageBreak from '../components/ui/PageBreak';
import ECharts from '../components/ui/ECharts';
import Table from '../components/ui/Table';
import Timeline from '../components/ui/Timeline';
import EnterpriseFaceCard from '../components/ui/EnterpriseFaceCard';
import EnterpriseFaceTrend from '../components/ui/EnterpriseFaceTrend';

export type Property = {
  key: string;
  name?: string;
  setters?: ({type: string; option?: object;} | string)[];
};

export type ComponentsMapping = {
  [key: string]: {
    type: string;
    component: ComponentType<any>;
    // 显示类型： 整页（整个页面） | 分页符（填充剩余部分） | 块
    display: 'page' | 'page-break' | 'block';
    ignoreMargin?: boolean;
    [key: string]: any;
  }
}

export const COMPONENTS_MAP: ComponentsMapping = {
  heading: {
    type: 'heading',
    component: Heading,
    display: 'block',
  },
  "card-enterprise-face": {
    type: "card-enterprise-face",
    component: EnterpriseFaceCard,
    display: 'block',
  },
  "card-enterprise-trend": {
    type: "card-enterprise-trend",
    component: EnterpriseFaceTrend,
    display: 'block',
  },
  text: {
    type: 'text',
    component: Text,
    display: 'block',
  },
  toc: {
    type: 'toc',
    component: TableOfContents,
    display: 'page',
  },
  cover: {
    type: 'cover',
    component: Cover,
    display: 'page',
    ignoreMargin: true,
  },
  'back-cover': {
    type: 'cover',
    component: Cover,
    display: 'page',
    ignoreMargin: true,
  },
  image: {
    type: 'image',
    component: Image,
    display: 'block',
  },
  'page-break': {
    type: 'page-break',
    component: PageBreak,
    display: 'page',
    ignoreMargin: true,
  },
  'config-echarts': {
    type: 'config-echarts',
    component: ECharts,
    display: 'block',
  },
  // 表格
  table: {
    type: 'table',
    component: Table,
    display: 'block',
  },
  timeline: {
    type: 'timeline',
    component: Timeline,
    display: 'block',
  }
};
