import React, { useState, useEffect } from 'react';
import Table from '../../../components/Table';
import styles from './index.module.less';
import { useIndustryLinkDistributionSubtableQuery } from '../../../../../../../service/cityTool';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetCompanySpecialTagsQuery,
} from '../../../../../../../service/interactive-v2';
import CustomTreeFilter from '../../../../../../../components/CustomTreeFilter';
import CustomListFilter from '../../../../../../../components/CustomListFilter';
import { findNode } from '../../../../../../../utils/tool';
import { FilterIcon } from '../../../../../../../components/CustomTableIcon';

function SectorCompanyTable(props) {
  const {
    industryCode,
    linkCode,
    cityCode,
    setCount,
    onCompanyClick,
    page,
    setPage,
  } = props;
  const [pageSize, setPageSize] = useState(20);
  const [specialTag, setSpecialTag] = useState([]);
  const [areaCode, setAreaCode] = useState(cityCode);

  const { data: tableData, isFetching: isCompanyListFetching } = useIndustryLinkDistributionSubtableQuery({
    area_key: areaCode,
    industry_code: industryCode,
    link_code: linkCode,
    special_tag: specialTag.filter(item => item !== 'all'),
    page_size: 20,
    page_number: page,
  }, { skip: !industryCode || !areaCode });
  const { total = 0, count = '', columns = [], dataSource } = tableData || {};

  useEffect(() => {
    setCount(count);
  }, [count]);

  useEffect(() => {
    setAreaCode(cityCode);
  }, [cityCode]);

  const { regionTree = [] } = useGetAreaPlugMarqueeV2Query(
    undefined,
    {
      selectFromResult: ({ data }) => {
        const root = data ? findNode(data[0], node => node.key === cityCode) : null;
        if (!root) return [];
        return {
          regionTree: [root],
        };
      },
    },
  );
  const { data: companyTags = [] } = useGetCompanySpecialTagsQuery();
  const Tablehandle = (item) => (
    {
      index: {
        width: 50,
        className: styles.index,
      },
      company_name: {
        ellipsis: true,
        onCell: (record, rowIndex) => ({
          onClick: () => { onCompanyClick(record, rowIndex); },
        }),
        className: styles.name,
      },
      special_tag: {
        width: 150,
        ellipsis: true,
        filterIcon: <FilterIcon />,
        filteredValue: specialTag.length > 0 ? specialTag : ['all'],
        filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
        filters: companyTags,
        filterResetToDefaultFilteredValue: true,
      },
      location: {
        width: 90,
        ellipsis: true,
        filterIcon: <FilterIcon />,
        filterMode: 'tree',
        filters: regionTree,
        filterDropdown: _props => <CustomTreeFilter {..._props} />,
        filteredValue: [areaCode],
      },
    }
  );
  const updateScreenCondition = ({ current, pageSize: size }, filters, sorter) => {
    const { location, special_tag: Tag } = filters;
    setPage(current);
    setPageSize(size);
    setSpecialTag(Tag);
    setAreaCode(location[0]);
  };
  return (
    <Table
      {...props}
      Tablehandle={Tablehandle}
      regionTree={regionTree}
      companyTags={companyTags}
      data={{ columns, dataSource }}
      isFetching={isCompanyListFetching}
      onChange={updateScreenCondition}
      pagination={{
        current: page,
        pageSize,
        showQuickJumper: true,
        showSizeChanger: false,
        total,
        // onChange: (current) => {
        //   setPage(current);
        // },
        // onShowSizeChange: (size) => {
        //   setPageSize(size);
        // },
      }}
    />
  );
}
export default SectorCompanyTable;
