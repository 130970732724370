import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
// import styles from './index.module.less';
import {
  useGetChannelInvestmentListQuery,
  useGetChannelInvestmentRoundListQuery,
} from '../../../../service/industry-channel';
// import { getSingleOption } from '../../../../utils/tool';
import { getHighlightConfig, getNameConfig, getSortKv } from '../../../../utils/table';
import SortTitle from '../SortTitle';
import { FilterIcon } from '../../../../components/CustomTableIcon';
import CustomListFilter from '../../../../components/CustomListFilter';

const MultiLineEllipsis = text => {
  const _text = Array.isArray(text) ? text.join('、') : text;
  return <div className="overflow-multi-5" title={_text}>{_text}</div>;
};

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: 'table-index',
  },
  ent_name: {
    title: '企业名称',
    dataIndex: 'ent_name',
    key: 'ent_name',
    // width: 330,
    ellipsis: true,
  },
  total_money: {
    title: '融资(万元)',
    dataIndex: 'total_money',
    key: 'total_money',
    width: 100,
    sortDirections: ['ascend', 'descend'],
    sorter: true,
    className: 'table-number',
    render: MultiLineEllipsis,
  },
  invest_date: {
    title: '融资时间',
    dataIndex: 'invest_date',
    key: 'invest_date',
    width: 100,
    sorter: true,
    className: 'table-number',
    render: MultiLineEllipsis,
  },
  value: {
    title: '融资轮次',
    dataIndex: 'value',
    key: 'value',
    width: 112,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: MultiLineEllipsis,
  },
  short: {
    title: '投资方',
    dataIndex: 'short',
    key: 'short',
    width: 300,
    render: MultiLineEllipsis,
  },
  financing: {
    title: '融资总额(万元)',
    dataIndex: 'financing',
    key: 'financing',
    width: 112,
    className: 'table-number',
    render: MultiLineEllipsis,
  },
  establish_date: {
    title: '成立时间',
    dataIndex: 'establish_date',
    key: 'establish_date',
    width: 102,
    className: 'table-number',
    render: MultiLineEllipsis,
  },
  special_tag: {
    title: '资质类别',
    dataIndex: 'special_tag',
    key: 'special_tag',
    width: 162,
    className: 'table-number',
    render: MultiLineEllipsis,
  },
};

const COLUMN_KEYS = [
  'index', 'ent_name', 'total_money', 'invest_date',
  'value', 'short', 'financing', 'establish_date',
  'special_tag',
];

/**
 * 点击“融资金额”、“融资时间”、“融资轮次”、“投资方”、“累计融资总额”进入投资详情页
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function InvestmentTable(props) {
  const {
    keyword,
    industryCode,
    sortedInfo = {},
    filteredInfo = {},
    pageNumber = 1,
    pageSize = 20,
    onData,
    onCompanyClick,
    onInvestmentClick,
    setParams,
  } = props;

  const { data: round } = useGetChannelInvestmentRoundListQuery();

  const {
    key: sortKey,
    value: sortValue,
  } = getSortKv(sortedInfo);
  const { data, isFetching } = useGetChannelInvestmentListQuery({
    key_word: keyword,
    industry_code: industryCode,
    sort_key: sortKey,
    sort_value: sortValue,
    // round: getSingleOption(filteredInfo?.value),
    round: (filteredInfo?.value && filteredInfo.value.filter(item => item !== 'all').length !== 0)
      ? filteredInfo.value : undefined,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });

  const { total = 0, columns = [], dataSource } = data ?? {};

  const _columns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return columns.map(item => {
      let rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      if (item.dataIndex === 'ent_name') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({
            onClick: onCompanyClick,
            keyword,
            idField: '_id',
            className: 'overflow-multi-5 clickable-name',
          })),
        };
      }
      if (item.dataIndex === 'value') {
        rowConfig.filters = round;
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filteredValue = filteredInfo.value?.length > 0 ? filteredInfo.value : ['all'];
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (['total_money', 'invest_date', 'value', 'financing'].includes(item.dataIndex)) {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig(({
            onClick: onInvestmentClick,
            idField: 'invest_id',
            className: 'overflow-multi-5 clickable-name',
          })),
        };
      }
      if (item.dataIndex === 'short') {
        rowConfig = {
          ...rowConfig,
          ...getNameConfig({
            keyword,
            onClick: onInvestmentClick,
            idField: 'invest_id',
            className: 'overflow-multi-5 clickable-name',
          }),
        };
      }
      if (rowConfig.sorter) {
        if (item.dataIndex === sortedInfo?.columnKey) {
          rowConfig.sortOrder = sortedInfo.order;
        } else {
          rowConfig.sortOrder = undefined;
        }
        rowConfig.title = () => (
          <SortTitle
            name={item.title}
            {...rowConfig}
          />
        );
      }
      return rowConfig;
    });
  }, [onCompanyClick, round, columns, filteredInfo, keyword, onInvestmentClick]);

  useEffect(() => {
    if (onData) {
      onData(data);
    }
  }, [data, onData]);

  useEffect(() => {
    if (setParams) {
      setParams({
        key_word: keyword,
        industry_code: industryCode,
        sort_key: sortKey,
        sort_value: sortValue,
        round: (filteredInfo?.value && filteredInfo.value.filter(item => item !== 'all').length !== 0)
          ? filteredInfo.value : undefined,
      });
    }
  }, [keyword, industryCode, sortedInfo, filteredInfo]);

  return (
    <Table
      columns={_columns}
      dataSource={dataSource}
      loading={isFetching}
      total={total}
      pageSize={pageSize}
      current={pageNumber}
      {...props}
    />
  );
}

export default InvestmentTable;
