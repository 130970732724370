/**
 * 产业频道页面相关接口
 */
import { apiSlice } from '../slices/api';

const PREFIX = '/interaction';

export const interactionV2Api = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAreaPlugMarqueeV2: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/area_select_box`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getCompanySpecialTags: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/get_company_type_list`,
          method: 'post',
          body: arg,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    getIndustryTree: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/search_industry_tree`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 数据-研究机构-机构级别筛选
    getCityChannelInstitutionsLevel: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/institutions_level_select_list`,
          method: 'post',
          body: arg,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    // 数据-研究机构-机构类型筛选
    getCityChannelInstitutionsType: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/institutions_type_select_list`,
          method: 'post',
          body: arg,
        };
      },
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue?.map(item => ({ ...item, text: item.title }));
      },
    }),
    // 获取城市中心点
    getCenterInfo: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/get_center_info`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  /**
   * 获取区域筛选框
   * @type {(arg?: undefined, opt?: UseQueryOption) => UseQueryResult}
   */
  useGetAreaPlugMarqueeV2Query,
  /**
   * 获取可选资质类别清单
   * @type {(arg?: undefined opt?: UseQueryOption) => UseQueryResult}
   */
  useGetCompanySpecialTagsQuery,
  /**
   * 获取可选产业类别清单
   * @type {(arg?: undefined opt?: UseQueryOption) => UseQueryResult}
   */
  useGetIndustryTreeQuery,
  /**
   * 获取机构级别筛选
   * @type {(arg?: undefined opt?: UseQueryOption) => UseQueryResult}
   */
  useGetCityChannelInstitutionsLevelQuery,
  /**
   * 获取机构类型筛选
   * @type {(arg?: undefined opt?: UseQueryOption) => UseQueryResult}
   */
  useGetCityChannelInstitutionsTypeQuery,
  /**
   * 获取城市中心点
   * @type {(arg?: undefined opt?: UseQueryOption) => UseQueryResult}
   */
  useGetCenterInfoQuery,
} = interactionV2Api;
