/**
数据搜索
*/

import { apiSlice } from '../slices/api';

const DATAOWNLOAD = '/data_download';

export const enterpriseExportApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 查询剩余可下载次数
    findDownloadRemainingNumber: builder.query({
      query(arg) {
        return {
          url: `${DATAOWNLOAD}/find_download_remaining_number`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 权限判断 /v2/data_download/button_permission
    administratorPermission: builder.query({
      query(arg) {
        return {
          url: `${DATAOWNLOAD}/button_permission`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useFindDownloadRemainingNumberQuery,
  useAdministratorPermissionQuery,
} = enterpriseExportApi;
