import React from 'react';
import classNames from 'classnames';
import style from './index.module.less';

const Drawer = (props) => {
  const { open, setOpen, title, btn } = props;
  return (
    <div className={classNames(style.box, { [style.on]: open, [style.off]: !open })}>
      <div className={style.title}>
        {title}
      </div>
      <div className={style.btn} style={{ display: btn ? '' : 'none' }} onClick={() => { setOpen(!open); }}>
        <div className={classNames({ [style.triangleRight]: open, [style.triangleLeft]: !open })} />
      </div>
      <div className={style.content}>
        { props.children }
      </div>
    </div>
  );
};

export default Drawer;
