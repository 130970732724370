import { cloneDeep } from "lodash";

// 连线数据生成结构树
const constructionTree = (data2, name, level) => {
  const treeData = {};
  treeData.title = name;
  treeData.level = 0;
  treeData.relation = data2.find(item => item.source === name).label;
  const addData = (name, level2) => {
    const children = [];
    data2.forEach(item => {
      if (item.source === name) {
        children.push({
          title: item.target,
          level: level2,
          relation: item.label,
          children: addData(item.target, level2 + 1),
        });
      }
    });
    return children;
  };
  treeData.children = addData(name, level);
  return treeData;
};

// 树结构数据格式化
export const upwardFormat = (data) => {
  if (data?.length > 0) {
    const cloneData = cloneDeep(data);
    const upData = [{ ...cloneData[0], children: cloneData[0].children.filter(item => item.direction === 'up') }];
    const downData = [{ ...cloneData[0], children: cloneData[0].children.filter(item => item.direction === 'down') }];
    const thrr = arr => {
      arr.forEach(item => {
        item.direction = 'upward';
        if (item?.children?.length > 0) {
          thrr(item.children);
        }
      });
      return arr;
    };
    const downThrr = arr => {
      arr.forEach(item => {
        item.direction = 'downward';
        if (item?.children?.length > 0) {
          downThrr(item.children);
        }
      });
      return arr;
    };
    return { downward: downThrr(downData)[0], upward: thrr(upData)[0] };
  }
};

export default constructionTree;
