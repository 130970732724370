/**
 * 城市频道页面相关接口
 */
import { apiSlice } from '../slices/api';
// import * as messages from '../proto/dot_map_pb';

const PREFIX = '/cityChannel';

export const cityChannelApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // 城市频道-数据模块统计
    getCityChannelStatistic: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/data_statistical`,
          method: 'post',
          body: arg,
        };
      },
    }),
    getRegionBorder: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_city_region_border`,
          params: arg,
        };
      },
    }),
    // 散点分布图proto
    // getDotClusterMap: builder.query({
    //   query(arg) {
    //     return {
    //       url: `${PREFIX}/dot_cluster_map`,
    //       params: arg,
    //     };
    //   },
    //   transformResponse(baseQueryReturnValue) {
    //     const ua = new Uint8Array(baseQueryReturnValue);
    //     const pointList = messages.CompanyType.deserializeBinary(ua).getCompaniesList();
    //     return pointList;
    //   },
    // }),
    // 图谱-产业地图企业数量
    getCityCompanyNumber: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/company_number`,
          params: arg,
        };
      },
    }),
    // 图谱-产业地图企业列表
    getCityCompanyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 专精特新潜力企业
    getCityChannelSRDIRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/specialization_rank_list`,
          params: arg,
        };
      },
    }),
    // 产业概况
    getIndustrialProfile: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_situation_chart`,
          params: arg,
        };
      },
    }),
    // 产业链现状
    getIndustryLinkCurrentSituation: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_link_current_situation`,
          params: arg,
        };
      },
    }),
    // 产业链列表 POST/industry_knowledge_engine/v2/cityChannel/industry_link_company_list
    getIndustryLinkCompanyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_link_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 获取产业载体类别列表get_industry_carrier_type_list
    getIndustryCarrierTypeList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/get_industry_carrier_type_list`,
          params: arg,
        };
      },
    }),
    // 获取级别列表 /get_industry_carrier_level_list
    getIndustryCarrierLevelList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/get_industry_carrier_level_list`,
          params: arg,
        };
      },
    }),
    // 对标分析
    getBenchmarkingAnalyse: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/benchmarking_analyse`,
          params: arg,
        };
      },
    }),
    // 已开通产业列表 find_auth_industry
    getFindAuthIndustry: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_auth_industry`,
          params: arg,
        };
      },
    }),

    // 产业载体清单 find_industry_carrier_list
    getFindIndustryCarrierList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_industry_carrier_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 政策清单 find_industry_policy_list
    getFindIndustryPolicyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_industry_policy_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 产业载体总数和覆盖数 industry_carrier_overlay_numbe
    getIndustryCarrierOverlayNumber: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_carrier_overlay_number`,
          params: arg,
        };
      },
    }),
    // 产业政策列表总数和覆盖数 industry_policy_overlay_number
    getIndustryPolicyOverlayNumber: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/industry_policy_overlay_number`,
          params: arg,
        };
      },
    }),
    // 获取企业分布地图 province_company_count
    getProvinceCompanyCount: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/province_company_count`,
          params: arg,
        };
      },
    }),
    // 企业分布右侧列表 area_distribution_company_rank
    getAreaDstributionCompanyRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/area_distribution_company_rank`,
          params: arg,
        };
      },
    }),
    // 地图区域分布企业列表 city_channel_area_map_company_list
    getCityChannelAreaMapCompanyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/city_channel_area_map_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 投资价值榜单
    getCityChannelInvestmentWorthRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/investment_value_rank_list`,
          params: arg,
        };
      },
    }),
    // 榜单-专利榜单
    getCityChannelCompanyPatentRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/patent_num_score_rank`,
          params: arg,
        };
      },
    }),
    // 榜单-上奇推荐
    getCityChannelCompanyScoreRank: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/company_score_rank`,
          params: arg,
        };
      },
    }),
    // 数据-企业列表
    getCityChannelCompanyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_company_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 数据-专利列表
    getCityChannelPatentList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_patent_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 数据-投资列表
    getCityChannelInvestmentList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_investment_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 数据-政策列表
    getCityChannelPolicyList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_policy_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
    // 数据-研究机构
    getCityChannelInstitutionsList: builder.query({
      query(arg) {
        return {
          url: `${PREFIX}/find_institutions_list`,
          method: 'post',
          body: arg,
        };
      },
    }),
  }),
});

export const {
  useGetCityCompanyNumberQuery,
  useGetCityCompanyListQuery,
  useLazyGetCityCompanyListQuery,
  useGetRegionBorderQuery,
  useGetIndustrialProfileQuery,
  useGetBenchmarkingAnalyseQuery,
  useGetFindAuthIndustryQuery,
  useGetIndustryLinkCurrentSituationQuery,
  useGetIndustryCarrierTypeListQuery,
  useGetFindIndustryCarrierListQuery,
  useGetFindIndustryPolicyListQuery,
  useGetIndustryCarrierLevelListQuery,
  useGetIndustryCarrierOverlayNumberQuery,
  useGetIndustryPolicyOverlayNumberQuery,
  useGetProvinceCompanyCountQuery,
  useGetAreaDstributionCompanyRankQuery,
  useGetCityChannelAreaMapCompanyListQuery,
  // useGetDotClusterMapQuery,
  useGetCityChannelStatisticQuery,
  useGetIndustryLinkCompanyListQuery,
  useGetCityChannelSRDIRankQuery,
  useGetCityChannelInvestmentWorthRankQuery,
  useGetCityChannelCompanyPatentRankQuery,
  useGetCityChannelCompanyScoreRankQuery,
  useGetCityChannelCompanyListQuery,
  useGetCityChannelPatentListQuery,
  useGetCityChannelInvestmentListQuery,
  useGetCityChannelPolicyListQuery,
  useGetCityChannelInstitutionsListQuery,
} = cityChannelApi;
