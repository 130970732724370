import React, { useMemo, useState } from 'react';
// import { ECharts } from '@sqke/design';
import classNames from 'classnames';
import { Spin } from 'antd';
import { StandardModal } from '../Modal';
import DistributionRanking from './DistributionRanking';
import SectorCompanyTable from './SectorCompanyTable';
import Underline from '../Underline';
import Subtitle from '../../../../components/Subtitle';
import ChartNoData from '../../../../components/Charts/ChartNoData';
import { openNewPage } from '../../../../utils/urlMethods';
import style from './index.module.less';
import { useGetIndustryDistributionQuery } from '../../../../service/cityDetail';

const colorList = [
  '#0F56EA', '#1961F5', '#4582FF', '#6B9BFF', '#00A9FB',
  '#22B6F1', '#00A372', '#00B572', '#70DFB6', '#FFDB92',
  '#FFC559', '#FDAD61', '#FF7F58', '#FF963C', '#FD6205',
  '#F35E5E', '#C377DC', '#B061F0', '#7B61F0', '#6E39BE',
];

function IndustrialDistribution({ cityCode, cityName, title }) {
  const [mapListQueryState, setMapListQueryState] = useState({
    pageSize: 20,
    pageNumber: 1,
    sortedInfo: {},
  });
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState({
    visible: false,
    industryCode: '',
    industryName: '',
    regionCode: '',
    type: '',
    filteredInfo: {},
  });
  const [pool, setPool] = useState({});
  const [count, setCount] = useState(0);

  const { data: chartData, isFetching } = useGetIndustryDistributionQuery({
    area_key: cityCode,
  }, {
    skip: !cityCode,
  });

  // const option = useMemo(() => {
  //   const values = chartData?.series?.[0]?.data ?? [];
  //   const min = values?.[values?.length - 1]?.value;
  //   const max = values?.[0]?.value;
  //   const range = (max - min) / 100;
  //   const seriesData = values.map((item, index) => ({
  //     ...item,
  //     symbolSize: 60 + (item.value / range),
  //     draggable: true,
  //     itemStyle: {
  //       shadowBlur: 10,
  //       shadowColor: colorList[index % colorList.length],
  //       color: colorList[index % colorList.length],
  //     },
  //     label: {
  //       show: true,
  //       textBorderWidth: 1,
  //       textBorderColor: colorList[index % colorList.length],
  //       textShadowBlur: 2,
  //       textShadowColor: colorList[index % colorList.length],
  //       color: '#fff',
  //     },
  //   }));

  //   return {
  //     tooltip: {
  //       formatter: params => `${params.marker}${params.name}\u3000\u3000${params.value.toLocaleString()}家`,
  //     },
  //     color: ['#fff', '#fff', '#fff'],
  //     series: [
  //       {
  //         type: 'graph',
  //         layout: 'force',
  //         force: {
  //           repulsion: 250,
  //           edgeLength: 10,
  //         },
  //         roam: true,
  //         data: seriesData,
  //       },
  //     ],
  //   };
  // }, [chartData]);
  // console.log(chartData);

  return (
    <div className={style.all} id={title}>
      <div className={style.title}>{title}</div>
      <div className={style.content}>
        <div className={style.left}>
          <ul>
            {
              // chartData?.series[0]?.data?.length > 0 ? chartData?.series[0].data.map((item, index) => (
              //   <li key={index}>
              //     <div className={style.hex}>
              //       <div className={style.hexIn} style={{ background: colorList[index] }}>
              //         <span className={style.name} title={item.name}>{item.name}</span>
              //         <span className={style.value}>{item.value}</span>
              //       </div>
              //     </div>
              //   </li>
              // )) : <ChartNoData />

              useMemo(() => {
                if (!chartData) return <Spin />;
                if (chartData.series[0]?.data?.length) {
                  return chartData.series[0].data.map((item, index) => (
                    <li key={index}>
                      <div className={style.hex}>
                        <div className={style.hexIn} style={{ background: colorList[index] }}>
                          <span className={style.name} title={item.name}>{item.name}</span>
                          <span className={style.value}>{item.value}</span>
                        </div>
                      </div>
                    </li>));
                }
                return <ChartNoData />;
              }, [chartData])
            }
          </ul>
        </div>
        <div className={style.right}>
          <div className={style.title}>
            <Subtitle
              className={style.subtitle}
              description={cityName}
              value={pool?.['企业总数']}
              unit="家企业"
            />
            <br />
            <Subtitle
              className={style.subtitle}
              description="链主企业"
              value={pool?.['链主企业总数']}
              unit="家，"
            />
            <Subtitle
              className={style.subtitle}
              description="上市企业"
              value={pool?.['上市企业总数']}
              unit="家，"
            />
            <Subtitle
              className={style.subtitle}
              description="专精特新"
              value={pool?.['专精特新企业总数']}
              unit="家，"
            />
            <Subtitle
              className={style.subtitle}
              description="高新技术"
              value={pool?.['高新技术企业总数']}
              unit="家"
            />
          </div>
          <Underline />
          <DistributionRanking
            regionCode={cityCode}
            pageSize={mapListQueryState.pageSize}
            pageNumber={mapListQueryState.pageNumber}
            sortedInfo={mapListQueryState.sortedInfo}
            onChange={({ current }, filter, sorter) => {
              setMapListQueryState(prev => ({
                ...prev,
                pageNumber: current,
                sortedInfo: sorter,
              }));
            }}
            onClick={({ record, column }) => {
              const { industry_code: _industryCode, industry_name: _industryName } = record;
              const { dataIndex } = column;
              setMapCompanyListQueryState(prev => ({
                ...prev,
                industryCode: _industryCode,
                industryName: _industryName,
                filteredInfo: {},
                type: [dataIndex],
                visible: true,
              }));
            }}
            getPool={setPool}
          />
        </div>
      </div>
      {
        mapCompanyListQueryState.visible && (
          <StandardModal
            header={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ color: '#0f2849', fontWeight: 'bold' }}>
                  {mapCompanyListQueryState.industryName}
                </span>
                <Subtitle
                  className={classNames(style.subtitle, style.modalTitle)}
                  description="筛选结果"
                  value={count}
                  unit="家"
                />
                {/* <Button
                  className={style.button}
                  type="primary"
                  text="下载"
                  onClick={() => {
                  }}
                /> */}
              </div>
            }
            onClose={() => {
              setMapCompanyListQueryState(prev => ({
                ...prev,
                pageNumber: 1,
                visible: false,
              }));
            }}
          >
            <SectorCompanyTable
              industryCode={mapCompanyListQueryState.industryCode}
              regionCode={mapCompanyListQueryState.regionCode || cityCode}
              pageSize={mapCompanyListQueryState.pageSize}
              pageNumber={mapCompanyListQueryState.pageNumber}
              companyTypes={mapCompanyListQueryState.type}
              onChange={({ current, pageSize }, filter) => {
                setMapCompanyListQueryState({
                  ...mapCompanyListQueryState,
                  pageSize,
                  pageNumber: current,
                  filteredInfo: filter,
                });
              }}
              filteredInfo={mapCompanyListQueryState.filteredInfo}
              onCompanyClick={({ company_id: _id }) => {
                if (!_id) return;
                openNewPage('companyDetail', { id: _id });
              }}
              getCount={setCount}
            />
          </StandardModal>
        )
      }
    </div>
  );
}

export default IndustrialDistribution;
