import React, { useEffect } from 'react';
import BarChart from '../BarChart';
import PieChart from '../PieChart';
import ChartTitle from '../ChartTitle';
import FunnelChart from '../FunnelChart';
import StackBarChart from '../StackBarChart';
import TransStackBarChart from '../TransStackBarChart';
import styles from './index.module.less';
import {
  useGetOverviewCompanyNumberQuery,
  useGetOverviewCompanyCapitalDistributeQuery,
  useGetOverviewIndustryPyramidQuery,
  useGetOverviewPatentTypeQuery,
  useGetOverviewStandardTypeQuery,
  useGetOverviewInstitutionsTypeQuery,
} from '../../../../service/industry-channel';

function Overview({ industryCode, industryName = '', getTime }) {
  const { data: barData } = useGetOverviewCompanyNumberQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: rosePieData } = useGetOverviewCompanyCapitalDistributeQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: funnelData } = useGetOverviewIndustryPyramidQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: stackBarData } = useGetOverviewPatentTypeQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: transStackBarData } = useGetOverviewStandardTypeQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const { data: pieData } = useGetOverviewInstitutionsTypeQuery({
    industry_code: industryCode,
  }, {
    skip: !industryCode,
  });

  const time = barData?.time_scope;
  useEffect(() => {
    getTime(time);
  }, [getTime, time]);

  return (
    <div className={styles.overview}>
      <div className={styles.top}>
        <div className={styles.chart}>
          <ChartTitle title={`全国${industryName}企业数量`} />
          <div className={styles.module}>
            {
              barData && <BarChart left={65} data={barData?.data} unit="家" yName="个" bottom={50} />
            }
          </div>
        </div>
        <div className={styles.chart}>
          <ChartTitle title={`全国${industryName}企业注册资本分布`} />
          <div className={styles.module}>
            {
              rosePieData && <PieChart data={rosePieData?.data} rose unit="家" />
            }
          </div>
        </div>
        <div className={styles.chart}>
          <ChartTitle title={`全国${industryName}企业金字塔结构`} />
          <div className={styles.module}>
            {
              funnelData && <FunnelChart data={funnelData?.data} />
            }
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.chart}>
          <ChartTitle title={`${industryName}产业专利类型`} />
          <div className={styles.module}>
            {
              stackBarData && <StackBarChart yName="个" unit="个" xAxis={stackBarData?.data?.xAxis} series={stackBarData?.data?.yAxis} />
            }
          </div>
        </div>
        <div className={styles.chart}>
          <ChartTitle title={`${industryName}产业标准类型`} />
          <div className={styles.module}>
            {
              transStackBarData && <TransStackBarChart data={transStackBarData?.data} />
            }
          </div>
        </div>
        <div className={styles.chart}>
          <ChartTitle title={`全国${industryName}领域创新机构分布`} />
          <div className={styles.module}>
            {
              pieData && <PieChart data={pieData?.data} unit="个" />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
