// 税收贡献
import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import style from './index.module.less';
import { useGetTaxRatingQuery, useGetTaxContributionQuery } from '../../../../../service/companyDetail';

function TaxContribution(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const [contributionPage, setContributionPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: tableFetching } = useGetTaxRatingQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });

  const { data: contributionData, isFetching: contributionFetching } = useGetTaxContributionQuery({
    company_id: companyId,
    page_size: contributionPage.pageSize,
    page_number: contributionPage.pageNumber,
  }, {
    skip: !companyId,
  });
  const _columns = (data) => {
    if (!data) return [];
    return data.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      return item;
    });
  };

  const revenueLevelTable = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={_columns(tableData?.columns)}
          title={() => tableData?.title}
          strip
          loading={tableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          // locale={{ emptyText: () => <div className={style.tableNoData}>该企业无此项数据</div> }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [tableData]);

  const incomeTaxTable = useMemo(() => {
    if (!contributionData || contributionData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={contributionData?.dataSource}
          columns={_columns(contributionData?.columns)}
          title={() => contributionData?.title}
          strip
          loading={contributionFetching}
          pagination={{
            hideOnSinglePage: true,
            current: contributionPage.pageNumber,
            pageSize: contributionPage.pageSize,
            total: contributionData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          // locale={{
          //   emptyText: () => {
          //     if (!(contributionData?.dataSource)) {
          //       return '';
          //     }
          //     return <div className={style.tableNoData}>该企业无此项数据</div>;
          //   }
          // }}
          onChange={({ current, pageSize }) => {
            setContributionPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [contributionData]);
  return (
    <div className={style.all} id="税收贡献">
      <div className={style.title}>税收贡献</div>
      {
        revenueLevelTable
      }
      {
        incomeTaxTable
      }
    </div>
  );
}

export default TaxContribution;
