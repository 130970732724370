// /*
// * Author: zhangmeng
// * Description: '历史融资'
// * Date: 2021/08/05
// */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Spin } from 'antd';
import InvestmentTableComp from '../InvestmentTableComp';
import PopOverComp from '../../IndustryCompanyDetail/CompanyContent/BaseInfo/FinancingHistory/PopOverComp';
import TitleComp from '../../IndustryCompanyDetail/CompanyContent/TitleComp';
import { historyFinancing } from '../../../api/InvestmentDetail';
import style from './index.module.less';

function InvestmentHistory(props) {
  const { id } = props;
  const [data, setData] = useState({});
  const condition = useRef({
    page_size: 5,
    page_number: 1,
  });

  const getList = (p) => {
    historyFinancing({
      investment_id: id,
      ...condition.current,
      ...p,
    }).then(res => {
      if (res.status === '00000') {
        const { columns = [] } = res.data;
        const newData = columns.map(item => {
          let obj = {};
          if (item.dataIndex === 'index') {
            obj = {
              ...item,
              width: '5%',
              ellipsis: true,
            };
          } else if (item.dataIndex === 'investor') {
            obj = {
              ...item,
              ellipsis: true,
              width: 340,
              render: (text) => {
                const dom = <PopOverComp data={text} />;
                return dom;
              },
            };
          } else {
            obj = {
              ...item,
              ellipsis: true,
            };
          }
          return obj;
        });
        setData({
          ...res.data,
          columns: newData,
        });
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const content = useMemo(() => (
    Object.keys(data).length > 0
    && <InvestmentTableComp data={data} size={5} getList={(p) => getList(p)} />
  ), [data]);

  return (
    <div className={style.all}>
      <TitleComp title="融资历史" />
      <Spin tip="搜索中，请稍候...." spinning={false}>
        <div className={style.ctn}>
          <div className={style.header}>
            <div className={style.left}>
              <div className={style.name}>总投资轮数</div>
              <div className={style.numBox}>
                <span className={style.num}>{data.count}</span>
                <span className={style.word}>轮</span>
              </div>
            </div>
            <div className={style.right}>
              <div className={style.name}>历史融资金额总计</div>
              <div className={style.numBox}>
                <span className={style.num}>{data.total_amount}</span>
                <span className={style.word}>万元</span>
              </div>
            </div>
          </div>
          {content}
        </div>
      </Spin>
    </div>
  );
}

export default InvestmentHistory;
