/*
* Author: zhangmeng
* Description: ''
* Date: 2022/06/29
*/

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Button, Pagination, Spin } from 'antd';
import moment from 'moment';
import { useGetNewsListQuery } from '../../../../service/dataSearch';
import TabSelect from '../SelectorComp/TabSelect';
import InputTreeSelect from '../SelectorComp/InputTreeSelect';
import DateSelect from '../SelectorComp/DateSelect';
import SearchSortor from '../SearchSortor';
import PublicSentimentListLine from '../PublicSentimentListLine';
import style from './index.module.less';

const sortData1 = [
  {
    title: '日期',
    key: 'time',
  },
];

function PublicSentimentPage(props) {
  const { filter, styles, participle, hideTitle = false } = props;
  const {
    news_type: newsTypeList,
    news_color: newsColorList,
    news_source_type: newsSourceTypeList,
    industryTree,
    areaTree,
  } = filter;
  const [newsType, setNewsType] = useState([]);
  const [newsColor, setNewsColor] = useState(['不限']);
  const [area, setArea] = useState([]);
  const [newsSourceType, setNewsSourceType] = useState([]);
  const [date, setDate] = useState(['', '']);
  const [industry, setIndustry] = useState([]);
  const [sortKey, setSort] = useState({ key: '', count: 0 });
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isConfirm, setIsConfirm] = useState(false);

  /* eslint-disable no-nested-ternary */
  const { data: list, isFetching } = useGetNewsListQuery(sortKey.count === 0 ? {
    news_type_list: newsType,
    news_color_list: newsColor.length === 1 ? (newsColor[0] === '不限' ? [] : newsColor) : newsColor,
    filtering_industry_list: industry,
    filtering_area_list: area,
    news_time_list: (date?.[0] !== '' && date?.[1] !== '') ? [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')] : [],
    news_source_list: newsSourceType,
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
  } : {
    news_type_list: newsType,
    news_color_list: newsColor.length === 1 ? (newsColor[0] === '不限' ? [] : newsColor) : newsColor,
    filtering_industry_list: industry,
    filtering_area_list: area,
    news_time_list: (date?.[0] !== '' && date?.[1] !== '') ? [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')] : [],
    news_source_list: newsSourceType,
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
    sort_column: sortKey.key,
    sort_order: sortKey.count === 1 ? 'asc' : 'desc',
  }, { skip: isConfirm });

  useEffect(() => {
    if (!isFetching) {
      setIsConfirm(true);
    }
  }, [isFetching]);

  useEffect(() => {
    setIsConfirm(false);
  }, [participle]);

  const resetClick = () => {
    setNewsType([]);
    setNewsColor(['不限']);
    setArea([]);
    setNewsSourceType([]);
    setDate(['', '']);
    setIndustry([]);
    setIsConfirm(true);
  };
  const confirmSearch = () => {
    setIsConfirm(false);
    setPageNum(1);
  };

  const changeSort = useCallback(
    (sort) => {
      setSort(sort);
      setIsConfirm(false);
    },
    [],
  );

  // pagination-onChange
  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
    setIsConfirm(false);
  };

  return (
    <div className={style.all} style={styles}>
      <div className={style.topBox}>
        {
          !hideTitle && <div className={style.title}>舆情</div>
        }
        <div className={style.selectorBox}>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="舆情类型"
                  data={newsTypeList}
                  select={newsType}
                  setSelect={p => {
                    setNewsType(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择舆情类型"
                />
              ), [newsTypeList, newsType])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <TabSelect
                  title="情感类型"
                  data={newsColorList}
                  selected={newsColor}
                  setSelected={p => {
                    setNewsColor(p);
                    setIsConfirm(true);
                  }}
                />
              ), [newsColorList, newsColor])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="区域"
                  data={areaTree}
                  select={area}
                  setSelect={p => {
                    setArea(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择区域"
                />
              ), [areaTree, area, setArea])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="来源类型"
                  data={newsSourceTypeList}
                  select={newsSourceType}
                  setSelect={p => {
                    setNewsSourceType(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择来源类型"
                />
              ), [newsSourceTypeList, newsSourceType, setNewsSourceType])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <DateSelect
                  title="日期"
                  select={date}
                  setSelect={p => {
                    if (p) {
                      setDate(p);
                    } else {
                      setDate(['', '']);
                    }
                    setIsConfirm(true);
                  }}
                />
              ), [date, setDate])
            }
          </div>
          <div style={{ width: '50%' }}>
            {
              useMemo(() => (
                <InputTreeSelect
                  title="产业"
                  data={industryTree}
                  select={industry}
                  setSelect={p => {
                    setIndustry(p);
                    setIsConfirm(true);
                  }}
                  ph="请选择产业"
                />
              ), [industryTree, industry, setIndustry])
            }
          </div>
          <div className={style.btnGroup}>
            <Button type="primary" ghost onClick={() => resetClick()}>
              重置
            </Button>
            &emsp;
            <Button type="primary" className="confirmSearch" onClick={() => confirmSearch()}>
              查询
            </Button>
          </div>
        </div>
      </div>
      <div className={style.listBox}>
        <Spin tip="搜索中，请稍候..." spinning={isFetching}>
          <SearchSortor data={sortData1} sort={sortKey} total={list?.data_count || 0} onChange={changeSort} />
          <div className={style.list}>
            {
              list && list.data_list && list.data_list.length > 0 ? list.data_list.map((item, index) => (
                <PublicSentimentListLine key={index} record={item} participle={participle} />
              )) : <div style={{ textAlign: 'center', marginTop: '50px' }}>暂无数据</div>
            }
            <div className={style.pagination}>
              <Pagination
                showQuickJumper
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={pageSize}
                current={pageNum}
                total={list?.auth_count || 0}
                onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default PublicSentimentPage;
