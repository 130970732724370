import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Button, Form, InputNumber, DatePicker, Select, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLazyGetFindCompanyListByLikeNameQuery, useFindCompanyIndexQuery } from '../../../../service/tool';
import style from './index.module.less';

const { Option } = Select;
const serveList = ['电子信息', '生物医药', '航空航天', '新材料', '高技术服务', '新能源', '节能', '智能制造', '其他'];
let timeout = null;

const CompanyInfo = () => {
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const [getCityCompanyListQuery] = useLazyGetFindCompanyListByLikeNameQuery();
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const goParams = new URLSearchParams();
  const name = params.get('name');
  const history = useHistory();
  const [form] = Form.useForm();

  const { data } = useFindCompanyIndexQuery({ company_id: companyId }, { skip: !companyId });
  // 设置表单初始值
  useEffect(() => {
    let companyInfo = localStorage.getItem('companyInfoForm');
    if (companyInfo !== 'undefined' && companyInfo) {
      companyInfo = JSON.parse(companyInfo);
      companyInfo.time = moment(companyInfo.time);
      setCompanyId(companyInfo.companyId);
      form.setFieldsValue(companyInfo);
    } else {
      setCompanyId(params.get('id'));
      form.setFieldsValue({
        violation: '否',
        name,
      });
    }
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        time: moment(data.time),
        designPatent: data.design_patent,
        patent: data.invent_patent,
        practicalPatent: data.practical_patent,
        copyright: data.software_copyright,
      });
    }
  }, [data]);

  // 获取企业列表
  const getCompanyLsit = (value) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    // 请求企业列表
    const getCompanyLsitCompany = async () => {
      try {
        const res = await getCityCompanyListQuery({ like_name: value });
        setCompanyList(res.data);
      } catch {
        setCompanyList([]);
        throw new Error('获取企业列表请求发生错误');
      }
    };
    timeout = setTimeout(getCompanyLsitCompany, 300);
  };

  // 输入时触发
  const handleSearch = (newValue) => {
    if (newValue) {
      getCompanyLsit(newValue);
    } else {
      setCompanyList([]);
    }
  };

  // 验证通过将数据储存到localStorage
  const onFinish = (values) => {
    const formattedDate = moment(values.time).format('YYYY-MM-DD');
    localStorage.setItem('companyInfoForm', JSON.stringify({ ...values, time: formattedDate, companyId }));
    goParams.set('name', params.get('name'));
    goParams.set('id', params.get('id'));
    history.push({
      pathname: '/tool/companyTest/testResult',
      search: goParams.toString(),
    });
  };
  const onFinishFailed = (errorInfo) => {
    message.warning('请按照规范正确填写表单');
  };

  // 去记录页
  const goToEvaluationRecord = () => {
    goParams.set('name', params.get('name'));
    goParams.set('id', params.get('id'));
    history.push({
      pathname: '/tool/companyTest/evaluationRecord',
      search: goParams.toString(),
    });
  };

  // 验证规则
  const rulesFun = (rules) => {
    const list = ['required', 'message', 'type', 'pattern'];
    const obj = {};
    list.forEach(item => {
      if (rules[item]) {
        obj[item] = rules[item];
      }
    });
    if (Object.keys(obj).length) {
      return [obj];
    }
    return '';
  };

  // 时间选矿禁止选择大于今天的时间
  const disabledDate = (current) => {
    // 获取今天的日期
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // 将current转换为日期对象
    const currentDate = new Date(current);
    currentDate.setHours(0, 0, 0, 0);
    // 如果current大于今天，则禁止选择
    return currentDate.getTime() > today.getTime();
  };

  const infoList = [
    {
      label: '企业名称',
      name: 'name',
      required: true,
      message: '请输入正确的公司名称',
      placeholder: '请输入公司名称关键词',
      El: (placeholder) => (
        <Select
          showSearch
          placeholder={placeholder}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={(value, info) => {
            setCompanyId(info.key);
          }}
          notFoundContent={null}
        >
          {
            companyList?.map(item => <Option key={item.id} value={item.name}>{item.name}</Option>)
          }
        </Select>
      ),
    },
    { label: '注册时间', name: 'time', required: false, placeholder: '请输入时间', El: (placeholder) => <DatePicker style={{ width: '100%' }} disabled placeholder={placeholder} disabledDate={(value) => disabledDate(value)} /> },
    { label: '发明专利（件）', name: 'patent', required: false, pattern: /^[0-9]\d*$/, message: '请输入正确的类型（不为0的正整数）', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '实用新型专利（件）', name: 'practicalPatent', required: false, pattern: /^[0-9]\d*$/, message: '请输入正确的类型（不为0的正整数）', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '外观设计专利（件）', name: 'designPatent', required: false, pattern: /^[0-9]\d*$/, message: '请输入正确的类型（不为0的正整数）', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '软件著作权（件）', name: 'copyright', required: false, pattern: /^[0-9]\d*$/, message: '请输入正确的类型（不为0的正整数）', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    {
      label: '产品(服务)所属领域',
      name: 'serve',
      required: true,
      placeholder: '请输入',
      El: (placeholder) => (
        <Select
          placeholder={placeholder}
          mode="multiple"
        >
          {
            serveList.map(item => (
              <Option key={item} value={item}>{item}</Option>
            ))
          }
        </Select>
      ),
    },
    {
      label: '近一年内是否发生违法行为',
      name: 'violation',
      required: false,
      placeholder: '请输入',
      El: (placeholder) => (
        <Select
          placeholder={placeholder}
        >
          <Option value="是">是</Option>
          <Option value="否">否</Option>
        </Select>
      ),
    },
    { label: '研发人数占比(%)', name: 'YFRS', required: true, pattern: /^(?:[1-9][0-9]?|100)$/, message: '请输入0-100的正整数', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '近三年研发费用(万元)', name: 'expenditure', required: true, pattern: /^[1-9]\d*$/, message: '请输入正确的类型如(10)', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '近三年中国境内研发费用（万元）', name: 'cnExpenditure', required: true, pattern: /^[1-9]\d*$/, message: '请输入正确的类型如(10)', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '近三年同期销售收入（万元）', name: 'revenue', required: true, pattern: /^[1-9]\d*$/, message: '请输入正确的类型如(10)', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '近一年高新技术产品(服务)收（万元）', name: 'serviceCharge', required: true, pattern: /^[1-9]\d*$/, message: '请输入正确的类型如(10)', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '企业同期总收入（万元）', name: 'grossRevenue', required: true, pattern: /^[1-9]\d*$/, message: '请输入正确的类型如(10)', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
    { label: '自主知识产权数量（件）', name: 'intellectualProperty', required: false, pattern: /^[1-9]\d*$/, message: '请输入正确的类型如(10)', placeholder: '请输入', El: (placeholder) => <InputNumber style={{ width: '100%' }} placeholder={placeholder} /> },
  ];
  return (
    <div className={style.companyInfo}>
      <div className={style.content}>
        <header>
          <div>
            <div className={style.left}>
              <p>高企测评</p>
              <p>
                完善信息
              </p>
            </div>
            <div className={style.right}>
              <button type="button" onClick={goToEvaluationRecord}>
                查看记录
              </button>
            </div>
          </div>
          <button
            type="button"
            className={style.out}
            onClick={() => {
              goParams.set('name', params.get('name'));
              goParams.set('id', params.get('id'));
              history.push({
                pathname: '/tool/companyTest',
                search: params.toString(),
              });
              localStorage.removeItem('companyInfoForm');
            }}
          >
            <p />
            <p>返回</p>
          </button>
        </header>
        <div className={style.info}>
          <Form
            name="basic"
            labelAlign="left"
            form={form}
            labelWrap
            colon={false}
            labelCol={{
              span: 3,
              offset: 5,
            }}
            wrapperCol={{
              span: 10,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            {
              infoList.map(item => (
                <Form.Item
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  rules={rulesFun(item)}
                >
                  {item.El(item.placeholder)}
                </Form.Item>
              ))
            }
            <Form.Item
              wrapperCol={{
                offset: 5,
                span: 13,
              }}
            >
              <Button type="primary" htmlType="submit">
                查看评测结果
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

    </div>
  );
};

export default CompanyInfo;
