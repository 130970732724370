import { useCallback, useEffect, useState } from 'react';

export function useResize(resizeHandler, deps) {
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, deps);
}

export function useOverHeight(ref, deps) {
  const [isOver, setIsOver] = useState(false);
  const resizeHandler = useCallback(() => {
    if (ref.current) {
      const el = ref.current;
      const { clientHeight, scrollHeight } = el;
      setIsOver(clientHeight < scrollHeight);
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [ref, ...deps]);

  return isOver;
}

export function useClickSpace(callback, deps) {
  useEffect(() => {
    if (typeof callback !== 'function') {
      return undefined;
    }
    window.addEventListener('click', callback);

    return () => {
      window.removeEventListener('click', callback);
    };
  }, deps);
}

export function useFullscreen(callback, deps) {
  useEffect(() => {
    if (typeof callback !== 'function') {
      return undefined;
    }

    document.addEventListener('fullscreenchange', callback);

    return () => {
      document.removeEventListener('fullscreenchange', callback);
    };
  }, deps);
}

export function useMatchMedia(mediaQueryString, callback, deps) {
  useEffect(() => {
    if (typeof callback !== 'function') {
      return undefined;
    }
    const { matchMedia } = window;
    callback(matchMedia(mediaQueryString));
    matchMedia(mediaQueryString).addEventListener('change', callback);

    return () => {
      matchMedia(mediaQueryString).addEventListener('change', callback);
    };
  }, deps);
}
