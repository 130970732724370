/*
 *Description: Scatter
 *Date: Tue Nov 09 2021 10:56:41 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { ScatterChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, getWordsWidth, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-scatter-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const ScatterChart = forwardRef((props: ScatterChartProps, ref: any) => {
  const { mode, label, legend, title = '', fontSize, data, handleItemStyle } = props;
  const [hanldeData, setHanldeData] = useState<any>([]);
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;
    if (!data?.data_list?.length) {
      return;
    }
    // console.log(data?.data_list);
    let max = -Infinity;
    const handleStyle = handleItemStyle || function styleHanlder(_dat: any) { return {}; };
    const legendData: { name: any; itemStyle: any; }[] = [];
    const handledBlock = new Map();
    // eslint-disable-next-line no-unused-expressions
    const handledData = data?.data_list.map((item: any) => {
      max = Math.max(item.value, max);
      const itemStyle = handleStyle(item);
      if (!handledBlock.get(item.block)) {
        handledBlock.set(item.block, 1);
        legendData.push({
          name: item.block,
          itemStyle,
        });
      }
      return {
        name: item.name,
        value: [Number(item.x), Number(item.y), Number(item.value)],
        itemStyle,
        label: {
          show: item.is_tag,
          overflow: 'none',
          formatter: () => {
            const str = `{name|${item.name}\n}(${item.x}, ${item.y}, ${item.value})`;
            return str;
          },
          rich: {
            name: {
              width: 100,
              align: 'center',
            },
          },
        },
      };
    });
    const series: any[] = [{
      name: 'name',
      type: 'scatter',
      // clip: true,
      symbolSize(value: any) {
        const defaultSize = 8;
        if (max === 0) {
          return defaultSize;
        }
        const size = (((value[2]) / max) * 16 + 4);
        // console.log(size);
        return size;
      },
      data: handledData,
      // datasetIndex: 2,
      label: {
        show: false,
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
        position: 'top',
      },
      labelLayout(params: any) {
        const dataItem = handledData[params.dataIndex];
        if (!dataItem.label.show) {
          return {};
        }
        // 边界溢出处理
        const { labelRect, rect } = params;
        const centerRectX = rect.x + (rect.width / 2);
        const labelLeft = centerRectX - (labelRect.width / 2);
        // 左溢
        if (labelLeft < 2) {
          return {
            dx: Math.abs(labelLeft) + 4,
          };
        }
        const instance = chartRef.current.getEchartsInstance();
        const conWidth = instance.getWidth();
        const labelRight = centerRectX + (labelRect.width / 2);
        // console.log(labelRight, conWidth, dataItem);
        if (labelRight > conWidth - 2) {
          return {
            dx: -Math.abs(conWidth - labelRight) - 2,
          };
        }
        return {};
      },
    }];
    legendData.forEach(item => {
      series.push({
        type: 'custom',
        name: item.name,
        renderItem() {},
      });
    });
    const xNameGap = 10;
    const yNameGap = 10;
    const { nameFontSize } = curFont;
    const options = {
      grid: {
        top: nameFontSize + yNameGap + 20,
        left: ((data.x_name.length / 2) * 10) - 6,
        right: getWordsWidth(data.x_name, { font: `${10}px sans-serif` }) + xNameGap + 8,
        bottom: 20,
        containLabel: true,
      },
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        data: legendData,
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      xAxis: {
        type: 'value',
        // data: xData,
        name: data.x_name,
        nameLocation: 'end',
        nameGap: xNameGap,
        nameTextStyle: {
          padding: 0,
          fontSize: nameFontSize,
        },
        axisLine: {
          show: true,
          onZero: true,
          lineStyle: {
            color: 'rgba(0, 0, 0, 0.45)',
          },
        },
        axisLabel: {
          // rotate: -45,
          fontSize: curFont.legnedFontSize,
          color: '#777A7A',
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        name: data.y_name,
        nameLocation: 'end',
        nameGap: yNameGap,
        nameTextStyle: {
          padding: 0,
          fontSize: nameFontSize,
        },
        type: 'value',
        // data: yData,
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: curFont.legnedFontSize,
          color: '#777A7A',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#EEEEEE',
            type: 'dashed',
          },
        },
        axisLine: {
          show: false,
          onZero: false,
        },
      },
      visualMap: {
        min: 1,
        max: 10,
        text: ['', ''],
        realtime: false,
        calculable: true,
        inRange: {
          color: curStyle.color,
        },
        show: false,
      },
      series,
    };
    setOption(options);
  }, [hanldeData, fontSize, label, legend, mode, title, data]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default ScatterChart;

ScatterChart.defaultProps = {
  mode: 'light',
  label: true,
  legend: true,
  fontSize: 'middle',
  data: {} as any,
};
