/*
 *Author: zhaochenyu
 *Description: 分析引擎右侧图表
 *Date: 2021/10/26
*/

import React from 'react';
import {
  EnterpriseRegisterHorizontalBarChart,
  EnterpriseCountRankingHorizontalBarChart,
  EnterPrisePyramidFunnelChart,
  LegalTypesPieChart,
  EnterPriseRegisterPieChart,
  TransactionTrendsChart,
  InvestmentRoundsBarChart,
  InvestmentStackBarChart,
  PatentTypeStackBarChart,
  CompanyCountBarChart,
  RegionGrowAndRatioBarAndLineChart,
  PerCapitaGDPBarchart,
  AverageRegionalGDPBarchart,
  RegionalGDPStructureStackBarChart,
  PublicBudgetRevenueExpenditureBarCharts,
  TotalRetailSalesSocialConsumerGoodsBarChart,
  EnterPriseStockCount,
  ComparisonOfNewEnterprisesBarChart,
  PatentComparisonBarChart,
  IndustryPatentDistribution,
  ComparisonOfNumberResearchInstitutionsBarChart,
  ResearchTypeDiff,
  IndustryDistribution,
  CompositionOfRegionalGDPBarChart,
  ChangesInPerCapitaGDPBarChart,
  ChangeOfAverageRegionalGDPBarChart,
  RevenueAndExpenditureGeneralPublicBudgetBarChart,
  ChangesTotalRetailSalesSocialConsumerGoods,
  AreaGrowAndRatioBarAndLineChart,
  CompanyChartTOP10EnterprisesWithRegisteredCapitalFenxi,
  InvestmentChartearlyStageFundingListFenxi,
  PatentChartpatentNumberGroupCompanyFenxi,
  CompanyChartqueueListOfListedCompaniesFenxi,
  HeadEnterprisesCount,
  HighTechEnterprise,
  InvestAndFinancingDistribute,
  InvestAndFinancingMoneyDistribute,
  ParkChartcomparisonOfParkQuantityFenxi,
  ResearchAreaTypeDistribute,
  ResearchTypeDistribute,
  IndustryAreaTypeDistribute,
  HeaderCompanyTop5,
  IndustryLoaderTypeDistribute,
  IndustryLoaderDistribute,
  PatentChartauthorizedInventionPatentCityFenxi,
  CompanyChartheaderCompanyTypeDistributionFenxi,
  PatentChartnewNumberOfPatentsBenchmarkingFenxi,
  CompanyChartcompanyCountBenchmarkingFenxi,
  CompanyChartindustryCompanyNumberFenxi,
  EnterpriseAndindustryDistribute,
  CompanyChartHandlercompanyNumChangeFenxi,
  PatentChartnewIndustryPatentNumberFenxi,
  PatentChartHandlerpatentApplyChangeFenxi,
  PatentChartHandlerpatentGrantChangeFenxi,
  AuthorizedAndInventionDistribute,
  AverageFinancingChange,
  IndustryDistributionHorizontalBarChart,
  IndustryMoneyDistributionHorizontalBarChart,
  CompanyChartpyramidFigureMarketFenxi,
  PatentChartnewAuthorizedInventionPatentCityFenxi,
  InstitutionChartnationalLevelInstitutionIndustryAreaFenxi,
  InvestmentChartchangesFinancingEventsNumberFenxi,
  InvestmentChartchangesFinancingEventsMoneyFenxi,
  PatentChartnewRectangularTreeGraphPatentIndustryFenxi,
  CompanyChartHandlerenterpriseGeospatialDistributionFenxi,
  PatentChartnewIndustrialDistributionPatentFenxi,
  PatentChartindustrialDistributionPatentFenxi,
  CompanyChartindustrialDistributionCompanyFenxi,
  CarrierChartindustrialDistributionFenxi,
  IndustryChartindustryLinkFenxi,
  CompanyChartcompanyNumberTopTableFenxi,
  CompanyChartcurveGraphMarketSumFenxi,
  CompanyChartnewCompanyWaterfallFenxi,
  CompanyChartnewCompanyNumberRankingFenxi,
  CompanyChartenterpriseEstablishmentTimeStructureChartFenxi,
  PatentChartnumberOfAuthorizedInventionPatentsFenxi,
  StandardChartnumberOfAuthorizedInventionPatentsFenxi,
  InstitutionChartresearchOrganizationStructureChartFenxi,
  PolicyChartpolicyQuantityChangeFenxi,
  CompanyChartchangesNumberExistingEnterprisesFenxi,
  PatentChartnewAuthorizedInventionPatentCountFenxi,
  PolicyChartregionalDistributionOfPolicyQuantityFenxi,
  PatentChartnewAuthorizedInventionPatentTopNumberFenxi,
  PatentChartauthorizedInventionPatentKeywordCloudFenxi,
  InstitutionChartinstitutionCountDistributionDiagramFenxi,
  ParkChartparkCountDistributionDiagramFenxi,
  InvestmentChartinvestmentCountDistributionDiagramFenxi,
  InvestmentChartinvestmentMoneyDistributionDiagramFenxi,
  InvestmentChartearlyInvestmentMoneyListTOP10Fenxi,
  InvestmentChartonlyFundingInvestmentMoneyListTOP10Fenxi,
  StandardChartstandardDraftingUnitTopFenxi,
  PatentChartinventionPatentUnitCooperationFenxi,
  CompanyChartindustrialPyramidFenxi,
  StandardChartstandardUnitCooperationFenxi,
  PatentChartpatentAreaThermodynamicChartFenxi,
  CommonChartcompanyBranchInvesteeGraph,
  CommonChartcompanyBranchInvesteeTop3TableGraph,
  PatentChartinnovationTechnologyPenetrationFenxi,
  PatentChartcompanyPatentKeywordCloud,
  CommonChartcompanyGrowUpActiveCurveFenxi,
  CommonChartgrowthProcessFenxi,
  CommonChartmultiDimensionalComprehensiveIndustryGraph,
  CommonChartindustryRegisteredCapitalTop20,
  CompanyChartcompanyQualificationLabelsFenxi,
  CompanyChartcompanyCertificationCurveGraph,
  StandardChartcompanyStandardsCountFenxi,
  StandardChartparticipateDraftingStandardsFenxi,
  PatentChartpatentValuesGraph,
  PatentChartinventPatentTimeSeriesFenxi,
  PatentChartpatentTypeGraphFenxi,
  PatentChartpatentTypeIPCCategoricalDistributionGraph,
  PatentCharthomdogyPatentGlobalDistribution,
  PatentChartrankOfPatentInventorsFenxi,
  InstitutionChartresearchInstitutionLevelTypeGraph,
  PatentChartcooperativePatentApplicationNetworkFenxi,
  PatentChartdistributionOfPatentTypesFenxi,
  StandardChartcompanyStandardDraftingIssueNetworks,
  StandardChartdistributionOfStandardTypesFenxi,
  PatentChartdetailsNewPatentsGrantedFenxi,
  StandardChartcompanyStandardDraftingIssue,
  CompanyChartmarketCompanyBusinessCircumstanceGraph,
  InvestmentChartcompanyInvestmentExperience,
  CompetitionPatternMindMapchart,
  CompanyChartcompanyStockMapFenxi,
  CompanyChartcompanyStockStrikeTableFenxi,
  CompanyChartvaluationOfListedEnterprisesFenxi,
  CommonChartgrowthRateCurvesOfAllDimensionsFenxi,
  CompnayDetailInfoChart,
  CompanyChartCompanyAreaThermodynamicChartFenxi,
  IndustryCharTpanoramaIndustrialChain,
  IndustryChartBasicIndustrialChain,
  CompnayChartChartListOfInvestmentManagersFenxi,
  CompnayChartDistributionOfIndustrialLinksFenxi,
  CompanyChartMarketEnterprisesTop30,
  CompanyChartRegisteredCapitalTop30,
  CommonChartPatentRankTOP30Fenxi,
  CompanyChartFinancingAmountTop30,
  CompanyChartBTHEnterprisesOddScoreTop30,
  CompanyChartYRDEnterprisesOddScoreTop30,
  CompanyChartPREnterprisesTop30OddScore,
  CompanyChartListOfKeyRecommendedEnterprisesForInvestmentPromotion,
  CompanyChartInvestmentUnitsTop30,
  CompanyChartRegisteredCapitalOfUnlistedEnterprisesTop30,
  CompanyChartUnlistedCompaniesRankedTop30InPatents,
  CompanyChartPatentCooperationAmongUnlistedEnterprisesTop30,
  CompanyChartUnlistedEnterprisesNationalLabelTop30,
  CompanyChartUnlistedEnterprisesFinancingAmountTop30,
  CompanyChartUnlistedEnterprisesEarlyRiskFinancingSpeedTop30,
  CompanyChartInvestmentByKeyInstitutions,
  CompanyChartInvestmentFocusRecommendedEnterprises,
  CompanyChartTop30QualityShareholdersOfUnlistedEnterprises,
  CompanyChartCompanyAreaThermodynamicChartSpecialization,
  CompnayChartCompanyDistributedVariousIndustriesSpecialization,
  CompnayChartIndustrialDistributionCompanySpecialization,
  CompanyChartCompanyCapitalDistributeSpecialization,
  CompanyChartDateOfEstablishmentSpecialization,
  CompanyChartPyramidFigureMarketSpecialization,
  CompanyChartRegisteredCapitalOfUnlistedEnterprisesTop30Specialization,
  PatentChartPatentApplyChangeSpecialization,
  PatentChartPatentGrantChangeSpecialization,
  PatentChartDistributionOfPatentIndustrySpecialization,
  PatentChartNumberOfPatentsBenchmarkingSpecialization,
  CompanyChartMobilityOfCapitalPyramidSpecialization,
  CompanyChartGazelleCompanyDistributionSpecialization,
  InvestmentChartInvestmentCountBarChartSpecialization,
  InvestmentChartInvestmentMoneyBarChartSpecialization,
  InvestmentChartInvestmentIndustryCountBarChartSpecialization,
  InvestmentChartInvestmentIndustryMoneyBarChartSpecialization,
  CompanyChartAvgInvestHistoricalDaysSpecialization,
  MacroEconomyChartdiyTagCompanyDistributeFenxi,
  CompanyChartNationalHighTechGazelleCompanyRegCapitalTop,
  CompanyChartChineseUnicornCompanyRegCapitalTop,
  CompanyStatisticalChartDiyTagCompanyStandardFenxi,
  CompanyStatisticalChartDiyTagNotMarketCompanyStandardFenxi,
  CompanyStatisticalChartAfterCroundInvestmentMoneyTop100,
  InvestmentChartInvestMarketCompanyTop100,
  CompanyStatisticalChartPatentNumberSpecialization,
  CompanyStatisticalChartUnlistedEnterprisePatentNumberSpecialization,
  PatentChartInventionPatentUnitCooperationSpecialization,
  StandardChartStandardUnitCooperationSpecialization,
  CommonChartPatentStandardRankSpecialization,
  CompanyChartEnterpriseRegionalDistributionSpecialization,
  InvestmentChartRoundDistributionFinancingEventsSpecialization,
  PatentChartDistributionAuthorizedPatentTypesSpecialization,
  InvestmentChartChangesFinancingEventsNumberSpecialization,
  InvestmentChartChangesFinancingEventsMoneySpecialization,
  InvestmentChartCurveGraphInvestmentAvgSpecialization,
  InvestmentChartChangeNumberOfFinancingEventsSpecialization,
  InvestmentChartListedCompanyFinancingAndAvgFinancingSpecialization,
  InvestmentChartUnlistedCompanyFinancingAndAvgFinancingSpecialization,
  InvestmentChartFinancingRoundDistributionSpecialization,
  InvestmentChartRegionalDistributionFinancingSpecialization,
  InvestmentChartDistributionFinancingIndustrySpecialization,
  PatentChartDistributionOfPatentIndustryTreeSpecialization,
  CompanyChartCompanyRegisteredCapitalTop100Specialization,
  CompanyStatisticalChartAuthorizedPatentsTop100Specialization,
  CompanyStatisticalChartUnlistedAuthorizedPatentsTop100Specialization,
  CompanyStatisticalChartAuthorizedInventionPatentTop100Specialization,
  PatentChartLatestAuthorizedInventionPatentDetailsTop100,
  CompanyStatisticalChartDraftStandardTOP100Specialization,
  CompanyStatisticalChartUnlistedDraftStandardTOP100Specialization,
  StandardChartLatestStandardDetailsTop100Specialization,
  CompanyStatisticalChartAmountOfFinancingObtainedSpecialization,
  CompanyStatisticalChartUnlistedAmountOfFinancingObtainedSpecialization,
  CompanyStatisticalChartTop100FinancingSpeedUnlistedEnterprisesSpecialization,
  InvestmentChartLatestFinancingEventDetailsTop100,
  StandardChartStandardTypeDistributionSpecialization,
  CompanyChartUnlistedNationalHighTechGazelleCompanyRegCapitalTop,
  CompanyChartUnlistedGazelleCompanyDistributionSpecialization,
  CompanyChartSubdivisionDistribution,
  CompnayChartSubdivideLinkRegionalDistribution,
  PatentChartAuthorizedInventionPatentedTechnology,
  StandardChartStandardVariationTrend,
  StandardChartDraftStandardRegionalDistribution,
  PatentChartFindPatentList,
} from '@shangqi/cube-component';
import ChartBorder from './ChartBorder';
import { baseIp } from '../../utils/url';
import style from './index.module.less';

// let baseIp;
// if (process.env.NODE_ENV === 'production') {
//   /* 自动获取服务器地址 */
//   if (window.location.origin) {
//     baseIp = window.location.origin;
//   } else {
//     // 兼容origin没有的情况
//     baseIp = `${window.location.protocol}/${window.location.hostname}
//     ${window.location.port ? `:${window.location.port}` : ''}`;
//   }
//   baseIp = `${baseIp}${getUrlPrefix()}/industry_knowledge_engine/v2`;
// } else {
//   baseIp = '/devproxy/industry_knowledge_engine/v2'; // 开发环境
// }

const chartBaseIp = [baseIp];

export function getChart(itemMsg, chartIndex, imgRefs, onReq) {
  const { params, chart_id: id, fontSize } = itemMsg;
  let res = null;
  const commonCondition = {
    mode: 'light',
    ip: chartBaseIp,
    conditions: params,
    title: false,
  };
  if (imgRefs) {
    commonCondition.imgRef = imgRefs.current[chartIndex];
  }
  if (typeof onReq === 'function') {
    commonCondition.onRequestComplete = onReq;
  }
  if (fontSize) {
    commonCondition.fontSize = fontSize;
  }
  switch (id) {
    case 1:
      res = <EnterpriseRegisterHorizontalBarChart
        {...commonCondition}
      />;
      break;
    case 2:
      res = <EnterpriseCountRankingHorizontalBarChart
        {...commonCondition}
      />;
      break;
    case 3:
      res = <EnterPrisePyramidFunnelChart
        {...commonCondition}
        legend={false}
      />;
      break;
    case 4:
      res = <LegalTypesPieChart
        {...commonCondition}
      />;
      break;
    case 5:
      res = <EnterPriseRegisterPieChart
        {...commonCondition}
      />;
      break;
    case 6:
      res = <CompanyCountBarChart
        {...commonCondition}
      />;
      break;
    case 7:
      res = <InvestmentStackBarChart
        {...commonCondition}
      />;
      break;
    case 8:
      res = <TransactionTrendsChart
        {...commonCondition}
      />;
      break;
    case 9:
      res = <InvestmentRoundsBarChart
        {...commonCondition}
      />;
      break;
    case 10:
      res = <PatentTypeStackBarChart
        {...commonCondition}
      />;
      break;
    case 11:
      res = <RegionGrowAndRatioBarAndLineChart
        {...commonCondition}
      />;
      break;
    case 12:
      res = <CompanyChartTOP10EnterprisesWithRegisteredCapitalFenxi
        {...commonCondition}
      />;
      break;
    case 93:
      res = <AverageRegionalGDPBarchart
        {...commonCondition}
      />;
      break;
    case 94:
      res = <RegionalGDPStructureStackBarChart
        {...commonCondition}
      />;
      break;
    case 95:
      res = <PublicBudgetRevenueExpenditureBarCharts
        {...commonCondition}
      />;
      break;
    case 96:
      res = <TotalRetailSalesSocialConsumerGoodsBarChart
        {...commonCondition}
      />;
      break;
    case 97:
      res = <EnterPriseStockCount
        {...commonCondition}
      />;
      break;
    case 98:
      res = <ComparisonOfNewEnterprisesBarChart
        {...commonCondition}
      />;
      break;
    case 99:
      res = <PatentComparisonBarChart
        {...commonCondition}
      />;
      break;
    case 100:
      res = <IndustryPatentDistribution
        {...commonCondition}
      />;
      break;
    case 101:
      res = <ComparisonOfNumberResearchInstitutionsBarChart
        {...commonCondition}
      />;
      break;
    case 102:
      res = <ResearchTypeDiff
        {...commonCondition}
      />;
      break;
    case 103:
      res = <IndustryDistribution
        {...commonCondition}
      />;
      break;
    case 104:
      res = <AreaGrowAndRatioBarAndLineChart
        {...commonCondition}
      />;
      break;
    case 105:
      res = <CompositionOfRegionalGDPBarChart
        {...commonCondition}
      />;
      break;
    case 106:
      res = <ChangesInPerCapitaGDPBarChart
        {...commonCondition}
      />;
      break;
    case 107:
      res = <ChangeOfAverageRegionalGDPBarChart
        {...commonCondition}
      />;
      break;
    case 108:
      res = <RevenueAndExpenditureGeneralPublicBudgetBarChart
        {...commonCondition}
      />;
      break;
    case 109:
      res = <ChangesTotalRetailSalesSocialConsumerGoods
        {...commonCondition}
      />;
      break;
    case 111:
      res = <PatentChartpatentNumberGroupCompanyFenxi
        {...commonCondition}
      />;
      break;
    case 112:
      res = <CompanyChartqueueListOfListedCompaniesFenxi
        {...commonCondition}
      />;
      break;
    case 113:
      res = <InvestmentChartearlyStageFundingListFenxi
        {...commonCondition}
      />;
      break;
    case 114:
      res = <PerCapitaGDPBarchart
        {...commonCondition}
      />;
      break;
    case 115:
      res = <HeadEnterprisesCount
        {...commonCondition}
      />;
      break;
    case 116:
      res = <HighTechEnterprise
        {...commonCondition}
      />;
      break;
    case 117:
      res = <InvestAndFinancingMoneyDistribute
        {...commonCondition}
      />;
      break;
    case 118:
      res = <InvestAndFinancingDistribute
        {...commonCondition}
      />;
      break;
    case 119:
      res = <ParkChartcomparisonOfParkQuantityFenxi
        {...commonCondition}
      />;
      break;
    case 120:
      res = <IndustryLoaderDistribute
        {...commonCondition}
      />;
      break;
    case 121:
      res = <PatentChartauthorizedInventionPatentCityFenxi
        {...commonCondition}
      />;
      break;
    case 122:
      res = <ResearchAreaTypeDistribute
        {...commonCondition}
      />;
      break;
    case 123:
      res = <ResearchTypeDistribute
        {...commonCondition}
      />;
      break;
    case 124:
      res = <IndustryAreaTypeDistribute
        {...commonCondition}
      />;
      break;
    case 125:
      res = <HeaderCompanyTop5
        {...commonCondition}
      />;
      break;
    case 126:
      res = <IndustryLoaderTypeDistribute
        {...commonCondition}
      />;
      break;
    case 127:
      res = <CompanyChartheaderCompanyTypeDistributionFenxi
        {...commonCondition}
      />;
      break;
    case 128:
      res = <CompanyChartcompanyCountBenchmarkingFenxi
        {...commonCondition}
      />;
      break;
    case 129:
      res = <CompanyChartindustryCompanyNumberFenxi
        {...commonCondition}
      />;
      break;
    case 130:
      res = <PatentChartnewNumberOfPatentsBenchmarkingFenxi
        {...commonCondition}
      />;
      break;
    case 131:
      res = <PatentChartnewIndustryPatentNumberFenxi
        {...commonCondition}
      />;
      break;
    case 132:
      res = <CompanyChartHandlercompanyNumChangeFenxi
        {...commonCondition}
      />;
      break;
    case 133:
      res = <EnterpriseAndindustryDistribute
        {...commonCondition}
      />;
      break;
    case 134:
      res = <PatentChartHandlerpatentApplyChangeFenxi
        {...commonCondition}
      />;
      break;
    case 135:
      res = <PatentChartHandlerpatentGrantChangeFenxi
        {...commonCondition}
      />;
      break;
    case 136:
      res = <AuthorizedAndInventionDistribute
        {...commonCondition}
      />;
      break;
    case 137:
      res = <PatentChartnewRectangularTreeGraphPatentIndustryFenxi
        {...commonCondition}
      />;
      break;
    case 138:
      res = <PatentChartnewAuthorizedInventionPatentCityFenxi
        {...commonCondition}
      />;
      break;
    case 139:
      res = <InstitutionChartnationalLevelInstitutionIndustryAreaFenxi
        {...commonCondition}
      />;
      break;
    case 140:
      res = <InvestmentChartchangesFinancingEventsNumberFenxi
        {...commonCondition}
      />;
      break;
    case 141:
      res = <InvestmentChartchangesFinancingEventsMoneyFenxi
        {...commonCondition}
      />;
      break;
    case 142:
      res = <AverageFinancingChange
        {...commonCondition}
      />;
      break;
    case 143:
      res = <IndustryDistributionHorizontalBarChart
        {...commonCondition}
      />;
      break;
    case 144:
      res = <IndustryMoneyDistributionHorizontalBarChart
        {...commonCondition}
      />;
      break;
    case 145:
      res = <CompanyChartpyramidFigureMarketFenxi
        {...commonCondition}
      />;
      break;
    case 146:
      res = <CompanyChartHandlerenterpriseGeospatialDistributionFenxi
        {...commonCondition}
      />;
      break;
    case 147:
      res = <CompanyChartindustrialDistributionCompanyFenxi
        {...commonCondition}
      />;
      break;
    case 148:
      res = <PatentChartindustrialDistributionPatentFenxi
        {...commonCondition}
      />;
      break;
    case 149:
      res = <PatentChartnewIndustrialDistributionPatentFenxi
        {...commonCondition}
      />;
      break;
    case 150:
      res = <CarrierChartindustrialDistributionFenxi
        {...commonCondition}
      />;
      break;
    case 151:
      res = <IndustryChartindustryLinkFenxi
        {...commonCondition}
      />;
      break;
    case 152:
      res = <CompanyChartcompanyNumberTopTableFenxi
        {...commonCondition}
      />;
      break;
    case 153:
      res = <CompanyChartcurveGraphMarketSumFenxi
        {...commonCondition}
      />;
      break;
    case 154:
      res = <CompanyChartnewCompanyWaterfallFenxi
        {...commonCondition}
      />;
      break;
    case 155:
      res = <CompanyChartnewCompanyNumberRankingFenxi
        {...commonCondition}
      />;
      break;
    case 157:
      res = <CompanyChartenterpriseEstablishmentTimeStructureChartFenxi
        {...commonCondition}
      />;
      break;
    case 158:
      res = <PatentChartnumberOfAuthorizedInventionPatentsFenxi
        {...commonCondition}
      />;
      break;
    case 159:
      res = <StandardChartnumberOfAuthorizedInventionPatentsFenxi
        {...commonCondition}
      />;
      break;
    case 160:
      res = <InstitutionChartresearchOrganizationStructureChartFenxi
        {...commonCondition}
      />;
      break;
    case 161:
      res = <PolicyChartpolicyQuantityChangeFenxi
        {...commonCondition}
      />;
      break;
    case 162:
      res = <CompanyChartchangesNumberExistingEnterprisesFenxi
        {...commonCondition}
      />;
      break;
    case 163:
      res = <PatentChartnewAuthorizedInventionPatentCountFenxi
        {...commonCondition}
      />;
      break;
    case 164:
      res = <PolicyChartregionalDistributionOfPolicyQuantityFenxi
        {...commonCondition}
      />;
      break;
    case 165:
      res = <PatentChartnewAuthorizedInventionPatentTopNumberFenxi
        {...commonCondition}
      />;
      break;
    case 166:
      res = <PatentChartauthorizedInventionPatentKeywordCloudFenxi
        {...commonCondition}
      />;
      break;
    case 167:
      res = <InstitutionChartinstitutionCountDistributionDiagramFenxi
        {...commonCondition}
      />;
      break;
    case 168:
      res = <ParkChartparkCountDistributionDiagramFenxi
        {...commonCondition}
      />;
      break;
    case 169:
      res = <InvestmentChartinvestmentCountDistributionDiagramFenxi
        {...commonCondition}
      />;
      break;
    case 170:
      res = <InvestmentChartinvestmentMoneyDistributionDiagramFenxi
        {...commonCondition}
      />;
      break;
    case 171:
      res = <InvestmentChartearlyInvestmentMoneyListTOP10Fenxi
        {...commonCondition}
      />;
      break;
    case 172:
      res = <InvestmentChartonlyFundingInvestmentMoneyListTOP10Fenxi
        {...commonCondition}
      />;
      break;
    case 173:
      res = <CompanyChartindustrialPyramidFenxi
        {...commonCondition}
      />;
      break;
    case 174:
      res = <PatentChartpatentAreaThermodynamicChartFenxi
        {...commonCondition}
      />;
      break;
    case 175:
      res = <StandardChartstandardDraftingUnitTopFenxi
        {...commonCondition}
      />;
      break;
    case 176:
      res = <PatentChartinventionPatentUnitCooperationFenxi
        {...commonCondition}
      />;
      break;
    case 177:
      res = <StandardChartstandardUnitCooperationFenxi
        {...commonCondition}
      />;
      break;
    case 178:
      res = <CompanyChartcompanyStockMapFenxi {...commonCondition} />;
      break;
    case 179:
      res = <CompanyChartcompanyStockStrikeTableFenxi {...commonCondition} />;
      break;
    case 180:
      res = <CommonChartcompanyBranchInvesteeGraph {...commonCondition} />;
      break;
    case 181:
      res = <CommonChartcompanyBranchInvesteeTop3TableGraph {...commonCondition} />;
      break;
    case 182:
      res = <PatentChartinnovationTechnologyPenetrationFenxi {...commonCondition} />;
      break;
    case 183:
      res = <PatentChartcompanyPatentKeywordCloud {...commonCondition} />;
      break;
    case 184:
      res = <CommonChartcompanyGrowUpActiveCurveFenxi {...commonCondition} />;
      break;
    case 185:
      res = <CommonChartgrowthProcessFenxi {...commonCondition} />;
      break;
    case 186:
      res = <CommonChartmultiDimensionalComprehensiveIndustryGraph {...commonCondition} />;
      break;
    case 187:
      res = <CommonChartindustryRegisteredCapitalTop20 {...commonCondition} />;
      break;
    case 188:
      res = <CompanyChartcompanyQualificationLabelsFenxi {...commonCondition} />;
      break;
    case 189:
      res = <CompanyChartcompanyCertificationCurveGraph {...commonCondition} />;
      break;
    case 190:
      res = <StandardChartcompanyStandardsCountFenxi {...commonCondition} />;
      break;
    case 191:
      res = <StandardChartparticipateDraftingStandardsFenxi {...commonCondition} />;
      break;
    case 192:
      res = <PatentChartpatentValuesGraph {...commonCondition} />;
      break;
    case 193:
      res = <PatentChartinventPatentTimeSeriesFenxi {...commonCondition} />;
      break;
    case 194:
      res = <PatentChartpatentTypeGraphFenxi {...commonCondition} />;
      break;
    case 195:
      res = <PatentChartpatentTypeIPCCategoricalDistributionGraph {...commonCondition} />;
      break;
    case 196:
      res = <PatentCharthomdogyPatentGlobalDistribution {...commonCondition} />;
      break;
    case 197:
      res = <PatentChartrankOfPatentInventorsFenxi {...commonCondition} />;
      break;
    case 198:
      res = <InstitutionChartresearchInstitutionLevelTypeGraph {...commonCondition} />;
      break;
    case 199:
      res = <PatentChartcooperativePatentApplicationNetworkFenxi {...commonCondition} />;
      break;
    case 200:
      res = <PatentChartdistributionOfPatentTypesFenxi {...commonCondition} />;
      break;
    case 201:
      res = <StandardChartcompanyStandardDraftingIssueNetworks {...commonCondition} />;
      break;
    case 202:
      res = <StandardChartdistributionOfStandardTypesFenxi {...commonCondition} />;
      break;
    case 203:
      res = <PatentChartdetailsNewPatentsGrantedFenxi {...commonCondition} />;
      break;
    case 204:
      res = <StandardChartcompanyStandardDraftingIssue {...commonCondition} />;
      break;
    case 205:
      res = <CompanyChartmarketCompanyBusinessCircumstanceGraph {...commonCondition} />;
      break;
    case 206:
      res = <CompanyChartvaluationOfListedEnterprisesFenxi {...commonCondition} />;
      break;
    case 207:
      res = <InvestmentChartcompanyInvestmentExperience {...commonCondition} />;
      break;
    case 208:
      res = <CompetitionPatternMindMapchart {...commonCondition} />;
      break;
    case 209:
      res = <CommonChartgrowthRateCurvesOfAllDimensionsFenxi {...commonCondition} />;
      break;
    case 210:
      res = <CompnayDetailInfoChart {...commonCondition} />;
      break;
    case 213:
      res = <CompanyChartCompanyAreaThermodynamicChartFenxi {...commonCondition} />;
      break;
    case 214:
      res = <CompnayChartChartListOfInvestmentManagersFenxi {...commonCondition} />;
      break;
    case 215:
      res = <IndustryCharTpanoramaIndustrialChain {...commonCondition} />;
      break;
    case 216:
      res = <IndustryChartBasicIndustrialChain {...commonCondition} />;
      break;
    case 217:
      res = <CompnayChartDistributionOfIndustrialLinksFenxi {...commonCondition} />;
      break;
    case 218:
      res = <CompanyChartMarketEnterprisesTop30 {...commonCondition} />;
      break;
    case 219:
      res = <CommonChartPatentRankTOP30Fenxi {...commonCondition} />;
      break;
    case 220:
      res = <CompanyChartRegisteredCapitalTop30 {...commonCondition} />;
      break;
    case 221:
      res = <CompanyChartFinancingAmountTop30 {...commonCondition} />;
      break;
    case 222:
      res = <CompanyChartBTHEnterprisesOddScoreTop30 {...commonCondition} />;
      break;
    case 223:
      res = <CompanyChartYRDEnterprisesOddScoreTop30 {...commonCondition} />;
      break;
    case 224:
      res = <CompanyChartPREnterprisesTop30OddScore {...commonCondition} />;
      break;
    case 225:
      res = <CompanyChartListOfKeyRecommendedEnterprisesForInvestmentPromotion
        {...commonCondition}
      />;
      break;
    case 226:
      res = <CompanyChartInvestmentUnitsTop30 {...commonCondition} />;
      break;
    case 227:
      res = <CompanyChartRegisteredCapitalOfUnlistedEnterprisesTop30 {...commonCondition} />;
      break;
    case 228:
      res = <CompanyChartUnlistedCompaniesRankedTop30InPatents {...commonCondition} />;
      break;
    case 229:
      res = <CompanyChartPatentCooperationAmongUnlistedEnterprisesTop30 {...commonCondition} />;
      break;
    case 230:
      res = <CompanyChartUnlistedEnterprisesNationalLabelTop30 {...commonCondition} />;
      break;
    case 231:
      res = <CompanyChartUnlistedEnterprisesFinancingAmountTop30 {...commonCondition} />;
      break;
    case 232:
      res = <CompanyChartUnlistedEnterprisesEarlyRiskFinancingSpeedTop30 {...commonCondition} />;
      break;
    case 233:
      res = <CompanyChartInvestmentByKeyInstitutions {...commonCondition} />;
      break;
    case 234:
      res = <CompanyChartTop30QualityShareholdersOfUnlistedEnterprises {...commonCondition} />;
      break;
    case 235:
      res = <CompanyChartInvestmentFocusRecommendedEnterprises
        {...commonCondition}
      />;
      break;
    case 236:
      res = <CompanyChartCompanyAreaThermodynamicChartSpecialization {...commonCondition} />;
      break;
    case 237:
      res = <CompnayChartCompanyDistributedVariousIndustriesSpecialization {...commonCondition} />;
      break;
    case 238:
      res = <CompnayChartIndustrialDistributionCompanySpecialization {...commonCondition} />;
      break;
    case 239:
      res = <CompanyChartCompanyCapitalDistributeSpecialization {...commonCondition} />;
      break;
    case 240:
      res = <CompanyChartDateOfEstablishmentSpecialization {...commonCondition} />;
      break;
    case 241:
      res = <CompanyChartPyramidFigureMarketSpecialization {...commonCondition} />;
      break;
    case 242:
      res = <CompanyChartRegisteredCapitalOfUnlistedEnterprisesTop30Specialization
        {...commonCondition}
        />;
      break;
    case 243:
      res = <PatentChartPatentApplyChangeSpecialization {...commonCondition} />;
      break;
    case 244:
      res = <PatentChartPatentGrantChangeSpecialization {...commonCondition} />;
      break;
    case 245:
      res = <PatentChartDistributionOfPatentIndustrySpecialization
        {...commonCondition}
      />;
      break;
    case 246:
      res = <PatentChartNumberOfPatentsBenchmarkingSpecialization
        {...commonCondition}
      />;
      break;
    case 247:
      res = <CompanyChartMobilityOfCapitalPyramidSpecialization {...commonCondition} />;
      break;
    case 248:
      res = <CompanyChartGazelleCompanyDistributionSpecialization {...commonCondition} />;
      break;
    case 249:
      res = <CompanyChartNationalHighTechGazelleCompanyRegCapitalTop {...commonCondition} />;
      break;
    case 250:
      res = <CommonChartPatentStandardRankSpecialization {...commonCondition} />;
      break;
    case 251:
      res = <CompanyChartAvgInvestHistoricalDaysSpecialization {...commonCondition} />;
      break;
    case 252:
      res = <MacroEconomyChartdiyTagCompanyDistributeFenxi {...commonCondition} />;
      break;
    case 253:
      res = <CompanyChartChineseUnicornCompanyRegCapitalTop {...commonCondition} />;
      break;
    case 254:
      res = <CompanyStatisticalChartDiyTagCompanyStandardFenxi {...commonCondition} />;
      break;
    case 255:
      res = <CompanyStatisticalChartDiyTagNotMarketCompanyStandardFenxi {...commonCondition} />;
      break;
    case 256:
      res = <CompanyStatisticalChartAfterCroundInvestmentMoneyTop100 {...commonCondition} />;
      break;
    case 257:
      res = <InvestmentChartInvestMarketCompanyTop100 {...commonCondition} />;
      break;
    case 258:
      res = <CompanyStatisticalChartPatentNumberSpecialization {...commonCondition} />;
      break;
    case 259:
      res = <CompanyStatisticalChartUnlistedEnterprisePatentNumberSpecialization
        {...commonCondition}
        />;
      break;
    case 260:
      res = <PatentChartInventionPatentUnitCooperationSpecialization {...commonCondition} />;
      break;
    case 261:
      res = <StandardChartStandardUnitCooperationSpecialization {...commonCondition} />;
      break;
    case 262:
      res = <InvestmentChartInvestmentCountBarChartSpecialization {...commonCondition} />;
      break;
    case 263:
      res = <InvestmentChartInvestmentMoneyBarChartSpecialization {...commonCondition} />;
      break;
    case 264:
      res = <InvestmentChartInvestmentIndustryCountBarChartSpecialization {...commonCondition} />;
      break;
    case 265:
      res = <InvestmentChartInvestmentIndustryMoneyBarChartSpecialization {...commonCondition} />;
      break;
    case 266:
      res = <CompanyChartEnterpriseRegionalDistributionSpecialization {...commonCondition} />;
      break;
    case 267:
      res = <CompanyChartCompanyRegisteredCapitalTop100Specialization {...commonCondition} />;
      break;
    case 268:
      res = <PatentChartDistributionAuthorizedPatentTypesSpecialization {...commonCondition} />;
      break;
    case 269:
      res = <PatentChartDistributionOfPatentIndustryTreeSpecialization {...commonCondition} />;
      break;
    case 270:
      res = <CompanyStatisticalChartAuthorizedPatentsTop100Specialization {...commonCondition} />;
      break;
    case 271:
      res = <CompanyStatisticalChartUnlistedAuthorizedPatentsTop100Specialization
        {...commonCondition} />;
      break;
    case 272:
      res = <CompanyStatisticalChartAuthorizedInventionPatentTop100Specialization
        {...commonCondition} />;
      break;
    case 273:
      res = <PatentChartLatestAuthorizedInventionPatentDetailsTop100 {...commonCondition} />;
      break;
    case 274:
      res = <CompanyStatisticalChartDraftStandardTOP100Specialization {...commonCondition} />;
      break;
    case 275:
      res = <CompanyStatisticalChartUnlistedDraftStandardTOP100Specialization
        {...commonCondition} />;
      break;
    case 276:
      res = <StandardChartLatestStandardDetailsTop100Specialization {...commonCondition} />;
      break;
    case 277:
      res = <InvestmentChartChangesFinancingEventsNumberSpecialization {...commonCondition} />;
      break;
    case 278:
      res = <InvestmentChartChangesFinancingEventsMoneySpecialization {...commonCondition} />;
      break;
    case 279:
      res = <InvestmentChartCurveGraphInvestmentAvgSpecialization {...commonCondition} />;
      break;
    case 280:
      res = <InvestmentChartChangeNumberOfFinancingEventsSpecialization {...commonCondition} />;
      break;
    case 281:
      res = <InvestmentChartRoundDistributionFinancingEventsSpecialization {...commonCondition} />;
      break;
    case 282:
      res = <InvestmentChartListedCompanyFinancingAndAvgFinancingSpecialization
        {...commonCondition} />;
      break;
    case 283:
      res = <InvestmentChartUnlistedCompanyFinancingAndAvgFinancingSpecialization
        {...commonCondition} />;
      break;
    case 284:
      res = <InvestmentChartFinancingRoundDistributionSpecialization {...commonCondition} />;
      break;
    case 285:
      res = <InvestmentChartRegionalDistributionFinancingSpecialization {...commonCondition} />;
      break;
    case 286:
      res = <InvestmentChartDistributionFinancingIndustrySpecialization {...commonCondition} />;
      break;
    case 287:
      res = <CompanyStatisticalChartAmountOfFinancingObtainedSpecialization {...commonCondition} />;
      break;
    case 288:
      res = <CompanyStatisticalChartUnlistedAmountOfFinancingObtainedSpecialization
        {...commonCondition} />;
      break;
    case 289:
      res = <CompanyStatisticalChartTop100FinancingSpeedUnlistedEnterprisesSpecialization
        {...commonCondition} />;
      break;
    case 290:
      res = <InvestmentChartLatestFinancingEventDetailsTop100
        {...commonCondition} />;
      break;
    case 291:
      res = <StandardChartStandardTypeDistributionSpecialization
        {...commonCondition} />;
      break;
    case 292:
      res = <CompanyChartUnlistedNationalHighTechGazelleCompanyRegCapitalTop
        {...commonCondition} />;
      break;
    case 293:
      res = <CompanyChartUnlistedGazelleCompanyDistributionSpecialization
        {...commonCondition} />;
      break;
    case 294:
      res = <CompanyChartSubdivisionDistribution
        {...commonCondition} />;
      break;
    case 295:
      res = <CompnayChartSubdivideLinkRegionalDistribution
        {...commonCondition} />;
      break;
    case 296:
      res = <PatentChartAuthorizedInventionPatentedTechnology
        {...commonCondition} />;
      break;
    case 297:
      res = <StandardChartStandardVariationTrend
        {...commonCondition} />;
      break;
    case 298:
      res = <StandardChartDraftStandardRegionalDistribution
        {...commonCondition} />;
      break;
    case 299:
      res = <PatentChartFindPatentList {...commonCondition} />;
      break;
    default: break;
  }
  return res;
}

function AnalysisEngineCharts(props) {
  const { chartMsg, createModelData, refList, editChartParam, hidden } = props;

  return (
    <div className={style.all}>
      {chartMsg.map((item, index) => {
        const { uid, title } = item;
        return (
          <ChartBorder
            title={title || ''}
            hidden={hidden}
            onRemove={typeof createModelData === 'function' ? () => createModelData('index', { index }) : undefined}
            onEdit={typeof editChartParam === 'function' ? () => editChartParam(item, index) : undefined}
            chartIndex={index}
            key={uid ? `${uid}chartItem` : `${index}chartItem`}
            chartMsg={item}
            modalChildren={getChart(item, index)}
          >
            {item ? getChart(item, index, refList) : ''}
          </ChartBorder>
        );
      })}
    </div>
  );
}

export default AnalysisEngineCharts;
