import React, { useReducer } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom'; // Redirect
import { Helmet } from 'react-helmet';
import BasicLayout from './layout/basic';
import { Context, menus } from '../../utils/context';

const DataAnalysisTool = () => {
  const initValue = {
    username: '',
    uid: '',
    menus,
    key: '',
    key2: '',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'setState':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(reducer, initValue);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <HashRouter>
        <Helmet>
          <meta charSet="utf-8" />
          <title>产业通 - 百条产业链一键查询、百万份报告一键生成，服务行研、招商与投资</title>
        </Helmet>
        <Switch>
          <Route path="/" component={BasicLayout} />
        </Switch>
      </HashRouter>
    </Context.Provider>);
};

export default DataAnalysisTool;
