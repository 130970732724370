import { openNewPage } from '../utils/urlMethods';
export const jumpCompantDetail = async (id) => {
  openNewPage('companyDetail', {
    id: id,
  });
};
export const jumpIndustry = async (type, id) => {
  openNewPage('industry', {
    type,
    id,
  });
};
export const jumpPatentDetail = async (id) => {
  openNewPage('patentDetail', {
    id: id,
  });
};
export const jumpInstitutionDetail = async (id) => {
  openNewPage('institutionDetail', {
    id: id,
  });
};