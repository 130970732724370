import React, { useState, useMemo } from 'react';
import { Table } from '@sqke/design';
import Container from '../../Container';
import Barandline from '../../../../Charts/BarAndLine';
import {
  useGetResearchInputChartQuery,
  useGetResearchInputTableQuery,
} from '../../../../../service/companyDetail';
import style from './index.module.less';// import TableNoData from '../../../TableNoData';

function ResearchInput(props) {
  const { companyId } = props;
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const { data: tableData, isFetching: isTableTableFetching } = useGetResearchInputTableQuery({
    company_id: companyId,
    page_size: 10,
    page_number: 1,
  }, {
    skip: !companyId,
  });
  const { data: chartData, isFetching: isChartTableFetching } = useGetResearchInputChartQuery({
    company_id: companyId,
    page_size: 10,
    page_number: 1,
  }, {
    skip: !companyId,
  });
  const columns = () => tableData?.columns.map(item => {
    if (item.dataIndex === 'index') {
      return {
        width: 70,
        ...item,
      };
    }
    return item;
  });

  const tabelEl = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return (
        <Table
          rowKey="index"
          dataSource={tableData?.dataSource}
          columns={columns()}
          title={() => tableData?.title}
          strip
          loading={isTableTableFetching}
          pagination={{
            hideOnSinglePage: true,
            current: page.pageNumber,
            pageSize: page.pageSize,
            total: tableData?.auth_count,
            showSizeChanger: false,
            showQuickJumper: true,
            responsive: true,
            showLessItems: true,
          }}
          onChange={({ current, pageSize }) => {
            setPage({
              pageSize,
              pageNumber: current,
            });
          }}
        />
      );
    }
    return null;
  }, [tableData]);
  const chart = useMemo(() => {
    if (!chartData || chartData?.series?.length > 0) {
      const newSeries = chartData?.series.map(item => {
        if (item.name === '研发投入占比') {
          return {
            ...item,
            type: 'line',
          };
        }
        return item;
      });
      return (
        <Barandline data={{ ...chartData, series: newSeries, right: 28, left: 60, bottom: 74 }} contentTop={46} isFetching={isChartTableFetching} />
      );
    }
    return <></>;
  }, [chartData]);
  return (
    <Container id='研发投入'>
      <div className={style.box}>
        <div>
          {
            chart
          }
        </div>
        <div>
          {tabelEl}
        </div>
      </div>

    </Container>
  );
}

export default ResearchInput;
