/*
*Description: HeapMapRectangle业务组件
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import NormalChart from '../../BasicsCompoment/NormalChart';
import { post } from '../../utils/request';
import { handleWithIPList, isFunction } from '../../utils/tool';
import { BusinessNormalChartProps } from '../businessType';
import '../../index.less';

// 类名前缀
const prefixCls: string = 'cube-component';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleTitle = classNames(`${prefixCls}-title`);
const styleChart = classNames(`${prefixCls}-chart`);
const styleNoTitleChart = classNames(`${prefixCls}-no-title`);
const styleSpin = classNames(`${prefixCls}-spin`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect } = React;

function BusinessNormalChart(props: BusinessNormalChartProps) {
  const {
    mode, ip, conditions,
    onRequestComplete, title, componmentKey,
    imgRef,
  } = props;

  const [chartTitle, setChartTitle] = useState<string>('');
  const [httpLoading, setHttpLoading] = useState(true);
  const [chartDesc, setChartDesc] = useState<any>({
    before_describe_value_dict: {},
    after_describe_value_dict: {},
  });

  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (ip) {
      const newIp = handleWithIPList(ip);
      post(`${newIp}/proxyChart/common_chart`, {
        chart_route: componmentKey.class_name,
        params: {
          command: `/${componmentKey.function_key}`,
          ...conditions,
        },

      }).then(res => {
        if (res.status === '00000') {
          const resData = res.data;
          if (resData.data) {
            const callBack = typeof props.adaperDataWithStyle === 'function' ? props.adaperDataWithStyle : (
              _arg: any,
            ) => ({
              containerStyle: {},
              values: [],
            });
            setData(callBack(resData));
          }
          setChartTitle(res.data.title);
          setChartDesc({
            before_describe_value_dict: (res.data && res.data.before_describe_value_dict) || {},
            after_describe_value_dict: (res.data && res.data.after_describe_value_dict) || {},
          });
        }
        if (isFunction(onRequestComplete)) {
         onRequestComplete!(res.data);
        }
      }).catch(_reason => {
        console.log(_reason);
        if (isFunction(onRequestComplete)) {
          onRequestComplete!('failed');
        }
      }).finally(() => {
        setHttpLoading(false);
      });
    }
  }, [ip, conditions, title, componmentKey.class_name,
    componmentKey.function_key, onRequestComplete,
  ]);

  return (!httpLoading ? (
    <div className={styleMap[mode!] || styleDark} style={{ overflowY: 'scroll' }}>
      {title && <div className={styleTitle}>{typeof title === 'string' ? title : chartTitle}</div>}
      <div className={title ? styleChart : styleNoTitleChart}>
        <NormalChart
          title={typeof title === 'string' ? title : chartTitle}
          {...{
            data,
            mode,
            chartDesc,
            ref: imgRef,
          }}
        />
      </div>
    </div>) : (
      <div className={styleSpin}>
        <Spin tip="加载中..." />
      </div>
  ));
}
BusinessNormalChart.defaultProps = {
  mode: 'light',
};

type commonStyleGeneratorParams = {
  colGap: Array<number>,
  titleStyle: Array<any>,
  colStyle: Array<any>,
  titleNoWrap?: Array<number>,
  minRows?: number,
  noSplit?: boolean,
  preHandleData?: (data: any) => void,
}
function commonStyleGenerator({
  colGap, titleStyle, colStyle,
  titleNoWrap, minRows, preHandleData,
  noSplit,
}:commonStyleGeneratorParams) {
  return function adaperDataWithStyle(resData: {
    data: { columns: any[]; dataSource: any[]; }
  }) {
    if (typeof preHandleData === 'function') {
      preHandleData(resData);
    }
    const handledData: any[][] = [];
    const titles = [];
    let beforeEndColumn = 1;

    // eslint-disable-next-line no-return-assign
    const allColumn = colGap.reduce((prev, item) => (prev += item));
    // title 表头 获取 并获取列的顺序
    const orderKey = [];
    for (let i = 0, L = resData.data.columns.length; i < L; i += 1) {
      const item = resData.data.columns[i];
      const curColStart = beforeEndColumn;
      const curColEnd = curColStart + colGap[i];
      beforeEndColumn = curColEnd;
      titles.push({
        // 四个字换个行
        text: item.title.length > 4 && !noSplit ? item.title.split('').reduce((
          prev: string, str: string, index: number, arr: any,
        ) => {
          let count = 4;
          if (titleNoWrap?.[i]) {
            count = 0;
          }
          if (index && (index + 1) === count && index !== arr.length - 1) {
            prev += (`${str}<br />`);
          } else {
            prev += (str);
          }
          return prev;
        }, '') : item.title,
        style: {
          gridColumnStart: curColStart,
          gridColumnEnd: curColEnd,
          gridRowStart: 1,
          gridRowEnd: 2,
          ...titleStyle[i],
        },
      });
      orderKey.push(item.dataIndex);
    }
    handledData.push(titles);
    const rowStyle = [
      {
        background: '#EFF6FF',
      },
      {
        background: '#fff',
      },
    ];
    // resData.data.dataSource.length = 1;
    for (let i = 0, L = resData.data.dataSource.length; i < L; i += 1) {
      const item = resData.data.dataSource[i];
      const temp: any[] = [];
      let curBeforeEndColumn = 1;
      orderKey.forEach((key, col) => {
        const curColStart = curBeforeEndColumn;
        const curColEnd = curColStart + colGap[col];
        curBeforeEndColumn = curColEnd;
        temp.push({
          text: item[key],
          style: {
            gridColumnStart: curColStart,
            gridColumnEnd: curColEnd,
            gridRowStart: i + 2,
            gridRowEnd: i + 3,
            ...colStyle[col],
            ...rowStyle[1 - (i % 2)],
          },
        });
      });
      handledData.push(temp);
    }
    let row = handledData.length;
    if (typeof minRows === 'number') {
      row = row > minRows ? row : minRows;
    }
    return {
      containerStyle: {
        gridTemplateColumns: `repeat(${allColumn}, 1fr)`,
        gridTemplateRows: `auto repeat(${row},1fr)`,
      },
      values: handledData,
    };
  };
}
// 34. 注册资本TOP10头部企业情况
export function CompanyChartTOP10EnterprisesWithRegisteredCapitalFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'TOP10_enterprises_with_registered_capital_fenxi',
    class_name: '/chart/company',
  };
  const colGap = [1, 3, 2, 3, 2];
  const titleStyle = [
    {},
    {
      justifyContent: 'left',
    },
    {
      justifyContent: 'center',
    },
    {},
    {
      justifyContent: 'left',
    },
    {
      justifyContent: 'left',
    },
  ];
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      color: '#3E5C76',
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
    },
    {
      color: '#919191',
      fontWeight: 'bold',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.6666666666666666)', // 控制字体大小
      flex: `0 0 ${(1 / 0.6666666666666666) * 100}%`,
      textAlign: 'left',
      justifyContent: 'center',
    },
    {
      color: '#3E5C76',
      fontWeight: 'bold',
      textAlign: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 10,
  });
  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 41. 授权发明专利数量TOP20企业榜单
export function PatentChartpatentNumberGroupCompanyFenxi(props: BusinessNormalChartProps) {
  const componmentKey = {
    function_key: 'patent_number_group_company_fenxi',
    class_name: '/chart/patent',
  };
  function adaperDataWithStyle(resData: {
    data: { columns: any[]; dataSource: any[]; }
  }) {
    const handledData: any[][] = [];
    const titles = [];
    let beforeEndColumn = 1;
    const colGap = [2, 3, 3, 2, 3, 3];
    const orderKey = ['index', 'name', 'patent_num', 'index1', 'name1', 'patent_num1'];
    // 列序
    const titleStyle = [
      {},
      {
        justifyContent: 'left',
      },
      {
      },
      {},
      {
        justifyContent: 'left',
      },
      {
      },
    ];
    // eslint-disable-next-line no-return-assign
    const allColumn = colGap.reduce((prev, item) => (prev += item));
    // 双标头 复制一份原来的
    resData.data.columns.push(...resData.data.columns);
    // title 表头 获取
    for (let i = 0, L = resData.data.columns.length; i < L; i += 1) {
      const item = resData.data.columns[i];
      const curColStart = beforeEndColumn;
      const curColEnd = curColStart + colGap[i];
      beforeEndColumn = curColEnd;
      titles.push({
        // 四个字换个行
        text: item.title.length > 4 ? item.title.split('').reduce((
          prev: string, str: string, index: number, arr: any,
        ) => {
          if (index && (index + 1) % 4 === 0 && index !== arr.length - 1) {
            prev += (`${str}<br />`);
          } else {
            prev += (str);
          }
          return prev;
        }, '') : item.title,
        style: {
          gridColumnStart: curColStart,
          gridColumnEnd: curColEnd,
          gridRowStart: 1,
          gridRowEnd: 2,
          ...titleStyle[i],
        },
      });
      // orderKey.push(item.dataIndex);
    }
    handledData.push(titles);
    // 列序
    const colStyle = [
      {
        color: '#919191',
      },
      {
        flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
        transform: 'scale(0.8333333333333334)',
        lineHeight: '12px',
        color: '#333333',
        letterSpacing: '1px',
      },
      {
        color: '#3E5C76',
        fontWeight: 'bold',
        marginRight: '3px',
        flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
        transform: 'scale(0.8333333333333334)',
        textAlign: 'center',
      },
      {
        color: '#919191',
        fontWeight: 'bold',
        transform: 'scale(0.8333333333333334)',
        flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
        marginLeft: '3px',
        justifyContent: 'center',
        textAlign: 'center',
      },
      {
        justifyContent: 'center',
        flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
        transform: 'scale(0.8333333333333334)',
        lineHeight: '12px',
        color: '#333333',
        letterSpacing: '1px',
        textAlign: 'left',
      },
      {
        color: '#3E5C76',
        fontWeight: 'bold',
        flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
        transform: 'scale(0.8333333333333334)',
        textAlign: 'center',
      },
    ];
    const rowStyle = [
      {
        background: '#EFF6FF',
      },
      {
        background: '#fff',
      },
    ];
    // resData.data.dataSource.length = 2;
    const secondTable = resData.data.dataSource.splice(
      Math.floor(resData.data.dataSource.length / 2),
    );
    resData.data.dataSource.forEach((item, index: number) => {
      item.index1 = secondTable[index].index;
      item.name1 = secondTable[index].name;
      item.patent_num1 = secondTable[index].patent_num;
    });
    for (let i = 0, L = resData.data.dataSource.length; i < L; i += 1) {
      const item = resData.data.dataSource[i];
      const temp: any[] = [];

      let curBeforeEndColumn = 1;
      orderKey.forEach((key, col) => {
        const curColStart = curBeforeEndColumn;
        const curColEnd = curColStart + colGap[col];
        curBeforeEndColumn = curColEnd;
        temp.push({
          text: item[key],
          style: {
            gridColumnStart: curColStart,
            gridColumnEnd: curColEnd,
            gridRowStart: i + 2,
            gridRowEnd: i + 3,
            ...colStyle[col],
            ...rowStyle[1 - (i % 2)],
          },
        });
      });
      handledData.push(temp);
    }
    const rows = handledData.length - 1 < 10 ? 10 : handledData.length - 1;
    return {
      containerStyle: {
        gridTemplateColumns: `repeat(${allColumn}, 1fr)`,
        gridTemplateRows: `auto repeat(${rows},1fr)`,
      },
      values: handledData,
    };
  }
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 50. 排队上市企业列表
export function CompanyChartqueueListOfListedCompaniesFenxi(props: BusinessNormalChartProps) {
  const componmentKey = {
    function_key: 'queue_list_of_listed_companies_fenxi',
    class_name: '/chart/company',
  };
  const colGap = [3, 1, 3, 4, 1, 3];
  // 列序
  const titleStyle = [
    {},
    {
    },
    {
    },
    {},
    {
      justifyContent: 'left',
    },
    {
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      paddingLeft: '11%',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
      textAlign: 'center',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    },
    {
      color: '#919191',
      fontWeight: 'bold',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    },
    {
      justifyContent: 'left',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 51. 早期融资列表
export function InvestmentChartearlyStageFundingListFenxi(props: BusinessNormalChartProps) {
  const componmentKey = {
    function_key: 'early_stage_funding_list_fenxi',
    class_name: '/chart/investment',
  };
  const colGap = [1, 4, 2, 2, 1, 4];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'left',
    },
    {
    },
    {},
    {
      justifyContent: 'left',
    },
    {
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      color: '#919191',
    },
    {
      justifyContent: 'left',
      transform: 'scale(0.8333333333333334) translateX(-10.6%)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 4 . 152--企业数量TOP表
export function CompanyChartcompanyNumberTopTableFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'company_number_top_table_fenxi',
    class_name: '/chart/company',
  };
  const colGap = [1, 1, 1, 1, 3];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'left',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    // fontWeight: 'bold',
    },
    {
      color: '#919191',
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334) translateX(-10.6%)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });

  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 17 . --相关标准单位TOP表
export function StandardChartstandardDraftingUnitTopFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'standard_drafting_unit_top_fenxi',
    class_name: '/chart/standard',
  };

  function adaperDataWithStyle(resData: {
    data: { columns: any[]; dataSource: any[]; }
  }) {
    const handledData: any[][] = [];
    const titles: any[] = [];
    // let beforeEndColumn = 1;
    const colGap = [1, 4, 1, 1, 1, 1];
    // 列的间隔
    const colTitleGap = [1, 4, [
      [4],
      [1, 1, 1, 1],
    ]];
    const commonStyleOfSubTitle = {
      background: '#4E7293',
      padding: '4px 9px',
      whiteSpace: 'normal',
    };
    // 列序
    const titleStyle = [
      {},
      {
        justifyContent: 'left',
        marginRight: 2,
      },
      [
        [{
        }],
        [
          {
            ...commonStyleOfSubTitle,
          },
          {
            ...commonStyleOfSubTitle,
          },
          {
            ...commonStyleOfSubTitle,
          },
          {
            ...commonStyleOfSubTitle,
          },
        ],
      ],
    ];
    const orderKey: any[] = []; // 列的key获取
    // eslint-disable-next-line no-return-assign
    const allColumn = colGap.reduce((prev, item) => (prev += item));
    function getTittle(
      row: Array<any>,
      startRow: number,
      startCol: number,
      gapArray: Array<any>,
      styleArr: Array<any>,
      _depth = 0, // 列
    ) {
      for (let i = 0, L = row.length; i < L; i += 1) {
        const item = row[i];
        if (Array.isArray(item)) {
          getTittle(item, i + 1, startCol, gapArray[i], styleArr[i], _depth + 1);
          // eslint-disable-next-line no-continue
          continue;
        }
        const curColStart = startCol;
        const curColEnd = curColStart + gapArray[i];
        startCol = curColEnd;
        titles.push({
          // 四个字换个行
          text: item.title.length > 4 ? item.title.split('').reduce((
            prev: string, str: string, index: number, arr: any,
          ) => {
            if (index && ((index + 1) === 4) && index !== arr.length - 1) {
              prev += (`${str}<br />`);
            } else {
              prev += (str);
            }
            return prev;
          }, '') : item.title,
          style: {
            gridColumnStart: curColStart,
            gridColumnEnd: curColEnd,
            gridRowStart: startRow,
            gridRowEnd: startRow + (!_depth ? 2 : 1), // 第一层2行 其他一行
            ...styleArr[i],
          },
        });
        if (item.dataIndex) {
          orderKey.push(item.dataIndex);
        }
      }
    }
    // title 表头 获取
    if (!Array.isArray(resData.data.columns[2][0])) { // 先处理成数组 后续跟后端说一下
      resData.data.columns[2][0] = [resData.data.columns[2][0]];
    }
    getTittle(resData.data.columns, 1, 1, colTitleGap, titleStyle);
    handledData.push(titles);
    // 列序
    const colStyle = [
      {
        color: '#919191',
      },
      {
        flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
        marginRight: 2,
        transform: 'scale(0.8333333333333334)',
        lineHeight: '12px',
        color: '#333333',
        letterSpacing: '1px',
      },
      {
        justifyContent: 'center',
        transform: 'scale(0.8333333333333334) translateX(-10.6%)',
        lineHeight: '12px',
        marginRight: '2px',
        color: '#3E5C76',
        letterSpacing: '1px',
      },
      {
        justifyContent: 'center',
        transform: 'scale(0.8333333333333334) translateX(-10.6%)',
        lineHeight: '12px',
        color: '#3E5C76',
        letterSpacing: '1px',
      },
      {
        justifyContent: 'center',
        transform: 'scale(0.8333333333333334) translateX(-10.6%)',
        lineHeight: '12px',
        margin: '0 2px',
        color: '#3E5C76',
        letterSpacing: '1px',
      },
      {
        justifyContent: 'center',
        transform: 'scale(0.8333333333333334) translateX(-10.6%)',
        lineHeight: '12px',
        color: '#3E5C76',
        letterSpacing: '1px',
      },
    ];
    const rowStyle = [
      {
        background: '#EFF6FF',
      },
      {
        background: '#fff',
      },
    ];

    for (let i = 0, L = resData.data.dataSource.length; i < L; i += 1) {
      const item = resData.data.dataSource[i];
      const temp: any[] = [];

      let curBeforeEndColumn = 1;
      orderKey.forEach((key, col) => {
        const curColStart = curBeforeEndColumn;
        const curColEnd = curColStart + colGap[col];
        curBeforeEndColumn = curColEnd;
        temp.push({
          text: item[key],
          style: {
            gridColumnStart: curColStart,
            gridColumnEnd: curColEnd,
            gridRowStart: i + 3,
            gridRowEnd: i + 4,
            ...colStyle[col],
            ...rowStyle[(i % 2)],
          },
        });
      });
      handledData.push(temp);
    }
    return {
      containerStyle: {
        gridTemplateColumns: `repeat(${allColumn}, 1fr)`,
        gridTemplateRows: `auto repeat(${handledData.length},1fr)`,
      },
      values: handledData,
    };
  }

  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 34 . 12--授权发明专利数量表
export function PatentChartnumberOfAuthorizedInventionPatentsFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'number_of_authorized_invention_patents_fenxi',
    class_name: '/chart/patent',
  };
  const colGap = [1, 2, 2, 1];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
    },
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      textAlign: 'center',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      color: '#919191',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    titleNoWrap: [0, 0, 0, 1],
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 14 . --
export function PatentChartnewAuthorizedInventionPatentTopNumberFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'new_authorized_invention_patent_top_number_fenxi',
    class_name: '/chart/patent',
  };
  const colGap = [1, 2, 2];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      textAlign: 'center',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}

    />);
}

// 23 . --早期融资金额TOP事件表
export function InvestmentChartearlyInvestmentMoneyListTOP10Fenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'early_investment_money_list_TOP10_fenxi',
    class_name: '/chart/investment',
  };
  const colGap = [1, 3, 2, 2, 1, 3];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'left',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}

    />);
}

// 24 . --股权融资金额TOP事件表
export function InvestmentChartonlyFundingInvestmentMoneyListTOP10Fenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'only_funding_investment_money_list_TOP10_fenxi',
    class_name: '/chart/investment',
  };
  const colGap = [1, 3, 2, 2, 1, 3];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'left',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}

    />);
}

// 22 . --授权发明专利数量TOP20发明人
export function PatentChartrankOfPatentInventorsFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'rank_of_patent_inventors_fenxi',
    class_name: '/chart/patent',
  };
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      fontWeight: 'bold',
      marginRight: '3px',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      textAlign: 'center',
    },
    {
      color: '#919191',
      fontWeight: 'bold',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      marginLeft: '3px',
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      justifyContent: 'center',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
      textAlign: 'left',
    },
    {
      color: '#3E5C76',
      fontWeight: 'bold',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      textAlign: 'center',
    },
  ];
  const colGap = [2, 2, 3, 2, 2, 3];
  const titleStyle = [
    {},
    {
      justifyContent: 'left',
    },
    {
    },
    {},
    {
      justifyContent: 'left',
    },
    {
    },
  ];
  function preHandleData(source: any) {
    const { data } = source;
    // 双标头 复制一份原来的
    const originKeys: string[] = [];
    const copyied: { title: any; dataIndex: string; }[] = [];
    data.columns.forEach((item: any) => {
      originKeys.push(item.dataIndex);
      copyied.push({
        title: item.title,
        dataIndex: `${item.dataIndex}1`,
      });
    });
    data.columns.push(...copyied);
    const secondTable = data.dataSource.splice(
      Math.floor(data.dataSource.length / 2),
    );
    data.dataSource.forEach((item: any, index: number) => {
      originKeys.forEach((key: string) => {
        item[`${key}1`] = secondTable[index][key];
      });
    });
  }
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    preHandleData,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 28 . --最新授权专利详情
export function PatentChartdetailsNewPatentsGrantedFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'details_new_patents_granted_fenxi',
    class_name: '/chart/patent',
  };
  const colGap = [1, 2, 3, 2];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'left',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 6 . --对外投资/分支机构数量TOP3省份分布情况
export function CommonChartcompanyBranchInvesteeTop3TableGraph(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'company_branch_investee_top3_table_graph',
    class_name: '/chart/company',
  };
  const colGap = [1, 2, 2, 2, 3];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });

  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 12 . --行业注册资本TOP20企业授权发明专利数量及融资金额明细
export function CommonChartindustryRegisteredCapitalTop20(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'industry_registered_capital_top_10',
    class_name: '/chart/common',
  };

  const colGap = [3, 2, 1];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      paddingLeft: 10,
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  // function preHandleData(source: any) {
  //   const { data } = source;
  //   // 双标头 复制一份原来的
  //   const originKeys: string[] = [];
  //   const copyied: { title: any; dataIndex: string; }[] = [];
  //   data.columns.forEach((item: any) => {
  //     originKeys.push(item.dataIndex);
  //     copyied.push({
  //       title: item.title,
  //       dataIndex: `${item.dataIndex}1`,
  //     });
  //   });
  //   data.columns.push(...copyied);
  //   const secondTable = data.dataSource.splice(
  //     Math.floor(data.dataSource.length / 2),
  //   );
  //   data.dataSource.forEach((item: any, index: number) => {
  //     originKeys.forEach((key: string) => {
  //       item[`${key}1`] = secondTable[index][key];
  //     });
  //   });
  // }
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    // preHandleData,
  });
  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 21 . --同族专利及全球布局
export function PatentCharthomdogyPatentGlobalDistribution(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'homdogy_patent_global_distribution',
    class_name: '/chart/patent',
  };
  const colGap = [3, 2, 2, 3, 2];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'left',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 23 . --创建研究机构详情
export function InstitutionChartresearchInstitutionLevelTypeGraph(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'research_institution_level_type_graph',
    class_name: '/chart/institution',
  };

  const colGap = [1, 2, 3, 2, 2, 2];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
      justifyContent: 'center',

    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const titleNoWrap = [];
  titleNoWrap[2] = 1;
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    titleNoWrap,
  });

  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 29 . --最新起草标准详情
export function StandardChartcompanyStandardDraftingIssue(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'company_standard_drafting_issue',
    class_name: '/chart/standard',
  };

  const colGap = [1, 2, 3, 2];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'left',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 2 . 179--对外投资企业数量
export function CompanyChartcompanyStockStrikeTableFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'company_stock_strike_table_fenxi',
    class_name: '/chart/company',
  };
  const colGap = [1, 1];
  // 列序
  const titleStyle = [
    {},
    {
      justifyContent: 'center',
    },
    {
    },
    {},
    {
      justifyContent: 'center',
    },
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'left',
    // fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    // fontWeight: 'bold',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 招商长名单
export function CompnayChartChartListOfInvestmentManagersFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'list_of_investment_managers_fenxi',
    class_name: '/chart/company',
  };
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      // lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
      paddingRight: '2px',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    },
    {
      color: '#919191',
      fontWeight: 'bold',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      marginLeft: '3px',
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      justifyContent: 'center',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      // lineHeight: '12px',
      color: '#333333',
      letterSpacing: '1px',
      textAlign: 'left',
      paddingRight: '2px',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
    },
  ];
  const colGap = [1, 4, 4, 1, 4, 4];
  const titleStyle = [
    {},
    {
      justifyContent: 'left',
    },
    {
      justifyContent: 'left',
    },
    {},
    {
      justifyContent: 'left',
    },
    {
      justifyContent: 'left',
    },
  ];
  function preHandleData(source: any) {
    const { data } = source;
    // 双标头 复制一份原来的
    const originKeys: string[] = [];
    const copyied: { title: any; dataIndex: string; }[] = [];
    data.columns.forEach((item: any) => {
      originKeys.push(item.dataIndex);
      copyied.push({
        title: item.title,
        dataIndex: `${item.dataIndex}1`,
      });
    });
    data.columns.push(...copyied);
    const secondTable = data.dataSource.splice(
      Math.floor(data.dataSource.length / 2),
    );
    data.dataSource.forEach((item: any, index: number) => {
      originKeys.forEach((key: string) => {
        item[`${key}1`] = secondTable[index][key];
      });
    });
  }
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    preHandleData,
    colStyle,
  });
  return (
    <BusinessNormalChart
      {...props}
      componmentKey={componmentKey}
      adaperDataWithStyle={adaperDataWithStyle}
    />);
}

// 上市企业Top30
export function CompanyChartMarketEnterprisesTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'market_enterprises_top_30',
    class_name: '/chart/company',
  };

  const colGap = [1, 4, 3, 2, 2, 2, 2, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      paddingLeft: '2px',
      textAlign: 'left',
    },
    {
      justifyContent: 'center',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      lineHeight: '12px',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',

    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 注册资金Top30
export function CompanyChartRegisteredCapitalTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'registered_capital_Top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 3, 5, 2, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {
      justifyContent: 'left',
    },
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'left',
      paddingLeft: '2px',
      color: '#919191',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 专利排名Top30
export function CommonChartPatentRankTOP30Fenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'patent_rank_TOP30_fenxi',
    class_name: '/chart/common',
  };

  const colGap = [1, 5, 2, 3, 4, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 融资金额Top30
export function CompanyChartFinancingAmountTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'financing_amount_Top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 3, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 京津冀企业上奇评分Top30
export function CompanyChartBTHEnterprisesOddScoreTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'beijing_tianjin_hebei_enterprises_odd_score_top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 长三角企业上奇评分Top30
export function CompanyChartYRDEnterprisesOddScoreTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'yangtze_river_delta_enterprises_odd_score_top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 珠三角企业上奇评分Top30
export function CompanyChartPREnterprisesTop30OddScore(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'pearl_river_delta_enterprises_top30_odd_score',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 招商重点推荐企业列表
export function CompanyChartListOfKeyRecommendedEnterprisesForInvestmentPromotion(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'list_of_key_recommended_enterprises_for_investment_promotion',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 3, 2, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 投资单位Top30
export function CompanyChartInvestmentUnitsTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'investment_units_top30',
    class_name: '/chart/company',
  };

  const colGap = [2, 5, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市企业注册资金Top30
export function CompanyChartRegisteredCapitalOfUnlistedEnterprisesTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'registered_capital_of_unlisted_enterprises_top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 3, 4, 2, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'left',
      color: '#919191',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市企业专利排名Top30
export function CompanyChartUnlistedCompaniesRankedTop30InPatents(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_companies_ranked_top30_in_patents',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市企业专利合作Top30
export function CompanyChartPatentCooperationAmongUnlistedEnterprisesTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'patent_cooperation_among_unlisted_enterprises_top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市企业国家级标签Top30
export function CompanyChartUnlistedEnterprisesNationalLabelTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_enterprises_national_label_top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 3, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市企业优质股东Top30
export function CompanyChartTop30QualityShareholdersOfUnlistedEnterprises(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'top30_quality_shareholders_of_unlisted_enterprises',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 5, 3, 2, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市企业融资金额Top30
export function CompanyChartUnlistedEnterprisesFinancingAmountTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_enterprises_financing_amount_top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 3, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市企业早期风险融资速度Top30
export function CompanyChartUnlistedEnterprisesEarlyRiskFinancingSpeedTop30(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_enterprises_early_risk_financing_speed_top30',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 2, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {
      whiteSpace: 'break-spaces',
    },
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#333',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 获重点机构投资
export function CompanyChartInvestmentByKeyInstitutions(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'investment_by_key_institutions',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 3, 2, 3, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 投资重点推荐企业
export function CompanyChartInvestmentFocusRecommendedEnterprises(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'investment_focus_recommended_enterprises',
    class_name: '/chart/company',
  };

  const colGap = [1, 5, 2, 3, 2, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业非上市企业注册资本TOP30
export function CompanyChartRegisteredCapitalOfUnlistedEnterprisesTop30Specialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'registered_capital_of_unlisted_enterprises_top30_specialization',
    class_name: '/chart/company',
  };

  const colGap = [1, 6, 3, 4];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业区域分布
export function MacroEconomyChartdiyTagCompanyDistributeFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'diy_tag_company_distribute_fenxi',
    class_name: '/chart/macroEconomy',
  };

  const colGap = [1, 3, 3, 2, 4];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 获“国家高新区瞪羚企业”标签企业注册资金Top100
export function CompanyChartNationalHighTechGazelleCompanyRegCapitalTop(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'national_high_tech_gazelle_company_reg_capital_top',
    class_name: '/chart/company',
  };

  const colGap = [1, 6, 2, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 获“中国独角兽企业”标签企业名单
export function CompanyChartChineseUnicornCompanyRegCapitalTop(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'chinese_unicorn_company_reg_capital_top',
    class_name: '/chart/company',
  };

  const colGap = [1, 6, 2, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#919191',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级啊专精特新起草国家级标准数量TOP100
export function CompanyStatisticalChartDiyTagCompanyStandardFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'diy_tag_company_standard_fenxi',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新非上市企业起草国家标准Top100
export function CompanyStatisticalChartDiyTagNotMarketCompanyStandardFenxi(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'diy_tag_not_market_company_standard_fenxi',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业C轮及以后非上市企业融资金额Top100
export function CompanyStatisticalChartAfterCroundInvestmentMoneyTop100(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'after_c_round_investment_money_top_100',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 在上市企业数量最多的Top100投资方
export function InvestmentChartInvestMarketCompanyTop100(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'invest_market_company_top_100',
    class_name: '/chart/investment',
  };

  const colGap = [1, 4, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业授权发明专利TOP100
export function CompanyStatisticalChartPatentNumberSpecialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'patent_number_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业非上市企业授权发明专利TOP100
export function CompanyStatisticalChartUnlistedEnterprisePatentNumberSpecialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_enterprise_patent_number_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业注册资金TOP100
export function CompanyChartCompanyRegisteredCapitalTop100Specialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'company_registered_capital_top100_specialization',
    class_name: '/chart/company',
  };

  const colGap = [1, 6, 3];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业授权专利TOP100
export function CompanyStatisticalChartAuthorizedPatentsTop100Specialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'authorized_patents_top100_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市国家级专精特新企业授权专利TOP100
export function CompanyStatisticalChartUnlistedAuthorizedPatentsTop100Specialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_authorized_patents_top100_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业授权发明专利Top100
export function CompanyStatisticalChartAuthorizedInventionPatentTop100Specialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'authorized_invention_patent_Top100_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 1, 1, 1, 1];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业最新100条授权发明专利详情
export function PatentChartLatestAuthorizedInventionPatentDetailsTop100(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'latest_authorized_invention_patent_details_top100',
    class_name: '/chart/patent',
  };

  const colGap = [1, 4, 3, 3, 3, 1, 3, 3, 5, 4];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      color: '#333',
      fontWeight: 'bold',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业起草标椎Top100
export function CompanyStatisticalChartDraftStandardTOP100Specialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'draft_standard_TOP100_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市国家级专精特新企业起草标椎Top100
export function CompanyStatisticalChartUnlistedDraftStandardTOP100Specialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_draft_standard_TOP100_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业最新100条标椎详情
export function StandardChartLatestStandardDetailsTop100Specialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'latest_standard_details_top100_specialization',
    class_name: '/chart/standard',
  };

  const colGap = [1, 5, 3, 2, 2, 4, 7, 2, 4];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      justifyContent: 'center',
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业获融资金额Top100
export function CompanyStatisticalChartAmountOfFinancingObtainedSpecialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'amount_of_financing_obtained_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市国家级专精特新企业获融资金额Top100
export function CompanyStatisticalChartUnlistedAmountOfFinancingObtainedSpecialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_amount_of_financing_obtained_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 6, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市国家级专精特新企业各轮次融资企业融资速度Top30
export function CompanyStatisticalChartTop100FinancingSpeedUnlistedEnterprisesSpecialization(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'Top100_financing_speed_unlisted_enterprises_specialization',
    class_name: '/chart/companyStatistical',
  };

  const colGap = [1, 5, 2, 3, 3, 3, 4];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {
      whiteSpace: 'break-spaces',
    },
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      color: '#333',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 国家级专精特新企业最新100条融资事件详情
export function InvestmentChartLatestFinancingEventDetailsTop100(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'latest_financing_event_details_top100',
    class_name: '/chart/investment',
  };

  const colGap = [1, 5, 3, 3, 2, 3, 4, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#333',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'left',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 非上市国家高新区瞪羚企业注册资金Top100
export function CompanyChartUnlistedNationalHighTechGazelleCompanyRegCapitalTop(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'unlisted_national_high_tech_gazelle_company_reg_capital_top',
    class_name: '/chart/company',
  };

  const colGap = [1, 6, 3, 4];
  // 列序
  const titleStyle = [
    {},
    {},
    {},
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#919191',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}

// 专利TOP20
export function PatentChartFindPatentList(
  props: BusinessNormalChartProps,
) {
  const componmentKey = {
    function_key: 'find_patent_list',
    class_name: '/chart/patent',
  };

  const colGap = [1, 5, 2, 2, 3, 3, 3, 2, 2];
  // 列序
  const titleStyle = [
    {},
    {},
    {
      whiteSpace: 'break-spaces',
    },
    {
      whiteSpace: 'break-spaces',
    },
    {
      whiteSpace: 'break-spaces',
    },
    {
      whiteSpace: 'break-spaces',
    },
    {
      whiteSpace: 'break-spaces',
    },
    {
      whiteSpace: 'break-spaces',
    },
    {},
  ];
  // 列序
  const colStyle = [
    {
      color: '#919191',
    },
    {
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      transform: 'scale(0.8333333333333334)',
      lineHeight: '12px',
      color: '#333',
      textAlign: 'center',
      letterSpacing: '1px',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    {
      color: '#3E5C76',
      transform: 'scale(0.8333333333333334)',
      flex: `0 0 ${(1 / 0.8333333333333334) * 100}%`,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  ];
  const adaperDataWithStyle = commonStyleGenerator({
    colGap,
    titleStyle,
    colStyle,
    minRows: 6,
    noSplit: true,
  });

  return (
    <BusinessNormalChart
      {...props}
      adaperDataWithStyle={adaperDataWithStyle}
      componmentKey={componmentKey}
    />);
}
