import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import style from './index.module.less';
import QrCodeDesc from '../QrCodeDesc';

function EnterpriseLogin(props) {
  const { onFinish, isLoading, forgetPassword } = props;

  const [radioCheck, setRadioCheck] = useState(false);
  const [useAgreeCheck, setUserAgreeCheck] = useState(true);

  return (
    <div className={style.all}>
      <Form
        onFinish={(value) => {
          if (useAgreeCheck) {
            onFinish(value, radioCheck);
          } else {
            message.warning('请仔细阅读《用户协议》');
          }
        }}
      >
        <Form.Item
          label=""
          name="username"
          className="username"
          rules={[
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号',
            },
            {
              required: true,
              message: '请输入手机号码',
            },
          ]}
        >
          <Input placeholder="请输入手机号码" />
        </Form.Item>

        <Form.Item
          label=""
          name="password"
          className="password"
          rules={[
            {
              pattern: /^.{6,18}$/,
              message: '密码长度为6~18位！',
            },
            {
              required: true,
              message: '请输入密码',
            },
          ]}
        >
          <Input placeholder="请输入密码" type="password" />
        </Form.Item>

        <div className={style.loginFree}>
          <Checkbox checked={radioCheck} onChange={(e) => setRadioCheck(e.target.checked)} />
          &nbsp;
          <div className={style.checkBoxWord}>
            14天内免登录
          </div>
        </div>

        <Form.Item className="submit">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className={`${!useAgreeCheck ? style.unChecked : undefined}`}
          >
            登录
          </Button>
        </Form.Item>

        <div className={style.desc}>
          <QrCodeDesc
            useAgreeCheck={useAgreeCheck}
            setUserAgreeCheck={setUserAgreeCheck}
          />
        </div>

        <div className={style.bottom}>
          <div className={style.register} onClick={() => forgetPassword()}>
            忘记密码
          </div>
        </div>
      </Form>
    </div>
  );
}

export default EnterpriseLogin;
