/**
 * 参演标准
 */
import React, { useEffect, useState, useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
// import ReactEcharts from 'echarts-for-react';
import Container from '../../../Container';
import { getStandardTypeDistribution } from '../../../../../../api/companyDetail';
// import ChartNoData from '../../../ChartNoData';
import style from './index.module.less';

const RectangularTreeGraph = ({ companyId }) => {
  // const treeMapEl = useRef(null);
  const [data, setData] = useState(null);
  const [text, setText] = useState('');
  useEffect(() => {
    getStandardTypeDistribution({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setText(res.data?.pool?.desc);
        setData(res.data?.series?.[0]?.data);
      }
    });
  }, []);

  const option = {
    color: ['#00A372', '#30DD7D', '#84F3B6', '#CFEFBC', '#F0FFA3', '#F7F289', '#FFDB92', '#FFC559', '#FFC58E', '#EE964B', '#FF9F81', '#FF5A17'],
    tooltip: {
      formatter: (params) => (
        `${params.marker}${params.name}\u3000\u3000${params.value}${params.data.unit}`
      ),
    },
    series: [
      {
        type: 'treemap',
        visibleMin: 30,
        childrenVisibleMin: 50,
        breadcrumb: { show: false },
        roam: true,
        data: data?.map(item => ({ ...item })),
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        minZoom: 0.2, // 设置最小缩放比例
      },
    ],
  };

  // eslint-disable-next-line consistent-return
  // useEffect(() => {
  //   console.log(treeMapEl);
  //   let chartInstance = null;
  //   if (treeMapEl.current) {
  //     chartInstance = treeMapEl.current.getEchartsInstance();
  //     const handleDataZoom = (params) => {
  //       console.log(89898);
  //       // 获取缩放比例
  //       const { start, end } = params.batch[0].dataZoom[0];
  //       const zoomScale = end - start;

  //       // 设置最小缩放比例
  //       const minZoomScale = 0.5;

  //       // 如果缩放比例小于最小值，恢复到最小允许的缩放比例
  //       if (zoomScale < minZoomScale) {
  //         const newZoomStart = end - minZoomScale;
  //         chartInstance.dispatchAction({
  //           type: 'dataZoom',
  //           start: newZoomStart,
  //           end,
  //         });
  //       }
  //     };
  //     // 监听缩放事件
  //     chartInstance.on('dataZoom', handleDataZoom);
  //     return () => {
  //       // 解绑事件
  //       chartInstance.off('dataZoom', handleDataZoom);
  //     };
  //   }
  // }, [treeMapEl.current]);

  const linChartEl = useMemo(() => {
    if (!data) return <div style={{ minHeight: '500px' }}><Spin /></div>;
    if (data.length > 0) {
      return (

        <>
          <Container id="参研标准">
            <div className={style.text}>
              {text}
            </div>
            <ECharts
              // ref={treeMapEl}
              // onEvents={(e) => { console.log(e); }}
              className={style.chart}
              option={option}
            />
          </Container>
        </>
      );
    }
    return null;
  }, [data]);
  return linChartEl;
};

export default RectangularTreeGraph;
