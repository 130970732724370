import React from 'react';
import style from './index.module.less';
import Barandline from '../../../../../components/Charts/BarAndLine';
import MoreBar from '../../../../../components/Charts/MoreBar';
import { useGetSocialDevelopmentQuery } from '../../../../../service/cityDetail';

const Develop = ({ title, cityCode }) => {
  const { data, isFetching } = useGetSocialDevelopmentQuery({
    area_key: cityCode,
  }, { skip: !cityCode });
  return (
    <div className={style.box} id={title}>
      <p className={style.title}>{title}</p>
      <ul>
        <li>
          <p className={style.chartTitle} >{data?.[0]?.title || ''}</p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[0],
                highlight: data?.[0]?.highlight[0],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[1]?.title || ''}</p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[1],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
              showLabel
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[2]?.title || ''}</p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[2],
                highlight: data?.[2]?.highlight[0],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[3]?.title || ''}</p>
          <div className={style.chart}>
            <MoreBar
              data={{
                ...data?.[3],
                highlight: data?.[3]?.highlight[0],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[4]?.title || ''}</p>
          <div className={style.chart}>
            <MoreBar
              data={{
                ...data?.[4],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
              showLabel
            />
          </div>
        </li>
        <li>
          <p className={style.chartTitle} >{data?.[5]?.title || ''}</p>
          <div className={style.chart}>
            <Barandline
              data={{
                ...data?.[5],
                highlight: data?.[5]?.highlight[0],
                leftYName: [0, 40, 0, 0],
              }}
              isFetching={isFetching}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Develop;
