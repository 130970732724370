/*
 *Author: zhaochenyu
 *Description: 我的关注列表复制移动弹窗
 *Date: 2021/08/06
*/

import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Checkbox, message, Spin } from 'antd';
import { getFollowDataGroupData } from '../../../api/mySelf';
import style from './index.module.less';

function MyselfModal(props) {
  const { visible, setVisible, leftTreeData, selectNode, selectTrunkNode, multi,
    handleAttetionId, addFollow, cancelFollowGroup, getCurTableData } = props;
  const [checked, setchecked] = useState([]);
  const [selectableList, setSelectableList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [otherSelectedName, setOtherSelectedName] = useState('');

  const handleWithChecked = useCallback(
    (e, checkList) => {
      // 通过multi去判断当前是复制弹窗/移动弹窗
      if (e.target.checked) {
        setchecked(multi ? [...checkList, e.target.value] : [e.target.value]);
      } else {
        setchecked(checkList.filter(item => item !== e.target.value));
      }
    },
    [],
  );

  const setNewFollow = useCallback(
    (curChecked) => {
      if (curChecked.length > 0 && multi) {
        // 复制
        addFollow({
          attention_data_type_name: selectTrunkNode,
          attention_data_id: handleAttetionId,
          attention_group_id_list: curChecked,
          is_empty_original_data: false,
        }).then(res => {
          if (res.status === '00000') {
            message.success('操作成功');
            setVisible(false);
            getCurTableData();
          }
        });
      } else if (curChecked.length > 0 && !multi) {
        // 移动 先取消关注再关注移动的分组
        cancelFollowGroup({
          attention_data_type_name: selectTrunkNode,
          attention_data_id: handleAttetionId,
          attention_group_id: selectNode,
        }).then(res => {
          if (res.status === '00000') {
            addFollow({
              attention_data_type_name: selectTrunkNode,
              attention_data_id: handleAttetionId,
              attention_group_id_list: curChecked,
              is_empty_original_data: false,
            }).then(res1 => {
              if (res1.status === '00000') {
                message.success('操作成功');
                setVisible(false);
                getCurTableData();
              }
            });
          }
        });
      }
    },
    [selectTrunkNode, selectNode, handleAttetionId, setVisible, getCurTableData],
  );

  useEffect(() => {
    setLoading(true);
    // 获取关注对象当前在所有分组中的关注情况
    getFollowDataGroupData({
      attention_data_type_name: selectTrunkNode,
      attention_data_id: handleAttetionId,
    }).then(res => {
      if (res.status === '00000') {
        const selectable = [];
        const selectedListName = [];
        res.data.group_list.forEach(item => {
          if (!item.is_check) {
            selectable.push({
              title: item.default_group_name,
              key: item.id,
            });
          }
          if (item.id !== selectNode && item.is_check) {
            selectedListName.push(item.default_group_name);
          }
        });
        setSelectableList(selectable);
        if (selectedListName.length > 0) setOtherSelectedName(selectedListName.join('、'));
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <Modal
      visible={visible}
      footer={null}
      maskClosable={false}
      wrapClassName={style.handleModal}
      onCancel={() => setVisible(false)}
    >
      <div className={style.title}>{multi ? '复制到' : '移动到'}</div>
      <div className={style.desc}>
        当前所在
        <span style={{ color: '#3E5C76', margin: '0 4px' }}>
          {leftTreeData.find(item => item.key === selectTrunkNode)
            .children.find(item => item.key === selectNode).title}
        </span>
        {`中，可将其${multi ? '复制' : '移动'}到以下分组`}
      </div>
      <Spin spinning={loading} tip="搜索中，请稍候.....">
        <div className={style.checkboxGroup}>
          {selectableList.map((item, index) => (
            <div key={`${index}${item.key}`} style={{ marginBottom: '10px' }}>
              <Checkbox
                checked={checked.includes(item.key)}
                value={item.key}
                onChange={(e) => handleWithChecked(e, checked)}
              >
                {item.title}
              </Checkbox>
            </div>
          ))}
        </div>
      </Spin>
      {otherSelectedName && multi && (
      <div className={style.desc} style={{ fontSize: '12px' }}>
        当前关注的对象也存在于
        <span style={{ color: '#3E5C76', margin: '0 4px' }}>{otherSelectedName}</span>
        中
      </div>
      )}
      <div className={style.btns}>
        <div
          className={`${style.determine} ${checked.length === 0 && style.noClick}`}
          onClick={() => setNewFollow(checked)}
        >
          确认
        </div>
        <div className={style.cancel} onClick={() => setVisible(false)}>取消</div>
      </div>
    </Modal>
  );
}

export default MyselfModal;
