/*
* Author: zhangmeng
* Description: '投资'
* Date: 2022/06/29
*/

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Button, Pagination, Spin } from 'antd';
import { useGetSearchFinancingListQuery } from '../../../../service/dataSearch';
import TabSelect from '../SelectorComp/TabSelect';
import MoneySection from '../SelectorComp/MoneySelect';
import InputTreeSelect from '../SelectorComp/InputTreeSelect';
import DateSelect from '../SelectorComp/DateSelect';
import SearchSortor from '../SearchSortor';
import InvestmentListLine from '../InvestmentListLine';
import style from './index.module.less';

const sortData1 = [
  {
    title: '曝光日期',
    key: 'announce_date',
  },
  {
    title: '交易金额',
    key: 'total_money',
  },
];
function InvestmentPage(props) {
  const { filter, styles, participle, hideTitle = false } = props;
  const {
    industryTree,
    areaTree,
    financing_round: financingRoundList,
  } = filter;
  const [financingRound, setFinancingRound] = useState(['不限']); // 交易轮次
  const [registeredCapital, setRegisteredCapital] = useState(['', '']); // 交易金额
  const [industry, setIndustry] = useState([]);
  const [area, setArea] = useState([]);
  const [date, setDate] = useState(['', '']); // 曝光日期
  const [sortKey, setSort] = useState({ key: '', count: 0 });
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isConfirm, setIsConfirm] = useState(false);

  /* eslint-disable no-nested-ternary */
  const params = sortKey.count === 0 ? {
    financing_round_list: financingRound.length === 1 ? (financingRound[0] === '不限' ? [] : financingRound) : financingRound,
    filtering_industry_list: industry,
    filtering_area_list: area,
    transaction_amount_list: registeredCapital,
    exposure_date_list: (date?.[0] !== '' && date?.[1] !== '') ? [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')] : [],
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
  } : {
    financing_round_list: financingRound.length === 1 ? (financingRound[0] === '不限' ? [] : financingRound) : financingRound,
    filtering_industry_list: industry,
    filtering_area_list: area,
    transaction_amount_list: registeredCapital,
    exposure_date_list: (date?.[0] !== '' && date?.[1] !== '') ? [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')] : [],
    page_size: pageSize,
    page_number: pageNum,
    keyword: participle.join(''),
    sort_column: sortKey.key,
    sort_order: sortKey.count === 1 ? 'asc' : 'desc',
  };
  const { data: list, isFetching } = useGetSearchFinancingListQuery(params, { skip: isConfirm });
  /* eslint-disable no-nested-ternary */

  useEffect(() => {
    if (!isFetching) {
      setIsConfirm(true);
    }
  }, [isFetching]);

  useEffect(() => {
    setIsConfirm(false);
  }, [participle]);

  const resetClick = () => {
    setFinancingRound(['不限']);
    setArea([]);
    setIndustry([]);
    setDate(['', '']);
    setRegisteredCapital(['', '']);
    setIsConfirm(true);
  };
  const confirmSearch = () => {
    setIsConfirm(false);
    setPageNum(1);
  };

  const changeSort = useCallback(
    (sort) => {
      setSort(sort);
      setIsConfirm(false);
    },
    [],
  );

  // pagination-onChange
  const paginationOnChange = (n, s) => {
    setPageNum(n);
    setPageSize(s);
    setIsConfirm(false);
  };
  return (
    <div className={style.all} style={styles}>
      <div className={style.topBox}>
        {
          !hideTitle && <div className={style.title}>投资</div>
        }
        <div className={style.selectorBox}>
          {
            useMemo(() => (
              <TabSelect
                title="交易轮次"
                data={financingRoundList}
                selected={financingRound}
                setSelected={p => {
                  setFinancingRound(p);
                  setIsConfirm(true);
                }}
              />
            ), [financingRoundList, financingRound])
          }
          {
            useMemo(() => (
              <InputTreeSelect
                title="区域"
                data={areaTree}
                select={area}
                setSelect={p => {
                  setArea(p);
                  setIsConfirm(true);
                }}
                ph="请选择区域"
              />
            ), [areaTree, area, setArea])
          }
          {
            useMemo(() => (
              <MoneySection
                title="交易金额"
                select={registeredCapital}
                setSelect={p => {
                  setRegisteredCapital(p);
                  setIsConfirm(true);
                }}
              />
            ), [registeredCapital, setRegisteredCapital])
          }
          {
            useMemo(() => (
              <InputTreeSelect
                title="产业"
                data={industryTree}
                select={industry}
                setSelect={p => {
                  setIndustry(p);
                  setIsConfirm(true);
                }}
                ph="请选择产业"
              />
            ), [industryTree, industry, setIndustry])
          }
          {
            useMemo(() => (
              <DateSelect
                title="曝光日期"
                select={date}
                setSelect={p => {
                  if (p) {
                    setDate(p);
                  } else {
                    setDate(['', '']);
                  }
                  setIsConfirm(true);
                }}
              />
            ), [date, setDate])
          }
          <div className={style.btnGroup}>
            <Button type="primary" ghost onClick={() => resetClick()}>
              重置
            </Button>
            &emsp;
            <Button type="primary" className="confirmSearch" onClick={() => confirmSearch()}>
              查询
            </Button>
          </div>
        </div>
      </div>
      <div className={style.listBox}>
        <Spin tip="搜索中，请稍候..." spinning={isFetching}>
          <SearchSortor
            exportType="exportSearchFinance"
            dataKey="search_investment"
            params={params}
            data={sortData1}
            sort={sortKey}
            total={list?.data_count || 0}
            onChange={changeSort}
          />
          <div className={style.list}>
            {
              list && list.data_list && list.data_list.length > 0 ? list.data_list.map((item, index) => (
                <InvestmentListLine key={index} record={item} participle={participle} />
              )) : <div style={{ textAlign: 'center', marginTop: '50px' }}>暂无数据</div>
            }
            <div className={style.pagination}>
              <Pagination
                showQuickJumper
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={pageSize}
                current={pageNum}
                total={list?.auth_count || 0}
                onChange={(page, pageSize1) => paginationOnChange(page, pageSize1)}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default InvestmentPage;
