/*
 *Author: zhangmeng
 *Description: 产业图谱-桑基图
 *Date: 2022/02/28
 */
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import html2canvas from 'html2canvas';
import { Spin } from 'antd';
import { colorList, firstLevelColor, noLinkColor } from './utils';
import backPng from '../../../../../components/IndustryCompanyDetail/CompanyContent/RelationshipPenetration/IndustrySector/IndustrySectorGraph/components/Tree/background.png';
import { mockData } from './mockdata';
import styles from './index.module.less';

const generateRight = (level) => {
  let right = -200;
  switch (level) {
    case 1:
      right = 100;
      break;
    case 2:
      right = -200;
      break;
    default:
      right = -level * 200;
      break;
  }
  return right;
};

// const generateLineValue = (level, chartLevel) => {
//   let lineValue = 30;
//   switch (level) {
//     case 1:
//       if (chartLevel === 0) {
//         lineValue = 1;
//       } else {
//         lineValue = 30;
//       }
//       break;
//     case 2:
//       if (chartLevel === 0) {
//         lineValue = 3;
//       } else {
//         lineValue = 10;
//       }
//       break;
//     case 3:
//       if (chartLevel === 0) {
//         lineValue = 5;
//       } else {
//         lineValue = 10;
//       }
//       break;
//     default:
//       if (chartLevel === 0) {
//         lineValue = 10;
//       } else {
//         lineValue = 10;
//       }
//       break;
//   }
//   return lineValue;
// };

function SankeyChart(props, ref) {
  const chartRef = useRef(null);
  const { data = [mockData], selectLevel, selectCompanyName, graphTitle, isFetching } = props;
  const [clickIndex, setClickIndex] = useState('');
  const name = [];
  const link = [];

  // 连线粗细
  const genernateLineValue = (level) => {
    if (data?.[0]?.max_level_count < 40) {
      return 10;
    }
    if (level === 0) {
      return 2;
    }
    return 5;
  };

  // 图谱高度
  const generateGraphHeight = () => {
    if (!data?.[0]?.max_level_count) {
      return '1000px';
    }
    if (data[0]?.max_level_count < 28) {
      return '1000px';
    }
    if (data[0].max_level_count < 40) {
      return `${data[0].max_level_count * 35}px`;
    }
    return `${data[0].max_level_count * 30}px`;
  };

  // 桑基图图形高亮
  const highlight = (item) => {
    if (item.need_high_light === 1) {
      return {
        color: '#1961F5',
        borderColor: '#80A9FF',
      };
    }
    return {
      color: item.color,
      borderColor: item.borderColor,
      // opacity: 0.1,
    };
  };

  // 桑基图标签高亮
  const highlightLabel = (item) => {
    if (item.need_high_light === 1) {
      return {
        color: '#1961F5',
        fontWeight: 600,
      };
    }
    if (item.level === 0) {
      return {
        color: '#0F2849',
        fontWeight: 600,
      };
    }
    return {
      ...item,
      color: '#333333',
    };
  };

  // 递归收集link所需数据
  function linkRecursion(params) {
    params.forEach((item) => {
      if (item?.children?.length) {
        item.children.forEach((item2, index2) => {
          if (item.chart_id === item2.chart_id) {
            return;
          }
          link.push({
            source: item.chart_id,
            target: item2.chart_id,
            id: index2,
            value: genernateLineValue(item.level),
            // value: item.level === 0 ? 2 : 5,
          });
        });
        linkRecursion(item.children);
      }
    });
  }

  // 递归收集图表data数据
  // eslint-disable-next-line no-shadow
  function dataRecursion(params, clickIndex) {
    params.forEach((item, index) => {
      if (name.findIndex(i => i.name === item.value) > -1) return;
      item.labelColor = '#333';
      if (item.level === 0) {
        item.color = firstLevelColor.color;
        item.borderColor = firstLevelColor.borderColor;
        item.labelColor = firstLevelColor.labelColor;
      } else if (item.level > 0 && item.level <= selectLevel) {
        item.color = '#1961F5';
        item.borderColor = '#80A9FF';
        item.labelColor = '#333333';
      } else {
        item.labelColor = '#333333';
      }
      const labelNameData = item.title;
      name.push({
        name: item.chart_id,
        title: item.title,
        // canClick: item.link_data_count > 0,
        labelName: labelNameData,
        label: {
          ...highlightLabel(item),
          formatter: () => item.title,
        },
        depth: item.level,
        itemStyle: highlight(item),
        value: 50,
      });
      if (item.children?.length) {
        dataRecursion(JSON.parse(JSON.stringify(item.children)), clickIndex);
      }
    });
  }

  const getOption = useMemo(() => {
    if (data?.length > 0) {
      dataRecursion(JSON.parse(JSON.stringify(data)), clickIndex);
      linkRecursion(data);
    }
    const option = {
      series: {
        type: 'sankey',
        layout: 'none',
        // width: 1366,
        nodeWidth: 11,
        animation: false,
        draggable: false,
        focusNodeAdjacency: 'allEdges',
        links: link,
        layoutIterations: 1024,
        top: 20,
        bottom: 20,
        left: 0,
        right: 140,
        // right: generateRight(selectLevel),
        label: {
          color: '#333333',
          fontSize: 12,
        },
        lineStyle: {
          curveness: 0.4,
          color: 'target',
        },
        data: name,
      },
    };
    return option;
  }, [data]);

  // 图表label事件
  // const onEvents = {
  //   click: (params) => {
  //     params.event.event.stopPropagation();
  //     const { data: data1, dataType } = params;
  //     if (dataType === 'node' && data1.itemStyle.color !== noLinkColor) {
  //       setClickIndex(params.name);
  //       // setClickColor('#00EBF8');
  //       dataRecursion(JSON.parse(JSON.stringify(data)), params.name);
  //       chartClick(data1);
  //     }
  //   },
  //   finished: (params) => {
  //     if (typeof onFinished === 'function') {
  //       onFinished(params);
  //     }
  //   },
  // };
  useImperativeHandle(ref, () => ({
    download: () => {
      // 获取 base64 图片
      const myChart = chartRef.current.echarts.getInstanceByDom(chartRef.current.ele);
      const bgImage = new Image();
      bgImage.src = backPng;
      bgImage.onload = () => {
        myChart.setOption({
          graphic: {
            elements: [{
              type: 'image',
              style: {
                image: bgImage,
                width: '100%',
                height: '100%',
              },
              left: 0,
              right: 0,
            }],
          },
        });
      };
      html2canvas(myChart.getDom()).then(canvas => {
        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = `${selectCompanyName}${graphTitle}`;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        document.body.removeChild(a);
      });
    },
  }));

  // useEffect(() => {
  //   const eleBox = document.getElementById('spinChart');
  //   if (selectLevel) {
  // eleBox.style.width = `${(selectLevel + 2) * 300 > 1366 ? (selectLevel + 2) * 300 : 1366}px`;
  //   }
  // }, [selectLevel]);
  return (
    <Spin spinning={isFetching} id='spinChart' wrapperClassName={styles.spinChart}>
      <ReactEcharts
        ref={chartRef}
        id='chartBox'
        option={getOption}
        className={styles.chart}
        // onEvents={onEvents}
        style={{ width: selectLevel > 2 ? `${(selectLevel - 2) * 400 + 1366}px` : '100%', height: generateGraphHeight() }}
      />
    </Spin>
  );
}

export default forwardRef(SankeyChart);
