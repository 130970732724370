/*
* Author: zhangmeng
* Description: '投资事件表格'
* Date: 2021/08/05
*/

import React, { useState } from 'react';
import { Table } from 'antd';
import PaginationComp from '../../Pagination';
import style from './index.module.less';

function InvestmentTableComp(props) {
  const { data, title, noPagination, size, getList } = props;
  const { count, columns, dataSource } = data;
  const [pageSize, setPageSize] = useState(10); // 默认选中10条/每页
  const [currPage, setCurrPage] = useState(1); // 当前页默认为1

  // 条/页 select-onChange
  const selectOnChange = (value) => {
    setPageSize(value);
    setCurrPage(1);
    getList({
      page_number: 1,
      page_size: value,
    });
  };

  // pagination-onChange
  const paginationOnChange = (page) => {
    setCurrPage(page);
    getList({
      page_number: page,
      page_size: size || pageSize,
    });
  };

  return (
    <div className={style.all}>
      <div className={style.title} style={{ display: title ? '' : 'none' }}>
        {title}
      </div>
      <Table
        rowKey="index"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <div className={style.pagination}>
        {
          count > 5
          && !noPagination
          && (
            <PaginationComp
              total={count > 100 ? 100 : count}
              currPage={currPage}
              pageSize={size || pageSize}
              paginationOnChange={paginationOnChange}
              selectOnChange={selectOnChange}
              isNeedPages={false}
            />
          )
        }
      </div>
    </div>
  );
}

export default InvestmentTableComp;
