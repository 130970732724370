/*
 *Author: zhaochenyu
 *Description: 撤销操作表格
 *Date: 2021/08/02
*/
import React, { useCallback } from 'react';
import { message } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import style from './index.module.less';

function RevokeTable(props) {
  const { data, setData, addFollow, selectTrunkNode, getCurTableData } = props;
  const refollow = useCallback(
    (index) => {
      const newData = cloneDeep(data);
      addFollow({
        attention_data_type_name: selectTrunkNode,
        attention_data_id: newData[index].attention_data_id,
        attention_group_id_list: [newData[index].group_id],
        is_empty_original_data: false,
      }).then(res => {
        if (res.status === '00000') {
          message.success('操作成功');
          newData.splice(index, 1);
          setData(newData);
          getCurTableData();
        }
      });
    },
    [data, setData, getCurTableData],
  );
  return (
    <div className={style.all}>
      <div className={style.title}>已取消关注</div>
      {data.map((item, index) => (
        <div className={style.row} key={`${index}revoke`}>
          {item.name && <div style={{ width: '50%' }} title={item.name}>{item.name}</div>}
          {item.groupName && <div style={{ width: '25%' }} title={item.groupName}>{item.groupName}</div>}
          {item.time && <div style={{ width: '25%' }} title={item.time}>{item.time}</div>}
          {item.operator && (
          <div style={{ width: '25%' }}>
            <span className={style.operator} onClick={() => refollow(index)}>{item.operator}</span>
          </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default RevokeTable;
