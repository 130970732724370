/*
 *Author: zhaochenyu
 *Description: 产业画像报告-封皮
 *Date: 2021/12/06
*/

import React from 'react';
import style from './index.module.less';

function FrontCover(props) {
  const { industry, area } = props;
  const myDate = new Date();
  // const getTitleSize = () => {
  //   if (!industry) return 68;
  //   let size = 68;
  //   if (industry.length < 7) {
  //     size = 68;
  //   } else if (industry.length < 9) {
  //     size = 56;
  //   } else if (industry.length < 12) {
  //     size = 48;
  //   } else {
  //     size = 40;
  //   }
  //   return size;
  // };
  return (
    <div className={style.all}>
      <div className={style.coverLogo} />
      <div className={style.bigTitle} />
      <div className={style.area}>{area}</div>
      <div
        className={style.industry}
      >
        {industry}
      </div>
      <div className={style.subTitle}>产业画像与分析报告</div>
      <div className={style.companyName}>
        {`${myDate.getFullYear()}年${myDate.getMonth() + 1}月`}
      </div>
    </div>
  );
}

export default FrontCover;
