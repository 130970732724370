import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
import { FilterIcon } from '../../../../components/CustomTableIcon';
import CustomListFilter from '../../../../components/CustomListFilter';
import { useGetInvestmentEventTableQuery, useGetChannelInvestmentRoundListQuery } from '../../../../service/industry-channel';
import styles from './index.module.less';

const MultiLineEllipsis = text => {
  const _text = Array.isArray(text) ? text.join('、') : text;
  return <div className="overflow-multi-5" title={_text}>{_text}</div>;
};

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  company_name: {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    ellipsis: true,
    className: styles.name,
  },
  total_money: {
    title: '融资(万元)',
    dataIndex: 'total_money',
    key: 'total_money',
    width: 86,
    ellipsis: true,
  },
  invest_date: {
    title: '融资时间',
    dataIndex: 'invest_date',
    key: 'invest_date',
    width: 86,
    ellipsis: true,
  },
  round: {
    title: '融资轮次',
    dataIndex: 'round',
    key: 'round',
    width: 86,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    render: MultiLineEllipsis,
  },
};

const COLUMN_KEYS = ['index', 'company_name', 'total_money', 'invest_date', 'round'];

function OrganCompanyTable(props) {
  const {
    industryCode,
    investorName,
    companyType,
    filteredInfo = {},
    linkCode,
    pageNumber = 1,
    pageSize = 20,
    onCompanyClick,
    getCount,
  } = props;

  const { data: round } = useGetChannelInvestmentRoundListQuery();

  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetInvestmentEventTableQuery({
    industry_code: industryCode,
    investor_name: investorName,
    company_type: companyType,
    round_list: (filteredInfo?.round && filteredInfo.round.filter(item => item !== 'all').length !== 0)
      ? filteredInfo.round : undefined,
    link_code: industryCode === linkCode ? '' : linkCode,
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode || !investorName,
  });

  const { columns: tableHead = [], datasource: tableBody, total = 0, count = 0 } = companyData ?? {};

  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      // 处理企业名称
      if (item.dataIndex === 'company_name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      if (item.dataIndex === 'round') {
        rowConfig.filters = round;
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filteredValue = filteredInfo.round?.length > 0 ? filteredInfo.round : ['all'];
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      return rowConfig;
    });
  }, [onCompanyClick, tableHead, filteredInfo]);

  useEffect(() => {
    getCount(count);
  }, [getCount, count]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
    />
  );
}

export default OrganCompanyTable;
