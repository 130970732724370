import { IGraphVisualConfig } from '../interface/config';
import { getDataItem, getFormatter, omitUndefined } from './utils';
import { ISeriesGetter, SeriesTypes } from '../interface/base';
import { merge } from 'lodash';

const colorList = [
  '#FDAD61', '#4582FF', '#84F3B6', '#70DFB6', '#FFC559',
  '#FF5A17', '#59D700', '#1961F5', '#DC77BC', '#6B9BFF',
  '#569EFF', '#00B572', '#F88C6B', '#7B61F0', '#FFE050',
  '#EE964B', '#22B6F1', '#B061F0', '#4E88FF', '#51C886',
];

const getEach = (nodeStyle: IGraphVisualConfig) => {
  const {
    symbol,
    symbolSize,
    symbolRotate,
    label,
    itemStyle,
    // line
    lineStyle,
    edgeLabel,
    edgeSymbol,
    edgeSymbolSize,
    autoCurveness,
  } = nodeStyle;

  return omitUndefined({
    symbol,
    symbolSize,
    symbolRotate,
    label,
    itemStyle,
    lineStyle,
    edgeSymbol,
    edgeSymbolSize,
    autoCurveness,
    edgeLabel,
  });
}

/**
 * 关系图
 * 1. 设置节点（及其符号）样式
 * 2. 设置节点文本样式
 * 3. 设置边（及其符号）样式
 * 4. 设置边文本样式
 * 5. 根据类别设置某类节点（及其符号）样式、节点文本样式
 */

export const getSeriesGraph: ISeriesGetter<IGraphVisualConfig> = (series, index, extra) => {
  const type: SeriesTypes = 'graph';
  const {
    material,
    getMatchedItemStyle,
  } = extra;
  const { config } = material;
  const { visualConfig } = config;
  const {
    series: seriesStyles,
    categories: categoryStyles,
    categoryMatchByName,
    roam,
    top,
    right,
    bottom,
    left,
    center,
    zoom,
    layout, // 'none' | 'circular' | 'force'
    circular,
    coordinateSystem,
    nodeScaleRatio,
    force,
    draggable,
  } = visualConfig as IGraphVisualConfig;
  const seriesStyle = seriesStyles ? seriesStyles[index] as IGraphVisualConfig: undefined;
  const { name, data = [] as any, links }  = series;
  const seriesProperty = {
    seriesName: name,
    seriesIndex: index,
  };
  const {
    categories,
  } = series;
  const seriesItem = {
    name,
    type,
    center,
    zoom,
    layout,
    circular,
    top,
    right,
    bottom,
    left,
    roam,
    nodeScaleRatio,
    force,
    draggable,
    coordinateSystem: coordinateSystem !== 'none' ? coordinateSystem : undefined,
    ...getEach(visualConfig as IGraphVisualConfig),
    categories: [] as any,
  };
  if (categoryMatchByName) {
    seriesItem.categories = categories?.map((category, index) => {
      const categoryStyle = categoryStyles
        ? (categoryStyles?.find(({ name }) => name === category))
        : undefined;

      if (!categoryStyle) {
        return { name: category };
      }

      return {
        ...getEach(categoryStyle),
        name: category,
      };
    })
  } else {
    seriesItem.categories = categories?.map((category, index) => {
      if (!categoryStyles || !categoryStyles[index]) {
        return { name: category };
      }
      return {
        ...categoryStyles[index],
        name: category,
      }
    });
  }

  // 简单关系图用
  const min = data?.[data?.length - 1]?.value;
  const max = data?.[0]?.value;
  const range = (max - min) / 100;

  const seriesData = data.map((d: any, index: number) => {
    const item = getDataItem(d) as any;
    const _d = {
      ...seriesProperty,
      type,
      ...item,
      index,
    };
    // TODO: 简单关系图,应该可以通过下面的jsonLogic实现,暂时先这样来
    if (layout === 'force') {
      return ({
        ...item,        
        symbolSize: 60 + (item?.value / range),
        itemStyle: {
          shadowBlur: 10,
          shadowColor: colorList[index % colorList.length],
          color: colorList[index % colorList.length],
        },
        label: {
          show: true,
          textBorderWidth: 1,
          textBorderColor: colorList[index % colorList.length],
          textShadowBlur: 2,
          textShadowColor: colorList[index % colorList.length],
          color: '#fff',
        },
      });
    }

    const matcherStyle = getMatchedItemStyle(_d) as IGraphVisualConfig;

    if (Object.keys(matcherStyle).length === 0) {
      return d;
    }
    return ({
      ...item,
      ...getEach(matcherStyle),
    });
  });
  return omitUndefined(
    merge(
      seriesItem,
      { data: seriesData, links },
      seriesStyle ? getEach(seriesStyle) : {},
    )
  );
}
