import { ComponentType, CSSProperties, ReactNode, useEffect, useState } from 'react';
import { AnyDataSource } from '@sqke/parser';

export interface WithConfigProps {
  type: any;
  style?: CSSProperties;
  visualConfig: any;
  data?: any;
  dataConfig: {
    dataSource: AnyDataSource;
    dataMapping: Record<string, string>;
  };
  [key: string]: any;
}

interface WithConfigOption<T> {
  prepare?: (config: WithConfigProps) => Promise<boolean>;
  mapConfigToProps?: (config: WithConfigProps, data: any) => T;
  empty?: (config: WithConfigProps, data: any) => boolean;
  emptyRender?: () => ReactNode;
  errorRender?: (props: { style?: CSSProperties, [key: string]: any }) => ReactNode;
}

/**
 * 数据转换
 */
export function withConfig<T extends {}>(
  WrappedComponent: ComponentType<T>, option: WithConfigOption<T> = {}
) {
  const {
    prepare,
    mapConfigToProps,
    empty,
    emptyRender,
    errorRender,
  } = option;
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithConfig = (props: WithConfigProps) => {
    let { data } = props;
    // 如果未提供本地数据，或者本地数据为空，那么根据其数据类型，返回假数据用于显示
    // if (!data) {
    //   data = LOOKUP_TABLE[getDefaultParser(props) as keyof typeof LOOKUP_TABLE];
    // }

    const [isPrepared, setIsPrepared] = useState(!prepare);
    useEffect(() => {
      if (!prepare) return;
      // setIsPrepared(false);
      prepare(props)
        .then(() => setIsPrepared(true))
        .catch((err: any) => {
          console.error(err);
        });
    }, [props]);

    // 请求完成后，如果系列不存在，则不绘制图形，显示“暂无数据”
    if (!props?.complex && empty && empty(props, data)) {
      if (emptyRender) {
        return emptyRender();
      }

      return (
        <div
          style={{
            ...(props?.style ?? props.style),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          暂无数据
        </div>
      );
    }

    try {
      const pickUpProps = { style: props?.style, className: props?.className };
      const _props = mapConfigToProps ? mapConfigToProps(props, data) : {};
      if (!isPrepared) {
        return null;
      }

      return (
        <WrappedComponent {...pickUpProps} {...(_props as T)} />
      );
    } catch (e) {
      if (errorRender) {
        return errorRender(props);
      }

      return (
        <h3 style={props?.style}>发生了错误，请检查配置或映射</h3>
      )
    }

  };

  ComponentWithConfig.displayName = `withConfig(${displayName})`;

  return ComponentWithConfig as ComponentType;
}
