import cloneDeep from 'lodash/cloneDeep';

export function getSelectArea(arr, select) {
  if (!select || !arr) return [];
  let res = [];
  function getAreaData(curArr, selectKey) {
    curArr.forEach(item => {
      if (item.key === selectKey) {
        res = cloneDeep(item);
      }
      if (item.children) {
        getAreaData(item.children || [], selectKey);
      }
    });
  }
  getAreaData(arr, select);
  return [res];
}

export function floatAreaArray(array) {
  const a = [];
  function floatArr(arr) {
    arr.forEach(item => {
      a.push({
        title: item.title,
        key: item.key,
        code: item.code,
        parent: item.parent,
      });
      if (item.children) {
        floatArr(item.children);
      }
    });
  }
  floatArr(array);
  return a;
}

export function getCurUpgradeType(upType) {
  let res = 'none';
  if (upType && upType !== 'none') {
    res = upType === 'ol' ? 'online' : 'contactCustomerService';
  }
  // const role = localStorage.getItem('role');
  // if (role === 'auth_industry_engine_1') {
  //   res = 'online';
  // } else if (upType === 'up' && role !== 'auth_industry_engine_1') {
  //   res = 'contactCustomerService';
  // } else {
  //   res = 'none';
  // }
  return res;
}

export function getBindWxPermission(data) {
  const wxpage = data.find(item => item.metadata === 'accountSetting');
  let res = false;
  if (wxpage && wxpage.child.find(item => item.metadata === 'bindWX')) {
    res = true;
  }
  return res;
}

function findChildIsinClude(children, key) {
  const have = children?.find(item => item.metadata === key);
  return !!have;
}

function findUpgradeType(child) {
  const ol = findChildIsinClude(child, 'online');
  const cs = findChildIsinClude(child, 'contactCustomerService');
  const none = findChildIsinClude(child, 'none');
  let res = 'none';
  if (none) {
    res = 'none';
  } else if (ol) {
    res = 'ol';
  } else if (cs) {
    res = 'cs';
  }
  return res;
}

export function handleWidthRolePermission(data) {
  let res = {};
  data.forEach((item, index) => {
    let perm = {};
    let bindWX = '';
    let back = '';
    let pushIndustry = '';
    let upGrade = ''; // 页面模块触发升级
    let upGrade1 = ''; // 产业链表格升级
    let upGrade2 = ''; // 专精特新升级
    let exportList = [];
    let industryGraphList = [];
    let cityGraphList = [];
    let subjectList = [];
    switch (item.metadata) {
      case 'accountSetting':
        upGrade = findUpgradeType(item.child);
        bindWX = findChildIsinClude(item.child, 'bindWX');
        break;
      case 'zgc':
        res.zgc = true;
        break;
      case 'knowledgeEngine':
      case 'reportGeneration':
      case 'vip':
        upGrade = findUpgradeType(item.child);
        break;
      case 'homePage':
        upGrade = findUpgradeType(item.child);
        upGrade2 = findUpgradeType(item.child.find(item => item.metadata === 'specializedNew')?.child || []);
        break;
      case 'mySelf':
      case 'digitalPark':
      case 'companyDetail':
      case 'patentDetail':
      case 'institutionDetail':
      case 'policyDetail':
      case 'investmentDetail':
      case 'standardDetail':
      case 'researchReportDetail':
      case 'industryCompanyChart':
        break;
      case 'industryData':
      case 'industryMap':
        upGrade1 = findUpgradeType(item.child.find(item => item.metadata === 'industryChain').child);
        break;
      case 'atlasDetail':
        upGrade1 = findUpgradeType(item.child.find(item => item.metadata === 'industryChain').child);
        back = findChildIsinClude(item.child, 'backHomepage');
        pushIndustry = findChildIsinClude(item.child, 'toIndustryData');
        break;
      case 'enterpriseAccountExport':
        exportList = getChildList(item.child);
        break;
      case 'industryGraph':
        industryGraphList = getChildList(item.child);
        break;
      case 'cityGraph':
        cityGraphList = getChildList(item.child);
        break;
      case 'specialSubject':
        subjectList = getChildList(item.child);
        break;
      default: break;
    }
    if (bindWX) {
      perm.bd = true;
    }
    if (back) {
      perm.bk = true;
    }
    if (pushIndustry) {
      perm.ptid = true;
    }
    if (upGrade) {
      perm.up = upGrade;
      if(item.metadata === 'reportGeneration'){
        item.child.forEach(item2 => {
          if(item2.en_name !== 'reportGeneration-none'){
            perm[item2.en_name] = true;
          }
        });
      }
    }
    if (upGrade1) {
      perm.idc = {
        up: upGrade1,
      }
    }
    if (upGrade2) {
      perm.spn = {
        up: upGrade2,
      }
    }
    // 留着这些权限从新版跳到旧版使用
    switch (item.metadata) {
      // 旧版权限
      case 'accountSetting':
        res.acst = perm;
        break;
      case 'homePage':
        res.hp = perm;
        break;
      case 'knowledgeEngine':
        res.kle = perm;
        break;
      case 'reportGeneration':
        res.rgt = perm;
        break;
      case 'vip':
        res.vip = perm;
        break;
      case 'mySelf':
        res.ms = perm;
        break;
      case 'digitalPark':
        res.dip = perm;
        break;
      case 'companyDetail':
        res.cod = perm;
        break;
      case 'patentDetail':
        res.pad = perm;
        break;
      case 'institutionDetail':
        res.indl = perm;
        break;
      case 'policyDetail':
        res.pod = perm;
        break;
      case 'investmentDetail':
        res.itd = perm;
        break;
      case 'standardDetail':
        res.std = perm;
        break;
      case 'researchReportDetail':
        res.red = perm;
        break;
      case 'industryCompanyChart':
        res.icc = perm;
        break;
      case 'industryData':
        res.ind = perm;
        break;
      case 'industryMap':
        res.imp = perm;
        break;
      case 'atlasDetail':
        res.atd = perm;
        break;
      // 新版权限
      case 'industryGraph':
        res.graph = industryGraphList;
        break;
      case 'industryReport':
        res.industryReport = true;
        break;
      case 'industryRanking':
        res.industryRanking = true;
        break;
      case 'industryDataV2':
        res.industryDataV2 = true;
        break;
      case 'hiddenDetailOption':
        res.hiddenDetailOption = true;
        break;
      case 'hiddenDetailTag':
        res.hiddenDetailTag = true;
        break;
      // case 'reportGeneration':
      //   console.log(8999999);
      //   res.reportGeneration = perm;
      //   break;
      case 'hiddenIndustryTopLeftTab':
        res.hiddenIndustryTopLeftTab = true;
        break;
      case 'hiddenSearchTopLeftTab':
        res.hiddenSearchTopLeftTab = true;
        break;
      case 'EAPersonalCenter':
        res.EAPersonalCenter = true;
        break;
      case 'enterpriseAccountExport':
        res.enterpriseAccountExport = exportList;
        break;
      case 'cityGraph':
        res.cityGraph = cityGraphList;
        break;
      case 'specialSubject':
        res.specialSubject = subjectList;
        break;
      case 'anchorExpande':
        res.anchorExpande = true;
        break;
      case 'searchCompanyMap':
        res.searchCompanyMap = true;
        break;
      case 'companyDetailPageTo':
        res.companyDetailPageTo = true;
        break;
      case 'openCompanyDetailWithToken':
        res.openCompanyDetailWithToken = true;
        break;
      case 'closeCompanyDetail':
        res.closeCompanyDetail = true;
        break;
      default: break;
    }
  });
  return res;
}

export function getChildList(children) {
  const arr = [];
  children.forEach(item => {
    arr.push(item.metadata);
  });
  return arr;
};

export function getUrlPrefix({ slash = true } = {}) {
  let res = `${slash ? '/' : ''}`;
  if (window.location.host === 'chanyeos.com' || window.location.host === 'www.chanyeos.com') {
    res = '';
  }
  // const res = '';
  return res;
};

export const findNode = (root, predicate) => {
  const arr = [root];
  while (arr.length > 0) {
    const p = arr.pop();
    if (predicate(p)) return p;
    if (!Array.isArray(p.children)) continue;
    arr.push(...p.children);
  }
  return undefined;
};

export const findAllNode = (root, predicate) => {
  const filterNode = (d) => {
    let arr = [];
    d.forEach(item => {
      if (predicate(item)) {
        arr.push({
          ...item,
          children: item?.children?.length > 0 && filterNode(item?.children)
        })
      }
    })
    return arr;
  };
  return filterNode(root);
};

export const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

/**
 * 渲染下拉列表的函数
 * @param str
 * @param keyword
 * @return {*}
 */
export const highlightKeyword = (
  str,
  keyword,
  highlightClassName,
) => {
  if (!keyword || !str) return str;
  const arr = str.split(keyword);
  const maxIndex = arr['length'] - 1;
  return arr.reduce((prev, next, index) => {
    next !== '' && prev.push(<span key={index}>{next}</span>);
    if (index !== maxIndex) {
      prev.push(
        <span className={highlightClassName} key={`${keyword || 'keyword'}-${index}`}>
          {keyword}
        </span>
      );
    }
    return prev;
  }, []);
};

/**
 * 通过判断区域获取报告ID
 */
export const judgeArea = (area, ids) => {
  const _area = area[0];
  const id = ids.split(',');
  if (_area.slice(4, 6) !== '00' && id[1]) {
    return id[1];
  }
  return id[0];
};
/**
 * 企业详情下的工具
  */
export const companyDetailsTool = {
  peer_comparison:{
    keyword: "peer_comparison",
    name: "同行对标",
    url: "tool/firmComparison",
  },
  HighEvaluation:{
    keyword:"HighEvaluation",
    name: "高企自测",
    url: "tool/companyTest",
  },
  anchor_point_extension:{
    keyword:"anchor_point_extension",
    name: "锚点拓客",
    url: "tool/anchorExpande",
  },
  companyIntelligentEvaluation:{
    keyword: 'companyIntelligentEvaluation',
    name: '小巨人智测',
    url: 'companyIntelligentEvaluation'
  },
  industryChainAdvantageEnterprise:{
    keyword: "industryChainAdvantageEnterprise",
    name: "优势企业挂链",
    url: "/tool/penetrateGraph/industryChainAdvantageEnterprise",
  },
  largeEnterpriseRegionLayout:{
    keyword:"largeEnterpriseRegionLayout",
    name: "企业区域分布",
    url: "/tool/penetrateGraph/largeEnterpriseRegionLayout",
  },
  largeEnterpriseBusinessLayout:{
    keyword:"largeEnterpriseBusinessLayout",
    name: "企业业务布局",
    url: "/tool/penetrateGraph/largeEnterpriseBusinessLayout",
  },
  regionCapitalTrend:{
    keyword:"regionCapitalTrend",
    name: "投资流向分析",
    url: "/tool/penetrateGraph/regionCapitalTrend",
  },
};



/**
* 根据一个经纬度及距离角度，算出另外一个经纬度
* @param {*} lng 经度 113.3960698
* @param {*} lat 纬度 22.941386
* @param {*} brng 方位角 45 ---- 正北方：000°或360° 正东方：090° 正南方：180° 正西方：270°
* @param {*} dist 90000距离(米)
*
* 1、角度转换为弧度公式：弧度=角度×(π ÷度180 )
* 2、弧度转换为角度公式： 角度=弧度×（180÷π）
*/
export const getRandLngLat = (lng, lat, dist)=>{
  //大地坐标系资料WGS-84 长半径a=6378137 短半径b=6356752.3142 扁率f=1/298.2572236
  var a = 6378137;
  var b = 6356752.3142;
  var f = 1 / 298.257223563;
  var brng = 90;
  var lon1 = lng * 1;
  var lat1 = lat * 1;
  var s = dist;
  var alpha1 = brng * (Math.PI / 180)//mapNumberUtil.rad(brng);
  var sinAlpha1 = Math.sin(alpha1);
  var cosAlpha1 = Math.cos(alpha1);

  //var tanU1 = (1 - f) * Math.tan(mapNumberUtil.rad(lat1));
  var tanU1 = (1 - f) * Math.tan(lat1 * (Math.PI / 180));
  var cosU1 = 1 / Math.sqrt((1 + tanU1 * tanU1)), sinU1 = tanU1 * cosU1;
  var sigma1 = Math.atan2(tanU1, cosAlpha1);
  var sinAlpha = cosU1 * sinAlpha1;
  var cosSqAlpha = 1 - sinAlpha * sinAlpha;
  var uSq = cosSqAlpha * (a * a - b * b) / (b * b);
  var A = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
  var B = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));

  var sigma = s / (b * A), sigmaP = 2 * Math.PI;
  while (Math.abs(sigma - sigmaP) > 1e-12) {
    var cos2SigmaM = Math.cos(2 * sigma1 + sigma);
    var sinSigma = Math.sin(sigma);
    var cosSigma = Math.cos(sigma);
    var deltaSigma = B * sinSigma * (cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
      B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
    sigmaP = sigma;
    sigma = s / (b * A) + deltaSigma;
  }

  var tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1;
  var lat2 = Math.atan2(sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
    (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp));
  var lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1);
  var C = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
  var L = lambda - (1 - C) * f * sinAlpha *
    (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));

  var revAz = Math.atan2(sinAlpha, -tmp); // final bearing

  var lngLatObj = { lng: lon1 + L * (180 / Math.PI), lat: lat2 * (180 / Math.PI) }
  return lngLatObj
}
