import * as CONSTS from '../../consts';

export const modeStyle = {
  light: {
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',
    xNameTextStyle: {
      color: '#333333',
    },
    yAxisLabel: {
      color: '#919191',
    },
    series: {
      label: {
        color: '#262c35',
      },
    },
    itemStyle: {
      borderColor: '#4E7293',
      barColor: ['#4E7293', '#4E7293'],
      linearDirection: {
        x: 0,
        y: 0,
        x2: 1,
        y2: 0,
      },
    },
  },
  dark: {
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',
    xNameTextStyle: {
      color: '#B4B4B4',
    },
    yAxisLabel: {
      color: '#B4B4B4',
    },
    series: {
      label: {
        color: '#B4B4B4',
      },
    },
    itemStyle: {
      borderColor: '#00F2FE',
      barColor: ['#03001E', '#00F2FE'],
      linearDirection: {
        x: 0,
        y: 0,
        x2: 0.3,
        y2: 0,
      },
    },
  },
};
export const chartFontSize = {
  small: {
    legnedFontSize: 10,
  },
  middle: {
    legnedFontSize: 12,
  },
  large: {
    legnedFontSize: 14,
  },
};
