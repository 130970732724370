import React, { useState, useEffect } from 'react';
import {
  useGetParkIndustryLinkDistributionSubtableQuery,
} from '../../../../../../../../service/parkTool';
import {
  useGetCompanySpecialTagsQuery,
} from '../../../../../../../../service/interactive-v2';
import Table from '../../../components/Table';
// import { findNode } from '../../../../../../../../utils/tool';
import { FilterIcon } from '../../../../../../../../components/CustomTableIcon';
import CustomListFilter from '../../../../../../../../components/CustomListFilter';
import styles from './index.module.less';

function SectorCompanyTable(props) {
  const {
    industryCode,
    linkCode,
    parkCode,
    setCount,
    onCompanyClick,
    page,
    setPage,
    parkTitle,
  } = props;
  const [pageSize, setPageSize] = useState(20);
  const [specialTag, setSpecialTag] = useState([]);
  const [areaCode, setAreaCode] = useState(parkCode);

  const { data: tableData, isFetching: isCompanyListFetching } = useGetParkIndustryLinkDistributionSubtableQuery({
    area_key: parkTitle === '园区总览' ? areaCode : undefined,
    park_id_list: parkTitle === '园区总览' ? undefined : [areaCode],
    industry_code: industryCode,
    link_code: linkCode,
    special_tag: specialTag.filter(item => item !== 'all'),
    page_size: 20,
    page_number: page,
  }, { skip: !industryCode || !areaCode });
  const { total = 0, count = '', columns = [], dataSource = [] } = tableData || {};

  useEffect(() => {
    setCount(count);
  }, [count]);

  useEffect(() => {
    setAreaCode(parkCode);
  }, [parkCode]);

  useEffect(() => {
    setPage(1);
  }, [industryCode]);

  // const { regionTree = [] } = useGetAreaPlugMarqueeV2Query(
  //   undefined,
  //   {
  //     selectFromResult: ({ data }) => {
  //       const root = data ? findNode(data[0], node => node.key === parkCode) : null;
  //       if (!root) return [];
  //       return {
  //         regionTree: [root],
  //       };
  //     },
  //   },
  // );
  const { data: companyTags = [] } = useGetCompanySpecialTagsQuery();
  const Tablehandle = (item) => (
    {
      index: {
        width: 50,
        className: styles.index,
      },
      company_name: {
        ellipsis: true,
        onCell: (record, rowIndex) => ({
          onClick: () => { onCompanyClick(record, rowIndex); },
        }),
        className: styles.name,
      },
      special_tag: {
        width: 150,
        ellipsis: true,
        filterIcon: <FilterIcon />,
        filteredValue: specialTag.length > 0 ? specialTag : ['all'],
        filterDropdown: _props => <CustomListFilter {..._props} extraControl />,
        filters: companyTags,
        filterResetToDefaultFilteredValue: true,
      },
    }
  );
  const updateScreenCondition = (_, filters, sorter) => {
    const { special_tag: Tag } = filters;
    setSpecialTag(Tag);
    // setAreaCode(location);
  };
  return (
    <Table
      {...props}
      Tablehandle={Tablehandle}
      // regionTree={regionTree}
      companyTags={companyTags}
      data={{ columns, dataSource }}
      isFetching={isCompanyListFetching}
      onChange={updateScreenCondition}
      // className={styles.tableBox}
      pagination={{
        current: page,
        pageSize,
        showQuickJumper: false,
        showSizeChanger: false,
        total,
        onChange: (current) => {
          setPage(current);
        },
        onShowSizeChange: (size) => {
          setPageSize(size);
        },
      }}
    />
  );
}
export default SectorCompanyTable;
