/**
 * 取出并生成 title 对应的元素，图表使用
 */

import { ComponentType, ReactNode, memo } from 'react';

export interface WithTitleProps {
  title?: {
    show?: boolean;
    content?: string;
    contentL?: string;
    contentR?: string;
  };
  [key: string]: any;
}

const Title = ({ children, contentL, contentR }: { children?: ReactNode, contentL?: ReactNode, contentR?: ReactNode,  }) => (
  <div className="sqke-figcaption">
    {children}
    {
      contentL && contentR && <div className="sqke-subfigcaption">
        <div>{contentL}</div>
        <div>{contentR}</div>
      </div>
    }
  </div>
);

const CHART_TYPES = ['config-echarts', 'timeline', 'image'];
const TABLE_TYPES = ['table', 'card-enterprise-face', 'card-enterprise-trend'];

export function withTitle<T extends WithTitleProps>(WrappedComponent: ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  const Component = (props: T) => {
    const {
      position,
      title,
      __type: type,
    } = props;

    let prefix;

    if (typeof position === 'number') {
      if (TABLE_TYPES.includes(type)) {
        prefix = `表${position} `;
      } else if (CHART_TYPES.includes(type)) {
        prefix = `图${position} `;
      }
    }

    const {
      show,
      content,
      contentL,
      contentR,
    } = title ?? {};

    return (
      <>
        { show && <Title contentL={contentL} contentR={contentR}>{prefix || ''}{content}</Title> }
        <WrappedComponent {...props as T} />
      </>
    );
  };

  Component.displayName = `withTitle(${displayName})`;

  return Component;
}
