/*
* Author: zhangmeng
* Description: '公司详情-标题'
* Date: 2021/05/12
*/

import React from 'react';
import style from './index.module.less';

function TitleComp(props) {
  const { title, num } = props;

  return (
    <div className={style.all} id={title}>
      <div className={style.title}>{title}</div>
      <div className={style.num}>{num}</div>
    </div>
  );
}

export default TitleComp;
