import React, { useState, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { Table } from '@sqke/design';
import Container from '../../Container';
import { getGuardianship } from '../../../../../api/companyDetail';
import constructionTree from '../../../../../utils/constructionTree';
import GraphForceOriented1 from '../../GraphForceOriented1';
// import ChartNoData from '../../ChartNoData';
import { useGetLegalRepresentativeRelationTableQuery } from '../../../../../service/companyDetail';
// import TableNoData from '../../TableNoData';

function CorporateExecutives(props) {
  const { companyId, companyName } = props;
  const [data, setData] = useState(null);
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  useEffect(() => {
    setLoading(true);
    getGuardianship({
      company_id: companyId,
    }).then(res => {
      if (res.status === '00000') {
        setData([constructionTree(res.data?.series?.[0]?.links, companyName, 1)]);
        setTitle(res.data?.title);
      }
      setLoading(false);
    });
  }, []);

  const { data: tableData, isFetching: isTableFetching } = useGetLegalRepresentativeRelationTableQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, {
    skip: !companyId,
  });
  const { dataSource, columns } = tableData || {};
  const _columns = useMemo(() => columns?.map(item => {
    if (item.dataIndex === 'index') {
      return {
        ...item,
        width: 70,
      };
    }
    return item;
  }), [tableData]);

  const tableEl = useMemo(() => {
    if (!dataSource || dataSource.length) {
      return (<Table
        rowKey="index"
        dataSource={dataSource}
        columns={_columns}
        title={() => tableData?.title}
        strip
        loading={isTableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.auth_count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }) => {
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />);
    }
    return null;
  }, [dataSource]);
  return (
    <Container id="企业高管" question="展示该企业及相关企业法人及高管关系" num={tableData?.count} title={title}>
      <Spin spinning={loading} tip="搜索中，请稍候.....">
        {
          useMemo(() => {
            if (!data) {
              return '';
            }
            return (
              data.length > 0 ? (
                <div style={{ height: 720 }}>
                  <GraphForceOriented1 data={data} />
                </div>
              ) : null
            );
          }, [data])
        }
      </Spin>
      {
        tableEl
      }
    </Container>
  );
}

export default CorporateExecutives;
