/*
 *Author: zhaochenyu
 *Description: 产业画像报告
 *Date: 2021/12/03
*/

import React, { useMemo } from 'react';
import ReportChart from '../ReportChart';
import FrontCover from './FrontCover';
import BackCover from './BackCover';
import style from '../index.module.less';

function IndustryPortraitReport(props) {
  const { chartConditions, chartTitlesRef, refList, chartTitles, pushToAchor,
    getChartFinishedPercent, buyStatus } = props;
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  return (
    <>
      {buyStatus ? (
        <>
          <div className={style.first}>
            <FrontCover area={urlReportMsg.area} industry={urlReportMsg.industry} />
          </div>

          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、产业定义')}>
              <div className={style.firstTitle}>一、产业定义</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、企业总量')}>
              <div className={style.firstTitle}>二、企业总量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）企业总量')}>
              <div className={style.secondTitle}>（一）企业总量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            {/* <div className={style.thirdCata} onClick={() => pushToAchor('1.企业数量')}>
              <div className={style.thirdTitle}>1.企业数量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div> */}
            <div className={style.secondCata} onClick={() => pushToAchor('（二）区域分布')}>
              <div className={style.secondTitle}>（二）区域分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>3</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）企业结构')}>
              <div className={style.secondTitle}>（三）企业结构</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）上市企业')}>
              <div className={style.secondTitle}>（四）上市企业</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>7</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）新增企业')}>
              <div className={style.secondTitle}>（五）新增企业</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、创新要素')}>
              <div className={style.firstTitle}>三、创新要素</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）全量专利')}>
              <div className={style.secondTitle}>（一）全量专利</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）标准分布')}>
              <div className={style.secondTitle}>（二）标准分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>16</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）创新机构')}>
              <div className={style.secondTitle}>（三）创新机构</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>17</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、股权融资')}>
              <div className={style.firstTitle}>四、股权融资</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）总量结构')}>
              <div className={style.secondTitle}>（一）总量结构</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）重点项目')}>
              <div className={style.secondTitle}>（二）重点项目</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>24</div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="一、产业定义">一、产业定义</div>
            {/* 产业产业链图 */}
            {chartConditions.length > 0 && (
            <ReportChart
              chartMsg={chartConditions[0]}
              chartIndex={0}
              ref={chartTitlesRef.current[0]}
              chartHeight={720}
              refList={refList}
              title={`图1 ${chartTitles[0] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="二、企业总量">二、企业总量</div>
            <div className={style.secondaryTitle} id="（一）企业总量">（一）企业总量</div>
            {/* 存量企业数量变化图 */}
            {chartConditions.length > 1 && (
            <ReportChart
              chartMsg={chartConditions[1]}
              chartIndex={1}
              ref={chartTitlesRef.current[1]}
              chartHeight={350}
              refList={refList}
              title={`图2 ${chartTitles[1] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）区域分布">（二）区域分布</div>
            {/* 产业分布情况图 */}
            {chartConditions.length > 2 && (
            <ReportChart
              chartMsg={chartConditions[2]}
              chartIndex={2}
              ref={chartTitlesRef.current[2]}
              chartHeight={660}
              refList={refList}
              title={`图3 ${chartTitles[2] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            {/* 产业企业注册资本结构图 */}
            {chartConditions.length > 3 && (
            <ReportChart
              chartMsg={chartConditions[3]}
              chartIndex={3}
              ref={chartTitlesRef.current[3]}
              chartHeight={640}
              refList={refList}
              title={`图4 ${chartTitles[3] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）企业结构">（三）企业结构</div>
            <div className={style.tertiaryTitle} id="1.产业金字塔">1. 产业金字塔</div>
            {/* 产业企业成立时间结构图 */}
            {chartConditions.length > 4 && (
            <ReportChart
              chartMsg={chartConditions[4]}
              chartIndex={4}
              ref={chartTitlesRef.current[4]}
              chartHeight={600}
              refList={refList}
              title={`图5 ${chartTitles[4] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>上市企业：上交所、深交所、北交所上市的企业。</div>
                <div>高成长企业：近三年获得过融资的企业。</div>
                <div>高技术企业：拥有“国家高新技术企业”称号的企业。</div>
              </div>
            </div> */}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="2.成立年限">2. 成立年限</div>
            {/* 产业金字塔图 */}
            {chartConditions.length > 5 && (
            <ReportChart
              chartMsg={chartConditions[5]}
              chartIndex={5}
              ref={chartTitlesRef.current[5]}
              chartHeight={300}
              refList={refList}
              title={`图6 ${chartTitles[5] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.tertiaryTitle}>3. 注册资本</div>
            {chartConditions.length > 6 && (
            <ReportChart
              chartMsg={chartConditions[6]}
              chartIndex={6}
              ref={chartTitlesRef.current[6]}
              chartHeight={300}
              refList={refList}
              title={`图7 ${chartTitles[6] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）上市企业">（四）上市企业</div>
            {/* <div className={style.tertiaryTitle} id="1.历年上市企业数量">1. 历年上市企业数量</div> */}
            {/* 产业上市企业数量变化图 */}
            {chartConditions.length > 7 && (
            <ReportChart
              chartMsg={chartConditions[7]}
              chartIndex={7}
              ref={chartTitlesRef.current[7]}
              chartHeight={300}
              refList={refList}
              title={`图8 ${chartTitles[7] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            {/* <div className={style.tertiaryTitle} id="2.上市企业金字塔">2. 上市企业金字塔</div> */}
            {/* 产业上市企业分类金字塔图 */}
            {chartConditions.length > 8 && (
            <ReportChart
              chartMsg={chartConditions[8]}
              chartIndex={8}
              ref={chartTitlesRef.current[8]}
              chartHeight={320}
              refList={refList}
              title={`图9 ${chartTitles[8] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（五）新增企业">（五）新增企业</div>
            {/* 申请专利变化趋势 */}
            {chartConditions.length > 9 && (
            <ReportChart
              chartMsg={chartConditions[9]}
              chartIndex={9}
              ref={chartTitlesRef.current[9]}
              chartHeight={660}
              refList={refList}
              title={`图10 ${chartTitles[9] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="三、创新要素">三、创新要素</div>
            <div className={style.secondaryTitle} id="（一）全量专利">（一）全量专利</div>
            <div className={style.tertiaryTitle} id="1.申请专利">1. 申请专利</div>
            {/* 产业授权专利数量图 */}
            {chartConditions.length > 10 && (
            <ReportChart
              chartMsg={chartConditions[10]}
              chartIndex={10}
              ref={chartTitlesRef.current[10]}
              chartHeight={420}
              refList={refList}
              title={`图11 ${chartTitles[10] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="2.授权专利">2. 授权专利</div>
            {/* 产业授权发明专利分布图 */}
            {chartConditions.length > 11 && (
            <ReportChart
              chartMsg={chartConditions[11]}
              chartIndex={11}
              ref={chartTitlesRef.current[11]}
              chartHeight={460}
              refList={refList}
              title={`图12 ${chartTitles[11] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="3.授权发明专利区域分布">3. 授权发明专利区域分布</div>
            {/* 产业授权发明专利数量表 */}
            {chartConditions.length > 12 && (
            <ReportChart
              chartMsg={chartConditions[12]}
              chartIndex={12}
              ref={chartTitlesRef.current[12]}
              chartHeight={660}
              refList={refList}
              title={`图13 ${chartTitles[12] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            {/* 产业新增授权发明专利省份对比图 */}
            {chartConditions.length > 13 && (
            <ReportChart
              chartMsg={chartConditions[13]}
              chartIndex={13}
              ref={chartTitlesRef.current[13]}
              chartHeight={660}
              refList={refList}
              title={`表1 ${chartTitles[13] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.tertiaryTitle} id="4.新增授权发明专利省份分布">4. 新增授权发明专利区域分布</div>
            {/* 产业授权发明专利词云图 */}
            {chartConditions.length > 14 && (
            <ReportChart
              chartMsg={chartConditions[14]}
              chartIndex={14}
              ref={chartTitlesRef.current[14]}
              chartHeight={660}
              refList={refList}
              title={`图14 ${chartTitles[14] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="5.授权发明专利关键词词云">5. 授权发明专利关键词词云</div>
            {/* 授权发明专利单位合作关系图 */}
            {chartConditions.length > 15 && (
            <ReportChart
              chartMsg={chartConditions[15]}
              chartIndex={15}
              ref={chartTitlesRef.current[15]}
              chartHeight={460}
              refList={refList}
              title={`图15 ${chartTitles[15] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="6.授权发明专利合作关系">6. 授权发明专利合作关系</div>
            {/* 产业相关标准类型结构图 */}
            {chartConditions.length > 16 && (
            <ReportChart
              chartMsg={chartConditions[16]}
              chartIndex={16}
              ref={chartTitlesRef.current[16]}
              chartHeight={620}
              refList={refList}
              title={`图16 ${chartTitles[16] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）标准分布">（二）标准分布</div>
            {/* 产业相关研究机构省份对比图 */}
            {chartConditions.length > 17 && (
            <ReportChart
              chartMsg={chartConditions[17]}
              chartIndex={17}
              ref={chartTitlesRef.current[17]}
              chartHeight={500}
              refList={refList}
              title={`图17 ${chartTitles[17] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）创新机构">（三）创新机构</div>
            <div className={style.tertiaryTitle} id="1.省份分布">1. 区域分布</div>
            {/* 产业相关研究机构类型结构图 */}
            {chartConditions.length > 18 && (
            <ReportChart
              chartMsg={chartConditions[18]}
              chartIndex={18}
              ref={chartTitlesRef.current[18]}
              chartHeight={660}
              refList={refList}
              title={`图18 ${chartTitles[18] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="2.类型分布">2. 类型分布</div>
            {/* 产业融资事件数量图 */}
            {chartConditions.length > 19 && (
            <ReportChart
              chartMsg={chartConditions[19]}
              chartIndex={19}
              ref={chartTitlesRef.current[19]}
              chartHeight={400}
              refList={refList}
              title={`图19 ${chartTitles[19] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="四、股权融资">四、股权融资</div>
            <div className={style.secondaryTitle} id="（一）总量结构2">（一）总量结构</div>
            <div className={style.tertiaryTitle} id="1.融资事件">1. 融资事件</div>
            {/* 产业融资金额图 */}
            {chartConditions.length > 20 && (
            <ReportChart
              chartMsg={chartConditions[20]}
              chartIndex={20}
              ref={chartTitlesRef.current[20]}
              chartHeight={280}
              refList={refList}
              title={`图20 ${chartTitles[20] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.tertiaryTitle} id="2.融资金额">2. 融资金额</div>
            {chartConditions.length > 21 && (
            <ReportChart
              chartMsg={chartConditions[21]}
              chartIndex={21}
              ref={chartTitlesRef.current[21]}
              chartHeight={280}
              refList={refList}
              title={`图21 ${chartTitles[21] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：融资金额只统计企业对外公开的数额。</div>
              </div>
            </div>
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="3.平均单笔融资额">3. 平均单笔融资额</div>
            {/* 产业平均单笔融资金额图 */}
            {chartConditions.length > 22 && (
            <ReportChart
              chartMsg={chartConditions[22]}
              chartIndex={22}
              ref={chartTitlesRef.current[22]}
              chartHeight={460}
              refList={refList}
              title={`图22 ${chartTitles[22] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="4.融资事件省份分布">4. 融资事件区域分布</div>
            {/* 产业融资事件省份对比图 */}
            {chartConditions.length > 23 && (
            <ReportChart
              chartMsg={chartConditions[23]}
              chartIndex={23}
              ref={chartTitlesRef.current[23]}
              chartHeight={660}
              refList={refList}
              title={`图23 ${chartTitles[23] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="5.融资金额省份分布">5. 融资金额区域分布</div>
            {/* 产业融资金额省份对比图 */}
            {chartConditions.length > 24 && (
            <ReportChart
              chartMsg={chartConditions[24]}
              chartIndex={24}
              ref={chartTitlesRef.current[24]}
              chartHeight={660}
              refList={refList}
              title={`图24 ${chartTitles[24] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="6.融资轮次">6. 融资轮次</div>
            {/* 产业融资轮次对比图 */}
            {chartConditions.length > 25 && (
            <ReportChart
              chartMsg={chartConditions[25]}
              chartIndex={25}
              ref={chartTitlesRef.current[25]}
              chartHeight={500}
              refList={refList}
              title={`图25 ${chartTitles[25] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}

          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）重点项目">（二）重点项目</div>
            <div className={style.tertiaryTitle} id="1.早期融资金额TOP10事件">1. 早期融资金额TOP10事件</div>
            {/* 产业股权融资金额 TOP10 事件表 */}
            {chartConditions.length > 26 && (
            <ReportChart
              chartMsg={chartConditions[26]}
              chartIndex={26}
              ref={chartTitlesRef.current[26]}
              chartHeight={700}
              refList={refList}
              title={`表2 ${chartTitles[26] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.tertiaryTitle} id="2.股权融资金额TOP10事件">2. 股权融资金额TOP10事件</div>
            {/* 产业政策数量图 */}
            {chartConditions.length > 27 && (
            <ReportChart
              chartMsg={chartConditions[27]}
              chartIndex={27}
              ref={chartTitlesRef.current[27]}
              chartHeight={700}
              refList={refList}
              title={`表3 ${chartTitles[27] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.last}>
            <BackCover />
          </div>
        </>
      ) : (
        <>
          <div className={style.first}>
            <FrontCover area={urlReportMsg.area} industry={urlReportMsg.industry} />
          </div>

          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、产业定义')}>
              <div className={style.firstTitle}>一、产业定义</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、企业总量')}>
              <div className={style.firstTitle}>二、企业总量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）企业总量')}>
              <div className={style.secondTitle}>（一）企业总量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            {/* <div className={style.thirdCata} onClick={() => pushToAchor('1.企业数量')}>
              <div className={style.thirdTitle}>1.企业数量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div> */}
            <div className={style.secondCata} onClick={() => pushToAchor('（二）区域分布')}>
              <div className={style.secondTitle}>（二）区域分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>3</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）企业结构')}>
              <div className={style.secondTitle}>（三）企业结构</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）上市企业')}>
              <div className={style.secondTitle}>（四）上市企业</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>7</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）新增企业')}>
              <div className={style.secondTitle}>（五）新增企业</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、创新要素')}>
              <div className={style.firstTitle}>三、创新要素</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）全量专利')}>
              <div className={style.secondTitle}>（一）全量专利</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）标准分布')}>
              <div className={style.secondTitle}>（二）标准分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>16</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）创新机构')}>
              <div className={style.secondTitle}>（三）创新机构</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>17</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、股权融资')}>
              <div className={style.firstTitle}>四、股权融资</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）总量结构')}>
              <div className={style.secondTitle}>（一）总量结构</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>19</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）重点项目')}>
              <div className={style.secondTitle}>（二）重点项目</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>24</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default IndustryPortraitReport;
