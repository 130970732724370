/*
 *Author: zhaochenyu
 *Description: 精品报告支付页
 *Date: 2022/06/22
*/

import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Alert, Button, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QRcode from 'qrcode';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/url';
import PayAmountCard from '../PayAmountCard';
import SQPayAmountCard from '../SQPayAmountCard';
import WxShare from '../WxShare';
// import { IconFont } from '../../utils/mapIcon';
import { getBoutiqueReport, downloadBoutiqueReport } from '../../api/reportGeneration';
import {
  getBoutiqueReportQrCode,
  getBoutiqueReportStatus,
  paySQcoinPaidReport,
} from '../../api/apply';
import { getUserSQcoinNum } from '../../api/mySelf';
import style from './index.module.less';

function BoutiqueReportDetail() {
  const query = useQuery();
  const history = useHistory();
  const [pay, setPay] = useState(false);
  const [reportImgList, setReportImgList] = useState([]);
  const [applyExpired, setApplyExpired] = useState(false);
  const [successPay, setSuccessPay] = useState(false);
  const [openWxModal, setOpenWxModal] = useState(true);
  const [costSQcoin, setCostSQcoin] = useState(0);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [cost, setCost] = useState(0);
  const [sqcoin, setSqcoin] = useState(0);
  const [needCoin, setNeedCoin] = useState(false);
  const timer = useRef(null);
  const timer2 = useRef(null);
  const orderId = useRef(null);
  const expiredRef = useRef(null);
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const reportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  const { id, isApply, title, subTitle, price, coverImg } = reportMsg;
  const getReportImg = useCallback(() => {
    getBoutiqueReport({
      report_id: id || '',
    }).then(res => {
      if (res.status === '00000') {
        setCost(res.data?.goods_sq_coin);
        setNeedCoin(res.data.download_need_SQCoin_pay);
        setReportImgList(res.data?.image_list || []);
      }
    });
  }, [id]);

  const paySuccess = () => {
    paySQcoinPaidReport({
      report_id: id,
    }).then(res => {
      if (res.status === '00000') {
        getReportImg();
        setPay(true);
        setSuccessPay(true);
        setTimeout(() => {
          setSuccessPay(false);
        }, 3000);
      }
    });
  };
  const download = () => {
    if (needCoin && cost > sqcoin) {
      message.warning('上奇币不足，请获取！');
      return;
    }
    downloadBoutiqueReport({ report_id: id }, title);
  };

  const pushDetailPage = useCallback((key) => {
    query.set('tab', '个人中心');
    query.set('detail', key);
    history.push({
      pathname: 'accountSetting',
      search: query.toString(),
    });
  }, [query]);

  useEffect(() => {
    expiredRef.current = applyExpired;
  }, [applyExpired]);

  const getOrderCurStatus = useCallback(() => {
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    getBoutiqueReportStatus({
      order_id: orderId.current,
      report_id: id || '',
    }).then(res => {
      if (res.status === '00000') {
        clearTimeout(timer.current);
        getReportImg();
        setPay(true);
        setSuccessPay(true);
        // window.opener.postMessage('refreshReportList');
        setTimeout(() => {
          setSuccessPay(false);
        }, 3000);
      } else if (!expiredRef.current) {
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    }).catch(() => {
      if (!expiredRef.current) {
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    });
  }, [id]);
  const applyCountDown = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    getBoutiqueReportQrCode({
      report_id: id || '',
    }).then(res => {
      if (res.status === '00000') {
        setCostSQcoin(res.data.goods_sq_coin);
        setSqcoin(res.data.user_sq_coin);
        orderId.current = res.data.order_id;
        QRcode.toCanvas(res.data.code_url, { width: 50, margin: 1 }, (err, canvas) => {
          if (err) {
            console.log(err);
          }
          const contanier = document.getElementById('qrcode');
          if (contanier.childNodes[0]) {
            contanier.removeChild(contanier.childNodes[0]);
          }
          contanier.appendChild(canvas);
        });
        setApplyExpired(false);
        expiredRef.current = false;
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
        timer.current = setTimeout(() => {
          setApplyExpired(true);
          expiredRef.current = true;
        }, 120 * 1000);
      }
    });
  }, [getOrderCurStatus, id]);

  const getUserSQcoin = () => {
    getUserSQcoinNum().then(res => {
      if (res.status === '00000') {
        setSqcoin(res.data);
      }
    });
  };

  useEffect(() => {
    setOpenWxModal(isApply);
    setPay(isApply);
    if (!isApply) {
      applyCountDown();
    }
  }, [isApply]);

  useEffect(() => {
    getUserSQcoin();
    getReportImg();
    return () => {
      expiredRef.current = true;
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  const isSqCoinNotEnough = cost > sqcoin;

  return (
    <div className={style.all}>
      <div className={style.body}>
        {
          pay && (
            <div
              className={style.downloadBtn}
              onClick={() => {
                if (!needCoin) {
                  download();
                } else {
                  setDownloadModalVisible(true);
                }
              }}
            >
              立即下载
            </div>
          )
        }
        <div className={style.content}>
          {reportImgList.map((item, index) => (
            <img alt="" key={`${index}img`} src={item} className={style.imgItem} />
          ))}
        </div>
      </div>
      {
        !pay && (
          <>
            <div className={style.lockCover}>
              <div className={style.lock} />
              <div className={style.desc}>
                扫码支付后查看并下载全文
                <DownOutlined />
              </div>
            </div>
            <div className={style.header}>
              <div className={style.headerContent}>
                <div className={style.reportCover}>
                  <img alt="" src={coverImg} className={style.cover} />
                </div>
                <div className={style.applyMsg}>
                  <div className={style.info}>
                    <div className={style.title}>{title || ''}</div>
                    <div className={style.subTitle}>{subTitle || ''}</div>
                    <div className={style.payAmount}>
                      <PayAmountCard count={price || 0} />
                      <div className={style.applyCode}>
                        <div className={style.payQrCode}>
                          <div className={style.qrcodeCover} style={{ display: applyExpired ? '' : 'none' }}>
                            二维码已失效
                          </div>
                          <div id="qrcode" className={style.qrcode} />
                        </div>
                        <div className={style.refresh} onClick={() => applyCountDown()}>
                          {/* <IconFont type="iconsync" style={{ marginRight: '4px' }} /> */}
                          刷新二维码
                        </div>
                      </div>
                      <div className={style.SQPayAmount}>
                        <SQPayAmountCard sqcoin={sqcoin || 0} count={costSQcoin || 0} paySuccess={paySuccess} />
                      </div>
                    </div>
                    <div className={style.portocol}>
                      支付即代表您同意
                      <span
                        style={{ color: '#fff', cursor: 'pointer' }}
                        onClick={() => { window.open('https://www.chanyeos.com/industry_engine_resource/industry_engine_Q_and_A_pdf/产业通用户服务协议.pdf'); }}
                      >
                        《用户协议》
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {successPay && (
      <div className={style.successAlert}>
        <Alert
          message={(
            <span>
              此报告
              <span style={{ margin: '0 10px' }}>已购买成功</span>
            </span>)}
          type="success"
          showIcon
        />
      </div>)}
      {
        downloadModalVisible && (
          <>
            <div className={style.modal}>
              <div className={style.top}>
                <div className={style.title}>报告已生成</div>
                <div className={classnames(style.cost, { [style.alert]: isSqCoinNotEnough })}>
                  {
                    cost === 0
                      ? (
                        <>
                          是否下载
                        </>
                      ) : (
                        <>
                          是否消耗
                          {cost}
                          上奇币下载（余额
                          {sqcoin}
                          个）
                          {
                            isSqCoinNotEnough && (
                              <span
                                onClick={() => pushDetailPage('achieve')}
                              >
                                去获取
                              </span>
                            )
                          }
                        </>
                      )
                  }
                </div>
              </div>
              <div className={style.buttons}>
                <Button className={style.left} onClick={() => setDownloadModalVisible(false)}>稍后再试</Button>
                <Button
                  className={style.right}
                  onClick={() => {
                    setDownloadModalVisible(false);
                    download();
                  }}
                >
                  现在下载
                </Button>
              </div>
            </div>
            <div className={style.modalCover} />
          </>
        )
      }
      {/* <div className={classnames(style.wxModalMasking, { [style.wxModalMaskingNone]: openWxModal })}>
        <div className={style.wxModal}>
          <div key={id}>
            <QRCode value={`https://chanyeos.com/smart-ke/#/reportList?id=${id}&coverUrl=${reportImgList[0]}`} size={142} />
          </div>
          <div>打开微信扫一扫发送助力邀请</div>
          <div onClick={() => {
            setOpenWxModal(true);
          }}
          >
            直接支付
          </div>
          <div className={style.close}>
            <span onClick={() => {
              setOpenWxModal(true);
            }}
            >
              <CloseOutlined />
            </span>
          </div>
        </div>
      </div> */}
      <WxShare
        /* <QRCode value="https://industrydata.cn/smart-ke/#/reportList?id=99&coverUrl=https://industry-engine.ks3-cn-beijing.ksyun.com/prod/report/05894人工智能产业分析报告(2023)/content/3964596c71317a353164502f64476f4b55676a6651513d3d0a_0.png" size={142} />  */
        id={id}
        url={`${window.location.origin}${window.location.pathname}#/reportList?id=${id}&coverUrl=${reportImgList[0]}`}
        openWxModal={openWxModal}
        setOpenWxModal={setOpenWxModal}
      />
    </div>
  );
}

export default BoutiqueReportDetail;
