/*
 *Description: WordsCloud
 *Date: Tue Nov 30 2021 15:22:16 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import 'echarts-wordcloud';
import { WordsCloudChartProps } from '../basicsType';
import { addWaterMark, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-words-cloud-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const WordsCloudChart = forwardRef((props: WordsCloudChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize } = props;
  const [hanldeData, setHanldeData] = useState<any>([]);
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || []));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    if (!hanldeData.length) {
      return;
    }

    hanldeData.sort(
      (item1: { value: number; }, item2: { value: number; }) => item1.value - item2.value,
    );
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;
    const series: any[] = [{
      name: 'wordCloud',
      type: 'wordCloud',
      drawOutOfBound: true,
      // shape(theta: number) {
      //   console.log(theta % (2 * Math.PI / 3));
      // },
      shape: 'circle',
      left: 'center',
      top: 'center',
      width: '80%',
      height: '80%',
      right: null,
      bottom: null,
      sizeRange: [8, 20],
      rotationRange: [-0, 0],
      rotationStep: -90,
      gridSize: 0, // the larger the grid size, the bigger the gap between words.
      data: hanldeData,
      textStyle: { // 也可定义在data中
        color(params: any) {
          const { dataIndex } = params;
          return curStyle.color[dataIndex % curStyle.color.length];
        },
        rotate() {
          // console.log(1);
        },
      },
      label: {
        distance: 0,
        show: label,
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
        position: 'top',
      },
    }];

    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };
    setOption(options);
  }, [hanldeData]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '90%', height: '95%' }}
        ref={chartRef}
      />
    </div>);
});

export default WordsCloudChart;

WordsCloudChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
