/*
* Author: zhangmeng
* Description: '研报详情页'
* Date: 2021/07/13
*/

import React, { useEffect, useMemo, useState } from 'react';
import { IconFont, tabMap } from '../../../utils/mapIcon';
import TopInfo from './TopInfo';
import Catalogue from './Catalogue';
import MainChart from './MainChart';
import SimilarReport from './SimilarReport';
import { reportSearchDetailInfo, getMainChart, getCatalog, getReportRecommend } from '../../../api/researchReportDetail';
import { openNewPage } from '../../../utils/urlMethods';
import style from './index.module.less';

function ResearchReportDetail(props) {
  const { detailParams } = props;
  const [topData, setTopData] = useState({});
  const [catalogueData, setCatalogueData] = useState('');
  const [mainChart, setMainChart] = useState([]);
  const [similarReport, setSimilarReport] = useState([]);
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);

  useEffect(() => {
    reportSearchDetailInfo({
      report_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setTopData(res.data);
      }
    });
    getMainChart({
      report_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setMainChart(res.data);
      }
    });
    getCatalog({
      report_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setCatalogueData(res.data.catalogue);
      }
    });
    getReportRecommend({
      report_id: detailParams || params.get('id'),
    }).then(res => {
      if (res.status === '00000') {
        setSimilarReport(res.data.data);
      }
    });
  }, []);

  const detail = (id) => {
    if (id) {
      openNewPage('researchReportDetail', {
        id,
      });
    }
  };

  return (
    <div className={style.all}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.title}>
            <span className={style.before}>
              产业通
            &emsp;
              <span className={style.point} />
            &emsp;
            </span>
            <span className={style.later}>研报</span>
          </div>
          <div className={style.imgBox}>
            <IconFont type={tabMap.research_report} />
          </div>
        </div>
      </div>
      {
        useMemo(() => <TopInfo data={topData} id={detailParams} />, [topData, detailParams])
      }
      {
        useMemo(() => catalogueData !== '' && <Catalogue data={catalogueData} />, [catalogueData])
      }
      {
        useMemo(() => mainChart.length > 0 && <MainChart data={mainChart} />, [mainChart])
      }
      {
        useMemo(() => <SimilarReport data={similarReport} detail={(id) => detail(id)} />,
          [similarReport])
      }
    </div>
  );
}

export default ResearchReportDetail;
