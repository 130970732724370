/*
 *Author: zhaochenyu
 *Description: 企业画像首页
 *Date: 2022/01/05
*/

import React from 'react';
import style from './index.module.less';

function FrontCover(props) {
  const { name } = props;
  const myDate = new Date();
  return (
    <div className={style.all}>
      {/* <div className={style.coverLogo} /> */}
      {/* <div className={style.pngjia} /> */}
      <div className={style.subTitle}>
        {name || ''}
        <div className={style.bigTitle}>企业尽调报告</div>
      </div>
      <div className={style.companyName}>上奇研究院</div>
      <div className={style.time}>
        {`${myDate.getFullYear()}年${myDate.getMonth() + 1}月`}
      </div>
    </div>
  );
}

export default FrontCover;
