/*
* Author: zhangmeng
* Description: '堆叠柱状图'
* Date: 2022/03/02
*/

import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const multicolor = ['#1961F5', '#4E88FF', '#59D700', '#FDAD61', '#FD6205'];

/**
 * @param {{
 *   data: any;
 *   disableLegend: boolean;
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
function StackBarChart(props) {
  const { data, disableLegend } = props;
  const [options, setOptions] = useState({});
  useEffect(() => {
    if (Object.keys(data.data).length < 1) return;
    const { data: { xAxis, yAxis, yName } } = data;
    if (!Array.isArray(yAxis)) return;
    const legend = yAxis?.map((item, index) => (
      {
        name: item.name,
        itemStyle: {
          color: `${multicolor[index]}`,
        },
      }
    ));
    const newData = yAxis?.map((item, index) => (
      {
        name: item.name,
        type: 'bar',
        stack: 'total',
        barMaxWidth: 18,
        itemStyle: {
          color: `${multicolor[index]}`,
        },
        emphasis: {
          focus: 'series',
        },
        data: item.data,
      }
    )) ?? [];
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
        },
        backgroundColor: '#fff',
        borderWidth: 0,
        borderColor: '#7B8796',
        textStyle: {
          color: '#7B8796',
        },
      },
      grid: {
        top: 30,
        right: 10,
        bottom: 86,
        left: 60,
      },
      legend: {
        show: !disableLegend,
        bottom: 10,
        itemWidth: 8,
        itemHeight: 10,
        symbol: 'rect',
        textStyle: {
          color: '#7B8796',
          fontSize: 12,
        },
        data: legend,
      },
      xAxis: [
        {
          type: 'category',
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#000000',
            },
          },
          axisLabel: {
            interval: 0,
            // rotate: 55,
            formatter: v => {
              let label = '';
              if (v === '客户区') {
                label = `{curArea|${v}}`;
              } else {
                label = `{otherArea|${v}}`;
              }
              return label;
            },
            rich: {
              curArea: {
                color: '#00F2FE',
                fontSize: 8,
              },
              otherArea: {
                color: '#7B8796',
                fontSize: 10,
              },
            },
          },
          data: xAxis,
        },
      ],
      yAxis: {
        type: 'value',
        name: `(${yName || ''})`,
        splitLine: {
          lineStyle: {
            color: '#EBEBEB',
            type: 'dashed',
          },
        },
      },
      series: [
        ...newData,
      ],
    };
    setOptions(option);
  }, [data, disableLegend]);

  return (
    <ReactEcharts
      option={options}
      notMerge={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default StackBarChart;
