// 对外投资
import React, { useState, useMemo, useEffect } from 'react';
import { ECharts, echarts, Table } from '@sqke/design';
import { Spin, Radio, Space } from 'antd';
import { cloneDeep } from 'lodash';
import style from './index.module.less';
import {
  // useGetOutwardInvestmentQuery,
  useGetStockStrikeSheetQuery,
  useGetCompanyInvestMapQuery,
  useGetCompanyInvestTableQuery,
  useGetInvestIndustryDistributeQuery,
  useGetInvestStockRightsDistributeQuery,
} from '../../../../../service/companyDetail';
import { useLazyGetCityJSONQuery } from '../../../../../service/static-resource';
import HollowPie from '../../../../Charts/HollowPie';
// import { FilterIcon } from '../../../../CustomTableIcon';
import CompanyLevleTag from '../../../../CompanyLevleTag';
import Barandline from '../../../../Charts/BarAndLine';
// import Container from '../../Container';
import { openNewPage } from '../../../../../utils/urlMethods';
import InvestPenetrateTable from './InvestPenetrateTable/index';

const MAP_COLOR = [
  '#8CCAFF', '#70BCFF', '#49AAFF', '#3088F5',
  '#2F73FF', '#1961F5', '#0051F7', '#0044D1',
];
const tagList = [
  { title: '全部', value: '0.3' },
  { title: '1级子公司', value: '2' },
  { title: '2/3级子公司', value: '2.3' },
];
const stortMap = {
  ascend: 'asc',
  descend: 'desc',
};
function OutboundInvestment(props) {
  const { companyId, preventPageto } = props;
  const [option, setOption] = useState({});
  const [sortValue, setSortValue] = useState(null);
  const [active, setActive] = useState('0.3');
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  const [getCityJSON] = useLazyGetCityJSONQuery();
  const { data: mapData, isFetching: isMapFetching } = useGetCompanyInvestMapQuery({
    company_id: companyId,
    penetration_grade: active,
  }, {
    skip: !companyId,
  });
  const { data: companyNumberTableData, isFetching: isCompanyNumberTableDataFetching } = useGetCompanyInvestTableQuery({
    company_id: companyId,
    penetration_grade: active,
  }, {
    skip: !companyId,
  });
  const { data: barData, isFetching: isBarFetching } = useGetInvestIndustryDistributeQuery({
    company_id: companyId,
    penetration_grade: active,
  }, {
    skip: !companyId,
  });
  const { data: peiData, isFetching: isPeiFetching } = useGetInvestStockRightsDistributeQuery({
    company_id: companyId,
    penetration_grade: active,
  }, {
    skip: !companyId,
  });
  const { data: tableData, isFetching: isTableFetching } = useGetStockStrikeSheetQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
    sort_value: sortValue,
  }, {
    skip: !companyId,
  });

  useEffect(() => {
    const onCharData = async () => {
      const newData = await getCityJSON('000000', true);
      const { data: geojson, isError: isGetJSONError } = newData;
      if (isGetJSONError) return;
      echarts.registerMap('china', cloneDeep(geojson));
    };
    onCharData();
    const series = mapData?.series?.map(item => ({
      name: item.name,
      type: 'map',
      geoIndex: 0,
      map: 'china',
      tooltip: {
        formatter: params => {
          const { data: _data } = params;
          if (!_data) return null;
          return `${_data.name}&emsp;&emsp;&emsp;${_data.value}`;
        },
      },
      data: item.data,
    }));
    const seriesData = mapData?.series?.[0]?.data;
    const maxValue = seriesData?.length > 0 ? Math.max(...seriesData.map(item => item.value)) : 0;
    const minValue = seriesData?.length > 0 ? Math.min(...seriesData.map(item => item.value)) : 0;
    setOption({
      geo: {
        map: 'china',
        roam: true,
        scaleLimit: {
          min: 0.8,
        },
      },
      tooltip: {},
      visualMap: [{
        type: 'piecewise',
        min: minValue,
        max: maxValue,
        left: 60,
        bottom: 80,
        splitNumber: 8,
        text: [maxValue, minValue],
        inRange: {
          color: MAP_COLOR,
        },
        seriesIndex: 0,
        itemWidth: 22,
        itemHeight: 6,
        itemGap: 3,
        showLabel: false,
      }],
      series,
    });
  }, [mapData]);

  const tableColumns = useMemo(() => (
    tableData?.columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 70,
        };
      }
      if (item.dataIndex === 'investee') {
        if (preventPageto) {
          return {
            ...item,
            width: 350,
          };
        }
        return {
          ...item,
          width: 350,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('companyDetail', { id: record.investee_id })}
            >
              {text}
            </div>
          ),
        };
      }
      if (item.dataIndex === 'investor' && !preventPageto) {
        if (preventPageto) {
          return {
            ...item,
            width: 350,
          };
        }
        return {
          ...item,
          width: 350,
          render: (text, record) => (
            <div
              className={style.operation}
              onClick={() => text && openNewPage('companyDetail', { id: record.investor_id })}
            >
              {text}
            </div>
          ),
        };
      }
      // if (item.dataIndex === 'level') {
      //   return {
      //     ...item,
      //     width: 130,
      //     filteredValue: filteredInfo,
      //     filterIcon: <FilterIcon />,
      //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, filters, clearFilters }) => (
      //       <div className={style.list}>
      //         <Radio.Group
      //           value={selectedKeys[0]}
      //           options={filters}
      //           // buttonStyle="solid"
      //           style={{ display: 'block' }}
      //           onChange={e => {
      //             setSelectedKeys([e.target.value]);
      //             confirm();
      //           }}
      //         >
      //           <Space direction="vertical">
      //             {
      //               filters.map(it => <Radio key={it.value} value={it.value}>{it.label}</Radio>)
      //             }
      //           </Space>
      //         </Radio.Group>
      //       </div>
      //     ),
      //     filters: [{ label: '不限', value: 0, text: '不限' }, { label: '一级', value: 1, text: '一级' }, { label: '二级', value: 2, text: '二级' }, { label: '三级', value: 3, text: '三级' }],
      //     filterResetToDefaultFilteredValue: true,
      //   };
      // }
      if (item.dataIndex === 'establish_date') {
        return {
          ...item,
          width: 120,
        };
      }
      if (item.dataIndex === 'legal_person') {
        return {
          ...item,
          width: 120,
          ellipsis: true,
        };
      }
      if (item.dataIndex === 'share_ratio') {
        return {
          ...item,
          width: 120,
          sorter: true,
          sortDirections: ['ascend', 'descend'],
        };
      }
      return {
        ...item,
        ellipsis: true,
      };
    })
  ), [tableData, preventPageto]);

  // 投资分布地图
  const chartEl1 = useMemo(() => {
    if (!mapData) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (mapData.series?.length > 0) {
      return (
        <>
          <div className={style.subTitle}>
            {mapData?.title}
          </div>
          <ECharts
            // showLoading={isMapFetching}
            className={style.map}
            // onEvents={{
            //   // 点击地图上的区域
            //   click: (params) => {
            //     console.log(params);
            //   },
            // }}
            option={option}
          />
        </>
      );
    }
    return <></>;
    // return <div className={style.noData}>该企业无此数据图谱</div>;
  }, [mapData, option]);

  const companyNumberColumns = () => companyNumberTableData?.columns.map(item => {
    if (item.dataIndex === 'name') {
      return {
        ...item,
        render: (text, params) => <div className={params.level === 'province' ? style.province : style.city}>{text}</div>,
      };
    }
    return item;
  });

  // const CompanyLevleTag
  const investCompanyTable = () => {
    if (!companyNumberTableData || companyNumberTableData?.dataSource.length) {
      return (
        <div className={style.tagBox}>
          <CompanyLevleTag
            list={tagList}
            active={active}
            setActive={setActive}
          />
          {
            <Table
              rowKey="name"
              locale={{ emptyText: ' ' }}
              dataSource={companyNumberTableData?.dataSource}
              columns={companyNumberColumns()}
              scroll={{ y: 600 }}
              title={() => (
                <>
                  <span>
                    投资企业数
                  </span>
                  <span className={style.count}>
                    {companyNumberTableData?.company_count}
                  </span>
                  家
                </>
              )}
              strip
              pagination={false}
              loading={isCompanyNumberTableDataFetching}
            // pagination={{
            //   current: page.pageNumber,
            //   pageSize: page.pageSize,
            //   total: companyNumberTableData?.count,
            //   showSizeChanger: false,
            //   showQuickJumper: true,
            //   responsive: true,
            //   showLessItems: true,
            // }}
            // onChange={({ current, pageSize }, filters, stort) => {
            //   if (stort.order) {
            //     setSortValue('desc');
            //   } else {
            //     setSortValue(null);
            //   }
            //   if (filters.level) {
            //     setFilteredInfo(filters.level);
            //   }

            //   setPage({
            //     pageSize,
            //     pageNumber: current,
            //   });
            // }}
            />
          }
        </div>
      );
    }
    return null;
  };

  const chartEl2 = useMemo(() => {
    if (!barData) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (barData.series?.length > 0) {
      return (
        <>
          <div className={style.subTitle}>
            {barData?.title}
          </div>
          <div style={{ width: '100%', height: '300px' }}>
            <Barandline data={{ ...barData, right: 60, rotate: 40, bottom: 50 }} contentTop={76} isFetching={isBarFetching} />
          </div>
        </>
      );
    }
    return null;
  }, [barData]);
  const chartEl3 = useMemo(() => {
    if (!peiData) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (peiData.series?.length > 0) {
      return (
        <div>
          <div className={style.subTitle}>
            {peiData?.title}
          </div>
          <div style={{ width: '100%', height: '300px' }}>
            <HollowPie data={peiData?.series[0]} labelName={(params) => `${params.name}\n${params.data.value} 家`} bottom={50} radius={['45%', '60%']} icon="rect" isFetching={isPeiFetching} />
          </div>
        </div>
      );
    }
    return <></>;
    // return <div className={style.noData}>该企业无此数据图谱</div>;
  }, [peiData]);

  const table = useMemo(() => {
    if (!tableData || tableData?.dataSource.length) {
      return <Table
        rowKey={(text, value) => value}
        dataSource={tableData?.dataSource}
        columns={tableColumns}
        title={() => (
          <>
            <span>{tableData?.title}</span>
            <span className={style.count}>{tableData?.count}</span>
          </>
        )}
        strip
        loading={isTableFetching}
        pagination={{
          hideOnSinglePage: true,
          current: page.pageNumber,
          pageSize: page.pageSize,
          total: tableData?.count,
          showSizeChanger: false,
          showQuickJumper: true,
          responsive: true,
          showLessItems: true,
        }}
        onChange={({ current, pageSize }, filters, stort) => {
          if (stort.order) {
            setSortValue(stortMap[stort.order]);
          } else {
            setSortValue(null);
          }
          setPage({
            pageSize,
            pageNumber: current,
          });
        }}
      />;
    }
    return null;
  }, [tableData]);

  return (
    <div className={style.all} id="对外投资">
      <div className={style.title}>对外投资</div>
      <div className={style.investment}>
        <div className={style.mapBox}>
          {
            chartEl1
          }
        </div>
        {
          investCompanyTable()
        }
      </div>
      <div className={style.bottom}>
        <div>
          {
            chartEl2
          }
        </div>
        <div>
          {
            chartEl3
          }
        </div>
      </div>
      {
        table
      }
      <div style={{ marginTop: '22px' }}>
        <InvestPenetrateTable {...props} />
      </div>
    </div>
  );
}
export default OutboundInvestment;
