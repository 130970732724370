/*
 *Description: HeapMapRectangle
 *Date: Sat Nov 06 2021 17:43:08 GMT+0800 (中国标准时间)
*/

import React, { } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { HeapMapRectangleChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, toolTipFormatter, getWordsWidth } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-heap-map-rectangle-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;
const defaultData = {
  xName: '',
  yName: '',
  xAxis: [],
  yAxis: [],
  data: [],
  unit: '',
};
const HeapMapRectangleChart = forwardRef((props: HeapMapRectangleChartProps, ref: any) => {
  const { mode, label = true, legend = false, data = defaultData, title = '', fontSize } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    const { xAxis = [], xName = '', yAxis = [], yName = '', unit = '' } = data;

    const dataInMap: any[] = [];
    let min = Infinity;
    let max = -Infinity;
    yAxis.forEach((_y, yIndex) => {
      xAxis.forEach((_item, xIndex) => {
        let item = data.data[yIndex * xAxis.length + xIndex];
        if (!item) {
          item = 0;
        }
        min = Math.min(+item, min);
        max = Math.max(+item, max);
        const dataItem = {
          name: `${yAxis[yIndex]}:${xAxis[xIndex]}`,
          value: [
            xIndex,
            yIndex,
            item,
          ],
          unit,
        };
        if (item === 0) {
          (dataItem as any).itemStyle = {
            color: '#fff',
          };
        }
        dataInMap.push(dataItem);
      });
    });
    dataInMap.forEach(item => {
      const value = item.value[2];
      const percent = value / max;
      // const colorIndex = Math.round(curStyle.color.length * percent) - 1;
      if (percent < 0.7) {
        item.label = {
          color: 'rgb(13, 59, 102)',
        };
      } else {
        item.label = {
          color: '#fff',
        };
      }
      // item.label.rotate = 45;
    });
    const series: any[] = [{
      name: 'name',
      type: 'heatmap',
      data: dataInMap,
      label: {
        show: true,
        color: '#0D3B66', // curStyle.labelColor,
        fontSize: curFont.labelFontSize,
        position: props.labelPosition || 'inside',
        distance: 0,
        textBorderColor: '#B8FFEF',
        textBorderWidth: 0,
        // textShadowBlur: 1,
        // textShadowColor: '#0D3B66',
        // textShadowOffsetX: -0.5,
        // textShadowOffsetY: -0.5,
        // overflow: 'breakAll',
        formatter(params: any) {
          const { value } = params.data;
          if (!value[2]) {
            return '';
          }
          return value[2];
        },
      },
      labelLayout(arg: any) {
        const { labelRect, rect, text } = arg;
        // 超出边界 缩放字号
        if (labelRect.width >= (rect.width - 2) || labelRect.height >= rect.height) {
          let curSize = curFont.labelFontSize;
          const curStr = `${text}`;
          let curLabelWidth = getWordsWidth(curStr, { font: `${curSize}px sans-serif` });
          while (curLabelWidth > rect.width) {
            curSize -= 1;
            curLabelWidth = getWordsWidth(curStr, { font: `${curSize}px sans-serif` });
            if (curSize === 1) {
              break;
            }
          }
          return {
            fontSize: curSize,
            // x: 1000000,
            // y: 1000000,
          };
        }
        return {};
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: '#eef',
      },
      emphasis: {
        itemStyle: {
          shadowColor: '#2D2D2D',
          shadowOffsetX: -2,
          shadowOffsetY: 4,
          shadowBlur: 6,
        },
      },
    }];
    const gridLeft = 2;

    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // eslint-disable-next-line no-shadow
          const { unit, value, name } = params.data;
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([{ value: value[2], name, unit }], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      grid: {
        // eslint-disable-next-line max-len
        top: 15 + curStyle.yAxis.nameTextStyle.fontSize * ((yName.length / yName.length) || 0), // 1或0
        right: 14 + curStyle.xAxis.nameTextStyle.fontSize * xName.length,
        bottom: 10,
        left: `${gridLeft}%`,
        containLabel: true,
      },
      backgroundColor: '#fff',
      xAxis: {
        type: 'category',
        data: xAxis,
        name: !xAxis.length ? '' : xName,
        nameTextStyle: curStyle.xAxis.nameTextStyle,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#C4C4C4',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          ...curStyle.xAxis.axisLabel,
        },
        splitArea: {
          show: true,
        },
      },
      yAxis: {
        type: 'category',
        name: !yAxis.length ? '' : yName,
        nameTextStyle: curStyle.yAxis.nameTextStyle,
        nameGap: 0,
        data: yAxis,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitArea: {
          show: true,
        },
        axisLabel: {
          ...curStyle.yAxis.axisLabel,
          overflow: 'break-all',
          formatter(value: string) {
            let ans = '';
            for (let i = 0, L = value.length; i < L; i += 1) {
              // 非0  每5个  不是最后一个
              if (i && !((i + 1) % 5) && (L - 1 - i)) {
                ans += (`${value[i]}\n`);
              } else {
                ans += value[i];
              }
            }
            return ans;
          },
        },
      },
      visualMap: {
        min: 0,
        max,
        text: ['', ''],
        realtime: false,
        calculable: true,
        inRange: {
          color: [...curStyle.color].reverse(),
        },
        show: false,
      },
      series,
    };
    setOption(options);
    if (!chartRef.current) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    const realGridWidth = instance.getWidth() - (
      gridLeft * instance.getWidth()
    ) / 100 - options.grid.right;

    const perCellWidth = realGridWidth / xAxis.length;
    // 根据 width 13.011333333333333 fontsize 6 做一个等比例缩放
    let curFontSize = (curFont.labelFontSize / 13.011333333333333) * perCellWidth;
    if (!curFontSize || curFontSize === Infinity) {
      curFontSize = curFont.labelFontSize;
    }
    if (curFontSize > 10) {
      curFontSize = 10;
    }
    let axixFontSize = (curStyle.xAxis.axisLabel.fontSize / 443) * instance.getWidth();

    if (curFontSize > axixFontSize) {
      axixFontSize = curFontSize + 1; // 确保坐标的字号 一定大于 单元格字号；
    }
    if (axixFontSize > 11) {
      axixFontSize = 11;
    }
    options.xAxis.axisLabel.fontSize = axixFontSize;
    options.yAxis.axisLabel.fontSize = axixFontSize;

    // 计算xlabel最大width
    let maxXLabelWidth = -Infinity;
    xAxis.forEach(text => {
      maxXLabelWidth = Math.max(maxXLabelWidth, getWordsWidth(text, { font: `${axixFontSize}px sans-serif` }));
    });

    const realGridHeight = instance.getHeight() - (// 得到有效高度
      options.grid.top + options.grid.bottom + (
        options.yAxis.nameTextStyle.fontSize + options.yAxis.nameTextStyle.padding[2]
      ) + maxXLabelWidth * Math.cos(options.xAxis.axisLabel.rotate)
    );
    const perCellHeight = realGridHeight / yAxis.length;
    const ratio = 0.6;
    if (axixFontSize > perCellHeight * ratio) { // 显示全部y轴标签的时候 使得其大小跟每个单元格大小一致
      options.yAxis.axisLabel.fontSize = perCellHeight * ratio;
      // options.yAxis.axisLabel.lineHeight = perCellHeight * 1.2;
      // options.yAxis.axisLabel.rotate = 25;
    }

    // console.log(curFontSize);
    options.series[0].label.fontSize = curFontSize;
  }, [hanldeData, fontSize, label, legend, mode, title]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 1) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: '100%' }}
        ref={chartRef}
      />
    </div>);
});

export default HeapMapRectangleChart;

HeapMapRectangleChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: false,
  fontSize: 'middle',
};
