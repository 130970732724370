import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { Select } from 'antd';
import {
  useGetParkAreaPlugMarqueeV2Query,
} from '../../../../../../service/parkDetail';
import {
  useGetParkTimeScreeningBoxQuery,
  useGetParkMigrationAnalysisQuery,
} from '../../../../../../service/parkTool';
import {
  jumpCompantDetail,
} from '../../../../../../utils/jumpCompantDetail';
import IndustryMigrationMap from './IndustryMigrationMap';
import InputTreeSelect from './InputTreeSelect';
import SectorCompanyTable from './SectorCompanyTable';
import DistributionRanking from './DistributionRanking';
import EmigrationWarningTable from './EmigrationWarningTable';
import Barandline from '../../../../../../components/Charts/BarAndLine';
import CrosswiseDar from '../../../../../../components/Charts/CrosswiseDar';
import HollowPie from '../../../../../../components/Charts/HollowPie';
import Underline from '../../../../../../components/Underline';
import { StandardModal } from '../../../../../../components/Modal';
import Subtitle from '../../../../../../components/Subtitle';
import { findAllNode } from '../../../../../../utils/tool';
import style from './index.module.less';

const DIRECTLY_CITY = ['110000', '120000', '500000', '310000'];

const TAB_TYPE = {
  emigration: '迁出',
  immigration: '迁入',
};

function MigrationMonitoring(props) {
  const { title, parkCode, parkName, parkTitle, cityCode, cityName } = props;
  const [tab, setTab] = useState('emigration');
  const [area, setArea] = useState([]);
  const [time, setTime] = useState([]);
  const [emigrationTab, setEmigrationTab] = useState('迁出统计');
  const [mapListQueryState, setMapListQueryState] = useState({
    pageSize: 20,
    pageNumber: 1,
    sortedInfo: {},
  });
  const [emigrationTableState, setEmigrationTableState] = useState({
    pageSize: 20,
    pageNumber: 1,
  });
  const [mapCompanyListQueryState, setMapCompanyListQueryState] = useState({
    visible: false,
    areaKey: '',
    areaName: '',
    type: '',
    typeName: '',
    sortedInfo: {},
    filteredInfo: {},
    pageSize: 20,
    pageNumber: 1,
  });
  const [count, setCount] = useState(0);

  const { regionTree = [] } = useGetParkAreaPlugMarqueeV2Query({
    enable: {
      country: '1',
      province: '1',
      city: '1',
      district: '0',
    },
    single_enable: [parkCode],
  }, {
    selectFromResult: ({ data }) => {
      const root = data ? findAllNode(data, node => !node.disabled || node.key === parkCode || DIRECTLY_CITY.indexOf(node.key) > -1) : null;
      if (!root) return [];
      return {
        regionTree: root,
      };
    },
  });

  const { data: timeOption = [] } = useGetParkTimeScreeningBoxQuery();
  useEffect(() => {
    if (timeOption?.[0]?.key) {
      setTime([timeOption?.[0]?.key]);
    }
  }, [timeOption]);

  const { data: chartData, isFetching } = useGetParkMigrationAnalysisQuery({
    area_key: parkTitle === '园区总览' ? parkCode : undefined,
    park_id_list: parkTitle === '园区总览' ? undefined : [parkCode],
    select_area_list: area,
    select_time_scope: time,
    industry_code_list: [],
    move_type: tab,
    project_type: 'park',
  }, { skip: !parkCode || !time || time.length === 0 });
  const generateSeries = (newData) => newData?.map(item => (
    {
      ...item,
      type: item.name.includes('企业增长率') ? 'line' : 'bar',
      yAxisIndex: item.name.includes('企业增长率') ? 'line' : 'bar',
    }
  ));

  return (
    <div className={style.all} id={title}>
      <div className={style.tabs}>
        <div className={clsx(style.tab, { [style.active]: tab === 'emigration' })} onClick={() => { setTab('emigration'); setEmigrationTab('迁出统计'); }}>迁出</div>
        <div className={clsx(style.tab, { [style.active]: tab === 'immigration' })} onClick={() => { setTab('immigration'); setEmigrationTab('迁出统计'); }}>迁入</div>
      </div>
      {
        parkTitle === '园区总览' ? null : (
          <>
            <div className={style.select}>
              {
                useMemo(() => (
                  <InputTreeSelect
                    title={`${TAB_TYPE[tab]}目的地`}
                    data={regionTree}
                    select={area}
                    setSelect={p => {
                      setArea(p);
                    }}
                    ph="请选择区域"
                  />
                ), [tab, regionTree, area, setArea])
              }
              <div className={style.input}>
                <div className={style.label}>时间</div>
                <Select
                  value={time}
                  onChange={(value) => {
                    if (value.length === 0) return;
                    if (value.includes(timeOption?.[0]?.key)) {
                      setTime((pre) => {
                        if (pre.includes(timeOption?.[0]?.key)) {
                          return value.filter(item => item !== pre[0]);
                        }
                        return [timeOption?.[0]?.key];
                      });
                    } else {
                      setTime(value);
                    }
                  }}
                  placeholder="请选择时间"
                  fieldNames={{ label: 'title', value: 'key' }}
                  mode="multiple"
                  options={timeOption}
                  maxTagCount={4}
                />
              </div>
              <div className={style.input}>
                <div className={style.label}>产业</div>
                <Select
                  value="全部"
                  options={[
                    {
                      value: '全部',
                      label: '全部',
                    },
                  ]}
                />
              </div>
            </div>
            <div className={style.main}>
              <IndustryMigrationMap
                tab={tab}
                parkCode={parkCode}
                parkName={parkName}
                cityCode={cityCode}
                cityName={cityName}
                parkTitle={parkTitle}
                areaList={area}
                dateRangeKey={time}
                industryCodeList={[]}
                initialSize="100%"
              />
            </div>
            <div className={style.title} style={{ paddingLeft: 24 }}>
              {TAB_TYPE[tab]}
              分析
            </div>
          </>
        )
      }
      <div className={style.charts}>
        <div className={style.content}>
          <div className={style.chartTitle}>
            {chartData?.[0]?.title}
            {chartData?.[0].subtitle && `(${chartData?.[0].subtitle})`}
          </div>
          <div className={style.chart}>
            <Barandline
              data={{
                ...chartData?.[0],
                leftYName: [0, 30, 0, 0],
                rotate: 30,
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[1]?.title}
            {chartData?.[1].subtitle && `(${chartData?.[1].subtitle})`}
          </div>
          <div className={style.chart}>
            <HollowPie data={chartData?.[1].series[0]} isFetching={isFetching} />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle} >
            {chartData?.[2]?.title}
            {chartData?.[2].subtitle && `(${chartData?.[2].subtitle})`}
          </div>
          <div className={style.chart}>
            <HollowPie data={chartData?.[2].series[0]} isFetching={isFetching} roseType="radius" radius={['20%', '70%']} />
          </div>
        </div>
      </div>
      <div className={style.charts}>
        <div className={style.content}>
          <div className={style.chartTitle}>
            {chartData?.[3]?.title}
            {chartData?.[3].subtitle && `(${chartData?.[3].subtitle})`}
          </div>
          <div className={style.chart}>
            <CrosswiseDar
              data={{
                ...chartData?.[3],
              }}
              top={10}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.chartTitle}>
            {chartData?.[4]?.title}
            {chartData?.[4].subtitle && `(${chartData?.[4].subtitle})`}
          </div>
          <div className={style.chart}>
            <Barandline
              data={{
                ...chartData?.[4],
                series: generateSeries(chartData?.[4]?.series),
                right: 40,
                leftYName: [0, 40, 0, 0],
                rightYName: [0, 0, 0, 32],
              }}
              isFetching={isFetching}
            />
          </div>
        </div>
        <div className={style.content}>
          {
            tab === 'emigration' && (
              <>
                <div className={style.chartTitle} >
                  {chartData?.[5]?.title}
                  {chartData?.[5]?.subtitle && `(${chartData?.[5]?.subtitle})`}
                </div>
                <div className={style.chart}>
                  <HollowPie data={chartData?.[5]?.series?.[0]} isFetching={isFetching} roseType="radius" radius={['20%', '70%']} />
                </div>
              </>
            )
          }
        </div>
      </div>
      <div className={style.table}>
        <div className={style.header}>
          {
            tab === 'emigration' && (
              <div className={clsx(style.tabs, style.emigration)}>
                <div className={clsx(style.tab, { [style.active]: emigrationTab === '迁出统计' })} onClick={() => setEmigrationTab('迁出统计')}>迁出统计</div>
                <div className={clsx(style.tab, { [style.active]: emigrationTab === '迁出预警' })} onClick={() => setEmigrationTab('迁出预警')}>迁出预警</div>
              </div>
            )
          }
          {
            tab === 'immigration' && (
              <div className={clsx(style.tabs, style.emigration)}>
                <div className={clsx(style.tab, style.active)}>迁入统计</div>
              </div>
            )
          }
        </div>
        <Underline />
        {
          emigrationTab === '迁出统计' && (
            <DistributionRanking
              tab={tab}
              parkTitle={parkTitle}
              regionCode={parkCode}
              pageSize={mapListQueryState.pageSize}
              pageNumber={mapListQueryState.pageNumber}
              sortedInfo={mapListQueryState.sortedInfo}
              onChange={({ current }, filter, sorter) => {
                setMapListQueryState(prev => ({
                  ...prev,
                  pageNumber: current,
                  sortedInfo: sorter,
                }));
              }}
              onClick={({ record, column }) => {
                const { area_key: _areaKey, area: _areaName } = record;
                const { dataIndex, title: _typeName } = column;
                setMapCompanyListQueryState(prev => ({
                  ...prev,
                  areaKey: _areaKey,
                  areaName: _areaName,
                  filteredInfo: {},
                  sortedInfo: {},
                  type: [dataIndex],
                  typeName: _typeName,
                  visible: true,
                }));
              }}
            />
          )
        }
        {
          emigrationTab === '迁出预警' && (
            <EmigrationWarningTable
              {...props}
              regionCode={parkCode}
              parkTitle={parkTitle}
              filteredInfo={emigrationTableState.filteredInfo}
              pageSize={emigrationTableState.pageSize}
              pageNumber={emigrationTableState.pageNumber}
              onChange={({ current }, filter) => {
                setEmigrationTableState(prev => ({
                  ...prev,
                  pageNumber: current,
                  filteredInfo: filter,
                }));
              }}
              onCompanyClick={({ company_id: _id }) => {
                if (!_id) return;
                jumpCompantDetail(_id);
              }}
            />
          )
        }
        {
          mapCompanyListQueryState.visible && (
            <StandardModal
              className={style.emigrationModal}
              header={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#0F2849' }}>
                    15年至今
                    {
                      TAB_TYPE[tab] === '迁出' ? (
                        <span style={{ color: '#1961F5' }}>
                          {TAB_TYPE[tab]}
                          到
                          {mapCompanyListQueryState.areaName}
                        </span>
                      ) : (
                        <span style={{ color: '#1961F5' }}>
                          自
                          {mapCompanyListQueryState.areaName}
                          {TAB_TYPE[tab]}
                        </span>
                      )
                    }
                    {mapCompanyListQueryState.typeName}
                  </span>
                  <Subtitle
                    className={clsx(style.subtitle, style.modalTitle)}
                    description="共"
                    value={count}
                    unit="家"
                  />
                </div>
              }
              onClose={() => {
                setMapCompanyListQueryState(prev => ({
                  ...prev,
                  pageNumber: 1,
                  visible: false,
                }));
              }}
            >
              <SectorCompanyTable
                tab={tab}
                parkCode={parkCode}
                parkTitle={parkTitle}
                areaKey={mapCompanyListQueryState.areaKey}
                pageSize={mapCompanyListQueryState.pageSize}
                pageNumber={mapCompanyListQueryState.pageNumber}
                companyTypes={mapCompanyListQueryState.type}
                onChange={({ current, pageSize }, filter, sorter) => {
                  setMapCompanyListQueryState({
                    ...mapCompanyListQueryState,
                    pageSize,
                    pageNumber: current,
                    filteredInfo: filter,
                    sortedInfo: sorter,
                  });
                }}
                filteredInfo={mapCompanyListQueryState.filteredInfo}
                sortedInfo={mapCompanyListQueryState.sortedInfo}
                onCompanyClick={({ company_id: _id }) => {
                  if (!_id) return;
                  jumpCompantDetail(_id);
                }}
                getCount={setCount}
              />
            </StandardModal>
          )
        }
      </div>
    </div>
  );
}
export default MigrationMonitoring;
