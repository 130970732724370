import { IPieVisualConfig, IRadarVisualConfig, IWordCloudVisualConfig } from '../interface/config';
import { getLabel, omitUndefined } from './utils';
import { ISeriesGetter } from '../interface/base';

/**
 * 获取饼图的其他配置
 * @param visualConfig
 */
export const getEach = (visualConfig: IPieVisualConfig) => {
  const {
    rose,
    roseType,
    label,
    labelLine,
    labelLayout,
    itemStyle,
    // center,
    radius,
    clockwise,
    startAngle,
    top,
    bottom,
    left,
    right,
  } = visualConfig;

  return omitUndefined({
    label: getLabel(label),
    labelLine,
    labelLayout,
    itemStyle,
    // center,
    radius,
    clockwise,
    startAngle,
    top,
    bottom,
    left,
    right,
    ...(rose ? { roseType: roseType ?? 'radius' } : {}),
  });
}

export const getPieSeries: ISeriesGetter<IPieVisualConfig> = (series: any, index, extra) => {
  const { material } = extra;
  const { config } = material;
  const { visualConfig } = config;
  
  // 单独处理center以支持双饼图
  if (visualConfig?.center && Array.isArray(visualConfig?.center?.[0])) {
    series.center = visualConfig?.center?.[index];
  }
  if (visualConfig?.center && !Array.isArray(visualConfig?.center?.[0])) {
    series.center = visualConfig?.center;
  }
  return omitUndefined({
    ...series,
    ...getEach(visualConfig as IPieVisualConfig),
  });
};
