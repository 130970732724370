import React, { useEffect, useMemo, useState } from 'react';
import { Input, Tree, Select, Spin } from 'antd';
import { ECharts } from '@sqke/design';
import { LarkMap, PointLayer, CustomControl, LegendCategories, Popup } from '@antv/larkmap';
import { openNewPage } from '../../../../utils/urlMethods';
import { barCrosswiseOption, generateColumns, colorData } from './utils';
import {
  useGetCompanyListQuery,
  useGetLayoutConditionQuery,
  useGetSubsidiaryLayoutQuery,
  useGetUrbanDistributionQuery,
  useGetIndustrialDistributionQuery,
} from '../../../../service/special';
import { getAllCity, getAllIndustry } from '../../../../api/knowledgeEngine';
import Table from '../../components/Table';
import style from './index.module.less';

const mapConfig = {
  mapType: 'Gaode',
  logoVisible: false,
  mapOptions: {
    zoom: 2.7,
    token: '31e1ee90b55cae0838e65a2da758d2bd',
    style: 'light',
    center: [116.211004, 39.912758],
  },
};
const mapOptions = {
  autoFit: false,
  shape: {
    field: 'shape',
    value: ({ shape }) => shape,
    scale: { type: 'quantile' },
  },
  size: {
    field: 'size',
    value: ({ size }) => size,
  },
  color: {
    field: 'color',
    value: ({ color }) => color,
  },
  state: {
    active: true,
  },
  style: {
    opacity: 1,
    // stroke: 'rgba(16, 181, 162, 0.8)',
    // strokeWidth: 4,
    // strokeOpacity: 0.5,
    // blur: 4,
    // blur
  },
  // animate: {
  //   enable: true,
  //   rings: 2,
  // },
};

const Ranking = ({ name }) => {
  const [barCrosswiseHeight, setBarCrosswiseHeight] = useState('100%');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [areaFilter, setAreaFilter] = useState([]);
  const [industryCode, setIndustryCode] = useState('');
  const [industryList, setIndustryList] = useState([]);
  const [mapIndustryCode, setMapIndustryCode] = useState(['all']);
  const [mapIndustry, setMapIndustry] = useState('全部');
  const [regionCode, setRegionCode] = useState('');
  const [searchText, setSearchText] = useState('');
  const [newSearch, setNewSearch] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [mapCompanyName, setMapCompanyName] = useState('');
  const [mapCompanyLngLat, setMapCompanyLngLat] = useState({});
  const [mapCompanyId, setMapCompanyId] = useState('');
  const [subsidiaryLevel, setSubsidiaryLevel] = useState('全部');
  const [selectIndex, setSelectIndex] = useState(1);
  const [companyId, setCompanyId] = useState(null);
  const [mapKey, setMapKey] = useState(0);
  const [source, setSource] = useState({
    data: [],
    parser: { type: 'json', x: 'lng', y: 'lat' },
  });
  useEffect(() => {
    getAllCity({ area_key: '000000' }).then(res => {
      if (res.status === '00000') {
        setAreaFilter(res.data);
      }
    });
    getAllIndustry().then(res => {
      if (res.status === '00000') {
        setIndustryList([res.data]);
      }
    });
  }, []);

  // 公司布局输入框 防抖
  useEffect(() => {
    const time = setTimeout(() => {
      setNewSearch(searchText);
    }, 500);
    return () => {
      clearInterval(time);
    };
  }, [searchText]);

  // 获取列表数据
  const { data, isFetching: tableLoading } = useGetCompanyListQuery({
    label: name,
    industry_code_list: industryCode,
    region_code: regionCode,
    page_size: pageSize,
    page_number: page,
    keyword: newSearch,
  });
  // 获取布局情况
  const { data: layoutDescribe } = useGetLayoutConditionQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });
  // 获取子公司布局地图分布
  const { data: mapData, isFetching: mapLoading } = useGetSubsidiaryLayoutQuery({
    company_id: companyId,
    subsidiary_level: subsidiaryLevel,
    industry_code_list: mapIndustryCode,
  }, {
    skip: !companyId,
  });
  // 柱状图
  const { data: berChartData, isFetching: berloading } = useGetUrbanDistributionQuery({
    company_id: companyId,
    subsidiary_level: subsidiaryLevel,
    industry_code_list: mapIndustryCode,
  });
  // 矩形树图
  const { data: rectangleData, isFetching: rectangleLoading } = useGetIndustrialDistributionQuery({
    company_id: companyId,
    subsidiary_level: subsidiaryLevel,
  });
  useEffect(() => {
    if (mapData) {
      const newMapData = mapData.series[0]?.data?.map(item => {
        const shape = item?.level === '总部' ? 'hexagram' : 'circle';
        return {
          ...item,
          lng: item?.value[0],
          lat: item?.value[1],
          size: item?.level === '总部' ? [20, 30] : 6,
          shape,
          id: item._id,
          color: colorData[item?.level],
        };
      });
      if (mapKey >= 10) {
        setMapKey(1);
      } else {
        setMapKey(it => it + 1);
      }
      setSource({ data: newMapData, parser: { type: 'json', x: 'lng', y: 'lat' } });
    }
  }, [mapData]);
  const { columns = [], dataSource = [] } = data || {};
  // 生成公司布局列表的 columns
  const layoutColumns = useMemo(() => columns.map(item => (
    generateColumns({
      item,
      areaFilter,
      setIndustryCode,
      setRegionCode,
      industryList,
      industryCode,
      setPage,
    })[item.dataIndex]))
    .filter(it => it),
  [columns, areaFilter, industryList]);

  // 动态计算柱状图的宽度
  useEffect(() => {
    setBarCrosswiseHeight((`${berChartData?.x?.length * 12 + (berChartData?.x?.length / 2) * 12 + 120}px`));
  }, [berChartData]);

  useEffect(() => {
    if (data?.dataSource?.length > 0 && !searchText) {
      setSelectIndex(1);
      setCompanyName(data?.dataSource[0].company_name);
      setCompanyId(data?.dataSource[0]?.company_id);
    }
    if (searchText) {
      setSelectIndex('');
    }
  }, [data]);

  // 高亮选中行
  const getRowClassName = (record, index) => {
    let className = '';
    const indexNum = index + 1;
    className = indexNum === selectIndex
      ? style.selectedRow : '';
    return className;
  };
  // 地图选择产业
  const onSelectRegion = (selectedKeys, info) => {
    setMapIndustryCode(selectedKeys);
    setMapIndustry(info.node.title);
    setMapCompanyLngLat({});
  };
  // 点击地图上的企业
  const mapHandelClick = (e) => {
    const { feature, lngLat } = e;
    setMapCompanyName(feature.name);
    setMapCompanyLngLat(lngLat);
    setMapCompanyId(feature.id);
  };
  return (
    <ul className={style.tableAndMap}>
      <li className={style.table}>
        <div className={style.tableHeader}>
          <p>公司布局</p>
          <div>
            <Input
              value={searchText}
              onChange={(e) => { setSearchText(e.target.value); }}
            />
            <div className={style.selectIcon} />
          </div>
        </div>
        <Spin spinning={tableLoading}>
          <div className={style.tableBody}>
            <Table
              rowKey="index"
              columns={layoutColumns}
              dataSource={dataSource}
              responsive
              rowClassName={getRowClassName}
              onRow={(record, index) => ({
                onClick: () => {
                  // 设置选中的index
                  setSelectIndex(index + 1);
                  setCompanyId(record.company_id);
                  setCompanyName(record.company_name);
                  setMapIndustry('全部');
                  setSubsidiaryLevel('全部');
                  setMapCompanyLngLat({});
                },
              })}
              pagination={{
                current: page,
                pageSize: 30,
                showQuickJumper: true,
                showSizeChanger: false,
                total: data?.count,
                onChange: (current) => {
                  setPage(current);
                },
                onShowSizeChange: (size) => {
                  setPageSize(size);
                },
              }}
            />
          </div>
        </Spin>
      </li>
      <li className={style.right}>
        <div>
          <div className={style.rightHeader}>
            {companyName}
          </div>
          <div className={style.rightText}>
            <p>
              <span />
              <span>
                总部位于
                <span>
                  {layoutDescribe?.pool['总部位置']}
                  ,
                </span>
                一级子公司
                <span>{layoutDescribe?.pool['一级子公司数量']}</span>
                家,
                二级子公司
                <span>{layoutDescribe?.pool['二级子公司数量']}</span>
                家,
                三级及以下
                <span>{layoutDescribe?.pool['三级子公司数量']}</span>
                家,
                子公司总数量
                <span>{layoutDescribe?.pool['子公司总数量']}</span>
                家。
              </span>
            </p>
            <p>
              <span />
              <span>
                子公司分布在全国
                <span>{layoutDescribe?.pool['有布局的城市']}</span>
                个城市,排名前三的城市：
                {
                  layoutDescribe?.pool['排名前三的城市'].map(item => (
                    <span key={item.city_name}>
                      {item.city_name}
                      {item.count}
                      家、
                    </span>
                  ))
                }
              </span>
            </p>
          </div>
          <div className={style.optionBox}>
            <p>产业:</p>
            <Select
              value={mapIndustry}
              style={{
                width: 220,
                marginRight: 12,
              }}
              dropdownRender={() => (
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                  <Tree
                    onSelect={onSelectRegion}
                    treeData={industryList}
                    defaultExpandedKeys={industryList.length > 0 ? [industryList[0].key] : []}
                  />
                </div>
              )}
            />
            <p>级别:</p>
            <Select
              value={subsidiaryLevel}
              style={{
                width: 220,
                // marginLeft: 12,
              }}
              onChange={(value) => {
                setSubsidiaryLevel(value);
                setMapCompanyLngLat({});
              }}
              options={[
                {
                  value: '全部',
                  label: '全部',
                },
                {
                  value: '一级子公司',
                  label: '一级子公司',
                },
                {
                  value: '二级子公司',
                  label: '二级子公司',
                },
                {
                  value: '三级子公司',
                  label: '三级子公司',
                },
              ]}
            />
          </div>
          <div className={style.mapTitle}>
            共计
            {subsidiaryLevel === '全部' ? '子公司' : subsidiaryLevel}
            {mapData?.pool?.['所选级别子公司数量']}
            家
            {
              mapIndustry !== '全部' && (
                <span>
                  ，
                  {mapIndustry}
                  产业
                  {mapData?.pool?.['所选产业子公司数量']}
                  家
                </span>
              )
            }

          </div>
          <Spin spinning={mapLoading}>
            <div
              className={style.map}
              key={mapKey}
            >
              <LarkMap
                {...mapConfig}
                style={{ height: '100%', width: '100%' }}
              >
                <PointLayer
                  {...mapOptions}
                  source={source}
                  onClick={e => { mapHandelClick(e); }}
                />
                {
                  Object.keys(mapCompanyLngLat).length && (
                    <div
                      onMouseOut={(e) => {
                        setMapCompanyLngLat({});
                      }}
                      onBlur={(e) => { console.log(e); }}
                    >
                      <Popup
                        lngLat={mapCompanyLngLat}
                        closeButton={false}
                      >
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            openNewPage('companyDetail', { id: mapCompanyId });
                          }}
                        >
                          {mapCompanyName}
                        </div>
                      </Popup>
                    </div>
                  )
                }
                <CustomControl position="bottomright">
                  <LegendCategories
                    colors={Object.values(colorData)}
                    labels={Object.keys(colorData)}
                    // icons={['https://gw.alipayobjects.com/mdn/rms_5e897d/afts/img/A*6ONoRKNECC0AAAAAAAAAAAAAARQnAQ', <StepBackwardOutlined />, <StepBackwardOutlined />, <StepBackwardOutlined />]}
                    style={{
                      padding: 8,
                      border: '1px solid #FFF',
                      borderRadius: '2px',
                      background: 'rgba(255,255,255,0.43)',
                    }}
                  />
                </CustomControl>
              </LarkMap>
            </div>
          </Spin>
        </div>

        <ul className={style.chart} key={mapKey}>
          <li>
            <p className={style.chartTitle}>{berChartData?.title}</p>
            <Spin spinning={berloading}>
              <div className={style.bar}>
                <ECharts
                  style={{
                    height: barCrosswiseHeight,
                    width: '100%',
                  }}
                  option={barCrosswiseOption(berChartData)}
                />
              </div>
            </Spin>
          </li>
          <li>
            <p className={style.chartTitle}>{rectangleData?.title}</p>
            <Spin spinning={rectangleLoading}>
              <div className={style.treemap}>
                <ECharts
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  option={{
                    tooltip: {},
                    series: [
                      {
                        type: 'treemap',
                        width: '90%',
                        height: '90%',
                        visibleMin: 30,
                        childrenVisibleMin: 50,
                        data: rectangleData?.series[0]?.data?.map(item => ({ ...item })),
                        itemStyle: {
                          gapWidth: 0,
                          borderWidth: 0,
                          // color: (evt) => { console.log(evt); return 'pink'; },
                        },
                        label: {
                          fontSize: 10,
                          overflow: 'truncate',
                        },
                      },
                    ],
                  }}
                />
              </div>
            </Spin>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default Ranking;
