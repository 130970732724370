export const getSelectCompareVlaue = (value, fnArray) => {
  const newVlaueObj = fnArray.find((item) => item.title === value);
  return newVlaueObj.value;
}
export const getSelectCompareTitle = (value, fnArray) => {
  console.log(fnArray);
  if (fnArray.length > 0) {
    const newVlaueObj = fnArray.find((item) => item.value === value);
    console.log(newVlaueObj, newVlaueObj?.title);
    return newVlaueObj?.title;
  }
}