/*
 *Author: zhaochenyu
 *Description: 思维导图样式
 *Date: 2022/01/06
*/

const modeStyle = {
  light: {
    toDataURLBackground: '#fff',
    centerColor: '#fff',
    centerBorderColor: '#0D3B66',
    centerBackground: '#4E7293',
    childNodeColor: '#333',
    childNodeBackground: '#fff',
    childNodeBorderColor: ['#4E7293', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#EAD1C1', '#CBC7DB', '#E9C582', '#EE964B', '#D5671D', '#C54646',
      '#CB997E', '#E0C7C7', '#CCCCBA', '#D8E1E9', '#BCB3DF', '#CE7070', '#DBACBD', '#B56D2F', '#D5671D', '#FFC49B', '#D2D278', '#B4B471',
      '#30A170', '#5B7868', '#57BDA7', '#B5F2E5', '#93D4ED', '#839EDA', '#5540A9', '#9070AA', '#C474A3', '#E58080', '#8C6C6C', '#C8B0B0',
      '#E7E7E7', '#F2E5D1', '#D6D3B4', '#B4D2D6', '#B5BFBB'],
    connectLineColor: '#4E7293',
  },
  dark: {
    toDataURLBackground: '#fff',
    centerColor: '#fff',
    centerBorderColor: '#0D3B66',
    centerBackground: '#4E7293',
    childNodeColor: '#333',
    childNodeBackground: '#fff',
    childNodeBorderColor: ['#4E7293', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#EAD1C1', '#CBC7DB', '#E9C582', '#EE964B', '#D5671D', '#C54646',
      '#CB997E', '#E0C7C7', '#CCCCBA', '#D8E1E9', '#BCB3DF', '#CE7070', '#DBACBD', '#B56D2F', '#D5671D', '#FFC49B', '#D2D278', '#B4B471',
      '#30A170', '#5B7868', '#57BDA7', '#B5F2E5', '#93D4ED', '#839EDA', '#5540A9', '#9070AA', '#C474A3', '#E58080', '#8C6C6C', '#C8B0B0',
      '#E7E7E7', '#F2E5D1', '#D6D3B4', '#B4D2D6', '#B5BFBB'],
    connectLineColor: '#4E7293',
  },
};

export {
  modeStyle,
};
