/*
*Description: 样式文件
*/
import * as CONSTS from '../../consts';

const chartFontSize = {
  small: {
    legnedFontSize: 10,
    labelFontSize: 10,
  },
  middle: {
    legnedFontSize: 12,
    labelFontSize: 12,
  },
  large: {
    legnedFontSize: 14,
    labelFontSize: 14,
  },
};

const modeStyle = {
  light: {
    toDataURLBackground: CONSTS.toDataURLBackgroundLight,
    color: ['#0D3B66', '#4E7293', '#A4C3B2', '#C5D5EA', '#CCE3DE', '#EAD1C1', '#E9C582', '#CBC7DB', '#EE964B'],
    labelColor: '#0D3B66',
    labelBgdColor: '#E2EEFF',
    labelBorderColor: '#C5D5EA',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#3E5C76', '#3E5C76', '#262D36', '#4E7293'],
    tooltipBackground: '#fff',
    tooltipBorder: '#DCDCDC',
    tooltipBoxShandow: 'rgba(108,117,125,0.32)',

    xAxisLabelColor: '#777A7A',
    xAxisLineColor: 'rgba(0, 0, 0, 0.45)',
    yAxisLabel: {
      color: '#777A7A',
    },
    yNameTextStyle: {
      color: '#919191',
    },
    ySplitLine: {
      color: '#eee',
    },
    series: {
      label: {
        color: '#262c35',
      },
    },
    itemStyle: {
      bar: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0, color: '#4E7293',
            },
            {
              offset: 1, color: '#4E7293',
            },
          ],
        },
        colorList: ['#0D3B66', '#3E5C76', '#A9BDD5', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#CCCCBA', '#FFF5E1', '#EAD1C1', '#CBC7DB'],
        colorList1: ['#0D3B66', '#3E5C76', '#A9BDD5', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#CCCCBA', '#FFF5E1', '#EAD1C1', '#CBC7DB'],
      },
    },
  },
  dark: {
    toDataURLBackground: CONSTS.toDataURLBackgroundDark,
    color: ['#00F2FE', '#3E83F7', '#1D16F4', '#5170CA', '#390D97', '#E65226', '#FEC52A'],
    labelColor: '#fff',
    labelBgdColor: 'rgba(27, 2, 253, 0.3)',
    labelBorderColor: 'transparent',
    labelLineColor: '#4E7293',
    legendTextColor: '#919191',
    legendColor: '#919191',
    tooltipFont: ['#fff', '#fff', '#fff', '#fff'],
    tooltipBackground: 'rgba(3, 0, 97, 0.5)',
    tooltipBorder: 'rgba(28, 22, 244, 0.5)',
    tooltipBoxShandow: 'rgba(3, 0, 30, 1)',

    xAxisLabelColor: '#777A7A',
    xAxisLineColor: 'rgba(0, 0, 0, 0.45)',
    yAxisLabel: {
      color: '#777A7A',
    },
    yNameTextStyle: {
      color: '#919191',
    },
    ySplitLine: {
      color: '#eee',
    },
    series: {
      label: {
        color: '#262c35',
      },
    },
    itemStyle: {
      bar: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0, color: '#4E7293',
            },
            {
              offset: 1, color: '#4E7293',
            },
          ],
        },
        colorList: ['#0D3B66', '#3E5C76', '#A9BDD5', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#CCCCBA', '#FFF5E1', '#EAD1C1', '#CBC7DB'],
        colorList1: ['#0D3B66', '#3E5C76', '#A9BDD5', '#C5D5EA', '#A4C3B2', '#CCE3DE', '#CCCCBA', '#FFF5E1', '#EAD1C1', '#CBC7DB'],
      },
    },
  },
};

export {
  chartFontSize,
  modeStyle,
};
