/*
* Author: zhangmeng
* Description: '详情头部'
* Date: 2021/07/13
*/

import React from 'react';
import detailBackg from '../../../../assets/images/researchReport/detailBackg.png';
import { IconFont } from '../../../../utils/mapIcon';
// import { companyTabsColorMap } from '../../../../utils/color';
import { tagStyleMap } from '../../../../utils/tagColor';
import { reportResearchRead, reportResearchDownload } from '../../../../api/researchReportDetail';
import style from './index.module.less';

function TopInfo(props) {
  const { data, id } = props;
  const {
    title, tags, page_size: pageSize, view_times: viewTimes, download_times: downloadTimes,
    publish_org: publishOrg, publish_date: publishDate, value_grade: valueGrade,
    author,
  } = data;
  const overViewId = new URLSearchParams(window.location.href.split('?')[1]).get('id');

  const viewOnline = (p) => {
    reportResearchRead({
      report_id: p || overViewId,
    }).then(res => {
      if (res.status === '00000') {
        window.open(res.data.link);
      }
    });
  };

  const download = (name) => {
    reportResearchDownload({
      report_id: id || overViewId,
    }, name);
  };

  return (
    <div className={style.all}>
      <div className={style.left}>
        <img src={detailBackg} alt="" />
      </div>
      <div className={style.right}>
        <div className={style.titleRow}>
          <span className={style.title}>{title}</span>
          <div className={style.info}>
            <span className={style.page}>
              <IconFont type="icon16" />
              {pageSize}
              &nbsp;页
            </span>
            <span className={style.view}>
              <IconFont type="iconico_yueduliang" />
              {viewTimes}
              &nbsp;次
            </span>
            <span className={style.download}>
              <IconFont type="iconxiazai" />
              {downloadTimes}
              &nbsp;次
            </span>
          </div>
        </div>
        <div className={style.tagContainer}>
          {
            tags && tags.map((item, index) => (
              item.tabs.map((item1, index1) => (
                <span
                  key={`${index}${index1}${item1}`}
                  className={`${style.tag} ${tagStyleMap[item.type]}`}
                >
                  {item1}
                </span>
              ))
            ))
          }
        </div>
        <div className={style.overview}>
          <div className={style.row}>
            <span className={style.label}>发布日期</span>
            <span className={style.value}>{publishDate}</span>
          </div>
          <div className={style.row}>
            <span className={style.label}>研报价值分</span>
            <span className={style.value}>
              {valueGrade ? `${valueGrade} 分` : '暂无'}
            </span>
          </div>
          <div className={style.row}>
            <span className={style.label}>发布机构</span>
            <span className={style.value}>{publishOrg}</span>
          </div>
          <div className={style.row}>
            <span className={style.label}>作者</span>
            <span className={style.value}>{author}</span>
          </div>
        </div>
        <div className={style.btnGroup}>
          <span className={style.viewOnline} onClick={() => viewOnline(id)}>在线浏览</span>
          <span className={style.download} onClick={() => download(title)}>下载</span>
        </div>
      </div>
    </div>
  );
}

export default TopInfo;
