import React from 'react';
import { useHistory } from 'react-router-dom';
import style from './index.module.less';
import Underline from '../../../../../components/Underline';

const Index = ({ addUserCompanyContrastCollect }) => {
  const history = useHistory();
  return (
    <div className={style.box}>
      <ul>
        <li>企业对标</li>
        <li />
        <li>
          <button type="button" onClick={addUserCompanyContrastCollect}>收藏</button>
        </li>
        <li>
          <button type="button" onClick={() => { history.push('/tool/firmComparison/collect'); }}>查看更多收藏</button>
        </li>
      </ul>
      <Underline />
    </div>
  );
};

export default Index;
