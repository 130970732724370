/*
* Author: zhangmeng
* Description: ''
* Date: 2020/12/28
*/

import React, { useCallback, useState } from 'react';
// import { companyTabsColorMap } from '../../utils/color';
import { tagStyleMap } from '../../utils/tagColor';
import style from './index.module.less';

function NewListLable(props) {
  // eslint-disable-next-line no-unused-vars
  const { labels, type } = props;
  const [arr, setArr] = useState(null);

  const labelsBox = useCallback(node => {
    if (node && node.scrollHeight) {
      setArr(node.scrollHeight);
    }
  }, [labels]);

  return (
    <div className={style.box}>
      <div
        className={style.tabs}
        ref={labelsBox}
        style={{
          overflow: arr > 28 ? 'hidden' : '',
          height: arr > 28 ? ' 22px' : 'auto',
        }}
      >
        {
          labels && labels.length > 0 && labels.map((item, index) => (
            typeof item === 'string'
              ? (
                <div
                  key={`${item}${index}}`}
                  className={style.tab}
                >
                  {item}
                </div>
              )
              : item.tabs.map((item1, index1) => (
                <div
                  key={`${item1}${index1}${index}}`}
                  className={`${style.tab} ${tagStyleMap[item.type]}`}
                >
                  {item1}
                </div>
              ))
          ))
        }
      </div>
      <div className={style.omit} style={{ display: arr > 28 ? '' : 'none' }}>...</div>
    </div>
  );
}

export default NewListLable;
