import React, { useMemo, useState } from 'react';
import { Table } from '@sqke/design';
import Barandline from '../../../../../Charts/BarAndLine';

import { useGetMarketValueAtlasQuery, useGetMarketValueTableQuery } from '../../../../../../service/companyDetail';
import style from './index.module.less';

const MarkeValue = ({ companyId }) => {
  const [page, setPage] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  // 图
  const { data: chartData, isFetching: chartIsFetching } = useGetMarketValueAtlasQuery({
    company_id: companyId,
  }, { skip: !companyId });
  // 表
  const { data: tableData, isFetching: tableIsFetching } = useGetMarketValueTableQuery({
    company_id: companyId,
    page_size: page.pageSize,
    page_number: page.pageNumber,
  }, { skip: !companyId });

  const chart = useMemo(() => {
    if (!chartData || chartData?.series?.length > 0) {
      const newData = chartData?.series.map(item => {
        if (item.name === '市值') {
          return {
            ...item,
          };
        }
        return {
          ...item,
          type: 'line',
        };
      });
      return (
        <div className={style.chart}>
          <Barandline data={{ ...chartData, series: newData, right: 60, left: 80, rotate: 40, bottom: 78 }} contentTop={76} isFetching={chartIsFetching} />
        </div>
      );
    }
    return <></>;
  }, [chartData]);
  const { columns, dataSource } = tableData || {};
  const _columns = useMemo(() => {
    if (!columns) return [];
    return columns.map(item => {
      if (item.dataIndex === 'index') {
        return {
          ...item,
          width: 160,
          ellipsis: true,
        };
      }
      return { ...item, ellipsis: true };
    });
  }, [columns]);

  const table = useMemo(() => {
    if (!dataSource || dataSource.length) {
      return (
        <div className={style.table}>
          <Table
            rowKey="_id"
            dataSource={dataSource}
            columns={_columns}
            // title={() => tableData?.title}
            strip
            loading={tableIsFetching}
            pagination={{
              hideOnSinglePage: true,
              current: page.pageNumber,
              pageSize: page.pageSize,
              total: tableData?.auth_count,
              showSizeChanger: false,
              showQuickJumper: true,
              responsive: true,
              showLessItems: true,
            }}
            onChange={({ current, pageSize }) => {
              setPage({
                pageSize,
                pageNumber: current,
              });
            }}
          />
        </div>

      );
    }
    return null;
  }, [tableData]);
  return (
    <div className={style.markeValue}>
      {
        chart
      }
      {
        table
      }
    </div>
  );
};

export default MarkeValue;
