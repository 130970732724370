// 市场反馈
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import style from './index.module.less';
import { useGetMarketFeedbackQuery } from '../../../../../service/companyDetail';

function MarketFeedback(props) {
  const { companyId } = props;

  const { data, isFetching } = useGetMarketFeedbackQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });
  const { tags_number: tagsNumber, winning_the_bid: bidWinning } = data ?? {};

  const tagsNumberOption = useMemo(() => {
    const series = tagsNumber?.series?.map(item => (
      {
        name: item.name,
        type: 'line',
        data: item.data,
        areaStyle: {},
      }
    ));

    return {
      xAxis: {
        // name: tagsNumber?.xName,
        // nameTextStyle: {
        //   padding: [0, 0, 0, -10],
        // },
        type: 'category',
        data: tagsNumber?.x,
      },
      tooltip: {},
      legend: {
        bottom: 0,
        itemGap: 30,
      },
      yAxis: {
        name: `${tagsNumber?.yName}(${tagsNumber?.series?.[0]?.unit})`,
        nameTextStyle: {
          padding: [0, 0, 0, 20],
        },
      },
      series,
    };
  }, [tagsNumber]);

  const bidWinningOption = useMemo(() => {
    const series = bidWinning?.series?.map(item => (
      {
        name: item.name,
        type: item.name === '中标次数' ? 'line' : 'bar',
        data: item.data,
        areaStyle: item.type === 'line' ? {} : null,
        yAxisIndex: item.name === '中标次数' ? 1 : 0,
      }
    ));

    return {
      xAxis: {
        // name: bidWinning?.xName,
        // nameTextStyle: {
        //   padding: [0, 0, 0, -10],
        // },
        type: 'category',
        data: bidWinning?.x,
      },
      tooltip: {},
      legend: {
        bottom: 0,
        itemGap: 30,
      },
      yAxis: [
        {
          name: `${bidWinning?.yName}(${bidWinning?.series?.[0]?.unit})`,
          nameTextStyle: {
            padding: [0, 10, 0, 0],
          },
        },
        {
          name: `${bidWinning?.yName1}(${bidWinning?.series?.[1]?.unit})`,
          position: 'right',
          nameTextStyle: {
            padding: [0, 10, 0, 0],
          },
        },
      ],
      series,
    };
  }, [bidWinning]);

  const chartEl1 = useMemo(() => {
    if (!tagsNumber) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (tagsNumber.series?.length > 0 && tagsNumber.x?.length) {
      return (
        <>
          <div className={style.subTitle}>
            {tagsNumber?.title}
          </div>
          <ECharts
            showLoading={isFetching}
            className={style.chart}
            option={tagsNumberOption}
          />
        </>
      );
    }
    return null;
  }, [tagsNumber]);
  const chartEl2 = useMemo(() => {
    if (!bidWinning) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (bidWinning.series?.length > 0) {
      return (
        <>
          <div className={style.subTitle}>
            {bidWinning?.title}
          </div>
          <ECharts
            showLoading={isFetching}
            className={style.chart}
            option={bidWinningOption}
          />
        </>
      );
    }
    return null;
  }, [bidWinning]);

  return (
    <div className={style.all} id="市场反馈">
      <div className={style.title}>市场反馈</div>
      {
        chartEl1
      }
      {
        chartEl2
      }
    </div>
  );
}

export default MarketFeedback;
