/*
 *Author: zhaochenyu
 *Description: 区域画像预览页
 *Date: 2021/11/17
*/

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Divider, Spin, Button, message, Progress, Modal, Alert } from 'antd';
import { DownOutlined, ScanOutlined } from '@ant-design/icons';
import QRcode from 'qrcode';
import cloneDeep from 'lodash/cloneDeep';
// import dayjs from 'dayjs';
import { getReportMsg, downloadSystemTemplateReport, checkWordCreated } from '../../api/office';
import { base64toFile } from '../../utils/report';
import { openNewPage } from '../../utils/urlMethods';
import {
  getSystemReportQrcode,
  getSystemReportQrcodeStatus,
} from '../../api/apply';
import AreaPortraitReport from './AreaPortraitReport';
import CountryPortraitReport from './CountryPortraitReport';
import IndustryPortraitReport from './IndustryPortraitReport';
import CompanyPortraitReport from './CompanyPortraitReport';
import IndustryPortraitCountryReport from './IndustryPortraitCountryReport';
import InvestmentPromotionReport from './InvestmentPromotionReport';
import PreciseInvestReport from './PreciseInvestmentReport';
import EquityInvestmentReport from './EquityInvestmentReport';
import SpecialNewsReport from './SpecialNewsReport';
import SpecialNewsReportCountry from './SpecialNewsReportCountry';
import HighTechnologyReport from './HighTechnologyReport';
import HighTechnologyReportCountry from './HighTechnologyReportCountry';
import IntellectualPropertyReport from './IntellectualPropertyReport';
import IntellectualPropertyReportCountry from './IntellectualPropertyReportCountry';
import { reportCoverMap } from '../../utils/reportCoverMap';
// import downloadComplete from '../../assets/images/report/downloadCompleted.png';
import { requestFile } from '../../utils/request';
import { IconFont } from '../../utils/mapIcon';
import PayAmountCard from '../PayAmountCard';
import ChannelModal from '../MySelf/ChannelModal';
import style from './index.module.less';

const defaultTip = '计算模型分析中，请稍后...';
const collectTip = '数据转换中，请稍后...';
const requestTip = '报告生成中，请稍后...';

const allCitys = [
  '北京市',
  '天津市',
  '河北省',
  '山西省',
  '内蒙古自治区',
  '辽宁省',
  '吉林省',
  '黑龙江省',
  '上海市',
  '江苏省',
  '浙江省',
  '安徽省',
  '福建省',
  '江西省',
  '山东省',
  '河南省',
  '湖北省',
  '湖南省',
  '广东省',
  '广西壮族自治区',
  '海南省',
  '重庆市',
  '四川省',
  '贵州省',
  '云南省',
  '西藏自治区',
  '陕西省',
  '甘肃省',
  '青海省',
  '宁夏回族自治区',
  '新疆维吾尔自治区',
];

function RegionalPortraitReport() {
  const [chartConditions, setChartConditions] = useState([]);
  const [chartTitles, setChartTitles] = useState([]);
  const [loading, setLoading] = useState(false); // 页面loading
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadingDisable, setDownloadingDisable] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [editDisable, setEditDisable] = useState(true);
  const [tip, setTip] = useState(defaultTip); // 页面loading提示信息
  const [percent, setPercent] = useState(0);
  const [percentShow, setPercentShow] = useState(true);
  // const [loadingComplete, setLoadingComplete] = useState(false);
  const [buyStatus, setBuyStatus] = useState(true);
  const [applyExpired, setApplyExpired] = useState(false);
  const [successPay, setSuccessPay] = useState(false);
  const [channelModalVisible, setChannelModalVisible] = useState(false);
  const [amount, setAmount] = useState(18.8);
  const refList = useRef([]);
  const chartTitlesRef = useRef([]);
  const mainRef = useRef(null);
  const chartReady = useRef(null);
  const timer = useRef(null);
  const timer1 = useRef(null);
  const timer2 = useRef(null);
  const timer3 = useRef(null);
  const orderId = useRef(null);
  const expiredRef = useRef(null);
  // const commonRef = useRef({ blockUnregister: null, nextLocation: {}, lastChartCount: 0 });
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  if (urlReportMsg.area) {
    urlReportMsg.area = window.decodeURIComponent(urlReportMsg.area);
  }
  if (urlReportMsg.industry) {
    urlReportMsg.industry = window.decodeURIComponent(urlReportMsg.industry);
  }
  const pushToAchor = (name) => {
    const achorName = document.getElementById(name);
    if (achorName) {
      let dom = achorName;
      let y = 0;
      do {
        y += dom.offsetTop;
        dom = dom.parentElement;
      } while (dom.parentElement);
      mainRef.current.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    }
  };

  // const beforeunloadListener = (e) => {
  //   // console.log(e);
  //   e.preventDefault();
  //   e.returnValue = '';
  // };

  const getChartFinishedPercent = (index) => {
    chartReady.current[index] = true;
    const total = chartReady.current.length;
    const ready = chartReady.current.filter(item => item).length;
    const curPercent = (ready / total) * 100;
    if (curPercent !== 100) {
      setPercent(curPercent.toFixed(2));
    } else {
      timer.current = setTimeout(() => {
        setPercent(curPercent);
        setDownloadingDisable(false);
        setEditDisable(false);
        setLoading(false);
        // setLoadingComplete(true);
        timer1.current = setTimeout(() => {
          setPercentShow(false);
        }, 3000);
      }, 1500);
    }
  };

  // useEffect(() => {
  // }, [chartConditions]);
  // 生成并下载
  const getReportWord = async (type, urlData) => {
    let created = false;
    await checkWordCreated({
      report_id: urlData.id,
    }).then(res => {
      if (res.status === '00000') {
        if (res.data && type === 'down') {
          // const downFilename = new URLSearchParams(res.data.split('?')[1]);
          // requestFile('GET', `${res.data}`, {}, downFilename.get('fileName')).then(() => {
          //   setLoading(false);
          //   setDownloadLoading(false);
          //   setEditDisable(false);
          // });
          window.open(res.data);
          setLoading(false);
          setDownloadLoading(false);
          setEditDisable(false);
          created = true;
        } else if (res.data && type === 'edit') {
          setLoading(false);
          setEditLoading(false);
          setDownloadingDisable(false);
          openNewPage('office', {
            id: urlData.id,
          });
          created = true;
        }
      }
    });
    if (created) return;
    let imgList = [];
    const promises = [];
    const descs = [];
    const titles = [];
    const sizes = [];
    refList.current.forEach(item => {
      promises.push(item.current
        ? item.current.toDataURL() : Promise.resolve(null));
      descs.push(item.current ? item.current.getChartDesc() : '');
      sizes.push(item.current ? item.current.getChartSize() : { width: 0, height: 0 });
    });
    imgList = await Promise.all(promises);
    imgList.forEach((item, index) => {
      if (item && typeof item !== 'string') {
        sizes[index] = { width: 0, height: 0 };
      }
    });
    chartTitlesRef.current.forEach(item => {
      titles.push(item.current ? item.current.getChartTitle() : '');
    });
    const fileList = imgList.map((item, index) => base64toFile(item, chartConditions[index].title || ''));
    // 校验报告名称是否存在
    // const picName = selectMode.map(item => item.title).join(',');
    const selectModeList = chartConditions.map((item, index) => {
      let copyItem = cloneDeep(item);
      copyItem = {
        ...copyItem,
        before_describe_value_dict: descs[index].before_describe_value_dict || '',
        after_describe_value_dict: descs[index].after_describe_value_dict || '',
        title: titles[index],
        size: sizes[index],
      };
      return copyItem;
    });
    const reportMsg = {
      report_id: urlData.id,
      query_params: JSON.stringify(selectModeList),
      area_name: urlData.area || '',
      industry_name: urlData.industry || '',
      company_name: urlData.name || '',
      pic_list: fileList,
    };
    setTip(requestTip);
    downloadSystemTemplateReport(reportMsg).then(res => {
      if (res.status === '00000') {
        if (type === 'down') {
          // const downFilename = new URLSearchParams(res.data.split('?')[1]);
          // requestFile('GET', `${res.data}`, {}, downFilename.get('fileName')).then(() => {
          // });
          window.open(res.data);
          setLoading(false);
          setDownloadLoading(false);
          setEditDisable(false);
        } else {
          setLoading(false);
          setEditLoading(false);
          setDownloadingDisable(false);
          openNewPage('office', {
            id: urlData.id,
          });
        }
      } else {
        message.error(res.message);
      }
    }).finally(() => {
      setLoading(false);
      setDownloadLoading(false);
      setEditDisable(false);
      setEditLoading(false);
      setDownloadingDisable(false);
    });
  };

  const getCurReport = () => {
    const reportType = parseInt(urlReportMsg.curTemplateId);
    let res = null;
    switch (reportType) {
      case 16:
        res = <AreaPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          allCitys={allCitys}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 23:
        res = <CountryPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 17:
        res = <IndustryPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 22:
        res = <IndustryPortraitCountryReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 18:
        res = <CompanyPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 500:
        res = <InvestmentPromotionReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 499:
        res = <PreciseInvestReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 498:
        res = <EquityInvestmentReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 492:
        res = <SpecialNewsReportCountry
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 493:
        res = <SpecialNewsReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 494:
        res = <HighTechnologyReportCountry
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 495:
        res = <HighTechnologyReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 496:
        res = <IntellectualPropertyReportCountry
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      case 497:
        res = <IntellectualPropertyReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
      default:
        res = <AreaPortraitReport
          chartConditions={chartConditions}
          chartTitlesRef={chartTitlesRef}
          refList={refList}
          chartTitles={chartTitles}
          pushToAchor={pushToAchor}
          getChartFinishedPercent={getChartFinishedPercent}
          buyStatus={buyStatus}
        />;
        break;
    }
    return res;
  };

  const getOrderCurStatus = useCallback(() => {
    if (timer3.current) {
      clearTimeout(timer3.current);
      timer3.current = null;
    }
    getSystemReportQrcodeStatus({
      order_id: orderId.current,
      report_id: urlReportMsg.id,
    }).then(res => {
      if (res.status === '00000') {
        setSuccessPay(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (!expiredRef.current) {
        timer3.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    }).catch(() => {
      if (!expiredRef.current) {
        timer3.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    });
  }, []);

  useEffect(() => {
    setTip(defaultTip);
    setPercent(0);
    setLoading(true);
    getReportMsg({
      report_id: urlReportMsg.id,
      industry_code: urlReportMsg.industryKey,
    }).then(res => {
      if (res.status === '00000') {
        setBuyStatus(true);
        const curCharts = JSON.parse(res.data.query_params);

        const titles = curCharts.map(item => item.title);
        setChartTitles(titles);

        refList.current = curCharts.map(() => React.createRef());
        chartTitlesRef.current = curCharts.map(() => React.createRef());
        chartReady.current = curCharts.map(() => false);
        // 查询图表标题
        setChartConditions(curCharts);
        // setDownloadingDisable(false);
        // setEditDisable(false);
      } else {
        setBuyStatus(false);
        setLoading(false);
        // applyCountDown();
      }
    });
    // window.addEventListener('beforeunload', beforeunloadListener);
    return () => {
      clearTimeout(timer.current);
      clearTimeout(timer1.current);
      clearTimeout(timer2.current);
      clearTimeout(timer3.current);
      // window.removeEventListener('beforeunload', beforeunloadListener);
    };
  }, []);

  return (
    <>
      {loading && (
      <div className={style.loadingCover}>
        <Spin spinning={loading} tip={tip} />
      </div>
      )}
      <div className={style.all} ref={mainRef} style={{ transform: `scale(${window.innerWidth / 796})` }}>
        {/* {buyStatus && percentShow && (
        <div className={style.process}>
          <Progress percent={percent} showInfo={false} type="line" strokeWidth={6} />
        </div>
        )} */}
        {/* {buyStatus && (
      <Button
        className={downloadingDisable ? style.disableDownload : style.downLoad}
        loading={downloadLoading}
        // disabled={downloadingDisable}
        onClick={() => {
          if (loading || downloadingDisable) {
            return;
          }
          setDownloadLoading(true);
          setEditDisable(true);
          setTip(collectTip);
          setLoading(true);
          setTimeout(() => {
            getReportWord('down', urlReportMsg);
          }, 500);
        }}
      >
        生成并下载
      </Button>)} */}
        {/* <Button
        className={editDisable ? style.disableEdit : style.edit}
        loading={editLoading}
        // disabled={editDisable}
        onClick={() => {
          if (loading || editDisable) {
            return;
          }
          setEditLoading(true);
          setDownloadingDisable(true);
          setTip(collectTip);
          setLoading(true);
          setTimeout(() => {
            getReportWord('edit', urlReportMsg);
          }, 500);
        }}
      >
        在线编辑
      </Button> */}
        {getCurReport()}
        <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#333333', height: '248px', background: '#FAFAFA', padding: '32px', width: `calc(100vw * (1 - ${window.innerWidth / 796}) * 4)` }}>
          <div style={{ fontSize: '28px' }}>更多报告www.chanyeos.com</div>
          <div style={{ padding: '0px 20%', marginTop: '80px' }}>
            <Divider plain style={{ color: '#CECECE', fontSize: '24px' }}>已经到底了</Divider>
          </div>
        </div>
        {/* {!buyStatus && <div style={{ height: '100px' }}></div>}
      {!buyStatus && (
      <div className={style.lockCover}>
        <div className={style.lock} />
        <div className={style.desc}>
          扫码支付后查看并下载全文
          <DownOutlined />
        </div>
      </div>)}
      {!buyStatus && (
      <div className={style.header}>
        <div className={style.headerContent}>
          <div className={style.reportCover}>
            <img alt="" src={reportCoverMap[urlReportMsg.curTemplateId]} className={style.cover} />
          </div>
          <div className={style.applyMsg}>
            <div className={style.info}>
              <div className={style.title}>{urlReportMsg.title || ''}</div>
              <div className={style.payAmount}>
                <PayAmountCard count={amount || 0} />
              </div>
              <div className={style.portocol}>
                支付即代表您同意
                <span
                  style={{ color: '#fff', cursor: 'pointer' }}
                  onClick={() => { window.open('https://www.chanyeos.com/industry_engine_resource/industry_engine_Q_and_A_pdf/产业通用户服务协议.pdf'); }}
                >
                  《用户协议》
                </span>
              </div>
            </div>
            <div className={style.applyCode}>
              <div className={style.word}>请扫码支付</div>
              <div className={style.payQrCode}>
                <div className={style.qrcodeCover} style={{ display: applyExpired ? '' : 'none' }}>
                  二维码已失效
                </div>
                <div id="qrcode" className={style.qrcode} />
              </div>
              <div className={style.refresh} onClick={() => applyCountDown()}>
                <IconFont type="iconsync" style={{ marginRight: '4px' }} />
                刷新二维码
              </div>
            </div>
            <div className={style.buyChannel} onClick={() => setChannelModalVisible(true)}></div>
          </div>
        </div>
      </div>)}
      {successPay && (
      <div className={style.successAlert}>
        <Alert
          message={(
            <span>
              此报告
              <span style={{ margin: '0 10px' }}>已购买成功</span>
            </span>)}
          type="success"
          showIcon
        />
      </div>)}
      {
        useMemo(() => channelModalVisible && <ChannelModal
          defaultSelectIndustry={urlReportMsg.industryKey[0]}
          onClose={() => setChannelModalVisible(false)}
          paySuccessCb={(buyCOdeList) => {
            if (buyCOdeList.includes(urlReportMsg.industryKey[0])) {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          }}
        />, [channelModalVisible, setChannelModalVisible])
      } */}
        {/* {loadingComplete && (
      <Modal
        title={null}
        visible={loadingComplete}
        footer={null}
        closable={false}
        keyboard={false}
        maskClosable={false}
        maskStyle={{
          background: 'rgba(62, 92, 118, 0.79)',
        }}
        wrapClassName={style.loadingModal}
      >
        <div className={style.emptyHeader}></div>
        <img src={downloadComplete} alt="" className={style.loadingComplete} />
        <div className={style.desc}>已加载完毕</div>
        <div className={style.operator}>
          请选择
          <div
            className={style.modalBtn}
            onClick={() => {
              if (loading || downloadingDisable) {
                return;
              }
              setLoadingComplete(false);
              setDownloadLoading(true);
              setEditDisable(true);
              setTip(collectTip);
              setLoading(true);
              setTimeout(() => {
                getReportWord('down');
              }, 500);
            }}
          >
            生成并下载
          </div>
          或
          <div
            className={style.modalBtn}
            onClick={() => {
              if (loading || editDisable) {
                return;
              }
              setLoadingComplete(false);
              setEditLoading(true);
              setDownloadingDisable(true);
              setTip(collectTip);
              setLoading(true);
              setTimeout(() => {
                getReportWord('edit');
              }, 500);
            }}
          >
            在线编辑
          </div>
          此报告
        </div>
        <div className={style.closeBtn} onClick={() => setLoadingComplete(false)}>确定</div>
      </Modal>)} */}
      </div>
    </>
  );
}

export default RegionalPortraitReport;
