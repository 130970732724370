/*
* Author: zhangmeng
* Description: ''
* Date: 2021/08/05
*/

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

const color = ['#4E7293', '#A4C3B2'];
function StackBarChart(props) {
  const { data } = props;
  const { abscissa_list: xAxis, detail_list: detailList } = data;
  const [option, setOption] = useState({});

  useEffect(() => {
    const legend = [];
    const series = [];
    const { unit } = detailList[0];
    detailList.forEach((item, index) => {
      legend.push(item.target);
      series.push(
        {
          name: item.target,
          type: 'bar',
          barWidth: 18,
          data: item.ordinate,
          smooth: true,
          stack: '推出趋势',
          itemStyle: {
            color: color[index],
          },
        },
      );
    });

    const formatterFunc = (p) => {
      const arr = p.map((item) => `<div style="display: flex;margin-top: 11px;">
          <div style="font-size: 14px;color: #262D36;letter-spacing: 1.75px;margin-right: 14px">${item.seriesName}</div>
          <div style="font-size: 18px;color: #4E7293;letter-spacing: 2.25px;">${item.value}</div>
          <div style="font-size: 14px;color: #262D36;letter-spacing: 1.75px;">&nbsp;${unit}</div>
                </div>`);
      const len = arr.length;
      let str = '';
      for (let i = 0; i < len; i += 1) {
        str += arr[i];
      }
      return str;
    };

    const options = {
      grid: {
        top: 40,
        right: 10,
        bottom: 50,
        left: 20,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        axisPointer: {
          type: 'shadow',
        },
        borderColor: '#DCDCDC',
        formatter: p => {
          const str = `<div>
                         <div style="font-size: 14px;color: #3E5C76;letter-spacing: 1.75px;">
                            ${p[0].name || ''}
                          </div>
                        ${formatterFunc(p)}
                    </div>`;
          return str;
        },
      },
      legend: {
        data: legend,
        bottom: 10,
        itemGap: 50,
        itemWidth: 10,
        itemHeight: 7,
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: '#777A7A',
            interval: 0,
          },
          data: xAxis,
        },
      ],
      yAxis: [
        {
          name: unit || '',
          nameTextStyle: {
            fontSize: 10,
            color: '#919191',
            align: 'right',
          },
          axisTick: { show: false },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 12,
            color: '#777A7A',
          },
          type: 'value',
          splitLine: { // 网格线
            lineStyle: {
              type: 'dashed', // 设置网格线类型 dotted：虚线   solid:实线
            },
            show: true, // 隐藏或显示
          },
        },
      ],
      series,
    };
    setOption(options);
  }, []);

  return (
    <ReactEcharts
      option={option}
      style={{ height: '100%', width: '100%' }}
    />
  );
}

export default StackBarChart;
