import html2canvas from 'html2canvas';

export default (divElement, name) => {
  html2canvas(divElement).then((canvas) => {
    // 在此处可以对canvas进行操作，例如添加水印或其他绘制
    // 将canvas转换为图像元素
    const image = new Image();
    image.src = canvas.toDataURL('image/png');

    // 创建一个下载链接
    const link = document.createElement('a');
    link.href = image.src;
    link.download = `${name}.png`; // 设置下载文件名

    // 将链接添加到DOM中并模拟点击下载
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
