import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import { useGetCompanyPatentListQuery } from '../../../../../../../service/industry-channel';

const Childtable = ({ props, id, setPatentNum }) => {
  const [page, setPage] = useState(1);
  const { data: tableData = {}, isFetching: tableIsFetching } = useGetCompanyPatentListQuery({
    company_id: id,
    page_number: page,
    page_size: 20,
  }, { skip: !id });
  const Tablehandle = () => ({
    index: {
      width: 50,
    },
  });
  useEffect(() => {
    if (tableData) {
      setPatentNum(tableData.count);
    }
  }, [tableData]);

  return (
    <div>
      <Table
        {...props}
        Tablehandle={Tablehandle}
        data={tableData}
        isFetching={tableIsFetching}
        pagination={{
          current: page,
          pageSize: 20,
          showQuickJumper: false,
          showSizeChanger: false,
          total: tableData?.total,
          onChange: (current) => {
            setPage(current);
          },
        }}
      />
    </div>
  );
};

export default Childtable;
