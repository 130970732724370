/*
 *Description: Sanky
 *Date: Tue Nov 16 2021 09:29:03 GMT+0800 (中国标准时间)
*/

import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import ReactEcharts from 'echarts-for-react';
import { SankyChartProps } from '../basicsType';
import { addWaterMark, getDefaultCanvas, toolTipFormatter } from '../../utils/tool';
import { chartFontSize, modeStyle } from './style';
import { checkData } from './checkDataFormat';
import './index.less';
import '../../index.less';

const prefixCls: string = 'cube-component-sanky-chart';
const styleDark = classNames(`${prefixCls}-dark`);
const styleLight = classNames(`${prefixCls}-light`);
const styleEmpty = classNames(`${prefixCls}-empty`);

const styleMap = {
  dark: styleDark,
  light: styleLight,
};

const { useState, useEffect, useRef, forwardRef, useImperativeHandle } = React;

const SankyChart = forwardRef((props: SankyChartProps, ref: any) => {
  const { mode, label, legend, data, title = '', fontSize } = props;
  const [hanldeData, setHanldeData] = useState<any>({});
  const [option, setOption] = useState<any>({});
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [echartHeight, setEchartHeight] = useState('100%');
  const chartRef: any = useRef(null);
  const bodyRef: any = useRef(null);

  useEffect(() => {
    setHanldeData(checkData(data || {}));
    setChartLoading(false);
  }, [data]);

  useEffect(() => {
    const curStyle = modeStyle[mode!] || modeStyle.dark;
    const curFont = chartFontSize[fontSize!] || chartFontSize.middle;

    const sankyData: Array<Object> = [];
    const links: Array<Object> = [];

    let colorIndex = 0;

    let maxNodesCountInLevel = -Infinity;
    function parseData(nodes: SankyChartProps['data'], parent?: SankyChartProps['data'][0]) {
      if (!nodes) {
        return;
      }
      nodes.forEach(node => {
        if (!node) {
          return;
        }
        if (node?.max_level_count) {
          maxNodesCountInLevel = Math.max(maxNodesCountInLevel, node.max_level_count);
        }
        sankyData.push({
          name: node?.value,
          showData: {
            name: node?.title,
            value: '',
            unit: '',
          },
          // value: node?.children?.length || 1,
          // value: node?.level === 0 ? 2 : 1,
          depth: node?.level,
          itemStyle: {
            color: node?.level > 1 ? (// 二级子节点之后用同一个颜色
              parent?.color) : curStyle.color[colorIndex % curStyle.color.length]
            ,
          },
        });
        node.color = (sankyData[sankyData.length - 1] as any).itemStyle.color;
        colorIndex += 1;
        if (parent) {
          links.push({
            source: parent.value,
            target: node.value,
            showData: {
              name: node.title,
              value: '',
              unit: '',
            },
            // value: node?.children?.length || 1,
            // value: childCount,
            value: parent.level === 0 ? 2 : 1,
            lineStyle: {
              color: 'source',
            },
          });
        }
      });
      nodes.forEach(node => {
        parseData(node?.children as any, node);
      });
    }
    parseData(data as any);
    // console.log(links);
    // console.log(maxNodesCountInLevel);
    const series: any[] = [{
      type: 'sankey',
      data: links.length ? sankyData : [],
      links,
      nodeAlign: 'right',
      draggable: false,
      top: 5,
      bottom: 5,
      // height: '90%',
      // orient: ''
      layoutIterations: 0,
      label: {
        distance: 3,
        // padding: [2, 0, 2, 0],
        show: label,
        color: curStyle.labelColor,
        fontSize: curFont.labelFontSize,
        position: 'right',
        formatter(params: any) {
          const value = params.data.showData.name;
          let ans = '';
          const lineCount = 8;
          for (let i = 0, L = value.length; i < L; i += 1) {
            // 非0  每lineCount个  不是最后一个
            if (i && !((i + 1) % lineCount) && (L - 1 - i)) {
              ans += (`${value[i]}\n`);
            } else {
              ans += value[i];
            }
          }
          return ans;
        },
      },
      nodeWidth: 8,
      // nodeHeight: 6,
      nodeGap: 5,
      lineStyle: {
        curveness: 0.4,
        opacity: 0.49,
      },
    }];
    const serie = series[0];
    const graphHeight = maxNodesCountInLevel * curFont.labelFontSize * 2.5 + (
      (maxNodesCountInLevel - 1) * serie.nodeGap + serie.bottom
    );

    const options = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          // 模板自动生成可能需要自己手动改改
          const toolTipStyle = [
            curStyle.tooltipFont[0],
            '',
            curStyle.tooltipFont[2],
            curStyle.tooltipFont[3],
          ];
          return toolTipFormatter([params.data.showData], toolTipStyle, title);
        },
        backgroundColor: curStyle.tooltipBackground,
        extraCssText: `border: 1px solid ${curStyle.tooltipBorder}; box-shadow: 2px 4px 8px 0px ${curStyle.tooltipBoxShandow};`,
      },
      legend: {
        show: legend,
        itemWidth: 10,
        itemHeight: 7,
        selectedMode: false,
        bottom: 0,
        left: 'center',
        textStyle: {
          color: curStyle.legendColor,
          fontSize: curFont.legnedFontSize,
        },
      },
      series,
    };
    setOption(options);
    if (!chartRef.current) {
      return;
    }
    const instance = chartRef.current.getEchartsInstance();
    setEchartHeight(`${Math.max(graphHeight, instance.getHeight())}px`);
  }, [hanldeData, fontSize, label, legend, mode, title]);

  useImperativeHandle(ref, () => ({
    getCanvas(pixelRatio = 3) {
      const instance = chartRef.current.getEchartsInstance();
      return Promise.resolve(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }));
    },
    toDataURL(pixelRatio = 3) {
      if (!option?.series?.['0']?.data?.length) {
        return new Promise(res => res(
          (getDefaultCanvas(8, 8)),
        ));
      }
      const instance = chartRef.current.getEchartsInstance();
      return new Promise(resolve => resolve(addWaterMark(instance.getRenderedCanvas({
        pixelRatio,
        backgroundColor: (modeStyle[mode!] || modeStyle.dark).toDataURLBackground,
      }), pixelRatio)));
    },
    getChartDesc() {
      return props.chartDesc;
    },
    getChartSize() {
      return {
        width: bodyRef.current.clientWidth,
        height: bodyRef.current.clientHeight + 20,
      };
    },
  }));

  return (
    <div className={styleMap[mode!] || styleDark} ref={bodyRef} style={{ height: `${echartHeight}` }}>
      {chartLoading && (<div className={styleEmpty}><Spin tip="加载中..." spinning={chartLoading} /></div>)}
      {!chartLoading && (!option?.series?.['0']?.data?.length)
        && (<div className={styleEmpty}>暂无数据</div>)}
      <ReactEcharts
        option={option}
        notMerge
        style={{ width: '100%', height: `${echartHeight}` }}
        ref={chartRef}
      />
    </div>);
});

export default SankyChart;

SankyChart.defaultProps = {
  mode: 'dark',
  label: true,
  legend: true,
  fontSize: 'middle',
};
