import React from 'react';
import Container from '../../Container';

// import CreativePlatform from './CreativePlatform';
import CreativePlatform from './CreativePlatform';
import PenetrateTable from './PenetrateTable';
// import InnovateTable from './InnovateTable';
import CreativePlatformTable from './CreativePlatformTable';
// import ResearchInput from './ResearchInput';

const ResearchAndDevelopment = (props) => (
  <Container id='创新平台'>
    {/* <CreativePlatform {...props} /> */}
    {/* <CreativePlatform {...props} />
    // {/* <CreativePlatformTable {...props} /> */}
    {/* <ResearchInput {...props} /> */}

    <CreativePlatform {...props} />
    <CreativePlatformTable {...props} />
    {/* 接口暂时不通 */}
    <PenetrateTable {...props} />
  </Container>
);

export default ResearchAndDevelopment;
