import { createContext } from 'react';

interface ConfigContextState {
  geoJSONUrl: string | ((mapCode: string) => string);
}

const initialState: ConfigContextState = {
  geoJSONUrl: '/city_json/{CODE}',
};

/**
 * 配置相关的 Config
 * [state, dispatch] = useContext(SchemeRenderContext);
 * @property Provider
 * @property Consumer
 */
export const ConfigContext = createContext<ConfigContextState>(
  initialState,
);
export const { Provider, Consumer } = ConfigContext;
export {
  initialState,
};
