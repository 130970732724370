/*
 *Author: zhaochenyu
 *Description: 公用表格组件
 *Date: 2020/11/24
*/

import React, { useState } from 'react';
import {
  Table, Pagination, Input, Space,
} from 'antd';
import style from './index.module.less';

// const { Option } = Select;
// const pageSizes = [10, 20, 30, 40];

function CommonTable(props) {
  const [jumpPage, setJump] = useState(''); // 跳转 默认为空
  const {
    data: { tableHead, tableBody, total },
    curPage, setCurPage, pageSize, getCondition,
  } = props;

  // // 条/页 select-onChange
  // const selectOnChange = (value) => {
  //   setPageSize(value);
  //   setCurPage(1);
  //   getCondition({
  //     page_number: 1,
  //     page_size: value,
  //   });
  // };

  // pagination-onChange
  const paginationOnChange = (page) => {
    setCurPage(page);
    getCondition({
      page_number: page,
    });
  };

  // 跳至 input-onChange
  const inputOnChang = ((e) => {
    const { value } = e.target;
    const a = parseInt(value);
    if (a && a > 0) {
      // 纯数字且大于0
      setJump(value.replace(/[^\d]/g, ''));
    } else {
      setJump('');
    }
  });

  // 回车事件
  const pushToPage = (e) => {
    if (e.target.value) {
      const page = parseInt(e.target.value);
      const maxPage = Math.ceil(total / pageSize);
      const curPageP = page > maxPage ? maxPage : page;
      setCurPage(curPageP);
      getCondition({
        page_number: curPageP,
      });
      setJump('');
    }
  };

  return (
    <div className={style.all}>
      <Table
        columns={tableHead}
        dataSource={tableBody}
        pagination={false}
        size="middle"
        rowKey="index"
      />
      <div className={style.paginationBox} style={{ display: total === 0 ? 'none' : '' }}>
        <Space>
          {/* <div>
            <Select value={pageSize} onChange={(value) => selectOnChange(value)}>
              {
                  pageSizes.map((item) => (
                    <Option value={item} key={`page${item}`}>
                      {item}
                      &nbsp;
                      条/页
                    </Option>
                  ))
                }
            </Select>
          </div> */}
          <div>
            <Pagination
              showSizeChanger={false}
              hideOnSinglePage
              pageSize={pageSize}
              current={curPage}
              total={total > 100 ? 100 : total}
              onChange={(page, pageSizeP) => paginationOnChange(page, pageSizeP)}
            />
          </div>
          <div>
            跳至
            &nbsp;
            <Input
              value={jumpPage}
              disabled={total / pageSize <= 1}
              style={{ width: '48px' }}
              onPressEnter={(e) => pushToPage(e)}
              onChange={(value) => inputOnChang(value)}
            />
            &nbsp;
            页
          </div>
        </Space>
      </div>
    </div>
  );
}

export default CommonTable;
