import React from 'react';
import classnames from 'classnames';
import { IconFont } from '../../../../../../utils/mapIcon';
import styles from './index.module.less';

function DataSearchIcon({ className, style, onClick }) {
  return (
    <IconFont
      type="iconshujuchaxun-icon"
      className={classnames(styles.icon, className)}
      style={style}
      onClick={onClick}
    />
  );
}

/**
 * 数据搜索按钮
 * @param {string} text
 * @param {string} className
 * @param {React.CSSProperties} style
 * @param {(e: MouseEvent) => void} onClick
 * @returns {JSX.Element}
 * @constructor
 */
function DataSearchButton({ text, className, style, onClick }) {
  const _text = text?.toString();
  return (
    <div className={classnames(styles.button, className)}>
      <span className={styles.text} title={text}>
        {text}
      </span>
      {
        _text && _text !== '0' && _text !== '0/0' && <IconFont
          type="iconshujuchaxun-icon"
          className={classnames(styles.icon, className)}
          style={style}
          onClick={onClick}
        />
      }
    </div>
  );
}

export default DataSearchButton;

export { DataSearchIcon };
