import React, { useState, useMemo } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import style from './index.module.less';

/**
 *
 * @param {Array} dataSource 主体数据
 * @param {Array} columns 表头
 * @param {Boolean} edit 是否可编辑表格内容默认是true 可编辑
 * @param {Boolean} delRow 是否显示列删除Icon 默认显示 false 为显示
 * @param {Boolean} noString 是否可以输入字符串默认是不允许
 * @param {Boolean} addDataSourceState 是否显示行的增加按钮
 * @param {Boolean} addColumnState 是否显示列的增加按钮 默认是显示 以及是否可编辑表格
 * @param {Boolean} delCrosswise 是否显示行删除Icon 默认显示 false 为显示
 * @param {Function} updateDataSource 更新单元格行的数据
 * @param {Function} updateColumns 更新单元格列的数据
 * @param {Function} delColumn 删除列
 * @param {Function} delDataSource 删除行
 * @param {Function} addCell 新增列
 * @param {Function} addDataSource 新增行
 * @returns {Element}
 */
const Table = ({ dataSource, columns, delRow, delCrosswise, addDataSourceState, addColumnState = true, addDataSource, updateDataSource, delColumn, delDataSource, updateColumns, addCell, noString }) => {
  const [enidIng, setEnidIng] = useState(false);

  const width = useMemo(() => {
    if (!columns.length) {
      return 0;
    }
    let customNumber = [];
    try {
      customNumber = columns.filter(item => item.width).map(item => item.width);
    } catch (err) {
      throw new Error(err);
    }
    try {
      return (columns.length - customNumber.length) * 96 + customNumber.reduce((a, b) => a + b) + 35;
    } catch (err) {
      return 100;
    }
  }, [columns.length]);

  const delInput = (e, key, index) => {
    e.stopPropagation();
    const element = document.getElementById('input');
    const { value } = e.target;
    // 改变表头时不需要key
    if (key) {
      if (/^[0-9]+(\.[0-9]+)?$/.test(value) && !noString) {
        element?.remove();
        setEnidIng(false);
        updateDataSource(value, key, index);
      }
      if (noString || index === 'name') {
        updateDataSource(value, key, index);
      }
    } else {
      updateColumns(value, index);
    }
    element?.remove();
    setEnidIng(false);
  };

  // useEffect(() => {
  //   window.addEventListener('click', delInput);
  //   return () => {
  //     window.removeEventListener('click', delInput);
  //   };
  // }, []);

  const generateInput = (value, key, index) => {
    const inputElement = document.createElement('input');
    inputElement.id = 'input';
    inputElement.style.position = 'absolute';
    inputElement.style.top = '0px';
    inputElement.style.left = '0px';
    inputElement.style.width = '100%';
    inputElement.style.border = '1px solid #E4E4E4';
    inputElement.value = value;
    // inputElement.focus();
    inputElement.addEventListener('blur', (e) => { delInput(e, key, index); });
    // console.log(inputElement);

    return inputElement;
  };

  const handleDoubleClickEditCell = (e, key, index) => {
    // const element = document.getElementById('input');
    // if (element) {
    //   element?.remove();
    //   setEnidIng(true);
    // }
    if (enidIng) return;
    setEnidIng(true);
    const value = e.target.textContent;
    const dom = e.target;
    const inputElement = generateInput(value, key, index);
    dom.style.position = 'relative';
    dom.appendChild(inputElement);
  };
  return (
    <div className={style.content} style={{ width: `${width}px` }}>
      <div className={style.table}>
        <table>
          <thead>
            <tr>
              {
                columns.map(item => (
                  <th key={item.key || item.index} style={{ width: item.width ? `${item.width}px` : '' }} >
                    {
                      item.title && (
                        <div className={style.title}>
                          <span
                            onDoubleClick={(e) => {
                              if (!addColumnState) return;
                              e.stopPropagation();
                              handleDoubleClickEditCell(e, '', item.dataIndex);
                            }}
                          >
                            {item.title}
                          </span>
                          {
                            !delRow && <CloseCircleOutlined
                              className={style.delIcon}
                              onClick={() => {
                                if (enidIng) return;
                                delColumn(item.key, item.dataIndex);
                              }}
                            />
                          }
                        </div>
                      )
                    }
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              dataSource.map((item, index) => (
                <tr key={item.key || item.index}>
                  {
                    columns.map((item2, index2) => (
                      <td
                        key={item2.key}
                        onDoubleClick={(e) => {
                          // if (index2 === 0 || !addColumnState) {
                          //   return;
                          // }
                          if (!addColumnState) {
                            return;
                          }
                          handleDoubleClickEditCell(e, item.key, item2.dataIndex);
                        }}
                      >
                        {item[item2.dataIndex]}
                        {
                          !delCrosswise && index2 === 0 && dataSource.length > 1 && (
                            <>
                              &nbsp; &nbsp;  &nbsp;
                              <CloseCircleOutlined
                                className={style.delIcon}
                                onClick={() => {
                                  delDataSource(item.key || item.index);
                                }}
                              />
                            </>
                          )
                        }
                      </td>
                    ))
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
        {
          addDataSourceState && (
            <div className={style.addDataSource} onClick={addDataSource}>
              +
            </div>
          )
        }
      </div>
      {
        addColumnState && (
          <div className={style.add} onClick={addCell}>
            +
          </div>
        )
      }
    </div>
  );
};

export default Table;
