import React, { useMemo } from 'react';
import { useQuery } from '../../../../../hooks/url';
import {
  useGetParkHeadDescriptionQuery,
} from '../../../../../service/parkDetail';
import { openNewPage } from '../../../../../utils/urlMethods';
import { useLazyRecordingSpotQuery } from '../../../../../service/public';
import styles from './index.module.less';

const parkTool = [
  { key: 'MigrationMonitoring', name: '迁移监测' },
  { key: 'weakLinks', name: '短板诊断' },
  { key: 'targetRecruitment', name: '靶向招商' },
  { key: 'classificationBreed', name: '分级培育' },
  // { key: 'disposition', name: '区域对比' },
];

const DetailTop = () => {
  const [recordingSpotQuery] = useLazyRecordingSpotQuery();
  const query = useQuery();

  const { title } = useMemo(() => ({
    title: query.get('park-title'),
  }), [query]);
  const { code } = useMemo(() => ({
    code: window.atob(query.get('park-code')),
  }), [query]);
  const { cityCode } = useMemo(() => ({
    cityCode: window.atob(query.get('city-code')),
  }), [query]);
  const { cityName } = useMemo(() => ({
    cityName: query.get('city-name'),
  }), [query]);
  const { data } = useGetParkHeadDescriptionQuery({
    park_id: code,
  });
  const { pool } = data || {};
  const goToParkTool = (park) => {
    recordingSpotQuery({
      spot_name: `园区-工具-${park.name}`,
    });
    if (park.key === 'disposition') return;
    const params = {
      parkCode: window.btoa(code),
      cityCode: window.btoa(cityCode),
      cityName,
      title: park.name,
      key: park.key,
      isProvinceDirectly: false,
      parkName: pool?.园区全称 || '园区详情',
      parkTitle: '园区详情',
    };
    openNewPage('parkDetailTool', params);
  };

  return (
    <>
      <div className={styles.city}>
        <p>
          {pool?.园区全称}
        </p>
      </div>
      <div className={styles.line} />
      {/* <div className={styles.subtitle}>{title}</div> */}
      <div className={styles.toolBox}>
        <div className={styles.describe}>
          {
            pool?.获批时间 && (
              <>
                获批时间：
                {pool?.获批时间.split('-')[0]}
                年
                {pool?.获批时间.split('-')[1]}
                月，
              </>
            )
          }
          {/* {pool?.园区全称} */}
          {
            pool?.主导产业 && (
              <>
                主导产业为
                {pool?.主导产业}
                。
              </>
            )
          }
          企业总量
          <span>{pool?.企业数量}</span>
          家，
          &nbsp;高新技术企业
          <span>{pool?.高新技术}</span>
          家，
          专精特新
          <span>{pool?.专精特新}</span>
          家，
          上市公司
          <span>{pool?.上市企业}</span>
          家
        </div>
        <div className={styles.toolList}>
          {parkTool.map(item => (
            <div className={styles.tool} key={item.key} onClick={() => goToParkTool(item)}>
              <p />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DetailTop;
