import React, { useMemo, useEffect } from 'react';
import Table from '../../../../components/Table';
import styles from './index.module.less';
import { useGetChannelLinkCompanyListQuery } from '../../../../service/industry-channel';
import {
  useGetAreaPlugMarqueeV2Query,
  useGetCompanySpecialTagsQuery,
} from '../../../../service/interactive-v2';
import CustomTreeFilter from '../../../../components/CustomTreeFilter';
import CustomListFilter from '../../../../components/CustomListFilter';
import { findNode } from '../../../../utils/tool';
import { FilterIcon } from '../../../../components/CustomTableIcon';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    className: styles.name,
  },
  special_tag: {
    title: '资质类别',
    dataIndex: 'special_tag',
    key: 'special_tag',
    width: 150,
    ellipsis: true,
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  in_area: {
    title: '区域',
    dataIndex: 'in_area',
    key: 'in_area',
    width: 72,
    ellipsis: true,
    filters: [],
    filterIcon: <FilterIcon />,
    filterMode: 'tree',
  },
};

const COLUMN_KEYS = ['index', 'name', 'special_tag', 'in_area'];

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

// const DATA_SOURCE = (new Array(20)).fill(0).map((item, index) => ({
//   _id: '21',
//   index: index + 1,
//   name: '河南新野纺织股份有限公司',
//   special_tag: '高新技术企业、专精特新、隐形冠军、小巨人',
//   in_area: '江苏省',
// }));

function SectorCompanyTable(props) {
  const {
    industryCode,
    linkCode,
    regionCode = '000000',
    pageNumber = 1,
    pageSize = 20,
    // total = 0,
    // loading,c
    companyTypes,
    onCompanyClick,
    filteredInfo = {},
    setLinkCompanyCount,
    // onChange,
    // className,
  } = props;
  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetChannelLinkCompanyListQuery({
    industry_code: industryCode,
    second_special_tag: filteredInfo.special_tag?.filter(tag => tag !== 'all') ?? [],
    link_code: linkCode,
    area_key: getSingleOption(filteredInfo.in_area) ?? regionCode,
    first_special_tag: getSingleOption(companyTypes),
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });

  const { tableHead = [], tableBody, total = 0, count } = companyData ?? {};
  useEffect(() => {
    if (typeof count === 'number' && setLinkCompanyCount) {
      setLinkCompanyCount(count);
    }
  }, [count]);

  const { regionTree = [] } = useGetAreaPlugMarqueeV2Query(
    undefined,
    {
      selectFromResult: ({ data }) => {
        const root = data ? findNode(data[0], node => node.key === regionCode) : null;
        if (!root) return [];
        return {
          regionTree: [root],
        };
      },
    },
  );
  const { data: companyTags = [] } = useGetCompanySpecialTagsQuery();
  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      // 处理企业名称
      if (item.dataIndex === 'name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      if (item.dataIndex === 'special_tag') {
        rowConfig.filteredValue = filteredInfo.special_tag?.length > 0 ? filteredInfo.special_tag : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = companyTags;
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (item.dataIndex === 'in_area') {
        rowConfig.filters = regionTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} />;
        rowConfig.filteredValue = filteredInfo?.in_area ?? [regionCode];
      }
      return rowConfig;
    });
  }, [onCompanyClick, tableHead, regionTree, companyTags, filteredInfo, regionCode]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
    />
  );
}

export default SectorCompanyTable;
