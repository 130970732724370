import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { message, Modal } from 'antd';
import AttentionService from './AttentionService';
// import Qrcode from './Qrcode';
import { useQuery } from '../../../hooks/url';
import {
  getTaskList,
  getUserSQcoin,
  getSQcoinList,
} from '../../../api/mySelf';
import { useLazyRecordingSpotQuery } from '../../../service/public';
import style from './index.module.less';

const TAB = [
  {
    title: '获取',
    key: 'achieve',
  },
  {
    title: '明细',
    key: 'detail',
  },
];
const pCTaskList = [
  '邀请注册',
  '转发给朋友',
  '关注公众号',
  '完善个人信息',
  '关注服务号',
];
const TASKLIST = [
  {
    buttonFinishText: '已邀请',
    buttonText: '待邀请',
    investUrl: true,
  },
  {
    buttonFinishText: '已完成',
    buttonText: '待分享',
  },
  {
    buttonFinishText: '已关注',
    buttonText: '待关注',
  },
  {
    buttonFinishText: '已完成',
    buttonText: '待登录',
  },
  {
    buttonFinishText: '已关注',
    buttonText: '去关注',
  },
];

function RevenueDetail() {
  const history = useHistory();
  const query = useQuery();
  const [recordingSpotQuery] = useLazyRecordingSpotQuery();
  const tab = query.get('detail');
  const [tabKey, setTabKey] = useState(tab);
  const [sqcoin, setSqcoin] = useState(0);
  const [taskList, setTaskList] = useState(TASKLIST);
  const [detailList, setDetailList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getTask = () => {
    getTaskList().then(task => {
      if (task.status === '00000') {
        const list = [];
        const newTask = task?.data?.filter(item => pCTaskList.includes(item.title));
        TASKLIST.forEach((item, index) => {
          list.push({ ...item, ...newTask[index] });
        });
        setTaskList(list);
      }
    });
  };
  const getSQcoin = () => {
    getUserSQcoin().then(res => {
      if (res.status === '00000') {
        setSqcoin(res.data);
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // 关注服务号
  const attentionService = (value) => {
    if (value === 'first_follow_serve_accounts') {
      setIsModalOpen(true);
    }
  };

  const getSQcoinDetailList = () => {
    getSQcoinList().then(res => {
      if (res.status === '00000') {
        setDetailList(res?.data);
      }
    });
  };

  const copyUrl = (text) => {
    // text是复制文本
    // 创建input元素
    const el = document.createElement('input');
    // 给input元素赋值需要复制的文本
    el.setAttribute('value', text);
    // 将input元素插入页面
    document.body.appendChild(el);
    // 选中input元素的文本
    el.select();
    // 复制内容到剪贴板
    document.execCommand('copy');
    // 删除input元素
    document.body.removeChild(el);
    message.success('复制成功');
  };

  const backTo = () => {
    query.delete('detail');
    history.push({ search: query.toString() });
    recordingSpotQuery({
      spot_name: '个人中心',
    });
  };

  useEffect(() => {
    setTabKey(tab);
  }, [query]);

  useEffect(() => {
    getSQcoin();
    getTask();
    getSQcoinDetailList();
  }, []);

  return (
    <div className={style.all}>
      <div className={style.top}>
        <div className={style.title}>
          <div className={style.line} />
          <div>上奇币</div>
        </div>
        <div className={style.back} onClick={() => backTo()} />
      </div>
      <div className={style.banner}>
        上奇币：
        <span className={style.bannerNum}>{sqcoin}</span>
        个
      </div>
      <div className={style.table}>
        <div className={style.tab}>
          {
            TAB.map(item => (
              <div
                className={classNames(style.tabItem, { [style.active]: item.key === tabKey })}
                key={item.key}
                onClick={() => {
                  query.set('detail', item.key);
                  history.push({ search: query.toString() });
                }}
              >
                {item.title}
              </div>
            ))
          }
        </div>
        <div className={style.subject}>
          {
            tab === 'achieve' && taskList?.length > 0 && taskList.map((item, index) => (
              <div className={style.content} key={`${index}${item?.task_key}`}>
                <div className={style.left}>{item?.title}</div>
                <div className={style.center}>
                  <div className={style.text}>
                    <div className={style.firstLine}>{item?.title_describe}</div>
                    {
                      item?.investUrl && (
                        <div className={style.secondLine}>
                          <div className={style.top}>
                            百万报告、千张图谱、过亿企业一键查阅，访问我的邀请链接：
                            <span>{item?.relation_link}</span>
                          </div>
                          <div className={style.bottom}>
                            新用户注册即送上奇币
                            <div
                              className={style.copy}
                              onClick={() => copyUrl(`百万报告、千张图谱、过亿企业一键查阅，访问我的邀请链接：${item?.relation_link}新用户注册即送上奇币`)}
                            >
                              复制
                            </div>
                          </div>
                        </div>
                      )
                    }
                    <div className={style.lastLine}>
                      <div className={style.icon} />
                      +
                      {item?.obtain_sq_coin_num}
                    </div>
                  </div>
                  {
                    item?.relation_link && item?.task_key !== 'invest_user_register' && (
                      // <Qrcode url={item?.relation_link} id={item?.task_key} />
                      <img src={item?.relation_link} alt="" style={{ width: '130px', height: '130px' }} />
                    )
                  }
                </div>
                <div className={style.right}>
                  {
                    item?.completion_status === '1' && item?.task_key !== 'invest_user_register' && (
                      <div className={classNames(style.finishButton, style.button)}>{item.buttonFinishText}</div>
                    )
                  }
                  {
                    item?.completion_status === '1' && item?.task_key === 'invest_user_register' && (
                      <div className={classNames(style.finishButton, style.button)}>
                        {item.buttonFinishText}
                        {item.invite_num}
                        人
                      </div>
                    )
                  }
                  {
                    item?.completion_status === '0' && <div className={style.button} style={{ cursor: item.task_key === 'first_follow_serve_accounts' ? 'pointer' : '' }} onClick={() => { attentionService(item.task_key); }}>{item.buttonText}</div>
                  }
                </div>
              </div>
            ))
          }
          {
            tab === 'detail' && detailList?.length > 0 && detailList.map((item, index) => (
              <div className={style.detail} key={`${index}${item?.id}`}>
                <div className={style.left}>
                  <div className={style.title}>{item?.desc}</div>
                  <div className={style.date}>{item?.create_time}</div>
                </div>
                <div className={style.right}>{item?.sq_coin}</div>
              </div>
            ))
          }
        </div>
      </div>
      <Modal
        title="关注服务号"
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        <AttentionService
          onCancel={handleCancel}
          getTaskList={getTask}
        />
      </Modal>
    </div>
  );
}

export default RevenueDetail;
