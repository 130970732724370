import { IScatterVisualConfig } from '../interface/config';
import { getDataItem, omitUndefined } from './utils';
import { ISeriesGetter, SeriesTypes } from '../interface/base';
import { OptionParser } from './optionParser';
import { IDataItemObject } from '../interface/data';
import { merge, max, maxBy, min, minBy } from 'lodash';


const getEach =  (series: any) => {
  const {
    // 仅在 series 层生效
    colorBy,
    showBackground,
    roundCap,
    labelLine,
    stack,
    barWidth,
    barMaxWidth,
    barMinWidth,
    barMinHeight,
    barMinAngle,
    barGap,
    barCategoryGap,
    clip,
    markPoint,
    markLine,
    markArea,
    // series 层和 dataItem 层都可使用
    symbol,
    symbolSize,
    symbolOffset,
    label,
    lineStyle,
    itemStyle,
  } = series;

  return omitUndefined({
    colorBy,
    showBackground,
    roundCap,
    labelLine,
    stack,
    barWidth,
    barMaxWidth,
    barMinWidth,
    barMinHeight,
    barMinAngle,
    barGap,
    barCategoryGap,
    clip,
    markPoint,
    markLine,
    markArea,
    symbol,
    symbolSize,
    symbolOffset,
    label,
    lineStyle,
    itemStyle,
  });
}

export const getScatterSeries: ISeriesGetter<IScatterVisualConfig> = (series, index, extra) => {
  const type: SeriesTypes = 'scatter';
  const {
    material,
    optionParser,
    getMatchedItemStyle,
  } = extra;
  const { config } = material;
  const { visualConfig } = config;
  const { series: seriesStyles, coordinateSystem, } = visualConfig;
  const seriesStyle = seriesStyles ? seriesStyles[index]: undefined;
  const { name, data, xAxisIndex, yAxisIndex, polarIndex, geoIndex }  = series;
  const seriesProperty = {
    seriesName: name,
    seriesIndex: index,
  };
  const seriesItem = {
    name,
    type,
    coordinateSystem,
    xAxisIndex,
    yAxisIndex,
    polarIndex,
    geoIndex,
  };
  // 类目轴 [['x'],['y']]
  const categoryAxis = Object.values(optionParser.getAxis(seriesItem as any)).flat().filter(OptionParser.isAxisCategory);
  const seriesData = data.map((d, index) => {
    const item = getDataItem(d);
    const _d = {
      ...seriesProperty,
      type,
      category: categoryAxis.length !== 0 ? categoryAxis[0].data[index] as string : undefined,
      ...(item as IDataItemObject),
      index,
    };
    const matcherStyle = getMatchedItemStyle(_d);

    if (Object.keys(matcherStyle).length === 0) {
      return d;
    }
    return ({
      ...item,
      ...getEach(matcherStyle),
    });
  });

  const maxData = Array.isArray(data?.[0]) ? maxBy(data, (key: any) => key?.[key?.length - 1]) : max(data);
  const minData = Array.isArray(data?.[0]) ? minBy(data, (key: any) => key?.[key?.length - 1]) : min(data);
  const maxCount = Array.isArray(maxData) ? maxData[maxData?.length - 1] : maxData;
  const minCount = Array.isArray(minData) ? minData[minData?.length - 1] : minData;
  const range = (Number(maxCount) - Number(minCount)) / 40;
  const _seriesStyle = seriesStyle ? getEach({
    ...seriesStyle,
    symbolSize: function (val: any) {
      if (Array.isArray(val)) {
        return 3 + val[val.length - 1] / range;
      }
      return 3 + val / range;
    },
  }) : {};

  return omitUndefined(
    merge(
      seriesItem,
      getEach(visualConfig),
      { data: seriesData },
      _seriesStyle,
    )
  );
};
