/*
* Author: zhangmeng
* Description: '漏斗图'
* Date: 2022/03/02
*/

import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import style from './index.module.less';

const color1 = [
  '#FD6205', '#59D700', '#4E88FF', '#1961F5',
  '#1307ba', '#0702a5', '#030061',
];
const color2 = ['#011436', '#011436', '#011436', '#011436', '#011436', '#011436'];
function FunnelChart(props) {
  const { data } = props;
  const [options, setOptions] = useState({});
  const { length: dataLength } = data ?? {};
  // const gap = dataLength ? 100 / dataLength : 25;
  const paddingLeft = 55 / dataLength;

  useEffect(() => {
    if (!data.length) return;
    const { length } = data;
    const gap = 100 / length;
    const newData = data.map((item, index) => (
      {
        value: index * gap + gap,
        name: item.name,
        amount: item.amount,
        percent: item.percent,
        itemStyle: {
          borderWidth: 0,
          color: color1[index % color1.length],
          // color: {
          //   type: 'linear',
          //   x: 0,
          //   y: 0,
          //   x2: 0,
          //   y2: 1,
          //   colorStops: [
          //     {
          //       offset: 0, color: color1[index % color1.length],
          //     },
          //     {
          //       offset: 1, color: color2[index % color2.length],
          //     },
          //   ],
          //   global: false,
          // },
        },
      }
    ));

    const option = {
      series: [
        {
          // z: 5,
          // type: 'funnel',
          // left: '5%',
          // top: 20,
          // bottom: 20,
          // width: '50%',
          // min: 0,
          // max: 100,
          // minSize: '0%',
          // maxSize: '100%',
          // sort: 'ascending',
          // gap: 0,
          // label: {
          //   show: true,
          //   position: 'inner',
          //   fontSize: 12,
          //   color: '#ACF2FF',
          //   fontWeight: 600,
          //   textBorderColor: 'transparent',
          // },
          // labelLine: {
          //   length: 0,
          // },
          z: 10,
          name: 'Funnel',
          type: 'funnel',
          left: 0,
          top: 20,
          bottom: 20,
          width: '100%',
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          sort: 'ascending',
          gap: 2,
          label: {
            show: true,
            position: 'inside',
            color: '#FFFFFF',
            fontWeight: 600,
            fontSize: 12,
            lineHeight: 10,
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 3,
              type: 'solid',
            },
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 3,
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: newData,
        },
      ],
    };
    setOptions(option);
  }, [data]);

  return (
    <div className={style.all}>
      <div style={{ width: '50%', height: '100%', zIndex: 10 }}>
        <ReactEcharts
          option={options}
          notMerge={true}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div className={style.right}>
        {
          data.map((item, index) => (
            <div
              className={style.box}
              // style={{minHeight: `${gap}%` ?? '23%'}}
              // style={{ paddingLeft: `${(index + 0.2) * paddingLeft}%` }}
              key={index}
            >
              <div className={style.num}>
                {item.amount}
                家
              </div>
              {
                item.name !== '全量企业' && (
                  <div className={style.percent} title={item.proportion}>
                    {item.proportion}
                  </div>
                )
              }
              {/* <div className={style.percent} title={item.proportion}>
                {item.proportion}
              </div> */}
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default FunnelChart;

FunnelChart.defaultProps = {
  data: [],
};
