import React, { useMemo } from 'react';
import Table from '../../../../../../components/Table';
import styles from './index.module.less';
import { useGetCityChannelAreaMapCompanyListQuery } from '../../../../../../service/city-channel';
import {
  useGetIndustryTreeQuery,
  useGetCompanySpecialTagsQuery,
} from '../../../../../../service/interactive-v2';
import CustomTreeFilter from '../../../../../../components/MultipleChoiceTree';
import CustomListFilter from '../../../../../../components/CustomListFilter';
import { FilterIcon } from '../../../../../../components/CustomTableIcon';

const THEAD_TABLE = {
  index: {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 36,
    className: styles.index,
  },
  name: {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    className: styles.name,
  },
  special_tag: {
    title: '资质类别',
    dataIndex: 'special_tag',
    key: 'special_tag',
    width: 120,
    ellipsis: true,
    filters: [],
    defaultFilteredValue: ['all'],
    filterIcon: <FilterIcon />,
  },
  industry_tag: {
    title: '产业',
    dataIndex: 'industry_tag',
    key: 'industry_tag',
    width: 150,
    ellipsis: true,
    filters: ['all'],
    filterIcon: <FilterIcon />,
    filterMode: 'tree',
  },
};

const COLUMN_KEYS = ['index', 'name', 'special_tag', 'industry_tag'];

const getSingleOption = arr => {
  if (Array.isArray(arr)) {
    return arr[0];
  }
  if (arr === null) {
    return undefined;
  }
  return arr;
};

// const DATA_SOURCE = (new Array(20)).fill(0).map((item, index) => ({
//   _id: '21',
//   index: index + 1,
//   name: '河南新野纺织股份有限公司',
//   special_tag: '高新技术企业、专精特新、隐形冠军、小巨人',
//   in_area: '江苏省',
// }));

function SectorCompanyTable(props) {
  const {
    industryCode,
    linkCode,
    regionCode = '000000',
    pageNumber = 1,
    pageSize = 20,
    // total = 0,
    // loading,c
    companyTypes,
    onCompanyClick,
    filteredInfo = {},
    // onChange,
    // className,
  } = props;
  const {
    data: companyData,
    isFetching: isCompanyListFetching,
  } = useGetCityChannelAreaMapCompanyListQuery({
    industry_code_list: filteredInfo.industry_tag?.filter(tag => tag !== 'all') ?? [],
    second_special_tag: filteredInfo.special_tag?.filter(tag => tag !== 'all') ?? [],
    link_code: linkCode,
    area_key: regionCode,
    first_special_tag: getSingleOption(companyTypes),
    page_size: pageSize,
    page_number: pageNumber,
  }, {
    skip: !industryCode,
  });
  const { tableHead = [], tableBody = [], total = 0 } = companyData ?? {};

  const { industryTree = [] } = useGetIndustryTreeQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        if (!data) return [];
        return {
          industryTree: [data],
        };
      },
    },
  );
  const { data: companyTags = [] } = useGetCompanySpecialTagsQuery();

  const columns = useMemo(() => {
    if (!tableHead || tableHead.length === 0) {
      return COLUMN_KEYS.map(key => THEAD_TABLE[key]).filter(Boolean);
    }
    return tableHead.map(item => {
      const rowConfig = ({
        ...(THEAD_TABLE[item.dataIndex] ?? {}),
        ...item,
      });
      // 处理企业名称
      if (item.dataIndex === 'name') {
        rowConfig.onCell = (record, rowIndex) => ({
          onClick: () => onCompanyClick && onCompanyClick(record, rowIndex),
        });
      }
      if (item.dataIndex === 'special_tag') {
        rowConfig.filteredValue = filteredInfo.special_tag?.length > 0 ? filteredInfo.special_tag : ['all'];
        rowConfig.filterDropdown = _props => <CustomListFilter {..._props} extraControl />;
        rowConfig.filters = companyTags;
        rowConfig.filterResetToDefaultFilteredValue = true;
      }
      if (item.dataIndex === 'industry_tag') {
        rowConfig.filters = industryTree;
        rowConfig.filterDropdown = _props => <CustomTreeFilter {..._props} multiple />;
        rowConfig.filteredValue = filteredInfo?.industry_tag ?? ['all'];
      }
      return rowConfig;
    });
  }, [onCompanyClick, tableHead, industryTree, companyTags, filteredInfo, regionCode]);

  return (
    <Table
      columns={columns}
      dataSource={tableBody}
      loading={isCompanyListFetching}
      current={pageNumber}
      pageSize={pageSize}
      total={total}
      {...props}
    />
  );
}

export default SectorCompanyTable;
