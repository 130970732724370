import React from 'react';
import classnames from 'classnames';
import styles from './index.module.less';

const CLS_TABLE = {
  company: styles.company,
  patent: styles.patent,
  investment: styles.investment,
  policy: styles.policy,
  news: styles.news,
  reports: styles.reports,
};

/**
 * @typedef {'company' | 'patent' | 'investment' | 'policy' | 'news' | 'reports'} CardType
 */
/**
 * 数据卡片
 * @param {CardType} type
 * @param {string} name
 * @param {number} value
 * @param {string} unit
 * @param {boolean} active
 * @param {(e: {type: CardType; name: string; value: number; unit: string;}) => void} onClick
 * @param {string} className
 * @returns {JSX.Element}
 * @constructor
 */
function DataTab({
  type,
  name,
  value,
  unit,
  onClick,
  active,
  className,
}) {
  return (
    <div
      className={classnames(
        styles.dataTab,
        { active },
        className,
      )}
      onClick={() => onClick && onClick({ type, name, value, unit })}
    >
      {/* <div className={classnames(styles.icon, CLS_TABLE[type])} /> */}
      <div className={styles.name}>{name}</div>
      {/* <div> */}
      <span className={styles.value}>{value}</span>
      <span className={styles.unit}>{unit}</span>
      {/* </div> */}
    </div>
  );
}

export default DataTab;
