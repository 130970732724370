/*
* Author: zhangmeng
* Description: '验证码登录组件'
* Date: 2020/12/03
*/

import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, message, Checkbox } from 'antd';
import { getLoginNote } from '../../../api/login';
import { useLazyFindUserChanyeOsBQuery } from '../../../service/homepage';
import QrCodeDesc from '../QrCodeDesc';
import style from './index.module.less';

function CodeLogin(props) {
  const { onFinish, useApply, forgetPassword, isLoading, setChanyeosTob } = props;
  const [btnValue, setBtnValue] = useState('获取验证码');
  const [radioCheck, setRadioCheck] = useState(false);
  const [useAgreeCheck, setUserAgreeCheck] = useState(true);
  const captchaRef = useRef({ timer: null });

  const [findUserChanyeOsBQuery] = useLazyFindUserChanyeOsBQuery();

  const onFinishFailed = (params) => {
    // console.log(params, 'onFinishFailed');
  };
  const getCaptcha = (curValue) => {
    if (curValue === '获取验证码') {
      const phone = document.querySelector('#phone').value;
      const checkPhone = new RegExp(/^[1][3,4,5,6,7,8,9][0-9]{9}$/);
      if (!phone) {
        message.error('手机号不能为空');
      } else if (!checkPhone.test(phone)) {
        message.error('手机号码格式错误');
      } else {
        getLoginNote({
          phone_no: phone,
        }).then(res => {
          if (res.status === '00000') {
            let second = 60;
            setBtnValue(`${second}s`);
            if (captchaRef.current && captchaRef.current.timer) {
              clearInterval(captchaRef.current.timer);
            }
            captchaRef.current.timer = setInterval(() => {
              if (second > 0) {
                second -= 1;
                setBtnValue(`${second}s`);
              } else {
                if (captchaRef.current) {
                  clearInterval(captchaRef.current.timer);
                }
                setBtnValue('获取验证码');
              }
            }, 1000);
          } else {
            message.error(res.message);
            useApply();
          }
        });
        // 查询是否有B端的权限
        findUserChanyeOsBQuery({ username: phone }).then(res => {
          if (res.data) {
            setChanyeosTob(res.data.auth);
          }
        });
      }
    }
  };
  useEffect(() => () => {
    if (captchaRef.current && captchaRef.current.timer) {
      clearInterval(captchaRef.current.timer);
    }
  }, []);

  return (
    <div className={style.all}>
      <Form
        name="basic"
        onFinish={(value) => {
          if (useAgreeCheck) {
            onFinish(value, radioCheck);
          } else {
            message.warning('请仔细阅读《用户协议》');
          }
        }}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label=""
          name="phone_no"
          className="username"
          rules={[
            {
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号',
            },
            {
              required: true,
              message: '请输入手机号码',
            },
          ]}
        >
          <Input placeholder="请输入手机号码" id="phone" />
        </Form.Item>

        <Form.Item label="" className="captchaBox">
          <Form.Item
            name="sms"
            className="captcha"
            rules={[{ required: true, message: '请输入验证码' }]}
          >
            <Input placeholder="6位短信验证码" maxLength={6} />
          </Form.Item>
          <div className="getCaptcha">
            <Button
              ref={captchaRef}
              type="text"
              onClick={() => getCaptcha(btnValue)}
              className={btnValue !== '获取验证码' ? 'btnClicked' : ''}
              disabled={btnValue !== '获取验证码'}
            >
              {btnValue}
            </Button>
          </div>
        </Form.Item>

        <div className={style.loginFree}>
          <Checkbox checked={radioCheck} onChange={(e) => setRadioCheck(e.target.checked)} />
          &nbsp;
          <div className={style.checkBoxWord}>
            14天内免登录
          </div>
        </div>

        <div className={style.bottom}>
          <div
            className={style.register}
            onClick={() => useApply()}
            // style={{ display: 'none' }}
          >
            注册
          </div>
          <div className={style.register} style={{ display: 'none' }} onClick={() => forgetPassword()}>
            忘记密码
          </div>
        </div>

        <div className={style.desc}>
          <QrCodeDesc
            useAgreeCheck={useAgreeCheck}
            setUserAgreeCheck={setUserAgreeCheck}
          />
        </div>

        <Form.Item className="submit">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className={`${!useAgreeCheck ? style.unChecked : undefined}`}
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CodeLogin;
