import { requestFile } from '../utils/request';
// /industry_knowledge_engine/v2/dataAnalysis/catalogue/side_table_contents
const DATAANALYSIS = '/industry_knowledge_engine/v2';

// 下载表格/industry_knowledge_engine/v2/little_giant_wise_test/testing_range/condition_testing_export
const conditionTestingExport = (param, name) => requestFile('post', `${DATAANALYSIS}/little_giant_wise_test/testing_range/condition_testing_export`, {
  ...param,
}, `${name}.xlsx`, false, {
  'Content-Type': 'application/json',
}, '数据导出中');

export {
  conditionTestingExport,
};
