// 利润增长
import React, { useMemo } from 'react';
import { ECharts } from '@sqke/design';
import { Spin } from 'antd';
import style from './index.module.less';
import { useGetProfitGrowthQuery } from '../../../../../service/companyDetail';

function ProfitGrowth(props) {
  const { companyId } = props;

  const { data, isFetching } = useGetProfitGrowthQuery({
    company_id: companyId,
  }, {
    skip: !companyId,
  });

  const option = useMemo(() => {
    const series = data?.series?.map(item => (
      {
        name: item.name,
        type: 'bar',
        data: item.data,
      }
    ));

    return {
      xAxis: {
        // name: data?.xName,
        // nameTextStyle: {
        //   padding: [0, 0, 0, -10],
        // },
        type: 'category',
        data: data?.x,
      },
      tooltip: {},
      // legend: {
      //   bottom: 0,
      //   itemGap: 30,
      // },
      yAxis: {
        name: `${data?.yName}(${data?.series?.[0]?.unit})`,
        nameTextStyle: {
          padding: [0, 40, 0, 0],
        },
      },
      series,
    };
  }, [data]);
  const chartEl = useMemo(() => {
    if (!data) return <div style={{ minHeight: '300px' }}><Spin size="large" /></div>;
    if (data.series?.length > 0) {
      return <ECharts
        showLoading={isFetching}
        className={style.chart}
        option={option}
      />;
    }
    return <div className={style.noData}>该企业无此数据图谱</div>;
  }, [data]);
  return (
    <div className={style.all} id="利润增长">
      <div className={style.title}>利润增长</div>
      {/* <div className={style.subTitle}>
        {data?.title}
      </div> */}
      {
        chartEl
      }
    </div>
  );
}

export default ProfitGrowth;
