import React, { useEffect } from 'react';
import BarTable from '../../../component/BarTable';
import { useGetCommonChartQuery } from '../../../../../../../service/chartTableTool';

const FinancingRound = (props) => {
  const { url, dataFilterParams, reportData, ok } = props;
  const { data, isFetching } = useGetCommonChartQuery({
    chart_route: url,
    ...dataFilterParams,
  }, { skip: !url || !dataFilterParams || reportData || !ok });
  const newMap = () => {
    if (!data) return;
    // eslint-disable-next-line consistent-return
    return data.series.map(item => {
      if (item.unit === '笔') {
        return { ...item, type: 'line' };
      }
      return item;
    });
  };
  // 创建一个AbortController实例来取消请求
  const abortController = new AbortController();
  useEffect(() => () => {
    abortController.abort();
  }, []);
  return <BarTable {...props} data={{ ...data, series: newMap() }} isFetching={isFetching} chartType="柱线图" />;
};

export default FinancingRound;
