/**
* Author: tianye
* Description: 模态确认窗口,
* Date: 2020/12/2
* */

import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import style from './index.module.less';

export default function Confirm(props) {
  const { visible, icon, text, onOK, okText, onCancel, cancelText, className, subText } = props;

  return (
    <Modal
      className={`${style.confirmContainer} ${className || ''}`}
      maskStyle={{ backgroundColor: 'rgba(62, 92, 118, 0.2)' }}
      visible={visible}
      footer={null}
      closeIcon={(
        <CloseOutlined
          className={style.closeIcon}
          onClick={onCancel || undefined}
        />
      )}
    >
      <div className={style.confirmContent}>
        <div className={style.confirmBody}>
          <span className={style.confirmIcon}>
            {icon || ''}
          </span>
          <span>
            {text || ''}
            <br />
            <span>
              {subText || ''}
            </span>
          </span>
        </div>
        <div className={style.confirmFooter}>
          <div className={style.confirmButton} onClick={onCancel || undefined}>{cancelText || '取消'}</div>
          <div
            className={`${style.confirmButton} ${style.confirmButtonPrimary}`}
            onClick={onOK || undefined}
          >
            {okText || '确定'}
          </div>
        </div>
      </div>
    </Modal>
  );
}
