/*
 *Author: zhaochenyu
 *Description: 股权投资报告
 *Date: 2022/03/08
*/

import React, { useMemo } from 'react';
import ReportChart from '../ReportChart';
import FrontCover from './FrontCover';
import BackCover from './BackCover';
import style from '../index.module.less';

function IndustryPortraitReport(props) {
  const { chartConditions, chartTitlesRef, refList, chartTitles, pushToAchor,
    getChartFinishedPercent, buyStatus } = props;
  const params = useMemo(() => new URLSearchParams(window.location.href.split('?')[1]), [window.location.href]);
  const urlReportMsg = JSON.parse(window.decodeURIComponent(window.atob(params.get('msg') || '')) || '{}');
  return (
    <>
      {buyStatus ? (
        <>
          <div className={style.first}>
            <FrontCover industry={urlReportMsg.industry} />
          </div>

          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、产业图谱')}>
              <div className={style.firstTitle}>一、产业图谱</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、全量项目')}>
              <div className={style.firstTitle}>二、全量项目</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）融资总量')}>
              <div className={style.secondTitle}>（一）融资总量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）融资金额')}>
              <div className={style.secondTitle}>（二）融资金额</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>3</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）区域分布')}>
              <div className={style.secondTitle}>（三）区域分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）融资轮次')}>
              <div className={style.secondTitle}>（四）融资轮次</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）热点赛道')}>
              <div className={style.secondTitle}>（五）热点赛道</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（六）机构布局')}>
              <div className={style.secondTitle}>（六）机构布局</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、明星项目')}>
              <div className={style.firstTitle}>三、明星项目</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）注册资金TOP30')}>
              <div className={style.secondTitle}>（一）注册资金TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）专利排名TOP30')}>
              <div className={style.secondTitle}>（二）专利排名TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）对外合作TOP30')}>
              <div className={style.secondTitle}>（三）对外合作TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>11</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）资质能力TOP30')}>
              <div className={style.secondTitle}>（四）资质能力TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>12</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）优质股东TOP30')}>
              <div className={style.secondTitle}>（五）优质股东TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>13</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（六）融资金额TOP30')}>
              <div className={style.secondTitle}>（六）融资金额TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>14</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（七）融资速度TOP30')}>
              <div className={style.secondTitle}>（七）融资速度TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>15</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（八）资本青睐TOP30')}>
              <div className={style.secondTitle}>（八）资本青睐TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>16</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、上奇推荐')}>
              <div className={style.firstTitle}>四、上奇推荐</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>17</div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="一、产业图谱">一、产业图谱</div>
            {/* 产业产业链图 */}
            {chartConditions.length > 0 && (
            <ReportChart
              chartMsg={chartConditions[0]}
              chartIndex={0}
              ref={chartTitlesRef.current[0]}
              chartHeight={720}
              refList={refList}
              title={`图1 ${chartTitles[0] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="二、全量项目">二、全量项目</div>
            <div className={style.secondaryTitle} id="（一）融资总量">（一）融资总量</div>
            {/* 融资事件变化 */}
            {chartConditions.length > 1 && (
            <ReportChart
              chartMsg={chartConditions[1]}
              chartIndex={1}
              ref={chartTitlesRef.current[1]}
              chartHeight={430}
              refList={refList}
              title={`图2 ${chartTitles[1] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）融资金额">（二）融资金额</div>
            {/* 融资金额变化 */}
            {chartConditions.length > 2 && (
            <ReportChart
              chartMsg={chartConditions[2]}
              chartIndex={2}
              ref={chartTitlesRef.current[2]}
              chartHeight={430}
              refList={refList}
              title={`图3 ${chartTitles[2] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：融资金额只统计企业对外公开的数额。</div>
              </div>
            </div>
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）区域分布">（三）区域分布</div>
            {/* 融资事件区域分布 */}
            {chartConditions.length > 3 && (
            <ReportChart
              chartMsg={chartConditions[3]}
              chartIndex={3}
              ref={chartTitlesRef.current[3]}
              chartHeight={660}
              refList={refList}
              title={`图4 ${chartTitles[3] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）融资轮次">（四）融资轮次</div>
            {/* 投融资交易轮次分布 */}
            {chartConditions.length > 4 && (
            <ReportChart
              chartMsg={chartConditions[4]}
              chartIndex={4}
              ref={chartTitlesRef.current[4]}
              chartHeight={430}
              refList={refList}
              title={`图5 ${chartTitles[4] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（五）热点赛道">（五）热点赛道</div>
            {/* 投融资交易事件 */}
            {chartConditions.length > 5 && (
            <ReportChart
              chartMsg={chartConditions[5]}
              chartIndex={5}
              ref={chartTitlesRef.current[5]}
              chartHeight={660}
              refList={refList}
              title={`图6 ${chartTitles[5] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            {/* 投融资交易金额 */}
            {chartConditions.length > 6 && (
            <ReportChart
              chartMsg={chartConditions[6]}
              chartIndex={6}
              ref={chartTitlesRef.current[6]}
              chartHeight={660}
              refList={refList}
              title={`图7 ${chartTitles[6] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（六）机构布局">（六）机构布局</div>
            {/* 投资单位 Top30 */}
            {chartConditions.length > 7 && (
            <ReportChart
              chartMsg={chartConditions[7]}
              chartIndex={7}
              ref={chartTitlesRef.current[7]}
              chartHeight={700}
              refList={refList}
              title={`表1 ${chartTitles[7] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.primaryTitle} id="三、明星项目">三、明星项目</div>
            <div className={style.secondaryTitle} id="（一）注册资金TOP30">（一）注册资金TOP30</div>
            {/* 注册资金TOP30 */}
            {chartConditions.length > 8 && (
            <ReportChart
              chartMsg={chartConditions[8]}
              chartIndex={8}
              ref={chartTitlesRef.current[8]}
              chartHeight={840}
              refList={refList}
              title={`表2 ${chartTitles[8] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（二）专利排名TOP30">（二）专利排名TOP30</div>
            {/* 专利排名TOP30 */}
            {chartConditions.length > 9 && (
            <ReportChart
              chartMsg={chartConditions[9]}
              chartIndex={9}
              ref={chartTitlesRef.current[9]}
              chartHeight={800}
              refList={refList}
              title={`表3 ${chartTitles[9] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（三）对外合作TOP30">（三）对外合作TOP30</div>
            {/* 对外合作TOP30 */}
            {chartConditions.length > 10 && (
            <ReportChart
              chartMsg={chartConditions[10]}
              chartIndex={10}
              ref={chartTitlesRef.current[10]}
              chartHeight={800}
              refList={refList}
              title={`表4 ${chartTitles[10] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（四）资质能力TOP30">（四）资质能力TOP30</div>
            {/* 资质能力TOP30 */}
            {chartConditions.length > 11 && (
            <ReportChart
              chartMsg={chartConditions[11]}
              chartIndex={11}
              ref={chartTitlesRef.current[11]}
              chartHeight={800}
              refList={refList}
              title={`表5 ${chartTitles[11] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（五）优质股东TOP30">（五）优质股东TOP30</div>
            {/* 优质股东 Top30 */}
            {chartConditions.length > 12 && (
            <ReportChart
              chartMsg={chartConditions[12]}
              chartIndex={12}
              ref={chartTitlesRef.current[12]}
              chartHeight={800}
              refList={refList}
              title={`表6 ${chartTitles[12] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>
          <div className={style.page}>
            <div className={style.secondaryTitle} id="（六）融资金额TOP30">（六）融资金额TOP30</div>
            {/* 融资金额 Top30 */}
            {chartConditions.length > 13 && (
            <ReportChart
              chartMsg={chartConditions[13]}
              chartIndex={13}
              ref={chartTitlesRef.current[13]}
              chartHeight={800}
              refList={refList}
              title={`表7 ${chartTitles[13] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（七）融资速度TOP30">（七）融资速度TOP30</div>
            {/* 融资速度 Top30 */}
            {chartConditions.length > 14 && (
            <ReportChart
              chartMsg={chartConditions[14]}
              chartIndex={14}
              ref={chartTitlesRef.current[14]}
              chartHeight={800}
              refList={refList}
              title={`表8 ${chartTitles[14] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.page}>
            <div className={style.secondaryTitle} id="（八）资本青睐TOP30">（八）资本青睐TOP30</div>
            {/* 企业 Top30 */}
            {chartConditions.length > 15 && (
            <ReportChart
              chartMsg={chartConditions[15]}
              chartIndex={15}
              ref={chartTitlesRef.current[15]}
              chartHeight={800}
              refList={refList}
              title={`表9 ${chartTitles[15] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
            <div className={style.pageNote}>
              <div className={style.notesLine} />
              <div className={style.notes}>
                <div>注：重点机构指机构布局 Top30 单位。</div>
              </div>
            </div>
          </div>

          <div className={style.page}>
            <div className={style.primaryTitle} id="四、上奇推荐">四、上奇推荐</div>
            {/* 投资重点推荐企业 */}
            {chartConditions.length > 16 && (
            <ReportChart
              chartMsg={chartConditions[16]}
              chartIndex={16}
              ref={chartTitlesRef.current[16]}
              chartHeight={800}
              refList={refList}
              title={`表10 ${chartTitles[16] || ''}`}
              getChartFinishedPercent={getChartFinishedPercent}
            />)}
          </div>

          <div className={style.last}>
            <BackCover />
          </div>
        </>
      ) : (
        <>
          <div className={style.first}>
            <FrontCover industry={urlReportMsg.industry} />
          </div>

          <div className={style.page}>
            <div className={style.catalogue}>目录</div>
            <div className={style.firstCata} onClick={() => pushToAchor('一、产业图谱')}>
              <div className={style.firstTitle}>一、产业图谱</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>1</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('二、全量项目')}>
              <div className={style.firstTitle}>二、全量项目</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）融资总量')}>
              <div className={style.secondTitle}>（一）融资总量</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>2</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）融资金额')}>
              <div className={style.secondTitle}>（二）融资金额</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>3</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）区域分布')}>
              <div className={style.secondTitle}>（三）区域分布</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>4</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）融资轮次')}>
              <div className={style.secondTitle}>（四）融资轮次</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>5</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）热点赛道')}>
              <div className={style.secondTitle}>（五）热点赛道</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>6</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（六）机构布局')}>
              <div className={style.secondTitle}>（六）机构布局</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>8</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('三、明星项目')}>
              <div className={style.firstTitle}>三、明星项目</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（一）注册资金TOP30')}>
              <div className={style.secondTitle}>（一）注册资金TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>9</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（二）专利排名TOP30')}>
              <div className={style.secondTitle}>（二）专利排名TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>10</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（三）对外合作TOP30')}>
              <div className={style.secondTitle}>（三）对外合作TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>11</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（四）资质能力TOP30')}>
              <div className={style.secondTitle}>（四）资质能力TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>12</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（五）优质股东TOP30')}>
              <div className={style.secondTitle}>（五）优质股东TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>13</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（六）融资金额TOP30')}>
              <div className={style.secondTitle}>（六）融资金额TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>14</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（七）融资速度TOP30')}>
              <div className={style.secondTitle}>（七）融资速度TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>15</div>
            </div>
            <div className={style.secondCata} onClick={() => pushToAchor('（八）资本青睐TOP30')}>
              <div className={style.secondTitle}>（八）资本青睐TOP30</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>16</div>
            </div>
            <div className={style.firstCata} onClick={() => pushToAchor('四、上奇推荐')}>
              <div className={style.firstTitle}>四、上奇推荐</div>
              <div className={style.cataDote} />
              <div className={style.pageNumber}>17</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default IndustryPortraitReport;
