import React, { useEffect, useRef, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import QRcode from 'qrcode';
import classNames from 'classnames';
import { message, Modal, Button } from 'antd';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../hooks/url';
import style from './index.module.less';
import {
  getIndustryListCanBuy,
  getBuyIndustryQrCode,
  getTradeOrderStatus,
  paySQcoinIndustry,
} from '../../../../api/mySelf';

function IndustryChannel(props, channelModalRef) {
  const { onClose, paySuccessCb, defaultSelectIndustry } = props;

  const history = useHistory();
  const query = useQuery();

  const [amount, setAmount] = useState({
    amount: '0',
    discount: '0',
  });
  const [loading, setLoading] = useState(false);
  const [expire, setExpire] = useState(false);
  const [sqcoin, setSqcoin] = useState(0);

  const channelData = useRef([]);
  const orderId = useRef(null);
  const timer = useRef(null);
  const timer2 = useRef(null);
  const expiredRef = useRef(null);

  useImperativeHandle(channelModalRef, () => (
    {
      loading,
    }
  ));

  const getCodeList = () => {
    const _codeList = [];
    channelData.current.forEach(element => {
      if (element.checked) {
        _codeList.push(element.key);
      }
    });
    return _codeList;
  };

  const getOrderCurStatus = useCallback(() => {
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    const codeList = getCodeList();
    getTradeOrderStatus({
      order_id: orderId.current,
      industry_code_list: codeList,
    }).then(res => {
      if (res.status === '00000') {
        Modal.success({
          centered: true,
          content: <div style={{ fontSize: '20px', lineHeight: 1 }}>已成功支付</div>,
          okText: '去查看用户权益',
          keyboard: false,
          maskStyle: {
            background: 'transparent',
          },
          onOk: () => {
            paySuccessCb(codeList);
            onClose();
          },
          onCancel: () => {
            paySuccessCb(codeList);
            onClose();
          },
          width: 450,
        });
      } else if (!expiredRef.current) {
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    }).catch(() => {
      if (!expiredRef.current) {
        timer2.current = setTimeout(() => {
          getOrderCurStatus();
        }, 1000);
      }
    });
  }, [paySuccessCb, channelData]);

  const getQrCode = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (timer2.current) {
      clearTimeout(timer2.current);
      timer2.current = null;
    }
    const codeList = getCodeList();
    setLoading(true);
    getBuyIndustryQrCode({
      industry_code_list: codeList,
    }).then(res => {
      if (res.status === '00000') {
        orderId.current = res.data.order_id;
        QRcode.toCanvas(res.data.code_url, { width: 100, height: 100, margin: 1.5 }, (err, canvas) => {
          if (err) {
            console.log(err);
          }
          const contanier = document.getElementById('channelQrcode');
          if (contanier.childNodes[0]) {
            contanier.removeChild(contanier.childNodes[0]);
          }
          contanier.appendChild(canvas);

          const _amount = {
            amount: res.data.amount ?? '0',
            discount: res.data.discount ?? '0',
            goodsSQcoin: res.data.goods_sq_coin ?? '0',
          };
          setAmount(_amount);
          setLoading(false);
          setExpire(false);
          setSqcoin(res.data.user_sq_coin ?? 0);
          expiredRef.current = false;
          timer2.current = setTimeout(() => {
            getOrderCurStatus();
          }, 1000);
          timer.current = setTimeout(() => {
            setExpire(true);
            expiredRef.current = true;
          }, 120 * 1000);
        });
      }
    });
  }, [channelData]);

  const getIndustryList = () => {
    getIndustryListCanBuy().then(res => {
      if (res.status === '00000') {
        if (defaultSelectIndustry) {
          res.data.forEach(item => {
            if (item.key === defaultSelectIndustry) {
              item.checked = true;
            }
          });
          channelData.current = res.data;
          getQrCode();
        } else if (Array.isArray(res.data) && res.data.length > 0) {
          res.data[0].checked = true;
          channelData.current = res.data;
          getQrCode();
        } else {
          channelData.current = [];
        }
      }
    });
  };

  const selectChanne = (index) => {
    if (loading) return;
    const _channelData = cloneDeep(channelData.current);
    _channelData[index].checked = !_channelData[index].checked ?? true;
    const len = _channelData.filter(item => item.checked === true) ?? 0;
    if (len < 1) {
      _channelData[index].checked = true;
      message.warning('请至少选择一个产业！');
      return;
    }
    channelData.current = _channelData;
    getQrCode();
  };

  const paySQcoin = useCallback(() => {
    if (sqcoin >= amount?.goodsSQcoin) {
      const codeList = getCodeList();
      paySQcoinIndustry({
        industry_code_list: codeList,
      }).then(res => {
        if (res.status === '00000') {
          Modal.success({
            centered: true,
            content: <div style={{ fontSize: '20px', lineHeight: 1 }}>已成功支付</div>,
            okText: '去查看用户权益',
            keyboard: false,
            maskStyle: {
              background: 'transparent',
            },
            onOk: () => {
              paySuccessCb(codeList);
              onClose();
            },
            onCancel: () => {
              paySuccessCb(codeList);
              onClose();
            },
            width: 450,
          });
        }
      });
    } else {
      message.warning('上奇币不足，请获取！');
    }
  }, [channelData, sqcoin, amount]);

  useEffect(() => {
    getIndustryList();
    return () => {
      expiredRef.current = true;
      channelData.current = [];
      clearTimeout(timer.current);
      clearTimeout(timer2.current);
    };
  }, []);

  return (
    <>
      <div className={style.channel}>
        {
          channelData.current.length > 0 && channelData.current.map((item, index) => (
            <div
              className={classNames(style.channelItem, { [style.channelSelect]: item.checked })}
              key={item.key}
              onClick={() => selectChanne(index)}
            >
              {item.title}
            </div>
          ))
        }
      </div>
      <div className={style.payPanel}>
        <div className={style.wxPay}>
          <div className={style.left}>
            <div className={style.firstLine}>付款方式</div>
            <div className={style.secondLine}>
              <div className={style.wxIcon} />
              微信
            </div>
          </div>
          <div className={style.right}>
            <div className={style.payPanelText}>
              <div className={style.limitOffer}>
                限时优惠
              </div>
              <div
                className={style.firstLine}
                style={{ fontSize: amount.amount.toString().length > 6 ? 12 : '' }}
              >
                ￥
                {amount.amount}
              </div>
              <div className={style.secondLine}>
                产业：
                {channelData.current.filter((item) => item.checked === true).length ?? 0}
                个
              </div>
              {
                amount.discount && amount.discount !== '0' && (
                  <div
                    className={style.discount}
                    style={{ fontSize: amount.discount.toString().length > 6 ? 12 : '' }}
                  >
                    原价：￥
                    <span
                      className={style.through}
                    >
                      {amount.discount}
                      <svg viewBox="0 0 60 18" preserveAspectRatio="none" className={style.lineThrough}>
                        <path d="M 1 1 L 59 17 z" stroke="currentColor" strokeWidth="2" />
                      </svg>
                    </span>
                  </div>
                )
              }
            </div>
            <div className={style.qrcode}>
              <div className={classNames(style.qrcodeCover, { [style.hide]: !loading && !expire })} onClick={() => getQrCode()}>
                {loading ? '加载中...' : '已失效'}
              </div>
              <div
                className={style.qrcodePicture}
                id="channelQrcode"
              />
              <div className={style.refresh} onClick={() => getQrCode()}>刷新二维码</div>
            </div>
          </div>
        </div>
        <div className={style.shangqiPay}>
          <div className={style.left}>
            <div className={style.firstLine}>付款方式</div>
            <div className={style.secondLine}>
              <div className={style.sqIcon} />
              上奇币
            </div>
          </div>
          <div className={style.right}>
            <div className={style.firstLine}>
              产业：
              {channelData.current.filter((item) => item.checked === true).length ?? 0}
              个
            </div>
            <Button loading={!amount.goodsSQcoin} className={style.secondLine} onClick={() => paySQcoin()}>
              {amount.goodsSQcoin}
              上奇币
            </Button>
            {
              sqcoin >= amount?.goodsSQcoin && (
                <div className={style.thirdLine}>
                  当前余额
                  {sqcoin}
                  上奇币
                </div>
              )
            }
            {
              amount?.goodsSQcoin > sqcoin && (
                <div className={style.fourthLine}>
                  {/* 当前余额
                  {sqcoin}
                  上奇币， */}
                  <span
                    className={style.toget}
                    onClick={() => {
                      if (onClose) {
                        onClose();
                      }
                      query.set('tab', '个人中心');
                      query.set('detail', 'achieve');
                      history.push({ pathname: '/accountSetting', search: query.toString() });
                    }}
                  >
                    转发好友
                  </span>
                  赠上奇币，免费开通频道
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(IndustryChannel);
