import React, { useState } from 'react';
import classNames from 'classnames';
import style from './index.module.less';

function CityTabSimple({ data, itemClick, EAPersonalCenter = false }) {
  const [cityData, setCityData] = useState(data[0]?.children || []);
  const [active, setActive] = useState('推荐');
  const selectProvince = (e) => {
    setActive(e.key);
    setCityData(e.children);
  };
  return (
    <>
      <div className={style.dropGroup}>
        <div className={style.groupTitle}>
          <div>省</div>
          <div>份</div>
        </div>
        <div className={style.groupBody}>
          <div className={style.topLine} />
          <div className={style.groupCityBody}>
            {data.map(item => (
              <div
                className={classNames(style.cityItem, { [style.active]: active === item.key })}
                key={item.key}
                onClick={() => selectProvince(item)}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={style.dropGroup}>
        <div className={style.groupTitle}>
          <div>地</div>
          <div>区</div>
        </div>
        <div className={style.groupBody}>
          <div className={style.topLine} />
          <div className={style.groupCityBody}>
            {cityData.map(item => (
              <div
                className={classNames(style.cityItem, { [style.cityItemDisable]: item.disabled && EAPersonalCenter })}
                key={item.key}
                onClick={() => {
                  if (item.disabled && EAPersonalCenter) return;
                  itemClick(item);
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CityTabSimple;
